import { Formik } from "formik";
import debounce from "lodash/debounce";
import noop from "lodash/noop";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { OnlyCheckbox as Checkbox } from "components/Checkbox/OnlyCheckbox";
import { Flex } from "components/Flex";
import Select from "components/Select";
import { languageOptions } from "constants/language";
import { PalStatus } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";
import { mapSelectValuesToOptions } from "utils/select";

export const Filter = () => {
  const { status, language } = usePageInfo();
  const urlQueries = useUrlQuery();

  const history = useHistory();

  const [form, setForm] = useState({
    active: status ? status?.includes(PalStatus.Active) : true,
    approved: status ? status?.includes(PalStatus.Approved) : true,
    onboarded: status ? status?.includes(PalStatus.Onboarded) : true,
    inactive: status?.includes(PalStatus.Inactive),
    language: language?.split(",") || [],
  });

  const handleChange = (newForm: any) => {
    const status = [
      ...(newForm.active ? [PalStatus.Active] : []),
      ...(newForm.approved ? [PalStatus.Approved] : []),
      ...(newForm.onboarded ? [PalStatus.Onboarded] : []),
      ...(newForm.inactive ? [PalStatus.Inactive] : []),
    ].join(",");

    const language = newForm.language.join(",");

    const query = queryString.stringify(
      {
        ...urlQueries,
        status,
        language,
      },
      { skipEmptyString: true, skipNull: true }
    );

    if (!query && !history.location.search) {
      return;
    }

    if (`?${query}` !== history.location.search) {
      history.push(`?${query}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => debounce(handleChange, 500), [urlQueries]);

  const handleCheckboxToggle = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newForm = {
      ...form,
      [name]: checked,
    };

    setForm(newForm);
    action(newForm);
  };

  const handleLanguageChange = (value: any) => {
    const newForm = { ...form, language: value };

    setForm(newForm);
    action(newForm);
  };

  return (
    <Formik initialValues={form} onSubmit={noop} enableReinitialize>
      <Flex columnGap={40} alignItems="center" flexWrap="wrap" marginBottom={20}>
        <Flex gridGap={10} alignItems="center" height={36}>
          <Checkbox
            label="Active"
            name="active"
            onChange={handleCheckboxToggle}
            checked={form.active}
          />
          <Checkbox
            label="Approved"
            name="approved"
            onChange={handleCheckboxToggle}
            checked={form.approved}
          />
          <Checkbox
            label="Onboarded"
            name="onboarded"
            onChange={handleCheckboxToggle}
            checked={form.onboarded}
          />
          <Checkbox
            label="Inactive"
            name="inactive"
            onChange={handleCheckboxToggle}
            checked={form.inactive}
          />
        </Flex>
        <Select
          name="languages"
          options={languageOptions}
          isSearchable
          autoWidth
          isMulti
          placeholder="Filter by language"
          onChange={handleLanguageChange}
          value={mapSelectValuesToOptions(form.language, languageOptions)}
        />
      </Flex>
    </Formik>
  );
};
