import { useState } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Pal } from "generated/types";

export const usePalActions = () => {
  const [pal, setPal] = useState<Pal | null>(null);
  const [action, setPalAction] = useState(DataTableMenuActions.NoAction);

  const setAction = (action: DataTableMenuActions, pal: Pal | null = null) => {
    setPal(pal);
    setPalAction(action);
  };

  return {
    pal,
    action,
    setAction,
  };
};
