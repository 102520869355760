import { CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import styled from "styled-components";
import { CreditCard } from "styled-icons/fa-solid";

const CardElementWithToggle = () => {
  const [cardEnabled, setCardEnabled] = useState(false);

  return (
    <>
      <StyledCreditCardIcon
        className={cardEnabled ? "enabled" : ""}
        size={cardEnabled ? 20 : 40}
        onClick={() => setCardEnabled(!cardEnabled)}
        aria-label="toggle card"
      />
      {cardEnabled && (
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "1rem",
                color: "#424770",
              },
            },
          }}
        />
      )}
    </>
  );
};

const StyledCreditCardIcon = styled(CreditCard)`
  transition: 0.2s;
  cursor: pointer;
  margin-bottom: 1rem;

  color: ${({ theme }) => theme.variants.secondary};

  &.enabled {
    color: ${({ theme }) => theme.variants.primary};
  }
`;

export default CardElementWithToggle;
