import { gql } from "@apollo/client";

export const GET_PAL_VACCINATION = gql`
  query covidVaccinationRecords(
    $filter: [CovidVaccinationRecordFilter!]
    $pagination: PaginationInput
  ) {
    covidVaccinationRecords(filter: $filter, pagination: $pagination) {
      data {
        id
        approvedAt
        pal {
          data {
            id
            fullName
            email
            account {
              data {
                id
                homeLocation {
                  data {
                    state
                    zipcode
                    city
                  }
                }
              }
            }
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
