import { useQuery } from "@apollo/client";
import { useField, useFormikContext } from "formik";
import React, { FunctionComponent } from "react";
import { OptionTypeBase, ValueType } from "react-select/src/types";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Select from "components/Select";
import { ServiceRequestTypeListResult } from "generated/types";
import { nonNullWithId } from "utils/nonNull";
import { mapSelectValuesToOptions } from "utils/select";

import { GET_SERVICEREQUEST_TYPES } from "./gql";

interface Data {
  serviceRequestTypes: ServiceRequestTypeListResult;
}

interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

interface Props {
  name: string;
  initialValue?: string | null;
  placeholder?: string;
}

const CareConciergeTypeSelect: FunctionComponent<Props> = ({
  name,
  initialValue = "",
  placeholder = "",
}) => {
  const [, , helpers] = useField(name);
  const { isSubmitting } = useFormikContext();
  const { data, loading } = useQuery<Data>(GET_SERVICEREQUEST_TYPES);
  const typeOptions = nonNullWithId(data?.serviceRequestTypes?.data).map(({ id, name }) => ({
    value: id,
    label: name ?? "",
  }));

  if (loading) return <SpinningIcon size={18} />;

  const changeType = (value: ValueType<ChangeSelectEvent>) => {
    helpers.setValue(value);
  };

  return (
    <Select
      aria-label={name}
      name={name}
      placeholder={placeholder}
      defaultValue={mapSelectValuesToOptions(initialValue, typeOptions)}
      options={typeOptions}
      isDisabled={isSubmitting}
      onChange={changeType}
      isSearchable={false}
    />
  );
};

export default CareConciergeTypeSelect;
