import { gql } from "@apollo/client";

export const GET_ADMINS_LIST = gql`
  query AccountsList(
    $filter: [AccountFilter]
    $pagination: PaginationInput
    $sorting: AccountSorting
  ) {
    accounts(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        fullName
        type
        email
        phoneNumber
        insertedAt
        deletedAt
        papaTeam
        plan {
          data {
            id
            name
          }
        }
        permissions {
          admin
        }
        homeLocation {
          data {
            address
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
