import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { usePalLocationQuery } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

const Details = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = usePalLocationQuery({
    variables: { palId: id },
  });

  const { lastKnownLocation, lastKnownLocationUpdatedAt, insertedAt, updatedAt } =
    data?.pal?.data ?? {};

  return (
    <>
      <Helmet>
        <title>Pal Details - Papa Admin</title>
      </Helmet>

      <QueryErrors error={error} />

      <Panel title="Locations" loading={loading}>
        <Panel.Item>
          <Text bold>Last known location</Text>
          {lastKnownLocation ? (
            <>
              <Text color="muted">Lat: {lastKnownLocation?.lat}</Text>
              <Text color="muted">Lng: {lastKnownLocation?.lng}</Text>
            </>
          ) : (
            EMPTY_PLACEHOLDER
          )}
        </Panel.Item>

        <Panel.Item>
          <Text bold>Last known location updated at</Text>
          <Text color="muted">{formatDateTime(lastKnownLocationUpdatedAt)}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Joined at</Text>
          <Text color="muted">{formatDateTime(insertedAt)}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Updated at</Text>
          <Text color="muted">{formatDateTime(updatedAt)}</Text>
        </Panel.Item>
      </Panel>
    </>
  );
};

export default Details;
