import { useField, useFormikContext } from "formik";
import moment, { Moment } from "moment-timezone";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { DayPickerCalendar } from "components/DayPickerCalendar";
import Flex from "components/Flex";
import Feedback from "components/Form/FormFeedback";
import InputDate from "components/InputDate";
import Text from "components/Text";
import config from "config";
import { DEFAULT_TIMEZONE } from "constants/date";
import { BusinessPolicy } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { isDayBefore } from "utils/isDayBefore";
import { isOutsideBusinessPolicy } from "utils/isOutsideBusinessPolicy";

import { Values } from "../../types";

interface DateOptionEntry {
  label: string;
  value: string;
  disabled: boolean;
}

interface Props {
  isEdit: boolean;
  scheduleAfter?: Moment;
  isOutsideEarliestAllowedDate?: (day: Moment) => boolean;
  earliestAllowedDate?: string | Moment;
  dateOptions: Array<DateOptionEntry>;
  businessPolicies: Array<BusinessPolicy>;
}

const DateSelect = ({
  isEdit,
  scheduleAfter,
  isOutsideEarliestAllowedDate,
  earliestAllowedDate,
  dateOptions,
  businessPolicies,
}: Props) => {
  const { values, isSubmitting } = useFormikContext<Values>();
  const [{ value: scheduledForDay }, , scheduledForDayHelpers] = useField("scheduledForDay");
  const [{ value: selectedRecommendedDate }, { touched, error }, selectedRecommendedDateHelpers] =
    useField("selectedRecommendedDate");
  const isInvalid = touched && !!error;

  const timezone = values.location?.timezone || DEFAULT_TIMEZONE;

  const accountId = values.accountId;

  const isRecurring = !!values.freq;

  const { isSupervisor, isVisitSuccessAgent } = useCurrentAccountRole();

  const handleSelectRecommendedDate = (value: string) => {
    scheduledForDayHelpers.setValue("");
    selectedRecommendedDateHelpers.setValue(value);
  };

  useEffect(() => {
    if (!isEdit && !config.featureFlag.scheduleRecommendation) {
      scheduledForDayHelpers.setValue(moment().toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    // if not a business, don't check using isOutsideBusinessPolicy
    if (!values.businessId) return;

    if (
      isOutsideBusinessPolicy(businessPolicies, moment(selectedRecommendedDate || scheduledForDay))
    ) {
      selectedRecommendedDateHelpers.setValue("");
      scheduledForDayHelpers.setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const violatesBusinessPolicy = (day: Moment) =>
    isDayBefore(day, timezone) ||
    (!!values.businessId && isOutsideBusinessPolicy(businessPolicies, day));

  const isInvalidDate = (day: Moment) => {
    const ignoreEarliestAllowedDate = isSupervisor || isVisitSuccessAgent || values.palId;
    if (ignoreEarliestAllowedDate) {
      return violatesBusinessPolicy(day);
    } else {
      return isOutsideEarliestAllowedDate ? isOutsideEarliestAllowedDate(day) : false;
    }
  };

  return (
    <>
      {isOutsideEarliestAllowedDate && scheduleAfter ? (
        <div>
          <DayPickerCalendar name="scheduledForDay" isOutsideRange={isInvalidDate} />
          {isInvalid && <Feedback isInvalid>{error}</Feedback>}
          <Text bold size="small">
            Must schedule on or after {scheduleAfter.format("MMMM DD")}.{" "}
          </Text>
          {isRecurring && !!error ? (
            <Text size="small" color={"warning"}>
              Recurring visits cannot begin before this date. Create a one-time visit if override
              criteria applies.
            </Text>
          ) : (
            <Text size="small">If meets override criteria, contact supervisor.</Text>
          )}
        </div>
      ) : (
        <>
          {earliestAllowedDate != null && (
            <Info>
              <Text bold inline color={earliestAllowedDate ? undefined : "warning"}>
                Note:
              </Text>{" "}
              In this area we recommend scheduling this visit{" "}
              <Text bold inline>
                on or after {dateOptions[0].label}
              </Text>
            </Info>
          )}
          <Flex wrap columnGap={25} rowGap={10}>
            {config.featureFlag.scheduleRecommendation &&
              !isEdit &&
              dateOptions.map(({ label, value, disabled }, index) => (
                <DateOption
                  data-testid={`schedule-visit-date-option-${index}`}
                  key={value}
                  selected={
                    moment(selectedRecommendedDate).dayOfYear() === moment(value).dayOfYear()
                  }
                  disabled={disabled || isSubmitting}
                  onClick={
                    disabled || isSubmitting ? undefined : () => handleSelectRecommendedDate(value)
                  }
                >
                  {label}
                </DateOption>
              ))}
            <InputDateContainer
              selected={!!scheduledForDay}
              noOutline={isEdit || !config.featureFlag.scheduleRecommendation}
            >
              <InputDate
                disabled={isEdit}
                id="scheduledForDay"
                name="scheduledForDay"
                placeholder="Other Date"
                isOutsideRange={isInvalidDate}
                onDateChange={() => selectedRecommendedDateHelpers.setValue("")}
              />
            </InputDateContainer>
          </Flex>
          {isInvalid && <Feedback isInvalid>{error}</Feedback>}
        </>
      )}
    </>
  );
};

export default DateSelect;

const InputDateContainer = styled.div<{ selected: boolean; noOutline?: boolean }>`
  ${({ selected, noOutline }) =>
    selected &&
    !noOutline &&
    css`
      border: 1px solid #0d2973;
    `}

  border-radius: 4px;
`;

const Info = styled.div`
  margin-top: -16px;
  margin-bottom: 16px;
  font-size: 12px;
`;

const DateOption = styled.div<{ selected?: boolean; disabled?: boolean }>`
  width: 97px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  ${({ selected, disabled, theme }) =>
    disabled
      ? css`
          border: 1px solid #e4e7eb;
          color: ${theme.variants.secondary};
        `
      : selected
      ? css`
          border: none;
          background: #0d2973;
          color: white;
        `
      : css`
          border: 1px solid #e4e7eb;

          :hover {
            border: 1px solid #0d2973;
          }
        `};

  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
