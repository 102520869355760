export enum KeywordType {
  negative = "negative",
  neutral = "neutral",
  positive = "positive",
}

export interface Keyword {
  id: string;
  keyword: string;
  type: KeywordType;
}
