import { useQuery } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import moment, { Moment } from "moment-timezone";
import queryString, { Stringifiable } from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DateSchema, date, object } from "yup";

import Button from "components/Button";
import { GET_PAPAS } from "components/DataTables/PapasTable/gql";
import { usePapasSorting } from "components/DataTables/PapasTable/usePapasSorting";
import Form from "components/Form";
import FormGroup from "components/Form/FormGroup";
import InputDateRange from "components/InputDateRange";
import {
  Container,
  FilterTitle,
  FilterType,
  Footer,
  StyledFormGroup,
} from "components/Modal/FilterModal.styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import PapaSearch from "components/Search/PapaSearch";
import SearchPills from "components/Search/SearchPills";
import { usePapaSearch } from "components/Search/usePapaSearch";
import Select from "components/Select";
import { BusinessSearchSelect } from "components/Select/Business/BusinessSearchSelect";
import CareConciergeAssignSelect from "components/ServiceRequest/AssignSelect";
import CareConciergeNeedSelect from "components/ServiceRequest/NeedSelect";
import { usePapasSearch } from "components/ServiceRequest/PapaSelect";
import ServiceRequestStatusSelect from "components/ServiceRequest/StatusSelect";
import CareConciergeTypeSelect from "components/ServiceRequest/TypeSelect";
import Text from "components/Text";
import { originOptions, priorityOptions } from "constants/service-requests";
import { ServiceType } from "constants/service-requests";
import { PapaListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";

interface FormValues {
  dateRange: {
    endDate: Moment | string | null;
    startDate: Moment | string | null;
  };
  priority: string | string[] | null;
  status: string | string[] | null;
  origin: string | null;
  typeId: string | null;
  needId: string | string[] | null;
  id: string | null;
  assignedToId: string | null;
  healthPlanId: string | null;
}

interface Props {
  type: ServiceType;
  onSubmit: () => void;
}

interface Data {
  papas: PapaListResult;
}

export const ServiceRequestsFilterModal: React.FC<Props> = ({ type, onSubmit }) => {
  const history = useHistory();
  const { beforeCursor, afterCursor, limit, ...serviceRequestsFilters } = usePageInfo();
  const { filter, pills, setPills } = usePapaSearch();
  const [sorting] = usePapasSorting();
  const { data, loading } = useQuery<Data>(GET_PAPAS, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter,
      sorting,
    },
  });
  const { loadOptions, searchPillNames } = usePapasSearch(papasConcealed(data?.papas.data));

  const handleSubmit = async (values: FormValues) => {
    const { id, dateRange, priority, status, origin, typeId, needId, assignedToId, healthPlanId } =
      values;
    const pillSearch = pills.map(({ name, id }) => `${name}:${id}`);
    const query: Record<
      string,
      string | number | boolean | readonly Stringifiable[] | null | undefined
    > = {
      page: 1,
      ...(dateRange.startDate
        ? { dayFrom: moment(dateRange.startDate).startOf("day").toISOString() }
        : {}),
      ...(dateRange.endDate ? { dayTo: moment(dateRange.endDate).endOf("day").toISOString() } : {}),
      ...(priority ? { priority } : {}),
      ...(status ? { status } : {}),
      ...(origin ? { origin } : {}),
      ...(typeId ? { serviceRequestTypeId: typeId } : {}),
      ...(needId ? { serviceRequestNeedId: needId } : {}),
      ...(id ? { id } : {}),
      ...(assignedToId ? (assignedToId === " " ? { assignedToId: " " } : { assignedToId }) : {}),
      ...(healthPlanId ? { healthPlanId: healthPlanId } : {}),
      ...(pills?.[0]?.id ? { papaId: pillSearch } : {}),
    };
    history.push(`?${queryString.stringify(query)}`);

    onSubmit();
  };

  const initialValues = {
    dateRange: {
      startDate: serviceRequestsFilters.dayFrom ? moment(serviceRequestsFilters.dayFrom) : null,
      endDate: serviceRequestsFilters.dayTo ? moment(serviceRequestsFilters.dayTo) : null,
    },
    priority: serviceRequestsFilters.priority ?? "",
    status: serviceRequestsFilters.status ?? "",
    origin: serviceRequestsFilters.origin ?? "",
    typeId: serviceRequestsFilters.serviceRequestTypeId ?? "",
    needId: serviceRequestsFilters.serviceRequestNeedId ?? "",
    id: serviceRequestsFilters.id ?? "",
    assignedToId: serviceRequestsFilters.assignedToId ? serviceRequestsFilters.assignedToId : "",
    healthPlanId: serviceRequestsFilters.healthPlanId ? serviceRequestsFilters.healthPlanId : "",
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={object().shape({
        dateRange: object().shape(
          {
            startDate: date()
              .nullable()
              .when("endDate", (endDate: string | null | undefined, schema: DateSchema) =>
                endDate ? schema.required("Both start and end dates must be provided") : schema
              ),
            endDate: date()
              .nullable()
              .when("startDate", (startDate: string | null | undefined, schema: DateSchema) =>
                startDate ? schema.required("Both start and end dates must be provided") : schema
              ),
          },
          [["startDate", "endDate"]]
        ),
      })}
    >
      {({ resetForm, values }) => (
        <FormikForm>
          <Container>
            <ModalContainer>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Type</FilterType>
                    </FilterTitle>
                    <CareConciergeTypeSelect
                      name="typeId"
                      initialValue={initialValues.typeId}
                      placeholder="Service Request Type"
                    />
                  </StyledFormGroup>
                </Form.Col>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Need</FilterType>
                    </FilterTitle>
                    <CareConciergeNeedSelect
                      name="needId"
                      initialValue={initialValues.needId}
                      typeId={values.typeId}
                      placeholder="Service Request Need"
                      isMulti
                    />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <StyledFormSearchGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Member Name</FilterType>
                    </FilterTitle>
                    <div style={{ marginBottom: "3rem" }}>
                      <PapaSearch
                        pills={pills}
                        setPills={setPills}
                        loadOptions={loadOptions}
                        placeholder="Search Member"
                      />
                    </div>
                    <SearchPills
                      pills={pills}
                      setPills={setPills}
                      names={searchPillNames}
                      loading={loading}
                    />
                  </StyledFormSearchGroup>
                </Form.Col>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Health Plan</FilterType>
                    </FilterTitle>
                    <BusinessSearchSelect
                      name="healthPlanId"
                      defaultValue={initialValues.healthPlanId}
                    />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
              <Form.Row>
                {type !== ServiceType.user && (
                  <Form.Col>
                    <StyledFormGroup>
                      <FilterTitle bold size="largex">
                        Filter by
                        <FilterType as="span">Assignee</FilterType>
                      </FilterTitle>
                      <CareConciergeAssignSelect
                        name="assignedToId"
                        placeholder="Search Agent Name"
                        initialValue={initialValues.assignedToId}
                      />
                    </StyledFormGroup>
                  </Form.Col>
                )}
                {type === ServiceType.unassigned && (
                  <Form.Col>
                    <StyledFormGroup>
                      <FilterTitle bold size="largex">
                        Filter by
                        <FilterType as="span">Service Request ID</FilterType>
                      </FilterTitle>
                      <Form.Input name="id" aria-label="id" placeholder="Input ID" />
                    </StyledFormGroup>
                  </Form.Col>
                )}
              </Form.Row>
              {type === ServiceType.user && (
                <Form.Row>
                  <Form.Col>
                    <StyledFormGroup>
                      <FilterTitle bold size="largex">
                        Filter by
                        <FilterType as="span">Service Request ID</FilterType>
                      </FilterTitle>
                      <Form.Input name="id" aria-label="id" placeholder="Input ID" />
                    </StyledFormGroup>
                  </Form.Col>
                  <Form.Col>
                    <StyledFormGroup>
                      <FilterTitle bold size="largex">
                        Filter by
                        <FilterType as="span">Status</FilterType>
                      </FilterTitle>
                      <ServiceRequestStatusSelect />
                    </StyledFormGroup>
                  </Form.Col>
                </Form.Row>
              )}
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Priority</FilterType>
                    </FilterTitle>
                    <Select
                      name="priority"
                      placeholder="Select priority"
                      isSearchable={false}
                      options={priorityOptions}
                      isMulti
                    />
                  </StyledFormGroup>
                </Form.Col>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Origin</FilterType>
                    </FilterTitle>
                    <Select
                      name="origin"
                      placeholder="Select origin"
                      isSearchable={false}
                      options={originOptions}
                    />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Date Opened</FilterType>
                    </FilterTitle>
                    <InputDateRange
                      id="dateRange"
                      name="dateRange"
                      isOutsideRange={() => false}
                      minimumNights={0}
                    />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
            </ModalContainer>

            <Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  setPills([]);
                  resetForm({
                    values: {
                      dateRange: {
                        startDate: null,
                        endDate: null,
                      },
                      priority: [],
                      status: [],
                      origin: "",
                      typeId: "",
                      needId: [],
                      id: "",
                      assignedToId: "",
                      healthPlanId: "",
                    },
                  });
                }}
              >
                Clear Filters
              </Button>
              <Button type="submit" variant="primary">
                Apply
              </Button>
            </Footer>
          </Container>
        </FormikForm>
      )}
    </Formik>
  );
};

const StyledFormSearchGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  > ${Text} {
    margin-bottom: 13px;
  }
`;
