import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import ProspectPalActionsMenu from "components/ActionsMenu/ProspectPalActionMenu";
import Actions from "components/ActionsMenu/ProspectPalActionMenu/Actions";
import { ProspectPalMenuActions } from "components/ActionsMenu/ProspectPalActionMenu/models";
import { useProspectPalActions } from "components/ActionsMenu/ProspectPalActionMenu/useProspectPalActions";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import {
  PalOnboardingSingleResult,
  SortDirection,
  useAdminWaitingRoomTasksLazyQuery,
} from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import { PAL_ONBOARDING } from "../gql";
import Activity from "./Activity";
import OnboardingTasks from "./OnboardingTasks";
import Overview from "./Overview";
import VehiclePanel from "./VehiclePanel";

type Props = {
  prospectPalId: string;
};

const Container = styled("div")`
  display: flex;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

const PalDetails = ({ match }: RouteComponentProps<Props>) => {
  const { page, limit } = usePageInfo();
  const { prospectPalId } = match.params;
  const { data, loading, error } = useQuery<{ palOnboarding: PalOnboardingSingleResult }>(
    PAL_ONBOARDING,
    {
      variables: {
        palOnboardingId: prospectPalId,
        pagination: { page, limit },
        sorting: { insertedAt: SortDirection.Desc },
      },
    }
  );

  const [
    getWaitingRoomTasks,
    { data: waitingRoomTasksData, loading: waitingRoomTasksLoading, error: waitingRoomTasksError },
  ] = useAdminWaitingRoomTasksLazyQuery({
    onError(error) {
      window.console.error(error);
      toast.error(error.message);
    },
  });

  const palOnboarding = data?.palOnboarding?.data;
  const { action, setAction } = useProspectPalActions();

  const { providedFirstName, providedLastName } = palOnboarding ?? {};
  const fullName = [providedFirstName || "", providedLastName || ""].join(" ");

  useTopBarOptions({
    title: "Prospect Pal Details",
    name: fullName,
    icon: AccountCircle,
    breadcrumbs: ["Accounts", { label: "Prospect Pals", link: "/prospect-pals" }],
    deps: [fullName],
  });

  return (
    <>
      <Container>
        <Overview palOnboarding={palOnboarding} loading={loading} error={error} />
        <Main>
          <FlexRow>
            <Nav>
              <Nav.Link to={`/prospect-pals/${prospectPalId}/vehicleinsurance`}>
                Vehicle Insurance
              </Nav.Link>
              <Nav.Link to={`/prospect-pals/${prospectPalId}/activity`}>Activity</Nav.Link>
              <Nav.Link to={`/prospect-pals/${prospectPalId}/onboardingtasks`}>
                Onboarding Tasks
              </Nav.Link>
            </Nav>
            {palOnboarding && (
              <ProspectPalActionsMenu
                palOnboarding={palOnboarding}
                setAction={setAction}
                isListMenu={false}
              />
            )}
            ,
          </FlexRow>
          <Switch>
            <Route
              exact
              path="/prospect-pals/:prospectPalId/vehicleinsurance"
              component={() => (
                <VehiclePanel palOnboarding={palOnboarding} error={error} loading={loading} />
              )}
            />
            <Route
              exact
              path="/prospect-pals/:prospectPalId/activity"
              component={() => <Activity id={prospectPalId} />}
            />
            <Route
              exact
              path="/prospect-pals/:prospectPalId/onboardingtasks"
              component={() => (
                <OnboardingTasks
                  id={prospectPalId}
                  query={getWaitingRoomTasks}
                  data={waitingRoomTasksData}
                  loading={waitingRoomTasksLoading}
                  error={waitingRoomTasksError}
                />
              )}
            />
            <Redirect
              from="/prospect-pals/:prospectPalId"
              to="/prospect-pals/:prospectPalId/activity"
            />
          </Switch>
        </Main>
        {palOnboarding && (
          <Actions
            palOnboarding={palOnboarding}
            action={action}
            closeModal={() => setAction(ProspectPalMenuActions.noAction)}
          />
        )}
      </Container>
    </>
  );
};

export default PalDetails;
