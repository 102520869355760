import { useFormikContext } from "formik";
import moment, { Moment } from "moment-timezone";

import { DEFAULT_TIMEZONE } from "constants/date";
import { isDayBefore, isDayBeforeOtherDay } from "utils/isDayBefore";

type useEarliestAllowedDateProps = {
  earliestAllowedDates?: { VvDate: string; IpvDate: string };
};

export const useEarliestAllowedDate = ({ earliestAllowedDates }: useEarliestAllowedDateProps) => {
  const { values } = useFormikContext<{
    isVirtual: boolean;
    location?: { timezone: string | null };
  }>();

  const timezone = values.location?.timezone ?? DEFAULT_TIMEZONE;

  const earliestAllowedDate =
    values?.isVirtual === true ? earliestAllowedDates?.VvDate : earliestAllowedDates?.IpvDate;

  const isOutsideEarliestAllowedDate = (day: Moment) => {
    try {
      if (earliestAllowedDate && moment(earliestAllowedDate).isValid()) {
        return isDayBeforeOtherDay(day, moment(earliestAllowedDate).startOf("day"), timezone);
      }
    } catch {}
    return isDayBefore(day, timezone);
  };

  const scheduleAfter =
    (earliestAllowedDate && moment(earliestAllowedDate)) || moment().tz(timezone);

  return {
    earliestAllowedDate,
    isOutsideEarliestAllowedDate,
    scheduleAfter,
  };
};
