import { useMutation } from "@apollo/client";
import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { GET_VISITS_LIST } from "components/DataTables/VisitsTable/gql";
import Modal from "components/Modal";
import { ModalContainer, ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";

import { ACCEPT_VISIT } from "./gql";

interface Props {
  visitId: string;
  isOpen: boolean;
  toggle: () => void;
}

export const AcceptVisitModal: React.FC<Props> = ({ visitId, isOpen, toggle }) => {
  const [acceptVisit, { loading }] = useMutation(ACCEPT_VISIT, {
    refetchQueries: [{ query: GET_VISITS_LIST }],
  });

  const onConfirm = async () => {
    try {
      const { data } = await acceptVisit({ variables: { visitId } });

      if (data?.acceptVisit?.data?.id) {
        toast.success("Visit was accepted");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Title>
        <StyledIcon fontWeight="bold" size={23} />
        <TextContainer>
          <Text>Accept the visit?</Text>
        </TextContainer>
      </Modal.Title>
      <Modal.Body>
        <ModalContainer>
          <Text>You will be assigned as a pal and you will be able to start a virtual call.</Text>
        </ModalContainer>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button disabled={loading} type="button" variant="primary" onClick={onConfirm}>
          {loading ? <SpinningIcon size={18} /> : "Yes"}
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
