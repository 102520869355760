import { Language } from "generated/types";

const languageDict = {
  [Language.AmericanSignLanguage]: "American Sign Language",
  [Language.Arabic]: "Arabic",
  [Language.Bengali]: "Bengali",
  [Language.Cantonese]: "Cantonese",
  [Language.Chinese]: "Chinese",
  [Language.Creole]: "Creole",
  [Language.Dutch]: "Dutch",
  [Language.English]: "English",
  [Language.Farsi]: "Farsi",
  [Language.Finnish]: "Finnish",
  [Language.French]: "French",
  [Language.Fula]: "Fula",
  [Language.German]: "German",
  [Language.Greek]: "Greek",
  [Language.Gujarati]: "Gujarati",
  [Language.Hawaiian]: "Hawaiian",
  [Language.Hebrew]: "Hebrew",
  [Language.Hindi]: "Hindi",
  [Language.Hmong]: "Hmong",
  [Language.Igbo]: "Igbo",
  [Language.Ilocano]: "Ilocano",
  [Language.Italian]: "Italian",
  [Language.Japanese]: "Japanese",
  [Language.Khmer]: "Khmer",
  [Language.Korean]: "Korean",
  [Language.Kurdish]: "Kurdish",
  [Language.Mandarin]: "Mandarin",
  [Language.Nepali]: "Nepali",
  [Language.Persian]: "Persian",
  [Language.Polish]: "Polish",
  [Language.Portuguese]: "Portuguese",
  [Language.Punjabi]: "Punjabi",
  [Language.Russian]: "Russian",
  [Language.Samoan]: "Samoan",
  [Language.Somali]: "Somali",
  [Language.Spanish]: "Spanish",
  [Language.Swahili]: "Swahili",
  [Language.Swedish]: "Swedish",
  [Language.Tagalog]: "Tagalog",
  [Language.Tamil]: "Tamil",
  [Language.Telugu]: "Telugu",
  [Language.Thai]: "Thai",
  [Language.Turkish]: "Turkish",
  [Language.Urdu]: "Urdu",
  [Language.Vietnamese]: "Vietnamese",
  [Language.Welsh]: "Welsh",
  [Language.Yiddish]: "Yiddish",
  [Language.Yoruba]: "Yoruba",
  [Language.ZzTest]: "ZZ Test",
};

export const languageOptions = [
  { label: languageDict[Language.English], value: Language.English },
  { label: languageDict[Language.Spanish], value: Language.Spanish },
  { label: languageDict[Language.AmericanSignLanguage], value: Language.AmericanSignLanguage },
  { label: languageDict[Language.Arabic], value: Language.Arabic },
  { label: languageDict[Language.Bengali], value: Language.Bengali },
  { label: languageDict[Language.Cantonese], value: Language.Cantonese },
  { label: languageDict[Language.Chinese], value: Language.Chinese },
  { label: languageDict[Language.Creole], value: Language.Creole },
  { label: languageDict[Language.Dutch], value: Language.Dutch },
  { label: languageDict[Language.Farsi], value: Language.Farsi },
  { label: languageDict[Language.Finnish], value: Language.Finnish },
  { label: languageDict[Language.French], value: Language.French },
  { label: languageDict[Language.Fula], value: Language.Fula },
  { label: languageDict[Language.German], value: Language.German },
  { label: languageDict[Language.Greek], value: Language.Greek },
  { label: languageDict[Language.Gujarati], value: Language.Gujarati },
  { label: languageDict[Language.Hawaiian], value: Language.Hawaiian },
  { label: languageDict[Language.Hebrew], value: Language.Hebrew },
  { label: languageDict[Language.Hindi], value: Language.Hindi },
  { label: languageDict[Language.Hmong], value: Language.Hmong },
  { label: languageDict[Language.Igbo], value: Language.Igbo },
  { label: languageDict[Language.Ilocano], value: Language.Ilocano },
  { label: languageDict[Language.Italian], value: Language.Italian },
  { label: languageDict[Language.Japanese], value: Language.Japanese },
  { label: languageDict[Language.Khmer], value: Language.Khmer },
  { label: languageDict[Language.Korean], value: Language.Korean },
  { label: languageDict[Language.Kurdish], value: Language.Kurdish },
  { label: languageDict[Language.Mandarin], value: Language.Mandarin },
  { label: languageDict[Language.Nepali], value: Language.Nepali },
  { label: languageDict[Language.Persian], value: Language.Persian },
  { label: languageDict[Language.Polish], value: Language.Polish },
  { label: languageDict[Language.Portuguese], value: Language.Portuguese },
  { label: languageDict[Language.Punjabi], value: Language.Punjabi },
  { label: languageDict[Language.Russian], value: Language.Russian },
  { label: languageDict[Language.Samoan], value: Language.Samoan },
  { label: languageDict[Language.Somali], value: Language.Somali },
  { label: languageDict[Language.Swahili], value: Language.Swahili },
  { label: languageDict[Language.Swedish], value: Language.Swedish },
  { label: languageDict[Language.Tagalog], value: Language.Tagalog },
  { label: languageDict[Language.Tamil], value: Language.Tamil },
  { label: languageDict[Language.Telugu], value: Language.Telugu },
  { label: languageDict[Language.Thai], value: Language.Thai },
  { label: languageDict[Language.Turkish], value: Language.Turkish },
  { label: languageDict[Language.Urdu], value: Language.Urdu },
  { label: languageDict[Language.Vietnamese], value: Language.Vietnamese },
  { label: languageDict[Language.Welsh], value: Language.Welsh },
  { label: languageDict[Language.Yiddish], value: Language.Yiddish },
  { label: languageDict[Language.Yoruba], value: Language.Yoruba },
  { label: languageDict[Language.ZzTest], value: Language.ZzTest },
];

export const getLanguage = (language: Language) => languageDict[language];
