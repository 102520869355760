import React from "react";

import { TopBarOptions } from "./MainContainerContext";
import { useTopBarOptions } from "./useTopBarOptions";

interface Props {
  options: TopBarOptions;
  children: React.ReactNode;
}

const WithTopBar = ({ options, children }: Props) => {
  useTopBarOptions({ ...options, deps: [options] });
  return <>{children}</>;
};

export default WithTopBar;
