import * as yup from "yup";

import { VisitFlagReason } from "generated/types";

export interface Schema {
  overrideVisitLeadTimeAdditionalDetails: string;
  overrideVisitLeadTimeReason: VisitFlagReason | null;
}

export const schema = yup.object().shape({
  overrideVisitLeadTimeReason: yup
    .mixed<VisitFlagReason>()
    .oneOf(Object.values(VisitFlagReason))
    .required("Required"),
  overrideVisitLeadTimeAdditionalDetails: yup.string(),
});
