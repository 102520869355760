import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import * as yup from "yup";

import Button from "components/Button";
import FormGroup from "components/Form/FormGroup";
import FormInput from "components/Form/FormInput";
import FormLabel from "components/Form/FormLabel";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { PalVisitInfo } from "components/PalVisitInfo";

interface Params {
  visitId: string;
  palId: string;
}

const VisitMatch = () => {
  useTopBarOptions({ title: "Visit Match", icon: AccessTime, breadcrumbs: ["Visits"] });
  const { palId, visitId } = useParams<Params>();

  const initialValues: Params = {
    palId: palId || "",
    visitId: visitId || "",
  };

  const [formValues, setFormValues] = useState<Params>(initialValues);

  const validationSchema = yup.object({
    palId: yup.string().required("Required"),
    visitId: yup.string().required("Required"),
  });

  return (
    <div>
      <p>Compare the required visit attributes to the pal attributes to see if they match</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          setFormValues(values);
        }}
      >
        {({ handleChange, handleBlur, isSubmitting, values }) => (
          <Form>
            <FormContainer>
              <FormGroup>
                <FormLabel required>Pal Id</FormLabel>
                <FormInput
                  name="palId"
                  aria-label="palId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.palId}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel required>Visit Id</FormLabel>
                <FormInput
                  name="visitId"
                  aria-label="visitId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.visitId}
                />
              </FormGroup>

              <Button type="submit" disabled={isSubmitting}>
                Compare
              </Button>
            </FormContainer>

            <PalVisitInfo palId={formValues.palId} visitId={formValues.visitId} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const FormContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 24px;
  }
`;

export default VisitMatch;
