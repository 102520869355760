import React, { FunctionComponent } from "react";
import styled from "styled-components";

import Button from "components/Button";
import { ServiceType } from "constants/service-requests";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { ReassignModal } from "modals/CareConcierge/Reassign";
import UpdateModal from "modals/CareConcierge/Update";

import { ServiceRequestActions } from "./models";
import { useServiceRequestActions } from "./useServiceRequestActions";

interface Props {
  type: ServiceType;
  serviceRequestIds: string[];
  onRefetch: () => void;
}
const StyledActionButton = styled(Button)`
  margin-right: 30px;
  &:disabled {
    cursor: default;
  }
`;
const ServiceRequestActionMenu: FunctionComponent<Props> = ({
  type,
  serviceRequestIds,
  onRefetch,
}) => {
  const { isCareConciergeSpecialist } = useCurrentAccountRole();
  const { action, setAction } = useServiceRequestActions();
  const assign = () => {
    setAction(ServiceRequestActions.Reassign);
  };
  const update = () => {
    setAction(ServiceRequestActions.Update);
  };
  const closeModal = () => {
    setAction(ServiceRequestActions.NoAction);
  };
  if (
    (type === ServiceType.unassigned ||
      type === ServiceType.all ||
      type === ServiceType.user ||
      type === ServiceType.papa) &&
    isCareConciergeSpecialist
  ) {
    return (
      <>
        <StyledActionButton onClick={assign} disabled={serviceRequestIds?.length === 0}>
          Assign
        </StyledActionButton>
        <StyledActionButton onClick={update} disabled={serviceRequestIds?.length === 0}>
          Update
        </StyledActionButton>
        <ReassignModal
          type={ServiceType.unassigned}
          serviceRequestIds={serviceRequestIds}
          isOpen={action === ServiceRequestActions.Reassign}
          toggle={closeModal}
          onRefetch={onRefetch}
        />
        <UpdateModal
          serviceRequestIds={serviceRequestIds}
          isOpen={action === ServiceRequestActions.Update}
          toggle={closeModal}
        />
      </>
    );
  }
  return null;
};

export default ServiceRequestActionMenu;
