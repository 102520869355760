import * as yup from "yup";

import {
  Gender,
  Language,
  LivingSituation,
  Location,
  PreferredContactMethod,
} from "generated/types";

export interface Schema {
  fullName: string | null;
  birthDate: string | null;
  gender: Gender | null;
  genderText: string | null;
  language: Language | null;
  location: Partial<Location> | null;
  address2: string | null;
  fixedPhoneNumber: string | null;
  doesNotHaveFixedPhoneNumber?: boolean;
  phoneNumber: string | null;
  doesNotHavePhoneNumber?: boolean;
  enrollTextMessaging?: boolean | null;
  email: string | null;
  doesNotHaveEmail?: boolean;
  preferredContactMethod: PreferredContactMethod | null;
  livingSituation: LivingSituation | null;
  hasPets?: boolean;
  hasCat?: boolean;
  hasDog?: boolean;
  hasOtherPet?: boolean;
  usesWheelchair?: boolean;
  smokes?: boolean;
  hearingImpaired?: boolean;
  visuallyImpaired?: boolean;
  cantDrive?: boolean;
  description?: string | null;

  genderPreference: Gender | null;
  genderPreferenceIsFlexible: boolean | null;
  genderPreferenceWasAsked: boolean;
}

export const schema = yup.object().shape({
  fullName: yup.string().required("Name is required"),
  birthDate: yup.string().nullable().required("Required"),
  gender: yup.string().nullable(),
  genderText: yup.string().nullable().max(255),
  language: yup.string().nullable().required("Required"),

  location: yup.object().shape({
    address: yup.string().required("Required"),
  }),
  address2: yup.string(),
  fixedPhoneNumber: yup.string().when("doesNotHaveFixedPhoneNumber", {
    is: true,
    otherwise: (schema: yup.StringSchema) => schema.required("Required"),
  }),
  phoneNumber: yup.string().when("doesNotHavePhoneNumber", {
    is: true,
    otherwise: (schema: yup.StringSchema) => schema.required("Required"),
  }),
  email: yup
    .string()
    .email("Email invalid")
    .when("doesNotHaveEmail", {
      is: true,
      otherwise: (schema: yup.StringSchema) => schema.required("Required"),
    }),
  preferredContactMethod: yup.string().nullable().required("Required"),

  livingSituation: yup.string().required("Living Situation is required").nullable(),

  hasPets: yup.boolean().nullable().required("Required"),
  hasCat: yup.boolean().nullable(),
  hasDog: yup.boolean().nullable(),
  hasOtherPet: yup.boolean().nullable(),
  usesWheelchair: yup.boolean().nullable().required("Required"),
  smokes: yup.boolean().nullable().required("Required"),
  hearingImpaired: yup.boolean().nullable(),
  visuallyImpaired: yup.boolean().nullable(),
  cantDrive: yup.boolean().nullable(),
  description: yup.string().nullable(),

  genderPreference: yup.string().nullable(),
  genderPreferenceIsFlexible: yup
    .boolean()
    .nullable()
    .when("genderPreference", {
      is: (genderPreference) => {
        return genderPreference === Gender.Female || genderPreference === Gender.Male;
      },
      then: (schema: yup.BooleanSchema) =>
        schema.required("Please choose yes or no for gender preference flexibility."),
    }),
  genderPreferenceWasAsked: yup.boolean().required(),
});
