import React from "react";
import ReactModal from "react-modal";

import Heading from "../Heading";
import Modal from "./index";

interface Props extends ReactModal.Props {
  toggle: () => void;
  title: string;
  width?: number;
}

export const ModalLayout: React.FC<Props> = ({
  children,
  isOpen,
  toggle,
  title,
  width,
  ...rest
}) => (
  <Modal isOpen={isOpen} onRequestClose={toggle} width={width} {...rest}>
    <Modal.Title>
      <Heading as="h2" color="primary">
        {title}
      </Heading>
    </Modal.Title>
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);
