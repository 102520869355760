import React, { useState } from "react";
import { Link } from "react-router-dom";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { ServiceType } from "constants/service-requests";
import { originOptions, priorityOptions } from "constants/service-requests";
import {
  ServiceRequest,
  ServiceRequestSorting,
  ServiceRequestStatus,
  SortDirection,
} from "generated/types";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";
import { fancyEnum } from "utils/strings/fancyEnum";

interface Params {
  type: ServiceType;
  sorting: ServiceRequestSorting;
  onSortChange: (sort: ServiceRequestSorting) => void;
}

export const useServicesTableColumns = ({
  type,
  sorting: initialSorting,
  onSortChange,
}: Params) => {
  const [sorting, setSorting] = useState<ServiceRequestSorting>(initialSorting);
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [serviceRequest, setServiceRequest] = useState<ServiceRequest | null>(null);
  const onSetSorting = (sortKey: keyof ServiceRequestSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };
  const columns: Column<ServiceRequest>[] = [
    {
      header: { title: " ", type: "select" },
      cellProps: { width: "54px" },
      dataField: "select",
    },
    {
      header: { title: "Status" },
      dataField: "status",
      formatter({ status, closedReason }) {
        return (
          `${fancyEnum(status!)}` +
          (status === ServiceRequestStatus.Closed && closedReason
            ? ` (${fancyEnum(closedReason)})`
            : "")
        );
      },
    },
    {
      header: {
        title: "Member",
        sort: sorting.memberName,
        onChange: (direction) => onSetSorting("memberName", direction),
      },
      dataField: "papa.data.id",
      formatter(serviceRequest) {
        const papaConcealedEntity = papaConcealed(serviceRequest?.papa?.data);
        return (
          <Link to={`/papas/${papaConcealedEntity?.id}/care-concierge/`}>
            {papaConcealedEntity?.fullName}
          </Link>
        );
      },
    },
    {
      header: { title: "Health Plan" },
      dataField: "papa.data.business.data.name",
    },
    {
      header: {
        title: "ID",
        sort: sorting.id,
        onChange: (direction) => onSetSorting("id", direction),
      },
      dataField: "id",
      formatter({ papa, id }) {
        return <Link to={`papas/${papa?.data?.id}/care-concierge/${id}/`}>{id}</Link>;
      },
    },
    {
      header: {
        title: "Type",
        sort: sorting.serviceRequestTypeId,
        onChange: (direction) => onSetSorting("serviceRequestTypeId", direction),
      },
      dataField: "type.data.name",
    },
    {
      header: {
        title: "Need",
        sort: sorting.serviceRequestNeedId,
        onChange: (direction) => onSetSorting("serviceRequestNeedId", direction),
      },
      dataField: "need.data.name",
      formatter({ needText, need }) {
        return need?.data?.name === "Other" ? "Other - " + needText : need?.data?.name;
      },
    },
    {
      header: {
        title: "Priority",
        sort: sorting.priority,
        onChange: (direction) => onSetSorting("priority", direction),
      },
      dataField: "priority",
      formatter({ priority }) {
        return mapValueToLabel(priorityOptions, priority);
      },
    },
    {
      header: {
        title: "Origin",
      },
      dataField: "origin",
      formatter({ origin }) {
        return mapValueToLabel(originOptions, origin);
      },
    },
    {
      header: { title: "Assignee" },
      dataField: "assignedTo",
      formatter({ assignedTo }) {
        return assignedTo?.data ? (
          <Link to={`/members/${assignedTo?.data?.id}`}>{assignedTo?.data?.fullName}</Link>
        ) : (
          "Unassigned"
        );
      },
    },
    {
      header: {
        title: "Date opened",
        sort: sorting.openedAt,
        onChange: (direction) => onSetSorting("openedAt", direction),
      },
      dataField: "openedAt",
      formatter: ({ openedAt }) => formatDateTime(openedAt),
    },
  ];

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setServiceRequest(null);
  };

  return {
    columns,
    action,
    serviceRequest,
    closeModal,
  };
};
