import React, { ChangeEvent } from "react";
import styled from "styled-components";

import Feedback from "../Form/FormFeedback";

type Props = {
  value: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  error?: string;
  onChange: (value: string) => void;
};

const FormTextArea: React.FC<Props> = ({ id, name, error, value, onChange }: Props) => {
  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <>
      <TextAreaContainer isError={!!error}>
        <StyledTextArea id={id} value={value} aria-label={name} onChange={handleOnChange} />
      </TextAreaContainer>
      {!!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

type ContainerProps = {
  isError?: boolean;
};

const TextAreaContainer = styled("div")<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px
    ${({ isError, theme }) => {
      if (isError) return theme.variants.danger;
      return theme.borderColor;
    }}
    solid;
  background-color: white;
  padding: 10px;
  border-radius: 5px;

  &:focus-within {
    border: thin solid ${({ theme }) => theme.variants.primary};
  }
`;

const StyledTextArea = styled.textarea`
  flex: 1;
  border: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: inherit;
    opacity: 0.5;
  }
`;

export default FormTextArea;
