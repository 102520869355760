import { gql } from "@apollo/client";

export const CREATE_VISIT_COMMENT = gql`
  mutation createVisitComment($input: VisitCommentInput!) {
    createVisitComment(input: $input) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_VISIT_COMMENT = gql`
  mutation updateVisitComment($id: ID!, $input: VisitCommentInput!) {
    updateVisitComment(id: $id, input: $input) {
      data {
        id
      }
    }
  }
`;
