import { ApolloError } from "@apollo/client";
import { InfoCircle } from "@styled-icons/boxicons-regular/InfoCircle";
import capitalize from "lodash/capitalize";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as PersonInCircleIcon } from "assets/images/icons/person-in-circle.svg";
import { ServiceRequestActions } from "components/ActionsMenu/ServiceRequestActionMenu/models";
import { Button } from "components/Button";
import { Button as ButtonLink } from "components/ButtonLink";
import Conditional from "components/Conditional";
import Flex from "components/Flex";
import IsTestPapaSwitch from "components/IsTestPapa/Switch";
import Link from "components/Link";
import Panel from "components/Panel";
import PapaAutomatedCallsStatusIcon from "components/PapaAutomatedCalls/StatusIcon";
import TextMessagingStatusIcon from "components/PapaTextMessaging/StatusIcon";
import PopoverInfo from "components/PopoverInfo";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import VirtualCall from "components/VirtualCall";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { FeatureFlags } from "constants/featureFlags";
import { DATA_CONCEALED } from "constants/fieldsPermissions";
import { genderOptions } from "constants/gender";
import { languageOptions } from "constants/language";
import { papaStateOptions } from "constants/papaState";
import { preferredContactsOptions } from "constants/preferredContacts";
import { relationshipLabel, relationshipOptions } from "constants/relationship";
import { Gender, IntervalUnit, Maybe, Papa, PapaAllotmentPeriod, PapaState } from "generated/types";
import { useAllowedSetPapaStatus } from "hooks/papa/useAllowedSetPapaStatus";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCallItClientProgramOrMember } from "hooks/useCallItClientProgramOrMember";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { CreateServiceRequestModal } from "modals/CareConcierge/CreateServiceRequest";
import { PapaConcealed } from "types";
import { isFieldConcealed } from "utils/fieldsPermissions/isFieldConcealed";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import {
  formatDateTime,
  getIntervalString,
  mapValueToLabel,
  minutesToHours,
} from "utils/helpers/formatters";
import { concatFirstLastNames } from "utils/strings/concatFirstLastNames";

import EditOverviewControl from "./EditOverview/EditOverviewControl";

type Props = {
  currentAllotmentPeriod?: PapaAllotmentPeriod | null | undefined;
  error?: ApolloError | Error | undefined;
  loading: boolean;
  onClickEdit: () => void;
  papa?: Papa | null | undefined;
  refetch?: any;
};

const Overview = ({ error, loading, onClickEdit, papa, refetch }: Props) => {
  const {
    capitalized: { singularEntityName: programOrMember },
  } = useCallItClientProgramOrMember();
  const {
    capitalized: { singularEntityName: programOrBusiness },
    kebabCased,
  } = useCallItClientProgramOrBusiness();

  const papaId = papa?.id || "";

  const careConciergeMatch = useRouteMatch("/papas/:id/care-concierge");
  const underCareConciergeTag = careConciergeMatch !== null;

  const papaConcealedEntity = papaConcealed(papa);

  const { isAllowedToSetPapaStatus } = useAllowedSetPapaStatus({ papa: papaConcealedEntity });

  const {
    account,
    authorizedUser,
    automatedCallOptIn,
    birthDate,
    business,
    caregiver,
    covidVaccinated,
    currentBusinessPolicy,
    dataVisibility,
    eligibleLife,
    email,
    fixedPhoneNumber,
    gender,
    genderText,
    language,
    memberId,
    messageServiceAuthorizations,
    phoneNumber,
    preferredContactMethod,
    relationship,
    resourceBudget,
    status,
    papaSuspension,
    isTestPapa,
  } = papaConcealedEntity ?? {};
  const { id: accountId, fullName: accountName } = account?.data ?? {};
  const { isAdmin, isSupervisor, isCareConciergeSpecialist } = useCurrentAccountRole();
  const limitingTimeAllocation = resourceBudget?.data?.limitingTimeAllocation?.data;

  const {
    intervalUnit,
    remainingMinutes,
    numberOfMinutes: allocation,
  } = limitingTimeAllocation ?? {};

  const allotmentHoursUpdateOldUiEnabled = useIsFeatureEnabled(
    FeatureFlags.AllotmentHoursUpdateOldUi
  );

  const isCareConciergeEnabled = currentBusinessPolicy?.data?.careConciergeEnabled ? true : false;
  const [action, setAction] = useState(ServiceRequestActions.NoAction);
  const closeModal = () => {
    setAction(ServiceRequestActions.NoAction);
  };
  const createServiceRequest = () => {
    setAction(ServiceRequestActions.CreateServiceRequest);
  };

  const handleRefetch = async () => {
    await refetch();
  };

  if (loading) return <Panel.SideLoading />;

  if (error)
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );

  const isCareConciergeField =
    underCareConciergeTag &&
    isCareConciergeEnabled &&
    (isCareConciergeSpecialist || isAdmin || isSupervisor);

  const isBannedOrSuspended = [PapaState.Banned, PapaState.Suspended].includes(
    papa?.status as PapaState
  );

  const suspendedUntil = papaSuspension?.data?.suspendedUntil;

  return (
    <Panel.Left>
      {!isBannedOrSuspended && (
        <Panel.Block spaceX="1rem">
          <ButtonLink to={`/schedule-visit/${papaId}`} block withIcon>
            <PersonInCircleIcon />
            Schedule visit
          </ButtonLink>
          <Flex flexDirection="column" gridGap="1rem">
            <VirtualCall papaId={papaId} />
          </Flex>
        </Panel.Block>
      )}

      <Panel.Block>
        <Panel.Head>
          <Panel.Item marginBottom={0}>
            <Text bold>Status</Text>
            {status ? (
              <SuccessText>{mapValueToLabel(papaStateOptions, status)}</SuccessText>
            ) : (
              EMPTY_PLACEHOLDER
            )}
            {status === PapaState.Suspended &&
              (suspendedUntil ? (
                <Tooltip
                  title={`Until ${moment(suspendedUntil).tz(moment.tz.guess()).format("LLL z")}`}
                >
                  <SuccessText>
                    {moment(suspendedUntil).isAfter(moment())
                      ? `${moment(suspendedUntil).fromNow(true)} left`
                      : "until today"}
                  </SuccessText>
                </Tooltip>
              ) : (
                <SuccessText>To be manually unsuspended</SuccessText>
              ))}
          </Panel.Item>
          {isAllowedToSetPapaStatus && <Panel.Edit onClick={onClickEdit}>Edit</Panel.Edit>}
        </Panel.Head>
        <Panel.Section>
          <IsTestPapaSwitch papaId={papaId} isTestPapa={isTestPapa} />
          <Panel.Item>
            <Text bold>Papa ID</Text>
            <Text>{papaId || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          {!isCareConciergeField && (
            <>
              <Panel.Item>
                <Text bold>Member ID</Text>
                <Text>{memberId || EMPTY_PLACEHOLDER}</Text>
              </Panel.Item>
              {caregiver?.data?.business?.data?.id && (
                <Panel.Item>
                  <Text bold>{programOrBusiness}</Text>
                  <Link
                    to={`/${kebabCased.pluralEntityName}/${caregiver?.data?.business?.data?.id}`}
                  >
                    {caregiver?.data?.business?.data?.name}
                  </Link>
                </Panel.Item>
              )}
              <CaregiverPanelItem papa={papaConcealedEntity} />
            </>
          )}

          <GenderDisplay gender={gender} genderText={genderText} />

          <Panel.Item>
            <Text bold>Birth date</Text>
            <Text>
              {isFieldConcealed("birthDate", dataVisibility)
                ? DATA_CONCEALED
                : formatDateTime(birthDate, { format: "L" })}
            </Text>
          </Panel.Item>
          {authorizedUser?.data?.relationship && (
            <Panel.Item>
              <Text bold>Authorized User</Text>
              <Text>
                {concatFirstLastNames(authorizedUser.data.firstName, authorizedUser.data.lastName)}
                {" - "}
                {capitalize(authorizedUser.data.relationship)}
              </Text>
            </Panel.Item>
          )}
          <Panel.Item>
            <Text bold>Preferred Language</Text>
            <Text>{mapValueToLabel(languageOptions, language) || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <MobileNumberWithStatus>
            <Panel.Item>
              <Text bold>Preferred contact method</Text>
              <Text>
                {preferredContactMethod
                  ? mapValueToLabel(preferredContactsOptions, preferredContactMethod)
                  : EMPTY_PLACEHOLDER}
              </Text>
            </Panel.Item>

            <PapaAutomatedCallsStatusIcon enabled={!!automatedCallOptIn} />
          </MobileNumberWithStatus>

          <Panel.Item>
            <Text bold>Member Email</Text>
            {email ? (
              <Text title={email} wrapString>
                {email}
              </Text>
            ) : (
              EMPTY_PLACEHOLDER
            )}
          </Panel.Item>

          <MobileNumberWithStatus>
            <Panel.Item>
              <Text bold>Mobile number</Text>
              <Text>{phoneNumber || EMPTY_PLACEHOLDER}</Text>
            </Panel.Item>

            <TextMessagingStatusIcon
              messageServiceAuthorization={messageServiceAuthorizations?.data?.[0]}
            />
          </MobileNumberWithStatus>
          <Panel.Item>
            <Text bold>Home number</Text>
            <Text>{fixedPhoneNumber || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          {isCareConciergeField ? (
            <>
              <CaregiverPanelItem papa={papaConcealedEntity} />
              <Panel.Item>
                <Text bold>Health Plan</Text>
                <Text>{business?.data?.name || EMPTY_PLACEHOLDER}</Text>
              </Panel.Item>
              <Panel.Item>
                <Text bold>Health Plan ID</Text>
                <Text>{eligibleLife?.data?.planId || EMPTY_PLACEHOLDER}</Text>
              </Panel.Item>
              <Panel.Item>
                <Text bold>Health Plan Member ID</Text>
                <Text>{memberId || EMPTY_PLACEHOLDER}</Text>
              </Panel.Item>
              <Panel.Item>
                <Text bold>Health Plan Type</Text>
                <Text>{eligibleLife?.data?.planType || EMPTY_PLACEHOLDER}</Text>
              </Panel.Item>
              <Panel.Item>
                <Text bold>Health Plan Phone Number</Text>
                <Text>{business?.data?.inboundMemberSupportNumber || EMPTY_PLACEHOLDER}</Text>
              </Panel.Item>
              <Panel.Item>
                <Text bold>PCP Name</Text>
                <Text>{eligibleLife?.data?.pcpName || EMPTY_PLACEHOLDER}</Text>
              </Panel.Item>
            </>
          ) : (
            <>
              <Panel.Item>
                <Text bold>{programOrMember}</Text>
                {accountId ? (
                  <Link to={`/members/${accountId}`}>{accountName}</Link>
                ) : (
                  EMPTY_PLACEHOLDER
                )}
              </Panel.Item>

              <Panel.Item>
                <Text bold>Relationship</Text>
                <Text>
                  {relationship
                    ? mapValueToLabel(relationshipOptions, relationship)
                    : EMPTY_PLACEHOLDER}
                </Text>
              </Panel.Item>

              {!allotmentHoursUpdateOldUiEnabled &&
                allocation != null &&
                remainingMinutes != null &&
                intervalUnit && (
                  <>
                    <Panel.Item>
                      <Text bold>Hours used</Text>
                      <Text>{minutesToHours(allocation - remainingMinutes)}</Text>
                    </Panel.Item>
                    <Panel.Item>
                      <Text bold>Hours remaining</Text>
                      <Text>{minutesToHours(remainingMinutes)}</Text>
                    </Panel.Item>
                    <Panel.Item>
                      <Text bold>Allotment hours</Text>
                      <Text>
                        {minutesToHours(allocation)} / {getIntervalString(intervalUnit)}
                      </Text>
                    </Panel.Item>
                  </>
                )}

              <Conditional show={allotmentHoursUpdateOldUiEnabled}>
                <AllotmentHoursPanelItem papa={papaConcealedEntity} intervalUnit={intervalUnit} />
              </Conditional>

              <Panel.Item>
                <Text bold>Covid-19 Vaccinated</Text>
                <Text>{covidVaccinated ? "Yes" : "No"}</Text>
              </Panel.Item>
            </>
          )}

          <Panel.Buttons>
            {isCareConciergeField && (
              <>
                <CreateServiceRequestModal
                  papaId={papaId}
                  isOpen={action === ServiceRequestActions.CreateServiceRequest}
                  toggle={closeModal}
                />
                <NewRequestButton action onClick={createServiceRequest}>
                  New request
                </NewRequestButton>
              </>
            )}
            <EditOverviewControl papaId={papaId} papa={papa} onRefetch={handleRefetch} />
          </Panel.Buttons>
        </Panel.Section>
      </Panel.Block>
    </Panel.Left>
  );
};

type CaregiverProps = {
  papa?: PapaConcealed | null;
};

const CaregiverPanelItem = ({ papa }: CaregiverProps) => {
  if (!papa || !papa?.caregiver?.data) return null;

  return (
    <Panel.Item as="div">
      <Text bold>Caregiver</Text>
      <Link to={`/caregivers/${papa.caregiver.data.id}`}>{papa.caregiver.data.fullName}</Link>
      <PopoverInfo
        title="Caregiver Information"
        content={
          <div>
            <Panel.Item marginBottom="0.5rem">
              <Text bold>Name</Text>
              <Text>{papa.caregiver?.data?.fullName}</Text>
            </Panel.Item>
            <Panel.Item marginBottom="0.5rem">
              <Text bold>Relationship to caregiver</Text>
              <Text>
                {papa.relationship ? relationshipLabel[papa.relationship] : EMPTY_PLACEHOLDER}
              </Text>
            </Panel.Item>
            <Panel.Item marginBottom="0.5rem">
              <Text bold>DOB</Text>
              <Text>{formatDateTime(papa.caregiver?.data?.birthDate, { format: "L" })}</Text>
            </Panel.Item>
            <Panel.Item marginBottom="2rem">
              <Text bold>Phone Number</Text>
              <Text>{papa.caregiver?.data?.account?.data?.phoneNumber}</Text>
            </Panel.Item>
          </div>
        }
        placement="right"
      >
        <InfoCircle size={16} />
      </PopoverInfo>
    </Panel.Item>
  );
};

type GenderDisplayProps = { gender?: Gender | null; genderText?: Maybe<string> };

const GenderDisplay = ({ gender, genderText }: GenderDisplayProps) => {
  let genderDisplay;

  if (gender === Gender.Diverse) {
    genderDisplay = genderText || "Non-Binary";
  } else {
    genderDisplay = gender ? mapValueToLabel(genderOptions, gender) : EMPTY_PLACEHOLDER;
  }

  return (
    <>
      <Panel.Item>
        <Text bold>Gender</Text>
        <Text>{genderDisplay}</Text>
      </Panel.Item>
    </>
  );
};

type AllotmentHoursPanelItemProps = {
  papa?: PapaConcealed | null;
  currentAllotmentPeriod?: PapaAllotmentPeriod | null;
  intervalUnit?: IntervalUnit | null;
};

const AllotmentHoursPanelItem = ({
  papa,
  currentAllotmentPeriod,
  intervalUnit,
}: AllotmentHoursPanelItemProps) => {
  if (!papa || !currentAllotmentPeriod || !intervalUnit) return null;

  return (
    <>
      <Panel.Item>
        <Text bold>Hours Used</Text>
        <Text>{minutesToHours(currentAllotmentPeriod.usedMinutes)}</Text>
      </Panel.Item>
      <Panel.Item>
        <Text bold>Hours Remaining</Text>
        <Text>{minutesToHours(currentAllotmentPeriod.availableMinutes)}</Text>
      </Panel.Item>
      <Panel.Item>
        <Text bold>Allotment Hours</Text>
        <Text>
          {minutesToHours(currentAllotmentPeriod.allottedMinutes)} /{" "}
          {getIntervalString(intervalUnit)}
        </Text>
      </Panel.Item>
      <Panel.Item>
        <Text bold>Allotment Period</Text>
        <Text>
          {formatDateTime(currentAllotmentPeriod.startDate, { format: "L" })} to{" "}
          {formatDateTime(currentAllotmentPeriod.endDate, { format: "L" })}
        </Text>
      </Panel.Item>
    </>
  );
};

const SuccessText = styled(Text)`
  color: ${({ theme }) => theme.variants.success} !important;
`;

const MobileNumberWithStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & > div {
    margin-bottom: 1.5rem;
  }
`;

const NewRequestButton = styled(Button)`
  font-family: Avenir, sans-serif;
  margin-bottom: 0.85rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export default Overview;
