import { DemandProfileSegmentation, VisitFlagName, VisitState } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { isUuid } from "utils/isUuid";

export const useVisitFilterInput = () => {
  const {
    states,
    visitType,
    dayTimeFrom,
    dayTimeTo,
    name,
    state,
    city,
    county,
    demandProfile,
    isCritical,
    isGrievancesAppeals,
    isUberEligible,
    isEmployerVisit,
    isPreviouslyMissed,
    isVisitLeadTimeException,
    papa,
    pal,
    taskIds,
    papaId,
    visitId,
  } = usePageInfo();

  const maybeAppendFilter = (shouldAppend: any, filterObj: object) =>
    shouldAppend ? filterObj : {};

  return {
    ...(states?.length
      ? {
          state: {
            in: states?.split(",") as VisitState[],
          },
        }
      : {}),
    ...maybeAppendFilter(visitType === "isVirtual", {
      isVirtual: {
        eq: true,
      },
    }),
    ...maybeAppendFilter(visitType === "isInPerson", {
      isVirtual: {
        ne: true,
      },
    }),
    ...((dayTimeFrom || dayTimeTo) && {
      scheduledFor: {
        ...(dayTimeFrom && { gte: dayTimeFrom }),
        ...(dayTimeTo && { lte: dayTimeTo }),
      },
    }),
    ...maybeAppendFilter(name, {
      account: {
        nameContains: name,
      },
    }),
    ...maybeAppendFilter(papa && !papaId, {
      papa: {
        nameContains: papa,
      },
    }),
    ...maybeAppendFilter(isUuid(papaId ?? ""), {
      papa: {
        idEq: papaId,
      },
    }),
    ...maybeAppendFilter(isUuid(visitId ?? ""), {
      id: {
        eq: visitId,
      },
    }),
    ...maybeAppendFilter(pal, {
      pal: {
        nameContains: pal,
      },
    }),
    ...maybeAppendFilter(state || city || county, {
      location: {
        ...(state
          ? {
              stateIn: (state.length ? state : [state]) as string[],
            }
          : {}),
        ...(city
          ? {
              cityNameContains: city,
            }
          : {}),
        ...(county
          ? {
              countyNameContains: county,
            }
          : {}),
      },
    }),
    ...maybeAppendFilter(demandProfile, {
      demandProfile: {
        segmentationIn: demandProfile as DemandProfileSegmentation[],
      },
    }),
    ...maybeAppendFilter(isCritical, {
      flag: { nameEq: VisitFlagName.Critical, isResolved: false },
    }),
    ...maybeAppendFilter(isGrievancesAppeals, {
      flag: { nameEq: VisitFlagName.GrievancesAppeals, isResolved: false },
    }),
    ...maybeAppendFilter(isVisitLeadTimeException, {
      flag: { nameEq: VisitFlagName.VisitLeadTimeException, isResolved: false },
    }),
    ...maybeAppendFilter(isEmployerVisit, {
      isEmployerVisit: true,
    }),
    ...maybeAppendFilter(isPreviouslyMissed, {
      isPreviouslyMissed: true,
    }),
    ...maybeAppendFilter(isUberEligible, {
      isUberEligible: true,
    }),
    ...maybeAppendFilter(taskIds, {
      task: { idIn: taskIds?.split(",") },
    }),
  };
};
