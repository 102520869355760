import { DEFAULT_TIMEZONE } from "constants/date";

import { FormValues } from "./index";
import { getFormattedDateRange } from "./utils";

export const getVisitsFiltersUrlQuery = (params: FormValues) => {
  const { dateRange, timezone, timeTo, timeFrom, visitType, states, accountId } = params;
  const { from, to } = getFormattedDateRange({
    dateRange,
    timezone,
    timeTo,
    timeFrom,
  });

  return {
    page: 1,
    accountId,
    ...(visitType !== "isBoth" && { visitType }),
    ...(timezone !== DEFAULT_TIMEZONE && { timezone }),
    ...(states?.length && { states: states.join(",") }),
    ...((timeFrom || dateRange.startDate) && { dayTimeFrom: from }),
    ...((timeTo || dateRange.endDate) && { dayTimeTo: to }),
  };
};
