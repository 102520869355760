import { object, string } from "yup";

export interface FormValues {
  palId: string | null;
}

export const schema = object().shape({
  palId: string()
    .nullable()
    .required("Please select a Pal")
    .when("genderMismatch", {
      is: true,
      then: string().test(
        "gender mismatch",
        "This pal does not match the member's gender preferences. Please select a different pal.",
        () => false
      ),
    }),
});
