import { createContext } from "react";
import { StyledIcon } from "styled-icons/types";

export type TopBarOptions = {
  title?: string;
  icon?: StyledIcon;
  breadcrumbs?: Breadcrumb[];
  name?: string;
};

type ContextProps = {
  topBarOptions: TopBarOptions;
  setTopBarOptions: React.Dispatch<React.SetStateAction<TopBarOptions>>;
};

export type Breadcrumb =
  | {
      label: string;
      link?: string;
    }
  | string;

const MainContainerContext = createContext<ContextProps>({
  topBarOptions: {},
  setTopBarOptions: () => {},
});

export default MainContainerContext;
