import { Cog } from "@styled-icons/boxicons-solid";
import React from "react";
import styled from "styled-components";

import { ButtonStyle } from "components/ButtonLink";
import Dropdown from "components/Dropdown";

import ColumnCustomizeMenu from "./ColumnCustomizeMenu";
import { Column } from "./schema";

type Props<T> = {
  visibleColumns: Column<T>[];
  columns: Column<T>[];
  onColumnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAll: () => void;
  expect: string[];
  withIcon?: boolean;
};

const ColumnCustomize = <T,>({
  visibleColumns,
  columns,
  onColumnChange,
  onSelectAll,
  expect,
  withIcon = true,
}: Props<T>) => (
  <Dropdown placement="bottom-end">
    <CustomizeButton variant="secondary" withIcon={withIcon} size="small">
      {withIcon && <Cog size={18} />}
      <span>Customize Columns</span>
    </CustomizeButton>
    <Dropdown.Menu>
      <ColumnCustomizeMenu
        visibleColumns={visibleColumns}
        columns={columns}
        onColumnChange={onColumnChange}
        onSelectAll={onSelectAll}
        expect={expect}
      />
    </Dropdown.Menu>
  </Dropdown>
);

const CustomizeButton = styled(Dropdown.Toggle)`
  ${ButtonStyle}
`;

export default ColumnCustomize;
