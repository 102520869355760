import { useMutation } from "@apollo/client";
import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Heading from "components/Heading";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";

import { DELETE_PAL_ACCOUNT } from "./gql";

export enum DeletablePalType {
  Pal,
  ProspectPal,
}

interface Props {
  id: string;
  accountId: string;
  fullName: string;
  palType: DeletablePalType;
  closeModal: () => void;
}

const PalDelete: React.FC<Props> = ({ id, accountId, fullName, palType, closeModal }) => {
  const [deletePalAccount, { loading, error: mutationError }] = useMutation(DELETE_PAL_ACCOUNT, {
    update(cache, _data) {
      //Because the backend runs these as async jobs, it is best to remove these from the cache immediately while they process
      // Depending on what is being handed to this modal, we want to evict either the onboarding record or the pal record
      // from the cache. Prospect Pals will never have a Pal record
      if (palType === DeletablePalType.Pal) {
        cache.evict({ id: `Pal:${id}` });
        cache.evict({ id: `Account:${accountId}` });
      } else if (palType === DeletablePalType.ProspectPal) {
        cache.evict({ id: `PalOnboarding:${id}` });
        cache.evict({ id: `Account:${accountId}` });
      }
    },
  });
  const history = useHistory();
  const route = useRouteMatch("/pals");
  const isPalsList = route !== null && route.isExact;
  const handleOk = async () => {
    try {
      const { data } = await deletePalAccount({
        variables: {
          accountId: accountId,
        },
      });
      if (data?.deletePalAccount?.success) {
        toast.success(`Pal ${fullName} was deleted with success!`);
        // Depending on the Pal Type, redirect to the appropriate list of Pals or Prospect Pals
        if (!isPalsList && palType === DeletablePalType.Pal) {
          history.push("/pals");
        } else if (!isPalsList && palType === DeletablePalType.ProspectPal) {
          history.push("/prospect-pals");
        }
      } else {
        throw new Error("Something is wrong");
      }
      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
    closeModal();
  };

  return (
    <>
      <Modal.Body>
        <Container>
          <StyledIcon fontWeight="bold" size={23} />
          <TextContainer>
            <Heading as="h2">Delete Pal</Heading>
            <Text>
              Are you sure you want to delete pal <strong>{fullName}</strong>?
            </Text>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={loading} type="button" variant="primary" onClick={handleOk}>
          {loading ? <SpinningIcon size={18} /> : "Ok"}
        </Button>
      </StyledFooter>
    </>
  );
};

export default PalDelete;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
