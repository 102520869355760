import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { HTMLAttributes } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Form from "components/Form";
import GoogleLocationSearch from "components/GoogleLocationSearch";
import InputDate from "components/InputDate";
import InputPhone from "components/InputPhone";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { DATE_FORMAT } from "constants/date";
import { genderOptions } from "constants/gender";
import { languageOptions } from "constants/language";
import { vehicleTypeOptions, vehicleYearOptions } from "constants/vehicle";
import { Gender } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { generatePassword } from "utils/generate-password";
import { mapSelectValuesToOptions } from "utils/select";
import { formatFullName } from "utils/strings/formatFullName";

import { GET_PAL_TYPES } from "../../gql";
import { CREATE_ACCOUNT, CREATE_LOCATION, CREATE_PAL } from "./gql";
import { Schema, schema } from "./schema";

interface Props extends HTMLAttributes<HTMLElement> {
  closeModal: () => void;
  onCreateFinish: () => void;
}

interface AccountInput {
  fullName: string;
  email: string;
  password: string;
  phoneNumber: string;
  homeLocationId?: string;
  testAccount: boolean;
}

const CreatePalForm = ({ closeModal, onCreateFinish }: Props) => {
  const [createLocation] = useMutation(CREATE_LOCATION);
  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const [createPal] = useMutation(CREATE_PAL);
  const { data: palTypes } = useQuery(GET_PAL_TYPES);
  const arePalTypesEnabled = useIsFeatureEnabled("pal_types_in_admin");
  const { isEngineeringAdmin, isSuperAdmin, isVisitSuccessAgent } = useCurrentAccountRole();

  const initialValues: Schema = {
    fullName: "",
    email: "",
    dateOfBirth: null,
    phoneNumber: "",
    gender: null,
    palTypeId: null,
    languages: [],
    school: "",
    fieldOfStudy: "",
    referralSource: "",
    address: { address: "" },
    bio: "",
    vehicle: null,
    vehicleModel: "",
    vehicleYear: "",
    vehicleColor: "",
    verified: false,
    drugTestClean: false,
    testAccount: false,
    covidVaccinated: false,
  };

  const handleSubmit = async (formValue: Schema) => {
    const { address, fullName, email, phoneNumber, testAccount, ...palInput } = formValue;
    const input: AccountInput = {
      fullName: formatFullName(fullName),
      email,
      password: generatePassword(20),
      phoneNumber,
      testAccount,
    };
    try {
      if (address.address) {
        const { data: locationData } = await createLocation({
          variables: { input: address },
        });
        input.homeLocationId = locationData?.createLocation?.data?.id;
      }
      const { data: accountData } = await createAccount({
        variables: { input },
      });
      const accountId = accountData?.createAccount?.data?.id;
      const { data } = await createPal({
        variables: {
          input: {
            accountId,
            ...palInput,
          },
        },
      });
      if (data.createPal.data) {
        toast.success("Pal was created with success!");
        onCreateFinish();
        closeModal();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const listPalTypeOptions = () => {
    return palTypes?.palTypes?.data?.map((value: { code: String; id: String }) => ({
      label: value.code,
      value: value.id,
    }));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values }) => (
        <ModalStyledForm>
          <ModalContainer>
            <FormRow>
              <FormCol>
                <Form.Group>
                  <Form.Label required>Full Name</Form.Label>
                  <Form.Input name="fullName" aria-label="fullName" />
                </Form.Group>
              </FormCol>
              <FormCol>
                <Form.Group>
                  <Form.Label required>Email</Form.Label>
                  <Form.Input name="email" aria-label="email" />
                </Form.Group>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <Form.Group>
                  <Form.Label>Birth date</Form.Label>
                  <InputDate
                    format={DATE_FORMAT}
                    isOutsideRange={() => false}
                    id="dateOfBirth"
                    name="dateOfBirth"
                  />
                </Form.Group>
              </FormCol>
              <FormCol>
                <Form.Group>
                  <Form.Label required>Phone number</Form.Label>
                  <InputPhone name="phoneNumber" defaultCountry="US" aria-label="phoneNumber" />
                </Form.Group>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <Form.Group>
                  <Form.Label required>Gender</Form.Label>
                  <Select
                    name="gender"
                    defaultValue={mapSelectValuesToOptions(initialValues.gender, genderOptions)}
                    options={genderOptions}
                    isSearchable={false}
                    placeholder="Select"
                    aria-label="gender"
                  />
                  {values.gender === Gender.Diverse && (
                    <>
                      <Form.Label>Please, self identify</Form.Label>
                      <Form.Input name="genderText" />
                    </>
                  )}
                </Form.Group>
              </FormCol>
              <FormCol>
                <Form.Group>
                  <Form.Label required>Languages</Form.Label>
                  <Select
                    name="languages"
                    defaultValue={mapSelectValuesToOptions(
                      initialValues.languages,
                      languageOptions
                    )}
                    options={languageOptions}
                    isSearchable={false}
                    isMulti
                    placeholder="Select"
                    aria-label="languages"
                  />
                </Form.Group>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <Form.Group>
                  <Form.Label>School</Form.Label>
                  <Form.Input name="school" aria-label="school" />
                </Form.Group>
              </FormCol>
              <FormCol>
                <Form.Group>
                  <Form.Label>Field of study</Form.Label>
                  <Form.Input name="fieldOfStudy" aria-label="fieldOfStudy" />
                </Form.Group>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <Form.Group>
                  <Form.Label>Referral Source</Form.Label>
                  <Form.Input name="referralSource" aria-label="referralSource" />
                </Form.Group>
              </FormCol>
              <FormCol>
                <Form.Group>
                  <Form.Label required>Home Address</Form.Label>
                  <GoogleLocationSearch name="address" aria-label="address" placeholder="" />
                </Form.Group>
              </FormCol>
            </FormRow>
            {arePalTypesEnabled && (isEngineeringAdmin || isSuperAdmin || isVisitSuccessAgent) && (
              <FormRow>
                <FormCol>
                  <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Select
                      name="palTypeId"
                      aria-label="palTypeId"
                      options={listPalTypeOptions()}
                      isSearchable={false}
                    />
                  </Form.Group>
                </FormCol>
              </FormRow>
            )}
            <Form.Group>
              <Form.Label>Can you tell me a little bit about yourself?</Form.Label>
              <Form.TextArea aria-label="bio" name="bio" fullWidth />
            </Form.Group>
            <Form.Fieldset legend="Vehicle">
              <FormRow>
                <FormCol4>
                  <Form.Group>
                    <Form.Label required>Type</Form.Label>
                    <Select
                      name="vehicle"
                      defaultValue={
                        mapSelectValuesToOptions(initialValues.vehicle, vehicleTypeOptions) as any
                      }
                      options={vehicleTypeOptions as any}
                      isSearchable={false}
                      small
                      aria-label="vehicle"
                    />
                  </Form.Group>
                </FormCol4>
                <FormCol4>
                  <Form.Group>
                    <Form.Label>Model</Form.Label>
                    <Form.Input name="vehicleModel" aria-label="vehicleModel" fullWidth />
                  </Form.Group>
                </FormCol4>
                <FormCol4>
                  <Form.Group>
                    <Form.Label>Year</Form.Label>
                    <Select
                      name="vehicleYear"
                      maxMenuHeight={200}
                      defaultValue={
                        mapSelectValuesToOptions(
                          initialValues.vehicleYear,
                          vehicleYearOptions
                        ) as any
                      }
                      options={vehicleYearOptions as any}
                      isSearchable={false}
                      placeholder="Select"
                      small
                      aria-label="vehicleYear"
                    />
                  </Form.Group>
                </FormCol4>
                <FormCol4>
                  <Form.Group>
                    <Form.Label>Color</Form.Label>
                    <Form.Input name="vehicleColor" aria-label="vehicleColor" fullWidth />
                  </Form.Group>
                </FormCol4>
              </FormRow>
            </Form.Fieldset>
            <Form.Fieldset legend="Settings">
              <FormRow>
                <FormCol4>
                  <Form.Group>
                    <Form.Label>Approved</Form.Label>
                    <Form.Switch name="verified" aria-label="verified" />
                  </Form.Group>
                </FormCol4>
                <Form.Col4>
                  <Form.Group>
                    <Form.Label>Covid-19 Vaccinated</Form.Label>
                    <Form.Switch name="covidVaccinated" aria-label="covidVaccinated" />
                  </Form.Group>
                </Form.Col4>
                <FormCol4>
                  <Form.Group>
                    <Form.Label>Drug test passed</Form.Label>
                    <Form.Switch name="drugTestClean" aria-label="drugTestClean" />
                  </Form.Group>
                </FormCol4>
                <FormCol4>
                  <Form.Group>
                    <Form.Label>Is test account</Form.Label>
                    <Form.Switch name="testAccount" aria-label="testAccount" />
                  </Form.Group>
                </FormCol4>
              </FormRow>
            </Form.Fieldset>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};

export default CreatePalForm;

const FormRow = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  justify-content: space-between;
`;

const FormCol = styled.div`
  padding: 0 0.5rem;
  width: 50%;
`;

const FormCol4 = styled.div`
  padding: 0 0.5rem;
  width: 25%;
`;
