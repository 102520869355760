import { gql } from "@apollo/client";

import { visitListFragment } from "components/DataTables/VisitsTable";

export const VISITS_SEARCH_AS_PAL = gql`
  query visitsSearchAsPal(
    $palId: ID!
    $coordinates: CoordinateInput!
    $filter: [VisitFilter!]
    $pagination: PaginationInput
    $sorting: VisitSorting
  ) {
    visitsSearchAsPal(
      palId: $palId
      input: $coordinates
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      data {
        ...VisitPartsForDataTables
        events(filter: { action: { eq: MEMBER_CONFIRMED } }) {
          data {
            ... on VisitEvent {
              action
            }
          }
        }
        requirements {
          details
          name
        }
        papa {
          data {
            genderPreference
            blockedPals {
              data {
                id
              }
            }
          }
        }
        requiredVehicleType
        favoritePalsOnly
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
  ${visitListFragment}
`;

export const VISITS_SEARCH_AS_PAL_EMPTY_CHECK = gql`
  query visitsSearchAsPalEmptyCheck(
    $palId: ID!
    $coordinates: CoordinateInput!
    $upcomingFilters: [VisitFilter!]
    $availableFilters: [VisitFilter!]
    $myRequestsFilters: [VisitFilter!]
  ) {
    upcoming: visitsSearchAsPal(
      palId: $palId
      input: $coordinates
      filter: $upcomingFilters
      pagination: { limit: 1 }
    ) {
      data {
        id
      }
    }
    available: visitsSearchAsPal(
      palId: $palId
      input: $coordinates
      filter: $availableFilters
      pagination: { limit: 1 }
    ) {
      data {
        id
      }
    }
    myRequests: visitsSearchAsPal(
      palId: $palId
      input: $coordinates
      filter: $myRequestsFilters
      pagination: { limit: 1 }
    ) {
      data {
        id
      }
    }
  }
`;
