import { AccountCircle } from "@styled-icons/material/AccountCircle";
import { Phone } from "@styled-icons/material/Phone";
import debounce from "lodash/debounce";
import React, { CSSProperties, FC } from "react";
import Async from "react-select/async";
import { ValueType } from "react-select/src/types";
import styled from "styled-components";

import { customStyles } from "components/Select";
import { ChangeSelectEvent } from "components/UserSelect";
import { papaStateOptions } from "constants/papaState";
import { PapaState } from "generated/types";
import { PillData } from "types/search";
import { mapValueToLabel } from "utils/helpers/formatters";

export interface OptionLabel {
  value: string;
  label: string;
  name: string;
  member?: string;
  phone?: string;
  email?: string;
  address?: string;
  status?: PapaState | null;
}

interface Props {
  pills: PillData[];
  setPills: (pills: PillData[] | ((pills: PillData[]) => PillData[])) => void;
  loadOptions: (value: string) => Promise<OptionLabel[]>;
  placeholder?: string;
}

const PapaSearch: FC<Props> = ({ pills, setPills, loadOptions, placeholder }: Props) => {
  const onSearchInputChange = (event: ValueType<ChangeSelectEvent>) => {
    const { value, name } = event as ChangeSelectEvent;
    setPills([{ id: value, name }]);
  };

  const fetch = (inputValue: string, callback: any) => {
    loadOptions(inputValue).then((results: any) => callback(results));
  };

  const debouncedSearch = debounce(fetch, 500);

  return (
    <SearchBox>
      <Async
        aria-label="search"
        placeholder={placeholder}
        loadOptions={debouncedSearch}
        onChange={onSearchInputChange}
        value={null}
        // @ts-ignore TODO:remove this
        styles={searchFieldStyles}
        formatOptionLabel={formatOptionLabel}
      />
    </SearchBox>
  );
};

export const searchFieldStyles = {
  ...customStyles,
  dropdownIndicator: () => ({
    display: "none",
  }),
  container: (provided: CSSProperties, state: any) => ({
    ...provided,
    background: "white",
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocus ? "4px 4px 0 0" : 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
  }),
  option: (provided: CSSProperties, { isSelected, isFocused }: any) => ({
    ...provided,
    color: (isSelected && "#FFFFFF") || (isFocused && "#FFFFFF") || "inherit",
    backgroundColor: (isSelected && "#0D2973") || (isFocused && "#0D2973") || "transparent",

    "&:hover": {
      backgroundColor: "#0D2973",
      color: isSelected ? "inherit" : "#FFFFFF",
    },
  }),
};

export const formatOptionLabel = ({ label, member, phone, status }: OptionLabel) => (
  <OptionLabelContainer>
    <div>{label}</div>
    {status && <Italic>{mapValueToLabel(papaStateOptions, status)}</Italic>}
    {phone && (
      <Italic>
        <Phone size={16} /> {phone}
      </Italic>
    )}
    {member && (
      <Muted>
        <AccountCircle size={16} /> {member}
      </Muted>
    )}
  </OptionLabelContainer>
);

export const SearchBox = styled.div`
  display: flex;
  width: 100%;
  margin-right: 1.25rem;
  button {
    margin-left: 1.25rem;
  }
`;

const OptionLabelContainer = styled.div`
  svg {
    vertical-align: text-bottom;
    color: #9a9a9a;
  }
`;

const Italic = styled.div`
  font-style: italic;
`;

const Muted = styled.div`
  color: #9a9a9a;
`;

export default PapaSearch;
