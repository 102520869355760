import { gql } from "@apollo/client";

export const GET_PAL = gql`
  query SelectedPal($id: ID!) {
    pal(id: $id) {
      data {
        id
        account {
          data {
            id
            fullName
            phoneNumber
          }
        }
      }
    }
  }
`;

export const PAPA_PREFERENCES = gql`
  query PapaPreferences($papaId: ID!) {
    papa(id: $papaId) {
      data {
        id
        defaultToFavoritePalsOnly
        favoritePals {
          data {
            id
            fullName
          }
        }
        genderPreference
        genderPreferenceLocked
      }
    }
  }
`;

export const PAL_GENDER = gql`
  query PalGender($palId: ID!) {
    pal(id: $palId) {
      data {
        id
        gender
      }
    }
  }
`;
