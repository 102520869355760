import { gql } from "@apollo/client";

export const GET_ACCOUNT_ROLES = gql`
  query account($id: ID!) {
    account(id: $id) {
      data {
        roles {
          data {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($id: ID!, $input: AccountInput!) {
    updateAccount(id: $id, input: $input) {
      data {
        id
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateMemberLocation($locationInput: LocationInput!) {
    createLocation(input: $locationInput) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $locationInput: LocationInput!) {
    updateLocation(id: $id, input: $locationInput) {
      data {
        id
      }
    }
  }
`;
