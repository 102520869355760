import React, { useMemo } from "react";
import styled from "styled-components";

import FormContext from "./FormContext";

type Props = {
  children: React.ReactNode;
  controlId?: string;
};

const StyledFormGroup = styled("div")`
  margin-bottom: 1.25rem;
`;

const FormGroup = React.forwardRef<HTMLDivElement, Props>(
  ({ controlId, children, ...rest }: Props, ref) => {
    const context = useMemo(() => ({ controlId }), [controlId]);
    return (
      <FormContext.Provider value={context}>
        <StyledFormGroup {...rest} ref={ref}>
          {children}
        </StyledFormGroup>
      </FormContext.Provider>
    );
  }
);

FormGroup.displayName = "FormGroup";

export default FormGroup;
