import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import DataTableDropdownMenu from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Payment, PaymentSorting, SortDirection } from "generated/types";
import { useCallItPapaOrCareRecipient } from "hooks/useCallItPapaOrCareRecipient";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";

interface Params {
  sorting: PaymentSorting;
  onSortChange: (sort: PaymentSorting) => void;
}

export const usePaymentsTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const [sorting, setSorting] = useState<PaymentSorting>(initialSorting);

  const { capitalized } = useCallItPapaOrCareRecipient();

  const menuItems = ({ id, visit }: Payment) => [
    { label: "View payment", link: `/payments/members-payments/${id}` },
    { label: "View visit", link: `/visits/${visit?.data?.id}` },
  ];

  const onSetSorting = (sortKey: keyof PaymentSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const columns: Column<Payment>[] = [
    {
      header: { title: " ", type: "select" },
      cellProps: { width: "54px" },
      dataField: "select",
    },
    {
      header: { title: "State" },
      dataField: "state",
      formatter({ state }) {
        return state ? capitalize(state) : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Member" },
      dataField: "recipient",
      cellProps: { noWrap: true },
      formatter({ recipient }) {
        return <Link to={`/members/${recipient?.data?.id}`}>{recipient?.data?.fullName}</Link>;
      },
    },
    {
      header: { title: "Pal" },
      dataField: "pal.data",
      cellProps: { noWrap: true },
      formatter({ pal }) {
        if (pal?.data) {
          return <Link to={`/pals/${pal.data.id}`}>{pal.data.fullName}</Link>;
        }
        return EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: `${capitalized.singularEntityName}` },
      dataField: "visit.data.papa.data",
      cellProps: { noWrap: true },
      formatter({ visit }) {
        if (visit?.data?.papa?.data) {
          const papaConcealedEntity = papaConcealed(visit.data.papa.data);
          return (
            <Link to={`/papas/${papaConcealedEntity?.id}`}>{papaConcealedEntity?.fullName}</Link>
          );
        }
        return EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Total" },
      dataField: "total",
      formatter({ total }) {
        return formatNumberToCurrency(total ?? 0);
      },
    },
    {
      header: {
        title: "Created",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
    {
      header: {
        title: "Approved at",
        sort: sorting.approvedAt,
        onChange: (direction) => onSetSorting("approvedAt", direction),
      },
      dataField: "approvedAt",
      cellProps: { noWrap: true },
      formatter: ({ approvedAt }) => formatDateTime(approvedAt),
    },
    {
      header: {
        title: "Charged at",
        sort: sorting.chargedAt,
        onChange: (direction) => onSetSorting("chargedAt", direction),
      },
      dataField: "chargedAt",
      cellProps: { noWrap: true },
      formatter: ({ chargedAt }) => formatDateTime(chargedAt),
    },
    {
      header: {
        title: "Resolved at",
        sort: sorting.resolvedAt,
        onChange: (direction) => onSetSorting("resolvedAt", direction),
      },
      dataField: "resolvedAt",
      cellProps: { noWrap: true },
      formatter: ({ resolvedAt }) => formatDateTime(resolvedAt),
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(payment) {
        return <DataTableDropdownMenu items={menuItems(payment)} />;
      },
    },
  ];

  return columns;
};
