import * as yup from "yup";

import { googleLocationSchema } from "components/GoogleLocationSearch/schema";

export const schema = yup.object().shape({
  address: googleLocationSchema,
  address2: yup.string(),
  nickname: yup.string(),
  note: yup.string(),
});
