import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import UpdatePaymentInvoicesState from "modals/PaymentInvoices/UpdatePaymentInvoicesState";

import { ActionsMenu } from "./MassMenu";

interface Props {
  massAction: ActionsMenu | null;
  paymentInvoiceIds: string[];
  closeModal: () => void;
  onRefetch?: () => void;
  onSuccess?: () => void;
}

const PaymentInvoiceMassActions: React.FC<Props> = ({
  massAction,
  paymentInvoiceIds,
  closeModal,
  onRefetch = () => {},
  onSuccess = () => {},
}: Props) => {
  return (
    <>
      <ModalLayout
        toggle={closeModal}
        title="Change payment invoices status"
        isOpen={massAction === ActionsMenu.ChangeStatus}
      >
        <UpdatePaymentInvoicesState
          paymentInvoiceIds={paymentInvoiceIds}
          closeModal={closeModal}
          onRefetch={onRefetch}
          onSuccess={onSuccess}
        />
      </ModalLayout>
    </>
  );
};

export default PaymentInvoiceMassActions;
