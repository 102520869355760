import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";

import { SpinningIcon } from "components/CustomIcon/Spinning";

interface Props {
  centered?: boolean;
  isLoading?: boolean;
  children?: ReactElement;
}

export const Loader: FunctionComponent<Props> = ({
  centered = true,
  isLoading = true,
  children,
}) => {
  return (
    <>
      {isLoading ? (
        <Wrapper centered={centered}>
          <SpinningIcon size={20} />
        </Wrapper>
      ) : (
        children
      )}
    </>
  );
};

const Wrapper = styled.div<Props>`
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  width: ${({ centered }) => (centered ? "100%" : "auto")};
`;
