import { gql, useQuery } from "@apollo/client";
import { useField, useFormikContext } from "formik";
import React, { FunctionComponent } from "react";
import { default as ReactSelect, ValueType } from "react-select";

import { Loader } from "components/Loader";
import { customStyles } from "components/Select";
import { ChangeSelectEvent } from "components/UserSelect";
import { Role } from "generated/types";

interface Props {
  name: string;
}

export const GET_ROLES = gql`
  query roles($filter: RoleFilter) {
    roles(filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;

export const SelectRoles: FunctionComponent<Props> = ({ name }) => {
  const [{ value }, , { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const { data, loading } = useQuery<{ roles: { data: Role[] } }>(GET_ROLES);

  if (loading) {
    return <Loader />;
  }
  if (!data) {
    return null;
  }

  const rolesOptions =
    data?.roles?.data.map(({ id, name }) => ({ value: id!, label: name! })) ?? [];

  const handleChange = (value: ValueType<ChangeSelectEvent>) => {
    setValue(value?.map((option: { value: string[] }) => option.value));
  };

  return (
    <ReactSelect
      placeholder="Select"
      // @ts-ignore TODO:remove this
      styles={customStyles}
      options={rolesOptions}
      onChange={handleChange}
      isDisabled={isSubmitting}
      value={rolesOptions.filter((option) => value?.includes(option.value))}
      isClearable={false}
      classNamePrefix="select"
      isMulti
      menuPlacement="top"
    />
  );
};
