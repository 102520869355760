import { Formik } from "formik";
import moment from "moment-timezone";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import InputDate from "components/InputDate";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import TimePicker from "components/TimePicker";
import PartnerSelect from "components/UserSelect/PartnerSelect";
import { TZ_FORMAT } from "constants/date";
import {
  useCompleteHandoffedVisitMutation,
  useGetHandoffedVisitDetailsQuery,
} from "generated/types";
import { mergeDateAndTime } from "utils/date";

import { Schema, schema } from "./schema";

interface Props {
  visitId: string;
  isOpen: boolean;
  toggle: () => void;
}

export const CompleteVisitHandoff: React.FC<Props> = ({ visitId, isOpen, toggle }) => {
  const { data: visitData, loading: visitLoading } = useGetHandoffedVisitDetailsQuery({
    variables: { visitId },
  });
  const [completeHandoffedVisit, { loading }] = useCompleteHandoffedVisitMutation();

  const { partner, location } = visitData?.visit?.data ?? {};
  const timezone = location?.data?.timezone ?? moment.tz.guess();

  const partnerId = partner?.data?.id ?? "";

  const handleSubmit = async ({
    partnerId,
    completedAtDate,
    completedAtTime,
    startedAtDate,
    startedAtTime,
    overrideDurationMins,
    visitDistance,
  }: Schema) => {
    try {
      const completedAt = mergeDateAndTime({
        day: completedAtDate,
        time: completedAtTime,
        timezone,
      });

      const startedAt = mergeDateAndTime({
        day: startedAtDate,
        time: startedAtTime,
        timezone,
      });

      const { data } = await completeHandoffedVisit({
        variables: {
          visitId,
          input: {
            partnerId,
            completedAt,
            startedAt,
            overrideDurationMins,
            visitDistance,
          },
        },
      });

      if (data) {
        toast.success("Successfully completed visit handoff");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Complete visit handoff" isOpen={isOpen}>
      {visitLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            partnerId,
            completedAtDate: "",
            completedAtTime: "",
            startedAtDate: "",
            startedAtTime: "",
            visitDistance: null,
            overrideDurationMins: null,
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label required>Partner</Form.Label>
                <PartnerSelect />
              </Form.Group>

              <Form.Group>
                <Form.Label required>Started at</Form.Label>
                <Form.Row>
                  <Form.Col>
                    <InputDate
                      id="startedAtDate"
                      name="startedAtDate"
                      placeholder="Started at"
                      isOutsideRange={() => false}
                    />
                  </Form.Col>
                  <Form.Col>
                    <TimePicker name="startedAtTime" placeholder="Started at time" />
                    <Form.Feedback>{moment().tz(timezone).format(TZ_FORMAT)}</Form.Feedback>
                  </Form.Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Label required>Completed at</Form.Label>
                <Form.Row>
                  <Form.Col>
                    <InputDate
                      id="completedAtDate"
                      name="completedAtDate"
                      placeholder="Completed at"
                      isOutsideRange={() => false}
                    />
                  </Form.Col>
                  <Form.Col>
                    <TimePicker name="completedAtTime" placeholder="Completed at time" />
                    <Form.Feedback>{moment().tz(timezone).format(TZ_FORMAT)}</Form.Feedback>
                  </Form.Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Label required>Visit distance</Form.Label>
                <Form.Input
                  name="visitDistance"
                  placeholder="Visit distance"
                  type="number"
                  step=".01"
                  aria-label="visit distance"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label required>Total time of visit (minutes)</Form.Label>
                <Form.Input
                  name="overrideDurationMins"
                  placeholder="Total time of visit (minutes)"
                  type="number"
                  step="1"
                  aria-label="override duration mins"
                />
              </Form.Group>
            </ModalContainer>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button type="submit" disabled={loading} variant="primary">
                {loading && <SpinningIcon size={18} />}
                Save
              </Button>
            </ModalFooter>
          </ModalStyledForm>
        </Formik>
      )}
    </ModalLayout>
  );
};
