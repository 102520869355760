import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { useState } from "react";
import Helmet from "react-helmet";

import Button from "components/Button";
import PapasTable, { GET_PAPAS } from "components/DataTables/PapasTable";
import { usePapasSorting } from "components/DataTables/PapasTable/usePapasSorting";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import { PapaListResult } from "generated/types";
import { useCallItClientProgramOrMember } from "hooks/useCallItClientProgramOrMember";
import { usePageInfo } from "hooks/usePageInfo";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";

import CreatePapa from "./CreatePapa";
import { PapaExportModal } from "./PapaExport/PapaExportModal";
import PerformanceReportModal from "./PerformanceReport";
import { usePapasSearch } from "./usePapasSearch";

interface Data {
  papas: PapaListResult;
}

const Papas = () => {
  const {
    capitalized: { singularEntityName: programOrMember },
  } = useCallItClientProgramOrMember();

  useTopBarOptions({ title: "Papas", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { filter, pills, setPills } = useSearch({ deletedAt: { eq: null } });
  const [sorting, setSorting] = usePapasSorting();
  const { data, loading, error, refetch } = useQuery<Data>(GET_PAPAS, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter,
      sorting,
    },
  });

  const papas = papasConcealed(data?.papas?.data);

  const { loadOptions, searchPillNames } = usePapasSearch(papas);

  const [showExportPapaModal, setShowExportPapaModal] = useState(false);
  const toggleExportPapaModal = () => setShowExportPapaModal(!showExportPapaModal);

  const [showPerformanceReportModal, setShowPerformanceReportModal] = useState(false);
  const togglePerformanceReportModal = () =>
    setShowPerformanceReportModal(!showPerformanceReportModal);

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>Papas - Papa Admin</title>
      </Helmet>
      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <FlexRow>
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder={`Search by ID, Name, Phone, ${programOrMember}`}
          />
        </FlexRow>

        <FlexRow>
          <Button onClick={togglePerformanceReportModal}>Performance Report</Button>
          <Button onClick={toggleExportPapaModal}>Export Papas</Button>
          <CreatePapa onCreateFinish={handleRefetch} />
        </FlexRow>
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />
      <PapasTable
        data={papas}
        loading={loading}
        pagination={data?.papas?.pagination}
        error={error}
        sorting={sorting}
        onSortChange={setSorting}
        onRefetch={handleRefetch}
      />

      <PapaExportModal isOpen={showExportPapaModal} toggle={toggleExportPapaModal} />

      <PerformanceReportModal
        isOpen={showPerformanceReportModal}
        toggle={togglePerformanceReportModal}
      />
    </>
  );
};

export default Papas;
