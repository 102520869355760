import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Conditional from "components/Conditional";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import YesNoRadioGroup from "components/YesNoRadioGroup";
import { VisitMobilityNeeds } from "generated/types";
import * as Segment from "utils/segment";

import CannotScheduleAlert from "./CannotScheduleAlert";

interface Props {
  tasks: string[];
  hasMobilityTask: boolean;
  papaId: string;
  onChange: (need: VisitMobilityNeeds) => void;
  hasError?: boolean;
}

/* 
This component allows us to determine whether or not we can support a member with a transportation
visit. We ask a series of questions to ensure that the member is able to get in and out of a 
car with a mobility device that our pals can support.

We have intentionally not used Formik within this component.
*/
const MobilitySupport = ({ tasks, hasMobilityTask, papaId, onChange, hasError }: Props) => {
  //// STATE ////
  const [needsAssistance, setNeedsAssistance] = useState<boolean | null>(null);
  const [hasMobilityAid, setHasMobilityAid] = useState<boolean | null>(null);
  const [mobilityAidFitsCar, setMobilityAidFitsCar] = useState<boolean | null>(null);

  //// HOOKS ////
  useEffect(() => {
    /* This is a bad use of useEffect, however, the TaskButton that should trigger the reset
       of this value is gnarly and it seemed like the lesser of two evils to avoid sticking this
       logic in there where we would have trouble remembering/finding it in the future.
    */

    /* For clone visit, we need to set the need to Unsupported so that these questions will
       be validated (since we do not get the value back from the backend we make the user answer
       it again). Checking that needsAssistance is null shows that the first question has not been 
       answered. */
    if (hasMobilityTask && needsAssistance === null) {
      onChange(VisitMobilityNeeds.Unsupported);
    }

    // If the agent changes their selection from a transportation task to a non-transportation task
    // we want to set this back to the default value to prevent an error during validation.
    if (!hasMobilityTask) {
      onChange(VisitMobilityNeeds.NotApplicable);
    }
  }, [hasMobilityTask, needsAssistance, onChange]);

  //// CALLBACKS ////
  /* In these event handlers we eagerly set the mobility need to UNSUPPORTED even though
  we may find the member to be qualified after additional questions have been answered.
  This is to support our schema validation and ensure agents have answered all required
  questions.
  */
  const handleNeedsAssistanceChange = (value: boolean) => {
    onChange(VisitMobilityNeeds.Unsupported);
    setNeedsAssistance(value);
    Segment.visitSchedulingAction(
      "mobility support needs",
      "answered assistance in out car",
      value,
      papaId
    );
  };

  const handleHasMobilityAidChange = (value: boolean) => {
    if (value) {
      onChange(VisitMobilityNeeds.Unsupported);
    } else {
      onChange(VisitMobilityNeeds.None);
    }
    setHasMobilityAid(value);
    Segment.visitSchedulingAction(
      "mobility support needs",
      "answered use mobility aid",
      value,
      papaId
    );
  };

  const handleMobilityAidFitsCarChange = (value: boolean) => {
    if (value) {
      onChange(VisitMobilityNeeds.QualifyingDevice);
    } else {
      onChange(VisitMobilityNeeds.Unsupported);
    }
    setMobilityAidFitsCar(value);
    Segment.visitSchedulingAction(
      "mobility support needs",
      "answered mobility aid car fit",
      value,
      papaId
    );
  };

  //// RENDERING ////
  const membersDeviceIsTooLarge =
    needsAssistance === false && hasMobilityAid === true && mobilityAidFitsCar === false;
  const showAlert = needsAssistance || membersDeviceIsTooLarge;

  if (!hasMobilityTask) {
    return null;
  }

  return (
    <>
      <ScheduleVisitLabel>Mobility support needs</ScheduleVisitLabel>
      {hasError && <Error>Unable to provide support</Error>}
      <YesNoRadioGroup
        name="needsAssistance"
        label="Do you need someone to help you to get in and out of a car?"
        value={needsAssistance}
        onChange={handleNeedsAssistanceChange}
      />
      <Conditional show={needsAssistance === false}>
        <YesNoRadioGroup
          name="hasMobilityAid"
          label="Do you use a mobility aid, such as a wheelchair, walker, or scooter?"
          value={hasMobilityAid}
          onChange={handleHasMobilityAidChange}
        />
      </Conditional>
      <Conditional show={needsAssistance === false && hasMobilityAid === true}>
        <YesNoRadioGroup
          name="mobilityAidFitsCar"
          label="Does your mobility aid easily fit in an average-sized car? (It should be lightweight and collapsible.) "
          value={mobilityAidFitsCar}
          onChange={handleMobilityAidFitsCarChange}
        />
      </Conditional>
      <Conditional show={showAlert}>
        <CannotScheduleAlert tasks={tasks} papaId={papaId} />
      </Conditional>
    </>
  );
};

const Error = styled.div`
  color: ${({ theme }) => theme.variants.danger};
  margin-top: -10px;
  margin-bottom: 15px;
`;

export default MobilitySupport;
