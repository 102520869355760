import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown";
import React from "react";
import styled from "styled-components";

import Table from "components/Table";
import { AssessmentSubmissionFragment, AssessmentsAnswerFragment } from "generated/types";
import { AssessmentType } from "types/assessments";
import { checkIncompleteAssessment, getPapaSocialIndexPoints } from "utils/helpers/assessments";
import { nonNull } from "utils/nonNull";

import { AssessmentAnswers } from "../AssessmentAnswers/AssessmentAnswers";

interface Props {
  assessment: AssessmentSubmissionFragment;
  showAnswers: boolean;
  toggleAssessment: (id: string) => void;
  type: string | null;
}

export const AssessmentScore: React.FC<Props> = ({
  type,
  assessment,
  showAnswers,
  toggleAssessment,
}) => {
  const answers = sortAnswersByPosition(nonNull(assessment.answers?.data));

  const getScore = () => {
    if (type === AssessmentType.papaSocialIndex) {
      return checkIncompleteAssessment(assessment) ? "N/A" : getPapaSocialIndexPoints(assessment);
    } else if (type === AssessmentType.papaSocialIndexMedicare) {
      return null;
    }
    return assessment.score;
  };

  return (
    <Cell>
      {getScore()}
      <StyledChevron
        size={40}
        onClick={() => toggleAssessment(assessment.id!)}
        isRotated={showAnswers}
      />

      {showAnswers && <AssessmentAnswers answers={answers} type={type} />}
    </Cell>
  );
};

const sortAnswersByPosition = (answers: AssessmentsAnswerFragment[]) => {
  answers.sort((a, b) => {
    if (!a.question?.data?.position || !b.question?.data?.position) {
      return 0;
    }

    return a.question.data.position - b.question.data.position;
  });

  return answers;
};

const Cell = styled(Table.Cell)`
  position: relative;
`;

const StyledChevron = styled(ChevronDown)<{ isRotated: boolean }>`
  color: #a1a7af;
  margin-left: 12px;
  transition: 0.2s all ease;
  cursor: pointer;
  transform: ${({ isRotated }) => (isRotated ? "rotate(180deg)" : "rotate(0deg)")};
`;
