import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { SecureRoute, Security } from "@okta/okta-react";
import React, { useCallback } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import { useLocalStorage } from "hooks/useLocalStorage";
import Login from "pages/Login";
import Logout from "pages/Logout";

import { OktaConfig } from "../config";
import Dashboard from "./Dashboard";
import LoginCallback from "./LoginCallback";

function customAuthHandler(history: any) {
  history.push("/login");
}

const oktaAuth = new OktaAuth(OktaConfig);

const Authentication = () => {
  const history = useHistory();

  // @ts-ignore
  const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri ?? "/", window.location.origin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={() => customAuthHandler(history)}
    >
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" render={() => <Logout withOkta={false} />} />
        <Route exact path="/okta-logout" render={() => <Logout withOkta={true} />} />
        <Route path="/openid/consume" component={LoginCallback} />
        <SecureRouteSwitch />
      </Switch>
    </Security>
  );
};

const SecureRouteSwitch: React.FC = () => {
  const [token] = useLocalStorage("token", null);

  if (token) {
    return <Route path="/" component={Dashboard} />;
  }

  return <SecureRoute path="/" component={Dashboard} />;
};

export default Authentication;
