import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { StyledForm } from "components/EditBio/styles";
import { FlexRow } from "components/Flex";
import Form from "components/Form";
import Heading from "components/Heading";
import Text from "components/Text";

import { UPDATE_SERVICE_REQUEST_RATING } from "./gql";
import { Schema, schema } from "./schema";

type Props = {
  rating?: number | null;
  serviceRequestId: string;
};
const ServiceRequestRating = ({ rating, serviceRequestId }: Props) => {
  const [updateRating] = useMutation(UPDATE_SERVICE_REQUEST_RATING);
  const [editMode, setEditMode] = useState(!!rating);
  const range = Array.from(Array(10), (_, index) => index + 1).map((e) => ({
    label: e.toString(),
    value: e,
  }));

  const options = range;

  const initialValues: Schema = {
    rating: rating || null,
  };
  const handleSubmit = async ({ rating }: Schema) => {
    setEditMode(true);
    const input = {
      rating,
    };
    try {
      const { data } = await updateRating({ variables: { id: serviceRequestId, input } });
      if (!data?.updateServiceRequest) {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : `${error}`);
    }
  };

  return (
    <div>
      <Heading as="h2" color="primary">
        Rating
      </Heading>
      <Text>What was the satisfaction rating given by member for solving the service request?</Text>
      <Formik<Schema>
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ resetForm, values }) => (
          <StyledForm>
            <StyledButtonGroup>
              {options.map(({ label, value }, index) => (
                <Form.Radio
                  key={index}
                  className="radio-button"
                  value={value}
                  name="rating"
                  disabled={editMode}
                  controlId={label}
                  label={label}
                />
              ))}
            </StyledButtonGroup>
            {/* <RadioGroup options={options} name="rating"></RadioGroup> */}
            <FlexRow justifyContent="space-between">
              <Text>Very Dissatisfied</Text>
              <Text>Very Satisfied</Text>
            </FlexRow>

            {!editMode && (
              <FlexRow justifyContent="space-between">
                <StyledButton type="submit" variant="primary" disabled={!values.rating}>
                  Submit
                </StyledButton>
                <StyledButton
                  disabled={!values.rating}
                  onClick={() => {
                    resetForm({
                      values: {
                        rating: null,
                      },
                    });
                  }}
                >
                  Clear
                </StyledButton>
              </FlexRow>
            )}
            {editMode && (
              <FlexRow justifyContent="center">
                <StyledButton
                  disabled={!values.rating}
                  onClick={() => {
                    setEditMode(false);
                  }}
                >
                  Edit
                </StyledButton>
              </FlexRow>
            )}
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};

export const StyledHeading = styled(Heading)`
  background-color: ${({ theme }) => theme.variants.primary};
  color: #fff;
  padding-left: 12px;
  cursor: pointer;
`;
export const StyledButton = styled(Button)`
  &:disabled {
    cursor: initial;
  }
  width: 35%;
  margin: 0px 25px;
`;
export const Arrow = styled.div`
  margin-right: 5px;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  div:last-child label {
  }
  div label {
    margin: 20px 10px;
    width: 50px;
    height: 50px;
  }
  input {
    display: none;
    &:disabled + label {
      opacity: 0.5;
      cursor: initial;
    }
  }
  label {
    color: ${({ theme }) => theme.variants.primary};
    background-color: ${({ theme }) => theme.background.body};
    border-radius: 8px;
    border: white 1px solid;
    font-weight: bold;
    text-align: center;
    padding: 12px 16px;
    transition: 0.3s;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
  }
  input:checked + label {
    color: #fff;
    background: ${({ theme }) => theme.variants.primary};
    border-color: grey;
    position: relative;
  }
`;
export default ServiceRequestRating;
