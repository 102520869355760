import React from "react";
import ReactModal from "react-modal";

interface Props extends ReactModal.Props {
  className?: string;
  width?: number;
}

export const ReactModalAdapter: React.FC<Props> = ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  // disable screen reader warning for tests
  const ariaHideApp = typeof jest === "undefined";

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      ariaHideApp={ariaHideApp}
      {...props}
    />
  );
};
