import { TipaltiDisbursementStatus } from "generated/types";

type TipaltiDisbursementsFiltersFilterMakerProps = {
  dayFrom?: string;
  dayTo?: string;
  papa?: string;
  status?: string;
};

export const countFilters = (filters: Partial<TipaltiDisbursementsFiltersFilterMakerProps>) => {
  let count = 0;
  if (filters.status?.length) count++;
  if (filters.dayFrom) count++;
  if (filters.dayTo) count++;
  if (filters.papa) count++;
  return count;
};

export function tipaltiDisbursementsFiltersMaker({
  dayFrom,
  dayTo,
  status,
  papa,
}: TipaltiDisbursementsFiltersFilterMakerProps) {
  return {
    ...(dayFrom || dayTo
      ? {
          invoiceDate: {
            ...(dayFrom ? { gte: dayFrom } : {}),
            ...(dayTo ? { lt: dayTo } : {}),
          },
        }
      : {}),
    ...(status?.length && !status.includes(TipaltiDisbursementStatus.Processing)
      ? {
          status: {
            in: status.split(",") as TipaltiDisbursementStatus[],
          },
        }
      : status?.length
      ? {
          status: {
            notIn: [
              ...(!status.includes(TipaltiDisbursementStatus.Paid)
                ? [TipaltiDisbursementStatus.Paid]
                : []),
              ...(!status.includes(TipaltiDisbursementStatus.Error)
                ? [TipaltiDisbursementStatus.Error]
                : []),
            ] as TipaltiDisbursementStatus[],
          },
        }
      : {}),
    ...(papa ? { papaName: { cont: papa } } : {}),
  };
}
