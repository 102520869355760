import styled from "styled-components";

export const ScheduleVisitLabel = styled.label`
  display: block;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 14px;
  color: ${({ theme }) => theme.variants.primary};

  &:not(:first-child) {
    margin-top: 40px;
  }
`;
