import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import PartnerSelect from "components/UserSelect/PartnerSelect";

import { HANDOFF_VISIT } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  id: string;
  isOpen: boolean;
  toggle: () => void;
}

export const HandoffVisitModal: React.FC<Props> = ({ id, isOpen, toggle }) => {
  const [handoffVisit, { loading }] = useMutation(HANDOFF_VISIT);

  const handleSubmit = async (values: Schema) => {
    try {
      const { data } = await handoffVisit({ variables: { id, input: values } });
      const state = data?.handoffVisit?.data?.state;

      if (state && state === "PARTNER_OPERATED") {
        toast.success("Handoff visit was successful");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Handoff visit" isOpen={isOpen}>
      <Formik initialValues={{ partnerId: "" }} validationSchema={schema} onSubmit={handleSubmit}>
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label required>Select Partner</Form.Label>
              <PartnerSelect />
            </Form.Group>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading} variant="primary">
              {loading && <SpinningIcon size={18} />}
              Save
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};
