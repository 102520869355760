import { gql } from "@apollo/client";

export const UPDATE_PAYMENT_INVOICE_STATE = gql`
  mutation updatePaymentInvoiceState(
    $paymentInvoiceId: ID!
    $state: UpdatablePaymentInvoiceState!
  ) {
    updatePaymentInvoiceState(id: [$paymentInvoiceId], input: { state: $state }) {
      data {
        id
        state
      }
    }
  }
`;

export const GET_PAYMENT_INVOICE_STATE = gql`
  query getPaymentInvoiceState($paymentInvoiceId: ID!) {
    paymentInvoice(id: $paymentInvoiceId) {
      data {
        id
        state
      }
    }
  }
`;
