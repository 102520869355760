import React, { useContext } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";
import { StyledIcon } from "styled-icons/types";

import SidebarContext from "./SidebarContext";

interface Props extends NavLinkProps {
  children: React.ReactNode;
  to: string;
  icon?: StyledIcon;
  collapsed?: boolean;
}

const NavItem = ({ to, exact, icon: Icon, children }: Props) => {
  const { sidebarCollapsed } = useContext(SidebarContext);

  return (
    <StyledNavItem to={to} exact={exact} collapsed={sidebarCollapsed}>
      {Icon && <Icon size={24} />} {!sidebarCollapsed && children}
    </StyledNavItem>
  );
};

export const StyledNavItem = styled(({ collapsed, ...props }) => <NavLink {...props} />)<Props>`
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.text.tint};
  font-weight: 500;
  text-decoration: none;
  padding: ${({ collapsed }) => (collapsed ? "0 1.750rem" : "0 1.875rem")};
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s color ease;

  &.active {
    color: ${({ theme }) => theme.text.panelHeader};
    background: ${({ theme }) => theme.variants.primary};
    box-shadow: inset 4px 0 0 ${({ theme }) => theme.background.primaryTint};

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      color: ${({ theme }) => theme.text.tint};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.text.main};
  }

  svg {
    flex-shrink: 0;
    margin-right: ${({ collapsed }) => (collapsed ? 0 : "1rem")};
  }
`;

export default NavItem;
