import { PalStatus, Papa, PapaState } from "generated/types";

// enums

export enum TaskDisableReason {
  virtualVisit = "Unavailable for virtual visit",
  maxTimeRule = "Out of assets",
  businessPolicy = "Not available due to business policy",
  clientProgramPolicy = "Not available due to client program policy",
}

type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};

export type ConductStatusLegend =
  | PickEnum<PapaState, PapaState.UnderInvestigation | PapaState.Banned | PapaState.Suspended>
  | PickEnum<PalStatus, PalStatus.UnderInvestigation | PalStatus.Banned | PalStatus.Suspended>;

// entities

export interface DataVisibility {
  concealed: string[];
  full: string[];
  masked: string[];
}

export interface PapaConcealed extends Omit<Papa, "stillDrives"> {
  stillDrives?: string;
}
