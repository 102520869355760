import { gql } from "@apollo/client";

export const CANCEL_VISIT = gql`
  mutation terminateVisit($id: ID!, $input: TerminateVisitInput) {
    terminateVisit(id: $id, input: $input) {
      data {
        state
        id
        papa {
          data {
            id
          }
        }
      }
    }
  }
`;
