import { useField } from "formik";
import React, { HTMLAttributes } from "react";
import styled from "styled-components";

import Feedback from "components/Form/FormFeedback";
import { opacity } from "utils/opacity";

export const SwitchButtonInput = styled.input.attrs({
  type: "checkbox",
})`
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid #dfe1e5;
  background: #dfe1e5;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  width: 38px;
  min-width: 38px;
  border-radius: 11px;

  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform 0.3s ease, opacity 0.2s;

    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: #ffffff;
    transform: translateX(0);
  }
  &:checked {
    background: ${({ theme, color }) => color ?? theme.variants.primary};
    border-color: ${({ theme, color }) => color ?? theme.variants.primary};
    :after {
      background: #ffffff;
      transform: translateX(17px);
    }
  }
  &:disabled {
    background: #e9edf1;
    cursor: not-allowed;
    border-color: #e9edf1;
    &:checked {
      background: #e9edf1;
    }
    & + label {
      cursor: not-allowed;
    }
    &:after {
      opacity: 0.5;
      background: #ffffff;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        border-color: ${({ theme, color }) => color ?? theme.variants.primary};
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 2px ${({ theme, color }) => opacity(color ?? theme.variants.primary, 0.3)};
  }
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const Text = styled.span`
  margin-left: 5px;
`;

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const SwitchButton = ({ label, name, disabled = false, ...inputProps }: Props) => {
  const [field, { touched, error }, helpers] = useField(name);
  return (
    <>
      <Label>
        <SwitchButtonInput
          {...inputProps}
          checked={!!field.value}
          onChange={() => helpers.setValue(!field.value)}
          disabled={disabled}
        />
        <Text>{label}</Text>
      </Label>{" "}
      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

export default SwitchButton;
