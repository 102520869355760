import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

import Heading from "components/Heading";
import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { PalOnboardingActivity, SortDirection } from "generated/types";
import { PalOnboardingSingleResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";

import { PAL_ONBOARDING } from "../gql";

type Props = {
  id: string;
};

const Activity = ({ id }: Props) => {
  const { page, limit } = usePageInfo();
  const { data, loading, error, refetch } = useQuery<{ palOnboarding: PalOnboardingSingleResult }>(
    PAL_ONBOARDING,
    {
      variables: {
        palOnboardingId: id,
        pagination: { page, limit },
        sorting: { insertedAt: SortDirection.Desc },
      },
    }
  );
  const activities = data?.palOnboarding?.data?.activity;
  const location = useLocation();
  const notes = activities?.data;
  const columns: Column<PalOnboardingActivity>[] = [
    {
      header: { title: "Note" },
      dataField: "note",
    },
    {
      header: { title: "Updated At" },
      dataField: "updatedAt",
    },
  ];

  useEffect(() => {
    const handleRefetch = async () => {
      await refetch();
    };
    handleRefetch();
  }, [location.key, refetch]);

  return (
    <>
      <Helmet>
        <title>Prospect Pal Activity - Papa Admin</title>
      </Helmet>
      <Heading color="primary">Activity Feed</Heading>

      <SmartTable<PalOnboardingActivity>
        keyField="id"
        data={nonNull(notes)}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default Activity;
