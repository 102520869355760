import React from "react";
import { Route, Switch } from "react-router-dom";

import Details from "./Details";

const VisitsRoutes = () => {
  return (
    <Switch>
      <Route exact path={["/visits/:id", "/visits/:id/:tab"]} component={Details} />
    </Switch>
  );
};

export default VisitsRoutes;
