import { useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { Tasks } from "styled-icons/fa-solid";

import { OnlyCheckbox } from "components/Checkbox/OnlyCheckbox";
import Text from "components/Text";
import { iconsDict } from "constants/tasks";
import { Task, VisitObjectiveTemplate, VisitObjectiveTemplateListResult } from "generated/types";
import { WithId, nonNullWithId } from "utils/nonNull";

import { GET_VISIT_TASK_OBJECTIVES } from "./gql";

export type CompletedObjectives = { [key: string]: boolean };

interface ObjectivesData {
  visitObjectiveTemplates: VisitObjectiveTemplateListResult;
}

interface Props {
  tasks: Task[];
  objectives: WithId<Pick<VisitObjectiveTemplate, "id" | "description">>[];
  completedObjectives: CompletedObjectives;
  setCompletedObjectives: (objectives: CompletedObjectives) => void;
}

export const VisitTasks: React.FC<Props> = ({
  tasks,
  objectives,
  completedObjectives,
  setCompletedObjectives,
}) => {
  const taskIds = tasks.map(({ id }) => id);

  const { data } = useQuery<ObjectivesData>(GET_VISIT_TASK_OBJECTIVES, {
    variables: {
      filter: {
        taskId: {
          in: taskIds,
        },
      },
      pagination: {
        limit: 1000,
      },
    },
  });
  const objectiveTemplates = nonNullWithId(data?.visitObjectiveTemplates?.data);

  const onSetCompletedObjectives = (id: string) => {
    setCompletedObjectives({
      ...completedObjectives,
      [id]: !completedObjectives[id],
    });
  };

  return (
    <>
      <Text size="largex" bold>
        Visit task completed
      </Text>
      <Text>Please mark the completed tasks</Text>

      <TasksContainer>
        {tasks.map(({ id: taskId, name, icon }) => {
          const Icon = (icon && iconsDict[icon]) || Tasks;

          return (
            <TaskAndObjectives key={taskId}>
              <TaskContainer>
                <Icon size={32} />
                <Text size="large">{name}</Text>
              </TaskContainer>

              <ObjectivesContainer>
                {objectives
                  .filter(({ id }) =>
                    objectiveTemplates
                      .filter(({ task }) => task?.data?.id === taskId)
                      .map(({ id }) => id)
                      .includes(id)
                  )
                  .map(({ description, id: objectiveId }) => {
                    return (
                      <OnlyCheckbox
                        key={objectiveId}
                        label={description ?? ""}
                        name={`objective-${objectiveId}`}
                        onChange={() => onSetCompletedObjectives(objectiveId)}
                        checked={completedObjectives[objectiveId]}
                      />
                    );
                  })}
              </ObjectivesContainer>
            </TaskAndObjectives>
          );
        })}
      </TasksContainer>
    </>
  );
};

const TasksContainer = styled.div`
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  margin: 23px 0 32px;
  padding-left: 35px;
`;

const TaskAndObjectives = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TaskContainer = styled.div`
  display: flex;
  grid-gap: 8px;
  align-items: center;
  margin-bottom: 11px;

  svg {
    background: rgb(235, 86, 105, 0.16);
    color: #222428;
    padding: 7px;
    border-radius: 10px;
  }
`;

const ObjectivesContainer = styled.div`
  display: flex;
  padding-left: 8px;
  gap: 16px;
  flex-direction: column;
`;
