import { InfoCircle } from "@styled-icons/boxicons-regular/InfoCircle";
import React from "react";
import styled from "styled-components";

import Panel from "components/Panel";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import { Visit, VisitState } from "generated/types";
import { getSlimDuration } from "utils/numbers/durations";

type VisitFields = Pick<
  Visit,
  | "deductedMins"
  | "overrideDurationMins"
  | "observedDurationMins"
  | "estimatedDuration"
  | "deductedHoursExplanation"
  | "state"
>;

type Props = {
  visit?: VisitFields | null;
};

const Duration = ({ visit }: Props) => {
  const visitDuration = visit?.overrideDurationMins || visit?.observedDurationMins || 0;
  const estimatedDuration = visit?.estimatedDuration || 0;
  const isVisitPending =
    visit?.state === VisitState.Pending ||
    visit?.state === VisitState.Accepted ||
    visit?.state === VisitState.Confirmed ||
    visit?.state === VisitState.Started;

  const getDeductedMinutes = () => {
    if (!visit?.deductedMins) return "N/A";

    const duration = getSlimDuration(visit?.deductedMins);

    return isVisitPending ? `${duration} (pending)` : `${duration} (used)`;
  };

  const renderDeductedHoursTooltip = () => {
    if (!visit?.deductedHoursExplanation || isVisitPending) return null;

    const createBulletPoints = (list: string[]) => (
      <ul>
        {list.map((item: string, index: number) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
    );

    return (
      <Tooltip
        width="20rem"
        placement="right"
        content={
          <>
            <TooltipTitle bold>Deducted Hours Rules:</TooltipTitle>
            <TooltipHeaders bold>In-person Visits</TooltipHeaders>
            {createBulletPoints(visit.deductedHoursExplanation.inPersonVisits)}
            <TooltipHeaders bold>Virtual Visits</TooltipHeaders>
            {createBulletPoints(visit.deductedHoursExplanation.virtualVisits)}
            <TooltipHeaders bold>Member No Show</TooltipHeaders>
            {createBulletPoints(visit.deductedHoursExplanation.memberNoShow)}
          </>
        }
        ariaLabel="duration tooltip"
      >
        <StyledInfoCircle />
      </Tooltip>
    );
  };

  return (
    <Panel.Item>
      <Text bold color="primary">
        Duration
      </Text>
      <Text>
        Deducted: {getDeductedMinutes()} {renderDeductedHoursTooltip()}
      </Text>
      <Text>Recorded: {getSlimDuration(visitDuration)}</Text>
      <Text>Scheduled: {getSlimDuration(estimatedDuration)}</Text>
    </Panel.Item>
  );
};

const StyledInfoCircle = styled(InfoCircle)`
  cursor: pointer;
  width: 1rem;
  height: 100%;
  vertical-align: top;
`;

const TooltipTitle = styled(Text)`
  margin-bottom: 1rem;
`;

const TooltipHeaders = styled(Text)`
  margin-bottom: -0.75rem;
`;

export default Duration;
