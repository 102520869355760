import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";

export const getFirstLastName = (fullName: string | null = "") => {
  if (!fullName) return EMPTY_PLACEHOLDER;

  const pieces = fullName?.trim()?.split(" ");

  if (pieces.length === 1) return pieces[0];

  return [pieces[0], pieces[pieces.length - 1]].join(" ");
};
