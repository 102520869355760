import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { FunctionComponent, useState } from "react";
import Helmet from "react-helmet";

import Button from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import {
  Organization,
  OrganizationListResult,
  OrganizationSorting,
  SortDirection,
  SupportTicketRequesterType,
} from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePageInfo } from "hooks/usePageInfo";
import OrganizationModal from "modals/OrganizationModal";
import SubmitTicketGuestUserModal from "modals/SubmitTicketGuestUser";

import { Filter } from "./Filter";
import { GET_ORGANIZATIONS } from "./gql";
import { useOrganizationsTableColumns } from "./useOrganizationsTableColumns";

interface Data {
  organizations: OrganizationListResult;
}

const Organizations: FunctionComponent = () => {
  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");
  const topBarTitle = clientCopyUpdateEnabled ? "Clients" : "Organizations";
  const pageTitle = clientCopyUpdateEnabled ? "Clients - Papa Admin" : "Organizations - Papa Admin";
  const newClientButtonCopy = clientCopyUpdateEnabled ? "New Client" : "New Organization";
  const clientLinkPrefix = clientCopyUpdateEnabled ? "clients" : "organizations";

  useTopBarOptions({ title: topBarTitle, icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { beforeCursor, afterCursor, limit, name } = usePageInfo();
  const [sorting, setSorting] = useState<Partial<OrganizationSorting>>({
    insertedAt: SortDirection.Desc,
  });
  const { columns, action, organization, createOrganization, closeModal } =
    useOrganizationsTableColumns({
      sorting,
      onSortChange: setSorting,
    });

  const { data, loading, error, refetch } = useQuery<Data>(GET_ORGANIZATIONS, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter: { name: { cont: name } },
      sorting,
    },
  });

  const organizations = data?.organizations;
  const pagination = data?.organizations?.pagination;

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <FlexRow justifyContent="space-between">
        <Filter />
        <Button action onClick={createOrganization}>
          {newClientButtonCopy}
        </Button>
      </FlexRow>

      <SmartTable<Organization>
        keyField="id"
        localStorageKey="organizationsMainTable"
        data={organizations?.data}
        loading={loading}
        columns={columns}
        error={error}
        pagination={pagination}
        cursorPagination
        rowProps={({ id }) => ({
          linkTo: `/${clientLinkPrefix}/${id}`,
        })}
      />

      <OrganizationModal
        organization={organization}
        isOpen={action === DataTableMenuActions.Edit || action === DataTableMenuActions.Create}
        toggle={closeModal}
        onCreateFinish={handleRefetch}
      />

      <SubmitTicketGuestUserModal
        id={organization?.id!}
        type={SupportTicketRequesterType.Organization}
        isOpen={action === DataTableMenuActions.SubmitTicket}
        toggle={closeModal}
      />
    </div>
  );
};

export default Organizations;
