import { createContext } from "react";

import { BusinessPolicy, ResourceBudget } from "generated/types";

interface ContextValue {
  currentBusinessPolicy: BusinessPolicy | null;
  setCurrentBusinessPolicy: (value: BusinessPolicy | null) => void;
  resourceBudget: ResourceBudget | null;
  setResourceBudget: (value: ResourceBudget | null) => void;
}

const defaultValue: ContextValue = {
  currentBusinessPolicy: null,
  setCurrentBusinessPolicy: (value: BusinessPolicy | null) => {},
  resourceBudget: null,
  setResourceBudget: (value: ResourceBudget | null) => {},
};

export const CurrentBusinessPolicyContext = createContext(defaultValue);
