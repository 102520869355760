import { gql } from "@apollo/client";

export const GET_PALS = gql`
  query Pals($filter: [PalFilter!], $pagination: PaginationInput, $sorting: PalSorting) {
    pals(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        fullName
        email
        phoneNumber
        gender
        genderText
        insertedAt
        lastKnownLocationUpdatedAt
        languages {
          data
        }
        account {
          data {
            emailVerified
            id
            permissions {
              admin
            }
            homeLocation {
              data {
                address
                city
              }
            }
            palOnboarding {
              data {
                id
                zipcode
              }
            }
          }
        }
        status
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;

export const GET_PAL_OVERVIEW = gql`
  query PalOverview($id: ID!) {
    pal(id: $id) {
      data {
        id
        fullName
        status
        phoneNumber
        languages {
          data
        }
        gender
        genderText
        email
        dateOfBirth
        insertedAt
        account {
          data {
            emailVerified
            id
            fullName
            permissions {
              admin
            }
            homeLocation {
              data {
                id
                address
                lat
                lng
              }
            }
            palOnboarding {
              data {
                id
                zipcode
              }
            }
            billingProfile {
              data {
                palDisbursementProcessor
              }
            }
          }
        }
        school
        fieldOfStudy
        verifiedAt
        bio
        covidVaccinated
        vehicleModel
        drugTestClean
        lastKnownLocation {
          lat
          lng
        }
        lastKnownLocationUpdatedAt
        covidVaccinationRecord {
          data {
            id
            sponsor
            cardFront
            pendingReview
            approvedAt
            updatedAt
            vaccinationDateOne
            vaccinationDateTwo
          }
        }
        palType {
          data {
            id
            description
            code
          }
        }
        suspendedPals {
          data {
            id
            suspendedUntil
            insertedAt
          }
        }
        optOutVisitSuccessCall {
          data {
            id
            optOut
            optOutUntil
          }
        }
      }
    }
  }
`;

export const GET_PAL_NOTES = gql`
  query PalNotes(
    $id: ID!
    $filter: [PalNoteFilter!]
    $pagination: PaginationInput
    $sorting: PalNoteSorting
  ) {
    pal(id: $id) {
      data {
        notes(filter: $filter, pagination: $pagination, sorting: $sorting) {
          data {
            id
            insertedAt
            content
            creator {
              data {
                fullName
                id
                type
              }
            }
          }
          pagination {
            limit
            page
            totalCount
            totalPages
          }
        }
      }
    }
  }
`;

export interface CreatePalNoteInput {
  input: {
    content: string;
    palId: string;
  };
}

export const CREATE_PAL_NOTE = gql`
  mutation CreatePalNote($input: PalNoteInput!) {
    createPalNote(input: $input) {
      data {
        id
        insertedAt
        content
        creator {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const GET_PAL_LOCATION = gql`
  query PalLocation($palId: ID!) {
    pal(id: $palId) {
      data {
        id
        insertedAt
        updatedAt
        lastKnownLocation {
          lat
          lng
        }
        lastKnownLocationUpdatedAt
      }
    }
  }
`;

export const GET_PAL_VEHICLE = gql`
  query PalVehicle($id: ID!) {
    pal(id: $id) {
      data {
        id
        vehicle
        vehicleYear
        vehicleColor
        vehicleModel
      }
    }
  }
`;

export const GET_PAL_STATUS_LOGS = gql`
  query PalStatusLogs(
    $id: ID!
    $filter: [PalStatusLogFilter!]
    $pagination: PaginationInput = { page: 1, limit: 20 }
    $sorting: PalStatusLogSorting
  ) {
    pal(id: $id) {
      data {
        statusLogs(filter: $filter, pagination: $pagination, sorting: $sorting) {
          data {
            id
            newStatus
            comment
            insertedAt
            account {
              data {
                id
                fullName
              }
            }
          }
          pagination {
            limit
            page
            totalCount
            totalPages
          }
        }
      }
    }
  }
`;

export const GET_PAL_DEVICES = gql`
  query PalDevices($id: ID!) {
    pal(id: $id) {
      data {
        account {
          data {
            deviceTokens(sorting: { updatedAt: DESC }) {
              data {
                id
                platform
                updatedAt
                info {
                  appVersion
                  model
                  manufacturer
                  systemName
                  systemVersion
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PAL_ACCOUNT = gql`
  mutation deletePalAccount($accountId: ID!) {
    deletePalAccount(accountId: $accountId) {
      success
    }
  }
`;

export const GET_PAL_TYPES = gql`
  query PalTypes {
    palTypes {
      data {
        id
        code
        description
        isContractor
        insertedAt
      }
    }
  }
`;

export const VACCINE_APPROVE = gql`
  mutation approveCovidVaccinationRecord($covidVaccinationRecordId: ID!) {
    approveCovidVaccinationRecord(covidVaccinationRecordId: $covidVaccinationRecordId) {
      data {
        approvedAt
      }
    }
  }
`;

export const UPDATE_DISBURSEMENT_PROCESSOR = gql`
  mutation UpdatePalDisbursementProcessor($id: ID!, $processor: String!) {
    updatePalDisbursementProcessor(id: $id, processor: $processor)
  }
`;
