import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import VisitsTable from "components/DataTables/VisitsTable";
import { VisitListResult, VisitSorting } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useSorting } from "hooks/useSorting";
import { nonNull } from "utils/nonNull";

import { GET_MEMBER_VISITS } from "../gql";

type Data = {
  visits: VisitListResult;
};

const Visits = () => {
  const { id } = useParams<{ id: string }>();
  const { page, limit } = usePageInfo();
  const [sorting, setSorting] = useSorting<VisitSorting>(["scheduledFor"]);

  const { data, loading, error } = useQuery<Data>(GET_MEMBER_VISITS, {
    variables: {
      id,
      pagination: { page, limit },
      sorting,
    },
  });

  return (
    <>
      <Helmet>
        <title>Member Visits - Papa Admin</title>
      </Helmet>
      <VisitsTable
        data={nonNull(data?.visits?.data)}
        loading={loading}
        sorting={sorting}
        onSortChange={setSorting}
        pagination={data?.visits?.pagination}
        error={error}
      />
    </>
  );
};

export default Visits;
