import { gql } from "@apollo/client";

export const MAKE_PAPA_VACCINATED = gql`
  mutation makePapaVaccinated($id: ID!) {
    updatePapa(id: $id, input: { covidVaccinated: true }) {
      data {
        id
        covidVaccinated
      }
    }
  }
`;
