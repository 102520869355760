import { Moment } from "moment-timezone";
import { array, boolean, date, number, object, string } from "yup";

import { IntervalUnit } from "generated/types";

export interface Schema {
  dateRange: {
    startDate: Moment | null;
    endDate: Moment | null;
  };
  numberOfHours: number;
  intervalUnit: IntervalUnit | null | undefined;
  inPersonDisallowedTaskIds: string[];
  virtualDisallowedTaskIds: string[];
  allowInPersonVisits: boolean;
  allowVirtualVisits: boolean;
  lonelinessCompliant: boolean;
  palCovidVaccination: boolean;
  papaCovidVaccination: boolean;
  allowUberForTransportation: boolean;
  careConciergeEnabled: boolean;
  paysForCareConcierge: boolean | null;
  maxMinutesPerVisit: number | null;
  hiddenAssessments: string[];
  warmTransfer: boolean;
  warmTransferPhoneNumber: string;
  maxVisitDistanceMi: number | null | undefined;
  maxScheduledVisitsPerDay: number | null | undefined;
}

export const schema = object().shape(
  {
    dataRange: object().shape({
      startDate: date(),
      endDate: date(),
    }),
    numberOfHours: number().required("Required"),
    intervalUnit: string().required("Required"),
    inPersonDisallowedTaskIds: array().of(string()),
    virtualDisallowedTaskIds: array().of(string()),
    allowInPersonVisits: boolean().when("allowVirtualVisits", {
      is: true,
      then: boolean(),
      otherwise: boolean().oneOf([true], "Required"),
    }),
    allowVirtualVisits: boolean().when("allowInPersonVisits", {
      is: true,
      then: boolean(),
      otherwise: boolean().oneOf([true], "Required"),
    }),
    allowUberForTransportation: boolean(),
    lonelinessCompliant: boolean(),
    palCovidVaccination: boolean().nullable(),
    papaCovidVaccination: boolean().nullable(),
    careConciergeEnabled: boolean().nullable(),
    paysForCareConcierge: boolean().nullable(),
    maxMinutesPerVisit: number().nullable(),
    warmTransfer: boolean().nullable(),
    warmTransferPhoneNumber: string().when("warmTransfer", {
      is: true,
      then: string().required("Required"),
      otherwise: string(),
    }),
    maxVisitDistanceMi: number().positive().nullable(),
    maxScheduledVisitsPerDay: number().positive().nullable(),
  },
  [["allowVirtualVisits", "allowInPersonVisits"]]
);
