import { ApolloError } from "@apollo/client";
import React from "react";

import ProspectPalActions from "components/ActionsMenu/ProspectPalActionMenu/Actions";
import SmartTable from "components/SmartTable";
import { Pagination, PalOnboarding, PalOnboardingSorting } from "generated/types";

import { usePalOnboardingTableColumns } from "./usePalOnboardingsTableColumns";

export interface Props {
  data?: PalOnboarding[];
  loading: boolean;
  pagination?: Pagination;
  error?: ApolloError;
  sorting: PalOnboardingSorting;
  onSortChange: (sort: PalOnboardingSorting) => void;
  cursorPagination?: boolean;
}

const PalOnboardingsTable = ({
  data,
  loading,
  error,
  pagination,
  sorting,
  onSortChange,
  cursorPagination = false,
}: Props) => {
  const { columns, action, palOnboarding, closeModal } = usePalOnboardingTableColumns({
    sorting,
    onSortChange,
  });

  return (
    <>
      <SmartTable<PalOnboarding>
        keyField="id"
        localStorageKey="palOnboardings"
        data={data}
        loading={loading}
        columns={columns}
        pagination={pagination}
        error={error}
        cursorPagination={cursorPagination}
      />

      <ProspectPalActions action={action} palOnboarding={palOnboarding} closeModal={closeModal} />
    </>
  );
};

export default PalOnboardingsTable;
