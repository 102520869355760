import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { toast } from "react-toastify";

import { Drawer } from "components/Drawer";
import { Loader } from "components/Loader";
import QueryErrors from "components/QueryErrors";
import {
  QuestionEnum,
  useCreateAnswerMutation,
  useDestroyAnswerMutation,
  useEditAssessmentAnswersMutation,
  useGetAssessmentAnswersQuery,
} from "generated/types";
import { useGetSurvey } from "hooks/useGetSurvey";
import { AssessmentType } from "types/assessments";
import {
  formatAnswersToEditInputs,
  generateInitialValuesEdit,
  generateSchema,
  getHardcodedQuestionsRequired,
} from "utils/helpers/assessments";
import { nonNull } from "utils/nonNull";

import { AnswerScoreProvider } from "./AnswersScoreEdit";
import SectionFlow from "./SectionFlow";
import { groups, hardcodedQuestionRequired, questionsFlow } from "./hardcodedQuestions";

type FormValues = Record<string, string | string[] | QuestionEnum | undefined | null>;

interface Props {
  assessmentId: string;
  papaId: string;
  onClose: () => void;
  onRefetch?: () => void;
}

const AssessmentsQuestionsPapaSocialIndexEdit: React.FC<Props> = ({
  papaId,
  assessmentId,
  onClose,
  onRefetch = () => {},
}) => {
  const type = AssessmentType.papaSocialIndex;
  const { data, loading, error } = useGetAssessmentAnswersQuery({
    variables: {
      assessmentId,
    },
  });

  const [editAssessmentAnswer, { loading: isEditing }] = useEditAssessmentAnswersMutation();
  const [createAnswer] = useCreateAnswerMutation();
  const [destroyAnswer] = useDestroyAnswerMutation();
  const {
    data: { survey },
  } = useGetSurvey({ papaId, type });

  if (error) {
    return <QueryErrors error={error} />;
  }

  if (loading) return <Loader />;

  const questions = nonNull(survey?.questions?.data);
  const answers = nonNull(data?.showVqeSubmission?.data?.answers?.data);

  const initialValues = generateInitialValuesEdit(answers);

  const schema = generateSchema(questions, getHardcodedQuestionsRequired(type));

  const handleSubmit = async (values: FormValues) => {
    const { editAnswers, deleteAnswers, createAnswers } = formatAnswersToEditInputs({
      values,
      questions,
      initialValues,
      answers,
      questionsFlow,
    });

    try {
      for (const answer of editAnswers) {
        await editAssessmentAnswer({
          variables: {
            answer: answer.answer as string,
            answerId: answer.answerId,
            surveyQuestionId: answer.questionId,
            surveySubmissionId: assessmentId,
          },
        });
      }

      for (const answer of deleteAnswers) {
        await destroyAnswer({
          variables: {
            answerId: answer.answerId,
          },
        });
      }
      for (const answer of createAnswers) {
        await createAnswer({
          variables: {
            input: {
              surveyQuestionId: answer.questionId,
              surveySubmissionId: assessmentId,
              answer: answer.answer,
            },
          },
        });
      }

      toast.success("The assessment answers were updated");
      onClose();
      onRefetch();
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <AnswerScoreProvider questions={questions}>
        <FormikForm noValidate>
          <SectionFlow
            type={type}
            questions={questions}
            hardCodedQuestionsRequired={hardcodedQuestionRequired}
            questionsFlow={questionsFlow}
            handleClose={onClose}
            isSaving={isEditing}
            groups={[
              ...groups,
              {
                id: "S6",
                questions: ["P29", "P30"],
              },
            ]}
          />
        </FormikForm>
      </AnswerScoreProvider>
    </Formik>
  );
};

type ModalProps = {
  toggle: () => void;
  isOpen: boolean;
  papaId: string;
  assessmentId: string;
  onClose: () => void;
  onRefetch?: () => void;
};

const Modal = ({
  toggle,
  isOpen,
  papaId,
  assessmentId,
  onClose,
  onRefetch = () => {},
}: ModalProps) => {
  return (
    <Drawer onClose={toggle} title="Edit Assessment Answers" open={isOpen} size="lg">
      <AssessmentsQuestionsPapaSocialIndexEdit
        papaId={papaId}
        assessmentId={assessmentId}
        onClose={() => {
          toggle();
          onClose();
        }}
        onRefetch={onRefetch}
      />
    </Drawer>
  );
};

export default Modal;
