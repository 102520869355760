import * as yup from "yup";

import { Location, PapaTeam } from "generated/types";

export interface Schema {
  phoneNumber: string;
  email: string;
  location: Partial<Location>;
  roleIds: string[];
  isAdmin: boolean;
  fullName: string;
  papaTeam?: PapaTeam | null;
}

const googleLocationSchema = yup
  .object()
  .shape({
    address: yup.string(),
  })
  .nullable();

export const schema = yup.object().shape({
  phoneNumber: yup.string().nullable(),
  email: yup.string().nullable(),
  location: googleLocationSchema,
  roleIds: yup.array().of(yup.string()).nullable(),
  isAdmin: yup.boolean(),
  fullName: yup.string(),
  papaTeam: yup.string().nullable(),
});
