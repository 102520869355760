import React, { FunctionComponent } from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { VisitState } from "generated/types";

import CreatePriorityBonusForm from "./CreatePriorityBonusForm";

interface Props {
  visitId: string;
  visitState?: VisitState | null;
  isOpen: boolean;
  toggle: () => void;
  onRefetch: () => void;
}

const CreatePriorityBonusModal: FunctionComponent<Props> = ({
  visitId,
  visitState,
  toggle,
  isOpen,
  onRefetch,
}) => {
  return (
    <ModalLayout toggle={toggle} title="Create Priority Bonus" isOpen={isOpen}>
      <CreatePriorityBonusForm
        visitId={visitId}
        visitState={visitState}
        closeModal={toggle}
        onSubmitFinish={onRefetch}
      />
    </ModalLayout>
  );
};

export default CreatePriorityBonusModal;
