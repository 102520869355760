export enum VisitCommentType {
  ComplaintFromPapa = "Complaint from Papa",
  ComplaintFromPal = "Complaint from Pal",
  VisitIncident = "Visit Incident",
  CareConcierge = "Care Concierge",
  VisitAdjustment = "Visit Adjustment",
  GeneralFeedback = "General Feedback",
  PalBonus = "Pal Bonus",
  Escalation = "Escalation",
  Suggestion = "Suggestion",
  Other = "Other",
}

export const VISIT_COMMENT_TYPES = Array.from(Object.values(VisitCommentType)) as string[];

export const visitCommentOptions = [
  { label: "Complaint from Papa", value: VisitCommentType.ComplaintFromPapa },
  { label: "Complaint from Pal", value: VisitCommentType.ComplaintFromPal },
  { label: "Visit Incident", value: VisitCommentType.VisitIncident },
  { label: "Care concierge", value: VisitCommentType.CareConcierge },
  { label: "Visit Adjustment", value: VisitCommentType.VisitAdjustment },
  { label: "General Feedback", value: VisitCommentType.GeneralFeedback },
  { label: "Pal Bonus", value: VisitCommentType.PalBonus },
  { label: "Escalation", value: VisitCommentType.Escalation },
  { label: "Suggestion", value: VisitCommentType.Suggestion },
  { label: "Other", value: VisitCommentType.Other },
];
