import React from "react";

import FormGroup from "./FormGroup";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";

type Props = {
  label: string;
  name: string;
  placeholder: string;
};

const FormInputWithLabel = ({ label, name, placeholder }: Props) => {
  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <FormInput name={name} placeholder={placeholder} />
    </FormGroup>
  );
};

export default FormInputWithLabel;
