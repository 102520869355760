import { useQuery } from "@apollo/client";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Conditional from "components/Conditional";
import { confirm } from "components/Confirm";
import Form from "components/Form";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import { Loader } from "components/Loader";
import { ConfirmModalIcon } from "components/Modal/ConfirmModal";
import Tooltip from "components/Tooltip";
import PalSelect from "components/UserSelect/AsyncPalSelect";
import SyncPalSelect from "components/UserSelect/SyncPalSelect";
import {
  Pal,
  PalWithVisitMetadataResult,
  usePapaAssignablePalsQuery,
  useSelectedPalQuery,
} from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { nonNull } from "utils/nonNull";

import { PreferredAndRecentPalTable } from "./PreferredAndRecentPalTable";
import { PAPA_PREFERENCES } from "./gql";

enum PermissionLevel {
  Elevated,
  Admin,
  None,
}

interface Props {
  disabled: boolean;
  isEditMode: boolean;
  sectionNumber?: number;
}

const PalSettings = ({ disabled, isEditMode, sectionNumber }: Props) => {
  const [isAssignPal, setIsAssignPal] = useState(false);
  const { isAdmin, isVisitSuccessAgent, isSupervisor } = useCurrentAccountRole();

  const [{ value: papaId }] = useField("papaId");
  const [{ value: palId }, , palIdHelpers] = useField("palId");
  const [{ value: isRecurring }] = useField("freq");
  const {
    data,
    loading,
    refetch: papaPreferencesRefetch,
  } = useQuery(PAPA_PREFERENCES, {
    variables: { papaId },
    skip: !papaId,
  });
  const favoritePals = data?.papa?.data?.favoritePals?.data || [];
  const noFavoritePals = !favoritePals || favoritePals.length === 0;

  const { data: selectedPal, loading: isSelectedPalLoading } = useSelectedPalQuery({
    variables: { id: palId },
    skip: !palId,
  });

  const {
    data: assignablePalsData,
    loading: assignablePalsLoading,
    error: assignablePalsError,
    refetch: assignablePalsRefetch,
  } = usePapaAssignablePalsQuery({
    variables: {
      id: papaId,
    },
    skip: !papaId,
  });

  const determinePermissionLevel = () => {
    if (isVisitSuccessAgent || isSupervisor) {
      return PermissionLevel.Elevated;
    } else if (isAdmin) {
      return PermissionLevel.Admin;
    } else {
      return PermissionLevel.None;
    }
  };
  const permissionLevel = determinePermissionLevel();

  const assignablePals = nonNull(assignablePalsData?.papa?.data?.assignablePals?.data) ?? [];

  const shouldShowAssignPal =
    !isEditMode &&
    !isRecurring &&
    (permissionLevel === PermissionLevel.Elevated ||
      (permissionLevel === PermissionLevel.Admin && assignablePals.length > 0));

  const onPreferredPalUpdate = () => {
    assignablePalsRefetch();
    papaPreferencesRefetch();
  };

  const openAssignPalModal = async () => {
    const confirmed = await confirm(
      <>
        <ul>
          <li>
            Assigning a Pal to this visit will manually accept the visit for the Pal and add it to
            their schedule.
          </li>
          <li>
            Before proceeding, please ensure the Pal is aware and agrees to go on this visit with
            the member.
          </li>
        </ul>
      </>,
      {
        header: "Are you sure?",
        cancelBtnText: "No, go back",
        confirmBtnText: "Yes, continue",
        icon: ConfirmModalIcon.none,
      }
    );

    setIsAssignPal(confirmed);
  };

  // Clear palId when switching between assigned and preferred pal(s)
  useEffect(() => {
    if (!isAssignPal) {
      palIdHelpers.setValue(null);
    }
  }, [isAssignPal, palIdHelpers]);

  return (
    <Form.Panel number={sectionNumber} headerText="Pal Settings">
      <Container>
        <Loader isLoading={loading}>
          <>
            <PreferredOrAssignedPalSelection
              noFavoritePals={noFavoritePals}
              shouldShowAssignPal={shouldShowAssignPal}
              isAssignPal={isAssignPal}
              setIsAssignPal={setIsAssignPal}
              openAssignPalModal={openAssignPalModal}
            />

            <Conditional show={!isAssignPal && !!assignablePals}>
              <PreferredAndRecentPalTable
                onPreferredPalUpdate={onPreferredPalUpdate}
                assignablePals={assignablePals}
                assignablePalsLoading={assignablePalsLoading}
                assignablePalsError={assignablePalsError}
              />
            </Conditional>

            <Conditional show={isAssignPal && !isEditMode}>
              <AssignPal
                isSelectedPalLoading={isSelectedPalLoading}
                selectedPal={selectedPal}
                favoritePals={favoritePals}
                disabled={disabled}
                assignablePalsLoading={assignablePalsLoading}
                assignablePals={assignablePals}
                permissions={permissionLevel}
              />
            </Conditional>

            <Conditional show={!isAssignPal && !noFavoritePals}>
              <PreferredPalUnavailable />
            </Conditional>
          </>
        </Loader>
      </Container>
    </Form.Panel>
  );
};

export default PalSettings;

interface PreferredOrAssignedPalSelectionProps {
  noFavoritePals: boolean;
  shouldShowAssignPal: boolean;
  isAssignPal: boolean;
  setIsAssignPal: React.Dispatch<React.SetStateAction<boolean>>;
  openAssignPalModal: () => {};
}
const PreferredOrAssignedPalSelection = ({
  noFavoritePals,
  shouldShowAssignPal,
  isAssignPal,
  setIsAssignPal,
  openAssignPalModal,
}: PreferredOrAssignedPalSelectionProps) => {
  return (
    <>
      <ScheduleVisitLabel>Who would you like to go on this visit?</ScheduleVisitLabel>
      <Form.Group>
        <Form.GroupInline>
          <Form.Inline>
            <Form.Radio
              controlId="palTables"
              name="isAssignPal"
              useFormik={false}
              value={!isAssignPal}
              onChange={() => setIsAssignPal(false)}
              label={
                noFavoritePals
                  ? "Any pal - all pals in the area will see this visit"
                  : "Preferred Pals"
              }
            />
          </Form.Inline>
          <Conditional show={shouldShowAssignPal}>
            <Form.Inline>
              <Form.Radio
                controlId="assignAPal"
                name="isAssignPal"
                useFormik={false}
                value={isAssignPal}
                label="Assign a pal"
                onChange={() => openAssignPalModal()}
              />
            </Form.Inline>
          </Conditional>
        </Form.GroupInline>
      </Form.Group>
    </>
  );
};

interface AssignPalProps {
  isSelectedPalLoading: boolean;
  selectedPal: any;
  favoritePals: any;
  disabled: boolean;
  assignablePalsLoading: boolean;
  assignablePals: any;
  permissions: PermissionLevel;
}
const AssignPal = ({
  isSelectedPalLoading,
  selectedPal,
  favoritePals,
  disabled,
  assignablePalsLoading,
  assignablePals,
  permissions,
}: AssignPalProps) => {
  return (
    <Tooltip
      title={
        permissions === PermissionLevel.None
          ? "Your account does not have the role required to perform this action. A supervisor or the IT helpdesk should be able to assist you with gaining the required role."
          : null
      }
      placement="auto-start"
    >
      <Form.Group>
        <Form.Label>Assigned pal</Form.Label>
        <Loader isLoading={isSelectedPalLoading || assignablePalsLoading}>
          <PalSelection
            selectedPal={selectedPal}
            favoritePals={favoritePals}
            disabled={disabled}
            assignablePals={assignablePals}
            permissions={permissions}
          />
        </Loader>
      </Form.Group>
    </Tooltip>
  );
};

const PreferredPalUnavailable = () => {
  return (
    <>
      <ScheduleVisitLabel>
        What if your Preferred Pal is unavailable for this visit?
      </ScheduleVisitLabel>
      <Form.Group>
        <Form.GroupInline>
          <Form.Inline>
            <Form.Radio
              controlId="cancelVisit"
              name="favoritePalsOnly"
              value={true}
              label="Cancel Visit"
            />
          </Form.Inline>
          <Form.Inline>
            <Form.Radio
              controlId="sendAnyPal"
              name="favoritePalsOnly"
              value={false}
              label="Send any Pal"
            />
          </Form.Inline>
        </Form.GroupInline>
      </Form.Group>
    </>
  );
};

interface PalSelectionProps {
  selectedPal: any;
  favoritePals: any;
  disabled: boolean;
  assignablePals: PalWithVisitMetadataResult[];
  permissions: PermissionLevel;
}

const PalSelection = ({
  selectedPal,
  favoritePals,
  disabled,
  assignablePals,
  permissions,
}: PalSelectionProps) => {
  const favoritePalIds = favoritePals.map((pal: Pal) => pal.id);

  const mapPalWithMetadataToDropDownItem = (pal: PalWithVisitMetadataResult) => {
    return {
      value: pal?.id ?? "",
      label: pal?.fullName ?? "",
      phone: pal?.phoneNumber ?? "",
      isPreferred: pal?.isFavorite ?? false,
    };
  };

  const mapPalToDropDownItem = (pal: any) => {
    return {
      value: pal?.id ?? "",
      label: pal?.account?.data?.fullName ?? "",
      phone: pal?.account?.data?.phoneNumber ?? "",
      isPreferred: favoritePalIds.includes(pal?.id),
    };
  };

  const palSelectItem = mapPalToDropDownItem(selectedPal?.pal?.data);
  const assignablePalsAsDefaultOptions = assignablePals.map(mapPalWithMetadataToDropDownItem);

  if (permissions === PermissionLevel.Elevated) {
    const props = {
      "aria-label": "assign any pal",
      placeholder: "Start typing to add any pal",
      name: "palId",
      defaultValue: selectedPal?.pal?.data?.id ? palSelectItem : null,
      defaultOptions: assignablePalsAsDefaultOptions,
      isDisabled: disabled,
      isClearable: true,
      preferredPalIds: favoritePalIds,
    };

    return <PalSelect {...props} />;
  } else if (permissions === PermissionLevel.Admin) {
    const props = {
      "aria-label": "assign previous pal",
      name: "palId",
      isDisabled: disabled,
      pals: assignablePals,
      placeholder: "Select Pal",
    };

    return <SyncPalSelect {...props} />;
  } else {
    return null;
  }
};

const Container = styled.div`
  margin-bottom: 40px;
`;
