import { gql } from "@apollo/client";

import { Pal } from "generated/types";
import { PapaTeam, PermissionListResult } from "generated/types";

export type CurrentAccountData = {
  currentAccount?: {
    data: CurrentAccount;
  };
};

export type EnabledFeaturesForCurrentAccountData = {
  currentAccount?: {
    data: EnabledFeaturesForCurrentAccount;
  };
};

export type CurrentAccount = {
  email?: string;
  fullName?: string;
  id: string;
  roles: RolesData;
  pal: { data: Pal };
  papaTeam?: PapaTeam;
  accessPermissions: PermissionListResult;
  enabledFeatures: string[];
  availableCreditReasons: { data: { id: string; reasonName: string }[] };
};

type EnabledFeaturesForCurrentAccount = {
  id: string;
  enabledFeatures: string[];
};

type Role = {
  id?: string;
  name?: string;
};

export type RolesData = {
  data?: Role[];
};

export const GET_CURRENT_ACCOUNT = gql`
  query currentAccount {
    currentAccount {
      data {
        id
        email
        papaTeam
        fullName
        roles {
          data {
            name
          }
        }
        accessPermissions {
          data {
            name
          }
        }
        enabledFeatures
        availableCreditReasons {
          data {
            id
            reasonName
          }
        }
        pal {
          data {
            id
            activeVisit {
              data {
                id
              }
            }
            visits(filter: { state: { eq: STARTED } }) {
              data {
                id
                state
                events(
                  pagination: { limit: 1 }
                  filter: { action: { in: [CALL_ATTEMPTED, CALL_ENDED] } }
                ) {
                  data {
                    ... on VisitEvent {
                      action
                    }
                  }
                }
                papa {
                  data {
                    id
                    fullName
                    dataVisibility {
                      concealed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ENABLED_FEATURES_FOR_CURRENT_ACCOUNT = gql`
  query enabledFeaturesForCurrentAccount {
    currentAccount {
      data {
        id
        enabledFeatures
      }
    }
  }
`;
