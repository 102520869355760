/* eslint-disable quotes */
import { StyledIconBase } from "@styled-icons/styled-icon";
import React from "react";
import { DefaultTheme, css, keyframes } from "styled-components";
import styled from "styled-components";

import { ButtonProps, ButtonStyle } from "components/ButtonLink";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

type Variants = keyof DefaultTheme["variants"];
type Sizes = keyof DefaultTheme["sizes"];
type FontSizes = keyof DefaultTheme["fontSizes"];

export const defaultProps = {
  variant: "primary" as Variants,
  size: "middle" as Sizes,
  fontSize: "middle" as FontSizes,
};

type DefaultProps = Readonly<typeof defaultProps>;

type Props = {
  variant?: Variants;
  size?: Sizes;
  fontSize?: FontSizes;
  block?: boolean;
  loading?: boolean;
  disabled?: boolean;
  action?: boolean;
  noContent?: boolean;
  nowrap?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  borderWidth?: string;
  spanSpacing?: string;
} & Partial<DefaultProps>;

const NativeButton = ({
  action,
  noContent,
  block,
  isSelected,
  isActive,
  borderWidth,
  spanSpacing,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & Props) => <button {...props} />;

export const Button = styled(NativeButton)<Props>`
  width: ${({ block }) => (block ? "100%" : "auto")};
  display: flex;
  justify-content: ${({ block, noContent }) => (block || noContent ? "center" : "space-between")};
  background: ${({ variant = defaultProps.variant, theme, action }) =>
    action ? theme.variants[variant!] : "transparent"};
  border: ${({ borderWidth }) => (borderWidth ? borderWidth : "0.15625rem")}
    ${({ variant = defaultProps.variant, theme }) => theme.variants[variant!]} solid;
  border-radius: 0.375rem;
  color: ${({ variant = defaultProps.variant, theme, action }) =>
    action ? "white" : theme.variants[variant!]};
  font-weight: bold;
  text-align: center;
  align-items: center;
  padding: ${({ size = defaultProps.size, theme }) => theme.sizes[size!]};
  font-size: ${({ fontSize = defaultProps.fontSize, theme }) => theme.fontSizes[fontSize!]};
  transition: 0.3s;
  padding-right: ${({ action, noContent, loading }) =>
    (action && !noContent) || loading ? "45px" : "inherent"};
  cursor: pointer;
  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: ${({ variant = defaultProps.variant, theme, disabled }) =>
      !disabled && theme.variants[variant!]};
    color: ${({ variant = defaultProps.variant, theme, disabled }) => !disabled && "white"};
  }
  ${({ noContent, loading, action }) =>
    !noContent &&
    css`
      &:before {
        align-self: flex-start;
        animation: ${loading &&
        css`
          ${rotate} 2s linear infinite;
        `};
        line-height: ${(action || loading) && "1.125rem"};
        font-size: ${(action || loading) && "1.875rem"};
        font-weight: ${(action || loading) && "normal"};
        margin-right: ${(action || loading) && "0.625rem"};
        content: ${action || loading ? '"+"' : '""'};
      }
      &:after {
        content: "";
      }
    `}
  &:active {
    transform: ${({ disabled, loading }) => (disabled || !loading) && "translateY(0.125rem)"};
  }
  &:focus {
    outline: none;
  }
  ${StyledIconBase} {
    color: ${({ variant = defaultProps.variant, theme, action }) =>
      action ? "white" : theme.variants[variant!]};
  }
  &:hover {
    ${StyledIconBase} {
      color: white;
    }
  }
  ${StyledIconBase} + span,
  span + span {
    margin-left: ${({ spanSpacing }) => (spanSpacing ? spanSpacing : "0.5rem")};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.variants.primary};
      border-color: ${({ theme }) => theme.variants.primary};
      color: white;
    `}
`;

Button.defaultProps = defaultProps;

export const ButtonLink = styled(Button)`
  text-decoration: underline;
  border-width: 0;
  ${StyledIconBase} {
    color: ${({ theme }) => theme.variants.primary};
    margin-right: 1rem;
  }
  &:hover {
    ${StyledIconBase} {
      color: white;
    }
  }
`;

export const ButtonNew = styled.button<ButtonProps>`
  ${ButtonStyle}
`;

export default Button;
