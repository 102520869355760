import React from "react";
import { Link } from "react-router-dom";

import DataTableDropdownMenu from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { Account } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { formatDateTime } from "utils/helpers/formatters";

export const useBusinessTableColumns = () => {
  const { singularEntityName: programOrBusiness, kebabCased } = useCallItClientProgramOrBusiness();
  const menuItems = ({ id }: Account) => [
    {
      label: `View ${programOrBusiness}`,
      link: `/${kebabCased.pluralEntityName}/${id}`,
    },
  ];
  const columns: Column<Account>[] = [
    {
      header: { title: "Name" },
      dataField: "fullName",
      formatter({ fullName, id }) {
        return <Link to={`/members/${id}`}>{fullName}</Link>;
      },
    },
    {
      header: { title: "Contact" },
      dataField: "email",
      formatter({ email, phoneNumber }) {
        return (
          <>
            <Text>{email}</Text>
            <Text>{phoneNumber}</Text>
          </>
        );
      },
    },
    {
      header: { title: "Membership" },
      dataField: "plan.data.name",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "Joined At" },
      dataField: "insertedAt",
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt);
      },
    },
    {
      header: { title: "#Number of papas" },
      dataField: "papas",
      formatter({ totalPapas }) {
        return totalPapas?.toString() || "0";
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(account) {
        return <DataTableDropdownMenu items={menuItems(account)} />;
      },
    },
  ];

  return {
    columns,
  };
};
