import { VisitState } from "generated/types";

type VisitFilterMakerProps = {
  visitType?: string;
  dayTimeFrom?: string;
  dayTimeTo?: string;
  states?: string;
};

export const countFilters = (filters: Partial<VisitFilterMakerProps>) => {
  let count = 0;
  if (filters.visitType) count++;
  if (filters.states?.length) count++;
  if (filters.dayTimeFrom) count++;
  if (filters.dayTimeTo) count++;
  return count;
};

export function visitFilterMaker({
  visitType,
  dayTimeFrom,
  dayTimeTo,
  states,
}: VisitFilterMakerProps) {
  return {
    ...(visitType === "isVirtual"
      ? {
          isVirtual: {
            eq: true,
          },
        }
      : {}),
    ...(visitType === "isInPerson"
      ? {
          isVirtual: {
            ne: true,
          },
        }
      : {}),
    ...(dayTimeFrom || dayTimeTo
      ? {
          scheduledFor: {
            ...(dayTimeFrom ? { gte: dayTimeFrom } : {}),
            ...(dayTimeTo ? { lt: dayTimeTo } : {}),
          },
        }
      : {}),
    ...(states?.length
      ? {
          state: {
            in: states.split(",") as VisitState[],
          },
        }
      : {}),
  };
}
