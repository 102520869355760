import { gql } from "@apollo/client";

export const SEARCH_QUERY = gql`
  query searchResult($search: String!, $index: String) {
    searchResult(search: $search, index: $index) {
      data {
        ... on Papa {
          id
          fullName
          phoneNumber
          status
          dataVisibility {
            concealed
          }
          account {
            data {
              fullName
              permissions {
                admin
              }
            }
          }
          deletedAt
        }
        ... on Visit {
          id
          papa {
            data {
              fullName
              dataVisibility {
                concealed
              }
            }
          }
          account {
            data {
              fullName
            }
          }
          location {
            data {
              address
            }
          }
        }
        ... on Pal {
          id
          fullName
          phoneNumber
          email
          account {
            data {
              homeLocation {
                data {
                  address
                }
              }
            }
          }
        }
        ... on PalOnboarding {
          id
          providedFirstName
          providedLastName
          bgcStatus
          zipcode
          stage
          hasCompletedOrientationVideo
          account {
            data {
              emailVerified
              id
              email
              phoneNumber
            }
          }
        }
        ... on Business {
          id
          name
          inboundMemberSupportNumber
        }
        ... on EligibleLife {
          id
          firstName
          lastName
          phone1
          clientName
        }
        ... on VisitObjectiveTemplate {
          id
          description
        }
      }
    }
  }
`;

export const SEARCH_RESULT_BY_BUSINESS = gql`
  query searchResultByBusiness($search: String!, $index: String) {
    searchResult(search: $search, index: $index) {
      data {
        ... on Business {
          id
          name
          inboundMemberSupportNumber
        }
      }
    }
  }
`;

export const SEARCH_RESULT_BY_CAREGIVER = gql`
  query searchResultByCaregiver($search: String!) {
    searchResult(search: $search, index: "caregivers", size: 5) {
      data {
        ... on Caregiver {
          id
          fullName
          businessId
          account {
            data {
              email
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_RESULT_BY_VISITS = gql`
  query searchResultByVisits($search: String!, $index: String) {
    searchResult(search: $search, index: $index) {
      data {
        ... on Papa {
          id
          fullName
          phoneNumber
          status
          dataVisibility {
            concealed
          }
          account {
            data {
              fullName
            }
          }
          deletedAt
        }
        ... on Visit {
          id
          papa {
            data {
              fullName
              dataVisibility {
                concealed
              }
            }
          }
          account {
            data {
              fullName
            }
          }
          location {
            data {
              address
            }
          }
        }
      }
    }
  }
`;

export const ELIGIBLE_LIVES_SEARCH = gql`
  query searchEligibleLives($search: String!) {
    searchEligibleLives(search: $search) {
      data {
        id
        firstName
        lastName
        phone1
        clientName
      }
    }
  }
`;

export const ELIGIBLE_EMPLOYEES_SEARCH = gql`
  query searchEligibleEmployees($search: String!) {
    searchEligibleEmployees(search: $search) {
      data {
        id
        firstName
        lastName
        business {
          data {
            name
          }
        }
      }
    }
  }
`;
