import { gql } from "@apollo/client";

export const GET_SURVEYS = gql`
  query Surveys($filter: [SurveyFilter], $pagination: PaginationInput) {
    surveys(filter: $filter, pagination: $pagination) {
      data {
        id
        name
        url
        isActive
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;

export const CREATE_SURVEY = gql`
  mutation CreateSurvey($input: SurveyInput!) {
    createSurvey(input: $input) {
      data {
        name
        url
      }
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UpdateSurvey($id: ID!, $input: SurveyInput!) {
    updateSurvey(id: $id, input: $input) {
      data {
        name
        url
      }
    }
  }
`;
