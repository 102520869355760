import { useField } from "formik";
import React from "react";
import styled from "styled-components";

import Button from "components/Button";
import Conditional from "components/Conditional";
import Flex from "components/Flex";
import Feedback from "components/Form/FormFeedback";

interface DurationButtonsRadiosProps {
  maxDuration?: number;
  error?: string | null;
}

type BuildDurationHoursOption = {
  value: number;
  quant: string;
  text: string;
};

export const DurationButtonsRadios = ({ maxDuration = 600, error }: DurationButtonsRadiosProps) => {
  const [{ value: fieldValue }, , helpers] = useField("estimatedDuration");

  const handleClick = (duration: number) => () => {
    if (duration !== fieldValue) {
      helpers.setValue(duration);
    }
  };

  const buildDefaultDurationOptions = (
    accumulator: number,
    options: Array<BuildDurationHoursOption>
  ): Array<BuildDurationHoursOption> => {
    if (accumulator >= maxDuration) return options;
    accumulator += 60;
    const hours = Math.floor(accumulator / 60);
    const unit = hours === 1 ? "hour" : "hours";
    return buildDefaultDurationOptions(accumulator, [
      ...options,
      {
        value: accumulator,
        quant: `${hours}`,
        text: unit,
      },
    ]);
  };

  const defaultDurationOptions = buildDefaultDurationOptions(0, []);

  return (
    <Flex
      flexDirection="column"
      gridGap="1rem"
      justifyContent="center"
      marginTop="1.4rem"
      alignItems="start"
    >
      {defaultDurationOptions?.map(({ value, quant, text }) => (
        <DurationButton
          key={value}
          type="button"
          borderWidth="1px"
          spanSpacing="0.35em"
          onClick={handleClick(value)}
          isSelected={value === fieldValue}
          aria-label={`${quant} ${text}`}
        >
          <div>
            <span>{quant}</span>
            <HourOnButton>{text}</HourOnButton>
          </div>
        </DurationButton>
      ))}
      <Conditional show={!!error}>
        <Feedback isInvalid>{error}</Feedback>
      </Conditional>
    </Flex>
  );
};

const DurationButton = styled(Button)`
  min-width: 7rem;
`;

const HourOnButton = styled.span`
  font-weight: normal;
`;
