import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { ELIGIBLE_LIVES_SEARCH } from "components/Search/gql";
import { EligibleLife } from "generated/types";
import { SearchResult } from "types/search";

export const useEligibleLivesSearch = (data: EligibleLife[]) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: ELIGIBLE_LIVES_SEARCH,
        variables: {
          search: value,
        },
      });

      if (data?.searchEligibleLives?.data) {
        return data.searchEligibleLives.data
          .filter(({ deletedAt }: SearchResult) => !deletedAt)
          .map(({ id, firstName, lastName, phone1, clientName }: SearchResult) => {
            return {
              value: id,
              label: `${firstName} ${lastName}`,
              phone: phone1,
              member: clientName,
              name: `${firstName} ${lastName}`,
            };
          });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };
  const searchPillNames = data?.reduce(
    (obj, { id, firstName, lastName }) => ({
      ...obj,
      [id!]: `${firstName} ${lastName}`,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
