/* istanbul ignore file */
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";

import config, { AppConfig } from "config";

import App from "./App";
import { mswSetup } from "./mswSetup";

async function main() {
  if (config.featureFlag.useMSW) {
    await mswSetup();
  }

  Sentry.init({
    dsn: "https://577b0c93b53f4d4da1d9595562c10f73@o239484.ingest.sentry.io/6742668",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
    release: "admin@" + config.version,
    environment: AppConfig("ADMIN_ENV"),
    enabled: AppConfig("ADMIN_ENV") === "production",
    autoSessionTracking: false,
    ignoreErrors: ["Failed to fetch", "You must be authenticated to perform this action."],
  });

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
}

main();
