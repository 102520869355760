import { Formik, Form as FormikForm } from "formik";
import queryString, { Stringifiable } from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import Form from "components/Form";
import {
  Container,
  FilterTitle,
  FilterType,
  Footer,
  StyledFormGroup,
} from "components/Modal/FilterModal.styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import { useSearch } from "components/Search/useSearch";
import Select from "components/Select";
import { vehicleInsuranceStatusOptions } from "constants/vehicle";
import { usePageInfo } from "hooks/usePageInfo";

interface FormValues {
  vehicleInsuranceStatus: string | null;
}

interface Props {
  onSubmit: () => void;
}

export const ProspectPalFilterModal: React.FC<Props> = ({ onSubmit }) => {
  const history = useHistory();
  const { vehicleInsuranceStatus } = usePageInfo();
  const { pills } = useSearch();

  const handleSubmit = async (values: FormValues) => {
    const { vehicleInsuranceStatus } = values;

    const query: Record<
      string,
      string | number | boolean | readonly Stringifiable[] | null | undefined
    > = {
      page: 1,
      ...(vehicleInsuranceStatus ? { vehicleInsuranceStatus } : {}),
      ...(pills?.[0]?.id ? { search: pills?.[0]?.id } : {}),
    };
    history.push(`?${queryString.stringify(query)}`);
    onSubmit();
  };

  const initialValues = {
    vehicleInsuranceStatus: vehicleInsuranceStatus ?? "",
  };

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm, values }) => (
        <FormikForm>
          <Container>
            <ModalContainer>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Vehicle Insurance Status</FilterType>
                    </FilterTitle>
                    <Select
                      name="vehicleInsuranceStatus"
                      placeholder="Select insurance Status"
                      isSearchable={false}
                      options={vehicleInsuranceStatusOptions}
                    />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
            </ModalContainer>

            <Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm({
                    values: {
                      vehicleInsuranceStatus: "",
                    },
                  });
                }}
              >
                Clear Filters
              </Button>
              <Button type="submit" variant="primary">
                Apply
              </Button>
            </Footer>
          </Container>
        </FormikForm>
      )}
    </Formik>
  );
};
