import styled, { DefaultTheme, css } from "styled-components";

import {
  PaymentInvoiceState,
  PaymentState,
  TipaltiDisbursementStatus,
  VisitState,
} from "generated/types";
import { ConductStatusLegend } from "types";

const getKeyValue =
  <T extends object, U extends keyof T>(obj: T) =>
  (key: U) =>
    obj[key];

export const getStateTextColor = ({
  variant,
  theme,
  statusLegend,
  conductStatusLegend,
  paymentStatusLegend,
  paymentInvoiceStateLegend,
  tipaltiDisbursementStatusLegend,
}: StateTextProps) => {
  if (statusLegend) return getKeyValue(theme.statusLegend)(statusLegend);
  if (conductStatusLegend) return getKeyValue(theme.conductStatusLegend)(conductStatusLegend);
  if (paymentStatusLegend) return getKeyValue(theme.paymentStatusLegend)(paymentStatusLegend);
  if (paymentInvoiceStateLegend)
    return getKeyValue(theme.paymentInvoiceStateLegend)(paymentInvoiceStateLegend);
  if (tipaltiDisbursementStatusLegend)
    return getKeyValue(theme.tipaltiDisbursementStatusLegend)(tipaltiDisbursementStatusLegend);
  if (variant === "success") return theme.variants.success;
  if (variant === "danger") return theme.variants.danger;
  if (variant === "warning") return theme.variants.warning;
};

export const getStateTextWeight = ({
  variant,
  statusLegend,
  paymentStatusLegend,
  tipaltiDisbursementStatusLegend,
}: StateTextProps) =>
  variant || statusLegend || paymentStatusLegend || tipaltiDisbursementStatusLegend
    ? "bold"
    : "normal";

export type RowProps = {
  variant?: "success" | "warning" | "danger";
  statusLegend?: VisitState | null;
  conductStatusLegend?: ConductStatusLegend;
  paymentStatusLegend?: PaymentState;
  paymentInvoiceStateLegend?: PaymentInvoiceState;
  tipaltiDisbursementStatusLegend?: TipaltiDisbursementStatus | null;
  cellPosition?: "static" | "absolute";
  linkTo?: string;
};

const StyledRow = styled.tr<RowProps>`
  color: ${({ theme }) => theme.text.muted};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ linkTo }) =>
    !!linkTo &&
    css`
      box-shadow: 0;

      transition: box-shadow 0.2s ease-in-out;

      &.hover-style {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }

      &:active {
        transition-delay: 0;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      }
    `}

  & > td:first-of-type {
    position: ${({ cellPosition }) => cellPosition || "relative"};
    color: ${getStateTextColor};
    font-weight: ${getStateTextWeight};
    padding-left: calc(0.938rem + 0.5rem);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: ${({ theme }) => `${theme.borderRadius} 0 0 ${theme.borderRadius} `};
      height: 100%;
      width: 0.5rem;
      background: ${({
        variant,
        theme,
        statusLegend,
        conductStatusLegend,
        paymentStatusLegend,
        paymentInvoiceStateLegend,
        tipaltiDisbursementStatusLegend,
      }) => {
        if (statusLegend) return getKeyValue(theme.statusLegend)(statusLegend);
        if (conductStatusLegend) return getKeyValue(theme.conductStatusLegend)(conductStatusLegend);
        if (paymentStatusLegend) return getKeyValue(theme.paymentStatusLegend)(paymentStatusLegend);
        if (paymentInvoiceStateLegend)
          return getKeyValue(theme.paymentInvoiceStateLegend)(paymentInvoiceStateLegend);
        if (tipaltiDisbursementStatusLegend)
          return getKeyValue(theme.tipaltiDisbursementStatusLegend)(
            tipaltiDisbursementStatusLegend
          );
        if (variant === "success") return theme.variants.success;
        if (variant === "danger") return theme.variants.danger;
        if (variant === "warning") return theme.variants.warning;
        return "transparent";
      }};
    }
  }
`;

type StateTextProps = Partial<RowProps> & {
  theme: DefaultTheme;
};

export default StyledRow;
