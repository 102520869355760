import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";

import { CompleteVisitForm } from "./CompleteVisitForm";

interface Props {
  visitId: string;
  isOpen: boolean;
  toggle: () => void;
}

export const CompleteVisitModal = ({ visitId, isOpen, toggle }: Props) => {
  return (
    <ModalLayout toggle={toggle} title="Complete Visit" isOpen={isOpen}>
      <CompleteVisitForm visitId={visitId} isOpen={isOpen} toggle={toggle} />
    </ModalLayout>
  );
};
