import { useQuery } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import includes from "lodash/includes";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import PapaActionsMenu from "components/ActionsMenu/PapaActionsMenu";
import Actions from "components/ActionsMenu/PapaActionsMenu/Actions";
import { PapaMenuActions } from "components/ActionsMenu/PapaActionsMenu/models";
import { usePapaActions } from "components/ActionsMenu/PapaActionsMenu/usePapaActions";
import Button from "components/Button";
import Flex, { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import config from "config";
import { FeatureFlags } from "constants/featureFlags";
import { BusinessType, Papa, PapaSingleResult, usePapaOverviewQuery } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import usePagination from "hooks/usePagination";
import { CreateServiceRequestModal } from "modals/CareConcierge/CreateServiceRequest";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";

import { GET_PAPA_DETAILS } from "../gql";
import Activity from "./Activity";
import Assessments from "./Assessments";
import ServiceRequestDetail from "./CareConcierge/Details/ServiceRequestDetail";
import ServiceRequests from "./CareConcierge/List";
import CreditCards from "./CreditCards/CreditCards";
import Details from "./Details";
import Overview from "./Overview";
import Visits from "./Visits";

type Props = {
  id: string;
};

const PapaDetails = ({ match }: RouteComponentProps<Props>) => {
  const { currentPage, setPage, currentLimit } = usePagination({ page: 1, limit: 25 });
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const { id } = match.params;
  const { isAdmin, isSupervisor, isCareConciergeSpecialist } = useCurrentAccountRole();
  const { action, setAction } = usePapaActions();
  const careConciergeMatch = useRouteMatch("/papas/:id/care-concierge");
  const underCareConciergeTag = careConciergeMatch !== null;

  const { data, loading, error, refetch } = useQuery<{ papa: PapaSingleResult }>(GET_PAPA_DETAILS, {
    variables: { id },
  });

  const {
    data: overviewData,
    loading: overviewLoading,
    error: overviewError,
    refetch: refetchOverview,
  } = usePapaOverviewQuery({
    variables: { papaId: id },
  });
  const papa = data?.papa.data;

  const isDhlBusiness = papa?.business?.data?.isDhl || false;

  const papaConcealedEntity = papaConcealed(data?.papa.data);

  const papaOverview = overviewData?.papa?.data as Papa;
  const allotmentPeriods = papaOverview?.allotmentPeriods?.data;

  const currentBusinessPolicy = papa?.currentBusinessPolicy?.data;

  const isCareConciergeEnabled = !!currentBusinessPolicy?.careConciergeEnabled;
  const isHealthPlanMember = includes(
    [BusinessType.Medicaid, BusinessType.MedicareAdvantage],
    papa?.business?.data?.type
  );

  useTopBarOptions({
    title: "Papa Details",
    name: papaConcealedEntity?.fullName ?? "",
    icon: AccountCircle,
    breadcrumbs: ["Accounts", { label: "All Papas", link: "/papas" }],
    deps: [papaConcealedEntity?.fullName],
  });

  const handleRefetch = async () => {
    await refetch();
  };
  const closeModal = () => {
    setAction(PapaMenuActions.noAction);
  };
  const createServiceRequest = () => {
    setAction(PapaMenuActions.CreateServiceRequest);
  };

  const handleClickEdit = () => {
    setAction(PapaMenuActions.setStatus, papaConcealedEntity);
  };

  const handleAddLocation = () => {
    setAction(PapaMenuActions.addLocation, papaConcealedEntity);
  };

  const visitTabLabel =
    useIsFeatureEnabled(FeatureFlags.AllotmentHours) && !isDhlBusiness
      ? "Hours and visits"
      : "Visits";

  return (
    <>
      <Container>
        <Overview
          error={overviewError}
          loading={overviewLoading}
          papa={papaOverview}
          onClickEdit={handleClickEdit}
          refetch={refetchOverview}
        />
        <Main>
          {papaConcealedEntity?.isTestPapa ? (
            <TestPapaBanner>
              This is a test account. Please contact Product or Engineering for questions.
            </TestPapaBanner>
          ) : null}
          <Flex justifyContent="space-between" marginBottom="1.5rem" alignItems="center">
            <Nav noMargin>
              <Nav.Link to={`/papas/${id}/details`}>Details</Nav.Link>
              <Nav.Link to={`/papas/${id}/visits`}>{visitTabLabel}</Nav.Link>
              <Nav.Link to={`/papas/${id}/activity`}>Activity</Nav.Link>
              <Nav.Link to={`/papas/${id}/call-log`}>Call log</Nav.Link>
              <Nav.Link to={`/papas/${id}/assessments`}>Assessments</Nav.Link>
              {config.featureFlag.papaCreditCard && !hideCreditCardFields && (
                <Nav.Link to={`/papas/${id}/credit-cards`}>Credit cards</Nav.Link>
              )}
              {(isCareConciergeSpecialist || isAdmin || isSupervisor) && isCareConciergeEnabled && (
                <Nav.Link to={`/papas/${id}/care-concierge`} onClick={() => setPage(1)}>
                  Service requests
                </Nav.Link>
              )}
            </Nav>
            {papaConcealedEntity ? (
              <Flex paddingHorizontal="0.5rem">
                <PapaActionsMenu papa={papaConcealedEntity} setAction={setAction} />
              </Flex>
            ) : (
              ""
            )}
            {underCareConciergeTag &&
              isCareConciergeEnabled &&
              (isCareConciergeSpecialist || isAdmin || isSupervisor) && (
                <FlexRow justifyContent="flex-end">
                  <Button action onClick={createServiceRequest}>
                    New request
                  </Button>
                </FlexRow>
              )}
          </Flex>
          <Switch>
            <Route exact path="/papas/:id/details">
              <Details
                papa={papa}
                loading={loading}
                error={error}
                onRefetch={handleRefetch}
                onAddLocationClick={handleAddLocation}
              />
            </Route>
            <Route exact path="/papas/:id/visits">
              <Visits
                allotmentPeriods={allotmentPeriods}
                businessPolicy={currentBusinessPolicy}
                isHealthPlanMember={isHealthPlanMember}
                isDhlBusiness={isDhlBusiness}
              />
            </Route>
            <Route exact path="/papas/:id/activity" component={Activity} />
            <Route exact path="/papas/:id/assessments" component={Assessments} />
            {hideCreditCardFields ? null : (
              <Route exact path="/papas/:id/credit-cards" component={CreditCards} />
            )}
            <Route
              exact
              path="/papas/:id/care-concierge"
              render={() =>
                (isCareConciergeSpecialist || isAdmin || isSupervisor) &&
                isCareConciergeEnabled && (
                  <ServiceRequests
                    currentPage={currentPage}
                    currentLimit={currentLimit}
                    setPage={setPage}
                  />
                )
              }
            />
            <Route
              exact
              path="/papas/:id/care-concierge/:serviceId"
              render={() =>
                isCareConciergeSpecialist && isCareConciergeEnabled && <ServiceRequestDetail />
              }
            />
            <Redirect from="/papas/:id" to="/papas/:id/details" />
          </Switch>
        </Main>
        {!!papaConcealedEntity && (
          <Actions
            papa={papaConcealedEntity}
            action={action}
            closeModal={() => setAction(PapaMenuActions.noAction)}
            onRefetch={handleRefetch}
          />
        )}
        <CreateServiceRequestModal
          papaId={id}
          isOpen={action === PapaMenuActions.CreateServiceRequest}
          toggle={closeModal}
        />
      </Container>
    </>
  );
};

const Container = styled("div")`
  display: flex;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

const TestPapaBanner = styled("div")`
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
`;

export default PapaDetails;
