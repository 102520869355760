import { useField, useFormikContext } from "formik";
import React from "react";
import Async, { Props as AsyncProps } from "react-select/async";
import { OptionProps } from "react-select/src/components/Option";
import { OptionTypeBase, ValueType } from "react-select/src/types";
import styled, { css } from "styled-components";

import { FlexRow } from "components/Flex";
import Feedback from "components/Form/FormFeedback";
import { customStyles } from "components/Select";
import { Wrapper } from "components/Select/SelectWrapper";
import Text from "components/Text";

export interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

export interface Props extends Partial<AsyncProps<any>> {
  name: string;
  placeholder?: string;
  onLoadOptions: (value: string) => Promise<ChangeSelectEvent[]>;
  onSelect?: (value: ValueType<ChangeSelectEvent>) => void;
  "aria-label"?: string;
  isMulti?: boolean;
}

type ItemProps = {
  isSelected: boolean;
};

const Item = styled.div<ItemProps>`
  padding: 0.5rem 0.25rem 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${Text} {
    color: ${({ theme }) => theme.text.main};
  }

  ${FlexRow} {
    margin-bottom: 0;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.background.primaryTint};

      ${Text} {
        color: ${({ theme }) => theme.text.panelHeader};
      }
    `}

  &:hover {
    background-color: ${({ theme }) => theme.background.primaryTint};

    ${Text} {
      color: ${({ theme }) => theme.text.panelHeader};
    }
  }
`;

const Options = (props: OptionProps<ChangeSelectEvent>) => (
  <Item {...props.innerProps} isSelected={props.isSelected}>
    <FlexRow style={{ gap: 5 }}>
      <Text as="span">{props.children}</Text>
      {!!props.data.accountName && (
        <Text as="span" color="muted" size="small">
          ({props.data.accountName})
        </Text>
      )}
    </FlexRow>
  </Item>
);

const UserSelect: React.FC<Props> = ({
  onLoadOptions,
  name,
  placeholder = "Search users...",
  onSelect,
  isMulti = false,
  customStyles: customStylesInput,
  ...otherProps
}) => {
  const [, { touched, error }, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  const handleChange = (value: ValueType<ChangeSelectEvent>) => {
    if (value) {
      const computedValue = isMulti
        ? (value as ChangeSelectEvent)?.map(({ value }: { value: string }) => value) || null
        : (value as ChangeSelectEvent).value || "";

      helpers.setValue(computedValue);
    } else {
      helpers.setValue(null);
    }

    if (onSelect) {
      onSelect(value);
    }
  };

  const loadOptions = (value = "") => onLoadOptions(value);

  const isInvalid = touched && !!error;

  return (
    <Wrapper isInvalid={isInvalid}>
      <Async
        aria-label="users search"
        placeholder={placeholder}
        loadOptions={loadOptions}
        onChange={handleChange}
        // @ts-ignore TODO:remove this
        styles={customStylesInput ?? customStyles}
        isDisabled={isSubmitting}
        components={{ Option: Options }}
        isMulti={isMulti}
        className="select"
        classNamePrefix="select"
        {...otherProps}
      />
      {isInvalid && <Feedback isInvalid>{error}</Feedback>}
    </Wrapper>
  );
};

export default UserSelect;
