import { Formik, Form as FormikForm } from "formik";
import moment, { Moment } from "moment-timezone";
import queryString, { Stringifiable } from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import Form from "components/Form";
import InputDateRange from "components/InputDateRange";
import {
  CheckboxContainer,
  Container,
  FilterTitle,
  FilterType,
  Footer,
  StyledFormGroup,
} from "components/Modal/FilterModal.styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import { tipaltiDisbursementStatusOptions } from "constants/tipaltidisbursements";
import { TipaltiDisbursementStatus } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

interface FormValues {
  status: TipaltiDisbursementStatus[] | null;
  dateRange: {
    endDate: Moment | string | null;
    startDate: Moment | string | null;
  };
  papa: string | null;
}

interface Props {
  onSubmit: () => void;
}

export const TipaltiDisbursementsFilterModal: React.FC<Props> = ({ onSubmit }) => {
  const history = useHistory();

  const { page, limit, ...tipaltiDisbursementsFilters } = usePageInfo();

  const handleSubmit = async (values: FormValues) => {
    const { status, dateRange, papa } = values;

    const query: Record<
      string,
      string | number | boolean | readonly Stringifiable[] | null | undefined
    > = {
      page: 1,
      ...(status?.length ? { status: status.join(",") } : {}),
      ...(dateRange.startDate ? { dayFrom: moment(dateRange.startDate).toISOString() } : {}),
      ...(papa ? { papa } : {}),
    };
    if (dateRange.endDate) {
      query.dayTo = moment(dateRange.endDate).toISOString();
    }

    history.push(`?${queryString.stringify(query)}`);

    onSubmit();
  };

  const initialValues = {
    status: tipaltiDisbursementsFilters.status
      ? (tipaltiDisbursementsFilters.status.split(",") as TipaltiDisbursementStatus[])
      : [],
    dateRange: {
      startDate: tipaltiDisbursementsFilters.dayFrom
        ? moment(tipaltiDisbursementsFilters.dayFrom)
        : null,
      endDate: tipaltiDisbursementsFilters.dayTo ? moment(tipaltiDisbursementsFilters.dayTo) : null,
    },
    papa: tipaltiDisbursementsFilters.papa ?? "",
  };

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm }) => (
        <FormikForm>
          <Container>
            <ModalContainer>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Invoice Date</FilterType>
                    </FilterTitle>
                    <InputDateRange
                      id="dateRange"
                      name="dateRange"
                      isOutsideRange={() => false}
                      minimumNights={0}
                    />
                  </StyledFormGroup>
                </Form.Col>

                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Member Name</FilterType>
                    </FilterTitle>
                    <Form.Input name="papa" aria-label="papa" placeholder="Papa name" />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">Payment Status</FilterType>
                    </FilterTitle>

                    <CheckboxContainer>
                      {tipaltiDisbursementStatusOptions.map(({ label, value }) => {
                        return (
                          <div key={value}>
                            <Form.Check
                              controlId={`status-${value}`}
                              name="status"
                              value={value}
                              label={label}
                              arial-label={label}
                            />
                          </div>
                        );
                      })}
                    </CheckboxContainer>
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
            </ModalContainer>
            <Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={() =>
                  resetForm({
                    values: {
                      status: [] as TipaltiDisbursementStatus[],
                      dateRange: {
                        startDate: null,
                        endDate: null,
                      },
                      papa: "",
                    },
                  })
                }
              >
                Clear Filters
              </Button>
              <Button type="submit" variant="primary">
                Apply
              </Button>
            </Footer>
          </Container>
        </FormikForm>
      )}
    </Formik>
  );
};
