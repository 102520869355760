import { gql } from "@apollo/client";

export const GET_PAYMENTS = gql`
  query PaymentsList(
    $filter: [PaymentFilter]
    $pagination: PaginationInput
    $sorting: PaymentSorting
  ) {
    payments(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        state
        insertedAt
        approvedAt
        resolvedAt
        chargedAt
        total
        recipient {
          data {
            id
            fullName
          }
        }
        pal {
          data {
            id
            fullName
          }
        }
        visit {
          data {
            id
            papa {
              data {
                id
                fullName
                birthDate
                dataVisibility {
                  concealed
                }
              }
            }
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;

export const GET_PAYMENT_OVERVIEW = gql`
  query PaymentOverview($id: ID!) {
    payment(id: $id) {
      data {
        state
        total
      }
    }
  }
`;

export const GET_PAYMENT_BILLING_POINTS = gql`
  query PaymentBillingPoints($id: ID!) {
    payment(id: $id) {
      data {
        billingPoints {
          data {
            id
            amount
            explanation
            unit
            unitPrice
            type
            currency
            total
          }
        }
      }
    }
  }
`;

export const GET_PAYMENT_EVENTS = gql`
  query PaymentEvents($id: ID!) {
    payment(id: $id) {
      data {
        events {
          data {
            id
            type
            insertedAt
            updatedAt
            account {
              data {
                id
                fullName
              }
            }
            failure {
              code
              message
              source
              type
            }
          }
        }
      }
    }
  }
`;

export const APPROVE_PAYMENTS = gql`
  mutation approvePayments($paymentIds: [ID!]!) {
    approvePayments(ids: $paymentIds) {
      data {
        id
        state
      }
    }
  }
`;

export const RESOLVE_PAYMENTS = gql`
  mutation resolvePayments($paymentIds: [ID!]!) {
    resolvePayments(ids: $paymentIds) {
      data {
        id
        state
      }
    }
  }
`;

export const MARK_PAYMENTS_AS_CHARGED = gql`
  mutation markPaymentsAsCharged($paymentIds: [ID!]!) {
    markPaymentsAsCharged(ids: $paymentIds) {
      data {
        id
        state
      }
    }
  }
`;

export const DISAPPROVE_PAYMENTS = gql`
  mutation disapprovePayments($paymentIds: [ID!]!) {
    disapprovePayments(ids: $paymentIds) {
      data {
        id
        state
      }
    }
  }
`;

export const UNRESOLVE_PAYMENTS = gql`
  mutation unresolvePayments($paymentIds: [ID!]!) {
    unresolvePayments(ids: $paymentIds) {
      data {
        id
        state
      }
    }
  }
`;
