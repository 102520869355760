import { Formik } from "formik";
import moment from "moment-timezone";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import InputDate from "components/InputDate";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import { useOptOutVisitSuccessCallsMutation } from "generated/types";

import { Schema, schema } from "./schema";

interface Props {
  pal: { id: string; fullName: string };
  isOpen: boolean;
  toggle: () => void;
}

export const OptOutVisitSuccessCalls = ({ pal, isOpen, toggle }: Props) => {
  const [optOutVisitSuccessCalls, { loading }] = useOptOutVisitSuccessCallsMutation();

  const handleSubmit = async ({ indefinitely, optOutUntil }: Schema) => {
    try {
      const { data } = await optOutVisitSuccessCalls({
        variables: {
          palId: pal.id,
          indefinitely,
          optOutUntil: indefinitely ? undefined : moment(optOutUntil).endOf("day").toISOString(),
        },
      });

      if (data) {
        toast.success("Successfully opted out of Visit Success calls");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Opt-out of Visit Success calls" isOpen={isOpen}>
      <Formik
        initialValues={{
          indefinitely: true,
          optOutUntil: "",
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                Pal {pal.fullName} will not show up in the Pals in the Area screen.
              </Form.Group>
              <Form.Group>
                <Form.Radio
                  value={true}
                  name="indefinitely"
                  label="Indefinitely"
                  controlId="indefinitely"
                />
                <Form.Radio
                  value={false}
                  name="indefinitely"
                  label="Until end of day"
                  controlId="until end of day"
                />
              </Form.Group>
              <Form.Group>
                {!values.indefinitely && <InputDate id="optOutUntil" name="optOutUntil" />}
              </Form.Group>
            </ModalContainer>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button type="submit" disabled={loading} variant="primary">
                {loading && <SpinningIcon size={18} />}
                Save
              </Button>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};
