import { gql } from "@apollo/client";

export const GET_PAYMENT_INVOICE = gql`
  query getPaymentInvoice($paymentInvoiceId: ID!) {
    paymentInvoice(id: $paymentInvoiceId) {
      data {
        id
        amount
        state
        insertedAt
      }
    }
  }
`;

export const GET_PAYMENT_INVOICE_ITEMS = gql`
  query getPaymentInvoiceItems($paymentInvoiceId: ID!) {
    paymentInvoice(id: $paymentInvoiceId) {
      data {
        paymentInvoiceItems {
          data {
            adminDescription
            amount
            currency
            id
            insertedAt
          }
        }
      }
    }
  }
`;

export const GET_PAYMENT_INVOICE_EVENTS = gql`
  query getPaymentInvoiceEvents($paymentInvoiceId: ID!) {
    paymentInvoice(id: $paymentInvoiceId) {
      data {
        paymentInvoiceEvents {
          data {
            action
            id
            insertedAt
          }
        }
      }
    }
  }
`;
