import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import moment from "moment-timezone";
import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { confirm } from "components/Confirm";
import Form from "components/Form";
import InputDate from "components/InputDate";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";
import TimePicker from "components/TimePicker";
import { DEFAULT_TIMEZONE, TZ_FORMAT } from "constants/date";
import { VisitSingleResult } from "generated/types";
import { mergeDateAndTime } from "utils/date";
import { isDayBefore } from "utils/isDayBefore";

import { GET_VISIT, UPDATE_STATE_VISIT } from "./gql";
import { FormValues, schema } from "./schema";

interface Props {
  onEditFinish: () => void;
  visitId: string;
}

export const EditStartTime: FunctionComponent<Props> = ({ visitId, onEditFinish }) => {
  const { data, loading, error } = useQuery<{ visit?: VisitSingleResult | null }>(GET_VISIT, {
    variables: { id: visitId },
  });
  const [updateVisit] = useMutation(UPDATE_STATE_VISIT);

  const visit = data?.visit?.data;

  if (loading) return <Loader />;

  const startedAt = visit?.startedAt ? moment.tz(visit.startedAt, DEFAULT_TIMEZONE) : "";

  const initialValues: FormValues = {
    startedAtDay: startedAt ? startedAt.toISOString() : "",
    startedAtTime: startedAt ? startedAt.format("LT") : "",
  };
  const timezone = visit?.location?.data?.timezone || DEFAULT_TIMEZONE;

  const handleSubmit = async ({ startedAtDay, startedAtTime }: FormValues) => {
    const startedAt = mergeDateAndTime({
      day: startedAtDay,
      time: startedAtTime,
      timezone,
    });

    if (moment(startedAt).isAfter(moment())) {
      confirm(
        "Please select a different start time. Remember to use the timezone of the visit (indicated in the pop up). For example, if it says PST, make sure you write the start time in PST.",
        { header: "This start time is in the future!", noCancel: true }
      );
      return;
    }

    try {
      const { data } = await updateVisit({
        variables: {
          id: visitId,
          input: { startedAt },
        },
      });

      if (data?.updateVisit?.data?.id) {
        toast.success("Edit start time success!");
        onEditFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <QueryErrors error={error} />

          <InlineFormGroup>
            <InputDate
              id="startedAtDay"
              isOutsideRange={(day) => isDayBefore(day, timezone)}
              name="startedAtDay"
              isShort
            />
            <TimePicker name="startedAtTime" />
            {visit?.location?.data?.timezone && (
              <Timezone>
                {moment()
                  .tz(visit.location.data.timezone || DEFAULT_TIMEZONE)
                  .format(TZ_FORMAT)}
              </Timezone>
            )}
          </InlineFormGroup>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onEditFinish}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

const InlineFormGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .time-picker {
    width: 170px;
    .rc-time-picker-clear {
      top: 6px;
    }
  }
`;

const Timezone = styled.div`
  font-size: 16px;
  font-weight: 600;
`;
