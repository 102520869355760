import { gql } from "@apollo/client";

export const GET_PRIORITY_BONUSES = gql`
  query getPriorityBonuses($visitId: ID!) {
    visit(id: $visitId) {
      data {
        id
        priorityBonuses {
          data {
            id
            amountInCents
            reason
            insertedAt
            current
            createdByName
          }
        }
      }
    }
  }
`;
