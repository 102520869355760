import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { OktaAuth } from "@okta/okta-auth-js";
import { createLink } from "apollo-absinthe-upload-link";

import config, { AppConfig, OktaConfig } from "config";

if (!AppConfig("REACT_APP_BACKEND_API_URL")) {
  throw new Error("REACT_APP_BACKEND_API_URL is not defined");
}

if (AppConfig("ADMIN_ENV") === "production") {
  datadogRum.init({
    applicationId: AppConfig("DATADOG_APPLICATION_ID"),
    clientToken: AppConfig("DATADOG_CLIENT_TOKEN"),
    site: "datadoghq.com",
    service: "admin",
    env: AppConfig("ADMIN_ENV"),
    version: config.version,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingOrigins: [AppConfig("DATADOG_TRACING_ORIGIN")],
    useSecureSessionCookie: true,
  });

  datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken: AppConfig("DATADOG_CLIENT_TOKEN"),
    site: "datadoghq.com",
    service: "admin",
    env: AppConfig("ADMIN_ENV"),
    version: config.version,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    forwardReports: "all",
    sampleRate: 100,
    useSecureSessionCookie: true,
  });
}

export const linkErrorHandle = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (
        message === "You must be authenticated to perform this action." &&
        !window.location.href.includes("logout")
      ) {
        window.location.href = "/logout";
      }
      if (AppConfig("ADMIN_ENV") !== "production") {
        window.console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });

  if (networkError) window.console.error(`[Network error]: ${networkError}`);
});

export const authLink = setContext((_, { headers }) => {
  let token = "";

  // Don't use localStorage directly to get the access token
  // Use Okta's storage provider because the storage implementation can change
  // This also ensures access works with multiple tab token synchronization
  const authClient = new OktaAuth(OktaConfig);
  const oktaToken = authClient.getAccessToken();

  if (oktaToken) {
    token = oktaToken;
  } else if (localStorage.getItem("token")) {
    token = localStorage.getItem("token") as string;
  }

  return {
    headers: {
      "app-name": "Papa Admin",
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink = createLink({
  uri: AppConfig("REACT_APP_BACKEND_API_URL"),
});

const client = new ApolloClient({
  link: linkErrorHandle.concat(authLink.concat(uploadLink)),
  cache: new InMemoryCache({
    typePolicies: {
      BillingPoint: {
        keyFields: false,
      },
      AdditionalLocation: {
        keyFields: false,
      },
      VisitSurvey: {
        keyFields: false,
      },
    },
  }),
});

export default client;
