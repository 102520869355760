import { gql } from "@apollo/client";

export const VISIT_QUERY = gql`
  query VisitEdit($id: ID!) {
    visit(id: $id) {
      data {
        id
        state
        isVirtual
        visibleToPalApp
        scheduledFor
        estimatedDuration
        overrideDurationMins
        recurrenceId
        delivery
        favoritePalsOnly
        isUberEligible
        pal {
          data {
            id
          }
        }
        recurrence {
          data {
            ...VisitRecurrenceParts
          }
        }
        note
        memberNote
        systemNotes
        mobilityNeeds
        card {
          data {
            id
          }
        }
        objectives {
          data {
            id
            checked
            description
            isImpactProgram
          }
        }
        additionalLocations {
          data {
            id
            lat
            lng
            description
            address
            stayWithMember
            dropOff
          }
        }
        discount {
          data {
            type
            value
          }
        }
        tasks {
          data {
            id
            name
            description
            icon
          }
        }
        account {
          data {
            id
          }
        }
        papa {
          data {
            id
            fullName
            resourceBudget {
              data {
                allocations {
                  data {
                    intervalUnit
                    remainingMinutes
                    numberOfMinutes
                  }
                }
              }
            }
          }
        }
        events(filter: { action: { eq: MEMBER_CONFIRMED } }) {
          data {
            ... on VisitEvent {
              action
              reasonDescription
              visitEventParams {
                startedAt
              }
            }
          }
        }
        location {
          data {
            id
            timezone
            address
            supportOperatingHours {
              startTime
              endTime
            }
          }
        }
        destination {
          data {
            id
          }
        }
        invitations {
          data {
            id
          }
        }
        pal {
          data {
            id
          }
        }
        surveyResponses {
          data {
            survey {
              data {
                id
              }
            }
          }
        }
      }
    }
  }
  fragment VisitRecurrenceParts on VisitRecurrence {
    validUntil
    config {
      byDay
      count
      freq
    }
  }
`;

export const UPDATE_VISIT = gql`
  mutation UpdateScheduledVisit(
    $id: ID!
    $additionalLocations: [AdditionalLocationInput!]
    $applyToFutureRecurrences: Boolean
    $applyVisitProgram: Boolean
    $billableCommuteDistance: Float
    $billableVisitDistance: Float
    $bonusConfirmation: Int
    $cancellationFeeChargeable: Boolean
    $cardId: ID
    $commuteDistance: Float
    $commuteStartedAt: Datetime
    $completedAt: Datetime
    $delivery: Boolean
    $destinationId: ID
    $discount: VisitDiscountInput
    $dontBillMember: Boolean
    $estimatedDuration: Int
    $favoritePalsOnly: Boolean
    $ignoreDuplicates: Boolean
    $invitePalIds: [ID!]
    $isUberEligible: Boolean
    $isVirtual: Boolean
    $locationId: ID
    $memberNote: String
    $nonRecommendedTimeReason: NonRecommendedTimeReason
    $note: String
    $objectives: [VisitObjectiveInput!]
    $obpReason: ReasonInput
    $overrideBillableDurationMins: Int
    $overrideBusinessPolicy: Boolean
    $overrideDurationMins: Int
    $palId: ID
    $requiresSignature: Boolean
    $source: VisitSource
    $startedAt: Datetime
    $successful: Boolean
    $surveyIds: [ID!]
    $taskIds: [ID!]
    $visibleToPalApp: Boolean
    $visitDistance: Float
  ) {
    updateVisit(
      id: $id
      input: {
        additionalLocations: $additionalLocations
        applyToFutureRecurrences: $applyToFutureRecurrences
        applyVisitProgram: $applyVisitProgram
        billableCommuteDistance: $billableCommuteDistance
        billableVisitDistance: $billableVisitDistance
        bonusConfirmation: $bonusConfirmation
        cancellationFeeChargeable: $cancellationFeeChargeable
        cardId: $cardId
        commuteDistance: $commuteDistance
        commuteStartedAt: $commuteStartedAt
        completedAt: $completedAt
        delivery: $delivery
        destinationId: $destinationId
        discount: $discount
        dontBillMember: $dontBillMember
        estimatedDuration: $estimatedDuration
        favoritePalsOnly: $favoritePalsOnly
        ignoreDuplicates: $ignoreDuplicates
        invitePalIds: $invitePalIds
        isUberEligible: $isUberEligible
        isVirtual: $isVirtual
        locationId: $locationId
        memberNote: $memberNote
        nonRecommendedTimeReason: $nonRecommendedTimeReason
        note: $note
        objectives: $objectives
        obpReason: $obpReason
        overrideBillableDurationMins: $overrideBillableDurationMins
        overrideBusinessPolicy: $overrideBusinessPolicy
        overrideDurationMins: $overrideDurationMins
        palId: $palId
        requiresSignature: $requiresSignature
        source: $source
        startedAt: $startedAt
        successful: $successful
        surveyIds: $surveyIds
        taskIds: $taskIds
        visibleToPalApp: $visibleToPalApp
        visitDistance: $visitDistance
      }
    ) {
      data {
        ...VisitPartsForUpdateVisit
        pal {
          data {
            id
            fullName
            phoneNumber
          }
        }
        papa {
          data {
            id
            fullName
            gender
            birthDate
            phoneNumber
            dataVisibility {
              concealed
            }
          }
        }
        objectives {
          data {
            id
            checked
            description
          }
        }
        invitations {
          data {
            id
            fullName
            phoneNumber
          }
        }
        location {
          data {
            ...LocationPartsForEdit
          }
        }
        destination {
          data {
            ...LocationPartsForEdit
          }
        }
      }
    }
  }
  fragment VisitPartsForUpdateVisit on Visit {
    id
    note
    memberNote
    systemNotes
    state
    scheduledFor
    estimatedDuration
    insertedAt
    acceptedAt
    commuteStartedAt
    startedAt
    completedAt
    canceledAt
    terminatedAt
    delivery
    favoritePalsOnly
  }
  fragment LocationPartsForEdit on Location {
    id
    nickname
    address
    address2
    streetName
    houseNumber
    city
    state
    zipcode
    lat
    lng
    note
    description
    timezone
    insertedAt
    deletedAt
  }
`;

export const RESCHEDULE_VISIT = gql`
  mutation RescheduleVisit($id: ID!, $input: VisitRescheduleInput!) {
    rescheduleVisit(id: $id, input: $input) {
      data {
        id
        scheduledFor
        nonRecommendedTimeReason
      }
    }
  }
`;
