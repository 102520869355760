import { number, object, string } from "yup";

export const schema = object().shape({
  name: string().required("Required"),
  templates: number().required("Required").nullable(),
});

export interface FormValues {
  name: string;
  templates: number | null;
}
