import { useMutation } from "@apollo/client";
import React, { HTMLAttributes } from "react";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { Loader } from "components/Loader";
import ConfirmModal from "components/Modal/ConfirmModal";
import { SwitchButtonInput } from "components/SwitchButton";
import { useModalToggle } from "hooks/useModalToggle";

import { SET_PAPA_AUTOMATED_CALL_OPT_IN } from "./gql";

interface Props {
  papaId: string;
  enabled: boolean;
}

const Switch = ({ papaId, enabled }: Props) => {
  const { isOpen: confirmModalOpen, toggle: toggleConfirmModal } = useModalToggle();
  const [setPapaAutomatedCallOptIn, { loading }] = useMutation<
    {
      updatePapa: {
        data: {
          id: string;
          automatedCallOptIn: boolean;
        };
      };
    },
    { papaId: string; automatedCallOptIn: boolean }
  >(SET_PAPA_AUTOMATED_CALL_OPT_IN);

  const handleEnroll = async () => {
    try {
      const newValue = !enabled;

      const { data } = await setPapaAutomatedCallOptIn({
        variables: {
          papaId,
          automatedCallOptIn: newValue,
        },
      });

      if (data?.updatePapa?.data?.id) {
        toast.success(newValue ? "Automated calls enabled" : "Automated calls disabled");
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Container>
      <SwitchButton
        label={loading ? <Loader /> : "Automated Calls Enabled"}
        checked={enabled}
        disabled={loading}
        onClick={toggleConfirmModal}
      />

      <ConfirmModal
        isOpen={confirmModalOpen}
        onCancel={toggleConfirmModal}
        onConfirm={() => {
          handleEnroll();
          toggleConfirmModal();
        }}
        header="Please Confirm"
      >
        {enabled
          ? "Are you sure you want to disable automated calls for the member?"
          : "Are you sure you want to enable automated calls for the member?"}
      </ConfirmModal>
    </Container>
  );
};

type SwitchButtonProps = {
  label: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const SwitchButton = ({ label, checked, disabled, ...inputProps }: SwitchButtonProps) => {
  return (
    <>
      <Label disabled={disabled}>
        <SwitchButtonInput readOnly {...inputProps} checked={checked} disabled={disabled} />
        <Text>{label}</Text>
      </Label>
    </>
  );
};

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  padding-top: 10px;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.text.secondary};
    `}
`;

const Text = styled.span`
  margin-left: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default Switch;
