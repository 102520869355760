import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";

import { OverrideLocationForm } from "./OverrideLocationForm";

interface Props {
  locationId: string;
  isOpen: boolean;
  toggle: () => void;
}

const OverrideLocation = ({ locationId, isOpen, toggle }: Props) => {
  return (
    <ModalLayout toggle={toggle} title="Override Location" isOpen={isOpen}>
      <OverrideLocationForm locationId={locationId} onClose={toggle} />
    </ModalLayout>
  );
};

export default OverrideLocation;
