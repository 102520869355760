import { useQuery } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";

import MemberActionsMenu from "components/ActionsMenu/MemberActionMenu";
import Actions from "components/ActionsMenu/MemberActionMenu/Actions";
import { useMemberActions } from "components/ActionsMenu/MemberActionMenu/useMemberActions";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import { FeatureFlags } from "constants/featureFlags";
import { AccountSingleResult, BusinessListResult } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";

import { GET_BUSINESS_FROM_ACCOUNT, GET_MEMBER_OVERVIEW } from "../gql";
import Activity from "./Activity/Activity";
import CreditCards from "./CreditCards";
import Devices from "./Devices";
import Information from "./Information";
import Overview from "./Overview";
import Papas from "./Papas";
import PapasEnrolled from "./PapasEnrolled";
import StatusLog from "./StatusLog";
import Vehicle from "./Vehicle";
import Visits from "./Visits";
import VisitsScheduled from "./VisitsScheduled";

type Props = {
  id: string;
};

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

const Details = ({ match }: RouteComponentProps<Props>) => {
  const { kebabCased } = useCallItClientProgramOrBusiness();
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const { id } = match.params;
  const currentAccount = useCurrentAccount();
  const { isAdmin } = useCurrentAccountRole();
  const { action, setAction } = useMemberActions();
  const { data, loading, error, refetch } = useQuery<{ account: AccountSingleResult }>(
    GET_MEMBER_OVERVIEW,
    {
      variables: { id: id },
    }
  );
  const { data: businessData } = useQuery<{ businesses: BusinessListResult }>(
    GET_BUSINESS_FROM_ACCOUNT,
    {
      variables: {
        accountId: id,
      },
    }
  );
  const history = useHistory();

  const member = data?.account.data;

  useTopBarOptions({
    title: "Member Details",
    name: member?.fullName ?? "",
    icon: AccountCircle,
    breadcrumbs: ["Accounts", { label: "All Members", link: "/members" }],
    deps: [member?.fullName],
  });

  useEffect(() => {
    if (businessData?.businesses?.data?.[0]?.id) {
      history.replace(`/${kebabCased.pluralEntityName}/${businessData.businesses.data[0].id}`);
    }
  }, [businessData, history, kebabCased.pluralEntityName]);

  const handleRefetch = async () => {
    await refetch();
  };

  const isHavePalAssociated = !!data?.account?.data?.pal?.data?.id;

  return (
    <>
      <Container>
        <Overview member={member} loading={loading} error={error} onRefetch={handleRefetch} />

        <Main>
          <FlexRow>
            <Nav>
              <Nav.Link to={`/members/${id}/details`}>Details</Nav.Link>
              {isHavePalAssociated && <Nav.Link to={`/members/${id}/activity`}>Activity</Nav.Link>}
              <Nav.Link to={`/members/${id}/vehicle`}>Vehicle</Nav.Link>
              <Nav.Link to={`/members/${id}/visits`}>Visits</Nav.Link>
              <Nav.Link to={`/members/${id}/papas`}>Papas</Nav.Link>
              {!hideCreditCardFields && (
                <Nav.Link to={`/members/${id}/credit-cards`}>Credit Cards</Nav.Link>
              )}
              <Nav.Link to={`/members/${id}/statuslog`}>Status log</Nav.Link>
              <Nav.Link to={`/members/${id}/devices`}>Devices</Nav.Link>
              {id === currentAccount?.id && isAdmin && (
                <>
                  <Nav.Link to={`/members/${id}/visits-scheduled`}>Visits Scheduled</Nav.Link>
                  <Nav.Link to={`/members/${id}/papas-enrolled`}>Papas Enrolled</Nav.Link>
                </>
              )}
            </Nav>
            {member && <MemberActionsMenu member={member} setAction={setAction} />}
          </FlexRow>

          <Switch>
            <Route exact path="/members/:id/details" component={Information} />
            {isHavePalAssociated && (
              <Route exact path="/members/:id/activity" component={Activity} />
            )}
            <Route exact path="/members/:id/vehicle" component={Vehicle} />
            <Route exact path="/members/:id/visits" component={Visits} />
            <Route exact path="/members/:id/statuslog" component={StatusLog} />
            <Route exact path="/members/:id/devices" component={Devices} />
            <Route exact path="/members/:id/papas" component={Papas} />
            {!hideCreditCardFields && (
              <Route exact path="/members/:id/credit-cards" component={CreditCards} />
            )}
            <Route exact path="/members/:id/visits-scheduled" component={VisitsScheduled} />
            <Route exact path="/members/:id/papas-enrolled" component={PapasEnrolled} />
            <Redirect from="/members/:id" to="/members/:id/details" />
          </Switch>
        </Main>
        {member && (
          <Actions
            action={action}
            account={member}
            onClose={() => setAction(DataTableMenuActions.NoAction)}
            onRefetch={handleRefetch}
          />
        )}
      </Container>
    </>
  );
};

export default Details;
