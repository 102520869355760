import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { DropdownActionMenu } from "components/ActionsMenu/DropdownActionMenu";
import { ArrowToggle } from "components/ArrowToggle";
import Button from "components/Button";
import { confirm } from "components/Confirm";
import Dropdown from "components/Dropdown";
import Heading from "components/Heading";
import { Overflow } from "components/Overflow";
import SmartTable from "components/SmartTable";
import { palComplaintReasonOptions } from "constants/complaint";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { PalComplaint, PalSingleResult, useDeletePalComplaintMutation } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountPermission } from "hooks/useCurrentAccountPermission";
import { useModalToggle } from "hooks/useModalToggle";
import CreatePalComplaintModal from "modals/CreatePalComplaintModal";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";

import { GET_PAL_COMPLAINTS } from "./gql";

interface Data {
  pal: PalSingleResult;
}

const Complaints = () => {
  const { kebabCased } = useCallItClientProgramOrBusiness();
  const { id } = useParams<{ id: string }>();
  const { isOpen: createPalComplaintModalOpen, toggle: toggleCreatePalComplaintModal } =
    useModalToggle();

  const showCreateComplaintButton = useCurrentAccountPermission(["CAN_CREATE_COMPLAINT"]);

  const { data, loading, error, refetch } = useQuery<Data>(GET_PAL_COMPLAINTS, {
    variables: { id },
  });

  const [deletePalComplaint] = useDeletePalComplaintMutation();

  const complaints = data?.pal?.data?.palComplaints?.data?.filter(
    (palComplaint) => palComplaint && !palComplaint.softDeletedAt
  );

  return (
    <>
      <CreatePalComplaintModal
        palId={id}
        isOpen={createPalComplaintModalOpen}
        toggle={toggleCreatePalComplaintModal}
        onCreateFinish={() => refetch()}
      />

      <Heading>Complaints</Heading>
      {showCreateComplaintButton && (
        <Button variant="danger" action onClick={toggleCreatePalComplaintModal}>
          Add complaint
        </Button>
      )}

      <SmartTable<PalComplaint>
        keyField="id"
        data={complaints}
        loading={loading}
        error={error}
        columns={[
          {
            header: { title: "Reason" },
            dataField: "reason",
            formatter({ reason }) {
              return reason
                ? mapValueToLabel(palComplaintReasonOptions, reason)
                : EMPTY_PLACEHOLDER;
            },
          },
          {
            header: { title: "Comment" },
            dataField: "comment",
            formatter({ comment }) {
              return <Comment comment={comment} />;
            },
          },
          {
            header: { title: "Timestamp" },
            dataField: "insertedAt",
            formatter({ insertedAt }) {
              return formatDateTime(insertedAt);
            },
          },
          {
            header: { title: "Created by" },
            dataField: "insertedBy",
            formatter({ insertedBy }) {
              return (
                <Link
                  to={
                    insertedBy?.data?.type === "BUSINESS"
                      ? `/${kebabCased.pluralEntityName}/${insertedBy?.data?.id}`
                      : `/members/${insertedBy?.data?.id}`
                  }
                >
                  {insertedBy?.data?.fullName}
                </Link>
              );
            },
          },
          {
            header: { title: "" },
            dataField: "actions",
            cellProps: {
              fixed: true,
              align: "center",
            },
            formatter({ id }) {
              return (
                <DropdownActionMenu isListMenu>
                  <Dropdown.Item
                    onClick={async () => {
                      if (!(await confirm("Are you sure you want to delete this complaint?")))
                        return;

                      try {
                        const { data } = await deletePalComplaint({
                          variables: {
                            id: id!,
                          },
                        });

                        if (data?.deletePalComplaint?.data?.softDeletedAt) {
                          toast.success("Complaint deleted successfully!");
                          refetch();
                        } else {
                          throw Error("Something is wrong");
                        }
                      } catch (error) {
                        toast.error((error as Error).message);
                      }
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </DropdownActionMenu>
              );
            },
          },
        ]}
      />
    </>
  );
};

const CommentContainer = styled.div<{ noWrap: boolean }>`
  max-width: 400px;
  position: relative;
  text-overflow: ellipsis;
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
  overflow: hidden;
  padding-right: 30px;
`;

const Comment = ({ comment }: { comment?: string | null }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <Overflow
      container={CommentContainer}
      noWrap={isCollapsed}
      render={({ isOverflowed }) => (
        <>
          {comment}
          {isOverflowed && (
            <ArrowToggle onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed} />
          )}
        </>
      )}
    />
  );
};

export default Complaints;
