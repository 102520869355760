import { useFormikContext } from "formik";
import React, { useEffect } from "react";

interface Props {
  onChange: any;
}

export const FormikValueObserver: React.FC<Props> = ({ onChange }) => {
  const { values, initialValues } = useFormikContext();

  useEffect(() => onChange(values, initialValues), [values, initialValues, onChange]);

  return null;
};
