import { IfFeatureEnabled } from "@growthbook/growthbook-react";
import { ChevronDown } from "@styled-icons/fa-solid/ChevronDown";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import Conditional from "components/Conditional";
import { Drawer } from "components/Drawer";
import { FlexRow } from "components/Flex";
import Form from "components/Form";
import Heading from "components/Heading";
import { StyledHeader } from "components/Panel/index";
import Text from "components/Text";
import { Experiments } from "constants/experiments";
import { FeatureFlags } from "constants/featureFlags";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { usePapaExperiment } from "hooks/usePapaExperiment";
import { allotmentHoursToggled, creditEvent } from "utils/segment";

import { BusinessPolicy, IntervalUnit, PapaAllotmentPeriod } from "../../../../generated/types";
import AddCredit from "./AddCredit";
import AllotmentPeriodSelect from "./AllotmentPeriodSelect";
import HeaderContents from "./HeaderContents";
import ShowCredits from "./ShowCredits";

interface Props {
  papaId: string;
  allotmentPeriods: Array<PapaAllotmentPeriod>;
  businessPolicy: BusinessPolicy;
  isHealthPlanMember: boolean;
}

/*
The Hours component displays an allotment hours dropdown which allows the user to select
an allotment hour and have the member's current hour usage displayed in boxes.

If the member is not a health plan member, no data is displayed in the box.
*/
const Hours: React.FC<Props> = ({
  papaId,
  allotmentPeriods,
  businessPolicy,
  isHealthPlanMember,
}) => {
  //// HOOKS ////
  const [allotmentPeriod, setAllotmentPeriod] = useState<PapaAllotmentPeriod | null>(null);

  const [isExpanded, setExpanded] = useState(true);
  const { isOpen: showCreditsDrawer, toggle: toggleCreditsDrawer } = useModalToggle();
  const { isOpen: showCreditDetailsDrawer, toggle: toggleCreditDetailsDrawer } = useModalToggle();

  const hasActiveBizPolicy = moment().isBetween(businessPolicy.startsAt, businessPolicy.endsAt);
  const toggle = () => {
    const newState = !isExpanded;
    setExpanded(newState);
    allotmentHoursToggled(newState);
  };

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded && !!hasActiveBizPolicy && !!isHealthPlanMember,
    defaultExpanded: true && !!hasActiveBizPolicy && !!isHealthPlanMember,
  });

  const { isAdmin, isGrievancesAppealsAdmin, isSuperAdmin, isMemberSupportAdmin } =
    useCurrentAccountRole();

  const isElevatedRole = isGrievancesAppealsAdmin || isSuperAdmin || isMemberSupportAdmin;

  const isAddCreditEnable = usePapaExperiment(papaId, {
    key: Experiments.AddCreditFCR,
    variations: [false, true],
    hashAttribute: "papa_id",
  });

  const routeMatch = useRouteMatch();

  const isCurrentOrFutureAP = moment().isBefore(allotmentPeriod?.endDate);

  // this is to refresh the selected allotment period after they are refetched on create/delete
  useEffect(() => {
    if (allotmentPeriod) {
      const updatedAllotmentPeriod = allotmentPeriods?.find((x) => allotmentPeriod.id === x.id);
      if (updatedAllotmentPeriod) setAllotmentPeriod(updatedAllotmentPeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allotmentPeriods, setAllotmentPeriod]);

  ///// Event Handlers /////
  const handleAllotmentPeriodChange = (ap: Pick<PapaAllotmentPeriod, "id">, label?: string) => {
    const newAllotmentPeriod = allotmentPeriods.find((x) => x.id === ap.id);
    if (newAllotmentPeriod) {
      setAllotmentPeriod(newAllotmentPeriod);
    }
  };

  const onAddCredit = () => {
    creditEvent("clicked add credit", {
      from_screen: routeMatch.path,
      button_location: "Hours UI component",
      papa_id: papaId,
    });
    toggleCreditsDrawer();
  };

  //// RENDERING ////
  // Benefit Date Range Text
  const benefitBeginDate = businessPolicy.startsAt
    ? `${moment(businessPolicy.startsAt).format("MM/DD/YYYY")}`
    : "";

  const benefitExpirationDate = businessPolicy.endsAt
    ? `${moment(businessPolicy.endsAt).format("MM/DD/YYYY")}`
    : "";

  // Interval Unit Text for Hour Cards
  const intervalUnitLabels = {
    [IntervalUnit.Quarters]: "quarter",
    [IntervalUnit.Years]: "year",
    [IntervalUnit.Months]: "month",
    [IntervalUnit.BusinessPolicy]: "benefit period",
  };

  const intervalUnitLabel = businessPolicy.intervalUnit
    ? intervalUnitLabels[businessPolicy.intervalUnit]
    : "benefit period";

  // Conditional Rendering Booleans
  const showHours = hasActiveBizPolicy && isHealthPlanMember;

  const creditButtonOnClick = () => {
    creditEvent("clicked view credits", { papa_id: papaId });
    toggleCreditDetailsDrawer();
  };

  const creditDetailsOnClose = () => {
    creditEvent("closed view credits", { papa_id: papaId });
    toggleCreditDetailsDrawer();
  };

  return (
    <Block>
      <BlockHeader isExpanded={isExpanded && !!hasActiveBizPolicy && !!isHealthPlanMember}>
        <StyledHeader {...getToggleProps({ onClick: toggle })}>
          <Heading as="h2" color="primary">
            Hours
          </Heading>
          {showHours && (
            // This parent div is needed to make the chevron rotate around its center
            // when animated
            <div>
              <Chevron isExpanded={isExpanded} data-testid="toggle">
                <ChevronDown size={18} />
              </Chevron>
            </div>
          )}
        </StyledHeader>
        <HeaderContents
          hasActiveBizPolicy={hasActiveBizPolicy}
          isHealthPlanMember={isHealthPlanMember}
        />
      </BlockHeader>

      <BlockBody {...getCollapseProps()} data-testid="body">
        <Spacer />
        <Conditional show={showHours}>
          <>
            <FlexRow justifyContent="space-between">
              <FlexRow>
                <Form.Group>
                  <Heading as="h2" color="primary">
                    Allotment period
                  </Heading>
                  <AllotmentPeriodSelect
                    allotmentPeriods={allotmentPeriods}
                    businessPolicy={businessPolicy}
                    onChange={handleAllotmentPeriodChange}
                    value={allotmentPeriod}
                  />
                </Form.Group>
              </FlexRow>
              <FlexRow marginHorizontal={"2em"} columnGap={"2em"}>
                <Form.Group>
                  <Text bold>Benefit begins</Text>
                  <Text>{benefitBeginDate}</Text>
                </Form.Group>
                <Form.Group>
                  <Text bold>Benefit expires</Text>
                  <Text>{benefitExpirationDate}</Text>
                </Form.Group>
              </FlexRow>
            </FlexRow>
            <FlexRow justifyContent="space-between">
              <FlexRow>
                <HoursCard
                  title="Available Hours"
                  minutes={allotmentPeriod?.availableMinutes || 0}
                  isShaded
                >
                  <IfFeatureEnabled feature={FeatureFlags.Credits}>
                    <Text>Credits included</Text>
                  </IfFeatureEnabled>
                </HoursCard>
              </FlexRow>
              <FlexRow>
                <HoursCard
                  title="Allotment"
                  minutes={allotmentPeriod?.allottedMinutes || 0}
                  labelPostfix={`/${intervalUnitLabel}`}
                />
                <HoursCard
                  title="Pending Hours"
                  hourPrefix="-"
                  minutes={allotmentPeriod?.pendingMinutes || 0}
                />
                <HoursCard
                  title="Used Hours"
                  hourPrefix="-"
                  minutes={allotmentPeriod?.usedMinutes || 0}
                />
                <IfFeatureEnabled feature={FeatureFlags.Credits}>
                  <HoursCard
                    title="Credit Hours"
                    hourPrefix="+"
                    minutes={allotmentPeriod?.creditedMinutes || 0}
                  >
                    <FlexRow>
                      <CreditButton size="small" onClick={creditButtonOnClick}>
                        View
                      </CreditButton>
                      <Conditional
                        show={
                          isCurrentOrFutureAP && (isElevatedRole || (isAdmin && isAddCreditEnable))
                        }
                      >
                        <CreditButton size="small" onClick={onAddCredit}>
                          Add
                        </CreditButton>
                      </Conditional>
                    </FlexRow>
                  </HoursCard>
                </IfFeatureEnabled>
              </FlexRow>
            </FlexRow>
          </>
        </Conditional>
      </BlockBody>

      <Drawer
        onClose={creditDetailsOnClose}
        title="Credit Details"
        open={showCreditDetailsDrawer}
        size="sm"
        noOverlay
        noFooter
      >
        <ShowCredits allotmentPeriod={allotmentPeriod} />
      </Drawer>

      <Drawer onClose={toggleCreditsDrawer} title="Credit" open={showCreditsDrawer} size="sm">
        <AddCredit
          papaId={papaId}
          allotmentPeriods={allotmentPeriods}
          initialAllotmentPeriod={allotmentPeriod}
          businessPolicy={businessPolicy}
          onClose={toggleCreditsDrawer}
          onAllotmentPeriodChange={handleAllotmentPeriodChange}
        />
      </Drawer>
    </Block>
  );
};

/*
The box used to display each bucket of hours (allotted, used, pending, etc)
*/
const HoursCard = ({
  title,
  hourPrefix,
  minutes,
  labelPostfix,
  isShaded,
  children,
}: {
  title: string;
  hourPrefix?: string;
  minutes: number;
  labelPostfix?: string;
  isShaded?: boolean;
  children?: React.ReactNode | [];
}) => {
  return (
    <Card role="group" aria-label={title} isShaded={!!isShaded}>
      <Text bold marginBottom={5}>
        {title}
      </Text>
      <HoursAndMinutesDisplay
        minutes={minutes}
        hourPrefix={hourPrefix}
        labelPostfix={labelPostfix}
      />
      {children}
    </Card>
  );
};

export const HoursAndMinutesDisplay = ({
  hourPrefix,
  minutes,
  labelPostfix,
}: {
  hourPrefix?: string;
  minutes: number;
  labelPostfix?: string;
}) => {
  // We pull out the negative so that we don't display a negative
  // sign on both the hours and the minutes.
  const isNegative = minutes < 0;
  const amt = Math.abs(minutes);
  const hourAmt = Math.floor(amt / 60);
  const minuteAmt = amt % 60;

  const getHourPrefix = () => {
    if (hourPrefix) {
      return hourPrefix;
    } else if (isNegative) {
      return "-";
    } else {
      return null;
    }
  };
  const hourPrefixStr = getHourPrefix();

  return (
    <HoursFlexRow>
      {hourPrefixStr && (
        <Text bold size="largex" marginRight=".4rem">
          {hourPrefixStr}
        </Text>
      )}
      <Text bold size="largex">
        {hourAmt}
      </Text>
      <Text size="largex" marginLeft={2} color="secondary">
        h
      </Text>
      <Conditional show={!!minuteAmt}>
        <>
          <Text bold size="largex" marginLeft=".4rem">
            {minuteAmt}
          </Text>
          <Text size="largex" marginLeft={2} color="secondary">
            m
          </Text>
        </>
      </Conditional>
      <Conditional show={!!labelPostfix}>
        <Text size="largex" marginLeft={2} color="secondary">
          {labelPostfix}
        </Text>
      </Conditional>
    </HoursFlexRow>
  );
};

//// STYLING ////

export const Block = styled.div`
  background: ${({ theme }) => theme.background.panelContent};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: 1.875rem;
`;

export const Spacer = styled.div`
  height: 1.25rem;
`;

export const BlockHeader = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  &:hover {
    cursor: ${({ isExpanded }) => !isExpanded && "pointer;"};
  }
`;

export const BlockBody = styled.div`
  height: 21rem;
  padding: 0 1.25rem 0 1.25rem;
  border-radius: ${({ theme }) => `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: ${({ theme }) => theme.borderColor};
`;

const Card = styled.div<{ isShaded: boolean }>`
  border: 1px solid ${({ theme }) => theme.borderColor};
  background-color: ${({ theme, isShaded }) => (isShaded ? theme.background.grayTint : "white")};
  padding: 0.75rem 0.75rem;
  width: 11.45rem;
  height: 7.25rem;

  &:not(:last-child) {
    border-right: none;
  }
`;

const Chevron = styled.div<{ isExpanded: boolean }>`
  transform: rotate(0deg);
  transition: all 0.25s ease-out;
  ${({ isExpanded }) => isExpanded && "transform: rotate(180deg);"}
`;

const HoursFlexRow = styled(FlexRow)`
  margin-bottom: 0.4rem;
`;

const CreditButton = styled(Button)`
  border: 1px solid;
`;

export default Hours;
