import { useField } from "formik";
import React from "react";
import styled from "styled-components";

import { ReactComponent as HourAllotmentReachedIcon } from "assets/images/hour-allotment-reached.svg";
import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";
import Text from "components/Text";
import config from "config";
import { usePapaHourAllotmentQuery } from "generated/types";
import { getIntervalString, minutesToHours } from "utils/helpers/formatters";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

const HourAllotmentReached: React.FC<Props> = ({ isOpen, toggle }) => {
  const [{ value: papaId }] = useField("papaId");
  const { data } = usePapaHourAllotmentQuery({
    variables: { id: papaId },
    skip: !papaId || !config.featureFlag.businessPolicy,
  });

  const { resourceBudget } = data?.papa?.data ?? {};

  const limitingTimeAllocation = resourceBudget?.data?.limitingTimeAllocation?.data;

  const {
    intervalUnit,
    remainingMinutes,
    numberOfMinutes: allocation,
  } = limitingTimeAllocation ?? {};

  return (
    <ModalLayout toggle={toggle} title="Hour Allotment Reached" isOpen={isOpen}>
      <Container>
        <HourAllotmentReachedIcon />
        <Text bold>Member has exceeded hour allotment </Text>
        <HorizontalLine />
        <Text>
          This member has exceeded hour allotment, however you can still schedule a visit for them
          by overriding business policies. If you think this is not correct, please contact a
          supervisor.
        </Text>
        {remainingMinutes != null && allocation != null && intervalUnit && (
          <Text>
            For this {getIntervalString(intervalUnit)}, the member has already used{" "}
            {minutesToHours(allocation - remainingMinutes)} hours out of the allowed{" "}
            {minutesToHours(allocation)}.
          </Text>
        )}
        <Footer>
          <Button type="button" variant="secondary" onClick={toggle}>
            Close
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px 34px;
  width: 100%;
  svg {
    margin-bottom: 20px;
  }
  & ${Text} {
    margin: 10px 0;
    text-align: center;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
`;

const HorizontalLine = styled.hr`
  width: 207px;
  height: 0px;

  border: 1px solid #e5e5e5;
`;

export default HourAllotmentReached;
