import { gql } from "@apollo/client";

export const GET_PAL_REVIEW = gql`
  query visitRatings(
    $filter: VisitRatingFilter
    $pagination: PaginationInput
    $sorting: VisitRatingSorting
  ) {
    visitRatings(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        approved
        commentForPal
        ratingForPal
        visit {
          data {
            id
            papa {
              data {
                id
                fullName
                dataVisibility {
                  concealed
                }
              }
            }
            pal {
              data {
                id
                fullName
                account {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
`;

export const APPROVE_OR_REJECT_REVIEW = gql`
  mutation approveOrRejectReview($visitId: ID!, $approved: Boolean!) {
    approvePapaRating(visitId: $visitId, approved: $approved) {
      data {
        approved
      }
    }
  }
`;
