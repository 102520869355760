import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import { Organization } from "generated/types";
import { formatFullName } from "utils/strings/formatFullName";

import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  organization: Organization | null;
  isOpen: boolean;
  toggle: () => void;
  onCreateFinish: () => void;
}

const OrganizationModal = ({
  organization,
  isOpen,
  toggle,
  onCreateFinish,
}: Props): ReactElement => {
  const [organizationMutation, { loading }] = useMutation(
    organization ? UPDATE_ORGANIZATION : CREATE_ORGANIZATION
  );

  const initialValues: Schema = {
    name: organization?.name ?? "",
  };

  const handleSubmit = async ({ name }: Schema) => {
    const input = { name: formatFullName(name) };
    try {
      if (organization) {
        const { data } = await organizationMutation({
          variables: { id: organization.id, input },
        });

        if (data?.updateOrganization?.data?.id) {
          toast.success("Rename successful!");
          toggle();
        } else {
          throw new Error("Something is wrong");
        }
      } else {
        const { data } = await organizationMutation({
          variables: { input },
        });

        if (data?.createOrganization?.data?.id) {
          toast.success("Create organization successful!");
          onCreateFinish();
          toggle();
        } else {
          throw new Error("Something is wrong");
        }
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout
      toggle={toggle}
      title={organization ? "Edit Organization" : "Create Organization"}
      isOpen={isOpen}
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Input
                type="text"
                aria-label="name"
                name="name"
                placeholder="Enter organization name"
              />
            </Form.Group>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading} variant="primary">
              {loading && <SpinningIcon size={18} />}
              Save
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};

export default OrganizationModal;
