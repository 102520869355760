import { useField } from "formik";
import React from "react";
import styled from "styled-components";

import Form from "components/Form";
import Feedback from "components/Form/FormFeedback";
import { Container } from "components/Radio";

interface Options {
  label: string;
  value: any;
}

interface Props {
  options: Options[];
  name: string;
}

export const CheckboxGroup: React.FC<Props> = ({ options, name }) => {
  const [, { error, touched }] = useField(name);

  return (
    <>
      <Group>
        {options.map(({ label, value }, index) => (
          <Form.Check key={index} value={value} name={name} controlId={label} label={label} />
        ))}
      </Group>

      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

const Group = styled.div`
  ${Container} + ${Container} {
    margin-top: 0.625rem;
  }
`;
