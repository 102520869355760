import React, { ReactElement, createContext, useState } from "react";

import Button from "components/Button";
import { Drawer } from "components/Drawer";
import { Papa } from "generated/types";

import EditOverview from "./EditOverview";

export const ToggleEditOverviewShowContext = createContext({
  show: false,
  toggleShow: (value: boolean) => {},
});

type Props = {
  papaId: string;
  papa?: Papa | null;
  onRefetch: () => void;
};

const EditOverviewControl = ({ papaId, papa, onRefetch }: Props): ReactElement => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button block onClick={() => setShow(true)}>
        Edit
      </Button>

      <Drawer open={show} onClose={() => setShow(false)} size="md" title="Edit Details">
        <EditOverview
          papaId={papaId}
          papa={papa}
          onRefetch={onRefetch}
          closeDrawer={() => setShow(false)}
        />
      </Drawer>
    </>
  );
};

export default EditOverviewControl;
