import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  isValid?: boolean;
  isInvalid?: boolean;
};

const StyledFeedback = styled.div<Props>`
  color: ${({ isValid, isInvalid, theme }) => {
    if (isValid) return theme.variants.success;
    if (isInvalid) return theme.variants.danger;
    return theme.text.muted;
  }};
  font-size: 0.75rem;
  margin-top: 5px;
`;

const FormFeedback: React.FC<Props> = ({ children, ...rest }: Props) => {
  return <StyledFeedback {...rest}>{children}</StyledFeedback>;
};

export default FormFeedback;
