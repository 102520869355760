import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";

import { FeatureFlags } from "constants/featureFlags";
import { AccountType, CaregiverType, useAccountByPapaQuery } from "generated/types";
import { Values as FormValues } from "pages/ScheduleVisit/types";

export const useValidatePayment = () => {
  const [, , helpers] = useField<boolean>("isPaymentValid");
  const { values } = useFormikContext<Pick<FormValues, "papaId" | "isPaymentValid">>();
  const papaId = values.papaId;
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);

  const { data } = useAccountByPapaQuery({
    skip: !papaId,
    variables: {
      papaId,
    },
  });

  const papa = data?.papa?.data;

  useEffect(() => {
    const isPapaUnderABusiness = () => papa?.account?.data?.type === AccountType.Business;
    const isPapaUnderACaregiver = () =>
      papa?.caregiver?.data !== null &&
      (papa?.caregiver?.data?.type === CaregiverType.Member || !papa?.businessId === null);

    const isPaymentValid =
      hideCreditCardFields || isPapaUnderABusiness() || isPapaUnderACaregiver();

    helpers.setValue(isPaymentValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [papa]);

  return values.isPaymentValid;
};
