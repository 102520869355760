import React, { ReactElement, useState } from "react";

import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";

import ExportPalsModal from "./ExportPalsModal";

const ExportPals = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Export Pals</Button>

      <ModalLayout toggle={() => setIsOpen(!isOpen)} title="Export Pals" isOpen={isOpen}>
        <ExportPalsModal closeModal={() => setIsOpen(false)} />
      </ModalLayout>
    </>
  );
};

export default ExportPals;
