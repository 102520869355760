import { useApolloClient } from "@apollo/client";
import { useField } from "formik";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import Async from "react-select/async";
import { ValueType } from "react-select/src/types";
import styled from "styled-components";

import { searchFieldStyles } from "components/Search";
import {
  OrganizationDocument,
  OrganizationQuery,
  OrganizationQueryVariables,
  OrganizationsSelectOptionsDocument,
  OrganizationsSelectOptionsQuery,
  OrganizationsSelectOptionsQueryVariables,
} from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { Option } from "utils/select";

interface Props {
  name: string;
}

export const OrganizationSelect: React.FC<Props> = ({ name }) => {
  const [{ value: selectedOrganizationId }, , helpers] = useField(name);
  const [options, setOptions] = useState<Option<string>[]>([]);
  const client = useApolloClient();
  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query<
        OrganizationsSelectOptionsQuery,
        OrganizationsSelectOptionsQueryVariables
      >({
        query: OrganizationsSelectOptionsDocument,
        variables: {
          filter: { name: { cont: value } },
        },
      });

      if (data?.organizations?.data) {
        return data.organizations.data.map(({ id, name }) => ({ value: id, label: name }));
      }
    } catch (error) {
      window.console.error(error);
    }

    return [];
  };

  useEffect(() => {
    const getFirstOption = async () => {
      const { data } = await client.query<OrganizationQuery, OrganizationQueryVariables>({
        query: OrganizationDocument,
        variables: {
          id: selectedOrganizationId,
        },
      });

      if (data?.organization?.data?.name) {
        setOptions([
          {
            value: selectedOrganizationId,
            label: data.organization.data.name,
          },
        ]);
      }
    };
    if (selectedOrganizationId && !options.length) {
      getFirstOption();
    }
  }, [selectedOrganizationId, options, client]);

  const fetch = (inputValue: string, callback: any) => {
    loadOptions(inputValue).then((results: any) => callback(results));
  };

  const debouncedSearch = debounce(fetch, 500);

  const onSearchInputChange = (event: ValueType<{ label: string; value: string }>) => {
    const option = event as { label: string; value: string };
    setOptions([option]);
    helpers.setValue(option.value);
  };

  const placeholderSearchText = clientCopyUpdateEnabled
    ? "Search by client name"
    : "Search by organization name";

  return (
    <Container>
      <Async
        aria-label="search"
        placeholder={placeholderSearchText}
        loadOptions={debouncedSearch}
        onChange={onSearchInputChange}
        value={options}
        // @ts-ignore TODO:remove this
        styles={searchFieldStyles}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 18.75rem;
`;
