import * as faSolid from "@styled-icons/fa-solid";
import { Plus as PlusIcon } from "@styled-icons/fa-solid/Plus";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import ButtonOld, { ButtonNew as Button } from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import {
  CompleteAgentVisitMutation,
  Visit,
  VisitBusinessPurpose,
  useCompleteAgentVisitMutation,
  useEndAgentCallMutation,
  useRestartAgentCallMutation,
  useStartAgentVisitMutation,
} from "generated/types";
import { useCurrentCall } from "hooks/useCurrentCall";
import { useModalToggle } from "hooks/useModalToggle";
import { useUrlQuery } from "hooks/useUrlQuery";
import AssociateServiceRequestsModal from "modals/AssociateServiceRequests";
import SetUpVirtualCallModal from "modals/VisitPrograms/SetUpVirtualCall";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import { GET_CURRENT_ACCOUNT } from "utils/gql/current-account";

import { FeedbackModalsContainer } from "./VisitFeedback/FeedbackModalsContainer";

type Props = {
  papaId?: string;
  visitId?: string | null;
  isOutside?: boolean;
  isHidden?: boolean;
  onCompleteVisit?: () => void;
};

const VirtualCall = ({
  visitId,
  papaId,
  isOutside = false,
  isHidden = false,
  onCompleteVisit = () => {},
}: Props) => {
  const papaMatch = useRouteMatch<{ id: string }>("/papas/:id/:tab");
  const visitMatch = useRouteMatch<{ id: string }>("/visits/:id/:tab");

  const urlQueryString = useUrlQuery() as {
    startVirtualCall?: string;
  };
  const { currentCall, loading: isGetting } = useCurrentCall();

  const callMatch = papaMatch
    ? currentCall?.papa?.data?.id === papaMatch.params?.id
    : visitMatch
    ? currentCall?.id === visitMatch.params?.id
    : false;

  const papa = papaConcealed(currentCall?.papa?.data);

  const { isOpen: showFeedbackModal, toggle: toggleFeedbackModal } = useModalToggle();
  const { isOpen: isSetUpVirtualModalOpen, toggle: toggleSetUpVirtualModalVisibility } =
    useModalToggle();
  const [lastCall, setLastCall] = useState<Visit | null>(null);

  const history = useHistory();
  const location = useLocation();

  const [startAgentVisit, { loading: isStarting }] = useStartAgentVisitMutation();

  const [endAgentCall, { loading: isStopping }] = useEndAgentCallMutation();
  const [restartAgentCall, { loading: isRestarting }] = useRestartAgentCallMutation();
  const [completeAgentVisit, { loading: isCompleting }] = useCompleteAgentVisitMutation();

  const onShowFeedbackModal = () => {
    toggleFeedbackModal();
  };

  const { isOpen: isAssignServiceRequestModalOpen, toggle: toggleAssignServiceRequests } =
    useModalToggle();

  const lastEvent = currentCall?.events?.data?.[0];
  const lastEventAction =
    lastEvent?.__typename === "VisitEvent" && lastEvent?.action ? lastEvent.action : "";

  const onCall = lastEventAction === "call_attempted";
  const startVirtualCall = urlQueryString?.startVirtualCall === "true";

  const isToSetUpVirtualCall = !startVirtualCall && isNull(visitMatch);

  const handleSetUpVirtualCall = () => {
    toggleSetUpVirtualModalVisibility();
  };

  const handleStartVisit = async (
    isUnscheduled?: boolean,
    businessPurposes?: VisitBusinessPurpose[]
  ) => {
    try {
      const { data: startAgentVisitSingleResult } = await startAgentVisit({
        variables: {
          visitId: visitId ? visitId : undefined,
          papaId: visitId ? undefined : papaId,
          templateCode: "WELLCARE1",
          isUnscheduled,
          isInbound: isUnscheduled,
          businessPurposes,
        },
        refetchQueries: [{ query: GET_CURRENT_ACCOUNT }],
      });

      if (startAgentVisitSingleResult?.startAgentVisit?.data?.id) {
        toast.success("The virtual call started!", { position: "top-center" });
      } else {
        toast.error("It' was not possible to start a virtual call", { position: "top-center" });
      }
    } catch (error) {
      toast.error((error as Error).message, { position: "top-center" });
    }
  };

  const handleStartScheduledVisit = async () => {
    await handleStartVisit(false, []);
  };

  // eslint-disable-next-line
  const handleStartUnscheduledVisit = async () => {
    await handleStartVisit(true, [VisitBusinessPurpose.Enrollment]);
  };

  useEffect(() => {
    const url = `${location.pathname}?${queryString.stringify(
      omit(urlQueryString, ["startVirtualCall"])
    )}`;
    if (!isOutside && startVirtualCall && !isGetting) {
      if (!currentCall) {
        handleStartUnscheduledVisit();
        history.push(url);
      } else if (currentCall) {
        history.push(url);
      }
    }
  }, [
    startVirtualCall,
    urlQueryString,
    isGetting,
    isOutside,
    currentCall,
    handleStartUnscheduledVisit,
    location.pathname,
    location.search,
    history,
  ]);

  const handleStopCall = async () => {
    if (!currentCall?.id) return;

    try {
      const { data: stopAgentCallData } = await endAgentCall({
        variables: {
          visitId: currentCall.id,
        },
        refetchQueries: [{ query: GET_CURRENT_ACCOUNT }],
      });

      if (stopAgentCallData?.endAgentCall?.data?.id) {
        toast.success("The virtual call stopped", { position: "top-center" });
      } else {
        toast.error("Unable to stop the call", { position: "top-center" });
      }
    } catch (error) {
      toast.error((error as Error).message, { position: "top-center" });
    }
  };

  const handleRestartCall = async () => {
    if (!currentCall?.id) return;

    try {
      const { data: restartAgentCallData } = await restartAgentCall({
        variables: {
          visitId: currentCall.id,
        },
        refetchQueries: [{ query: GET_CURRENT_ACCOUNT }],
      });

      if (restartAgentCallData?.restartAgentCall?.data?.id) {
        toast.success("The virtual call restarted", { position: "top-center" });
      } else {
        toast.error("Unable to restart the call", { position: "top-center" });
      }
    } catch (error) {
      toast.error((error as Error).message, { position: "top-center" });
    }
  };

  const wasScheduledVisit = (completeAgentVisitSingleResult: CompleteAgentVisitMutation) => {
    return (
      !completeAgentVisitSingleResult.completeVisit?.data?.businessPurposes?.some(
        (vbp) =>
          vbp === VisitBusinessPurpose.Enrollment ||
          vbp === VisitBusinessPurpose.CareNavigation ||
          vbp === VisitBusinessPurpose.Assessment
      ) || false
    );
  };

  const isCareNavigation = (completeAgentVisitSingleResult: CompleteAgentVisitMutation) => {
    return (
      completeAgentVisitSingleResult.completeVisit?.data?.businessPurposes?.some(
        (vbp) => vbp === VisitBusinessPurpose.CareNavigation
      ) || false
    );
  };

  const handleCompleteVisit = async () => {
    if (!currentCall?.id) return;
    try {
      const { data: completeAgentVisitSingleResult } = await completeAgentVisit({
        variables: {
          visitId: currentCall.id,
        },
        refetchQueries: [{ query: GET_CURRENT_ACCOUNT }],
      });

      if (completeAgentVisitSingleResult?.completeVisit?.data?.id) {
        toast.success("The virtual call was completed", { position: "top-center" });

        // We save the current call so that we can use it for persisting data in the modals below.
        // `currentCall` becomes undefined as soon as the call is stopped.
        setLastCall(currentCall);

        onCompleteVisit();

        if (isCareNavigation(completeAgentVisitSingleResult)) {
          toggleAssignServiceRequests();
        }

        if (wasScheduledVisit(completeAgentVisitSingleResult) && currentCall.id) {
          onShowFeedbackModal();
        }
      } else {
        toast.error("Unable to complete the call", { position: "top-center" });
      }
    } catch (error) {
      toast.error((error as Error).message, { position: "top-center" });
    }
  };

  return (
    <>
      {!isHidden && (
        <>
          {!!currentCall && callMatch && !isOutside && (
            <>
              {onCall ? (
                <ButtonOld
                  variant="warning"
                  type="button"
                  onClick={handleStopCall}
                  disabled={isGetting || isStopping || isCompleting}
                >
                  {isStopping ? (
                    <>
                      <SpinningIcon size={18} />
                      <span>Stopping Call...</span>
                    </>
                  ) : (
                    "Stop Call"
                  )}
                </ButtonOld>
              ) : (
                <ButtonOld
                  type="button"
                  variant="success"
                  onClick={handleRestartCall}
                  disabled={isGetting || isRestarting || isCompleting}
                >
                  {isRestarting ? (
                    <>
                      <SpinningIcon size={18} />
                      <span>Restarting...</span>
                    </>
                  ) : (
                    "Restart Call"
                  )}
                </ButtonOld>
              )}
              <ButtonOld
                type="button"
                action
                onClick={handleCompleteVisit}
                disabled={isGetting || isCompleting}
              >
                {isCompleting ? (
                  <>
                    <SpinningIcon size={18} />
                    <span>Completing Visit...</span>
                  </>
                ) : (
                  "Complete Visit"
                )}
              </ButtonOld>
            </>
          )}
          {!!currentCall && !callMatch && isOutside && (
            <FloatingCall>
              {onCall ? (
                <CircleButton
                  action
                  noContent
                  variant="danger"
                  type="button"
                  onClick={handleStopCall}
                  disabled={isGetting || isStopping || isCompleting}
                >
                  {isStopping ? <SpinningIcon size={18} /> : <faSolid.Times size={20} />}
                </CircleButton>
              ) : (
                <CircleButton
                  type="button"
                  onClick={handleRestartCall}
                  disabled={isGetting || isRestarting || isCompleting}
                >
                  {isRestarting ? <SpinningIcon size={18} /> : <faSolid.PhoneAlt size={16} />}
                </CircleButton>
              )}
              <CircleButton
                type="button"
                action
                noContent
                onClick={handleCompleteVisit}
                disabled={isGetting || isCompleting}
              >
                {isCompleting ? <SpinningIcon size={18} /> : <faSolid.Plus size={16} />}
              </CircleButton>
              <PapaNameContainer>
                <GreenText>On call with:</GreenText>
                <PapaLinkToVisit to={`/visits/${currentCall.id}`}>{papa?.fullName}</PapaLinkToVisit>
              </PapaNameContainer>
            </FloatingCall>
          )}
          {(!currentCall || (!!currentCall && !callMatch)) && !isOutside && (
            <Button
              type="button"
              onClick={isToSetUpVirtualCall ? handleSetUpVirtualCall : handleStartScheduledVisit}
              disabled={isGetting || isStarting}
              variant="secondary"
              block
              withIcon
            >
              {isStarting ? (
                <>
                  <SpinningIcon size={18} />
                  <span>Starting Virtual Call...</span>
                </>
              ) : isToSetUpVirtualCall ? (
                <>
                  <PlusIcon size={14} />
                  Start virtual visit
                </>
              ) : (
                <>
                  <PlusIcon size={14} />
                  Start virtual visit
                </>
              )}
            </Button>
          )}
        </>
      )}
      {lastCall?.id && (
        <FeedbackModalsContainer
          visitId={lastCall.id}
          toggle={toggleFeedbackModal}
          isOpen={showFeedbackModal}
        />
      )}
      <SetUpVirtualCallModal
        papaId={papaId}
        isOpen={isSetUpVirtualModalOpen}
        toggle={toggleSetUpVirtualModalVisibility}
      />
      {lastCall?.id && lastCall?.papa?.data?.id && (
        <AssociateServiceRequestsModal
          visitId={lastCall.id}
          papaId={lastCall.papa?.data?.id}
          isOpen={isAssignServiceRequestModalOpen}
          toggle={toggleAssignServiceRequests}
        />
      )}
    </>
  );
};

const FloatingCall = styled.div`
  background: ${({ theme }) => theme.background.panelContent};
  border: 1px solid #edebeb;
  border-radius: 4px;
  box-shadow: 2px 4px 4px rgba(190, 190, 190, 0.21);

  height: 4rem;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 1;
  padding: 0.625rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${ButtonOld} + ${ButtonOld} {
    margin-left: 1rem;
  }
`;

const CircleButton = styled(ButtonOld)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0;

  align-items: center;
  justify-content: center;
`;

const PapaNameContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const GreenText = styled.p`
  color: ${({ theme }) => theme.variants.success} !important;
  font-size: 0.75rem;
  margin: 0;
`;

const PapaLinkToVisit = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.variants.primary};
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: -0.175rem;
`;

export default VirtualCall;
