import { PapaWarning } from "generated/types";

export const warningsOptions = [
  { value: PapaWarning.HasPets, label: "Has Pets" },
  { value: PapaWarning.HearingImpaired, label: "Hearing Impaired" },
  { value: PapaWarning.VisuallyImpaired, label: "Visually impaired" },
  { value: PapaWarning.NoDriving, label: "No driving" },
  { value: PapaWarning.NoPhone, label: "No phone" },
  { value: PapaWarning.Smokes, label: "Smokes" },
  { value: PapaWarning.Seizure, label: "Seizure" },
  { value: PapaWarning.Dementia, label: "Dementia" },
];
