import { useApolloClient } from "@apollo/client";
import { Times } from "@styled-icons/fa-solid/Times";
import { useField, useFormikContext } from "formik";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import React, { CSSProperties, useEffect, useState } from "react";
import Select from "react-select";
import { OptionTypeBase, ValueType } from "react-select/src/types";
import styled from "styled-components";

import Button from "components/Button";
import { confirm } from "components/Confirm";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { InputContainer, StyledInput } from "components/Form/FormInput";
import { customStyles } from "components/Select";
import Text from "components/Text";
import { VisitObjectiveTemplate } from "generated/types";
import { Objective } from "types/objective";

import { GET_TASK_OBJECTIVES } from "./gql";

interface Props {
  taskIds?: string[];
  objectives?: Option[];
  hiddenObjectiveIds?: string[];
  papaId?: string;
}

export const TaskObjectives: React.FC<Props> = ({
  taskIds = [],
  objectives = [],
  hiddenObjectiveIds = [],
  papaId,
}) => {
  const [, , helpers] = useField("objectives");
  const client = useApolloClient();
  const { isSubmitting } = useFormikContext();

  const [loading, setLoading] = useState(false);
  const [objectivesOptions, setObjectivesOptions] = useState<VisitObjectiveTemplate[]>(objectives);
  const [lastTasksId, setLastTasksId] = useState<string[]>([]);

  const getObjectives = async (taskIds: string[]) => {
    if (taskIds.length === 0) {
      setObjectivesOptions([]);
      helpers.setValue([]);

      return;
    }
    setLoading(true);
    const { data } = await client.query({
      query: GET_TASK_OBJECTIVES,
      variables: {
        filter: [
          {
            taskId: { in: taskIds },
            isActive: { eq: true },
            deletedAt: { eq: null },
            papaId: papaId,
          },
          ...(objectives.length
            ? [
                {
                  id: { in: objectives.map(({ value }) => value) },
                  taskId: { in: taskIds },
                },
              ]
            : []),
        ],
        pagination: { limit: 1000 },
      },
    });

    if (data?.visitObjectiveTemplates?.data) {
      setObjectivesOptions(data.visitObjectiveTemplates.data);
      // If the user has unselected the task, then the objectives for this task will be unselected

      if (objectives.length) {
        const nextSelectedItems = objectives.filter((item) =>
          data.visitObjectiveTemplates.data.map(({ id }: Objective) => id).includes(item.value)
        );

        helpers.setValue(nextSelectedItems);
      }
    }

    setLoading(false);
  };

  const removeSelectedItem = (computedValue: ChangeSelectEvent) => async () => {
    if (await confirm(`Are you sure to delete "${computedValue.label}"?`)) {
      const newItems = objectives.filter((item) => item.value !== computedValue.value);
      helpers.setValue(newItems);
    }
  };

  useEffect(() => {
    if (!isEqual(lastTasksId, taskIds)) {
      getObjectives(taskIds);
      setLastTasksId(taskIds);
    }
    // eslint-disable-next-line
  }, [taskIds]);

  useEffect(() => {
    if (!objectives.length) {
      helpers.setValue([]);
      return;
    }

    // This useEffect is to get the task of the initial selected objectives, since we can't get it directly from Visit.objectives
    (async () => {
      setLoading(true);
      const { data } = await client.query({
        query: GET_TASK_OBJECTIVES,
        variables: {
          filter: [
            ...(objectives.length
              ? [
                  {
                    id: { in: objectives.map(({ value }) => value) },
                    taskId: { in: taskIds },
                    papaId: papaId,
                  },
                ]
              : []),
          ],
          pagination: { limit: 1000 },
        },
      });

      // When rendered with initial values, this will include which task the objective belongs to
      if (data?.visitObjectiveTemplates?.data) {
        const selectedItemsWithTask = mapVisitObjectivesToOption(
          data.visitObjectiveTemplates.data.filter((item: Objective) =>
            objectives.map((item) => item.id).includes(item.id)
          )
        );
        helpers.setValue(selectedItemsWithTask);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  const handleChange = (value: ValueType<ChangeSelectEvent>) => {
    const computedValue = value as ChangeSelectEvent;
    let newSelectedItems: ChangeSelectEvent[] = [];

    if (!objectives.find((item) => item.value === computedValue.value)) {
      newSelectedItems = [...objectives, computedValue];
    }

    helpers.setValue(newSelectedItems);
  };

  if (loading) {
    return <SpinningIcon size={18} />;
  }

  const selectStyles = {
    ...customStyles,
    container: (provided: CSSProperties, state: any) => ({
      ...customStyles.container(provided, state),
      maxWidth: "100%",
    }),
  };

  return (
    <>
      {taskIds.length ? (
        <>
          <Select
            isSearchable
            placeholder="Select additional details for this visit"
            aria-label="task objectives"
            options={mapVisitObjectivesToOption(
              objectivesOptions.filter(({ id }) => !includes(hiddenObjectiveIds, id))
            )}
            value={[]}
            onChange={handleChange}
            // @ts-ignore TODO:remove this
            styles={selectStyles}
            isDisabled={isSubmitting}
          />
          <Items>
            {objectives.map((item) => (
              <Item key={item.value}>
                <Text color="primary">{item.label}</Text>
                <CloseButton
                  onClick={removeSelectedItem(item)}
                  disabled={isSubmitting}
                  type="button"
                >
                  <Times size={7} />
                </CloseButton>
              </Item>
            ))}
          </Items>
        </>
      ) : (
        <InputContainer disabled>
          <StyledInput value="Please select a task first" disabled />
        </InputContainer>
      )}
    </>
  );
};

export interface Option extends Pick<VisitObjectiveTemplate, "id" | "description" | "task"> {
  value: string;
  label: string;
}

interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

const mapVisitObjectivesToOption = (objectives: VisitObjectiveTemplate[]) =>
  objectives.map(({ id, description, ...othersProps }) => ({
    ...othersProps,
    value: id ?? "",
    label: description as string,
  }));

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;

const Item = styled.div`
  display: inline-flex;
  background: #eaefff;
  padding: 9px 22px;
  position: relative;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const CloseButton = styled(Button).attrs({ action: true, noContent: true })`
  border-width: 0;
  padding: 3px;
  position: absolute;
  top: -3px;
  right: -3px;
`;
