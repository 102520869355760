import React from "react";
import { Route, Switch } from "react-router-dom";

import { ServiceType } from "constants/service-requests";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";

import CareConcierge from "./List";

const CareConciergeRoutes = () => {
  const { isSupervisor, isCareConciergeSpecialist } = useCurrentAccountRole();
  return isCareConciergeSpecialist ? (
    <Switch>
      <Route
        exact
        path="/careconcierge-mydashboard"
        render={({ ...props }) => <CareConcierge {...props} type={ServiceType.user} />}
      />
      <Route
        exact
        path="/careconcierge-myfollowups"
        render={({ ...props }) => <CareConcierge {...props} type={ServiceType.followups} />}
      />
      <Route
        exact
        path="/careconcierge-unassigned"
        render={({ ...props }) => <CareConcierge {...props} type={ServiceType.unassigned} />}
      />
      {isSupervisor && (
        <Route
          exact
          path="/careconcierge-all"
          render={({ ...props }) => <CareConcierge {...props} type={ServiceType.all} />}
        />
      )}
    </Switch>
  ) : null;
};

export default CareConciergeRoutes;
