import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import Text from "components/Text";
import { useModalToggle } from "hooks/useModalToggle";
import { useUrlQuery } from "hooks/useUrlQuery";

type Props = {
  papaId: string;
  onClose: () => void;
};

const PapaNotCurrentlyQualified = ({ papaId, onClose }: Props) => {
  const urlQuery = useUrlQuery();
  const history = useHistory();

  const handleClose = () => {
    onClose();
    const extraUrlQuery = !isEmpty(urlQuery)
      ? `?${queryString.stringify(omit(urlQuery, ["lonelinessCompliant"]))}`
      : "";
    history.push(`/papas/${papaId}/assessments${extraUrlQuery}`);
  };

  return (
    <ModalContent>
      <div>
        <Information>
          <img src="/images/icons/alert-icon.svg" alt="alert" />
          <ModalHeader>Papa is not currently qualified.</ModalHeader>
          <Separator />
          <ModalText>
            Papa does not currently qualify as they are not lonely. We’re unable to provide services
            at this moment. Please reach out to your supervisor if you think this is not correct.
          </ModalText>
        </Information>
        <Actions>
          <Button type="button" onClick={handleClose} action noContent>
            Ok
          </Button>
        </Actions>
      </div>
    </ModalContent>
  );
};

type PapaNotCurrentlyQualifiedModalProps = {
  papaId: string;
};

export const PapaNotCurrentlyQualifiedModal = ({ papaId }: PapaNotCurrentlyQualifiedModalProps) => {
  const { isOpen, toggle } = useModalToggle(true);

  return (
    <ModalLayout
      toggle={toggle}
      isOpen={isOpen}
      title="Eligibility"
      width={750}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <PapaNotCurrentlyQualified papaId={papaId} onClose={toggle} />
    </ModalLayout>
  );
};

const ModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Separator = styled.hr`
  display: inline-flex;
`;

const Information = styled.div`
  text-align: center;
`;

const ModalHeader = styled(Heading)`
  margin-bottom: 0.85rem;
  margin-bottom: 0.85rem;
`;

const ModalText = styled(Text)`
  margin-bottom: 0.85rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
