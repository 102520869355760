import React, { ChangeEvent, FunctionComponent, useState } from "react";
import styled from "styled-components";

import { Label, StyledCheckbox } from "components/Checkbox";
import { VisitObjective, VisitObjectiveInput } from "generated/types";
import { WithId } from "utils/nonNull";

interface Props {
  visitId: string;
  objective: WithId<VisitObjective>;
  onChange: (visitObjectiveInput: VisitObjectiveInput) => void;
  disabled: boolean;
}

const MarkableVisitObjective: FunctionComponent<Props> = ({
  visitId,
  objective,
  onChange,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(!!objective.checked);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: id, checked } = event.currentTarget;
    setIsChecked(checked);
    onChange({ id, checked });
  };

  return (
    <Container>
      <StyledCheckbox
        type="checkbox"
        id={`objective_${objective.id}`}
        value={objective.id}
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
      />
      <Label htmlFor={`objective_${objective.id}`}>{objective.description}</Label>
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  align-items: flex-start;

  input {
    margin-top: 2px;
    padding: 0.4rem;
  }
`;

export default MarkableVisitObjective;
