import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { SEARCH_QUERY } from "components/Search/gql";
import { PapaConcealed } from "types";
import { SearchResult } from "types/search";
import { concealedField } from "utils/fieldsPermissions/concealedField";

export const usePapasSearch = (data: PapaConcealed[]) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: SEARCH_QUERY,
        variables: {
          search: `*${value}*`,
          index: "papas",
        },
      });
      if (data?.searchResult?.data) {
        return data.searchResult.data
          .filter(({ deletedAt }: SearchResult) => !deletedAt)
          .map(({ id, phoneNumber, account, status, ...result }: SearchResult) => {
            const papaFullName = concealedField(
              "fullName",
              result?.fullName,
              result?.dataVisibility
            );

            const memberName = account?.data?.fullName ?? "No Member";
            return {
              value: id,
              label: papaFullName,
              phone: phoneNumber,
              member: memberName,
              name: papaFullName,
              status,
            };
          });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };
  const searchPillNames = data?.reduce(
    (obj, { id, fullName }) => ({
      ...obj,
      [id!]: fullName,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
