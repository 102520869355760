import { useQuery } from "@apollo/client";
import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import React from "react";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";

import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { VisitDiscountType, VisitMobilityNeeds } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";
import CreateVisitForm from "pages/ScheduleVisit/CreateVisitForm";

import { GET_ACCOUNT_BY_PAPA } from "./gql";
import { Values } from "./types";

const ScheduleVisit = () => {
  useTopBarOptions({ title: "Schedule Visit", icon: AccessTime, breadcrumbs: ["Visits"] });
  const { papaId } = useParams<{ papaId?: string }>();
  const { data, error } = useQuery(GET_ACCOUNT_BY_PAPA, {
    skip: !papaId,
    variables: {
      papaId,
    },
  });

  const urlQuery = useUrlQuery();

  const accountId = data?.papa?.data?.account?.data?.id;
  const defaultToFavoritePalsOnly = !!data?.papa?.data?.defaultToFavoritePalsOnly;

  const initialValues: Values = {
    accountId: accountId || "",
    accountType: "",
    additionalLocations: [],
    businessId: "",
    cardId: "",
    businessPolicyEnd: "",
    byDay: null,
    count: null,
    destination: null,
    discount: {
      type: VisitDiscountType.FixedAmount,
      valueFixed: 0,
      valuePercentage: 0,
    },
    endOnDate: true,
    estimatedDuration: 60,
    favoritePalsOnly: defaultToFavoritePalsOnly,
    freq: null,
    hasGroceryTask: false,
    hasMedicationTask: false,
    hasRelocationTasks: false,
    hasUberTasks: false,
    hasOvernightTransportationTasks: false,
    invitePalIds: [] as string[],
    isPaymentValid: false,
    isUberEligible: null,
    acceptsThirdPartyTransportation: null,
    isVirtual: urlQuery?.type === "virtual",
    location: null,
    memberNote: "",
    mobilityNeeds: VisitMobilityNeeds.None,
    note: "",
    overrideBusinessPolicy: false,
    obpReasonDescription: "",
    palId: null,
    papaId: accountId && papaId ? papaId : "",
    scheduledForDay: "",
    scheduledForTime: "",
    selectedRecommendedDate: "",
    surveyIds: [],
    systemNotes: [],
    taskIds: [],
    validUntil: null,
    visibleToPalApp: true,
    overrideVisitLeadTime: false,
    overrideVisitLeadTimeReason: null,
    overrideVisitLeadTimeAdditionalDetails: "",
    validateAcceptsThirdPartyTransportation: false,
  };

  if (!accountId && papaId && !error) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height={1015}
        backgroundColor="#f3f3f3"
        foregroundColor="#c0c0c0"
        uniqueKey="CreateVisitLoader"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="500" />
        <rect x="0" y="515" rx="3" ry="3" width="100%" height="500" />
      </ContentLoader>
    );
  }

  return (
    <>
      <CreateVisitForm initialValues={initialValues} />
    </>
  );
};

export default ScheduleVisit;
