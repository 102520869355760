import { gql } from "@apollo/client";

export const VACCINE_REJECT = gql`
  mutation rejectCovidVaccinationRecord($input: CovidVaccinationRejectionInput!) {
    rejectCovidVaccinationRecord(input: $input) {
      data {
        approvedAt
      }
    }
  }
`;
