import { useApolloClient } from "@apollo/client";
import { Redo } from "@styled-icons/fa-solid";
import React, { useState } from "react";
import styled from "styled-components";

import NotEnrolled from "assets/images/text-messaging-status/not-enrolled.svg";
import OptedIn from "assets/images/text-messaging-status/opted-in.svg";
import OptedOut from "assets/images/text-messaging-status/opted-out.svg";
import Pending from "assets/images/text-messaging-status/pending.svg";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Tooltip from "components/Tooltip";
import { MessageServiceAuthorization } from "generated/types";

import { MESSAGE_SERVICE_AUTHORIZATION } from "./gql";
import { TextMessagingStatus, calculateStatus } from "./utils";

interface Props {
  messageServiceAuthorization?: MessageServiceAuthorization | null;
}

const TextMessagingStatusIcon = ({ messageServiceAuthorization }: Props) => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const status = calculateStatus(messageServiceAuthorization);

  const handleRefetchStatus = async () => {
    setLoading(true);
    await client.query({
      query: MESSAGE_SERVICE_AUTHORIZATION,
      variables: {
        messageServiceAuthorizationId: messageServiceAuthorization?.id,
      },
      fetchPolicy: "network-only",
    });
    setLoading(false);
  };

  return (
    <Container>
      {status === TextMessagingStatus.NotEnrolled ? (
        <Tooltip title="Not Enrolled in Text Messaging">
          <img src={NotEnrolled} alt="not enrolled status icon" />
        </Tooltip>
      ) : status === TextMessagingStatus.Pending ? (
        <Tooltip title="Pending Confirming from Member">
          <img src={Pending} alt="pending status icon" />
        </Tooltip>
      ) : status === TextMessagingStatus.OptIn ? (
        <Tooltip title="Enrolled in Text Messaging">
          <img src={OptedIn} alt="opted-in status icon" />
        </Tooltip>
      ) : status === TextMessagingStatus.OptOut ? (
        <Tooltip title="Opt-out of Text Messaging">
          <img src={OptedOut} alt="opted-out status icon" />
        </Tooltip>
      ) : null}
      {status !== TextMessagingStatus.NotEnrolled && (
        <Tooltip title="Refresh Status">
          {loading ? (
            <SpinningIcon size={18} />
          ) : (
            <StyledRedo size={12} onClick={handleRefetchStatus} data-testid="refresh status" />
          )}
        </Tooltip>
      )}
    </Container>
  );
};

export default TextMessagingStatusIcon;

const StyledRedo = styled(Redo)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
