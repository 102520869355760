import { useEffect, useState } from "react";
import { geocodeByAddress } from "react-places-autocomplete";

import { EligibleLife } from "generated/types";
import { geoCodeResultToLocation } from "utils/geolocation";

import { Schema } from "./schema";

const emptyInitialValues = {
  language: null,
  fullName: "",
  email: "",
  birthDate: "",
  gender: null,
  genderText: null,
  phoneNumber: "",
  fixedPhoneNumber: "",
  livingSituation: null,
  location: { address: "" },
  address2: "",
  enrollTextMessaging: true,
  preferredContactMethod: null,
  genderPreference: null,
  genderPreferenceIsFlexible: null,
  genderPreferenceWasAsked: false,
};

export const useInitialValues = (eligibleLife?: EligibleLife | null) => {
  const [initialValues, setInitialValues] = useState<Schema>(emptyInitialValues);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!eligibleLife) {
      return;
    }

    const getFullName = (firstName?: string, lastName?: string): string => {
      if (!firstName && !lastName) {
        return "";
      }

      return `${firstName} ${lastName}`;
    };

    setInitialValues((prevState) => ({
      ...prevState,
      fullName: getFullName(eligibleLife?.firstName ?? "", eligibleLife?.lastName ?? ""),
      birthDate: eligibleLife?.dob || "",
      phoneNumber: eligibleLife?.phone1 || "",
      fixedPhoneNumber: eligibleLife?.phone2 || "",
      address2: eligibleLife?.addressLn2 || "",
    }));

    async function getInitialAddress() {
      const address = [
        eligibleLife?.addressLn1,
        eligibleLife?.addressLn2,
        eligibleLife?.city,
        eligibleLife?.state,
        eligibleLife?.zip,
      ]
        .filter((a) => !!a)
        .join(", ");

      try {
        const [result] = await geocodeByAddress(address);

        const location = geoCodeResultToLocation(result);
        setInitialValues((prevState) => ({
          ...prevState,
          location,
        }));
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }

    getInitialAddress();
  }, [eligibleLife]);

  return {
    initialValues,
    loading,
  };
};
