import { ChevronLeft, ChevronRight, EllipsisH } from "@styled-icons/fa-solid";
import React from "react";
import styled from "styled-components";

import Button from "components/Button";

const Pagination = ({
  currentPage,
  totalPages,
  itemsAfterAndBefore = 3,
  align = "right",
  onChangePage,
}: Props) => {
  if (currentPage < 1) {
    return <>`currentPage` should be greater than zero.</>;
  } else if (totalPages < 1) {
    return <>`totalPages` should be greater than zero.</>;
  } else if (itemsAfterAndBefore < 1) {
    return <>`itemsAfterAndBefore` should be greater than zero.</>;
  } else if (currentPage > totalPages) {
    return <>`currentPage` should be less or equal to `totalPages`.</>;
  }

  const handleClick = (page: number) => () => onChangePage(page);

  const before = Math.max(currentPage - itemsAfterAndBefore, 1);
  const after = Math.min(currentPage + itemsAfterAndBefore + 1, totalPages);

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const showFirstPage = before > 1;
  const showLastPage = after - 1 < totalPages;
  const showEllipsisBefore = before - 1 > 1;
  const showEllipsisAfter = totalPages - after > 0;

  const pages = [];

  for (let l = before; l < after; l++) {
    pages.push(l);
  }

  return (
    <Wrapper align={align} role="navigation">
      {!isFirstPage && (
        <SideButton title="previous page" onClick={handleClick(currentPage - 1)}>
          <ChevronLeft size={18} />
        </SideButton>
      )}
      {showFirstPage && <PaginationButton onClick={handleClick(1)}>1</PaginationButton>}
      {showEllipsisBefore && (
        <Ellipsis role="separator">
          <EllipsisHIcon size={18} />
        </Ellipsis>
      )}
      {pages.map((page) =>
        page === currentPage ? (
          <CurrentPage key={page}>{page}</CurrentPage>
        ) : (
          <PaginationButton key={page} onClick={handleClick(page)}>
            {page}
          </PaginationButton>
        )
      )}
      {showEllipsisAfter && (
        <Ellipsis role="separator">
          <EllipsisHIcon size={18} />
        </Ellipsis>
      )}
      {showLastPage &&
        (currentPage === totalPages ? (
          <CurrentPage>{totalPages}</CurrentPage>
        ) : (
          <PaginationButton onClick={handleClick(totalPages)}>{totalPages}</PaginationButton>
        ))}
      {!isLastPage && (
        <SideButton title="next page" onClick={handleClick(currentPage + 1)}>
          <ChevronRight size={18} />
        </SideButton>
      )}
    </Wrapper>
  );
};

const alignConfig = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

type Props = {
  currentPage: number;
  totalPages: number;
  onChangePage: (page: number) => void;
  itemsAfterAndBefore?: number;
  align?: "left" | "center" | "right";
};

type WrapperProps = {
  align: "left" | "center" | "right";
};

const Wrapper = styled.nav<WrapperProps>`
  display: flex;
  justify-content: ${({ align }) => alignConfig[align]};
`;

const PaginationButton = styled(Button).attrs({
  variant: "secondary",
  type: "button",
})`
  width: auto;
  min-width: 1.625rem;
  padding: 0.125rem 0.375rem;
  border-width: 2px;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  text-align: center;
  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.variants.primary};
    color: ${({ theme }) => theme.variants.primary};
  }
`;

const CurrentPage = styled.span`
  min-width: 1.625rem;
  border-radius: 0.375rem;
  padding: 0.125rem 0;
  border-width: 2px;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  text-align: center;
  background-color: ${({ theme }) => theme.variants.primary};
  color: white;
`;

const SideButton = styled(PaginationButton)`
  border-color: transparent;
`;

const EllipsisHIcon = styled(EllipsisH)`
  color: ${({ theme }) => theme.variants.secondary};
`;

const Ellipsis = styled.span`
  margin-left: 0.313rem;
  margin-left: 0.313rem;
  padding-right: 0.125rem;
  padding-right: 0.125rem;
`;

export default Pagination;
