import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import BusinessPolicyModal from "modals/BusinessPolicyModal";

type Props = {
  accountId: string;
  onCreateFinish: () => void;
};

export const CreateBusinessPolicy: React.FC<Props> = ({ accountId, onCreateFinish }) => {
  const { isOpen, toggle } = useModalToggle();
  const {
    capitalized: { singularEntityName },
  } = useCallItClientProgramOrBusiness();

  const { isSuperAdmin, isClientAdmin } = useCurrentAccountRole();

  const clientAdminShowModal = () => {
    if (isSuperAdmin || isClientAdmin) {
      toggle();
    } else {
      toast.warn("Must be a ClientAdmin or SuperAdmin to Create Business Policy.");
    }
  };

  return (
    <>
      <Button action onClick={clientAdminShowModal}>
        Create {singularEntityName} Policy
      </Button>

      <BusinessPolicyModal
        accountId={accountId}
        onClose={toggle}
        isOpen={isOpen}
        onCreateFinish={onCreateFinish}
      />
    </>
  );
};

export default CreateBusinessPolicy;
