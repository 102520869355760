import { Star } from "@styled-icons/ionicons-solid/Star";
import React from "react";
import styled from "styled-components";

interface Props {
  setRating: (rating: number) => void;
  rating: number;
}

export const VisitRating: React.FC<Props> = ({ rating, setRating }) => {
  return (
    <RateVisitContainer>
      <StarsContainer>
        {Array.from({ length: 5 }).map((_, index) => {
          return (
            <StyledStar
              size={45}
              key={index}
              data-testid={`rating-star-${index}`}
              active={rating >= index + 1}
              onClick={() => setRating(index + 1)}
            />
          );
        })}
      </StarsContainer>
    </RateVisitContainer>
  );
};

const RateVisitContainer = styled.div`
  margin: 43px 0 57px;
`;

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 22px;
  margin: 20px 0 57px;
`;

const StyledStar = styled(Star)<{ active: boolean }>`
  cursor: pointer;
  transition: 0.2s color ease;
  color: ${({ active, theme }) => (active ? theme.variants.primary : "#9393AA")};
`;
