import { useQuery } from "@apollo/client";
import { CaretDown } from "@styled-icons/bootstrap/CaretDown";
import { CaretUp } from "@styled-icons/bootstrap/CaretUp";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams, useRouteMatch } from "react-router-dom";

import ServiceRequestActionMenu from "components/ActionsMenu/ServiceRequestActionMenu";
import { FlexRow } from "components/Flex";
import SmartTable from "components/SmartTable";
import { useRowsSelectList } from "components/SmartTable/useRowsSelectList";
import { ServiceType } from "constants/service-requests";
import { ServiceRequest, ServiceRequestListResult, ServiceRequestStatus } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import { Arrow, StyledHeading } from ".";
import { GET_PAPA_REQUESTS } from "./gql";
import { useServicesTableColumns } from "./useServicesTableColumns";

interface Data {
  serviceRequests: ServiceRequestListResult;
}

const OpenedRequests = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const { rowsSelected, onRowSelect, onAllRowsSelect } = useRowsSelectList();
  const { page, limit } = usePageInfo();
  const careConciergeMatch = useRouteMatch("/papas/:id/care-concierge");
  const underCareConciergeTag = careConciergeMatch !== null;
  const { columns } = useServicesTableColumns({
    statusType: "open",
  });
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useQuery<Data>(GET_PAPA_REQUESTS, {
    variables: {
      filter: {
        papaId: {
          eq: id,
        },
        status: {
          in: [
            ServiceRequestStatus.New,
            ServiceRequestStatus.InProgress,
            ServiceRequestStatus.OnHold,
          ],
        },
      },
      pagination: { page, limit },
    },
  });
  const toggle = () => {
    isCollapsed ? setCollapsed(false) : setCollapsed(true);
  };
  useEffect(() => {
    const handleRefetch = async () => {
      await refetch();
    };
    if (underCareConciergeTag) {
      handleRefetch();
    }
  }, [underCareConciergeTag, page, refetch]);
  const servicerequests = data?.serviceRequests;
  return (
    <div>
      <Helmet>
        <title>Service Requests - Papa Admin</title>
      </Helmet>
      <StyledHeading onClick={toggle}>
        <FlexRow justifyContent="space-between">
          <div>Open Service Requests ({data?.serviceRequests?.pagination?.totalCount})</div>
          <Arrow>{isCollapsed ? <CaretUp size={18} /> : <CaretDown size={18} />}</Arrow>
        </FlexRow>
      </StyledHeading>
      {!isCollapsed && (
        <>
          <FlexRow justifyContent="flex-end">
            <ServiceRequestActionMenu
              type={ServiceType.papa}
              serviceRequestIds={rowsSelected}
              onRefetch={refetch}
            />
          </FlexRow>
          <SmartTable<ServiceRequest>
            keyField="id"
            data={servicerequests?.data}
            pagination={servicerequests?.pagination}
            loading={loading}
            columns={columns}
            error={error}
            onRowSelect={onRowSelect}
            onAllRowsSelect={onAllRowsSelect}
            rowsSelected={rowsSelected}
          />
        </>
      )}
    </div>
  );
};

export default OpenedRequests;
