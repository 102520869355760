import { gql } from "@apollo/client";

export const UPDATE_PAL_VEHICLE = gql`
  mutation updatePalVehicle($id: ID!, $input: PalInput!) {
    updatePal(id: $id, input: $input) {
      data {
        id
        vehicle
        vehicleColor
        vehicleModel
        vehicleYear
      }
    }
  }
`;
