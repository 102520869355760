import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import SurveysSelect from "components/SurveysSelect";
import TaskButtons from "components/TaskButtons";
import { TaskObjectives } from "components/TaskObjectives/TaskObjectives";
import { useUpdateVisitTemplateMutation, useVisitTemplateQuery } from "generated/types";

import { FormValues, schema } from "./schema";

interface Props {
  onFormComplete: () => void;
  id: string;
}

export const ProgramTemplateForm: React.FC<Props> = ({ id, onFormComplete }) => {
  const [editTemplate] = useUpdateVisitTemplateMutation();
  const { data, loading: templateLoading } = useVisitTemplateQuery({ variables: { id } });

  const onSubmit = async ({ templateName, objectives, surveyIds, taskIds }: FormValues) => {
    try {
      const { data } = await editTemplate({
        variables: {
          id,
          input: {
            name: templateName,
            surveyIds,
            taskIds,
            visitObjectiveTemplateIds: objectives?.map(({ value }) => value),
          },
        },
      });

      if (data?.updateVisitTemplate?.data?.id) {
        toast.success("Template was edited with success");
        onFormComplete();
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const template = data?.visitTemplate?.data;

  const initialValues = {
    templateName: template?.name || "",
    // @ts-ignore - type error because of the schema without `!`
    surveyIds: template?.surveys?.data?.map(({ id }) => id) || [],
    objectives:
      template?.objectives?.data?.map((objective) => {
        return {
          ...objective,
          label: objective?.description || "",
          value: objective?.id || "",
        } as any;
      }) || [],
    // @ts-ignore - type error because of the schema without `!`
    taskIds: template?.tasks?.data?.map(({ id }) => id) || [],
  };

  if (templateLoading) {
    return (
      <SpinnerContainer>
        <SpinningIcon size={45} />
      </SpinnerContainer>
    );
  }

  return (
    <Formik<FormValues> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ values }) => (
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label htmlFor="templateName" required>
                Template name
              </Form.Label>
              <Form.Input name="templateName" placeholder="Template name" id="templateName" />
            </Form.Group>

            <Form.Group>
              <Form.Label required>Template tasks</Form.Label>
              <TaskButtons name="taskIds" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Tasks objectives</Form.Label>

              <TaskObjectives taskIds={values.taskIds} objectives={values.objectives} />
            </Form.Group>

            <Form.Group controlId="surveyIds">
              <Form.Label>Visit surveys</Form.Label>
              <SurveysSelect name="surveyIds" />
            </Form.Group>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={onFormComplete}>
              Close
            </Button>
            <Form.SubmitButton>Save template</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
