import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";

import CaregiverActionsMenu from "components/ActionsMenu/CaregiverActionMenu";
import Actions from "components/ActionsMenu/CaregiverActionMenu/Actions";
import { useCaregiverActions } from "components/ActionsMenu/CaregiverActionMenu/useCaregiverActions";
import Button from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import { FeatureFlags } from "constants/featureFlags";
import { useCaregiverQuery } from "generated/types";
import { useCallItPapaOrCareRecipient } from "hooks/useCallItPapaOrCareRecipient";

import About from "./About/About";
import CreditCards from "./CreditCards";
import Overview from "./Overview/Overview";
import Papas from "./Papas/Papas";
import Visits from "./Visits/Visits";

interface Props {
  caregiverId: string;
}

const Details = ({ match }: RouteComponentProps<Props>) => {
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const { caregiverId } = match.params;
  const { data, loading, error, refetch } = useCaregiverQuery({
    variables: { caregiverId },
  });
  const { action, setAction } = useCaregiverActions();

  const caregiver = data?.caregiver?.data;

  const { capitalized } = useCallItPapaOrCareRecipient();

  useTopBarOptions({
    title: "Caregiver Details",
    name: caregiver?.fullName ?? "",
    icon: AccountCircle,
    breadcrumbs: ["Accounts", { label: "All Caregivers", link: "/caregivers" }],
    deps: [caregiver?.fullName],
  });

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Container>
        <Overview caregiver={caregiver} loading={loading} error={error} />

        <Main>
          <FlexRow justifyContent="space-between">
            <Nav>
              <Nav.Link to={`/caregivers/${caregiverId}/visits`}>Visits</Nav.Link>
              <Nav.Link to={`/caregivers/${caregiverId}/about`}>About</Nav.Link>
              <Nav.Link to={`/caregivers/${caregiverId}/papas`}>
                {capitalized.pluralEntityName}
              </Nav.Link>
              {!hideCreditCardFields && (
                <Nav.Link to={`/caregivers/${caregiverId}/credit-cards`}>Credit Cards</Nav.Link>
              )}
            </Nav>
            {caregiver && <CaregiverActionsMenu caregiver={caregiver} setAction={setAction} />}
            <FlexRow justifyContent="flex-end">
              <Button
                type="button"
                action
                onClick={() => setAction(DataTableMenuActions.AddPapa, caregiver)}
              >
                {`Create ${capitalized.singularEntityName}`}
              </Button>
            </FlexRow>
          </FlexRow>

          <Switch>
            <Route exact path="/caregivers/:caregiverId/visits" component={Visits} />
            <Route exact path="/caregivers/:caregiverId/about" component={About} />
            <Route exact path="/caregivers/:caregiverId/papas" component={Papas} />
            {!hideCreditCardFields && (
              <Route exact path="/caregivers/:caregiverId/credit-cards" component={CreditCards} />
            )}

            <Redirect from="/caregivers/:caregiverId" to="/caregivers/:caregiverId/visits" />
          </Switch>
        </Main>

        {caregiver && (
          <Actions
            action={action}
            caregiver={caregiver}
            onClose={() => setAction(DataTableMenuActions.NoAction)}
            onRefetch={handleRefetch}
          />
        )}
      </Container>
    </>
  );
};

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

export default Details;
