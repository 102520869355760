import React from "react";

import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import Text from "components/Text";
import { businessTypeOptions } from "constants/businessType";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Business } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useModalToggle } from "hooks/useModalToggle";
import { BusinessForm } from "modals/Businesses/CreateUpdateBusiness";
import { mapValueToLabel } from "utils/helpers/formatters";

interface Props {
  business: Business;
  onRefetch: () => void;
}

const Overview: React.FC<Props> = ({ business, onRefetch }) => {
  const {
    capitalized: { singularEntityName },
  } = useCallItClientProgramOrBusiness();

  const { isOpen: isOverviewEditOpen, toggle: toggleOverviewEdit } = useModalToggle();

  const account = business?.account?.data;

  const { id: accountId, createdBy, stripeCustomerId, stripeSubscriptionId, plan } = account ?? {};

  const email = business?.email;

  if (!business?.id) {
    return null;
  }

  return (
    <Panel.Side>
      <Panel.Item>
        <Text bold>ID</Text>
        <Text>{business.id}</Text>
      </Panel.Item>

      <Panel.Item>
        <Text bold>Account ID</Text>
        {accountId}
      </Panel.Item>

      <Panel.Item>
        <Text bold>E-mail</Text>
        {email ? (
          <Text title={email} wrapString>
            {email}
          </Text>
        ) : (
          EMPTY_PLACEHOLDER
        )}
      </Panel.Item>

      <Panel.Item>
        <Text bold>Papa's Dedicated Number</Text>
        <Text>{business.inboundMemberSupportNumber}</Text>
      </Panel.Item>

      {business.type && (
        <Panel.Item>
          <Text bold>{singularEntityName} Type</Text>
          <Text>{mapValueToLabel(businessTypeOptions, business.type)}</Text>
        </Panel.Item>
      )}

      {business.eligibleLifeS3Folder && (
        <Panel.Item>
          <Text bold>Eligible Lives Import S3 Folder</Text>
          <Text>{business.eligibleLifeS3Folder}</Text>
        </Panel.Item>
      )}

      {business.eligibleEmployeeS3Folder && (
        <Panel.Item>
          <Text bold>Eligible Employees Import S3 Folder</Text>
          <Text>{business.eligibleEmployeeS3Folder}</Text>
        </Panel.Item>
      )}

      <Panel.Item>
        <Text bold>Stripe customer ID</Text>
        <Text>{stripeCustomerId || EMPTY_PLACEHOLDER}</Text>
      </Panel.Item>

      <Panel.Item>
        <Text bold>Stripe subscription ID</Text>
        <Text>{stripeSubscriptionId || EMPTY_PLACEHOLDER}</Text>
      </Panel.Item>

      <Panel.Item>
        <Text bold>Membership</Text>
        <Text>{plan?.data?.name || EMPTY_PLACEHOLDER}</Text>
      </Panel.Item>

      <Panel.Item>
        <Text bold>Created by</Text>
        {createdBy?.data?.fullName ? (
          <>
            <Text>{createdBy?.data?.fullName}</Text>
            <Text>{createdBy?.data?.email}</Text>
          </>
        ) : (
          EMPTY_PLACEHOLDER
        )}
      </Panel.Item>

      <Panel.Item>
        <Text bold> Uses Custom Visit Lead Hours</Text>
        <Text>{business.useCustomVisitLeadHours ? "Yes" : "No"}</Text>
      </Panel.Item>

      {business.useCustomVisitLeadHours && (
        <Panel.Item>
          <Text bold>Custom Visit Lead Hours</Text>
          <Text>{business.customVisitLeadHours}</Text>
        </Panel.Item>
      )}

      <Panel.Item>
        <Text bold>Is admin</Text>
        <Text>{account?.permissions?.admin ? "Yes" : "No"}</Text>
      </Panel.Item>

      <Panel.Item>
        <Text bold>Requires signature</Text>
        <Text>{account?.requiresSignature ? "Yes" : "No"}</Text>
      </Panel.Item>

      <Panel.Item>
        <Text bold>Requires drug tested pals</Text>
        <Text>{account?.requiresDrugTest ? "Yes" : "No"}</Text>
      </Panel.Item>

      <Panel.Item>
        <Text bold>Is test account</Text>
        <Text>{account?.testAccount ? "Yes" : "No"}</Text>
      </Panel.Item>

      <Button type="button" block onClick={toggleOverviewEdit}>
        Edit
      </Button>

      <ModalLayout
        toggle={toggleOverviewEdit}
        title={`Update ${singularEntityName}`}
        isOpen={isOverviewEditOpen}
      >
        <BusinessForm
          businessId={business.id}
          closeModal={toggleOverviewEdit}
          onSubmitFinish={onRefetch}
        />
      </ModalLayout>
    </Panel.Side>
  );
};

export default Overview;
