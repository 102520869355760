import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { SEARCH_RESULT_BY_BUSINESS } from "components/Search/gql";
import { Business } from "generated/types";
import { SearchResult } from "types/search";

export const useBusinessSearch = (data: Business[]) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: SEARCH_RESULT_BY_BUSINESS,
        variables: {
          search: `*${value}*`,
          index: "business",
        },
      });
      if (data?.searchResult?.data) {
        return data.searchResult.data.map(({ id, name, phoneNumber }: SearchResult) => {
          return {
            value: id,
            label: name,
            phone: phoneNumber,
            name,
          };
        });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };

  const searchPillNames = data?.reduce(
    (obj, { id, name }) => ({
      ...obj,
      [id!]: name,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
