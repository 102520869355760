import React from "react";
import { Route, Switch } from "react-router-dom";

import CaregiversDetails from "./Details/Details";
import List from "./List";

const CaregiverRoutes = () => {
  return (
    <Switch>
      <Route exact path="/caregivers" component={List} />
      <Route path="/caregivers/:caregiverId" component={CaregiversDetails} />
    </Switch>
  );
};

export default CaregiverRoutes;
