import { useState } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Caregiver } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";

export const useCaregiverActions = () => {
  const { createPapa } = useUrlQuery() as {
    createPapa?: string;
  };

  const [caregiver, setCaregiver] = useState<Caregiver | null>(null);
  const [action, setCaregiverAction] = useState(
    createPapa ? DataTableMenuActions.AddPapa : DataTableMenuActions.NoAction
  );

  const setAction = (action: DataTableMenuActions, caregiver: Caregiver | null = null) => {
    setCaregiver(caregiver);
    setCaregiverAction(action);
  };

  return {
    caregiver,
    action,
    setAction,
  };
};
