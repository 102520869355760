import React, { FunctionComponent } from "react";

import { formatOptionLabel } from "components/Search";
import Select, { OptionsType } from "components/Select";
import { PalWithVisitMetadataResult } from "generated/types";

interface Props {
  name: string;
  pals: Array<PalWithVisitMetadataResult | null | undefined>;
  placeholder: string;
}

const SyncPalSelect: FunctionComponent<Props> = ({
  name,
  pals,
  placeholder,
  ...passthroughProps
}) => {
  const formattedOptions = pals?.map((pal) => {
    return {
      value: pal?.id ?? "",
      label: pal?.fullName ?? "",
      phone: pal?.phoneNumber ?? "",
      isPreferred: pal?.isFavorite ?? "",
    };
  });

  return (
    <Select
      options={formattedOptions as OptionsType}
      name={name}
      placeholder={placeholder}
      formatOptionLabel={formatOptionLabel}
      customStyles={palSelectStyles}
      isClearable={true}
      {...passthroughProps}
    />
  );
};

export default SyncPalSelect;

const palSelectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles.control,
    display: "flex",
    height: state.hasValue ? "5.7rem" : "2.25rem",
  }),
};
