import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { SEARCH_RESULT_BY_CAREGIVER } from "components/Search/gql";
import { Caregiver } from "generated/types";
import { SearchResult } from "types/search";

export const useCaregiversSearch = (data: Caregiver[], businessId?: string) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: SEARCH_RESULT_BY_CAREGIVER,
        variables: {
          search: `*${value}*`,
        },
      });
      if (data?.searchResult?.data) {
        return data.searchResult.data
          .filter((entity: SearchResult) => (businessId ? entity?.businessId === businessId : true))
          .map(({ id, fullName, account }: SearchResult) => {
            return {
              value: id,
              label: fullName,
              name: fullName,
              email: account?.data?.email,
              phone: account?.data?.phoneNumber,
            };
          });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };

  const searchPillNames = data?.reduce(
    (obj, { id, fullName }) => ({
      ...obj,
      [id!]: fullName,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
