import React from "react";
import styled from "styled-components";

import { confirm } from "components/Confirm";
import { Gender, useGenderMismatchCheckLazyQuery } from "generated/types";

export const useHasGenderPreferenceMismatchConfirm = () => {
  const [genderMismatchCheck] = useGenderMismatchCheckLazyQuery();

  return async (variables: {
    papaId: string;
    favoritePalIds: string[];
    gender?: Gender | null;
  }) => {
    if (!variables.gender || variables.gender === Gender.Diverse) return true;

    const { data } = await genderMismatchCheck({ variables, fetchPolicy: "network-only" });

    const hasFavoritePalMismatch = (data?.pals?.data ?? []).length > 0;
    const hasVisitsWithPalMismatch = (data?.visits?.data ?? []).length > 0;

    const showAlert = hasFavoritePalMismatch || hasVisitsWithPalMismatch;

    if (!showAlert) return true;

    return await confirm(
      <StyledList>
        {hasFavoritePalMismatch && (
          <li>
            This member has preferred pals who do not match their gender preferences. These
            preferred pals will not see visits for this member.
          </li>
        )}
        {hasVisitsWithPalMismatch && (
          <li>
            This member has upcoming visits with pals who do not match their gender preferences. We
            will remove those pals from the upcoming visits.
          </li>
        )}
      </StyledList>,
      {
        header: "Please be aware",
        confirmBtnText: "Confirm change",
      }
    );
  };
};

const StyledList = styled.ul`
  padding: 0;
  margin: 0;

  li {
    margin: 20px;
  }
`;
