import { Payment as PaymentIcon } from "@styled-icons/material-twotone/Payment";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";

import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";

import Activity from "./Activity";
import BillingPoints from "./BillingPoints";
import Overview from "./Overview";

type Props = {
  id: string;
};

const Container = styled("div")`
  display: flex;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

const PaymentDetails = ({ match }: RouteComponentProps<Props>) => {
  useTopBarOptions({
    title: "Payment Details",
    icon: PaymentIcon,
    breadcrumbs: ["Payments", { label: "Members Payments", link: "/payments/members-payments" }],
  });

  const { id } = match.params;

  return (
    <>
      <Container>
        <Overview />

        <Main>
          <Nav>
            <Nav.Link to={`/payments/members-payments/${id}/billingpoints`}>
              Billing Points
            </Nav.Link>
            <Nav.Link to={`/payments/members-payments/${id}/activity`}>Activity</Nav.Link>
          </Nav>

          <Switch>
            <Route
              exact
              path="/payments/members-payments/:id/billingpoints"
              component={BillingPoints}
            />
            <Route exact path="/payments/members-payments/:id/activity" component={Activity} />
            <Redirect
              from="/payments/members-payments/:id"
              to="/payments/members-payments/:id/billingpoints"
            />
          </Switch>
        </Main>
      </Container>
    </>
  );
};

export default PaymentDetails;
