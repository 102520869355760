import React, { useState } from "react";

import PalActionsMenu from "components/ActionsMenu/PalActionMenu";
import { usePalActions } from "components/ActionsMenu/PalActionMenu/usePalActions";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { languageOptions } from "constants/language";
import { Pal, PalSorting, SortDirection } from "generated/types";
import { formatDateTime, formatGender, mapValueToLabel } from "utils/helpers/formatters";

interface Params {
  sorting: PalSorting;
  onSortChange: (sort: PalSorting) => void;
}

export const usePalTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { pal, action, setAction } = usePalActions();

  const [sorting, setSorting] = useState<PalSorting>(initialSorting);

  const onSetSorting = (sortKey: keyof PalSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const columns: Column<Pal>[] = [
    {
      header: { title: "Full name" },
      dataField: "fullName",
    },
    {
      header: { title: "Contact" },
      dataField: "email",
      formatter({ email, phoneNumber }) {
        return (
          <>
            <Text>{email}</Text>
            <Text>{phoneNumber}</Text>
          </>
        );
      },
    },
    {
      header: { title: "Home location" },
      dataField: "address",
      formatter({ account }) {
        const palOnboardingZipcode = account?.data?.palOnboarding?.data?.zipcode;

        return (
          (account?.data?.homeLocation?.data?.address || EMPTY_PLACEHOLDER) +
          (palOnboardingZipcode ? ` (${palOnboardingZipcode})` : "")
        );
      },
    },
    {
      header: { title: "City" },
      dataField: "city",
      formatter({ account }) {
        return account?.data?.homeLocation?.data?.city || "";
      },
    },
    {
      header: { title: "Sex" },
      dataField: "gender",
      formatter({ gender, genderText }) {
        return formatGender(gender, genderText);
      },
    },
    {
      header: { title: "Languages" },
      dataField: "languages",
      formatter({ languages }) {
        if (!languages?.data?.length) return EMPTY_PLACEHOLDER;
        return (
          <>
            {languages.data.map((language: string, index: number) => (
              <Text key={`language_${index}`}>{mapValueToLabel(languageOptions, language)}</Text>
            ))}
          </>
        );
      },
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(pal) {
        return <PalActionsMenu pal={pal} setAction={setAction} isListMenu />;
      },
    },
  ];

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction, null);
  };

  return {
    columns,
    action,
    pal,
    closeModal,
  };
};
