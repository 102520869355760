import { Relationship } from "generated/types";

export const relationshipOptions = [
  { value: Relationship.Aunt, label: "Aunt" },
  { value: Relationship.Brother, label: "Brother" },
  { value: Relationship.Client, label: "Client" },
  { value: Relationship.Dad, label: "Dad" },
  { value: Relationship.Friend, label: "Friend" },
  { value: Relationship.Grandfather, label: "Grand Father" },
  { value: Relationship.Grandmother, label: "Grand Mother" },
  { value: Relationship.Husband, label: "Husband" },
  { value: Relationship.Mom, label: "Mom" },
  { value: Relationship.Myself, label: "Myself" },
  { value: Relationship.Sister, label: "Sister" },
  { value: Relationship.Uncle, label: "Uncle" },
  { value: Relationship.Wife, label: "Wife" },
];

export const relationshipLabel: {
  [key in Relationship]: string;
} = {
  [Relationship.Aunt]: "Aunt",
  [Relationship.Brother]: "Brother",
  [Relationship.Client]: "Client",
  [Relationship.Dad]: "Dad",
  [Relationship.Friend]: "Friend",
  [Relationship.Grandfather]: "Grand Father",
  [Relationship.Grandmother]: "Grand Mother",
  [Relationship.Husband]: "Husband",
  [Relationship.Mom]: "Mom",
  [Relationship.Myself]: "Myself",
  [Relationship.Sister]: "Sister",
  [Relationship.Uncle]: "Uncle",
  [Relationship.Wife]: "Wife",
  [Relationship.Children]: "Children",
  [Relationship.Grandparents]: "Grandparents",
  [Relationship.OtherLovedOne]: "Other Loved One",
  [Relationship.Parents]: "Parents",
  [Relationship.SpousePartner]: "Spouse/Partner",
};
