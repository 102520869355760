import { gql } from "@apollo/client";

export const LOCATIONS_SEARCH = gql`
  query SearchLocations(
    $filter: [LocationFilter]
    $pagination: PaginationInput
    $sorting: LocationSorting
  ) {
    locations(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        address
        address2
        nickname
        timezone

        # for time recommendation
        state
        city
        county

        supportOperatingHours {
          startTime
          endTime
        }
      }
      pagination {
        limit
        totalCount
      }
    }
  }
`;
