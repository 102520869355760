import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { useState } from "react";
import Helmet from "react-helmet";

import Button from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import SmartTable from "components/SmartTable";
import { Account } from "generated/types";
import { AccountListResult, AccountSorting, SortDirection } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useAccountsSearch } from "utils/hooks/useAccountsSearch";
import { nonNull } from "utils/nonNull";

import Actions from "./Actions";
import { GET_ADMINS_LIST } from "./gql";
import { useAdminsTableColumns } from "./useAdminsTableColumns";

interface Data {
  accounts: AccountListResult;
}

const AdminsList = () => {
  useTopBarOptions({ title: "Admins", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const [sorting, setSorting] = useState<AccountSorting>({
    insertedAt: SortDirection.Desc,
  });
  const { columns, action, account, setAction, closeModal } = useAdminsTableColumns({
    sorting,
    onSortChange: setSorting,
  });
  const { filter, pills, setPills } = useSearch({
    permissions: {
      admin: true,
    },
    deletedAt: { eq: null },
  });
  const { data, loading, error, refetch } = useQuery<Data>(GET_ADMINS_LIST, {
    variables: {
      filter,
      sorting,
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const handleRefetch = async () => {
    await refetch();
  };

  const accounts = data?.accounts;
  const { loadOptions, searchPillNames } = useAccountsSearch({
    data: nonNull(accounts?.data),
    query: GET_ADMINS_LIST,
    isAdmin: true,
  });

  return (
    <div>
      <Helmet>
        <title>Admins - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <FlexRow>
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder="Search by ID, Name"
          />
        </FlexRow>

        <FlexRow>
          <Button action onClick={() => setAction(DataTableMenuActions.Create)}>
            Create admin
          </Button>
        </FlexRow>
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />

      <SmartTable<Account>
        keyField="id"
        localStorageKey="admins"
        data={accounts?.data}
        pagination={accounts?.pagination}
        loading={loading}
        error={error}
        columns={columns}
        cursorPagination
        rowProps={({ id }) => ({
          linkTo: `/members/${id}`,
        })}
      />

      <Actions action={action} account={account} onClose={closeModal} onRefetch={handleRefetch} />
    </div>
  );
};

export default AdminsList;
