import React from "react";
import { Route, Switch } from "react-router-dom";

import AccountLogs from "./AccountLogs";
import SubjectLogs from "./SubjectLogs";

const ActivityLogsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/accounts/:id/activity-logs/subject" component={SubjectLogs} />
      <Route exact path="/accounts/:id/activity-logs" component={AccountLogs} />
    </Switch>
  );
};

export default ActivityLogsRoutes;
