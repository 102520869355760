import { gql } from "@apollo/client";

export const GET_VISIT_PROGRAMS = gql`
  query visitPrograms(
    $filter: [VisitProgramFilter]
    $pagination: PaginationInput
    $sorting: VisitProgramSorting
  ) {
    visitPrograms(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        name
        insertedAt
        isActive
        visitTemplates {
          pagination {
            totalCount
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
