import { Formik } from "formik";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import queryString from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ButtonLink } from "components/Button";
import { OnlyCheckbox as Checkbox } from "components/Checkbox/OnlyCheckbox";
import Select from "components/Select";
import TaskButtons from "components/TaskButtons";
import { demandProfileOptions } from "constants/demandProfile";
import { locationOptions } from "constants/location";
import { VisitState } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { mapSelectValuesToOptions } from "utils/select";

import { defaultStates } from "./constants";

interface Props {
  loading: boolean;
}

export const Filter: React.FC<Props> = ({ loading }) => {
  const pageInfo = usePageInfo();
  const history = useHistory();

  const [form, setForm] = useState({
    visitStates: pageInfo.states ? pageInfo.states?.split(",") : defaultStates,
    isCritical: pageInfo.isCritical === "true",
    isGrievancesAppeals: pageInfo.isGrievancesAppeals === "true",
    isEmployerVisit: pageInfo.isEmployerVisit === "true",
    isPreviouslyMissed: pageInfo.isPreviouslyMissed === "true",
    isUberEligible: pageInfo.isUberEligible === "true",
    isVisitLeadTimeException: pageInfo.isVisitLeadTimeException === "true",
    taskIds: pageInfo.taskIds?.split(",") ?? ([] as string[]),
    state: pageInfo.state ?? [],
    demandProfile: pageInfo.demandProfile ?? "",
  });

  const hasFilters =
    history.location.search &&
    !isEqual(Object.keys(queryString.parse(history.location.search)), ["page"]);

  const handleChange = (newForm: any) => {
    const query = queryString.stringify(
      {
        page: pageInfo.page,
        isCritical: newForm.isCritical ? "true" : null,
        isGrievancesAppeals: newForm.isGrievancesAppeals ? "true" : null,
        isEmployerVisit: newForm.isEmployerVisit ? "true" : null,
        isPreviouslyMissed: newForm.isPreviouslyMissed ? "true" : null,
        isUberEligible: newForm.isUberEligible ? "true" : null,
        isVisitLeadTimeException: newForm.isVisitLeadTimeException ? "true" : null,
        taskIds: newForm.taskIds.join(","),
        states: newForm.visitStates.join(","),
        state: newForm.state,
        demandProfile: newForm.demandProfile,
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: "index" }
    );

    if (!query && !history.location.search) {
      return;
    }

    if (`?${query}` !== history.location.search) {
      history.push(`?${query}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = React.useMemo(() => debounce(handleChange, 300), []);

  const handleSelectChange = (name: string) => (value: any) => {
    const newForm = { ...form, [name]: value };

    setForm(newForm);
    action(newForm);
  };

  const handleCheckboxToggle = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    let newForm = { ...form };

    if (defaultStates.includes(name.toUpperCase())) {
      const clickedState = name.toUpperCase();
      newForm = {
        ...form,
        visitStates: checked
          ? [...form.visitStates, clickedState]
          : form.visitStates.filter((state) => state !== clickedState),
      };
    } else {
      newForm = {
        ...form,
        ...(name === "isCritical" && checked
          ? { isCritical: true, isGrievancesAppeals: false }
          : name === "isGrievancesAppeals" && checked
          ? { isCritical: false, isGrievancesAppeals: true }
          : { [name]: checked }),
      };
    }

    setForm(newForm);
    action(newForm);
  };

  const handleTaskChange = (value: string[]) => {
    const newForm = { ...form, taskIds: value };

    setForm(newForm);
    action(newForm);
  };

  const handleCleanFilters = () => {
    setForm({
      visitStates: defaultStates,
      isCritical: false,
      isGrievancesAppeals: false,
      isEmployerVisit: false,
      isPreviouslyMissed: false,
      isUberEligible: false,
      isVisitLeadTimeException: false,
      taskIds: [],
      state: "",
      demandProfile: "",
    });
    history.push("/visits-dashboard");
  };

  return (
    <Formik initialValues={form} onSubmit={noop} enableReinitialize>
      <Container>
        <TaskButtons name="taskIds" onChange={handleTaskChange} small />

        <Row>
          <SelectContainer>
            <Select
              name="state"
              options={locationOptions}
              isSearchable={true}
              placeholder="US States"
              aria-label="state"
              onChange={handleSelectChange("state")}
              isClearable
              isMulti
            />
          </SelectContainer>
          <SelectContainer>
            <Select
              name="demandProfile"
              options={demandProfileOptions}
              placeholder="County demand profile"
              aria-label="demand profile"
              onChange={handleSelectChange("demandProfile")}
              value={mapSelectValuesToOptions(form.demandProfile ?? "", demandProfileOptions) ?? ""}
              isClearable
              isMulti
            />
          </SelectContainer>
        </Row>
        <Row>
          <Checkbox
            label="Accepted"
            name="accepted"
            onChange={handleCheckboxToggle}
            checked={form.visitStates.includes(VisitState.Accepted)}
            disabled={loading}
          />
          <Checkbox
            label="Confirmed"
            name="confirmed"
            onChange={handleCheckboxToggle}
            checked={form.visitStates.includes(VisitState.Confirmed)}
            disabled={loading}
          />
          <Checkbox
            label="Enroute"
            name="enroute"
            onChange={handleCheckboxToggle}
            checked={form.visitStates.includes(VisitState.Enroute)}
            disabled={loading}
          />
          <Checkbox
            label="Pending"
            name="pending"
            onChange={handleCheckboxToggle}
            checked={form.visitStates.includes(VisitState.Pending)}
            disabled={loading}
          />
          <Checkbox
            label="Partner Op"
            name="partner_operated"
            onChange={handleCheckboxToggle}
            checked={form.visitStates.includes(VisitState.PartnerOperated)}
            disabled={loading}
          />
        </Row>
        <Row>
          <Checkbox
            label="Critical"
            name="isCritical"
            data-testid="isCritical"
            onChange={handleCheckboxToggle}
            checked={form.isCritical}
            disabled={loading}
          />
          <Checkbox
            label="Grievances and Appeals"
            name="isGrievancesAppeals"
            data-testid="isGrievancesAppeals"
            onChange={handleCheckboxToggle}
            checked={form.isGrievancesAppeals}
            disabled={loading}
          />
          <Checkbox
            label="Previously Missed"
            name="isPreviouslyMissed"
            data-testid="isPreviouslyMissed"
            onChange={handleCheckboxToggle}
            checked={form.isPreviouslyMissed}
            disabled={loading}
          />
          <Checkbox
            label="Employer Market"
            name="isEmployerVisit"
            data-testid="isEmployerVisit"
            onChange={handleCheckboxToggle}
            checked={form.isEmployerVisit}
            disabled={loading}
          />
          <Checkbox
            label="Member OK with Uber"
            name="isUberEligible"
            data-testid="isUberEligible"
            onChange={handleCheckboxToggle}
            checked={form.isUberEligible}
            disabled={loading}
          />
          <Checkbox
            label="Visit lead time exception"
            name="isVisitLeadTimeException"
            data-testid="isVisitLeadTimeException"
            onChange={handleCheckboxToggle}
            checked={form.isVisitLeadTimeException}
            disabled={loading}
          />
          {hasFilters && (
            <Button color="muted" size="small" onClick={handleCleanFilters}>
              Clean Filters
            </Button>
          )}
        </Row>
      </Container>
    </Formik>
  );
};

export default Filter;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 10px;
  margin-bottom: 1rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  margin-bottom: 1rem;
`;

const SelectContainer = styled.div`
  max-width: 14rem;
  width: 100%;
`;

const Button = styled(ButtonLink)`
  padding: 2px 7px;
`;
