import { useState } from "react";

import { VisitMenuActions } from "constants/visit";

export const useVisitActions = () => {
  const [visitId, setActionVisitId] = useState<string | null>(null);
  const [palId, setActionPalId] = useState<string | null>(null);
  const [action, setVisitAction] = useState(VisitMenuActions.NoAction);
  const [warmTransferData, setwarmTransferData] = useState({});

  const setAction = (
    action: VisitMenuActions,
    visitId?: string | null,
    palId: string | null = null,
    warmTransferData: any = {}
  ) => {
    setActionVisitId(visitId ?? null);
    setActionPalId(palId);
    setVisitAction(action);
    setwarmTransferData(warmTransferData);
  };

  return {
    visitId,
    palId,
    action,
    warmTransferData,
    setAction,
  };
};
