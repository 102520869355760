import { Moment } from "moment-timezone";
import * as yup from "yup";

interface DateRange {
  startDate: Moment | null;
  endDate: Moment | null;
}

export interface Schema {
  range: DateRange;
  memberType: string | null;
  memberId: string | null;
}

export const schema = yup.object().shape({
  range: yup.object().shape({
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
  }),
  memberType: yup.string().nullable(),
  memberId: yup.string().nullable(),
});
