import { useQuery } from "@apollo/client";
import capitalize from "lodash/capitalize";
import React from "react";
import { useParams } from "react-router-dom";

import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { PaymentSingleResult } from "generated/types";
import { formatNumberToCurrency } from "utils/numbers/currency";

import { GET_PAYMENT_OVERVIEW } from "../gql";

interface Data {
  payment?: PaymentSingleResult;
}

const Overview = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<Data>(GET_PAYMENT_OVERVIEW, {
    variables: { id: id },
  });

  const { state, total } = data?.payment?.data ?? {};

  if (loading) return <Panel.Side />;
  if (error)
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );

  return (
    <Panel.Side>
      <div>
        <Panel.Item>
          <Text bold>ID</Text>
          <Text>{id}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Status</Text>
          <Text>{state ? capitalize(state) : "(empty)"}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Total</Text>
          <Text>{formatNumberToCurrency(total ?? 0)}</Text>
        </Panel.Item>
      </div>
    </Panel.Side>
  );
};

export default Overview;
