import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import StatusTag from "components/Tag/StatusTag";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { VisitRating } from "generated/types";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";

export const usePalReviewTableColumns = () => {
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [id, setId] = useState<string | null>(null);
  const columns: Column<VisitRating>[] = [
    {
      header: { title: "Status" },
      dataField: "approved",
      cellProps: { noWrap: true },
      formatter({ approved }) {
        return <StatusTag>{approved}</StatusTag>;
      },
    },
    {
      header: { title: "Visit" },
      dataField: "visit.data.id",
      cellProps: { noWrap: true },
      formatter({ visit }) {
        if (visit?.data?.pal?.data) {
          return <Link to={`/visits/${visit.data.id}`}>View Visit</Link>;
        }
        return EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Papa" },
      dataField: "visit.data.papa.data.id",
      cellProps: { noWrap: true },
      formatter({ visit }) {
        if (visit?.data?.papa?.data) {
          const papaConcealedEntity = papaConcealed(visit.data.papa?.data);
          return (
            <Link to={`/papas/${papaConcealedEntity?.id}`}>{papaConcealedEntity?.fullName}</Link>
          );
        }
        return EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Pal" },
      dataField: "visit.data.pal.data.id",
      cellProps: { noWrap: true },
      formatter({ visit }) {
        if (visit?.data?.pal?.data) {
          return <Link to={`/pals/${visit.data.pal.data.id}`}>{visit.data.pal.data.fullName}</Link>;
        }
        return EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Comment" },
      dataField: "commentForPal",
    },
    {
      header: { title: "Rating" },
      dataField: "ratingForPal",
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        align: "center",
        noWrap: true,
      },
      formatter({ visit }) {
        if (visit?.data?.id) {
          const id = visit.data.id;
          return (
            <ActionButtons>
              <Button
                size="small"
                variant="success"
                onClick={() => menuActionsHandler(DataTableMenuActions.Approve, id)}
              >
                Approve
              </Button>
              <Button
                size="small"
                variant="danger"
                onClick={() => menuActionsHandler(DataTableMenuActions.Reject, id)}
              >
                Reject
              </Button>
            </ActionButtons>
          );
        }
        return null;
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, id?: string) => {
    if (!id) return;

    setAction(action);
    setId(id);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setId(null);
  };

  return {
    columns,
    action,
    id,
    closeModal,
  };
};

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;

  Button + Button {
    margin-left: 0.5rem;
  }
`;
