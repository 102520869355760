import { VehicleType } from "generated/types";

export const yearOptions = (() => {
  const current = new Date().getFullYear();

  return Array.from({ length: 19 }, (year, index) => {
    const value = current - index;

    return { value, label: String(value) };
  });
})();

export interface VehicleData {
  vehicle?: VehicleType;
  vehicleColor?: string;
  vehicleModel?: string;
  vehicleYear?: string;
}
