import { ApolloError } from "@apollo/client";
import React from "react";

import SmartTable from "components/SmartTable";
import { Pagination } from "generated/types";

import { getColumns } from "./getColumns";

interface Props<T> {
  logs: T[];
  pagination?: Pagination | null;
  isLoading: boolean;
  error: ApolloError;
}

export const StatusLogsTable = <T,>({ logs, pagination, isLoading, error }: Props<T>) => (
  <SmartTable<T>
    keyField="id"
    data={logs}
    pagination={pagination}
    loading={isLoading}
    columns={getColumns<T>()}
    error={error}
  />
);
