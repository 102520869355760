import { Formik } from "formik";
import React from "react";

import Button from "components/Button";
import Form from "components/Form";
import { BiographyQuestions } from "generated/types";

import { Schema, schema } from "./schema";
import { Container, FormFooter, StyledForm } from "./styles";

interface Props {
  questions: string[];
  answers: BiographyQuestions[];
  onEditFinish: () => void;
  onSubmit: (answers: BiographyQuestions[]) => Promise<void>;
}

export const EditBio: React.FC<Props> = ({ answers, questions, onSubmit, onEditFinish }) => {
  const handleSubmit = (values: Schema) => {
    const biographyQuestions = values.answers.map((answer, index) => ({
      question: questions[index],
      answer,
    }));

    onSubmit(biographyQuestions);
  };

  const initialValues: Schema = {
    answers: answers.length
      ? answers.map(({ answer }) => answer || "")
      : Array.from({ length: questions.length }),
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <StyledForm>
          <Container>
            {questions.map((question, index) => (
              <Form.Group key={index}>
                <Form.Label>{question}</Form.Label>
                <Form.TextArea name={`answers[${index}]`} fullWidth />
              </Form.Group>
            ))}
          </Container>

          <FormFooter>
            <Button type="button" variant="secondary" onClick={onEditFinish}>
              Close
            </Button>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </FormFooter>
        </StyledForm>
      </Formik>
    </>
  );
};
