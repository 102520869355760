import { ApolloError } from "@apollo/client";
import { InfoCircle } from "@styled-icons/boxicons-regular/InfoCircle";
import React, { useState } from "react";
import styled from "styled-components";

import { ItemsCount } from "components/DataTables/VisitsTable/ItemsCount";
import Heading from "components/Heading";
import PaginationQueryString from "components/Pagination/PaginationQueryString";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import { Pagination, VisitForDashboardCardFragment } from "generated/types";

import { VisitPreview } from "../VisitPreview";
import { Card } from "./Card";

interface Props {
  visits: VisitForDashboardCardFragment[];
  loading: boolean;
  pagination?: Pagination | null;
  error?: ApolloError;
}

const LateVisits = ({ visits, loading, error, pagination }: Props) => {
  const [visitId, setVisitId] = useState("");

  const totalCount = pagination?.totalCount;
  const totalPages = pagination?.totalPages;

  if (error) return <QueryErrors error={error} />;

  return (
    <>
      <Heading as="h2">
        At Risk Visits{" "}
        <Tooltip
          content={
            <InfoBox>
              <Text bold>At Risk Visits Criteria:</Text>
              <Text bold>Pending:</Text>
              <Text>3 hours before start time or less</Text>
              <Text bold>Enroute:</Text>
              <Text>Exactly on start time or less</Text>
              <Text bold>Confirmed:</Text>
              <Text>30 mins before start time or less</Text>
              <Text bold>Accepted:</Text>
              <Text>3 hours before start time or less</Text>
            </InfoBox>
          }
        >
          <InfoCircle size={24} />
        </Tooltip>
      </Heading>

      {totalCount != null && <ItemsCount totalCount={totalCount} />}

      <Container>
        {loading
          ? Array.from(Array(16), (_, i) => i + 1).map((key) => <Card.Loading key={key} />)
          : visits.map((visit) => (
              <Card key={visit.id} visit={visit} setVisitId={(visitId) => setVisitId(visitId)} />
            ))}
      </Container>

      {!!totalPages && <PaginationQueryString align="right" totalPages={totalPages} />}
      {!!visitId && <VisitPreview visitId={visitId} onRequestClose={() => setVisitId("")} />}
    </>
  );
};

export default LateVisits;

const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
  margin-bottom: 40px;
  margin-top: 24px;
`;

const InfoBox = styled.div`
  font-weight: 16;
`;
