import React from "react";
import styled from "styled-components";

import Conditional from "components/Conditional";
import Form from "components/Form";
import { OnlyRadio } from "components/Radio/OnlyRadio";

type Props = {
  name: string;
  label: string;
  value: boolean | null;
  required?: boolean;
  showRequiredError?: boolean;
  onChange: (value: boolean) => void;
};

const YesNoRadioGroup = ({
  name,
  label,
  value,
  required = false,
  showRequiredError = false,
  onChange,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === "true");
  };

  return (
    <Container data-testid={name}>
      <Form.Label required={required}>{label}</Form.Label>
      <RadioGroupContainer>
        <RadioContainer>
          <OnlyRadio
            name={name}
            label="Yes"
            value="true"
            checked={value === true}
            onChange={handleChange}
          />
        </RadioContainer>
        <RadioContainer>
          <OnlyRadio
            name={name}
            label="No"
            value="false"
            checked={value === false}
            onChange={handleChange}
          />
        </RadioContainer>
      </RadioGroupContainer>
      <Conditional show={showRequiredError}>
        <Form.Feedback isInvalid>Required</Form.Feedback>
      </Conditional>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 15px;
`;

const RadioGroupContainer = styled.div`
  display: flex;
  align-items: center:
`;

const RadioContainer = styled.div`
  margin-right: 10px;
`;

export default YesNoRadioGroup;
