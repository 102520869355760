import { useField, useFormikContext } from "formik";
import moment, { Moment } from "moment-timezone";
import React, { CSSProperties, useState } from "react";
import styled, { css } from "styled-components";

import { ButtonNew } from "components/Button";
import { confirm } from "components/Confirm";
import { DayPickerCalendar } from "components/DayPickerCalendar";
import { Drawer } from "components/Drawer";
import Flex from "components/Flex";
import Form from "components/Form";
import { ConfirmModalIcon } from "components/Modal/ConfirmModal";
import Select from "components/Select";
import Text from "components/Text";
import { visitRecurrenceWeekdayOptions } from "constants/visit";
import { VisitRecurrenceFrequency, VisitRecurrenceWeekday } from "generated/types";

type RecurrenceDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  scheduleAfter: Moment;
  isOutsideEarliestAllowedDate: (day: Moment) => boolean;
  selectionMode: "selectStartDate" | "selectRecurrence";
  setSelectionMode: (mode: "selectStartDate" | "selectRecurrence") => void;
};

export const RecurrenceDrawer = ({
  isOpen,
  onClose,
  onCancel,
  scheduleAfter,
  isOutsideEarliestAllowedDate,
  selectionMode,
  setSelectionMode,
}: RecurrenceDrawerProps) => {
  const { values } = useFormikContext<{
    scheduledForDay: string;
    validUntil: string | null;
    count: number | null;
    endOnDate: boolean;
    freq:
      | VisitRecurrenceFrequency.Daily
      | VisitRecurrenceFrequency.Weekly
      | VisitRecurrenceFrequency.Fortnightly
      | VisitRecurrenceFrequency.Monthly
      | null;
  }>();
  const [, , validUntilHelpers] = useField("validUntil");
  const [, , countHelpers] = useField("count");
  const [, , byDayHelpers] = useField("byDay");
  const [hasBeenSaved, setHasBeenSaved] = useState(false);

  const scheduledForDay = values.scheduledForDay ? moment(values.scheduledForDay) : null;
  const validUntil = values.validUntil ? moment(values.validUntil) : null;
  const { endOnDate, freq, count } = values;

  const isEdit = false;

  const isAllowedDayForEnding = (day: Moment) => {
    if (isOutsideEarliestAllowedDate(day) || freq === null || scheduledForDay === null) return true;

    const scheduledForDayISO = scheduledForDay.toISOString();
    const dayISO = day.toISOString();

    if (freq === VisitRecurrenceFrequency.Daily) {
      return moment(scheduledForDayISO).isSameOrAfter(moment(dayISO), "day");
    } else if (freq === VisitRecurrenceFrequency.Weekly) {
      return !isDayWeekly(scheduledForDayISO, dayISO);
    } else if (freq === VisitRecurrenceFrequency.Fortnightly) {
      return !isDayBiweekly(scheduledForDayISO, dayISO);
    }

    return !isDayMonthly(scheduledForDayISO, dayISO);
  };

  const handleEndOnDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === "onDate") {
      countHelpers.setValue(null);
    } else {
      validUntilHelpers.setValue(null);
    }
  };

  const handleFreqChange = (option: any) => {
    if (
      scheduledForDay !== null &&
      (option === VisitRecurrenceFrequency.Fortnightly ||
        option === VisitRecurrenceFrequency.Weekly)
    ) {
      byDayHelpers.setValue(getByDayFromDay(scheduledForDay));
    } else {
      byDayHelpers.setValue(null);
    }
  };

  const handleDayPickerScheduledForDayChange = (day: string) => {
    if (
      day &&
      (freq === VisitRecurrenceFrequency.Fortnightly || freq === VisitRecurrenceFrequency.Weekly)
    ) {
      byDayHelpers.setValue(getByDayFromDay(day));
    } else {
      byDayHelpers.setValue(null);
    }
    setSelectionMode("selectRecurrence");
  };

  const isSelectionComplete = scheduledForDay && (validUntil || count);

  const handleCancel = async () => {
    if (scheduledForDay && hasBeenSaved && (validUntil !== null || count !== null)) {
      onClose();
      return;
    }

    if (
      !(await confirm(
        "Changing to a one time visit will remove the entire recurring series. Do you want to continue?",
        {
          header: "Change recurring to a one time visit?",
          icon: ConfirmModalIcon.none,
          cancelBtnText: "No, cancel",
          confirmBtnText: "Yes, continue",
        }
      ))
    )
      return;

    onCancel();
  };

  const handleSave = () => {
    setHasBeenSaved(true);
    onClose();
  };

  const startDateInvalid = scheduledForDay ? isOutsideEarliestAllowedDate(scheduledForDay) : false;
  const drawerTitle = hasBeenSaved ? "Edit recurring visit" : "Create recurring visit";

  return (
    <Drawer onClose={handleCancel} title={drawerTitle} open={isOpen} size="lg">
      <Drawer.Container>
        <Form.Group>
          <Form.Label>This visit is recurring</Form.Label>
          <Select
            name="freq"
            options={options}
            aria-label="state"
            isSearchable={false}
            onChange={handleFreqChange}
            styles={dropdownStyles}
          />
        </Form.Group>
        <Flex gridGap={20} marginBottom="1.25rem">
          <Button
            type="button"
            isActive={selectionMode === "selectStartDate"}
            onClick={() => setSelectionMode("selectStartDate")}
            startDateInvalid={startDateInvalid}
          >
            {scheduledForDay ? (
              <>
                Start <strong>{scheduledForDay.format("ddd, MMMM DD, YYYY")}</strong>
              </>
            ) : (
              "Add start date"
            )}
          </Button>
          <Button
            type="button"
            isActive={selectionMode === "selectRecurrence"}
            disabled={!scheduledForDay}
            onClick={() => setSelectionMode("selectRecurrence")}
          >
            {validUntil ? (
              <>
                End <strong>{validUntil.format("ddd, MMMM DD, YYYY")}</strong>
              </>
            ) : count ? (
              <>
                End after{" "}
                <strong>
                  {count} occurrence{count !== 1 ? "s" : ""}
                </strong>
              </>
            ) : (
              "Add end date (max 6 months out)"
            )}
          </Button>
        </Flex>
        {selectionMode === "selectStartDate" ? (
          <DayPickerCalendar
            name="scheduledForDay"
            isOutsideRange={isOutsideEarliestAllowedDate}
            onChange={handleDayPickerScheduledForDayChange}
            isFocus
          />
        ) : (
          <>
            <Form.Group>
              <Flex flexDirection="column" marginBottom="1rem" marginLeft="-0.938rem">
                <Form.Inline marginVertical="0.25rem">
                  <Form.Radio
                    controlId="onDate"
                    name="endOnDate"
                    value={true}
                    label="End on a specific day"
                    disabled={isEdit}
                    onChange={handleEndOnDateChange}
                  />
                </Form.Inline>
                <Form.Inline marginVertical="0.25rem">
                  <Form.Radio
                    controlId="onCount"
                    name="endOnDate"
                    value={false}
                    label="End after a number of occurrences"
                    disabled={isEdit}
                    onChange={handleEndOnDateChange}
                  />
                </Form.Inline>
              </Flex>

              {endOnDate ? (
                <DayPickerCalendar
                  name="validUntil"
                  isOutsideRange={isAllowedDayForEnding}
                  isFocus
                />
              ) : (
                <OccurenceInput
                  type="number"
                  name="count"
                  placeholder="0"
                  aria-label="Number of occurrences"
                  min="2"
                  disabled={isEdit}
                />
              )}
            </Form.Group>
          </>
        )}

        {selectionMode === "selectStartDate" && (
          <Text bold size="small">
            Must schedule on or after {scheduleAfter.format("MMMM DD")}.{" "}
            {startDateInvalid ? (
              <Text bold size="small" color={"warning"}>
                Recurring visits cannot begin before this date. Create a one time visit if override
                criteria applies.
              </Text>
            ) : (
              ""
            )}
          </Text>
        )}
      </Drawer.Container>
      <Drawer.Footer>
        <ButtonNew type="button" onClick={handleSave} disabled={!isSelectionComplete} size="small">
          Save recurring visit
        </ButtonNew>
      </Drawer.Footer>
    </Drawer>
  );
};

const options = [
  { value: VisitRecurrenceFrequency.Daily, label: "Daily" },
  { value: VisitRecurrenceFrequency.Weekly, label: "Weekly" },
  { value: VisitRecurrenceFrequency.Fortnightly, label: "Bi-Weekly" },
  { value: VisitRecurrenceFrequency.Monthly, label: "Monthly" },
];

const isDayWeekly = (baseDay: string, currentDay: string): boolean => {
  if (moment(baseDay).isSame(moment(currentDay), "days")) return false;
  if (moment(baseDay).diff(moment(currentDay), "days") % 7 === 0) {
    return true;
  }
  return false;
};

const isDayBiweekly = (baseDay: string, currentDay: string): boolean => {
  if (moment(baseDay).isSame(moment(currentDay), "day")) return false;
  if (moment(baseDay).diff(moment(currentDay), "days") % 14 === 0) {
    return true;
  }
  return false;
};

const isDayMonthly = (base: string, current: string): boolean => {
  const baseMoment = moment(base);
  const currentMoment = moment(current);
  if (baseMoment.isSameOrAfter(currentMoment, "month")) return false;

  const lastDayFromBase = baseMoment.clone().endOf("month").date();
  const lastDayFromCurrent = currentMoment.clone().endOf("month").date();
  const baseDate = baseMoment.date();
  const currentDate = currentMoment.date();

  if (lastDayFromCurrent < baseDate && lastDayFromCurrent < lastDayFromBase) {
    return currentDate === lastDayFromCurrent;
  }
  return currentDate === baseDate;
};

const getByDayFromDay = (day: string | Moment): VisitRecurrenceWeekday[] | null => {
  const dayOfWeek = moment(day).format("dddd");
  const find = visitRecurrenceWeekdayOptions.find(
    ({ label }) => dayOfWeek.toLowerCase() === label.toLowerCase()
  );

  if (find) {
    return [find.value];
  }
  return null;
};

const dropdownStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  container: (provided: CSSProperties, state: any) => ({
    ...provided,
    width: "7.3rem",
  }),
};

const Button = styled.button<{
  isActive?: boolean;
  startDateInvalid?: boolean;
}>`
  padding: 8px 14px;
  border-radius: 8px;
  color: #0e2765;
  font-size: 0.875rem;
  border: 1px solid #0e2765;
  background: white;
  cursor: pointer;
  margin: 1px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background: #c0d2ff;
    border: 2px solid #0e2765;
    margin: 1px;
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        margin: 0px;
      }
    `}

  &:disabled {
    opacity: 0.5;
    border: 1px solid #0e2765;
    background: white;
    box-shadow: none;
    cursor: default;
    margin: 0px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border: 2px solid #0e2765;
      background: #c0d2ff;
      cursor: default;
    `}

  ${({ startDateInvalid }) =>
    startDateInvalid &&
    css`
      border: 2px solid #ed1c24;
    `}
`;

const OccurenceInput = styled(Form.Input)`
  max-width: 7.3rem;
`;
