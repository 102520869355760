import { useMutation } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import { Drawer } from "components/Drawer";
import Form from "components/Form";
import Select from "components/Select";
import { livingSituationOptions } from "constants/livingSituations";
import {
  generateInitialSpecialConsiderations,
  generateSpecialConnsiderationsInput,
} from "constants/specialConsiderations";
import { LivingSituation, useSpecialConsiderationsQuery } from "generated/types";
import { Papa } from "generated/types";
import { nonNull } from "utils/nonNull";
import { mapSelectValuesToOptions } from "utils/select";

import { GET_PAPA_DETAILS } from "../../gql";
import { Schema, schema } from "./EditDetails.schema";
import { SpecialConsiderations } from "./SpecialConsiderations";
import { UPDATE_PAPA_DETAILS } from "./gql";

type Props = {
  papaId: string;
  papa: Papa;
  onEditFinish?: () => void;
};

const EditDetails = ({ papaId, papa, onEditFinish = () => {} }: Props): ReactElement => {
  const { data: specialConsiderationsData } = useSpecialConsiderationsQuery();
  const specialConsiderations = nonNull(specialConsiderationsData?.specialConsiderations?.data);

  const [updatePapa, { error: mutationError }] = useMutation(UPDATE_PAPA_DETAILS, {
    refetchQueries: [{ query: GET_PAPA_DETAILS, variables: { id: papaId } }],
  });

  const papaSpecialConsiderations = nonNull(papa?.papaSpecialConsiderations?.data);

  const initialValues: Schema = {
    description: papa?.description || "",
    livingSituation: papa?.livingSituation || null,

    ...generateInitialSpecialConsiderations(papaSpecialConsiderations),
  };

  const handleSubmit = async (input: Schema) => {
    try {
      const { data } = await updatePapa({
        variables: {
          id: papaId,
          input: {
            description: input.description,
            livingSituation: input.livingSituation,
            specialConsiderations: generateSpecialConnsiderationsInput(
              specialConsiderations,
              input
            ),
          },
        },
      });

      if (data?.updatePapa?.data) {
        toast.success("Papa details was edited with success!");
        onEditFinish();
      }
      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <FormikForm>
        <Drawer.Container>
          <SpecialConsiderations />
          <Form.Group>
            <Form.Label>Living Situation</Form.Label>
            <Select
              name="livingSituation"
              defaultValue={mapSelectValuesToOptions<LivingSituation>(
                initialValues.livingSituation,
                livingSituationOptions
              )}
              options={livingSituationOptions}
              isSearchable={false}
              placeholder="Select Living Situation"
              aria-label="Living Situation Select"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Notes</Form.Label>
            <Form.TextArea name="description" />
          </Form.Group>
        </Drawer.Container>

        <Drawer.Footer>
          <Form.SubmitButton action noContent>
            Save
          </Form.SubmitButton>
        </Drawer.Footer>
      </FormikForm>
    </Formik>
  );
};

export default EditDetails;
