import { gql } from "@apollo/client";

export const VISIT_PROGRAM = gql`
  query visitProgram($id: ID!) {
    visitProgram(id: $id) {
      data {
        id
        insertedAt
        businessId
        name
      }
    }
  }
`;

export const BUSINESS_NAME = gql`
  query businessName($id: ID!) {
    business(id: $id) {
      data {
        name
      }
    }
  }
`;

export const VISIT_TEMPLATES = gql`
  query visitTemplates($id: ID!, $pagination: PaginationInput, $sorting: VisitTemplateSorting) {
    visitProgram(id: $id) {
      data {
        id
        visitTemplates(pagination: $pagination, sorting: $sorting) {
          data {
            id
            name
            position
          }
          pagination {
            totalPages
          }
        }
      }
    }
  }
`;
