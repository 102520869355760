import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import ButtonLink from "components/ButtonLink";
import VisitsTable, { GET_VISITS_LIST } from "components/DataTables/VisitsTable";
import { FlexRow } from "components/Flex";
import ConfirmModal from "components/Modal/ConfirmModal";
import SearchPills from "components/Search/SearchPills";
import Search from "components/Search/VisitSearch";
import { useUrlSearchFilters } from "components/Search/useUrlSearchFilters";
import { VisitListResult, VisitSorting, VisitState } from "generated/types";
import { useLocalStorage } from "hooks/useLocalStorage";
import { usePageInfo } from "hooks/usePageInfo";
import { useSorting } from "hooks/useSorting";
import { VisitsFiltersToggle } from "pages/Visits/VisitsFiltersToggle";
import { nonNull } from "utils/nonNull";
import { visitFilterMaker } from "utils/visitFilters";
import { countFilters } from "utils/visitFilters";

import { VisitFilter } from "./VisitFilters/VisitFilter";
import { useVisitFilterInput } from "./VisitFilters/useVisitFilterInput";
import { useVisitSearch } from "./useVisitSearch";

type Data = {
  visits: VisitListResult;
};

export const visitsDefaultStates = [
  VisitState.Pending,
  VisitState.Accepted,
  VisitState.Confirmed,
  VisitState.PartnerOperated,
  VisitState.Enroute,
  VisitState.Started,
];

const Home = () => {
  const { page, limit, ...visitFilters } = usePageInfo();
  const { pathname } = useLocation();
  const history = useHistory();
  const [isBetaSearch, setIsBetaSearch] = useLocalStorage<boolean | null>("isBetaSearch", null);
  const [openBetaSearchConfirmModal, setOpenBetaSearchConfirmModal] = useState(false);

  const {
    filter: oldFilter,
    pills,
    setPills,
  } = useUrlSearchFilters(visitFilterMaker(visitFilters));
  const filterBeta = useVisitFilterInput();
  const [sorting, setSorting] = useSorting<VisitSorting>(["scheduledFor"]);

  const filter = isBetaSearch ? filterBeta : oldFilter;

  const toggleBetaSearch = () => {
    setIsBetaSearch(!isBetaSearch);
    setPills([]);
    history.push(pathname);
  };

  useEffect(() => {
    if (isBetaSearch === null) {
      setOpenBetaSearchConfirmModal(true);
    }
  }, [isBetaSearch, setIsBetaSearch]);

  const {
    data,
    loading: visitLoading,
    error,
  } = useQuery<Data>(GET_VISITS_LIST, {
    variables: {
      pagination: { page, limit },
      // @ts-ignore
      ...(Object.keys(filter).length && { filter }),
      sorting,
    },
  });

  const { loadOptions, searchPillNames } = useVisitSearch(pills);
  const countFilter = countFilters(visitFilters);

  return (
    <>
      <Helmet>
        <title>Visits - Papa Admin</title>
      </Helmet>
      <FlexRow justifyContent="flex-end">
        <BetaSearchButton onClick={toggleBetaSearch}>
          {isBetaSearch ? "Use old search" : "Use beta search"}
        </BetaSearchButton>
      </FlexRow>

      {isBetaSearch ? (
        <VisitFilter loading={visitLoading}>
          <ButtonLink to="/schedule-visit" action nowrap>
            Schedule visit
          </ButtonLink>
        </VisitFilter>
      ) : (
        <FlexRow justifyContent="space-between" FlexRowWrap="wrap-reverse">
          <FlexRow>
            <Search
              pills={pills}
              setPills={setPills}
              loadOptions={loadOptions}
              placeholder="Search by Papa or VisitId"
            />
            <VisitsFiltersToggle
              countFilterDefault={countFilter}
              visitFiltersDefault={visitFilters}
            />
          </FlexRow>

          <FlexRow>
            <ButtonLink to="/schedule-visit" action>
              Schedule visit
            </ButtonLink>
          </FlexRow>
        </FlexRow>
      )}

      {!isBetaSearch && (
        <SearchPills
          pills={pills}
          setPills={setPills}
          names={searchPillNames}
          loading={visitLoading}
        />
      )}

      <VisitsTable
        data={nonNull(data?.visits?.data)}
        loading={visitLoading}
        sorting={sorting}
        onSortChange={setSorting}
        pagination={data?.visits?.pagination}
        error={error}
      />

      <ConfirmModal
        isOpen={openBetaSearchConfirmModal}
        cancelBtnText="No"
        confirmBtnText="Yes"
        onCancel={() => {
          setIsBetaSearch(false);
          setOpenBetaSearchConfirmModal(false);
        }}
        onConfirm={() => {
          toggleBetaSearch();
          setOpenBetaSearchConfirmModal(false);
        }}
      >
        Do you want to use the beta search functionality?
      </ConfirmModal>
    </>
  );
};

export default Home;

const BetaSearchButton = styled.button`
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
  font-family: inherit;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;
