import groupBy from "lodash/groupBy";
import moment from "moment";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import Conditional from "components/Conditional";
import SimpleSelect from "components/Select/Simple";
import Text from "components/Text";
import {
  BusinessPolicy,
  BusinessPolicySingleResult,
  IntervalUnit,
  Maybe,
  PapaAllotmentPeriod,
} from "generated/types";
import { allotmentPeriodSelected } from "utils/segment";

type AllotmentPeriod = Pick<PapaAllotmentPeriod, "id" | "startDate" | "endDate"> & {
  businessPolicy?: Maybe<BusinessPolicySingleResult>;
};

interface Props {
  allotmentPeriods: AllotmentPeriod[];
  businessPolicy: BusinessPolicy;
  onChange: (ap: AllotmentPeriod, label?: string) => void;
  value: AllotmentPeriod | null;
  initialAllotmentPeriod?: PapaAllotmentPeriod | null;
  isOnlyShowingAvailablePeriods?: boolean;
  id?: string;
}

const AllotmentPeriodSelect: React.FC<Props> = ({
  allotmentPeriods,
  businessPolicy,
  onChange,
  value,
  initialAllotmentPeriod,
  isOnlyShowingAvailablePeriods = false,
  id,
}) => {
  const routeMatch = useRouteMatch();

  // this is to initialize the selected allotment period on load to be the one for today.
  useEffect(() => {
    let selectedAllotmentPeriod;

    if (initialAllotmentPeriod) {
      selectedAllotmentPeriod = initialAllotmentPeriod;
    } else {
      selectedAllotmentPeriod = allotmentPeriods?.find((x) =>
        moment().isBetween(x.startDate, x.endDate, "day", "[]")
      );
    }
    if (selectedAllotmentPeriod) onChange(selectedAllotmentPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentBizPolicyIntervalUnitIsYearlyOrBp =
    (businessPolicy.intervalUnit === IntervalUnit.Years &&
      moment(businessPolicy.endsAt).diff(businessPolicy.startsAt, "months") <= 12) ||
    businessPolicy.intervalUnit === IntervalUnit.BusinessPolicy;

  //// CALLBACKS ////
  const onSelectedAllotmentPeriodChanged = (selectedAllotmentPeriod: any) => {
    const selectedId = selectedAllotmentPeriod.value;
    const newSelection = allotmentPeriods?.find((it) => it.id === selectedId);

    if (newSelection) {
      onChange(newSelection, selectedAllotmentPeriod.label);
      allotmentPeriodSelected(selectedId, routeMatch.path);
    }
  };

  // Allotment Period Dropdown
  const allotmentPeriodOptions: AllotmentPeriodOption[] | GroupedAllotmentPeriodOption[] =
    getAllotmentPeriodOptions(allotmentPeriods, businessPolicy, isOnlyShowingAvailablePeriods);

  const currentAllotmentPeriodText = !!value
    ? allotmentPeriodOptionLabel(value, businessPolicy)
    : "";

  const defaultAllotmentPeriod: AllotmentPeriodOption | null = getDefaultAllotmentPeriod(
    value,
    allotmentPeriodOptions
  );
  return (
    <>
      <Conditional show={currentBizPolicyIntervalUnitIsYearlyOrBp}>
        <Text>{currentAllotmentPeriodText}</Text>
      </Conditional>
      <Conditional show={!currentBizPolicyIntervalUnitIsYearlyOrBp}>
        <SimpleSelect
          aria-label="currentAllotmentPeriod"
          id={id}
          name="currentAllotmentPeriod"
          options={allotmentPeriodOptions}
          customStyles={selectStyles}
          isSearchable={false}
          defaultValue={defaultAllotmentPeriod}
          onChange={onSelectedAllotmentPeriodChanged}
        />
      </Conditional>
    </>
  );
};

// Retrieve allotment period options, sorted by date, and grouped by year if applicable.
//
// When grouped by year, the result will look like:
// [{label: '2023',
//   options: [
//     {label: 'Jan 2023', value: 'eb809210-ac11-4ebd-9837-3920ce2f2db8'}
//   ]
// }]
//
// Otherwise it will just be a list of ungrouped options:
// [{label: 'Jan 2023', value: 'eb809210-ac11-4ebd-9837-3920ce2f2db8'}]
const getAllotmentPeriodOptions = (
  allotmentPeriods: AllotmentPeriod[],
  businessPolicy: BusinessPolicy,
  isOnlyShowingAvailablePeriods: boolean
): AllotmentPeriodOption[] | GroupedAllotmentPeriodOption[] => {
  const isGroupedByYear =
    businessPolicy.intervalUnit === IntervalUnit.Quarters ||
    businessPolicy.intervalUnit === IntervalUnit.Months;

  const isAPInCurrentOrFutureBP = (ap: AllotmentPeriod) =>
    moment(ap.businessPolicy?.data?.endsAt).isSameOrAfter(moment());

  const isAPCurrentOrFuture = (ap: AllotmentPeriod) => {
    if (isOnlyShowingAvailablePeriods) {
      const endDate = moment(ap.endDate).startOf("day");
      const today = moment().startOf("day");

      return endDate.isSameOrAfter(today);
    } else {
      return true;
    }
  };

  const activeAllotmentPeriods = allotmentPeriods
    .filter(isAPInCurrentOrFutureBP)
    .filter(isAPCurrentOrFuture)
    .map((ap) => ({
      label: allotmentPeriodOptionLabel(ap, businessPolicy),
      value: ap.id,
      startDate: moment(ap.startDate).toDate(),
      year: moment(ap.startDate).year(),
    }))
    .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

  if (isGroupedByYear) {
    const groupedByYear = groupBy(activeAllotmentPeriods, "year");

    return Object.keys(groupedByYear).map((year) => ({
      label: year,
      options: groupedByYear[year].map((item) => ({
        label: item.label,
        value: item.value,
      })),
    }));
  } else {
    return (
      activeAllotmentPeriods?.map((ap) => ({
        label: ap.label,
        value: ap.value,
      })) || []
    );
  }
};

//// HELPERS ////

interface AllotmentPeriodOption {
  label: string;
  value: string;
}

interface GroupedAllotmentPeriodOption {
  label: string;
  options: AllotmentPeriodOption[];
}
// This is more complex than I'd like because depending on the interval unit,
// the options are either grouped or they're flat. There's probably a better
// solution to be found here.
const getDefaultAllotmentPeriod = (
  currentAllotmentPeriod: AllotmentPeriod | null | undefined,
  allotmentPeriodOptions: AllotmentPeriodOption[] | GroupedAllotmentPeriodOption[]
): AllotmentPeriodOption | null => {
  if (!currentAllotmentPeriod) return null;

  const findOption = (options: AllotmentPeriodOption[], value: string) =>
    options.find((opt) => opt.value === currentAllotmentPeriod.id);

  const isGrouped = (option: any): option is GroupedAllotmentPeriodOption =>
    (option as GroupedAllotmentPeriodOption).options !== undefined;

  for (const option of allotmentPeriodOptions) {
    if (isGrouped(option)) {
      const foundOption = findOption(option.options, currentAllotmentPeriod.id);
      if (foundOption) return foundOption;
    } else {
      if (option.value === currentAllotmentPeriod.id) return option;
    }
  }

  return null;
};

// Returns labels shown to the user in the allotment period dropdown
const allotmentPeriodOptionLabel = (ap: AllotmentPeriod, businessPolicy: BusinessPolicy) => {
  if (
    businessPolicy.intervalUnit === IntervalUnit.BusinessPolicy ||
    businessPolicy.intervalUnit === IntervalUnit.Years
  ) {
    // Business Policy: 'Jan 2024 - Dec 2024'
    // Years          : 'Jan 2024 - Dec 2024'
    return `${moment(ap.startDate).format("MMM YYYY")} - ${moment(ap.endDate).format("MMM YYYY")}`;
  } else if (businessPolicy.intervalUnit === IntervalUnit.Quarters) {
    // Quarters       : 'Jan - Mar 2024'
    return `${moment(ap.startDate).format("MMM")} - ${moment(ap.endDate).format("MMM YYYY")}`;
  } else {
    // Months         : 'Jan 2024'
    return `${moment(ap.startDate).format("MMM YYYY")}`;
  }
};

//// STYLES ////
const selectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles.control,
    display: "flex",
    fontWeight: "bold",
    minHeight: "2.75rem",
  }),
  menuList: (baseStyles: any, state: any) => ({
    ...baseStyles.menuList,
    fontWeight: "bold",
    overflow: "scroll",
    maxHeight: "12rem",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles.option,
    padding: ".75rem 0 .75rem .5rem",
    color: state.isSelected && "#0000EE",
    background: state.isSelected ? "#EAEFFF" : "white",
    boxShadow: "0 -1px 0 #DFE3E9",
  }),
};

export default AllotmentPeriodSelect;
