import { inflect, singularize } from "inflection";
import { flow, toLower } from "lodash/fp";
import moment, { MomentBuiltinFormat, MomentSetObject } from "moment-timezone";

import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { genderOptions } from "constants/gender";
import {
  Gender,
  IntervalUnit,
  Submission,
  SurveyScoreFormula,
  VehicleInsuranceStatus,
} from "generated/types";
import { Option } from "utils/select";

export function formatVisitDuration(value: number | undefined | null) {
  if (value === undefined || value === null) {
    return EMPTY_PLACEHOLDER;
  }

  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  return `${hours} h, ${minutes} mins`;
}

export function formatBusinessPolicyDateTime(
  value: string | undefined | null,
  format = "MM/DD/YYYY h:mm A",
  timeset: MomentSetObject,
  timezone?: string
) {
  if (value === undefined || value === null) {
    return EMPTY_PLACEHOLDER;
  }

  const date = timezone ? moment(value).tz(timezone) : moment(value);

  return date.isValid() ? date.set(timeset).format(format) : EMPTY_PLACEHOLDER;
}

export function formatDateTime(
  value: string | undefined | null,
  options?: {
    format?: string;
    timezone?: string | null;
    defaultToOriginal?: boolean;
    sourceFormat?: string | MomentBuiltinFormat;
  }
) {
  const {
    format = "MM/DD/YYYY h:mm A",
    timezone,
    defaultToOriginal = false,
    sourceFormat,
  } = options ?? {};

  if (value === undefined || value === null) {
    return EMPTY_PLACEHOLDER;
  }

  try {
    if (!moment(value).isValid()) {
      return defaultToOriginal ? value : EMPTY_PLACEHOLDER;
    }

    const date = timezone ? moment(value, sourceFormat).tz(timezone) : moment(value);

    return date.isValid() ? date.format(format) : defaultToOriginal ? value : EMPTY_PLACEHOLDER;
  } catch (err) {
    return EMPTY_PLACEHOLDER;
  }
}

export function mapValueToLabel(options: Option<any>[], value: any) {
  const foundOption = options.find((option) => option.value === value);

  return foundOption ? foundOption.label : value;
}

export const boolToText = (value: boolean | undefined): string => (value ? "Yes" : "No");

export const welcomeVideoBooltoText = (value: boolean | undefined): string =>
  value ? "Watched" : "Not Watched";

export const formatVehicleInsuranceStatus = (status: VehicleInsuranceStatus) => {
  switch (status) {
    case VehicleInsuranceStatus.Approved:
      return "Approved";
    case VehicleInsuranceStatus.NoVehicle:
      return "No Vehicle";
    case VehicleInsuranceStatus.PendingReview:
      return "Pending Review";
    case VehicleInsuranceStatus.PendingUpload:
      return "Pending Upload";
    default:
      return "";
  }
};

export const formatGender = (gender?: Gender | null, genderText?: string | null) => {
  if (!gender) {
    return EMPTY_PLACEHOLDER;
  }

  if (gender === Gender.Diverse && genderText) {
    return genderText;
  }

  return mapValueToLabel(genderOptions, gender);
};

export const formatAssessmentsResult = (submission: Submission) => {
  const { score, survey } = submission;
  if (!score || survey?.data?.formula === SurveyScoreFormula.Mean) {
    return "";
  }
  if (score <= 3) {
    return "Not Lonely";
  }
  if (score < 7) {
    return "Lonely";
  }

  return "Severely Lonely";
};

export const minutesToHours = (minutes = 0, precision = 1): string => {
  if (!minutes) {
    return "0";
  }

  const hours = minutes / 60;

  return hours.toFixed(precision);
};

export const getIntervalString = (intervalUnit: IntervalUnit): string => {
  return flow(toLower, singularize)(intervalUnit);
};

export const durationToHoursAndMinutes = (duration: number): string => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  const hoursStr = `${hours} ${inflect("hour", hours)}`;
  const minutesStr = `${minutes} ${inflect("minute", minutes)}`;

  if (hours > 0 && minutes > 0) {
    return `${hoursStr} and ${minutesStr}`;
  } else if (hours > 0) {
    return hoursStr;
  } else {
    return minutesStr;
  }
};

export const formatMilesFromMeters = (meters?: number | null) => {
  if (meters == null) return EMPTY_PLACEHOLDER;

  return `${(meters / 1609.34).toFixed(2)} miles`;
};
