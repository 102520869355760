import { gql } from "@apollo/client";

export const ADD_GRIEVANCES_APPEAL_FLAG = gql`
  mutation addGrievancesAppealFlag($visitId: ID!, $reason: VisitFlagReason!, $description: String) {
    createVisitFlag(
      input: {
        visitId: $visitId
        name: GRIEVANCES_APPEALS
        reason: $reason
        description: $description
      }
    ) {
      data {
        id
        name
        visit {
          data {
            id
            visitFlags {
              data {
                id
                resolvedAt
                description
              }
            }
          }
        }
      }
    }
  }
`;
