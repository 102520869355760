import { useApolloClient } from "@apollo/client";
import { useField } from "formik";
import React, { FunctionComponent } from "react";
import Async from "react-select/async";
import { ValueType } from "react-select/src/types";
import styled from "styled-components";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import { SEARCH_MEMBER } from "components/UserSelect/gql";
import { Account, useSearchMembersQuery } from "generated/types";
import { nonNull } from "utils/nonNull";

interface Props {
  withUnassigned?: boolean;
  name: string;
  initialValue?: string | null;
  placeholder?: string;
  isDisabled?: boolean;
}

const CareConciergeAssignSelect: FunctionComponent<Props> = ({
  withUnassigned = true,
  name,
  initialValue = "",
  placeholder = "",
  isDisabled = false,
}) => {
  const client = useApolloClient();
  const [, , { setValue }] = useField(name);
  const { data: initialValueData, loading: initialValueLoading } = useSearchMembersQuery({
    variables: {
      filter: { id: { eq: initialValue } },
    },
    skip: !initialValue,
  });

  const commonFilterForOptions = {
    roleNames: {
      contain: "CareConcierge",
    },
    permissions: {
      admin: true,
    },
    deletedAt: { eq: null },
  };

  const paginationForOptions = {
    limit: 50,
  };

  const { data: defaultOptionsData, loading: defaultOptionsLoading } = useSearchMembersQuery({
    variables: {
      filter: commonFilterForOptions,
      pagination: paginationForOptions,
    },
  });

  const loading = initialValueLoading || defaultOptionsLoading;

  const accountsForInitialValue = nonNull(initialValueData?.accounts?.data);
  const defaultValue = accountsForInitialValue?.[0]
    ? {
        value: accountsForInitialValue[0].id ?? "",
        label: accountsForInitialValue[0].fullName ?? "",
      }
    : undefined;

  const accountsForDefaultOptions = nonNull(defaultOptionsData?.accounts?.data);
  const defaultOptions = [
    ...(withUnassigned ? [{ value: " ", label: "Unassigned" }] : []),
    ...accountsForDefaultOptions
      .map((account: Account) => ({
        value: account.id ?? "",
        label: account.fullName ?? "",
      }))
      // sort by label alphabetically
      .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label)),
  ];

  const handleLoadOptions = async (value: string) => {
    const { data } = await client.query({
      query: SEARCH_MEMBER,
      variables: {
        filter: {
          ...commonFilterForOptions,
          fullName: {
            cont: value,
          },
        },
        pagination: paginationForOptions,
      },
    });

    if (data?.accounts?.data) {
      return [
        ...(withUnassigned ? [{ value: " ", label: "Unassigned" }] : []),
        ...data.accounts.data
          .map((account: Account) => ({
            value: account.id,
            label: account.fullName,
          }))
          // sort by label alphabetically
          .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label)),
      ];
    }

    return [];
  };

  const handleChange = (value: ValueType<{ label: string; value: string }>) => {
    setValue((value as { label: string; value: string }).value);
  };

  if (loading) return <SpinningIcon size={18} />;

  return (
    <Container>
      <Async
        aria-label={name}
        name={name}
        placeholder={placeholder}
        loadOptions={(value = "") => handleLoadOptions(value)}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        onChange={handleChange}
        isDisabled={isDisabled}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 300px;
`;

export default CareConciergeAssignSelect;
