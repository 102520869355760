declare global {
  interface Window {
    analytics: any;
  }
}

export type ActionMenuAnalytics = {
  action: string;
  buttonLocation: string;
};

export function page(category: String, name: String, properties: any) {
  // These properties are omitted in the tracking plan downstream
  // There is concern the default values added by analytics.js may at times contain PII
  const propertiesToSanitize = {
    referrer: null,
    search: null,
    title: null,
    url: null,
  };

  const sanitizedProperties = Object.assign(properties, propertiesToSanitize);

  window.analytics.page(category, name, sanitizedProperties);
}

export function identify(
  userID: string,
  {
    email,
    roles,
    papaTeam,
  }: { email?: string; roles?: (string | undefined)[] | undefined; papaTeam?: string } = {}
) {
  window.analytics.identify(userID, { email: email, roles: roles, papa_team: papaTeam });
}

export function cancelPriorityBonusButtonClicked() {
  window.analytics.track("Cancel Priority Bonus Button Clicked");
}

export function createPriorityBonusButtonClicked() {
  window.analytics.track("Create Priority Bonus Button Clicked");
}

export function startAgentVisitClicked() {
  window.analytics.track("Start Agent Visit Clicked");
}

export function reasonCodeSearched(keyword: string) {
  window.analytics.track("Reason Code Searched", { keyword });
}

export function reasonCodeSelected(keyword: string, reasonCode: string) {
  window.analytics.track("Reason Code Selected", { keyword, reason_code: reasonCode });
}

export function palPaymentsAmountDescriptionDropdownClicked() {
  window.analytics.track("Pal Payments Amount Description Dropdown Clicked");
}

export function virtualVisitOptionSelected(value: string) {
  window.analytics.track("Virtual Visit Option Selected", {
    visit_type: "Virtual",
    vv_type: value,
  });
}

export function virtualVisitCanceled() {
  window.analytics.track("Virtual Visit Canceled");
}

export function virtualVisitStarted(value: string) {
  window.analytics.track("Virtual Visit Started", { visit_type: "Virtual", vv_type: value });
}

export function allotmentHoursToggled(expanded: boolean) {
  window.analytics.track("Allotment Hours Toggled", { expanded: expanded });
}

export function creditEvent(
  action:
    | "clicked view credits"
    | "closed view credits"
    | "clicked show credit details"
    | "clicked hide credit details"
    | "clicked add credit"
    | "answered if credit will resolve issue"
    | "selected credit allotment period"
    | "selected credit amount"
    | "selected credit reason"
    | "clicked submit credit"
    | "clicked cancel credit submission"
    | "clicked confirm remove credit"
    | "clicked remove credit"
    | "clicked keep credit",
  data: {
    from_screen?: string;
    button_location?: "Hours UI component";
    papa_id?: string | null;
    selection?: string | boolean | number;
  }
) {
  window.analytics.track("Credit Event", { ...data, action });
}

export function allotmentPeriodSelected(selectedAllotmentPeriod: string, fromScreen: string) {
  window.analytics.track("Allotment hour action", {
    action: selectedAllotmentPeriod,
    fromScreen: fromScreen,
  });
}

export function actionMenuClicked(analyticsData: ActionMenuAnalytics, fromScreen: string) {
  window.analytics.track("Action Menu Clicked", { ...analyticsData, fromScreen: fromScreen });
}

export function assessmentNotCompleted(reason: string, accountId: string, papaId: string) {
  window.analytics.track("Assessment not completed", {
    reason: reason,
    account_id: accountId,
    papa_id: papaId,
  });
}

export function visitSchedulingAction(
  visitSchedulingSection: "mobility support needs" | "tasks" | "save visit",
  action:
    | "answered assistance in out car"
    | "answered use mobility aid"
    | "answered mobility aid car fit"
    | "selected task"
    | "deselected task"
    | "answered delivery question"
    | "clicked submit visit",
  selection: string | boolean | null,
  papaId: string
) {
  window.analytics.track("Visit scheduling action", {
    visitSchedulingType: "scheduled new visit",
    visitSchedulingSection: visitSchedulingSection,
    action: action,
    selection: selection,
    papaId: papaId,
  });
}
