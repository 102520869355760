import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import PriorityBonusActions from "components/ActionsMenu/PriorityBonusActionsMenu/Actions";
import { PriorityBonusMenuActions as DataTableMenuActions } from "components/ActionsMenu/PriorityBonusActionsMenu/models";
import Button from "components/Button";
import { FlexRow } from "components/Flex";
import Heading from "components/Heading";
import SmartTable from "components/SmartTable";
import { PriorityBonus, VisitSingleResult, VisitState } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";

import CurrentBonusTable from "./CurrentBonusTable";
import { GET_PRIORITY_BONUSES } from "./gql";
import { usePriorityBonusesTableColumns } from "./usePriorityBonusesTableColumns";

interface Props {
  visitState?: VisitState | null;
}

const PriorityBonuses: React.FC<Props> = ({ visitState }) => {
  const { visitId } = useParams<{ visitId: string }>();
  const { columns } = usePriorityBonusesTableColumns();
  const [action, setAction] = useState(DataTableMenuActions.noAction);

  const { data, loading, error, refetch } = useQuery<{
    visit?: VisitSingleResult | null;
  }>(GET_PRIORITY_BONUSES, {
    variables: { visitId: visitId },
  });

  const { isSupervisor } = useCurrentAccountRole();

  const cancelPriorityBonus = () => {
    setAction(DataTableMenuActions.cancelPriorityBonus);
  };

  const createPriorityBonus = () => {
    setAction(DataTableMenuActions.createPriorityBonus);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.noAction);
  };

  const handleRefetch = async () => {
    await refetch();
  };

  const priorityBonuses = data?.visit?.data?.priorityBonuses?.data;
  const currentBonus = priorityBonuses?.filter((bonus) => bonus.current).shift();
  const previouslyOfferedBonuses = priorityBonuses?.filter((bonus) => !bonus.current);

  return (
    <>
      <Helmet>
        <title>Priority Bonuses - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="flex-end" wrap="wrap-reverse">
        <FlexRow>
          <Button
            action
            onClick={createPriorityBonus}
            disabled={isSupervisor ? false : visitState !== VisitState.Pending}
          >
            Update Bonus
          </Button>
        </FlexRow>
      </FlexRow>

      <Heading color="primary">Current Bonus</Heading>
      <CurrentBonusTable
        loading={loading}
        currentBonus={currentBonus}
        cancelPriorityBonus={cancelPriorityBonus}
      />

      <Heading color="primary">Previously Offered</Heading>

      <SmartTable<PriorityBonus>
        keyField="id"
        data={previouslyOfferedBonuses}
        loading={loading}
        columns={columns}
        error={error}
      />

      <PriorityBonusActions
        visitId={visitId}
        visitState={visitState}
        action={action}
        onClose={closeModal}
        onRefetch={handleRefetch}
      />
    </>
  );
};

export default PriorityBonuses;
