import { useQuery } from "@apollo/client";
import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import isNull from "lodash/isNull";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";

import VisitActionsMenu from "components/ActionsMenu/VisitActionsMenu";
import Actions from "components/ActionsMenu/VisitActionsMenu/Actions";
import { useVisitActions } from "components/ActionsMenu/VisitActionsMenu/useVisitActions";
import FlexRow from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import QueryErrors from "components/QueryErrors";
import VirtualCall from "components/VirtualCall";
import { VisitMenuActions } from "constants/visit";
import { VisitDetailsDocument, VisitSingleResult } from "generated/types";
import { useCurrentCall } from "hooks/useCurrentCall";
import { useUrlQuery } from "hooks/useUrlQuery";

import Activity from "./Activity/Activity";
import VisitDetails from "./Details";
import Invitations from "./Invitations";
import Overview from "./Overview";
import PalsInTheArea from "./PalsInTheArea";
import Papa from "./Papa";
import PriorityBonuses from "./PriorityBonuses";
import Surveys from "./Surveys/Surveys";
import VisitRoute from "./VisitRoute";

type Props = {
  id: string;
};

const Details = ({ match }: RouteComponentProps<Props>) => {
  const { id } = match.params;
  const { fromPapa = false, fromPal = false } = useUrlQuery();
  const { currentCall } = useCurrentCall();
  const { action, warmTransferData, setAction } = useVisitActions();

  const { data, error, refetch, loading } = useQuery<{
    visit?: VisitSingleResult | null;
  }>(VisitDetailsDocument, {
    variables: { id },
  });

  const visit = data?.visit?.data ?? {};
  const timezone = visit?.location?.data?.timezone;

  const handleCompleteVisit = () => {
    refetch();
  };

  const papa = visit?.papa?.data;
  const pal = visit?.pal?.data;

  const preservedQueryParams = fromPal ? "?fromPal=true" : fromPapa ? "?fromPapa=true" : "";

  const breadcrumbs = [
    "Visits",
    !!fromPapa && papa
      ? {
          label: `${papa?.fullName}'s Visits`,
          link: `/papas/${papa?.id}/visits`,
        }
      : !!fromPal && pal
      ? {
          label: `${pal?.fullName}'s Visits`,
          link: `/pals/${pal?.id}/visits`,
        }
      : { label: "All Visits", link: "/" },
  ];

  useTopBarOptions({
    title: "Visit Details",
    name: papa?.fullName ?? "",
    icon: AccessTime,
    breadcrumbs,
    deps: [papa, pal],
  });

  if (!loading && (!visit || isNull(visit))) {
    return (
      <>
        <h1>404 :-(</h1>
        <p>visit not found</p>
      </>
    );
  }

  return (
    <>
      <Container>
        <Overview data={{ visit: { data: visit } }} loading={loading} />
        <Main>
          <FlexRow justifyContent="space-between" alignItems="center">
            <Nav>
              <Nav.Link to={`/visits/${id}/details${preservedQueryParams}`}>Details</Nav.Link>
              <Nav.Link to={`/visits/${id}/activity${preservedQueryParams}`}>Activity</Nav.Link>
              <Nav.Link to={`/visits/${id}/papa${preservedQueryParams}`}>Papa</Nav.Link>
              <Nav.Link to={`/visits/${id}/pals-in-the-area${preservedQueryParams}`}>
                Pals in the area
              </Nav.Link>
              <Nav.Link to={`/visits/${id}/route${preservedQueryParams}`}>Route</Nav.Link>
              <Nav.Link to={`/visits/${id}/surveys${preservedQueryParams}`}>Surveys</Nav.Link>
              <Nav.Link to={`/visits/${id}/bonuses${preservedQueryParams}`}>Bonuses</Nav.Link>
              <Nav.Link to={`/visits/${id}/invitations${preservedQueryParams}`}>
                Pals Invited
              </Nav.Link>
            </Nav>
            {visit && (
              <Action>
                <VisitActionsMenu setAction={setAction} visit={visit} />
              </Action>
            )}
            <FlexRow justifyContent="flex-end">
              <VirtualCall
                visitId={visit?.id}
                isHidden={
                  !(
                    (["PENDING", "ACCEPTED", "CONFIRMED"].includes(visit?.state as string) &&
                      visit?.isVirtual) ||
                    currentCall?.id === visit?.id
                  )
                }
                onCompleteVisit={handleCompleteVisit}
              />
            </FlexRow>
          </FlexRow>

          <QueryErrors error={error} />

          <Switch>
            <Route
              exact
              path="/visits/:id/details"
              render={() => <VisitDetails visit={visit} loading={loading} />}
            />
            <Route
              exact
              path="/visits/:id/activity"
              render={() => <Activity timezone={timezone} />}
            />
            <Route exact path="/visits/:id/papa" render={() => <Papa visit={visit} />} />
            <Route exact path="/visits/:id/pals-in-the-area" component={PalsInTheArea} />
            <Route exact path="/visits/:id/invitations" component={Invitations} />
            <Route exact path="/visits/:id/route" component={VisitRoute} />
            <Route exact path="/visits/:id/surveys" render={() => <Surveys visit={visit} />} />
            <Route
              exact
              path="/visits/:visitId/bonuses"
              render={() => <PriorityBonuses visitState={data?.visit?.data?.state} />}
            />
            <Redirect from="/visits/:id" to={`/visits/:id/details${preservedQueryParams}`} />
          </Switch>
        </Main>{" "}
        {visit?.id && (
          <Actions
            action={action}
            visitId={visit.id}
            warmTransferData={warmTransferData}
            onClose={() => setAction(VisitMenuActions.NoAction)}
          />
        )}
      </Container>
    </>
  );
};

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

const Action = styled.div`
  margin-left: 20px;
  margin-bottom: 1.875rem;
`;

export default Details;
