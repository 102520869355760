import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import Button from "components/Button";
import VisitsTable, { GET_VISITS_LIST } from "components/DataTables/VisitsTable";
import { ModalLayout } from "components/Modal/ModalLayout";
import {
  SortDirection,
  VisitListResult,
  VisitSorting,
  VisitState,
  VisitsQueryVariables,
} from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { useSorting } from "hooks/useSorting";
import { VisitFiltersModal } from "modals/VisitFiltersModal";
import { nonNull } from "utils/nonNull";
import { countFilters, visitFilterMaker } from "utils/visitFilters";

interface Data {
  visits: VisitListResult;
}

const Visits = () => {
  const { page, limit, ...visitFilters } = usePageInfo(Object.values(VisitState));
  const { id } = useParams<{ id: string }>();
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const [sorting, setSorting] = useSorting<VisitSorting>(["scheduledFor"], {
    scheduledFor: SortDirection.Desc,
  });
  const { data, loading, error } = useQuery<Data, VisitsQueryVariables>(GET_VISITS_LIST, {
    variables: {
      filter: [
        {
          pal: {
            idEq: id,
          },
          ...visitFilterMaker(visitFilters),
        },
      ],
      sorting,
      pagination: { page, limit },
    },
  });

  const countFilter = countFilters(visitFilters);

  const handleFiltersChange = () => {
    toggleFiltersModal();
  };

  return (
    <>
      <Helmet>
        <title>Pal Visits - Papa Admin</title>
      </Helmet>
      <p>
        <Button type="button" onClick={toggleFiltersModal}>
          <span>Filter</span>
          {countFilter > 0 && <span>({countFilter})</span>}
        </Button>
      </p>
      <VisitsTable
        data={nonNull(data?.visits?.data)}
        loading={loading}
        sorting={sorting}
        onSortChange={setSorting}
        pagination={data?.visits?.pagination}
        error={error}
        fromPal
      />

      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Visits Filter"
        isOpen={isFiltersModalOpen}
      >
        <VisitFiltersModal onSubmit={handleFiltersChange} allStatesDefault />
      </ModalLayout>
    </>
  );
};

export default Visits;
