import React, { ReactElement } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { ModalLayout } from "components/Modal/ModalLayout";
import { Account } from "generated/types";
import { DeleteAccountModal } from "modals/Accounts/DeleteAccount";
import EditOverview from "modals/Accounts/EditOverview";
import { ResetPasswordModal } from "modals/ResetPasswordModal/ResetPasswordModal";

import { CreateAdminForm } from "./CreateAdminForm/CreateAdminForm";

interface Props {
  action: DataTableMenuActions;
  account: Account | null;
  onClose: () => void;
  onRefetch: () => void;
}

const Actions = ({ action, account, onClose, onRefetch }: Props): ReactElement => {
  return (
    <>
      <ModalLayout
        toggle={onClose}
        title={"Edit Admin Account"}
        isOpen={action === DataTableMenuActions.Edit}
      >
        <EditOverview
          account={account ?? {}}
          toggleShow={onClose}
          forAdmin
          onEditFinish={onRefetch}
        />
      </ModalLayout>

      <DeleteAccountModal
        account={account}
        isOpen={action === DataTableMenuActions.Delete}
        toggle={onClose}
        onDeleteFinish={onRefetch}
      />

      <ResetPasswordModal
        email={account?.email || ""}
        isOpen={action === DataTableMenuActions.ResetPassword}
        toggle={onClose}
      />

      <ModalLayout
        toggle={onClose}
        title="Create admin account"
        isOpen={action === DataTableMenuActions.Create}
      >
        <CreateAdminForm onClose={onClose} onCreateFinish={onRefetch} />
      </ModalLayout>
    </>
  );
};

export default Actions;
