import { Formik, Form as FormikForm } from "formik";
import moment, { Moment } from "moment-timezone";
import queryString, { Stringifiable } from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import Form from "components/Form";
import InputDateRange from "components/InputDateRange";
import {
  CheckboxContainer,
  Container,
  FilterTitle,
  FilterType,
  Footer,
  StyledFormGroup,
} from "components/Modal/FilterModal.styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import { paymentStateOptions } from "constants/payment";
import { PaymentState } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

interface FormValues {
  states: PaymentState[] | null;
  dateRange: {
    endDate: Moment | string | null;
    startDate: Moment | string | null;
  };
  pal: string | null;
  papa: string | null;
  member: string | null;
}

interface Props {
  onSubmit: () => void;
}

export const PaymentsFilterModal: React.FC<Props> = ({ onSubmit }) => {
  const history = useHistory();

  const { page, limit, ...paymentsFilters } = usePageInfo();

  const handleSubmit = async (values: FormValues) => {
    const { states, dateRange, pal, papa, member } = values;

    const query: Record<
      string,
      string | number | boolean | readonly Stringifiable[] | null | undefined
    > = {
      page: 1,
      ...(states?.length ? { states: states.join(",") } : {}),
      ...(dateRange.startDate
        ? { dayFrom: moment(dateRange.startDate).startOf("day").toISOString() }
        : {}),
      ...(pal ? { pal } : {}),
      ...(papa ? { papa } : {}),
      ...(member ? { member } : {}),
    };
    if (dateRange.endDate) {
      query.dayTo = moment(dateRange.endDate).endOf("day").toISOString();
    }

    history.push(`?${queryString.stringify(query)}`);

    onSubmit();
  };

  const initialValues = {
    states: paymentsFilters.states ? (paymentsFilters.states.split(",") as PaymentState[]) : [],
    dateRange: {
      startDate: paymentsFilters.dayFrom ? moment(paymentsFilters.dayFrom) : null,
      endDate: paymentsFilters.dayTo ? moment(paymentsFilters.dayTo) : null,
    },
    pal: paymentsFilters.pal ?? "",
    papa: paymentsFilters.papa ?? "",
    member: paymentsFilters.member ?? "",
  };

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm }) => (
        <FormikForm>
          <Container>
            <ModalContainer>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">inserted date</FilterType>
                    </FilterTitle>
                    <InputDateRange
                      id="dateRange"
                      name="dateRange"
                      isOutsideRange={() => false}
                      minimumNights={0}
                    />
                  </StyledFormGroup>
                </Form.Col>

                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">recipient name</FilterType>
                    </FilterTitle>
                    <Form.Input name="member" aria-label="member" placeholder="Recipient name" />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>

              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">papas name</FilterType>
                    </FilterTitle>
                    <Form.Input name="papa" aria-label="papa" placeholder="Papa name" />
                  </StyledFormGroup>
                </Form.Col>

                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">pal names</FilterType>
                    </FilterTitle>
                    <Form.Input name="pal" aria-label="pal" placeholder="Pal name" />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>

              <StyledFormGroup>
                <FilterTitle bold size="largex">
                  Filter by
                  <FilterType as="span">payment status</FilterType>
                </FilterTitle>

                <CheckboxContainer>
                  {paymentStateOptions.map(({ label, value }) => {
                    return (
                      <div key={value}>
                        <Form.Check
                          controlId={`visitState-${value}`}
                          name="states"
                          value={value}
                          label={label}
                        />
                      </div>
                    );
                  })}
                </CheckboxContainer>
              </StyledFormGroup>
            </ModalContainer>
            <Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={() =>
                  resetForm({
                    values: {
                      states: [] as PaymentState[],
                      dateRange: {
                        startDate: null,
                        endDate: null,
                      },
                      pal: "",
                      papa: "",
                      member: "",
                    },
                  })
                }
              >
                Clear Filters
              </Button>
              <Button type="submit" variant="primary">
                Apply
              </Button>
            </Footer>
          </Container>
        </FormikForm>
      )}
    </Formik>
  );
};
