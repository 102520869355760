import { Placement } from "@popperjs/core";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";

import { DropdownContext } from "contexts/DropdownContext";

import DropdownButtonToggle from "./DropDownButtonToggle";
import { DropdownActionButton } from "./DropdownActionButton";
import DropdownItem from "./DropdownItem";
import DropdownLink from "./DropdownLink";
import DropdownMenu from "./DropdownMenu";
import DropdownToggle from "./DropdownToggle";

type Props = {
  children: React.ReactNode;
  placement: Placement;
};

type ChildProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  readonly isOpen: boolean;
  readonly handleToggle: () => void;
};

const Dropdown = ({ children, placement }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRef = useRef<HTMLButtonElement | null>();
  const menuRef = useRef<HTMLDivElement | null>();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  function handleClick(event: MouseEvent) {
    if (
      toggleRef.current &&
      !toggleRef.current.contains(event.target as Node) &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      handleToggle();
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <DropdownContext.Provider value={{ isOpen }}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<ChildProps>(child)) {
          const propsToPass = {
            ...(child.type === DropdownMenu
              ? {
                  ref: setPopperElement,
                  menuRef,
                  isOpen,
                  styles: styles.popper,
                  attributes: attributes.popper,
                  arrow: setArrowElement,
                  handleToggle,
                }
              : { ref: setReferenceElement, handleToggle, toggleRef, isOpen }),
          };

          return React.cloneElement(child, propsToPass);
        }
      })}
    </DropdownContext.Provider>
  );
};

Dropdown.defaultProps = {
  placement: "bottom-start",
};

Dropdown.Toggle = DropdownToggle;
Dropdown.ToggleButton = styled(Dropdown.Toggle)`
  padding: 0;
  display: flex;
  align-items: center;
  padding: 6px 14px 6px 18px;
  background: ${({ theme }) => theme.variants.primary};
  color: #fff;
  border-radius: 0.375rem;

  &:focus {
    outline: none;
  }

  &:active:not([disabled]) {
    transform: translateY(0.125rem);
  }
`;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
Dropdown.Link = DropdownLink;
Dropdown.ButtonToggle = DropdownButtonToggle;

const StyledActionsToggle = styled(Dropdown.Toggle)`
  width: 100%;
  min-width: 66px;
  min-height: 20px;
  color: #ced0da;
`;

Dropdown.Actions = StyledActionsToggle;
Dropdown.ActionButton = DropdownActionButton;

Dropdown.ActionButton = DropdownActionButton;

export default Dropdown;
