import { useField } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Form from "components/Form";
import Select from "components/Select";
import Table from "components/Table";
import { Maybe, Plan } from "generated/types";
import { useMountEffect } from "hooks/useMountEffect";
import { Option, mapSelectValuesToOptions } from "utils/select";

interface Props {
  plans: Plan[];
  onChange?: (plan: Maybe<Plan>) => void;
}

export const PlanPicker: React.FC<Props> = ({ plans, onChange }) => {
  const [{ value: selectedPlanId }] = useField("planId");
  const [plan, setPlan] = useState<Plan | null>(null);
  const [options, setOptions] = useState<Option<string>[]>([]);

  const onSelect = (value: any) => {
    const plan = plans.find(({ id }) => id === value) || null;

    setPlan(plan);

    if (onChange) {
      onChange(plan);
    }
  };

  useEffect(() => {
    const mappedOptions = plans.map(({ id, name }) => ({
      value: id || "",
      label: name || "",
    }));

    setOptions(mappedOptions);
  }, [plans]);

  useMountEffect(() => {
    const selectedPlan = plans?.find(({ id }) => id === selectedPlanId) || null;

    if (selectedPlan) {
      setPlan(selectedPlan);
    }
  });

  if (!plans) {
    return null;
  }

  return (
    <>
      <Form.Group>
        <Form.Label required>Select Plan</Form.Label>
        {options?.length && (
          <Select
            name="planId"
            defaultValue={mapSelectValuesToOptions(selectedPlanId, options)}
            options={options}
            aria-label="planId"
            onChange={onSelect}
            placeholder="Select Plan"
          />
        )}
      </Form.Group>

      <PlanContainer>
        {plan && (
          <>
            <h4>Selected Plan:</h4>
            <table>
              <Table.Row>
                <Table.HeadCell>ID</Table.HeadCell>
                <Table.Cell>{plan.id}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeadCell>Name</Table.HeadCell>
                <Table.Cell>{plan.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeadCell>Description</Table.HeadCell>
                <Table.Cell>{plan.description}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeadCell>Hourly rate</Table.HeadCell>
                <Table.Cell>{plan.hourlyRate}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeadCell>Monthly rate</Table.HeadCell>
                <Table.Cell>{plan.monthlyRate}</Table.Cell>
              </Table.Row>
            </table>
          </>
        )}
      </PlanContainer>
    </>
  );
};

const PlanContainer = styled.div`
  min-height: 10rem;
  h4 {
    margin: 0.5rem 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
`;
