import { Form, Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "components/Loader";
import { ModalContainer } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import config from "config";
import { useAssessmentAnswersMutation } from "generated/types";
import { useGetSurvey } from "hooks/useGetSurvey";
import { useUrlQuery } from "hooks/useUrlQuery";
import { ASSESSMENTS_ANSWERS } from "pages/Papas/Details/Assessments/gql";
import { AssessmentType } from "types/assessments";
import {
  formatAnswersToInput,
  generateInitialValues,
  generateSchema,
  getHardcodedQuestionsRequired,
} from "utils/helpers/assessments";
import { nonNull } from "utils/nonNull";

import SectionFlow from "./SectionFlow";
import { groups, questionsFlow } from "./hardcodedQuestionsMedicare";
import { FormValues } from "./models";

interface Props {
  type?: AssessmentType;
  papaId: string;
  onSkip?: () => void;
  onClose: () => void;
  onSubmit: () => void;
  showProgress?: boolean;
}

export const AssessmentsQuestions: React.FC<Props> = ({
  type = AssessmentType.loneliness,
  papaId,
  onSkip,
  onClose,
  onSubmit,
  showProgress,
}) => {
  const history = useHistory();
  const urlQuery = useUrlQuery();
  const {
    error,
    loading,
    data: { lonelinessCompliant, survey },
  } = useGetSurvey({ papaId, type });

  const [submitAssessmentAnswers, { loading: isSaving }] = useAssessmentAnswersMutation({
    refetchQueries: [{ query: ASSESSMENTS_ANSWERS, variables: { id: papaId } }],
  });

  if (error) {
    return <QueryErrors error={error} />;
  }

  if (loading) return <Loader />;

  if (!survey) {
    return (
      <ModalContainer>
        <Text>Survey not found</Text>
      </ModalContainer>
    );
  }

  const extraUrlQuery = !isEmpty(urlQuery)
    ? `?${queryString.stringify(omit(urlQuery, ["survey"]))}`
    : "";

  const questions = nonNull(survey?.questions?.data);
  const initialValues = generateInitialValues(questions);
  const schema = generateSchema(questions, getHardcodedQuestionsRequired(type));

  const handleSubmit = async (values: FormValues) => {
    try {
      const answers = formatAnswersToInput(values);

      const { data: submitAssessmentAnswersData } = await submitAssessmentAnswers({
        variables: {
          input: {
            answers,
            papaId,
            surveyId: survey?.id as string,
          },
        },
      });

      const assessment = submitAssessmentAnswersData?.submitSurveyAnswers?.data;

      if (assessment?.id) {
        toast.success("The assessment answers were sent");
        onSubmit();

        if (type === AssessmentType.loneliness) {
          const lonelinessCompliantQuery =
            config.featureFlag.businessPolicy && lonelinessCompliant
              ? "lonelinessCompliant=true"
              : "";
          history.push(`/papas/${papaId}/assessments?${lonelinessCompliantQuery}`);
        } else {
          history.push(`/papas/${papaId}/assessments${extraUrlQuery}`);
        }
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const handleClose = () => {
    onClose();
    history.push(`/papas/${papaId}/assessments${extraUrlQuery}`);
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form noValidate>
        <SectionFlow
          type={type}
          questions={questions}
          questionsFlow={questionsFlow}
          onSkip={onSkip}
          handleClose={handleClose}
          isSaving={isSaving}
          groups={groups}
          showProgress={showProgress}
        />
      </Form>
    </Formik>
  );
};
