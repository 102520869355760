import React from "react";
import { Route, Switch } from "react-router-dom";

import Details from "./Details";
import BusinessPoliciesRoutes from "./Details/BusinessPolicies/BusinessPoliciesRoutes";
import VisitProgramsRoutes from "./Details/VisitPrograms/VisitProgramsRoutes";
import List from "./List";

const BusinessesRoutes = () => {
  return (
    <Switch>
      <Route exact path="/businesses" component={List} />
      <Route exact path="/client-programs" component={List} />
      <Route
        exact
        path={["/businesses/:businessId", "/businesses/:businessId/:tab"]}
        component={Details}
      />
      <Route
        exact
        path={["/client-programs/:businessId", "/client-programs/:businessId/:tab"]}
        component={Details}
      />
      <Route path="/businesses/:businessId/visit-programs" component={VisitProgramsRoutes} />
      <Route path="/client-programs/:businessId/visit-programs" component={VisitProgramsRoutes} />
      <Route path="/businesses/:businessId/business-policies" component={BusinessPoliciesRoutes} />
      <Route
        path="/client-programs/:businessId/client-program-policies"
        component={BusinessPoliciesRoutes}
      />
    </Switch>
  );
};

export default BusinessesRoutes;
