import * as Yup from "yup";

const file = Yup.mixed<File>().test("required", "You need to provide a csv file valid", (file) => {
  if (!file) return false;

  if (file.type !== "text/csv") return false;

  if (file.size === 0) return false;

  return true;
});

export const schema = Yup.object().shape<Values>({
  file: file,
  partnerId: Yup.string().required("Required"),
});

export type Values = {
  partnerId: string;
  file: File | null;
};
