import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import DataTableDropdownMenu, { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { FeatureFlags } from "constants/featureFlags";
import { Organization, OrganizationSorting, SortDirection } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { formatDateTime } from "utils/helpers/formatters";
import { ActionMenuAnalytics, actionMenuClicked } from "utils/segment";

interface Params {
  sorting: Partial<OrganizationSorting>;
  onSortChange: (sort: Partial<OrganizationSorting>) => void;
}

export const useOrganizationsTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { pluralEntityName: programsOrBusinesses } = useCallItClientProgramOrBusiness();

  const { isSupervisor } = useCurrentAccountRole();
  const [sorting, setSorting] = useState<Partial<OrganizationSorting>>(initialSorting);
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const viewClientMenuItemLabel = clientCopyUpdateEnabled ? "View client" : "View organization";
  const clientMenuItemLinkPrefix = clientCopyUpdateEnabled ? "clients" : "organizations";

  const editClientMenuItemLabel = clientCopyUpdateEnabled ? "Edit client" : "Edit organization";

  const onSetSorting = (sortKey: keyof OrganizationSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const menuItems = ({ id }: Organization) => [
    {
      label: viewClientMenuItemLabel,
      link: `/${clientMenuItemLinkPrefix}/${id}`,
    },
    {
      label: editClientMenuItemLabel,
      action: DataTableMenuActions.Edit,
      showCondition: isSupervisor,
    },
    {
      label: "Submit Ticket",
      action: DataTableMenuActions.SubmitTicket,
      showCondition: supportTicketingEnabled,
    },
  ];

  const columns: Column<Organization>[] = [
    {
      header: { title: "Name" },
      dataField: "name",
    },
    {
      header: { title: `#Number of ${programsOrBusinesses}` },
      dataField: "totalAccounts",
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(organization) {
        const items = menuItems(organization);
        return (
          <DataTableDropdownMenu
            items={items}
            actionHandler={(action) =>
              menuActionsHandler(
                action,
                organization,
                items.find((it) => it.action === action)
              )
            }
          />
        );
      },
    },
  ];

  const routeMatch = useRouteMatch();

  const menuActionsHandler = (
    action: DataTableMenuActions,
    organization: Organization,
    menuItem: any
  ) => {
    setAction(action);
    setOrganization(organization);
    actionMenuClicked({ action: menuItem.label } as ActionMenuAnalytics, routeMatch.path);
  };

  const createOrganization = () => {
    setAction(DataTableMenuActions.Create);
    setOrganization(null);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setOrganization(null);
  };

  return {
    columns,
    action,
    organization,
    createOrganization,
    closeModal,
  };
};
