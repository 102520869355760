export enum Location {
  AL = "AL",
  AK = "AK",
  AS = "AS",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  DC = "DC",
  FM = "FM",
  FL = "FL",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MH = "MH",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  MP = "MP",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PW = "PW",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VI = "VI",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export const DEFAULT_LOCATION = Location.NY;

export const LocationsName: {
  [key: string]: string;
} = {
  [Location.AL]: "Alabama",
  [Location.AK]: "Alaska",
  [Location.AS]: "American Samoa",
  [Location.AZ]: "Arizona",
  [Location.AR]: "Arkansas",
  [Location.CA]: "California",
  [Location.CO]: "Colorado",
  [Location.CT]: "Connecticut",
  [Location.DE]: "Delaware",
  [Location.DC]: "District Of Columbia",
  [Location.FM]: "Federated States Of Micronesia",
  [Location.FL]: "Florida",
  [Location.GA]: "Georgia",
  [Location.GU]: "Guam",
  [Location.HI]: "Hawaii",
  [Location.ID]: "Idaho",
  [Location.IL]: "Illinois",
  [Location.IN]: "Indiana",
  [Location.IA]: "Iowa",
  [Location.KS]: "Kansas",
  [Location.KY]: "Kentucky",
  [Location.LA]: "Louisiana",
  [Location.ME]: "Maine",
  [Location.MH]: "Marshall Islands",
  [Location.MD]: "Maryland",
  [Location.MA]: "Massachusetts",
  [Location.MI]: "Michigan",
  [Location.MN]: "Minnesota",
  [Location.MS]: "Mississippi",
  [Location.MO]: "Missouri",
  [Location.MT]: "Montana",
  [Location.NE]: "Nebraska",
  [Location.NV]: "Nevada",
  [Location.NH]: "New Hampshire",
  [Location.NJ]: "New Jersey",
  [Location.NM]: "New Mexico",
  [Location.NY]: "New York",
  [Location.NC]: "North Carolina",
  [Location.ND]: "North Dakota",
  [Location.MP]: "Northern Mariana Islands",
  [Location.OH]: "Ohio",
  [Location.OK]: "Oklahoma",
  [Location.OR]: "Oregon",
  [Location.PW]: "Palau",
  [Location.PA]: "Pennsylvania",
  [Location.PR]: "Puerto Rico",
  [Location.RI]: "Rhode Island",
  [Location.SC]: "South Carolina",
  [Location.SD]: "South Dakota",
  [Location.TN]: "Tennessee",
  [Location.TX]: "Texas",
  [Location.UT]: "Utah",
  [Location.VT]: "Vermont",
  [Location.VI]: "Virgin Islands",
  [Location.VA]: "Virginia",
  [Location.WA]: "Washington",
  [Location.WV]: "West Virginia",
  [Location.WI]: "Wisconsin",
  [Location.WY]: "Wyoming",
};

export const locationOptions = [
  { label: "Alabama", value: Location.AL },
  { label: "Alaska", value: Location.AK },
  { label: "American Samoa", value: Location.AS },
  { label: "Arizona", value: Location.AZ },
  { label: "Arkansas", value: Location.AR },
  { label: "California", value: Location.CA },
  { label: "Colorado", value: Location.CO },
  { label: "Connecticut", value: Location.CT },
  { label: "Delaware", value: Location.DE },
  { label: "District Of Columbia", value: Location.DC },
  { label: "Federated States Of Micronesia", value: Location.FM },
  { label: "Florida", value: Location.FL },
  { label: "Georgia", value: Location.GA },
  { label: "Guam", value: Location.GU },
  { label: "Hawaii", value: Location.HI },
  { label: "Idaho", value: Location.ID },
  { label: "Illinois", value: Location.IL },
  { label: "Indiana", value: Location.IN },
  { label: "Iowa", value: Location.IA },
  { label: "Kansas", value: Location.KS },
  { label: "Kentucky", value: Location.KY },
  { label: "Louisiana", value: Location.LA },
  { label: "Maine", value: Location.ME },
  { label: "Marshall Islands", value: Location.MH },
  { label: "Maryland", value: Location.MD },
  { label: "Massachusetts", value: Location.MA },
  { label: "Michigan", value: Location.MI },
  { label: "Minnesota", value: Location.MN },
  { label: "Mississippi", value: Location.MS },
  { label: "Missouri", value: Location.MO },
  { label: "Montana", value: Location.MT },
  { label: "Nebraska", value: Location.NE },
  { label: "Nevada", value: Location.NV },
  { label: "New Hampshire", value: Location.NH },
  { label: "New Jersey", value: Location.NJ },
  { label: "New Mexico", value: Location.NM },
  { label: "New York", value: Location.NY },
  { label: "North Carolina", value: Location.NC },
  { label: "North Dakota", value: Location.ND },
  { label: "Northern Mariana Islands", value: Location.MP },
  { label: "Ohio", value: Location.OH },
  { label: "Oklahoma", value: Location.OK },
  { label: "Oregon", value: Location.OR },
  { label: "Palau", value: Location.PW },
  { label: "Pennsylvania", value: Location.PA },
  { label: "Puerto Rico", value: Location.PR },
  { label: "Rhode Island", value: Location.RI },
  { label: "South Carolina", value: Location.SC },
  { label: "South Dakota", value: Location.SD },
  { label: "Tennessee", value: Location.TN },
  { label: "Texas", value: Location.TX },
  { label: "Utah", value: Location.UT },
  { label: "Vermont", value: Location.VT },
  { label: "Virgin Islands", value: Location.VI },
  { label: "Virginia", value: Location.VA },
  { label: "Washington", value: Location.WA },
  { label: "West Virginia", value: Location.WV },
  { label: "Wisconsin", value: Location.WI },
  { label: "Wyoming", value: Location.WY },
];
