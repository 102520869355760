import { Field, FieldProps } from "formik";
import React, { useContext } from "react";

import Feedback from "components/Form/FormFeedback";
import SwitchButton from "components/SwitchButton";

import FormContext from "./FormContext";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  id?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FormSwitch: React.FC<Props> = ({
  label,
  id,
  size,
  isValid,
  name,
  isInvalid,
  "aria-label": ariaLabel,
  ...rest
}: Props) => {
  const { controlId } = useContext(FormContext);

  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }: FieldProps) => (
        <>
          <SwitchButton
            label={label || ""}
            id={id || controlId}
            name={name}
            checked={field.value}
            onChange={field.onChange}
            aria-label={ariaLabel}
            {...rest}
          />
          {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
        </>
      )}
    </Field>
  );
};

export default FormSwitch;
