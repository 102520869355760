import React, { ReactElement } from "react";

import { VisitState } from "generated/types";
import { CancelPriorityBonusModal } from "modals/PriorityBonusActionModals/CancelPriorityBonusModal";
import CreatePriorityBonusModal from "modals/PriorityBonusActionModals/CreatePriorityBonusModal";

import { PriorityBonusMenuActions } from "./models";

interface Props {
  action: PriorityBonusMenuActions;
  visitId: string;
  visitState?: VisitState | null;
  onClose: () => void;
  onRefetch: () => void;
}

const PriorityBonusActions = ({
  action,
  visitId,
  visitState,
  onClose,
  onRefetch,
}: Props): ReactElement => {
  return (
    <>
      <CancelPriorityBonusModal
        visitId={visitId}
        visitState={visitState}
        isOpen={action === PriorityBonusMenuActions.cancelPriorityBonus}
        toggle={onClose}
        onRefetch={onRefetch}
      />
      <CreatePriorityBonusModal
        toggle={onClose}
        visitId={visitId}
        visitState={visitState}
        isOpen={action === PriorityBonusMenuActions.createPriorityBonus}
        onRefetch={onRefetch}
      />
    </>
  );
};

export default PriorityBonusActions;
