import { Formik } from "formik";
import moment from "moment";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { CheckboxGroup } from "components/Checkbox/CheckboxGroup";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import {
  ServiceRequestStatus,
  useAssignServiceRequestsToVisitMutation,
  useGetPapaRequestsQuery,
} from "generated/types";
import { nonNull } from "utils/nonNull";

import { Schema, schema } from "./schema";

interface Props {
  visitId: string;
  papaId: string;
  isOpen: boolean;
  toggle: () => void;
}

const AssociateServiceRequestsModal = ({
  visitId,
  papaId,
  isOpen,
  toggle,
}: Props): ReactElement => {
  const { data } = useGetPapaRequestsQuery({
    variables: {
      // We retrieve on all service requests for this papa that are:
      // 1. Active
      // 2. Closed in the last two weeks
      filter: [
        {
          papaId: {
            eq: papaId,
          },
          status: {
            in: [
              ServiceRequestStatus.New,
              ServiceRequestStatus.InProgress,
              ServiceRequestStatus.OnHold,
            ],
          },
        },
        {
          papaId: {
            eq: papaId,
          },
          status: {
            in: [
              ServiceRequestStatus.Resolved,
              ServiceRequestStatus.Closed,
              ServiceRequestStatus.ClosedNotANeed,
              ServiceRequestStatus.ClosedEscalatedToClient,
            ],
          },
          closedAt: {
            gte: moment().subtract(2, "weeks").startOf("day").toISOString(),
          },
        },
      ],
    },
  });

  const [assignServiceRequestsToVisitMutation] = useAssignServiceRequestsToVisitMutation();

  const serviceRequests = data?.serviceRequests?.data;

  const optionsParsed = nonNull(serviceRequests).map((sr) => ({
    label: sr.need?.data?.name as string,
    value: sr.id as string,
  }));

  const initialValues: Schema = { serviceRequestIds: [] };

  const handleSubmit = async (values: Schema) => {
    try {
      const { data: serviceRequestsToVisit } = await assignServiceRequestsToVisitMutation({
        variables: {
          id: visitId,
          serviceRequestIds: values.serviceRequestIds,
        },
      });

      if (serviceRequestsToVisit) {
        toast.success("Visit complete");
      }

      toggle();
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title={"Complete virtual visit"} isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <ModalStyledForm>
          <ModalContainer>
            <Form.Label>What Service Requests did you work on during this call?</Form.Label>
            <CheckboxGroup name="serviceRequestIds" options={optionsParsed}></CheckboxGroup>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Complete Visit
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};

export default AssociateServiceRequestsModal;
