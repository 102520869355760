import React from "react";
import styled from "styled-components";

import Panel from "components/Panel";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { PaymentRate } from "generated/types";
import { formatNumberToCurrency } from "utils/numbers/currency";

interface Props {
  paymentRate?: PaymentRate | null;
}

const PaymentRatePanel = ({ paymentRate }: Props) => {
  if (!paymentRate) return <Panel title="Payment Rates">{EMPTY_PLACEHOLDER}</Panel>;

  const {
    chargeForInitialHalfHour,
    chargeForInitialHalfHourVirtual,
    chargeForInitialHour,
    chargeForInitialHourVirtual,
    courtesyTransportMiles,
    ratePerMileInTenths,
    ratePerMinuteInTenths,
    ratePerVirtualMinuteInTenths,
    rateDelivery,
  } = paymentRate;

  return (
    <Panel title="Payment Rates">
      <Panel.Item>
        <PaymentRateCategory>In-person Visit</PaymentRateCategory>
        <PaymentRatesContainer>
          <PaymentRateItem>
            <Text bold>Per Minute</Text>
            <Text>
              {ratePerMinuteInTenths
                ? formatNumberToCurrency(ratePerMinuteInTenths / 10)
                : EMPTY_PLACEHOLDER}
            </Text>
          </PaymentRateItem>
          <PaymentRateItem>
            <Text bold>
              Half Hour <RegularText>(30 min)</RegularText>
            </Text>
            <Text>
              {chargeForInitialHalfHour
                ? formatNumberToCurrency(chargeForInitialHalfHour)
                : EMPTY_PLACEHOLDER}
            </Text>
          </PaymentRateItem>
          <PaymentRateItem>
            <Text bold>
              Full Hour <RegularText>(60 min)</RegularText>
            </Text>
            <Text>
              {chargeForInitialHour
                ? formatNumberToCurrency(chargeForInitialHour)
                : EMPTY_PLACEHOLDER}
            </Text>
          </PaymentRateItem>
          <PaymentRateItem>
            <Text bold>Courtesy Transportation</Text>
            <Text>{courtesyTransportMiles} miles</Text>
          </PaymentRateItem>
          <PaymentRateItem>
            <Text bold>Delivery</Text>
            <Text>{rateDelivery ? formatNumberToCurrency(rateDelivery) : EMPTY_PLACEHOLDER}</Text>
          </PaymentRateItem>
          <PaymentRateItem>
            <Text bold>Per Mile</Text>
            <Text>
              {ratePerMileInTenths
                ? formatNumberToCurrency(ratePerMileInTenths / 10)
                : EMPTY_PLACEHOLDER}
            </Text>
          </PaymentRateItem>
        </PaymentRatesContainer>
      </Panel.Item>
      <Panel.Item>
        <PaymentRateCategory>Virtual Visit</PaymentRateCategory>
        <PaymentRatesContainer>
          <PaymentRateItem>
            <Text bold>Per Minute</Text>
            <Text>
              {ratePerVirtualMinuteInTenths
                ? formatNumberToCurrency(ratePerVirtualMinuteInTenths / 10)
                : EMPTY_PLACEHOLDER}
            </Text>
          </PaymentRateItem>
          <PaymentRateItem>
            <Text bold>
              Half Hour <RegularText>(30 min)</RegularText>
            </Text>
            <Text>
              {chargeForInitialHalfHourVirtual
                ? formatNumberToCurrency(chargeForInitialHalfHourVirtual)
                : EMPTY_PLACEHOLDER}
            </Text>
          </PaymentRateItem>
          <PaymentRateItem>
            <Text bold>
              Full Hour <RegularText>(60 min)</RegularText>
            </Text>
            <Text>
              {chargeForInitialHourVirtual
                ? formatNumberToCurrency(chargeForInitialHourVirtual)
                : EMPTY_PLACEHOLDER}
            </Text>
          </PaymentRateItem>
        </PaymentRatesContainer>
      </Panel.Item>
    </Panel>
  );
};

export default PaymentRatePanel;

const RegularText = styled.span`
  font-weight: normal;
`;

const PaymentRateCategory = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin: 14px 0;
`;

const PaymentRatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const PaymentRateItem = styled.div`
  margin-right: 40px;

  ${Text} {
    margin: 4px 0;
  }
`;
