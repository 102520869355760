import React from "react";

import Panel from "components/Panel";
import Text from "components/Text";
import { Visit } from "generated/types";

type Props = {
  visit: Visit;
};

const VisitMileage = ({ visit }: Props) => {
  const commuteDistance = visit.commuteDistance ? visit.commuteDistance.toFixed(2) : 0;
  const visitDistance = visit.visitDistance ? visit.visitDistance.toFixed(2) : 0;
  const observedCommuteDistance = visit.observedCommuteDistance
    ? visit.observedCommuteDistance.toFixed(2)
    : 0;
  const observedVisitDistance = visit.observedVisitDistance
    ? visit.observedVisitDistance.toFixed(2)
    : 0;

  return (
    <>
      <Panel.Item>
        <Text bold>Commute Miles: {commuteDistance} miles</Text>
        <Text>Observed In App: {observedCommuteDistance} miles</Text>
      </Panel.Item>
      <Panel.Item>
        <Text bold>Visit Miles: {visitDistance} miles</Text>
        <Text>Observed In App: {observedVisitDistance} miles</Text>
        <Text>Zeroed: {visit.distanceTared ? "True" : "False"}</Text>
      </Panel.Item>
    </>
  );
};

export default VisitMileage;
