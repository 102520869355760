import { gql } from "@apollo/client";

export const DESTROY_PAPA = gql`
  mutation destroyPapa($id: ID!) {
    destroyPapa(id: $id) {
      data {
        id
        deletedAt
        account {
          data {
            id
          }
        }
      }
    }
  }
`;

export const UNDELETE_PAPA = gql`
  mutation undeletePapa($id: ID!) {
    undeletePapa(id: $id) {
      data {
        id
      }
    }
  }
`;
