import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

import { TaskListResult } from "generated/types";

// TODO: move this to gql.ts file
export const TASK_LIST = gql`
  query tasks {
    tasks(pagination: { limit: 1000 }) {
      data {
        id
        name
        icon
      }
    }
  }
`;

export const useQueryTasks = () => {
  const { data, loading, error } = useQuery<{ tasks: TaskListResult }>(TASK_LIST);

  useEffect(() => {
    if (error) {
      window.console.error(error);
    }
  }, [error]);

  return { data, loading, error };
};
