import { gql, useApolloClient, useMutation } from "@apollo/client";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { getLastLoginTime } from "utils/getLastLoginTime";
import { formatDateTime } from "utils/helpers/formatters";

const LoginCallback: React.FC<{
  errorComponent?: React.ComponentType<{ error: Error }>;
  onAuthResume?: OnAuthResumeFunction;
}> = ({ errorComponent, onAuthResume }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const client = useApolloClient();
  const [recordOktaLogin] = useMutation(RECORD_OKTA_LOGIN);
  const [callbackError, setCallbackError] = React.useState<null | Error>(null);

  const authStateReady = !!authState;
  const ErrorReporter = errorComponent || OktaError;
  useEffect(() => {
    if (onAuthResume && oktaAuth.isInteractionRequired?.()) {
      onAuthResume();
      return;
    }
    oktaAuth
      .handleLoginRedirect()
      .then(() => getLastLoginTime(client))
      .then((lastLoginTime: string) => {
        const dateTimeString = formatDateTime(lastLoginTime);

        dateTimeString !== EMPTY_PLACEHOLDER
          ? toast.info(`You successfully logged in. Last logged in at ${dateTimeString}`)
          : toast.info("You successfully logged in.");
      })
      .then(() => recordOktaLogin())
      .catch((e: Error) => {
        setCallbackError(e);
      });
  }, [oktaAuth, recordOktaLogin, onAuthResume, client]);

  const authError = authStateReady ? authState.error : null;
  const displayError = callbackError || authError;
  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return null;
};

const RECORD_OKTA_LOGIN = gql`
  mutation recordOktaLogin {
    recordOktaLogin {
      success
    }
  }
`;

const OktaError: React.FC<{ error: Error }> = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p>
        {error.name}: {error.message}
      </p>
    );
  }
  return <p>Error: {error.toString()}</p>;
};

type OnAuthResumeFunction = () => void;

export default LoginCallback;
