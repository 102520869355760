import { DemandProfileSegmentation } from "generated/types";

export const demandProfileOptions = [
  { label: "New Market", value: DemandProfileSegmentation.NewMarket },
  { label: "Very High Demand", value: DemandProfileSegmentation.VeryHighDemand },
  { label: "High Demand", value: DemandProfileSegmentation.HighDemand },
  { label: "Med-High Demand", value: DemandProfileSegmentation.MedHighDemand },
  { label: "Med-Low Demand", value: DemandProfileSegmentation.MedLowDemand },
  { label: "Low Demand", value: DemandProfileSegmentation.LowDemand },
  { label: "Very Low Demand", value: DemandProfileSegmentation.VeryLowDemand },
  { label: "Unserved Market", value: DemandProfileSegmentation.UnservedMarket },
  { label: "None", value: DemandProfileSegmentation.None },
];
