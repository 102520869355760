import moment from "moment";
import React from "react";

import Form from "components/Form";
import { GenderInput } from "components/GenderInput";
import InputDate from "components/InputDate";
import Select from "components/Select";
import Text from "components/Text";
import { languageOptions } from "constants/language";

export const PersonalInformation = () => {
  return (
    <>
      <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="0.5rem">
        Personal Information
      </Text>

      <Form.Group>
        <Form.Label required>Full Name</Form.Label>
        <Form.Input aria-label="fullName" name="fullName" placeholder="Full name" />
      </Form.Group>

      <Form.Group>
        <Form.Label required>Date of Birth</Form.Label>
        <InputDate
          id="birthDate"
          name="birthDate"
          isOutsideRange={(day) => day.isAfter(moment())}
          withYear
          block
        />
      </Form.Group>

      <GenderInput />

      <Form.Group>
        <Form.Label required>Primary Language</Form.Label>
        <Select
          name="language"
          aria-label="language"
          options={languageOptions}
          isSearchable={false}
          placeholder="Select language"
        />
      </Form.Group>
    </>
  );
};
