import { useQuery } from "@apollo/client";
import React from "react";

import { ServiceRequestActions } from "components/ActionsMenu/ServiceRequestActionMenu/models";
import Pagination from "components/Pagination";
import SmartTable from "components/SmartTable";
import { ServiceRequestFollowupStatus, ServiceRequestSingleResult } from "generated/types";
import usePagination from "hooks/usePagination";
import { CreateServiceRequestFollowUpModal } from "modals/CareConcierge/CreateFollowUp";

import { GET_SERVICE_FOLLOWUPS } from "./gql";
import { useFollowUpsTableColumns } from "./useFollowUpsTableColumns";

interface Data {
  serviceRequest: ServiceRequestSingleResult;
}

type Props = {
  completed: boolean;
  serviceId: string;
};
const ServiceRequestFollowUps = ({ completed, serviceId }: Props) => {
  const { currentPage, setPage, currentLimit } = usePagination({ page: 1, limit: 10 });
  const { data, loading, refetch } = useQuery<Data>(GET_SERVICE_FOLLOWUPS, {
    variables: {
      id: serviceId,
      filter: {
        status: {
          eq: completed
            ? ServiceRequestFollowupStatus.Completed
            : ServiceRequestFollowupStatus.Opened,
        },
      },
      pagination: { page: currentPage, limit: currentLimit },
    },
  });
  const onUpdateFinish = () => {
    refetch();
  };
  const { columns, action, closeModal, serviceRequestFollowup } = useFollowUpsTableColumns({
    serviceId,
    onUpdateFinish,
  });
  const totalPages =
    data?.serviceRequest?.data?.serviceRequestFollowups?.pagination?.totalPages ?? 0;
  return (
    <div>
      <SmartTable
        keyField="id"
        loading={loading}
        data={data?.serviceRequest?.data?.serviceRequestFollowups?.data}
        columns={columns}
      />
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          itemsAfterAndBefore={3}
          onChangePage={setPage}
        />
      )}
      <CreateServiceRequestFollowUpModal
        serviceRequestId={serviceId}
        serviceRequestFollowup={serviceRequestFollowup}
        isOpen={action === ServiceRequestActions.CreateServiceRequestFollowUp}
        toggle={closeModal}
        onCreateFinish={onUpdateFinish}
      />
    </div>
  );
};

export default ServiceRequestFollowUps;
