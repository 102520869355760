import { useState } from "react";

import { ServiceRequestActions } from "./models";

export const useServiceRequestActions = () => {
  const [action, setServiceRequestAction] = useState(ServiceRequestActions.NoAction);
  const setAction = (action: ServiceRequestActions) => {
    setServiceRequestAction(action);
  };

  return {
    action,
    setAction,
  };
};
