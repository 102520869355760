import React from "react";

import Form from "components/Form";
import Text from "components/Text";

export const MemberIdInput: React.FC = () => (
  <Form.Group>
    <Form.Label>Member ID</Form.Label>
    <Form.Input name="memberId" placeholder="" />
    <Text size="small">Leave Member ID blank if this is not a business account</Text>
  </Form.Group>
);
