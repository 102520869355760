import capitalize from "lodash/capitalize";
import kebabCase from "lodash/kebabCase";

import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

/*  
Use it to make hooks to switch between calling something this or that, like 

import { FeatureFlags } from "constants/featureFlags";

import { useCallItThisOrThat } from "./useCallItThisOrThat";

const featureFlag = FeatureFlags.ClientCopyUpdate;

const current = {
  singular: "business",
  plural: "businesses",
};

const next = {
  singular: "client program",
  plural: "client programs",
};

export function useCallItClientProgramOrBusiness() {
  return useCallItThisOrThat(featureFlag, current, next);
}
*/

interface thisOrThatParams {
  plural: string;
  singular: string;
}

const capitalizeEach = (toCapitalize: string): string => {
  return toCapitalize.split(" ").map(capitalize).join(" ");
};

export function useCallItThisOrThat(
  featureFlag: string,
  current: thisOrThatParams,
  next: thisOrThatParams
) {
  const flagEnabled = useIsFeatureEnabled(featureFlag);

  const pluralEntityName = flagEnabled ? next.plural : current.plural;

  const singularEntityName = flagEnabled ? next.singular : current.singular;

  return {
    pluralEntityName,
    singularEntityName,
    capitalized: {
      pluralEntityName: capitalizeEach(pluralEntityName),
      singularEntityName: capitalizeEach(singularEntityName),
    },
    kebabCased: {
      pluralEntityName: kebabCase(pluralEntityName),
      singularEntityName: kebabCase(singularEntityName),
    },
  };
}
