import React from "react";

import Table from "components/Table";
import { VisitSurvey } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

interface Props {
  surveys: VisitSurvey[];
}

export const SurveysTable: React.FC<Props> = ({ surveys }) => (
  <Table>
    <Table.Head>
      <Table.HeadCell>Name</Table.HeadCell>
      <Table.HeadCell>Completed At</Table.HeadCell>
    </Table.Head>
    <Table.Body>
      {!surveys?.length && (
        <Table.Row>
          <Table.Cell colSpan={2} align="center" noWrap>
            No data to show
          </Table.Cell>
        </Table.Row>
      )}

      {surveys.map((item) => {
        const { completedAt, url } = item;
        const name = item?.survey?.data?.name;

        return (
          <Table.Row key={item.id}>
            <Table.Cell width="45%">
              {url ? (
                <a target="_blank" rel="noopener noreferrer" href={url}>
                  {name}
                </a>
              ) : (
                name
              )}
            </Table.Cell>
            <Table.Cell>{formatDateTime(completedAt)}</Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  </Table>
);
