import React from "react";
import styled, { css } from "styled-components";

import { withProperties } from "utils/type-helper";

import Link from "./Link";

type Props = {
  children: React.ReactNode;
  noMargin?: boolean;
};

const Nav = styled.nav<Props>`
  display: flex;
  overflow: auto;
  white-space: nowrap;

  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-bottom: 1.875rem;
    `}
`;

export default withProperties(Nav, { Link });
