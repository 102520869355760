import React, { useState } from "react";
import styled from "styled-components";

import MainContainerContext, { TopBarOptions } from "./MainContainerContext";
import TopBar from "./TopBar";

type Props = {
  children: React.ReactNode;
};

const MainContainer = ({ children }: Props) => {
  const [topBarOptions, setTopBarOptions] = useState<TopBarOptions>({});

  return (
    <Container>
      <MainContainerContext.Provider value={{ topBarOptions, setTopBarOptions }}>
        <TopBar />
        {children}
      </MainContainerContext.Provider>
    </Container>
  );
};

const Container = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export default MainContainer;
