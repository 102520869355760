import React, { FunctionComponent } from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { BaseRate as Entity } from "pages/Accounting/accountingMocked";

import { EditBaseRateForm } from "./EditBaseRateForm";

interface Props {
  onClose: () => void;
  baseRate: Entity;
  isOpen: boolean;
}

const EditBaseRateModal: FunctionComponent<Props> = ({ isOpen, onClose, baseRate }) => {
  if (!baseRate.id) return null;

  return (
    <ModalLayout toggle={onClose} title="Edit in Person Visit Base Rate" isOpen={isOpen}>
      <EditBaseRateForm baseRateId={baseRate.id} onClose={onClose} />
    </ModalLayout>
  );
};

export default EditBaseRateModal;
