import { BusinessType } from "generated/types";

export const businessTypeOptions = [
  {
    label: "Select an Option",
    value: "",
  },
  {
    label: "Medicare Advantage",
    value: BusinessType.MedicareAdvantage,
  },
  {
    label: "Medicaid",
    value: BusinessType.Medicaid,
  },
  {
    label: "Employer",
    value: BusinessType.Employer,
  },
  {
    label: "Other",
    value: BusinessType.Other,
  },
];
