import { VehicleInsuranceStatus, VehicleType } from "generated/types";

export const vehicleInsuranceStatusOptions = [
  { label: "No Vehicle", value: VehicleInsuranceStatus.NoVehicle },
  { label: "Pending Upload", value: VehicleInsuranceStatus.PendingUpload },
  { label: "Pending Review", value: VehicleInsuranceStatus.PendingReview },
  { label: "Approved", value: VehicleInsuranceStatus.Approved },
];

export const vehicleTypeOptions = [
  { label: "Two door", value: VehicleType.TwoDoor },
  { label: "Four door", value: VehicleType.FourDoor },
  { label: "SUV", value: VehicleType.Suv },
  { label: "None", value: VehicleType.None },
];

export const vehicleYearOptions = (() => {
  const current = new Date().getFullYear();

  return Array.from({ length: 19 }, (year, index) => {
    const value = (current - index).toString();

    return { value, label: String(value) };
  });
})();
