import { useApolloClient } from "@apollo/client";
import { useField } from "formik";
import { isArray } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { CSSProperties } from "styled-components";

import { formatOptionLabel } from "components/Search";
import { customStyles } from "components/Select";
import { Account } from "generated/types";
import { Option } from "utils/select";

import { SEARCH_PAL } from "./gql";
import UserSelect, { Props as UserSelectProps } from "./index";

interface Props extends Partial<UserSelectProps> {
  name: string;
  selectedPals?: Option<string>[] | Option<string>;
  placeholder?: string;
  isMulti?: boolean;
  preferredPalIds?: Array<string>;
}

export const palSelectStyles = {
  ...customStyles,
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
    minHeight: 50,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles.control,
    display: "flex",
    minHeight: state.hasValue ? "5.7rem" : "2.25rem",
  }),
};

const PalSelect: FunctionComponent<Props> = ({
  name,
  selectedPals,
  placeholder = "Select Pal",
  isMulti = false,
  preferredPalIds = [],
  ...otherProps
}) => {
  const [, , helpers] = useField(name);
  const [value, setValue] = useState(selectedPals);

  useEffect(() => {
    if (selectedPals === undefined) return;

    setValue(selectedPals);
    if (isArray(selectedPals)) {
      helpers.setValue(selectedPals?.map(({ value }) => value) ?? []);
    } else {
      helpers.setValue(selectedPals?.value ?? (isMulti ? [] : null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPals]);

  const client = useApolloClient();

  async function handleLoadOptions(value: string) {
    const { data } = await client.query({
      query: SEARCH_PAL,
      variables: {
        filter: [
          {
            pal: { present: true },
            permissions: { admin: false },
            fullName: { cont: value },
            deletedAt: { eq: null },
          },
          {
            pal: { present: true },
            permissions: { admin: false },
            email: { cont: value },
            deletedAt: { eq: null },
          },
        ],
      },
    });

    if (data?.accounts?.data) {
      return data.accounts.data.map((account: Account) => ({
        accountId: account.id,
        value: account.pal?.data?.id,
        label: account.fullName,
        phone: account.phoneNumber,
        isPreferred: preferredPalIds.includes(account.pal?.data?.id || "-2"),
      }));
    }

    return [];
  }

  return (
    <UserSelect
      name={name}
      value={value}
      onLoadOptions={handleLoadOptions}
      onChange={(newValue) => {
        setValue(newValue);
        helpers.setTouched(true);
        if (isArray(newValue)) {
          helpers.setValue(newValue.map(({ value }: { value: string }) => value));
        } else {
          helpers.setValue(newValue?.value ?? null);
        }
      }}
      placeholder={placeholder}
      aria-label={name}
      isMulti={isMulti}
      customStyles={palSelectStyles}
      formatOptionLabel={formatOptionLabel}
      {...otherProps}
    />
  );
};

export default PalSelect;
