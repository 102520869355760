import { HardCodedQuestionsRequired, QuestionsFlow } from "types/assessments";

export const hardcodedQuestionRequired: HardCodedQuestionsRequired = {
  P1: true,
  P2: true,
  P4: true,
  P6: true,
  P5: true,
  P7: true,
  P9: true,
  P10: true,
  P11: true,
  P12: true,
  P13: true,
  P15: true,
  P17: true,
  P25: true,
  P27: true,
};

export const groups = [
  {
    id: "S1",
    questions: ["P1", "P2", "P3", "P4", "P5", "P6"],
  },
  {
    id: "S2",
    questions: ["P7", "P8", "P9"],
  },
  {
    id: "S3",
    questions: ["P10", "P11", "P12", "P13", "P14"],
  },
  {
    id: "S4",
    questions: ["P15", "P16", "P17", "P18", "P19", "P20", "P21", "P22", "P23", "P24"],
  },
  {
    id: "S5",
    questions: ["P25", "P26", "P27", "P28"],
  },
];

export const questionsFlow: QuestionsFlow = {
  P3: {
    P2: "yes",
  },
  P14: {
    P13: ["no", "Member chose not to answer"],
  },
  P16: {
    P15: "yes",
  },
  P18: {
    P17: "yes",
  },
  P19: {
    P18: "yes",
  },
  P20: {
    P17: "yes",
  },
  P21: {
    P20: "yes",
  },
  P22: {
    P17: "yes",
  },
  P23: {
    P17: "yes",
  },
  P24: {
    P17: ["no", "member chose not to answer"],
  },
  P26: {
    P25: ["Often true", "Sometimes true"],
  },
  P28: {
    P27: ["Often true", "Sometimes true"],
  },
  P30: {
    P29: ["No"],
  },
};

export const lastQuestionsPositions = ["P29", "P30"];
