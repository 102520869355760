import { useState } from "react";

import { PapaConcealed } from "types";

import { PapaMenuActions } from "./models";

export const usePapaActions = () => {
  const [papa, setPapa] = useState<PapaConcealed | null>(null);
  const [action, setPapaAction] = useState(PapaMenuActions.noAction);

  const setAction = (action: PapaMenuActions, papa: PapaConcealed | null = null) => {
    setPapa(papa);
    setPapaAction(action);
  };

  return {
    papa,
    action,
    setAction,
  };
};
