import { Column } from "components/SmartTable/schema";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { priorityBonusReasonLabels } from "constants/priorityBonus";
import { PriorityBonus } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";

export const usePriorityBonusesTableColumns = () => {
  const columns: Column<PriorityBonus>[] = [
    {
      header: { title: "Amount" },
      dataField: "amountInCents",
      formatter({ amountInCents }) {
        return formatNumberToCurrency(amountInCents ?? 0);
      },
    },
    {
      header: { title: "Reason" },
      dataField: "reason",
      formatter({ reason }) {
        return reason ? priorityBonusReasonLabels[reason] : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: {
        title: "Created",
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
    {
      header: {
        title: "Created By",
      },
      dataField: "createdByName",
      cellProps: { noWrap: true },
    },
  ];

  return { columns };
};
