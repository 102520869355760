import range from "lodash/range";
import React from "react";
import styled from "styled-components";

import { Flex } from "components/Flex";

type Props = {
  amount: number;
};

export const AssessmentCardsPlaceholder = ({ amount }: Props) => {
  const cards = range(0, amount);
  return (
    <Container>
      {cards.map((_, i) => (
        <Card key={i}>
          <Flex justifyContent="space-between" alignItems="center">
            <div>
              <Title />
              <Questions />
            </div>
            <Due />
          </Flex>
          <Start />
        </Card>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  grid-gap: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Card = styled.div`
  border-radius: 4px;
  box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 258px;
  height: 106px;
  padding: 0.75rem 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  background-color: #f2f2f2;
  border-radius: 2px;
  height: 1.5rem;
  width: 100px;
  margin-bottom: 0.5rem;
`;

const Questions = styled.div`
  background-color: #f2f2f2;
  border-radius: 2px;
  height: 1rem;
  width: 70px;
  margin-bottom: 0.5rem;
`;

const Due = styled.div`
  background-color: #f2f2f2;
  border-radius: 2px;
  height: 1rem;
  width: 60px;
`;

const Start = styled.div`
  background-color: #f2f2f2;
  border-radius: 2px;
  height: 16px;
  width: 100px;
`;
