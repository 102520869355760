import { gql } from "@apollo/client";

export const SEARCH_PAPA_LIST = gql`
  query papas($filter: [PapaFilter]) {
    papas(filter: $filter) {
      data {
        id
        fullName
        dataVisibility {
          concealed
        }
        account {
          data {
            fullName
          }
        }
      }
    }
  }
`;
