import { useApolloClient } from "@apollo/client";
import React, { ReactElement, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import Heading from "components/Heading";
import Text from "components/Text";
import { formatNumberToCurrency } from "utils/numbers/currency";

import { GET_ACCOUNT_PLAN } from "./gql";

type Props = {
  accountId?: string;
};

type ContainerProps = {
  children: ReactElement[] | ReactElement;
};

const Container = ({ children }: ContainerProps) => (
  <Tile>
    <Heading as="h3" color="primary" bold={false}>
      * Plan
    </Heading>
    {children}
  </Tile>
);

type Plan = {
  hourlyRate: number;
  monthlyRate: number;
  id: string;
};

const PlanBlock = ({ accountId }: Props) => {
  const client = useApolloClient();
  const [currentPlan, setPlan] = useState<Plan | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!accountId) return;

    const getPlanAccountDetails = async (accountId: string) => {
      setLoading(true);
      const { data } = await client.query({
        query: GET_ACCOUNT_PLAN,
        variables: {
          id: accountId,
        },
      });

      if (data?.account?.data?.plan?.data) {
        setPlan(data.account.data.plan.data);
      } else {
        setPlan(null);
      }
      setLoading(false);
    };

    getPlanAccountDetails(accountId);
  }, [accountId, client]);

  if (!accountId) {
    return (
      <Container>
        <p>No member selected</p>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <ContentLoader viewBox="0 0 311 100 " style={{ maxWidth: 311 }}>
          <rect x="51" y="3" rx="4" ry="4" width="169" height="18" />
          <rect x="51" y="30" rx="4" ry="4" width="246" height="18" />
          <rect x="51" y="57" rx="4" ry="4" width="252" height="18" />
        </ContentLoader>
      </Container>
    );
  }

  if (!currentPlan) {
    return (
      <Container>
        <p>Member doen&apos;t have a plan</p>
      </Container>
    );
  }

  return (
    <Container>
      <blockquote>
        <Text>Hourly rate: {formatNumberToCurrency(currentPlan.hourlyRate)}</Text>
        <Text>Monthly membership: {formatNumberToCurrency(currentPlan.monthlyRate)}</Text>
        <Text>Plan ID: {currentPlan.id}</Text>
      </blockquote>
    </Container>
  );
};

const Tile = styled.div`
  padding: 0.75rem 1.25rem;
  background-color: #f7f9ff;
`;

export default PlanBlock;
