import { useField } from "formik";
import React from "react";

import Form from "components/Form";
import SwitchButton from "components/SwitchButton";

const CustomVisitLeadHoursOptions = () => {
  const [{ value: useCustomVisitLeadHours }, ,] = useField<boolean>("useCustomVisitLeadHours");
  return (
    <>
      <Form.Group>
        <SwitchButton name="useCustomVisitLeadHours" label="Uses Custom Visit Lead Hours" />
      </Form.Group>

      <Form.Group>
        <Form.Label htmlFor="custom-visit-lead-hours">Custom Visit Lead Hours</Form.Label>
        <Form.Input
          id="custom-visit-lead-hours"
          name="customVisitLeadHours"
          type="number"
          disabled={!useCustomVisitLeadHours}
        />
      </Form.Group>
    </>
  );
};

export default CustomVisitLeadHoursOptions;
