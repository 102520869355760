import { PalStatus, PapaState } from "generated/types";

export const statusLogLabels: {
  [key in PalStatus | PapaState]?: string;
} = {
  [PalStatus.Activated]: "Activated",
  [PalStatus.Active]: "Active",
  [PalStatus.Approved]: "Approved",
  [PalStatus.Banned]: "Banned",
  [PalStatus.Churned]: "Churned",
  [PalStatus.Inactive]: "Inactive",
  [PalStatus.NeverActivated]: "Never Activated",
  [PalStatus.Onboarded]: "Onboarded",
  [PalStatus.Suspended]: "Suspended",
  [PalStatus.UnderInvestigation]: "Under Investigation",
  [PapaState.Active]: "Active",
  [PapaState.ActiveWithoutRecurring]: "Active Without Recurring",
  [PapaState.ChangedPlans]: "Changed Plans",
  [PapaState.Deceased]: "Deceased",
  [PapaState.DuplicateMember]: "Duplicate Member",
  [PapaState.Inactive]: "Inactive",
  [PapaState.Ineligible]: "Ineligible",
  [PapaState.OptOut]: "Opt Out",
  [PapaState.Paused]: "Paused",
  [PapaState.WrongAccount]: "Wrong Account",
  [PapaState.NotCurrentlyQualified]: "Not currently qualified",
  [PapaState.UnderInvestigation]: "Under Investigation",
};

export const STATUS_LOG_TYPES = Array.from(Object.values(statusLogLabels)) as string[];
