import { RateReview } from "@styled-icons/material-outlined/RateReview";
import React, { ReactElement } from "react";
import Helmet from "react-helmet";

import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";

import PalReviewsTable from "./PalReviewsTable";

const PalReviews = (): ReactElement => {
  useTopBarOptions({ title: "Pal Reviews", icon: RateReview, breadcrumbs: ["Reviews"] });
  const { isReviewer } = useCurrentAccountRole();

  return (
    <>
      <Helmet>
        <title>Pal Reviews - Papa Admin</title>
      </Helmet>

      {isReviewer ? <PalReviewsTable /> : "Not Available"}
    </>
  );
};

export default PalReviews;
