import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import omit from "lodash/omit";
import moment from "moment-timezone";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import GoogleLocationSearch from "components/GoogleLocationSearch";
import InputDate from "components/InputDate";
import InputPhone from "components/InputPhone";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";
import Select from "components/Select";
import { DATE_FORMAT } from "constants/date";
import { genderOptions } from "constants/gender";
import { languageOptions } from "constants/language";
import { Gender, Location, LocationInput, PalInput, PalSingleResult } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { UPDATE_LOCATION } from "utils/gql/location";
import { mapSelectValuesToOptions } from "utils/select";
import { formatEmail } from "utils/strings/formatEmail";
import { formatFullName } from "utils/strings/formatFullName";

import { GET_PAL_OVERVIEW, GET_PAL_TYPES } from "../../gql";
import { CREATE_LOCATION, GET_PAL_OVERVIEW_EDIT_FIELDS, UPDATE_ACCOUNT, UPDATE_PAL } from "./gql";
import { UpdateAccountInput } from "./models";
import { FormValues, schema } from "./schema";

interface Props {
  palId: string;
  onEditFinish: () => void;
}

type Data = {
  pal: PalSingleResult;
};

export const OverviewEditForm: React.FC<Props> = ({ palId, onEditFinish }) => {
  const { data, error, loading } = useQuery<Data>(GET_PAL_OVERVIEW_EDIT_FIELDS, {
    variables: { id: palId },
  });
  const { data: palTypes } = useQuery(GET_PAL_TYPES);
  const [updatePal] = useMutation(UPDATE_PAL);
  const [createLocation] = useMutation(CREATE_LOCATION);
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    refetchQueries: [{ query: GET_PAL_OVERVIEW, variables: { id: palId } }],
  });
  const arePalTypesEnabled = useIsFeatureEnabled("pal_types_in_admin");
  const { isEngineeringAdmin, isSuperAdmin, isVisitSuccessAgent } = useCurrentAccountRole();

  const handleSubmit = async (values: FormValues) => {
    if (!data || !data?.pal?.data?.account?.data?.id) return;
    if (values.gender !== Gender.Diverse) {
      values.genderText = "";
    }
    try {
      const palInput = getPalInput(values);
      const locationInput = getLocationInput(values, data.pal.data.account.data.id);
      if (!homeLocationId) {
        const locationData = await createLocation({
          variables: {
            input: locationInput,
          },
        });
        homeLocationId = locationData?.data?.createLocation?.data?.id ?? null;
      }
      const accountInput: UpdateAccountInput = {
        fullName: formatFullName(values.fullName),
        email: formatEmail(values.email),
        phoneNumber: values.phoneNumber,
        homeLocationId,
        testAccount: values.testAccount,
      };
      const mutations = [
        updatePal({ variables: { id: pal?.id, input: palInput } }),
        updateAccount({ variables: { id: palAccountId, input: accountInput } }),
      ];
      if (initialAddress !== values.address?.address) {
        mutations.push(
          updateLocation({
            variables: {
              id: homeLocationId,
              input: locationInput,
            },
          })
        );
      }
      const [palUpdate, accountUpdate] = await Promise.all(mutations);
      if (palUpdate.data?.updatePal?.data?.id && accountUpdate.data?.updateAccount?.data?.id) {
        toast.success("Pal was edited with success!");
        onEditFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (!data || loading)
    return (
      <ModalContainer>
        <Loader />
      </ModalContainer>
    );
  const pal = data?.pal?.data;

  if (!pal) {
    return (
      <>
        <h1>404 :-(</h1>
        <p>pal not found</p>
      </>
    );
  }

  const location = pal?.account?.data?.homeLocation?.data as Location;
  const initialAddress = location?.address;
  let homeLocationId = location?.id ?? null;
  const palAccountId = pal.account?.data?.id;

  const initialValues: FormValues = {
    fullName: pal.fullName ?? "",
    school: pal.school ?? "",
    email: pal.email ?? "",
    address: location ?? {},
    phoneNumber: pal.phoneNumber ?? "",
    dateOfBirth: pal.dateOfBirth ?? "",
    fieldOfStudy: pal.fieldOfStudy ?? "",
    languages: pal.languages?.data?.filter((language) => language) ?? [],
    gender: pal.gender ?? null,
    palTypeId: pal?.palType?.data?.id ?? "",
    genderText: pal.genderText ?? "",
    drugTestClean: pal?.drugTestClean ?? false,
    testAccount: pal?.account?.data?.testAccount ?? false,
    bio: pal?.bio ?? "",
    covidVaccinated: pal?.covidVaccinated ?? false,
    optOutVisitSuccessCall: pal?.optOutVisitSuccessCall?.data?.optOut
      ? omit(pal?.optOutVisitSuccessCall?.data, ["id", "__typename"])
      : {
          optOut: false,
          indefinitely: true,
          optOutUntil: "",
        },
  };

  const listPalTypeOptions = () => {
    return palTypes?.palTypes?.data?.map((value: { code: String; id: String }) => ({
      label: value.code,
      value: value.id,
    }));
  };

  const palTypeIdDefaultValue =
    pal?.palType?.data?.id && pal?.palType?.data?.code
      ? { label: pal.palType.data.code, value: pal.palType.data.id }
      : undefined;

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values }) => (
        <ModalStyledForm>
          <ModalContainer>
            <QueryErrors error={error} />
            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Input name="fullName" />
                </Form.Group>
              </Form.Col>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Input name="email" />
                </Form.Group>
              </Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Birth date</Form.Label>
                  <InputDate
                    format={DATE_FORMAT}
                    isOutsideRange={() => false}
                    withYear={true}
                    id="pal-birth-date"
                    name="dateOfBirth"
                  />
                </Form.Group>
              </Form.Col>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Phone number</Form.Label>
                  <InputPhone name="phoneNumber" />
                </Form.Group>
              </Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <Select
                    name="gender"
                    defaultValue={mapSelectValuesToOptions(initialValues.gender, genderOptions)}
                    options={genderOptions}
                    isSearchable={false}
                  />
                  {values.gender === Gender.Diverse && (
                    <>
                      <Form.Label>Please, self identify</Form.Label>
                      <Form.Input name="genderText" />
                    </>
                  )}
                </Form.Group>
              </Form.Col>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Language</Form.Label>
                  <Select
                    name="languages"
                    defaultValue={mapSelectValuesToOptions(
                      initialValues.languages,
                      languageOptions
                    )}
                    options={languageOptions}
                    isSearchable={false}
                    isMulti
                  />
                </Form.Group>
              </Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>School</Form.Label>
                  <Form.Input name="school" />
                </Form.Group>
              </Form.Col>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Field of study</Form.Label>
                  <Form.Input name="fieldOfStudy" />
                </Form.Group>
              </Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Home address</Form.Label>
                  <GoogleLocationSearch name="address" placeholder="" />
                </Form.Group>
              </Form.Col>
              {arePalTypesEnabled &&
                (isEngineeringAdmin || isSuperAdmin || isVisitSuccessAgent) && (
                  <Form.Col>
                    <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <Select
                        name="palTypeId"
                        defaultValue={palTypeIdDefaultValue}
                        options={listPalTypeOptions()}
                        isSearchable={false}
                      />
                    </Form.Group>
                  </Form.Col>
                )}
            </Form.Row>

            <Form.Label>Bio</Form.Label>
            <Form.TextArea aria-label="bio" name="bio" fullWidth />

            <Form.Fieldset legend="Settings">
              <Form.Row>
                <Form.Col4>
                  <Form.Group>
                    <Form.Label>Covid-19 Vaccinated</Form.Label>
                    <Form.Switch name="covidVaccinated" aria-label="covidVaccinated" />
                  </Form.Group>
                </Form.Col4>
                <Form.Col4>
                  <Form.Group>
                    <Form.Label>Drug test passed</Form.Label>
                    <Form.Switch name="drugTestClean" aria-label="drugTestClean" />
                  </Form.Group>
                </Form.Col4>
                <Form.Col4>
                  <Form.Group>
                    <Form.Label>Is test account</Form.Label>
                    <Form.Switch name="testAccount" aria-label="testAccount" />
                  </Form.Group>
                </Form.Col4>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <Form.Group>
                    <Form.Label>Opt-out Visit Success calls</Form.Label>
                    <Form.Switch
                      name="optOutVisitSuccessCall.optOut"
                      aria-label="optOutVisitSuccessCall"
                    />
                  </Form.Group>
                </Form.Col>
                {values.optOutVisitSuccessCall.optOut && (
                  <>
                    <Form.Col>
                      <Form.Radio
                        value={true}
                        name="optOutVisitSuccessCall.indefinitely"
                        label="Indefinitely"
                        controlId="indefinitely"
                      />
                      <Form.Radio
                        value={false}
                        name="optOutVisitSuccessCall.indefinitely"
                        label="Until end of day"
                        controlId="until end of day"
                      />
                    </Form.Col>
                    <Form.Col>
                      {!values.optOutVisitSuccessCall.indefinitely && (
                        <InputDate id="optOutUntil" name="optOutVisitSuccessCall.optOutUntil" />
                      )}
                    </Form.Col>
                  </>
                )}
              </Form.Row>
            </Form.Fieldset>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={onEditFinish}>
              Close
            </Button>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};

const getPalInput = (values: FormValues): PalInput => ({
  dateOfBirth: values.dateOfBirth || null,
  fieldOfStudy: values.fieldOfStudy,
  school: values.school,
  gender: values.gender,
  genderText: values.genderText,
  languages: values.languages,
  bio: values.bio,
  palTypeId: values.palTypeId,
  covidVaccinated: values.covidVaccinated,
  drugTestClean: values.drugTestClean,
  optOutVisitSuccessCall: {
    optOut: values.optOutVisitSuccessCall.optOut,
    indefinitely: !values.optOutVisitSuccessCall.optOut
      ? false
      : values.optOutVisitSuccessCall.indefinitely,
    optOutUntil: !values.optOutVisitSuccessCall.optOut
      ? undefined
      : values.optOutVisitSuccessCall.indefinitely
      ? undefined
      : moment(values.optOutVisitSuccessCall.optOutUntil).endOf("day").toISOString(),
  },
});

const getLocationInput = (values: FormValues, accountId: string): Partial<LocationInput> => ({
  address: values.address?.address,
  lat: values.address?.lat,
  lng: values.address?.lng,
  state: values.address?.state,
  city: values.address?.city,
  countryIso: values.address?.countryIso,
  zipcode: values.address?.zipcode,
  accountId,
});
