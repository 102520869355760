import { CreditCard } from "generated/types";

export function creditCardStatus({ default: isDefault, deletedAt }: CreditCard): string {
  if (deletedAt) {
    return "Deleted";
  }

  if (isDefault) {
    return "Default";
  }

  return "";
}
