import { gql } from "@apollo/client";

export const MAKE_A_PAL = gql`
  mutation businessCreatePal($input: PalInput!) {
    createPal(input: $input) {
      data {
        id
      }
    }
  }
`;
