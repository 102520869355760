import { useMutation, useQuery } from "@apollo/client";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { ReadOnlyCheckbox } from "components/Checkbox/ReadOnlyCheckbox";
import { Loader } from "components/Loader";
import ConfirmModal from "components/Modal/ConfirmModal";
import { SwitchButtonInput } from "components/SwitchButton";
import { ConsentStatus } from "generated/types";
import {
  MessageServiceAuthorization,
  MessageServiceAuthorizationSingleResult,
  ResendMessageServiceTextSingleResult,
} from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";

import {
  ENROLL_PAPA_TEXT_MESSAGING,
  MESSAGE_SERVICES,
  OPT_OUT_PAPA_TEXT_MESSAGING,
  RESEND_OPT_IN_TEXT_MESSAGE,
} from "./gql";
import { TextMessagingStatus, TextMessagingStatusColor, calculateStatus } from "./utils";

interface Props {
  papaId: string;
  messageServiceAuthorization?: MessageServiceAuthorization | null;
  onRefetch: () => void;
}

const Switch = ({
  onRefetch,
  papaId,
  messageServiceAuthorization: initialMessageServiceAuthorization,
}: Props) => {
  const { isOpen: enrollConfirmModalOpen, toggle: toggleEnrollConfirmModal } = useModalToggle();
  const { isOpen: resendConfirmModalOpen, toggle: toggleResendConfirmModal } = useModalToggle();
  const { isOpen: optOutConfirmModalOpen, toggle: toggleOptOutConfirmModal } = useModalToggle();
  const [messageServiceAuthorization, setMessageServiceAuthorization] = useState<
    MessageServiceAuthorization | null | undefined
  >(initialMessageServiceAuthorization);

  const [enrollPapaTextMessaging, { loading }] = useMutation<
    {
      createMessageServiceAuthorization: MessageServiceAuthorizationSingleResult;
    },
    { papaId: string; messageServiceId: string; agreement?: string }
  >(ENROLL_PAPA_TEXT_MESSAGING);
  const [resendOptInTextMessage] = useMutation<
    { resendMessageServiceText: ResendMessageServiceTextSingleResult },
    { messageServiceAuthorizationId: string; message?: string }
  >(RESEND_OPT_IN_TEXT_MESSAGE);
  const [optOutPapaTextMessaging] = useMutation<
    { optOutMessageServiceText: MessageServiceAuthorizationSingleResult },
    { papaId: string }
  >(OPT_OUT_PAPA_TEXT_MESSAGING);

  const status = calculateStatus(messageServiceAuthorization);
  const skipMessageServiceQuery = !(status === TextMessagingStatus.NotEnrolled);

  const { data } = useQuery(MESSAGE_SERVICES, { skip: skipMessageServiceQuery });
  const messageServiceId = data?.messageServices?.data?.[0]?.id;

  const handleEnroll = async () => {
    try {
      const { data } = await enrollPapaTextMessaging({
        variables: {
          papaId,
          messageServiceId,
        },
      });

      const newMessageServiceAuthorization = data?.createMessageServiceAuthorization?.data;

      if (newMessageServiceAuthorization?.id) {
        onRefetch();
        setMessageServiceAuthorization(newMessageServiceAuthorization);
        toast.success("Confirmation text message has been sent to the member");
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const handleResend = async () => {
    try {
      const { data } = await resendOptInTextMessage({
        variables: {
          messageServiceAuthorizationId: messageServiceAuthorization?.id ?? "",
        },
      });

      const status = data?.resendMessageServiceText?.data?.status;

      if (status === "accepted") {
        toast.success("Confirmation text message has been resent to the member");
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const handleOptOut = async () => {
    try {
      const { data } = await optOutPapaTextMessaging({ variables: { papaId } });

      const consentStatus = data?.optOutMessageServiceText?.data?.consentStatus;

      if (consentStatus === ConsentStatus.OptOut) {
        toast.success("Member has been opted out of text messaging");
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  useEffect(() => {
    setMessageServiceAuthorization(initialMessageServiceAuthorization);
  }, [initialMessageServiceAuthorization]);

  return (
    <Container>
      {status === TextMessagingStatus.NotEnrolled ? (
        <SwitchButton
          label={loading ? <Loader /> : "Enable Text Messaging"}
          checked={false}
          disabled={loading}
          onClick={toggleEnrollConfirmModal}
        />
      ) : status === TextMessagingStatus.Pending ? (
        <SwitchButton
          label="Pending Member Confirmation"
          checked={true}
          color={TextMessagingStatusColor.Pending}
          onClick={toggleOptOutConfirmModal}
        />
      ) : status === TextMessagingStatus.OptIn ? (
        <SwitchButton
          label="Text Messaging Enabled"
          checked={true}
          color={TextMessagingStatusColor.OptIn}
          onClick={toggleOptOutConfirmModal}
        />
      ) : status === TextMessagingStatus.OptOut ? (
        <SwitchButton label="Enable Text Messsaging" checked={false} disabled={true} />
      ) : null}
      {status === TextMessagingStatus.Pending && (
        <ResendLink onClick={toggleResendConfirmModal}>Resend Opt-In Text Msg</ResendLink>
      )}
      <ReadOnlyCheckbox
        label="Member Declined Text Messaging"
        checked={status === TextMessagingStatus.OptOut ? true : false}
        color={TextMessagingStatusColor.OptOut}
        disabled={status === TextMessagingStatus.OptOut ? false : true}
      />
      <SmallText>
        In order to opt-in, the member needs to
        <br />
        text START to +1 305-424-2995.
      </SmallText>
      <ConfirmModal
        isOpen={enrollConfirmModalOpen}
        onCancel={toggleEnrollConfirmModal}
        onConfirm={() => {
          handleEnroll();
          toggleEnrollConfirmModal();
        }}
        header="Please Confirm"
      >
        Are you sure you want to send an opt-in text message to the member?
      </ConfirmModal>
      <ConfirmModal
        isOpen={resendConfirmModalOpen}
        onCancel={toggleResendConfirmModal}
        onConfirm={() => {
          handleResend();
          toggleResendConfirmModal();
        }}
        header="Please Confirm"
      >
        Are you sure you want to RE-SEND an opt-in text message to the member?
      </ConfirmModal>
      <ConfirmModal
        isOpen={optOutConfirmModalOpen}
        onCancel={toggleOptOutConfirmModal}
        onConfirm={() => {
          handleOptOut();
          toggleOptOutConfirmModal();
        }}
        header="Please Confirm"
      >
        Are you sure you want to opt-out this member?
      </ConfirmModal>
    </Container>
  );
};

type SwitchButtonProps = {
  label: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const SwitchButton = ({ label, checked, disabled, ...inputProps }: SwitchButtonProps) => {
  return (
    <>
      <Label disabled={disabled}>
        <SwitchButtonInput readOnly {...inputProps} checked={checked} disabled={disabled} />
        <Text>{label}</Text>
      </Label>
    </>
  );
};

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  padding-top: 10px;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.text.secondary};
    `}
`;

const Text = styled.span`
  margin-left: 10px;
`;

const SmallText = styled.div`
  font-size: 12px;
  white-space: nowrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ResendLink = styled.div`
  font-size: 10px;
  color: #2e5bff;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 48px;
  margin-top: -12px;
`;

export default Switch;
