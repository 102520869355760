import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import Pill from "components/Pill";
import { PillData } from "types/search";

interface Props {
  pills: PillData[];
  setPills: (pills: PillData[]) => void;
  names: { [key: string]: string };
  loading: boolean;
  noNegativeMarge?: boolean;
}

/**
 * The SearchPills component shows Pills, each with the name of the search criterion.
 *
 * @description
 * Each Pill shows the account name, the ID of which is the search criterion.
 * Also, each Pill has a button to remove this search criterion.
 * In order to correctly show the names of already applied search criteria, the component needs
 * to provide the prop `name` as an object that contains pairs of ID - name.
 * This object is formed from the data query for the page.
 * Also, the component uses the loading flag from the same data query.
 *
 * @usage
 * The SearchPills component must be used in conjunction with the Search component and the useSearch hook
 * See example usage in the Search component description
 */
const SearchPills = ({ pills, setPills, names, loading, noNegativeMarge }: Props) => {
  const removePill = (id: string) => {
    setPills(pills.filter((item) => item.id !== id));
  };
  if (pills.length === 0) return null;

  return (
    <PillsContainer noNegativeMarge={noNegativeMarge}>
      {!loading ? (
        pills.map(({ id, name }, index) => (
          <Pill key={id} onDelete={() => removePill(id)}>
            {names?.[id] ? names[id] : name}
          </Pill>
        ))
      ) : (
        <ContentLoader
          speed={2}
          width="100%"
          height={10}
          backgroundColor="#f3f3f3"
          foregroundColor="#c0c0c0"
        >
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="10" />
        </ContentLoader>
      )}
    </PillsContainer>
  );
};

const PillsContainer = styled.div<{ noNegativeMarge?: boolean }>`
  margin: ${({ noNegativeMarge }) => (noNegativeMarge ? "0" : "-3rem")} 0 1.5rem;
`;

export default SearchPills;
