import React from "react";
import styled from "styled-components";

import Text from "components/Text";
import { AssessmentsAnswerFragment } from "generated/types";
import { AssessmentType } from "types/assessments";

interface Props {
  answers: AssessmentsAnswerFragment[];
  type: string | null;
}

export const AssessmentAnswers: React.FC<Props> = ({ answers, type }) => {
  const answerByQuestions = Object.values(
    answers.reduce<
      Record<
        string,
        {
          questionId: string;
          questionText: string;
          questionPosition: number;
          answers: AssessmentsAnswerFragment[];
        }
      >
    >((acc, answer) => {
      const questionId = answer.question?.data?.id as string;
      const questionText = answer.question?.data?.questionText as string;
      const questionPosition = answer.question?.data?.position as number;

      if (!acc[questionId]) {
        acc[questionId] = { questionId, questionText, questionPosition, answers: [] };
      }
      acc[questionId].answers.push(answer);
      return acc;
    }, {})
  );

  return (
    <Container>
      {answerByQuestions.map(({ questionId, questionText, questionPosition, answers }) => (
        <AnswerContainer key={questionId}>
          <Text>
            {questionPosition}: {questionText}
          </Text>
          <ul>
            {answers.map(({ id, answer, score }) => {
              const points = type === AssessmentType.papaSocialIndex && score ? score : 0;

              return (
                <AnswerText key={id}>
                  {answer}.{!!points && <Points as="span">{points} Points</Points>}
                </AnswerText>
              );
            })}
          </ul>
        </AnswerContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  grid-gap: 14px;

  position: absolute;
  left: 0;

  background: #fff;
  padding: 7px 26px 26px 26px;
  z-index: 1;
`;

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerText = styled.li`
  margin-top: 10px;
  color: #00a56c;
`;

const Points = styled(Text)`
  display: inline-block;
  margin-left: 5px;
  font-size: 10px;
  font-style: italic;
`;
