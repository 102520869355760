import React from "react";
import styled from "styled-components";

import TableBody from "./TableBody";
import TableCell from "./TableCell";
import TableHead from "./TableHead";
import TableHeadCell from "./TableHeadCell";
import TableLoadingCell from "./TableLoadingCell";
import { TableLoadingPlaceholder } from "./TableLoadingPlaceholder";
import TableRow from "./TableRow";

type Props = {
  children: React.ReactNode;
  showOverflow?: boolean;
  fixedLayout?: boolean;
};

const TableWrapper = styled("div")<{ showOverflow?: boolean }>`
  display: block;
  width: 100%;
  ${({ showOverflow }) => !showOverflow && "overflow-x: auto;"}
`;

const StyledTable = styled("table")<{ fixedLayout?: boolean }>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
  position: relative;
  ${({ fixedLayout }) => fixedLayout && "table-layout: fixed"}
`;

const Table = ({ children, showOverflow, fixedLayout }: Props) => {
  return (
    <TableWrapper showOverflow={showOverflow}>
      <StyledTable fixedLayout={fixedLayout}>{children}</StyledTable>
    </TableWrapper>
  );
};

Table.Head = TableHead;
Table.HeadCell = TableHeadCell;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.LoadingCell = TableLoadingCell;
Table.Placeholder = TableLoadingPlaceholder;

export default Table;
