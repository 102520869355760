import { useMutation } from "@apollo/client";
import React from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { CREDIT_CARDS } from "components/DataTables/CreditCardsTable/gql";
import ConfirmModal from "components/Modal/ConfirmModal";
import { CreditCardFragment } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import { DELETE_BUSINESS_CREDIT_CARD } from "./gql";

interface Props {
  card: CreditCardFragment;
  isOpen: boolean;
  toggle: () => void;
}

export const DeleteCreditCardModal: React.FC<Props> = ({ isOpen, toggle, card }) => {
  const { page, limit } = usePageInfo();
  const { id: accountId } = useParams<{ id: string }>();
  const [deleteCreditCard, { loading }] = useMutation(DELETE_BUSINESS_CREDIT_CARD, {
    refetchQueries: [
      {
        query: CREDIT_CARDS,
        variables: {
          filter: {
            account: {
              idEq: accountId,
            },
          },
          pagination: { page, limit },
        },
      },
    ],
  });

  const onDelete = async () => {
    try {
      const { data } = await deleteCreditCard({
        variables: { id: card.id },
      });

      if (data?.destroyCreditCard?.data?.id) {
        toggle();
        toast.success("Credit card was successfully removed");
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      loading={loading}
      header="Delete credit card?"
      onCancel={toggle}
      onConfirm={onDelete}
    >
      {`Are you sure that you want to delete ${card?.nickname}`}
    </ConfirmModal>
  );
};
