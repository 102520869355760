import { useMutation, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { ModalContainer, ModalFooter } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Text from "components/Text";

import { EXPORT_PAYMENTS, GET_PAYMENT_COUNT } from "./gql";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  filters?: any;
}

export const PaymentExportModal: React.FC<Props> = ({ isOpen, toggle, filters }) => {
  const [exportPayments, { loading: exportLoading, error: mutationError }] =
    useMutation(EXPORT_PAYMENTS);
  const { data } = useQuery(GET_PAYMENT_COUNT, {
    variables: {
      filter: isEmpty(filters) ? null : filters,
    },
  });

  const totalCount = data?.payments?.pagination?.totalCount;

  const handleSubmit = async () => {
    try {
      const { data } = await exportPayments({
        variables: { filter: isEmpty(filters) ? null : filters },
      });
      if (mutationError) {
        toast.error(mutationError);
      }
      if (data?.exportPayments?.data?.success) {
        toast.success("Payments exported! Check your email.");
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
    toggle();
  };

  return (
    <ModalLayout title="Export Payments" isOpen={isOpen} toggle={toggle}>
      <ModalContainer>
        <Text>
          A CSV file containing all <span>{totalCount}</span> payments will be sent to your email
          address.
        </Text>

        <ModalFooter>
          <Button variant="secondary" onClick={toggle}>
            Close
          </Button>
          <Button disabled={exportLoading} variant="primary" onClick={handleSubmit}>
            {exportLoading && <SpinningIcon size={18} />}
            OK
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalLayout>
  );
};

export default PaymentExportModal;
