import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { FunctionComponent } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Heading from "components/Heading";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import { Account, OrganizationSingleResult } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { GET_ORGANIZATION } from "./gql";
import { useBusinessTableColumns } from "./useBusinessTableColumns";

type Params = {
  id: string;
};

interface Data {
  organization: OrganizationSingleResult;
}

const Organization: FunctionComponent = () => {
  const {
    capitalized: { pluralEntityName: programsOrBusinesses },
  } = useCallItClientProgramOrBusiness();

  const { id } = useParams<Params>();
  const { columns } = useBusinessTableColumns();
  const { data, loading, error } = useQuery<Data>(GET_ORGANIZATION, {
    variables: { id },
  });
  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const topBarTitle = clientCopyUpdateEnabled ? "Client" : "Organization";
  const accountsBreadcrumbLabel = clientCopyUpdateEnabled ? "All Clients" : "All Organizations";
  const accountsBreadcrumbLink = clientCopyUpdateEnabled ? "/clients" : "/organizations";

  const organization = data?.organization.data;
  const businesses = organization?.accounts?.data;
  const heading = `${programsOrBusinesses} List`;
  const title = `${heading} - Papa Admin`;

  useTopBarOptions({
    title: topBarTitle,
    name: organization?.name ?? "",
    icon: AccountCircle,
    breadcrumbs: ["Accounts", { label: accountsBreadcrumbLabel, link: accountsBreadcrumbLink }],
    deps: [organization?.name],
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Heading>{loading ? <SpinningIcon size={20} /> : heading}</Heading>

      <SmartTable<Account>
        keyField="id"
        localStorageKey="organizationTable"
        data={businesses}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default Organization;
