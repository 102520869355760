import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { SEARCH_RESULT_BY_VISITS } from "components/Search/gql";
import { PillData, SearchResult } from "types/search";
import { concealedField } from "utils/fieldsPermissions/concealedField";
import { isUuid } from "utils/isUuid";

export const useVisitSearch = (pills: PillData[]) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: SEARCH_RESULT_BY_VISITS,
        variables: {
          search: `*${value}*`,
          index: isUuid(value) ? "visits" : "papas",
        },
      });

      if (data?.searchResult?.data) {
        return data.searchResult.data.map(({ id, ...result }: SearchResult) => {
          const papaFullName = concealedField("fullName", result?.fullName, result?.dataVisibility);
          const visitPapaFullName = concealedField(
            "fullName",
            result?.papa?.data?.fullName,
            result?.papa?.data?.dataVisibility
          );

          const papaName = papaFullName || visitPapaFullName || "No Papa";

          return {
            typename: result.__typename,
            value: id,
            ...(result.__typename === "Papa" ? { papaId: id } : {}),
            ...(result.__typename === "Visit" ? { visitId: id } : {}),
            member: result?.account?.data?.fullName,
            ...(result.location ? { address: result?.location?.data?.address ?? "" } : {}),
            label: papaName,
            name: papaName,
            status: result.status,
          };
        });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };

  const searchPillNames = pills.reduce(
    (obj, { name, papaId, visitId }) => ({
      ...obj,
      ...(papaId ? { [papaId as string]: name || "no Papa" } : {}),
      ...(visitId ? { [visitId as string]: visitId } : {}),
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
