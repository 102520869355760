import React, { FunctionComponent } from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Dropdown from "components/Dropdown";
import { FeatureFlags } from "constants/featureFlags";
import { PalOnboarding, SupportTicketRequesterType } from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { DropdownActionMenu } from "../DropdownActionMenu";
import { ProspectPalMenuActions } from "./models";

interface Props {
  palOnboarding: PalOnboarding;
  isListMenu?: boolean;
  setAction: (action: ProspectPalMenuActions, palOnboarding: PalOnboarding) => void;
}

const ProspectPalActionsMenu: FunctionComponent<Props> = ({
  palOnboarding,
  setAction,
  isListMenu = false,
}) => {
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();
  const id = palOnboarding.id!;
  const type = SupportTicketRequesterType.PalOnboarding;
  const hasEmail = !!palOnboarding.account?.data?.email;

  if (loading) return <SpinningIcon size={18} />;

  const hasCompletedOrientationVideo = palOnboarding.hasCompletedOrientationVideo;

  const composeHandleSelect = (action: ProspectPalMenuActions) => () =>
    setAction(action, palOnboarding);

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "prospect pal action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {isListMenu && (
        <Dropdown.Link to={`/prospect-pals/${palOnboarding.id}`}>View Prospect Pal</Dropdown.Link>
      )}
      {renderDropDownItem(
        "Send verification email",
        composeHandleSelect(ProspectPalMenuActions.SendVerificationEmail)
      )}
      {!hasCompletedOrientationVideo &&
        renderDropDownItem(
          "Watched orientation video",
          composeHandleSelect(ProspectPalMenuActions.SetWelcomeVideoValue)
        )}
      {renderDropDownItem(
        "Delete Prospect Pal",
        composeHandleSelect(ProspectPalMenuActions.Delete)
      )}
      {supportTicketingEnabled && hasEmail
        ? renderDropDownItem("Submit Ticket", () => handleSubmitSupportTicket({ id, type }))
        : null}
    </DropdownActionMenu>
  );
};

export default ProspectPalActionsMenu;
