import React from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { LocationFormModal } from "components/LocationForm/LocationFormModal";
import { ModalLayout } from "components/Modal/ModalLayout";
import { FeatureFlags } from "constants/featureFlags";
import { Business, SupportTicketRequesterType } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DeleteAccountModal } from "modals/Accounts/DeleteAccount";
import AddCreditCard from "modals/AddCreditCard";
import { BusinessForm } from "modals/Businesses/CreateUpdateBusiness";
import ChoosePlanForm from "modals/ChoosePlan/ChoosePlanForm";
import MakePalModal from "modals/MakePal";
import { ResetPasswordModal } from "modals/ResetPasswordModal/ResetPasswordModal";
import SubmitTicketGuestUserModal from "modals/SubmitTicketGuestUser";
import { ChangeStatusFormModal } from "pages/Pals/List/ChangeStatus/ChangeStatusFormModal";
import CreatePapaModal from "pages/Papas/List/CreatePapa/CreatePapaModal";

interface Props {
  action: DataTableMenuActions;
  business: Business | null;
  onClose: () => void;
  onRefetch: () => void;
  onPapasRefetch?: () => void;
}

const Actions: React.FC<Props> = ({
  action,
  business,
  onClose,
  onRefetch,
  onPapasRefetch = () => {},
}) => {
  const {
    capitalized: { singularEntityName },
  } = useCallItClientProgramOrBusiness();

  const makePalEnabled = useIsFeatureEnabled(FeatureFlags.EnableMakePal);

  return (
    <>
      <ModalLayout
        toggle={onClose}
        title={business ? `Update ${singularEntityName}` : `Create ${singularEntityName}`}
        isOpen={action === DataTableMenuActions.Edit}
      >
        <BusinessForm businessId={business?.id} closeModal={onClose} onSubmitFinish={onRefetch} />
      </ModalLayout>

      <CreatePapaModal
        businessId={business?.id}
        accountId={business?.account?.data?.id}
        isOpen={action === DataTableMenuActions.AddPapa}
        toggle={onClose}
        onCreateFinish={onPapasRefetch}
      />
      <LocationFormModal
        accountId={business?.account?.data?.id}
        isOpen={action === DataTableMenuActions.AddLocation}
        toggle={onClose}
      />

      <DeleteAccountModal
        account={business?.account?.data || null}
        isOpen={action === DataTableMenuActions.Delete}
        toggle={onClose}
        onDeleteFinish={onRefetch}
      />

      <ResetPasswordModal
        email={business?.email || ""}
        isOpen={action === DataTableMenuActions.ResetPassword}
        toggle={onClose}
      />

      <ModalLayout
        toggle={onClose}
        title="Choose membership plan"
        isOpen={action === DataTableMenuActions.ChoosePlan}
      >
        <ChoosePlanForm accountId={business?.account?.data?.id || ""} closeModal={onClose} />
      </ModalLayout>

      <ModalLayout
        toggle={onClose}
        title="Add Credit Card"
        isOpen={action === DataTableMenuActions.AddCreditCard}
      >
        <AddCreditCard accountId={business?.account?.data?.id || ""} closeModal={onClose} />
      </ModalLayout>
      {makePalEnabled && (
        <MakePalModal
          isOpen={action === DataTableMenuActions.MakePal}
          account={business?.account?.data || null}
          onClose={onClose}
        />
      )}

      <SubmitTicketGuestUserModal
        id={business?.id!}
        type={SupportTicketRequesterType.Business}
        isOpen={action === DataTableMenuActions.SubmitTicket}
        toggle={onClose}
      />

      {business?.account?.data?.pal?.data?.id && (
        <ChangeStatusFormModal
          isOpen={action === DataTableMenuActions.SetPalStatus}
          toggle={onClose}
          palId={business.account.data.pal.data.id}
          status={business?.account?.data?.pal?.data?.status ?? null}
        />
      )}
    </>
  );
};

export default Actions;
