import moment from "moment-timezone";

import { Pal } from "generated/types";

const VIRTUAL_VISIT_PALS_CUTOFF_DATE = "2021-11-15";
const PAPA_EMAIL_ADDRESSES = ["@papa.com", "@joinpapa.com", "@papa.co"];

export const canPalSeeVirtualVisits = ({
  insertedAt,
  email,
}: Pick<Pal, "insertedAt" | "email">) => {
  if (!insertedAt || !email) {
    return false;
  }

  const withinCutoff = moment(insertedAt).isBefore(VIRTUAL_VISIT_PALS_CUTOFF_DATE, "day");
  const isPapaEmployee = PAPA_EMAIL_ADDRESSES.some((address) => email.includes(address));

  return withinCutoff || isPapaEmployee;
};
