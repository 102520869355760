import { gql } from "@apollo/client";

export const SET_PAPA_MARKETING_CONSENT = gql`
  mutation setPapaMarketingConsent($papaId: ID!, $marketingConsent: Boolean!) {
    updatePapa(id: $papaId, input: { marketingConsent: $marketingConsent }) {
      data {
        id
        marketingConsent
      }
    }
  }
`;
