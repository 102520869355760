import { useField } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";

import Form from "components/Form";
import SwitchButton from "components/SwitchButton";

const BusinessPolicySwitches = () => {
  const [{ value: allowInPersonVisits }, ,] = useField<boolean>("allowInPersonVisits");
  const [, , palCovidVaccinationHelpers] = useField<boolean>("palCovidVaccination");
  const [, , papaCovidVaccinationHelpers] = useField<boolean>("papaCovidVaccination");
  const [{ value: careConciergeEnabled }, ,] = useField<boolean>("careConciergeEnabled");
  const [{ value: warmTransfer }, ,] = useField<boolean>("warmTransfer");
  const [{ value: paysForCareConcierge }, , { setValue: setPaysForCareConcierge }] = useField<
    boolean | undefined
  >("paysForCareConcierge");

  useEffect(() => {
    if (allowInPersonVisits === false) {
      palCovidVaccinationHelpers.setValue(false);
      papaCovidVaccinationHelpers.setValue(false);
    }
    // eslint-disable-next-line
  }, [allowInPersonVisits]);

  useEffect(() => {
    if (careConciergeEnabled === false && typeof paysForCareConcierge !== "undefined") {
      setPaysForCareConcierge(undefined, false);
    }
  }, [careConciergeEnabled, paysForCareConcierge, setPaysForCareConcierge]);

  return (
    <>
      <Form.Group>
        <SwitchButton name="allowInPersonVisits" label="In person visit" />
      </Form.Group>

      <Form.Group>
        <SwitchButton name="allowVirtualVisits" label="Virtual visit" />
      </Form.Group>

      <Form.Group>
        <SwitchButton name="lonelinessCompliant" label="Assessment must show loneliness" />
      </Form.Group>

      <Form.Group>
        <SwitchButton
          name="palCovidVaccination"
          label="Covid-19 Vaccination - Pals"
          disabled={!allowInPersonVisits}
        />
      </Form.Group>

      <Form.Group>
        <SwitchButton
          name="papaCovidVaccination"
          label="Covid-19 Vaccination - Papas"
          disabled={!allowInPersonVisits}
        />
      </Form.Group>

      <Form.Group>
        <SwitchButton name="allowUberForTransportation" label="Allow Uber for transportation" />
      </Form.Group>

      <Form.Group>
        <SwitchButton name="careConciergeEnabled" label="Service Requests Enabled" />
      </Form.Group>

      <IndentedFormGroup>
        <SwitchButton
          disabled={!careConciergeEnabled}
          label="Pays for Social Care Navigation"
          name="paysForCareConcierge"
        />
      </IndentedFormGroup>

      <Form.Group>
        <SwitchButton name="warmTransfer" label="Warm Transfer" />
      </Form.Group>

      <IndentedFormGroup>
        <Form.Label>Warm Transfer Phone Number</Form.Label>
        <Form.Input
          type="text"
          name="warmTransferPhoneNumber"
          aria-label="warmTransferPhoneNumber"
          disabled={!warmTransfer}
        />
      </IndentedFormGroup>
    </>
  );
};

const IndentedFormGroup = styled(Form.Group)`
  padding-left: 2.5rem;
`;

export default BusinessPolicySwitches;
