import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import CreditCardsTable from "components/DataTables/CreditCardsTable";
import { useCaregiverQuery } from "generated/types";

const CreditCards: React.FC = () => {
  const { caregiverId } = useParams<{ caregiverId: string }>();
  const { data } = useCaregiverQuery({
    variables: { caregiverId },
  });
  const accountId = data?.caregiver?.data?.account?.data?.id;

  return (
    <>
      <Helmet>
        <title>Caregiver&apos;s Credit Cards - Papa Admin</title>
      </Helmet>

      <CreditCardsTable accountId={accountId} />
    </>
  );
};

export default CreditCards;
