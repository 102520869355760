import { useField } from "formik";
import React from "react";
import styled, { DefaultTheme } from "styled-components";

import Feedback from "components/Form/FormFeedback";
import Radio from "components/Radio";

interface Options {
  label: string;
  value: any;
}

type Variants = keyof DefaultTheme["variants"];
type Sizes = keyof DefaultTheme["sizes"];

export const defaultProps = {
  variant: "primary" as Variants,
  size: "small" as Sizes,
};

interface ButtonProps {
  variant?: Variants;
  size?: Sizes;
  disabled?: boolean;
}

interface Props extends ButtonProps {
  options: Options[];
  name: string;
}

const RadioButtonGroup: React.FC<Props> = ({ options, name, ...buttonProps }) => {
  const [, { error, touched }] = useField(name);

  return (
    <>
      <StyledButtonGroup {...buttonProps}>
        {options.map(({ label, value }, index) => (
          <Radio
            key={index}
            className="radio-button"
            value={value}
            name={name}
            controlId={label}
            label={label}
          />
        ))}
      </StyledButtonGroup>

      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

const StyledButtonGroup = styled.div<ButtonProps>`
  display: flex;
  div:first-child label {
    margin: 0;
    border-radius: 0.375rem 0 0 0.375rem;
  }
  div:last-child label {
    border-radius: 0 0.375rem 0.375rem 0;
  }
  input {
    display: none;
  }
  label {
    color: ${({ theme }) => theme.text.muted};
    border: 0.15625rem ${({ theme }) => theme.text.muted} solid;
    font-weight: bold;
    text-align: center;
    padding: ${({ size = defaultProps.size, theme }) => theme.sizes[size!]};
    margin-left: -2px;
    transition: 0.3s;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
    &:hover {
      color: ${({ variant = defaultProps.variant, theme }) => theme.variants[variant!]};
    }
  }
  input:checked + label {
    color: #fff;
    background: ${({ variant = defaultProps.variant, theme }) => theme.variants[variant!]};
    border-color: ${({ variant = defaultProps.variant, theme }) => theme.variants[variant!]};
    position: relative;
  }
`;

StyledButtonGroup.defaultProps = defaultProps;

export default RadioButtonGroup;
