import moment from "moment";
import React from "react";
import styled from "styled-components";

interface Props {
  month: moment.Moment;
  onMonthSelect: (currentMonth: moment.Moment, newMonth: string) => void;
  onYearSelect: (currentMonth: moment.Moment, newYear: string) => void;
  isVisible: boolean;
}

const currentYear = new Date().getFullYear();
const yearsCount = 110;

const options = Array.from({ length: yearsCount }, (year, index) => {
  const value = currentYear + 10 - index;

  return (
    <option key={value} value={value}>
      {value}
    </option>
  );
});

export const yearsPickerRenderer = ({ month, onYearSelect }: Props) => {
  return (
    <Container>
      {month.format("MMMM")}
      <StyledSelect value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
        {options}
      </StyledSelect>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -4px;
  padding: 0 50px;
`;

const StyledSelect = styled.select`
  width: 80px;
  height: 34px;
`;
