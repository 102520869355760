import { object, string } from "yup";

import { CommentType } from "constants/papaComment";

export interface Schema {
  type: CommentType | null;
  content: string;
}

export const schema = object().shape({
  type: string().nullable().required("Please select a comment's type."),
  content: string().required("Please enter a comment."),
});
