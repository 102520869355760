import React, { FunctionComponent } from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Dropdown from "components/Dropdown";
import { FeatureFlags } from "constants/featureFlags";
import { PapaState, SupportTicketRequesterType } from "generated/types";
import { useAllowedSetPapaStatus } from "hooks/papa/useAllowedSetPapaStatus";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { PapaConcealed } from "types";

import { DropdownActionMenu } from "../DropdownActionMenu";
import { PapaMenuActions } from "./models";

interface Props {
  papa: PapaConcealed;
  isListMenu?: boolean;
  setAction: (action: PapaMenuActions, papa: PapaConcealed) => void;
}

const PapaActionsMenu: FunctionComponent<Props> = ({ papa, setAction, isListMenu = false }) => {
  const { isSupervisor } = useCurrentAccountRole();
  const { isAllowedToSetPapaStatus } = useAllowedSetPapaStatus({ papa });
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();
  const id = papa.id!;
  const type = SupportTicketRequesterType.Papa;

  const isBannedOrSuspended = [PapaState.Banned, PapaState.Suspended].includes(
    papa?.status as PapaState
  );

  const submitOnClick = () => {
    if (papa.business?.data?.currentBusinessPolicy?.data?.warmTransfer) {
      setAction(PapaMenuActions.warmTransfer, papa);
    } else {
      handleSubmitSupportTicket({ id, type });
    }
  };

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "papa action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  if (loading) return <SpinningIcon size={18} />;

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {isListMenu && <Dropdown.Link to={`/papas/${papa.id}`}>View Papa</Dropdown.Link>}
      {isSupervisor && (
        <Dropdown.Link to={`/accounts/${papa.accountId}/activity-logs`}>
          View account logs
        </Dropdown.Link>
      )}
      {isListMenu && (
        <>
          {isAllowedToSetPapaStatus &&
            renderDropDownItem("Set Papa status", () => setAction(PapaMenuActions.setStatus, papa))}
          {!isBannedOrSuspended && (
            <Dropdown.Link to={`/schedule-visit/${papa.id}`}>Schedule visit</Dropdown.Link>
          )}
          {renderDropDownItem("Add Location", () => setAction(PapaMenuActions.addLocation, papa))}
        </>
      )}
      {supportTicketingEnabled &&
        renderDropDownItem("Report member/agent interaction", () =>
          handleSubmitSupportTicket({ id, type, redirectToAgent: true })
        )}
      {supportTicketingEnabled && renderDropDownItem("Submit Ticket", submitOnClick)}
    </DropdownActionMenu>
  );
};

export default PapaActionsMenu;
