import { gql } from "@apollo/client";

export const GET_PAYMENT_INVOICES = gql`
  query getPaymentInvoices(
    $filter: FilterPaymentInvoiceInput
    $pagination: PaginationInput
    $sorting: PaymentInvoiceSorting
  ) {
    paymentInvoices(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        amount
        state
        insertedAt
        visit {
          data {
            account {
              data {
                fullName
              }
            }
            pal {
              data {
                id
                fullName
              }
            }
            papa {
              data {
                id
                fullName
                dataVisibility {
                  concealed
                }
              }
            }
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
