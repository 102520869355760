import * as yup from "yup";

import { MemberComplaintCode } from "generated/types";

export interface Schema {
  comment: string;
  reason: MemberComplaintCode | null;
}

export const schema = yup.object().shape({
  reason: yup.string().nullable().required("Required"),
  comment: yup.string().required("Required"),
});
