import queryString from "query-string";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";

import Pagination from "./index";

const PaginationQueryString = ({ totalPages, itemsAfterAndBefore = 3, align = "right" }: Props) => {
  const { page } = usePageInfo();
  const urlQuery = useUrlQuery();
  const location = useLocation();
  const history = useHistory();
  const setCurrentPage = (page: number) => {
    history.push(
      location.pathname +
        "?" +
        queryString.stringify({ ...urlQuery, page }, { arrayFormat: "index" })
    );
  };

  return (
    <Pagination
      align={align}
      currentPage={page}
      totalPages={totalPages}
      itemsAfterAndBefore={itemsAfterAndBefore}
      onChangePage={setCurrentPage}
    />
  );
};

type Props = {
  totalPages: number;
  itemsAfterAndBefore?: number;
  align?: "left" | "center" | "right";
};

export default PaginationQueryString;
