import { DependencyList, useContext, useEffect } from "react";

import MainContainerContext, { TopBarOptions } from "./MainContainerContext";

type HookProps = TopBarOptions & {
  deps?: DependencyList;
};

// if you need this to update based on a graphql query,
// send it in as deps
export const useTopBarOptions = (props?: HookProps) => {
  const { title, icon, breadcrumbs, name, deps } = props ?? {};
  const context = useContext(MainContainerContext);

  useEffect(() => {
    context.setTopBarOptions({ title, icon, breadcrumbs, name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);

  useEffect(
    () => () => {
      context.setTopBarOptions({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
