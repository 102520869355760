import { Globe } from "@styled-icons/bootstrap/Globe";
import { CheckCircle } from "@styled-icons/boxicons-regular/CheckCircle";
import { Car } from "@styled-icons/fa-solid";
import React from "react";
import styled from "styled-components";

import EmployerIcon from "assets/images/employer.svg";
import { StyledTooltip } from "components/DataTables/VisitsTable";
import Tooltip from "components/Tooltip";
import { VisitFlags } from "components/VisitFlags";
import { visitStatusLabels } from "constants/visit";
import { VisitFlag, VisitState } from "generated/types";

interface Props {
  state?: VisitState | null;
  visitFlags?: VisitFlag[];
  isPreviouslyMissed?: boolean | null;
  isMemberConfirmed?: boolean | null;
  isVirtual?: boolean | null;
  isUberEligible?: boolean;
  isEmployerVisit?: boolean;
}

export const StatusCell: React.FunctionComponent<Props> = ({
  state,
  visitFlags,
  isPreviouslyMissed,
  isMemberConfirmed,
  isVirtual,
  isUberEligible,
  isEmployerVisit = false,
}) => {
  return (
    <StatusContainer>
      {isMemberConfirmed && state ? (
        <StyledTooltip state={state}>
          <Tooltip title="Member confirmed">
            <ConfirmedMemberIcon size={20} />
          </Tooltip>
        </StyledTooltip>
      ) : (
        <Space />
      )}
      {!!state && visitStatusLabels[state]}
      <OtherStatuses>
        {isVirtual && state && (
          <StyledTooltip state={state}>
            <Tooltip title="Virtual Visit">
              <StyledGlobe size="18" />
            </Tooltip>
          </StyledTooltip>
        )}
        {!!visitFlags && (
          <VisitFlags visitFlags={visitFlags} isPreviouslyMissed={isPreviouslyMissed} />
        )}
        {isEmployerVisit && (
          <Tooltip title="Employer Market Visit">
            <img src={EmployerIcon} width={20} alt="employer visit icon" />
          </Tooltip>
        )}
        {isUberEligible && (
          <Tooltip title="Papa would be okay with Uber for this visit">
            <Car size={20} />
          </Tooltip>
        )}
      </OtherStatuses>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledGlobe = styled(Globe)`
  color: #07d1de;
`;

const ConfirmedMemberIcon = styled(CheckCircle)`
  margin-right: 5px;
  color: #52c41a;
`;

const OtherStatuses = styled.div`
  display: flex;
  gap: 4px;
  margin-left: 4px;
`;

const Space = styled.span`
  width: 25px;
  height: 20px;
`;
