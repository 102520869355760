import { gql } from "@apollo/client";

export const PAPA_CONDUCT = gql`
  query papaConduct($filter: [PapaFilter], $sorting: PapaSorting, $pagination: PaginationInput) {
    papas(filter: $filter, sorting: $sorting, pagination: $pagination) {
      data {
        id
        fullName
        complaintPoints
        status
        mostRecentComplaint {
          data {
            id
            insertedAt
            initialPoints
            remainingPoints
          }
        }
        email
        phoneNumber
      }
      pagination {
        afterCursor
        beforeCursor
        limit
      }
    }
  }
`;
