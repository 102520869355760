import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ServiceRequestComment } from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { GET_SERVICE_DETAIL } from "pages/Papas/Details/CareConcierge/Details/gql";

import { CREATE_SERVICE_REQUEST_COMMENT, UPDATE_SERVICE_REQUEST_COMMENT } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  serviceId?: string;
  serviceRequestComment: ServiceRequestComment | null;
  onEditFinish: () => void;
}

const EditNote: React.FC<Props> = ({ serviceId, serviceRequestComment, onEditFinish }) => {
  const request = serviceRequestComment
    ? UPDATE_SERVICE_REQUEST_COMMENT
    : CREATE_SERVICE_REQUEST_COMMENT;
  const filter = serviceRequestComment ? { id: serviceRequestComment.id } : {};
  const [updateComment, { error: mutationError }] = useMutation(request, {
    refetchQueries: [{ query: GET_SERVICE_DETAIL, variables: { id: serviceId } }],
  });
  const currentUserId = useCurrentAccount()?.id;
  const handleSubmit = async ({ content }: Schema) => {
    try {
      const { data } = await updateComment({
        variables: {
          ...filter,
          input: {
            accountId: currentUserId,
            note: content,
            serviceRequestId: serviceId,
          },
        },
      });
      if (data) {
        toast.success(
          `Service request note was ${serviceRequestComment ? "edited" : "added"} with success!`
        );
        onEditFinish();
      }
      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  const initialValues: Schema = {
    content: serviceRequestComment?.note || "",
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label required>Add note</Form.Label>
                <Form.TextArea
                  name="content"
                  placeholder="Please provide any information that will help!"
                  fullWidth
                />
              </Form.Group>
            </ModalContainer>

            <ModalFooter>
              <Button variant="secondary" onClick={onEditFinish}>
                Close
              </Button>
              <Form.SubmitButton isSubmitting={isSubmitting}>Save</Form.SubmitButton>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </>
  );
};

export default EditNote;
