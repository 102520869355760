import { Spinner3 } from "@styled-icons/evil/Spinner3";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinningIcon = styled(Spinner3)`
  animation: 0.5s linear ${spin} infinite;

  & + span {
    margin-right: 0.5rem;
  }
`;
