import { OktaAuthOptions } from "@okta/okta-auth-js";
import { VERSION } from "version";

const windowEnv = window as any;

export const AppConfig = (key: string) => {
  return windowEnv[key] || process.env[key];
};

const flagEnabled = (value: boolean | string | undefined) =>
  value?.toString() === "true" || AppConfig("ADMIN_ENV") !== "production";

export const OktaConfig: OktaAuthOptions = {
  clientId: AppConfig("REACT_APP_OKTA_CLIENT_ID"),
  issuer: AppConfig("REACT_APP_OKTA_ISSUER"),
  redirectUri: AppConfig("REACT_APP_OKTA_REDIRECT_URI"),
  scopes: ["openid", "profile", "email"],
  pkce: true,
  tokenManager: {
    autoRenew: true,
    storage: "localStorage",
    syncStorage: true,
    // expireEarlySeconds: 3480, // Uncomment in dev to test token renewal every 2 minutes
  },
  storageManager: {
    token: {
      storageType: "localStorage",
      storageTypes: ["localStorage"],
    },
  },
  services: {
    autoRemove: true,
    autoRenew: true,
    syncStorage: true,
  },
  devMode: AppConfig("REACT_APP_OKTA_DEV_MODE"),
};

const config = {
  version: VERSION,
  stripeApiKey: AppConfig("REACT_APP_STRIPE_API_KEY") ?? "",
  featureFlag: {
    visitTemplate: flagEnabled(AppConfig("REACT_APP_FEATURE_FLAG_VISIT_TEMPLATE")),
    eligibleMembers: flagEnabled(AppConfig("REACT_APP_FEATURE_FLAG_ELIGIBLE_MEMBERS")),
    palProfileReviews: flagEnabled(AppConfig("REACT_APP_FEATURE_FLAG_PAL_PROFILE_REVIEWS")),
    businessPolicy: flagEnabled(AppConfig("REACT_APP_FEATURE_FLAG_BUSINESS_POLICY")),
    papaCreditCard: flagEnabled(AppConfig("REACT_APP_FEATURE_PAPA_CREDIT_CARD")),
    papasTrial: flagEnabled(AppConfig("REACT_APP_FEATURE_PAPAS_TRIAL")),
    scheduleRecommendation: flagEnabled(AppConfig("REACT_APP_FEATURE_SCHEDULE_RECOMMENDATION")),
    accountant: flagEnabled(AppConfig("REACT_APP_FEATURE_ACCOUNTANT")),
    palType: flagEnabled(AppConfig("REACT_APP_FEATURE_PAL_TYPE")),
    timeRecommendation: flagEnabled(AppConfig("REACT_APP_FEATURE_TIME_RECOMMENDATION")),
    /**
     * This env are not using flagEnabled, because it's only need to use for testing or review purposes
     */
    disableOkta:
      typeof AppConfig("REACT_APP_DISABLE_OKTA") === "boolean"
        ? AppConfig("REACT_APP_DISABLE_OKTA")
        : AppConfig("REACT_APP_DISABLE_OKTA") === "true",
    useMSW: AppConfig("REACT_APP_USE_MWS") === "true",
  },
};

export default config;
