import { useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { ReactElement, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { ModalFooter } from "components/Modal/Modal.styles";

import { CREATE_CREDIT_CARD } from "./gql";

interface Props {
  accountId: string;
  closeModal: () => void;
}

const AddCreditCard = ({ accountId, closeModal }: Props): ReactElement => {
  const [createCreditCard] = useMutation(CREATE_CREDIT_CARD);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) return;

    setLoading(true);
    try {
      const { error, token: { id: tokenId, card } = {} } = await stripe.createToken(cardElement);

      if (error || !tokenId || !card) {
        // @ts-ignore
        toast.error((error as Error) ?? "Error on to create stripe token");
        return;
      }

      const { data } = await createCreditCard({
        variables: {
          input: {
            accountId,
            brand: card.brand,
            default: true,
            expMonth: card.exp_month,
            expYear: card.exp_year,
            last4: card.last4,
            nickname: `${card.brand} - ${card.last4}`,
            stripeCardId: card.id,
            stripeToken: tokenId,
          },
        },
      });

      if (data?.createCreditCard) {
        toast.success("Your credit card was saved with success! ");
        closeModal();
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    setLoading(false);
  };

  return (
    <ModalContent>
      <CardContainer>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "1rem",
                color: "#424770",
              },
            },
          }}
        />
      </CardContainer>

      <ModalFooter>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" disabled={loading} onClick={handleSubmit}>
          {loading ? (
            <>
              <SpinningIcon size={18} />
              <span>Saving...</span>
            </>
          ) : (
            "Save"
          )}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const ModalContent = styled.div`
  width: 100%;
`;

const CardContainer = styled.div`
  margin: 0 2.625rem;
  border: 1px ${({ theme }) => theme.borderColor} solid;
  background-color: white;
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  height: 2.35rem;
`;

export default AddCreditCard;
