import { Lock, LockOpen } from "@styled-icons/boxicons-solid";
import React, { HTMLAttributes } from "react";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import Conditional from "components/Conditional";
import { Loader } from "components/Loader";
import ConfirmModal from "components/Modal/ConfirmModal";
import { SwitchButtonInput } from "components/SwitchButton";
import { Papa, useSetVirtualVisitsOnlyMutation } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";

interface Props {
  papa?: Papa | null;
  virtualVisitsOnly?: boolean;
}

const Switch = ({ papa }: Props) => {
  const { isOpen: confirmModalOpen, toggle: toggleConfirmModal } = useModalToggle();
  const [setVirtualVisitsOnly, { loading }] = useSetVirtualVisitsOnlyMutation();

  const { isSuperAdmin, isGrievancesAppealsAdmin } = useCurrentAccountRole();

  const papaId = papa?.id || "";
  const virtualVisitsOnly = !!papa?.virtualVisitsOnly;
  const healthPlanCurrentBusinessPolicy = papa?.currentBusinessPolicy?.data;

  const caregiver = papa?.caregiver?.data;
  const employerCurrentBusinessPolicy = caregiver?.business?.data?.currentBusinessPolicy?.data;

  const businessPolicy = caregiver
    ? employerCurrentBusinessPolicy
    : healthPlanCurrentBusinessPolicy;
  const allowVirtualVisits =
    businessPolicy === undefined || businessPolicy === null
      ? true
      : !!businessPolicy?.allowVirtualVisits;

  const isAuthorized =
    (isSuperAdmin || isGrievancesAppealsAdmin) && (virtualVisitsOnly || allowVirtualVisits);

  const handleToggle = async () => {
    try {
      const { data } = await setVirtualVisitsOnly({
        variables: { id: papaId, virtualVisitsOnly: !virtualVisitsOnly },
      });

      if (data?.setVirtualVisitsOnly?.data?.id) {
        toast.success(
          `Member is now ${
            virtualVisitsOnly
              ? "allowed to schedule in-person visits"
              : "restricted to scheduling virtual visits only"
          }`
        );
      } else {
        toast.error("Failed updating visit type for member!");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const switchButtonLabel = virtualVisitsOnly ? "Virtual Visits Only" : "IPVs or VVs";
  const confirmationMsg = virtualVisitsOnly
    ? "Are you sure you want to allow this member to schedule in-person visits?"
    : "Are you sure you want to restrict this member to scheduling virtual visits only?";

  return (
    <Container>
      <Loader isLoading={loading}>
        <>
          <Conditional show={!allowVirtualVisits}>
            <VirtualVisitText>This business does not allow virtual visits.</VirtualVisitText>
          </Conditional>
          <SwitchButton
            label={switchButtonLabel}
            checked={virtualVisitsOnly}
            disabled={!isAuthorized || loading}
            onClick={toggleConfirmModal}
          />
        </>
      </Loader>

      <ConfirmModal
        isOpen={confirmModalOpen}
        onCancel={toggleConfirmModal}
        onConfirm={() => {
          handleToggle();
          toggleConfirmModal();
        }}
        header="Please Confirm"
      >
        {confirmationMsg}
      </ConfirmModal>
    </Container>
  );
};

type SwitchButtonProps = {
  label: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const SwitchButton = ({ label, checked, disabled, ...inputProps }: SwitchButtonProps) => {
  return (
    <>
      <Label disabled={disabled}>
        <SwitchButtonInput readOnly {...inputProps} checked={checked} disabled={disabled} />
        {checked ? <Lock size={18} /> : <LockOpen size={18} />}
        <Text>{label}</Text>
      </Label>
    </>
  );
};

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 10px;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.text.secondary};
    `}

  & > svg {
    margin-left: 10px;
  }
`;

const Text = styled.span`
  margin-left: 10px;
`;

const VirtualVisitText = styled.span`
  color: primary;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default Switch;
