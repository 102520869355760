import React from "react";
import styled from "styled-components";

import Checkbox from "components/Checkbox";
import Radio from "components/Radio";

import Fieldset from "./Fieldset";
import { FileInput } from "./FileInput";
import FormFeedback from "./FormFeedback";
import FormGroup from "./FormGroup";
import FormGroupInline from "./FormGroupInline";
import FormHeading from "./FormHeading";
import FormInline from "./FormInline";
import FormInput from "./FormInput";
import FormInputCurrency from "./FormInputCurrency";
import FormInputWithLabel from "./FormInputWithLabel";
import FormLabel from "./FormLabel";
import FormPanel from "./FormPanel";
import { SubmitButton } from "./FormSubmitButton";
import FormSwitch from "./FormSwitch";
import FormTextArea from "./FormTextArea";

type Props = {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
};

const Form = ({ children, ...otherProps }: Props): JSX.Element => {
  return <form {...otherProps}>{children}</form>;
};

const FormRow = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  justify-content: space-between;
`;

const FormCol = styled.div`
  padding: 0 0.5rem;
  width: 50%;
`;

const FormCol4 = styled.div`
  padding: 0 0.5rem;
  width: 25%;
`;

Form.Group = FormGroup;
Form.Label = FormLabel;
Form.Input = FormInput;
Form.InputWithLabel = FormInputWithLabel;
Form.FileInput = FileInput;
Form.Check = Checkbox;
Form.Radio = Radio;
Form.Feedback = FormFeedback;
Form.TextArea = FormTextArea;
Form.Heading = FormHeading;
Form.Panel = FormPanel;
Form.Inline = FormInline;
Form.GroupInline = FormGroupInline;
Form.InputCurrency = FormInputCurrency;
Form.SubmitButton = SubmitButton;
Form.Fieldset = Fieldset;
Form.Switch = FormSwitch;
Form.Row = FormRow;
Form.Col = FormCol;
Form.Col4 = FormCol4;

export default Form;
