import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { boolean, object, string } from "yup";

import Button from "components/Button";
import { confirm } from "components/Confirm";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import Text from "components/Text";
import { usePageInfo } from "hooks/usePageInfo";
import { GET_OBJECTIVES } from "pages/Objectives/List/gql";
import { Objective, ObjectiveTasksData } from "types/objective";
import { objectiveFilterMaker } from "utils/objectiveFilters";
import { mapSelectValuesToOptions } from "utils/select";

import { CREATE_OBJECTIVE, GET_OBJECTIVE_TASKS, UPDATE_OBJECTIVE } from "./gql";

interface FormValues {
  taskId: string;
  description: string;
  isActive: boolean;
}

interface Props {
  objective?: Objective | null;
  onEditFinish: () => void;
}

interface Data {
  tasks: ObjectiveTasksData;
}

export const ObjectiveForm: React.FC<Props> = ({ onEditFinish, objective }) => {
  const isEditing = Boolean(objective);
  const { beforeCursor, afterCursor, limit, tasks } = usePageInfo();
  const variables = {
    pagination: { beforeCursor, afterCursor, limit },
    filter: {
      deletedAt: { eq: null },
      ...objectiveFilterMaker({ tasks }),
    },
  };

  const { data, loading: tasksLoading } = useQuery<Data>(GET_OBJECTIVE_TASKS, {
    variables: { pagination: { limit: 1000 } },
  });
  const [mutateObjective] = useMutation(isEditing ? UPDATE_OBJECTIVE : CREATE_OBJECTIVE, {
    refetchQueries: [
      {
        query: GET_OBJECTIVES,
        variables: isEditing ? {} : variables,
      },
    ],
  });

  const tasksOptions =
    data?.tasks.data.map(({ name, id }) => ({
      label: name,
      value: id,
    })) || [];

  const handleSubmit = async (input: FormValues) => {
    if (
      isEditing &&
      objective?.isActive &&
      !input.isActive &&
      !(await confirm("This objective will no longer be available for future visits.", {
        header: "Are you sure?",
        confirmBtnText: "Yes",
      }))
    ) {
      return;
    } else if (
      isEditing &&
      !objective?.isActive &&
      input.isActive &&
      !(await confirm("This objective will now be available for future visits.", {
        header: "Are you sure?",
        confirmBtnText: "Yes",
      }))
    ) {
      return;
    }

    try {
      const { data } = await mutateObjective({
        variables: {
          id: isEditing ? objective!.id : null,
          input,
        },
      });
      const resultId = isEditing
        ? data?.updateVisitObjectiveTemplate?.data?.id
        : data?.createVisitObjectiveTemplate?.data?.id;

      if (resultId) {
        toast.success(`Objective was successfully ${isEditing ? "edited" : "added"}!`);
        onEditFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues: FormValues = {
    taskId: objective?.task?.data?.id || "",
    description: objective?.description || "",
    isActive: objective?.isActive != null ? objective.isActive : true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object().shape({
        taskId: string().required("Task is required"),
        description: string().required("Description is required"),
        isActive: boolean(),
      })}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label id="objective-task">Task</Form.Label>

              {tasksLoading ? (
                <SpinningIcon size={18} />
              ) : (
                <Select
                  data-testid="objective-task"
                  aria-labelledby="objective-task"
                  name="taskId"
                  defaultValue={mapSelectValuesToOptions(initialValues.taskId, tasksOptions)}
                  options={tasksOptions}
                  isDisabled={isEditing}
                />
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="objective-description">Description</Form.Label>
              <Form.TextArea id="objective-description" name="description" disabled={isEditing} />
            </Form.Group>

            {isEditing && (
              <Form.Group>
                <Form.Label htmlFor="objective-isactive">
                  Active
                  <Text size="small">Make this objective available when scheduling a visit.</Text>
                </Form.Label>
                <Form.Switch
                  id="objective-isactive"
                  name="isActive"
                  label={values.isActive ? "Yes" : "No"}
                />
              </Form.Group>
            )}
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={onEditFinish}>
              Close
            </Button>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};
