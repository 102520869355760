import styled from "styled-components";

import { withProperties } from "utils/type-helper";

const Grid = styled("div")`
  display: flex;
  margin: 0 -0.938rem;
`;

type ColumnProps = {
  size?: number;
};

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
  margin: 0 0.938rem;
`;

export default withProperties(Grid, { Column });
