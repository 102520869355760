import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { FunctionComponent, useState } from "react";
import Helmet from "react-helmet";

import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import SmartTable from "components/SmartTable";
import { EligibleEmployee, Pagination } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import EnrollEmployeeModal from "modals/EligibleEmployees/EnrollEmployeeModal";

import { GET_ELIGIBLE_EMPLOYEE_LIST } from "./gql";
import { useEligibleEmployeesListColumns } from "./useEligibleEmployeesListColumns";
import { useEligibleEmployeesSearch } from "./useEligibleEmployeesSearch";

interface Data {
  eligibleEmployees: {
    data: EligibleEmployee[];
    pagination: Pagination;
  };
}

const EligibleEmployees: FunctionComponent = () => {
  useTopBarOptions({ title: "Eligible Employees", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { isOpen: isEnrollEmployeeOpen, toggle: toggleEnrollEmployeeModal } = useModalToggle();
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { filter, pills, setPills } = useSearch();
  const [currentEnrollEmployeeId, setCurrentEmployeeId] = useState<string>("");

  const enrollEmployee = (id: string) => {
    setCurrentEmployeeId(id);
    toggleEnrollEmployeeModal();
  };

  const columns = useEligibleEmployeesListColumns(enrollEmployee);

  const { data, loading, error } = useQuery<Data>(GET_ELIGIBLE_EMPLOYEE_LIST, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      ...(filter ? { filter } : {}),
    },
  });

  const { loadOptions, searchPillNames } = useEligibleEmployeesSearch(
    data?.eligibleEmployees.data!
  );

  const eligibleEmployees = data?.eligibleEmployees;

  return (
    <div>
      <Helmet>
        <title>Eligible Employees - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between">
        <FlexRow>
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder="Search by Name, Client"
          />
        </FlexRow>
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />

      <SmartTable<EligibleEmployee>
        keyField="id"
        data={eligibleEmployees?.data!}
        pagination={eligibleEmployees?.pagination!}
        loading={loading}
        columns={columns}
        error={error}
        cursorPagination
      />

      {currentEnrollEmployeeId && (
        <EnrollEmployeeModal
          enrollEmployeeId={currentEnrollEmployeeId}
          isOpen={isEnrollEmployeeOpen}
          toggle={toggleEnrollEmployeeModal}
        />
      )}
    </div>
  );
};

export default EligibleEmployees;
