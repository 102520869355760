import * as yup from "yup";

import { VisitFlagReason } from "generated/types";

export interface Schema {
  reason: VisitFlagReason;
  description?: string;
}

export const schema = yup.object().shape({
  reason: yup.string().required("Required"),
  description: yup.string(),
});

export const reasonOptions = [
  {
    label: "Escalation Request from Client Success",
    value: VisitFlagReason.EscalationClientSuccess,
  },
  {
    label: "Grievances and Appeals Escalation",
    value: VisitFlagReason.EscalationGrievancesAppeals,
  },
];
