export interface Option<T> {
  value: T;
  label: string;
}

export const mapSelectValuesToOptions = <T>(
  selectedValues: T | T[] | null,
  values: Option<T>[]
) => {
  if (Array.isArray(selectedValues)) {
    return values.filter(({ value }) => {
      return selectedValues.some((selectedValue) => selectedValue === value);
    });
  }

  return values.filter(({ value }) => value === selectedValues)[0];
};

export const getOptionByValue = <T>(options: Option<T>[], value: T) => {
  return options.find((option) => option.value === value) as Option<T>;
};
