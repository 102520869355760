import { useQuery } from "@apollo/client";
import { CaretDown } from "@styled-icons/bootstrap/CaretDown";
import { CaretUp } from "@styled-icons/bootstrap/CaretUp";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams, useRouteMatch } from "react-router-dom";

import { FlexRow } from "components/Flex";
import Pagination from "components/Pagination";
import SmartTable from "components/SmartTable";
import { useRowsSelectList } from "components/SmartTable/useRowsSelectList";
import { ServiceRequest, ServiceRequestListResult, ServiceRequestStatus } from "generated/types";

import { Arrow, StyledHeading } from ".";
import { GET_PAPA_REQUESTS } from "./gql";
import { useServicesTableColumns } from "./useServicesTableColumns";

interface Data {
  serviceRequests: ServiceRequestListResult;
}
type Props = {
  currentPage: number;
  currentLimit: number;
  setPage: (page: number) => void;
};
const ClosedRequests = ({ currentPage, currentLimit, setPage }: Props) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const { rowsSelected, onRowSelect, onAllRowsSelect } = useRowsSelectList();
  const careConciergeMatch = useRouteMatch("/papas/:id/care-concierge");
  const underCareConciergeTag = careConciergeMatch !== null;
  const { columns } = useServicesTableColumns({
    statusType: "CLOSED",
  });
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useQuery<Data>(GET_PAPA_REQUESTS, {
    variables: {
      filter: {
        papaId: {
          eq: id,
        },
        status: {
          in: [
            ServiceRequestStatus.ClosedEscalatedToClient,
            ServiceRequestStatus.ClosedNotANeed,
            ServiceRequestStatus.Closed,
            ServiceRequestStatus.Resolved,
          ],
        },
      },
      pagination: { page: currentPage, limit: currentLimit },
    },
  });
  const totalPages = data?.serviceRequests?.pagination?.totalPages ?? 0;
  const toggle = () => {
    isCollapsed ? setCollapsed(false) : setCollapsed(true);
  };
  useEffect(() => {
    const handleRefetch = async () => {
      await refetch();
    };
    if (underCareConciergeTag) {
      handleRefetch();
    }
  }, [underCareConciergeTag, currentPage, refetch]);
  const servicerequests = data?.serviceRequests;

  return (
    <div>
      <Helmet>
        <title>Service Requests - Papa Admin</title>
      </Helmet>
      <StyledHeading onClick={toggle}>
        <FlexRow justifyContent="space-between">
          <div>
            Resolved Service Requests ({data?.serviceRequests?.pagination?.totalCount ?? 0})
          </div>
          <Arrow>{isCollapsed ? <CaretUp size={18} /> : <CaretDown size={18} />}</Arrow>
        </FlexRow>
      </StyledHeading>
      {!isCollapsed && (
        <SmartTable<ServiceRequest>
          keyField="id"
          data={servicerequests?.data}
          loading={loading}
          columns={columns}
          error={error}
          onRowSelect={onRowSelect}
          onAllRowsSelect={onAllRowsSelect}
          rowsSelected={rowsSelected}
        />
      )}
      {!isCollapsed && totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          itemsAfterAndBefore={3}
          onChangePage={setPage}
        />
      )}
    </div>
  );
};

export default ClosedRequests;
