import React, { FunctionComponent } from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { VirtualBaseRate as Entity } from "pages/Accounting/accountingMocked";

import { EditVirtualBaseRateForm } from "./EditVirtualBaseRateForm";

interface Props {
  onClose: () => void;
  baseRate: Entity;
  isOpen: boolean;
}

const EditVirtualBaseRateModal: FunctionComponent<Props> = ({ isOpen, onClose, baseRate }) => {
  if (!baseRate.id) return null;

  return (
    <ModalLayout toggle={onClose} title="Edit Virtual Visit Base Rate" isOpen={isOpen}>
      <EditVirtualBaseRateForm baseRateId={baseRate.id} onClose={onClose} />
    </ModalLayout>
  );
};

export default EditVirtualBaseRateModal;
