import { DocumentNode, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { Account } from "generated/types";
import { isUuid } from "utils/isUuid";

interface Params {
  data: Account[];
  query: DocumentNode;
  isAdmin?: boolean;
}

export const useAccountsSearch = ({ data, query, isAdmin }: Params) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    const filterParams = {
      deletedAt: { eq: null },
    };
    Object.assign(
      filterParams,
      isAdmin
        ? { permissions: { admin: true } }
        : { type: { eq: "REGULAR" }, permissions: { admin: false } }
    );

    const filter = isUuid(value)
      ? [
          {
            ...filterParams,
            id: { eq: value },
          },
        ]
      : [
          {
            ...filterParams,
            fullName: { cont: value },
          },
        ];

    try {
      const { data } = await client.query({
        query,
        variables: {
          filter,
          pagination: { page: 1, limit: 10 },
        },
      });
      if (data?.accounts?.data) {
        return data.accounts.data.map(({ id, fullName, phoneNumber }: Account) => {
          return {
            value: id,
            label: fullName,
            phone: phoneNumber,
            name: fullName,
          };
        });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const searchPillNames = data?.reduce(
    (pills, { id, fullName }) => ({
      ...pills,
      [id!]: fullName,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
