import { gql } from "@apollo/client";

export const CHANGE_PAPA_STATUS = gql`
  mutation changePapaStatus($id: ID!, $input: PapaStatusInput!) {
    changePapaStatus(id: $id, input: $input) {
      data {
        id
        status
        papaSuspension {
          data {
            id
            suspendedUntil
          }
        }
      }
    }
  }
`;
