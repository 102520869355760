import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { CommentType, commentOptions } from "constants/papaComment";
import { PalSingleResult } from "generated/types";
import { CREATE_PAL_NOTE, CreatePalNoteInput, GET_PAL_NOTES } from "pages/Pals/gql";
import { mapSelectValuesToOptions } from "utils/select";

import { Schema, schema } from "./AddCommentForm.schema";

interface Props {
  onAddFinish: () => void;
  id: string;
}

const AddCommentForm: React.FC<Props> = ({ id, onAddFinish }) => {
  const [createPalNote] = useMutation<{ createPalNote: PalSingleResult }, CreatePalNoteInput>(
    CREATE_PAL_NOTE,
    {
      refetchQueries: [{ query: GET_PAL_NOTES, variables: { id } }],
    }
  );

  const handleSubmit = async ({ palId, type, content }: Schema) => {
    const input = {
      palId,
      content: type ? `${type}: ${content}` : content,
    };
    try {
      const { data } = await createPalNote({ variables: { input } });

      if (data?.createPalNote?.data?.id) {
        toast.success("Pal comment was added with success!");
        onAddFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues: Schema = {
    palId: id,
    type: null,
    content: "",
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label required>Select comment type</Form.Label>
              <Select
                name="type"
                defaultValue={mapSelectValuesToOptions<CommentType>(
                  initialValues.type,
                  commentOptions
                )}
                options={commentOptions}
                isSearchable={false}
                placeholder="Select type"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label required>Type in your comment here</Form.Label>
              <Form.TextArea name="content" fullWidth />
            </Form.Group>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={onAddFinish}>
              Close
            </Button>
            <Button disabled={isSubmitting} type="submit" variant="primary">
              Save
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};

export default AddCommentForm;
