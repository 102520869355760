import { gql } from "@apollo/client";

export const GET_PAL_OVERVIEW_EDIT_FIELDS = gql`
  query PalOverviewEditFields($id: ID!) {
    pal(id: $id) {
      data {
        id
        fullName
        phoneNumber
        gender
        genderText
        email
        palType {
          data {
            id
            code
            description
          }
        }
        dateOfBirth
        school
        fieldOfStudy
        languages {
          data
        }
        bio
        covidVaccinated
        drugTestClean
        account {
          data {
            id
            testAccount
            homeLocation {
              data {
                id
                address
              }
            }
            permissions {
              admin
            }
            roles {
              data {
                id
                name
              }
            }
          }
        }
        optOutVisitSuccessCall {
          data {
            id
            indefinitely
            optOut
            optOutUntil
          }
        }
      }
    }
  }
`;

export const UPDATE_PAL = gql`
  mutation updatePal($id: ID!, $input: PalInput!) {
    updatePal(id: $id, input: $input) {
      data {
        id
        fullName
        gender
        fieldOfStudy
        dateOfBirth
        school
        covidVaccinated
        languages {
          data
        }
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation createPalOverviewLocation($input: LocationInput!) {
    createLocation(input: $input) {
      data {
        id
        address
        city
        countryIso
        lat
        lng
        state
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updatePalsAccount($id: ID!, $input: AccountInput!) {
    updateAccount(id: $id, input: $input) {
      data {
        id
        email
        phoneNumber
      }
    }
  }
`;

export const ASSIGN_ROLES = gql`
  mutation assignRoles($accountId: ID!, $roleId: [ID!]) {
    assignRoles(accountId: $accountId, roleId: $roleId) {
      data {
        id
      }
    }
  }
`;
