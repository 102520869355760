import React, { useState } from "react";

import Form from "components/Form";
import Select from "components/Select";
import { genderOptions } from "constants/gender";
import { Gender } from "generated/types";
import { mapSelectValuesToOptions } from "utils/select";

/**
 * Renders a form input for selecting a gender.
 * If the selected gender is "Diverse", an additional input field is rendered for the user to specify their gender.
 *
 * @param initialValue - The initial value for the gender input. Should be one of the values defined in the `Gender` enum.
 * @returns The rendered form input for selecting a gender.
 */
export const GenderInput = ({ initialValue }: { initialValue?: Gender | null }) => {
  const [gender, setGender] = useState<Gender | null>(initialValue || null);

  const handleGenderChange = (selectedOption: any) => {
    setGender(selectedOption);
  };

  const value = initialValue ? mapSelectValuesToOptions<Gender>(initialValue, genderOptions) : null;

  return (
    <>
      <Form.Group>
        <Form.Label>Gender</Form.Label>
        <Select
          aria-label="gender"
          defaultValue={value}
          isSearchable={false}
          name="gender"
          onChange={handleGenderChange}
          options={genderOptions}
          placeholder="Select gender"
        />
      </Form.Group>

      {gender === Gender.Diverse && (
        <Form.Group>
          <Form.Label>Gender - Member Specified</Form.Label>
          <Form.Input aria-label="genderText" name="genderText" />
        </Form.Group>
      )}
    </>
  );
};
