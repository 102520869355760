import { useQuery } from "@apollo/client";
import React from "react";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import EditButton from "components/EditButton";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { vehicleTypeOptions } from "constants/vehicle";
import { PalSingleResult } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { mapValueToLabel } from "utils/helpers/formatters";

import { GET_PAL_VEHICLE } from "../gql";
import { VehicleEditForm } from "./VehicleEditForm/VehicleEditForm";

type Data = {
  pal: PalSingleResult;
};

const Vehicle = () => {
  const { id } = useParams<{ id: string }>();
  const { isOpen: showEditModal, toggle: toggleEditModal } = useModalToggle();
  const { data, loading, error } = useQuery<Data>(GET_PAL_VEHICLE, {
    variables: { id },
  });

  const { vehicle, vehicleColor, vehicleModel, vehicleYear } = data?.pal.data ?? {};

  return (
    <>
      <QueryErrors error={error} />

      <Panel>
        <Helmet>
          <title>Pal Vehicle - Papa Admin</title>
        </Helmet>
        <Heading color="primary">Vehicle</Heading>

        {loading && (
          <ContentLoader
            speed={2}
            width="60%"
            height={50}
            backgroundColor="#f3f3f3"
            foregroundColor="#e6e6e6"
          >
            <rect x="0" y="0" rx="3" ry="3" width="48%" height="10" />
            <rect x="52%" y="0" rx="3" ry="3" width="48%" height="10" />
            <rect x="0" y="20" rx="3" ry="3" width="35%" height="10" />
            <rect x="0" y="40" rx="3" ry="3" width="35%" height="10" />
          </ContentLoader>
        )}

        <Container>
          <Item>
            <Text bold>
              <span>{mapValueToLabel(vehicleTypeOptions, vehicle)}</span>{" "}
              <span>{vehicleModel}</span>
            </Text>
            <Text color="muted">{vehicleYear}</Text>
            <Text color="muted">{vehicleColor}</Text>
          </Item>

          <EditButton onClick={toggleEditModal}>Edit</EditButton>
        </Container>

        <ModalLayout toggle={toggleEditModal} title="Vehicle" isOpen={showEditModal}>
          <VehicleEditForm id={id} onEditFinish={toggleEditModal} />
        </ModalLayout>
      </Panel>
    </>
  );
};

const Panel = styled("div")`
  max-width: 50%;
  background: ${({ theme }) => theme.background.panelContent};
  padding: 1.75rem 2.188rem;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Item = styled("div")`
  margin-bottom: 1.25rem;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1200px) {
    flex-direction: column;
    grid-gap: 5px;
  }
`;

export default Vehicle;
