import React from "react";
import styled from "styled-components";

import Text from "components/Text";
import { getDuration } from "utils/date";

interface Props {
  duration?: number | null;
}

export const VisitDuration: React.FC<Props> = ({ duration }) => (
  <Container>
    <VisitTimeContainer>
      <VisitTime>
        <Text bold>{getDuration(duration ?? 0, "minutes")}</Text>
      </VisitTime>
      <Text>Time</Text>
    </VisitTimeContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const VisitTimeContainer = styled.div`
  margin-bottom: 46px;
  text-align: center;
`;

const VisitTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  padding: 24px 36px;
  margin: 26px 0 5px;
  font-size: 2.5rem;
`;
