import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Select from "components/Select";
import ServiceRequestClosedReasonSelect from "components/ServiceRequest/ClosedReasonSelect";
import CareConciergeNeedSelect from "components/ServiceRequest/NeedSelect";
import ServiceRequestStatusSelect from "components/ServiceRequest/StatusSelect";
import CareConciergeTypeSelect from "components/ServiceRequest/TypeSelect";
import { priorityOptions } from "constants/service-requests";
import { ServiceRequestStatus } from "generated/types";

import { BULK_EDIT_SERVICE_REQUESTS } from "./gql";
import { Schema, formSchema } from "./schema";

interface Props {
  serviceRequestIds: string[];
  isOpen: boolean;
  toggle: () => void;
}

const Update = ({ serviceRequestIds, isOpen, toggle }: Props) => {
  const [otherSelected, setOther] = useState(false);
  const [schema, setSchema] = useState(formSchema(false));
  const [bulkEditServiceRequests] = useMutation(BULK_EDIT_SERVICE_REQUESTS);

  const initialValues: Schema = {
    typeId: "",
    needId: "",
    needText: "",
    status: null,
    priority: null,
  };

  const handleSubmit = async ({
    typeId,
    needId,
    needText,
    status,
    priority,
    closedReason,
  }: Schema) => {
    try {
      const serviceRequestEntries = serviceRequestIds.map((id) => ({
        id,
        typeId: typeId ? typeId : undefined,
        needId: needId ? needId : undefined,
        needText: needText ? needText : undefined,
        status: status ? status : undefined,
        priority: priority ? priority : undefined,
        closedReason,
      }));

      const { data } = await bulkEditServiceRequests({
        variables: {
          input: { serviceRequestEntries },
        },
      });
      if (data) {
        toast.success("Update service requests successful!");
        toggle();
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const selectOther = (value: boolean) => {
    setOther(value);
  };
  useEffect(() => {
    setSchema(formSchema(otherSelected));
  }, [otherSelected]);

  return (
    <ModalLayout toggle={toggle} title="Update Service Requests" isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ values }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <CareConciergeTypeSelect
                  name="typeId"
                  aria-label="typeId"
                  placeholder="Select Type"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Need</Form.Label>
                <CareConciergeNeedSelect
                  name="needId"
                  aria-label="needId"
                  selectOther={selectOther}
                  typeId={values.typeId}
                  placeholder="Select Need"
                />
              </Form.Group>
              {otherSelected && (
                <Form.Group>
                  <Form.Label required>Other</Form.Label>
                  <Form.Input name="needText" aria-label="needText" />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Select Status</Form.Label>
                <ServiceRequestStatusSelect />
              </Form.Group>

              {values.status === ServiceRequestStatus.Closed && (
                <Form.Group>
                  <Form.Label>Select reason</Form.Label>
                  <ServiceRequestClosedReasonSelect />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Priority</Form.Label>
                <Select
                  name="priority"
                  isSearchable
                  placeholder="Select priority"
                  options={priorityOptions}
                />
              </Form.Group>
            </ModalContainer>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Form.SubmitButton>Save</Form.SubmitButton>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};

export default Update;
