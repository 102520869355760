import capitalize from "lodash/capitalize";
import React from "react";
import styled from "styled-components";

import EligibleMemberActionMenu from "components/ActionsMenu/EligibleMembersActionMenu";
import EditButton from "components/EditButton";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { FeatureFlags } from "constants/featureFlags";
import { EligibleLife } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { formatDateTime } from "utils/helpers/formatters";

export const useEligibleMembersListColumns = (enrollMember: (memberId: string) => void) => {
  const clientCopyUpdateEnabled = useIsFeatureEnabled(FeatureFlags.ClientCopyUpdate);
  const clientOrClientProgram = clientCopyUpdateEnabled ? "Client Program" : "Client";

  const columns: Column<EligibleLife>[] = [
    {
      header: { title: "Member ID" },
      dataField: "memberId",
    },
    {
      header: { title: "Full name" },
      dataField: "fullName",
      formatter({ firstName, lastName, id }) {
        return `${capitalize(firstName ?? "")} ${capitalize(lastName ?? "")}`;
      },
    },
    {
      header: { title: `${clientOrClientProgram} Name` },
      dataField: "clientName",
    },
    {
      header: { title: "DOB" },
      dataField: "dob",
      formatter({ dob }) {
        return formatDateTime(dob, { format: "L" });
      },
    },
    {
      header: { title: "Phone Number" },
      dataField: "phone1",
    },
    {
      header: { title: "Location" },
      dataField: "addressLn1",
      formatter({ addressLn1, addressLn2, zip }) {
        return (
          <>
            <Text>{addressLn1}</Text>
            {addressLn2 && <Text>{addressLn2}</Text>}
            {zip && (
              <Text bold inline>
                {zip}
              </Text>
            )}
          </>
        );
      },
    },
    {
      header: { title: "Status" },
      dataField: "status",
      formatter({ status }) {
        return capitalize(status ?? "");
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
        noWrap: true,
      },
      formatter({ id, status }) {
        if (status === "pending") {
          return (
            <StyledEditButton onClick={() => enrollMember(id as string)}>
              Enroll member
            </StyledEditButton>
          );
        }

        return "";
      },
    },
  ];

  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);

  if (supportTicketingEnabled) {
    columns.push({
      header: { title: " " },
      dataField: "actionMenu",
      cellProps: { fixed: true, align: "center" },
      formatter(eligibleMember) {
        return (
          eligibleMember && <EligibleMemberActionMenu eligibleMember={eligibleMember} isListMenu />
        );
      },
    });
  }

  return columns;
};

const StyledEditButton = styled(EditButton)`
  margin: 0 0.938rem;
`;
