import { Placement } from "@popperjs/core";
import { ChevronUp } from "@styled-icons/boxicons-regular/ChevronUp";
import React, { cloneElement, useRef, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";

import Flex from "components/Flex";
import Text from "components/Text";

type Props = {
  content: React.ReactNode;
  placement: Placement;
  isPrimary: boolean;
  children: JSX.Element;
  childButtonOnClick?: () => void;
};

const Tooltip = ({ content, placement, isPrimary, children, childButtonOnClick }: Props) => {
  const targetRef = useRef(null);
  const popperRef = useRef(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const [isVisible, setVisibility] = useState(false);

  const { styles, attributes } = usePopper(targetRef.current, popperRef.current, {
    placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowRef,
          padding: 5,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const triggerProps = {
    ref: targetRef,
    onClick: () => {
      setVisibility(!isVisible);
      if (childButtonOnClick) childButtonOnClick();
    },
  };

  if (!content) return children;

  return (
    <>
      {cloneElement(children, triggerProps)}
      {isVisible ? (
        <PopperContainer
          ref={popperRef}
          isPrimary={isPrimary}
          style={styles.popper}
          {...attributes.popper}
        >
          <Arrow ref={setArrowRef} style={styles.arrow} />
          <PopperContent>
            {content}
            <Flex justifyContent="center">
              <ChevronUp
                size={20}
                onClick={() => setVisibility(false)}
                aria-label="close-popover"
              />
            </Flex>
          </PopperContent>
        </PopperContainer>
      ) : null}
    </>
  );
};

Tooltip.defaultProps = {
  placement: "top",
  isPrimary: false,
};

const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    border: 1px solid #ced0da;
  }

  &[data-hide] {
    visibility: hidden;
  }
`;

const PopperContent = styled(Text)`
  font-size: 0.875rem;
`;

type PopperContainerProps = {
  isPrimary?: boolean;
};

const PopperContainer = styled.div<PopperContainerProps>`
  border-radius: 7px;
  background-color: white;
  padding: 0.438rem 1.25rem;
  border: 1px solid #ced0da;
  background-color: ${({ isPrimary, theme }) => (isPrimary ? theme.variants.primary : "white")};
  border-color: ${({ isPrimary, theme }) => (isPrimary ? theme.variants.primary : "#ced0da")};
  max-width: 380px;
  z-index: 1;

  ${PopperContent} {
    color: ${({ isPrimary, theme }) => (isPrimary ? "white" : "inherit")};
  }

  ${Arrow} {
    :after {
      background-color: ${({ isPrimary, theme }) => (isPrimary ? theme.variants.primary : "white")};
      border-color: ${({ isPrimary, theme }) => (isPrimary ? theme.variants.primary : "#ced0da")};
    }
  }

  &[data-popper-placement^="bottom"] > ${Arrow} {
    bottom: 100%;
    :after {
      top: 0.188rem;
      border-bottom-width: 0;
      border-right-width: 0;
    }
  }
  &[data-popper-placement^="top"] > ${Arrow} {
    top: 100%;
    :after {
      top: -0.313rem;
      border-top-width: 0;
      border-left-width: 0;
    }
  }
  &[data-popper-placement^="right"] > ${Arrow} {
    right: 100%;
    :after {
      left: 0.188rem;
      border-top-width: 0;
      border-right-width: 0;
    }
  }
  &[data-popper-placement^="left"] > ${Arrow} {
    left: 100%;
    :after {
      left: -0.313rem;
      border-left-width: 0;
      border-bottom-width: 0;
    }
  }
`;

export default Tooltip;
