import React, { useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import EditButton from "components/EditButton";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import { DAY_MONTH_YEAR_FORMAT, DEFAULT_TIMEZONE, TIME_WITH_TIMEZONE_FORMAT } from "constants/date";
import { VisitComment, useVisitActivityLogsQuery } from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

import CommentForm from "../CommentForm";
import { ActivityComment, VisitActivityLog } from "./ActivityComment";
import { TimelineChanges } from "./TimelineChanges";

interface Props {
  timezone?: string | null;
}

const Activity = ({ timezone = DEFAULT_TIMEZONE }: Props) => {
  const { id: visitId } = useParams<{ id: string }>();
  const { beforeCursor, afterCursor, limit } = usePageInfo();

  const currentUserId = useCurrentAccount()?.id;
  const { isOpen, toggle } = useModalToggle();
  const initialComment = {
    id: null,
    content: null,
  };
  const [comment, setComment] = useState<VisitComment>(initialComment);

  const { data, loading, error, refetch } = useVisitActivityLogsQuery({
    variables: { visitId, pagination: { beforeCursor, afterCursor, limit } },
  });
  const logs = nonNull(data?.visitActivityLogs?.data) as VisitActivityLog[];
  const pagination = data?.visitActivityLogs?.pagination;

  const closeModal = () => {
    setComment(initialComment);
    toggle();
  };

  const addComment = () => {
    setComment(initialComment);
    toggle();
  };

  const editComment = ({ id, content }: { id: string; content: string }) => {
    setComment({ id, content });
    toggle();
  };

  return (
    <>
      <Helmet>
        <title>Visit Activity - Papa Admin</title>
      </Helmet>
      <Heading color="primary">Activity Feed</Heading>

      <Button variant="primary" action onClick={() => addComment()}>
        Add comment
      </Button>

      <SmartTable<VisitActivityLog>
        data={logs}
        loading={loading}
        pagination={pagination}
        error={error}
        keyField="id"
        columns={[
          {
            header: { title: "Date" },
            dataField: "date",
            formatter({ insertedAt }) {
              return formatDateTime(insertedAt, {
                format: DAY_MONTH_YEAR_FORMAT,
                timezone,
              });
            },
          },
          {
            header: { title: "Time" },
            dataField: "time",
            formatter({ insertedAt }) {
              return formatDateTime(insertedAt, {
                format: TIME_WITH_TIMEZONE_FORMAT,
                timezone,
              });
            },
          },
          {
            header: { title: "Comment" },
            cellProps: { maxWidth: "500px", width: "55%" },
            dataField: "comment",
            formatter(log) {
              const isCurrentUserCreator = currentUserId === log?.accountId;
              return (
                <>
                  <ActivityComment log={log} />
                  {log.__typename === "VisitActivityLogVisitComment" && isCurrentUserCreator && (
                    <StyledEditButton
                      onClick={() => editComment({ id: log.id!, content: log.content! })}
                    >
                      Edit
                    </StyledEditButton>
                  )}
                </>
              );
            },
          },
          {
            header: { title: "Timeline Changes" },
            dataField: "timelineChanges",
            formatter({ timelineChanges }) {
              return (
                <TimelineChanges
                  timelineChanges={timelineChanges}
                  timezone={timezone ?? DEFAULT_TIMEZONE}
                />
              );
            },
          },
        ]}
      />

      <ModalLayout
        toggle={closeModal}
        title={`${comment.id ? "Edit" : "Add a"} comment`}
        isOpen={isOpen}
      >
        {
          <CommentForm
            visitId={visitId}
            comment={comment!}
            closeModal={closeModal}
            onFinish={() => refetch()}
          />
        }
      </ModalLayout>
    </>
  );
};

const StyledEditButton = styled(EditButton)`
  margin: 0;
  margin-top: 10px;
`;

export default Activity;
