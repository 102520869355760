import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import InputPhone from "components/InputPhone";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { SelectRoles } from "components/Select/SelectRoles";
import { GET_ROLES } from "components/Select/SelectRoles";
import { papaTeamOptions } from "constants/papaTeam";
import { useAssignRolesMutation } from "generated/types";
import "generated/types";
import { generatePassword } from "utils/generate-password";
import { formatFullName } from "utils/strings/formatFullName";

import { CREATE_ADMIN_ACCOUNT } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  onClose: () => void;
  onCreateFinish: () => void;
}

export const CreateAdminForm: React.FC<Props> = ({ onClose, onCreateFinish }) => {
  const [createAccount] = useMutation(CREATE_ADMIN_ACCOUNT);
  const [assignRoles] = useAssignRolesMutation();
  const { data: rolesData } = useQuery(GET_ROLES, {
    variables: {
      filter: {
        name: {
          eq: "AgentPal",
        },
      },
    },
  });
  const [adminRole] = rolesData?.roles?.data ?? [];

  const onSubmit = async (values: Schema) => {
    try {
      const { data } = await createAccount({
        variables: {
          input: {
            fullName: formatFullName(values.fullName),
            email: values.email,
            phoneNumber: values.phoneNumber,
            permissions: {
              admin: values.isAdmin,
            },
            papaTeam: values.papaTeam,
            password: generatePassword(20),
          },
        },
      });

      const accountId = data?.createAccount?.data?.id;

      if (accountId) {
        await assignRoles({
          variables: {
            accountId,
            roleId: values.roleIds,
          },
        });

        toast.success("Account was created with success");
        onCreateFinish();
        onClose();
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    isAdmin: true,
    papaTeam: null,
    roleIds: adminRole ? [adminRole.id] : [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label required>Full name</Form.Label>
            <Form.Input aria-label="fullName" name="fullName" />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Email</Form.Label>
            <Form.Input aria-label="email" name="email" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone number</Form.Label>
            <InputPhone name="phoneNumber" aria-label="phoneNumber" />
          </Form.Group>

          <Form.Group>
            <Form.Label>Has admin permission</Form.Label>
            <Form.Switch name="isAdmin" aria-label="isAdmin" />
          </Form.Group>

          <Form.Group>
            <Form.Label>Team</Form.Label>
            <Select
              name="papaTeam"
              options={papaTeamOptions}
              isSearchable={false}
              placeholder="Select team"
              aria-label="team"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Roles</Form.Label>
            <SelectRoles name="roleIds" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
