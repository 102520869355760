import React from "react";

import {
  PriorityBonusMenuActions as DataTableMenuActions,
  menuItems,
} from "components/ActionsMenu/PriorityBonusActionsMenu/models";
import DataTableDropdownMenu from "components/DataTableDropdownMenu";
import Table from "components/Table";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { priorityBonusReasonLabels } from "constants/priorityBonus";
import { PriorityBonus, PriorityBonusReason } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";

interface Props {
  loading: boolean;
  currentBonus?: PriorityBonus | null;
  cancelPriorityBonus: () => void;
}

const CurrentBonusTable: React.FC<Props> = ({
  currentBonus,
  loading = false,
  cancelPriorityBonus,
}) => {
  const menuActionsHandler = (action: DataTableMenuActions) => {
    if (action === DataTableMenuActions.cancelPriorityBonus) {
      cancelPriorityBonus();
    }
  };

  return (
    <Table>
      <Table.Head>
        <Table.HeadCell>Amount</Table.HeadCell>
        <Table.HeadCell>Reason</Table.HeadCell>
        <Table.HeadCell>Created</Table.HeadCell>
        <Table.HeadCell>Created By</Table.HeadCell>
        <Table.HeadCell></Table.HeadCell>
      </Table.Head>

      <Table.Body>
        {loading && <Table.Placeholder rows={1} columns={5} />}
        {!loading && !currentBonus && (
          <Table.Row>
            <Table.Cell colSpan={5} align="center" noWrap>
              No data to show
            </Table.Cell>
          </Table.Row>
        )}
        {currentBonus?.amountInCents && (
          <Table.Row key={currentBonus.id}>
            <Table.Cell>{formatNumberToCurrency(currentBonus.amountInCents)}</Table.Cell>
            <Table.Cell>
              {currentBonus.reason
                ? priorityBonusReasonLabels[currentBonus.reason]
                : EMPTY_PLACEHOLDER}
            </Table.Cell>
            <Table.Cell>{formatDateTime(currentBonus.insertedAt) || EMPTY_PLACEHOLDER}</Table.Cell>
            <Table.Cell>{currentBonus.createdByName || EMPTY_PLACEHOLDER}</Table.Cell>
            <Table.Cell align="center">
              {currentBonus.reason !== PriorityBonusReason.Cancelled && (
                <DataTableDropdownMenu
                  items={menuItems()}
                  actionHandler={(action) => menuActionsHandler(action)}
                />
              )}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default CurrentBonusTable;
