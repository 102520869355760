import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";
import {
  PriorityBonusReason,
  VisitState,
  useCreatePriorityBonusMutation,
  useCreatePriorityBonusOverrideMutation,
} from "generated/types";
import * as segment from "utils/segment";

interface Props {
  visitId: string;
  visitState?: VisitState | null;
  isOpen: boolean;
  toggle: () => void;
  onRefetch: () => void;
}

export const CancelPriorityBonusModal: React.FC<Props> = ({
  visitId,
  visitState,
  isOpen,
  toggle,
  onRefetch,
}) => {
  const [createPriorityBonusMutation, { loading }] = useCreatePriorityBonusMutation();
  const [createPriorityBonusOverrideMutation] = useCreatePriorityBonusOverrideMutation();

  const onConfirm = async () => {
    try {
      if (visitState === VisitState.Pending) {
        const { data } = await createPriorityBonusMutation({
          variables: {
            visitId: visitId,
            input: {
              amountInCents: 0,
              reason: PriorityBonusReason.Cancelled,
            },
          },
        });

        if (data?.createPriorityBonus?.data) {
          toast.success("Bonus was cancelled!");
          toggle();
          onRefetch();
        } else {
          throw new Error("It was not possible to cancel the bonus");
        }
      } else {
        const { data } = await createPriorityBonusOverrideMutation({
          variables: {
            visitId: visitId,
            input: {
              amountInCents: 0,
            },
          },
        });

        if (data?.createPriorityBonusOverride?.data) {
          toast.success("Bonus was cancelled!");
          toggle();
          onRefetch();
        } else {
          throw new Error("It was not possible to cancel the bonus");
        }
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <StyledIcon fontWeight="bold" size={23} />
          <TextContainer>
            <Text>Do you want to cancel this bonus?</Text>
            <br />
            <Text>
              Note: Cancelling a bonus sets the amount to $0.00. You can still update the bonus in
              the future if necessary.
            </Text>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          type="button"
          variant="primary"
          onClick={() => {
            segment.cancelPriorityBonusButtonClicked();
            onConfirm();
          }}
        >
          {loading ? <SpinningIcon size={18} /> : "Ok"}
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
