import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import PapasTable from "components/DataTables/PapasTable";
import { usePapasSorting } from "components/DataTables/PapasTable/usePapasSorting";
import { Pagination, Papa, useCaregiverPapasQuery } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";

const Papas: React.FC = () => {
  const { page, limit } = usePageInfo();
  const { caregiverId } = useParams<{ caregiverId: string }>();
  const [sorting, setSorting] = usePapasSorting();

  const { data, loading, error, refetch } = useCaregiverPapasQuery({
    variables: {
      caregiverId,
      sorting,
      papasPagination: { page, limit },
    },
  });

  const papas = papasConcealed(data?.caregiver?.data?.papas?.data as Papa[]);
  const pagination = data?.caregiver?.data?.papas?.pagination as Pagination;

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>Caregiver&apos;s Papas - Papa Admin</title>
      </Helmet>

      <PapasTable
        data={papas}
        loading={loading}
        pagination={pagination}
        error={error}
        sorting={sorting}
        onSortChange={setSorting}
        onRefetch={handleRefetch}
      />
    </>
  );
};

export default Papas;
