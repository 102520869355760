import { Form } from "formik";
import styled from "styled-components";

import Button from "../Button";

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5rem;
  padding-right: 2.625rem;
  ${Button}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export const Container = styled.div`
  padding: 0 3.375rem 0 2.25rem;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;
