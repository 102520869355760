import * as yup from "yup";

import { ReasonCode, WhatTriggeredOption } from "constants/visit";

export interface Schema {
  withFee: boolean | null;
  reason: ReasonCode | null;
  whatTriggered: WhatTriggeredOption;
  applyToFutureRecurrences: boolean;
  description: string;
}

export const schema = yup.object().shape({
  withFee: yup.boolean().nullable().required("Please select fee!"),
  reason: yup.string().nullable().required("Please select reason!"),
  applyToFutureRecurrences: yup.boolean(),
  description: yup.string(),
});
