import styled from "styled-components";

import { getStateTextColor, getStateTextWeight } from "components/Table/TableRow";
import Text from "components/Text";
import {
  PaymentInvoiceState,
  PaymentState,
  TipaltiDisbursementStatus,
  VisitState,
} from "generated/types";

type StateTypeProps = {
  variant?: "success" | "warning" | "danger";
  statusLegend?: VisitState;
  paymentStatusLegend?: PaymentState;
  paymentInvoiceStateLegend?: PaymentInvoiceState;
  tipaltiDisbursementStatusLegend?: TipaltiDisbursementStatus;
};

const StateText = styled(Text)<StateTypeProps>`
  color: ${getStateTextColor};
  font-weight: ${getStateTextWeight};
`;

export default StateText;
