import styled from "styled-components";

import FormGroup from "./FormGroup";

const FormGroupInline = styled(FormGroup)`
  display: flex;
  margin-left: -0.938rem;
  margin-right: -0.938rem;
  flex-direction: column;

  @media (min-width: 75rem) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export default FormGroupInline;
