import range from "lodash/range";
import moment, { Moment } from "moment-timezone";
import React, { CSSProperties, useState } from "react";
import { default as ReactSelect } from "react-select";
import styled from "styled-components";

import Button from "components/Button";
import Flex from "components/Flex";
import { StyledRadio } from "components/Radio";
import {
  SwitchButtonInput,
  Label as SwitchLabel,
  Text as SwitchText,
} from "components/SwitchButton";
import Text from "components/Text";
import { Location } from "generated/types";
import { isTimeBetween, timezoneGenericAbbrev } from "utils/time";

interface Props {
  location: Location;
  scheduledForTime: string | null;
  onScheduledForTimeChange: (time: string) => void;
}

const NewTimeSelect = ({ location, scheduledForTime, onScheduledForTimeChange }: Props) => {
  const [isCustomTime, setIsCustomTime] = useState(false);

  const hour = scheduledForTime ? parseInt(moment(scheduledForTime, "h:mm A").format("h")) : 0;
  const minute = scheduledForTime ? parseInt(moment(scheduledForTime, "h:mm A").format("mm")) : 0;
  const isAM = scheduledForTime ? moment(scheduledForTime, "h:mm A").format("A") === "AM" : true;

  const handleIsCustomTimeChange = () => {
    setIsCustomTime(!isCustomTime);
  };

  const getTime = (newHour: number, newMinute: number, newIsAM: boolean, timezone: string) => {
    const newTime = moment(`${newHour}:${newMinute} ${newIsAM ? "AM" : "PM"}`, "h:mm A", timezone);
    return newTime.format("h:mm A");
  };

  const handleCustomTimeChange = (name: string, data: any) => {
    switch (name) {
      case "hour":
        onScheduledForTimeChange(getTime(data.value, minute, isAM, location!.timezone!));
        break;
      case "minute":
        onScheduledForTimeChange(getTime(hour, data.value, isAM, location!.timezone!));
        break;
      case "amPM":
        onScheduledForTimeChange(
          getTime(hour, minute, data.currentTarget.value === "am", location!.timezone!)
        );
        break;
    }
  };

  const handleHourSelectChange = (value: Moment) => {
    onScheduledForTimeChange(value?.format("LT"));
  };

  const openHour = location.timezone
    ? moment.tz(location.supportOperatingHours?.startTime, location.timezone).format("ha")
    : "";
  const closeHour = location.timezone
    ? moment.tz(location.supportOperatingHours?.endTime, location.timezone).format("ha")
    : "";

  if (!location.timezone) return null;
  return (
    <Flex flexDirection="column" rowGap="1rem">
      <SwitchLabel>
        <SwitchButtonInput
          name="isCustomTime"
          checked={isCustomTime}
          onChange={handleIsCustomTimeChange}
        ></SwitchButtonInput>
        <SwitchText>Choose a custom start time</SwitchText>
      </SwitchLabel>
      <SchedulingInstructions>
        Visits scheduled {openHour}-{closeHour}; no exceptions.
      </SchedulingInstructions>
      {isCustomTime ? (
        <CustomTime
          isAM={isAM}
          hour={hour}
          minute={minute}
          startTime={location.supportOperatingHours?.startTime}
          endTime={location.supportOperatingHours?.endTime}
          scheduledForTime={scheduledForTime}
          timezone={location.timezone}
          onChange={handleCustomTimeChange}
        />
      ) : (
        <HourQuickPicker
          timezone={location.timezone}
          startTime={location.supportOperatingHours?.startTime}
          endTime={location.supportOperatingHours?.endTime}
          scheduledForTime={scheduledForTime}
          onChange={handleHourSelectChange}
        />
      )}
    </Flex>
  );
};

interface CustomTimeProps {
  isAM: boolean;
  hour: number;
  minute: number;
  startTime: string;
  endTime: string;
  scheduledForTime: string | null;
  timezone: string;
  onChange: (time: string, e: any) => void;
}

const CustomTime = ({
  isAM,
  hour,
  minute,
  startTime,
  endTime,
  scheduledForTime,
  timezone,
  onChange,
}: CustomTimeProps) => {
  const amPmOnChange = (e: any) => onChange("amPM", e);

  const isDuringBusinessHours = scheduledForTime
    ? isTimeBetween(scheduledForTime, startTime, endTime, timezone)
    : true;

  return (
    <>
      <Flex columnGap="0.7rem" alignItems="center">
        <TimeSelect
          type="hour"
          options={range(1, 13)}
          hour={hour}
          minute={minute}
          onChange={onChange}
        />
        <ClockColon>:</ClockColon>
        <TimeSelect
          type="minute"
          options={range(0, 60, 15)}
          hour={hour}
          minute={minute}
          onChange={onChange}
        />
        <StyledRadio
          id="am"
          type="radio"
          name="ampm"
          onChange={amPmOnChange}
          checked={isAM}
          value="am"
        />
        <AmPmLabel htmlFor="am">AM</AmPmLabel>
        <StyledRadio
          id="pm"
          type="radio"
          name="ampm"
          onChange={amPmOnChange}
          checked={!isAM}
          value="pm"
        />
        <AmPmLabel htmlFor="pm">PM</AmPmLabel>
      </Flex>
      {!isDuringBusinessHours && (
        <OutsideHoursWarning color="error">
          This start time is outside of the allowed range. Please select a different time.
        </OutsideHoursWarning>
      )}
    </>
  );
};

interface HourQuickPickerProps {
  timezone: string;
  startTime: string;
  endTime: string;
  scheduledForTime: string | null;
  onChange: (time: Moment) => void;
}
const HourQuickPicker = ({
  timezone,
  startTime,
  endTime,
  scheduledForTime,
  onChange,
}: HourQuickPickerProps) => {
  const getHour = (dateTime: string, timezone: string) => {
    const date = moment.tz(dateTime, timezone);
    return parseInt(date.format("H"));
  };

  const startHour = getHour(startTime, timezone);
  const endHour = getHour(endTime, timezone);

  const amHours = range(startHour, 12);
  const pmHours = range(12, endHour + 1);

  return (
    <Flex columnGap="1.6rem">
      <Flex flexDirection="column" rowGap="1rem">
        <HourList
          hours={amHours}
          timezone={timezone}
          scheduledForTime={scheduledForTime}
          onChange={onChange}
        />
      </Flex>
      <Flex flexDirection="column" rowGap="1rem">
        <HourList
          hours={pmHours}
          timezone={timezone}
          scheduledForTime={scheduledForTime}
          onChange={onChange}
        />
      </Flex>
    </Flex>
  );
};

interface HourListProps {
  hours: number[];
  timezone: string;
  scheduledForTime: string | null;
  onChange: (time: Moment) => void;
}

const HourList = ({ hours, timezone, scheduledForTime, onChange }: HourListProps) => {
  const getButtons = () => {
    return hours.map((hour) => {
      const newTime = moment().tz(timezone).set({ hour: hour, minute: 0, second: 0 });
      const hourLabel = newTime.format("ha");
      const timezoneLabel = timezoneGenericAbbrev(timezone);
      const isSelected = scheduledForTime === newTime.format("LT");

      return (
        <Button
          key={hour}
          type="button"
          borderWidth="1px"
          spanSpacing="0.35em"
          isSelected={isSelected}
          onClick={() => {
            onChange(newTime);
          }}
        >
          <div>
            <span>{hourLabel}</span>
            <TimezoneOnButton>{timezoneLabel}</TimezoneOnButton>
          </div>
        </Button>
      );
    });
  };

  return (
    <Flex flexDirection="column" rowGap="1rem">
      {getButtons()}
    </Flex>
  );
};

interface TimeSelectProps {
  type: string;
  options: number[];
  hour: number;
  minute: number;
  onChange: (type: string, event: any) => void;
}
const TimeSelect = ({ type, options, hour, minute, onChange }: TimeSelectProps) => {
  const selectOptions = options.map((v) => {
    return {
      value: v.toString(),
      label: type === "hour" ? v.toString() : v.toString().padStart(2, "0"),
    };
  });

  const value = type === "hour" ? hour.toString() : minute.toString().padStart(2, "0");
  return (
    <ReactSelect
      name={type}
      options={selectOptions}
      isSearchable={false}
      value={{ value: value, label: value }}
      styles={dropdownStyles}
      onChange={(e) => onChange(type, e)}
    />
  );
};

const AmPmLabel = styled.label`
  cursor: pointer;
  font-size: 0.85em;
  margin-left: -0.5rem;
`;

const TimezoneOnButton = styled.span`
  font-weight: normal;
`;

const SchedulingInstructions = styled.div`
  font-size: 0.85em;
  margin-bottom: 1rem;
`;

const ClockColon = styled.div`
  color: #7d8695;
  font-weight: bolder;
  font-size: 1.3em;
  margin-bottom: 0.5rem;
`;

const OutsideHoursWarning = styled(Text)`
  margin-top: 1rem;
`;

const dropdownStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  valueContainer: () => ({
    width: "100%",
  }),
  singleValue: () => ({
    padding: "0.5em 1.5em",
    fontWeight: "bold",
  }),
  container: (provided: CSSProperties, state: any) => ({
    ...provided,
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocused ? "4px 4px 0 0" : 4,
    height: "2.6rem",
    width: "4.4rem",
    display: "flex",
    justifyContent: "center",
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    borderColor: "#DFE3E9",
    boxShadow: "none",
    borderWidth: 0,
  }),
  menu: (provided: CSSProperties, state: any) => ({
    ...provided,
    margin: 0,
  }),
  menuList: () => ({}),
  option: (provided: CSSProperties, state: any) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: state.isSelected && "black",
    background: state.isSelected ? "#EAEFFF" : "white",
    fontWeight: "bold",
  }),
};

export default NewTimeSelect;
