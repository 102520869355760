import { useMutation } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import Form from "components/Form";
import Heading from "components/Heading";
import { LoginResult } from "generated/types";

import { LOGIN } from "./gql";

interface Values {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const LoginForm = () => {
  const history = useHistory();
  const [login] = useMutation<
    { loginAdmin: LoginResult },
    { input: { email: string; password: string } }
  >(LOGIN);

  const handleSubmit = async (values: Values) => {
    try {
      const { data } = await login({
        variables: {
          input: values,
        },
      });
      if (!data?.loginAdmin?.token) {
        throw new Error("Something went wrong");
      }

      const token = data.loginAdmin.token;

      localStorage.setItem("token", token);

      toast.info("You successfully logged in.");
      history.push("/");
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Container>
      <Heading>Login</Heading>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <FormikForm>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Input name="email" aria-label="email" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Input type="password" name="password" aria-label="password" />
          </Form.Group>
          <Action>
            <Form.SubmitButton loadingText="Loading" action noContent>
              Let me in!
            </Form.SubmitButton>
          </Action>
        </FormikForm>
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  max-width: 300px;
  width: 100%;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default LoginForm;
