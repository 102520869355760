import { Globe as GlobeIcon } from "@styled-icons/bootstrap/Globe";
import { Car as CarIcon } from "@styled-icons/fa-solid/Car";
import React from "react";
import styled from "styled-components";

import EmployerIcon from "assets/images/employer.svg";
import Tooltip from "components/Tooltip";
import { VisitState } from "generated/types";

type Props = {
  isEmployerVisit?: boolean | null;
  isUberEligible?: boolean | null;
  isVirtual?: boolean | null;
  state?: VisitState | null;
};

export const TypeCell = ({ state, isUberEligible, isVirtual, isEmployerVisit = false }: Props) => {
  if (!state) return null;
  return (
    <Wrapper state={state}>
      {isEmployerVisit && (
        <Tooltip title="Employer Market Visit">
          <img src={EmployerIcon} width={20} alt="employer visit icon" />
        </Tooltip>
      )}
      {isUberEligible && (
        <Tooltip title="Papa would be okay with Uber for this visit">
          <CarIcon size={20} />
        </Tooltip>
      )}
      {isVirtual && (
        <Tooltip title="Virtual Visit">
          <GlobeIcon size="18" />
        </Tooltip>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ state: VisitState }>`
  color: ${({ theme, state }) => theme.statusLegend[state]};
`;
