import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.text.main};
  font-weight: bold;
  text-decoration: none;
  margin: 0 1.625rem;
  padding: 0.375rem 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: transparent;
  }

  &.active {
    color: ${({ theme }) => theme.text.primary};

    &:after {
      background: ${({ theme }) => theme.text.primary};
    }
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

export default NavLink;
