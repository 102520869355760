import React from "react";
import ReactModal from "react-modal";

import { ModalBody, ModalTitle, StyledModal } from "./Modal.styles";

ReactModal.setAppElement("#root");

interface Props extends ReactModal.Props {
  children: React.ReactNode;
  width?: number;
}

const Modal = ({ children, width, ...rest }: Props) => {
  return (
    <StyledModal width={width} {...(rest as any)}>
      {children}
    </StyledModal>
  );
};

Modal.Title = ModalTitle;
Modal.Body = ModalBody;

export default Modal;
