import { VisitState } from "generated/types";

export type VisitStatesForDashboard =
  | VisitState.Accepted
  | VisitState.Confirmed
  | VisitState.Enroute
  | VisitState.PartnerOperated
  | VisitState.Pending;

const visitStatesColorsAndName = {
  [VisitState.Accepted]: { background: "#C1F3F7", text: "#07D1DE", name: "Accepted" },
  [VisitState.Confirmed]: { background: "#F8F3BF", text: "#E5CF00", name: "Confirmed" },
  [VisitState.Enroute]: { background: "#CBC1F2", text: "#9747FF", name: "Enroute" },
  [VisitState.PartnerOperated]: { background: "#ECDFFA", text: "#B37FEB", name: "Partner Op" },
  [VisitState.Pending]: { background: "#F7BFEB", text: "#E100B0", name: "Pending" },
};

export const getColorsAndName = (state?: VisitState | null) => {
  if (!state || !defaultStates.includes(state)) {
    return { background: "#F7BFEB", text: "#E100B0", name: state };
  } else {
    return visitStatesColorsAndName[state as VisitStatesForDashboard];
  }
};

export const defaultStates = Object.keys(visitStatesColorsAndName);
