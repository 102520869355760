import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Heading from "components/Heading";
import Modal from "components/Modal";
import Text from "components/Text";
import {
  OnboardingStages,
  PalOnboardingInput,
  PalOnboardingSearchDocument,
  PalOnboardingUpdateInput,
} from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";

import { ModalFooter } from "../../components/Modal/Modal.styles";
import { usePalOnboardingMutation } from "./gql";

interface Props {
  email: string;
  id: any;
  isOpen: boolean;
  closeModal: () => void;
}

const StaticallyChangeHasCompletedOrientationVideo = true;

export const SetWatchedOrientationVideo: React.FC<Props> = ({ isOpen, closeModal, id, email }) => {
  const { page, limit } = usePageInfo();
  const { search } = useUrlQuery();

  const [palOnboardingMutation, { loading }] = usePalOnboardingMutation({
    refetchQueries: [
      {
        query: PalOnboardingSearchDocument,
        variables: { search: `*${search ?? ""}*`, page, limit },
      },
    ],
  });
  const onConfirm = async () => {
    const palOnboardInput: PalOnboardingUpdateInput = {
      id,
      hasCompletedOrientationVideo: StaticallyChangeHasCompletedOrientationVideo,
    };
    const input: PalOnboardingInput = {
      palOnboarding: palOnboardInput,
      onboardingStage: OnboardingStages.PalOnboarding,
    };
    palOnboardingMutation({ variables: { input } })
      .then((data) => {
        closeModal();
        toast.success(`${email} has completed Welcome Video`);
      })
      .catch((e) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <StyledIcon fontWeight="bold" size={23} />
          <TextContainer>
            <Heading as="h2">Change Users Welcome Video Status</Heading>
            <Text>Do you want to change {email} Welcome video status?</Text>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={loading} type="button" variant="primary" onClick={onConfirm}>
          {loading ? <SpinningIcon size={18} /> : "Ok"}
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
