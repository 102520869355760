import React from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { ModalFooter } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Text from "components/Text";
import { VisitProgram, useUpdateProgramActiveStateMutation } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import { GET_VISIT_PROGRAMS } from "../List/gql";

interface Props {
  program: VisitProgram;
  isOpen: boolean;
  onModalClose: () => void;
}

export const ChangeProgramStateModal: React.FC<Props> = ({ program, isOpen, onModalClose }) => {
  const { isActive } = program;
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { id } = useParams<{ id: string }>();
  const [updateProgram, { loading }] = useUpdateProgramActiveStateMutation({
    refetchQueries: [
      {
        query: GET_VISIT_PROGRAMS,
        variables: {
          filter: {
            businessId: id,
          },
          pagination: { beforeCursor, afterCursor, limit },
        },
      },
    ],
  });

  const onStateChange = async () => {
    try {
      const { data } = await updateProgram({
        variables: {
          id: program.id as string,
          input: {
            isActive: !isActive,
          },
        },
      });

      if (data?.updateVisitProgram?.data?.id) {
        toast.success(`Program is now ${isActive ? "inactive" : "active"}`);
        onModalClose();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={onModalClose} title="Program state" isOpen={isOpen}>
      <Container>
        <TextContainer>
          <Text>
            Do you want to change program state to
            <Text inline bold>
              &nbsp;{isActive ? "inactive" : "active"}?
            </Text>
          </Text>
        </TextContainer>

        <Footer>
          <Button disabled={loading} onClick={onModalClose}>
            Cancel
          </Button>
          <Button action noContent disabled={loading} onClick={onStateChange}>
            Confirm
            {loading && <SpinningIcon size={16} />}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Footer = styled(ModalFooter)`
  margin-top: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 70px;
`;
