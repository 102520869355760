import { CaretDown } from "@styled-icons/bootstrap/CaretDown";
import { CaretRight } from "@styled-icons/bootstrap/CaretRight";
import { StyledIcon } from "@styled-icons/styled-icon";
import React, { FC } from "react";
import styled from "styled-components";

import { useDropdownIsOpen } from "contexts/DropdownContext";

type Props = {
  color?: string;
  iconOpen?: StyledIcon;
  iconClose?: StyledIcon;
  size?: number;
};

export const DropdownIcon: FC<Props> = ({
  color = "#294157",
  iconOpen: Open = CaretDown,
  iconClose: Close = CaretRight,
  size = 18,
}) => {
  const isOpen = useDropdownIsOpen();

  return <Wrapper color={color}>{isOpen ? <Open size={size} /> : <Close size={size} />}</Wrapper>;
};

const Wrapper = styled.div<{ color: string }>`
  svg {
    color: ${({ color }) => color};
  }
`;
