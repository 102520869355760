import React from "react";
import styled from "styled-components";

import { ButtonNew } from "components/Button";

export type DrawerFooterProps = {
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  secondaryButtonText?: string | React.ReactNode;
  primaryButtonText?: string | React.ReactNode;
  children?: React.ReactNode;
};

export const DrawerFooter = ({
  primaryButtonText,
  secondaryButtonText,
  onSecondaryButtonClick = () => {},
  onPrimaryButtonClick,
  children,
}: DrawerFooterProps) => {
  const hasPrimaryClick = onPrimaryButtonClick !== undefined;
  return (
    <FooterWrapper>
      {secondaryButtonText && (
        <Button type="button" size="small" variant="secondary" onClick={onSecondaryButtonClick}>
          {secondaryButtonText}
        </Button>
      )}

      {primaryButtonText && (
        <Button
          type={hasPrimaryClick ? "button" : "submit"}
          size="small"
          variant="primary"
          onClick={onPrimaryButtonClick}
        >
          {primaryButtonText}
        </Button>
      )}

      {children}
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  height: 5.25rem;
  border: 0 solid #dfdfdf;
  border-top-width: 1px;
  padding-top: 1.16rem;
  padding-bottom: 1.16rem;
  padding-left: 1rem;
  padding-right: 1rem;
  grid-gap: 1rem;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const Button = styled(ButtonNew)`
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const DrawerContainer = styled.div`
  padding-left: ${({ theme }) => theme.drawer.padding};
  padding-right: ${({ theme }) => theme.drawer.padding};
`;
