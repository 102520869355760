import { Formik } from "formik";
import debounce from "lodash/debounce";
import noop from "lodash/noop";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import Form from "components/Form";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";

export const Filter = () => {
  const pageInfo = usePageInfo();
  const urlQueries = useUrlQuery();

  const history = useHistory();

  const [form, setForm] = useState({
    name: pageInfo.name ?? "",
  });

  const handleChange = (newForm: any) => {
    const name = newForm.name;

    const query = queryString.stringify(
      {
        ...urlQueries,
        name,
      },
      { skipEmptyString: true, skipNull: true }
    );

    if (!query && !history.location.search) {
      return;
    }

    if (`?${query}` !== history.location.search) {
      history.push(`?${query}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => debounce(handleChange, 500), [urlQueries]);

  const handleInput = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const newForm = { ...form, [name]: value };

    setForm(newForm);
    action(newForm);
  };

  return (
    <Formik initialValues={form} onSubmit={noop} enableReinitialize>
      <Form.Input
        name="name"
        aria-label="name"
        placeholder="Search by pal name"
        onChange={handleInput}
        keepEnable
      />
    </Formik>
  );
};
