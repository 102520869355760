import { useQuery } from "@apollo/client";
import { RateReview } from "@styled-icons/material-outlined/RateReview";
import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import Comment from "components/Comment";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { PapaBlockedPal } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { statusOptions } from "pages/Pals/List/ChangeStatus/ChangeStatus.models";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";

import { Filter } from "./Filter";
import { PAPA_BLOCKED_PALS } from "./gql";

const BlockedPals = () => {
  useTopBarOptions({ title: "Blocked Pals", icon: RateReview, breadcrumbs: ["Reviews"] });
  const { beforeCursor, afterCursor, limit, name } = usePageInfo();
  const { data, loading, error } = useQuery(PAPA_BLOCKED_PALS, {
    variables: {
      filter: name ? [{ palNameContains: name }] : [],
      sorting: { insertedAt: "DESC" },
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  return (
    <>
      <Helmet>
        <title>Blocked Pals - Papa Admin</title>
      </Helmet>

      <Filter />

      <SmartTable<PapaBlockedPal>
        loading={loading}
        error={error}
        keyField="id"
        pagination={data?.papaBlockedPals.pagination}
        columns={[
          {
            header: { title: "Full Name" },
            dataField: "fullName",
            formatter({ pal }) {
              return pal ? (
                <Link to={`/pals/${pal.data?.id}`}>{pal.data?.fullName}</Link>
              ) : (
                EMPTY_PLACEHOLDER
              );
            },
          },
          {
            header: { title: "Times Blocked" },
            dataField: "timesBlocked",
            formatter: ({ pal }) => pal?.data?.blockedPalCount ?? EMPTY_PLACEHOLDER,
          },
          {
            header: { title: "Latest Block" },
            dataField: "latestBlock",
            formatter: ({ insertedAt }) => formatDateTime(insertedAt),
          },
          {
            header: { title: "Latest Block Reason" },
            dataField: "latestBlockReason",
            formatter({ comment }) {
              return comment ? <Comment comment={comment} maxWidth={500} /> : EMPTY_PLACEHOLDER;
            },
          },
          {
            header: { title: "Status" },
            dataField: "status",
            formatter: ({ pal }) =>
              mapValueToLabel(statusOptions, pal?.data?.status) ?? EMPTY_PLACEHOLDER,
          },
        ]}
        data={data?.papaBlockedPals?.data}
      />
    </>
  );
};

export default BlockedPals;
