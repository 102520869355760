import { Check } from "@styled-icons/boxicons-regular/Check";
import { Cancel } from "@styled-icons/material-outlined/Cancel";
import { Field, FieldProps } from "formik";
import React, { useContext } from "react";
import styled from "styled-components";

import Feedback from "components/Form/FormFeedback";

import FormContext from "./FormContext";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  color?: string;
  name: string;
  id?: string;
  fullWidth?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  iconRight?: object;
  iconLeft?: object;
  disabled?: boolean;
  value?: string;
  dataTestid?: string;
  keepEnable?: boolean;
  type?: string;
};

const FormInput: React.FC<Props> = ({
  color,
  id,
  iconRight,
  iconLeft,
  isValid,
  name,
  isInvalid,
  fullWidth,
  "aria-label": ariaLabel,
  keepEnable,
  type,
  ...rest
}: Props) => {
  const { controlId } = useContext(FormContext);

  const determineIcon = () => {
    if (iconRight) return iconRight;
    if (isValid) return <GreenCheck size="16" />;
    if (isInvalid) return <RedCancel size="16" />;
  };
  const determinedIcon = determineIcon();

  return (
    <Field name={name}>
      {({ field, form, meta: { touched, error } }: FieldProps) => (
        <>
          <InputContainer
            isValid={isValid}
            isInvalid={isInvalid || (touched && !!error)}
            fullWidth={fullWidth}
            disabled={!keepEnable && form.isSubmitting}
            {...rest}
          >
            {!!iconLeft && <IconContainer>{iconLeft}</IconContainer>}
            <StyledInput
              disabled={form.isSubmitting}
              {...rest}
              {...field}
              color={color}
              value={field.value === null ? "" : field.value}
              id={id || controlId}
              aria-label={ariaLabel}
              type={type}
            />
            {determinedIcon && <IconContainer>{determinedIcon}</IconContainer>}
          </InputContainer>
          {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
        </>
      )}
    </Field>
  );
};

const IconContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.variants.secondary};
  margin: 0 0.375rem;
`;

const GreenCheck = styled(Check)`
  color: ${({ theme }) => theme.variants.success};
`;

const RedCancel = styled(Cancel)`
  color: ${({ theme }) => theme.variants.danger};
`;

type InputContainerProps = {
  fullWidth?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
  maxWidth?: string;
};

type StyledInputProps = {
  color?: string;
};

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: ${({ fullWidth, maxWidth = "18.75rem" }) => (fullWidth ? "100%" : maxWidth)};
  border: 1px solid
    ${({ isValid, isInvalid, theme }) => {
      if (isValid) return theme.variants.success;
      if (isInvalid) return theme.variants.danger;
      return theme.borderColor;
    }};
  background-color: white;
  padding: 0;
  border-radius: 0.25rem;
  font-size: ${({ theme }) => theme.fontSizes.middle};

  &:focus-within {
    border: thin solid ${({ theme }) => theme.variants.primary};
  }
`;

export const StyledInput = styled.input<StyledInputProps>`
  flex: 1;
  min-height: 18px;
  font-size: inherit;
  border-radius: 0.25rem;
  border: 0;
  padding: 0.625rem 0.75rem;
  width: 100%;

  color: ${({ color }) => {
    if (color) return color;
  }};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f3f3f3;
  }
`;

export default FormInput;
