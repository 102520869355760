import { gql } from "@apollo/client";

export const GET_TASK_OBJECTIVES = gql`
  query VisitTaskObjectives(
    $filter: [VisitObjectiveTemplateFilter]
    $pagination: PaginationInput
    $sorting: VisitObjectiveTemplateSorting
  ) {
    visitObjectiveTemplates(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        description
        task {
          data {
            id
          }
        }
      }
    }
  }
`;
