import { ReasonCode, WhatTriggeredOption } from "constants/visit";

export const withFeeOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const whatTriggeredOptions = [
  { value: WhatTriggeredOption.Member, label: "Member" },
  { value: WhatTriggeredOption.Pal, label: "Pal" },
  { value: WhatTriggeredOption.Service, label: "Papa Service" },
];

export const reasonOptions = [
  { value: ReasonCode.MemberNoShow, label: "Member No Show" },
  { value: ReasonCode.MemberResheduled, label: "Member Resheduled" },
  { value: ReasonCode.PalNoShow, label: "Pal No Show" },
  { value: ReasonCode.MemberCantMakeIt, label: "Member Can't Make It" },
  { value: ReasonCode.PalCantMakeIt, label: "Pal Can't Make It" },
  { value: ReasonCode.DuplicatedVisit, label: "Duplicated Visit" },
  { value: ReasonCode.AppIssues, label: "App Issues" },
  { value: ReasonCode.WeatherAbnormality, label: "Weather/Abnormality" },
  { value: ReasonCode.OutOfAssetsOrHours, label: "Out Of Assets Or Hours" },
  { value: ReasonCode.ChangedInsurance, label: "Changed Insurance" },
  { value: ReasonCode.FailedPayments, label: "Failed Payments" },
  {
    value: ReasonCode.ConvertingToAgentVirtualVisit,
    label: "Converting To Agent Virtual Visit",
  },
  {
    value: ReasonCode.PalsNotAvailableMemberNotFlexible,
    label: "Pals Not available/Member Not Flexible",
  },
  { value: ReasonCode.noPalsAvailable, label: "No Pals available" },
  { value: ReasonCode.Test, label: "Test" },
];

export const memberReasonOptions = [
  {
    label: "Pal",
    options: [
      { value: ReasonCode.MemberDoNotNeedAPal, label: "Do not need a Pal", tags: "good, help" },
      { value: ReasonCode.MemberPrefersAnotherPal, label: "Prefers another Pal", tags: "" },
      {
        value: ReasonCode.MemberNotMyPreferredPal,
        label: "Not my preferred Pal",
        tags: "available, availability",
      },
    ],
  },
  {
    label: "Member No-Show",
    options: [
      { value: ReasonCode.MemberNotHome, label: "Not home", tags: "away, papa" },
      {
        value: ReasonCode.MemberUnresponsive,
        label: "Unresponsive",
        tags: "left, voicemail, VM, LVM, attempt, attempted, papa",
      },
      {
        value: ReasonCode.MemberNoAnswerVV,
        label: "No Answer on VV",
        tags: "left, voicemail, VM, LVM, attempt, attempted, papa",
      },
    ],
  },
  {
    label: "Health",
    options: [
      {
        value: ReasonCode.MemberNotFeelingWell,
        label: "Not feeling well",
        tags: "sick, hospital, hospitalized, medication, admit, admitted, death",
      },
      {
        value: ReasonCode.MemberCovidRelated,
        label: "Covid related",
        tags: "exposed, unvaccinated, vaccine, positive, quarantine",
      },
      {
        value: ReasonCode.MemberDeceased,
        label: "Member deceased",
        tags: "death, calamity, situation",
      },
    ],
  },
  {
    label: "Event",
    options: [
      {
        value: ReasonCode.MemberHelpFromOtherPerson,
        label: "Help from other person",
        tags: "Friend, neighbour, family",
      },
      {
        value: ReasonCode.MemberVacationOrFamily,
        label: "Vacation or family",
        tags: "transport, transportation, guest, daughter, son, brother, sister, grandson, granddaughter",
      },
    ],
  },
  {
    label: "Issue",
    options: [
      {
        value: ReasonCode.MemberPauseService,
        label: "Pause service",
        tags: "stop, unsubscribe",
      },
      {
        value: ReasonCode.MemberForgotAboutVisit,
        label: "Forgot about visit",
        tags: "Miscommunication",
      },
      {
        value: ReasonCode.MemberLessFrequentVisits,
        label: "Less frequent visits",
        tags: "often, many",
      },
    ],
  },
  {
    label: "Other",
    options: [
      {
        value: ReasonCode.MemberNotHappyWithPapa,
        label: "Not happy with Papa",
        tags: "unhappy, frustrated, DNC",
      },
      {
        value: ReasonCode.MemberNotScheduledDidNotKnow,
        label: "Not scheduled / did not know",
        tags: "unaware, error",
      },
      {
        value: ReasonCode.MemberNotNeededAnymore,
        label: "Not needed anymore",
        tags: "cancel, mistake, late, good",
      },
      {
        value: ReasonCode.MemberInactive,
        label: "Inactive",
        tags: "stop, cancel",
      },
      {
        value: ReasonCode.MemberStopService,
        label: "Stop service",
        tags: "cancel",
      },
      {
        value: ReasonCode.MemberUndefined,
        label: "Undefined",
        tags: "",
      },
    ],
  },
];

export const palReasonOptions = [
  {
    label: "Pal",
    options: [
      {
        value: ReasonCode.PalNoShow,
        label: "No-Show",
        tags: "unresponsive, attempt, attempted, LVM, VM",
      },
      {
        value: ReasonCode.PalSomethingCameUp,
        label: "Something came up",
        tags: "schedule",
      },
      {
        value: ReasonCode.PalNotFeelingWell,
        label: "Not feeling well",
        tags: "appointment, sick, hospital, hospitalized, medication",
      },
      {
        value: ReasonCode.PalCovidRelated,
        label: "Covid related",
        tags: "exposed, unvaccinated, vaccine, positive, quarantine",
      },
      {
        value: ReasonCode.PalEmergency,
        label: "Emergency",
        tags: "urgent",
      },
      {
        value: ReasonCode.PalUnawareOfTheVisit,
        label: "Unaware of the visit",
        tags: "error",
      },
    ],
  },
  {
    label: "Event",
    options: [
      { value: ReasonCode.PalCarIssues, label: "Car issues", tags: "flat, breakdown, engine" },
      {
        value: ReasonCode.PalWeatherIssues,
        label: "Weather issues",
        tags: "rain, storm, snow, heat",
      },
      {
        value: ReasonCode.PalAppIssues,
        label: "App issues (cannot see visit)",
        tags: "invisible, where, error",
      },
      {
        value: ReasonCode.PalCannotFindMember,
        label: "Cannot find member's home",
        tags: "address, gate, code",
      },
      {
        value: ReasonCode.PalDoubleBooked,
        label: "Double booked",
        tags: "",
      },
      {
        value: ReasonCode.PalBillingIssues,
        label: "Billing issues",
        tags: "money",
      },
    ],
  },
  {
    label: "Other",
    options: [{ value: ReasonCode.PalUndefined, label: "Undefined", tags: "" }],
  },
];

export const serviceReasonOptions = [
  {
    label: "Network",
    options: [
      { value: ReasonCode.ServiceNoPalAvailable, label: "No Pal available", tags: "" },
      {
        value: ReasonCode.ServiceNoPalAvailableFlexible,
        label: "No Pal available / member not flexible",
        tags: "",
      },
    ],
  },
  {
    label: "Eligibility",
    options: [
      { value: ReasonCode.ServiceOutOfHours, label: "Out of hours", tags: "" },
      { value: ReasonCode.ServiceNotEligible, label: "Not eligible", tags: "" },
    ],
  },
  {
    label: "Schedule",
    options: [
      { value: ReasonCode.ServiceDuplicateVisit, label: "Duplicate visit", tags: "admin" },
      { value: ReasonCode.ServiceWrongVisitType, label: "Wrong visit type", tags: "error" },
      { value: ReasonCode.ServiceWrongDateOrTime, label: "Wrong date or time", tags: "error" },
      { value: ReasonCode.ServiceWrongPhoneNumber, label: "Wrong phone number", tags: "" },
    ],
  },
  {
    label: "Other",
    options: [
      { value: ReasonCode.ServicePaymentIssue, label: "Payment issue", tags: "money" },
      { value: ReasonCode.ServiceWrongAccount, label: "Wrong account", tags: "" },
      { value: ReasonCode.ServiceDuplicateMember, label: "Duplicate member", tags: "" },
      { value: ReasonCode.ServiceAgentCloneVisit, label: "Clone visit", tags: "" },
    ],
  },
];

export const applyUpdateToOptions = [
  { value: false, label: "only this visit" },
  { value: true, label: "this visit and all visits in the future" },
];
