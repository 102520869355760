import { useEffect, useState } from "react";

export interface BaseRate {
  id?: string | null;
  hourlyRate?: number | null;
  state?: string | null;
  updatedAt?: string | null;
}

export interface VirtualBaseRate {
  id?: string | null;
  baseRate?: number | null;
  minuteFrom?: string | null;
  minuteTo?: string | null;
  updatedAt?: string | null;
}

export const baseRateMocked: BaseRate[] = [
  {
    id: "1",
    hourlyRate: 1000,
    state: "CA",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
];

export const virtualBaseRateMocked: VirtualBaseRate[] = [
  {
    id: "1",
    baseRate: 0,
    minuteFrom: "1",
    minuteTo: "7",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "2",
    baseRate: 500,
    minuteFrom: "8",
    minuteTo: "14",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "3",
    baseRate: 800,
    minuteFrom: "15",
    minuteTo: "19",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "4",
    baseRate: 1000,
    minuteFrom: "20",
    minuteTo: "44",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
];

const useQueryMocked = <T>(dataMocked: T) => {
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState(true);

  const error: Error | undefined = undefined;

  useEffect(() => {
    setTimeout(() => {
      setData(dataMocked);
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, error, loading };
};

const pagination = { afterCursor: null, beforeCursor: null };
type Pagination = { afterCursor: null; beforeCursor: null };

type List<T> = {
  entity?: {
    data?: T[] | null;
    pagination?: Pagination | null;
  } | null;
};

type Single<T> = {
  entity?: {
    data?: T | null;
  } | null;
};

export const useEntityQuery = (options: { variables: { entityId: string } }) => {
  return useQueryMocked<Single<{ id?: string | null }>>({
    entity: {
      data: {
        id: "1",
      },
    },
  });
};

export const useInPersonBaseRateQuery = () => {
  return useQueryMocked<List<BaseRate>>({
    entity: {
      data: baseRateMocked,
      pagination,
    },
  });
};

export const useVirtualBaseRateQuery = () => {
  return useQueryMocked<List<VirtualBaseRate>>({
    entity: {
      data: virtualBaseRateMocked,
      pagination,
    },
  });
};
