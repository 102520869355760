import { gql } from "@apollo/client";

export const BULK_EDIT_SERVICE_REQUESTS = gql`
  mutation bulkUpdateServiceRequests($input: BulkUpdateServiceRequestsInput!) {
    bulkUpdateServiceRequests(input: $input) {
      data {
        id
        need {
          data {
            id
            name
          }
        }
        needText
        type {
          data {
            id
            name
          }
        }
        priority
        status
        closedReason
      }
    }
  }
`;
