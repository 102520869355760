import { ApolloError } from "@apollo/client";
import moment from "moment-timezone";
import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import Button from "components/Button";
import ButtonLink from "components/EditButton";
import { Loader } from "components/Loader";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import SeeMapLink from "components/SeeMapLink";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import config from "config";
import { EMPTY_PLACEHOLDER, PENDING_PLACEHOLDER } from "constants/empty-placeholder";
import { languageOptions } from "constants/language";
import { Pal, PalStatus, useUpdatePalDisbursementProcessorMutation } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useModalToggle } from "hooks/useModalToggle";
import { OverrideLocationButton } from "modals/OverrideLocation/OverrideLocationButton";
import { statusOptions } from "pages/Pals/List/ChangeStatus/ChangeStatus.models";
import { canPalSeeVirtualVisits } from "utils/canPalSeeVirtualVisits";
import { formatDateTime, formatGender, mapValueToLabel } from "utils/helpers/formatters";
import { fancyEnum } from "utils/strings/fancyEnum";

import { OverviewEditModal } from "../shared/OverviewEditModal/OverviewEditModal";

type RouteParams = {
  id: string;
};

interface Props {
  pal?: Pal | null;
  loading: boolean;
  error: ApolloError | undefined;
  onClickEdit: () => void;
}

enum DisbursementProcessor {
  Stripe = "stripe",
}

const Overview: FC<Props> = ({ pal, loading, error, onClickEdit }) => {
  const { id } = useParams<RouteParams>();
  const { isOpen: showEditModal, toggle: toggleEditModal } = useModalToggle();
  const { isSupervisor } = useCurrentAccountRole();
  const isStripeDashboardLinkEnabled = useIsFeatureEnabled("stripe_dashboard_link");

  const [sendProcessorOnboardLink, { loading: onboardLinkLoading }] =
    useUpdatePalDisbursementProcessorMutation();

  if (loading) return <Panel.SideLoading />;

  if (error)
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );

  const {
    account,
    phoneNumber,
    languages,
    gender,
    genderText,
    email,
    dateOfBirth,
    insertedAt,
    status,
    verifiedAt,
    covidVaccinated,
    drugTestClean,
    vehicleModel,
    lastKnownLocation,
    lastKnownLocationUpdatedAt,
    suspendedPals,
    optOutVisitSuccessCall,
    palType,
  } = pal ?? {};

  const handleProcessorOnboardLink = async () => {
    try {
      const { data } = await sendProcessorOnboardLink({
        variables: { id: pal?.id as string, processor: DisbursementProcessor.Stripe },
      });
      showSuccessfulInvite(data?.updatePalDisbursementProcessor);
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  const showSuccessfulInvite = (data: string | null | undefined) => {
    data && toast.success("Stripe invite sent");
  };
  const isPalDisbursementProcessorStripe =
    pal?.account?.data?.billingProfile?.data?.palDisbursementProcessor ===
    DisbursementProcessor.Stripe;

  const { data: languagesList } = languages || {};
  const { id: accountId, fullName: accountName, palOnboarding } = account?.data || {};
  const palOnboardingId = palOnboarding?.data?.id;
  const palOnboardingZipcode = palOnboarding?.data?.zipcode;
  const palTypeCode = palType?.data?.code;

  const { address, lat, lng, id: locationId } = pal?.account?.data?.homeLocation?.data ?? {};

  const latestSuspendedPal = suspendedPals?.data?.slice(-1)[0];
  const suspendedUntil = latestSuspendedPal?.suspendedUntil;

  return (
    <Panel.Left>
      <Panel.Block>
        <Panel.Head>
          <Panel.Item marginBottom={0}>
            <Text bold as="div">
              Status: <ColoredStatus>{mapValueToLabel(statusOptions, status)}</ColoredStatus>
            </Text>
            {status === PalStatus.Suspended &&
              (suspendedUntil ? (
                <Tooltip
                  title={`Until ${moment(suspendedUntil).tz(moment.tz.guess()).format("LLL z")}`}
                >
                  <ColoredStatus fontSize="12px">
                    {moment(suspendedUntil).isAfter(moment())
                      ? `${moment(suspendedUntil).fromNow(true)} left`
                      : "until today"}
                  </ColoredStatus>
                </Tooltip>
              ) : (
                <ColoredStatus fontSize="12px">To be manually unsuspended</ColoredStatus>
              ))}
          </Panel.Item>
          <Panel.Edit onClick={onClickEdit}>Edit</Panel.Edit>
        </Panel.Head>
        <Panel.Section>
          <Panel.Item>
            <Text bold>Account ID</Text>
            <Text>{accountId || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Pal payment ID</Text>
            <Text>{id || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          {palOnboardingId && (
            <Panel.Item>
              <Text>
                <a
                  href={`/prospect-pals/${palOnboardingId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pal Onboarding
                </a>
              </Text>
            </Panel.Item>
          )}

          {isStripeDashboardLinkEnabled && isPalDisbursementProcessorStripe && (
            <Panel.Item>
              {onboardLinkLoading ? (
                <Loader />
              ) : (
                <ButtonLink onClick={handleProcessorOnboardLink}>Email Stripe invite</ButtonLink>
              )}
            </Panel.Item>
          )}

          <Panel.Item>
            <Text bold>Phone number</Text>
            <Text>{phoneNumber || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          {config.featureFlag?.palType && (
            <Panel.Item>
              <Text bold>Pal Type</Text>
              <Text>{palTypeCode ? fancyEnum(palTypeCode) : EMPTY_PLACEHOLDER}</Text>
            </Panel.Item>
          )}

          <Panel.Item>
            <Text bold>Email</Text>
            {email ? (
              <Text title={email} wrapString>
                {email}
              </Text>
            ) : (
              EMPTY_PLACEHOLDER
            )}
          </Panel.Item>

          <Panel.Item>
            <Text bold>Birth date</Text>
            <Text>{formatDateTime(dateOfBirth, { format: "L" })}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Home address</Text>
            <Text as="div">
              {address || EMPTY_PLACEHOLDER}
              <SeeMapLink location={{ lat: lat ?? "", lng: lng ?? "" }} />
              {palOnboardingZipcode ? ` (${palOnboardingZipcode})` : ""}

              {!!locationId && <OverrideLocationButton locationId={locationId ?? ""} />}
            </Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Last known location</Text>
            {lastKnownLocation ? (
              <>
                <SeeMapLink location={lastKnownLocation} />
                <Text>Updated: {formatDateTime(lastKnownLocationUpdatedAt)}</Text>
              </>
            ) : (
              <Text>{EMPTY_PLACEHOLDER}</Text>
            )}
          </Panel.Item>

          <Panel.Item>
            <Text bold>Signed up</Text>
            <Text>{formatDateTime(insertedAt)}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Approved</Text>
            <Text>{verifiedAt ? formatDateTime(verifiedAt) : PENDING_PLACEHOLDER}</Text>
          </Panel.Item>
        </Panel.Section>
        <Panel.Section>
          <Panel.Item>
            <Text bold>Language</Text>
            {languagesList?.length
              ? languagesList?.map((language: string, index: number) => (
                  <Text key={`language_${index}`}>
                    {mapValueToLabel(languageOptions, language)}
                  </Text>
                ))
              : EMPTY_PLACEHOLDER}
          </Panel.Item>

          <Panel.Item>
            <Text bold>Gender</Text>
            <Text>{formatGender(gender, genderText)}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Car</Text>
            <Text>
              {vehicleModel ? (
                <Link to={`/pals/${id}/vehicle`}>{vehicleModel}</Link>
              ) : (
                EMPTY_PLACEHOLDER
              )}
            </Text>
          </Panel.Item>

          {isSupervisor && (
            <Panel.Item>
              <Text bold>Member</Text>
              {accountId ? (
                <Link to={`/members/${accountId}`}>{accountName}</Link>
              ) : (
                EMPTY_PLACEHOLDER
              )}
            </Panel.Item>
          )}

          <Panel.Item>
            <Text bold>Drug test passed</Text>
            <Text>{drugTestClean ? "Yes" : "No"}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Covid-19 vaccinated</Text>
            <Text>{covidVaccinated ? "Yes" : "No"}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Can see VVs in app</Text>
            <Text>{pal && canPalSeeVirtualVisits(pal) ? "Yes" : "No"}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Opt-out Visit Success calls</Text>
            <Text>
              {optOutVisitSuccessCall?.data?.optOut && optOutVisitSuccessCall?.data?.optOutUntil ? (
                <Tooltip
                  title={`Until ${moment(optOutVisitSuccessCall.data.optOutUntil)
                    .tz(moment.tz.guess())
                    .format("LLL z")}`}
                >
                  <Text>
                    {moment(optOutVisitSuccessCall.data.optOutUntil).isSameOrBefore(moment(), "day")
                      ? "Until today"
                      : `${moment(optOutVisitSuccessCall.data.optOutUntil).fromNow(true)} left`}
                  </Text>
                </Tooltip>
              ) : optOutVisitSuccessCall?.data?.optOut ? (
                "Indefinitely"
              ) : (
                "No"
              )}
            </Text>
          </Panel.Item>

          <OverviewEditModal isOpen={showEditModal} toggle={toggleEditModal} />

          <Panel.Buttons>
            <Button block onClick={toggleEditModal}>
              Edit
            </Button>
          </Panel.Buttons>
        </Panel.Section>
      </Panel.Block>
    </Panel.Left>
  );
};

const ColoredStatus = styled.div<{ fontSize?: string }>`
  color: ${({ theme }) => theme.text.status};
  font-weight: normal;

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
`;

export default Overview;
