import React from "react";
import { Route, Switch } from "react-router-dom";

import Details from "./Details";
import List from "./List";

type Props = {
  accountId: string;
  businessId: string;
};

const BusinessPoliciesRoutes: React.FC<Props> = ({ accountId, businessId }) => {
  return (
    <Switch>
      <Route
        exact
        path="/businesses/:businessId/business-policies"
        component={() => <List accountId={accountId} businessId={businessId} />}
      />
      <Route
        exact
        path="/client-programs/:businessId/client-program-policies"
        component={() => <List accountId={accountId} businessId={businessId} />}
      />
      <Route
        exact
        path={"/businesses/:businessId/business-policies/:businessPolicyId"}
        component={Details}
      />
      <Route
        exact
        path={"/client-programs/:businessId/client-program-policies/:businessPolicyId"}
        component={Details}
      />
    </Switch>
  );
};

export default BusinessPoliciesRoutes;
