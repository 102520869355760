import * as yup from "yup";

import { googleLocationSchema } from "components/GoogleLocationSearch/schema";
import { Gender, Language, Location } from "generated/types";

export interface FormValues {
  fullName: string;
  address: Partial<Location>;
  phoneNumber: string;
  dateOfBirth: string | null;
  fieldOfStudy: string;
  school: string;
  email: string;
  gender: Gender | null;
  palTypeId: string;
  genderText?: string;
  languages: Language[];
  drugTestClean: boolean;
  testAccount: boolean;
  bio: string;
  covidVaccinated: boolean;
  optOutVisitSuccessCall: {
    indefinitely?: boolean | null;
    optOut?: boolean | null;
    optOutUntil?: string | null;
  };
}

export const schema = yup.object().shape({
  fullName: yup.string().nullable().required("Required"),
  address: googleLocationSchema,
  phoneNumber: yup.string().nullable().required("Required"),
  dateOfBirth: yup.string().nullable(),
  palTypeId: yup.string().nullable(),
  fieldOfStudy: yup.string().nullable(),
  email: yup.string().nullable().required("Required"),
  gender: yup.string().nullable().required("Required"),
  genderText: yup.string().nullable(),
  school: yup.string().nullable(),
  languages: yup.array().of(yup.string().nullable()).nullable().required("Required"),
  drugTestClean: yup.boolean().nullable(),
  testAccount: yup.boolean().nullable(),
  bio: yup.string().nullable(),
  covidVaccinated: yup.boolean().nullable(),
  optOutVisitSuccessCall: yup.object().shape({
    optOut: yup.boolean().nullable(),
    indefinitely: yup.boolean().nullable(),
    optOutUntil: yup.string().when("indefinitely", {
      is: false,
      then: yup.string().nullable().required("Required"),
      otherwise: yup.string().nullable(),
    }),
  }),
});
