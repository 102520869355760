import { ApolloError } from "@apollo/client";
import { getDistance } from "geolib";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import VisitActionsMenu from "components/ActionsMenu/VisitActionsMenu";
import Actions from "components/ActionsMenu/VisitActionsMenu/Actions";
import { useVisitActions } from "components/ActionsMenu/VisitActionsMenu/useVisitActions";
import { StatusCell } from "components/DataTables/VisitsTable/StatusCell";
import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import VerticalIconsList from "components/VerticalIconsList";
import VisitRecurrence from "components/VisitRecurrence";
import { DEFAULT_TIMEZONE } from "constants/date";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { VisitMenuActions } from "constants/visit";
import {
  GeoPoint,
  Pagination,
  Visit,
  VisitAction,
  VisitRequirementName,
  VisitState,
} from "generated/types";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import formatScheduledFor from "utils/formatScheduledFor";
import { nonNull } from "utils/nonNull";
import { getDuration as formatDurationHours } from "utils/numbers/durations";

type Props = {
  loading: boolean;
  data?: Visit[];
  coordinates?: GeoPoint | null;
  pagination?: Pagination | null;
  error?: ApolloError;
};

const PalAppVisitsTable = ({ data, loading, error, pagination, coordinates }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { visitId, action, warmTransferData, setAction } = useVisitActions();

  const columns: Column<Visit>[] = useMemo(() => {
    return [
      {
        header: { title: "Status" },
        dataField: "state",
        formatter({ state, isVirtual, events, visitFlags, isPreviouslyMissed }) {
          const isMemberConfirmed = events?.data?.some(
            (event) =>
              event?.__typename === "VisitEvent" &&
              event?.action?.toUpperCase() === VisitAction.MemberConfirmed
          );

          return (
            <StatusCell
              state={state}
              visitFlags={nonNull(visitFlags?.data)}
              isPreviouslyMissed={isPreviouslyMissed}
              isMemberConfirmed={isMemberConfirmed}
              isVirtual={isVirtual}
            />
          );
        },
      },
      {
        header: {
          title: "Scheduled For",
        },
        dataField: "scheduledFor",
        formatter(visit) {
          return (
            <>
              {formatScheduledFor(
                visit.scheduledFor,
                visit.location?.data?.timezone || DEFAULT_TIMEZONE
              )}
              {typeof visit.estimatedDuration === "number" && (
                <>
                  <br /> {formatDurationHours(visit.estimatedDuration)}
                </>
              )}
            </>
          );
        },
      },
      {
        header: { title: "Tasks" },
        dataField: "tasks.data",
        formatter(visit) {
          return <VerticalIconsList icons={nonNull(visit.tasks?.data)} />;
        },
      },
      {
        header: { title: "Business" },
        dataField: "account.data",
        formatter(visit) {
          return (
            <Link to={`/members/${visit.account?.data?.id}`}>{visit.account?.data?.fullName}</Link>
          );
        },
      },
      {
        header: { title: "Papa" },
        dataField: "papa.data",
        formatter(visit) {
          const papa = papaConcealed(visit.papa?.data);

          if (papa) {
            return <Link to={`/papas/${papa.id}`}>{papa.fullName}</Link>;
          }
          return EMPTY_PLACEHOLDER;
        },
      },
      {
        header: { title: "Pal" },
        dataField: "pal.data",
        formatter(visit) {
          if (visit.state === VisitState.Pending || !visit.pal?.data) {
            return EMPTY_PLACEHOLDER;
          }

          return <Link to={`/pals/${visit.pal.data.id}`}>{visit.pal.data.fullName}</Link>;
        },
      },
      {
        header: { title: "Recurrence" },
        dataField: "recurrence.data.config",
        formatter(visit) {
          return (
            (visit.recurrence?.data &&
              VisitRecurrence({
                recurrence: visit.recurrence.data,
                visit,
              })) ||
            "no recurrence"
          );
        },
      },
      {
        header: { title: "Location" },
        dataField: "location.data",
        formatter(visit) {
          if (!visit.location?.data) return "Empty";
          const {
            city,
            houseNumber,
            lat,
            lng,
            state: locationState,
            streetName,
            zipcode,
          } = visit.location.data;
          return (
            <a
              href={`https://www.google.com/maps/?q=${lat},${lng}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {houseNumber || streetName ? (
                <>
                  {[houseNumber, streetName].join(" ")}
                  <br />
                </>
              ) : (
                ""
              )}
              {city}, {locationState} {zipcode}
            </a>
          );
        },
      },
      {
        header: { title: "County" },
        dataField: "location.county",
        formatter(visit) {
          return visit.location?.data?.county ?? EMPTY_PLACEHOLDER;
        },
      },
      {
        header: { title: "Miles away from pal's home" },
        dataField: "distanceFromPal",
        formatter({ location }) {
          const papaLat = location?.data?.lat ? parseFloat(location?.data?.lat) : null;
          const papaLng = location?.data?.lng ? parseFloat(location?.data?.lng) : null;

          const palLat = coordinates?.lat ?? null;
          const palLng = coordinates?.lng ?? null;

          if (!(papaLat && papaLng && palLat && palLng)) {
            return EMPTY_PLACEHOLDER;
          }

          const visitDistance = Math.round(
            getDistance(
              { latitude: papaLat, longitude: papaLng },
              { latitude: palLat, longitude: palLng }
            ) * 0.00062137
          );

          return `${visitDistance} miles`;
        },
      },
      {
        header: { title: "Language" },
        dataField: "language",
        formatter({ requirements }) {
          return (
            nonNull(requirements).find(({ name }) => name === VisitRequirementName.Language)
              ?.details ?? EMPTY_PLACEHOLDER
          );
        },
      },
      {
        header: { title: "Car required" },
        dataField: "carRequired",
        formatter({ requirements }) {
          return (
            nonNull(requirements).find(({ name }) => name === VisitRequirementName.Vehicle)
              ?.details ?? "No"
          );
        },
      },
      {
        header: { title: "Vehicle type required" },
        dataField: "vehicleTypeRequired",
        formatter({ requiredVehicleType }) {
          return requiredVehicleType ?? "No";
        },
      },
      {
        header: { title: "Vaxx required" },
        dataField: "vaxxRequired",
        formatter({ requirements }) {
          return (
            nonNull(requirements).find(({ name }) => name === VisitRequirementName.Vaccine)
              ?.details ?? "No"
          );
        },
      },
      {
        header: { title: "Drug test required" },
        dataField: "drugTestRequired",
        formatter({ requirements }) {
          return (
            nonNull(requirements).find(({ name }) => name === VisitRequirementName.DrugTest)
              ?.details ?? "No"
          );
        },
      },
      {
        header: { title: "Gender preferred" },
        dataField: "genderPreferred",
        formatter({ papa }) {
          return papa?.data?.genderPreference ?? EMPTY_PLACEHOLDER;
        },
      },
      {
        header: { title: "Favorite pals only" },
        dataField: "favoritePalsOnly",
        formatter({ favoritePalsOnly }) {
          return favoritePalsOnly ? "Yes" : "No";
        },
      },
      {
        header: { title: "Blocked by member" },
        dataField: "blockedByMember",
        formatter({ papa }) {
          return nonNull(papa?.data?.blockedPals?.data).find(({ id: palId }) => palId === id)
            ? "Yes"
            : "No";
        },
      },
      {
        header: { title: "" },
        dataField: "actions",
        cellProps: {
          fixed: true,
          align: "center",
        },
        formatter(visit) {
          return <VisitActionsMenu setAction={setAction} visit={visit} isListMenu />;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SmartTable<Visit>
        keyField="id"
        localStorageKey="visits"
        data={data}
        pagination={pagination}
        loading={loading}
        error={error}
        rowProps={({ state }) =>
          state
            ? {
                statusLegend: state,
              }
            : {}
        }
        columns={columns}
      />

      <Actions
        action={action}
        visitId={visitId}
        warmTransferData={warmTransferData}
        onClose={() => setAction(VisitMenuActions.NoAction)}
      />
    </>
  );
};

export default PalAppVisitsTable;
