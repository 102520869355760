import { gql } from "@apollo/client";

export const SURVEYS_SEARCH = gql`
  query SurveysList(
    $filter: [SurveyFilter]
    $pagination: PaginationInput
    $sorting: SurveySorting
  ) {
    surveys(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        name
        url
        insertedAt
        updatedAt
      }
      pagination {
        limit
        totalCount
      }
    }
  }
`;
