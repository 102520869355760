import { object, string } from "yup";

import { PapaChangeStatusReasonEnum, PapaState } from "generated/types";

export interface Schema {
  state: PapaState | null;
  changeStatusReason: PapaChangeStatusReasonEnum | null;
  comment: string;
}

export const schema = object().shape({
  state: string().nullable().required("Please select a status"),
  changeStatusReason: string().nullable().required("Please select a reason"),
  comment: string().required("Comment is required"),
});
