import React from "react";
import styled from "styled-components";

import Text from "components/Text";

interface Props {
  totalCount?: number | null;
}

export const ItemsCount: React.FC<Props> = ({ totalCount }) => {
  if (!totalCount) return null;

  return (
    <Container>
      <Text size="large" bold>
        Total: {totalCount.toLocaleString("en-US")}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  margin-bottom: 20px;
`;
