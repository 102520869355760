import { gql } from "@apollo/client";

export const UPDATE_SERVICE_REQUEST_RATING = gql`
  mutation UpdateServiceRequestRating($id: ID!, $input: ServiceRequestInput!) {
    updateServiceRequest(id: $id, input: $input) {
      data {
        rating
        id
      }
    }
  }
`;
