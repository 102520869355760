import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useFormikContext } from "formik";
import React from "react";

import CreditCardSelect from "components/CreditCardSelect";
import Form from "components/Form";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import PlanBlock from "components/PlanBlock";
import Select from "components/Select";
import { discountOptions } from "constants/discount";
import { FeatureFlags } from "constants/featureFlags";
import { VisitDiscountType } from "generated/types";
import { getOptionByValue } from "utils/select";

import { Values } from "../types";

type Props = {
  sectionNumber?: number;
};

const Payment = ({ sectionNumber }: Props) => {
  const { values } = useFormikContext<Values>();
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);

  const discountType = values?.discount?.type || "";

  const selectedDiscountOption = getOptionByValue(discountOptions, discountType);

  return (
    <Form.Panel number={sectionNumber} headerText="Payments">
      <div>
        {!hideCreditCardFields && (
          <>
            <ScheduleVisitLabel>Card details</ScheduleVisitLabel>

            <Form.Group controlId="credit card">
              <Form.Label>Enter credit card</Form.Label>
              <CreditCardSelect name="cardId" papaId={values.papaId} accountId={values.accountId} />
            </Form.Group>
          </>
        )}
      </div>

      <Form.Group controlId="discount">
        <Form.Label>Enter discount (if applicable)</Form.Label>
        <Form.GroupInline>
          <Form.Inline>
            <Select
              name="discount.type"
              defaultValue={selectedDiscountOption}
              options={discountOptions}
              isSearchable={false}
            />
          </Form.Inline>
          <Form.Inline>
            {values?.discount?.type === VisitDiscountType.FixedAmount ? (
              <Form.InputCurrency name="discount.valueFixed" placeholder="$0.00" noDecimal />
            ) : (
              <Form.Input type="number" name="discount.valuePercentage" placeholder="1" />
            )}
          </Form.Inline>
        </Form.GroupInline>
        <PlanBlock accountId={values.accountId} />
      </Form.Group>
    </Form.Panel>
  );
};

export default Payment;
