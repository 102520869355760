import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import capitalize from "lodash/capitalize";
import omit from "lodash/omit";
import moment from "moment-timezone";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Drawer } from "components/Drawer";
import { FlexRow as Flex } from "components/Flex";
import Form from "components/Form";
import { Loader } from "components/Loader";
import { ENROLL_PAPA_TEXT_MESSAGING, MESSAGE_SERVICES } from "components/PapaTextMessaging/gql";
import Text from "components/Text";
import { generateSpecialConnsiderationsInput } from "constants/specialConsiderations";
import {
  EligibleLifeSingleResult,
  Gender,
  Relationship,
  useSpecialConsiderationsQuery,
} from "generated/types";
import { useAcceptEligibleLifeMutation } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";
import { concatFirstLastNames } from "utils/strings/concatFirstLastNames";

import { GET_ELIGIBLE_MEMBERS_LIST } from "../gql";
import { AdditionalDetails } from "./AdditionalDetails";
import { ContactInformation } from "./ContactInformation";
import { PersonalInformation } from "./PersonalInformation";
import { GET_ELIGIBLE_MEMBER } from "./gql";
import { Schema, schema } from "./schema";
import { useInitialValues } from "./useInitialValues";

interface Data {
  eligibleLife: EligibleLifeSingleResult;
}
interface Props {
  id: string | null;
  setDirty: (state: boolean) => void;
  closeModal: () => void;
}

const EnrollMemberForm: FunctionComponent<Props> = ({ id, setDirty, closeModal }) => {
  const history = useHistory();
  const { page, limit } = usePageInfo();
  const { data: specialConsiderationsData, loading: specialConsiderationsLoading } =
    useSpecialConsiderationsQuery();
  const specialConsiderations = nonNull(specialConsiderationsData?.specialConsiderations?.data);

  const { data: EligibleLifeSingleResult, loading: dataLoading } = useQuery<Data>(
    GET_ELIGIBLE_MEMBER,
    {
      variables: {
        id,
      },
    }
  );
  const { initialValues, loading: googleLoading } = useInitialValues(
    EligibleLifeSingleResult?.eligibleLife?.data
  );

  const [accept, { loading: acceptLoading }] = useAcceptEligibleLifeMutation({
    refetchQueries: [
      {
        query: GET_ELIGIBLE_MEMBERS_LIST,
        variables: { pagination: { page, limit } },
      },
    ],
  });
  const [enrollPapaTextMessaging, { loading: enrollLoading }] = useMutation(
    ENROLL_PAPA_TEXT_MESSAGING
  );

  const { data, loading: messageServiceLoading } = useQuery(MESSAGE_SERVICES);
  const messageServiceId = data?.messageServices?.data?.[0]?.id;

  const handleSubmit = async (formData: Schema) => {
    const {
      location,
      birthDate: formBirthDate,
      enrollTextMessaging,
      genderPreference,
      ...input
    } = omit(formData, [
      "hasPets",
      "hasCat",
      "hasDog",
      "hasOtherPet",
      "usesWheelchair",
      "smokes",
      "hearingImpaired",
      "visuallyImpaired",
      "cantDrive",

      "doesNotHavePhoneNumber",
      "doesNotHaveFixedPhoneNumber",
      "doesNotHaveEmail",
    ]);

    const birthDate =
      formBirthDate && moment(formBirthDate).isValid()
        ? moment(formBirthDate).format("YYYY-MM-DD")
        : null;

    const specialConsiderationsInput = generateSpecialConnsiderationsInput(
      specialConsiderations,
      formData
    );

    try {
      const { data } = await accept({
        variables: {
          // @ts-ignore
          id,
          input: {
            // @ts-ignore
            ...input,
            // @ts-ignore
            specialConsiderations: specialConsiderationsInput,
            birthDate,
            relationship: Relationship.Client,
            address: location?.address || null,
            city: location?.city || null,
            county: location?.county || null,
            countryIso: location?.countryIso || null,
            state: location?.state || null,
            zipcode: location?.zipcode || null,
            lng: location?.lng || null,
            lat: location?.lat || null,
            genderPreference: genderPreference === Gender.Diverse ? null : genderPreference,
          },
        },
      });

      const papa = data?.acceptEligibleLife?.data?.papa?.data;
      const papaId = papa?.id as string;

      if (data?.acceptEligibleLife?.data) {
        toast.success("Member is enrolled");
      } else {
        throw new Error("Something is wrong");
      }

      if (enrollTextMessaging) {
        try {
          const { data: enrollPapaTextMessagingData } = await enrollPapaTextMessaging({
            variables: {
              papaId,
              messageServiceId,
            },
          });

          if (enrollPapaTextMessagingData?.createMessageServiceAuthorization?.data) {
            toast.success("Confirmation text message has been sent to the member");
          } else {
            throw new Error("Something is wrong on sending the confirmation text message");
          }
        } catch (error) {
          toast.error((error as Error).message);
        }
      }

      closeModal();
      // the query param startVirtualCall=true will do some magic and start a virtual call
      // see tests/components/VirtualCall.test.tsx for an illustrative test case
      history.push(`/papas/${papaId}/assessments?startVirtualCall=true`);
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (dataLoading || messageServiceLoading || googleLoading || specialConsiderationsLoading)
    return <Loader />;

  const authorizedUser = EligibleLifeSingleResult?.eligibleLife?.data?.authorizedUser?.data;

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ dirty }) => {
        setDirty(dirty);

        return (
          <FormikForm>
            <Drawer.Container>
              {authorizedUser && (
                <AuthorizedUserSection>
                  <Information>
                    <Text color="primary" bold size="large">
                      Please verify you are speaking to the Authorized User before you continue.
                    </Text>
                    <Text size="small">
                      Once verified, you may begin with normal enrollment procedures.
                    </Text>
                  </Information>
                  <Flex>
                    <InfoColumn>
                      <Text>Authorized User Name:</Text>
                      <Text color="primary" bold size="large">
                        {concatFirstLastNames(authorizedUser.firstName, authorizedUser.lastName)}
                      </Text>
                    </InfoColumn>
                    <InfoColumn>
                      <Text>Relationship to Beneficiary:</Text>
                      <Text color="primary" bold size="large">
                        {capitalize(authorizedUser.relationship ?? "")}
                      </Text>
                    </InfoColumn>
                  </Flex>
                </AuthorizedUserSection>
              )}

              <PersonalInformation />
              <ContactInformation />
              <AdditionalDetails />
            </Drawer.Container>

            <Drawer.Footer>
              <Form.SubmitButton isSubmitting={acceptLoading || enrollLoading} action noContent>
                Enroll member and start virtual call
              </Form.SubmitButton>
            </Drawer.Footer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

const Information = styled.div`
  margin-bottom: 1rem;
`;

const InfoColumn = styled.div`
  & + & {
    margin-left: 2rem;
  }
`;

const AuthorizedUserSection = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
`;

export default EnrollMemberForm;
