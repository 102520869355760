import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { VISIT_COMMENT_TYPES, VisitCommentType, visitCommentOptions } from "constants/visitComment";
import { mapSelectValuesToOptions } from "utils/select";
import { getCommentTypeAndContent } from "utils/strings/getCommentTypeAndContent";

import { CREATE_VISIT_COMMENT, UPDATE_VISIT_COMMENT } from "./gql";
import { Schema, schema } from "./schema";

export interface VisitComment {
  id?: string | null;
  content?: string | null;
}

interface Props {
  visitId: string;
  comment: VisitComment;
  closeModal: () => void;
  onFinish?: () => void;
}

interface Variables {
  id?: string;
  input: {
    visitId: string;
    content: string;
  };
}

const CommentForm: FunctionComponent<Props> = ({ visitId, comment, closeModal, onFinish }) => {
  const [type, text] = getCommentTypeAndContent(comment.content || "", {
    commentTypes: VISIT_COMMENT_TYPES,
  });
  const initialValues: Schema = {
    type: type === EMPTY_PLACEHOLDER ? VisitCommentType.Other : (type as VisitCommentType),
    text,
  };
  const mutation = comment.id ? UPDATE_VISIT_COMMENT : CREATE_VISIT_COMMENT;
  const [saveComment] = useMutation(mutation);

  const handleSubmit = async ({ type, text }: Schema) => {
    const variables: Variables = {
      input: {
        visitId,
        content: type ? `${type}: ${text}` : text,
      },
    };
    if (comment.id) {
      variables.id = comment.id;
    }
    try {
      const { data } = await saveComment({ variables });

      if (data?.createVisitComment?.data?.id || data?.updateVisitComment?.data?.id) {
        toast.success(`Visit comment was ${comment.id ? "updated" : "added"} with success!`);

        if (onFinish) {
          onFinish();
        }

        closeModal();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label required>Select comment type</Form.Label>
              <Select
                name="type"
                defaultValue={mapSelectValuesToOptions<VisitCommentType>(
                  initialValues.type,
                  visitCommentOptions
                )}
                options={visitCommentOptions}
                isSearchable={false}
                placeholder="Select type"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Type in your comment here</Form.Label>
              <Form.TextArea style={{ height: "250px" }} fullWidth aria-label="text" name="text" />
            </Form.Group>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button disabled={isSubmitting} type="submit" variant="primary">
              Save
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};

export default CommentForm;
