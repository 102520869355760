import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import { ModalLayout } from "components/Modal/ModalLayout";
import { GeoPoint, Language, VisitListResult, VisitState } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";
import { VisitFiltersModal } from "modals/VisitFiltersModal";
import { nonNull } from "utils/nonNull";

import { CoordinatesForm } from "./CoordinatesForm";
import PalAppVisitsTable from "./PalAppVisitsTable";
import { PalAppVisitsTabs } from "./PalAppVisitsTabs";
import { VISITS_SEARCH_AS_PAL } from "./gql";

interface Props {
  palLanguages?: Language[] | null;
  accountId?: string;
  palId?: string;
  lastKnownLocation?: GeoPoint | null;
}

interface Data {
  visitsSearchAsPal: VisitListResult;
}

const Debug = ({ palLanguages, accountId, lastKnownLocation }: Props) => {
  const { page, limit } = usePageInfo();
  const { lat, lng } = useUrlQuery();
  const { id, debugTab } = useParams<{ id: string; debugTab: string }>();
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();

  const coordinates = {
    lat: lat ? parseFloat(lat as string) : lastKnownLocation?.lat,
    lng: lng ? parseFloat(lng as string) : lastKnownLocation?.lng,
  };

  const hasNoCoordinates = !coordinates.lat || !coordinates.lng;

  const withinRadius = 120;
  const dynamicFilters = {};

  const availableFilters = {
    state: {
      in: [VisitState.Pending],
    },

    isVirtual: { eq: false },
    papa: {
      languagePreferenceIn: palLanguages ?? [Language.English],
    },
    withinRadius,
    ...dynamicFilters,
  };

  const upcomingFilters = {
    state: {
      in: [VisitState.Accepted, VisitState.Confirmed, VisitState.Started, VisitState.Enroute],
    },
    scheduledFor: {
      gt: moment().startOf("day").toISOString(),
    },
  };

  const myRequestsFilters = {
    state: {
      in: [VisitState.Pending],
    },
    papa: {
      languagePreferenceIn: palLanguages ?? [Language.English],
    },
    ...{ invitees: { accountIdEq: accountId } },
  };

  const { data, loading, error } = useQuery<Data>(VISITS_SEARCH_AS_PAL, {
    variables: {
      palId: id,
      coordinates,
      filter:
        debugTab === "available-visits"
          ? availableFilters
          : debugTab === "upcoming-visits"
          ? upcomingFilters
          : debugTab === "my-requests"
          ? myRequestsFilters
          : undefined,
      sorting: {
        scheduledFor: "ASC",
      },
      pagination: { page, limit },
    },
    skip: hasNoCoordinates,
  });

  const handleFiltersChange = () => {
    toggleFiltersModal();
  };

  return (
    <>
      <Helmet>
        <title>Pal Debug - Papa Admin</title>
      </Helmet>

      <CoordinatesForm />

      <PalAppVisitsTabs filters={{ availableFilters, upcomingFilters, myRequestsFilters }} />

      <PalAppVisitsTable
        data={nonNull(data?.visitsSearchAsPal?.data)}
        coordinates={coordinates}
        loading={loading}
        pagination={data?.visitsSearchAsPal?.pagination}
        error={error}
      />

      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Visits Filter"
        isOpen={isFiltersModalOpen}
      >
        <VisitFiltersModal onSubmit={handleFiltersChange} />
      </ModalLayout>
    </>
  );
};

export default Debug;
