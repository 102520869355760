import * as Yup from "yup";

import { taskObjectivesSchema } from "components/TaskObjectives/constants";

import { AdditionalLocationScheduleInput } from "../types";

export const visitSchema = Yup.object().shape({
  accountId: Yup.string(),
  accountType: Yup.string(),
  cardId: Yup.string().when("isPaymentValid", {
    is: (value) => !value,
    then: Yup.string().required("Required"),
  }),
  estimatedDuration: Yup.number()
    .nullable()
    .when("isVirtual", {
      is: true,
      then: Yup.number().min(30, "Minimum estimated duration is 30 minutes"),
      otherwise: Yup.number().min(60, "Minimum estimated duration is one hour"),
    })
    .when(
      "maxMinutesPerVisit",
      (maxMinutesPerVisit: number | null | undefined, schema: Yup.NumberSchema) =>
        maxMinutesPerVisit != null
          ? schema.max(
              maxMinutesPerVisit,
              `Maximum estimated duration is ${maxMinutesPerVisit} minutes`
            )
          : schema
    ),
  freq: Yup.string().nullable(),
  byDay: Yup.array().of(Yup.string()).nullable(),
  validUntil: Yup.string().nullable(),
  count: Yup.number().nullable(),
  isVirtual: Yup.bool().required(),
  location: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
      timezone: Yup.string(),
    })
    .nullable()
    .required("Required"),
  favoritePalsOnly: Yup.boolean(),
  invitePalIds: Yup.array().of(Yup.string()).nullable(),
  papaId: Yup.string().required("Required"),
  selectedRecommendedDate: Yup.string().when("scheduledForDay", {
    is: (val) => !val,
    then: Yup.string().required("Required"),
  }),
  scheduledForDay: Yup.string(),
  scheduledForTime: Yup.string().required("Required"),
  surveyIds: Yup.array().of(Yup.string()),
  taskIds: Yup.array().of(Yup.string()).required("Please select at least one task"),
  hasRelocationTasks: Yup.boolean(),
  hasGroceryTask: Yup.boolean(),
  hasMedicationTask: Yup.boolean(),
  destination: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
      timezone: Yup.string(),
    })
    .nullable()
    .when(["hasRelocationTasks", "additionalLocations"], {
      is: (hasRelocationTasks, additionalLocations) => {
        // IF it's not a transportation visit
        // OR there's at least one additional location that has a description
        // THEN destination can be null
        return (
          !hasRelocationTasks ||
          (additionalLocations &&
            additionalLocations.some(
              (location: AdditionalLocationScheduleInput) =>
                location.description && location.description.trim() !== ""
            ))
        );
      },
      then: Yup.object().nullable(),
      otherwise: Yup.object().required("Required"),
    }),
  objectives: taskObjectivesSchema,
  note: Yup.string(),
  overrideBusinessPolicy: Yup.boolean(),
  obpReasonDescription: Yup.string().when("overrideBusinessPolicy", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  delivery: Yup.boolean().nullable(),
  visibleToPalApp: Yup.bool().required(),
  acceptsThirdPartyTransportation: Yup.boolean().nullable(),
  applyToFutureRecurrences: Yup.boolean()
    .nullable()
    .when(
      "scheduledForDay",
      (scheduledForDay: string | undefined | null, schema: Yup.BooleanSchema) =>
        !!scheduledForDay
          ? schema
          : schema.when(
              "scheduledForTime",
              (scheduledForTime: string | undefined | null, schema: Yup.BooleanSchema) =>
                !!scheduledForTime ? schema : schema.required("Required")
            )
    ),
});
