import { object, string } from "yup";

export interface FormValues {
  completedAtDay: string;
  completedAtTime: string;
}

export const schema = object().shape({
  completedAtDay: string().required("Completed at date is required"),
  completedAtTime: string().required("Completed at time is required").nullable(),
});
