import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";

import { GET_LOCATIONS } from "components/LocationForm/gql";
import { LOCATIONS_SEARCH } from "components/LocationSearch/gql";
import ConfirmModal from "components/Modal/ConfirmModal";
import { Location } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { GET_PAPA_DETAILS } from "pages/Papas/gql";

import { DELETE_LOCATION } from "./gql";

interface Props {
  location: Location | null;
  toggle: () => void;
  isOpen: boolean;
  papaId?: string;
}

export const DeleteLocationModal: React.FC<Props> = ({ location, isOpen, toggle, papaId }) => {
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const refetch = papaId
    ? [
        {
          query: GET_PAPA_DETAILS,
          variables: { id: papaId },
        },
        {
          query: LOCATIONS_SEARCH,
          variables: {
            filter: {
              deletedAt: { eq: null },
              papa: {
                idEq: papaId,
              },
            },
            pagination: {
              limit: 50,
            },
          },
        },
      ]
    : [
        {
          query: GET_LOCATIONS,
          variables: {
            filter: { deletedAt: { eq: null } },
            pagination: { beforeCursor, afterCursor, limit },
            sorting: { insertedAt: "DESC" },
          },
        },
      ];
  const [deleteObjective, { loading }] = useMutation(DELETE_LOCATION, {
    refetchQueries: refetch,
  });

  const onDelete = async () => {
    try {
      const { data } = await deleteObjective({
        variables: { id: location?.id },
      });

      if (data?.destroyLocation?.data?.id) {
        toggle();
        toast.success("Location was successfully removed");
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (!location) return null;

  return (
    <ConfirmModal
      isOpen={isOpen}
      loading={loading}
      header="Delete Location"
      onCancel={toggle}
      onConfirm={onDelete}
    >
      Are you sure you want to delete location
      <strong>&nbsp;{location.address}</strong>
    </ConfirmModal>
  );
};
