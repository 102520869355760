import { Formik, Form as FormikForm } from "formik";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { Drawer } from "components/Drawer";
import { FlexRow as Flex } from "components/Flex";
import Form from "components/Form";
import { Loader } from "components/Loader";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { HIGH, LOW, MEDIUM, assessmentMessage } from "constants/assessments";
import {
  AssessmentQuestionFragment,
  AssessmentSubmissionFragment,
  useAssessmentAnswersMutation,
  useCreateSubmissionAnswerMutation,
} from "generated/types";
import { useGetSurvey } from "hooks/useGetSurvey";
import { getSections } from "modals/AssessmentsQuestions/getSections";
import {
  hardcodedQuestionRequired,
  lastQuestionsPositions,
  questionsFlow,
} from "modals/AssessmentsQuestions/hardcodedQuestions";
import { ASSESSMENTS_ANSWERS } from "pages/Papas/Details/Assessments/gql";
import { AssessmentType, HardCodedQuestionsRequired, QuestionsFlow } from "types/assessments";
import {
  checkIncompleteAssessment,
  formatAnswersToInput,
  generateInitialValues,
  generateSchema,
  getHardcodedQuestionsRequired,
  getPapaSocialIndexResult,
} from "utils/helpers/assessments";
import { nonNull } from "utils/nonNull";

import Question from "./Question";
import SectionFlow from "./SectionFlow";
import { groups } from "./hardcodedQuestions";
import { AssessmentsSurveyTitles, FormValues } from "./models";

interface Props {
  papaId: string;
  type: AssessmentType;
  onClose: () => void;
  onSubmit: () => void;
}

export const AssessmentsQuestionsPapaSocialIndex: React.FC<Props> = ({
  papaId,
  type,
  onClose,
  onSubmit,
}) => {
  const [lastQuestionsVisibility, setLastQuestionsVisibility] = useState(false);
  const [assessment, setAssessment] = useState<AssessmentSubmissionFragment | null>(null);
  const {
    error,
    loading,
    data: { survey },
  } = useGetSurvey({ papaId, type });

  const [submitAssessmentAnswers, { loading: isSaving }] = useAssessmentAnswersMutation({
    refetchQueries: [{ query: ASSESSMENTS_ANSWERS, variables: { id: papaId } }],
  });
  const [createSubmissionAnswer] = useCreateSubmissionAnswerMutation({
    refetchQueries: [{ query: ASSESSMENTS_ANSWERS, variables: { id: papaId } }],
  });

  if (error) {
    return <QueryErrors error={error} />;
  }

  if (loading) return <Loader />;

  if (!survey) {
    return (
      <Drawer.Container>
        <Text>Survey not found</Text>
      </Drawer.Container>
    );
  }

  const questions = nonNull(survey?.questions?.data);
  const initialValues = generateInitialValues(questions);
  const schema = generateSchema(questions, getHardcodedQuestionsRequired(type));

  const handleSubmit = async (values: FormValues) => {
    const answers = formatAnswersToInput(values);

    try {
      const { data: submitAssessmentAnswersData } = await submitAssessmentAnswers({
        variables: {
          input: {
            answers,
            papaId,
            surveyId: survey?.id as string,
          },
        },
      });
      const assessmentResult = submitAssessmentAnswersData?.submitSurveyAnswers?.data;

      if (assessmentResult?.id) {
        if (checkIncompleteAssessment(assessmentResult)) {
          toast.success("The assessment answers were sent");
          onSubmit();
        } else {
          setAssessment(assessmentResult);
          setLastQuestionsVisibility(true);
        }
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const handleLastQuestionsSubmit = async (values: FormValues) => {
    try {
      const answers = Object.entries(values)
        .filter(([, value]) => value !== null)
        .map(([question, answerId]) => {
          const questionId = question.replace("question-", "");
          const option = nonNull(
            questions.find((question) => question.id === questionId)?.options?.data
          ).find((answer) => answer.id === answerId);
          return { questionId, answer: answerId, position: option?.position! };
        });

      for (const answer of answers) {
        if (typeof answer.answer === "string") {
          await createSubmissionAnswer({
            variables: {
              input: {
                surveyQuestionId: answer.questionId,
                surveySubmissionId: assessment?.id!,
                optionPosition: answer.position,
                answer: answer.answer,
              },
            },
          });
        }
      }

      toast.success("The assessment answers were sent");
      onSubmit();
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const result = assessment && getPapaSocialIndexResult(assessment);

  if (lastQuestionsVisibility && result && assessment) {
    return (
      <LastQuestions
        type={type}
        result={result}
        assessment={assessment}
        questions={questions}
        questionsFlow={questionsFlow}
        onSubmit={handleLastQuestionsSubmit}
      />
    );
  }

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <FormikForm noValidate>
        <SectionFlow
          type={type}
          questions={questions}
          questionsFlow={questionsFlow}
          hardCodedQuestionsRequired={hardcodedQuestionRequired}
          handleClose={handleClose}
          isSaving={isSaving}
          groups={groups}
        />
      </FormikForm>
    </Formik>
  );
};

type LastQuestionsProps = {
  type: AssessmentType;
  result: keyof typeof assessmentMessage;
  assessment: AssessmentSubmissionFragment;
  questions: AssessmentQuestionFragment[];
  questionsFlow: QuestionsFlow;
  onSubmit: (values: FormValues) => void;
};

const LastQuestions = ({
  type,
  result,
  assessment,
  questions,
  questionsFlow,
  onSubmit,
}: LastQuestionsProps) => {
  const firstQuestionAnswer = nonNull(assessment.answers?.data)
    .filter((answer) => `P${answer.question?.data?.position}` === "P1")
    .map((answer) => answer.answer)
    .join(", ");

  const groups = [
    {
      id: "S1",
      questions: ["P29", "P30"],
    },
  ];

  const lastQuestions = questions.filter((question) =>
    lastQuestionsPositions.includes(`P${question?.position}`)
  );

  const initialValues = generateInitialValues(lastQuestions);
  const schema = generateSchema(lastQuestions, getHardcodedQuestionsRequired(type));
  const title = AssessmentsSurveyTitles[type];

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      <FormikForm noValidate>
        <Drawer.Container>
          <Text as="h3" color="primary" size="largex" marginBottom="1rem">
            {title}
          </Text>
          <Flex flexDirection="row">{assessmentMessage[result]}</Flex>
          <Flex flexDirection="row">
            Reminder, the member expressed needs of: {firstQuestionAnswer}
          </Flex>

          <LastQuestionsSection
            type={type}
            result={result}
            questions={lastQuestions}
            hardCodedQuestionsRequired={hardcodedQuestionRequired}
            questionsFlow={questionsFlow}
            groups={groups}
            onSubmit={onSubmit}
          />
        </Drawer.Container>
        <Drawer.Footer>
          <Form.SubmitButton action noContent>
            Submit Assessment
          </Form.SubmitButton>
        </Drawer.Footer>
      </FormikForm>
    </Formik>
  );
};

type LastQuestionsSectionProps = {
  type: AssessmentType;
  result: keyof typeof assessmentMessage;
  questions: AssessmentQuestionFragment[];
  hardCodedQuestionsRequired: HardCodedQuestionsRequired;
  questionsFlow: QuestionsFlow;
  onSubmit: (values: FormValues) => void;
  groups: {
    id: string;
    questions: string[];
  }[];
};

const LastQuestionsSection = ({
  type,
  questions,
  hardCodedQuestionsRequired,
  questionsFlow,
  groups,
  result,
}: LastQuestionsSectionProps) => {
  const { values } = useFormikContext<FormValues>();

  const sections = getSections(type, questions, values, groups, questionsFlow);
  const newQuestions = sections["S1"].map((question) => {
    if (question.position !== 30) return question;

    return {
      ...question,
      options: {
        ...question.options,
        data: nonNull(question?.options?.data).filter((option) => {
          return (optionsHardCoded30[option.answerText!] ?? []).includes(result);
        }),
      },
    };
  });

  return (
    <>
      {newQuestions.map((question) => (
        <Question
          key={question.id}
          type={type}
          question={question}
          hardCodedQuestionsRequired={hardcodedQuestionRequired}
          submitTouched={false}
        />
      ))}
    </>
  );
};

const optionsHardCoded30: Record<string, string[]> = {
  "Weekly recurring visits": [MEDIUM, LOW],
  Weekly: [MEDIUM, LOW],
  "Monthly recurring visits": [HIGH, MEDIUM],
  "Bi-weekly recurring visits": [HIGH, LOW],
  "Non-recurring visits": [HIGH, MEDIUM, LOW],
  "The member does not want any visits at this time": [HIGH, MEDIUM, LOW],
};
