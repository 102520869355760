import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router";

import { FlexRow } from "components/Flex";
import Heading from "components/Heading";
import Nav from "components/Nav";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";

import ActivityLogsRoutes from "./ActivityLogsRoutes";

const ActivityLogs = () => {
  const { isSupervisor } = useCurrentAccountRole();
  const { id } = useParams<{ id: string }>();

  return (
    <div>
      <Helmet>
        <title>Activity Logs - Papa Admin</title>
      </Helmet>
      <Heading>Activity Logs</Heading>

      <FlexRow justifyContent="space-between">
        <Nav>
          <Nav.Link exact to={`/accounts/${id}/activity-logs`}>
            From this account
          </Nav.Link>
          <Nav.Link to={`/accounts/${id}/activity-logs/subject`}>To this account</Nav.Link>
        </Nav>
      </FlexRow>

      {isSupervisor ? <ActivityLogsRoutes /> : "Not Available"}
    </div>
  );
};

export default ActivityLogs;
