import { gql } from "@apollo/client";

export const UPDATE_PAYMENT_INVOICE_STATES = gql`
  mutation updatePaymentInvoiceStates(
    $paymentInvoiceIds: [ID!]
    $state: UpdatablePaymentInvoiceState!
  ) {
    updatePaymentInvoiceState(id: $paymentInvoiceIds, input: { state: $state }) {
      data {
        id
        state
      }
    }
  }
`;
