import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import { pick } from "lodash";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import Helmet from "react-helmet";

import Actions from "components/ActionsMenu/CaregiverActionMenu/Actions";
import Button from "components/Button";
import { Flex } from "components/Flex";
import Heading from "components/Heading";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import SmartTable from "components/SmartTable";
import {
  CaregiverOnListFragment,
  CaregiverSorting,
  SortDirection,
  useCaregiversQuery,
} from "generated/types";
import { useCallItPapaOrCareRecipient } from "hooks/useCallItPapaOrCareRecipient";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";
import { CaregiverFiltersModal } from "modals/Caregivers/CaregiverFilters/CaregiverFiltersModal";
import { nonNull } from "utils/nonNull";

import { useCaregiversSearch } from "./useCaregiversSearch";
import { useCaregiversTableColumns } from "./useCaregiversTableColumns";

const CaregiversList = () => {
  useTopBarOptions({ title: "Caregivers", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const query = useUrlQuery() as unknown as { businessId?: string };
  const [sorting, setSorting] = useState<CaregiverSorting>({
    insertedAt: SortDirection.Desc,
  });

  const { singularEntityName } = useCallItPapaOrCareRecipient();

  const { filter, pills, setPills } = useSearch(
    !isEmpty(query)
      ? {
          ...(query?.businessId ? { businessId: { eq: query.businessId } } : {}),
        }
      : undefined
  );

  const { columns, action, caregiver, createCaregiver, closeModal } = useCaregiversTableColumns({
    sorting,
    onSortChange: setSorting,
  });

  const { data, loading, error, refetch } = useCaregiversQuery({
    variables: {
      filter,
      pagination: { beforeCursor, afterCursor, limit },
      sorting,
    },
  });
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();

  const caregivers = nonNull(data?.caregivers?.data);
  const pagination = data?.caregivers?.pagination;
  const { loadOptions, searchPillNames } = useCaregiversSearch(caregivers, query.businessId);

  const handleRefetch = async () => {
    await refetch();
  };

  const countFilters = Object.keys(pick(query, ["businessId"])).length;
  const hasFilters = countFilters > 0;

  return (
    <div>
      <Helmet>
        <title>Caregivers - Papa Admin</title>
      </Helmet>

      <Heading>Caregivers</Heading>

      <Flex justifyContent="space-between" flexWrap="wrap-reverse" marginBottom="1rem">
        <Flex gridGap="0.25rem">
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder={`Search by caregiver name, ${singularEntityName} name, ${singularEntityName} phonenumber`}
          />
          <Button type="button" onClick={toggleFiltersModal}>
            <span>Filter</span>
            {hasFilters && <span>({countFilters})</span>}
          </Button>
        </Flex>

        <Flex>
          <Button action onClick={createCaregiver}>
            New Caregiver
          </Button>
        </Flex>
      </Flex>

      <SearchPills
        pills={pills}
        setPills={setPills}
        names={searchPillNames}
        loading={loading}
        noNegativeMarge
      />

      <SmartTable<CaregiverOnListFragment>
        keyField="id"
        localStorageKey="caregiversList"
        data={caregivers}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
        cursorPagination
        rowProps={({ id }) => ({
          linkTo: `/caregivers/${id}`,
        })}
      />
      <CaregiverFiltersModal isOpen={isFiltersModalOpen} toggle={toggleFiltersModal} />
      <Actions
        action={action}
        caregiver={caregiver}
        onClose={closeModal}
        onRefetch={handleRefetch}
      />
    </div>
  );
};

export default CaregiversList;
