import { useMutation } from "@apollo/client";
import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Heading from "components/Heading";
import Modal from "components/Modal";
import Text from "components/Text";

import { ModalFooter } from "../../components/Modal/Modal.styles";
import { SendResetPasswordInstructions } from "./gql";
import { ResetPasswordData } from "./models";

interface Props {
  email: string;
  isOpen: boolean;
  toggle: () => void;
}

export const ResetPasswordModal: React.FC<Props> = ({ email, isOpen, toggle }) => {
  const [sendResetPasswordEmail, { loading }] = useMutation<ResetPasswordData>(
    SendResetPasswordInstructions
  );

  const onResetPasswordConfirm = async () => {
    try {
      const input = { email };
      const { data } = await sendResetPasswordEmail({ variables: { input } });

      if (data?.sendResetPasswordInstructions?.sent) {
        toast.success(`Email with reset password sent to ${email}`);
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <StyledIcon fontWeight="bold" size={23} />
          <TextContainer>
            <Heading as="h2">Reset password</Heading>
            <Text>Do you want to send reset password instructions to {email}</Text>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button disabled={loading} type="button" variant="primary" onClick={onResetPasswordConfirm}>
          {loading ? <SpinningIcon size={18} /> : "Ok"}
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
