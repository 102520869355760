import { gql } from "@apollo/client";

export const REVIEW_TERMINATED_VISIT = gql`
  mutation reviewTerminatedVisit($visitId: ID!) {
    reviewTerminatedVisit(id: $visitId) {
      data {
        id
        state
      }
    }
  }
`;
