import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { ModalContainer, ModalFooter } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Text from "components/Text";

import { APPROVE_OR_REJECT_REVIEW } from "./gql";

interface Props {
  action: DataTableMenuActions;
  id: string;
  isOpen: boolean;
  toggle: () => void;
  refetch: () => void;
}

export const ApproveOrRejectModal: React.FC<Props> = ({ action, id, isOpen, toggle, refetch }) => {
  const [approveOrRejectReview, { loading, error: mutationError }] =
    useMutation(APPROVE_OR_REJECT_REVIEW);

  const handleSubmit = async () => {
    try {
      const { data } = await approveOrRejectReview({
        variables: {
          approved: action === DataTableMenuActions.Approve ? true : false,
          visitId: id,
        },
      });
      if (mutationError) {
        toast.error(mutationError);
      }
      if (
        data?.approvePapaRating?.data?.approved ===
        (action === DataTableMenuActions.Approve ? "approved" : "rejected")
      ) {
        toast.success(
          "Review " + (action === DataTableMenuActions.Approve ? "Approved" : "Rejected")
        );
        refetch();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
    toggle();
  };

  return (
    <ModalLayout
      title={action === DataTableMenuActions.Approve ? "Approve" : "Reject"}
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalContainer>
        <Text>
          Would you like this review to be{" "}
          {action === DataTableMenuActions.Approve ? "approved" : "rejected"}?
        </Text>

        <ModalFooter>
          <Button variant="secondary" onClick={toggle}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleSubmit}>
            {loading && <SpinningIcon size={18} />}
            OK
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalLayout>
  );
};

export default ApproveOrRejectModal;
