import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { Loader } from "components/Loader";
import { ModalFooter } from "components/Modal/Modal.styles";
import TaskButtons from "components/TaskButtons";
import { TaskObjectives } from "components/TaskObjectives/TaskObjectives";
import Text from "components/Text";
import { PapaMood, VisitDetailsDocument, VisitSingleResult } from "generated/types";
import { useRatePapaMutation } from "generated/types";
import { BUSINESS_POLICY_ON_DATE, PAPA_RESOURCE_BUDGET } from "pages/ScheduleVisit/gql";
import { CurrentBusinessPolicyContext } from "pages/ScheduleVisit/shared/CurrentBusinesPolicyContext";
import { nonNull, nonNullWithId } from "utils/nonNull";

import { GeneralExperience } from "./GeneralExperience";
import { MemberKeywords, SelectedKeywords } from "./MemberKeywords/MemberKeywords";
import { MemberMood } from "./MemberMood";
import { OtherCompletedTasks } from "./OtherCompletedTasks";
import { VisitDuration } from "./VisitDuration";
import { VisitRating } from "./VisitRating";
import { CompletedObjectives, VisitTasks } from "./VisitTasks/VisitTasks";
import { GET_VISIT_FOR_FEEDBACK, REVIEW_VISIT, UPDATE_VISIT_TASKS } from "./gql";

interface Props {
  id: string;
  close: () => void;
  onSuccess: () => void;
}

type MutationResults = any[];

export const VisitFeedbackModal: React.FC<Props> = ({ id, close, onSuccess }) => {
  const [rating, setRating] = useState<number>(-1);
  const [memberKeywords, setMemberKeywords] = useState<SelectedKeywords>({});
  const [completedObjectives, setCompletedObjectives] = useState<CompletedObjectives>({});
  const [memberMood, setMemberMood] = useState<PapaMood>();
  const [experience, setExperience] = useState<string>("");
  const [otherCompletedTasks, setOtherCompletedTasks] = useState<string>("");

  const { data, loading } = useQuery<{ visit?: VisitSingleResult | null }>(GET_VISIT_FOR_FEEDBACK, {
    variables: { id },
  });

  const visit = data?.visit?.data;
  const isVirtual = visit?.isVirtual;
  const scheduledFor = visit?.scheduledFor;
  const papaId = visit?.papa?.data?.id;
  const tasks = nonNullWithId(visit?.tasks?.data);
  const objectives = nonNullWithId(visit?.objectives?.data);
  const visitDuration = visit ? visit.overrideDurationMins ?? visit.observedDurationMins : 0;

  const { data: resourceBudgetData } = useQuery(PAPA_RESOURCE_BUDGET, {
    skip: !scheduledFor || !papaId,
    variables: {
      id: papaId,
      givenDate: scheduledFor,
    },
  });
  const accountId = resourceBudgetData?.papa?.data?.account?.data?.id;
  const { data: businessPolicyData } = useQuery(BUSINESS_POLICY_ON_DATE, {
    skip: !accountId,
    variables: {
      accountId,
      givenDate: scheduledFor,
    },
  });

  const currentBusinessPolicy = businessPolicyData?.businessPolicies?.data?.[0];
  const resourceBudget = resourceBudgetData?.papa.data.resourceBudget?.data;

  const [ratePapa, { loading: rateLoading }] = useRatePapaMutation({
    refetchQueries: [{ query: VisitDetailsDocument, variables: { id } }],
  });
  const [reviewVisit, { loading: reviewLoading }] = useMutation(REVIEW_VISIT);
  const [updateVisitTasks, { loading: updateVisitTasksLoading }] = useMutation(UPDATE_VISIT_TASKS);

  const submitLoading = rateLoading || reviewLoading || updateVisitTasksLoading;

  const onSendReview = async ({
    taskIds,
    objectives: additionalObjectives,
  }: {
    taskIds: string[];
    objectives: { value: string }[];
  }) => {
    if (rating < 0) {
      toast.error("Rating for papa is required");

      return;
    }

    try {
      const allObjectives = [
        ...objectives.map(({ id }) => ({ id, checked: false })),
        ...additionalObjectives.map(({ value }) => ({ id: value, checked: false })),
      ];

      const finishedObjectives = [
        ...Object.entries(completedObjectives)
          .filter(([id, isCompleted]) => isCompleted)
          .map(([id]) => ({ id, checked: true })),
        ...additionalObjectives.map(({ value }) => ({ id: value, checked: true })),
      ];

      if (true) {
        const { data } = await updateVisitTasks({
          variables: {
            visitId: id,
            taskIds,
            objectives: allObjectives,
          },
        });

        if (!data?.updateVisit?.data?.id) {
          throw new Error("Something is wrong");
        }
      }

      const [rate, review]: MutationResults = await Promise.all([
        ratePapa({
          variables: {
            visitId: id,
            // @ts-ignore
            rating: rating >= 0 ? rating : null,
            keywords: Object.values(memberKeywords),
            papaMood: memberMood,
            additionalInformation: experience,
            additionalTasksCompleted: otherCompletedTasks,
          },
        }),
        reviewVisit({
          variables: {
            id: id,
            input: { objectives: finishedObjectives },
          },
        }),
      ]);

      if (rate?.data?.ratePapa?.data?.id && review?.data?.reviewVisit?.data?.id) {
        onSuccess();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <CurrentBusinessPolicyContext.Provider
      value={{
        currentBusinessPolicy,
        resourceBudget,
        setCurrentBusinessPolicy: () => {},
        setResourceBudget: () => {},
      }}
    >
      <ModalContainer>
        <Formik
          initialValues={{
            taskIds: [],
            objectives: [],
            isVirtual,
          }}
          onSubmit={onSendReview}
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Text bold size="largex">
                Total time spent
              </Text>

              <VisitDuration duration={visitDuration} />

              <VisitTasks
                tasks={tasks}
                objectives={objectives}
                completedObjectives={completedObjectives}
                setCompletedObjectives={setCompletedObjectives}
              />

              <Text size="largex" bold>
                Add more Tasks and Objectives that you completed today
              </Text>
              <Text>Choose a task</Text>
              <TaskButtons name="taskIds" permanentTaskIds={nonNull(tasks.map(({ id }) => id))} />
              <TaskObjectives
                taskIds={values.taskIds}
                objectives={values.objectives}
                hiddenObjectiveIds={nonNull(objectives.map(({ id }) => id))}
              />

              <OtherCompletedTasks setOtherCompletedTasks={setOtherCompletedTasks} />

              <Text size="largex" bold>
                Rate this visit
              </Text>

              <MemberRating>
                <VisitRating setRating={setRating} rating={rating} />

                <MemberKeywords
                  rating={rating}
                  selectedKeywords={memberKeywords}
                  setKeywords={setMemberKeywords}
                />

                <MemberMood mood={memberMood} setMood={setMemberMood} />

                <GeneralExperience setExperience={setExperience} />
              </MemberRating>

              <Footer>
                <Button variant="secondary" onClick={close}>
                  Close
                </Button>
                <Button disabled={submitLoading} type="submit" variant="primary">
                  {submitLoading ? (
                    <>
                      <SpinningIcon size={18} />
                      <span>Saving...</span>
                    </>
                  ) : (
                    "Send review"
                  )}
                </Button>
              </Footer>
            </form>
          )}
        </Formik>
      </ModalContainer>
    </CurrentBusinessPolicyContext.Provider>
  );
};

const ModalContainer = styled.div`
  padding: 0 39px;
  width: 100%;
`;

const Footer = styled(ModalFooter)`
  padding-right: 0px;
`;

const MemberRating = styled.div`
  padding-left: 43px;
`;
