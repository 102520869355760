import { ApolloClient, gql } from "@apollo/client";

export const getLastLoginTime = async (client: ApolloClient<object>) => {
  const { data } = await client.query({ query: LAST_LOGGED_IN, fetchPolicy: "no-cache" });
  const lastLoginTime = data?.currentAccount?.data?.lastLoggedInAt;

  return lastLoginTime;
};

const LAST_LOGGED_IN = gql`
  query lastLoggedIn {
    currentAccount {
      data {
        lastLoggedInAt
      }
    }
  }
`;
