import { Star as StarIcon } from "@styled-icons/boxicons-solid/Star";
import capitalize from "lodash/capitalize";
import uniqueId from "lodash/uniqueId";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Flex from "components/Flex";
import Grid from "components/Grid";
import Panel from "components/Panel";
import SeeMapLink from "components/SeeMapLink";
import Text from "components/Text";
import VisitMileage from "components/VisitMileage";
import { VisitObjectives } from "components/VisitObjectives";
import MarkableVisitObjectives from "components/VisitObjectives/MarkableVisitObjectives";
import { sortObjectives } from "components/VisitObjectives/sortObjectives";
import VisitRecurrence from "components/VisitRecurrence";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { PapaMoodIcons } from "constants/papa";
import { Visit } from "generated/types";
import { PapaMood, VisitDetailsFragment, VisitState } from "generated/types";
import { getDiscount } from "utils/getDiscount";
import { nonNull, nonNullWithId } from "utils/nonNull";

import Duration from "./Duration";
import Surveys from "./Surveys/Surveys";

type Props = {
  visit?: Visit | null;
  loading?: boolean;
};

const VisitDetails = ({ visit, loading = false }: Props) => {
  const {
    id,
    additionalLocations,
    discount,
    location,
    memberConfirmed,
    recurrence,
    memberNote,
    systemNotes,
    account,
    state,
  } = visit ?? {};

  const tasks = nonNull(visit?.tasks?.data);
  const objectives = sortObjectives(nonNullWithId(visit?.objectives?.data));
  const objectivesChecked = objectives.filter((objective) => objective.checked);

  const visitDetails = visit as unknown as VisitDetailsFragment;
  const visitRating = visitDetails?.visitRating?.data;

  const favoritePalsOnly = visit?.favoritePalsOnly ?? false;

  const VisitName = account?.data?.fullName ?? "";

  return (
    <Grid>
      <Helmet>
        <title>{VisitName} Visit Details - Papa Admin</title>
      </Helmet>
      <Grid.Column>
        <Panel title="Main Details" loading={loading}>
          <Panel.Item>
            <Text bold>Preferred Pals Only</Text>
            <Text>{favoritePalsOnly ? "Yes" : "No"} </Text>
          </Panel.Item>
          <Panel.Item>
            <Text bold>Recurrence</Text>
            <Text>
              {visit &&
                VisitRecurrence({
                  recurrence: recurrence?.data,
                  visit,
                })}
            </Text>
          </Panel.Item>
          <Panel.Item>
            <Text bold>Location</Text>
            <Text>{location?.data?.address || EMPTY_PLACEHOLDER}</Text>
            <Text>{location?.data?.address2}</Text>
            <SeeMapLink location={location?.data} />
          </Panel.Item>
          <Panel.Item>
            <Text bold>Destination</Text>
            <Text>{visit?.destination?.data?.address || EMPTY_PLACEHOLDER}</Text>
            <SeeMapLink location={visit?.destination?.data} />
          </Panel.Item>

          <Duration visit={visit} />

          {visit && <VisitMileage visit={visit} />}
          {discount?.data && discount?.data?.type && typeof discount?.data?.value === "number" && (
            <Panel.Item>
              <Text bold>Discount</Text>
              <Text>
                {getDiscount({
                  type: discount?.data?.type,
                  value: discount?.data?.value,
                })}
              </Text>
            </Panel.Item>
          )}
          {additionalLocations?.data && !!additionalLocations.data.length && (
            <Panel.Item>
              <Text bold>Additional locations</Text>
              {nonNull(additionalLocations.data).map((location) => (
                <Text key={location.id}>{location.address}</Text>
              ))}
            </Panel.Item>
          )}
          <Panel.Item>
            <Text bold>Member Confirmed Visit</Text>
            <Text>{memberConfirmed === true ? "Yes" : "No"}</Text>
          </Panel.Item>
        </Panel>
      </Grid.Column>
      <Grid.Column>
        <Panel title="Tasks and Objectives" loading={loading}>
          <Panel.Item>
            <Text bold>Tasks</Text>
            <Text>
              {tasks.length ? tasks.map((task) => task.name).join(", ") : EMPTY_PLACEHOLDER}
            </Text>
          </Panel.Item>
          {!!objectives.length && (
            <Panel.Item>
              <Text bold>Objectives</Text>
              {id && state === VisitState.Started ? (
                <MarkableVisitObjectives visitId={id} objectives={objectives} />
              ) : (
                <VisitObjectives objectives={objectives} />
              )}
            </Panel.Item>
          )}
          <Panel.Item>
            <Text bold>Member Note</Text>
            <Text>{memberNote || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>
          <Panel.Item>
            <Text bold>System Notes</Text>
            <ul>
              {systemNotes &&
                systemNotes.map((note) => (
                  <li color="primary" key={uniqueId()} dangerouslySetInnerHTML={{ __html: note }} />
                ))}
            </ul>
          </Panel.Item>
        </Panel>
        <Panel title="Visit Review Notes" loading={loading}>
          {!visitRating ? (
            <Panel.Item>
              <Text color="primary">
                {state === VisitState.Reviewed
                  ? "Notes are blank"
                  : "Notes are not available until the visit has been reviewed"}
              </Text>
            </Panel.Item>
          ) : (
            <>
              <Panel.Item>
                <Text bold color="primary">
                  Member's Mood
                </Text>
                <Text color="primary" as="div">
                  {!!visitRating.papaMood ? (
                    <Flex alignItems="center" marginTop={4}>
                      <Mood papaMood={visitRating.papaMood} />
                      {capitalize(visitRating.papaMood)}
                    </Flex>
                  ) : (
                    EMPTY_PLACEHOLDER
                  )}
                </Text>
              </Panel.Item>
              <Panel.Item>
                <Text bold color="primary">
                  Pal App Rating
                </Text>
                <Text color="primary" as="div">
                  {typeof visitRating?.ratingForPapa === "number" ? (
                    <Flex alignItems="center" gridGap={4}>
                      <span>{visitRating.ratingForPapa}</span>
                      <StarIcon size={16} color="#0E2765" />
                    </Flex>
                  ) : (
                    EMPTY_PLACEHOLDER
                  )}
                </Text>
              </Panel.Item>
              <Panel.Item>
                <Text bold color="primary">
                  Completed Objectives
                </Text>
                {!!objectives.length && (
                  <ul>
                    {objectivesChecked.map((objective) => (
                      <li key={objective.id}>
                        <Text color="primary">{objective.description}</Text>
                      </li>
                    ))}
                    {!objectivesChecked.length && <Text color="primary">{EMPTY_PLACEHOLDER}</Text>}
                    {visitRating?.additionalTasksCompleted && (
                      <li>
                        <Text color="primary" as="div">
                          Other:
                          <Flex marginHorizontal="1.5rem">
                            {visitRating.additionalTasksCompleted}
                          </Flex>
                        </Text>
                      </li>
                    )}
                  </ul>
                )}
              </Panel.Item>
              <Panel.Item>
                <Text bold color="primary">
                  Additional Information
                </Text>
                <Text color="primary">
                  {visitRating.additionalInformation ?? EMPTY_PLACEHOLDER}
                </Text>
              </Panel.Item>
            </>
          )}
        </Panel>
        <Panel title="Surveys" loading={loading}>
          <Surveys listView visit={visit} />
        </Panel>
      </Grid.Column>
    </Grid>
  );
};

const Mood = ({ papaMood }: { papaMood: PapaMood }) => {
  if (!papaMood) return null;

  const Icon = PapaMoodIcons[papaMood];

  return (
    <Wrapper>
      <Icon />
    </Wrapper>
  );
};

const Wrapper = styled.span`
  padding-right: 4px;
  svg {
    fill: ${({ theme }) => theme.variants.primary};
    width: 18px;
    height: 18px;
  }
`;

export default VisitDetails;
