import { useMutation } from "@apollo/client";
import { Formik, useField } from "formik";
import React, { ReactElement, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { GET_VISITS_LIST } from "components/DataTables/VisitsTable/gql";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import RadioButtonGroup from "components/RadioGroup/RadioButtonGroup";
import { RadioGroup } from "components/RadioGroup/RadioGroup";
import Select, { GroupType } from "components/Select";
import { WhatTriggeredOption } from "constants/visit";
import { reasonCodeSearched, reasonCodeSelected } from "utils/segment";

import { CANCEL_VISIT } from "./gql";
import {
  applyUpdateToOptions,
  memberReasonOptions,
  palReasonOptions,
  serviceReasonOptions,
  whatTriggeredOptions,
  withFeeOptions,
} from "./models";
import { Schema, schema } from "./schema";

interface Props {
  visitId: string | null;
  closeModal: (papaId?: string) => void;
}

const CancelVisitForm = ({ visitId, closeModal }: Props): ReactElement => {
  const [cancelVisit, { loading }] = useMutation(CANCEL_VISIT, {
    refetchQueries: [{ query: GET_VISITS_LIST }],
  });
  const initialValues: Schema = {
    withFee: null,
    reason: null,
    whatTriggered: WhatTriggeredOption.Member,
    applyToFutureRecurrences: false,
    description: "",
  };

  const handleSubmit = async ({
    withFee,
    reason: code,
    applyToFutureRecurrences,
    description,
  }: Schema) => {
    const input = {
      applyToFutureRecurrences,
      reason: {
        code,
        description: description || "Canceled by Admin",
      },
      withFee,
    };
    try {
      const { data } = await cancelVisit({ variables: { id: visitId, input } });

      if (data?.terminateVisit) {
        toast.success("This visit canceled success");
        const papaId = applyToFutureRecurrences
          ? data.terminateVisit.data?.papa?.data?.id
          : undefined;
        closeModal(papaId);
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Hint>
              There is a cancelation fee of $15 if a visit is canceled in less than 24h before
              scheduled time.
            </Hint>
            <Form.Label required>Cancel with fee of $15:</Form.Label>
            <RadioButtonGroup aria-label="withFee" name="withFee" options={withFeeOptions} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Who triggered the cancellation?</Form.Label>
            <RadioGroup name="whatTriggered" options={whatTriggeredOptions} />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Reason</Form.Label>
            <ReasonCodeSelect />
          </Form.Group>
          <Form.Group>
            <Form.Label>Apply update to:</Form.Label>
            <RadioGroup
              options={applyUpdateToOptions}
              name="applyToFutureRecurrences"
              aria-label="applyToFutureRecurrences"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.TextArea aria-label="description" name="description" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button type="button" variant="secondary" onClick={() => closeModal()}>
            No
          </Button>
          <Button type="submit" disabled={loading} variant="primary">
            {loading && <SpinningIcon size={18} />}
            Yes
          </Button>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

const ReasonCodeSelect = () => {
  const [{ value: whatTriggeredValue }] = useField("whatTriggered");
  const [, , helpers] = useField("reason");

  useEffect(() => {
    helpers.setValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatTriggeredValue]);

  return (
    <Select
      name="reason"
      aria-label="reason"
      options={
        whatTriggeredValue === WhatTriggeredOption.Member
          ? (memberReasonOptions as GroupType[])
          : whatTriggeredValue === WhatTriggeredOption.Pal
          ? (palReasonOptions as GroupType[])
          : (serviceReasonOptions as GroupType[])
      }
      placeholder="Select reason"
      isSearchable={true}
      filterOption={(option, rawInput) =>
        option.label.toLowerCase().includes(rawInput.toLowerCase()) ||
        option.data?.tags?.toLowerCase().includes(rawInput.toLowerCase())
      }
      trackSearch={(keyword) => reasonCodeSearched(keyword)}
      trackSelected={(keyword, value) => reasonCodeSelected(keyword, value)}
    />
  );
};

const Hint = styled.span`
  font-size: 12px;
  white-space: nowrap;
`;

export default CancelVisitForm;
