import { AccountType, VisitState } from "generated/types";

type PaymentsFiltersFilterMakerProps = {
  dayFrom?: string;
  dayTo?: string;
  states?: string;
  recipient?: AccountType;
  pal?: string;
  papa?: string;
  member?: string;
};

export const countFilters = (filters: Partial<PaymentsFiltersFilterMakerProps>) => {
  let count = 0;
  if (filters.states?.length) count++;
  if (filters.dayFrom) count++;
  if (filters.dayTo) count++;
  if (filters.pal) count++;
  if (filters.papa) count++;
  if (filters.member) count++;
  return count;
};

export function paymentsFiltersMaker({
  dayFrom,
  dayTo,
  states,
  recipient,
  pal,
  papa,
  member,
}: PaymentsFiltersFilterMakerProps) {
  return {
    ...(dayFrom || dayTo
      ? {
          insertedAt: {
            ...(dayFrom ? { gte: dayFrom } : {}),
            ...(dayTo ? { lt: dayTo } : {}),
          },
        }
      : {}),
    ...(states?.length
      ? {
          state: {
            in: states.split(",") as VisitState[],
          },
        }
      : {}),
    ...(recipient || member
      ? {
          recipient: {
            typeIn: recipient,
            nameContains: member,
          },
        }
      : {}),
    ...(pal ? { pal: { nameContains: pal } } : {}),
    ...(papa ? { papa: { nameContains: papa } } : {}),
  };
}
