import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { SEARCH_QUERY } from "components/Search/gql";
import { PalOnboarding } from "generated/types";
import { SearchResult } from "types/search";

export const usePalOnboardingSearch = (data: PalOnboarding[]) => {
  const client = useApolloClient();
  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: SEARCH_QUERY,
        variables: {
          search: `*${value}*`,
          index: "prospectpals",
        },
      });
      if (data?.searchResult?.data) {
        return data.searchResult.data.map(
          ({ id, providedFirstName, providedLastName, account }: SearchResult) => {
            return {
              value: id,
              label: `${providedFirstName} ${providedLastName}`,
              phone: account?.data?.phoneNumber,
              email: account?.data?.email,
              address: account?.data?.homeLocation?.data?.address,
              name: `${providedFirstName} ${providedLastName}`,
            };
          }
        );
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };
  const searchPillNames = data?.reduce(
    (obj, { id, providedFirstName, providedLastName }) => ({
      ...obj,
      [id!]: `${providedFirstName} ${providedLastName}`,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
