import { useField } from "formik";
import moment from "moment-timezone";
import React, { useEffect } from "react";

import Form from "components/Form";
import InputDate from "components/InputDate";
import ConfirmModal from "components/Modal/ConfirmModal";
import TimePicker from "components/TimePicker";
import { DEFAULT_TIMEZONE, TZ_FORMAT } from "constants/date";
import { useModalToggle } from "hooks/useModalToggle";
import { isEarlyTime, isImpossibleStartEndTime, isLateTime } from "utils/time";

import { InputGroup } from ".";

interface Props {
  setIsImpossibleTime: React.Dispatch<React.SetStateAction<boolean>>;
  timezone?: string | null;
}

const TimeDetails: React.FC<Props> = ({ setIsImpossibleTime, timezone = DEFAULT_TIMEZONE }) => {
  const [{ value: startedAtDay }] = useField("startedAtDay");
  const [{ value: startedAtTime }, , startedAtTimeHelpers] = useField("startedAtTime");
  const [{ value: completedAtDay }] = useField("completedAtDay");
  const [{ value: completedAtTime }] = useField("completedAtTime");
  const { isOpen: earlyTimeConfirmModal, toggle: toggleEarlyTimeConfirmModal } = useModalToggle();
  const { isOpen: lateTimeConfirmModal, toggle: toggleLateTimeConfirmModal } = useModalToggle();

  useEffect(() => {
    if (!isImpossibleStartEndTime(startedAtDay, startedAtTime, completedAtDay, completedAtTime)) {
      setIsImpossibleTime(false);
    }
  }, [completedAtDay, completedAtTime, setIsImpossibleTime, startedAtDay, startedAtTime]);

  const handleTimeChange = () => {
    if (isEarlyTime(startedAtTime)) {
      toggleEarlyTimeConfirmModal();
    } else if (isLateTime(startedAtTime)) {
      toggleLateTimeConfirmModal();
    }
  };

  return (
    <>
      <Form.Group>
        <Form.Label required>Started At</Form.Label>
        <InputGroup>
          <InputDate id="startedAtDay" name="startedAtDay" isOutsideRange={() => false} />
          <TimePicker name="startedAtTime" onClose={handleTimeChange} />
        </InputGroup>
        <Form.Feedback>
          {moment(startedAtDay)
            .tz(timezone ?? DEFAULT_TIMEZONE)
            .format(TZ_FORMAT)}
        </Form.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label required>Completed At</Form.Label>
        <InputGroup>
          <InputDate id="completedAtDay" name="completedAtDay" isOutsideRange={() => false} />
          <TimePicker name="completedAtTime" />
        </InputGroup>
        <Form.Feedback>
          {moment(completedAtDay)
            .tz(timezone ?? DEFAULT_TIMEZONE)
            .format(TZ_FORMAT)}
        </Form.Feedback>
      </Form.Group>
      <ConfirmModal
        isOpen={earlyTimeConfirmModal}
        header="That’s early in the morning for a visit!"
        onConfirm={() => {
          toggleEarlyTimeConfirmModal();
        }}
        onCancel={() => {
          startedAtTimeHelpers.setValue("");
          toggleEarlyTimeConfirmModal();
        }}
        confirmBtnText="Yes"
        cancelBtnText="No"
      >
        Are you sure the visit started at {startedAtTime}?
      </ConfirmModal>
      <ConfirmModal
        isOpen={lateTimeConfirmModal}
        header="That’s late at night for a visit!"
        onConfirm={() => {
          toggleLateTimeConfirmModal();
        }}
        onCancel={() => {
          startedAtTimeHelpers.setValue("");
          toggleLateTimeConfirmModal();
        }}
        confirmBtnText="Yes"
        cancelBtnText="No"
      >
        Are you sure the visit started at {startedAtTime}?
      </ConfirmModal>
    </>
  );
};

export default TimeDetails;
