import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import moment from "moment-timezone";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Form from "components/Form";
import InputDate from "components/InputDate";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import TimePicker from "components/TimePicker";
import { DEFAULT_TIMEZONE, TZ_FORMAT } from "constants/date";
import { VisitSingleResult } from "generated/types";
import { mergeDateAndTime } from "utils/date";
import { formatDateTime } from "utils/helpers/formatters";

import { COMPLETE_VISIT, GET_VISIT } from "./gql";
import { FormValues, schema } from "./schema";

interface Props {
  visitId: string;
  isOpen: boolean;
  toggle: () => void;
}

export const CompleteVisitForm = ({ visitId, isOpen, toggle }: Props) => {
  const { data, loading, error } = useQuery<{ visit?: VisitSingleResult | null }>(GET_VISIT, {
    variables: { id: visitId },
    skip: !isOpen,
    fetchPolicy: "network-only",
  });

  const [completeVisit] = useMutation(COMPLETE_VISIT);

  const visit = data?.visit?.data;

  if (loading) return <Loader />;

  const initialValues: FormValues = {
    completedAtDay: "",
    completedAtTime: "",
  };
  const timezone = visit?.location?.data?.timezone || DEFAULT_TIMEZONE;

  const handleSubmit = async ({ completedAtDay, completedAtTime }: FormValues) => {
    const completedAt = mergeDateAndTime({
      day: completedAtDay,
      time: completedAtTime,
      timezone,
    });

    try {
      const { data } = await completeVisit({
        variables: {
          id: visitId,
          input: { completedAt },
        },
      });

      if (data?.completeVisit?.data?.completedAt) {
        toast.success("Completed visit!");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <QueryErrors error={error} />

          <Text size="middle">To complete the visit, please enter the time the visit ended.</Text>
          <Text size="middle">
            As a reminder, the visit started at{" "}
            {formatDateTime(visit?.startedAt, { format: "MMM D YYYY, h:mm A zz (Z)", timezone })}
          </Text>
          <InlineFormGroup>
            <InputDate
              id="completedAtDay"
              name="completedAtDay"
              isShort
              isOutsideRange={() => false}
              placeholder="Select the Day"
            />
            <TimePicker name="completedAtTime" />
            {visit?.location?.data?.timezone && (
              <Timezone>{moment().tz(timezone).format(TZ_FORMAT)}</Timezone>
            )}
          </InlineFormGroup>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={toggle}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

const InlineFormGroup = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 10px;
  align-items: flex-start;

  .time-picker {
    width: 170px;
    .rc-time-picker-clear {
      top: 6px;
    }
  }
`;

const Timezone = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
`;
