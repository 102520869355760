import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Pal } from "generated/types";

interface Props {
  favoritePals: Pal[];
}

const FavoritePals = ({ favoritePals }: Props) => {
  return (
    <>
      <Label aria-label="Preferred pals label">Preferred pals</Label>
      {favoritePals?.length
        ? favoritePals.map(({ id, fullName }, index: number) => (
            <span key={id}>
              <Link target="_blank" to={`/pals/${id}`}>
                {fullName}
              </Link>
              {index === favoritePals.length - 1 ? "" : ", "}
            </span>
          ))
        : "Papa has no preferred pals"}
    </>
  );
};

export default FavoritePals;

const Label = styled.div`
  font-weight: bold;
`;
