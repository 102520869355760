import { useMutation } from "@apollo/client";
import React, { HTMLAttributes } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { ModalContainer, ModalFooter } from "components/Modal/Modal.styles";

import { EXPORT_PALS } from "./gql";

interface Props extends HTMLAttributes<HTMLElement> {
  closeModal: () => void;
}

const ExportPalsModal = ({ closeModal }: Props) => {
  const [exportPals, { loading }] = useMutation(EXPORT_PALS);

  const onExportPals = async () => {
    try {
      const { data } = await exportPals();

      if (data?.exportPals?.data?.success) {
        toast.success("Export Pals Success.");
        closeModal();
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalContainer>
      <p>A CSV file containing all pals will be sent to your email address.</p>

      <ModalFooter>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button disabled={loading} variant="primary" onClick={onExportPals}>
          {loading && <SpinningIcon size={18} />}
          OK
        </Button>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ExportPalsModal;
