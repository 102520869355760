import React, { Children } from "react";
import ContentLoader from "react-content-loader";
import styled, { css } from "styled-components";

import { Button as ButtonLink } from "components/ButtonLink";
import Heading from "components/Heading";
import Text from "components/Text";
import { cssStringOrPx } from "utils/helpers/cssStringOrPx";
import { withProperties } from "utils/type-helper";

type Props = {
  title?: string;
  loading?: boolean;
  onEditClick?: () => void;
  children?: React.ReactNode;
  textEdit?: string;
};

const Panel = ({ title, onEditClick, children, loading = false, textEdit = "Edit" }: Props) => {
  const childrenCount = Children.toArray(children).length;

  return (
    <Block>
      <StyledHeader>
        {title && (
          <Heading as="h2" color="primary">
            {title}
          </Heading>
        )}

        {onEditClick && <EditButton onClick={onEditClick}>{textEdit}</EditButton>}
      </StyledHeader>

      {loading ? (
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#c0c0c0"
          height={childrenCount * 80}
        >
          {Array.from(Array(childrenCount).keys()).map((i) => (
            <React.Fragment key={i}>
              <rect x="0" y={20 + i * 80} rx="4" ry="4" width="25%" height="24" />
              <rect x="0" y={50 + i * 80} rx="4" ry="4" width="100%" height="24" />
            </React.Fragment>
          ))}
        </ContentLoader>
      ) : (
        children
      )}
    </Block>
  );
};

const Item = styled.div<{ marginBottom?: string | number }>`
  ${Text}:not(:first-of-type) {
    color: ${({ theme }) => theme.text.muted};
  }

  ${({ marginBottom }) =>
    typeof marginBottom !== "undefined"
      ? css`
          margin-bottom: ${cssStringOrPx(marginBottom)};
        `
      : css`
          margin-bottom: 1.5rem;
        `}

  &:last-child {
    margin-bottom: 0;
  }
`;

type BlockProps = {
  spaceX?: string | number;
};

export const Block = styled.div<BlockProps>`
  background: ${({ theme }) => theme.background.panelContent};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 1.25rem;
  margin-bottom: 1.875rem;

  ${({ spaceX }) =>
    spaceX &&
    css`
      & > :not([hidden]) ~ :not([hidden]) {
        margin-top: ${cssStringOrPx(spaceX)};
      }
    `}
`;

const Side = styled(Block)`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1.5rem;
  padding: 1.75rem 2.188rem;
`;

export const StyledHeader = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled("button")`
  color: ${({ theme }) => theme.text.primary};
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 800;
  text-decoration: underline;
  background: transparent;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
`;

const SideLoading = ({ rows = 5 }: { rows?: number }) => (
  <Side>
    <ContentLoader
      speed={2}
      width="100%"
      height={500}
      backgroundColor="#f3f3f3"
      foregroundColor="#c0c0c0"
    >
      <rect x="0" y="0" rx="5" ry="5" width="32" height="32" />
      <rect x="48" y="0" rx="4" ry="4" width="130" height="24" />

      {Array.from(Array(rows).keys()).map((i) => (
        <React.Fragment key={i}>
          <rect x="0" y={70 + 70 * i} rx="4" ry="4" width="48" height="20" />
          <rect x="0" y={95 + 70 * i} rx="4" ry="4" width="180" height="24" />
        </React.Fragment>
      ))}
    </ContentLoader>
  </Side>
);

export const Left = styled.div`
  width: 250px;
  margin-right: 1.5rem;
`;

const Edit = styled.button`
  color: ${({ theme }) => theme.text.primary};
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: inherit;
  padding: 0;
  outline: none;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Section = styled.div`
  border-top: 2px solid #ededed;
  padding-top: 1rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &:not(:last-child) {
    padding-bottom: 1rem;
  }
`;

const Buttons = styled.div`
  margin-top: 3rem;

  ${ButtonLink} {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.85rem;
  }
`;

export default withProperties(Panel, {
  Block,
  Buttons,
  Edit,
  Head,
  Item,
  Left,
  Section,
  Side,
  SideLoading,
});
