import React from "react";
import styled from "styled-components";

import { ReactComponent as Success } from "assets/images/success.svg";
import Button from "components/Button";
import Text from "components/Text";

interface Props {
  close: () => void;
}

export const VisitFeedbackSuccess: React.FC<Props> = ({ close }) => (
  <Container>
    <Success />
    <Text>Nice work!</Text>
    <Text>Your review is now submitted.</Text>

    <Button variant="secondary" onClick={close}>
      Close
    </Button>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 34px;
  width: 100%;

  svg {
    margin-bottom: 20px;
  }

  > ${Text} {
    margin-bottom: 10px;
  }

  ${Button} {
    margin-top: 80px;
  }
`;
