import { StyledIconBase } from "@styled-icons/styled-icon";
import { useField, useFormikContext } from "formik";
import noop from "lodash/noop";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import styled, { css } from "styled-components";
import { Tasks } from "styled-icons/fa-solid";

import Button from "components/Button";
import Feedback from "components/Form/FormFeedback";
import Text from "components/Text";
import { iconsDict } from "constants/tasks";
import * as Segment from "utils/segment";

import QueryErrors from "../QueryErrors";
import Tooltip from "../Tooltip";
import { useTasksList } from "./useTasksList";

type Props = {
  name: string;
  permanentTaskIds?: string[];
  setIsMaxTimeReached?: (state: boolean) => void;
  onChange?: (value: string[]) => void;
  small?: boolean;
};

const relocationTaskNames = [
  "Grocery Shopping",
  "Transportation",
  "Dr. Visits",
  "Run Errands",
  "Medication/RX",
];
const uberTasks = ["Transportation", "Dr. Visits", "Run Errands"];
const overnightTransportationTasks = ["Transportation", "Dr. Visits"];

const TaskButtons = ({
  name,
  permanentTaskIds = [],
  setIsMaxTimeReached = noop,
  onChange = noop,
  small = false,
}: Props) => {
  const [{ value }, { touched, error }, helpers] = useField(name);
  const [{ value: papaId }, ,] = useField("papaId");
  const [, , hasRelocationTasksHelpers] = useField("hasRelocationTasks");
  const [, , hasUberTasksHelpers] = useField("hasUberTasks");
  const [, , hasGroceryTaskHelpers] = useField("hasGroceryTask");
  const [, , hasMedicationTaskHelpers] = useField("hasMedicationTask");
  const [, , hasOvernightTransportationTasksHelpers] = useField("hasOvernightTransportationTasks");
  /* `taskNames` exists to provide data to MobilitySupport. In that component we need to make rendering
  decisions based upon the specific transportation tasks selected. Sharing the data through Formik
  state is not ideal, it would be better to refactor this to grab tasks in a parent and make them
  available to both components through props. 
  */
  const [, , taskNameHelpers] = useField("taskNames");

  const [selectedTasks, setSelectedTasks] = useState<string[]>(value);
  const { tasks, loading, error: taskListError } = useTasksList(setIsMaxTimeReached);
  const { isSubmitting } = useFormikContext();

  const handleToggleTasks = (task: string) => () => {
    let newSelectedTasks;

    if (permanentTaskIds.includes(task)) return;

    // We need the task name for the segment event. If for some reason we can't find the task
    // we're toggling (which should never happen), we'll send the task id instead.
    const taskName = tasks.find((t) => t.id === task)?.name ?? task;

    if (selectedTasks.includes(task)) {
      newSelectedTasks = selectedTasks.filter((selectedTask) => selectedTask !== task);
      Segment.visitSchedulingAction("tasks", "deselected task", taskName, papaId);
    } else {
      newSelectedTasks = [...selectedTasks, task];
      Segment.visitSchedulingAction("tasks", "selected task", taskName, papaId);
    }

    setSelectedTasks(newSelectedTasks);
    helpers.setValue(newSelectedTasks);
    onChange && onChange(newSelectedTasks);
  };

  const setHasRelocationTasksValue = (taskIds: string[]) => {
    const relocationTaskIds = tasks
      .filter(({ name }) => name && relocationTaskNames.includes(name))
      .map(({ id }) => id);
    hasRelocationTasksHelpers.setValue(
      taskIds.some((taskId) => relocationTaskIds.includes(taskId))
    );
  };

  const setHasUberTasksValue = (taskIds: string[]) => {
    const uberTaskIds = tasks
      .filter(({ name }) => name && uberTasks.includes(name))
      .map(({ id }) => id);
    hasUberTasksHelpers.setValue(taskIds.some((taskId) => uberTaskIds.includes(taskId)));
  };

  const setHasOvernightTransportationTasksValue = (taskIds: string[]) => {
    const overnightTransportationTaskIds = tasks
      .filter(({ name }) => name && overnightTransportationTasks.includes(name))
      .map(({ id }) => id);
    hasOvernightTransportationTasksHelpers.setValue(
      taskIds.some((taskId) => overnightTransportationTaskIds.includes(taskId))
    );
  };

  const setHasDeliveryTasksValue = (taskIds: string[]) => {
    const groceryTaskId = tasks.find(({ name }) => name === "Grocery Shopping")?.id;
    const medicationTaskId = tasks.find(({ name }) => name === "Medication/RX")?.id;
    hasGroceryTaskHelpers.setValue(taskIds.includes(groceryTaskId ?? ""));
    hasMedicationTaskHelpers.setValue(taskIds.includes(medicationTaskId ?? ""));
  };

  const setSelectedTaskNames = (taskIds: string[]) => {
    const findTaskById = (taskId: string) => tasks.find((task) => task.id === taskId);
    const selectedTasks = taskIds.map((id) => findTaskById(id)?.name);

    taskNameHelpers.setValue(selectedTasks);
  };

  useEffect(() => {
    if (!loading && tasks.length) {
      const newSelectedTasks = tasks
        .filter(
          (task) =>
            permanentTaskIds.includes(task.id) ||
            (selectedTasks.includes(task.id) && !task.disableReason)
        )
        .map((task) => task.id);

      setSelectedTasks(newSelectedTasks);
      helpers.setValue(newSelectedTasks);
    }
    // eslint-disable-next-line
  }, [tasks]);

  useEffect(() => {
    if (tasks.length > 0) {
      setHasRelocationTasksValue(value);
      setHasUberTasksValue(value);
      setHasDeliveryTasksValue(value);
      setHasOvernightTransportationTasksValue(value);
      setSelectedTaskNames(value);

      setSelectedTasks(value);
    }
    // eslint-disable-next-line
  }, [tasks, value]);

  if (loading) {
    return (
      <PlaceholderWrapper>
        <ContentLoader viewBox="0 0 1000 260">
          <rect x="48" y="0" rx="5" ry="5" width="66" height="67" />
          <rect x="41" y="85" rx="4" ry="4" width="82" height="12" />
          <rect x="210" y="0" rx="5" ry="5" width="66" height="67" />
          <rect x="202" y="85" rx="4" ry="4" width="82" height="12" />
          <rect x="372" y="0" rx="5" ry="5" width="66" height="67" />
          <rect x="365" y="85" rx="4" ry="4" width="82" height="12" />
          <rect x="534" y="0" rx="5" ry="5" width="66" height="67" />
          <rect x="527" y="85" rx="4" ry="4" width="82" height="12" />
          <rect x="696" y="0" rx="5" ry="5" width="66" height="67" />
          <rect x="689" y="85" rx="4" ry="4" width="82" height="12" />
          <rect x="858" y="0" rx="5" ry="5" width="66" height="67" />
          <rect x="851" y="85" rx="4" ry="4" width="82" height="12" />
          <rect x="48" y="130" rx="5" ry="5" width="66" height="67" />
          <rect x="41" y="215" rx="4" ry="4" width="82" height="12" />
          <rect x="210" y="130" rx="5" ry="5" width="66" height="67" />
          <rect x="202" y="215" rx="4" ry="4" width="82" height="12" />
          <rect x="372" y="130" rx="5" ry="5" width="66" height="67" />
          <rect x="365" y="215" rx="4" ry="4" width="82" height="12" />
          <rect x="534" y="130" rx="5" ry="5" width="66" height="67" />
          <rect x="527" y="215" rx="4" ry="4" width="82" height="12" />
        </ContentLoader>
      </PlaceholderWrapper>
    );
  }

  return (
    <>
      <QueryErrors error={taskListError} />

      <Wrapper data-testid="task-buttons">
        {tasks.map((task) => {
          const Icon = (task.icon && iconsDict[task.icon]) || Tasks;
          const isSelected = selectedTasks.includes(task.id);
          return (
            <Task key={task.id} small={small}>
              <ButtonIcon
                type="button"
                isSelected={isSelected}
                onClick={handleToggleTasks(task.id)}
                disabled={isSubmitting || !!task.disableReason}
                aria-label={task.name ?? undefined}
              >
                <Tooltip title={task.disableReason!}>
                  <Icon size={small ? 24 : 32} />
                </Tooltip>
              </ButtonIcon>
              <Text size="small">{task.name}</Text>
            </Task>
          );
        })}
      </Wrapper>
      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const Task = styled.div<{ small?: boolean }>`
  ${({ small }) =>
    small
      ? css`
          width: 4.8rem;
          margin-left: 0.3rem;
          margin-right: 0.3rem;
          margin-bottom: 1rem;
          text-align: center;
        `
      : css`
          width: 6.1rem;
          margin-left: 1rem;
          margin-right: 1rem;
          margin-bottom: 1.25rem;
          text-align: center;
        `};
`;

type ButtonIconProps = {
  isSelected?: boolean;
};

const ButtonIcon = styled(Button)<ButtonIconProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #222428;
  font-weight: normal;
  background-color: #d7d7d7;
  border-color: #d7d7d7;
  padding: 8px;
  border-radius: 20px;
  margin-bottom: 10px;

  ${StyledIconBase} {
    color: #222428;
  }

  &:disabled {
    opacity: 1;
    svg {
      opacity: 0.5;
    }
    &:hover {
      background-color: #8694b9;
      border-color: #8694b9;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.variants.primary};
    border-color: ${({ theme }) => theme.variants.primary};

    ${StyledIconBase} {
      color: white;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.variants.primary};
      border-color: ${({ theme }) => theme.variants.primary};

      ${StyledIconBase} {
        color: white;
      }
    `}
`;

const PlaceholderWrapper = styled.div`
  max-width: 1000px;
  height: 260px;
`;

export default TaskButtons;
