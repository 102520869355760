import { useMutation } from "@apollo/client";
import { Formik, FormikProps, useField } from "formik";
import React, { ReactElement, useRef } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { GET_VISITS_LIST } from "components/DataTables/VisitsTable/gql";
import Form from "components/Form";
import InputDate from "components/InputDate";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Select from "components/Select";
import { visitRecurrenceWeekdayOptions } from "constants/visit";
import { VisitRecurrenceFrequency } from "generated/types";
import { CREATE_VISIT_RECURRENCE } from "pages/ScheduleVisit/gql";
import { isDayBefore } from "utils/isDayBefore";
import { mapSelectValuesToOptions } from "utils/select";

import { Schema, schema } from "./schema";

interface Props {
  id: string;
  isOpen: boolean;
  toggle: () => void;
}

enum VisitEndType {
  never = "NEVER",
  onDate = "ON_DATE",
  occurences = "OCCURENCES",
}

const visitRecurrenceFrequencyOptions = [
  { label: "Daily", value: VisitRecurrenceFrequency.Daily },
  { label: "Weekly", value: VisitRecurrenceFrequency.Weekly },
  { label: "Bi-Weekly", value: VisitRecurrenceFrequency.Fortnightly },
  { label: "Monthly", value: VisitRecurrenceFrequency.Monthly },
];

const endsOptions = [
  { label: "Never", value: VisitEndType.never },
  { label: "On date", value: VisitEndType.onDate },
  { label: "After number of occurrences", value: VisitEndType.occurences },
];

const MakeRecurringVisitModal = ({ id, isOpen, toggle }: Props): ReactElement => {
  const formikRef = useRef<FormikProps<Schema>>(null);
  const [createVisitRecurrence, { loading }] = useMutation(CREATE_VISIT_RECURRENCE, {
    refetchQueries: [{ query: GET_VISITS_LIST }],
  });

  const initialValues: Schema = {
    freq: VisitRecurrenceFrequency.Daily,
    byDay: null,
    ends: VisitEndType.never,
  };

  const handleSubmit = async (values: Schema) => {
    const { freq, byDay: byDayValue, ends, count, validUntil } = values;
    const byDay =
      freq === VisitRecurrenceFrequency.Fortnightly || freq === VisitRecurrenceFrequency.Weekly
        ? byDayValue
        : null;
    const input = {
      freq,
      byDay,
      templateVisitId: id,
      validUntil: ends === VisitEndType.onDate ? validUntil : undefined,
      count: ends === VisitEndType.occurences ? count : undefined,
    };

    try {
      const { data } = await createVisitRecurrence({ variables: { input } });

      if (data?.createVisitRecurrence?.data?.id) {
        toast.success("Visit recurrence made successfully");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Make Recurring Visit" isOpen={isOpen}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label>Visit Recurrence Frequency</Form.Label>
                <Select
                  name="freq"
                  defaultValue={mapSelectValuesToOptions(
                    initialValues.freq,
                    visitRecurrenceFrequencyOptions
                  )}
                  options={visitRecurrenceFrequencyOptions}
                  isSearchable={false}
                />
              </Form.Group>

              {(values.freq === VisitRecurrenceFrequency.Fortnightly ||
                values.freq === VisitRecurrenceFrequency.Weekly) && (
                <Form.Group controlId="byDay">
                  <Form.Label>
                    {values.freq === VisitRecurrenceFrequency.Fortnightly
                      ? "Bi-weekly "
                      : "Weekly "}
                    every
                  </Form.Label>
                  <Select
                    name="byDay"
                    options={visitRecurrenceWeekdayOptions}
                    isSearchable={false}
                    placeholder="Select by day"
                    isMulti
                  />
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>Ends</Form.Label>
                <Select
                  name="ends"
                  defaultValue={mapSelectValuesToOptions(initialValues.ends, endsOptions)}
                  options={endsOptions}
                  isSearchable={false}
                />
              </Form.Group>
              <Form.Group>
                <DateEndInput />
                <OccurenceInput />
              </Form.Group>
            </ModalContainer>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button type="submit" disabled={loading} variant="primary">
                {loading && <SpinningIcon size={18} />}
                Save
              </Button>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};

const DateEndInput = () => {
  const [field] = useField("ends");
  return field.value === VisitEndType.onDate ? (
    <TallDiv>
      <InputDate id="validUntil" isOutsideRange={isDayBefore} name="validUntil" />
    </TallDiv>
  ) : null;
};

const OccurenceInput = () => {
  const [field] = useField("ends");
  return field.value === VisitEndType.occurences ? (
    <>
      <Form.Input type="number" name="count" placeholder="Number of occurrences" />
    </>
  ) : null;
};

const TallDiv = styled("div")`
  margin-bottom: 18.75rem;
`;

export default MakeRecurringVisitModal;
