import { useQuery } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import QueryErrors from "components/QueryErrors";
import Table from "components/Table";
import { AccountSingleResult } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

import { GET_MEMBER_DEVICES } from "../gql";

const Devices = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<{
    account?: AccountSingleResult | null;
  }>(GET_MEMBER_DEVICES, {
    variables: { id },
  });

  const devices = data?.account?.data?.deviceTokens?.data || [];

  return (
    <>
      <Helmet>
        <title>Member Devices - Papa Admin</title>
      </Helmet>

      <QueryErrors error={error} />

      <Table>
        <Table.Head>
          <Table.HeadCell>Manufacturer</Table.HeadCell>
          <Table.HeadCell>Model</Table.HeadCell>
          <Table.HeadCell>System version</Table.HeadCell>
          <Table.HeadCell>System name</Table.HeadCell>
          <Table.HeadCell>App version</Table.HeadCell>
          <Table.HeadCell>Platform</Table.HeadCell>
          <Table.HeadCell>Updated</Table.HeadCell>
        </Table.Head>

        <Table.Body>
          {loading && <Table.Placeholder rows={3} columns={7} />}

          {!devices?.length && !loading && (
            <Table.Row>
              <Table.Cell colSpan={7} align="center" noWrap>
                No data to show
              </Table.Cell>
            </Table.Row>
          )}

          {devices.map((item) => {
            const { id, info, platform, updatedAt } = item ?? {};
            const { manufacturer, model, systemVersion, systemName, appVersion } = info ?? {};

            return (
              <Table.Row key={id}>
                <Table.Cell noWrap>{manufacturer}</Table.Cell>
                <Table.Cell noWrap>{model}</Table.Cell>
                <Table.Cell noWrap>{systemVersion}</Table.Cell>
                <Table.Cell noWrap>{systemName}</Table.Cell>
                <Table.Cell noWrap>{appVersion}</Table.Cell>
                <Table.Cell noWrap>{platform}</Table.Cell>
                <Table.Cell noWrap>{formatDateTime(updatedAt)}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default Devices;
