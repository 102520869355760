import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Button from "components/Button";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import QueryErrors from "components/QueryErrors";
import Table from "components/Table";
import { AccountSingleResult } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useModalToggle } from "hooks/useModalToggle";
import { formatDateTime } from "utils/helpers/formatters";

import { GET_PAL_NOTES } from "../../gql";
import { AddCommentForm } from "./AddComments/AddComments";

const Activity = () => {
  const { kebabCased } = useCallItClientProgramOrBusiness();

  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useQuery<{
    account?: AccountSingleResult | null;
  }>(GET_PAL_NOTES, {
    variables: { id: id },
  });

  const { isOpen, toggle: toggleModal } = useModalToggle();

  const notes = data?.account?.data?.pal?.data?.notes?.data;
  const palId = data?.account?.data?.pal?.data?.id;
  const isHavePalAssociated = !!palId;

  const handleAddNewNote = () => {
    refetch();
    toggleModal();
  };

  return (
    <>
      <Helmet>
        <title>Member Activity - Papa Admin</title>
      </Helmet>
      <Heading color="primary">Activity Feed</Heading>

      {isHavePalAssociated && (
        <Button variant="primary" action onClick={toggleModal}>
          Add comment
        </Button>
      )}

      <QueryErrors error={error} />

      <Table>
        <Table.Head>
          <Table.HeadCell>Comment</Table.HeadCell>
          <Table.HeadCell>Timestamp</Table.HeadCell>
          <Table.HeadCell>Created by</Table.HeadCell>
        </Table.Head>

        <Table.Body>
          {loading && <Table.Placeholder rows={3} columns={3} />}

          {!notes?.length && !loading && (
            <Table.Row>
              <Table.Cell colSpan={3} align="center" noWrap>
                No data to show
              </Table.Cell>
            </Table.Row>
          )}

          {notes?.map((item, index) => {
            const { content, insertedAt, creator } = item ?? {};

            return (
              <Table.Row key={`note_${index}`}>
                <Table.Cell width="45%">{content}</Table.Cell>
                <Table.Cell>{formatDateTime(insertedAt)}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={
                      creator?.data?.type === "BUSINESS"
                        ? `/${kebabCased.pluralEntityName}/${creator?.data?.id}`
                        : `/members/${creator?.data?.id}`
                    }
                  >
                    {creator?.data?.fullName}
                  </Link>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {isHavePalAssociated && !!palId && (
        <ModalLayout title="Add a comment" isOpen={isOpen} toggle={toggleModal}>
          <AddCommentForm accountId={id} palId={palId} onEditFinish={handleAddNewNote} />
        </ModalLayout>
      )}
    </>
  );
};

export default Activity;
