import React, { FunctionComponent } from "react";

import Form from "components/Form";

import { PapaBioQuestions } from "./papa-bio-questions";

const BioForm: FunctionComponent = () => (
  <>
    {PapaBioQuestions.map((question, index) => (
      <Form.Group key={index}>
        <Form.Label>{question}</Form.Label>
        <Form.TextArea name={`answers[${index}]`} fullWidth />
      </Form.Group>
    ))}
  </>
);

export default BioForm;
