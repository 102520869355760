import { gql } from "@apollo/client";

export const SEARCH_MEMBER = gql`
  query searchMembers($filter: [AccountFilter], $pagination: PaginationInput) {
    accounts(filter: $filter, pagination: $pagination) {
      data {
        id
        fullName
        caregiver {
          data {
            id
          }
        }
      }
    }
  }
`;

export const SEARCH_PARTNER = gql`
  fragment Partner on Partner {
    id
    name
  }

  query SearchPartners(
    $filter: [PartnerFilter!]
    $pagination: PaginationInput
    $sorting: PartnerSorting
  ) {
    partners(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        ...Partner
      }
    }
  }
`;

export const SEARCH_PAL = gql`
  query searchPal($filter: [AccountFilter]) {
    accounts(filter: $filter) {
      data {
        id
        fullName
        phoneNumber
        pal {
          data {
            id
          }
        }
      }
    }
  }
`;

export const PAPA_ASSIGNABLE_PRIOR_PALS = gql`
  query PapaAssignablePals($id: ID!) {
    papa(id: $id) {
      data {
        assignablePals {
          data {
            id
            isFavorite
            fullName
            phoneNumber
            averageRatingForPal
            averageRatingForPapa
            latestVisitScheduledFor
            visitCount
          }
        }
      }
    }
  }
`;
