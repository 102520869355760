import React from "react";

import { Container, Label, StyledRadio } from "./";

type OnlyRadioProps = {
  label: string;
  value?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const OnlyRadio = ({ name, label, ...props }: OnlyRadioProps) => (
  <Container>
    <StyledRadio type="radio" name={name} id={`${label}-${name}`} {...props} />
    <Label htmlFor={`${label}-${name}`}>{label}</Label>
  </Container>
);
