import { useField, useFormikContext } from "formik";
import React from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import Select from "components/Select";
import { useGetServiceRequestFollowupReasonOptionsQuery } from "generated/types";
import { nonNull } from "utils/nonNull";

import { actionsWithReason } from "./schema";

const FollowupReasonSelectGroup = () => {
  const [{ value: action }] = useField("action");
  const { isSubmitting } = useFormikContext();

  const showReason = actionsWithReason.includes(action);

  const { data, loading } = useGetServiceRequestFollowupReasonOptionsQuery({
    variables: { action },
    skip: !showReason,
  });
  const reasonOptions = nonNull(data?.getServiceRequestFollowupReasonOptions);

  if (!showReason) return null;
  if (loading) return <SpinningIcon size={18} />;

  return (
    <Form.Group>
      <Form.Label required>Follow up reason</Form.Label>
      <Select
        aria-label="reason"
        name="reason"
        placeholder="Follow up reason"
        defaultValue={null}
        options={reasonOptions}
        isDisabled={isSubmitting}
        isSearchable={false}
      />
    </Form.Group>
  );
};

export default FollowupReasonSelectGroup;
