import React from "react";

import { BusinessPolicyFragment } from "generated/types";

import { InactiveBusinessPolicy } from "../modals/InactiveBusinessPolicy";
import { DataTableMenuActions } from "./useBusinessPoliciesColumns";

type Props = {
  action: DataTableMenuActions;
  entity: BusinessPolicyFragment | null;
  onClose: () => void;
};

export const BusinessPolicyActions: React.FC<Props> = ({ action, entity, onClose }) => {
  if (!entity?.id || !entity?.account?.data?.id) return null;

  return (
    <>
      <InactiveBusinessPolicy
        businessPolicyId={entity.id}
        accountId={entity.account.data.id}
        isOpen={action === DataTableMenuActions.Inactive}
        onClose={onClose}
      />
    </>
  );
};
