import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Form from "components/Form";
import InputDate from "components/InputDate";
import InputPhone from "components/InputPhone";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { DATE_FORMAT } from "constants/date";
import { genderOptions } from "constants/gender";
import { generatePassword } from "utils/generate-password";
import { mapSelectValuesToOptions } from "utils/select";
import { formatFullName } from "utils/strings/formatFullName";

import { CREATE_ACCOUNT, CREATE_CAREGIVER } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  closeModal: () => void;
  onCreateFinish: () => void;
}

const CreateCaregiverForm = ({ closeModal, onCreateFinish }: Props) => {
  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const [createCaregiver] = useMutation(CREATE_CAREGIVER);

  const initialValues: Schema = {
    fullName: "",
    email: "",
    dateOfBirth: null,
    phoneNumber: "",
    gender: null,
  };

  const handleSubmit = async (formValue: Schema) => {
    try {
      const { data: accountData } = await createAccount({
        variables: {
          input: {
            fullName: formatFullName(formValue.fullName),
            email: formValue.email,
            phoneNumber: formValue.phoneNumber,
            password: generatePassword(20),
          },
        },
      });
      const { data } = await createCaregiver({
        variables: {
          input: {
            accountId: accountData?.createAccount?.data?.id,
            gender: formValue.gender,
            birthDate: formValue.dateOfBirth,
            type: "REGULAR",
          },
        },
      });
      if (data.createCaregiver.data) {
        toast.success("Caregiver was created with success!");
        onCreateFinish();
        closeModal();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <FormRow>
            <FormCol>
              <Form.Group>
                <Form.Label required>Full Name</Form.Label>
                <Form.Input name="fullName" aria-label="fullName" />
              </Form.Group>
            </FormCol>
            <FormCol>
              <Form.Group>
                <Form.Label required>Email</Form.Label>
                <Form.Input name="email" aria-label="email" />
              </Form.Group>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <Form.Group>
                <Form.Label required>Gender</Form.Label>
                <Select
                  name="gender"
                  defaultValue={mapSelectValuesToOptions(initialValues.gender, genderOptions)}
                  options={genderOptions}
                  isSearchable={false}
                  placeholder="Select"
                  aria-label="gender"
                />
              </Form.Group>
            </FormCol>
            <FormCol>
              <Form.Group>
                <Form.Label htmlFor="dateOfBirth" required>
                  Birth date
                </Form.Label>
                <InputDate
                  format={DATE_FORMAT}
                  isOutsideRange={() => false}
                  id="dateOfBirth"
                  name="dateOfBirth"
                />
              </Form.Group>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <Form.Group>
                <Form.Label required htmlFor="phone-number">
                  Phone number
                </Form.Label>
                <InputPhone
                  name="phoneNumber"
                  id="phone-number"
                  defaultCountry="US"
                  aria-label="phoneNumber"
                />
              </Form.Group>
            </FormCol>
          </FormRow>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default CreateCaregiverForm;

const FormRow = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  justify-content: space-between;
`;

const FormCol = styled.div`
  padding: 0 0.5rem;
  width: 50%;
`;
