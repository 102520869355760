import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_INSURANCE_STATUS = gql`
  mutation palApplicationAdmin($input: PalApplicationAdminInput!) {
    palApplicationAdmin(input: $input) {
      data {
        vehicleInsuranceStatus
      }
    }
  }
`;
