import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Heading from "components/Heading";

import ClosedRequests from "./ClosedRequests";
import OpenedRequests from "./OpenedRequests";

type Props = {
  currentPage: number;
  currentLimit: number;
  setPage: (page: number) => void;
};
const ServiceRequests = ({ currentPage, currentLimit, setPage }: Props) => {
  return (
    <div>
      <Helmet>
        <title>Service Requests - Papa Admin</title>
      </Helmet>
      <OpenedRequests />
      <br />
      <ClosedRequests currentPage={currentPage} currentLimit={currentLimit} setPage={setPage} />
    </div>
  );
};

export const StyledHeading = styled(Heading)`
  background-color: ${({ theme }) => theme.variants.primary};
  color: #fff;
  padding-left: 12px;
  cursor: pointer;
`;

export const Arrow = styled.div`
  margin-right: 5px;
`;

export default ServiceRequests;
