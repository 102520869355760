import { useContext } from "react";

import { CurrentUserContext } from "contexts/currentUserContext";

export const useCurrentAccountRole = () => {
  const { data } = useContext(CurrentUserContext);
  const findRole = (name: string) =>
    !!data?.currentAccount?.data?.roles?.data?.find((roleData) => roleData?.name === name);

  return {
    isAdmin: findRole("Admin"),
    isClientAdmin: findRole("ClientAdmin"),
    isReviewer: findRole("Reviewer"),
    isSupervisor: findRole("Supervisor"),
    isBillingSpecialist: findRole("BillingSpecialist"),
    isCareConciergeSpecialist: findRole("CareConcierge"),
    isGrievancesAppealsAdmin: findRole("GrievancesAppealsAdmin"),
    isVisitProgramAdmin: findRole("VisitProgramAdmin"),
    isVisitSuccessAgent: findRole("VisitSuccessAgent"),
    isSuperAdmin: findRole("SuperAdmin"),
    isEngineeringAdmin: findRole("EngineeringAdmin"),
    isPal: findRole("Pal"),
    isReadWriteAdmin: findRole("ReadWriteAdmin"),
    isAgentPal: findRole("AgentPal"),
    isMemberSupportAdmin: findRole("MemberSupportAdmin"),
  };
};
