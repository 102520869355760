import { createContext } from "react";

type Props = {
  controlId: any;
};

const FormContext = createContext<Props>({
  controlId: undefined,
});

export default FormContext;
