import { gql } from "@apollo/client";

export const CLOSEST_PALS_ON_VISIT_LIST = gql`
  query VisitClosestPals(
    $id: ID!
    $visitId: UUID!
    $pagination: PaginationInput
    $sorting: ClosestPalSorting
    $filter: [PalFilter!]
  ) {
    visit(id: $id) {
      data {
        id
        closestPals(pagination: $pagination, sorting: $sorting, filter: $filter) {
          pagination {
            totalCount
            limit
          }
          data {
            insertedAt
            id
            fullName
            phoneNumber
            status
            languages {
              data
            }
            visitScopes(filter: [{ visitId: { eq: $visitId } }]) {
              data {
                distance
              }
            }
            palCalculatedField {
              data {
                id
                completedVisitsLast30Days
              }
            }
          }
        }
      }
    }
  }
`;
