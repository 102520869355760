import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React from "react";
import { useParams } from "react-router-dom";

import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { useGetBusinessPolicyQuery } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";

import EditBusinessPolicyForm from "./EditBusinessPolicyForm";

type ParamsProps = {
  businessId: string;
  businessPolicyId: string;
};

const BusinessPolicyPage = () => {
  const {
    capitalized: { pluralEntityName, singularEntityName },
    kebabCased,
  } = useCallItClientProgramOrBusiness();

  const { businessId, businessPolicyId } = useParams<ParamsProps>();

  const {
    data: businessPolicyData,
    loading: businessPoliciesLoading,
    error: businessPoliciesError,
  } = useGetBusinessPolicyQuery({ variables: { businessPolicyId } });

  const startString = new Date(
    businessPolicyData?.businessPolicy?.data?.startsAt
  ).toLocaleDateString("default", {
    month: "long",
    year: "numeric",
  });
  const endString = new Date(businessPolicyData?.businessPolicy?.data?.endsAt).toLocaleDateString(
    "default",
    {
      month: "long",
      year: "numeric",
    }
  );

  useTopBarOptions({
    title: `Edit ${singularEntityName} Policy ${startString} - ${endString}`,
    icon: AccountCircle,
    breadcrumbs: [
      "Accounts",
      { label: `All ${pluralEntityName}`, link: `/${kebabCased.pluralEntityName}` },
      {
        label: `${businessPolicyData?.businessPolicy?.data?.account?.data?.fullName}`,
        link: `/${kebabCased.pluralEntityName}/${businessId}`,
      },
      {
        label: `${singularEntityName} Policies`,
        link: `/${kebabCased.pluralEntityName}/${businessId}/${kebabCased.singularEntityName}-policies`,
      },
    ],
    deps: [startString, endString],
  });

  return (
    <>
      <EditBusinessPolicyForm
        accountId={businessPolicyData?.businessPolicy?.data?.account?.data?.id!}
        businessId={businessId}
        businessPolicyId={businessPolicyId}
        businessPolicyData={businessPolicyData}
        businessPoliciesLoading={businessPoliciesLoading}
        businessPoliciesError={businessPoliciesError}
      />
    </>
  );
};

export default BusinessPolicyPage;
