import { useField } from "formik";
import React from "react";

import Form from "components/Form";

const S3FolderInput: React.FC = () => {
  const [{ value: lifeS3Folder }, ,] = useField("eligibleLifeS3Folder");
  const [{ value: employeeS3Folder }, ,] = useField("eligibleEmployeeS3Folder");

  const isFirstFolderInput = !(Boolean(lifeS3Folder) || Boolean(employeeS3Folder));

  return (
    <>
      {(isFirstFolderInput || Boolean(lifeS3Folder)) && (
        <Form.Group>
          <Form.Label htmlFor="eligible-life-s3-folder">Eligible Lives Import S3 Folder</Form.Label>
          <Form.Input
            id="eligible-life-s3-folder"
            name="eligibleLifeS3Folder"
            disabled={employeeS3Folder && !lifeS3Folder}
          />
        </Form.Group>
      )}
      {(isFirstFolderInput || Boolean(employeeS3Folder)) && (
        <Form.Group>
          <Form.Label htmlFor="eligible-employee-s3-folder">
            Eligible Employees Import S3 Folder
          </Form.Label>
          <Form.Input
            id="eligible-employee-s3-folder"
            name="eligibleEmployeeS3Folder"
            disabled={lifeS3Folder && !employeeS3Folder}
          />
        </Form.Group>
      )}
    </>
  );
};

export default S3FolderInput;
