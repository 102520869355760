import { mixed, object, string } from "yup";

import { PriorityBonusReason } from "generated/types";

export interface FormValues {
  amountInDollars: string;
  reason: PriorityBonusReason | null;
}

export const schema = object().shape({
  amountInDollars: string().required("Required"),
  reason: mixed().oneOf(Object.values(PriorityBonusReason), "Please select a reason"),
});
