import React from "react";
import styled from "styled-components";

import Text from "components/Text";

interface Props {
  setExperience: (experience: string) => void;
}

export const GeneralExperience: React.FC<Props> = ({ setExperience }) => {
  return (
    <Container>
      <Text size="middle">Tell us about your general experience with member</Text>
      <StyledTextArea
        data-testid="member-experience"
        name="experience"
        placeholder="General experience"
        onChange={(e) => setExperience(e.target.value)}
      />

      <WarningText size="middle">
        Remember! Do not include any member’s Protected Health Information (PHI), in this
        description.
      </WarningText>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 42px;

  > ${Text} {
    margin-bottom: 10px;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 178px;
  border: 1px solid #dfe3e9;
  outline: none;
  border-radius: 4px;
  padding: 10px 14px;
`;

const WarningText = styled(Text)`
  color: #f8a60b;
  margin-top: 17px;
`;
