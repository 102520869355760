import { useState } from "react";

import { useUrlQuery } from "hooks/useUrlQuery";
import { PillData } from "types/search";

export const useSearch = (extraFilter?: { [key: string]: any }) => {
  const { search } = useUrlQuery() as { search: string };
  const initialPills = search?.split(",").map((id) => ({ id, name: id })) ?? [];
  const [pills, setPills] = useState<PillData[]>(initialPills);

  return {
    filter: search ? { id: { in: search.split(",") }, ...extraFilter } : extraFilter,
    pills,
    setPills,
  };
};
