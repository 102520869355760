import { useApolloClient, useQuery } from "@apollo/client";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import Tooltip from "components/Tooltip";
import PalSelect from "components/UserSelect/AsyncPalSelect";
import SyncPalSelect from "components/UserSelect/SyncPalSelect";
import {
  Gender,
  PalSingleResult,
  usePalGenderQuery,
  usePapaAssignablePalsQuery,
} from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useMountEffect } from "hooks/useMountEffect";

import FavoritePals from "./FavoritePals";
import { GET_PAL, PAPA_PREFERENCES } from "./gql";

interface PalAccountData {
  pal: PalSingleResult;
}

export interface PalOption {
  value: string;
  label: string;
  phone?: string;
  gender?: Gender | null;
  isPreferred?: boolean;
}

interface Props {
  disabled: boolean;
  isEditMode: boolean;
}

const PalVisibilityOptions = ({ disabled, isEditMode }: Props) => {
  const client = useApolloClient();
  const { isAdmin, isVisitSuccessAgent, isSupervisor } = useCurrentAccountRole();
  const [, , { setValue: setGenderMismatch }] = useField("genderMismatch");
  const [{ value: palId }, ,] = useField("palId");
  const [{ value: invitePalIds }, , invitePalIdsHelpers] = useField("invitePalIds");
  const [{ value: favoritePalsOnly }, , favoritePalsOnlyHelpers] = useField("favoritePalsOnly");

  const [{ value: papaId }] = useField("papaId");
  const { data, loading } = useQuery(PAPA_PREFERENCES, {
    variables: { papaId },
    skip: !papaId,
  });
  const defaultToFavoritePalsOnly = !!data?.papa?.data?.defaultToFavoritePalsOnly;
  const genderPreference = data?.papa?.data?.genderPreference;
  const genderPreferenceLocked = !!data?.papa?.data?.genderPreferenceLocked;
  const favoritePals = data?.papa?.data?.favoritePals?.data;
  const noFavoritePals = !favoritePals || favoritePals.length === 0;
  const enableAssignPal = isVisitSuccessAgent || isSupervisor;
  const limitAssignPalToPreviousPals = isAdmin && !enableAssignPal;
  const warnAboutPermissions = !(enableAssignPal || limitAssignPalToPreviousPals);

  const { data: palData } = usePalGenderQuery({
    variables: { palId },
    skip: !palId,
  });

  const palGender = palData?.pal?.data?.gender;
  const genderMismatch =
    genderPreferenceLocked && !!genderPreference && !!palId && genderPreference !== palGender;

  const [selectedPal, setSelectedPal] = useState<PalOption>();
  const [isPalLoading, setIsPalLoading] = useState(false);
  const [invitedPals, setInvitedPals] = useState<Array<PalOption>>([]);
  const [isInvitedPalsLoading, setIsInvitedPalsLoading] = useState(false);

  const { data: assignablePalsData, loading: assignablePalsLoading } = usePapaAssignablePalsQuery({
    variables: {
      id: papaId,
    },
    skip: !papaId,
  });

  const assignablePals = assignablePalsData?.papa?.data?.assignablePals?.data ?? [];

  //TODO May have to revert the changes in this file so that things
  //work okay with the feature flag off
  const renderSyncOrAsyncPalSelect = function () {
    if (limitAssignPalToPreviousPals) {
      const props = {
        "aria-label": "assign previous pal",
        name: "palId",
        isDisabled: disabled || assignablePalsLoading,
        pals: assignablePals,
        placeholder: "Select Pal",
      };

      return <SyncPalSelect {...props} />;
    } else {
      const props = {
        "aria-label": "assign any pal",
        name: "palId",
        defaultValue: selectedPal,
        defaultOptions: selectedPal ? [selectedPal] : [],
        isDisabled: disabled || !enableAssignPal,
      };

      return <PalSelect {...props} />;
    }
  };

  useEffect(() => {
    setGenderMismatch(genderMismatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderMismatch]);
  useEffect(() => {
    if (!isEditMode) {
      // should only allow to change it on create or clone
      const favoritePalsOnly = defaultToFavoritePalsOnly && !!favoritePals?.length;
      favoritePalsOnlyHelpers.setValue(favoritePalsOnly);
    }
    // eslint-disable-next-line
  }, [defaultToFavoritePalsOnly, favoritePals, isEditMode]);

  useEffect(() => {
    favoritePalsOnly && invitePalIdsHelpers.setValue(null);
    // eslint-disable-next-line
  }, [favoritePalsOnly]);

  useMountEffect(() => {
    const getSelectedPal = async () => {
      setIsPalLoading(true);

      const { data } = await client.query<PalAccountData>({
        query: GET_PAL,
        variables: {
          id: palId,
        },
      });
      const pal = data?.pal?.data;

      if (pal) {
        setSelectedPal({
          value: pal.id ?? "",
          label: pal?.account?.data?.fullName ?? "",
          phone: pal?.account?.data?.phoneNumber ?? "",
        });
      }

      setIsPalLoading(false);
    };

    const getInvitedPals = async () => {
      setIsInvitedPalsLoading(true);

      const invitedPals = [] as PalOption[];

      for (const palId of invitePalIds) {
        const { data: palData } = await client.query<PalAccountData>({
          query: GET_PAL,
          variables: {
            id: palId,
          },
        });
        const pal = palData?.pal?.data;
        if (pal) {
          invitedPals.push({
            value: pal.id ?? "",
            label: pal?.account?.data?.fullName ?? "",
            phone: pal?.account?.data?.phoneNumber ?? "",
          });
        }
      }

      setInvitedPals(invitedPals);
      setIsInvitedPalsLoading(false);
    };

    if (palId) {
      getSelectedPal();
    }

    if (invitePalIds.length > 0) {
      getInvitedPals();
    }
  });

  return (
    <Container>
      <ScheduleVisitLabel>Pal settings</ScheduleVisitLabel>
      {loading ? (
        <SpinningIcon size={20} />
      ) : (
        <>
          <Form.Group>
            <Form.GroupInline>
              <Form.Inline>
                <Form.Radio
                  controlId="favoritePalsOnly"
                  name="favoritePalsOnly"
                  value={true}
                  label="Preferred Pals"
                  disabled={disabled || noFavoritePals}
                />
              </Form.Inline>
              <Form.Inline>
                <Form.Radio
                  controlId="allPals"
                  name="favoritePalsOnly"
                  value={false}
                  label="All Pals"
                  disabled={disabled}
                />
              </Form.Inline>
            </Form.GroupInline>
          </Form.Group>
          <>
            {favoritePalsOnly ? (
              <FavoritePals favoritePals={favoritePals} />
            ) : (
              <Form.Group>
                <Form.Label>Pals to notify</Form.Label>
                {isInvitedPalsLoading ? (
                  <SpinningIcon size={20} />
                ) : (
                  <PalSelect
                    isDisabled={disabled}
                    placeholder="Search pals"
                    name="invitePalIds"
                    defaultValue={invitedPals}
                    defaultOptions={invitedPals}
                    isMulti
                  />
                )}
              </Form.Group>
            )}
          </>

          {!isEditMode && (
            <Tooltip
              title={
                warnAboutPermissions
                  ? "Your account does not have the role required to perform this action. A supervisor or the IT helpdesk should be able to assist you with gaining the required role."
                  : null
              }
              placement="auto-start"
            >
              <Form.Group>
                <Form.Label>Assigned pal</Form.Label>
                {isPalLoading ? <SpinningIcon size={20} /> : renderSyncOrAsyncPalSelect()}
              </Form.Group>
            </Tooltip>
          )}
        </>
      )}
    </Container>
  );
};

export default PalVisibilityOptions;

const Container = styled.div`
  margin-bottom: 40px;
`;
