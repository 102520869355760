import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import {
  SortDirection,
  VisitForDashboardCardFragment,
  VisitState,
  useVisitsDashboardAtRiskQuery,
} from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useVisitFilterInput } from "pages/Home/VisitFilters/useVisitFilterInput";
import { nonNull } from "utils/nonNull";

import AtRiskVisits from "./AtRiskVisits";
import Filter from "./Filter";
import { defaultStates } from "./constants";

const VisitsDashboard = () => {
  useTopBarOptions({
    title: "Visits Dashboard - Today’s Visits",
    icon: AccessTime,
    breadcrumbs: ["Visits"],
  });
  const { page, limit } = usePageInfo();
  const filters = useVisitFilterInput();
  const commonFilters = {
    ...filters,
    state: filters.state ? filters.state : { in: defaultStates as VisitState[] },
  };

  const [currentTime, setCurrentTime] = useState(moment().toISOString());
  const atRiskFilter = [
    {
      ...commonFilters,
      atRiskVisits: true,
    },
  ];

  const {
    data: atRiskData,
    loading: atRiskLoading,
    error: atRiskError,
  } = useVisitsDashboardAtRiskQuery({
    variables: {
      filter: atRiskFilter,
      sorting: {
        scheduledFor: SortDirection.Asc,
      },
      pagination: { page, limit },
    },
  });

  useEffect(() => {
    setTimeout(() => setCurrentTime(moment().toISOString()), 60000);
  }, [currentTime]);

  const atRiskVisits = nonNull(atRiskData?.visits?.data);
  const uniqueAtRiskVisits = atRiskVisits
    .map(({ id }) => id)
    .filter((id, index, self) => self.indexOf(id) === index)
    .map((id) => atRiskVisits.find((visit) => id === visit.id)) as VisitForDashboardCardFragment[];

  return (
    <>
      <Helmet>
        <title>Visits Dashboard - Today’s Visits</title>
      </Helmet>
      <Filter loading={false} />
      <AtRiskVisits
        visits={uniqueAtRiskVisits}
        loading={atRiskLoading}
        error={atRiskError}
        pagination={atRiskData?.visits?.pagination}
      />
    </>
  );
};

export default VisitsDashboard;
