import { VisitFlag, VisitFlagName, VisitFlagReason } from "generated/types";

export const filterVisitFlags = (visitFlags: VisitFlag[]) => {
  const manuallyMarkedCriticalFlag = visitFlags.find(
    ({ resolvedAt, name, reason }) =>
      !resolvedAt &&
      name === VisitFlagName.Critical &&
      reason === VisitFlagReason.ManuallyMarkedCritical
  );

  const grievancesAppealFlag = visitFlags.find(
    ({ resolvedAt, name }) => !resolvedAt && name === VisitFlagName.GrievancesAppeals
  );

  const needAuditFlag = visitFlags.find(
    ({ resolvedAt, name }) => !resolvedAt && name === VisitFlagName.NeedsAudit
  );

  const visitLeadTimeExceptionFlag = visitFlags.find(
    ({ resolvedAt, name }) => !resolvedAt && name === VisitFlagName.VisitLeadTimeException
  );

  return {
    manuallyMarkedCriticalFlag,
    grievancesAppealFlag,
    needAuditFlag,
    visitLeadTimeExceptionFlag,
  };
};

export const getGrievancesAppealFlagMessage = (grievancesAppealFlag: VisitFlag) =>
  `${
    grievancesAppealFlag.reason === VisitFlagReason.EscalationClientSuccess
      ? "Client Success Escalation: "
      : grievancesAppealFlag.reason === VisitFlagReason.EscalationGrievancesAppeals
      ? "G&A Escalation: "
      : ""
  }${grievancesAppealFlag.description}`;
