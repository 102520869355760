import { ApolloError, LazyQueryExecFunction } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";

import Panel from "components/Panel";
import Text from "components/Text";
import { AdminWaitingRoomTasksQuery } from "generated/types";

type Props = {
  id: string;
  query: LazyQueryExecFunction<AdminWaitingRoomTasksQuery, any>;
  data: AdminWaitingRoomTasksQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

const OnboardingTasks = ({ id, query, data, loading, error }: Props) => {
  if (!data && !error && !loading) {
    query({
      variables: { palOnboardingId: id },
    });
  }

  const taskData = data?.adminWaitingRoomTasks?.data;

  const renderTasks = () => {
    if (!taskData || taskData.length === 0)
      return (
        <>
          <Text>No data to show</Text>
        </>
      );

    return taskData.map((task, index) => (
      <Panel.Item key={index}>
        <Text bold>{task?.name}</Text>
        <Text>{task?.status}</Text>
      </Panel.Item>
    ));
  };
  return (
    <>
      <Helmet title="Prospect Pal Onboarding Tasks" />
      <Panel title="Onboarding Tasks" loading={loading}>
        {renderTasks()}
      </Panel>
    </>
  );
};

export default OnboardingTasks;
