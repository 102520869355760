import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import React from "react";
import Helmet from "react-helmet";

import VisitsTable from "components/DataTables/VisitsTable";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { Pagination, Visit, VisitSorting, useVisitsQuery } from "generated/types";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useSorting } from "hooks/useSorting";

const VisitsWatchList = () => {
  useTopBarOptions({ title: "Watch List", icon: AccessTime, breadcrumbs: ["Visits"] });
  const [visitWatchList] = useLocalStorage<string[]>("visits.watchList", []);
  const [sorting, setSorting] = useSorting<VisitSorting>(["scheduledFor"]);
  const { data, loading, error } = useVisitsQuery({
    variables: {
      filter: {
        id: {
          in: visitWatchList,
        },
      },
      sorting,
    },
  });

  return (
    <div>
      <Helmet>
        <title>Watch List - Papa Admin</title>
      </Helmet>

      <VisitsTable
        data={data?.visits?.data as Visit[]}
        loading={loading}
        sorting={sorting}
        onSortChange={setSorting}
        pagination={data?.visits?.pagination as Pagination}
        error={error}
      />
    </div>
  );
};

export default VisitsWatchList;
