import React from "react";
import { Link } from "react-router-dom";

import { Column } from "components/SmartTable/schema";
import { genderOptions } from "constants/gender";
import { papaStateOptions } from "constants/papaState";
import { PapaConcealed } from "types";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";

export const useColumns = () => {
  const columns: Column<PapaConcealed>[] = [
    {
      header: { title: "Name" },
      dataField: "name",
      formatter(papa) {
        const { fullName, id } = papa;

        return <Link to={`/papas/${id}`}>{fullName}</Link>;
      },
    },
    {
      header: { title: "Business" },
      dataField: "member",
      formatter({ account }) {
        const { fullName: memberName, id: memberId } = account?.data ?? {};

        return <Link to={`/members/${memberId}`}>{memberName}</Link>;
      },
    },
    {
      header: { title: "Phone number" },
      dataField: "phoneNumber",
      formatter({ phoneNumber }) {
        return phoneNumber || "";
      },
    },
    {
      header: { title: "Gender" },
      dataField: "gender",
      formatter({ gender }) {
        return mapValueToLabel(genderOptions, gender) || "";
      },
    },
    {
      header: { title: "Status" },
      dataField: "status",
      formatter({ status }) {
        return mapValueToLabel(papaStateOptions, status) || "";
      },
    },
    {
      header: { title: "Inserted At" },
      dataField: "insertedAt",
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
  ];

  return columns;
};
