import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS = gql`
  query organizations(
    $pagination: PaginationInput
    $sorting: OrganizationSorting
    $filter: OrganizationFilter
  ) {
    organizations(pagination: $pagination, sorting: $sorting, filter: $filter) {
      data {
        id
        name
        insertedAt
        totalAccounts
        accounts {
          data {
            id
            fullName
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
