import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ArrowToggle } from "components/ArrowToggle";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { formatDateTime } from "utils/helpers/formatters";

interface Props {
  timelineChanges: string;
  timezone: string;
}

export const TimelineChanges: React.FC<Props> = ({ timelineChanges, timezone }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const onToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!isCollapsed)
      wrapperRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
  }, [isCollapsed]);

  const timelineChangesObj = (() => {
    try {
      return JSON.parse(timelineChanges);
    } catch {
      return {};
    }
  })();
  const { data_before, data_after } = timelineChangesObj;

  return (
    <>
      {!isEmpty(data_before) ? (
        <div style={{ position: "relative" }} ref={wrapperRef}>
          <ArrowToggle onClick={onToggle} isCollapsed={isCollapsed} />
          {isCollapsed ? (
            <>
              <Text bold>Updated fields</Text>
              <Text>
                {Object.keys(data_before)
                  .map((key) => key)
                  .join(", ")}
              </Text>
            </>
          ) : (
            <TimelineChangesTable>
              <thead>
                <tr>
                  <th>Fields</th>
                  <th>Previous</th>
                  <th>Current</th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(data_before).map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>
                      {formatDateTime(String(data_before[key]), {
                        timezone,
                        defaultToOriginal: true,
                        sourceFormat: moment.ISO_8601,
                      })}
                    </td>
                    <td>
                      {formatDateTime(String(data_after[key]), {
                        timezone,
                        defaultToOriginal: true,
                        sourceFormat: moment.ISO_8601,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TimelineChangesTable>
          )}
        </div>
      ) : (
        EMPTY_PLACEHOLDER
      )}
    </>
  );
};

const TimelineChangesTable = styled.table`
  td {
    &:not(:last-child) {
      padding-right: 32px;
    }
  }
`;
