import React from "react";

import EditButton from "components/EditButton";
import { useModalToggle } from "hooks/useModalToggle";

import OverrideLocation from ".";

interface Props {
  locationId: string;
}

export const OverrideLocationButton = ({ locationId }: Props) => {
  const { isOpen, toggle } = useModalToggle();

  return (
    <>
      <EditButton onClick={toggle}>Override</EditButton>

      <OverrideLocation locationId={locationId} isOpen={isOpen} toggle={toggle} />
    </>
  );
};
