import { gql } from "@apollo/client";

import { visitListFragment } from "components/DataTables/VisitsTable";

export const GET_VISITS_SCHEDULED = gql`
  query VisitsScheduled(
    $filter: [VisitFilter!]
    $pagination: PaginationInput
    $sorting: VisitSorting
  ) {
    visits(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        ...VisitPartsForDataTables
        insertedAt
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
  ${visitListFragment}
`;
