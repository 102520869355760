import React, { useContext } from "react";
import ContentLoader from "react-content-loader";
import styled, { css } from "styled-components";
import { LinkExternal } from "styled-icons/boxicons-regular";

import Text from "components/Text";
import { CurrentUserContext } from "contexts/currentUserContext";

import QueryErrors from "../QueryErrors";
import SidebarContext from "./SidebarContext";

type Props = {
  collapsed: boolean;
};

const UserWidget = () => {
  const { sidebarCollapsed } = useContext(SidebarContext);
  const { data, loading, error } = useContext(CurrentUserContext);

  if (loading) {
    return (
      <LoaderWrapper>
        <ContentLoader
          speed={2}
          width="100%"
          height={35}
          backgroundColor="#f3f3f3"
          foregroundColor="#c0c0c0"
          uniqueKey="dataLoader"
        >
          <rect x="0" y="4" rx="3" ry="3" width="40%" height="10" />
          <rect x="0" y="26" rx="3" ry="3" width="80%" height="10" />
        </ContentLoader>
      </LoaderWrapper>
    );
  }

  if (error) return <QueryErrors error={error} />;

  const { fullName } = data?.currentAccount?.data ?? {};

  return (
    <User collapsed={sidebarCollapsed}>
      {!sidebarCollapsed && (
        <Name>
          <Text medium>Welcome, {fullName}</Text>
        </Name>
      )}

      <PapapediaLink href="https://papa.livepro.com/" target="_blank" collapsed={sidebarCollapsed}>
        <LinkExternal size={sidebarCollapsed ? 24 : 20} />
        {sidebarCollapsed ? <div>Papapedia</div> : " Open Papapedia in new tab"}
      </PapapediaLink>
    </User>
  );
};

const PapapediaLink = styled.a<Props>`
  text-decoration: none;
  color: #2e5bff;
  font-size: ${({ collapsed }) => (collapsed ? "10px" : "13px")};
  font-weight: 400;

  ${({ collapsed }) =>
    collapsed &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    `}
`;

const LoaderWrapper = styled("div")`
  padding: 0 1.5rem;
  margin: 1.5rem 0;
`;

const User = styled("div")<Props>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 1.5rem;
  margin: 1.5rem 0;
  transition: all 0.8ms;
  overflow: hidden;
  white-space: nowrap;
`;

const Name = styled("div")`
  ${Text} {
    font-size: 0.938rem;
  }
`;

export default UserWidget;
