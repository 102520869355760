import { useQuery } from "@apollo/client";
import { Payment as PaymentIcon } from "@styled-icons/material-twotone/Payment";
import React, { useState } from "react";
import Helmet from "react-helmet";

import PaymentInvoiceActions from "components/ActionsMenu/PaymentInvoicesMenu/Actions";
import PaymentInvoiceMassActions from "components/ActionsMenu/PaymentInvoicesMenu/MassActions";
import PaymentInvoicesMassMenu, {
  ActionsMenu,
} from "components/ActionsMenu/PaymentInvoicesMenu/MassMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable, { ItemSelected } from "components/SmartTable";
import { useRowsSelectList } from "components/SmartTable/useRowsSelectList";
import {
  PaymentInvoice,
  PaymentInvoiceListResult,
  PaymentInvoiceSorting,
  SortDirection,
} from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { usePageInfo } from "hooks/usePageInfo";

import { GET_PAYMENT_INVOICES } from "./gql";
import { usePaymentInvoicesTableColumns } from "./usePaymentInvoicesTableColumns";

type Data = {
  paymentInvoices: PaymentInvoiceListResult;
};

const BusinessesPaymentInvoices = () => {
  const {
    capitalized: { pluralEntityName: programsOrBusinesses },
  } = useCallItClientProgramOrBusiness();

  useTopBarOptions({
    title: `${programsOrBusinesses} Payments Invoices`,
    icon: PaymentIcon,
    breadcrumbs: ["Payments"],
  });
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const [sorting, setSorting] = useState<Partial<PaymentInvoiceSorting>>({
    insertedAt: SortDirection.Desc,
  });
  const [massAction, setMassAction] = useState<ActionsMenu | null>(null);
  const { columns, paymentInvoice, action, closeModal } = usePaymentInvoicesTableColumns({
    sorting,
    onSortChange: setSorting,
  });
  const { rowsSelected, onRowSelect, onAllRowsSelect, setRowsSelected } = useRowsSelectList();

  const { data, loading, error } = useQuery<Data>(GET_PAYMENT_INVOICES, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter: { account: { typeEq: "BUSINESS" } },
      sorting,
    },
  });

  const paymentInvoices = data?.paymentInvoices.data;
  const pagination = data?.paymentInvoices.pagination;

  const closeMassModal = () => {
    setMassAction(null);
  };

  const handleSuccess = () => {
    setRowsSelected([]);
  };

  return (
    <>
      <Helmet>
        <title>{programsOrBusinesses} Payments Invoices - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="flex-end">
        <ItemSelected rowsSelected={rowsSelected} />
        <PaymentInvoicesMassMenu isDisabled={!rowsSelected.length} setMassAction={setMassAction} />
      </FlexRow>
      <SmartTable<PaymentInvoice>
        keyField="id"
        data={paymentInvoices}
        pagination={pagination}
        loading={loading}
        columns={columns}
        rowProps={({ state }) =>
          state
            ? {
                paymentInvoiceStateLegend: state,
              }
            : {}
        }
        error={error}
        onRowSelect={onRowSelect}
        onAllRowsSelect={onAllRowsSelect}
        rowsSelected={rowsSelected}
        cursorPagination
      />

      <PaymentInvoiceActions
        paymentInvoice={paymentInvoice}
        action={action}
        closeModal={closeModal}
      />
      <PaymentInvoiceMassActions
        massAction={massAction}
        paymentInvoiceIds={rowsSelected}
        closeModal={closeMassModal}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default BusinessesPaymentInvoices;
