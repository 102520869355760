import { useField } from "formik";
import moment from "moment";
import React, { FunctionComponent } from "react";

import Form from "components/Form";
import { GenderInput } from "components/GenderInput";
import InputDate from "components/InputDate";
import InputPhone from "components/InputPhone";
import Select from "components/Select";
import MemberSelect from "components/UserSelect/MemberSelect";
import { languageOptions } from "constants/language";
import { livingSituationOptions } from "constants/livingSituations";
import { preferredContactsOptions } from "constants/preferredContacts";
import { relationshipOptions } from "constants/relationship";
import { requirementsOptions } from "constants/requirements";
import { warningsOptions } from "constants/warnings";
import { PreferredContactMethod } from "generated/types";

import { MemberIdInput } from "./MemberIdInput";

const BasePapaForm: FunctionComponent = () => {
  const [preferredContactMethodField] = useField("preferredContactMethod");

  return (
    <>
      <Form.Row>
        <Form.Col>
          <Form.Group>
            <Form.Label required>Member or caregiver</Form.Label>
            <MemberSelect />
          </Form.Group>
        </Form.Col>
        <Form.Col>
          <Form.Group>
            <Form.Label required>Relationship</Form.Label>
            <Select
              name="relationship"
              options={relationshipOptions}
              isSearchable={false}
              placeholder="Select relationship"
              aria-label="relationship"
            />
          </Form.Group>
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <MemberIdInput />
        </Form.Col>
        <Form.Col>
          <Form.Group>
            <Form.Label required>Language</Form.Label>
            <Select
              name="language"
              options={languageOptions}
              isSearchable={false}
              placeholder="Select language"
              aria-label="papa language"
            />
          </Form.Group>
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Form.Group>
            <Form.Label required>Full name</Form.Label>
            <Form.Input aria-label="fullName" name="fullName" />
          </Form.Group>
        </Form.Col>
        <Form.Col>
          <Form.Group>
            <Form.Label>Nickname</Form.Label>
            <Form.Input aria-label="nickname" name="nickname" />
          </Form.Group>
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <GenderInput />
        </Form.Col>
        <Form.Col>
          <Form.Group>
            <Form.Label>Date of Birth</Form.Label>
            <InputDate
              id="birthDate"
              name="birthDate"
              isOutsideRange={(day) => day.isAfter(moment())}
              withYear
              block
            />
          </Form.Group>
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Form.Group>
            <Form.Label required>Preferred contact</Form.Label>
            <Select
              name="preferredContactMethod"
              options={preferredContactsOptions}
              aria-label="preferredContactMethod"
              isSearchable={false}
            />
          </Form.Group>
        </Form.Col>
        <Form.Col>
          <Form.Group>
            <Form.Label
              required={preferredContactMethodField.value === PreferredContactMethod.Email}
            >
              Email
            </Form.Label>
            <Form.Input aria-label="email" name="email" />
          </Form.Group>
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Form.Group>
            <Form.Label
              required={
                preferredContactMethodField.value === PreferredContactMethod.CellPhoneNumber
              }
            >
              Mobile number
            </Form.Label>
            <InputPhone name="phoneNumber" aria-label="mobileNumber" />
          </Form.Group>
        </Form.Col>
        <Form.Col>
          <Form.Group>
            <Form.Label
              required={
                preferredContactMethodField.value === PreferredContactMethod.FixedPhoneNumber
              }
            >
              Home number
            </Form.Label>
            <InputPhone name="fixedPhoneNumber" aria-label="homeNumber" />
          </Form.Group>
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Form.Group>
            <Form.Label>Warnings</Form.Label>
            <Select name="warnings" options={warningsOptions} isSearchable={false} isMulti />
          </Form.Group>
        </Form.Col>
        <Form.Col>
          <Form.Group>
            <Form.Label>Requirement</Form.Label>
            <Select
              name="requirements"
              options={requirementsOptions}
              isSearchable={false}
              isMulti
            />
          </Form.Group>
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Form.Group>
            <Form.Label required>Living Situation</Form.Label>
            <Select
              name="livingSituation"
              options={livingSituationOptions}
              isSearchable={false}
              placeholder="Select Living Situation"
              aria-label="livingSituation"
            />
          </Form.Group>
        </Form.Col>
      </Form.Row>
    </>
  );
};

export default BasePapaForm;
