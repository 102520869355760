import { gql } from "@apollo/client";

export const SERVICE_REQUEST_REASSIGN = gql`
  mutation updateServiceRequests($ids: [ID!], $input: ServiceRequestInput!) {
    updateServiceRequests(ids: $ids, input: $input) {
      data {
        id
      }
    }
  }
`;
