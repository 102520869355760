import { useState } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Account } from "generated/types";

export const useMemberActions = () => {
  const [member, setMember] = useState<Account | null>(null);
  const [action, setMemberAction] = useState(DataTableMenuActions.NoAction);

  const setAction = (action: DataTableMenuActions, member: Account | null = null) => {
    setMember(member);
    setMemberAction(action);
  };

  return {
    member,
    action,
    setAction,
  };
};
