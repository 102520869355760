import { useQueryTasks } from "hooks/useQueryTasks";
import { nonNullWithId } from "utils/nonNull";

export const useTaskSelectOptions = () => {
  const { data, loading, error } = useQueryTasks();
  const options = nonNullWithId(data?.tasks?.data).map(({ id, name }) => ({
    label: name ?? "",
    value: id,
  }));

  return {
    options,
    loading,
    error,
  };
};
