import React from "react";

import { FlexRow } from "components/Flex";
import SmartTable from "components/SmartTable";
import { BusinessPolicyFragment, SortDirection, useBusinessPoliciesQuery } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";

import { CreateBusinessPolicy } from "../modals/CreateBusinessPolicy";
import { BusinessPolicyActions } from "./BusinessPolicyActions";
import { useBusinessPoliciesColumns } from "./useBusinessPoliciesColumns";

type Props = {
  accountId: string;
  businessId: string;
};

const BusinessPolicies: React.FC<Props> = ({ accountId, businessId }) => {
  const { kebabCased } = useCallItClientProgramOrBusiness();

  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { action, closeModal, columns, entity } = useBusinessPoliciesColumns();

  const { data, error, loading, refetch } = useBusinessPoliciesQuery({
    variables: {
      filter: {
        accountId: { eq: accountId },
      },
      sorting: {
        startsAt: SortDirection.Asc,
      },
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const handleRefetch = async () => {
    await refetch();
  };

  const businessPolicies = nonNull<BusinessPolicyFragment>(data?.businessPolicies?.data);
  const pagination = data?.businessPolicies?.pagination;

  return (
    <>
      <FlexRow justifyContent="flex-end" wrap="wrap-reverse">
        <CreateBusinessPolicy accountId={accountId} onCreateFinish={handleRefetch} />
      </FlexRow>

      <SmartTable<BusinessPolicyFragment>
        keyField="id"
        localStorageKey="businessPolicies"
        data={businessPolicies}
        pagination={pagination}
        loading={loading}
        error={error}
        columns={columns}
        cursorPagination
        rowProps={({ id }) => ({
          linkTo: `/${kebabCased.pluralEntityName}/${businessId}/${kebabCased.singularEntityName}-policies/${id}`,
        })}
      />

      <BusinessPolicyActions action={action} onClose={closeModal} entity={entity} />
    </>
  );
};

export default BusinessPolicies;
