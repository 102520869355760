import capitalize from "lodash/capitalize";
import React from "react";
import styled from "styled-components";

import EligibleEmployeeActionMenu from "components/ActionsMenu/EligibleEmployeeActionMenu";
import EditButton from "components/EditButton";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { FeatureFlags } from "constants/featureFlags";
import { EligibleEmployee } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { formatDateTime } from "utils/helpers/formatters";

export const useEligibleEmployeesListColumns = (enrollEmployee: (id: string) => void) => {
  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const employerClientColumnHeaderTitle = clientCopyUpdateEnabled
    ? "Employer Client"
    : "Client Name";

  const columns: Column<EligibleEmployee>[] = [
    {
      header: { title: "Employee ID" },
      dataField: "employeeId",
    },
    {
      header: { title: "Full name" },
      dataField: "fullName",
      formatter({ firstName, lastName }) {
        return `${capitalize(firstName!)} ${capitalize(lastName!)}`;
      },
    },
    {
      header: { title: employerClientColumnHeaderTitle },
      dataField: "business.data.name",
    },
    {
      header: { title: "Email" },
      dataField: "email",
    },
    {
      header: { title: "DOB" },
      dataField: "dob",
      formatter({ dob }) {
        return formatDateTime(dob, { format: "L" });
      },
    },
    {
      header: { title: "Location" },
      dataField: "addressLn1",
      formatter({ addressLn1, addressLn2 }) {
        return (
          <>
            <Text>{addressLn1}</Text>
            {addressLn2 && <Text>{addressLn2}</Text>}
          </>
        );
      },
    },
    {
      header: { title: "Zipcode" },
      dataField: "zip",
    },
    {
      header: { title: "Status" },
      dataField: "status",
      formatter({ status }) {
        return capitalize(status!);
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
        noWrap: true,
      },
      formatter({ id, status }) {
        if (status === "pending") {
          return (
            <StyledEditButton onClick={() => enrollEmployee(id!)}>Enroll employee</StyledEditButton>
          );
        }

        return "";
      },
    },
  ];

  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);

  if (supportTicketingEnabled) {
    columns.push({
      header: { title: " " },
      dataField: "actionMenu",
      cellProps: { fixed: true, align: "center" },
      formatter(eligibleEmployee) {
        return (
          eligibleEmployee && (
            <EligibleEmployeeActionMenu eligibleEmployee={eligibleEmployee} isListMenu />
          )
        );
      },
    });
  }

  return columns;
};

const StyledEditButton = styled(EditButton)`
  margin: 0 0.938rem;
`;
