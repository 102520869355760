import styled from "styled-components";

const EditButton = styled.button`
  color: ${({ theme }) => theme.text.primary};
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: inherit;
  padding: 0;
  outline: none;
`;

export default EditButton;
