import { useField } from "formik";
import React from "react";

import ConfirmModal from "components/Modal/ConfirmModal";

import { Schema } from "../schema";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  visitFormValues?: Schema;
  onSubmit: (values: Schema) => Promise<void>;
}

const LongVisitModal: React.FC<Props> = ({ isOpen, toggle, visitFormValues, onSubmit }) => {
  const [, , startedAtTimeHelpers] = useField("startedAtTime");
  const [, , completedAtTimeHelpers] = useField("completedAtTime");

  const handleSubmit = async () => {
    if (visitFormValues) {
      await onSubmit(visitFormValues);
    }
    toggle();
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      header="That's a long visit!"
      onConfirm={handleSubmit}
      onCancel={() => {
        toggle();
        startedAtTimeHelpers.setValue("");
        completedAtTimeHelpers.setValue("");
      }}
      confirmBtnText="Yes"
      cancelBtnText="No"
    >
      Are you sure the visit start and end times are correct?
    </ConfirmModal>
  );
};

export default LongVisitModal;
