import { useField } from "formik";
import { useContext, useEffect, useState } from "react";

import { FeatureFlags } from "constants/featureFlags";
import { Task } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useQueryTasks } from "hooks/useQueryTasks";
import { CurrentBusinessPolicyContext } from "pages/ScheduleVisit/shared/CurrentBusinesPolicyContext";
import { TaskDisableReason } from "types";
import { WithId, nonNull, nonNullWithId } from "utils/nonNull";

import { TaskWithDisableReason, getExcludedTasks, getTasksByVisitType } from "./utils";

export const useTasksList = (setIsMaxTimeReached: (state: boolean) => void) => {
  const callItClientProgram = useIsFeatureEnabled(FeatureFlags.ClientCopyUpdate);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [{ value: isVirtual }] = useField("isVirtual");
  const [{ value: scheduledForDay }] = useField("scheduledForDay");
  const [{ value: overrideBusinessPolicy }] = useField("overrideBusinessPolicy");
  const { data: allTasksData, loading: tasksLoading, error: tasksError } = useQueryTasks();
  const { currentBusinessPolicy, resourceBudget } = useContext(CurrentBusinessPolicyContext);
  // TODO: we need to figure out why using nonNull here cause a memory leak on testing
  const allTasks = allTasksData?.tasks?.data || [];

  useEffect(() => {
    const getTasks = () => {
      let tasksInner = getTasksByVisitType(nonNull(allTasks), isVirtual);

      if (currentBusinessPolicy) {
        const inPersonAllowedTaskIds = nonNullWithId(
          currentBusinessPolicy.inPersonAllowedTasks?.data
        ).map(({ id }) => id);

        const virtualAllowedTaskIds = nonNullWithId(
          currentBusinessPolicy.virtualAllowedTasks?.data
        ).map(({ id }) => id);
        tasksInner = getExcludedTasks(
          getTasksByVisitType(nonNull(allTasks), isVirtual),
          isVirtual ? virtualAllowedTaskIds : inPersonAllowedTaskIds,
          callItClientProgram
            ? TaskDisableReason.clientProgramPolicy
            : TaskDisableReason.businessPolicy
        );
      }

      if (
        !overrideBusinessPolicy &&
        resourceBudget?.remainingMinutesOnDay != null &&
        resourceBudget.remainingMinutesOnDay < 1
      ) {
        setIsMaxTimeReached(true);
        tasksInner = getExcludedTasks(
          getTasksByVisitType(nonNull(allTasks), isVirtual),
          [],
          TaskDisableReason.maxTimeRule
        );
      } else {
        setIsMaxTimeReached(false);
      }

      setTasks(tasksInner);
    };

    if (allTasks.length > 0) {
      if (currentBusinessPolicy) {
        getTasks();
      } else {
        setIsMaxTimeReached(false);
        setTasks(getTasksByVisitType(nonNull(allTasks), isVirtual));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBusinessPolicy,
    resourceBudget,
    allTasks,
    isVirtual,
    scheduledForDay,
    overrideBusinessPolicy,
  ]);

  return {
    tasks: tasks as WithId<TaskWithDisableReason>[],
    loading: tasksLoading,
    error: tasksError,
  };
};
