import { useQuery } from "@apollo/client";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Form from "components/Form";
import GenderPreferences from "components/GenderPreferences";
import Select from "components/Select";
import SwitchButton from "components/SwitchButton";
import PalSelect from "components/UserSelect/AsyncPalSelect";
import { languageOptions } from "constants/language";
import { vehicleTypeOptions } from "constants/vehicle";
import { CheckPalsIfBannedQuery } from "generated/types";
import { mapPalsToOptions } from "pages/Papas/Details/EditPreferenceForm/EditPreferenceForm";
import { nonNull } from "utils/nonNull";
import { Option } from "utils/select";

import { CHECK_PALS_IF_BANNED } from "./gql";

interface Props {
  favoritePals?: Option<string>[];
  genderPreferenceLocked?: boolean | null;
}

const PreferencesForm: React.FC<Props> = ({ favoritePals, genderPreferenceLocked }) => {
  const [selectedFavoritePals, setSelectedFavoritePals] = useState(favoritePals);
  const [{ value: favoritePalIds }, , favoritePalIdsHelpers] = useField<string[] | null>(
    "favoritePalIds"
  );

  const { data } = useQuery<CheckPalsIfBannedQuery>(CHECK_PALS_IF_BANNED, {
    variables: { palIds: favoritePalIds ?? [] },
  });

  const pals = nonNull(data?.pals?.data);

  useEffect(() => {
    if (!pals) return;
    const bannedPals = pals.filter(({ status }) => status === "BANNED");
    const filteredPals = pals.filter(({ status }) => status !== "BANNED");
    const newPalIds =
      favoritePalIds?.filter((favoritePalId: string) =>
        filteredPals.map(({ id }) => id).includes(favoritePalId)
      ) ?? [];

    if (bannedPals.length) {
      bannedPals.forEach(({ fullName }) => toast.error(`${fullName} is a banned pal`));

      favoritePalIdsHelpers.setValue(newPalIds);
      setSelectedFavoritePals(mapPalsToOptions(filteredPals));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoritePalIds, pals]);

  //------------------------------------------------------------
  // RENDERING
  //------------------------------------------------------------
  const renderLanguage = () => {
    return (
      <Form.Group>
        <Form.Label required>Language</Form.Label>
        <Select
          name="languagePreference"
          options={languageOptions}
          isSearchable={false}
          placeholder="Select language"
          aria-label="pal language"
        />
      </Form.Group>
    );
  };

  const renderVehicle = () => {
    return (
      <Form.Group>
        <Form.Label id="vehicle">Vehicle</Form.Label>
        <Select
          name="vehiclePreference"
          aria-labelledby="vehicle"
          options={vehicleTypeOptions}
          isSearchable={false}
          placeholder="Select vehicle type"
        />
      </Form.Group>
    );
  };

  const renderPreferredPals = () => {
    return (
      <Form.Group>
        <Form.Label id="preferred-pals">Preferred Pals</Form.Label>
        <PalSelect
          selectedPals={selectedFavoritePals}
          name="favoritePalIds"
          aria-labelledby="preferred-pals"
          isMulti
        />
      </Form.Group>
    );
  };

  const renderPreferredPalDefault = () => {
    return (
      <Form.Group>
        <Form.Label>Default to Preferred Pals Only</Form.Label>
        <SwitchButton name="defaultToFavoritePalsOnly" label="" />
      </Form.Group>
    );
  };

  return (
    <Form.Fieldset legend="Pal Preferences">
      <Form.Row>
        <Form.Col>{renderLanguage()}</Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <GenderPreferences genderPreferenceLocked={genderPreferenceLocked} />
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>{renderVehicle()}</Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>{renderPreferredPals()}</Form.Col>
        <Form.Col>{renderPreferredPalDefault()}</Form.Col>
      </Form.Row>
    </Form.Fieldset>
  );
};

export default PreferencesForm;
