import { useContext } from "react";

import { BusinessPolicy } from "generated/types";

import { Values } from "../types";
import { CurrentBusinessPolicyContext } from "./CurrentBusinesPolicyContext";

//------------------------------------------------------------------------------
// Uses the current business policy to determine if visits with non-delivery
// transportation tasks ("go with" visits) are allowed. If they are not
// allowed, the pal must do a delivery for the task instead. This primarily
// applies to Grocery and Medication/Rx tasks.
//------------------------------------------------------------------------------
export function isGoWithAllowed(businessPolicy: BusinessPolicy | null) {
  // No business policy means we can do whatever we want
  if (!businessPolicy) {
    return true;
  }

  // The policy does not even allow in-person visits
  if (!businessPolicy.allowInPersonVisits) {
    return false;
  }

  // The policy does not allow in-person transportation tasks
  const allowTransportation =
    businessPolicy.inPersonAllowedTasks?.data?.some(
      (task: any) => task?.name === "Transportation"
    ) || false;

  if (!allowTransportation) {
    return false;
  }

  return true;
}

//------------------------------------------------------------------------------
// A hook that uses the current business policy to determine if "go with"
// visits are allowed. This is a convenience function that wraps the
// isGoWithAllowed function.
//------------------------------------------------------------------------------
export function useIsGoWithAllowed() {
  const { currentBusinessPolicy } = useContext(CurrentBusinessPolicyContext);
  return isGoWithAllowed(currentBusinessPolicy);
}

//------------------------------------------------------------------------------
// "Delivery visits" have to have BOTH a task that supports delivery AND have
// the delivery flag set to true. Because this is essentially a composite
// value, we need to update it at the last moment before submitting, since the
// tasks might have been removed while leaving the delivery flag set to true.
//------------------------------------------------------------------------------
export function setDeliveryBasedOnTasks(values: Values) {
  if (!values.hasMedicationTask && !values.hasGroceryTask) {
    values.delivery = false;
  }
}
