import moment from "moment-timezone";
import * as yup from "yup";

import { mergeDateAndTime } from "utils/date";

export interface Schema {
  partnerId: string;
  startedAtTime: string;
  startedAtDate: string;
  completedAtTime: string;
  completedAtDate: string;
  visitDistance: number | null;
  overrideDurationMins: number | null;
}

export function isCompletedAtTimeAfterStartedAtTime(this: { parent: Schema }) {
  const { startedAtTime, startedAtDate, completedAtTime, completedAtDate } = this.parent;
  if (!startedAtTime || !startedAtDate || !completedAtTime || !completedAtDate) {
    return true;
  }
  const startedAt = mergeDateAndTime({
    day: startedAtDate,
    time: startedAtTime,
  });
  const completedAt = mergeDateAndTime({
    day: completedAtDate,
    time: completedAtTime,
  });

  return moment(completedAt).isAfter(startedAt);
}

export const schema = yup.object().shape({
  partnerId: yup.string().required("Required"),
  startedAtTime: yup.string().required("Required").nullable(),
  startedAtDate: yup.string().required("Required"),
  completedAtTime: yup
    .string()
    .required("Required")
    .nullable()
    .test("completedAtTime", "Must be after start time", isCompletedAtTimeAfterStartedAtTime),
  completedAtDate: yup.string().required("Required"),
  visitDistance: yup.number().nullable().required("Required"),
  overrideDurationMins: yup.number().nullable().required("Required").integer("Must be an integer"),
});
