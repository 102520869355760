type ObjectiveFilterMakerProps = {
  tasks?: string;
};

export const countFilters = (tasks: string | undefined) => {
  let count = 0;
  if (tasks) count++;
  return count;
};

export function objectiveFilterMaker({ tasks }: ObjectiveFilterMakerProps) {
  return {
    ...(tasks?.length
      ? {
          taskId: {
            in: tasks.split(","),
          },
        }
      : {}),
  };
}
