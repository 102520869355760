import React, { useState } from "react";
import Helmet from "react-helmet";

import DataTableDropdownMenu, {
  DataTableMenuActions,
  DataTableMenuItem,
} from "components/DataTableDropdownMenu";
import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { LocationsName } from "constants/location";
import { BaseRate as Entity, useInPersonBaseRateQuery } from "pages/Accounting/accountingMocked";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";

import { Actions } from "./InPersonVisitBaseRateActions";

export const InPersonVisitBaseRate = () => {
  const { data, error, loading } = useInPersonBaseRateQuery();

  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [entity, setEntity] = useState<Entity | null>(null);

  const menuItems = (item: Entity): DataTableMenuItem<DataTableMenuActions>[] => [
    {
      label: "Edit",
      action: DataTableMenuActions.Edit,
    },
  ];

  const columns: Column<Entity>[] = [
    {
      header: { title: "State" },
      dataField: "state",
      formatter(entity) {
        return entity.state ? LocationsName[entity.state] : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Hourly Rate" },
      dataField: "hourlyRate",
      formatter(entity) {
        return entity.hourlyRate ? formatNumberToCurrency(entity.hourlyRate) : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Last Updated" },
      dataField: "updatedAt",
      formatter(entity) {
        return formatDateTime(entity.updatedAt, { format: "MM/DD/YYYY" });
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(item) {
        return (
          <DataTableDropdownMenu<DataTableMenuActions>
            items={menuItems(item)}
            actionHandler={(action) => menuActionsHandler(action, item)}
          />
        );
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, entity: Entity) => {
    setAction(action);
    setEntity(entity);
  };

  const closeActionModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setEntity(null);
  };

  return (
    <>
      <Helmet>
        <title>IPV Base Rate - Papa Admin</title>
      </Helmet>

      <SmartTable<Entity>
        keyField="id"
        data={data?.entity?.data}
        pagination={data?.entity?.pagination}
        loading={loading}
        error={error}
        columns={columns}
        cursorPagination
      />

      <Actions action={action} onClose={closeActionModal} entity={entity} />
    </>
  );
};
