import isUndefined from "lodash/isUndefined";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as segment from "utils/segment";

export default function usePageViews() {
  const location = useLocation();

  return useEffect(() => {
    const [, topLevelPage, maybeId, tab] = location.pathname.split("/");
    const segmentCategory = topLevelPage;
    const pageName = isUndefined(tab) ? topLevelPage : `${topLevelPage}/${tab}`;
    const parameterizedPath = getParametrizedPath(tab, maybeId, topLevelPage);

    const eventProperties = {
      parameterized_path: parameterizedPath,
      path: location.pathname,
      tab_name: tab,
    };

    segment.page(segmentCategory, pageName, eventProperties);
  }, [location]);
}

const pageOrPageWithId = (maybeId: any, topLevelPage: String) => {
  return isUndefined(maybeId) ? topLevelPage : `${topLevelPage}/:id`;
};

const getParametrizedPath = (tab: any, maybeId: any, topLevelPage: String) => {
  return isUndefined(tab) ? pageOrPageWithId(maybeId, topLevelPage) : `${topLevelPage}/:id/${tab}`;
};
