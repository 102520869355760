import React from "react";

import Dropdown from "components/Dropdown";

export enum ActionsMenu {
  NoAction,
  ChangeStatus,
}

type Props = {
  isDisabled: boolean;
  setMassAction: (massAction: ActionsMenu) => void;
};

const PaymentInvoiceMassMenu: React.FC<Props> = ({ setMassAction, isDisabled }) => {
  return (
    <Dropdown.ActionButton isDisabled={isDisabled}>
      <Dropdown.Item onClick={() => setMassAction(ActionsMenu.ChangeStatus)}>
        Change Status
      </Dropdown.Item>
    </Dropdown.ActionButton>
  );
};

export default PaymentInvoiceMassMenu;
