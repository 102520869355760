import { array, object, string } from "yup";

import { Option } from "components/TaskObjectives/TaskObjectives";
import { taskObjectivesSchema } from "components/TaskObjectives/constants";

export const schema = object().shape({
  templateName: string().required("Required"),
  taskIds: array().of(string()).required("Please select at least one task"),
  surveyIds: array().of(string()),
  objectives: taskObjectivesSchema,
});

export interface FormValues {
  templateName: string;
  taskIds: string[];
  surveyIds: string[];
  objectives?: Option[];
}
