import { gql } from "@apollo/client";

export const SET_PAPA_AUTOMATED_CALL_OPT_IN = gql`
  mutation setPapaAutomatedCallOptIn($papaId: ID!, $automatedCallOptIn: Boolean!) {
    updatePapa(id: $papaId, input: { automatedCallOptIn: $automatedCallOptIn }) {
      data {
        id
        automatedCallOptIn
      }
    }
  }
`;
