import { Formik } from "formik";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ButtonLink } from "components/Button";
import { OnlyCheckbox as Checkbox } from "components/Checkbox/OnlyCheckbox";
import { Flex } from "components/Flex";
import { InputContainer as InputStyled } from "components/Form/FormInput";
import { useUrlQuery } from "hooks/useUrlQuery";

export const Filter: React.FC = ({ children }) => {
  const { type } = useUrlQuery();
  const urlQueries = useUrlQuery();

  const history = useHistory();

  const [form, setForm] = useState(
    !type?.length
      ? {
          complaint: true,
          visitComment: true,
          papaComment: true,
          statusChange: true,
        }
      : {
          complaint: type?.includes("MEMBER_COMPLAINT") ?? false,
          visitComment: type?.includes("VISIT_COMMENT") ?? false,
          papaComment: type?.includes("PAPA_COMMENT") ?? false,
          statusChange: type?.includes("PAPA_STATUS_LOG") ?? false,
        }
  );

  const hasFilters =
    history.location.search &&
    !isEqual(Object.keys(queryString.parse(history.location.search)), [
      "afterCursor",
      "beforeCursor",
    ]);

  const handleChange = (newForm: any) => {
    const type = [
      ...(newForm.complaint ? ["MEMBER_COMPLAINT"] : []),
      ...(newForm.visitComment ? ["VISIT_COMMENT"] : []),
      ...(newForm.papaComment ? ["PAPA_COMMENT"] : []),
      ...(newForm.statusChange ? ["PAPA_STATUS_LOG"] : []),
    ];

    const query = queryString.stringify(
      {
        ...urlQueries,
        type,
      },
      { arrayFormat: "index", skipEmptyString: true, skipNull: true }
    );

    const updateQuery =
      !(!query && !history.location.search) && `?${query}` !== history.location.search;

    if (updateQuery) {
      history.push(`?${query}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => debounce(handleChange, 500), [urlQueries]);

  const handleCheckboxToggle = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newForm = {
      ...form,
      [name]: checked,
    };

    setForm(newForm);
    action(newForm);
  };

  const handleCleanFilters = () => {
    setForm({
      complaint: false,
      visitComment: false,
      papaComment: false,
      statusChange: false,
    });
    history.push("?");
  };

  return (
    <Formik initialValues={form} onSubmit={noop} enableReinitialize>
      <Container>
        <Flex gridGap={10} alignItems="center" height={36}>
          <Checkbox
            label="Complaint"
            name="complaint"
            onChange={handleCheckboxToggle}
            checked={form.complaint}
          />
          <Checkbox
            label="Visit Comment"
            name="visitComment"
            onChange={handleCheckboxToggle}
            checked={form.visitComment}
          />
          <Checkbox
            label="Papa Comment"
            name="papaComment"
            onChange={handleCheckboxToggle}
            checked={form.papaComment}
          />
          <Checkbox
            label="Status Change"
            name="statusChange"
            onChange={handleCheckboxToggle}
            checked={form.statusChange}
          />
          {hasFilters && (
            <ButtonLink color="muted" size="small" onClick={handleCleanFilters} nowrap>
              Clean Filters
            </ButtonLink>
          )}
        </Flex>
      </Container>
    </Formik>
  );
};

const Container = styled(Flex).attrs({
  gridGap: 10,
  flexDirection: "column",
  marginTop: 20,
})`
  ${InputStyled} {
    max-width: 14rem;
  }
`;
