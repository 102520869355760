import { useApolloClient, useMutation } from "@apollo/client";
import { useOktaAuth } from "@okta/okta-react";
import { LogOut } from "@styled-icons/boxicons-regular/LogOut";
import { Map } from "@styled-icons/boxicons-regular/Map";
import { ConciergeBell } from "@styled-icons/fa-solid/ConciergeBell";
import { MenuAlt2 } from "@styled-icons/heroicons-outline/MenuAlt2";
import { DocumentText } from "@styled-icons/ionicons-outline/DocumentText";
import { RateReview } from "@styled-icons/material-outlined/RateReview";
import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import { CheckCircle } from "@styled-icons/material-twotone/CheckCircle";
import { Payment } from "@styled-icons/material-twotone/Payment";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Paid } from "styled-icons/material-twotone";

import Button from "components/Button";
import config from "config";
import { FeatureFlags } from "constants/featureFlags";
import { SortDirection, VisitState } from "generated/types";
import { useAreFeaturesEnabled } from "hooks/useAreFeaturesEnabled";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useLocalStorage } from "hooks/useLocalStorage";

import CollapsableNav from "./CollapsableNav";
import NavItem from "./NavItem";
import SidebarContext from "./SidebarContext";
import UserWidget from "./UserWidget";
import { LOGOUT } from "./gql";

type SidebarInnerProps = {
  collapsed: boolean;
};

type ToggleProps = {
  collapsed: boolean;
};

const Sidebar = () => {
  const {
    capitalized: { pluralEntityName: programsOrBusinesses },
    kebabCased,
  } = useCallItClientProgramOrBusiness();

  const client = useApolloClient();
  const history = useHistory();
  const currentUserId = useCurrentAccount()?.id;
  const { isSupervisor, isCareConciergeSpecialist, isGrievancesAppealsAdmin } =
    useCurrentAccountRole();
  const [isSidebarOpen, setSidebarCollapse] = useLocalStorage("sidebar", false);

  const [logout] = useMutation(LOGOUT);
  const { oktaAuth } = useOktaAuth();

  const {
    ShowNavItemMembers,
    ShowNavItemVerifyVaccination,
    ShowNavItemObjectives,
    ShowNavItemLocations,
  } = FeatureFlags;

  const navFeatureFlags = [
    ShowNavItemMembers,
    ShowNavItemVerifyVaccination,
    ShowNavItemObjectives,
    ShowNavItemLocations,
  ];

  const navFeatureFlagStates = useAreFeaturesEnabled(navFeatureFlags);

  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const handleLogout = async () => {
    // Stop the automatic token renewal service
    oktaAuth.stop();

    await logout();
    localStorage.removeItem("token");

    if (config.featureFlag.disableOkta) {
      client.clearStore();
      toast.info("You successfully logged out.");
      history.push("/login");
    } else {
      await oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + "/okta-logout",
      });
    }
  };

  const toggleSideBar = (value: boolean) => setSidebarCollapse(value);

  const clientsLinkCopy = clientCopyUpdateEnabled ? "Clients" : "Organizations";
  const clientsLink = clientCopyUpdateEnabled ? "/clients" : "/organizations";

  return (
    <SidebarContext.Provider value={{ sidebarCollapsed: isSidebarOpen }}>
      <SidebarContainer>
        <SidebarInner collapsed={isSidebarOpen}>
          <MainContent>
            <Toggle
              collapsed={isSidebarOpen}
              size={24}
              onClick={() => setSidebarCollapse(!isSidebarOpen)}
            />

            <UserWidget />

            <CollapsableNav icon={AccessTime} text="Visits" setSidebarCollapsed={toggleSideBar}>
              <NavItem to="/" exact>
                All Visits
              </NavItem>
              <NavItem
                to={`/visits-completed?states=${[VisitState.Completed, VisitState.Reviewed]}&sort=${
                  SortDirection.Desc
                }`}
              >
                Completed
              </NavItem>
              <NavItem to={`/visits-pending?states=${[VisitState.Pending]}`}>Pending</NavItem>
              <NavItem to="/visits-dashboard">Visits Dashboard</NavItem>
              <NavItem to={"/visits-watch-list"}>Watch List</NavItem>
              <NavItem to={"/visit-match"}>Visit Match</NavItem>
              <NavItem to={"/close-out-pov"}>Close Out POV</NavItem>
            </CollapsableNav>

            <CollapsableNav
              icon={AccountCircle}
              text="Accounts"
              setSidebarCollapsed={toggleSideBar}
            >
              <NavItem to="/pals">Pals</NavItem>
              <NavItem to="/papas">Papas</NavItem>
              {config.featureFlag.papasTrial && <NavItem to="/papas-trial">Papas Trial</NavItem>}
              <NavItem to="/caregivers">Caregivers</NavItem>{" "}
              {config.featureFlag.eligibleMembers && (
                <NavItem to="/eligible-members">Eligible Lives</NavItem>
              )}
              <NavItem to="/eligible-employees">Eligible Employees</NavItem>
              <NavItem to="/prospect-pals">Prospect Pals</NavItem>
              {navFeatureFlagStates[ShowNavItemMembers] ? (
                <NavItem to="/members">Members</NavItem>
              ) : null}
              <NavItem to={`/${kebabCased.pluralEntityName}`}>{programsOrBusinesses}</NavItem>
              <NavItem to={clientsLink}>{clientsLinkCopy}</NavItem>
              {navFeatureFlagStates[ShowNavItemVerifyVaccination] ? (
                <NavItem to="/verify-vaccination">Verify Vaccination</NavItem>
              ) : null}
              <NavItem to="/admins">Admins</NavItem>
            </CollapsableNav>

            <CollapsableNav icon={Payment} text="Payments" setSidebarCollapsed={toggleSideBar}>
              <NavItem to="/payments/members-payments">Members</NavItem>
              <NavItem to={`/payments/${kebabCased.pluralEntityName}-payment-invoices/`}>
                {programsOrBusinesses}
              </NavItem>
            </CollapsableNav>
            {config.featureFlag?.accountant && (
              <CollapsableNav icon={Paid} text="Accounting" setSidebarCollapsed={toggleSideBar}>
                <NavItem key="pay-settings" to="/accounting/pal-settings">
                  Pal Settings
                </NavItem>
              </CollapsableNav>
            )}
            {navFeatureFlagStates[ShowNavItemObjectives] ? (
              <NavItem icon={CheckCircle} to="/objectives">
                Objectives
              </NavItem>
            ) : null}

            {navFeatureFlagStates[ShowNavItemLocations] ? (
              <NavItem icon={Map} to="/locations">
                Locations
              </NavItem>
            ) : null}

            <NavItem icon={DocumentText} to="/surveys">
              Surveys
            </NavItem>

            <CollapsableNav icon={RateReview} text="Reviews" setSidebarCollapsed={toggleSideBar}>
              <NavItem to="/blocked-pals" exact>
                Blocked Pals
              </NavItem>
              {(isGrievancesAppealsAdmin || isSupervisor) && (
                <>
                  <NavItem to="/pal-conduct" exact>
                    Pal Conduct
                  </NavItem>
                  <NavItem to="/papa-conduct" exact>
                    Papa Conduct
                  </NavItem>
                </>
              )}
              <NavItem to="/pal-reviews" exact>
                Pal Reviews
              </NavItem>
            </CollapsableNav>

            {isCareConciergeSpecialist && (
              <CollapsableNav
                icon={ConciergeBell}
                text="Care Concierge"
                setSidebarCollapsed={toggleSideBar}
              >
                <NavItem to="/careconcierge-mydashboard">My Service Requests</NavItem>
                <NavItem to={`/careconcierge-myfollowups?&assignedToId=${currentUserId}`}>
                  My Follow Ups
                </NavItem>
                <NavItem to={"/careconcierge-unassigned"}>Unassigned Requests</NavItem>
                {isSupervisor && <NavItem to={"/careconcierge-all"}>All Service Requests</NavItem>}
              </CollapsableNav>
            )}
          </MainContent>
          <Logout>
            <Button onClick={handleLogout}>
              {isSidebarOpen ? <LogOut size={24} /> : "Logout"}
            </Button>
          </Logout>
        </SidebarInner>
      </SidebarContainer>
    </SidebarContext.Provider>
  );
};

const SidebarContainer = styled.div`
  position: relative;
`;

const SidebarInner = styled("div")<SidebarInnerProps>`
  width: ${({ collapsed }) => (collapsed ? "80px" : "235px")};
  background: ${({ theme }) => theme.background.panelContent};
  transition: width 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: overlay;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #888;
  }
`;

const MainContent = styled("div")`
  flex: 1;
`;

const Toggle = styled(MenuAlt2)<ToggleProps>`
  margin: ${({ collapsed }) => (collapsed ? "1.875rem 1.750rem" : "1.875rem")};
  cursor: pointer;
`;

const Logout = styled("div")`
  margin: 1.875rem auto;

  ${Button} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export default Sidebar;
