import React, { ChangeEvent } from "react";
import styled from "styled-components";

import Feedback from "components/Form/FormFeedback";

type Props = {
  value: string;
  onChange: (value: string) => void;
  color?: string;
  id?: string;
  disabled?: boolean;
  dataTestid?: string;
  error?: string;
};

const FormInput: React.FC<Props> = ({ onChange, color, id, value, error }: Props) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <>
      <InputContainer isError={!!error}>
        <StyledInput onChange={handleOnChange} color={color} value={value} id={id} type="input" />
      </InputContainer>
      {!!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

type InputContainerProps = {
  isError?: boolean;
};

type StyledInputProps = {
  color?: string;
};

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid
    ${({ isError, theme }) => {
      if (isError) return theme.variants.danger;
      return theme.borderColor;
    }};
  background-color: white;
  padding: 0;
  border-radius: 0.25rem;
  font-size: ${({ theme }) => theme.fontSizes.middle};

  &:focus-within {
    border: thin solid ${({ theme }) => theme.variants.primary};
  }
`;

export const StyledInput = styled.input<StyledInputProps>`
  flex: 1;
  min-height: 18px;
  font-size: inherit;
  border-radius: 0.25rem;
  border: 0;
  padding: 0.625rem 0.75rem;
  width: 100%;

  color: ${({ color }) => {
    if (color) return color;
  }};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f3f3f3;
  }
`;

export default FormInput;
