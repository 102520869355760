import { useLazyQuery } from "@apollo/client";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

import Select from "components/Select";
import { AccountListResult } from "generated/types";
import { useMountEffect } from "hooks/useMountEffect";
import { GET_ACCOUNTS } from "pages/Papas/List/PerformanceReport/gql";
import { nonNullWithId } from "utils/nonNull";
import { Option, mapSelectValuesToOptions } from "utils/select";

type Data = {
  accounts: AccountListResult;
};

interface Props {
  name: string;
  defaultValue?: string;
  onChange?: (accountId: string | null) => void;
}

export const AccountSearchSelect: React.FC<Props> = ({ name, defaultValue = "", onChange }) => {
  const [userInput, setUserInput] = useState<string>("");
  const [{ value: selectedAccount }, , helpers] = useField(name);
  const [options, setOptions] = useState<Option<string>[]>([]);
  const [getAccounts, { data }] = useLazyQuery<Data>(GET_ACCOUNTS, {
    variables: {
      filter: [
        {
          fullName: { cont: userInput },
          ...(selectedAccount && { id: { eq: selectedAccount } }),
        },
      ],
    },
  });

  useEffect(() => {
    if (selectedAccount || userInput) {
      getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, userInput]);

  useEffect(() => {
    const newOptions = nonNullWithId(data?.accounts?.data).map((data) => {
      return { label: data?.fullName || "", value: data.id };
    });

    setOptions(newOptions);
  }, [data]);

  useMountEffect(() => {
    if (defaultValue) {
      helpers.setValue(defaultValue);
    }
  });

  const handleChange = (newInput: string) => {
    setUserInput(newInput);
  };

  const onAccountChanged = (accountId: any) => {
    if (onChange) {
      onChange(accountId as string);
    }
  };

  return (
    <>
      <Select
        isClearable
        name={name}
        placeholder="Select a member"
        aria-label="member"
        maxMenuHeight={120}
        onChange={onAccountChanged!}
        onInputChange={handleChange}
        defaultValue={mapSelectValuesToOptions(selectedAccount, options)}
        options={options}
      />
    </>
  );
};
