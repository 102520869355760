import * as yup from "yup";

import { WhatTriggeredOption } from "constants/visit";
import { ReasonInput } from "generated/types";

export interface Schema {
  whatTriggered: WhatTriggeredOption;
  reason: ReasonInput;
}

export const schema = yup.object().shape({
  reason: yup.object().shape({
    code: yup.string().nullable().required("Reason is required"),
  }),
});
