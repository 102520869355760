import { useQuery } from "@apollo/client";
import { CaretDown } from "@styled-icons/bootstrap/CaretDown";
import { CaretUp } from "@styled-icons/bootstrap/CaretUp";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

import { FlexRow } from "components/Flex";
import Pagination from "components/Pagination";
import SmartTable from "components/SmartTable";
import { useRowsSelectList } from "components/SmartTable/useRowsSelectList";
import {
  ServiceRequestFollowup,
  ServiceRequestFollowupListResult,
  ServiceRequestFollowupSorting,
  SortDirection,
} from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import usePagination from "hooks/usePagination";
import { Arrow, StyledHeading } from "pages/Papas/Details/CareConcierge/List";

import { GET_MY_FOLLOWUPS } from "./gql";
import { useFollowUpsTableColumns } from "./useFollowUpsTableColumns";

interface Data {
  serviceRequestFollowups: ServiceRequestFollowupListResult;
}

const OpenedFollowUps = () => {
  const currentUserId = useCurrentAccount()?.id;
  const location = useLocation();
  const [sorting, setSorting] = useState<ServiceRequestFollowupSorting>({
    dueAt: SortDirection.Asc,
  });
  const [isCollapsed, setCollapsed] = useState(false);
  const { rowsSelected, onRowSelect, onAllRowsSelect } = useRowsSelectList();
  const { currentPage, setPage, currentLimit } = usePagination({ page: 1, limit: 10 });
  const { columns } = useFollowUpsTableColumns({
    sorting,
    onSortChange: setSorting,
  });
  const { data, loading, error, refetch } = useQuery<Data>(GET_MY_FOLLOWUPS, {
    variables: {
      filter: {
        assignedToId: {
          eq: currentUserId,
        },
        completedAt: {
          eq: null,
        },
      },
      pagination: { page: currentPage, limit: currentLimit },
      sorting,
    },
  });
  const totalPages = data?.serviceRequestFollowups?.pagination?.totalPages ?? 0;
  const toggle = () => {
    isCollapsed ? setCollapsed(false) : setCollapsed(true);
  };
  useEffect(() => {
    const handleRefetch = async () => {
      await refetch();
    };
    if (location.pathname === "/careconcierge-myfollowups") {
      handleRefetch();
    }
  }, [location.pathname, currentPage, refetch]);
  const servicerequestfollowups = data?.serviceRequestFollowups;
  return (
    <div>
      <Helmet>
        <title>Service Requests - Papa Admin</title>
      </Helmet>
      <StyledHeading onClick={toggle}>
        <FlexRow justifyContent="space-between">
          <div>Open Follow Ups ({data?.serviceRequestFollowups?.pagination?.totalCount ?? 0})</div>
          <Arrow>{isCollapsed ? <CaretUp size={18} /> : <CaretDown size={18} />}</Arrow>
        </FlexRow>
      </StyledHeading>
      {!isCollapsed && (
        <>
          <SmartTable<ServiceRequestFollowup>
            keyField="id"
            data={servicerequestfollowups?.data}
            loading={loading}
            columns={columns}
            error={error}
            onRowSelect={onRowSelect}
            onAllRowsSelect={onAllRowsSelect}
            rowsSelected={rowsSelected}
          />
        </>
      )}
      {!isCollapsed && totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          itemsAfterAndBefore={3}
          onChangePage={setPage}
        />
      )}
    </div>
  );
};

export default OpenedFollowUps;
