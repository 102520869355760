import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { useCaregiverAboutQuery } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

const About: React.FC = () => {
  const { caregiverId } = useParams<{ caregiverId: string }>();

  const { data, loading, error } = useCaregiverAboutQuery({
    variables: { caregiverId },
  });

  const account = data?.caregiver?.data?.account;

  if (loading && !account?.data) return <p>loading...</p>;

  const { insertedAt, updatedAt } = account?.data ?? {};

  return (
    <>
      <Helmet>
        <title>Caregiver About - Papa Admin</title>
      </Helmet>

      <QueryErrors error={error} />

      <Grid>
        <Column>
          <Panel>
            {!!insertedAt && (
              <Panel.Item>
                <Text>Joined at</Text>
                <Text>{formatDateTime(insertedAt)}</Text>
              </Panel.Item>
            )}
            {!!updatedAt && (
              <Panel.Item>
                <Text>Updated at</Text>
                <Text>{formatDateTime(updatedAt)}</Text>
              </Panel.Item>
            )}
          </Panel>
        </Column>
      </Grid>
    </>
  );
};

type ColumnProps = {
  size?: number;
};

const Grid = styled("div")`
  display: flex;
  margin: 0 -0.938rem;
`;

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
  margin: 0 0.938rem;
`;

export default About;
