import { useApolloClient, useQuery } from "@apollo/client";
import { useField } from "formik";
import React, { FunctionComponent, useEffect } from "react";

import { Account, AccountListResult } from "generated/types";

import { SEARCH_MEMBER } from "./gql";
import UserSelect from "./index";

interface Data {
  accounts: AccountListResult;
}

interface Props {
  placeholder?: string;
}

const MemberSelect: FunctionComponent<Props> = ({ placeholder = "Select Member" }) => {
  const client = useApolloClient();

  const [{ value: accountId }] = useField("accountId");
  const [, , caregiverIdHelpers] = useField("caregiverId");

  const { data } = useQuery<Data>(SEARCH_MEMBER, {
    variables: {
      filter: {
        id: { eq: accountId },
      },
    },
  });

  const selectedAccount = accountId ? data?.accounts?.data?.[0] : null;

  async function handleLoadOptions(value: string) {
    const { data } = await client.query({
      query: SEARCH_MEMBER,
      variables: {
        filter: [
          { fullName: { cont: value }, deletedAt: { eq: null } },
          { email: { cont: value }, deletedAt: { eq: null } },
        ],
      },
    });
    if (data?.accounts?.data) {
      return data.accounts.data.map((account: Account) => ({
        accountId: account.id,
        value: account.id,
        label: account.fullName,
        caregiverId: account.caregiver?.data ? account.caregiver.data.id : null,
      }));
    }

    return [];
  }

  const handleSelect = (value: any) => {
    caregiverIdHelpers.setValue(value.caregiverId);
  };

  useEffect(() => {
    caregiverIdHelpers.setValue(selectedAccount?.caregiver?.data?.id);
    // eslint-disable-next-line
  }, [selectedAccount]);

  return (
    <UserSelect
      name="accountId"
      value={
        selectedAccount
          ? {
              accountId: selectedAccount.id,
              value: selectedAccount.id,
              label: selectedAccount.fullName,
            }
          : null
      }
      options={
        selectedAccount
          ? [
              {
                accountId: selectedAccount.id,
                value: selectedAccount.id,
                label: selectedAccount.fullName,
              },
            ]
          : undefined
      }
      onSelect={handleSelect}
      onLoadOptions={handleLoadOptions}
      placeholder={placeholder}
      aria-label="member"
    />
  );
};

export default MemberSelect;
