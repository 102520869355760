import React from "react";
import Helmet from "react-helmet";

import { ServiceType } from "constants/service-requests";

import AllServiceRequests from "./AllServiceRequests";
import MyDashboard from "./MyDashboard";
import MyFollowUps from "./MyFollowUps";
import Unassigned from "./Unassigned";

type Props = {
  type: ServiceType;
};

const CareConcierge = ({ type }: Props) => {
  return (
    <div>
      <Helmet>
        <title>Care Concierge - Papa Admin</title>
      </Helmet>
      {type === ServiceType.unassigned && <Unassigned type={type} />}
      {type === ServiceType.all && <AllServiceRequests type={type} />}
      {type === ServiceType.followups && <MyFollowUps />}
      {type === ServiceType.user && <MyDashboard type={type} />}
    </div>
  );
};

export default CareConcierge;
