import { useField, useFormikContext } from "formik";
import moment from "moment";
import { Moment } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { DateRangePicker, DateRangePickerShape, FocusedInputShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import Feedback from "components/Form/FormFeedback";

import "./style.css";

export interface DateRange {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface Props extends Partial<DateRangePickerShape> {
  id: string;
  name: string;
  isOutsideRange?: (day: any) => boolean;
  onChange?: (value: DateRange) => void;
  block?: boolean;
  format?: string;
}

const InputDateRange: React.FC<Props> = ({
  id,
  name,
  displayFormat = "MMM D, YYYY",
  isOutsideRange,
  onChange,
  disabled,
  ...otherProps
}) => {
  const initialRange: DateRange = {
    startDate: null,
    endDate: null,
  };
  const [{ startDate, endDate }, setRange] = useState(initialRange);

  const [focused, setFocused] = useState<FocusedInputShape | null>(null);
  const [{ value }, { touched, error }, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  isOutsideRange = isOutsideRange || ((day) => day.isAfter(moment()));

  useEffect(() => {
    setRange(value);
  }, [value]);

  const handleChange = (value: DateRange) => {
    helpers.setValue(value);

    onChange && onChange(value);
  };

  return (
    <div className={focused ? "DateRangePicker--focused" : undefined}>
      <DateRangePicker
        startDate={startDate}
        startDateId={`${id}_start_date`}
        endDate={endDate}
        endDateId={`${id}_end_date`}
        onDatesChange={handleChange}
        focusedInput={focused}
        onFocusChange={(focusedInput) => setFocused(focusedInput)}
        displayFormat={displayFormat}
        isOutsideRange={isOutsideRange}
        disabled={disabled || isSubmitting}
        showDefaultInputIcon
        small
        {...otherProps}
      />
      {touched && !!error && (
        <Feedback isInvalid>
          {typeof error === "object" ? (Object.values(error)[0] as string) : error}
        </Feedback>
      )}
    </div>
  );
};

export default InputDateRange;
