import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import CommuteMap from "components/CommuteMap";
import Text from "components/Text";
import { VisitRouteDocument, VisitSingleResult } from "generated/types";

const VisitRoute = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery<{
    visit?: VisitSingleResult | null;
  }>(VisitRouteDocument, {
    variables: { id: id },
  });

  if (loading || !data?.visit?.data) {
    return <Text>Loading...</Text>;
  }

  const commutePath = data.visit.data.commutePath?.data ?? [];
  const visitPath = data.visit.data.visitPath?.data ?? [];

  const palHome = data.visit.data.pal?.data?.account?.data?.homeLocation?.data;
  const visitLocation = data.visit.data.location?.data;

  return (
    <>
      <Helmet>
        <title>Visit Route - Papa Admin</title>
      </Helmet>
      {palHome && visitLocation && (
        <h2>
          <a
            href={`https://www.google.com/maps/dir/?api=1&origin=${palHome.lat},${palHome.lng}&destination=${visitLocation.lat},${visitLocation.lng}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Maps: Pal home to visit location
          </a>
        </h2>
      )}
      {commutePath.length || visitPath.length ? (
        <div style={{ height: "900px", width: "100%" }}>
          <CommuteMap commutePath={commutePath} visitPath={visitPath} />
        </div>
      ) : (
        <div>
          <Text>Visit has no route yet</Text>
        </div>
      )}
    </>
  );
};

export default VisitRoute;
