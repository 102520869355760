import { ApolloError } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { capitalize, lowerCase } from "lodash";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { ButtonNew as Button } from "components/Button";
import Conditional from "components/Conditional";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { genderOptions } from "constants/gender";
import { PalOnboarding } from "generated/types";
import { statusOptions } from "pages/Pals/List/ChangeStatus/ChangeStatus.models";
import { formatDateTime, welcomeVideoBooltoText } from "utils/helpers/formatters";
import { mapValueToLabel } from "utils/helpers/formatters";

import { PROMOTE_PROSPECT_PAL } from "../gql";
import { EditProspectPalModal } from "./EditProspectPalModal";

interface Props {
  palOnboarding?: PalOnboarding | null;
  loading: boolean;
  error: ApolloError | undefined;
}

const Overview: FC<Props> = ({ palOnboarding, loading, error }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [promoteProspectPal, { loading: promoteProspectPalLoading }] =
    useMutation(PROMOTE_PROSPECT_PAL);

  if (loading) return <Panel.Side />;
  if (error)
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );

  const handlePromote = async () => {
    if (!window.confirm("Are you sure you want to promote this prospect pal?")) return;

    try {
      const { data } = await promoteProspectPal({
        variables: { prospectPalId: palOnboarding?.id },
      });

      if (data?.promoteProspectPal?.data?.id) {
        toast.success("Promoted prospect pal successfully!");
        history.push("/prospect-pals");
      }
    } catch (error) {
      // @ts-ignore
      const message = error.graphQLErrors?.[0]?.messages?.[0] ?? error.message;
      toast.error(message);
    }
  };

  const {
    providedGender,
    zipcode,
    account,
    id: palOnboardingId,
    bgcStatus,
    providedExpiryDate,
    stage,
    turnProfileUrl,
    hasCompletedOrientationVideo,
  } = palOnboarding ?? {};

  const gender = providedGender;

  const isRejectedorWithdrawn = (bgcStatus: string) =>
    ["withdrawn", "rejected"].includes(bgcStatus);

  const { email, emailVerified, id, pal } = account?.data ?? {};
  const { status, id: palId } = pal?.data ?? { status: "Not Promoted", id: undefined };
  const bgcStatusText = () => {
    if (!!turnProfileUrl) {
      return (
        <a href={turnProfileUrl} target="_blank" rel="noopener noreferrer">
          {capitalize(bgcStatus ?? "")}
        </a>
      );
    } else {
      return <Text>{capitalize(bgcStatus ?? "")}</Text>;
    }
  };

  return (
    <>
      <EditProspectPalModal
        email={email ?? ""}
        id={id ?? ""}
        palOnboardingId={palOnboardingId ?? ""}
        isOpen={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
      />
      <Panel.Side>
        <PanelSideContainer>
          <Panel.Item>
            <Text bold>Pal Onboarding ID</Text>
            <Text>{palOnboardingId ?? EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Conditional show={palId ? true : false}>
            <Panel.Item>
              <Text>
                <a href={`/pals/${palId}`} target="_blank" rel="noopener noreferrer">
                  Onboarded Pal
                </a>
              </Text>
            </Panel.Item>
          </Conditional>

          <Panel.Item>
            <Text bold as="div">
              Status: <ColoredStatus>{mapValueToLabel(statusOptions, status)}</ColoredStatus>
            </Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Stage of application</Text>
            <Text>{capitalize(lowerCase(stage ?? ""))}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Background check status</Text>
            {bgcStatusText()}
          </Panel.Item>

          <Panel.Item>
            <Text bold>Email</Text>
            {email ? (
              <>
                <Text title={email} wrapString>
                  {email}
                </Text>
                <Text size="small">{emailVerified ? "Verified" : "Not verified"}</Text>
              </>
            ) : (
              EMPTY_PLACEHOLDER
            )}
          </Panel.Item>

          <Panel.Item>
            <Text bold>Gender</Text>
            <Text>{gender ? mapValueToLabel(genderOptions, gender) : EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Zip code</Text>
            <Text>{zipcode || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Driver's license expiration</Text>
            <Text>{formatDateTime(providedExpiryDate)}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Welcome video</Text>
            <Text>{welcomeVideoBooltoText(!!hasCompletedOrientationVideo)}</Text>
          </Panel.Item>
        </PanelSideContainer>

        <Buttons>
          <Conditional show={!!bgcStatus && !isRejectedorWithdrawn(bgcStatus)}>
            <Button
              type="button"
              variant="primary"
              onClick={handlePromote}
              disabled={promoteProspectPalLoading}
              block
            >
              {promoteProspectPalLoading ? <SpinningIcon size={32} /> : "Promote Prospect Pal"}
            </Button>
          </Conditional>
          <Button type="button" variant="secondary" onClick={() => setIsOpen(true)} block>
            Edit
          </Button>
        </Buttons>
      </Panel.Side>
    </>
  );
};

const ColoredStatus = styled.div<{ fontSize?: string }>`
  color: ${({ theme }) => theme.text.status};
  font-weight: normal;

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
`;

const PanelSideContainer = styled.div`
  margin-bottom: 2rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default Overview;
