import { gql } from "@apollo/client";

export const UPDATE_BIO = gql`
  mutation UpdatePapaBio($id: ID!, $input: PapaInput!) {
    updatePapa(id: $id, input: $input) {
      data {
        id
        biographyQuestions {
          answer
        }
      }
    }
  }
`;
