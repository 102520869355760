import { gql } from "@apollo/client";

export const CONFIRM_MEMBER = gql`
  mutation memberConfirmVisit($id: ID!) {
    memberConfirmVisit(id: $id) {
      data {
        id
        state
        confirmedAt
      }
    }
  }
`;
