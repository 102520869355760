import { Account } from "generated/types";
import { PapaConcealed } from "types";

export const mapMemberToOption = (item: PapaConcealed | Account) => ({
  value: item.id ?? "",
  label: item.fullName ?? "",
});

export interface MemberOption {
  value: string;
  label: string;
}
