import { gql } from "@apollo/client";

export const GET_ACTIVITY_LOGS = gql`
  query (
    $accountId: ID
    $sorting: AccountActivityLogSorting
    $pagination: PaginationInput
    $subjectAccountId: ID
  ) {
    accountActivityLogs(
      accountId: $accountId
      sorting: $sorting
      pagination: $pagination
      subjectAccountId: $subjectAccountId
    ) {
      data {
        id
        accountId
        account {
          data {
            email
          }
        }
        changes {
          data {
            key
            value
          }
        }
        subject {
          data {
            email
          }
        }
        deviceName
        deviceType
        ipAddress
        operationType
        eventType
        updatedAt
        insertedAt
        data {
          dataText
        }
      }
    }
  }
`;
