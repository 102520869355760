import { ApolloProvider } from "@apollo/client";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import Authentication from "app/Authentication";
import client from "app/client";
import { createGrowthBookInstance } from "app/growthbook";
import { theme } from "app/theme";
import { AdminVersionToast } from "components/AdminVersionToast";
import { Loader } from "components/Loader";
import config, { AppConfig, OktaConfig } from "config";
import usePageViews from "hooks/usePageViews";
import { GlobalStyle } from "utils/themes/config";

if (!config.featureFlag.disableOkta) {
  // Start the automatic token renewal service
  const oktaAuth: OktaAuth = new OktaAuth(OktaConfig);
  oktaAuth.start();
}

const stripe = loadStripe(config.stripeApiKey).catch(() => null);

const googleMapsLibraries: Libraries = ["geometry", "drawing", "places"];

const growthbook = createGrowthBookInstance();

function AppWithoutRouter() {
  usePageViews();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: AppConfig("REACT_APP_GOOGLE_MAP_API"),
    libraries: googleMapsLibraries,
  });

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <ApolloProvider client={client}>
      <GrowthBookProvider growthbook={growthbook}>
        <Elements stripe={stripe}>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <GlobalStyle />
            <AdminVersionToast />
            <Authentication />
          </ThemeProvider>
        </Elements>
      </GrowthBookProvider>
    </ApolloProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppWithoutRouter />
    </BrowserRouter>
  );
}

export default App;
