import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import PalsTable from "components/DataTables/PalsTable";
import { usePalsSorting } from "components/DataTables/PalsTable/usePalSorting";
import { VisitInvitationsDocument, VisitSingleResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";

const PalsInTheArea = () => {
  const { id } = useParams<{ id: string }>();
  const { page, limit } = usePageInfo();
  const [sorting, setSorting] = usePalsSorting();

  const { data, loading, error } = useQuery<{
    visit?: VisitSingleResult | null;
  }>(VisitInvitationsDocument, {
    variables: {
      sorting,
      id,
      pagination: { page, limit },
    },
  });

  const invitationsPals = nonNull(data?.visit?.data?.invitations?.data);
  const pagination = data?.visit?.data?.invitations?.pagination;

  return (
    <>
      <Helmet>
        <title>Invitations - Papa Admin</title>
      </Helmet>

      <PalsTable
        data={invitationsPals}
        pagination={pagination}
        loading={loading}
        error={error}
        sorting={sorting}
        onSortChange={setSorting}
      />
    </>
  );
};

export default PalsInTheArea;
