import * as yup from "yup";

import { ReasonCode } from "constants/visit";

export interface Schema {
  reason: ReasonCode | null;
  description: string;
}

export const schema = yup.object().shape({
  reason: yup.string().nullable().required("Please select reason!"),
  description: yup.string(),
});
