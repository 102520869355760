import { useQuery } from "@apollo/client";
import { Payment as PaymentIcon } from "@styled-icons/material-twotone/Payment";
import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import styled from "styled-components";

import Actions from "components/ActionsMenu/PaymentInvoicesMenu/Actions";
import {
  PaymentInvoiceMenuActions as DataTableMenuActions,
  menuItems,
} from "components/ActionsMenu/PaymentInvoicesMenu/models";
import { usePaymentInvoiceActions } from "components/ActionsMenu/PaymentInvoicesMenu/usePaymentInvoicesActions";
import Breadcrumbs from "components/Breadcrumbs";
import DataTableDropdownMenu from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import { PaymentInvoiceSingleResult } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";

import Activity from "./Activity";
import Items from "./Items";
import Overview from "./Overview";
import { GET_PAYMENT_INVOICE } from "./gql";

interface Data {
  paymentInvoice: PaymentInvoiceSingleResult;
}

const BusinessesPaymentInvoicesDetails = () => {
  const {
    capitalized: { pluralEntityName: programsOrBusinesses },
    kebabCased,
  } = useCallItClientProgramOrBusiness();

  useTopBarOptions({
    title: "Payment Invoice Details",
    icon: PaymentIcon,
    breadcrumbs: [
      "Payments",
      {
        label: `${programsOrBusinesses} Payments Invoices`,
        link: `/payments/${kebabCased.pluralEntityName}-payment-invoices`,
      },
    ],
  });

  const { paymentInvoiceId } = useParams<{ paymentInvoiceId: string }>();
  const { data, loading, error } = useQuery<Data>(GET_PAYMENT_INVOICE, {
    variables: {
      paymentInvoiceId,
    },
  });
  const { action, setAction } = usePaymentInvoiceActions();

  const paymentInvoice = data?.paymentInvoice?.data;

  if (loading) return <Panel.Side />;
  if (error)
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );

  if (!paymentInvoice) {
    return (
      <>
        <h1>404 :-(</h1>
        <p>Payment Invoice not found</p>
      </>
    );
  }

  return (
    <>
      <Breadcrumbs />
      <Container>
        <Overview paymentInvoice={paymentInvoice} />

        <Main>
          <FlexRow>
            <Nav>
              <Nav.Link
                to={`/payments/${kebabCased.pluralEntityName}-payment-invoices/${paymentInvoiceId}/items`}
              >
                Items
              </Nav.Link>
              <Nav.Link
                to={`/payments/${kebabCased.pluralEntityName}-payment-invoices/${paymentInvoiceId}/activity`}
              >
                Activity
              </Nav.Link>
            </Nav>
            <DataTableDropdownMenu
              items={menuItems(paymentInvoice, kebabCased.pluralEntityName)}
              actionHandler={(action) => setAction(action, paymentInvoice)}
              isListMenu={false}
            />
          </FlexRow>
          <Switch>
            <Route
              exact
              path="/payments/businesses-payment-invoices/:paymentInvoiceId/items"
              component={Items}
            />
            <Route
              exact
              path="/payments/client-programs-payment-invoices/:paymentInvoiceId/items"
              component={Items}
            />
            <Route
              exact
              path="/payments/businesses-payment-invoices/:paymentInvoiceId/activity"
              component={Activity}
            />
            <Route
              exact
              path="/payments/client-programs-payment-invoices/:paymentInvoiceId/activity"
              component={Activity}
            />
            <Redirect
              from="/payments/businesses-payment-invoices/:paymentInvoiceId"
              to="/payments/businesses-payment-invoices/:paymentInvoiceId/items"
            />
            <Redirect
              from="/payments/client-programs-payment-invoices/:paymentInvoiceId"
              to="/payments/client-programs-payment-invoices/:paymentInvoiceId/items"
            />
          </Switch>
        </Main>

        <Actions
          paymentInvoice={paymentInvoice}
          action={action}
          closeModal={() => setAction(DataTableMenuActions.noAction)}
        />
      </Container>
    </>
  );
};

const Container = styled("div")`
  display: flex;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

export default BusinessesPaymentInvoicesDetails;
