export enum FeatureFlags {
  AllotmentHours = "allotment_hours",
  AllotmentHoursUpdateOldUi = "allotment_hours_update_old_ui",
  EnableCreatePal = "admin_enable_create_pal",
  EnableMakePal = "admin_enable_make_pal",
  ShowSurveysForPalToConduct = "show_surveys_for_pal_to_conduct",
  SupportTicketing = "support_ticketing",
  ShowNavItemMembers = "admin_show_nav_item_members",
  ShowNavItemVerifyVaccination = "admin_show_nav_item_verify_vaccination",
  ShowNavItemObjectives = "admin_show_nav_item_objectives",
  ShowNavItemLocations = "admin_show_nav_item_locations",
  ClientCopyUpdate = "client_copy_update",
  Credits = "credits",
  HideCreditCards = "hide_credit_cards",
}
