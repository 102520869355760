import React, { ReactElement } from "react";

import { ModalLayout } from "components/Modal/ModalLayout";

import CreateCaregiverForm from "./CreateCaregiverForm";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  onCreateFinish: () => void;
}

const CreateCaregiverModal = ({ isOpen, toggle, onCreateFinish }: Props): ReactElement => {
  return (
    <>
      <ModalLayout toggle={toggle} title="Create Caregiver" width={750} isOpen={isOpen}>
        <CreateCaregiverForm closeModal={toggle} onCreateFinish={onCreateFinish} />
      </ModalLayout>
    </>
  );
};

export default CreateCaregiverModal;
