import React, { FunctionComponent } from "react";

import { ModalLayout } from "components/Modal/ModalLayout";

import EditCompleteVisitForm from "./EditCompletedVisitForm";

interface Props {
  visitId?: string | null;
  isOpen: boolean;
  toggle: () => void;
}

const EditCompletedVisit: FunctionComponent<Props> = ({ visitId, toggle, isOpen }) => {
  if (!visitId) return null;

  return (
    <ModalLayout toggle={toggle} title="Edit Visit" isOpen={isOpen} width={800}>
      <EditCompleteVisitForm visitId={visitId} closeModal={toggle} />
    </ModalLayout>
  );
};

export default EditCompletedVisit;
