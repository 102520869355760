import React from "react";
import { Route, Switch } from "react-router-dom";

import PalDetails from "./Details";
import Pals from "./List";

const PalsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/pals" component={Pals} />
      <Route
        exact
        path={["/pals/:id", "/pals/:id/:tab", "/pals/:id/:tab/:debugTab"]}
        component={PalDetails}
      />
    </Switch>
  );
};

export default PalsRoutes;
