import styled, { css } from "styled-components";

import { cssStringOrPx } from "utils/helpers/cssStringOrPx";

import { InputContainer } from "./FormInput";

const Inline = styled.div<{
  marginVertical?: string | number;
}>`
  padding-left: 0.938rem;
  padding-right: 0.938rem;

  ${({ marginVertical }) =>
    typeof marginVertical !== "undefined"
      ? css`
          margin-top: ${cssStringOrPx(marginVertical)};
          margin-bottom: ${cssStringOrPx(marginVertical)};
        `
      : css`
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        `}

  ${InputContainer} {
    width: 100%;
  }
`;

export default Inline;
