import { ApolloError } from "@apollo/client";
import { useField } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { confirm } from "components/Confirm";
import Link from "components/Link";
import { ConfirmModalIcon } from "components/Modal/ConfirmModal";
import SmartTable from "components/SmartTable";
import { SwitchButtonInput } from "components/SwitchButton";
import { PalWithVisitMetadataResult, useUpdatePreferredPalsMutation } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

interface Props {
  data: PalWithVisitMetadataResult[];
  loading: boolean;
  error?: ApolloError;
  onMakePreferredPal: () => void;
  favoritePalIds: string[];
  nameColumnWidth?: string;
}
export const RecentPalCompletedVisitRatings = ({
  data,
  loading,
  error,
  onMakePreferredPal,
  favoritePalIds,
  nameColumnWidth = "22em",
}: Props) => {
  const [{ value: papaId }] = useField("papaId");

  const [makePreferredPalMutation, { loading: updatePreferredPalLoading }] =
    useUpdatePreferredPalsMutation();

  const makePreferredPal = async (palId?: string | null, palName?: string | null) => {
    if (!palId) return;

    const confirmed = await confirm(
      <>
        Being a Preferred Pal means:
        <ul>
          <li>They will get priority access to all of this member's visits</li>
          <li>They will now automatically receive visit requests and push notifications</li>
        </ul>
      </>,
      {
        header: `Add ${palName} as a Preferred Pal`,
        cancelBtnText: "No, go back",
        confirmBtnText: "Yes, continue",
        icon: ConfirmModalIcon.none,
      }
    );

    if (!confirmed) return;

    try {
      const { data } = await makePreferredPalMutation({
        variables: {
          papaId,
          favoritePalIds: [...favoritePalIds, palId],
        },
      });

      if (data?.updatePapa?.data?.id) {
        toast.success(palName + " was added as a Preferred Pal");
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    await onMakePreferredPal();
  };

  if (data.length === 0) return <StyledEmptyTable>No recent Pals</StyledEmptyTable>;
  return (
    <>
      <SmartTable
        loading={loading}
        error={error}
        keyField="palId"
        showPagination={false}
        columns={[
          {
            header: { title: "Name" },
            dataField: "fullName",
            formatter: ({ fullName, id }) => <Link to={`/pals/${id}`}>{fullName ?? ""}</Link>,
            cellProps: { width: nameColumnWidth },
          },
          {
            header: { title: "Last visit" },
            dataField: "latestVisitScheduledFor",
            formatter: ({ latestVisitScheduledFor }) =>
              formatDateTime(latestVisitScheduledFor, { format: "MMM DD, YYYY" }),
          },
          {
            header: { title: "Member visit rating" },
            dataField: "averageRatingForPapa",
            formatter: ({ averageRatingForPapa }) =>
              averageRatingForPapa ? Math.round(averageRatingForPapa * 100) / 100 + "/5" : "",
          },
          {
            header: { title: "Pal visit rating" },
            dataField: "averageRatingForPal",
            formatter: ({ averageRatingForPal }) =>
              averageRatingForPal ? Math.round(averageRatingForPal * 100) / 100 + "/5" : "",
          },
          {
            header: { title: "Total visits" },
            dataField: "visitCount",
            formatter: ({ visitCount }) => visitCount ?? "",
          },
          {
            header: { title: "Preferred Pal" },
            dataField: "preferredPal",
            formatter: ({ id, fullName }) => (
              <SwitchButtonInput
                checked={false}
                disabled={updatePreferredPalLoading}
                onChange={() => makePreferredPal(id, fullName)}
              />
            ),
          },
        ]}
        data={data}
      />
    </>
  );
};

const StyledEmptyTable = styled.div`
  padding: 20px;
  font-style: italic;
  color: ${({ theme }) => theme.text.muted};
`;
