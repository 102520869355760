import { gql } from "@apollo/client";

export const UPDATE_BUSINESS_ABOUT = gql`
  mutation updateBusinessAccountDescription($id: ID!, $input: AccountInput!) {
    updateAccount(id: $id, input: $input) {
      data {
        id
        about
      }
    }
  }
`;
