import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { PaymentInvoiceItem, PaymentInvoiceSingleResult } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";

import { GET_PAYMENT_INVOICE_ITEMS } from "./gql";

interface Data {
  paymentInvoice?: PaymentInvoiceSingleResult;
}

const Items = () => {
  const { paymentInvoiceId } = useParams<{ paymentInvoiceId: string }>();

  const { data, loading, error } = useQuery<Data>(GET_PAYMENT_INVOICE_ITEMS, {
    variables: { paymentInvoiceId },
  });

  const paymentInvoiceItems = data?.paymentInvoice?.data?.paymentInvoiceItems?.data;

  const columns: Column<PaymentInvoiceItem>[] = [
    {
      header: { title: "Admin Description" },
      dataField: "adminDescription",
    },
    {
      header: { title: "Amount" },
      dataField: "amount",
      formatter: ({ amount }) => formatNumberToCurrency(amount ?? 0),
    },
    {
      header: { title: "Currency" },
      dataField: "currency",
    },
    {
      header: { title: "Created" },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Business Payment Invoices Items - Papa Admin</title>
      </Helmet>

      <SmartTable<PaymentInvoiceItem>
        keyField="id"
        data={paymentInvoiceItems}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default Items;
