import { useQuery } from "@apollo/client";
import { EllipsisH } from "@styled-icons/fa-solid";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import Dropdown from "components/Dropdown";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import { Pagination, VisitProgram } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { formatDateTime } from "utils/helpers/formatters";

import { ChangeProgramStateModal } from "../ChangeProgramStateModal/ChangeProgramStateModal";
import { VisitProgramForm } from "../VisitProgramForm/VisitProgramForm";
import { GET_VISIT_PROGRAMS } from "./gql";

interface Data {
  visitPrograms: {
    data: VisitProgram[];
    pagination: Pagination;
  };
}

const VisitPrograms: React.FC = () => {
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { businessId } = useParams<{ businessId: string }>();
  const { isOpen: isCreateProgramOpen, toggle: toggleProgramModal } = useModalToggle();
  const { isOpen: isEditProgramOpen, toggle: toggleEditProgramModal } = useModalToggle();
  const [programToEdit, setProgramToEdit] = useState<VisitProgram | null>();
  const { isVisitProgramAdmin } = useCurrentAccountRole();
  const { kebabCased } = useCallItClientProgramOrBusiness();

  const onEditProgram = (program: VisitProgram) => {
    setProgramToEdit(program);
    toggleEditProgramModal();
  };

  const onModalClose = () => {
    setProgramToEdit(null);
    toggleEditProgramModal();
  };

  const { data, error, loading, refetch } = useQuery<Data>(GET_VISIT_PROGRAMS, {
    variables: {
      filter: {
        businessId: {
          eq: businessId,
        },
      },
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const handleRefetch = async () => {
    await refetch();
  };

  const programs = data?.visitPrograms;
  const pagination = programs?.pagination;

  return (
    <>
      {isVisitProgramAdmin && (
        <ActionsContainer>
          <Button onClick={toggleProgramModal}>Create program</Button>
        </ActionsContainer>
      )}

      <SmartTable
        keyField="id"
        localStorageKey="visitPrograms"
        data={programs?.data}
        pagination={pagination}
        loading={loading}
        error={error}
        cursorPagination
        columns={[
          {
            header: { title: "Name" },
            dataField: "name",
            formatter({ id: programId, name }) {
              return (
                <Link
                  to={`/${kebabCased.pluralEntityName}/${businessId}/visit-programs/${programId}`}
                >
                  {name}
                </Link>
              );
            },
          },
          {
            header: { title: "Templates" },
            dataField: "visitTemplates",
            formatter: ({ visitTemplates }) => visitTemplates?.pagination?.totalCount || 0,
          },
          {
            header: { title: "Status" },
            dataField: "isActive",
            formatter: ({ isActive }) => (isActive ? "Active" : "Not Active"),
          },
          {
            header: { title: "Inserted At" },
            dataField: "insertedAt",
            formatter: ({ insertedAt }) => formatDateTime(insertedAt),
          },
          {
            header: { title: "" },
            dataField: "actions",
            cellProps: {
              align: "right",
            },
            formatter(program) {
              const { id: programId, isActive } = program;
              return (
                <Dropdown placement="bottom-end">
                  <OpenMenuButton data-testid="dropdown-button">
                    <EllipsisH size={18} />
                  </OpenMenuButton>

                  <Dropdown.Menu>
                    <Dropdown.Link
                      to={`/${kebabCased.pluralEntityName}/${businessId}/visit-programs/${programId}`}
                    >
                      View
                    </Dropdown.Link>
                    {isVisitProgramAdmin && (
                      <Dropdown.Item onClick={() => onEditProgram(program)}>
                        Set {isActive ? "inactive" : "active"}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              );
            },
          },
        ]}
      />

      {programToEdit && (
        <ChangeProgramStateModal
          isOpen={isEditProgramOpen}
          program={programToEdit}
          onModalClose={onModalClose}
        />
      )}

      <ModalLayout toggle={toggleProgramModal} title="Create Program" isOpen={isCreateProgramOpen}>
        <VisitProgramForm toggle={toggleProgramModal} onCreateFinish={handleRefetch} />
      </ModalLayout>
    </>
  );
};

const OpenMenuButton = styled(Dropdown.Actions)`
  min-height: auto;
`;

const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export default VisitPrograms;
