export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "MM/DD/YYYY h:mm A z";
export const DAY_MONTH_YEAR_FORMAT = "dddd, MMM Do, YYYY"; // Thursday, Nov 21st, 2019
export const ISO_FORMAT_DATE_WITH_TIME_ZONE = "YYYY-MM-DD[T]HH:mm:ss[Z]";

export const HOURS_FORMAT = "h:mm a";

export const TIME_WITH_TIMEZONE_FORMAT = "h:mma z"; // 	9:35am EST

export const DEFAULT_TIMEZONE_ABBR = "EST";
export const DEFAULT_TIMEZONE = "America/New_York";
export const AMERICA_NEW_YORK = "America/New_York";

export const TZ_FORMAT = "z (Z)";

export const timezoneOptions = [
  { value: "America/Adak", label: "HST" },
  { value: "America/Anchorage", label: "AKST" },
  { value: "America/Boise", label: "MST" },
  { value: "America/Chicago", label: "CST" },
  { value: "America/New_York", label: "EDT/EST" },
  { value: "America/Los_Angeles", label: "PST" },
];
