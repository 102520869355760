import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Text from "components/Text";
import { useGetLocationForOverrideQuery, useOverrideLocationMutation } from "generated/types";

import { Values, validationSchema } from "./schema";

interface Props {
  locationId: string;
  onClose: () => void;
}

export const OverrideLocationForm = ({ locationId, onClose }: Props) => {
  const { data, loading } = useGetLocationForOverrideQuery({
    variables: {
      locationId,
    },
  });

  const location = data?.location?.data;

  const [overrideLocation] = useOverrideLocationMutation();

  const handleSubmit = async (values: Values) => {
    try {
      const { coordinates, ...input } = values;

      const [lat, lng] = coordinates.split(",").map((item) => item.trim());

      const { data } = await overrideLocation({
        variables: {
          locationId,
          input: {
            ...input,
            lat,
            lng,
            address2: null,
            streetName: null,
            countryIso: null,
            houseNumber: null,
          },
        },
      });

      if (data) {
        toast.success("Location has been successfully updated");
      }

      onClose();
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues = {
    address: "",
    city: "",
    state: "",
    zipcode: "",
    coordinates: "",
    nickname: location?.nickname ?? "",
  };

  if (loading) return <Loader />;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Text bold>Overriding location for</Text>
              <Text size="small">{location?.address}</Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Full Address</Form.Label>
              <Form.Input name="address" placeholder="address, city, state, zip" />
            </Form.Group>

            <Form.Group>
              <Text bold>Repeat the city, state, and zip here</Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Input name="city" placeholder="city" />
            </Form.Group>

            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Input name="state" placeholder="state" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Zipcode</Form.Label>
              <Form.Input name="zipcode" placeholder="zipcode" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Latitude, Longitude (Copied from google maps)</Form.Label>
              <Form.Input name="coordinates" placeholder="lat, lng" />
              {/* 29.289895105365165, -73.60445294355884 */}
            </Form.Group>

            <Form.Group>
              <Form.Label>Nickname</Form.Label>
              <Form.Input name="nickname" placeholder="nickname" />
            </Form.Group>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </>
  );
};
