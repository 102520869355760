import * as yup from "yup";

export interface Values {
  address: string;
  city: string;
  state: string;
  zipcode: string;
  coordinates: string;
  nickname: string;
}

export const validateFullAddress = (value?: string | null) => {
  if (!value) return false;
  return (value as string).split(",").length - 1 >= 2;
};

export const validateCoordinates = (value?: string | null) => {
  if (!value) return false;

  if (!/^-?[0-9]\d*(\.\d+)?,[ ]?-?[0-9]\d*(\.\d+)?$/.test(value)) return false;

  return true;
};

export const validationSchema = yup.object().shape({
  address: yup
    .string()
    .nullable()
    .test(
      "test commas",
      "Please enter full address including city, state and ZIP",
      validateFullAddress
    )
    .required("Required"),
  city: yup.string().nullable().required("Required"),
  state: yup
    .string()
    .nullable()
    .min(2, "State must be 2 letters")
    .max(2, "State must be 2 letters")
    .required("Required"),
  zipcode: yup.string().nullable().required("Required"),
  coordinates: yup
    .string()
    .nullable()
    .test(
      "test lat and long",
      "Please enter valid lat and long, ex: 25.7681, -80.194",
      validateCoordinates
    )
    .required("Required"),
  nickname: yup.string().nullable(),
});
