import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Select from "components/Select";
import { VisitFlagReason } from "generated/types";
import { mapValueToLabel } from "utils/helpers/formatters";

import { ADD_GRIEVANCES_APPEAL_FLAG } from "./gql";
import { Schema, reasonOptions, schema } from "./schema";

interface Props {
  visitId: string;
  isOpen: boolean;
  toggle: () => void;
}

const AddGrievancesAppealFlagModal = ({ visitId, isOpen, toggle }: Props) => {
  const [addGrievancesAppealFlag, { loading }] = useMutation(ADD_GRIEVANCES_APPEAL_FLAG);

  const handleSubmit = async ({ reason, description }: Schema) => {
    try {
      const { data } = await addGrievancesAppealFlag({
        variables: {
          visitId,
          reason,
          description: description || mapValueToLabel(reasonOptions, reason),
        },
      });

      if (data?.createVisitFlag) {
        toast.success("Visit flagged successfully");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title='Add "Grievances and Appeals" priority flag' isOpen={isOpen}>
      <Formik
        initialValues={{
          reason: VisitFlagReason.EscalationGrievancesAppeals,
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label>Select flagging reason</Form.Label>
              <Select name="reason" options={reasonOptions} isSearchable={false} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.TextArea aria-label="description" name="description" />
            </Form.Group>
          </ModalContainer>

          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              No
            </Button>
            <Button type="submit" disabled={loading} variant="primary">
              {loading && <SpinningIcon size={18} />}
              Yes
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};

export default AddGrievancesAppealFlagModal;
