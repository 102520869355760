import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import PalSelect from "components/UserSelect/AsyncPalSelect";

import {
  CREATE_PAPA_BLOCKED_PAL,
  DESTROY_PAPA_BLOCKED_PAL,
  PAPA_BLOCKED_PAL,
  UPDATE_PAPA_BLOCKED_PAL,
} from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  papaId: string;
  palId: string;
}

const PapaBlockedPalModal = ({ isOpen, toggle, papaId, palId }: Props) => {
  const [updatePapaBlockedPal] = useMutation(UPDATE_PAPA_BLOCKED_PAL);
  const [createPapaBlockedPal] = useMutation(CREATE_PAPA_BLOCKED_PAL);
  const [destroyPapaBlockedPal, { loading: destroyLoading }] =
    useMutation(DESTROY_PAPA_BLOCKED_PAL);
  const { data, loading: initialValueLoading } = useQuery(PAPA_BLOCKED_PAL, {
    variables: {
      papaId,
      palId,
    },
    skip: !palId,
  });

  if (!isOpen) return null;

  const isUpdate = !!palId;
  const papaBlockedPal = data?.papaBlockedPals?.data?.[0] ?? {};
  const initialPal = papaBlockedPal?.pal?.data;
  const initialPalValue = initialPal
    ? {
        value: initialPal.id,
        label: initialPal.fullName,
        phone: initialPal.phoneNumber,
      }
    : null;

  const initialValues = {
    palId,
    comment: papaBlockedPal.comment ?? "",
  };

  const handleSubmit = async (values: Schema) => {
    const mutation = isUpdate ? updatePapaBlockedPal : createPapaBlockedPal;

    try {
      const { data } = await mutation({
        variables: {
          id: isUpdate ? papaBlockedPal.id : undefined,
          input: {
            papaId,
            palId: values.palId,
            comment: values.comment,
          },
        },
        ...(isUpdate
          ? {}
          : {
              refetchQueries: [
                {
                  query: PAPA_BLOCKED_PAL,
                  variables: {
                    papaId,
                    palId: values.palId,
                  },
                },
              ],
            }),
      });

      if (isUpdate && data?.updatePapaBlockedPal?.data?.id) {
        toast.success("Updated successfully");
      } else if (!isUpdate && data?.createPapaBlockedPal?.data?.id) {
        toast.success("Blocked successfully");
      } else {
        throw new Error("Something went wrong");
      }

      toggle();
    } catch (error: any) {
      toast.error((error as Error).message);
    }
  };

  const handleDestroy = async () => {
    try {
      const { data } = await destroyPapaBlockedPal({ variables: { id: papaBlockedPal.id } });

      if (data?.destroyPapaBlockedPal?.data?.id) {
        toast.success("Unblocked successfully");
      } else {
        throw new Error("Something went wrong");
      }

      toggle();
    } catch (error: any) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Block a pal" isOpen={true}>
      {initialValueLoading ? (
        <Loader />
      ) : (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {({ isSubmitting }) => (
            <ModalStyledForm>
              <ModalContainer>
                <Form.Group>
                  <Form.Label required>Pal</Form.Label>
                  <PalSelect name="palId" defaultValue={initialPalValue} isDisabled={!!palId} />
                </Form.Group>

                <Form.Group>
                  <Form.Label required>Block Reason</Form.Label>
                  <Form.TextArea name="comment" placeholder="Enter block reason" />
                </Form.Group>
              </ModalContainer>
              <ModalFooter>
                <Button type="button" variant="secondary" onClick={toggle}>
                  Cancel
                </Button>
                {isUpdate && (
                  <Button
                    type="button"
                    variant="danger"
                    onClick={handleDestroy}
                    disabled={isSubmitting || destroyLoading}
                  >
                    {destroyLoading ? (
                      <>
                        <Loader />
                        Unblocking
                      </>
                    ) : (
                      "Unblock"
                    )}
                  </Button>
                )}
                <Form.SubmitButton isSubmitting={isSubmitting} disabled={destroyLoading}>
                  {isUpdate ? "Update" : "Block"}
                </Form.SubmitButton>
              </ModalFooter>
            </ModalStyledForm>
          )}
        </Formik>
      )}
    </ModalLayout>
  );
};

export default PapaBlockedPalModal;
