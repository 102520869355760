import { ApolloError } from "@apollo/client";
import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import React from "react";
import styled from "styled-components";

import { colors } from "app/theme";
import { ReactComponent as UserRejected } from "assets/images/icons/user-rejected.svg";
import { ReactComponent as UserVerified } from "assets/images/icons/user-verified.svg";
import Button from "components/Button";
import Link from "components/Link";
import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { CaregiverDetailsFragment, CaregiverState } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useModalToggle } from "hooks/useModalToggle";
import { CaregiverForm } from "pages/Caregivers/Details/CaregiverForm/CaregiverForm";
import { nonNull } from "utils/nonNull";

interface Props {
  caregiver?: CaregiverDetailsFragment | null;
  loading: boolean;
  error: ApolloError | undefined;
}

const Overview: React.FC<Props> = ({ caregiver, loading, error }) => {
  const { kebabCased } = useCallItClientProgramOrBusiness();

  const { isOpen: isOverviewModalOpen, toggle: toggleOverviewModal } = useModalToggle();

  const { id: caregiverId, account } = caregiver ?? {};
  const createdBy = account?.data?.createdBy;

  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");
  const clientPanelItemText = clientCopyUpdateEnabled ? "Employer Client" : "Business";

  if (loading) return <Panel.Side />;
  if (error) {
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );
  }

  return (
    <Panel.Side>
      <div>
        <Panel.Item>
          <Text bold>Status</Text>
          <CaregiverStatus caregiver={caregiver} />
        </Panel.Item>
        <Panel.Item>
          <Text bold>ID</Text>
          <Text>{caregiverId}</Text>
        </Panel.Item>
        <Panel.Item>
          <Text bold>{clientPanelItemText}</Text>
          {caregiver?.business?.data?.name ? (
            <Link to={`/${kebabCased.pluralEntityName}/${caregiver?.business?.data?.id}`}>
              {caregiver?.business?.data?.name}
            </Link>
          ) : (
            EMPTY_PLACEHOLDER
          )}
        </Panel.Item>

        <Panel.Item>
          <Text bold>E-mail</Text>
          {account?.data?.email ? (
            <Text title={account.data.email} wrapString>
              {account.data.email}
            </Text>
          ) : (
            EMPTY_PLACEHOLDER
          )}
        </Panel.Item>

        <Panel.Item>
          <Text bold>Phone number</Text>
          <Text>{account?.data?.phoneNumber || EMPTY_PLACEHOLDER}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Created by</Text>
          {createdBy?.data?.fullName ? (
            <>
              <Text>{createdBy?.data?.fullName}</Text>
              <Text>{createdBy?.data?.email}</Text>
            </>
          ) : (
            EMPTY_PLACEHOLDER
          )}
        </Panel.Item>
      </div>

      {caregiver && (
        <ModalLayout
          toggle={toggleOverviewModal}
          title="Edit Caregiver"
          isOpen={isOverviewModalOpen}
        >
          <CaregiverForm caregiver={caregiver} onFormComplete={toggleOverviewModal} />
        </ModalLayout>
      )}

      <Button type="button" block onClick={toggleOverviewModal}>
        Edit
      </Button>
    </Panel.Side>
  );
};

const CaregiverStatus = ({ caregiver }: { caregiver?: CaregiverDetailsFragment | null }) => {
  const color = {
    [CaregiverState.Active]: colors.green500,
    [CaregiverState.ChangedPlans]: colors.red500,
    [CaregiverState.Inactive]: colors.textMuted,
    [CaregiverState.Ineligible]: colors.red500,
    [CaregiverState.Paused]: colors.unreviewed,
  };

  if (!caregiver || !caregiver.status) return <Text>{EMPTY_PLACEHOLDER}</Text>;

  const mobileDevices = nonNull(caregiver.account?.data?.mobileDevices?.data);
  const isVerified = !!mobileDevices.length;
  const hasCareApp = !!mobileDevices.find((mobileDevice) => mobileDevice.appName === CARE_APP_NAME);

  return (
    <StatusField>
      <Status color={color[caregiver.status]}>{capitalize(lowerCase(caregiver.status))}</Status>
      <Tooltip
        content={
          <Text>
            {hasCareApp ? (
              "Care App user"
            ) : (
              <>
                Not a Care
                <br /> App user
              </>
            )}
          </Text>
        }
        placement="right"
      >
        {isVerified ? <UserVerified /> : <UserRejected />}
      </Tooltip>
    </StatusField>
  );
};

const CARE_APP_NAME = "Papa Care";

const StatusField = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Status = styled.p<{
  color?: string;
}>`
  color: ${({ color }) => color};
  margin-top: 0;
  margin-bottom: 0;
`;

export default Overview;
