import { Lock } from "@styled-icons/boxicons-solid";
import { Close } from "@styled-icons/evaicons-solid/Close";
import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import EmployerIcon from "assets/images/employer.svg";
import Heading from "components/Heading";
import Modal from "components/Modal";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import VerticalIconsList from "components/VerticalIconsList";
import { VisitFlags } from "components/VisitFlags";
import { DEFAULT_TIMEZONE, TIME_WITH_TIMEZONE_FORMAT } from "constants/date";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { useVisitPreviewQuery } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

import { ActionMenu } from "../ActionMenu";
import { getColorsAndName } from "../constants";

interface Props {
  visitId: string;
  onRequestClose: () => void;
}

export const VisitPreview = ({ visitId, onRequestClose }: Props) => {
  const { data, loading, error } = useVisitPreviewQuery({ variables: { visitId } });
  const visit = data?.visit?.data;

  const scheduledFor = formatDateTime(visit?.scheduledFor, {
    format: TIME_WITH_TIMEZONE_FORMAT,
    timezone: visit?.location?.data?.timezone || DEFAULT_TIMEZONE,
  });

  const stateColorsAndName = getColorsAndName(visit?.state);

  const papa = visit?.papa?.data;
  const pal = visit?.pal?.data;
  const location = visit?.location?.data ?? {};
  const tasks = nonNull(visit?.tasks?.data);
  const visitFlags = nonNull(visit?.visitFlags?.data);
  const claimedBy = visit?.claimedBy?.data;

  const { address, address2, city, state, zipcode } = location;
  const plan = papa?.account?.data?.plan?.data;

  return (
    <Modal
      isOpen={true}
      width={500}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
    >
      <Container>
        {loading ? (
          <ContentLoader height={200} width="100%">
            <rect x="0" y="0" rx="4" ry="4" width="60%" height="20%" />
            <rect x="0" y="30%" rx="4" ry="4" width="80%" height="10%" />
            <rect x="0" y="50%" rx="4" ry="4" width="100%" height="70%" />
          </ContentLoader>
        ) : error ? (
          <QueryErrors error={error} />
        ) : (
          <>
            <Header>
              <Heading as="h2">{papa?.fullName}</Heading>
              {!!claimedBy && (
                <Tooltip title={`Claimed by ${claimedBy.fullName}`}>
                  <Lock size={22} />
                </Tooltip>
              )}
              {visit && <ActionMenu visit={visit} />}
              <CloseButton>
                <Close size={24} onClick={onRequestClose} />
              </CloseButton>
            </Header>

            <SecondLine>
              <VisitTime>{scheduledFor}</VisitTime>
              <TasksContainer>
                <VerticalIconsList icons={tasks} small />
              </TasksContainer>
              <Status color={stateColorsAndName.background}>{stateColorsAndName.name}</Status>

              {visit?.isEmployerVisit && (
                <Tooltip title="Employer Market Visit">
                  <img src={EmployerIcon} width={15} alt="employer visit icon" />
                </Tooltip>
              )}

              <VisitFlags
                visitFlags={visitFlags}
                isPreviouslyMissed={visit?.isPreviouslyMissed}
                iconSize={14}
              />
            </SecondLine>

            <ThirdLine>
              <div>
                <Text>Contact Number:</Text>
                <Text bold>{papa?.phoneNumber}</Text>
              </div>
              <div>
                <Text>Visit Address:</Text>
                <Text bold>{[address, address2, city, state, zipcode].join(", ")}</Text>
              </div>
              <div>
                <Text>Member Plan:</Text>
                <Text bold>{plan?.name || plan?.id || EMPTY_PLACEHOLDER}</Text>
              </div>
            </ThirdLine>

            <Notes>
              <Text>Notes:</Text>
              <Text>{visit?.note || EMPTY_PLACEHOLDER}</Text>
            </Notes>

            <HorizontalLine />

            <Heading as="h3">Pal Contact Info</Heading>
            <PalInfo>
              {pal ? (
                <>
                  <div>
                    <Text>Full Name:</Text>
                    <Text bold>{pal?.fullName}</Text>
                  </div>
                  <div>
                    <Text>Contact Number:</Text>
                    <Text bold>{pal?.phoneNumber}</Text>
                  </div>
                </>
              ) : (
                EMPTY_PLACEHOLDER
              )}
            </PalInfo>
          </>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 28px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  background: none;
  border-width: 0;
  cursor: pointer;
`;

const SecondLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

const ThirdLine = styled.div`
  font-size: 12px;
  gap: 16px;
  display: flex;
  margin-bottom: 16px;

  > div {
    min-width: 25%;
  }
`;

const Notes = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TasksContainer = styled.div``;

const VisitTime = styled.div`
  font-size: 16px;
`;

const Status = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  border-radius: 6px;
  height: max-content;
  padding: 2px 8px;
  font-size: 10px;
`;

const HorizontalLine = styled.hr`
  border: 1px solid #ededed;
  margin: 16px 0;
`;

const PalInfo = styled.div`
  display: flex;

  > div {
    width: 50%;
  }
`;
