import { gql } from "@apollo/client";

export const UPDATE_ACCOUNT = gql`
  mutation UpdateCaregiverAccount($accountId: ID!, $input: AccountInput!) {
    updateAccount(id: $accountId, input: $input) {
      data {
        id
        fullName
        email
        phoneNumber
      }
    }
  }
`;

export const UPDATE_CAREGIVER = gql`
  mutation UpdateCaregiver($caregiverId: ID!, $input: CaregiverInput!) {
    updateCaregiver(id: $caregiverId, input: $input) {
      data {
        id
        fullName
        gender
        account {
          data {
            fullName
            email
            phoneNumber
          }
        }
      }
    }
  }
`;
