import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown";
import { ChevronUp } from "@styled-icons/boxicons-regular/ChevronUp";
import { Formik } from "formik";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ButtonLink } from "components/Button";
import { Button as ButtonStyled } from "components/Button";
import { OnlyCheckbox as Checkbox } from "components/Checkbox/OnlyCheckbox";
import { Flex } from "components/Flex";
import Form from "components/Form";
import { InputContainer as InputStyled } from "components/Form/FormInput";
import Select from "components/Select";
import TaskButtons from "components/TaskButtons";
import { DEFAULT_TIMEZONE } from "constants/date";
import { demandProfileOptions } from "constants/demandProfile";
import { FeatureFlags } from "constants/featureFlags";
import { locationOptions } from "constants/location";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useMountEffect } from "hooks/useMountEffect";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";
import { VisitsFiltersToggle } from "pages/Visits/VisitsFiltersToggle";
import { mapSelectValuesToOptions } from "utils/select";

interface Props {
  loading: boolean;
}

export const VisitFilter: React.FC<Props> = ({ loading, children }) => {
  const clientCopyUpdateEnabled = useIsFeatureEnabled(FeatureFlags.ClientCopyUpdate);
  const searchPlaceholder = clientCopyUpdateEnabled ? "Client" : "Member/Business";

  const pageInfo = usePageInfo();
  const urlQueries = useUrlQuery();

  const timezone = pageInfo?.timezone || DEFAULT_TIMEZONE;

  const history = useHistory();

  const filterIncludeHide = !!pageInfo.pal || !!pageInfo.state || !!pageInfo.city;
  const [filterVisibility, setFilterVisibility] = useState(filterIncludeHide);

  const [form, setForm] = useState({
    name: pageInfo.name ?? "",
    papa: pageInfo.papa ?? "",
    pal: pageInfo.pal ?? "",
    state: pageInfo.state ?? [],
    city: pageInfo.city ?? "",
    county: pageInfo.county ?? "",
    demandProfile: pageInfo.demandProfile ?? "",
    papaId: pageInfo.papaId ?? "",
    visitId: pageInfo.visitId ?? "",
    isCritical: pageInfo.isCritical === "true",
    isEmployerVisit: pageInfo.isEmployerVisit === "true",
    isGrievancesAppeals: pageInfo.isGrievancesAppeals === "true",
    isUberEligible: pageInfo.isUberEligible === "true",
    isPreviouslyMissed: pageInfo.isPreviouslyMissed === "true",
    isVisitLeadTimeException: pageInfo.isVisitLeadTimeException === "true",
    dayFrom: pageInfo.dayFrom,
    dayTo: pageInfo.dayTo,
    taskIds: pageInfo.taskIds?.split(",") ?? ([] as string[]),
  });

  const [{ isTodayChecked, isTomorrowChecked }, setTimeChecked] = useState({
    isTodayChecked: false,
    isTomorrowChecked: false,
  });

  const hasFilters =
    history.location.search &&
    !isEqual(Object.keys(queryString.parse(history.location.search)), ["page"]);

  useMountEffect(() => {
    if (!pageInfo.dayTimeFrom) return;
    if (moment.tz(pageInfo.dayTimeFrom, timezone).isSame(moment.tz(timezone), "day")) {
      setTimeChecked({
        isTodayChecked: true,
        isTomorrowChecked: false,
      });
    }
    if (
      moment.tz(pageInfo.dayTimeFrom, timezone).isSame(moment.tz(timezone).add(1, "day"), "day")
    ) {
      setTimeChecked({
        isTodayChecked: false,
        isTomorrowChecked: true,
      });
    }
  });

  const handleChange = (newForm: any) => {
    const query = queryString.stringify(
      {
        ...urlQueries,
        page: 1,
        name: newForm.name,
        papa: newForm.papa,
        papaId: newForm.papaId,
        visitId: newForm.visitId,
        pal: newForm.pal,
        state: newForm.state,
        city: newForm.city,
        county: newForm.county,
        demandProfile: newForm.demandProfile,
        dayTimeFrom: newForm.dayFrom,
        dayTimeTo: newForm.dayTo,
        isCritical: newForm.isCritical ? "true" : null,
        isGrievancesAppeals: newForm.isGrievancesAppeals ? "true" : null,
        isUberEligible: newForm.isUberEligible ? "true" : null,
        isEmployerVisit: newForm.isEmployerVisit ? "true" : null,
        isPreviouslyMissed: newForm.isPreviouslyMissed ? "true" : null,
        isVisitLeadTimeException: newForm.isVisitLeadTimeException ? "true" : null,
        taskIds: newForm.taskIds.join(","),
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: "index" }
    );

    if (!query && !history.location.search) {
      return;
    }

    if (`?${query}` !== history.location.search) {
      history.push(`?${query}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => debounce(handleChange, 500), [urlQueries]);

  const handleInput = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const newForm = { ...form, [name]: value };

    setForm(newForm);
    action(newForm);
  };

  const handleSelectChange = (name: string) => (value: any) => {
    const newForm = { ...form, [name]: value };

    setForm(newForm);
    action(newForm);
  };

  const handleCheckboxToggle = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newForm = {
      ...form,

      ...(name === "isCritical" && checked
        ? { isCritical: true, isGrievancesAppeals: false, isVisitLeadTimeException: false }
        : name === "isGrievancesAppeals" && checked
        ? { isCritical: false, isGrievancesAppeals: true, isVisitLeadTimeException: false }
        : name === "isVisitLeadTimeException" && checked
        ? { isCritical: false, isGrievancesAppeals: false, isVisitLeadTimeException: true }
        : { [name]: checked }),
    };

    setForm(newForm);
    action(newForm);
  };

  const handleTodayClick = ({ target: { name, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    let newForm = form;
    if (checked) {
      const now = moment.tz(timezone);

      if (name === "isTomorrow") {
        now.add(1, "day");
      }

      newForm = {
        ...form,
        dayFrom: now.startOf("day").toISOString(),
        dayTo: now.endOf("day").toISOString(),
      };

      setTimeChecked({
        isTodayChecked: name === "isToday",
        isTomorrowChecked: name === "isTomorrow",
      });
    } else {
      newForm = {
        ...form,
        dayFrom: "",
        dayTo: "",
      };

      setTimeChecked({
        isTodayChecked: false,
        isTomorrowChecked: false,
      });
    }
    setForm(newForm);
    action(newForm);
  };

  const handleTaskChange = (value: string[]) => {
    const newForm = { ...form, taskIds: value };

    setForm(newForm);
    action(newForm);
  };

  const handleCleanFilters = () => {
    setForm({
      name: "",
      papa: "",
      pal: "",
      state: [],
      city: "",
      county: "",
      demandProfile: "",
      papaId: "",
      visitId: "",
      isCritical: false,
      isGrievancesAppeals: false,
      isUberEligible: false,
      isEmployerVisit: false,
      isPreviouslyMissed: false,
      isVisitLeadTimeException: false,
      dayFrom: "",
      dayTo: "",
      taskIds: [],
    });
    setTimeChecked({
      isTodayChecked: false,
      isTomorrowChecked: false,
    });
    history.push("?");
  };

  return (
    <Formik initialValues={form} onSubmit={noop} enableReinitialize>
      <Container>
        <Flex justifyContent="space-between" alignItems="center" gridGap={10}>
          <Flex gridGap={10} grow>
            <Form.Input
              name="name"
              aria-label="name"
              placeholder={searchPlaceholder}
              onChange={handleInput}
              keepEnable
            />
            <Form.Input
              name="papa"
              aria-label="papa"
              placeholder="Papa"
              onChange={handleInput}
              keepEnable
            />
            <Button type="button" onClick={() => setFilterVisibility(!filterVisibility)} nowrap>
              <span>{filterVisibility ? "Less Search" : "More Search"}</span>
              <span>{filterVisibility ? <ChevronUp size={16} /> : <ChevronDown size={16} />}</span>
            </Button>
            <VisitsFiltersToggle hideCleanFilter />
          </Flex>

          <Flex>{children}</Flex>
        </Flex>
        {filterVisibility && (
          <>
            <Flex gridGap={10} justifyContent="flex-start" wrap>
              <Form.Input
                name="papaId"
                aria-label="papaId"
                placeholder="Papa ID"
                onChange={handleInput}
                keepEnable
              />
              <Form.Input
                name="visitId"
                aria-label="visitId"
                placeholder="Visit ID"
                onChange={handleInput}
                keepEnable
              />
              <Form.Input
                name="pal"
                aria-label="pal"
                placeholder="Pal"
                onChange={handleInput}
                keepEnable
              />
              <SelectContainer>
                <Select
                  name="state"
                  options={locationOptions}
                  isSearchable={true}
                  placeholder="US States"
                  aria-label="state"
                  onChange={handleSelectChange("state")}
                  isClearable
                  isMulti
                />
              </SelectContainer>
              <Form.Input
                name="city"
                aria-label="city"
                placeholder="City"
                onChange={handleInput}
                keepEnable
              />
              <Form.Input
                name="county"
                aria-label="county"
                placeholder="County"
                onChange={handleInput}
                keepEnable
              />
              <SelectContainer>
                <Select
                  name="demandProfile"
                  options={demandProfileOptions}
                  placeholder="County demand profile"
                  aria-label="demand profile"
                  onChange={handleSelectChange("demandProfile")}
                  value={
                    mapSelectValuesToOptions(form.demandProfile ?? "", demandProfileOptions) ?? ""
                  }
                  isClearable
                  isMulti
                />
              </SelectContainer>
            </Flex>

            <TaskButtons name="taskIds" onChange={handleTaskChange} small />
          </>
        )}
        <Flex gridGap={10} alignItems="center" wrap>
          <CheckBoxContainer>
            <Checkbox
              label="Today"
              name="isToday"
              data-testid="isToday"
              disabled={loading}
              checked={isTodayChecked}
              onChange={handleTodayClick}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              label="Tomorrow"
              name="isTomorrow"
              data-testid="isTomorrow"
              disabled={loading}
              checked={isTomorrowChecked}
              onChange={handleTodayClick}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              label="Critical"
              name="isCritical"
              data-testid="isCritical"
              onChange={handleCheckboxToggle}
              checked={form.isCritical}
              disabled={loading}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              label="Grievances and Appeals"
              name="isGrievancesAppeals"
              data-testid="isGrievancesAppeals"
              onChange={handleCheckboxToggle}
              checked={form.isGrievancesAppeals}
              disabled={loading}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              label="Previously Missed"
              name="isPreviouslyMissed"
              data-testid="isPreviouslyMissed"
              onChange={handleCheckboxToggle}
              checked={form.isPreviouslyMissed}
              disabled={loading}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              label="Employer Market"
              name="isEmployerVisit"
              data-testid="isEmployerVisit"
              onChange={handleCheckboxToggle}
              checked={form.isEmployerVisit}
              disabled={loading}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              label="Member OK with Uber"
              name="isUberEligible"
              data-testid="isUberEligible"
              onChange={handleCheckboxToggle}
              checked={form.isUberEligible}
              disabled={loading}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              label="Visit lead time exception"
              name="isVisitLeadTimeException"
              data-testid="isVisitLeadTimeException"
              onChange={handleCheckboxToggle}
              checked={form.isVisitLeadTimeException}
              disabled={loading}
            />
          </CheckBoxContainer>
          {hasFilters && (
            <ButtonLink color="muted" size="small" onClick={handleCleanFilters} nowrap>
              Clean Filters
            </ButtonLink>
          )}
        </Flex>
      </Container>
    </Formik>
  );
};

const Container = styled(Flex).attrs({
  gridGap: 10,
  flexDirection: "column",
  marginBottom: 20,
})`
  ${InputStyled} {
    max-width: 14rem;
    max-height: 38px;
  }
`;

const SelectContainer = styled.div`
  max-width: 14rem;
  width: 100%;
`;

const Button = styled(ButtonStyled)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

const CheckBoxContainer = styled.div`
  padding: 0.375rem 0 0.313rem;
`;
