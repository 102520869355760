import moment from "moment-timezone";

import { DEFAULT_TIMEZONE } from "constants/date";
import { Visit, VisitDiscountType } from "generated/types";
import { nonNullWithId } from "utils/nonNull";

export const getInitialValues = (visit: Visit) => {
  const objectives = nonNullWithId(visit?.objectives?.data)
    .filter(({ checked }) => checked)
    .reduce((obj, { id }) => ({ ...obj, [id]: true }), {});

  const taskIds = nonNullWithId(visit.tasks?.data).map(({ id }) => id);
  const timezone = visit.location?.data?.timezone || DEFAULT_TIMEZONE;

  return {
    startedAtDay: visit?.startedAt ? moment.tz(visit?.startedAt, timezone).format("LL") : "",
    startedAtTime: visit?.startedAt ? moment.tz(visit?.startedAt, timezone).format("LT") : "",
    completedAtDay: visit?.completedAt ? moment.tz(visit?.completedAt, timezone).format("LL") : "",
    completedAtTime: visit?.completedAt ? moment.tz(visit?.completedAt, timezone).format("LT") : "",
    commuteDistance: visit?.commuteDistance ? visit.commuteDistance : null,
    visitDistance: visit?.visitDistance ? visit.visitDistance : null,
    overrideDurationMins: visit?.overrideDurationMins,
    discount:
      visit?.discount?.data?.type && typeof visit?.discount?.data?.value === "number"
        ? {
            type: visit.discount.data.type,
            value: visit.discount.data.value,
          }
        : {
            type: VisitDiscountType.FixedAmount,
            value: 0,
          },
    delivery: visit.delivery,
    successful: visit.successful,
    taskIds,
    objectives,
  };
};
