/* eslint-disable no-template-curly-in-string */
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import * as yup from "yup";
import { NumberSchema, StringSchema } from "yup";

import { HIGH, INCOMPLETE, LOW, MEDIUM } from "constants/assessments";
import {
  AssessmentQuestionFragment,
  AssessmentSubmissionFragment,
  QuestionEnum,
  SubmissionAnswerFragment,
  SubmissionAnswerInput,
} from "generated/types";
import { hardcodedQuestionRequired } from "modals/AssessmentsQuestions/hardcodedQuestions";
import { hardcodedQuestionRequired as hardcodedQuestionRequiredMedicare } from "modals/AssessmentsQuestions/hardcodedQuestionsMedicare";
import { FormValues } from "modals/AssessmentsQuestions/models";
import { AssessmentType, HardCodedQuestionsRequired, QuestionsFlow } from "types/assessments";
import { isUuid } from "utils/isUuid";
import { nonNull } from "utils/nonNull";

export const getHardcodedQuestionsRequired = (assessmentType: AssessmentType) => {
  if (assessmentType === AssessmentType.papaSocialIndex) {
    return hardcodedQuestionRequired;
  } else if (assessmentType === AssessmentType.papaSocialIndexMedicare) {
    return hardcodedQuestionRequiredMedicare;
  } else {
    return null;
  }
};

export const generateInitialValues = (questions: AssessmentQuestionFragment[]) =>
  questions.reduce((acc: Record<string, QuestionEnum | string | null>, question) => {
    return {
      ...acc,
      [`question-${question?.id}`]:
        question?.type === QuestionEnum.Multiple || question?.type === QuestionEnum.CheckMultiple
          ? null
          : "",
    };
  }, {}) || {};

export const generateInitialValuesEdit = (answers: SubmissionAnswerFragment[]) =>
  answers.reduce((acc: Record<string, QuestionEnum | string | string[] | null>, answer) => {
    if (!answer?.answer) return acc;

    let value = answer.answer;
    const questionId = answer.question?.data?.id as string;

    if (answer.question?.data?.type === QuestionEnum.Multiple) {
      value = answer?.question?.data?.options?.data?.find(
        (option) => option?.answerText === value || option?.id === value
      )?.id as string;
    }
    if (answer.question?.data?.type === QuestionEnum.CheckMultiple) {
      value = answer?.question?.data?.options?.data?.find(
        (option) => option?.answerText === value || option?.id === value
      )?.id as string;
      let options: string[] = [];
      const question = acc[`question-${questionId}`];
      if (question && isArray(question)) {
        options = question;
      }
      options.push(value);
      return {
        ...acc,
        [`question-${questionId}`]: options,
      };
    }

    return {
      ...acc,
      [`question-${questionId}`]: value,
    };
  }, {}) || {};

const isQuestionRequired = (
  question: AssessmentQuestionFragment,
  requiredQuestions: HardCodedQuestionsRequired | null
): boolean => {
  if (requiredQuestions) {
    return !!requiredQuestions[`P${question.position}`];
  }
  return true;
};

// Generate a schema to be used for answer validation. If required questions are not provided
// all questions are required.
export const generateSchema = (
  questions: AssessmentQuestionFragment[],
  requiredQuestions: HardCodedQuestionsRequired | null
) =>
  yup.object().shape(
    questions.reduce(
      (
        acc: Record<
          string,
          StringSchema<string | null | undefined> | NumberSchema<number | null | undefined>
        >,
        question,
        index
      ) => {
        const questionSchema =
          question?.type === QuestionEnum.OpenendedNumeric
            ? yup
                .number()
                .typeError("${label} must be a number")
                .integer("${label} must be an integer")
                .min(0)
                .max(30)
                .nullable()
                .label(`"Answer ${index + 1}"`)
            : yup.string().nullable();

        const requiredQuestionSchema = questionSchema.required("Required");

        return {
          ...acc,
          [`question-${question?.id}`]: isQuestionRequired(question, requiredQuestions)
            ? requiredQuestionSchema
            : questionSchema,
        };
      },
      {}
    )
  );

export const formatAnswersToInput = (values: FormValues) =>
  Object.entries(values)
    .filter(([, value]) => value !== null)
    .reduce((acc, [question, value]) => {
      const questionId = question.replace("question-", "");

      if (isArray(value)) {
        const answer = value.map((optionId) => ({
          questionId,
          optionId,
        }));
        return [...acc, ...answer];
      } else if (value && isUuid(value)) {
        return [
          ...acc,
          {
            questionId,
            optionId: value,
          },
        ];
      } else if (Number.isInteger(value)) {
        return [
          ...acc,
          {
            questionId,
            numericAnswer: +value!,
          },
        ];
      } else if (!value) {
        return acc;
      }

      return [
        ...acc,
        {
          questionId,
          textAnswer: value,
        },
      ];
    }, [] as SubmissionAnswerInput[]);

type FormatInputProps = {
  values: FormValues;
  questions: AssessmentQuestionFragment[];
  answers: SubmissionAnswerFragment[];
  initialValues: Record<string, string | string[] | null>;
  questionsFlow?: QuestionsFlow;
};

export const formatAnswersToEditInputs = ({
  values,
  questions,
  answers,
  initialValues,
  questionsFlow = {},
}: FormatInputProps) => {
  const editAnswers: {
    answer: string;
    questionId: string;
    answerId: string;
  }[] = [];
  const deleteAnswers: {
    answerId: string;
  }[] = [];
  const createAnswers: {
    questionId: string;
    answer: string;
  }[] = [];

  const answersByQuestionId = answers.reduce((acc, answer) => {
    acc[answer.question?.data?.id!] = answer.id!;
    return acc;
  }, {} as Record<string, string>);

  Object.entries(values).forEach(([questionKey, value]) => {
    const questionId = questionKey.replace("question-", "");

    const question = questions.find((question) => question.id === questionId);
    if (!question) return;

    if (typeof questionsFlow[`P${question.position}`] !== "undefined") {
      const parentPosition = Object.keys(questionsFlow[`P${question.position}`])[0];
      const parentAnswerFlow = questionsFlow[`P${question.position}`][parentPosition];

      const parent = questions.find(
        (question) => question?.position === parseInt(parentPosition.replace("P", ""), 10)
      );
      const parentAnswerId = values[`question-${parent?.id}`];

      const parentAnswerText = nonNull(
        questions.find((question) => question.id === parent?.id)?.options?.data
      ).find((option) => option.id === parentAnswerId)?.answerText;

      if (
        isArray(parentAnswerFlow) &&
        !!value &&
        !isArray(value) &&
        !!parentAnswerText &&
        !parentAnswerFlow.map((a) => a.toLowerCase()).includes(parentAnswerText.toLowerCase())
      ) {
        deleteAnswers.push({
          answerId: answersByQuestionId[questionId],
        });
      }

      if (
        !isArray(parentAnswerFlow) &&
        !!value &&
        !isArray(value) &&
        !!parentAnswerText &&
        parentAnswerFlow.toLowerCase() !== parentAnswerText.toLowerCase()
      ) {
        deleteAnswers.push({
          answerId: answersByQuestionId[questionId],
        });
      }
    }

    if (!value) return;
    if (isEqual(initialValues[questionKey], value)) return;

    // create new answer
    if (!initialValues[questionKey]) {
      if (isArray(value)) {
        value.forEach((answer) => {
          const answerText = question.options?.data?.find(
            (option) => option?.id === answer
          )?.answerText;

          if (answerText) {
            createAnswers.push({
              questionId: questionId,
              answer: answerText,
            });
          }
        });
      } else if (isUuid(value)) {
        const answerText = question.options?.data?.find(
          (option) => option?.id === value
        )?.answerText;

        if (answerText) {
          createAnswers.push({
            questionId: questionId,
            answer: answerText,
          });
        }
      } else {
        createAnswers.push({
          questionId: questionId,
          answer: value,
        });
      }
    } else {
      const options = nonNull(question.options?.data);

      if (isArray(value)) {
        // creating
        value.forEach((optionId) => {
          const answerText = options.find((option) => option?.id === optionId)?.answerText;
          if (!!answerText && !initialValues[questionKey]?.includes(optionId)) {
            createAnswers.push({
              questionId: questionId,
              answer: answerText,
            });
          }
        });

        //  deleting;
        options.forEach((option) => {
          if (
            option.id &&
            initialValues[questionKey]?.includes(option.id) &&
            !value.includes(option.id)
          ) {
            const answer = answers.find((answer) => answer.answer === option?.answerText);
            if (answer?.id) {
              deleteAnswers.push({
                answerId: answer.id,
              });
            }
          }
        });
      } else if (isUuid(value)) {
        // editing value as id
        const answerText = options.find((option) => option?.id === value)?.answerText;
        if (!!answerText) {
          editAnswers.push({
            answerId: answersByQuestionId[questionId],
            answer: answerText,
            questionId,
          });
        }
      } else {
        // editing value
        editAnswers.push({
          answerId: answersByQuestionId[questionId],
          answer: `${value}`,
          questionId,
        });
      }
    }
  });

  return { editAnswers, deleteAnswers, createAnswers };
};

export const checkIncompleteAssessment = (submission: AssessmentSubmissionFragment): boolean => {
  const currentPositions = nonNull(submission.answers?.data).map(
    (answer) => `P${answer.question?.data?.position!}`
  );

  return Object.keys(hardcodedQuestionRequired).some((key) => {
    return !currentPositions.includes(key);
  });
};

export const getPapaSocialIndexPoints = (submission: AssessmentSubmissionFragment): number => {
  const points = nonNull(submission.answers?.data)
    .map((answer) => answer.score ?? 0)
    .reduce((acc, cur) => acc + cur, 0);

  if (points >= 30) {
    return 30;
  } else {
    return points;
  }
};

export const getPapaSocialIndexResult = (submission: AssessmentSubmissionFragment) => {
  if (checkIncompleteAssessment(submission)) {
    return INCOMPLETE;
  }

  const points = getPapaSocialIndexPoints(submission);

  return getResultFromPoints(points);
};

export const getResultFromPoints = (points: number) => {
  if (points >= 30) {
    return HIGH;
  }

  if (points > 10) {
    return MEDIUM;
  }

  return LOW;
};

export const sumAnsweredQuestions = (
  values: FormValues,
  questions: AssessmentQuestionFragment[]
): number => {
  const score = Object.entries(values)
    .map(([questionKey, value]) => {
      const questionId = questionKey.replace("question-", "");

      if (!value) return 0;

      const question = questions.find((question) => question.id === questionId);

      if (
        !question ||
        question.type === QuestionEnum.Openended ||
        question.type === QuestionEnum.OpenendedNumeric
      ) {
        return 0;
      }

      if (isArray(value)) {
        return value
          .map((optionId) => {
            const option = question.options?.data?.find((option) => option.id === optionId);
            const score = option?.score ?? 0;

            return score;
          })
          .reduce((acc, cur) => acc + cur, 0);
      }
      const option = question.options?.data?.find((option) => option.id === value);
      const score = option?.score ?? 0;

      return score;
    })
    .reduce((acc, cur) => acc + cur, 0);

  return score;
};
