import { Formik, Form as FormikForm } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { Drawer } from "components/Drawer";
import Form from "components/Form";
import { Loader } from "components/Loader";
import QueryErrors from "components/QueryErrors";
import {
  QuestionEnum,
  useCreateAnswerMutation,
  useDestroyAnswerMutation,
  useEditAssessmentAnswersMutation,
  useGetAssessmentAnswersQuery,
} from "generated/types";
import { useGetSurvey } from "hooks/useGetSurvey";
import { questionsFlow as questionsFlowMedicare } from "modals/AssessmentsQuestions/hardcodedQuestionsMedicare";
import { AssessmentType } from "types/assessments";
import {
  formatAnswersToEditInputs,
  generateInitialValuesEdit,
  generateSchema,
  getHardcodedQuestionsRequired,
} from "utils/helpers/assessments";
import { nonNull } from "utils/nonNull";

import Question from "./Question";

type FormRecords = Record<string, FormValue>;
type FormValue = string | string[] | QuestionEnum | undefined | null;

interface Props {
  papaId: string;
  assessmentId: string;
  assessmentType: AssessmentType;
  onClose: () => void;
  onRefetch?: () => void;
}

const AssessmentsQuestionsEdit: React.FC<Props> = ({
  papaId,
  assessmentId,
  assessmentType,
  onClose,
  onRefetch = () => {},
}) => {
  const { data, loading, error } = useGetAssessmentAnswersQuery({
    variables: {
      assessmentId,
    },
  });
  const [submitTouched, setSubmitTouched] = useState(false);

  const [editAssessmentAnswer, { loading: isEditing }] = useEditAssessmentAnswersMutation();
  const [createAnswer] = useCreateAnswerMutation();
  const [destroyAnswer] = useDestroyAnswerMutation();
  const {
    data: { survey },
  } = useGetSurvey({ papaId, type: assessmentType });

  if (error) {
    return <QueryErrors error={error} />;
  }

  if (loading) return <Loader />;

  const questions = nonNull(survey?.questions?.data);
  const answers = data?.showVqeSubmission?.data?.answers?.data || [];

  const initialValues = generateInitialValuesEdit(answers);
  const schema = generateSchema(questions, getHardcodedQuestionsRequired(assessmentType));

  const handleSubmit = async (values: FormRecords) => {
    setSubmitTouched(true);
    const questionsFlow =
      assessmentType === AssessmentType.papaSocialIndexMedicare ? questionsFlowMedicare : {};
    const { editAnswers, deleteAnswers, createAnswers } = formatAnswersToEditInputs({
      values,
      questions,
      answers,
      initialValues,
      questionsFlow,
    });

    try {
      for (const answer of editAnswers) {
        await editAssessmentAnswer({
          variables: {
            answer: answer.answer as string,
            answerId: answer.answerId,
            surveyQuestionId: answer.questionId,
            surveySubmissionId: assessmentId,
          },
        });
      }

      for (const answer of deleteAnswers) {
        await destroyAnswer({
          variables: {
            answerId: answer.answerId,
          },
        });
      }
      for (const answer of createAnswers) {
        await createAnswer({
          variables: {
            input: {
              surveyQuestionId: answer.questionId,
              surveySubmissionId: assessmentId,
              answer: answer.answer,
            },
          },
        });
      }

      toast.success("The assessment answers were updated");
      onClose();
      onRefetch();
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik<FormRecords>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <FormikForm noValidate>
        <Drawer.Container>
          {questions.map((question) => (
            <Question
              key={question.id}
              type={assessmentType}
              question={question}
              submitTouched={submitTouched}
            />
          ))}
        </Drawer.Container>

        <Drawer.Footer>
          <Button type="button" variant="secondary" onClick={onClose} disabled={isEditing}>
            Close
          </Button>

          <Form.SubmitButton action noContent>
            Submit assessment answers
          </Form.SubmitButton>
        </Drawer.Footer>
      </FormikForm>
    </Formik>
  );
};

type ModalProps = {
  toggle: () => void;
  isOpen: boolean;
  papaId: string;
  assessmentId: string;
  assessmentType: AssessmentType;
  onClose: () => void;
  onRefetch?: () => void;
};

const Modal = ({
  toggle,
  isOpen,
  papaId,
  assessmentId,
  assessmentType,
  onClose,
  onRefetch = () => {},
}: ModalProps) => {
  return (
    <Drawer onClose={toggle} title="Edit Assessment Answers" open={isOpen} size="lg">
      <AssessmentsQuestionsEdit
        papaId={papaId}
        assessmentId={assessmentId}
        assessmentType={assessmentType}
        onClose={() => {
          toggle();
          onClose();
        }}
        onRefetch={onRefetch}
      />
    </Drawer>
  );
};

export default Modal;
