import moment from "moment-timezone";
import React from "react";
import { toast } from "react-toastify";

import ConfirmModal from "components/Modal/ConfirmModal";
import { useGetBusinessPolicyEntityQuery, useUpdateBusinessPolicyMutation } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";

interface InactiveBusinessPolicyProps {
  accountId: string;
  businessPolicyId: string;
  onClose: () => void;
  isOpen: boolean;
}

export const InactiveBusinessPolicy: React.FC<InactiveBusinessPolicyProps> = ({
  accountId,
  businessPolicyId,
  isOpen,
  onClose,
}) => {
  const { singularEntityName, capitalized } = useCallItClientProgramOrBusiness();

  const { data, loading: getBusinessPolicyEntityLoading } = useGetBusinessPolicyEntityQuery({
    variables: {
      businessPolicyId,
    },
  });
  const [updateBusinessPolicy, { loading }] = useUpdateBusinessPolicyMutation();

  const startsAt = data?.businessPolicy?.data?.startsAt;
  const endsAt = moment().subtract(1, "minutes").toISOString();

  const onConfirm = async () => {
    if (!startsAt) return;

    try {
      const { data } = await updateBusinessPolicy({
        variables: {
          businessPolicyId,
          input: {
            accountId,
            startsAt,
            endsAt,
          },
        },
      });

      if (data?.updateBusinessPolicy?.data?.id) {
        toast.success(`${capitalized.singularEntityName} Policy was inactivated with success!`);
        onClose();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      loading={loading || getBusinessPolicyEntityLoading}
      header={`Set Inactive ${capitalized.singularEntityName} Policy`}
      confirmBtnText="Yes"
      onCancel={onClose}
      onConfirm={onConfirm}
    >
      Are you sure that you want to set this {singularEntityName} policy to inactive?
    </ConfirmModal>
  );
};
