import { Gender } from "generated/types";

export const genderOptions = [
  { label: "Male", value: Gender.Male },
  { label: "Female", value: Gender.Female },
  { label: "Not Listed", value: Gender.Diverse },
];

//------------------------------------------------------------------------------
// This powers the `Select` in the enrollment form:
//   - src/pages/EligibleMembers/List/EnrollMemberForm/AdditionalDetails.tsx
//
// ...as well as the papa details form (both create and edit):
//   - src/pages/Papas/Details/EditPreferenceForm/EditPreferenceForm.tsx
//
// BOTH of these have code that converts `Gender.Diverse` to `null` before
// submitting the form. This is intended because we overload `Gender.Diverse`
// to mean *no preference*. If we submitted `Gender.Diverse`, it would cause
// prevent the papa from being matched with pals in most markets.
//
// So instead of renaming the option throughout, we apparently just repurposed
// `Gender.Diverse` to mean no preference.
//------------------------------------------------------------------------------
export const genderPreferenceOptions = [
  { label: "Male", value: Gender.Male },
  { label: "Female", value: Gender.Female },
  { label: "No preference", value: Gender.Diverse },
];
