import { gql } from "@apollo/client";

export const GET_PAL_COMPLAINTS = gql`
  query palComplaints($id: ID!) {
    pal(id: $id) {
      data {
        palComplaints {
          data {
            id
            comment
            reason
            insertedAt
            softDeletedAt
            deletedBy {
              data {
                id
              }
            }
            insertedBy {
              data {
                id
                fullName
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PAL_COMPLAINT = gql`
  mutation DeletePalComplaint($id: ID!) {
    deletePalComplaint(id: $id) {
      data {
        id
        softDeletedAt
        deletedBy {
          data {
            id
          }
        }
      }
    }
  }
`;
