import React from "react";
import styled from "styled-components";

import { StyledCheckbox } from "components/Checkbox";
import Text from "components/Text";
import { VisitSurvey } from "generated/types";

interface Props {
  surveys: VisitSurvey[];
}

export const SurveysList: React.FC<Props> = ({ surveys }) => (
  <Container>
    {surveys.map((survey, index) => {
      return (
        <SurveyContainer key={survey?.survey?.data?.name ?? index}>
          <SurveyCheckbox
            type="checkbox"
            id={`survey-${survey.id ?? index}`}
            checked={!!survey.completedAt}
          />
          <Text>
            <a href={survey?.url ?? ""} target="_blank" rel="noopener noreferrer">
              {survey?.survey?.data?.name}
            </a>
          </Text>
        </SurveyContainer>
      );
    })}
  </Container>
);

const SurveyContainer = styled.div`
  display: flex;
  grid-gap: 10px;

  a:visited {
    color: inherit;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;

const SurveyCheckbox = styled(StyledCheckbox)`
  pointer-events: none;
`;
