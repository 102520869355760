import { useQuery } from "@apollo/client";
import { DocumentText } from "@styled-icons/ionicons-outline/DocumentText";
import React from "react";
import Helmet from "react-helmet";

import Button from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import { Survey, SurveyListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";

import AddEditSurveyModal from "./AddEditSurveyModal";
import { GET_SURVEYS } from "./gql";
import { useSurveysTableColumns } from "./useSurveysTableColumns";

interface Data {
  surveys: SurveyListResult;
}

const Surveys = () => {
  useTopBarOptions({ title: "Surveys", icon: DocumentText });
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { columns, action, survey, addSurvey, closeModal } = useSurveysTableColumns();

  const { data, loading, error, refetch } = useQuery<Data>(GET_SURVEYS, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const surveys = data?.surveys.data;
  const pagination = data?.surveys.pagination;

  return (
    <div>
      <Helmet>
        <title>Surveys - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="flex-end">
        <Button action onClick={addSurvey}>
          Add Survey
        </Button>
      </FlexRow>

      <SmartTable<Survey>
        keyField="id"
        data={nonNull(surveys)}
        loading={loading}
        columns={columns}
        pagination={pagination}
        error={error}
        cursorPagination
      />

      <AddEditSurveyModal
        toggle={closeModal}
        isOpen={action === DataTableMenuActions.Edit}
        survey={survey}
        refetch={refetch}
      />
    </div>
  );
};

export default Surveys;
