import { useField } from "formik";
import React from "react";
import styled from "styled-components";

import Feedback from "components/Form/FormFeedback";
import { ScheduleVisitLabel as Label } from "components/Form/ScheduleVisitLabel";

type Props = {
  name: string;
  label?: string;
};

export const FileInput = ({ name, label }: Props) => {
  const [{ value, ...field }, { touched, error }, { setValue }] = useField(name);

  const handleChange = ({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (validity.valid) {
      setValue(files?.[0]);
    } else {
      setValue(null);
    }
  };

  const isInvalid = touched && !!error;

  return (
    <>
      {!!label && <Label htmlFor={name}>{label}</Label>}
      <Input
        {...field}
        id={name}
        type="file"
        accept=".csv"
        onChange={handleChange}
        isInvalid={isInvalid}
        aria-label={label}
      />
      {isInvalid && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

type PropsInput = React.InputHTMLAttributes<HTMLInputElement> & {
  isInvalid?: boolean;
};

export const Input = styled.input<PropsInput>`
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.borderColor};
  display: inline-flex;
  border: 1px solid
    ${({ isInvalid, theme }) => {
      if (isInvalid) return theme.variants.danger;
      return theme.borderColor;
    }};
  min-height: 18px;
  padding: 0.625rem 0.75rem;
  width: 100%;
  max-width: 18.75rem;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f3f3f3;
  }
`;
