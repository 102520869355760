import * as yup from "yup";

import { VisitBusinessPurpose } from "generated/types";

export interface Schema {
  callType: VisitBusinessPurpose;
}

export const schema = yup.object().shape({
  callType: yup.string().required("Please select call type"),
});
