import { gql } from "@apollo/client";

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    destroyLocation(id: $id) {
      data {
        id
      }
    }
  }
`;
