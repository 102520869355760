import { ApolloError } from "@apollo/client";
import React from "react";
import styled from "styled-components";

import Text from "components/Text";

interface Props {
  error: ApolloError | Error | undefined;
}

const jsonErrors = ["Unexpected end of JSON input", "Unexpected token < in JSON at position 0"];

const QueryErrors: React.FC<Props> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Container>
      <ErrorText>
        {jsonErrors.includes(error.message) ? "Something went wrong" : error.message}
      </ErrorText>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  margin: 10px 0;
`;

const ErrorText = styled(Text)`
  color: #721c24;
`;

export default QueryErrors;
