import { useFormikContext } from "formik";
import isArray from "lodash/isArray";
import React, { createContext, useContext, useMemo } from "react";

import { AssessmentQuestionFragment } from "generated/types";
import { getResultFromPoints, sumAnsweredQuestions } from "utils/helpers/assessments";
import { nonNull } from "utils/nonNull";

import { FormValues } from "./models";

interface AnswerScoreValue {
  score: number | null;
  firstQuestionAnswer: string | null;
}

const AnswerScore = createContext<AnswerScoreValue>({
  score: null,
  firstQuestionAnswer: null,
});

type AnswerScoreProviderProps = {
  questions: AssessmentQuestionFragment[];
};

export const AnswerScoreProvider: React.FC<AnswerScoreProviderProps> = ({
  questions,
  children,
}) => {
  const { values } = useFormikContext<FormValues>();

  const score = useMemo(() => sumAnsweredQuestions(values, questions), [values, questions]);

  const firstQuestionAnswer = useMemo(() => {
    const question = questions.find((question) => question.position === 1);

    const valuesForQuestion = values[`question-${question?.id}`];
    if (!question || !isArray(valuesForQuestion)) return null;

    return nonNull(question.options?.data)
      .filter((option) => valuesForQuestion?.includes(option.id!))
      .filter((option) => option.answerText)
      .map((option) => option.answerText)
      .join(", ");
  }, [values, questions]);

  return (
    <AnswerScore.Provider value={{ score, firstQuestionAnswer }}>{children}</AnswerScore.Provider>
  );
};

export const useAnswersScore = () => {
  const { score, firstQuestionAnswer } = useContext(AnswerScore);
  const result = score ? getResultFromPoints(score) : null;
  return { score, result, firstQuestionAnswer };
};
