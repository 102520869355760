import React, { forwardRef } from "react";
import { DefaultTheme } from "styled-components";
import styled from "styled-components";

import mergeRefs from "utils/mergeRefs";

type Sizes = keyof DefaultTheme["sizes"];
type Variants = keyof DefaultTheme["variants"];

type ToggleProps = {
  children: React.ReactNode;
  size?: Sizes;
  variant?: Variants;
  readonly handleToggle?: () => void;
  readonly toggleRef?: React.MutableRefObject<HTMLButtonElement>;
};

const StyledButton = styled("button")`
  -webkit-appearance: button;
  background: transparent;
  cursor: pointer;
`;

const DropdownButtonToggle = forwardRef<HTMLButtonElement, ToggleProps>(
  ({ handleToggle, toggleRef, ...rest }: ToggleProps, ref) => (
    <StyledButton ref={mergeRefs([toggleRef, ref])} onClick={handleToggle} {...rest} />
  )
);

DropdownButtonToggle.displayName = "Toggle";

export default DropdownButtonToggle;
