import moment, { Moment } from "moment-timezone";

import { DEFAULT_TIMEZONE } from "constants/date";

export const isDayBefore = (day: Moment, timezone: string = DEFAULT_TIMEZONE) => {
  try {
    if (!day.isValid()) {
      return false;
    }
    return moment.tz(day.format("LLL"), "LLL", timezone).isBefore(moment().tz(timezone), "day");
  } catch {
    return false;
  }
};

export const isDayBeforeOtherDay = (
  dayOne: Moment,
  dayTwo: Moment,
  timezone: string = DEFAULT_TIMEZONE
) =>
  moment
    .tz(dayOne.format("LLL"), "LLL", timezone)
    .isBefore(moment.tz(dayTwo.format("LLL"), "LLL", timezone));
