import noop from "lodash/noop";
import { toast } from "react-toastify";

import { useSubmitSupportTicketMutation } from "generated/types";

export const useSubmitSupportTicket = ({ onSuccess = noop, onError = noop } = {}) => {
  const [mutation, { data, loading }] = useSubmitSupportTicketMutation();

  const submitSupportTicket = (variables: any) => {
    mutation({ variables })
      .then(({ data }) => {
        onSuccess();
        window.open(data?.submitSupportTicket?.url, "_blank");
      })
      .catch((_e) => {
        onError();
        toast.error("We were unable to generate a URL to create a new support ticket.");
      });
  };

  return { data, loading, submitSupportTicket };
};
