import { PaymentState, VisitState } from "generated/types";

export const colors = {
  white: "#ffffff",
  black: "#000000",
  blue200: "#EAEFFF",
  blue500: "#2E5BFF",
  blue800: "#0E2765",
  green500: "#1BB934",
  red500: "#ED1C24",
  gray100: "rgba(235,239,255,0.1);",
  gray150: "#F8F8F8",
  gray200: "#F4F6FF",
  gray250: "#eaeaea",
  gray300: "#CED0DA",
  gray350: "#CACACA",
  gray400: "#979797",
  yellow: "#FCA009",

  textMain: "#354052",
  textMuted: "#294157",
  textTint: "#B0BAC9",
  textStatus: "#6621F8",

  borderColor: "#DFE3E9",
  borderColorDark: "#CED0DA",

  // Status Color Legend
  pending: "#E100B0",
  accepted: "#07D1DE",
  confirmed: "#E5CF00",
  enRoute: "#3009CC",
  started: "#3009CC",
  updated: "#3009CC",
  completed: "#4ede9e",
  canceled: "#ED1C24",
  reviewed: "#FCA009",
  partnerOperated: "#B37FEB",
  charged: "#00A56C",
  approved: "#0290CD",
  unreviewed: "#FC6009",
  paymentPending: "#3009CC",
  refunded: "#FCA009",
  resolved: "#07D1DE",
};

const dimensions = {
  sizes: {
    small: "0.5rem 1rem",
    middle: "0.625rem 1.875rem",
    large: "0.875rem 2rem",
  },
  fontSizes: {
    small: "0.75rem", // 12px
    middle: "0.875rem", // 14px
    large: "1rem", // 16px
    largex: "1.125rem", // 18 px
  },
  sidebar: {
    fullWidth: "215px",
    collapsedWidth: "80px",
    itemHeight: "56px",
  },
  borderRadius: "7px",
  drawer: {
    padding: "1.875rem",
  },
};

const visitState: {
  [key in VisitState]: string;
} = {
  ACCEPTED: colors.accepted,
  CANCELED: colors.canceled,
  COMPLETED: colors.completed,
  CONFIRMED: colors.confirmed,
  ENROUTE: colors.enRoute,
  EXPIRED: colors.canceled,
  PARTNER_OPERATED: colors.partnerOperated,
  PENDING: colors.pending,
  REJECTED: colors.canceled,
  REVIEWED: colors.reviewed,
  STARTED: colors.started,
  TERMINATED: colors.canceled,
};

const paymentState: {
  [key in PaymentState]: string;
} = {
  APPROVED: colors.approved,
  CHARGED: colors.charged,
  FAILED: colors.canceled,
  PENDING: colors.paymentPending,
  REFUNDED: colors.refunded,
  RESOLVED: colors.resolved,
};

const eventAction = {
  updated: colors.updated,
  accepted: colors.accepted,
  inserted: colors.accepted,
  canceled: colors.canceled,
  completed: colors.completed,
  confirmed: colors.confirmed,
  member_confirmed: colors.confirmed,
  enRoute: colors.enRoute,
  expired: colors.canceled,
  partnerOperated: colors.partnerOperated,
  pending: colors.pending,
  rejected: colors.canceled,
  reviewed: colors.reviewed,
  started: colors.started,
  terminated: colors.started,
};

export const theme = {
  ...dimensions,
  variants: {
    primary: colors.blue800,
    secondary: colors.gray400,
    success: colors.green500,
    danger: colors.red500,
    warning: colors.yellow,
    text: colors.textMuted,
  },
  statusLegend: visitState,
  conductStatusLegend: {
    SUSPENDED: colors.yellow,
    UNDER_INVESTIGATION: colors.pending,
    BANNED: colors.red500,
  },
  paymentStatusLegend: paymentState,
  paymentInvoiceStateLegend: {
    APPROVED: colors.approved,
    AUTO_APPROVED: colors.charged,
    PENDING: colors.pending,
    REJECTED_MANUALLY: colors.refunded,
    FLAGGED: colors.enRoute,
    RESOLVED_MANUALLY: colors.partnerOperated,
    TRANSACTION_FAILED: colors.canceled,
    TRANSACTION_SUCCEEDED: colors.resolved,
  },
  tipaltiDisbursementStatusLegend: {
    ERROR: colors.canceled,
    PAID: colors.completed,
    PROCESSING: colors.reviewed,
  },
  invoiceStateLegend: {
    APPROVED: colors.approved,
    AUTO_APPROVED: colors.charged,
    PENDING: colors.pending,
    REJECTED_MANUALLY: colors.refunded,
    FLAGGED: colors.enRoute,
    RESOLVED_MANUALLY: colors.partnerOperated,
    TRANSACTION_FAILED: colors.canceled,
    TRANSACTION_SUCCEEDED: colors.resolved,
  },
  five9CallTypeLegend: {
    INBOUND: "#07D1DE",
    MANUAL: "#B37FEB",
    OUTBOUND: "#2E5BFF",
  },
  background: {
    body: colors.gray200,
    transparentTint: colors.gray100,
    grayTint: colors.gray150,
    primaryTint: colors.blue500,
    panelHeader: colors.gray350,
    panelHeaderActivated: colors.blue800,
    panelContent: colors.white,
    timeSlot: colors.gray250,
    invert: colors.black,
  },
  text: {
    main: colors.textMain,
    muted: colors.textMuted,
    tint: colors.textTint,
    warning: colors.yellow,
    error: colors.red500,
    primary: colors.blue800,
    secondary: colors.gray400,
    panelHeader: colors.white,
    timeSlotText: colors.textMain,
    status: colors.textStatus,
  },
  borderColor: colors.borderColor,
  borderColorDark: colors.borderColorDark,
  borderRadius: dimensions.borderRadius,
  eventAction,
};

type ThemeInterfaceDimensions = typeof dimensions;

interface ThemeInterface extends ThemeInterfaceDimensions {
  variants: { [key in keyof typeof theme.variants]: string };
  statusLegend: { [key in VisitState]: string };
  eventAction: { [key in string]: string };
  conductStatusLegend: {
    [key in keyof typeof theme.conductStatusLegend]: string;
  };
  paymentStatusLegend: {
    [key in keyof typeof theme.paymentStatusLegend]: string;
  };
  invoiceStateLegend: {
    [key in keyof typeof theme.invoiceStateLegend]: string;
  };
  paymentInvoiceStateLegend: {
    [key in keyof typeof theme.paymentInvoiceStateLegend]: string;
  };
  tipaltiDisbursementStatusLegend: {
    [key in keyof typeof theme.tipaltiDisbursementStatusLegend]: string;
  };
  five9CallTypeLegend: {
    [key in keyof typeof theme.five9CallTypeLegend]: string;
  };
  background: { [key in keyof typeof theme.background]: string };
  text: { [key in keyof typeof theme.text]: string };
  borderColor: string;
  borderColorDark: string;
  borderRadius: string;
}

declare module "styled-components" {
  export interface DefaultTheme extends ThemeInterface {}
}
