import { gql } from "@apollo/client";

export const GET_SERVICE_DETAIL = gql`
  query getServiceDetail($id: ID!) {
    serviceRequest(id: $id) {
      data {
        id
        origin
        type {
          data {
            id
            name
          }
        }
        openedAt
        closedAt
        closedReason
        priority
        status
        need {
          data {
            id
            code
            name
          }
        }
        needText
        description
        rating
        account {
          data {
            id
            fullName
          }
        }
        assignedTo {
          data {
            id
            fullName
          }
        }
        assignedToId
        serviceRequestComments(sorting: { insertedAt: DESC }) {
          data {
            account {
              data {
                id
                fullName
                email
              }
            }
            id
            serviceRequestId
            accountId
            note
            insertedAt
            updatedAt
          }
        }
        papa {
          data {
            id
            fullName
            dataVisibility {
              concealed
            }
          }
        }
        serviceRequestFollowups {
          data {
            id
            action
            actionText
            completed
            completedAt
            serviceRequestId
            note
            dueAt
            status
            assignedTo {
              data {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SERVICE_FOLLOWUPS = gql`
  query getServiceFollowups(
    $id: ID!
    $filter: [ServiceRequestFollowupFilter!]
    $pagination: PaginationInput
  ) {
    serviceRequest(id: $id) {
      data {
        id
        serviceRequestFollowups(filter: $filter, pagination: $pagination) {
          data {
            id
            action
            actionText
            reason
            completed
            completedAt
            serviceRequestId
            note
            dueAt
            status
            assignedTo {
              data {
                id
                fullName
              }
            }
          }
          pagination {
            limit
            page
            totalCount
            totalPages
          }
        }
      }
    }
  }
`;
