import { ApolloError } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";

import Button from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import Heading from "components/Heading";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { PalOnboarding } from "generated/types";
import { PalVehicleInsuranceUpdateModal } from "modals/PalVehicleInsuranceUpdateModal";
import { formatDateTime } from "utils/helpers/formatters";

import "./vehicle-insurance-image.css";

type ColumnProps = {
  size?: number;
};

interface Props {
  palOnboarding?: PalOnboarding | null;
  loading: boolean;
  error: ApolloError | undefined;
}

const VehiclePanel: React.FC<Props> = ({ palOnboarding, loading, error }) => {
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  if (loading) return <Panel.Side />;
  if (error)
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );

  if (!palOnboarding) return <Panel title="Vehicle">{EMPTY_PLACEHOLDER}</Panel>;

  const Update = () => {
    setAction(DataTableMenuActions.Update);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
  };

  const {
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleColor,
    hasVehicle,
    vehicleInsuranceImageUrl,
    vehicleStatusConfirmedAt,
    id,
  } = palOnboarding;

  const isInsuranceStatusApproved = palOnboarding?.vehicleInsuranceStatus === "APPROVED";

  if (!hasVehicle) return <Panel title="Vehicle">Pal has no vehicle</Panel>;

  return (
    <Panel title="Vehicle">
      <VehicleContainer>
        <Panel.Item>
          <Text bold>Make</Text>
          <Text>{vehicleMake || EMPTY_PLACEHOLDER}</Text>
        </Panel.Item>
        <Panel.Item>
          <Text bold>Model</Text>
          <Text>{vehicleModel || EMPTY_PLACEHOLDER}</Text>
        </Panel.Item>
        <Panel.Item>
          <Text bold>Year</Text>
          <Text>{vehicleYear || EMPTY_PLACEHOLDER}</Text>
        </Panel.Item>
        <Panel.Item>
          <Text bold>Color</Text>
          <Text>{vehicleColor || EMPTY_PLACEHOLDER}</Text>
        </Panel.Item>
      </VehicleContainer>
      <Heading as="h2" color="primary">
        Vehicle Insurance Status
      </Heading>
      <Panel>
        <Panel.Item>
          <Grid>
            <Column size={3}>
              <Text bold>Status</Text>
            </Column>
            <Column size={6}>
              <Text bold>Updated On</Text>
            </Column>
            <Column size={2}>
              <Button
                style={{
                  fontFamily: "Avenir, sans-serif",
                  marginBottom: "0.85rem",
                  width: "100%",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  border: 0,
                }}
                onClick={Update}
              >
                <Text underline bold size="large">
                  Update
                </Text>
              </Button>
            </Column>
          </Grid>
        </Panel.Item>
        <Panel.Item>
          <Grid>
            <Column size={3}>{isInsuranceStatusApproved ? "Approved" : "Pending"}</Column>
            <Column size={6}>{formatDateTime(vehicleStatusConfirmedAt)}</Column>
            <Column size={2}></Column>
          </Grid>
        </Panel.Item>
      </Panel>
      <Heading as="h2" color="primary">
        Proof of Insurance
      </Heading>
      <PalVehicleInsuranceUpdateModal
        palOnboardingId={id ?? ""}
        insuranceStatus={palOnboarding?.vehicleInsuranceStatus ?? ""}
        isOpen={action === DataTableMenuActions.Update}
        toggle={closeModal}
      />
      <Panel.Item>
        {vehicleInsuranceImageUrl ? (
          <>
            {isInsuranceStatusApproved ? null : (
              <img
                id="vehicle-insurance-image"
                alt="Prospect Pal Vehicle Insurance"
                src={vehicleInsuranceImageUrl}
              />
            )}
            <FlexRow>
              <Button
                onClick={() => {
                  window.open(vehicleInsuranceImageUrl);
                }}
              >
                Download Image
              </Button>
            </FlexRow>
          </>
        ) : (
          EMPTY_PLACEHOLDER
        )}
      </Panel.Item>
    </Panel>
  );
};

export default VehiclePanel;

const Grid = styled("div")`
  display: flex;
  height: 20px;
`;

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
`;
const VehicleContainer = styled.div`
  display: flex;
  gap: 20px;
  ${Panel.Item} {
    min-width: 100px;
  }
`;
