import { Task } from "generated/types";
import { withIds } from "utils/nonNull";

export const getTasksNames = (tasks: Task[] = [], taskIds: string[]): string => {
  if (!tasks.length || !taskIds.length) return " - ";

  const dict: { [id: string]: string } = withIds(tasks).reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: cur.name,
    };
  }, {});

  return taskIds.map((id) => dict[id]).join(", ");
};
