import { Location } from "constants/location";
import { SortDirection } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";

export interface QueryParams {
  page: string;
  limit: string;
  afterCursor: string;
  beforeCursor: string;
  visitType?: string;
  dayTimeFrom?: string;
  dayTimeTo?: string;
  dayFrom?: string;
  dayTo?: string;
  timezone?: string;
  states?: string;
  tasks?: string;
  sort?: SortDirection;
  accountId?: string;
  accountType?: string;
  pal?: string;
  papa?: string;
  member?: string;
  language?: string;
  location?: Location;
  priority?: string;
  serviceRequestTypeId?: string;
  serviceRequestNeedId?: string;
  status?: string;
  origin?: string;
  id?: string;
  assignedToId?: string;
  healthPlanId?: string;
  papaId?: string;
  name?: string;
  state?: string | string[];
  city?: string;
  county?: string;
  demandProfile?: string[];
  isCritical?: "true" | null;
  isGrievancesAppeals: "true" | null;
  isHighPriority?: "true" | null;
  isPreviouslyMissed?: "true" | null;
  isUberEligible?: "true" | null;
  isEmployerVisit?: "true" | null;
  isVisitLeadTimeException?: "true" | null;
  timeOpen?: string;
  taskIds?: string;
  visitId?: string;
  vehicleInsuranceStatus?: string;
}

export const usePageInfo = <State>(defaultStates: State[] = []) => {
  const {
    page: pageString,
    limit: limitString,
    states,
    location,
    ...otherQueryParams
  } = useUrlQuery() as Partial<QueryParams>;
  const page = parseInt(pageString ?? "1");
  const limit = parseInt(limitString ?? "25");

  return {
    page,
    limit,
    states: states || defaultStates.join(","),
    location,
    ...otherQueryParams,
  };
};
