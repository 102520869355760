import { gql } from "@apollo/client";

export const EXPORT_PAPAS = gql`
  mutation exportPapas {
    exportPapas {
      data {
        success
      }
    }
  }
`;
