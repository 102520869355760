import { useQuery } from "@apollo/client";
import { RateReview } from "@styled-icons/material-outlined/RateReview";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import { DropdownActionMenu } from "components/ActionsMenu/DropdownActionMenu";
import Dropdown from "components/Dropdown";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { papaStateOptions } from "constants/papaState";
import { Papa, PapaState, SortDirection } from "generated/types";
import { useCurrentAccountPermission } from "hooks/useCurrentAccountPermission";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import CreatePapaComplaintModal from "modals/CreatePapaComplaintModal";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";
import { isUuid } from "utils/isUuid";

import { Filter } from "./Filter";
import { PAPA_CONDUCT } from "./gql";

const PapaConduct = () => {
  useTopBarOptions({ title: "Papa Conduct & Records", icon: RateReview, breadcrumbs: ["Reviews"] });
  const { isSupervisor, isGrievancesAppealsAdmin } = useCurrentAccountRole();
  const { beforeCursor, afterCursor, limit, papa, dayTimeFrom, dayTimeTo, status } = usePageInfo();
  const [sorting, setSorting] = useState({ lastComplaintInsertedAt: SortDirection.Desc });
  const [actionPapaId, setActionPapaId] = useState("");
  const { isOpen: createPapaComplaintModalOpen, toggle: toggleCreatePapaComplaintModal } =
    useModalToggle();

  const dateFilter =
    dayTimeFrom && dayTimeTo
      ? { lastComplaintInsertedAt: { gte: dayTimeFrom, lte: dayTimeTo } }
      : { lastComplaintInsertedAt: { gte: "1970-01-01T00:00:00Z" } };
  const papaFilter = papa
    ? isUuid(papa)
      ? { id: { eq: papa } }
      : { fullName: { cont: papa } }
    : {};
  const statusFilter = status?.length
    ? {
        status: {
          in: status.split(",") as PapaState[],
        },
      }
    : {};
  const filter = { ...dateFilter, ...papaFilter, ...statusFilter };

  const { data, loading, error, refetch } = useQuery(PAPA_CONDUCT, {
    fetchPolicy: "network-only",
    variables: {
      filter: Object.keys(filter).length ? [filter] : [],
      sorting,
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const showCreateComplaintButton = useCurrentAccountPermission(["CAN_CREATE_COMPLAINT"]);

  if (!(isSupervisor || isGrievancesAppealsAdmin)) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Papa Conduct & Records - Papa Admin</title>
      </Helmet>

      <Filter loading={loading} />

      <SmartTable<Papa>
        loading={loading}
        error={error}
        keyField="id"
        pagination={data?.papas.pagination}
        rowProps={({ status }) => {
          if (
            status === PapaState.Suspended ||
            status === PapaState.Banned ||
            status === PapaState.UnderInvestigation
          ) {
            return {
              conductStatusLegend: status,
            };
          }
          return {};
        }}
        columns={[
          {
            header: { title: "Papa Name" },
            dataField: "fullName",
            formatter({ fullName, id }) {
              return <Link to={`/papas/${id}`}>{fullName}</Link>;
            },
          },
          {
            header: { title: "Total Points" },
            dataField: "complaintPoints",
            formatter({ complaintPoints }) {
              return `${complaintPoints} points`;
            },
          },
          {
            header: { title: "Status" },
            dataField: "status",
            formatter({ status }) {
              return mapValueToLabel(papaStateOptions, status);
            },
          },
          {
            header: {
              title: "Most Recent Points",
              sort: sorting.lastComplaintInsertedAt,
              onChange: () =>
                setSorting({
                  ...sorting,
                  lastComplaintInsertedAt:
                    sorting.lastComplaintInsertedAt === SortDirection.Desc
                      ? SortDirection.Asc
                      : SortDirection.Desc,
                }),
            },
            dataField: "mostRecentPoints",
            formatter({ mostRecentComplaint }) {
              const complaint = mostRecentComplaint?.data;
              return complaint ? (
                <>
                  <Text>{`${formatDateTime(complaint.insertedAt)}`}</Text>
                  <Text>{`Initial points: ${complaint.initialPoints}`}</Text>
                  <Text>{`Remaining points: ${complaint.remainingPoints}`}</Text>
                </>
              ) : (
                EMPTY_PLACEHOLDER
              );
            },
          },
          {
            header: { title: "Contact Info" },
            dataField: "contactInfo",
            formatter({ email, phoneNumber }) {
              return (
                <>
                  <Text>{email}</Text>
                  <Text>{phoneNumber}</Text>
                </>
              );
            },
          },
          {
            header: { title: "" },
            dataField: "actions",
            cellProps: {
              fixed: true,
              align: "center",
            },
            formatter({ id }) {
              return (
                <DropdownActionMenu isListMenu={true}>
                  <Dropdown.Link to={`/papas/${id}`}>View papa</Dropdown.Link>
                  {showCreateComplaintButton && (
                    <Dropdown.Item
                      onClick={() => {
                        if (!id) return;

                        setActionPapaId(id);
                        toggleCreatePapaComplaintModal();
                      }}
                    >
                      Add complaint
                    </Dropdown.Item>
                  )}
                </DropdownActionMenu>
              );
            },
          },
        ]}
        data={data?.papas?.data}
      />

      <CreatePapaComplaintModal
        papaId={actionPapaId}
        isOpen={createPapaComplaintModalOpen}
        toggle={toggleCreatePapaComplaintModal}
        onCreateFinish={() => refetch()}
      />
    </>
  );
};

export default PapaConduct;
