import { Formik } from "formik";
import React from "react";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";

import { Schema, schema } from "./schema";

type Props = {
  initialValues: Schema;
  onSubmit: (values: Schema) => Promise<void>;
  onClose: () => void;
};

export const VirtualBaseRateForm = ({ initialValues, onSubmit, onClose }: Props) => {
  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Row>
            <Form.Col>
              <Form.Group controlId="baseRate">
                <Form.Label>Base Rate</Form.Label>
                <Form.Input type="number" name="baseRate" aria-label="baseRate" />
              </Form.Group>
            </Form.Col>
          </Form.Row>
        </ModalContainer>
        <ModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
