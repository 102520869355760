import React from "react";

import { Column } from "components/SmartTable/schema";
import { PalStatusLog, PapaStatusLog } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

import { StatusCell } from "./StatusCell";

export const getColumns = <T,>(): Column<T>[] => {
  return [
    {
      header: { title: "Status" },
      dataField: "id",
      formatter(item: T) {
        const { newState, newStatus } = item as T & PalStatusLog & PapaStatusLog;

        return <StatusCell status={newStatus || newState} />;
      },
    },
    {
      header: { title: "Comment" },
      dataField: "comment",
    },
    {
      header: { title: "Timestamp" },
      dataField: "insertedAt",
      formatter(item: T) {
        const { insertedAt } = item as T & PapaStatusLog;

        return formatDateTime(insertedAt);
      },
    },
    {
      header: { title: "Changed By" },
      dataField: "account.data.fullName",
    },
  ];
};
