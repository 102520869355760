import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment-timezone";
import React, { useState } from "react";
import useCollapse from "react-collapsed";
import { GetTogglePropsInput, GetTogglePropsOutput } from "react-collapsed/dist/types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Button } from "components/Button";
import Conditional from "components/Conditional";
import { FlexRow } from "components/Flex";
import { Loader } from "components/Loader";
import Panel from "components/Panel";
import Text from "components/Text";
import { NONE_LISTED } from "constants/empty-placeholder";
import { CreditGrant, PapaAllotmentPeriod, useDeleteCreditGrantMutation } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { GET_PAPA_OVERVIEW } from "pages/Papas/gql";
import { creditEvent } from "utils/segment";

import { HoursAndMinutesDisplay } from ".";

const ShowCredits = ({ allotmentPeriod }: { allotmentPeriod: PapaAllotmentPeriod | null }) => {
  const { isSupervisor, isGrievancesAppealsAdmin, isSuperAdmin, isMemberSupportAdmin } =
    useCurrentAccountRole();
  const canRemove =
    isSupervisor || isGrievancesAppealsAdmin || isSuperAdmin || isMemberSupportAdmin;

  if (!allotmentPeriod) return null;

  const renderCreditGrants = () => {
    return [...(allotmentPeriod.creditGrants?.data || [])]
      .sort((a, b) => moment(b?.grantedAt).diff(moment(a?.grantedAt)))
      .map((creditGrant) => (
        <CreditGrantDetail key={creditGrant?.id} creditGrant={creditGrant} canRemove={canRemove} />
      ));
  };

  return (
    <>
      <CreditDetailSummary allotmentPeriod={allotmentPeriod} />

      {renderCreditGrants()}
    </>
  );
};

const CreditDetailSummary = ({ allotmentPeriod }: { allotmentPeriod: PapaAllotmentPeriod }) => {
  const startDate = moment(allotmentPeriod.startDate).format("MMM YYYY");
  const endDate = moment(allotmentPeriod.endDate).format("MMM YYYY");

  return (
    <StyledCreditDetailSummary>
      <StyledCreditDetailSummaryContainer>
        <HoursAndMinutesDisplay minutes={allotmentPeriod.creditedMinutes || 0} />

        <SummaryFlexRow>Credits Available for:</SummaryFlexRow>
        <SummaryFlexRow>
          {startDate} - {endDate}
        </SummaryFlexRow>
      </StyledCreditDetailSummaryContainer>
    </StyledCreditDetailSummary>
  );
};

const CreditGrantDetail = ({
  creditGrant,
  canRemove,
}: {
  creditGrant: CreditGrant | null;
  canRemove: boolean;
}) => {
  const [confirming, setConfirming] = useState<boolean>(false);

  const { getCollapseProps, getToggleProps } = useCollapse();

  if (!creditGrant) return null;

  const removeCreditLinkOnClick = () => {
    creditEvent("clicked remove credit", { papa_id: creditGrant.papaId });
    setConfirming(true);
  };

  const visitLink = creditGrant.visitId ? (
    <Link to={`/visits/${creditGrant.visitId}`}>{creditGrant.visitId}</Link>
  ) : (
    NONE_LISTED
  );

  return (
    <StyledCreditGrantDetail>
      <HoursAndMinutesDisplay minutes={creditGrant.minutes} hourPrefix="Granted" />
      <HoursFlexRow>on {moment(creditGrant.grantedAt).format("MMM Do, YYYY")}</HoursFlexRow>

      <CreditDetailField label="Reason" value={creditGrant?.creditReason?.data?.reasonName!} />

      <div {...getCollapseProps()}>
        <CreditDetailField label="Visit ID" value={visitLink} />
        <CreditDetailField label="Ticket ID" value={creditGrant.ticketId} />
        <CreditDetailField label="Notes" value={creditGrant.notes} />
        <CreditDetailField label="Granted By" value={creditGrant.grantedBy?.data?.fullName} />
        <Panel.Item></Panel.Item>
      </div>

      <ShowHideDetails getToggleProps={getToggleProps} papaId={creditGrant.papaId || null} />

      <Conditional show={canRemove}>
        <Conditional show={confirming}>
          <RemoveCredit creditGrant={creditGrant} setConfirming={setConfirming} />
        </Conditional>
        <Conditional show={!confirming}>
          <CenterContainer>
            <HoursFlexRow>
              <RemoveCreditLink onClick={removeCreditLinkOnClick}>Remove Credit</RemoveCreditLink>
            </HoursFlexRow>
          </CenterContainer>
        </Conditional>
      </Conditional>
    </StyledCreditGrantDetail>
  );
};

const CreditDetailField = ({
  label,
  value,
}: {
  label: string;
  value: Maybe<string> | JSX.Element;
}) => {
  return (
    <Panel.Item>
      <Text bold>{label}</Text>
      <Text>{value || NONE_LISTED}</Text>
    </Panel.Item>
  );
};

const ShowHideDetails = ({
  getToggleProps,
  papaId,
}: {
  getToggleProps: (config?: GetTogglePropsInput | undefined) => GetTogglePropsOutput;
  papaId: string | null;
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const onClick = () => {
    setExpanded((prevState) => {
      prevState
        ? creditEvent("clicked hide credit details", { papa_id: papaId })
        : creditEvent("clicked show credit details", { papa_id: papaId });
      return !prevState;
    });
  };

  return (
    <CenterContainer>
      <HoursFlexRow>
        <CreditButton size="small" {...getToggleProps({ onClick })}>
          {isExpanded ? "Hide" : "Show"} Details
        </CreditButton>
      </HoursFlexRow>
    </CenterContainer>
  );
};

const RemoveCredit = ({
  creditGrant,
  setConfirming,
}: {
  creditGrant: CreditGrant;
  setConfirming: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [deleteCreditGrantMutation, { loading }] = useDeleteCreditGrantMutation({
    variables: { id: creditGrant.id! },
    refetchQueries: [{ query: GET_PAPA_OVERVIEW, variables: { papaId: creditGrant.papaId! } }],
  });

  const keepCreditOnClick = () => {
    creditEvent("clicked keep credit", { papa_id: creditGrant.papaId });
    setConfirming(false);
  };

  const confirmOnClick = async () => {
    try {
      creditEvent("clicked confirm remove credit", { papa_id: creditGrant.papaId });
      const { data } = await deleteCreditGrantMutation();
      if (data?.deleteCreditGrant?.data?.id) toast.success("Credit has been removed");
    } catch (error) {
      toast.error((error as Error).message);
      setConfirming(false);
    }
  };

  return (
    <Loader isLoading={loading}>
      <>
        <ConfirmFlexRow>
          <Text>Are you sure you want to remove this credit?</Text>
        </ConfirmFlexRow>

        <CenterContainer>
          <ConfirmFlexRow>
            <ConfirmButton onClick={confirmOnClick}>Remove Credit</ConfirmButton>
          </ConfirmFlexRow>
        </CenterContainer>

        <CenterContainer>
          <ConfirmFlexRow>
            <KeepButton onClick={keepCreditOnClick}>Keep Credit</KeepButton>
          </ConfirmFlexRow>
        </CenterContainer>
      </>
    </Loader>
  );
};

const summaryPadding = "0.25rem";

const StyledCreditDetailSummary = styled.div`
  border: 0 solid #dfdfdf;
  border-bottom-width: 1px;
  font-size: 1.15rem;
  padding: ${summaryPadding};
`;

const StyledCreditDetailSummaryContainer = styled.div`
  background-color: ${({ theme }) => theme.background.grayTint};
  padding: calc(${({ theme }) => theme.drawer.padding} - ${summaryPadding});
`;

const StyledCreditGrantDetail = styled.div`
  border: 0 solid #dfdfdf;
  border-bottom-width: 1px;
  padding: ${({ theme }) => theme.drawer.padding};
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RemoveCreditLink = styled.div`
  text-decoration: underline;
  color: -webkit-link;
  cursor: pointer;
`;

const SummaryFlexRow = styled(FlexRow)`
  font-style: italic;
  margin-bottom: 0rem;
  margin-top: 0.25rem;
`;

const HoursFlexRow = styled(FlexRow)`
  margin-bottom: 1rem;
`;

const CreditButton = styled(Button)`
  border: 1px solid;
`;

const ConfirmFlexRow = styled(FlexRow)`
  margin-bottom: 1rem;
  flex: 1;
`;

const ConfirmButton = styled(CreditButton)`
  color: white;
  background: ${({ theme }) => theme.variants["primary"]};

  &:hover {
    opacity: 0.75;
  }

  width: 100%;
`;

const KeepButton = styled(CreditButton)`
  width: 100%;
`;

export default ShowCredits;
