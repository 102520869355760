import { gql } from "@apollo/client";

export const EXPORT_PALS = gql`
  mutation exportPals {
    exportPals {
      data {
        success
      }
    }
  }
`;
