export enum CommentType {
  Testimonial = "Testimonial",
  Complaint = "Complaint",
  Suggestion = "Suggestion",
  CareConcierge = "Care concierge",
  InternalMemo = "Internal memo",
}

export const COMMENT_TYPES = Array.from(Object.values(CommentType)) as string[];

export const commentOptions = [
  { label: "Testimonial", value: CommentType.Testimonial },
  { label: "Complaint", value: CommentType.Complaint },
  { label: "Suggestion", value: CommentType.Suggestion },
  { label: "Care concierge", value: CommentType.CareConcierge },
];
