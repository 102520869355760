import React, { FC } from "react";

import Dropdown from "components/Dropdown";
import { DropdownIcon } from "components/Dropdown/DropdownIcon";
import { AccountActivityChange } from "generated/types";

interface Props {
  changes: AccountActivityChange[];
  operationType?: string | null;
}

export const ActivityLogChanges: FC<Props> = ({ changes, operationType }) => {
  return (
    <Dropdown placement="bottom-end">
      <Dropdown.Actions>
        <DropdownIcon />
      </Dropdown.Actions>

      <Dropdown.Menu>
        <Dropdown.Item>
          <div>
            <p>
              <strong>{operationType}</strong>
            </p>
            {changes.map(({ key, value }) => (
              <p key={key}>
                {key} to {value}
              </p>
            ))}
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
