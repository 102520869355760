import React from "react";
import styled from "styled-components";

import Conditional from "components/Conditional";
import Form from "components/Form";
import Text from "components/Text";

type Props = {
  allowInPersonVisits: boolean;
  allowVirtualVisits: boolean;
  virtualVisitsOnly: boolean;
};

const VirtualVisitCheckbox = ({
  virtualVisitsOnly,
  allowInPersonVisits,
  allowVirtualVisits,
}: Props) => {
  return (
    <>
      <Conditional show={!!virtualVisitsOnly || !allowInPersonVisits}>
        <Text color={"error"}>This member is only eligible for virtual visits.</Text>
      </Conditional>
      <Conditional show={!allowVirtualVisits}>
        <Text color={"error"}>This member is only eligible for in person visits.</Text>
      </Conditional>
      <StyledFormGroup controlId="isVirtual">
        <div>
          <Form.Check
            controlId="isVirtual"
            name="isVirtual"
            label="Is this a virtual visit?"
            disabled={!!virtualVisitsOnly || !allowVirtualVisits || !allowInPersonVisits}
          />
        </div>
      </StyledFormGroup>
    </>
  );
};
const StyledFormGroup = styled(Form.Group)`
  display: flex;
`;

export default VirtualVisitCheckbox;
