import { gql } from "@apollo/client";

export const ASSESSMENTS_ANSWER_FRAGMENT = gql`
  fragment AssessmentsAnswer on Answer {
    answer
    id
    question {
      data {
        id
        questionText
        position
      }
    }
    score
  }
`;

export const ASSESSMENT_SUBMISSIONS_FRAGMENT = gql`
  fragment AssessmentSubmission on Submission {
    answers {
      data {
        ...AssessmentsAnswer
      }
    }
    id
    score
    submittedAt
    isLatest
    submittedBy {
      data {
        id
        fullName
      }
    }
    survey {
      data {
        formula
        id
        name
        insertedAt
        updatedAt
      }
    }
  }
  ${ASSESSMENTS_ANSWER_FRAGMENT}
`;

export const ASSESSMENTS_ANSWERS = gql`
  query AssessmentsAnswers($id: ID!) {
    papa(id: $id) {
      data {
        riskScore
        business {
          data {
            name
            type
          }
        }
        caregiver {
          data {
            business {
              data {
                name
                type
              }
            }
          }
        }
        surveySubmissions {
          data {
            ...AssessmentSubmission
          }
        }
        assignedScreeners {
          data {
            id
            screenerVersionId
            name
            title
            definition
          }
        }
        currentBusinessPolicy {
          data {
            id
            hiddenAssessments {
              data {
                id
                survey {
                  data {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ASSESSMENT_SUBMISSIONS_FRAGMENT}
`;

export const CREATE_SCREENER_SUBMISSION = gql`
  mutation createScreenerSubmission($input: ScreenerSubmissionInput!) {
    createScreenerSubmission(input: $input) {
      data {
        id
        papaId
        response
      }
    }
  }
`;

export const GET_SCREENER_SUBMISSIONS = gql`
  query getScreenerSubmissions($papaId: ID!) {
    papa(id: $papaId) {
      data {
        screenerSubmissions {
          data {
            screenerVersion {
              data {
                screener {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
