import { Field, FieldProps } from "formik";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import FormContext from "components/Form/FormContext";
import FormLabel from "components/Form/FormLabel";
import { opacity } from "utils/opacity";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  controlId?: string;
  label: string;
  name: string;
  value?: string;
  indeterminate?: boolean;
  color?: string;
  disabled?: boolean;
};

export const Container = styled("div")`
  display: flex;
  align-items: center;
`;

export const Label = styled(FormLabel)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.text.secondary : theme.text.main)};
  font-size: 0.875rem;
  padding-left: 0.5rem;
  margin-bottom: 0;
`;

export const StyledCheckbox = styled.input.attrs(({ type, indeterminate, color }: Props) => ({
  type,
  indeterminate,
  color,
}))`
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  background: white;
  border: 1px solid ${({ theme }) => theme.borderColorDark};
  border-radius: 0.25rem;
  margin: 0;
  position: relative;
  outline: 0;
  cursor: pointer;

  /* --- Checkbox icon --- */
  &:after {
    content: "";
    left: 35%;
    top: 17%;
    position: absolute;
    transition: transform 0.2s ease, opacity 0.2s;
    width: 37%;
    height: 54%;
    border: 2px solid white;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  /* --- Checkbox states --- */
  &:checked {
    background: ${({ theme, color }) => color ?? theme.variants.primary};
    border-color: ${({ theme, color }) => color ?? theme.variants.primary};
    :after {
      opacity: 1;
    }
  }

  &:disabled {
    background: #e9edf1;
    border: 1px solid ${({ theme }) => theme.borderColor};
    &:checked:after {
      border-color: ${({ theme }) => opacity(theme.text.main, 0.3)};
    }
  }

  &:checked {
    ${({ indeterminate }) =>
      indeterminate &&
      css`
        &:after {
          transform: rotate(90deg);
          border-bottom: 0;
        }
      `}
  }
`;

const Checkbox = ({ label, name, disabled, value, controlId, ...rest }: Props) => {
  const context = useMemo(() => ({ controlId }), [controlId]);

  return (
    <Field name={name} type="checkbox" value={value}>
      {({ field, form }: FieldProps) => (
        <Container>
          <FormContext.Provider value={context}>
            <StyledCheckbox
              id={controlId}
              type="checkbox"
              disabled={disabled || form.isSubmitting}
              {...rest}
              {...field}
            />
            <Label disabled={disabled}>{label}</Label>
          </FormContext.Provider>
        </Container>
      )}
    </Field>
  );
};

export default Checkbox;
