import { useQuery } from "@apollo/client";
import { RateReview } from "@styled-icons/material-outlined/RateReview";
import { inflect } from "inflection";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import { DropdownActionMenu } from "components/ActionsMenu/DropdownActionMenu";
import Dropdown from "components/Dropdown";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Pal, PalStatus, SortDirection } from "generated/types";
import { useCurrentAccountPermission } from "hooks/useCurrentAccountPermission";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import CreatePalComplaintModal from "modals/CreatePalComplaintModal";
import { statusOptions } from "pages/Pals/List/ChangeStatus/ChangeStatus.models";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";
import { isUuid } from "utils/isUuid";

import { Filter } from "./Filter";
import { PAL_CONDUCT } from "./gql";

const PalConduct = () => {
  useTopBarOptions({ title: "Pal Conduct & Records", icon: RateReview, breadcrumbs: ["Reviews"] });
  const { isSupervisor, isGrievancesAppealsAdmin } = useCurrentAccountRole();
  const { beforeCursor, afterCursor, limit, pal, dayTimeFrom, dayTimeTo, status } = usePageInfo();
  const [sorting, setSorting] = useState({ lastComplaintInsertedAt: SortDirection.Desc });
  const [actionPalId, setActionPalId] = useState("");
  const { isOpen: createPalComplaintModalOpen, toggle: toggleCreatePalComplaintModal } =
    useModalToggle();

  const dateFilter =
    dayTimeFrom && dayTimeTo
      ? { lastComplaintInsertedAt: { gte: dayTimeFrom, lte: dayTimeTo } }
      : { lastComplaintInsertedAt: { gte: "1970-01-01T00:00:00Z" } };
  const palFilter = pal ? (isUuid(pal) ? { id: { eq: pal } } : { nameContains: pal }) : {};
  const statusFilter = status?.length
    ? {
        status: {
          in: status.split(",") as PalStatus[],
        },
      }
    : {};
  const filter = { ...dateFilter, ...palFilter, ...statusFilter };

  const { data, loading, error, refetch } = useQuery(PAL_CONDUCT, {
    fetchPolicy: "network-only",
    variables: {
      filter: Object.keys(filter).length ? [filter] : [],
      sorting,
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const showCreateComplaintButton = useCurrentAccountPermission(["CAN_CREATE_COMPLAINT"]);

  if (!(isSupervisor || isGrievancesAppealsAdmin)) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Pal Conduct & Records - Papa Admin</title>
      </Helmet>

      <Filter loading={loading} />

      <SmartTable<Pal>
        loading={loading}
        error={error}
        keyField="id"
        pagination={data?.pals.pagination}
        rowProps={({ status }) => {
          if (
            status === PalStatus.Suspended ||
            status === PalStatus.Banned ||
            status === PalStatus.UnderInvestigation
          ) {
            return {
              conductStatusLegend: status,
            };
          }
          return {};
        }}
        columns={[
          {
            header: { title: "Pal Name" },
            dataField: "fullName",
            formatter({ fullName, id }) {
              return <Link to={`/pals/${id}`}>{fullName}</Link>;
            },
          },
          {
            header: { title: "Lifetime Points" },
            dataField: "lifetimeComplaintPoints",
            formatter({ lifetimeComplaintPoints }) {
              return `${lifetimeComplaintPoints} ${inflect("point", lifetimeComplaintPoints || 0)}`;
            },
          },
          {
            header: { title: "Active Points" },
            dataField: "complaintPoints",
            formatter({ complaintPoints }) {
              return `${complaintPoints} ${inflect("point", complaintPoints || 0)}`;
            },
          },
          {
            header: { title: "Status" },
            dataField: "status",
            formatter({ status }) {
              return mapValueToLabel(statusOptions, status);
            },
          },
          {
            header: {
              title: "Most Recent Complaint",
              sort: sorting.lastComplaintInsertedAt,
              onChange: () =>
                setSorting({
                  ...sorting,
                  lastComplaintInsertedAt:
                    sorting.lastComplaintInsertedAt === SortDirection.Desc
                      ? SortDirection.Asc
                      : SortDirection.Desc,
                }),
            },
            dataField: "mostRecentComplaint",
            formatter({ palComplaints }) {
              const mostRecentComplaint = palComplaints?.data?.[0];
              return mostRecentComplaint ? (
                <>
                  <Text>{`${formatDateTime(mostRecentComplaint.insertedAt)}`}</Text>
                  <Text>{`Initial points: ${mostRecentComplaint.initialPoints}`}</Text>
                  <Text>{`Remaining points: ${mostRecentComplaint.remainingPoints}`}</Text>
                </>
              ) : (
                EMPTY_PLACEHOLDER
              );
            },
          },
          {
            header: { title: "Contact Info" },
            dataField: "contactInfo",
            formatter({ email, phoneNumber }) {
              return (
                <>
                  <Text>{email}</Text>
                  <Text>{phoneNumber}</Text>
                </>
              );
            },
          },
          {
            header: { title: "" },
            dataField: "actions",
            cellProps: {
              fixed: true,
              align: "center",
            },
            formatter({ id }) {
              return (
                <DropdownActionMenu isListMenu={true}>
                  <Dropdown.Link to={`/pals/${id}`}>View pal</Dropdown.Link>
                  {showCreateComplaintButton && (
                    <Dropdown.Item
                      onClick={() => {
                        if (!id) return;

                        setActionPalId(id);
                        toggleCreatePalComplaintModal();
                      }}
                    >
                      Add complaint
                    </Dropdown.Item>
                  )}
                </DropdownActionMenu>
              );
            },
          },
        ]}
        data={data?.pals?.data}
      />

      <CreatePalComplaintModal
        palId={actionPalId}
        isOpen={createPalComplaintModalOpen}
        toggle={toggleCreatePalComplaintModal}
        onCreateFinish={() => refetch()}
      />
    </>
  );
};

export default PalConduct;
