import { useEffect } from "react";

import {
  useAssessmentsSurveyQuestionsByIdLazyQuery,
  useAssessmentsSurveyQuestionsByNameLazyQuery,
  usePapaBusinessPolicyInformationQuery,
} from "generated/types";
import { AssessmentsSurveyNames } from "modals/AssessmentsQuestions/models";
import { AssessmentType } from "types/assessments";

type Props = {
  papaId: string;
  type: AssessmentType;
};

export const useGetSurvey = ({ papaId, type }: Props) => {
  const [
    getAssessmentsQuestionByNameLazy,
    { data: surveysByName, loading: loadingByName, error: surveyByNameError },
  ] = useAssessmentsSurveyQuestionsByNameLazyQuery({
    variables: {
      filter: [
        {
          name: { eq: AssessmentsSurveyNames[type] },
        },
      ],
    },
  });
  const [
    getAssessmentsQuestionByIdLazy,
    { data: surveyById, loading: loadingById, error: surveyByIdError },
  ] = useAssessmentsSurveyQuestionsByIdLazyQuery();

  const { data: papaData, loading: papaLoading } = usePapaBusinessPolicyInformationQuery({
    variables: {
      papaId,
    },
  });

  const lonelinessAssessmentId =
    papaData?.papa?.data?.currentBusinessPolicy?.data?.lonelinessAssessment?.data?.id;

  useEffect(() => {
    if (!papaLoading) {
      if (type === AssessmentType.loneliness && lonelinessAssessmentId) {
        getAssessmentsQuestionByIdLazy({
          variables: {
            surveyId: lonelinessAssessmentId,
          },
        });
      } else {
        getAssessmentsQuestionByNameLazy();
      }
    }
  }, [
    lonelinessAssessmentId,
    papaLoading,
    type,
    getAssessmentsQuestionByNameLazy,
    getAssessmentsQuestionByIdLazy,
  ]);

  const error = surveyByNameError || surveyByIdError;
  const loading = loadingByName || papaLoading || loadingById;
  const survey = surveysByName?.surveys?.data?.[0] ?? surveyById?.survey?.data;

  const lonelinessCompliant =
    papaData?.papa?.data?.currentBusinessPolicy?.data?.lonelinessCompliant || false;

  return {
    error,
    loading,
    data: {
      survey,
      lonelinessCompliant,
    },
  };
};
