import React, { useState } from "react";
import { Link } from "react-router-dom";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import DataTableDropdownMenu from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { CovidVaccinationRecord } from "generated/types";

export const usePalVerifyVaccinationColumns = () => {
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const menuItems = ({ pal }: CovidVaccinationRecord) => [
    { label: "View Pal", link: `/pals/${pal?.data?.id}` },
    { label: "Verify Vaccination", link: `/pals/${pal?.data?.id}/vaccine-info` },
  ];

  const columns: Column<CovidVaccinationRecord>[] = [
    {
      header: { title: "Full name" },
      dataField: "pal",
      formatter({ pal }) {
        return <Link to={`/pals/${pal?.data?.id}`}>{pal?.data?.fullName}</Link>;
      },
    },
    {
      header: { title: "Contact" },
      dataField: "pal.data.email",
    },
    {
      header: { title: "Home Location" },
      dataField: "pal.data.account.data.id",
      formatter({ pal }) {
        const homeLocationData = pal?.data?.account?.data?.homeLocation?.data;
        return homeLocationData
          ? homeLocationData?.city +
              ", " +
              homeLocationData?.state +
              ", " +
              homeLocationData?.zipcode
          : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(palVaccinationVerify) {
        return <DataTableDropdownMenu items={menuItems(palVaccinationVerify)} />;
      },
    },
  ];

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
  };

  return {
    columns,
    action,
    closeModal,
  };
};
