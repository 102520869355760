import { gql } from "@apollo/client";

export const GET_PAPA_COMMENTS = gql`
  query PapaComments($id: ID!) {
    papa(id: $id) {
      data {
        papaComments {
          data {
            id
            insertedAt
            comment
            creator {
              data {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PAPA_COMMENT = gql`
  mutation CreatePapaComment($id: ID!, $input: PapaCommentInput!) {
    createPapaComment(id: $id, input: $input) {
      data {
        id
        insertedAt
        comment
        creator {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const UPDATE_PAPA_COMMENT = gql`
  mutation UpdatePapaComment($id: ID!, $input: PapaCommentInput!) {
    updatePapaComment(id: $id, input: $input) {
      data {
        id
        insertedAt
        comment
        creator {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const GET_PAPA_VISIT_COMMENTS = gql`
  query PapaVisitComments($id: ID!) {
    papa(id: $id) {
      data {
        visitComments {
          data {
            id
            insertedAt
            content
            creator {
              data {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PAPA_COMPLAINTS = gql`
  query papaComplaints($id: ID!) {
    papa(id: $id) {
      data {
        memberComplaints {
          data {
            id
            comment
            reason
            insertedAt
            softDeletedAt
            deletedBy {
              data {
                id
              }
            }
            insertedBy {
              data {
                id
                fullName
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PAPA_COMPLAINT = gql`
  mutation deletePapaComplaint($id: ID!) {
    deleteMemberComplaint(id: $id) {
      data {
        id
        softDeletedAt
        deletedBy {
          data {
            id
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_LOG = gql`
  query PapaActivityLogs(
    $papaId: ID!
    $pagination: PaginationInput
    $filter: PapaActivityLogFilter
  ) {
    papaActivityLogs(
      papaId: $papaId
      pagination: $pagination
      filter: $filter
      sorting: { insertedAt: DESC }
    ) {
      data {
        id
        content
        creatorId
        creatorName
        insertedAt
        type
        visitId
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
