import { gql } from "@apollo/client";

export const CREDIT_CARDS = gql`
  query creditCards(
    $filter: [CreditCardFilter]
    $pagination: PaginationInput
    $sorting: CreditCardSorting
  ) {
    creditCards(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        brand
        default
        deletedAt
        expMonth
        expYear
        id
        insertedAt
        last4
        nickname
        stripeToken
        updatedAt
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
`;
