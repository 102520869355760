import capitalize from "lodash/capitalize";
import React from "react";

import Panel from "components/Panel";
import StateText from "components/StateText";
import Text from "components/Text";
import { paymentInvoiceStateLabels } from "constants/paymentInvoices";
import { PaymentInvoice } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";

type Props = {
  paymentInvoice: PaymentInvoice;
};

const Overview: React.FC<Props> = ({ paymentInvoice }) => {
  const { id: paymentInvoiceId, state, amount, insertedAt } = paymentInvoice;

  return (
    <Panel.Side>
      <div>
        <Panel.Item>
          <Text bold>ID</Text>
          <Text>{paymentInvoiceId}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Status</Text>
          {state && (
            <StateText paymentInvoiceStateLegend={state}>
              {capitalize(paymentInvoiceStateLabels[state])}
            </StateText>
          )}
        </Panel.Item>

        <Panel.Item>
          <Text bold>Total</Text>
          <Text>{formatNumberToCurrency(amount ?? 0)}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Inserted at</Text>
          <Text>{formatDateTime(insertedAt)}</Text>
        </Panel.Item>
      </div>
    </Panel.Side>
  );
};

export default Overview;
