import { gql } from "@apollo/client";

export const UPDATE_BIO = gql`
  mutation UpdateMemberBio($id: ID!, $input: PalInput!) {
    updatePal(id: $id, input: $input) {
      data {
        id
        bio
      }
    }
  }
`;
