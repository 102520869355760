import { gql } from "@apollo/client";

export const START_COMMUTE = gql`
  mutation startCommuteForVisit($id: ID!) {
    startCommuteForVisit(id: $id) {
      data {
        id
        state
      }
    }
  }
`;
