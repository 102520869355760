import { gql } from "@apollo/client";

export const GET_ELIGIBLE_EMPLOYEE_LIST = gql`
  query eligibleEmployees(
    $filter: [EligibleEmployeeFilter]
    $pagination: PaginationInput
    $sorting: EligibleEmployeeSorting
  ) {
    eligibleEmployees(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        employeeId
        firstName
        lastName
        business {
          data {
            name
          }
        }
        dob
        email
        status
        addressLn1
        addressLn2
        zip
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
