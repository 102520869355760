import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import { Exact, Scalars } from "generated/types";

export const PalOnboardingDocumentMutation = gql`
  mutation PalOnboardingMutation($input: PalOnboardingInput!) {
    palOnboarding(input: $input) {
      data {
        hasCompletedOrientationVideo
      }
    }
  }
`;

export type PalOnboardingMutationVariables = Exact<{
  input: Scalars["Json"];
}>;

export function usePalOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<any, PalOnboardingMutationVariables>
) {
  const options = { ...baseOptions };
  return Apollo.useMutation<any, PalOnboardingMutationVariables>(
    PalOnboardingDocumentMutation,
    options
  );
}

export type PalOnboardingMutationHookResult = ReturnType<typeof usePalOnboardingMutation>;
export type PalOnboardingMutationResult = Apollo.MutationResult<any>;
