import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";

import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { DATA_CONCEALED } from "constants/fieldsPermissions";
import { DataVisibility } from "types";

import { boolToText } from "../helpers/formatters";
import { Field, isFieldConcealed } from "./isFieldConcealed";

export const concealedBoolean = (
  field: Field,
  value?: boolean | null,
  dataVisibility?: DataVisibility | null
) => {
  const isConcealed = isFieldConcealed(field, dataVisibility);

  if (isConcealed) {
    return DATA_CONCEALED;
  } else {
    if (isNull(value) || isUndefined(value)) {
      return EMPTY_PLACEHOLDER;
    }
  }

  return boolToText(value);
};

export const concealedField = <T = string>(
  field: Field,
  value?: T | null,
  dataVisibility?: DataVisibility | null
): T | string => {
  const isConcealed = isFieldConcealed(field, dataVisibility);

  if (isConcealed) {
    return DATA_CONCEALED;
  } else {
    if (isNull(value) || isUndefined(value)) {
      return EMPTY_PLACEHOLDER;
    }
  }

  return value;
};
