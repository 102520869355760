import capitalize from "lodash/capitalize";

import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";

export const fancyEnum = (value?: string | null) => {
  if (value === undefined || value === null) {
    return EMPTY_PLACEHOLDER;
  }

  return value
    .split("_")
    .map((word) => capitalize(word))
    .join(" ");
};
