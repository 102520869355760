import { gql } from "@apollo/client";

export const CREATE_LOCATION = gql`
  mutation createLocation($input: LocationInput!) {
    createLocation(input: $input) {
      data {
        id
      }
    }
  }
`;

export const SEARCH_MEMBER = gql`
  query searchMemberForLocations($filter: [AccountFilter]) {
    accounts(filter: $filter) {
      data {
        id
        fullName
      }
    }
  }
`;

export const SEARCH_PAPAS = gql`
  query PapasLocation($filter: [PapaFilter], $pagination: PaginationInput, $sorting: PapaSorting) {
    papas(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        fullName
        accountId
        dataVisibility {
          concealed
        }
      }
      pagination {
        limit
        totalCount
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query Locations(
    $filter: [LocationFilter]
    $pagination: PaginationInput
    $sorting: LocationSorting
  ) {
    locations(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        nickname
        address
        address2
        streetName
        houseNumber
        city
        state
        zipcode
        lat
        lng
        note
        description
        timezone
        insertedAt
        deletedAt
        account {
          data {
            id
            fullName
          }
        }
        papa {
          data {
            id
            fullName
            dataVisibility {
              concealed
            }
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;

export const GET_PAPA_DATA = gql`
  query papa($id: ID!) {
    papa(id: $id) {
      data {
        accountId
      }
    }
  }
`;
