import { useField, useFormikContext } from "formik";
import React from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Select from "components/Select";
import { ServiceRequestStatus, useGetServiceRequestStatusesQuery } from "generated/types";
import { nonNull } from "utils/nonNull";
import { fancyEnum } from "utils/strings/fancyEnum";

const ServiceRequestStatusSelect = () => {
  const [, , helpers] = useField("status");
  const [, , closedReasonHelpers] = useField("closedReason");
  const { isSubmitting } = useFormikContext();
  const { data, loading } = useGetServiceRequestStatusesQuery();
  const statusOptions = nonNull(data?.getServiceRequestStatuses?.data).map((value) => ({
    value: value,
    label: fancyEnum(value),
  }));
  if (loading) return <SpinningIcon size={18} />;

  const onChange = (value: any) => {
    helpers.setValue(value);

    if (value !== ServiceRequestStatus.Closed) {
      closedReasonHelpers.setValue(null);
    }
  };

  return (
    <Select
      aria-label="status"
      name="status"
      placeholder="Status"
      defaultValue={null}
      options={statusOptions}
      isDisabled={isSubmitting}
      onChange={onChange}
      isSearchable={false}
    />
  );
};

export default ServiceRequestStatusSelect;
