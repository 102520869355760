import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { VisitFlagName, VisitFlagReason } from "generated/types";

import { MAKE_VISIT_CRITICAL } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  visitId: string | null;
  closeModal: () => void;
}

const MakeCriticalForm = ({ visitId, closeModal }: Props): ReactElement => {
  const [makeCritical, { loading }] = useMutation(MAKE_VISIT_CRITICAL);

  const initialValues: Schema = {
    description: "",
  };

  const handleSubmit = async ({ description }: Schema) => {
    const input = {
      description: description || "Marked as critical by Admin",
      name: VisitFlagName.Critical,
      reason: VisitFlagReason.ManuallyMarkedCritical,
      visitId,
    };
    try {
      const { data } = await makeCritical({ variables: { input } });

      if (data?.createVisitFlag) {
        toast.success("This visit made a critical success");
        closeModal();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <p>Enter a description to make this visit critical</p>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.TextArea aria-label="description" name="description" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button type="button" variant="secondary" onClick={closeModal}>
            No
          </Button>
          <Button type="submit" disabled={loading} variant="primary">
            {loading && <SpinningIcon size={18} />}
            Yes
          </Button>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default MakeCriticalForm;
