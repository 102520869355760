import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

const DropdownLink = styled(({ handleToggle, ...props }) => <Link {...props} />)<LinkProps>`
  min-height: 38px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text.main};
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  padding: 9px 14px;
  cursor: pointer;
  text-decoration: none;

  &:first-of-type {
    border-top: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.variants.primary};
    background: #eaefff;
  }
`;

export default DropdownLink;
