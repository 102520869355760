import { VisitFlagReason } from "generated/types";

const exceptionOptions = [
  {
    label: "Confirmed pal can make visit",
    value: VisitFlagReason.ConfirmedPalCanMakeVisit,
  },
  {
    label: "Member is stranded away from home",
    value: VisitFlagReason.MemberStrandedFromHome,
  },
  {
    label: "Pal/Uber no show for doctor, Rx, or grocery visit happening now",
    value: VisitFlagReason.PalUberNoShowForDoctor,
  },
  {
    label: "Papa scheduling mistake for doctor, Rx, or grocery visit happening now",
    value: VisitFlagReason.PapaSchedulingMistakeRxDoctorOrGrocery,
  },
  {
    label: "Visit success reschedule",
    value: VisitFlagReason.VisitSuccessReschedule,
  },
];

export default exceptionOptions;
