import { useFormikContext } from "formik";
import React from "react";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";

interface Props {
  isSubmitting?: boolean;
  action?: boolean;
  loadingText?: string;
  noContent?: boolean;
  disabled?: boolean;
  disabledIfInvalid?: boolean;
}

export const SubmitButton: React.FC<Props> = ({
  isSubmitting,
  children,
  action = false,
  loadingText = "Saving",
  noContent = false,
  disabled = false,
  disabledIfInvalid,
}) => {
  const { isSubmitting: isSubmittingFormik, isValid } = useFormikContext();

  const isInvalid = disabledIfInvalid && !isValid;

  return (
    <Button
      disabled={disabled || isSubmitting || isSubmittingFormik || isInvalid}
      type="submit"
      variant="primary"
      action={action}
      noContent={noContent}
    >
      {isSubmitting || isSubmittingFormik ? (
        <>
          <SpinningIcon size={18} />
          <span>{loadingText}...</span>
        </>
      ) : (
        children
      )}
    </Button>
  );
};
