import { BooleanSchema, array, boolean, object, string } from "yup";

import { Gender, Maybe } from "generated/types";
import { Language } from "generated/types";
import { VehicleType } from "generated/types";

export const schema = object().shape({
  genderPreference: string().nullable(),
  languagePreference: string().nullable(),
  vehiclePreference: string().nullable(),
  favoritePalIds: array().of(string()).nullable(),
  defaultToFavoritePalsOnly: boolean(),
  genderPreferenceIsFlexible: boolean()
    .nullable()
    .when("genderPreference", {
      is: (genderPreference) => {
        return [Gender.Male, Gender.Female].includes(genderPreference);
      },
      then: (schema: BooleanSchema) =>
        schema.required("Please choose yes or no for gender preference flexibility."),
    }),
  genderPreferenceWasAsked: boolean().required(),
});

export interface FormValues {
  genderPreference: Maybe<Gender>;
  vehiclePreference: Maybe<VehicleType>;
  languagePreference: Maybe<Language>;
  favoritePalIds: string[];
  defaultToFavoritePalsOnly: boolean;
  genderPreferenceIsFlexible: boolean | null;
  genderPreferenceWasAsked: boolean;
}
