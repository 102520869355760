import { Formik } from "formik";
import React from "react";
import styled from "styled-components";

import Button from "components/Button";
import Form from "components/Form";
import Heading from "components/Heading";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Select from "components/Select";
import { VisitFlagReason } from "generated/types";
import exceptionOptions from "modals/VisitLeadTimeExceptionModal/exceptionOptions";

import { Values as RescheduleVisitValues } from "../../pages/ScheduleVisit/EditVisit/RescheduleVisitRequestModal/types";
import { Values as ScheduleVisitValues } from "../../pages/ScheduleVisit/types";
import { Schema, schema } from "./schema";

type ScheduleVisitFormValues = {
  action: "schedule";
  values: ScheduleVisitValues;
};

type RescheduleVisitFormValues = {
  action: "reschedule";
  values: RescheduleVisitValues;
};

type FormValues = ScheduleVisitFormValues | RescheduleVisitFormValues;

export interface Props {
  isOpen?: boolean;
  toggle?: () => void;
  onSubmit?: (values: ScheduleVisitValues | RescheduleVisitValues) => Promise<void>;
  visitFormValues: FormValues;
}

const VisitLeadTimeExceptionModal: React.FC<Props> = ({
  isOpen = false,
  toggle,
  onSubmit,
  visitFormValues,
}) => {
  const handleSubmit = async (values: Schema) => {
    if (visitFormValues && onSubmit && toggle) {
      await onSubmit({
        ...visitFormValues.values,
        overrideVisitLeadTime: true,
        overrideVisitLeadTimeReason: values.overrideVisitLeadTimeReason as VisitFlagReason,
        overrideVisitLeadTimeAdditionalDetails: values.overrideVisitLeadTimeAdditionalDetails,
      });
      toggle();
    } else if (toggle) {
      toggle();
    }
  };

  const initialValues: Schema = {
    overrideVisitLeadTimeReason: null,
    overrideVisitLeadTimeAdditionalDetails: "",
  };

  return (
    <ModalLayout
      title="Override visit lead time?"
      isOpen={isOpen}
      toggle={() => {
        if (toggle) toggle();
      }}
      width={800}
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <ModalStyledForm>
          <ModalContainer>
            <Form.Row>
              <StyledFormCol>
                <Reminders>
                  <Header as="h3" color="primary">
                    Reminders
                  </Header>
                  <ul>
                    <li>
                      Please schedule this visit as far out as possible to increase the likelihood
                      of it being filled
                    </li>
                    <li>
                      If overriding due to a complaint, please submit a dissatisfaction as well
                    </li>
                  </ul>
                </Reminders>
              </StyledFormCol>
              <StyledFormCol>
                <Form.Row>
                  <StyledFormGroup>
                    <FormFieldHeader>
                      <Header as="h3" color="primary">
                        Override reason<InlineFormLabel required={true}></InlineFormLabel>
                      </Header>
                    </FormFieldHeader>
                    <Select
                      name="overrideVisitLeadTimeReason"
                      options={exceptionOptions}
                      isSearchable={false}
                      aria-label="overrideVisitLeadTimeReason"
                      placeholder="Select reason"
                    />
                  </StyledFormGroup>
                </Form.Row>
                <Form.Row>
                  <StyledFormGroup>
                    <Form.Label>Additional details</Form.Label>
                    <Form.TextArea
                      name="overrideVisitLeadTimeAdditionalDetails"
                      aria-label="overrideVisitLeadTimeAdditionalDetails"
                    ></Form.TextArea>
                  </StyledFormGroup>
                </Form.Row>
              </StyledFormCol>
            </Form.Row>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              No
            </Button>
            <Form.SubmitButton>Yes</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};

const Header = styled(Heading)`
  margin-bottom: 1.5rem;
`;

const FormFieldHeader = styled.div`
  display: inline;
`;

const Reminders = styled.div`
  background-color: #fef9f1;
  padding: 1rem;
  margin-bottom: 1.5rem;
`;

const InlineFormLabel = styled(Form.Label)`
  padding-left: 0.5rem;
  display: inline;
`;

const StyledFormCol = styled(Form.Col)`
  width: 100%;
  padding-right: 2.5rem;
`;

const StyledFormGroup = styled(Form.Group)`
  width: 100%;
`;

export default VisitLeadTimeExceptionModal;
