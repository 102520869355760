import { gql } from "@apollo/client";

export const CREATE_PAPA_COMPLAINT = gql`
  mutation createMemberComplaint($input: MemberComplaintInput!) {
    createMemberComplaint(input: $input) {
      data {
        id
        reason
        comment
        insertedAt
        updatedAt
        insertedBy {
          data {
            id
            fullName
          }
        }
        papa {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;
