import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React from "react";
import Helmet from "react-helmet";

import PalsTable from "components/DataTables/PalsTable";
import { usePalsSorting } from "components/DataTables/PalsTable/usePalSorting";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import { FeatureFlags } from "constants/featureFlags";
import { PalListResult } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";

import { GET_PALS } from "../gql";
import CreatePal from "./CreatePal";
import ExportPals from "./ExportPals";
import { usePalsSearch } from "./usePalsSearch";

interface Data {
  pals: PalListResult;
}

const Pals = () => {
  useTopBarOptions({ title: "Pals", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { filter, pills, setPills } = useSearch();
  const [sorting, setSorting] = usePalsSorting();
  const { data, loading, error, refetch } = useQuery<Data>(GET_PALS, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter,
      sorting,
    },
  });
  const pals = nonNull(data?.pals?.data);
  const { loadOptions, searchPillNames } = usePalsSearch(pals);
  const createPalEnabled = useIsFeatureEnabled(FeatureFlags.EnableCreatePal);

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <div>
      <Helmet>
        <title>Pals - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <FlexRow>
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder="Search by ID, Name, Phone, Email, Location"
          />
        </FlexRow>

        <FlexRow>
          <ExportPals />
          {createPalEnabled && <CreatePal onCreateFinish={handleRefetch} />}
        </FlexRow>
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />

      <PalsTable
        loading={loading}
        data={pals}
        error={error}
        pagination={data?.pals?.pagination}
        sorting={sorting}
        onSortChange={setSorting}
        cursorPagination
      />
    </div>
  );
};

export default Pals;
