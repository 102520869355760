import { useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import omit from "lodash/omit";

import config from "config";
import { CreditCardInput } from "generated/types";

import { CREATE_CREDIT_CARD } from "./gql";

type SaveCreditCardParams = Pick<CreditCardInput, "papaId" | "accountId">;

export const useCreateCreditCard = () => {
  const [createCreditCardMutation, { data, error }] = useMutation(CREATE_CREDIT_CARD);
  const stripe = useStripe();
  const elements = useElements();

  const createCreditCard = async () => {
    const cardElement = elements?.getElement(CardElement);

    if (!cardElement || !stripe) {
      return { error: null, tokenId: null, card: null, cardElement };
    }

    const { error, token: { id: tokenId, card } = {} } = await stripe.createToken(cardElement);

    return { error, tokenId, card, cardElement };
  };

  const saveCreditCard = async (input: SaveCreditCardParams, tokenResult: any) => {
    const { tokenId, card } = tokenResult;
    let newInput: Partial<SaveCreditCardParams> = {};
    if (!config.featureFlag.papaCreditCard) {
      newInput = omit(input, ["papaId"]);
    } else {
      newInput = input;
    }

    return await createCreditCardMutation({
      variables: {
        input: {
          ...newInput,
          nickname: `${card.brand} - ${card.last4}`,
          brand: card.brand,
          expMonth: card.exp_month,
          expYear: card.exp_year,
          last4: card.last4,
          default: true,
          stripeCardId: card.id,
          stripeToken: tokenId,
        },
      },
    });
  };

  return { createCreditCard, saveCreditCard, data, error };
};
