import { object, string } from "yup";

export interface FormValues {
  startedAtDay: string;
  startedAtTime: string;
}

export const schema = object().shape({
  startedAtDay: string().required("Started date is required"),
  startedAtTime: string().required("Started time is required").nullable(),
});
