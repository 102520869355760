import { useState } from "react";

const usePagination = ({ page, limit }: Props) => {
  const [currentPage, setPage] = useState(page);
  const [currentLimit, setCurrentLimit] = useState(limit);

  return { currentPage, setPage, currentLimit, setCurrentLimit };
};
type Props = {
  page: number;
  limit: number;
};
export default usePagination;
