import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { toast } from "react-toastify";

import Actions from "components/ActionsMenu/BusinessActionMenu/Actions";
import Button from "components/Button";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import SmartTable from "components/SmartTable";
import { Business, BusinessListResult, BusinessSorting, SortDirection } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";

import { BUSINESSES_LIST } from "../gql";
import { useBusinessSearch } from "./useBusinessSearch";
import { useBusinessesTableColumns } from "./useBusinessesTableColumns";

interface Data {
  businesses: BusinessListResult;
}

const BusinessesList = () => {
  const {
    capitalized: { pluralEntityName, singularEntityName },
    kebabCased,
  } = useCallItClientProgramOrBusiness();

  useTopBarOptions({
    title: singularEntityName,
    icon: AccountCircle,
    breadcrumbs: ["Accounts"],
  });

  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const [sorting, setSorting] = useState<BusinessSorting>({
    insertedAt: SortDirection.Desc,
  });
  const { filter, pills, setPills } = useSearch();
  const { columns, action, business, createBusiness, closeModal } = useBusinessesTableColumns({
    sorting,
    onSortChange: setSorting,
  });
  const { isSuperAdmin, isClientAdmin } = useCurrentAccountRole();
  const { data, loading, error, refetch } = useQuery<Data>(BUSINESSES_LIST, {
    variables: {
      filter,
      sorting,
      pagination: { beforeCursor, afterCursor, limit },
    },
  });
  const businesses = nonNull(data?.businesses?.data);
  const pagination = data?.businesses.pagination;
  const { loadOptions, searchPillNames } = useBusinessSearch(businesses);

  const handleRefetch = async () => {
    await refetch();
  };

  const createIfClientAdmin = () => {
    if (isSuperAdmin || isClientAdmin) {
      createBusiness();
    } else {
      toast.warn("Must be a ClientAdmin or SuperAdmin to Create Business.");
    }
  };

  return (
    <div>
      <Helmet>
        <title>{pluralEntityName} - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <FlexRow>
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder="Search by ID, Name, Phone"
          />
        </FlexRow>

        <FlexRow>
          <Button action onClick={createIfClientAdmin}>
            New {singularEntityName}
          </Button>
        </FlexRow>
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />

      <SmartTable<Business>
        keyField="id"
        localStorageKey="businessAccounts"
        data={businesses}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
        cursorPagination
        rowProps={({ id }) => ({
          linkTo: `/${kebabCased.pluralEntityName}/${id}`,
        })}
      />

      <Actions action={action} business={business} onClose={closeModal} onRefetch={handleRefetch} />
    </div>
  );
};

export default BusinessesList;
