import { gql } from "@apollo/client";

export const GET_PAL_TIPALTI_DISBURSEMENTS = gql`
  query getPalTipaltiDisbursements(
    $id: ID!
    $filter: [TipaltiDisbursementFilter!]
    $pagination: PaginationInput
    $sorting: TipaltiDisbursementSorting
  ) {
    pal(id: $id) {
      data {
        tipaltiDisbursements(filter: $filter, pagination: $pagination, sorting: $sorting) {
          data {
            id
            amountInCents
            errorReason
            description
            status
            invoiceDate
            invoiceNumber
            visitDate
            visitId
            papaName
          }
          pagination {
            limit
            page
            totalCount
            totalPages
          }
        }
      }
    }
  }
`;
