import React, { FunctionComponent } from "react";
import Helmet from "react-helmet";

import SmartTable from "components/SmartTable";
import { usePapaEnrolled } from "hooks/usePapaEnrolled";
import { PapaConcealed } from "types";

import { useColumns } from "./useColumns";

const PapasEnrolled: FunctionComponent = () => {
  const { data, pagination, loading, error } = usePapaEnrolled();
  const columns = useColumns();

  return (
    <>
      <Helmet>
        <title>Member&apos;s Papas Enrolled - Papa Admin</title>
      </Helmet>

      <SmartTable<PapaConcealed>
        keyField="id"
        data={data}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default PapasEnrolled;
