import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";

import Form from "components/Form";
import GenderPreferences from "components/GenderPreferences";
import Select from "components/Select";
import Text from "components/Text";
import { livingSituationOptions } from "constants/livingSituations";

import { Schema } from "./schema";

export const AdditionalDetails = () => {
  const { errors, values, setFieldValue } = useFormikContext<Schema>();

  const hasPets = values.hasPets;

  const specialConsiderationsIncomplete = !!Object.keys(errors).find((key) =>
    ["usesWheelchair", "hasPets", "smokes"].includes(key)
  );

  useEffect(() => {
    if (!hasPets) {
      setFieldValue("hasCat", null);
      setFieldValue("hasDog", null);
      setFieldValue("hasOtherPet", null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPets]);

  return (
    <>
      <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="2rem">
        Additional Details
      </Text>

      <GenderPreferences
        genderPreferenceLocked={false}
        genderPreferenceLabel="Pal Gender Preference"
      />

      <Form.Group>
        <Form.Label required>Living Situation</Form.Label>
        <Select
          name="livingSituation"
          options={livingSituationOptions}
          isSearchable={false}
          placeholder="Select Living Situation"
          aria-label="livingSituation"
        />
      </Form.Group>

      <Form.Group data-testid="special-considerations">
        <Form.Label>Special Considerations</Form.Label>
        {specialConsiderationsIncomplete && (
          <Form.Feedback isInvalid>
            Required to select an answer for "Uses wheelchair", "Has pets", and "Smokes"
          </Form.Feedback>
        )}
        <RadioYesNo required name="hasPets" label="Has pets" />
        {values.hasPets && (
          <>
            <RadioYesNo indented name="hasCat" label="Cat" />
            <RadioYesNo indented name="hasDog" label="Dog" />
            <RadioYesNo indented name="hasOtherPet" label="Other" />
          </>
        )}
        <RadioYesNo required name="usesWheelchair" label="Uses wheelchair" />
        <RadioYesNo required name="smokes" label="Smokes" />

        {/* Non-required below */}
        <RadioYesNo name="hearingImpaired" label="Hearing impaired" />
        <RadioYesNo name="visuallyImpaired" label="Visually impaired" />
        <RadioYesNo name="cantDrive" label="Can't drive" />
      </Form.Group>

      <Form.Group>
        <Form.Label>Notes</Form.Label>
        <Form.TextArea name="description" fullWidth />
      </Form.Group>
    </>
  );
};

const RadioYesNo = ({
  name,
  label,
  required,
  indented,
}: {
  name: string;
  label: string;
  required?: boolean;
  indented?: boolean;
}) => {
  return (
    <RadioYesNoContainer>
      <RadioLabel required={required} indented={indented}>
        {label}
      </RadioLabel>
      <Form.Radio name={name} label="Yes" controlId={`${name}-true`} value={true} />
      <Form.Radio name={name} label="No" controlId={`${name}-false`} value={false} />
    </RadioYesNoContainer>
  );
};

const RadioLabel = styled(Form.Label)<{ indented?: boolean }>`
  width: 180px;

  padding-left: ${({ indented }) => (indented ? "20px" : "0")};
`;

const RadioYesNoContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 5px;
`;
