import { LivingSituation } from "generated/types";

export const livingSituationOptions = [
  { value: LivingSituation.Alone, label: "Live alone" },
  { value: LivingSituation.WithPartner, label: "Lives with spouse/partner" },
  { value: LivingSituation.Family, label: "Lives with others - family (not spouse/partner)" },
  { value: LivingSituation.NonFamily, label: "Lives with others - non family" },
  { value: LivingSituation.Facility, label: "Live at Assisted Living/Nursing facility" },
  { value: LivingSituation.PreferNotToAnswer, label: "Prefer not to answer" },
];

export const livingSituationLabel: {
  [key in LivingSituation]: string;
} = {
  [LivingSituation.Alone]: "Live alone",
  [LivingSituation.WithPartner]: "Lives with spouse/partner",
  [LivingSituation.Family]: "Lives with others - family (not spouse/partner)",
  [LivingSituation.NonFamily]: "Lives with others - non family",
  [LivingSituation.Facility]: "Live at Assisted Living/Nursing facility",
  [LivingSituation.PreferNotToAnswer]: "Prefer not to answer",
};
