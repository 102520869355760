import moment from "moment";

import { PapaConcealed } from "types";

export const evaluationNeeded = (papa: PapaConcealed) => {
  if (!papa || !papa.visits?.data) {
    return false;
  }

  if (papa.visits?.data.length < 3) {
    return false;
  }

  return getLastPapaSurveySubmissionQuarter(papa) !== moment().format("Q");
};

function getLastPapaSurveySubmissionQuarter(papa: PapaConcealed) {
  if (!papa.surveySubmissions?.data || !papa.surveySubmissions?.data[0]?.submittedAt) {
    return null;
  }

  return moment(papa.surveySubmissions?.data[0]?.submittedAt).format("Q");
}
