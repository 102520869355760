import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { PapaConcealed } from "types";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";

import { SEARCH_PAPA_LIST } from "./gql";

export const usePapasSearch = (data: PapaConcealed[]) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data: dataSearchPapa } = await client.query({
        query: SEARCH_PAPA_LIST,
        variables: {
          filter: { fullName: { cont: value } },
        },
      });
      if (dataSearchPapa?.papas?.data) {
        const papas = papasConcealed(dataSearchPapa.papas.data);
        return papas.map(({ id, fullName, account, status }) => {
          const memberName = fullName || account?.data?.fullName || "No Member";

          return {
            value: id ?? "",
            ...{ papaId: id ?? "" },
            member: account?.data?.fullName ?? "",
            label: fullName ?? "No Member",
            name: memberName,
            status: status,
          };
        });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };
  const searchPillNames = data?.reduce(
    (obj, { id, fullName }) => ({
      ...obj,
      [id!]: fullName,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
