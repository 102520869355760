import React from "react";

import Button from "components/Button";
import { FormFooter } from "components/EditBio/styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";

type Props = {
  error?: Error | undefined;
  onClose: () => void;
};

export const ErrorModalResponse = ({ error, onClose }: Props) => {
  return (
    <>
      <ModalContainer>
        <QueryErrors error={error} />
      </ModalContainer>
      <FormFooter>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </FormFooter>
    </>
  );
};
