import moment from "moment";

export const getDuration = (minutesDuration: number): string => {
  if (minutesDuration === 0) {
    return "0 minutes";
  }

  const durationMoment = moment.duration(minutesDuration, "minutes");
  const asHours = durationMoment.as("hours");
  const hours = Math.floor(asHours);
  const minutes = durationMoment.minutes();

  const display = [];

  if (asHours >= 1) {
    display.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }

  if (minutes >= 1) {
    display.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }

  return display.join(" and ");
};

export const getSlimDuration = (minutes: number): string => {
  if (minutes === 0) return "0m";

  const hourAmt = Math.floor(minutes / 60);
  const minuteAmt = minutes % 60;

  const result = [];

  if (hourAmt > 0) {
    result.push(`${hourAmt}h`);
  }

  if (minuteAmt > 0) {
    result.push(`${minuteAmt}m`);
  }

  return result.join(" ");
};
