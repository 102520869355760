import { ReactComponent as Happy } from "assets/images/member-mood/happy.svg";
import { ReactComponent as Mad } from "assets/images/member-mood/mad.svg";
import { ReactComponent as Neutral } from "assets/images/member-mood/neutral.svg";
import { ReactComponent as Sad } from "assets/images/member-mood/sad.svg";
import { PapaMood } from "generated/types";

export const PapaMoodIcons: {
  [key in PapaMood]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  [PapaMood.Happy]: Happy,
  [PapaMood.Sad]: Sad,
  [PapaMood.Neutral]: Neutral,
  [PapaMood.Mad]: Mad,
};

export const memberMoods = [
  { value: PapaMood.Happy, icon: PapaMoodIcons[PapaMood.Happy], label: "Happy" },
  { value: PapaMood.Sad, icon: PapaMoodIcons[PapaMood.Sad], label: "Sad" },
  { value: PapaMood.Neutral, icon: PapaMoodIcons[PapaMood.Neutral], label: "Neutral" },
  { value: PapaMood.Mad, icon: PapaMoodIcons[PapaMood.Mad], label: "Mad" },
];
