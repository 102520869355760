import camelCase from "lodash/camelCase";

import { Papa, Visit } from "generated/types";
import { DataVisibility } from "types";

export type Field = keyof Papa | keyof Visit;

export const isFieldConcealed = (field: Field, dataVisibility?: DataVisibility | null): boolean => {
  return (dataVisibility?.concealed ?? []).map((field) => camelCase(field)).includes(field);
};
