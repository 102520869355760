import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";
import { useLocalStorage } from "hooks/useLocalStorage";

interface Props {
  visitId: string;
  isOpen: boolean;
  toggle: () => void;
}

export const VisitWatchListModal: React.FC<Props> = ({ visitId, isOpen, toggle }) => {
  const [visitWatchList, setVisitWatchList] = useLocalStorage<string[]>("visits.watchList", []);

  const IsOnList = visitWatchList.includes(visitId);

  const onOk = async () => {
    if (IsOnList) {
      setVisitWatchList(visitWatchList.filter((id) => id !== visitId));
    } else {
      setVisitWatchList([visitId, ...visitWatchList]);
    }
    toast.success(`The visit was ${IsOnList ? "removed from" : "added to"} watch list.`);
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <StyledIcon fontWeight="bold" size={23} />
          <TextContainer>
            <Text>
              Do you want to {IsOnList ? "remove visit from" : "add visit to"} watch list?
            </Text>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button type="button" variant="primary" onClick={onOk}>
          Ok
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
