import { gql } from "@apollo/client";

export const CONFIRM_VISIT = gql`
  mutation confirmVisit($id: ID!) {
    confirmVisit(id: $id) {
      data {
        id
        state
        confirmedAt
      }
    }
  }
`;
