import { HeartCircle } from "@styled-icons/boxicons-regular/HeartCircle";
import { Email } from "@styled-icons/material-outlined/Email";
import { AccountCircle } from "@styled-icons/material/AccountCircle";
import { Home } from "@styled-icons/material/Home";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import React, { CSSProperties, FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Async from "react-select/async";
import { ValueType } from "react-select/src/types";
import styled from "styled-components";

import Button from "components/Button";
import { customStyles } from "components/Select";
import { ChangeSelectEvent } from "components/UserSelect";
import { papaStateOptions } from "constants/papaState";
import { PapaState } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";
import { PapaConcealed } from "types";
import { PillData } from "types/search";
import { mapValueToLabel } from "utils/helpers/formatters";

export interface OptionLabel {
  value: string;
  label: string;
  member?: string;
  phone?: string;
  email?: string;
  address?: string;
  status?: PapaState;
  papas?: PapaConcealed[];
  isPreferred?: boolean;
}

interface Props {
  pills: PillData[];
  setPills: (pills: PillData[] | ((pills: PillData[]) => PillData[])) => void;
  loadOptions: (value: string) => Promise<OptionLabel[]>;
  placeholder?: string;
  extraPillpadding?: boolean;
}

/**
 * The component generates the router's query string "search" parameter by the search criteria.
 *
 * @description
 * The Search component is designed to generate search criteria, as well as
 * to push these search criteria into the router history as query string.
 * The Search component consists of an input field with an autocomplete and a "Search" button.
 * When a user enters into the input field, a drop-down list of the matching items appears.
 * A matching item is one that contains the typed in its name, phone, etc.
 * The selected item is added to the search criteria list.
 * This list and the method for changing it is provided by the useSearch hook.
 * The SearchPills component is used to display a list of search criteria.
 * Thus, the user can type the required number of search criteria, while not yet applying them.
 * After click the "Search" button search criteria are pushed into the query string of the router history
 * Based on the search query string, the useSearch hook determines the filter that should be used in
 * the data request to apply the specified search criteria.
 * You also need to implement a custom search hook
 * that returns a function for loading autocomplete options and a dictionary of names
 *
 *
 * @usage
 * The Search component must be used in conjunction with the SearchPills component and the useSearch hook
 *
 * ```
 *   const { filter, pills, setPills } = useSearch({ deletedAt: { eq: null } });
 *   const { data, loading, error } = useQuery<Data>(GET_PAPAS, { variables: { filter } });
 *   const  { loadOptions, searchPillNames } = usePapasSearch(data?.papas.data!);
 *
 *   return (
 *     <>
 *      <Search pills={pills} setPills={setPills} loadOptions={loadOptions} placeholder="Search by Name, Phone" />
 *      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />
 *    </>
 *   );
 * ```
 */
const Search: FC<Props> = ({
  pills,
  setPills,
  loadOptions,
  placeholder,
  extraPillpadding,
}: Props) => {
  const urlQuery = useUrlQuery();
  const location = useLocation();
  const history = useHistory();

  const onSearchInputChange = (event: ValueType<ChangeSelectEvent>) => {
    const { value, name } = event as ChangeSelectEvent;

    setPills((pills) => {
      if (pills.map(({ id }) => id).includes(value)) {
        return pills;
      }

      return [...pills, { id: value, name }];
    });
  };

  const onSubmitSearch = () => {
    const urlSearchParams = new URLSearchParams(urlQuery as {});
    const searchIds = urlSearchParams.get("search")?.split(",") ?? [];
    const pillIds = pills.map(({ id }) => id);

    if (isEqual(searchIds, pillIds)) {
      return;
    }

    if (pills.length === 0) {
      urlSearchParams.delete("search");
    } else {
      urlSearchParams.set("search", pillIds.join(","));
      urlSearchParams.delete("page");
    }

    history.push(location.pathname + "?" + urlSearchParams.toString());
  };

  const fetch = (inputValue: string, callback: any) => {
    loadOptions(inputValue).then((results: any) => callback(results));
  };

  const debouncedSearch = debounce(fetch, 500);

  return (
    <SearchBox className={extraPillpadding ? "search-pill" : ""}>
      <Async
        aria-label="search"
        placeholder={placeholder}
        loadOptions={debouncedSearch}
        onChange={onSearchInputChange}
        value={null}
        // @ts-ignore TODO:remove this
        styles={searchFieldStyles}
        formatOptionLabel={formatOptionLabel}
      />
      <Button onClick={onSubmitSearch}>Search</Button>
    </SearchBox>
  );
};

export const searchFieldStyles = {
  ...customStyles,
  placeholder: (provided: CSSProperties) => ({
    ...provided,
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  container: (provided: CSSProperties, state: any) => ({
    ...provided,
    background: "white",
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocus ? "4px 4px 0 0" : 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
  }),
  option: (provided: CSSProperties, { isSelected, isFocused }: any) => ({
    ...provided,
    color: (isSelected && "#FFFFFF") || (isFocused && "#FFFFFF") || "inherit",
    backgroundColor: (isSelected && "#0D2973") || (isFocused && "#0D2973") || "transparent",

    "&:hover": {
      backgroundColor: "#0D2973",
      color: isSelected ? "inherit" : "#FFFFFF",
    },
  }),
};

export const formatOptionLabel = ({
  label,
  member,
  phone,
  email,
  address,
  status,
  papas,
  isPreferred,
}: OptionLabel) => (
  <OptionLabelContainer>
    {isPreferred && (
      <div>
        <Strong>Preferred Pal</Strong>
        <PreferredPalIcon size={16} />
      </div>
    )}
    <Primary>{label}</Primary>
    {status && <Italic>{mapValueToLabel(papaStateOptions, status)}</Italic>}
    {phone && <div>{phone}</div>}
    {email && (
      <Italic>
        <Email size={16} /> {email}
      </Italic>
    )}
    {member && (
      <Muted>
        <AccountCircle size={16} /> {member}
      </Muted>
    )}{" "}
    {papas && (
      <Muted>
        {papas?.map((papa) => (
          <>
            <AccountCircle size={16} /> {papa.fullName} ({papa.phoneNumber})
            <br />
          </>
        ))}
      </Muted>
    )}
    {address && (
      <Italic>
        <Home size={16} /> {address}
      </Italic>
    )}
  </OptionLabelContainer>
);

export const SearchBox = styled.div`
  display: flex;
  width: 30rem;
  margin-right: 1.25rem;
  button {
    margin-left: 1.25rem;
  }

  &.search-pill {
    margin-bottom: 2rem;
  }
`;

const OptionLabelContainer = styled.div`
  svg {
    vertical-align: text-bottom;
  }
`;

const Primary = styled.div`
  font-size: 1.1em;
  font-weight: bold;
`;

const Italic = styled.div`
  font-style: italic;
`;

const Muted = styled.div`
  color: #9a9a9a;
`;

const Strong = styled.span`
  font-size: 0.9em;
  font-weight: bold;
`;

const PreferredPalIcon = styled(HeartCircle)`
  margin-left: 0.25em;
  margin-bottom: 0.1em;
  color: #0e2765;
`;

export default Search;
