import { gql } from "@apollo/client";

import { visitListFragment } from "components/DataTables/VisitsTable";

export const GET_CAREGIVER_VISITS = gql`
  query caregiverVisits($caregiverId: ID, $pagination: PaginationInput, $sorting: VisitSorting) {
    visits(filter: { caregiverId: $caregiverId }, pagination: $pagination, sorting: $sorting) {
      data {
        ...VisitPartsForDataTables
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
  ${visitListFragment}
`;
