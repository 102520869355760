import { useField, useFormikContext } from "formik";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import styled from "styled-components";

import { InputContainer as Container, StyledInput as Input } from "components/Form/FormInput";
import Text from "components/Text";
import { geoCodeResultToLocation } from "utils/geolocation";

import Feedback from "../Form/FormFeedback";

type Props = {
  initialValue?: string;
  name: string;
  placeholder?: string;
};

const GoogleLocationSearch = ({
  name,
  initialValue = "",
  placeholder = "Enter additional location address",
}: Props) => {
  const [{ value }, { touched, error }, helpers] = useField(name);
  const [address, setAddress] = useState(value?.address ?? "");
  const { isSubmitting } = useFormikContext();

  const errors = typeof error === "object" ? Object.values(error).join(", ") : error;

  const handleChange = (value: string) => {
    setAddress(value);

    if (!value) {
      helpers.setValue(null);
    }
  };

  const handleSelect = useCallback(
    async (value: string) => {
      setAddress(value);

      try {
        const [result] = await geocodeByAddress(value);
        const location = geoCodeResultToLocation(result);

        helpers.setValue(location);
      } catch (error) {
        window.console.error(error);
      }
    },
    [helpers]
  );

  useEffect(() => {
    const newValue = { ...value };

    if (!isEqual(value, newValue)) {
      helpers.setValue(newValue);
    }
  }, [value, helpers]);

  const isInvalid = touched && !!error ? true : undefined;

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        debounce={500}
        onError={(status) =>
          status !== "ZERO_RESULTS" &&
          window.console.error(
            `[react-places-autocomplete]: error happened when fetching data from Google Maps API.\nPlease check the docs here (https://developers.google.com/maps/documentation/javascript/places#place_details_responses)\nStatus: ${status}`
          )
        }
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: "relative" }}>
            <Container disabled={isSubmitting} isInvalid={isInvalid}>
              <Input
                {...(getInputProps({
                  placeholder,
                  disabled: isSubmitting,
                }) as any)}
              />
            </Container>
            {(loading || !!suggestions.length) && (
              <Menu className="autocomplete-dropdown-container">
                {loading && <Loading>Loading...</Loading>}
                {suggestions.map((suggestion) => (
                  <Item {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                    <span>{suggestion.description}</span>
                  </Item>
                ))}
              </Menu>
            )}
          </div>
        )}
      </PlacesAutocomplete>
      {touched && !!error && <Feedback isInvalid>{errors}</Feedback>}
    </>
  );
};

const Menu = styled.div`
  position: absolute;
  background: white;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-top: 0.5rem;
  z-index: 1;
`;

const Loading = styled(Text)`
  min-height: 38px;
  display: flex;
  align-items: center;
  padding: 0.625rem 0.875rem;
`;

const Item = styled(Text)`
  min-height: 38px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text.main};
  padding: 0.625rem 0.875rem;
  cursor: pointer;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.borderColor};
  }

  &:hover {
    color: ${({ theme }) => theme.variants.primary};
    background: #eaefff;
  }
`;

export default GoogleLocationSearch;
