import React from "react";
import { toast } from "react-toastify";

import { confirm } from "components/Confirm";
import { Loader } from "components/Loader";
import { ErrorModalResponse } from "components/Modal/ErrorModalResponse";
import { useEntityQuery } from "pages/Accounting/accountingMocked";

import { BaseRateForm } from "../BaseRateForm";
import { Schema } from "../schema";

type Props = {
  onClose: () => void;
  baseRateId: string;
};

export const EditBaseRateForm: React.FC<Props> = ({ onClose, baseRateId }) => {
  const { loading, error } = useEntityQuery({
    variables: {
      entityId: baseRateId,
    },
  });

  const initialValues: Schema = {
    hourlyRate: "10",
    state: "CA",
  };

  const handleSubmit = async (values: Schema) => {
    if (!(await confirm("Are you sure that you want to edit this base rate?"))) return;

    try {
      toast.warn("Mutation not implemented yet");
      onClose();
    } catch (error) {
      window.console.error((error as Error).message);
      toast.error((error as Error).message);
    }
  };

  if (loading) return <Loader />;

  if (error) return <ErrorModalResponse error={error} onClose={onClose} />;

  return <BaseRateForm initialValues={initialValues} onClose={onClose} onSubmit={handleSubmit} />;
};
