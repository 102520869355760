import { Form } from "formik";
import styled from "styled-components";

import Button from "components/Button";

import { ReactModalAdapter } from "./ModalAdapter";
import "./modal.css";

const defaultModalWidth = 600;
const modalWidth = (width?: number) => width || defaultModalWidth;

export const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 3, 25, 0.7);
    z-index: 500;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 7px;
    background-color: #fff;
    margin-top: 95px;
    margin-bottom: 30px;
    padding-bottom: 2.56rem;
    width: ${(props) => modalWidth(props.width)}px;
    outline: none;
  }

  form {
    width: 100%;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid #e5e5e5;
  padding: 1.375rem 2.625rem 0.75rem 4.5rem;
`;

export const ModalBody = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 1.875rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5rem;
  padding-right: 2.625rem;

  ${Button}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export const ModalContainer = styled.div`
  padding: 0 2.625rem 0 4.5rem;
`;

export const ModalStyledForm = styled(Form)`
  width: 100%;
`;
