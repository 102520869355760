import { gql } from "@apollo/client";

export const CREATE_BUSINESS = gql`
  mutation createBusiness($input: BusinessInput!) {
    createBusiness(input: $input) {
      data {
        id
        name
        email
        inboundMemberSupportNumber
        type
      }
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation updateBusiness($businessId: ID!, $input: BusinessInput!) {
    updateBusiness(id: $businessId, input: $input) {
      data {
        id
        name
        email
        inboundMemberSupportNumber
        type
      }
    }
  }
`;

export const CREATE_BUSINESS_ACCOUNT = gql`
  mutation createBusinessAccount($input: AccountInput!) {
    createAccount(input: $input) {
      data {
        id
        email
        emailVerified
        fullName
        phoneNumber
        type
        about
        insertedAt
        deletedAt
        requiresSignature
        requiresDrugTest
        testAccount
        insertedAt
        organization {
          data {
            id
          }
        }
        permissions {
          admin
        }
        createdBy {
          data {
            email
            fullName
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_BUSINESS_ACCOUNT = gql`
  mutation updateBusinessAccount($accountId: ID!, $input: AccountInput!) {
    updateAccount(id: $accountId, input: $input) {
      data {
        id
        email
        emailVerified
        fullName
        phoneNumber
        type
        about
        insertedAt
        deletedAt
        requiresSignature
        requiresDrugTest
        testAccount
        insertedAt
        organization {
          data {
            id
          }
        }
        permissions {
          admin
        }
      }
    }
  }
`;

export const GET_ACCOUNT_FROM_BUSINESS = gql`
  query getAccountFromBusiness($businessId: ID!) {
    business(id: $businessId) {
      data {
        accountId
        account {
          data {
            id
            fullName
            email
            phoneNumber
            insertedAt
            phoneNumber
            type
            about
            backupPhoneNumber
            backupFullName
            requiresDrugTest
            requiresSignature
            testAccount
            organization {
              data {
                id
              }
            }
            permissions {
              admin
            }
          }
        }
        eligibilityMethod
        eligibleLifeS3Folder
        eligibleEmployeeS3Folder
        inboundMemberSupportNumber
        type
        customVisitLeadHours
        useCustomVisitLeadHours
      }
    }
  }
`;
