import { useField, useFormikContext } from "formik";
import React, { FunctionComponent, useState } from "react";

import { confirm } from "components/Confirm";
import { TextMessagingStatus, calculateStatus } from "components/PapaTextMessaging/utils";
import {
  BusinessPolicy,
  ResourceBudget,
  VisitRecurrenceFrequency,
  useLocationPalSupplyQuery,
  usePapaDetailsQuery,
} from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useValidatePayment } from "hooks/useValidatePayment";
import { CurrentBusinessPolicyContext } from "pages/ScheduleVisit/shared/CurrentBusinesPolicyContext";

import VisitLeadTimeExceptionModal from "../../../modals/VisitLeadTimeExceptionModal";
import { Values } from "../types";
import MemberDetails from "./MemberDetails";
import PalSettings from "./PalSettings";
import Payment from "./Payment";
import Summary from "./Summary";
import TaskDetails from "./TaskDetails/TaskDetails";
import TimeDetails from "./TimeDetails";
import { RecurrenceDrawer } from "./TimeDetails/RecurrenceDrawer";
import { useEarliestAllowedDate } from "./TimeDetails/useEarliestAllowedDate";

interface Props {
  isPalSettingsSchedulingSectionEnabled?: boolean;
  isEditMode?: boolean;
  isCloneMode?: boolean;
  isPending?: boolean;
  isDemandProfilesLeadTimeModalOpen?: boolean;
  toggleDemandProfilesLeadTimeModal?: () => void;
  onDemandProfilesLeadTimeModal?: (values: Values) => Promise<void>;
  visitFormValues?: Values;
  handleLocationChange?: (locationId: string) => Promise<any>;
  earliestAllowedDates?: { VvDate: string; IpvDate: string };
}

export const VisitSteps: FunctionComponent<Props> = ({
  isEditMode = false,
  isCloneMode = false,
  isPending,
  isDemandProfilesLeadTimeModalOpen,
  toggleDemandProfilesLeadTimeModal,
  onDemandProfilesLeadTimeModal,
  visitFormValues,
  handleLocationChange,
  earliestAllowedDates,
}) => {
  const [currentBusinessPolicy, setCurrentBusinessPolicy] = useState<BusinessPolicy | null>(null);
  const [resourceBudget, setResourceBudget] = useState<ResourceBudget | null>(null);
  const [isRecurrenceVisit, setIsRecurrenceVisit] = useState<boolean>(false);
  const [, , scheduledForDayHelpers] = useField("scheduledForDay");
  const [, , freqHelpers] = useField("freq");
  const [, , validUntilHelpers] = useField("validUntil");
  const [, , countHelpers] = useField("count");
  const [, , palIdHelpers] = useField("palId");
  const { values } = useFormikContext<Values>();
  const [recurrenceSelectionMode, setRecurrenceSelectionMode] = useState<
    "selectStartDate" | "selectRecurrence"
  >("selectStartDate");

  const isPalSettingsSchedulingSectionEnabled = useIsFeatureEnabled(
    "pal_settings_scheduling_section"
  );
  useValidatePayment();

  let submitTitle = "";

  if (isEditMode) {
    submitTitle = "Save edits";
  } else if (isCloneMode) {
    submitTitle = "Clone visit";
  } else {
    submitTitle = "Submit a visit request";
  }

  const sectionNumbers = isPalSettingsSchedulingSectionEnabled
    ? {
        memberDetails: 1,
        timeDetails: 2,
        taskDetails: 3,
        palSettings: 4,
        payment: 5,
        summary: 6,
      }
    : {
        memberDetails: 1,
        timeDetails: 2,
        taskDetails: 3,
        payment: 4,
        summary: 5,
      };

  const locationId = values.location?.id;
  const { data } = useLocationPalSupplyQuery({
    variables: { locationId: locationId! },
    skip: !locationId,
  });
  const sufficientSupply = data?.locationPalSupply?.sufficientSupply;
  const { earliestAllowedDate, isOutsideEarliestAllowedDate, scheduleAfter } =
    useEarliestAllowedDate({ earliestAllowedDates });

  const [showRecurrenceDrawer, setShowRecurrenceDrawer] = useState<boolean>(false);
  const handleIsRecurrenceVisitCheckbox = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const switchToRecurrence = event.target.value === "true";

    if (
      !switchToRecurrence &&
      !(await confirm(
        "Switching to a one time visit will delete recurring visits.  Do you want to continue?",
        {
          cancelBtnText: "No, cancel",
          confirmBtnText: "Yes, continue",
        }
      ))
    ) {
      return;
    }

    if (!switchToRecurrence) {
      validUntilHelpers.setValue(null);
      countHelpers.setValue(null);
    } else {
      palIdHelpers.setValue(null);
    }

    setIsRecurrenceVisit(switchToRecurrence);
    setShowRecurrenceDrawer(switchToRecurrence);
    const defaultRecurrence =
      sufficientSupply === false
        ? VisitRecurrenceFrequency.Fortnightly
        : VisitRecurrenceFrequency.Daily;
    const freq = switchToRecurrence ? defaultRecurrence : null;
    freqHelpers.setValue(freq);
  };

  const handleCancelRecurrenceDrawer = () => {
    setIsRecurrenceVisit(false);
    setShowRecurrenceDrawer(false);
    freqHelpers.setValue(null);
    validUntilHelpers.setValue(null);
    countHelpers.setValue(null);
    scheduledForDayHelpers.setValue("");
    setRecurrenceSelectionMode("selectStartDate");
  };

  const handleEditRecurringVisit = () => {
    setShowRecurrenceDrawer(true);
  };

  const onLocationChange = async (locationId: string) => {
    if (!isEditMode) {
      handleCancelRecurrenceDrawer();
    }

    if (handleLocationChange) {
      handleLocationChange(locationId);
    }
  };

  const handleCloseRecurrenceDrawer = () => {
    setShowRecurrenceDrawer(false);
  };

  const {
    data: papaData,
    error: papaError,
    refetch: refetchPapaData,
  } = usePapaDetailsQuery({
    variables: { id: values.papaId },
  });

  const status = calculateStatus(papaData?.papa?.data?.messageServiceAuthorizations?.data?.[0]);
  // Duplicating logic here from `src/components/PapaTextMessaging/Switch.tsx`
  const isOptedIntoSMS =
    status === TextMessagingStatus.Pending || status === TextMessagingStatus.OptIn;

  return (
    <CurrentBusinessPolicyContext.Provider
      value={{ currentBusinessPolicy, setCurrentBusinessPolicy, resourceBudget, setResourceBudget }}
    >
      <MemberDetails
        isEditMode={isEditMode}
        handleLocationChange={onLocationChange}
        sectionNumber={sectionNumbers.memberDetails}
        papaData={papaData}
        papaQueryError={papaError}
        refetchPapaData={refetchPapaData}
      />
      <TimeDetails
        isEditMode={isEditMode}
        earliestAllowedDates={earliestAllowedDates}
        sectionNumber={sectionNumbers.timeDetails}
        sufficientSupply={sufficientSupply}
        isRecurrenceVisit={isRecurrenceVisit}
        showRecurrenceDrawer={showRecurrenceDrawer}
        handleIsRecurrenceVisitCheckbox={handleIsRecurrenceVisitCheckbox}
        isOutsideEarliestAllowedDate={isOutsideEarliestAllowedDate}
        earliestAllowedDate={earliestAllowedDate}
        scheduleAfter={scheduleAfter}
        onEditRecurringVisit={handleEditRecurringVisit}
      />
      <TaskDetails
        showNewPalSettings={isPalSettingsSchedulingSectionEnabled}
        disableVisibility={isEditMode && !isPending}
        isEditMode={isEditMode}
        sectionNumber={sectionNumbers.taskDetails}
        isOptedIntoSMS={isOptedIntoSMS}
      />
      {isPalSettingsSchedulingSectionEnabled && (
        <PalSettings
          disabled={isEditMode && !isPending}
          isEditMode={isEditMode}
          sectionNumber={sectionNumbers.palSettings}
        />
      )}
      <Payment sectionNumber={sectionNumbers.payment} />
      <Summary
        isEditMode={isEditMode}
        submitTitle={submitTitle}
        sectionNumber={sectionNumbers.summary}
      />
      <VisitLeadTimeExceptionModal
        isOpen={!isEditMode && isDemandProfilesLeadTimeModalOpen}
        toggle={toggleDemandProfilesLeadTimeModal}
        onSubmit={(formValues) => onDemandProfilesLeadTimeModal!(formValues as Values)}
        visitFormValues={{ action: "schedule", values: visitFormValues! || {} }}
      />
      <RecurrenceDrawer
        isOpen={showRecurrenceDrawer}
        onClose={handleCloseRecurrenceDrawer}
        onCancel={handleCancelRecurrenceDrawer}
        isOutsideEarliestAllowedDate={isOutsideEarliestAllowedDate}
        scheduleAfter={scheduleAfter}
        selectionMode={recurrenceSelectionMode}
        setSelectionMode={setRecurrenceSelectionMode}
      />
    </CurrentBusinessPolicyContext.Provider>
  );
};
