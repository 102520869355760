import { useMutation } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import ConfirmModal from "components/Modal/ConfirmModal";
import { ModalLayout } from "components/Modal/ModalLayout";
import { Survey } from "generated/types";

import { CREATE_SURVEY, UPDATE_SURVEY } from "./gql";

export interface Schema {
  id: string;
  name: string;
  url: string;
  isActive: boolean;
}

export const schema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required("Required"),
  url: yup.string().required("Required"),
  isActive: yup.boolean(),
});

interface Props {
  toggle: () => void;
  isOpen: boolean;
  survey: Survey | null;
  refetch: () => void;
}

export interface AddEditSurveyInfo {
  addOrEdit: "add" | "edit";
  id: string;
  name: string;
  url: string;
  isActive: boolean;
}

const AddEditSurveyModal: React.FC<Props> = ({ toggle, isOpen, survey, refetch }) => {
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [mutation, { loading, error: mutationError }] = useMutation(
    survey ? UPDATE_SURVEY : CREATE_SURVEY
  );

  const handleSubmit = async (input: Schema) => {
    try {
      const { data } = await mutation({
        variables: {
          id: survey ? input.id : undefined,
          input: {
            name: input.name,
            url: input.url,
            isActive: input.isActive,
          },
        },
      });

      if (mutationError) {
        toast.error(mutationError);
      }
      if (data?.createSurvey || data?.updateSurvey) {
        toast.success(`Survey was ${survey ? "edited" : "added"} with success!`);
        refetch();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
    toggle();
  };

  const initialValues: Schema = {
    id: survey?.id ?? "",
    name: survey?.name ?? "",
    url: survey?.url ?? "",
    isActive: survey?.isActive ?? true,
  };

  return (
    <ModalLayout toggle={toggle} isOpen={isOpen} title={survey ? "Edit Survey" : "Add Survey"}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <>
            <StyledForm>
              <Container>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Input name="name" aria-label="name" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>URL</Form.Label>
                  <Form.Input name="url" aria-label="url" />
                </Form.Group>
                <Form.Group>
                  <Form.GroupInline>
                    <Form.Inline>
                      <Form.Radio
                        controlId="isActive.true"
                        name="isActive"
                        value={true}
                        label="Active"
                      />
                    </Form.Inline>
                    <Form.Inline>
                      <Form.Radio
                        controlId="isActive.false"
                        name="isActive"
                        value={false}
                        label="Inactive"
                        onClick={() => {
                          setConfirmVisibility(true);
                        }}
                      />
                    </Form.Inline>
                  </Form.GroupInline>
                </Form.Group>
              </Container>

              <FormFooter>
                <Button variant="secondary" onClick={toggle}>
                  Close
                </Button>
                <Button type="submit" variant="primary" disabled={loading}>
                  {loading ? (
                    <>
                      <SpinningIcon size={18} />
                      <span>Saving</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </FormFooter>
            </StyledForm>
            <ConfirmModal
              isOpen={confirmVisibility}
              onCancel={() => {
                setFieldValue("isActive", true);
                setConfirmVisibility(false);
              }}
              onConfirm={() => {
                setConfirmVisibility(false);
              }}
              cancelBtnText="No"
              confirmBtnText="Yes"
              header="Are you sure you want to make this survey inactive? This will remove the survey from ALL future visits and ALL Visit Programs that use this survey."
            />
          </>
        )}
      </Formik>
    </ModalLayout>
  );
};

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5rem;
  padding-right: 2.625rem;

  ${Button}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const Container = styled.div`
  padding: 0 54px 0 36px;
`;

const StyledForm = styled(FormikForm)`
  width: 100%;
`;

export default AddEditSurveyModal;
