import { ApolloError } from "@apollo/client";
import React from "react";

import Actions from "components/ActionsMenu/PalActionMenu/Actions";
import SmartTable from "components/SmartTable";
import { Pagination, Pal, PalSorting } from "generated/types";

import { usePalTableColumns } from "./usePalTableColumns";

export interface Props {
  data?: Pal[];
  loading: boolean;
  pagination?: Pagination | null;
  error?: ApolloError;
  sorting: PalSorting;
  onSortChange: (sort: PalSorting) => void;
  cursorPagination?: boolean;
}

const PalsTable = ({
  data,
  loading,
  error,
  pagination,
  sorting,
  onSortChange,
  cursorPagination = false,
}: Props) => {
  const { columns, action, pal, closeModal } = usePalTableColumns({
    sorting,
    onSortChange,
  });

  return (
    <>
      <SmartTable<Pal>
        keyField="id"
        localStorageKey="pals"
        data={data}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
        cursorPagination={cursorPagination}
        rowProps={({ id }) => ({
          linkTo: `/pals/${id}`,
        })}
      />

      <Actions action={action} pal={pal} closeModal={closeModal} />
    </>
  );
};

export default PalsTable;
