import { ApolloError } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import VisitActionsMenu from "components/ActionsMenu/VisitActionsMenu";
import Actions from "components/ActionsMenu/VisitActionsMenu/Actions";
import { useVisitActions } from "components/ActionsMenu/VisitActionsMenu/useVisitActions";
import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import VerticalIconsList from "components/VerticalIconsList";
import { VisitFlags } from "components/VisitFlags";
import VisitRecurrence from "components/VisitRecurrence";
import { DEFAULT_TIMEZONE } from "constants/date";
import { demandProfileOptions } from "constants/demandProfile";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { FeatureFlags } from "constants/featureFlags";
import { VisitMenuActions } from "constants/visit";
import {
  Pagination,
  SortDirection,
  Visit,
  VisitAction,
  VisitSorting,
  VisitState,
} from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import formatScheduledFor from "utils/formatScheduledFor";
import { mapValueToLabel } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { getDuration as formatDurationHours } from "utils/numbers/durations";

import { ItemsCount } from "./ItemsCount";
import { StatusCell } from "./StatusCell";
import { TypeCell } from "./TypeCell";

export { GET_VISITS_LIST, GET_VISITS_LIST_WITH_CURSORS, visitListFragment } from "./gql";

type Props = {
  loading: boolean;
  data?: Visit[];
  pagination?: Pagination | null;
  error?: ApolloError;
  sorting: VisitSorting;
  onSortChange: (sorting: VisitSorting) => void;
  cursorPagination?: boolean;
  fromPapa?: boolean;
  fromPal?: boolean;
  fromCaregiver?: boolean;
  minimal?: boolean;
};

const VisitsTable = ({
  data,
  loading,
  error,
  pagination,
  onSortChange,
  sorting: initialSorting,
  cursorPagination = false,
  fromPapa = false,
  fromPal = false,
  fromCaregiver = false,
  minimal = false,
}: Props) => {
  const [sorting, setSorting] = useState<VisitSorting>(initialSorting);
  const { visitId, action, warmTransferData, setAction } = useVisitActions();

  const onSetSorting = (sortKey: keyof VisitSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const linkTo = (visitId: string) =>
    fromPal
      ? `/visits/${visitId}?fromPal=true`
      : fromPapa
      ? `/visits/${visitId}?fromPapa=true`
      : `/visits/${visitId}`;

  const clientCopyUpdateEnabled = useIsFeatureEnabled(FeatureFlags.ClientCopyUpdate);
  const clientColumnHeaderTitle = clientCopyUpdateEnabled ? "Client" : "Business";
  const papaColumnHeaderTitle =
    clientCopyUpdateEnabled && fromCaregiver ? "Care Recepient" : "Papa";

  const columns: Column<Visit>[] = useMemo(() => {
    return [
      {
        header: { title: "Status" },
        dataField: "state",
        formatter({ state, events }) {
          const isMemberConfirmed = events?.data?.some(
            (event) =>
              event?.__typename === "VisitEvent" &&
              event?.action?.toUpperCase() === VisitAction.MemberConfirmed
          );

          if (!state) return EMPTY_PLACEHOLDER;

          return <StatusCell state={state} isMemberConfirmed={!!isMemberConfirmed} />;
        },
      },
      {
        header: { title: "Type" },
        dataField: "type",
        formatter({ isEmployerVisit, isUberEligible, isVirtual, state }) {
          return (
            <TypeCell
              isEmployerVisit={isEmployerVisit}
              isUberEligible={isUberEligible}
              isVirtual={isVirtual}
              state={state}
            />
          );
        },
      },
      {
        header: { title: "Flags" },
        dataField: "flags",
        formatter({ visitFlags, isPreviouslyMissed }) {
          return (
            <VisitFlags
              visitFlags={nonNull(visitFlags?.data)}
              isPreviouslyMissed={isPreviouslyMissed}
            />
          );
        },
      },
      {
        header: {
          title: "Scheduled For",
          sort: sorting.scheduledFor,
          onChange: (direction) => onSetSorting("scheduledFor", direction),
        },
        dataField: "scheduledFor",
        formatter(visit) {
          return (
            <>
              {formatScheduledFor(
                visit.scheduledFor,
                visit.location?.data?.timezone || DEFAULT_TIMEZONE
              )}
              {typeof visit.estimatedDuration === "number" && (
                <>
                  <br /> {formatDurationHours(visit.estimatedDuration)}
                </>
              )}
            </>
          );
        },
      },
      {
        header: { title: "Deducted Hours" },
        dataField: "deductedMins",
        formatter({ deductedMins, state }) {
          const isPending =
            state === VisitState.Pending ||
            state === VisitState.Accepted ||
            state === VisitState.Confirmed ||
            state === VisitState.Started;

          if (isPending) {
            return (
              <>
                <Text>{formatDurationHours(deductedMins)}</Text>
                <Text italic>Pending</Text>
              </>
            );
          } else {
            return <Text>{formatDurationHours(deductedMins)}</Text>;
          }
        },
      },
      {
        header: { title: "Tasks" },
        dataField: "tasks.data",
        formatter(visit) {
          return <VerticalIconsList icons={nonNull(visit.tasks?.data)} maximum={4} />;
        },
      },
      {
        header: { title: clientColumnHeaderTitle },
        dataField: "account.data",
        formatter(visit) {
          return (
            <Link to={`/members/${visit.account?.data?.id}`}>{visit.account?.data?.fullName}</Link>
          );
        },
      },
      {
        header: { title: papaColumnHeaderTitle },
        dataField: "papa.data",
        formatter(visit) {
          const papa = papaConcealed(visit.papa?.data);

          if (papa) {
            return <Link to={`/papas/${papa.id}`}>{papa.fullName}</Link>;
          }
          return EMPTY_PLACEHOLDER;
        },
      },
      {
        header: { title: "Pal" },
        dataField: "pal.data",
        formatter(visit) {
          if (visit.state === VisitState.Pending || !visit.pal?.data) {
            return EMPTY_PLACEHOLDER;
          }

          return <Link to={`/pals/${visit.pal.data.id}`}>{visit.pal.data.fullName}</Link>;
        },
      },
      {
        header: { title: "Recurrence" },
        dataField: "recurrence.data.config",
        formatter(visit) {
          return (
            (visit.recurrence?.data &&
              VisitRecurrence({
                recurrence: visit.recurrence?.data,
                visit,
              })) ||
            "no recurrence"
          );
        },
      },
      {
        header: { title: "Location" },
        dataField: "location.data",
        formatter(visit) {
          if (!visit.location?.data) return EMPTY_PLACEHOLDER;
          const {
            city,
            houseNumber,
            lat,
            lng,
            state: locationState,
            streetName,
            zipcode,
          } = visit.location.data;
          return (
            <a
              href={`https://www.google.com/maps/?q=${lat},${lng}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {houseNumber || streetName ? (
                <>
                  {[houseNumber, streetName].join(" ")}
                  <br />
                </>
              ) : (
                ""
              )}
              {city}, {locationState} {zipcode}
            </a>
          );
        },
      },
      {
        header: { title: "County" },
        dataField: "location.county",
        formatter(visit) {
          const county = visit.location?.data?.county;

          if (!county) return EMPTY_PLACEHOLDER;

          return visit.demandProfile
            ? `${county} (${mapValueToLabel(demandProfileOptions, visit.demandProfile)})`
            : county;
        },
      },
      {
        header: { title: "Uber Interest" },
        dataField: "uberInterest",
        formatter({ isUberEligible }) {
          return isUberEligible === true ? "Yes" : isUberEligible === false ? "No" : "N/A";
        },
      },
      {
        header: { title: "Scheduling" },
        dataField: "scheduling",
        formatter({ applyVisitProgram }) {
          return applyVisitProgram === true ? "Scheduled" : "Unscheduled";
        },
      },
      {
        header: { title: "" },
        dataField: "actions",
        cellProps: {
          fixed: true,
          align: "center",
        },
        formatter(visit) {
          return (
            <VisitActionsMenu
              setAction={setAction}
              visit={visit}
              isListMenu
              fromPal={fromPal}
              fromPapa={fromPapa}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  return (
    <>
      {!minimal && <ItemsCount totalCount={pagination?.totalCount} />}
      <SmartTable<Visit>
        keyField="id"
        localStorageKey="visits"
        data={data}
        pagination={pagination}
        loading={loading}
        error={error}
        rowProps={({ state, id }) =>
          state && id
            ? {
                statusLegend: state,
                linkTo: linkTo(id),
              }
            : {}
        }
        columns={columns}
        cursorPagination={cursorPagination}
        minimal={minimal}
      />

      <Actions
        action={action}
        visitId={visitId}
        warmTransferData={warmTransferData}
        onClose={() => setAction(VisitMenuActions.NoAction)}
      />
    </>
  );
};

export const StyledTooltip = styled.span<{ state: VisitState }>`
  display: inline-block;
  color: #354052;

  svg {
    color: ${({ theme, state }) => theme.statusLegend[state]};
  }
`;

export default VisitsTable;
