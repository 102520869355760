import { PaymentInvoiceState, UpdatablePaymentInvoiceState } from "generated/types";

export const statePaymentInvoiceOptions = [
  { value: UpdatablePaymentInvoiceState.Flagged, label: "Flagged" },
  { value: UpdatablePaymentInvoiceState.RejectedManually, label: "Rejected Manually" },
  { value: UpdatablePaymentInvoiceState.ResolvedManually, label: "Resolved Manually" },
];

export const paymentInvoiceStateLabels = {
  [PaymentInvoiceState.Approved]: "approved",
  [PaymentInvoiceState.AutoApproved]: "auto approved",
  [PaymentInvoiceState.Pending]: "pending",
  [PaymentInvoiceState.Flagged]: "flagged",
  [PaymentInvoiceState.RejectedManually]: "rejected manually",
  [PaymentInvoiceState.ResolvedManually]: "resolved manually",
  [PaymentInvoiceState.TransactionFailed]: "transaction failed",
  [PaymentInvoiceState.TransactionSucceeded]: "transaction succeeded",
};
