import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import moment from "moment-timezone";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import InputDate from "components/InputDate";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import QueryErrors from "components/QueryErrors";
import Select from "components/Select";
import { DATE_FORMAT } from "constants/date";
import { genderOptions } from "constants/gender";
import {
  EligibleEmployee,
  EligibleEmployeeAcceptResult,
  RootMutationTypeAcceptEligibleEmployeeArgs,
} from "generated/types";
import { generatePassword } from "utils/generate-password";

import { ACCEPT_ELIGIBLE_EMPLOYEE, GET_ELIGIBLE_EMPLOYEE } from "./gql";
import { Schema, schema } from "./schema";

interface Data {
  eligibleEmployee: {
    data: EligibleEmployee;
  };
}

interface DataMutation {
  acceptEligibleEmployee: EligibleEmployeeAcceptResult;
}

type Props = {
  enrollEmployeeId: string;
  toggle: () => void;
  isOpen: boolean;
};

type EnrollEmployeeFormProps = {
  eligibleEmployee: EligibleEmployee;
  onClose: () => void;
};

const EnrollEmployeeForm = ({ eligibleEmployee, onClose }: EnrollEmployeeFormProps) => {
  const history = useHistory();
  const [enrollEmployee] = useMutation<DataMutation, RootMutationTypeAcceptEligibleEmployeeArgs>(
    ACCEPT_ELIGIBLE_EMPLOYEE
  );

  const handleSubmit = async (values: Schema) => {
    if (!eligibleEmployee || !values.gender || !values.email) return;

    const birthDate =
      values.birthDate && moment(values.birthDate).isValid()
        ? moment(values.birthDate).format("YYYY-MM-DD")
        : null;

    try {
      const { data: dataMutation } = await enrollEmployee({
        variables: {
          id: eligibleEmployee.id as string,
          input: {
            email: values.email as string,
            fullName: values.fullName,
            dob: birthDate,
            gender: values.gender.toString().toLowerCase(),
            password: generatePassword(10),
          },
        },
      });

      if (dataMutation?.acceptEligibleEmployee?.data?.caregiver?.data?.id) {
        toast.success("Employee is enrolled!");

        const url = `/caregivers/${
          dataMutation.acceptEligibleEmployee.data.caregiver.data.id
        }/visits?${queryString.stringify({
          createPapa: true,
          papaName: values.fullName,
          papaEmail: values.email,
          papaBirthday: birthDate,
          papaGender: values.gender,
        })}`;

        history.push(url);
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues: Schema = {
    birthDate: eligibleEmployee?.dob || "",
    email: "",
    fullName: [eligibleEmployee?.firstName, eligibleEmployee?.lastName]
      .filter((a) => !!a)
      .join(" "),
    gender: null,
  };

  return (
    <Formik<Schema> initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Row>
            <Form.Col>
              <Form.Group>
                <Form.Label required>Full name</Form.Label>
                <Form.Input aria-label="fullName" name="fullName" placeholder="Full name" />
              </Form.Group>
            </Form.Col>
            <Form.Col>
              <Form.Group>
                <Form.Label required>Email</Form.Label>
                <Form.Input aria-label="email" name="email" placeholder="Email" />
              </Form.Group>
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <Form.Col>
              <Form.Group>
                <Form.Label required>Gender</Form.Label>
                <Select
                  name="gender"
                  options={genderOptions}
                  isSearchable={false}
                  placeholder="Select gender"
                  aria-label="gender"
                />
              </Form.Group>
            </Form.Col>
            <Form.Col>
              <Form.Group>
                <Form.Label required>Birth Date</Form.Label>
                <InputDate
                  format={DATE_FORMAT}
                  isOutsideRange={() => false}
                  id="birthDate"
                  name="birthDate"
                />
              </Form.Group>
            </Form.Col>
          </Form.Row>
        </ModalContainer>
        <ModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Form.SubmitButton action noContent>
            Enroll
          </Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

const EnrollEmployeeModal = ({ enrollEmployeeId, isOpen, toggle }: Props) => {
  const { data, loading, error } = useQuery<Data>(GET_ELIGIBLE_EMPLOYEE, {
    variables: {
      id: enrollEmployeeId,
    },
  });

  const eligibleEmployee = data?.eligibleEmployee?.data;

  return (
    <ModalLayout
      toggle={toggle}
      isOpen={isOpen}
      title="Enroll Employee"
      width={750}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      {eligibleEmployee && (
        <EnrollEmployeeForm eligibleEmployee={eligibleEmployee} onClose={toggle} />
      )}
      {loading && <Loader />}

      <QueryErrors error={error} />
    </ModalLayout>
  );
};

export default EnrollEmployeeModal;
