import { ApolloError } from "@apollo/client";
import React, { FC } from "react";

import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { genderOptions } from "constants/gender";
import { languageOptions } from "constants/language";
import { Account } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import EditOverview from "modals/Accounts/EditOverview";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";

interface Props {
  member?: Account | null;
  loading: boolean;
  error: ApolloError | undefined;
  onRefetch: () => void;
}

const Overview: FC<Props> = ({ member, loading, error, onRefetch }) => {
  const { isOpen, toggle: toggleModal } = useModalToggle();

  if (loading) return <Panel.Side />;
  if (error)
    return (
      <Panel.Side>
        <QueryErrors error={error} />
      </Panel.Side>
    );

  const { email, homeLocation, id: accountId, pal, phoneNumber, plan } = member ?? {};
  const { id: palId, gender, dateOfBirth, languages, school, fieldOfStudy } = pal?.data ?? {};
  const { data: languagesList } = languages || {};

  const membership = plan?.data;

  return (
    <>
      <Panel.Side>
        <div>
          <Panel.Item>
            <Text bold>ID</Text>
            <Text>{accountId || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          {member?.pal?.data && (
            <Panel.Item>
              <Text bold>Pal payment ID</Text>
              <Text>{palId || EMPTY_PLACEHOLDER}</Text>
            </Panel.Item>
          )}

          <Panel.Item>
            <Text bold>Phone number</Text>
            <Text>{phoneNumber || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Languages</Text>
            {languagesList
              ? languagesList.map((language: string, index: number) => (
                  <Text key={`language_${index}`}>
                    {mapValueToLabel(languageOptions, language)}
                  </Text>
                ))
              : EMPTY_PLACEHOLDER}
          </Panel.Item>

          <Panel.Item>
            <Text bold>Gender</Text>
            <Text>{gender ? mapValueToLabel(genderOptions, gender) : EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Email</Text>
            {email ? (
              <Text title={email} wrapString>
                {email}
              </Text>
            ) : (
              EMPTY_PLACEHOLDER
            )}
          </Panel.Item>

          <Panel.Item>
            <Text bold>Date of birth</Text>
            <Text>{formatDateTime(dateOfBirth, { format: "L" })}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Home address</Text>
            <Text>{homeLocation?.data?.address || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>School</Text>
            <Text>{school || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Panel.Item>
            <Text bold>Field of study</Text>
            <Text>{fieldOfStudy || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>

          <Tooltip
            content={
              membership && (
                <>
                  <Text bold>Name</Text>
                  <Text>{membership.name ?? EMPTY_PLACEHOLDER}</Text>
                  <Text bold>ID</Text>
                  <Text>{membership.id ?? EMPTY_PLACEHOLDER}</Text>
                  <Text bold>Hourly rate</Text>
                  <Text>${((membership.hourlyRate ?? 0) / 100).toFixed(2)}</Text>
                  <Text bold>Monthly rate</Text>
                  <Text>${((membership.monthlyRate ?? 0) / 100).toFixed(2)}</Text>
                </>
              )
            }
          >
            <Panel.Item>
              <Text bold>Membership</Text>
              <Text>{membership ? membership.name || membership.id : EMPTY_PLACEHOLDER}</Text>
            </Panel.Item>
          </Tooltip>
        </div>

        <Button type="button" block onClick={toggleModal}>
          Edit
        </Button>
      </Panel.Side>

      {member && (
        <ModalLayout title="Edit Member Overview" isOpen={isOpen} toggle={toggleModal}>
          <EditOverview account={member} toggleShow={toggleModal} onEditFinish={onRefetch} />
        </ModalLayout>
      )}
    </>
  );
};

export default Overview;
