import { gql } from "@apollo/client";

import { visitListFragment } from "components/DataTables/VisitsTable";

export const GET_MEMBERS = gql`
  query MemberAccounts(
    $filter: [AccountFilter]
    $pagination: PaginationInput
    $sorting: AccountSorting
  ) {
    accounts(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        fullName
        email
        phoneNumber
        insertedAt
        plan {
          data {
            name
          }
        }
        pal {
          data {
            id
            status
            dateOfBirth
            gender
            languages {
              data
            }
            school
            fieldOfStudy
          }
        }
        homeLocation {
          data {
            id
            address
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;

export const GET_MEMBER_OVERVIEW = gql`
  query MemberOverview($id: ID!) {
    account(id: $id) {
      data {
        id
        email
        fullName
        phoneNumber
        stripeSubscriptionId
        type
        pal {
          data {
            id
            dateOfBirth
            gender
            languages {
              data
            }
            school
            fieldOfStudy
          }
        }
        homeLocation {
          data {
            id
            address
          }
        }
        plan {
          data {
            id
            name
            hourlyRate
            monthlyRate
          }
        }
      }
    }
  }
`;

export const GET_BUSINESS_FROM_ACCOUNT = gql`
  query getBusinessFromAccount($accountId: UUID!) {
    businesses(filter: { accountId: { eq: $accountId } }) {
      data {
        id
      }
    }
  }
`;

export const GET_MEMBER_INFO = gql`
  query MemberInfo($id: ID!) {
    account(id: $id) {
      data {
        id
        insertedAt
        updatedAt
        pal {
          data {
            id
            bio
          }
        }
      }
    }
  }
`;

export const GET_PAL_NOTES = gql`
  query getPalNotes($id: ID!) {
    account(id: $id) {
      data {
        id
        pal {
          data {
            id
            notes {
              data {
                id
                content
                insertedAt
                creator {
                  data {
                    id
                    fullName
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_VEHICLE = gql`
  query MemberVehicle($id: ID!) {
    account(id: $id) {
      data {
        id
        pal {
          data {
            id
            vehicle
            vehicleYear
            vehicleColor
            vehicleModel
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_VISITS = gql`
  query MemberVisits($id: ID!, $sorting: VisitSorting, $pagination: PaginationInput) {
    visits(filter: { account: { owner: $id } }, sorting: $sorting, pagination: $pagination) {
      data {
        ...VisitPartsForDataTables
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
  ${visitListFragment}
`;

export const GET_MEMBER_STATUS_LOG = gql`
  query MemberStatusLog($id: ID!) {
    account(id: $id) {
      data {
        pal {
          data {
            statusLogs {
              data {
                id
                newStatus
                comment
                updatedAt
                account {
                  data {
                    id
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_DEVICES = gql`
  query MemberDevices($id: ID!) {
    account(id: $id) {
      data {
        deviceTokens {
          data {
            id
            info {
              model
              systemName
              systemVersion
              appVersion
              manufacturer
            }
            platform
            updatedAt
          }
        }
      }
    }
  }
`;
