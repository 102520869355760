import { gql } from "@apollo/client";

export const CREATE_ADMIN_ACCOUNT = gql`
  mutation createAdminAccount($input: AccountInput!) {
    createAccount(input: $input) {
      data {
        id
      }
    }
  }
`;
