import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { FunctionComponent, useState } from "react";
import Helmet from "react-helmet";
import { toast } from "react-toastify";

import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import SmartTable from "components/SmartTable";
import { FeatureFlags } from "constants/featureFlags";
import { EligibleLife, EligibleLifeListResult } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePageInfo } from "hooks/usePageInfo";
import { GET_CURRENT_ACCOUNT } from "utils/gql/current-account";

import EnrollMember from "./EnrollMember";
import { GET_ELIGIBLE_MEMBERS_LIST } from "./gql";
import { useEligibleLivesSearch } from "./useEligibleLivesSearch";
import { useEligibleMembersListColumns } from "./useEligibleMembersListColumns";

interface Data {
  eligibleLives: EligibleLifeListResult;
}
const EligibleMembers: FunctionComponent = () => {
  const clientCopyUpdateEnabled = useIsFeatureEnabled(FeatureFlags.ClientCopyUpdate);
  const clientOrClientProgram = clientCopyUpdateEnabled ? "Client Program" : "Client";

  useTopBarOptions({ title: "Eligible Lives", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { beforeCursor, afterCursor } = usePageInfo();
  const { filter, pills, setPills } = useSearch();
  const [memberId, setMemberId] = useState<string | null>(null);
  const [isModalFormOpen, setIsModalFormOpen] = useState<boolean>(false);
  const { refetch } = useQuery(GET_CURRENT_ACCOUNT);
  const enrollMember = async (memberId: string) => {
    try {
      if (refetch) {
        const { data } = await refetch();
        if (data?.currentAccount?.data?.pal?.data?.activeVisit?.data) {
          toast.error("Please end your current visit before starting a new enrollment.");
        } else {
          setMemberId(memberId);
          setIsModalFormOpen(true);
        }
      }
    } catch (error: any) {
      toast.error((error as Error).message);
    }
  };

  const columns = useEligibleMembersListColumns(enrollMember);

  const { data, loading, error } = useQuery<Data>(GET_ELIGIBLE_MEMBERS_LIST, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit: 25 },
      ...(filter ? { filter } : {}),
    },
  });

  const { loadOptions, searchPillNames } = useEligibleLivesSearch(data?.eligibleLives.data!);

  const eligibleLives = data?.eligibleLives;

  return (
    <div>
      <Helmet>
        <title>Eligible Lives - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between">
        <FlexRow>
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder={`Search by Name, Phone, ${clientOrClientProgram}`}
          />
        </FlexRow>
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />

      <SmartTable<EligibleLife>
        keyField="id"
        data={eligibleLives?.data}
        pagination={eligibleLives?.pagination}
        loading={loading}
        columns={columns}
        error={error}
        cursorPagination={true}
      />

      <EnrollMember
        memberId={memberId}
        isModalFormOpen={isModalFormOpen}
        setIsModalFormOpen={setIsModalFormOpen}
      />
    </div>
  );
};

export default EligibleMembers;
