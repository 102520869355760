import { useApolloClient, useQuery } from "@apollo/client";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Async from "react-select/async";
import { OptionTypeBase, ValueType } from "react-select/src/types";
import styled from "styled-components";

import Feedback from "components/Form/FormFeedback";
import { InputContainer, StyledInput } from "components/Form/FormInput";
import { customStyles } from "components/Select";
import { Location, LocationListResult } from "generated/types";
import { nonNull } from "utils/nonNull";

import { getLocationOptions } from "./getLocationOptions";
import { LOCATIONS_SEARCH } from "./gql";

type Props = {
  name: string;
  papaId?: string;
  handleLocationChange?: (locationId: string) => Promise<any>;
};

interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

export type SelectedItem = {
  value: string;
  label: string;
};

type Data = {
  locations: LocationListResult;
};

const pagination = {
  limit: 50,
};

const LocationSearch = ({ name, papaId, handleLocationChange }: Props) => {
  const client = useApolloClient();
  const [{ value }, { touched, error }, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();
  const [defaultOptions, setDefaultOptions] = useState<SelectedItem[]>([]);
  const { data: initLocationsData } = useQuery<Data>(LOCATIONS_SEARCH, {
    skip: !papaId,
    variables: {
      filter: {
        deletedAt: { eq: null },
        papa: {
          idEq: papaId,
        },
      },
      pagination,
    },
  });

  useEffect(() => {
    if (initLocationsData?.locations?.data) {
      setDefaultOptions(getLocationOptions(nonNull(initLocationsData?.locations?.data)));
    } else {
      setDefaultOptions([]);
    }
  }, [initLocationsData, setDefaultOptions]);

  const mapChangeSelectEventToLocation = (event: ChangeSelectEvent) => {
    return {
      id: event.value,
      address: event.label,
      timezone: event.timezone,
      supportOperatingHours: event.supportOperatingHours,
    } as Location;
  };

  const handleChange = (value: ValueType<ChangeSelectEvent>) => {
    const computedValue = mapChangeSelectEventToLocation(value as ChangeSelectEvent);
    if (computedValue) {
      helpers.setValue(computedValue);
      if (handleLocationChange && computedValue?.id) {
        handleLocationChange(computedValue.id);
      }
    } else {
      helpers.setValue(null);
    }
  };

  async function handleLoadOptions(value: string) {
    const { data } = await client.query<Data>({
      query: LOCATIONS_SEARCH,
      variables: {
        filter: {
          deletedAt: { eq: null },
          nicknameOrAddress: { nicknameOrAddressContains: value },
          papa: {
            idEq: papaId,
          },
        },
        pagination,
      },
    });

    if (data?.locations.data) {
      return getLocationOptions(nonNull(data?.locations.data));
    }

    return [];
  }

  const loadOptions = (value = "") => handleLoadOptions(value);
  const locationId = value?.value ?? value?.id;
  const defaultValue = defaultOptions.find((option) => option.value === locationId);
  const location = defaultValue ? mapChangeSelectEventToLocation(defaultValue) : value;
  useEffect(() => {
    if (defaultValue && locationId && handleLocationChange) {
      helpers.setValue(location);
      handleLocationChange(locationId);
    } else if (defaultValue) {
      helpers.setValue(location);
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  return (
    <FlexCol>
      {defaultOptions.length ? (
        <Async
          aria-label="location search"
          defaultOptions={defaultOptions}
          defaultValue={defaultValue}
          placeholder="Location Search"
          loadOptions={loadOptions}
          onChange={handleChange}
          // @ts-ignore TODO:remove this
          styles={customStyles}
          isDisabled={isSubmitting}
        />
      ) : (
        <InputContainer disabled>
          <StyledInput
            value={papaId ? "no locations for this member" : "Please select the member first"}
            disabled
          />
        </InputContainer>
      )}
      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </FlexCol>
  );
};

const FlexCol = styled("div")`
  max-width: 18.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default LocationSearch;
