import styled, { css } from "styled-components";

interface WrapperProps {
  small?: boolean;
  autoWidth?: boolean;
  isInvalid?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  min-width: ${({ small }) => (small ? 6 : 12)}rem;
  width: ${({ autoWidth }) => (autoWidth ? "auto" : "100%")};

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      .select {
        border-color: ${({ theme }) => theme.variants.danger};
      }
    `}
`;
