import React, { useState } from "react";
import styled from "styled-components";

import Button from "components/Button";
import EditButton from "components/EditButton";
import { FlexRow } from "components/Flex";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import Table from "components/Table";
import { ServiceRequestComment } from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useModalToggle } from "hooks/useModalToggle";
import EditNote from "modals/CareConcierge/EditNote";
import { formatDateTime } from "utils/helpers/formatters";

export interface Props {
  serviceId?: string;
  serviceRequestComments?: ServiceRequestComment[];
}
type ColumnProps = {
  size?: number;
};
const ServiceRequestComments = ({ serviceId, serviceRequestComments }: Props) => {
  const currentUserId = useCurrentAccount()?.id;
  const { isOpen: showEditModal, toggle: toggleEditModal } = useModalToggle();
  const [serviceRequestComment, setServiceRequestComment] = useState<ServiceRequestComment | null>(
    null
  );
  const createServiveRequestComment = () => {
    setServiceRequestComment(null);
    toggleEditModal();
  };
  const editServiceRequestComment = (serviceRequestComment: ServiceRequestComment) => {
    setServiceRequestComment(serviceRequestComment);
    toggleEditModal();
  };

  return (
    <>
      <Wrapper>
        <Heading as="h2" color="primary">
          Service Notes
        </Heading>
      </Wrapper>
      <FlexRow justifyContent="flex-start">
        <Button variant="primary" action onClick={() => createServiveRequestComment()}>
          Add note
        </Button>
      </FlexRow>
      <ModalLayout
        toggle={toggleEditModal}
        title={`${serviceRequestComment ? "Edit" : "Add"} Service note`}
        isOpen={showEditModal}
      >
        <EditNote
          serviceId={serviceId}
          serviceRequestComment={serviceRequestComment}
          onEditFinish={toggleEditModal}
        />
      </ModalLayout>
      Most Recen
      {!serviceRequestComments?.length && (
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={4} align="center" noWrap>
                No data to show
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
      {serviceRequestComments?.map((item, index) => {
        const { note, insertedAt, accountId, account } = item;
        return (
          <Panel key={`serviceRequestComment_${index}`}>
            <Panel.Item>
              <Grid>
                <Column size={4}>By: {account?.data?.fullName ?? "Deleted account"}</Column>
                <Column size={4}>Date & Time: {formatDateTime(insertedAt)}</Column>
                <Column size={1}>
                  {currentUserId === accountId && (
                    <EditButton onClick={() => editServiceRequestComment(item)}>Edit</EditButton>
                  )}
                </Column>
              </Grid>
            </Panel.Item>
            <Panel.Item>{note}</Panel.Item>
          </Panel>
        );
      })}
    </>
  );
};
const Grid = styled("div")`
  display: flex;
`;

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
`;

const Wrapper = styled("div")`
  display: table;
  width: 100%;
  padding-bottom: 10px;
`;
export default ServiceRequestComments;
