import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import { AccountSearchSelect } from "components/AccountSearchSelect";
import Button from "components/Button";
import Form from "components/Form";
import InputDateRange from "components/InputDateRange";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { DATE_FORMAT } from "constants/date";
import { mapSelectValuesToOptions } from "utils/select";

import { PERFORMANCE_REPORT } from "./gql";
import { Schema, schema } from "./schema";

const memberTypeOptions = [
  { label: "Business", value: "BUSINESS" },
  { label: "Regular", value: "REGULAR" },
];

interface Props {
  toggle: () => void;
}

const PerformanceReportForm = ({ toggle }: Props): ReactElement => {
  const [performanceReport] = useMutation(PERFORMANCE_REPORT);
  const initialValues = {
    range: { startDate: null, endDate: null },
    memberType: null,
    memberId: null,
  };

  const handleSubmit = async (value: Schema) => {
    const { startDate, endDate } = value.range;
    const gte = startDate?.format(DATE_FORMAT);
    const lte = endDate?.format(DATE_FORMAT);
    const period = gte && lte ? { gte, lte } : {};
    try {
      const { data } = await performanceReport({
        variables: {
          filter: {
            period,
            memberType: { eq: value.memberType },
            memberId: { eq: value.memberId },
          },
        },
      });

      if (data?.exportPapaPerformanceReport?.data?.success) {
        toast.success("Export Papa Performance Report Success.");
        toggle();
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <InputDateRange id="range" name="range" />
          </Form.Group>

          <Form.Group>
            <Form.Label>Member Type</Form.Label>
            <Select
              aria-label="type"
              name="memberType"
              maxMenuHeight={200}
              defaultValue={
                mapSelectValuesToOptions(initialValues.memberType, memberTypeOptions) as any
              }
              options={memberTypeOptions as any}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Member</Form.Label>
            <AccountSearchSelect name="memberId" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={toggle}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default PerformanceReportForm;
