import { gql } from "@apollo/client";

export const EXPORT_PAYMENTS = gql`
  mutation exportPayments($filter: [PaymentFilter]) {
    exportPayments(filter: $filter) {
      data {
        success
      }
    }
  }
`;

export const GET_PAYMENT_COUNT = gql`
  query PaymentsCount(
    $filter: [PaymentFilter]
    $pagination: PaymentInput
    $sorting: PaymentSorting
  ) {
    payments(filter: $filter, pagination: $pagination, sorting: $sorting) {
      pagination {
        totalCount
      }
    }
  }
`;
