import { gql } from "@apollo/client";

export const GET_ACCOUNT_PLAN = gql`
  query getAccountPlan($id: ID!) {
    account(id: $id) {
      data {
        plan {
          data {
            hourlyRate
            id
            monthlyRate
          }
        }
      }
    }
  }
`;
