import { gql } from "@apollo/client";

const PAL_ONBOARDING_FRAGMENTS = gql`
  fragment PalOnboardingNameFragment on PalOnboarding {
    id
    providedFirstName
    providedLastName
  }
`;

export const PAL_ONBOARDINGS = gql`
  query PalOnboardings(
    $filter: [PalOnboardingFilter]
    $pagination: PaginationInput
    $sorting: PalOnboardingSorting
  ) {
    palOnboardings(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        ...PalOnboardingNameFragment
        id
        bgcStatus
        turnProfileUrl
        zipcode
        stage
        hasCompletedOrientationVideo
        vehicleInsuranceStatus
        insertedAt
        updatedAt
        account {
          data {
            emailVerified
            id
            email
            phoneNumber
          }
        }
      }
      pagination {
        limit
        page
        totalCount
        totalPages
        afterCursor
        beforeCursor
      }
    }
  }

  ${PAL_ONBOARDING_FRAGMENTS}
`;

export const PAL_ONBOARDING = gql`
  query PalOnboarding(
    $palOnboardingId: ID
    $pagination: PaginationInput
    $sorting: PalOnboardingActivitySorting
  ) {
    palOnboarding(id: $palOnboardingId) {
      data {
        ...PalOnboardingNameFragment
        id
        bgcStatus
        turnProfileUrl
        providedGender
        zipcode
        providedExpiryDate
        hasVehicle
        vehicleMake
        vehicleModel
        vehicleYear
        vehicleColor
        vehicleInsuranceStatus
        vehicleStatusConfirmedAt
        vehicleInsuranceImageUrl
        stage
        hasCompletedOrientationVideo
        account {
          data {
            id
            email
            emailVerified
            pal {
              data {
                id
                status
              }
            }
          }
        }
        activity(pagination: $pagination, sorting: $sorting) {
          pagination {
            afterCursor
            beforeCursor
            totalPages
            totalCount
            limit
            page
            totalCount
            totalPages
          }
          data {
            id
            note
            updatedAt
          }
        }
      }
    }
  }

  ${PAL_ONBOARDING_FRAGMENTS}
`;

export const PAL_ONBOARDINGS_SEARCH = gql`
  query palOnboardingSearch($search: String!, $page: Int!, $limit: Int!) {
    searchResult(index: "prospectpals", search: $search, size: $limit, page: $page) {
      data {
        ... on PalOnboarding {
          ...PalOnboardingNameFragment
          id
          bgcStatus
          zipcode
          stage
          hasCompletedOrientationVideo
          vehicleInsuranceStatus
          account {
            data {
              emailVerified
              id
              email
              phoneNumber
            }
          }
        }
      }
      pagination {
        totalPages
      }
    }
  }

  ${PAL_ONBOARDING_FRAGMENTS}
`;

export const PROMOTE_PROSPECT_PAL = gql`
  mutation promoteProspectPal($prospectPalId: ID!) {
    promoteProspectPal(prospectPalId: $prospectPalId) {
      data {
        id
        account {
          data {
            id
          }
        }
      }
    }
  }
`;

export const GET_WAITING_ROOM_TASKS = gql`
  query adminWaitingRoomTasks($palOnboardingId: ID!) {
    adminWaitingRoomTasks(palOnboardingId: $palOnboardingId) {
      data {
        name
        status
      }
    }
  }
`;
