import { useMutation } from "@apollo/client";
import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { GET_VISITS_LIST } from "components/DataTables/VisitsTable/gql";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";

import { START_VISIT } from "./gql";

interface Props {
  id: string;
  isOpen: boolean;
  toggle: () => void;
}

export const StartVisitModal: React.FC<Props> = ({ id, isOpen, toggle }) => {
  const [startVisit, { loading }] = useMutation(START_VISIT, {
    refetchQueries: [{ query: GET_VISITS_LIST }],
  });

  const onOk = async () => {
    try {
      const { data } = await startVisit({ variables: { id } });

      if (data?.startVisit?.data?.state === "STARTED") {
        toast.success("Visit was started");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <StyledIcon fontWeight="bold" size={23} />
          <TextContainer>
            <Text>Do you want to start the visit?</Text>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button disabled={loading} type="button" variant="primary" onClick={onOk}>
          {loading ? <SpinningIcon size={18} /> : "Ok"}
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 0 32px 0 32px;
}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
