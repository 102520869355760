import { useField, useFormikContext } from "formik";
import React from "react";
import { OptionTypeBase, ValueType } from "react-select/src/types";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Feedback from "components/Form/FormFeedback";
import Select from "components/Select";
import { useGetServiceRequestClosedReasonsQuery } from "generated/types";
import { nonNull } from "utils/nonNull";
import { fancyEnum } from "utils/strings/fancyEnum";

interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

const ServiceRequestClosedReasonSelect = () => {
  const [, { error }, helpers] = useField("closedReason");
  const { isSubmitting } = useFormikContext();
  const { data, loading } = useGetServiceRequestClosedReasonsQuery();
  const statusOptions = nonNull(data?.getServiceRequestClosedReasons?.data).map((value) => ({
    value: value,
    label: fancyEnum(value),
  }));

  if (loading) return <SpinningIcon size={18} />;

  const onChange = (value: ValueType<ChangeSelectEvent>) => {
    helpers.setValue(value);
  };

  return (
    <>
      <Select
        aria-label="closedReason"
        name="closedReason"
        placeholder="Closed reason"
        defaultValue={null}
        options={statusOptions}
        isDisabled={isSubmitting}
        onChange={onChange}
        isSearchable={false}
      />
      {!!error && (
        <Feedback isInvalid>
          {typeof error === "object" ? (Object.values(error)[0] as string) : error}
        </Feedback>
      )}
    </>
  );
};

export default ServiceRequestClosedReasonSelect;
