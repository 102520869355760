import React from "react";

import { DropdownActionMenu } from "components/ActionsMenu/DropdownActionMenu";

import Dropdown from "../Dropdown";

export enum DataTableMenuActions {
  NoAction,
  Create,
  Edit,
  Delete,
  ResetPassword,
  SendVerificationEmail,
  SetPalStatus,
  Approve,
  Reject,
  Update,
  ScheduleVisit,
  AddPapa,
  AddLocation,
  AddCreditCard,
  ChoosePlan,
  MakePal,
  SubmitTicket,
}

export interface DataTableMenuItem<T> {
  label: string;
  link?: string;
  action?: T;
  showCondition?: boolean;
}

interface Props<T> {
  items: DataTableMenuItem<T>[];
  actionHandler?: (action: T) => void;
  isListMenu?: boolean;
}

const DataTableDropdownMenu = <T,>({
  isListMenu = true,
  items,
  actionHandler = (action: T) => {},
}: Props<T>) => {
  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {items.map(({ label, link, action, showCondition = true }, index) => {
        if (showCondition && link)
          return (
            <Dropdown.Link key={index} to={link}>
              {label}
            </Dropdown.Link>
          );
        if (showCondition && action)
          return (
            <Dropdown.Item key={index} onClick={() => actionHandler(action)}>
              {label}
            </Dropdown.Item>
          );

        return null;
      })}
    </DropdownActionMenu>
  );
};

export default DataTableDropdownMenu;
