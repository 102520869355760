import { useApolloClient } from "@apollo/client";
import { useField } from "formik";
import first from "lodash/first";
import React from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import {
  PartnerFragment,
  SearchPartnersQuery,
  SearchPartnersQueryVariables,
  SortDirection,
  useSearchPartnersQuery,
} from "generated/types";
import { nonNullWithId } from "utils/nonNull";

import { SEARCH_PARTNER } from "./gql";
import UserSelect from "./index";

interface Props {
  placeholder?: string;
}

const PartnerSelect: React.FC<Props> = ({ placeholder = "Select Partner" }) => {
  const client = useApolloClient();
  const [{ value: partnerId }] = useField<string>("partnerId");

  const variables = preFilledPartnerOr10First(partnerId);
  const { data, loading } = useSearchPartnersQuery({ variables, fetchPolicy: "network-only" });

  async function handleLoadOptions(value: string) {
    const { data: dataOnSearch } = await client.query<
      SearchPartnersQuery,
      SearchPartnersQueryVariables
    >({
      query: SEARCH_PARTNER,
      variables: {
        filter: { name: { cont: value } },
      },
    });

    return fromPartnersToOptions(dataOnSearch?.partners?.data);
  }

  if (loading) {
    return <SpinningIcon size={20} />;
  }

  const { defaultOptions, selectedOption } = generateOptions(partnerId, data?.partners?.data);

  return (
    <UserSelect
      name="partnerId"
      value={selectedOption}
      defaultOptions={defaultOptions}
      onLoadOptions={handleLoadOptions}
      placeholder={placeholder}
      aria-label="partner"
      isClearable
    />
  );
};

export const preFilledPartnerOr10First = (partnerId?: string | null) => {
  if (partnerId) {
    return {
      filter: {
        id: { eq: partnerId },
      },
    };
  }

  return {
    pagination: {
      limit: 10,
      page: 1,
    },
    sorting: {
      name: SortDirection.Asc,
    },
  };
};

export const generateOptions = (
  partnerId?: string | null,
  partners?: (PartnerFragment | null | undefined)[] | null
) => {
  const partnersOptions = fromPartnersToOptions(partners);
  let selectedOption = null;
  let defaultOptions = partnersOptions;

  if (partnerId) {
    selectedOption = first(partnersOptions);
    defaultOptions = selectedOption ? [selectedOption] : [];
  }

  return { selectedOption, defaultOptions };
};

const fromPartnersToOptions = (partners?: (PartnerFragment | null | undefined)[] | null) =>
  nonNullWithId(partners).map((partner) => ({
    partnerId: partner.id,
    value: partner.id,
    label: partner.name ?? "",
  }));

export default PartnerSelect;
