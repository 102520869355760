import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";

import ConfirmModal from "components/Modal/ConfirmModal";
import { VisitState } from "generated/types";

import { REVIEW_TERMINATED_VISIT } from "./gql";

interface Props {
  visitId: string;
  isOpen: boolean;
  toggle: () => void;
}

const ReviewTerminatedVisitModal = ({ visitId, isOpen, toggle }: Props) => {
  const [reviewTerminatedVisit, { loading }] = useMutation(REVIEW_TERMINATED_VISIT);

  const onConfirm = async () => {
    try {
      const { data } = await reviewTerminatedVisit({ variables: { visitId } });

      if (data?.reviewTerminatedVisit?.data?.state === VisitState.Reviewed) {
        toast.success("Visit was changed to Reviewed");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ConfirmModal isOpen={isOpen} onCancel={toggle} onConfirm={onConfirm} loading={loading}>
      Do you want to change this visit to Reviewed?
    </ConfirmModal>
  );
};

export default ReviewTerminatedVisitModal;
