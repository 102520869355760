import { object, string } from "yup";

import { UpdatablePaymentInvoiceState } from "generated/types";

export interface Schema {
  state: UpdatablePaymentInvoiceState | null;
}

export const schema = object().shape({
  state: string().required("Please select a state"),
});
