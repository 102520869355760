import React from "react";

import Checkbox from "components/Checkbox";
import FormFeedback from "components/Form/FormFeedback";
import Radio from "components/Radio";
import SimpleSelect from "components/Select/Simple";

import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import FormTextArea from "./FormTextArea";

type Props = {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
};

const Form = ({ children, ...otherProps }: Props): JSX.Element => {
  return <form {...otherProps}>{children}</form>;
};

Form.Label = FormLabel;
Form.Input = FormInput;
Form.Check = Checkbox;
Form.Radio = Radio;
Form.TextArea = FormTextArea;
Form.Select = SimpleSelect;
Form.Feedback = FormFeedback;

export default Form;
