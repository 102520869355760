import React from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import EditBaseRateModal from "modals/Accounting/BaseRate/EditBaseRate";
import { BaseRate as Entity } from "pages/Accounting/accountingMocked";

type Props = {
  action: DataTableMenuActions;
  entity: Entity | null;
  onClose: () => void;
};

export const Actions: React.FC<Props> = ({ action, entity, onClose }) => {
  if (!entity?.id) return null;

  return (
    <>
      <EditBaseRateModal
        baseRate={entity}
        isOpen={action === DataTableMenuActions.Edit}
        onClose={onClose}
      />
    </>
  );
};
