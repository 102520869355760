import * as yup from "yup";

import { ServiceRequestFollowupAction, ServiceRequestFollowupReason } from "generated/types";

export const actionsWithReason = [
  ServiceRequestFollowupAction.CallMember,
  ServiceRequestFollowupAction.CallHealthPlan,
  ServiceRequestFollowupAction.CoordinateWithProvider,
  ServiceRequestFollowupAction.SchedulePalVisit,
];

export interface Schema {
  dueAt: string;
  note: string;
  action: ServiceRequestFollowupAction | null;
  serviceRequestId: string;
  actionText: string;
  assignedToId: string;
  reason: ServiceRequestFollowupReason | null;
}

export const formSchema = yup.object().shape({
  assignedToId: yup.string().nullable().required("Please select assignee!"),
  action: yup.string().nullable().required("Please select type!"),
  dueAt: yup.string().nullable().required("Please select due date!"),
  actionText: yup.string().when("action", {
    is: (action: ServiceRequestFollowupAction) => action === ServiceRequestFollowupAction.Other,
    then: yup.string().required("Please enter action text!"),
  }),
  reason: yup
    .string()
    .when("action", {
      is: (action: ServiceRequestFollowupAction) => actionsWithReason.includes(action),
      then: yup.string().required("Please select reason!"),
    })
    .nullable(),
});
