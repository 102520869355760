import { gql } from "@apollo/client";

export const START_VISIT = gql`
  mutation startVisit($id: ID!) {
    startVisit(id: $id) {
      data {
        id
        state
      }
    }
  }
`;
