import { ApolloError, OperationVariables, useApolloClient } from "@apollo/client";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ValueType } from "react-select/src/types";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import { LocationFormModal } from "components/LocationForm/LocationFormModal";
import LocationSearch from "components/LocationSearch";
import { ModalLayout } from "components/Modal/ModalLayout";
import QueryErrors from "components/QueryErrors";
import { OnlyRadio as Radio } from "components/Radio/OnlyRadio";
import Text from "components/Text";
import UserSelect, { ChangeSelectEvent } from "components/UserSelect";
import { Location, PapaDetailsQuery, PapaState, usePapaResourceBudgetQuery } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { CurrentBusinessPolicyContext } from "pages/ScheduleVisit/shared/CurrentBusinesPolicyContext";
import { PapaConcealed } from "types";
import { papaConcealed, papasConcealed } from "utils/fieldsPermissions/papaConcealed";

import { AddressEditForm } from "../../../Papas/Details/AddressEditForm/AddressEditForm";
import { SEARCH_PAPAS } from "../../gql";
import { Values } from "../../types";
import VirtualVisitCheckbox from "./VirtualVisitCheckbox";

type PapaChangeSelectEvent = {
  accountId?: string;
  accountType?: string;
} & ChangeSelectEvent;

interface MemberOption {
  accountId?: string;
  businessId?: string | null;
  value: string;
  label: string;
  accountType?: string;
  accountName?: string;
  status?: PapaState | null;
  virtualVisitsOnly: boolean;
}

const mapPapaToOption = (papa: PapaConcealed): MemberOption => ({
  accountId: papa?.accountId ?? "",
  accountType: papa?.account?.data?.type ?? "",
  businessId: papa?.businessId,
  value: papa?.id ?? "",
  label: papa.fullName ?? "Papa name",
  accountName: papa?.account?.data?.fullName ?? "",
  status: papa?.status,
  virtualVisitsOnly: papa?.virtualVisitsOnly || false,
});

enum VisitLocationType {
  Home,
  OtherLocation,
  noLocationSelected,
}

type Props = {
  isEditMode?: boolean;
  handleLocationChange?: (locationId: string) => Promise<any>;
  sectionNumber?: number;
  papaData?: PapaDetailsQuery;
  papaQueryError?: ApolloError;
  refetchPapaData: () => void;
};

const MemberDetails = ({
  isEditMode,
  handleLocationChange,
  sectionNumber,
  papaData,
  papaQueryError,
  refetchPapaData,
}: Props) => {
  const client = useApolloClient();
  const [, , idFieldHelpers] = useField("accountId");
  const [, , typeFieldHelpers] = useField("accountType");
  const [, , papaIdFieldHelpers] = useField("papaId");
  const [{ value: locationValue }, , papaLocationFieldHelper] = useField("location");
  const [, , businessIdFieldHelpers] = useField("businessId");
  const [, , isVirtualFieldHelpers] = useField("isVirtual");
  const [, , businessPolicyEndHelpers] = useField("businessPolicyEnd");
  const [, , maxMinutesPerVisitHelpers] = useField("maxMinutesPerVisit");
  const { values } = useFormikContext<Values>();
  const [selectedPapa, setSelectedPapa] = useState<MemberOption>();
  const [localHomeLocation, setHomeLocation] = useState<Location>();
  const [locationType, setLocationType] = useState<VisitLocationType>(VisitLocationType.Home);
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen: createLocationModalOpen, toggle: toggleCreateLocationModal } = useModalToggle();
  const { isOpen: editLocationModalOpen, toggle: toggleEditLocationModal } = useModalToggle();
  const { setCurrentBusinessPolicy, setResourceBudget } = useContext(CurrentBusinessPolicyContext);

  const { data: resourceBudgetData, error: resourceBudgetError } = usePapaResourceBudgetQuery({
    variables: { id: values.papaId },
  });

  const healthPlanCurrentBusinessPolicy = papaData?.papa?.data?.currentBusinessPolicy?.data;

  const caregiver = papaData?.papa?.data?.caregiver?.data;
  const employerCurrentBusinessPolicy = caregiver?.business?.data?.currentBusinessPolicy?.data;
  const homeLocation = papaData?.papa?.data?.homeLocation?.data;

  const currentBusinessPolicy = caregiver
    ? employerCurrentBusinessPolicy
    : healthPlanCurrentBusinessPolicy;

  const allowVirtualVisits = currentBusinessPolicy?.allowVirtualVisits ?? true;

  const allowInPersonVisits = currentBusinessPolicy?.allowInPersonVisits ?? true;

  const getPapas = useCallback(
    (filter: OperationVariables) => {
      return client.query({
        query: SEARCH_PAPAS,
        variables: {
          filter,
          sorting: {
            fullName: "ASC",
          },
        },
      });
    },
    [client]
  );

  useEffect(() => {
    const getSelectedPapa = async () => {
      setLoading(true);
      const { data } = await getPapas({ id: { in: values.papaId } });
      const papa = papaConcealed(data?.papas?.data?.[0]);

      if (papa) {
        if ([PapaState.Banned, PapaState.Suspended].includes(papa.status as PapaState)) {
          papaIdFieldHelpers.setValue("");

          setSelectedPapa(undefined);
          onSelect(undefined);

          toast.error(
            `You can't schedule visits for ${papa.fullName} because this papa is ${papa.status}`
          );
        } else {
          const option = mapPapaToOption(papa);

          setSelectedPapa(option);
          onSelect(option);
        }
      }

      setLoading(false);
    };

    if (values.papaId) {
      getSelectedPapa();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPapas, values.papaId]);

  useEffect(() => {
    homeLocation && setHomeLocation(homeLocation);

    if (!!locationValue?.id) {
      locationValue?.id === homeLocation?.id
        ? loadHomeLocation()
        : setLocationType(VisitLocationType.OtherLocation);
    }

    if (!!homeLocation && !localHomeLocation?.id && !locationValue?.id) {
      homeLocation && setHomeLocation(homeLocation);

      papaLocationFieldHelper.setValue({
        id: homeLocation!.id,
        address: homeLocation!.address,
        timezone: homeLocation!.timezone,
        supportOperatingHours: homeLocation!.supportOperatingHours,
      });

      handleLocationChange && homeLocation?.id && handleLocationChange(homeLocation?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeLocation, localHomeLocation]);

  useEffect(() => {
    if (currentBusinessPolicy) {
      setCurrentBusinessPolicy(currentBusinessPolicy!);
      // Note that allowInPersonVisits and allowVirtualVisits are determined by the member's business policy
      // virtualVisitOnly is specific to the member and may be set due to safety concerns
      if (!allowVirtualVisits) {
        isVirtualFieldHelpers.setValue(false);
      } else if (!allowInPersonVisits || selectedPapa?.virtualVisitsOnly) {
        isVirtualFieldHelpers.setValue(true);
      }

      businessPolicyEndHelpers.setValue(currentBusinessPolicy?.endsAt);
      maxMinutesPerVisitHelpers.setValue(currentBusinessPolicy?.maxMinutesPerVisit);
    }
    if (resourceBudgetData) {
      const resourceBudget = resourceBudgetData.papa?.data?.resourceBudget?.data;
      setResourceBudget(resourceBudget!);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceBudgetData, currentBusinessPolicy]);

  async function handleLoadOptions(value: string) {
    papaLocationFieldHelper.setValue({});
    setLocationType(VisitLocationType.noLocationSelected);

    const { data } = await getPapas({
      fullName: { cont: value },
    });

    if (data?.papas?.data) {
      const papas = papasConcealed(data.papas.data);
      return papas.map(mapPapaToOption);
    }

    return [];
  }

  const handleHomeLocationChange = async () => {
    await refetchPapaData();
    setLocationType(VisitLocationType.Home);
    papaLocationFieldHelper.setValue({});
    setHomeLocation(undefined);
  };

  const loadHomeLocation = () => {
    setLocationType(VisitLocationType.Home);

    papaLocationFieldHelper.setValue({
      id: homeLocation!.id,
      address: homeLocation!.address,
      timezone: homeLocation!.timezone,
      supportOperatingHours: homeLocation!.supportOperatingHours,
    });

    handleLocationChange && homeLocation?.id && handleLocationChange(homeLocation?.id);
  };

  const handleOtherLocationChange = () => {
    papaLocationFieldHelper.setValue({});
    setLocationType(VisitLocationType.OtherLocation);
  };

  const homeLocationText = (location: Location) => {
    return !!location?.id
      ? `${location?.address || ""} ${location?.address2 || ""} ${location?.city || ""}, ${
          location?.countryIso || ""
        }
      ${location?.zipcode || ""}`
      : "Home location not available please use another location";
  };

  const closeEditModal = () => {
    toggleEditLocationModal();
    handleHomeLocationChange();
  };

  const onSelect = (value: ValueType<PapaChangeSelectEvent> | undefined) => {
    if (!value) {
      businessIdFieldHelpers.setValue("");
      idFieldHelpers.setValue("");
      typeFieldHelpers.setValue(null);

      return;
    }

    const { accountId, accountType, businessId, virtualVisitsOnly } =
      value as PapaChangeSelectEvent;

    businessIdFieldHelpers.setValue(businessId);

    if (virtualVisitsOnly) {
      isVirtualFieldHelpers.setValue(true);
    }

    if (accountId || accountType) {
      idFieldHelpers.setValue(accountId);
      typeFieldHelpers.setValue(accountType);
    }
  };

  return (
    <Form.Panel number={sectionNumber} headerText="Member details">
      <ScheduleVisitLabel>Member name</ScheduleVisitLabel>

      <Form.Group>
        {loading ? (
          <SpinningIcon size={20} />
        ) : (
          <UserSelect
            name="papaId"
            defaultValue={selectedPapa}
            defaultOptions={selectedPapa ? [selectedPapa] : []}
            onLoadOptions={handleLoadOptions}
            placeholder="Enter with member's name"
            onSelect={onSelect}
            aria-label="member search"
            isDisabled={isEditMode}
          />
        )}
      </Form.Group>

      <QueryErrors error={papaQueryError || resourceBudgetError} />

      <ScheduleVisitLabel>Virtual Visit</ScheduleVisitLabel>

      <VirtualVisitCheckbox
        allowInPersonVisits={allowInPersonVisits}
        allowVirtualVisits={allowVirtualVisits}
        virtualVisitsOnly={!!selectedPapa?.virtualVisitsOnly}
      />

      <ScheduleVisitLabel>Visit location</ScheduleVisitLabel>

      <Form.Group controlId="location">
        <RadioButtonContainer>
          <Radio
            name="homeLocation"
            label="Use home address"
            checked={locationType === VisitLocationType.Home}
            onChange={handleHomeLocationChange}
          />
          {!papaData ? (
            <SpinningIcon size={20} />
          ) : (
            <LocationContainer>
              <LocationText>{homeLocationText(homeLocation!)}</LocationText>
              {locationType === VisitLocationType.Home && !!homeLocation?.id && (
                <EditHomeLocationButton
                  disabled={!values.papaId}
                  onClick={toggleEditLocationModal}
                  type="button"
                >
                  Edit Address
                </EditHomeLocationButton>
              )}
            </LocationContainer>
          )}
        </RadioButtonContainer>
        <RadioButtonContainer>
          <Radio
            name="location"
            label="Use another location"
            checked={locationType === VisitLocationType.OtherLocation}
            onChange={handleOtherLocationChange}
          />
          <LocationContainer>
            {locationType === VisitLocationType.OtherLocation && (
              <>
                <LocationSearch
                  name="location"
                  papaId={values.papaId}
                  handleLocationChange={handleLocationChange}
                />
                <AddLocationButton
                  disabled={!values.papaId}
                  onClick={toggleCreateLocationModal}
                  type="button"
                >
                  Add Location
                </AddLocationButton>
              </>
            )}
          </LocationContainer>
        </RadioButtonContainer>
      </Form.Group>

      <LocationFormModal
        isOpen={createLocationModalOpen}
        toggle={toggleCreateLocationModal}
        papaId={values?.papaId}
      />
      <ModalLayout toggle={closeEditModal} title="Edit Home Address" isOpen={editLocationModalOpen}>
        <AddressEditForm
          locationId={homeLocation?.id!}
          papaId={papaData?.papa?.data?.id!}
          accountId={papaData?.papa?.data?.accountId!}
          onEditFinish={closeEditModal}
        />
      </ModalLayout>
    </Form.Panel>
  );
};

const LocationContainer = styled("div")`
  display: flex;
  min-height: 42px;
  width: 485px;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
`;

const LocationText = styled(Text)`
  width: 300px;
`;

const RadioButtonContainer = styled("div")`
  padding: 10px 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const AddLocationButton = styled(Button)`
  height: 38px;
  min-width: 152px;
  display: flex;
  align-items: center;
`;

const EditHomeLocationButton = styled(Button)`
  height: 38px;
  min-width: 152px;
  display: flex;
  align-items: center;
`;

export default MemberDetails;
