import { gql } from "@apollo/client";

export const CREATE_SERVICE_REQUEST_COMMENT = gql`
  mutation createServiceRequestComment($input: ServiceRequestCommentInput!) {
    createServiceRequestComment(input: $input) {
      data {
        id
        serviceRequestId
        accountId
        note
        insertedAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_COMMENT = gql`
  mutation UpdateServiceRequestComment($id: ID!, $input: ServiceRequestCommentInput!) {
    updateServiceRequestComment(id: $id, input: $input) {
      data {
        id
        serviceRequestId
        accountId
        note
        insertedAt
        updatedAt
      }
    }
  }
`;
