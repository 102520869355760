import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import PapasTable, { GET_PAPAS } from "components/DataTables/PapasTable";
import { usePapasSorting } from "components/DataTables/PapasTable/usePapasSorting";
import { PapaListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";

interface Data {
  papas: PapaListResult;
}

const Papas: React.FC = () => {
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { id } = useParams<{ id: string }>();
  const [sorting, setSorting] = usePapasSorting();

  const { data, loading, error, refetch } = useQuery<Data>(GET_PAPAS, {
    variables: {
      filter: {
        account: {
          idEq: id,
        },
      },
      sorting,
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const handleRefetch = async () => {
    await refetch();
  };

  const papas = papasConcealed(data?.papas.data);

  return (
    <>
      <Helmet>
        <title>Member&apos;s Papas - Papa Admin</title>
      </Helmet>

      <PapasTable
        data={papas}
        loading={loading}
        pagination={data?.papas?.pagination}
        error={error}
        sorting={sorting}
        onSortChange={setSorting}
        onRefetch={handleRefetch}
      />
    </>
  );
};

export default Papas;
