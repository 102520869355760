import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import { SortDirection, VisitTemplate } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";

import { ProgramTemplateForm } from "../../ProgramTemplateForm/ProgramTemplateForm";
import { VISIT_TEMPLATES } from "./gql";

const VisitTemplates = () => {
  const { page, limit } = usePageInfo();
  const { programId } = useParams<{ programId: string }>();
  const [sorting, setSorting] = useState<{ position: SortDirection }>({
    position: SortDirection.Asc,
  });
  const { isVisitProgramAdmin } = useCurrentAccountRole();
  const { data, loading, error } = useQuery(VISIT_TEMPLATES, {
    variables: { id: programId, pagination: { page, limit }, sorting },
  });
  const { data: visitTemplates, pagination } = data?.visitProgram?.data?.visitTemplates ?? {};

  const { isOpen: isEditTemplateOpen, toggle: toggleTemplateModal } = useModalToggle();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const onOpenModal = (id: string | null) => {
    setSelectedTemplateId(id);
    toggleTemplateModal();
  };

  const onModalClose = () => {
    setSelectedTemplateId(null);
    toggleTemplateModal();
  };

  return (
    <>
      <SmartTable<VisitTemplate>
        keyField="id"
        localStorageKey="visitTemplates"
        loading={loading}
        error={error}
        data={visitTemplates}
        pagination={pagination}
        columns={[
          {
            header: { title: "Template Name" },
            dataField: "name",
          },
          {
            header: {
              title: "Order",
              sort: sorting.position,
              onChange: (direction) => setSorting({ position: direction }),
            },
            dataField: "position",
          },
          {
            header: { title: "" },
            dataField: "actions",
            cellProps: {
              align: "right",
            },
            formatter({ id }) {
              if (!isVisitProgramAdmin) return null;
              return (
                <EditButton onClick={() => onOpenModal(id as string)} variant="secondary">
                  Edit
                </EditButton>
              );
            },
          },
        ]}
      />

      {selectedTemplateId && (
        <ModalLayout toggle={onModalClose} title="Edit template" isOpen={isEditTemplateOpen}>
          <ProgramTemplateForm id={selectedTemplateId} onFormComplete={toggleTemplateModal} />
        </ModalLayout>
      )}
    </>
  );
};

const EditButton = styled(Button)`
  margin-left: auto;
`;

export default VisitTemplates;
