import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import omit from "lodash/omit";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { FormFooter, StyledForm } from "components/EditBio/styles";
import Form from "components/Form";
import InputPhone from "components/InputPhone";
import { Loader } from "components/Loader";
import { ModalContainer } from "components/Modal/Modal.styles";
import { OrganizationSelect } from "components/OrganizationSelect/OrganizationSelect";
import QueryErrors from "components/QueryErrors";
import Select from "components/Select";
import { businessTypeOptions } from "constants/businessType";
import { employerEligibilityMethodOptions } from "constants/employerEligibilityMethod";
import {
  AccountSingleResult,
  AccountType,
  BusinessInput,
  BusinessSingleResult,
  BusinessType,
  EligibilityMethod,
} from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { generatePassword } from "utils/generate-password";
import { mapSelectValuesToOptions } from "utils/select";
import { formatFullName } from "utils/strings/formatFullName";

import CustomVisitLeadHoursOptions from "./CustomVisitLeadHoursOptions";
import {
  CREATE_BUSINESS,
  CREATE_BUSINESS_ACCOUNT,
  GET_ACCOUNT_FROM_BUSINESS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_ACCOUNT,
} from "./gql";
import S3FolderInput from "./s3FolderInput";
import { FormValues, schema } from "./schema";

interface Props {
  businessId?: string | null;
  closeModal: () => void;
  onSubmitFinish: () => void;
}

export const BusinessForm: React.FC<Props> = ({ businessId, closeModal, onSubmitFinish }) => {
  const { singularEntityName, capitalized } = useCallItClientProgramOrBusiness();

  const { isSupervisor, isSuperAdmin, isEngineeringAdmin, isClientAdmin } = useCurrentAccountRole();
  const {
    data,
    loading: accountLoading,
    error,
  } = useQuery<{ business: BusinessSingleResult }>(GET_ACCOUNT_FROM_BUSINESS, {
    fetchPolicy: "network-only",
    variables: { businessId },
    skip: !businessId,
  });

  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");
  const clientSearchFieldLabel = clientCopyUpdateEnabled ? "Client" : "Organization";

  const isEditing = Boolean(businessId);
  const [createAccountMutation] = useMutation<{ createAccount: AccountSingleResult }>(
    CREATE_BUSINESS_ACCOUNT
  );
  const [updateAccountMutation] = useMutation<{ updateAccount: AccountSingleResult }>(
    UPDATE_BUSINESS_ACCOUNT
  );

  const [createBusinessMutation] = useMutation<
    { createBusiness: BusinessSingleResult },
    { input: BusinessInput }
  >(CREATE_BUSINESS);
  const [updateBusinessMutation] = useMutation<
    { updateBusiness: BusinessSingleResult },
    { businessId: string; input: BusinessInput }
  >(UPDATE_BUSINESS);

  const handleSubmit = async (values: FormValues) => {
    const accountId = data?.business?.data?.accountId;
    const {
      email,
      type,
      fullName: name,
      inboundMemberSupportNumber,
      eligibleLifeS3Folder,
      eligibleEmployeeS3Folder,
      eligibilityMethod,
      useCustomVisitLeadHours,
      customVisitLeadHours,
    } = values;

    const valuesToOmit = [
      "eligibleLifeS3Folder",
      "eligibleEmployeeS3Folder",
      "eligibilityMethod",
      "inboundMemberSupportNumber",
      "type",
      "customVisitLeadHours",
      "useCustomVisitLeadHours",
    ];

    const businessInput = {
      email,
      name,
      inboundMemberSupportNumber,
      eligibleLifeS3Folder,
      eligibleEmployeeS3Folder,
      ...(eligibilityMethod && {
        eligibilityMethod: eligibilityMethod as EligibilityMethod,
      }),
      type: type as BusinessType,
      useCustomVisitLeadHours,
      customVisitLeadHours,
    };

    try {
      if (isEditing && businessId && accountId) {
        await updateAccountMutation({
          variables: {
            accountId,
            input: {
              ...omit(values, valuesToOmit),
              fullName: formatFullName(values.fullName),
            },
          },
        });

        const { data: businessData } = await updateBusinessMutation({
          variables: {
            businessId,
            input: businessInput,
          },
        });

        if (businessData?.updateBusiness?.data) {
          toast.success(`${capitalized.singularEntityName} account was edited with success!`);
          onSubmitFinish();
          closeModal();
        } else {
          throw new Error(`It was not possible to update the ${singularEntityName}`);
        }
      } else {
        const { data: accountData } = await createAccountMutation({
          variables: {
            input: {
              ...omit(values, valuesToOmit),
              type: AccountType.Business,
              fullName: formatFullName(values.fullName),
              password: generatePassword(20),
            },
          },
        });

        const { data: businessData } = await createBusinessMutation({
          variables: {
            input: {
              ...businessInput,
              accountId: accountData?.createAccount?.data?.id,
            },
          },
        });

        if (businessData?.createBusiness?.data) {
          toast.success(`${capitalized.singularEntityName} account was created with success!`);
          onSubmitFinish();
          closeModal();
        } else {
          throw new Error(`It was not possible to create the ${singularEntityName}`);
        }
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const account = data?.business?.data?.account?.data;

  const initialValues: FormValues = {
    fullName: account?.fullName || "",
    email: account?.email || "",
    inboundMemberSupportNumber: data?.business?.data?.inboundMemberSupportNumber || "",
    type: data?.business?.data?.type || "",
    eligibleLifeS3Folder: data?.business?.data?.eligibleLifeS3Folder || "",
    eligibleEmployeeS3Folder: data?.business?.data?.eligibleEmployeeS3Folder || "",
    eligibilityMethod: data?.business?.data?.eligibilityMethod || "",
    about: account?.about || "",
    organizationId: account?.organization?.data?.id || "",
    backupFullName: account?.backupFullName || "",
    backupPhoneNumber: account?.backupPhoneNumber || "",
    requiresDrugTest: account?.requiresDrugTest || false,
    requiresSignature: account?.requiresSignature || false,
    testAccount: account?.testAccount || false,
    permissions: {
      admin: account?.permissions?.admin || false,
    },
    useCustomVisitLeadHours: data?.business?.data?.useCustomVisitLeadHours ?? false,
    customVisitLeadHours: data?.business?.data?.customVisitLeadHours ?? 72,
  };

  if (accountLoading) return <Loader />;

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <StyledForm>
        <ModalContainer>
          <QueryErrors error={error} />

          <Form.Group>
            <Form.Label htmlFor="business-full-name">Full name</Form.Label>
            <Form.Input id="business-full-name" name="fullName" />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="business-email">Email</Form.Label>
            <Form.Input id="business-email" name="email" />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="business-inbound-member-support-number">
              Papa's Dedicated Number
            </Form.Label>
            <InputPhone
              aria-label="Papa's Dedicated Number"
              id="business-inbound-member-support-number"
              name="inboundMemberSupportNumber"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label id="business-type">{capitalized.singularEntityName} Type</Form.Label>
            <Select
              name="type"
              aria-labelledby="business-type"
              placeholder="Select an Option"
              defaultValue={businessTypeOptions[0]}
              options={businessTypeOptions}
            />
          </Form.Group>

          <S3FolderInput />

          <Form.Group>
            <Form.Label>Employer Eligibility Method</Form.Label>
            <Select
              name="eligibilityMethod"
              aria-label="eligibility method"
              placeholder="Select an Option"
              defaultValue={
                initialValues.eligibilityMethod
                  ? mapSelectValuesToOptions(
                      initialValues.eligibilityMethod,
                      employerEligibilityMethodOptions
                    )
                  : employerEligibilityMethodOptions[0]
              }
              options={
                initialValues.eligibilityMethod !== ""
                  ? employerEligibilityMethodOptions.slice(1)
                  : employerEligibilityMethodOptions
              }
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="business-about">About</Form.Label>
            <Form.TextArea id="business-about" name="about" />
          </Form.Group>

          <Form.Group>
            <Form.Label>{clientSearchFieldLabel}</Form.Label>
            <OrganizationSelect name="organizationId" />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="business-backup-name">Backup Full name</Form.Label>
            <Form.Input id="business-backup-name" name="backupFullName" />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="business-backup-phone">Backup Phone number</Form.Label>
            <InputPhone id="business-backup-phone" name="backupPhoneNumber" />
          </Form.Group>

          {(isSupervisor || isSuperAdmin || isEngineeringAdmin || isClientAdmin) && (
            <>
              <CustomVisitLeadHoursOptions />
              <Form.Group>
                <Form.Check
                  label="Is admin"
                  aria-label="Is admin"
                  controlId="isAdmin"
                  name="permissions.admin"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  label="Requires signature"
                  aria-label="Requires signature"
                  controlId="requiresSignature"
                  name="requiresSignature"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  label="Requires drug tested pals"
                  aria-label="Requires drug tested pals"
                  controlId="requiresDrugTest"
                  name="requiresDrugTest"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  label="Is test account"
                  aria-label="Is test account"
                  controlId="testAccount"
                  name="testAccount"
                />
              </Form.Group>
            </>
          )}
        </ModalContainer>

        <FormFooter>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </FormFooter>
      </StyledForm>
    </Formik>
  );
};
