import { useQuery } from "@apollo/client";
import React from "react";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { vehicleTypeOptions } from "constants/vehicle";
import { AccountSingleResult } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { mapValueToLabel } from "utils/helpers/formatters";

import { GET_MEMBER_VEHICLE } from "../gql";
import { VehicleEditForm } from "./VehicleEdit/VehicleEditForm";

const Vehicle = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<{
    account?: AccountSingleResult | null;
  }>(GET_MEMBER_VEHICLE, {
    variables: { id },
  });

  const { isOpen, toggle: toggleModal } = useModalToggle();

  const {
    id: palId,
    vehicle,
    vehicleColor,
    vehicleModel,
    vehicleYear,
  } = data?.account?.data?.pal?.data ?? {};

  const editClickHandle = () => {
    if (palId) {
      toggleModal();

      return;
    }

    toast.error("You don't have data about your pal's vehicle");
  };

  const vehicleData = {
    vehicle: vehicle ?? undefined,
    vehicleColor: vehicleColor ?? "",
    vehicleModel: vehicleModel ?? "",
    vehicleYear: vehicleYear ?? "",
  };

  return (
    <>
      <Helmet>
        <title>Member Vehicle - Papa Admin</title>
      </Helmet>

      <QueryErrors error={error} />

      <Wrapper>
        <Panel title="Vehicle" onEditClick={editClickHandle}>
          {loading ? (
            <ContentLoader
              speed={2}
              width="60%"
              height={50}
              backgroundColor="#f3f3f3"
              foregroundColor="#e6e6e6"
            >
              <rect x="0" y="0" rx="3" ry="3" width="48%" height="10" />
              <rect x="52%" y="0" rx="3" ry="3" width="48%" height="10" />
              <rect x="0" y="20" rx="3" ry="3" width="35%" height="10" />
              <rect x="0" y="40" rx="3" ry="3" width="35%" height="10" />
            </ContentLoader>
          ) : (
            <>
              <Text bold>{vehicleModel}</Text>
              <Text color="muted">{mapValueToLabel(vehicleTypeOptions, vehicle)}</Text>
              <Text color="muted">{vehicleYear}</Text>
              <Text color="muted">{vehicleColor}</Text>
            </>
          )}
        </Panel>
      </Wrapper>

      <ModalLayout title="Vehicle" isOpen={isOpen} toggle={toggleModal}>
        <VehicleEditForm palId={palId || ""} vehicleData={vehicleData} onEditFinish={toggleModal} />
      </ModalLayout>
    </>
  );
};

const Wrapper = styled("div")`
  max-width: 50%;
`;

export default Vehicle;
