import { ApolloError } from "@apollo/client";
import { createContext } from "react";

import { CurrentAccountData } from "utils/gql/current-account";

interface CurrentUserContextValue {
  data?: CurrentAccountData;
  loading?: boolean;
  error?: ApolloError;
}

export const CurrentUserContext = createContext<CurrentUserContextValue>({});
