import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import CareConciergeAssignSelect from "components/ServiceRequest/AssignSelect";
import CareConciergeNeedSelect from "components/ServiceRequest/NeedSelect";
import CareConciergeTypeSelect from "components/ServiceRequest/TypeSelect";
import { ServiceRequestOrigin, ServiceRequestStatus } from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { usePageInfo } from "hooks/usePageInfo";
import { GET_PAPA_REQUESTS } from "pages/Papas/Details/CareConcierge/List/gql";

import { CREATE_SERVICEREQUEST } from "./gql";
import { Schema, formSchema } from "./schema";

interface Props {
  papaId: string;
  isOpen: boolean;
  toggle: () => void;
  onCreateFinish?: () => void;
}

export const CreateServiceRequestModal = ({ papaId, isOpen, toggle }: Props): ReactElement => {
  const { page, limit } = usePageInfo();
  const userAccountId = useCurrentAccount()?.id;
  const { isCareConciergeSpecialist } = useCurrentAccountRole();
  const [otherSelected, setOther] = useState(false);
  const [schema, setSchema] = useState(() => formSchema(otherSelected));
  const [servicerequestMutation] = useMutation(CREATE_SERVICEREQUEST, {
    refetchQueries: [
      {
        query: GET_PAPA_REQUESTS,
        variables: {
          filter: {
            papaId: {
              eq: papaId,
            },
            status: {
              in: [
                ServiceRequestStatus.New,
                ServiceRequestStatus.InProgress,
                ServiceRequestStatus.OnHold,
              ],
            },
          },
          pagination: { page, limit },
        },
      },
    ],
  });
  const initialValues: Schema = {
    assignedToId: " ",
    needId: "",
    typeId: "",
    description: "",
    needText: "",
  };

  useEffect(() => {
    setSchema(formSchema(otherSelected));
  }, [otherSelected]);

  const handleSubmit = async ({ assignedToId, ...formData }: Schema) => {
    try {
      const { data } = await servicerequestMutation({
        variables: {
          input: {
            status: ServiceRequestStatus.New,
            origin: ServiceRequestOrigin.ManualAdmin,
            papaId,
            assignedToId: assignedToId === " " ? null : assignedToId,
            accountId: userAccountId,
            ...formData,
          },
        },
      });
      if (data?.createServiceRequest?.data?.account?.data?.id) {
        toast.success("Create service request successful!");
        clodeModal();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  const selectOther = (value: boolean) => {
    setOther(value);
  };
  const clodeModal = () => {
    setOther(false);
    toggle();
  };

  return (
    <ModalLayout toggle={clodeModal} title="Create Service Request" isOpen={isOpen}>
      <Formik<Schema>
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label required>Service Request Type</Form.Label>
                <CareConciergeTypeSelect
                  name="typeId"
                  aria-label="typeId"
                  initialValue={initialValues.typeId}
                  placeholder="Select Type"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label required>Need</Form.Label>
                <CareConciergeNeedSelect
                  name="needId"
                  aria-label="needId"
                  selectOther={selectOther}
                  initialValue={initialValues.needId}
                  typeId={values.typeId}
                  placeholder="Select Need"
                />
              </Form.Group>
              {otherSelected && (
                <Form.Group>
                  <Form.Label required>Other</Form.Label>
                  <Form.Input name="needText" aria-label="needText" />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label required>Description</Form.Label>
                <Form.TextArea
                  aria-label="description"
                  name="description"
                  placeholder="Enter description"
                />
              </Form.Group>
              {isCareConciergeSpecialist && (
                <Form.Group>
                  <Form.Label>Assign To</Form.Label>
                  <CareConciergeAssignSelect name="assignedToId" placeholder="Select User" />
                </Form.Group>
              )}
            </ModalContainer>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={clodeModal}>
                Cancel
              </Button>
              <Form.SubmitButton isSubmitting={isSubmitting}>Save</Form.SubmitButton>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};
