import React from "react";
import styled from "styled-components";

import EditButton from "components/EditButton";
import Heading from "components/Heading";

interface Props {
  title: string;
  toggleModal: () => void;
}

const HeadingEditable: React.FC<Props> = ({ title, toggleModal }) => (
  <Wrapper>
    <Heading as="h2" color="primary">
      {title}
    </Heading>
    <EditButton onClick={toggleModal}>Edit</EditButton>
  </Wrapper>
);

export default HeadingEditable;

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;
