import { DataTableMenuItem } from "components/DataTableDropdownMenu";
import { PaymentInvoice } from "generated/types";

export enum PaymentInvoiceMenuActions {
  noAction,
  changeStatus,
}

export const menuItems = (
  { id }: PaymentInvoice,
  programsOrBusinesses: string
): DataTableMenuItem<PaymentInvoiceMenuActions>[] => {
  return [
    {
      label: "View payment invoice",
      link: `/payments/${programsOrBusinesses}-payment-invoices/${id}`,
    },
    { label: "Change payment invoice status", action: PaymentInvoiceMenuActions.changeStatus },
  ];
};
