import React, { HTMLAttributes, useContext } from "react";
import styled from "styled-components";

import FormContext from "./FormContext";

interface Props extends HTMLAttributes<HTMLLabelElement> {
  htmlFor?: string;
  srOnly?: boolean;
  required?: boolean;
}

const StyledLabel = styled("label")<Props>`
  display: block;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.text.muted};
  margin-bottom: 5px;
`;

const RequiredMark = styled.span`
  color: ${({ theme }) => theme.variants.danger};
`;

const FormLabel: React.FC<Props> = (props: Props) => {
  const { controlId } = useContext(FormContext);
  const { htmlFor, srOnly, children, required, ...rest } = props;

  return (
    <StyledLabel {...rest} htmlFor={htmlFor || controlId}>
      {required && <RequiredMark>* </RequiredMark>}
      {children}
    </StyledLabel>
  );
};

export default FormLabel;
