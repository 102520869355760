import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const TableLoadingCell = () => {
  return (
    <StyledCell>
      <ContentLoader
        speed={2}
        width="100%"
        height={10}
        backgroundColor="#f3f3f3"
        foregroundColor="#c0c0c0"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="10" />
      </ContentLoader>
    </StyledCell>
  );
};

const StyledCell = styled("td")`
  background: ${({ theme }) => theme.background.panelContent};
  padding: 0.75rem 0.938rem;

  &:first-of-type {
    border-radius: ${({ theme }) => `${theme.borderRadius} 0 0 ${theme.borderRadius}`};
  }
  &:last-of-type {
    border-radius: ${({ theme }) => `0 ${theme.borderRadius} ${theme.borderRadius} 0`};
  }
`;

export default TableLoadingCell;
