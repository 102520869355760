import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: white;
  height: 100vh;
  align-items: center;
`;

export const LoginImage = styled.img`
  width: 100%;
  overflow: hidden;
`;

export const PapaLogo = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  max-width: 85px;
  overflow: hidden;
  z-index: 1;
`;

export const ImageContainer = styled.div`
  display: flex;
  background-color: #1140c0;
  flex: 2;
  overflow: hidden;
  height: 100vh;
  align-items: center;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 3;
  height: 100vh;
`;

export const LoginWidget = styled.div`
  #okta-sign-in {
    border: none !important;
  }
`;

export const LoginDisclaimer = styled.p`
  color: red;
  margin-top: 20px;
  width: 50%;
  text-align: justify;
`;
