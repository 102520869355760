import { useState } from "react";

import { VisitState } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";
import { PillData } from "types/search";

export const useUrlSearchFilters = (prevFilters: Props) => {
  const { papaId, visitId } = useUrlQuery() as {
    papaId?: string;
    visitId?: string;
  };

  let initialPills: PillData[] = [];

  if (papaId) {
    const [name, id] = papaId.split(":");
    initialPills = [{ id, name, papaId: id }];
  }
  if (visitId) {
    const id = visitId;
    initialPills = [{ id, name: id, visitId: id }];
  }

  const [pills, setPills] = useState<PillData[]>(initialPills);

  return {
    ...(visitId
      ? {
          filter: { id: { eq: initialPills[0].id } },
        }
      : {}),
    ...(papaId
      ? {
          filter: [{ papa: { idEq: initialPills[0].id }, ...prevFilters }],
        }
      : {}),
    ...(!papaId && !visitId ? { filter: prevFilters } : {}),
    pills,
    setPills,
  };
};

type Props = {
  state?: {
    in: VisitState[];
  };
  scheduledFor?: {
    lt?: string;
    gte?: string;
  };
  isVirtual?:
    | {
        eq: boolean;
        ne?: undefined;
      }
    | {
        ne: boolean;
        eq?: undefined;
      };
};
