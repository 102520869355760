import { gql } from "@apollo/client";

export const CREATE_PAPA = gql`
  mutation createPapa($input: PapaInput!) {
    createPapa(input: $input) {
      data {
        id
      }
    }
  }
`;

export const CHECK_PALS_IF_BANNED = gql`
  query checkPalsIfBanned($palIds: [UUID!]) {
    pals(filter: { id: { in: $palIds } }) {
      data {
        id
        fullName
        status
      }
    }
  }
`;
