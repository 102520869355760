import { useQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import Text from "components/Text";
import { Keyword, KeywordType } from "types/keywords";

import { GET_MEMBER_KEYWORDS } from "./gql";

export type SelectedKeywords = { [key: string]: string };

interface Props {
  selectedKeywords: SelectedKeywords;
  setKeywords: (keywords: SelectedKeywords) => void;
  rating: number;
}

export const MemberKeywords: React.FC<Props> = ({ selectedKeywords, setKeywords, rating }) => {
  const [filteredKeywords, setFilteredKeywords] = useState<Keyword[]>([]);
  const { data: keywordsData } = useQuery(GET_MEMBER_KEYWORDS);

  const keywords: Keyword[] = useMemo(
    () => keywordsData?.visitRatingKeywords?.data || [],
    [keywordsData]
  );

  const onSetKeywords = (id: string) => {
    const updatedKeywords = { ...selectedKeywords };
    const isSelected = updatedKeywords[id];

    if (isSelected) {
      delete updatedKeywords[id];
    } else {
      const foundKeyword = keywords.find((keyword) => keyword.id === id);
      updatedKeywords[id] = foundKeyword?.keyword as string;
    }

    setKeywords(updatedKeywords);
  };

  useEffect(() => {
    if (!keywords.length) {
      return;
    }

    const filtered = keywords.filter(({ type }) => {
      if (rating <= 2) {
        return type === KeywordType.negative;
      }

      if (rating === 3) {
        return type === KeywordType.neutral;
      }

      return type === KeywordType.positive;
    });

    setKeywords({});
    setFilteredKeywords(filtered);
  }, [rating, keywords, setKeywords, setFilteredKeywords]);

  return (
    <>
      <Text size="large">My member was...</Text>

      <KeyWordsContainer>
        {filteredKeywords.map(({ keyword, id }) => {
          return (
            <KeyWord
              key={id}
              active={!!selectedKeywords[id]}
              onClick={() => onSetKeywords(id)}
              type="button"
            >
              <Text size="large">{keyword}</Text>
            </KeyWord>
          );
        })}
      </KeyWordsContainer>
    </>
  );
};

const KeyWordsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 10px;
  margin-top: 15px;
`;

const KeyWord = styled.button<{ active: boolean }>`
  padding: 6px 15px;
  border-radius: 17px;
  outline: none;
  border: none;
  transition: 0.2s all ease;
  color: ${({ active, theme }) => (active ? "#fff" : theme.variants.text)};
  background: ${({ active, theme }) => (active ? theme.variants.primary : "#F3F3F3")};
  cursor: pointer;
`;
