import { useQuery } from "@apollo/client";
import { ConciergeBell } from "@styled-icons/fa-solid/ConciergeBell";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

import ServiceRequestActionMenu from "components/ActionsMenu/ServiceRequestActionMenu";
import Button from "components/Button";
import { ItemsCount } from "components/DataTables/VisitsTable/ItemsCount";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import { useRowsSelectList } from "components/SmartTable/useRowsSelectList";
import { ServiceType } from "constants/service-requests";
import {
  ServiceRequest,
  ServiceRequestListResult,
  ServiceRequestPriority,
  ServiceRequestSorting,
  SortDirection,
} from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { ServiceRequestsFilterModal } from "modals/ServiceRequestFiltersModal";
import { countFilters, serviceRequestsFiltersMaker } from "utils/serviceRequestsFilters";

import { GET_SERVICEREQUESTS } from "./gql";
import { useServicesTableColumns } from "./useServicesTableColumns";

interface Data {
  serviceRequests: ServiceRequestListResult;
}
type Props = {
  type: ServiceType;
};
const Unassigned = ({ type }: Props) => {
  useTopBarOptions({
    title: "Unassigned Requests",
    icon: ConciergeBell,
    breadcrumbs: ["Care Concierge"],
  });

  const location = useLocation();
  const { page, limit, ...servicerequestsFilters } = usePageInfo();
  const countFilter = countFilters(servicerequestsFilters);

  const serviceRequestsFilter = serviceRequestsFiltersMaker({ ...servicerequestsFilters });
  const paysForCareConciergeFilter = {
    assignedToId: { eq: null },
    paysForCareConcierge: true,
  };
  const criticalFilter = {
    assignedToId: { eq: null },
    priority: { eq: ServiceRequestPriority.Critical },
  };
  const filter = [
    { ...paysForCareConciergeFilter, ...serviceRequestsFilter },
    { ...criticalFilter, ...serviceRequestsFilter },
  ];

  const [sorting, setSorting] = useState<ServiceRequestSorting>({
    priority: SortDirection.Asc,
  });
  const { rowsSelected, onRowSelect, onAllRowsSelect } = useRowsSelectList();
  const { columns } = useServicesTableColumns({
    type,
    sorting,
    onSortChange: setSorting,
  });
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const { data, loading, error, refetch } = useQuery<Data>(GET_SERVICEREQUESTS, {
    variables: {
      filter,
      pagination: { page, limit },
      sorting,
    },
  });
  const servicerequests = data?.serviceRequests;

  const handleFiltersChange = () => {
    toggleFiltersModal();
  };

  useEffect(() => {
    const handleRefetch = async () => {
      await refetch();
    };
    if (location.pathname === "/careconcierge-unassigned") {
      handleRefetch();
    }
  }, [location.pathname, refetch]);

  return (
    <div>
      <Helmet>
        <title>Care Concierge - Papa Admin</title>
      </Helmet>
      <FlexRow justifyContent="flex-end">
        <ServiceRequestActionMenu
          type={type}
          serviceRequestIds={rowsSelected}
          onRefetch={refetch}
        />
        <Button type="button" onClick={toggleFiltersModal} style={{ marginLeft: "1.25rem" }}>
          <span>Filter</span>
          {countFilter > 0 && <span>({countFilter})</span>}
        </Button>
      </FlexRow>
      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Filter"
        isOpen={isFiltersModalOpen}
      >
        <ServiceRequestsFilterModal type={type} onSubmit={handleFiltersChange} />
      </ModalLayout>

      <ItemsCount totalCount={data?.serviceRequests?.pagination?.totalCount} />
      <SmartTable<ServiceRequest>
        keyField="id"
        pagination={data?.serviceRequests?.pagination}
        data={servicerequests?.data}
        loading={loading}
        columns={columns}
        error={error}
        onRowSelect={onRowSelect}
        onAllRowsSelect={onAllRowsSelect}
        rowsSelected={rowsSelected}
      />
    </div>
  );
};
export default Unassigned;
