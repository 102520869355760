import { useState } from "react";

import { useUrlQuery } from "hooks/useUrlQuery";
import { PillData } from "types/search";

export const usePapaSearch = (extraFilter?: { [key: string]: any }) => {
  const { papaId } = useUrlQuery() as { papaId: string };
  const papaData = papaId?.split(":");
  const initialPills = papaData?.length ? [{ id: papaData[1], name: papaData[0] }] : [];
  const [pills, setPills] = useState<PillData[]>(initialPills);

  return {
    filter: papaId ? { papaId: { eq: papaId.split(",") }, ...extraFilter } : extraFilter,
    pills,
    setPills,
  };
};
