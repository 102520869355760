import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Button } from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import Heading from "components/Heading";
import Panel from "components/Panel";
import Text from "components/Text";
import { PalSingleResult } from "generated/types";
import { PalVaccineRejectModal } from "modals/PalVaccinationReject";
import { formatDateTime } from "utils/helpers/formatters";

import { GET_PAL_OVERVIEW, VACCINE_APPROVE } from "../gql";

type ColumnProps = {
  size?: number;
};
const VaccineInfo = () => {
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const { id } = useParams<{ id: string }>();
  const { data, loading, refetch } = useQuery<{ pal: PalSingleResult }>(GET_PAL_OVERVIEW, {
    variables: { id: id },
  });
  const pal = data?.pal?.data;
  const updatedAt = pal?.covidVaccinationRecord?.data
    ? formatDateTime(pal?.covidVaccinationRecord?.data?.updatedAt)
    : "";
  const covidId = pal?.covidVaccinationRecord?.data?.id ?? "";
  const pendingReview =
    pal?.covidVaccinationRecord?.data && pal?.covidVaccinationRecord?.data?.pendingReview;
  const [approveMutation] = useMutation(VACCINE_APPROVE);

  const Approve = async () => {
    try {
      const { data } = await approveMutation({
        variables: {
          covidVaccinationRecordId: covidId,
        },
      });
      if (data?.approveCovidVaccinationRecord?.data) {
        toast.success("Vaccination approval successful!");
        refetch();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {}
  };

  const Reject = () => {
    setAction(DataTableMenuActions.Reject);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
  };

  const handleRefetch = async () => {
    await refetch();
  };

  const openCard = () => {
    const vaccinationCard = window.open("", "_blank");
    vaccinationCard?.document.write(`
      <html>
        <head>
          <title>
          Vaccination
         </title>
        </head>
        <body>
          <img src=${pal?.covidVaccinationRecord?.data?.cardFront} alt="Vaccination Card">
        </body>
      </html>
    `);
  };

  if (!pal && !loading) {
    return (
      <>
        <h1>404 :-(</h1>
        <p>pal not found</p>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Pal Vaccine Info - Papa Admin</title>
      </Helmet>
      <Heading as="h2" color="primary">
        Vaccination Status
      </Heading>
      <Panel>
        <Panel.Item>
          <Grid>
            <Column size={3}>
              <Text bold>Status</Text>
            </Column>
            <Column size={3}>
              <Text bold>Type</Text>
            </Column>
            <Column size={3}>
              <Text bold>First Dose</Text>
            </Column>
            <Column size={4}>
              <Text bold>Second Dose</Text>
            </Column>
            <Column size={6}>
              <Text bold>Updated On</Text>
            </Column>
            <Column size={2}>
              {pendingReview && (
                <Button
                  style={{
                    fontFamily: "Avenir, sans-serif",
                    marginBottom: "0.85rem",
                    width: "100%",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    border: 0,
                  }}
                  onClick={Approve}
                >
                  <Text underline bold size="large">
                    Approve
                  </Text>
                </Button>
              )}
            </Column>
            <Column size={2}>
              {pendingReview && (
                <Button
                  style={{
                    fontFamily: "Avenir, sans-serif",
                    marginBottom: "0.85rem",
                    width: "100%",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    marginLeft: "0.85rem",
                    border: 0,
                  }}
                  onClick={Reject}
                >
                  <Text underline bold size="large">
                    Reject
                  </Text>
                </Button>
              )}
            </Column>
          </Grid>
        </Panel.Item>
        <Panel.Item>
          <Grid>
            <Column size={3}>
              {pal?.covidVaccinationRecord?.data
                ? pal?.covidVaccinationRecord?.data?.pendingReview
                  ? "Pending"
                  : "Approved"
                : "--"}
            </Column>
            <Column size={3}>{pal?.covidVaccinationRecord?.data?.sponsor}</Column>
            <Column size={3}>{pal?.covidVaccinationRecord?.data?.vaccinationDateOne}</Column>
            <Column size={4}>{pal?.covidVaccinationRecord?.data?.vaccinationDateTwo}</Column>
            <Column size={6}>{updatedAt}</Column>
            <Column size={5}></Column>
          </Grid>
        </Panel.Item>
      </Panel>
      <Heading as="h2" color="primary">
        Vaccination Card
      </Heading>
      <Panel>
        <Panel.Item>
          {pal?.covidVaccinationRecord?.data?.cardFront && (
            <img
              alt="Vaccination Card"
              src={pal?.covidVaccinationRecord?.data?.cardFront}
              style={{ maxWidth: "500px", cursor: "zoom-in" }}
              onClick={openCard}
            />
            // </a>
          )}
        </Panel.Item>
      </Panel>
      {pendingReview && (
        <PalVaccineRejectModal
          covidId={covidId}
          isOpen={action === DataTableMenuActions.Reject}
          toggle={closeModal}
          onRefetch={handleRefetch}
        />
      )}
    </>
  );
};
const Grid = styled("div")`
  display: flex;
`;

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
`;
export default VaccineInfo;
