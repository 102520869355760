import { gql } from "@apollo/client";

export const GET_VISIT_FOR_FEEDBACK = gql`
  query VisitForFeedback($id: ID!) {
    visit(id: $id) {
      data {
        id
        scheduledFor
        state
        estimatedDuration
        isVirtual
        tasks {
          data {
            id
            name
            icon
          }
        }
        observedDurationMins
        objectives {
          data {
            id
            checked
            description
          }
        }
        pal {
          data {
            id
            fullName
          }
        }
        papa {
          data {
            id
          }
        }
      }
    }
  }
`;

export const REVIEW_VISIT = gql`
  mutation reviewVisitMutation($id: ID!, $input: VisitReviewInput) {
    reviewVisit(id: $id, input: $input) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_VISIT_TASKS = gql`
  mutation updateVisitTasks($visitId: ID!, $taskIds: [ID!]!, $objectives: [VisitObjectiveInput!]) {
    updateVisit(id: $visitId, input: { taskIds: $taskIds, objectives: $objectives }) {
      data {
        id
      }
    }
  }
`;

export const RATE_MEMBER = gql`
  mutation RatePapa(
    $visitId: ID!
    $rating: Int!
    $keywords: [String!]
    $papaMood: PapaMood
    $additionalTasksCompleted: String
    $additionalInformation: String
  ) {
    ratePapa(
      visitId: $visitId
      rating: $rating
      keywords: $keywords
      papaMood: $papaMood
      additionalTasksCompleted: $additionalTasksCompleted
      additionalInformation: $additionalInformation
    ) {
      data {
        id
      }
    }
  }
`;
