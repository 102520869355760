import { gql } from "@apollo/client";

export const CREATE_SERVICEREQUEST = gql`
  mutation createServiceRequest($input: ServiceRequestInput!) {
    createServiceRequest(input: $input) {
      data {
        account {
          data {
            id
          }
        }
        id
        papa {
          data {
            id
          }
        }
        description
        openedAt
        origin
        type {
          data {
            id
          }
        }
        priority
        status
        closedReason
        need {
          data {
            id
          }
        }
        needText
        assignedTo {
          data {
            id
          }
        }
      }
    }
  }
`;
