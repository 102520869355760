import { gql } from "@apollo/client";

export const HANDOFF_VISIT = gql`
  mutation handoffVisit($id: ID!, $input: VisitHandoffInput!) {
    handoffVisit(id: $id, input: $input) {
      data {
        id
        state
      }
    }
  }
`;
