import React, { ReactElement } from "react";

import ConfirmModal from "components/Modal/ConfirmModal";
import { SupportTicketRequesterType } from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { formatPhoneNumber } from "utils/strings/formatPhoneNumber";

interface Props {
  id: string;
  type: SupportTicketRequesterType;
  data?: any;
  businessName: string;
  phoneNumber: string;
  isOpen: boolean;
  closeModal: () => void;
}

const WarmTransferModal = ({
  id,
  type,
  data = {},
  businessName,
  phoneNumber,
  isOpen,
  closeModal,
}: Props): ReactElement | null => {
  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket({
    onSuccess: closeModal,
  });

  const onConfirm = () => {
    handleSubmitSupportTicket({ id, type, data });
  };

  const formattedNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : "";

  return (
    <ConfirmModal
      isOpen={isOpen}
      onCancel={closeModal}
      onConfirm={onConfirm}
      header="Is this a dissatisfaction?"
      loading={loading}
      confirmBtnText="Submit Ticket"
    >
      <p>All verbal dissatisfactions for {businessName} require a warm transfer.</p>
      <p>Call the client at: {formattedNumber}</p>
    </ConfirmModal>
  );
};

export default WarmTransferModal;
