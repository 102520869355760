import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { PaymentInvoice } from "generated/types";
import UpdatePaymentInvoiceState from "modals/PaymentInvoices/UpdatePaymentInvoiceState";

import { PaymentInvoiceMenuActions as Actions } from "./models";

interface Props {
  paymentInvoice: PaymentInvoice | null;
  action: Actions | null;
  closeModal: () => void;
  onRefetch?: () => void;
}

const PaymentInvoiceActions = ({
  paymentInvoice,
  action,
  closeModal,
  onRefetch = () => {},
}: Props) => {
  if (!paymentInvoice?.id) return null;

  return (
    <>
      <ModalLayout
        toggle={closeModal}
        title="Change payment invoice status"
        isOpen={action === Actions.changeStatus}
      >
        <UpdatePaymentInvoiceState
          paymentInvoiceId={paymentInvoice.id}
          closeModal={closeModal}
          onRefetch={onRefetch}
        />
      </ModalLayout>
    </>
  );
};

export default PaymentInvoiceActions;
