import * as yup from "yup";

export interface Schema {
  indefinitely: boolean;
  optOutUntil?: string | null;
}

export const schema = yup.object().shape({
  indefinitely: yup.boolean().nullable(),
  optOutUntil: yup.string().when("indefinitely", {
    is: false,
    then: yup.string().nullable().required("Required"),
    otherwise: yup.string().nullable(),
  }),
});
