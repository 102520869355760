import { Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "components/Button";
import { FormFooter, StyledForm } from "components/EditBio/styles";
import Form from "components/Form";
import { ModalContainer } from "components/Modal/Modal.styles";
import { RadioGroup } from "components/RadioGroup/RadioGroup";
import { VisitBusinessPurpose, useStartAgentVisitMutation } from "generated/types";
import { GET_CURRENT_ACCOUNT } from "utils/gql/current-account";
import * as segment from "utils/segment";

import { Schema, schema } from "./schema";

interface Props {
  papaId: string;
  onClose: () => void;
}

const SetUpVirtualCallContent: React.FC<Props> = ({ papaId, onClose }) => {
  const history = useHistory();
  const [startAgentVisit] = useStartAgentVisitMutation();

  const handleSubmit = async (formData: Schema) => {
    if (!formData.callType) return;
    const isUnscheduled = !formData.callType.includes(VisitBusinessPurpose.ScheduledVirtualVisit);

    segment.virtualVisitStarted(formData.callType);

    try {
      const { data: startAgentVisitData } = await startAgentVisit({
        variables: {
          papaId,
          businessPurposes: [formData.callType],
          isInbound: isUnscheduled,
          isUnscheduled: isUnscheduled,
          templateCode: "WELLCARE1",
        },
        refetchQueries: [{ query: GET_CURRENT_ACCOUNT }],
      });
      if (startAgentVisitData?.startAgentVisit?.data?.id) {
        toast.success("The virtual visit started!", { position: "top-center" });
        onClose();
        history.push(`/visits/${startAgentVisitData.startAgentVisit.data.id}`);
        return;
      } else {
        toast.error("It' was not possible to start a virtual call", { position: "top-center" });
      }

      onClose();
    } catch (error) {
      window.console.error(error);
      toast.error((error as Error).message);
    }
  };

  const handleCancel = () => {
    segment.virtualVisitCanceled();
    onClose();
  };

  const initialValues = {
    callType: VisitBusinessPurpose.ScheduledVirtualVisit,
  };

  const callTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    segment.virtualVisitOptionSelected(event.target.id);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <StyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label>What type of a call is it?</Form.Label>
            <RadioGroup
              name="callType"
              onChange={callTypeChanged}
              options={[
                {
                  value: VisitBusinessPurpose.ScheduledVirtualVisit,
                  label: "Scheduled visit",
                },
                {
                  value: VisitBusinessPurpose.Enrollment,
                  label: "Enrollment call",
                },
                {
                  value: VisitBusinessPurpose.Assessment,
                  label: "Assessment call",
                },
                {
                  value: VisitBusinessPurpose.CareNavigation,
                  label: "Social Care Navigator call",
                },
                {
                  value: VisitBusinessPurpose.Reengagement,
                  label: "Reengagement call",
                },
              ]}
            />
          </Form.Group>
        </ModalContainer>
        <FormFooter>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Form.SubmitButton>Start Call</Form.SubmitButton>
        </FormFooter>
      </StyledForm>
    </Formik>
  );
};

export default SetUpVirtualCallContent;
