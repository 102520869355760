import { gql } from "@apollo/client";

export const CHANGE_PAL_STATUS = gql`
  mutation changePalStatus($id: ID!, $input: PalStatusInput!) {
    changePalStatus(id: $id, input: $input) {
      data {
        id
        status
        suspendedPals {
          data {
            id
            insertedAt
            suspendedUntil
          }
        }
      }
    }
  }
`;
