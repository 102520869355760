import { lowerCase, startCase } from "lodash";

import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { COMMENT_TYPES } from "constants/papaComment";

const TYPE_SEPARATOR = ": ";

interface Options {
  commentTypes?: string[];
  allowAnyType?: boolean;
  capitalizeType?: boolean;
}

export function getCommentTypeAndContent(comment: string, options?: Options) {
  const {
    commentTypes = COMMENT_TYPES,
    allowAnyType = false,
    capitalizeType = false,
  } = options ?? {};

  const [type, ...rest] = comment.split(TYPE_SEPARATOR);
  const splitType = commentTypes.includes(type) || allowAnyType;

  if (rest.length > 0 && splitType) {
    return [capitalizeType ? capitalize(type) : type, rest.join(TYPE_SEPARATOR)];
  }

  return [EMPTY_PLACEHOLDER, comment];
}

export function getCommentType(comment: string, options?: Options) {
  const {
    commentTypes = COMMENT_TYPES,
    allowAnyType = false,
    capitalizeType = false,
  } = options ?? {};

  if (!comment) return EMPTY_PLACEHOLDER;
  const [type, content] = comment.split(TYPE_SEPARATOR);
  const splitType = commentTypes.includes(type) || allowAnyType;
  if (content && splitType) {
    return capitalizeType ? capitalize(type) : type;
  }

  return EMPTY_PLACEHOLDER;
}

export function getCommentContent(comment: string, options?: Options) {
  const {
    commentTypes = COMMENT_TYPES,
    allowAnyType = false,
    capitalizeType = false,
  } = options ?? {};

  if (!comment) return EMPTY_PLACEHOLDER;
  const [type, ...rest] = comment.split(TYPE_SEPARATOR);
  const splitType = commentTypes.includes(type) || allowAnyType;
  if (rest.length > 0 && splitType) {
    return rest.join(TYPE_SEPARATOR);
  }

  return capitalizeType ? capitalize(type) : type;
}

function capitalize(type: string) {
  return startCase(lowerCase(type));
}
