import { gql } from "@apollo/client";

export const ENROLL_PAPA_TEXT_MESSAGING = gql`
  mutation createMessageServiceAuthorization($papaId: String!, $messageServiceId: String!) {
    createMessageServiceAuthorization(
      input: { messageServiceId: $messageServiceId, papaId: $papaId }
    ) {
      data {
        id
        consentStatus
        agreement
        consentAgreementSigned
        initialMessageDelivered
        autoEnrolled
      }
    }
  }
`;

export const RESEND_OPT_IN_TEXT_MESSAGE = gql`
  mutation resendMessageServiceText($messageServiceAuthorizationId: String!) {
    resendMessageServiceText(
      input: { messageServiceAuthorizationId: $messageServiceAuthorizationId }
    ) {
      data {
        status
      }
    }
  }
`;

export const OPT_OUT_PAPA_TEXT_MESSAGING = gql`
  mutation OptOutPapaTextMessaging($papaId: ID!) {
    optOutMessageServiceText(papaId: $papaId) {
      data {
        id
        consentStatus
        consentAgreementSigned
        autoEnrolled
      }
    }
  }
`;

export const MESSAGE_SERVICE_AUTHORIZATION = gql`
  query MessageServiceAuthorization($messageServiceAuthorizationId: ID!) {
    messageServiceAuthorization(id: $messageServiceAuthorizationId) {
      data {
        id
        consentStatus
        agreement
        consentAgreementSigned
        initialMessageDelivered
        autoEnrolled
      }
    }
  }
`;

export const MESSAGE_SERVICES = gql`
  query MessageServices {
    messageServices(filter: { code: { eq: "CONF" } }) {
      data {
        id
      }
    }
  }
`;
