import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { FunctionComponent } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import Dropdown from "components/Dropdown";
import { FeatureFlags } from "constants/featureFlags";
import { Account } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { DropdownActionMenu } from "../DropdownActionMenu";

interface Props {
  member: Account;
  isListMenu?: boolean;
  setAction: (action: DataTableMenuActions, member: Account) => void;
}

const MemberActionsMenu: FunctionComponent<Props> = ({ member, setAction, isListMenu = false }) => {
  const { isSupervisor } = useCurrentAccountRole();
  const makePalEnabled = useIsFeatureEnabled(FeatureFlags.EnableMakePal);
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "member action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {isListMenu && <Dropdown.Link to={`/members/${member.id}`}>View Member</Dropdown.Link>}
      {isSupervisor && (
        <Dropdown.Link to={`/accounts/${member.id}/activity-logs`}>View account logs</Dropdown.Link>
      )}
      {renderDropDownItem("Edit Member", () => setAction(DataTableMenuActions.Edit, member))}
      {!!member.pal?.data?.id &&
        renderDropDownItem("Edit Pal Status", () =>
          setAction(DataTableMenuActions.SetPalStatus, member)
        )}
      {renderDropDownItem("Send reset password email", () =>
        setAction(DataTableMenuActions.ResetPassword, member)
      )}
      {!member.pal?.data?.id &&
        renderDropDownItem("Add Papa", () => setAction(DataTableMenuActions.AddPapa, member))}
      {!member.pal?.data?.id &&
        !hideCreditCardFields &&
        renderDropDownItem("Add credit card", () =>
          setAction(DataTableMenuActions.AddCreditCard, member)
        )}
      {!member.pal?.data?.id &&
        renderDropDownItem("Add location", () =>
          setAction(DataTableMenuActions.AddLocation, member)
        )}
      {!member.pal?.data?.id &&
        renderDropDownItem("Choose Plan", () => setAction(DataTableMenuActions.ChoosePlan, member))}
      {!member.pal?.data?.id &&
        makePalEnabled &&
        renderDropDownItem("Make a Pal", () => setAction(DataTableMenuActions.MakePal, member))}
      {renderDropDownItem("Delete", () => setAction(DataTableMenuActions.Delete, member))}
    </DropdownActionMenu>
  );
};

export default MemberActionsMenu;
