import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ButtonLink } from "components/Button";
import { StyledCheckbox } from "components/Checkbox";
import Text from "components/Text";

import { Column } from "./schema";

type Props<T> = {
  visibleColumns: Column<T>[];
  columns: Column<T>[];
  onColumnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAll: () => void;
  expect: string[];
};

const ColumnCustomizeMenu = <T,>({
  visibleColumns,
  columns,
  onColumnChange,
  onSelectAll,
  expect,
}: Props<T>) => {
  const [columnsVisibility, setColumnsVisibility] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const updatedColumns = visibleColumns.reduce((columns, column) => {
      return { ...columns, [column.dataField]: true };
    }, {});

    setColumnsVisibility(updatedColumns);
  }, [visibleColumns]);

  return (
    <Content>
      <Information>
        <Text>Please choose which column that you want to see on table bellow.</Text>
        <ButtonLink onClick={onSelectAll}>Select all</ButtonLink>
      </Information>
      <Columns>
        {columns
          .filter((column) => !expect.includes(column.dataField))
          .map((column) => (
            <label key={`${column.dataField}Column`} htmlFor={`${column.dataField}Column`}>
              <StyledCheckbox
                type="checkbox"
                id={`${column.dataField}Column`}
                name={column.dataField}
                onChange={onColumnChange}
                checked={columnsVisibility[column.dataField] || false}
              />
              <Text as="span">{column.header.title}</Text>
            </label>
          ))}
      </Columns>

      <span>
        ({visibleColumns.length} Column{visibleColumns.length !== 1 && "s"})
      </span>
    </Content>
  );
};

const Content = styled.div`
  padding: 0.85rem 0.85rem;
`;

const Information = styled.div`
  margin-bottom: 1rem;

  ${Text} {
    margin-bottom: 0.25rem;
  }

  ${ButtonLink} {
    padding: 0.5rem 0.25rem;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 210px;

  label {
    flex: 1 1 auto;
    display: inline-flex;
    margin-bottom: 12px;
    width: 50%;

    ${Text} {
      margin-left: 0.25rem;
    }
  }
`;

export default ColumnCustomizeMenu;
