import { MemberComplaintCode, PalComplaintCode } from "generated/types";
import { mapValueToLabel } from "utils/helpers/formatters";

export const palComplaintReasonOptions = [
  {
    value: PalComplaintCode.ArrivingLate,
    label: "Arriving late",
  },
  {
    value: PalComplaintCode.BringingCompanion,
    label: "Bringing companion",
  },
  {
    value: PalComplaintCode.DidNotConfirm,
    label: "Did not confirm",
  },
  {
    value: PalComplaintCode.DropForBonus,
    label: "Drop for bonus",
  },
  {
    value: PalComplaintCode.ElectronicHarassment,
    label: "Electronic harassment",
  },
  {
    value: PalComplaintCode.ExtendingVisitTime,
    label: "Extending Virtual Visits and IPV",
  },
  {
    value: PalComplaintCode.FailedBackgroundCheck,
    label: "Failed background check",
  },
  {
    value: PalComplaintCode.FalseMisleadingInfo,
    label: "False misleading info",
  },
  {
    value: PalComplaintCode.Fraud,
    label: "Fraud",
  },
  {
    value: PalComplaintCode.IncompatibleWithPapa,
    label: "Incompatible with Papa company values",
  },
  {
    value: PalComplaintCode.Inconclusive,
    label: "Inconclusive",
  },
  {
    value: PalComplaintCode.LeavingEarly,
    label: "Leaving early",
  },
  {
    value: PalComplaintCode.LatePalCancellationUnder_3,
    label: "Canceled visit within 3 hours of scheduled time",
  },
  {
    value: PalComplaintCode.LatePalCancellationUnder_12,
    label: "Canceled visit within 12 hours of scheduled time",
  },
  {
    value: PalComplaintCode.LowMemberSurvey,
    label: "Unsatisfactory member survey",
  },
  {
    value: PalComplaintCode.MisusePapaServices,
    label: "Misuse papa services",
  },
  {
    value: PalComplaintCode.NoShow,
    label: "No show",
  },
  {
    value: PalComplaintCode.NoShowFraud,
    label: "No show with fraud",
  },
  {
    value: PalComplaintCode.OutOfScopeTask,
    label: "Out of scope task",
  },
  {
    value: PalComplaintCode.OvernightStay,
    label: "Overnight stay",
  },
  {
    value: PalComplaintCode.PalRefusedTask,
    label: "Pal refused to perform a visit task",
  },
  {
    value: PalComplaintCode.SexualAssault,
    label: "Sexual assault",
  },
  {
    value: PalComplaintCode.SexualHarassment,
    label: "Sexual harassment",
  },
  {
    value: PalComplaintCode.SolicitingTips,
    label: "Soliciting tips",
  },
  {
    value: PalComplaintCode.SolicitingWork,
    label: "Soliciting work",
  },
  {
    value: PalComplaintCode.StartingEndingVisitWithoutMember,
    label: "Starting or ending a visit when the member is not present",
  },
  {
    value: PalComplaintCode.Theft,
    label: "Theft",
  },
  {
    value: PalComplaintCode.UnauthorizedMemberCancellationByPal,
    label: "Unauthorized member cancellation",
  },
  {
    value: PalComplaintCode.UnderInfluence,
    label: "Under influence",
  },
  {
    value: PalComplaintCode.UnsafeDriving,
    label: "Unsafe driving",
  },
  {
    value: PalComplaintCode.VehicleInPoorCondition,
    label: "Vehicle in poor condition",
  },
  {
    value: PalComplaintCode.VerbalHarassment,
    label: "Verbal harassment",
  },
];

export const papaComplaintReasonOptions = [
  {
    value: MemberComplaintCode.FalseMisleadingInfo,
    label: "False misleading info",
  },
  {
    value: MemberComplaintCode.Fraud,
    label: "Fraud",
  },
  {
    value: MemberComplaintCode.Inconclusive,
    label: "Inconclusive",
  },
  {
    value: MemberComplaintCode.MisusePapaServices,
    label: "Misuse papa services",
  },
  {
    value: MemberComplaintCode.NoShow,
    label: "No show",
  },
  {
    value: MemberComplaintCode.OvernightStay,
    label: "Overnight stay",
  },
  {
    value: MemberComplaintCode.SexualAssault,
    label: "Sexual assault",
  },
  {
    value: MemberComplaintCode.SexualHarassment,
    label: "Sexual harassment",
  },
  {
    value: MemberComplaintCode.Theft,
    label: "Theft",
  },
  {
    value: MemberComplaintCode.UnderInfluence,
    label: "Under influence",
  },
  {
    value: MemberComplaintCode.VerbalHarassment,
    label: "Verbal harassment",
  },
];

export const PAPA_COMPLAINT_TYPES = Array.from(Object.values(MemberComplaintCode)).map(
  (complaint) => mapValueToLabel(papaComplaintReasonOptions, complaint)
) as string[];
