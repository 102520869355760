import * as yup from "yup";

export interface Schema {
  palId: string;
  comment: string;
}

export const schema = yup.object().shape({
  palId: yup.string().nullable().required("Please select a pal"),
  comment: yup.string().nullable().required("Please add a reason"),
});
