import isNull from "lodash/isNull";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { ButtonLink } from "components/Button";
import { Drawer } from "components/Drawer";
import Heading from "components/Heading";
import QueryErrors from "components/QueryErrors";
import Table from "components/Table";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { AssignedScreener, useAssessmentsAnswersQuery } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { useUrlQuery } from "hooks/useUrlQuery";
import { AssessmentsQuestions } from "modals/AssessmentsQuestions/AssessmentsQuestions";
import AssessmentsQuestionsEditModal from "modals/AssessmentsQuestions/AssessmentsQuestionsEdit";
import { AssessmentsQuestionsPapaSocialIndex } from "modals/AssessmentsQuestions/AssessmentsQuestionsPapaSocialIndex";
import AssessmentsQuestionsPapaSocialIndexEdit from "modals/AssessmentsQuestions/AssessmentsQuestionsPapaSocialIndexEdit";
import { getAssessmentType } from "modals/AssessmentsQuestions/models";
import { PapaNotCurrentlyQualifiedModal } from "modals/PapaNotCurrentlyQualifiedModal";
import { AssessmentType } from "types/assessments";
import { getPapaSocialIndexResult } from "utils/helpers/assessments";
import { formatAssessmentsResult, formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

import { AssessmentCards } from "./AssessmentCards";
import { AssessmentInstructions } from "./AssessmentInstructions/AssessmentInstructions";
import { AssessmentScore } from "./AssessmentScore/AssessmentScore";
import { Screener } from "./Screener";
import { useAvailableAssessments } from "./useAvailableAssessments";

const Assessments: React.FC = () => {
  const { id: papaId } = useParams<{ id: string }>();
  const { lonelinessCompliant } = useUrlQuery() as {
    lonelinessCompliant?: "true";
  };
  const { isSupervisor } = useCurrentAccountRole();
  const { data, loading, error, refetch } = useAssessmentsAnswersQuery({
    variables: { id: papaId },
  });

  const [isScreenerOpen, setIsScreenerOpen] = useState<boolean>(false);
  const [selectedScreener, setSelectedScreener] = useState<
    Pick<AssignedScreener, "definition" | "title" | "screenerVersionId"> | undefined
  >(undefined);

  const assessments = nonNull(data?.papa?.data?.surveySubmissions?.data);

  const { isOpen: isAssessmentSubmittingModal, toggle: toggleAssessmentSubmitting } =
    useModalToggle();
  const { isOpen: isAssessmentEditingModal, toggle: toggleAssessmentEditing } = useModalToggle();
  const [assessmentType, setAssessmentType] = useState<AssessmentType | null>(null);

  const [assessmentEditing, setAssessmentEditing] = useState<string | null>(null);
  const [currentRowOpened, setCurrentRowOpened] = useState<string | null>(null);

  const { availableAssessments } = useAvailableAssessments({
    assessmentAnswerData: data,
  });

  const assignedScreeners = nonNull(data?.papa?.data?.assignedScreeners?.data);

  const onAssessmentModalOpen = (type: AssessmentType) => {
    setAssessmentType(type);
    toggleAssessmentSubmitting();
  };

  const isLegacyPapaSocialIndex = () => {
    return assessmentType === AssessmentType.papaSocialIndex;
  };

  const onBackToLoneliness = () => {
    setAssessmentType(AssessmentType.loneliness);
  };

  const showBackButton =
    availableAssessments.includes(AssessmentType.loneliness) &&
    assessmentType === AssessmentType.health;

  const showProgress =
    availableAssessments.filter(
      (type) => type === AssessmentType.loneliness || type === AssessmentType.health
    ).length >= 2;

  const showSkipButton =
    availableAssessments.includes(AssessmentType.health) &&
    assessmentType === AssessmentType.loneliness;

  const handleAssessmentClose = () => {
    toggleAssessmentSubmitting();
  };

  const onScreenerClick = (id: string) => {
    const selectedScreener = assignedScreeners.find((x) => x.id === id);
    if (selectedScreener) {
      setSelectedScreener(selectedScreener);
      setIsScreenerOpen(true);
    } else {
      window.console.error(`Could not set the assigned screener with id: ${id}`);
    }
  };

  const onScreenerClose = () => {
    setIsScreenerOpen(false);
  };

  return (
    <>
      <AssessmentCards
        assessments={assessments}
        assessmentAnswerData={data}
        onAssessmentClick={onAssessmentModalOpen}
        onScreenerCardClick={onScreenerClick}
        availableAssessments={availableAssessments}
        papaId={papaId}
        assignedScreeners={assignedScreeners}
      />

      <QueryErrors error={error} />
      <AssessmentInstructions
        papaBusiness={data?.papa?.data?.business?.data}
        caregiverBusiness={data?.papa?.data?.caregiver?.data?.business?.data}
        riskScore={data?.papa?.data?.riskScore}
      />
      <Heading as="h3" color="primary">
        Completed assessments ({assessments.length})
      </Heading>

      <Table showOverflow={true}>
        <Table.Head>
          <Table.HeadCell>Assessment scores</Table.HeadCell>
          <Table.HeadCell>Result</Table.HeadCell>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Created at</Table.HeadCell>
          <Table.HeadCell>Quarter</Table.HeadCell>
          <Table.HeadCell>Created by</Table.HeadCell>
          {isSupervisor && <Table.HeadCell>Actions</Table.HeadCell>}
        </Table.Head>

        <Table.Body>
          {loading && <Table.Placeholder rows={3} columns={isSupervisor ? 7 : 6} />}

          {!assessments.length && !loading && (
            <Table.Row>
              <Table.Cell colSpan={isSupervisor ? 7 : 6} align="center" noWrap>
                No Assessments Completed
              </Table.Cell>
            </Table.Row>
          )}

          {assessments.map((assessment) => {
            const id = assessment.id as string;

            const type = getAssessmentType(assessment.survey?.data?.name);

            return (
              <Table.Row key={id} cellPosition="static">
                <AssessmentScore
                  type={type}
                  assessment={assessment}
                  toggleAssessment={() =>
                    currentRowOpened === id ? setCurrentRowOpened(null) : setCurrentRowOpened(id)
                  }
                  showAnswers={currentRowOpened === id}
                />
                <Table.Cell>
                  {type === AssessmentType.papaSocialIndex
                    ? getPapaSocialIndexResult(assessment)
                    : formatAssessmentsResult(assessment)}
                </Table.Cell>
                <Table.Cell>{assessment?.survey?.data?.name}</Table.Cell>
                <Table.Cell>{formatDateTime(assessment?.submittedAt)}</Table.Cell>
                <Table.Cell>
                  {formatDateTime(assessment?.submittedAt, { format: "[Q]Q" })}
                </Table.Cell>
                <Table.Cell>
                  {assessment?.submittedBy?.data?.fullName || EMPTY_PLACEHOLDER}
                </Table.Cell>
                {isSupervisor && (
                  <Table.Cell>
                    <ButtonLink
                      onClick={() => {
                        setAssessmentType(type as AssessmentType);
                        setAssessmentEditing(assessment.id as string);
                        toggleAssessmentEditing();
                      }}
                    >
                      Edit
                    </ButtonLink>
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {selectedScreener && (
        <Screener
          screener={selectedScreener}
          papaId={papaId}
          isOpen={isScreenerOpen}
          onClose={onScreenerClose}
        />
      )}

      {papaId && (
        <Drawer
          open={isAssessmentSubmittingModal}
          onClose={handleAssessmentClose}
          onBack={showBackButton ? onBackToLoneliness : undefined}
          title="Assessments due"
          size="lg"
        >
          {!isNull(assessmentType) &&
            (isLegacyPapaSocialIndex() ? (
              <AssessmentsQuestionsPapaSocialIndex
                papaId={papaId}
                type={assessmentType}
                onSubmit={toggleAssessmentSubmitting}
                onClose={handleAssessmentClose}
              />
            ) : (
              <AssessmentsQuestions
                papaId={papaId}
                type={assessmentType}
                onSkip={showSkipButton ? () => setAssessmentType(AssessmentType.health) : undefined}
                onSubmit={() => {
                  if (showSkipButton) {
                    setAssessmentType(AssessmentType.health);
                  } else {
                    toggleAssessmentSubmitting();
                    refetch();
                  }
                }}
                onClose={() => {
                  toggleAssessmentSubmitting();
                  refetch();
                }}
                showProgress={showProgress}
              />
            ))}
        </Drawer>
      )}
      {isSupervisor &&
        assessmentEditing &&
        papaId &&
        assessmentType &&
        (assessmentType === AssessmentType.papaSocialIndex ? (
          <AssessmentsQuestionsPapaSocialIndexEdit
            assessmentId={assessmentEditing}
            papaId={papaId}
            isOpen={isAssessmentEditingModal}
            toggle={toggleAssessmentEditing}
            onClose={() => setAssessmentEditing(null)}
            onRefetch={refetch}
          />
        ) : (
          <AssessmentsQuestionsEditModal
            assessmentId={assessmentEditing}
            assessmentType={assessmentType}
            papaId={papaId}
            isOpen={isAssessmentEditingModal}
            toggle={toggleAssessmentEditing}
            onClose={() => setAssessmentEditing(null)}
            onRefetch={refetch}
          />
        ))}
      {lonelinessCompliant === "true" && <PapaNotCurrentlyQualifiedModal papaId={papaId} />}
    </>
  );
};

export default Assessments;
