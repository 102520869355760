import * as yup from "yup";

import { ServiceRequestOrigin, ServiceRequestPriority } from "generated/types";

export interface Schema {
  assignedToId: string;
  needId: string;
  description: string;
  typeId: string;
  priority: ServiceRequestPriority;
  origin: ServiceRequestOrigin;
  needText: string;
}

export const formSchema = (otherSelected: boolean) =>
  yup.object().shape({
    needId: yup.string().nullable().required("Please select need!"),
    typeId: yup.string().nullable().required("Please select type!"),
    description: yup.string().nullable().required("Please enter description!"),
    priority: yup.string().nullable().required("Please select priority!"),
    needText: otherSelected ? yup.string().required("Please enter other!") : yup.string(),
  });
