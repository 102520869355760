import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";

import { Loader } from "components/Loader";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import QueryErrors from "components/QueryErrors";
import { VisitProgram } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";

import Overview from "./Overview";
import VisitTemplates from "./VisitTemplates";
import { VISIT_PROGRAM } from "./gql";

type Props = {
  businessId: string;
  programId: string;
};

const Details = ({ match }: RouteComponentProps<Props>) => {
  const {
    capitalized: { pluralEntityName, singularEntityName },
    kebabCased,
  } = useCallItClientProgramOrBusiness();
  const { businessId, programId } = match.params;

  const { data, loading, error } = useQuery(VISIT_PROGRAM, {
    variables: { id: programId },
  });
  const visitProgram = data?.visitProgram?.data as VisitProgram;

  useTopBarOptions({
    title: "Visit Program",
    name: visitProgram?.name ?? "",
    icon: AccountCircle,
    breadcrumbs: [
      "Accounts",
      { label: `All ${pluralEntityName}`, link: `/${kebabCased.pluralEntityName}` },
      {
        label: `${singularEntityName} Details`,
        link: `/${kebabCased.pluralEntityName}/${businessId}`,
      },
      {
        label: "Visit Programs",
        link: `/${kebabCased.pluralEntityName}/${businessId}/visit-programs`,
      },
    ],
    deps: [visitProgram?.name],
  });

  return (
    <>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <QueryErrors error={error} />
        ) : (
          <>
            <Overview visitProgram={visitProgram} />

            <Main>
              <Nav>
                <Nav.Link
                  to={`/${kebabCased.pluralEntityName}/${businessId}/visit-programs/${programId}`}
                >
                  Templates
                </Nav.Link>
              </Nav>

              <Switch>
                <Route
                  exact
                  path="/businesses/:id/visit-programs/:programId"
                  component={() => <VisitTemplates />}
                />
                <Route
                  exact
                  path="/client-programs/:id/visit-programs/:programId"
                  component={() => <VisitTemplates />}
                />
              </Switch>
            </Main>
          </>
        )}
      </Container>
    </>
  );
};

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

export default Details;
