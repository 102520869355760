import * as yup from "yup";

import { PalChangeStatusReason, PalStatus } from "generated/types";

export interface Schema {
  status: PalStatus | null;
  reason: PalChangeStatusReason | null;
  comment: string;
}

export const schema = yup.object().shape({
  status: yup.string().nullable().required("Status is required"),
  reason: yup.string().nullable().required("Reason is required"),
  comment: yup.string().required("Comment is required"),
});
