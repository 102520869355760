import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown";
import { ChevronUp } from "@styled-icons/boxicons-regular/ChevronUp";
import React from "react";
import styled from "styled-components";

import Dropdown from "components/Dropdown";
import { DropdownIcon } from "components/Dropdown/DropdownIcon";

export enum ActionsMenu {
  NoAction,
  ChangeStatus,
}

type Props = {
  isDisabled: boolean;
};

export const DropdownActionButton: React.FC<Props> = ({ isDisabled, children }) => {
  return (
    <Dropdown>
      <Dropdown.ToggleButton disabled={isDisabled}>
        Actions
        <Icon>
          <DropdownIcon color="white" iconOpen={ChevronUp} iconClose={ChevronDown} size={23} />
        </Icon>
      </Dropdown.ToggleButton>
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

const Icon = styled.span`
  margin-left: 19px;
`;
