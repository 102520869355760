import { Moment } from "moment";
import moment from "moment-timezone";

import { DATE_FORMAT, HOURS_FORMAT } from "constants/date";

import { FormValues } from "./index";

type Params = Pick<FormValues, "dateRange" | "timeFrom" | "timeTo" | "timezone">;

export const getFormattedDateRange = ({
  timeFrom,
  timeTo,
  dateRange,
  timezone,
}: Params): { from: string; to: string } => {
  const dayFrom = formatDay(dateRange.startDate, timezone);
  const dayTo = formatDay(dateRange.endDate, timezone);

  const from = timeFrom
    ? formatDate(dayFrom, timeFrom, timezone)
    : dayFrom.startOf("day").toISOString();

  const to = timeTo
    ? formatDate(dayTo, timeTo, timezone)
    : dayTo.set("hour", 23).set("minutes", 30).toISOString();

  return { from, to };
};

const formatDate = (day: Moment, time: string, timezone: string): string => {
  return moment
    .tz(`${day.format(DATE_FORMAT)} ${time}`, `${DATE_FORMAT} ${HOURS_FORMAT}`, timezone)
    .toISOString();
};

const formatDay = (day: Moment | string | null, timezone: string): Moment => {
  return day ? moment.tz(day, timezone) : moment.tz(timezone);
};
