import React, { useState } from "react";

import DataTableDropdownMenu, { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { Objective } from "types/objective";
import { formatDateTime } from "utils/helpers/formatters";

export const useObjectivesTableColumns = () => {
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [objective, setObjective] = useState<Objective | null>(null);
  const menuItems = [{ label: "Edit", action: DataTableMenuActions.Edit }];
  const columns: Column<Objective>[] = [
    { header: { title: "Description" }, dataField: "description" },
    { header: { title: "Task" }, dataField: "task.data.name" },
    {
      header: { title: "Is Active" },
      dataField: "isActive",
      formatter: ({ isActive }) => (isActive ? "Yes" : "No"),
    },
    {
      header: { title: "Deleted At" },
      dataField: "deletedAt",
      cellProps: { noWrap: true },
      formatter: ({ deletedAt }) => formatDateTime(deletedAt),
    },
    {
      header: { title: "Inserted At" },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(objective) {
        return (
          <DataTableDropdownMenu
            items={menuItems}
            actionHandler={(action) => menuActionsHandler(action, objective)}
          />
        );
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, objective: Objective) => {
    setAction(action);
    setObjective(objective);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setObjective(null);
  };

  const createObjective = () => {
    setAction(DataTableMenuActions.Edit);
    setObjective(null);
  };

  return {
    columns,
    action,
    objective,
    closeModal,
    createObjective,
  };
};
