import { Field, FieldProps, useField } from "formik";
import React, { useContext } from "react";
import PhoneInput, { DefaultInputComponentProps } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled, { css } from "styled-components";

import FormContext from "components/Form/FormContext";
import Feedback from "components/Form/FormFeedback";

type Props = React.InputHTMLAttributes<HTMLInputElement> &
  Partial<DefaultInputComponentProps> & {
    name?: string;
    id?: string;
    fullWidth?: boolean;
    isValid?: boolean;
    isInvalid?: boolean;
    disabled?: boolean;
    value?: string;
    dataTestid?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };

export const InputContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "18.75rem")};
  border: 1px solid
    ${({ isValid, isInvalid, theme }) => {
      if (isValid) return theme.variants.success;
      if (isInvalid) return theme.variants.danger;
      return theme.borderColor;
    }};
  background-color: white;
  opacity: ${({ disabled }) => disabled && "0.5"};
  padding: 0;
  border-radius: 0.25rem;
  font-size: ${({ theme }) => theme.fontSizes.middle};

  &:focus-within {
    border: thin solid ${({ theme }) => theme.variants.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f3f3f3;
    `}
`;

const StyledInput = styled(PhoneInput)`
  flex: 1;
  min-height: 18px;
  font-size: inherit;
  border-radius: 0.25rem;
  border: 0;
  padding: 0.625rem 0.75rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  .PhoneInputInput {
    border: none;
    outline: none;
    font-size: inherit;

    &:disabled {
      background-color: #f3f3f3;
    }
  }
`;

const InputPhone: React.FC<Props> = ({
  id,
  size,
  isValid,
  name,
  isInvalid,
  fullWidth,
  disabled,
  "aria-label": ariaLabel,
  ...rest
}: Props) => {
  const { controlId } = useContext(FormContext);
  const [{ value }, , helpers] = useField(name as string);

  const onChange = (newValue: string) => {
    helpers.setValue(newValue);
  };

  return (
    <Field name={name}>
      {({ field, form, meta: { touched, error } }: FieldProps) => (
        <>
          <InputContainer
            size={size}
            isValid={isValid}
            isInvalid={isInvalid || (touched && !!error)}
            fullWidth={fullWidth}
            disabled={form.isSubmitting || disabled}
            {...rest}
          >
            <StyledInput
              {...field}
              onChange={onChange}
              value={value}
              countries={["US"]}
              disabled={form.isSubmitting || disabled}
              id={id || controlId}
              aria-label={ariaLabel}
              {...rest}
            />
          </InputContainer>
          {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
        </>
      )}
    </Field>
  );
};

export default InputPhone;
