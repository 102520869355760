import { useQuery } from "@apollo/client";

import { VisitListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { GET_VISITS_SCHEDULED } from "pages/Members/Details/VisitsScheduled/gql";

type Data = {
  visits: VisitListResult;
};

export const useVisitByAgent = () => {
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { data, loading, error } = useQuery<Data>(GET_VISITS_SCHEDULED, {
    variables: {
      filter: [{ created_by_agent: true }],
      pagination: { beforeCursor, afterCursor, limit },
      sorting: { insertedAt: "DESC" },
    },
  });

  return {
    data: data?.visits?.data,
    pagination: data?.visits?.pagination,
    loading,
    error,
  };
};
