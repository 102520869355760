import React from "react";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";
import { useQueryTasks } from "hooks/useQueryTasks";
import { Values } from "pages/ScheduleVisit/types";
import { nonNullWithId } from "utils/nonNull";

interface Props {
  isOpen: boolean;
  loading: boolean;
  visitFormValues?: Values;
  taskIdsWithNoObjectives: string[];
  onSubmit: (values: Values) => Promise<void>;
  toggle: () => void;
}

export const NoObjectiveConfirmModal: React.FC<Props> = ({
  isOpen,
  loading,
  visitFormValues,
  taskIdsWithNoObjectives,
  onSubmit,
  toggle,
}) => {
  const { data, loading: tasksLoading } = useQueryTasks();
  const tasks = nonNullWithId(data?.tasks?.data);

  const taskNames = taskIdsWithNoObjectives.map(
    (taskId) => tasks.find((task) => task.id === taskId)?.name
  );

  const handleSubmit = async () => {
    if (visitFormValues) {
      await onSubmit(visitFormValues);
      toggle();
    }
  };
  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <TextContainer>
            {tasksLoading ? (
              <SpinningIcon size={18} />
            ) : (
              <Text>
                You have no recommended tasks selected for <strong>{taskNames.join(", ")}</strong>.
                Are you sure you want to continue?
              </Text>
            )}
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={handleSubmit}
          disabled={tasksLoading || loading}
        >
          {loading && <SpinningIcon size={18} />}
          Ok
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
