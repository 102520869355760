import { Marker, Polyline } from "@react-google-maps/api";
import React from "react";

import { VisitPathNode } from "generated/types";

type VisitPathMapElementsProps = {
  path: VisitPathNode[];
  strokeColor: string;
  startIcon: string;
  endIcon?: string;
};

const getIcon = (icon: string) => ({
  url: icon,
  scaledSize: new google.maps.Size(32, 32),
});

const VisitPathMapElements = ({
  path,
  strokeColor = "#1890ff",
  startIcon,
  endIcon,
}: VisitPathMapElementsProps) => {
  if (!path.length) return null;

  const filteredPath = filterPath(path);

  const startMarkPosition = filteredPath[0];
  const endMarkPosition = filteredPath[filteredPath.length - 1];

  return (
    <>
      <Marker icon={getIcon(startIcon)} position={startMarkPosition} />
      {endIcon && <Marker icon={getIcon(endIcon)} position={endMarkPosition} />}
      <Polyline options={{ strokeColor }} path={filteredPath} />
    </>
  );
};

const filterPath = (path: VisitPathNode[]) => {
  const newPath = [];
  for (let i = 0; i < path.length; i++) {
    const node = path[i];
    if (node.lat && node.lng) {
      newPath.push({
        lat: node.lat,
        lng: node.lng,
      });
    }
  }
  return newPath;
};

export default VisitPathMapElements;
