import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { DeviceToken, PalSingleResult } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

import { GET_PAL_DEVICES } from "../gql";

interface Data {
  pal?: PalSingleResult;
}

const Devices = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<Data>(GET_PAL_DEVICES, {
    variables: { id: id },
  });
  const devices = nonNull(data?.pal?.data?.account?.data?.deviceTokens?.data);
  const columns: Column<DeviceToken>[] = [
    {
      header: { title: "Manufacturer" },
      dataField: "info.manufacturer",
    },
    {
      header: { title: "Model" },
      dataField: "info.model",
    },
    {
      header: { title: "System version" },
      dataField: "info.systemVersion",
    },
    {
      header: { title: "System name" },
      dataField: "info.systemName",
    },
    {
      header: { title: "App version" },
      dataField: "info.appVersion",
    },
    {
      header: { title: "Platform" },
      dataField: "platform",
    },
    {
      header: { title: "Updated" },
      dataField: "insertedAt",
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt?.toString());
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Pal Devices - Papa Admin</title>
      </Helmet>

      <SmartTable<DeviceToken>
        keyField="id"
        data={devices}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default Devices;
