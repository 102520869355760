import { gql } from "@apollo/client";

export const CREATE_CREDIT_CARD = gql`
  mutation createAccountCreditCard($input: CreditCardInput!) {
    createCreditCard(input: $input) {
      data {
        id
      }
    }
  }
`;
