import { useQuery } from "@apollo/client";
import { ConciergeBell } from "@styled-icons/fa-solid/ConciergeBell";
import React, { useState } from "react";
import Helmet from "react-helmet";

import ServiceRequestActionMenu from "components/ActionsMenu/ServiceRequestActionMenu";
import Button from "components/Button";
import { ItemsCount } from "components/DataTables/VisitsTable/ItemsCount";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import { useRowsSelectList } from "components/SmartTable/useRowsSelectList";
import { ServiceType } from "constants/service-requests";
import {
  ServiceRequest,
  ServiceRequestListResult,
  ServiceRequestSorting,
  SortDirection,
} from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { ServiceRequestsFilterModal } from "modals/ServiceRequestFiltersModal";
import { countFilters, serviceRequestsFiltersMaker } from "utils/serviceRequestsFilters";

import { GET_SERVICEREQUESTS } from "./gql";
import { useServicesTableColumns } from "./useServicesTableColumns";

interface Data {
  serviceRequests: ServiceRequestListResult;
}
type Props = {
  type: ServiceType;
};
const MyDashboard = ({ type }: Props) => {
  useTopBarOptions({
    title: "My Service Requests",
    icon: ConciergeBell,
    breadcrumbs: ["Care Concierge"],
  });

  const { id } = useCurrentAccount() ?? {};
  const [sorting, setSorting] = useState<ServiceRequestSorting>({
    priority: SortDirection.Asc,
  });
  const { rowsSelected, onRowSelect, onAllRowsSelect } = useRowsSelectList();
  const { columns } = useServicesTableColumns({
    type,
    sorting,
    onSortChange: setSorting,
  });
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const { page, limit, ...servicerequestsFilters } = usePageInfo();
  const countFilter = countFilters(servicerequestsFilters);
  const serviceRequestsFilter = serviceRequestsFiltersMaker({ ...servicerequestsFilters });
  const defaultFilter = {
    assignedToId: { eq: id },
  };
  const { data, loading, error, refetch } = useQuery<Data>(GET_SERVICEREQUESTS, {
    variables: {
      filter: {
        ...defaultFilter,
        ...serviceRequestsFilter,
      },
      pagination: { page, limit },
      sorting,
    },
    fetchPolicy: "network-only",
  });
  const servicerequests = data?.serviceRequests ?? null;
  const handleFiltersChange = () => {
    toggleFiltersModal();
  };

  return (
    <div>
      <Helmet>
        <title>Care Concierge - Papa Admin</title>
      </Helmet>
      <FlexRow justifyContent="flex-end">
        <ServiceRequestActionMenu
          type={type}
          serviceRequestIds={rowsSelected}
          onRefetch={refetch}
        />
        <Button
          type="button"
          style={{ marginLeft: "1.25rem" /* TODO: remove it later .*/ }}
          onClick={toggleFiltersModal}
        >
          <span>Filter</span>
          {countFilter > 0 && <span>({countFilter})</span>}
        </Button>
      </FlexRow>
      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Filter"
        isOpen={isFiltersModalOpen}
      >
        <ServiceRequestsFilterModal type={type} onSubmit={handleFiltersChange} />
      </ModalLayout>

      <ItemsCount totalCount={data?.serviceRequests?.pagination?.totalCount} />
      <SmartTable<ServiceRequest>
        keyField="id"
        pagination={data?.serviceRequests?.pagination}
        data={servicerequests?.data}
        loading={loading}
        columns={columns}
        error={error}
        onRowSelect={onRowSelect}
        onAllRowsSelect={onAllRowsSelect}
        rowsSelected={rowsSelected}
      />
    </div>
  );
};

export default MyDashboard;
