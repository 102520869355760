import React from "react";
import styled from "styled-components";

import NotEnrolled from "assets/images/text-messaging-status/not-enrolled.svg";
import OptedIn from "assets/images/text-messaging-status/opted-in.svg";
import Tooltip from "components/Tooltip";

interface Props {
  enabled: boolean;
}

const PapaAutomatedCallsStatusIcon = ({ enabled }: Props) => {
  return (
    <Container>
      {enabled ? (
        <Tooltip title="Automated calls are enabled.">
          <img src={OptedIn} alt="automated calls enabled" />
        </Tooltip>
      ) : (
        <Tooltip title="Automated calls are not enabled.">
          <img src={NotEnrolled} alt="automated calls not enabled" />
        </Tooltip>
      )}
    </Container>
  );
};

export default PapaAutomatedCallsStatusIcon;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
