import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import EditButton from "components/EditButton";
import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { useUpdatePapaHomeLocationMutation } from "generated/types";
import { Location } from "generated/types";
import { OverrideLocationButton } from "modals/OverrideLocation/OverrideLocationButton";
import { DeleteLocationModal } from "pages/Locations/List/DeleteLocationModal/DeleteLocationModal";

import { AddressEditForm } from "./AddressEditForm/AddressEditForm";

enum Action {
  Edit,
  Delete,
  Override,
}

type Props = {
  locations: Location[];
  homeLocation: Location;
  papaId: string;
  accountId: string;
  onAddLocationClick: () => void;
  onUpdate: () => void;
};

export const Address = ({
  papaId,
  accountId,
  locations,
  homeLocation,
  onAddLocationClick,
  onUpdate,
}: Props) => {
  const [updateHomeLocation] = useUpdatePapaHomeLocationMutation();

  const [action, setAction] = useState<Action | null>(null);
  const [location, setLocation] = useState<Location | null>(null);

  const makeHomeLocationHandler = async (location_id?: String) => {
    try {
      await updateHomeLocation({
        variables: {
          id: papaId,
          input: { homeLocationId: location_id as string },
        },
      });
      toast.success("Home location updated.");
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const onAddressEditHandler = (location: Location) => {
    setLocation(location);
    setAction(Action.Edit);
  };

  const onAddressDeleteHandler = (location: Location) => {
    setLocation(location);
    setAction(Action.Delete);
  };

  const closeModal = () => {
    setLocation(null);
    setAction(null);
    onUpdate();
  };

  const nonHomeLocations = (locations: Location[]) =>
    locations
      .filter((location) => location.id !== homeLocation.id)
      .map((location) => {
        const { address, address2, city, countryIso, zipcode, id, nickname } = location;
        return (
          <div key={id}>
            <Text bold>{!!nickname ? nickname : "Unnamed location"}</Text>
            <Container>
              <Panel.Item>
                <Text>{address}</Text>
                <Text>{address2}</Text>
                <Text>
                  <span>{city}</span>, <span>{countryIso}</span> <span>{zipcode}</span>
                </Text>
              </Panel.Item>

              <ActionButtons>
                <EditButton onClick={() => onAddressEditHandler(location)}>Edit</EditButton>
                <EditButton onClick={() => makeHomeLocationHandler(location.id!)}>
                  Make home
                </EditButton>
                {location.id && <OverrideLocationButton locationId={location.id} />}
                <EditButton onClick={() => onAddressDeleteHandler(location)}>Delete</EditButton>
              </ActionButtons>
            </Container>
          </div>
        );
      });

  return (
    <>
      <Panel title="Home">
        {!!homeLocation.id ? (
          <div key={homeLocation.id}>
            <Text bold>{!!homeLocation.nickname ? homeLocation.nickname : "Unnamed location"}</Text>
            <Container>
              <Panel.Item>
                <Text>{homeLocation.address}</Text>
                <Text>{homeLocation.address2}</Text>
                <Text>
                  <span>{homeLocation.city}</span>, <span>{homeLocation.countryIso}</span>{" "}
                  <span>{homeLocation.zipcode}</span>
                </Text>
              </Panel.Item>

              <ActionButtons>
                <EditButton onClick={() => onAddressEditHandler(homeLocation)}>Edit</EditButton>
                {homeLocation.id && <OverrideLocationButton locationId={homeLocation.id} />}
              </ActionButtons>
            </Container>
          </div>
        ) : (
          EMPTY_PLACEHOLDER
        )}
      </Panel>
      <Panel title="Locations" onEditClick={onAddLocationClick} textEdit="Add Location">
        {nonHomeLocations(locations).length > 0 ? nonHomeLocations(locations) : EMPTY_PLACEHOLDER}
        <ModalLayout toggle={closeModal} title="Edit Location" isOpen={action === Action.Edit}>
          <AddressEditForm
            locationId={location?.id!}
            papaId={papaId}
            accountId={accountId}
            onEditFinish={closeModal}
          />
        </ModalLayout>

        <DeleteLocationModal
          location={location}
          toggle={closeModal}
          isOpen={action === Action.Delete}
          papaId={papaId}
        />
      </Panel>
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
    grid-gap: 5px;
    margin-bottom: 15px;

    ${Panel.Item} {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: flex-start;

  button:not(:first-child) {
    margin-left: 1rem;
  }
`;
