import { useField } from "formik";
import React, { useMemo } from "react";
import styled from "styled-components";

import FormContext from "components/Form/FormContext";
import FormLabel from "components/Form/FormLabel";
import { opacity } from "utils/opacity";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  controlId: string;
  label: string;
  ariaLabelledby?: string;
  value?: any;
  useFormik?: boolean;
}

export const Container = styled("div")`
  display: flex;
  align-items: center;
`;

export const Label = styled(FormLabel)`
  color: ${({ theme }) => theme.text.main};
  font-size: 0.875rem;
  padding-left: 0.5rem;
  margin-bottom: 0;
  cursor: pointer;
`;

export const StyledRadio = styled.input.attrs(({ type }: Props) => ({
  type: type,
}))`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1rem;
  height: 1rem;
  background: white;
  border: 1px solid ${({ theme }) => theme.borderColorDark};
  border-radius: 50%;
  margin: 0;
  position: relative;
  outline: none;
  cursor: pointer;

  /* --- Radio icon --- */
  &:after {
    content: "";
    left: 25%;
    top: 25%;
    position: absolute;
    transition: opacity 0.2s;
    width: 50%;
    height: 50%;
    background: white;
    border-radius: 50%;
    opacity: 0;
  }

  /* --- Radio states --- */
  &:checked {
    background: ${({ theme }) => theme.variants.primary};
    border: none;
    :after {
      opacity: 1;
    }
  }

  &:disabled {
    background: #e9edf1;
    border: 1px solid ${({ theme }) => theme.borderColor};
    &:checked:after {
      background: ${({ theme }) => opacity(theme.text.main, 0.3)};
    }
  }
`;

const Radio = ({ controlId, name, label, value, onChange, useFormik = true, ...rest }: Props) => {
  const context = useMemo(() => ({ controlId }), [controlId]);
  const [{ value: fieldValue }, , helpers] = useField(name as string);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (useFormik) {
      helpers.setValue(value);
    }

    if (onChange) {
      onChange(event);
    }
  };

  const checked = useFormik ? fieldValue === value : value;
  return (
    <Container>
      <FormContext.Provider value={context}>
        <StyledRadio
          id={controlId}
          type="radio"
          onChange={handleChange}
          checked={checked}
          ariaLabelledby={label}
          {...rest}
        />
        <Label htmlFor={controlId}>{label}</Label>
      </FormContext.Provider>
    </Container>
  );
};

export default Radio;
