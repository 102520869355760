import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Nav from "components/Nav";

import { InPersonVisitBaseRate } from "./InPersonVisitBaseRate/InPersonVisitBaseRateList";
import { VirtualVisitBaseRate } from "./VirtualVisitBaseRate/VirtualVisitBaseRateList";

const Routes = () => {
  return (
    <>
      <Nav>
        <Nav.Link to="/accounting/pal-settings/in-person-visit-base-rate">IPV Base Rate</Nav.Link>
        <Nav.Link to="/accounting/pal-settings/virtual-visit-base-rate">VV Base Rate</Nav.Link>
      </Nav>
      <Switch>
        <Route
          exact
          path="/accounting/pal-settings/in-person-visit-base-rate"
          component={InPersonVisitBaseRate}
        />
        <Route
          exact
          path="/accounting/pal-settings/virtual-visit-base-rate"
          component={VirtualVisitBaseRate}
        />
        <Redirect
          from="/accounting/pal-settings"
          to="/accounting/pal-settings/in-person-visit-base-rate"
        />
      </Switch>
    </>
  );
};

export default Routes;
