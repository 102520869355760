import { isValidPhoneNumber } from "react-phone-number-input";
import { array, boolean, object, string } from "yup";

import { PapaTeam } from "generated/types";

export interface Schema {
  fullName: string;
  email: string;
  phoneNumber: string;
  isAdmin: boolean;
  papaTeam: PapaTeam | null;
  roleIds: string[];
}

export const schema = object().shape({
  fullName: string().required("Please enter the Full name"),
  email: string().email("Email invalid").required("Required"),
  phoneNumber: string().test("phoneNumber", "Invalid phone number", (value) =>
    value ? isValidPhoneNumber(value) : true
  ),
  isAdmin: boolean(),
  papaTeam: string().nullable(),
  roleIds: array().of(string()).nullable(),
});
