import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import GoogleLocationSearch from "components/GoogleLocationSearch";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";
import { LocationInput } from "generated/types";
import { UPDATE_LOCATION } from "utils/gql/location";

import { GET_LOCATION } from "./gql";
import { FormValues, LocationData } from "./models";
import { schema } from "./schema";

interface Props {
  locationId: string;
  papaId: string;
  accountId: string;
  onEditFinish: () => void;
}

export const AddressEditForm: React.FC<Props> = ({
  locationId,
  papaId,
  accountId,
  onEditFinish,
}) => {
  const { data, loading, error } = useQuery<LocationData>(GET_LOCATION, {
    variables: { id: locationId },
    skip: !locationId,
  });

  const [updateAddress] = useMutation(UPDATE_LOCATION);
  const location = data?.location?.data;

  const handleSubmit = async ({ nickname = "", address, address2 }: FormValues) => {
    try {
      const input: LocationInput = {
        address: address?.address,
        lat: address?.lat,
        lng: address?.lng,
        state: address?.state,
        city: address?.city,
        countryIso: address?.countryIso,
        zipcode: address?.zipcode,
        accountId,
        address2,
        nickname,
        papaId,
      };

      const { data } = await updateAddress({
        variables: { id: location?.id, input },
      });

      if (data?.updateLocation?.data?.id) {
        toast.success("Address was edited with success!");
        onEditFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues: FormValues = {
    address: {
      address: location?.address,
    },
    address2: location?.address2 || "",
    nickname: location?.nickname || "",
  };

  if (loading) return <ModalContainer>loading...</ModalContainer>;

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <QueryErrors error={error} />

          <Form.Group>
            <Form.Label>Location Name</Form.Label>
            <Form.Input name="nickname" />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Address</Form.Label>
            <GoogleLocationSearch name="address" placeholder="" />
            <Form.Feedback>Google places search</Form.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address 2nd line</Form.Label>
            <Form.Input name="address2" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onEditFinish}>
            Close
          </Button>
          <Form.SubmitButton action noContent>
            Save
          </Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
