import { useMutation } from "@apollo/client";
import React, { FunctionComponent, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { VisitDetailsDocument, VisitObjective, VisitObjectiveInput } from "generated/types";
import { WithId } from "utils/nonNull";

import MarkableVisitObjective from "./MarkableVisitObjective";
import { UPDATE_OBJECTIVES } from "./gql";

interface Props {
  visitId: string;
  objectives: WithId<VisitObjective>[];
}

const MarkableVisitObjectives: FunctionComponent<Props> = ({ visitId, objectives }) => {
  const [disabled, setDisabled] = useState(false);
  const [saveObjectives] = useMutation(UPDATE_OBJECTIVES, {
    refetchQueries: [{ query: VisitDetailsDocument, variables: { id: visitId } }],
  });

  const onChange = async ({ id, checked }: VisitObjectiveInput) => {
    const input = {
      objectives: objectives.map((objective) => ({
        id: objective.id,
        checked: objective.id === id ? checked : objective.checked,
      })),
    };

    try {
      setDisabled(true);
      const { data } = await saveObjectives({
        variables: {
          id: visitId,
          input,
        },
      });

      if (data?.updateVisit?.data?.id) {
        toast.success("Visit objectives updated with success!");
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      {objectives.map((objective) => (
        <Objective key={objective.id}>
          <MarkableVisitObjective
            visitId={visitId}
            objective={objective}
            onChange={onChange}
            disabled={disabled}
          />
        </Objective>
      ))}
    </>
  );
};

export default MarkableVisitObjectives;

const Objective = styled.div`
  margin: 0.5rem 0;
  display: flex;
`;
