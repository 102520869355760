import { useEffect, useState } from "react";

import { useLocalStorage } from "hooks/useLocalStorage";

import { TableSettings } from "./schema";

type UseTableStorage = [TableSettings, (value: TableSettings) => void];

export const useTableStorage = (tableKey: string, settings: TableSettings): UseTableStorage => {
  const [savedSettings, setSavedSettings] = useLocalStorage<TableSettings>(tableKey, settings);
  const [tableSettings, setTableSettings] = useState(settings);

  useEffect(() => {
    const newSettings = savedSettings?.columns ? savedSettings : settings;

    setTableSettings(newSettings);
  }, [savedSettings, settings]);

  return [tableSettings, setSavedSettings];
};
