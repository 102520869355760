import { boolean, number, object, string } from "yup";

import { emailRegexp } from "utils/regexp";

export interface FormValues {
  fullName: string;
  email: string;
  about: string;
  inboundMemberSupportNumber: string;
  type: string;
  organizationId: string;
  backupFullName: string;
  backupPhoneNumber: string;
  testAccount: boolean;
  requiresDrugTest: boolean;
  requiresSignature: boolean;
  eligibleLifeS3Folder: string;
  eligibleEmployeeS3Folder: string;
  eligibilityMethod: string;
  permissions: {
    admin: boolean;
  };
  customVisitLeadHours: number;
  useCustomVisitLeadHours: boolean;
}

export const schema = object().shape({
  fullName: string().required("Required"),
  email: string().matches(emailRegexp, "Please, enter a valid email").required("Required"),
  inboundMemberSupportNumber: string().nullable(),
  type: string().required("Required"),
  organizationId: string().nullable(),
  backupPhoneNumber: string(),
  backupFullName: string(),
  about: string(),
  testAccount: boolean(),
  requiresDrugTest: boolean(),
  requiresSignature: boolean(),
  eligibleLifeS3Folder: string(),
  eligibleEmployeeS3Folder: string(),
  eligibilityMethod: string().nullable(),
  permissions: object().shape({
    admin: boolean(),
  }),
  customVisitLeadHours: number().positive().integer(),
  useCustomVisitLeadHours: string(),
});
