import moment, { Moment } from "moment-timezone";

export const isEarlyTime = (time: string, format: string = "h:mm a") => {
  const timeToCompare = moment(time, format);
  const earlyTime = moment("8:00 am", format);

  return timeToCompare.isBefore(earlyTime);
};

export const isLateTime = (time: string, format: string = "h:mm a") => {
  const timeToCompare = moment(time, format);
  const beforeTime = moment("8:00 pm", format);
  const afterTime = moment("11:59 pm", format);

  return timeToCompare.isBetween(beforeTime, afterTime, null, "[]");
};

export const isImpossibleStartEndTime = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  timeFormat: string = "h:mm a"
) => {
  const startDateMoment = moment(startDate);
  const startTimeMoment = moment(startTime, timeFormat);
  const endDateMoment = moment(endDate);
  const endTimeMoment = moment(endTime, timeFormat);

  startDateMoment.set({
    hour: startTimeMoment.get("hour"),
    minute: startTimeMoment.get("minute"),
    second: startTimeMoment.get("second"),
  });

  endDateMoment.set({
    hour: endTimeMoment.get("hour"),
    minute: endTimeMoment.get("minute"),
    second: endTimeMoment.get("second"),
  });

  return endDateMoment.isBefore(startDateMoment);
};

export const isOvernightTime = (
  startDate: string,
  startTime: string,
  duration?: number,
  timeFormat: string = "h:mm a"
) => {
  const startDateWithoutDuration = moment(startDate);
  const startDateMoment = moment(startDate);
  const startTimeMoment = moment(startTime, timeFormat);

  startDateMoment.set({
    hour: startTimeMoment.get("hour"),
    minute: startTimeMoment.get("minute"),
    second: startTimeMoment.get("second"),
  });

  startDateMoment.add(duration, "minute");

  return !(startDateWithoutDuration.get("date") === startDateMoment.get("date"));
};

export const isLongVisit = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  timeFormat: string = "h:mm a"
) => {
  const startDateMoment = moment(startDate);
  const startTimeMoment = moment(startTime, timeFormat);
  const endDateMoment = moment(endDate);
  const endTimeMoment = moment(endTime, timeFormat);

  startDateMoment.set({
    hour: startTimeMoment.get("hour"),
    minute: startTimeMoment.get("minute"),
    second: startTimeMoment.get("second"),
  });
  endDateMoment.set({
    hour: endTimeMoment.get("hour"),
    minute: endTimeMoment.get("minute"),
    second: endTimeMoment.get("second"),
  });

  return endDateMoment.diff(startDateMoment, "hours") >= 4;
};

export const isWithin3Days = (
  visitDate: string,
  visitTime: string,
  timeFormat: string = "h:mm a"
) => {
  const startDate = moment();
  const earliestAllowedVisitDay = moment().add(3, "days").day();

  const visitDateMoment = moment(visitDate);
  const visitTimeMoment = moment(visitTime, timeFormat);

  visitDateMoment.set({
    hour: visitTimeMoment.get("hour"),
    minute: visitTimeMoment.get("minute"),
    second: visitTimeMoment.get("second"),
  });

  if (
    visitDateMoment.diff(startDate, "hours") < 72 &&
    visitDateMoment.diff(startDate, "hours") >= 0
  ) {
    return !(earliestAllowedVisitDay === visitDateMoment.day());
  } else {
    return false;
  }
};

export const timezoneGenericAbbrev = (tz: string): string => {
  const timezone = moment.tz(tz).format("z");
  const timezoneMap: { [key: string]: string } = {
    EST: "ET",
    EDT: "ET",
    CST: "CT",
    CDT: "CT",
    MST: "MT",
    MDT: "MT",
    PST: "PT",
    PDT: "PT",
    AKST: "AKT",
    AKDT: "AKT",
    HST: "HT",
    HDT: "HT",
  };

  return timezoneMap[timezone] || "??";
};

export const timezoneName = (tz: string): string => {
  const timezone = moment.tz(tz).format("z");
  const timezoneMap: { [key: string]: string } = {
    EST: "Eastern Time",
    EDT: "Eastern Time",
    CST: "Central Time",
    CDT: "Central Time",
    MST: "Mountain Time",
    MDT: "Mountain Time",
    PST: "Pacific Time",
    PDT: "Pacific Time",
    AKST: "Alaskan Time",
    AKDT: "Alaskan Time",
    HST: "Hawaiian Time",
    HDT: "Hawaiian Time",
  };

  return timezoneMap[timezone] || "??";
};

const getTime = (dateTime: Moment): Moment => {
  return moment({ h: dateTime.hours(), m: dateTime.minutes() });
};

export const isTimeBetween = (
  time: string,
  start: string,
  end: string,
  timezone: string
): boolean => {
  // It's important that we clear out the date so that we're only comparing
  // the time (momentjs will still create a moment object with a date but
  // will use the current date, meaning it is effectively ignored in this
  // comparison).
  const timeValue = getTime(moment.tz(time, "h:mm A", timezone));
  const startTime = getTime(moment.tz(start, timezone));
  const endTime = getTime(moment.tz(end, timezone));

  return timeValue.isBetween(startTime, endTime, "minute", "[]");
};
