export const LOW = "Low Needs";
export const MEDIUM = "Medium  Needs";
export const HIGH = "High Needs";
export const INCOMPLETE = "Incomplete";

export const assessmentMessage = {
  [HIGH]:
    "We recommend this member follows the high visit recurrence program based on their needs. Please confirm the type, length and frequency of visits this member is approved for using the scheduling guide.",
  [MEDIUM]:
    "We recommend this member follows the medium visit recurrence program based on their needs. Please confirm the type, length and frequency of visits this member is approved for using the scheduling guide.",
  [LOW]:
    "We recommend this member follows the low visit recurrence program based on their needs. Please confirm the type, length and frequency of visits this member is approved for using the scheduling guide.",
  [INCOMPLETE]: null,
};
