import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { vehicleTypeOptions } from "constants/vehicle";
import { Pal, VehicleType } from "generated/types";
import { mapSelectValuesToOptions } from "utils/select";

import { UPDATE_MEMBER_VEHICLE } from "./gql";
import { VehicleData, yearOptions } from "./models";
import { FormValues, schema } from "./schema";

interface Props {
  palId: string;
  vehicleData: Partial<Pal>;
  onEditFinish: () => void;
}

export const VehicleEditForm: React.FC<Props> = ({ palId, vehicleData, onEditFinish }) => {
  const [updateVehicle] = useMutation(UPDATE_MEMBER_VEHICLE);

  const handleSubmit = async ({ color, model, type, year }: FormValues) => {
    try {
      const input: VehicleData = {
        vehicle: type,
        vehicleModel: model,
        vehicleYear: String(year),
        vehicleColor: color,
      };

      const { data } = await updateVehicle({ variables: { id: palId, input } });

      if (data?.updatePal?.data?.id) {
        toast.success("Vehicle was edited with success!");
        onEditFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues: FormValues = {
    year: vehicleData?.vehicleYear ? +vehicleData.vehicleYear : new Date().getFullYear(),
    color: vehicleData?.vehicleColor || "",
    model: vehicleData?.vehicleModel || "",
    type: (vehicleData?.vehicle as VehicleType) || VehicleType.None,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Select
              name="type"
              defaultValue={mapSelectValuesToOptions(initialValues.type, vehicleTypeOptions) as any}
              options={vehicleTypeOptions as any}
              aria-label="type"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Model</Form.Label>
            <Form.Input name="model" aria-label="model" />
          </Form.Group>

          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Select
              name="year"
              maxMenuHeight={200}
              defaultValue={mapSelectValuesToOptions(initialValues.year, yearOptions) as any}
              options={yearOptions as any}
              aria-label="year"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Color</Form.Label>
            <Form.Input name="color" aria-label="color" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onEditFinish}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
