import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import { StatusLogsTable } from "components/DataTables/StatusLogsTable";
import { PalSingleResult, PalStatusLog } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { nonNull } from "utils/nonNull";

import { GET_PAL_STATUS_LOGS } from "../gql";

interface Data {
  pal?: PalSingleResult;
}

const StatusLogs = () => {
  const { page, limit } = usePageInfo();
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<Data>(GET_PAL_STATUS_LOGS, {
    variables: { id, pagination: { page, limit } },
  });

  const statusLogs = data?.pal?.data?.statusLogs;
  const logs = nonNull(statusLogs?.data);
  const pagination = statusLogs?.pagination;

  return (
    <>
      <Helmet>
        <title>Pal Status Logs - Papa Admin</title>
      </Helmet>
      <StatusLogsTable<PalStatusLog>
        logs={logs}
        pagination={pagination}
        isLoading={loading}
        error={error!}
      />
    </>
  );
};

export default StatusLogs;
