import React from "react";
import styled from "styled-components";

export interface Props {
  to: string;
  text: string;
}

const ExternalLink = ({ to, text }: Props) => {
  return (
    <StyledLink href={to} target="_blank">
      {text}
    </StyledLink>
  );
};

export default ExternalLink;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.variants.primary};
`;
