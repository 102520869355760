import { useQuery } from "@apollo/client";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import isEqual from "lodash/isEqual";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";

import MainContainer from "components/MainContainer";
import WithTopBar from "components/MainContainer/WithTopBar";
import Sidebar from "components/Sidebar";
import config from "config";
import { CurrentUserContext } from "contexts/currentUserContext";
import AccountingRoutes from "pages/Accounting";
import TheAccountant from "pages/Accounting";
import ActivityLogs from "pages/Accounts/ActivityLogs";
import AdminsList from "pages/Admins/List";
import BlockedPals from "pages/BlockedPals";
import BusinessesRoutes from "pages/Businesses/BusinessesRoutes";
import CareConciergeRoutes from "pages/CareConcierge/CareConciergeRoutes";
import CaregiversRoutes from "pages/Caregivers/CaregiversRoutes";
import EligibleEmployees from "pages/EligibleEmployees/List";
import EligibleMembers from "pages/EligibleMembers/List";
import Home from "pages/Home";
import LocationsRoutes from "pages/Locations/LocationsRoutes";
import MembersRoutes from "pages/Members/MembersRoutes";
import ObjectivesList from "pages/Objectives/List/List";
import OrganizationsRoutes from "pages/Organizations/OrganizationsRoutes";
import PalConduct from "pages/PalConduct";
import PalOnboardingsRoutes from "pages/PalOnboardings/PalOnboardingsRoutes";
import PalReviews from "pages/PalReviews";
import PalVerifyVaccination from "pages/PalVerifyVaccination";
import PalsRoutes from "pages/Pals/PalsRoutes";
import PapaConduct from "pages/PapaConduct";
import PapasRoutes from "pages/Papas/PapasRoutes";
import PapasTrialRoutes from "pages/PapasTrial/PapasTrialRoutes";
import PaymentsRoutes from "pages/Payments/PaymentsRoutes";
import ScheduleVisit from "pages/ScheduleVisit";
import CloneVisit from "pages/ScheduleVisit/CloneVisit";
import EditVisit from "pages/ScheduleVisit/EditVisit/EditVisit";
import Surveys from "pages/Surveys";
import TestSearch from "pages/TestSearch";
import VisitMatch from "pages/VisitMatch";
import CloseOutPOV from "pages/Visits/CloseOutPOV/CloseOutPOV";
import VisitsDashboard from "pages/Visits/VisitsDashboard";
import VisitsRoutes from "pages/Visits/VisitsRoutes";
import VisitsWatchList from "pages/Visits/VisitsWatchList";
import VisitsWithCursors from "pages/Visits/VisitsWithCursors";
import { CurrentAccount, CurrentAccountData, GET_CURRENT_ACCOUNT } from "utils/gql/current-account";
import { identify } from "utils/segment";

import { setGrowthbookAgentData } from "./growthbook";

type MainProps = {
  currentPath: string;
};

const Container = styled("div")`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.background.body};
`;

const Main = styled("div")<MainProps>`
  padding: 1.875rem;
  margin-bottom: 3.75rem;
`;

const Dashboard = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.substring(1);
  const growthbook = useGrowthBook();

  const nprogressLoad = () => {
    nprogress.start();
    setTimeout(() => nprogress.done(), 100);
  };

  useEffect(() => {
    nprogressLoad();
    return nprogressLoad;
  });

  const { data, loading, error } = useQuery<CurrentAccountData>(GET_CURRENT_ACCOUNT);

  const [currentAccount, setCurrentAccount] = useState<CurrentAccount | null>(null);

  useEffect(() => {
    if (data?.currentAccount?.data && !isEqual(currentAccount, data.currentAccount.data)) {
      identify(data.currentAccount.data.id, {
        email: data.currentAccount?.data?.email,
        roles: data?.currentAccount?.data?.roles?.data?.map((role) => role.name),
        papaTeam:
          data.currentAccount?.data?.papaTeam ??
          "This account has not yet been assigned to a Papa Team",
      });
      setCurrentAccount(data?.currentAccount?.data);
      setGrowthbookAgentData(growthbook, data?.currentAccount?.data);
    }
  }, [data?.currentAccount?.data, currentAccount, growthbook]);

  return (
    <CurrentUserContext.Provider value={{ data, loading, error }}>
      <Container>
        <Sidebar />

        <MainContainer>
          <Main currentPath={currentPath}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <WithTopBar
                    options={{ title: "All Visits", icon: AccessTime, breadcrumbs: ["Visits"] }}
                  >
                    <VisitsWithCursors />
                  </WithTopBar>
                )}
              />
              <Route
                path="/visits-pending"
                render={() => (
                  <WithTopBar
                    options={{ title: "Pending Visits", icon: AccessTime, breadcrumbs: ["Visits"] }}
                  >
                    <Home />
                  </WithTopBar>
                )}
              />
              <Route
                path="/visits-completed"
                render={() => (
                  <WithTopBar
                    options={{
                      title: "Completed Visits",
                      icon: AccessTime,
                      breadcrumbs: ["Visits"],
                    }}
                  >
                    <VisitsWithCursors />
                  </WithTopBar>
                )}
              />
              <Route path="/visits-dashboard" component={VisitsDashboard} />
              <Route path="/accounts/:id/activity-logs" component={ActivityLogs} />
              <Route path="/businesses" component={BusinessesRoutes} />
              <Route path="/client-programs" component={BusinessesRoutes} />
              <Route path="/locations" component={LocationsRoutes} />
              <Route path="/visits-watch-list" component={VisitsWatchList} />
              <Route path="/members" component={MembersRoutes} />
              <Route path="/the-accountant" component={TheAccountant} />
              <Route path="/objectives" component={ObjectivesList} />
              <Route path="/pals" component={PalsRoutes} />
              <Route path="/papas" component={PapasRoutes} />
              {config.featureFlag.papasTrial && (
                <Route path="/papas-trial" component={PapasTrialRoutes} />
              )}
              <Route path="/payments" component={PaymentsRoutes} />
              <Route path="/accounting" component={AccountingRoutes} />
              <Route path="/prospect-pals" component={PalOnboardingsRoutes} />
              <Route path="/verify-vaccination" component={PalVerifyVaccination} />
              <Route path="/schedule-visit/:papaId?" component={ScheduleVisit} />
              <Route path="/edit-visit/:visitId" component={EditVisit} />
              <Route path="/clone-visit/:visitId" component={CloneVisit} />
              <Route path="/surveys" component={Surveys} />
              <Route path="/visits" component={VisitsRoutes} />
              <Route path="/blocked-pals" component={BlockedPals} />
              <Route path="/pal-conduct" component={PalConduct} />
              <Route path="/papa-conduct" component={PapaConduct} />
              <Route path="/pal-reviews" component={PalReviews} />
              <Route path="/admins" component={AdminsList} />
              {config.featureFlag.eligibleMembers && (
                <Route path="/eligible-members" component={EligibleMembers} />
              )}
              <Route path="/eligible-employees" component={EligibleEmployees} />
              <Route path="/test-search" component={TestSearch} />
              <Route path="/clients" component={OrganizationsRoutes} />
              <Route path="/organizations" component={OrganizationsRoutes} />
              <Route path="/caregivers" component={CaregiversRoutes} />

              <Route
                path={["/visit-match", "/visit-match/visit/:visitId/palAccount/:palId"]}
                component={VisitMatch}
              />
              <Route path="/close-out-pov" component={CloseOutPOV} />

              <Route
                path={[
                  "/careconcierge",
                  "/careconcierge-mydashboard",
                  "/careconcierge-myfollowups",
                  "/careconcierge-unassigned",
                  "/careconcierge-all",
                ]}
                component={CareConciergeRoutes}
              />
            </Switch>
          </Main>
        </MainContainer>
      </Container>
    </CurrentUserContext.Provider>
  );
};

export default Dashboard;
