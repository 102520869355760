import { useField, useFormikContext } from "formik";
import moment, { Moment } from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import React from "react";

import Feedback from "components/Form/FormFeedback";

import "./style.css";

type Props = {
  name: string;
  format?: string;
  disabled?: boolean;
  onClose?: () => void;
  placeholder?: string;
};

const Component = ({
  name,
  format = "LT",
  disabled = false,
  onClose = () => {},
  placeholder = "Select the time",
}: Props) => {
  const [field, { touched, error }, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  function handleChange(value: Moment) {
    if (!value) {
      helpers.setValue(null);

      return;
    }

    helpers.setValue(value?.format(format));
  }

  const value = field.value ? moment(field.value, format) : undefined;

  return (
    <div>
      <TimePicker
        onClose={onClose}
        showSecond={false}
        value={value}
        className="time-picker"
        minuteStep={5}
        // @ts-ignore
        onChange={handleChange}
        format="h:mm a"
        disabled={isSubmitting || disabled}
        use12Hours
        inputReadOnly
        placeholder={placeholder}
      />
      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </div>
  );
};

export default Component;
