import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { SEARCH_QUERY } from "components/Search/gql";
import { Objective } from "types/objective";
import { SearchResult } from "types/search";

export const useObjectivesSearch = (data: Objective[]) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: SEARCH_QUERY,
        variables: {
          search: `*${value}*`,
          index: "visitobjectivetemplates",
        },
      });
      if (data?.searchResult?.data) {
        return data.searchResult.data.map(({ id, description }: SearchResult) => ({
          value: id,
          label: description,
          name: description,
        }));
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };
  const searchPillNames = data?.reduce(
    (obj, { id, description }) => ({
      ...obj,
      [id!]: description,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
