type ServiceRequestsFiltersFilterMakerProps = {
  dayFrom?: string;
  dayTo?: string;
  origin?: string;
  status?: string;
  priority?: string;
  serviceRequestTypeId?: string;
  serviceRequestNeedId?: string | Array<string>;
  id?: string;
  assignedToId?: string;
  healthPlanId?: string;
  papaId?: string;
};

export const countFilters = (filters: Partial<ServiceRequestsFiltersFilterMakerProps>) => {
  let count = 0;
  if (filters.dayFrom) count++;
  if (filters.dayTo) count++;
  if (filters.origin) count++;
  if (filters.priority) count++;
  if (filters.status) count++;
  if (filters.serviceRequestTypeId) count++;
  if (filters.serviceRequestNeedId) count++;
  if (filters.id) count++;
  if (filters.papaId) count++;
  if (filters.assignedToId) count++;
  return count;
};

export function serviceRequestsFiltersMaker({
  dayFrom,
  dayTo,
  origin,
  status,
  priority,
  serviceRequestTypeId,
  serviceRequestNeedId,
  id,
  assignedToId,
  healthPlanId,
  papaId,
}: ServiceRequestsFiltersFilterMakerProps) {
  return {
    ...(dayFrom || dayTo
      ? {
          openedAt: {
            range: {
              ...(dayFrom ? { after: dayFrom } : {}),
              ...(dayTo ? { before: dayTo } : {}),
            },
          },
        }
      : {}),
    ...(origin ? { origin: { eq: origin } } : {}),
    ...(priority
      ? typeof priority == "string"
        ? { priority: { eq: priority } }
        : {
            priority: { in: priority },
          }
      : {}),
    ...(serviceRequestTypeId ? { serviceRequestTypeId: { eq: serviceRequestTypeId } } : {}),
    ...(serviceRequestNeedId
      ? typeof serviceRequestNeedId == "string"
        ? { serviceRequestNeedId: { eq: serviceRequestNeedId } }
        : {
            serviceRequestNeedId: { in: serviceRequestNeedId },
          }
      : {}),
    ...(status
      ? typeof status == "string"
        ? { status: { eq: status } }
        : {
            status: { in: status },
          }
      : {}),
    ...(id ? { id: { eq: id } } : {}),
    ...(assignedToId ? { assignedToId: { eq: assignedToId === " " ? null : assignedToId } } : {}),
    ...(healthPlanId ? { business: { id_eq: healthPlanId } } : {}),
    ...(papaId ? { papaId: { eq: papaId.split(":")[1] } } : {}),
  };
}
