import React from "react";

import Dropdown from "components/Dropdown";
import { VisitMenuActions } from "constants/visit";
import { Visit, VisitState } from "generated/types";

interface Props {
  visit: Visit;
  setAction: (action: VisitMenuActions, id?: string | null) => void;
}

export const SubmitFeedbackButton: React.FC<Props> = ({ visit, setAction }) => {
  const isRated = visit.visitRating?.data?.ratingForPapa;
  const statesToShow = [VisitState.Reviewed, VisitState.Completed];
  const hideButton = isRated || !statesToShow.some((state) => state === visit?.state);

  if (hideButton) {
    return null;
  }

  return (
    <Dropdown.Item onClick={() => setAction(VisitMenuActions.Feedback, visit.id)}>
      Submit feedback
    </Dropdown.Item>
  );
};
