import React, { ReactElement } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { ModalLayout } from "components/Modal/ModalLayout";
import { Caregiver } from "generated/types";
import AddCreditCard from "modals/AddCreditCard";
import CreateCaregiverModal from "modals/CreateCaregiverModal";
import { ResetPasswordModal } from "modals/ResetPasswordModal/ResetPasswordModal";
import { CaregiverForm } from "pages/Caregivers/Details/CaregiverForm/CaregiverForm";
import CreatePapaModal from "pages/Papas/List/CreatePapa/CreatePapaModal";

interface Props {
  action: DataTableMenuActions;
  caregiver: Caregiver | null;
  onClose: () => void;
  onRefetch: () => void;
}

const Actions = ({ action, caregiver, onClose, onRefetch }: Props): ReactElement => {
  return (
    <>
      <CreateCaregiverModal
        isOpen={action === DataTableMenuActions.Create}
        toggle={onClose}
        onCreateFinish={onRefetch}
      />

      {caregiver && (
        <ModalLayout
          toggle={onClose}
          title="Edit Caregiver"
          isOpen={action === DataTableMenuActions.Edit}
        >
          <CaregiverForm caregiver={caregiver} onFormComplete={onClose} />
        </ModalLayout>
      )}

      <ResetPasswordModal
        email={caregiver?.account?.data?.email || ""}
        isOpen={action === DataTableMenuActions.ResetPassword}
        toggle={onClose}
      />

      <CreatePapaModal
        fromCaregiver={!!caregiver}
        accountId={caregiver?.account?.data?.id || undefined}
        isOpen={action === DataTableMenuActions.AddPapa}
        toggle={onClose}
      />

      <ModalLayout
        toggle={onClose}
        title="Add Credit Card"
        isOpen={action === DataTableMenuActions.AddCreditCard}
      >
        <AddCreditCard accountId={caregiver?.account?.data?.id || ""} closeModal={onClose} />
      </ModalLayout>
    </>
  );
};

export default Actions;
