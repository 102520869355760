import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import SmartTable from "components/SmartTable";
import { CreditCard } from "generated/types";
import { CreditCardListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { DeleteCreditCardModal } from "modals/DeleteCreditCardModal";

import { CREDIT_CARDS } from "./gql";
import { CreditCardTableActions } from "./models";
import { useCreditCardTableColumns } from "./useCreditCardsTableColumns";

interface Data {
  creditCards: CreditCardListResult;
}

interface Props {
  accountId?: string | null;
}

const CreditCardsTable: React.FC<Props> = (props) => {
  const { page, limit } = usePageInfo();
  const { id: accountId } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<Data>(CREDIT_CARDS, {
    variables: {
      filter: {
        account: {
          idEq: props.accountId ?? accountId,
        },
      },
      pagination: { page, limit },
    },
  });

  const creditCards = data?.creditCards?.data;
  const pagination = data?.creditCards?.pagination;
  const { columns, action, card, closeModal } = useCreditCardTableColumns();

  return (
    <>
      <SmartTable<CreditCard>
        keyField="id"
        data={creditCards}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
      />

      <DeleteCreditCardModal
        card={card!}
        isOpen={action === CreditCardTableActions.delete}
        toggle={closeModal}
      />
    </>
  );
};

export default CreditCardsTable;
