import React, { useState } from "react";

import MemberActionsMenu from "components/ActionsMenu/MemberActionMenu";
import { useMemberActions } from "components/ActionsMenu/MemberActionMenu/useMemberActions";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { Account, AccountSorting, SortDirection } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

interface Params {
  sorting: AccountSorting;
  onSortChange: (sort: AccountSorting) => void;
}

export const useMembersTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { member, action, setAction } = useMemberActions();
  const [sorting, setSorting] = useState<AccountSorting>(initialSorting);

  const onSetSorting = (sortKey: keyof AccountSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const columns: Column<Account>[] = [
    {
      header: { title: "Name" },
      dataField: "fullName",
    },
    {
      header: { title: "Contact" },
      dataField: "email",
      formatter({ email, phoneNumber }) {
        return (
          <>
            <Text>{email}</Text>
            <Text>{phoneNumber}</Text>
          </>
        );
      },
    },
    {
      header: { title: "Membership" },
      dataField: "plan.data.name",
      cellProps: { noWrap: true },
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt?.toString());
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(account) {
        return <MemberActionsMenu member={account} setAction={setAction} isListMenu />;
      },
    },
  ];

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction, null);
  };

  return {
    columns,
    action,
    account: member,
    closeModal,
  };
};
