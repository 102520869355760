import { Formik, Form as FormikForm } from "formik";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Form from "components/Form";
import { useUrlQuery } from "hooks/useUrlQuery";

export const CoordinatesForm = () => {
  const history = useHistory();
  const { lat, lng } = useUrlQuery();

  const initialValues = {
    coordinates: lat && lng ? `${lat}, ${lng}` : "",
  };

  const handleSubmit = async ({ coordinates }: { coordinates: string }) => {
    const [lat, lng] = coordinates.split(",").map((v) => v.trim());

    const urlQueryString = queryString.stringify({
      lat,
      lng,
    });

    history.push(history.location.pathname + "?" + urlQueryString);
  };
  return (
    <Formik initialValues={initialValues} validator={() => {}} onSubmit={handleSubmit}>
      <FormikForm>
        <StyledContainer>
          <Form.Input
            name="coordinates"
            placeholder="Enter pal's coordinates (copy from google maps)"
          />
          <Form.SubmitButton>Update Location</Form.SubmitButton>
        </StyledContainer>
      </FormikForm>
    </Formik>
  );
};

const StyledContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;
