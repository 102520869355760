import { useQuery } from "@apollo/client";
import { EllipsisH } from "@styled-icons/fa-solid";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link, useParams } from "react-router-dom";

import Dropdown from "components/Dropdown";
import SmartTable from "components/SmartTable";
import Text from "components/Text";
import { getLanguage } from "constants/language";
import { PalStatus, SortDirection, VisitSingleResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { OptOutVisitSuccessCalls } from "modals/OptOutVisitSuccessCalls";
import { statusOptions } from "pages/Pals/List/ChangeStatus/ChangeStatus.models";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

import { Filter } from "./Filter";
import { CLOSEST_PALS_ON_VISIT_LIST } from "./gql";

const PalsInTheArea = () => {
  const { id } = useParams<{ id: string }>();

  const [openOptOutVisitSuccessCall, setOpenOptOutVisitSuccessCall] = useState(false);
  const [palAction, setPalAction] = useState<{ id: string; fullName: string }>();
  const [sortDirection, setSortDirection] = useState<SortDirection>();
  const { status, language } = usePageInfo();
  const statusFilter = status?.length
    ? {
        status: {
          in: status.split(",") as PalStatus[],
        },
        languages: language ? { contains: language.split(",") } : undefined,
      }
    : {
        status: {
          in: [PalStatus.Active, PalStatus.Approved, PalStatus.Onboarded],
        },
      };

  const { data, loading, error } = useQuery<{
    visit?: VisitSingleResult | null;
  }>(CLOSEST_PALS_ON_VISIT_LIST, {
    variables: {
      id,
      visitId: id,
      sorting: sortDirection ? { completedVisitsLast30Days: sortDirection } : undefined,
      filter: [statusFilter],
    },
  });

  const nearbyPals = nonNull(data?.visit?.data?.closestPals?.data);

  return (
    <>
      <Helmet>
        <title>Pals in the area - Papa Admin</title>
      </Helmet>

      <Filter />

      <SmartTable
        keyField="id"
        localStorageKey="palsInTheArea"
        data={nearbyPals}
        loading={loading}
        error={error}
        columns={[
          {
            header: { title: "Full Name" },
            dataField: "fullName",
            formatter({ fullName, id }) {
              return <Link to={`/pals/${id}`}>{fullName}</Link>;
            },
          },
          {
            header: { title: "Phone" },
            dataField: "phone",
            formatter({ phoneNumber }) {
              return <Text underline>{phoneNumber}</Text>;
            },
          },
          {
            header: { title: "Distance" },
            dataField: "distance",
            formatter({ visitScopes }) {
              return visitScopes?.data?.[0]?.distance != null ? (
                <Text underline>{`${visitScopes.data[0].distance} mile(s) away`}</Text>
              ) : (
                "Unavailable"
              );
            },
          },
          {
            header: {
              title: "Recent Visits (30 days)",
              sort: sortDirection,
              onChange: (direction) => setSortDirection(direction),
            },
            dataField: "recentVisits",
            formatter({ palCalculatedField }) {
              return `${palCalculatedField?.data?.completedVisitsLast30Days ?? 0} visits`;
            },
          },
          {
            header: { title: "Languages" },
            dataField: "languages",
            formatter({ languages }) {
              return nonNull(languages?.data)
                .map((language) => getLanguage(language))
                .join(", ");
            },
          },
          {
            header: { title: "On-boarded" },
            dataField: "insertedAt",
            formatter({ insertedAt }) {
              return formatDateTime(insertedAt);
            },
          },
          {
            header: { title: "Status" },
            dataField: "status",
            formatter({ status }) {
              return mapValueToLabel(statusOptions, status);
            },
          },
          {
            header: { title: "Availability" },
            dataField: "availability",
            formatter({ fullName }) {
              return <Text underline>Contact {fullName}</Text>;
            },
          },
          {
            header: { title: "" },
            dataField: "actions",
            cellProps: {
              fixed: true,
              align: "center",
            },
            formatter({ id, fullName }) {
              return (
                <Dropdown placement="bottom-end">
                  <Dropdown.Actions>
                    <EllipsisH size={18} />
                  </Dropdown.Actions>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        if (!id || !fullName) return;
                        setPalAction({ id, fullName });
                        setOpenOptOutVisitSuccessCall(true);
                      }}
                    >
                      Opt-out Visit Success calls
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              );
            },
          },
        ]}
      />

      <OptOutVisitSuccessCalls
        pal={palAction!}
        isOpen={openOptOutVisitSuccessCall}
        toggle={() => setOpenOptOutVisitSuccessCall(false)}
      />
    </>
  );
};

export default PalsInTheArea;
