import React from "react";
import { Route, Switch } from "react-router-dom";

import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import Organization from "./Details";
import Organizations from "./List";

const OrganizationsRoutes = () => {
  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const clientsPath = clientCopyUpdateEnabled ? "/clients" : "/organizations";
  const clientsPathPrefix = clientCopyUpdateEnabled ? "clients" : "organizations";

  return (
    <Switch>
      <Route exact path={clientsPath} component={Organizations} />
      <Route exact path={[`/${clientsPathPrefix}/:id`]} component={Organization} />
    </Switch>
  );
};

export default OrganizationsRoutes;
