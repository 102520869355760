import React from "react";
import Helmet from "react-helmet";

import Grid from "components/Grid";
import Link from "components/Link";
import Panel from "components/Panel";
import Text from "components/Text";
import { Visit } from "generated/types";
import { papaConcealedFormat } from "utils/fieldsPermissions/papaConcealed";

type Props = {
  visit?: Visit | null;
};

const Papa = ({ visit }: Props) => {
  if (!visit || !visit?.papa?.data || !visit?.account?.data) return null;

  const papa = papaConcealedFormat(visit.papa.data);

  const { id: papaId, fullName: papaFullName, description } = papa;
  const { id: accountId, fullName: accountFullName } = visit.account.data;
  const warnings = visit.papa.data.warnings?.data ?? [];
  const requirements = visit.papa.data.requirements?.data ?? [];

  return (
    <Grid>
      <Helmet>
        <title>{papaFullName} Papa Details - Papa Admin</title>
      </Helmet>
      <Grid.Column>
        <Panel>
          <Panel.Item>
            <Text bold>Papa</Text>
            <Link to={`/papas/${papaId}`}>{papaFullName}</Link>
          </Panel.Item>
          <Panel.Item>
            <Text bold>Member</Text>
            <Link to={`/members/${accountId}`}>{accountFullName}</Link>
          </Panel.Item>
          <Panel.Item>
            <Text bold>About</Text>
            <Text>{description}</Text>
          </Panel.Item>
          <Panel.Item>
            <Text bold>Warnings</Text>
            <Text>{warnings.length ? warnings.join(", ") : "Empty"}</Text>
          </Panel.Item>
          <Panel.Item>
            <Text bold>Requirements</Text>
            <Text>{requirements.length ? requirements.join(", ") : "Empty"}</Text>
          </Panel.Item>
        </Panel>
      </Grid.Column>
    </Grid>
  );
};

export default Papa;
