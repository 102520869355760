import moment from "moment-timezone";

import { DATE_TIME_FORMAT } from "constants/date";

function formatScheduledFor(scheduledFor: string, locationTimezone: string) {
  const dateTimeOnLocationTimezone = moment
    .tz(scheduledFor, locationTimezone)
    .format(DATE_TIME_FORMAT);

  return `${dateTimeOnLocationTimezone}`;
}

export default formatScheduledFor;
