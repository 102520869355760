import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Select from "components/Select";
import { papaComplaintReasonOptions } from "constants/complaint";

import { CREATE_PAPA_COMPLAINT } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  papaId: string;
  isOpen: boolean;
  toggle: () => void;
  onCreateFinish?: () => void;
}

const CreatePapaComplaintModal: React.FC<Props> = ({
  papaId,
  onCreateFinish = () => {},
  isOpen,
  toggle,
}) => {
  const [createPapaComplaint] = useMutation(CREATE_PAPA_COMPLAINT);

  if (!isOpen) return null;

  const handleSubmit = async ({ comment, reason }: Schema) => {
    try {
      const { data } = await createPapaComplaint({
        variables: {
          input: {
            papaId,
            comment,
            reason,
          },
        },
      });

      if (data?.createMemberComplaint?.data?.id) {
        toast.success("Papa complaint was added with success!");
        onCreateFinish();
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues: Schema = {
    reason: null,
    comment: "",
  };

  return (
    <ModalLayout toggle={toggle} title="Add a complaint" isOpen={true}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label required>Reason</Form.Label>
                <Select
                  name="reason"
                  options={papaComplaintReasonOptions}
                  isSearchable={true}
                  placeholder="Select reason"
                  aria-label="reason"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label required>Comment</Form.Label>
                <Form.TextArea name="comment" aria-label="comment" fullWidth />
              </Form.Group>
            </ModalContainer>

            <ModalFooter>
              <Button variant="secondary" type="button" onClick={toggle}>
                Close
              </Button>
              <Button disabled={isSubmitting} type="submit" variant="primary">
                Save
              </Button>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};

export default CreatePapaComplaintModal;
