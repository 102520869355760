import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  PaymentInvoiceMenuActions as DataTableMenuActions,
  menuItems,
} from "components/ActionsMenu/PaymentInvoicesMenu/models";
import DataTableDropdownMenu from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import StateText from "components/StateText";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { paymentInvoiceStateLabels } from "constants/paymentInvoices";
import { PaymentInvoice, PaymentInvoiceSorting, SortDirection } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";

interface Params {
  sorting: Partial<PaymentInvoiceSorting>;
  onSortChange: (sort: Partial<PaymentInvoiceSorting>) => void;
}

export const usePaymentInvoicesTableColumns = ({
  sorting: initialSorting,
  onSortChange,
}: Params) => {
  const {
    capitalized: { singularEntityName: programOrBusiness },
    kebabCased,
  } = useCallItClientProgramOrBusiness();

  const [sorting, setSorting] = useState<Partial<PaymentInvoiceSorting>>(initialSorting);
  const [action, setAction] = useState(DataTableMenuActions.noAction);
  const [paymentInvoice, setPaymentInvoice] = useState<PaymentInvoice | null>(null);

  const onSetSorting = (sortKey: keyof PaymentInvoiceSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const columns: Column<PaymentInvoice>[] = [
    {
      header: { title: " ", type: "select" },
      cellProps: { width: "54px" },
      dataField: "select",
    },
    {
      header: { title: "State" },
      dataField: "state",
      formatter({ state }) {
        if (!state) {
          return EMPTY_PLACEHOLDER;
        }
        return (
          <StateText paymentInvoiceStateLegend={state}>
            {capitalize(paymentInvoiceStateLabels[state])}
          </StateText>
        );
      },
    },
    {
      header: { title: programOrBusiness },
      dataField: "visit.data.account.data.fullName",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "Pal" },
      dataField: "visit.data.pal.data.id",
      cellProps: { noWrap: true },
      formatter({ visit }) {
        if (visit?.data?.pal?.data) {
          return <Link to={`/pals/${visit.data.pal.data.id}`}>{visit.data.pal.data.fullName}</Link>;
        }
        return EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Papa" },
      dataField: "visit.data.papa.data.id",
      cellProps: { noWrap: true },
      formatter({ visit }) {
        const papaConcealedEntity = papaConcealed(visit?.data?.papa?.data);
        return (
          <Link to={`/papas/${papaConcealedEntity?.id}`}>{papaConcealedEntity?.fullName}</Link>
        );
      },
    },
    {
      header: { title: "Amount" },
      dataField: "amount",
      formatter({ amount }) {
        return formatNumberToCurrency(amount ?? 0);
      },
    },
    {
      header: {
        title: "Created",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(paymentInvoice) {
        return (
          <DataTableDropdownMenu
            items={menuItems(paymentInvoice, kebabCased.pluralEntityName)}
            actionHandler={(action) => menuActionsHandler(action, paymentInvoice)}
          />
        );
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, entity: PaymentInvoice) => {
    setAction(action);
    setPaymentInvoice(entity);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.noAction);
    setPaymentInvoice(null);
  };

  return { columns, setAction, paymentInvoice, action, closeModal };
};
