import * as yup from "yup";

import { googleLocationSchema } from "components/GoogleLocationSearch/schema";
import { Location } from "generated/types";

export interface Values {
  accountId: string;
  papaId: string;
  location: Partial<Location>;
  address2: string;
  nickname: string;
}

export const validationSchema = yup.object().shape({
  accountId: yup.string().nullable().required("Required"),
  papaId: yup.string().nullable(),
  location: googleLocationSchema.required("Required"),
  address2: yup.string().nullable(),
  nickname: yup.string().nullable().required("Required"),
});
