import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import PapaActionsMenu from "components/ActionsMenu/PapaActionsMenu";
import { PapaMenuActions } from "components/ActionsMenu/PapaActionsMenu/models";
import { Column } from "components/SmartTable/schema";
import { SuccessText } from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { genderOptions } from "constants/gender";
import { languageOptions } from "constants/language";
import { papaStateLabel } from "constants/papaState";
import { relationshipOptions } from "constants/relationship";
import { PapaSorting, SortDirection } from "generated/types";
import { useCallItClientProgramOrMember } from "hooks/useCallItClientProgramOrMember";
import { PapaConcealed } from "types";
import { getAgeFromDateBirth } from "utils/datetimes/getAgeFromDateBirth";
import { concealedField } from "utils/fieldsPermissions/concealedField";
import { isFieldConcealed } from "utils/fieldsPermissions/isFieldConcealed";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";
import { formatPhoneNumber } from "utils/strings/formatPhoneNumber";

import { usePapaActions } from "../../ActionsMenu/PapaActionsMenu/usePapaActions";
import { evaluationNeeded } from "./evaluationNeeded";

const EvaluationNeededLink = styled(Link)`
  display: block;
  color: #f8381e !important;
`;

interface Params {
  sorting: PapaSorting;
  onSortChange: (sort: PapaSorting) => void;
}

export const usePapaTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { capitalized, kebabCased, singularEntityName } = useCallItClientProgramOrMember();

  const { papa, action, setAction } = usePapaActions();
  const [sorting, setSorting] = useState<PapaSorting>(initialSorting);

  const onSetSorting = (sortKey: keyof PapaSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };
  const closeModal = () => {
    setAction(PapaMenuActions.noAction);
  };

  const columns: Column<PapaConcealed>[] = [
    {
      header: {
        title: "Name",
        sort: sorting.fullName,
        onChange: (direction) => onSetSorting("fullName", direction),
      },
      dataField: "name",
      formatter(papa) {
        const { fullName, id, status } = papa;

        return (
          <>
            {fullName}
            {!!status && <SuccessText>{papaStateLabel[status]}</SuccessText>}
            {evaluationNeeded(papa) && (
              <EvaluationNeededLink to={`/papas/${id}/assessments`}>
                Evaluation needed
              </EvaluationNeededLink>
            )}
          </>
        );
      },
    },
    {
      header: { title: capitalized.singularEntityName },
      dataField: singularEntityName,
      formatter({ account }) {
        const { fullName: memberName, id: memberId } = account?.data ?? {};

        return <Link to={`/${kebabCased.pluralEntityName}/${memberId}`}>{memberName}</Link>;
      },
    },
    {
      header: { title: "Relationship" },
      dataField: "relationship",
      formatter({ relationship }) {
        return mapValueToLabel(relationshipOptions, relationship) || "";
      },
    },
    {
      header: { title: "Phone number" },
      dataField: "phoneNumber",
      formatter({ phoneNumber }) {
        return phoneNumber ? formatPhoneNumber(phoneNumber) : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Date of birth" },
      dataField: "birthDate",
      formatter({ birthDate, dataVisibility }) {
        if (isFieldConcealed("birthDate", dataVisibility)) {
          return concealedField("birthDate", birthDate, dataVisibility);
        }

        return !!birthDate && birthDate !== EMPTY_PLACEHOLDER ? (
          <>
            <div>{formatDateTime(birthDate, { format: "L" })}</div>
            <div>Age: {getAgeFromDateBirth(birthDate)}</div>
          </>
        ) : (
          EMPTY_PLACEHOLDER
        );
      },
    },
    {
      header: { title: "Gender" },
      dataField: "gender",
      formatter({ gender }) {
        return mapValueToLabel(genderOptions, gender) || "";
      },
    },
    {
      header: { title: "Language" },
      dataField: "language",
      formatter({ language }) {
        return mapValueToLabel(languageOptions, language) || "";
      },
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(papa) {
        return <PapaActionsMenu papa={papa} setAction={setAction} isListMenu />;
      },
    },
  ];

  return {
    columns,
    action,
    papa,
    closeModal,
  };
};
