import { GrowthBook } from "@growthbook/growthbook-react";

import { AppConfig } from "config";
import { CurrentAccount } from "utils/gql/current-account";

export const createGrowthBookInstance = () => {
  const growthbook = new GrowthBook({
    apiHost: AppConfig("REACT_APP_GROWTHBOOK_API_HOST"),
    clientKey: AppConfig("REACT_APP_GROWTHBOOK_API_KEY"),
    // Dev mode allows you to use the Growthbook google chrome extension
    enableDevMode: AppConfig("REACT_APP_GROWTHBOOK_DEV_MODE"),
    trackingCallback: (experiment, result) => {
      window.analytics.track("Experiment Viewed", {
        experiment_id: experiment.key,
        hash_attribute: experiment.hashAttribute,
        hash_value: result.hashValue,
        in_experiment: result.inExperiment,
        value: result.value,
        variation_id: result.key,
      });
    },
  });

  growthbook.init({ streaming: false });

  return growthbook;
};

export const setGrowthbookAgentData = (
  growthbook: GrowthBook<Record<string, any>>,
  currentAccount: CurrentAccount
) => {
  const roles = currentAccount.roles?.data?.map((role) => role.name);
  growthbook.setAttributes({
    account_id: currentAccount.id,
    roles: roles,
  });
};
