import React from "react";

import Modal from "components/Modal";

import PalDelete, { DeletablePalType } from "./PalDelete";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  id?: string | null;
  accountId?: string | null;
  fullName: string;
  palType: DeletablePalType;
}

export const PalDeleteModal: React.FC<Props> = ({
  isOpen,
  toggle,
  id,
  accountId,
  fullName,
  palType,
}) => {
  if (!id || !accountId) return null;

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle}>
      <PalDelete
        palType={palType}
        id={id}
        accountId={accountId}
        fullName={fullName}
        closeModal={toggle}
      />
    </Modal>
  );
};
