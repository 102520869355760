import React from "react";

import Panel from "components/Panel";
import Text from "components/Text";
import { VisitProgram, useBusinessNameQuery } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { formatDateTime } from "utils/helpers/formatters";

interface Props {
  visitProgram: VisitProgram;
}

const Overview = ({ visitProgram }: Props) => {
  const {
    capitalized: { singularEntityName },
  } = useCallItClientProgramOrBusiness();

  const { id, businessId, insertedAt } = visitProgram;
  const { data } = useBusinessNameQuery({ variables: { id: businessId ?? "" }, skip: !businessId });
  const businessName = data?.business?.data?.name;

  return (
    <Panel.Side>
      <div>
        <Panel.Item>
          <Text bold>ID</Text>
          <Text>{id}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>{singularEntityName}</Text>
          <Text>{businessName}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Inserted at</Text>
          <Text>{formatDateTime(insertedAt)}</Text>
        </Panel.Item>
      </div>
    </Panel.Side>
  );
};

export default Overview;
