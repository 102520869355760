import { gql } from "@apollo/client";

export const DeleteAccount = gql`
  mutation destroyAccount($id: ID!) {
    destroyAccount(id: $id) {
      data {
        id
        deletedAt
      }
    }
  }
`;
