import { useApolloClient } from "@apollo/client";
import { useField } from "formik";
import isEqual from "lodash/isEqual";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { OptionTypeBase, ValueType } from "react-select/src/types";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import { InputContainer, StyledInput } from "components/Form/FormInput";
import Select from "components/Select";
import { ServiceRequestNeed } from "generated/types";
import { mapSelectValuesToOptions } from "utils/select";

import { GET_SERVICEREQUEST_NEEDS } from "./gql";

interface Props {
  typeId: string | null;
  name: string;
  initialValue?: string | string[] | null;
  selectOther?: (value: boolean) => void;
  placeholder?: string;
  isMulti?: boolean;
}

interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

const CareConciergeNeedSelect: FunctionComponent<Props> = ({
  typeId = "",
  name,
  selectOther,
  initialValue = "",
  placeholder = "",
  isMulti = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [lastTypeId, setLastTypeId] = useState("");
  const [, , helpers] = useField("needId");
  const [, , otherhelpers] = useField("needText");
  const [needsOptions, setNeedsOptions] = useState<ServiceRequestNeed[]>([]);
  const client = useApolloClient();
  const getNeedOptions = useCallback(
    (typeId: string) => {
      return client.query({
        query: GET_SERVICEREQUEST_NEEDS,
        variables: {
          filter: { id: { eq: typeId } },
        },
      });
    },
    [client]
  );

  useEffect(() => {
    const getNeeds = async (typeId: string) => {
      setLoading(true);
      const { data } = await getNeedOptions(typeId);
      if (data?.serviceRequestTypes?.data) {
        const needs = data.serviceRequestTypes.data[0].needs?.data;
        setNeedsOptions(needs);
        if (lastTypeId) {
          helpers.setValue("");
          otherhelpers.setValue("");
        } else if (initialValue) {
          helpers.setValue(initialValue);
        } else {
          helpers.setValue("");
          otherhelpers.setValue("");
        }
      } else {
        setNeedsOptions([]);
        helpers.setValue("");
        otherhelpers.setValue("");
      }
      setLoading(false);
    };
    if (typeId && !isEqual(lastTypeId, typeId)) {
      getNeeds(typeId);
      setLastTypeId(typeId);
      if (lastTypeId) {
        if (selectOther) {
          selectOther(false);
        }
        otherhelpers.setValue("");
      }
      helpers.setValue("");
    }
  }, [typeId, getNeedOptions, helpers, otherhelpers, lastTypeId, selectOther, initialValue]);

  const changeNeed = (event: ValueType<ChangeSelectEvent>) => {
    const value = event ? event.toString() : "";
    const label = mapLabelFromValue(value, needsOptions);
    if (label === "Other") {
      if (selectOther) {
        selectOther(true);
      }
    } else {
      if (selectOther) {
        selectOther(false);
      }
      otherhelpers.setValue("");
    }
  };

  const mapLabelFromValue = (value: string, options: ServiceRequestNeed[]) => {
    const item = options.find((e) => e.id === value);
    return item?.code === "other" ? "Other" : item?.name;
  };

  const mapNeedsToOption = (options: ServiceRequestNeed[]) =>
    options.map(({ id, code, name }) => ({
      value: id ?? "",
      label: code === "other" ? "Other" : name ?? "",
    }));
  if (loading) return <SpinningIcon size={18} />;
  return (
    <>
      {typeId ? (
        <Select
          aria-label={name}
          name={name}
          placeholder={placeholder}
          defaultValue={mapSelectValuesToOptions(initialValue, mapNeedsToOption(needsOptions))}
          options={mapNeedsToOption(needsOptions)}
          onChange={selectOther && changeNeed}
          isSearchable={false}
          isMulti={isMulti}
        />
      ) : (
        <InputContainer disabled>
          <StyledInput value="Please select a type first" disabled />
        </InputContainer>
      )}
    </>
  );
};
export const mapValueFromLabel = (label: string, options: ServiceRequestNeed[]) =>
  options.find((e) => e.name === label)?.id;

export default CareConciergeNeedSelect;
