import { gql } from "@apollo/client";

export const GET_PAPAS = gql`
  query PapasList($filter: [PapaFilter], $pagination: PaginationInput, $sorting: PapaSorting) {
    papas(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        isEligible
        eligibleLife {
          data {
            id
          }
        }
        fullName
        phoneNumber
        relationship
        birthDate
        gender
        insertedAt
        language
        status
        accountId
        dataVisibility {
          concealed
        }
        account {
          data {
            id
            fullName
          }
        }
        business {
          data {
            id
            name
            currentBusinessPolicy {
              data {
                id
                warmTransfer
                warmTransferPhoneNumber
              }
            }
          }
        }
        surveySubmissions {
          data {
            submittedAt
          }
        }
        visits(filter: { state: { eq: COMPLETED } }) {
          data {
            id
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
