import { useField } from "formik";
import React, { useEffect } from "react";
import { CheckCircle } from "styled-icons/boxicons-solid";
import { CircleWithCross } from "styled-icons/entypo";

import { Loader } from "components/Loader";
import QueryErrors from "components/QueryErrors";
import { usePalVisitInfoQuery } from "generated/types";
import { formatMilesFromMeters } from "utils/helpers/formatters";

interface Params {
  visitId?: string | null;
  palId?: string | null;
}

export const PalVisitInfo = ({ visitId, palId }: Params) => {
  const [, , { setValue: setGenderMismatch }] = useField("genderMismatch");
  const { data, error, loading } = usePalVisitInfoQuery({
    variables: { visitId: visitId!, palId: palId! },
    skip: !visitId || !palId,
    fetchPolicy: "network-only",
  });

  const {
    distanceInMeters,
    drugTestValid,
    genderValid,
    languageValid,
    radiusValid,
    vehicleValid,
    visibleToPalApp,
  } = data?.palVisitInfo?.data ?? {};

  const genderPreferenceLocked =
    !!data?.palVisitInfo?.data?.visit?.data?.papa?.data?.genderPreferenceLocked;

  const genderMismatch = genderPreferenceLocked && !genderValid;

  useEffect(() => {
    setGenderMismatch(genderMismatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderMismatch]);

  if (!visitId || !palId) return null;

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <QueryErrors error={error} />
      ) : (
        data && (
          <>
            <p>
              Drug Test Valid{" "}
              {drugTestValid ? (
                <CheckCircle color="green" size={20} />
              ) : (
                <CircleWithCross color="red" size={20} />
              )}
            </p>
            <p>
              Gender Valid{" "}
              {genderValid ? (
                <CheckCircle color="green" size={20} />
              ) : (
                <CircleWithCross color="red" size={20} />
              )}
            </p>
            <p>
              Language Valid{" "}
              {languageValid ? (
                <CheckCircle color="green" size={20} />
              ) : (
                <CircleWithCross color="red" size={20} />
              )}
            </p>
            <p>
              Vehicle Valid{" "}
              {vehicleValid ? (
                <CheckCircle color="green" size={20} />
              ) : (
                <CircleWithCross color="red" size={20} />
              )}
            </p>
            <p>
              Visible to Pal App{" "}
              {visibleToPalApp ? (
                <CheckCircle color="green" size={20} />
              ) : (
                <CircleWithCross color="red" size={20} />
              )}
            </p>
            <p>
              Distance: {formatMilesFromMeters(distanceInMeters)}{" "}
              {radiusValid ? (
                <CheckCircle color="green" size={20} />
              ) : (
                <CircleWithCross color="red" size={20} />
              )}
            </p>
          </>
        )
      )}
    </>
  );
};
