import { gql } from "@apollo/client";

export const GET_ORGANIZATION = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      data {
        id
        name
        insertedAt
        accounts {
          data {
            id
            fullName
            email
            phoneNumber
            insertedAt
            plan {
              data {
                name
              }
            }
            totalPapas
            papas {
              data {
                id
              }
            }
          }
        }
      }
    }
  }
`;
