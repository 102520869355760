import { VisitInput, VisitObjectiveTemplate } from "generated/types";
import { mergeDateAndTime } from "utils/date";
import { WithId } from "utils/nonNull";

import { Schema } from "./schema";

export const mapSubmitFormValues = (
  values: Schema,
  objectivesData: Pick<WithId<VisitObjectiveTemplate>, "id" | "description">[],
  timezone?: string | null
) => {
  const {
    completedAtDay,
    completedAtTime,
    startedAtDay,
    startedAtTime,
    commuteDistance,
    visitDistance,
    overrideDurationMins,
    discount,
    objectives,
    taskIds,
    delivery,
    successful,
  } = values;
  const input: VisitInput = {
    discount,
    taskIds,
  };
  if (commuteDistance != null) {
    input.commuteDistance = +commuteDistance!;
  }
  if (visitDistance != null) {
    input.visitDistance = +visitDistance!;
  }
  if (overrideDurationMins != null) {
    input.overrideDurationMins = +overrideDurationMins!;
  }
  const objectivesInput = objectivesData.map(({ id }) => ({
    id,
    checked: objectives[id] || false,
  }));

  return {
    input: {
      ...input,
      completedAt: mergeDateAndTime({
        day: completedAtDay,
        time: completedAtTime,
        timezone,
      }),
      startedAt: mergeDateAndTime({
        day: startedAtDay,
        time: startedAtTime,
        timezone,
      }),
      delivery,
      successful,
    },
    objectivesInput,
  };
};
