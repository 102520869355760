import { ApolloError } from "@apollo/client";
import React, { FC, useState } from "react";
import Helmet from "react-helmet";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { Drawer } from "components/Drawer";
import EditButton from "components/EditButton";
import HeadingEditable from "components/HeadingEditable";
import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import PapaGenderPreferenceLockedSwitch from "components/PapaGenderPreferenceLocked/Switch";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import VisitTypeSwitch from "components/VisitType/Switch";
import { EMPTY_PLACEHOLDER, NO_PREFERENCE_PLACEHOLDER } from "constants/empty-placeholder";
import { genderOptions } from "constants/gender";
import { languageOptions } from "constants/language";
import { livingSituationLabel } from "constants/livingSituations";
import { vehicleTypeOptions } from "constants/vehicle";
import { Papa, PapaSpecialConsideration } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import PapaBlockedPalModal from "modals/PapaBlockedPalModal";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { fancyEnum } from "utils/strings/fancyEnum";

import { Address } from "./Address";
import { EditPapaBio } from "./EditBio/EditBio";
import EditDetails from "./EditDetails/EditDetails";
import { EditPreferenceForm } from "./EditPreferenceForm/EditPreferenceForm";

type ColumnProps = {
  size?: number;
};

interface Props {
  papa?: Papa | null;
  loading: boolean;
  error?: ApolloError;
  onRefetch?: () => void;
  onAddLocationClick: () => void;
}

const Details: FC<Props> = ({ papa, loading, error, onAddLocationClick, onRefetch = () => {} }) => {
  const { id } = useParams<{ id: string }>();
  const { isOpen: showDetailsEditModal, toggle: toggleDetailsEditModal } = useModalToggle();
  const { isOpen: showBioEditModal, toggle: toggleBioEditModal } = useModalToggle();
  const { isOpen: showPreferenceModal, toggle: togglePreferenceModal } = useModalToggle();
  const { isOpen: showBlockedPalModal, toggle: toggleBlockedPalModal } = useModalToggle();
  const [blockedPalId, setBlockedPalId] = useState<string>("");

  if (error) return <QueryErrors error={error} />;

  const papaConcealedEntity = papaConcealed(papa);
  const {
    description,
    stillDrives,
    insertedAt,
    updatedAt,
    genderPreference,
    languagePreference,
    vehiclePreference,
    livingSituation,
    papaSpecialConsiderations,
  } = papaConcealedEntity ?? {};
  const papaSpecialConsiderationsList = nonNull(papaSpecialConsiderations?.data);
  const favoritePalsList = nonNull(papaConcealedEntity?.favoritePals?.data);
  const blockedPalsList = nonNull(papaConcealedEntity?.blockedPals?.data);
  const locations = nonNull(papaConcealedEntity?.locations?.data);
  const homeLocation = papaConcealedEntity?.homeLocation?.data ?? {};
  const enrolledBy = papaConcealedEntity?.enrolledBy?.data;
  const biographyQuestions = papaConcealedEntity?.biographyQuestions ?? [];
  const accountId = papaConcealedEntity?.account?.data?.id ?? "";
  const defaultToFavoritePalsOnly = papaConcealedEntity?.defaultToFavoritePalsOnly ?? false;

  const { fullName: enrolledByName, id: enrolledById } = enrolledBy ?? {};

  const handleEditFinish = () => {
    togglePreferenceModal();
    onRefetch();
  };

  const renderGender = () => {
    const gender = mapValueToLabel(genderOptions, genderPreference) || NO_PREFERENCE_PLACEHOLDER;

    let text;
    if (papa?.genderPreference == null) {
      text = papa?.genderPreferenceWasAsked ? "Any gender" : "Not asked";
    } else if (papa?.genderPreferenceIsFlexible == null) {
      text = gender;
    } else {
      const flexibility = papa.genderPreferenceIsFlexible ? "is flexible" : "not flexible";
      text = `${gender} (${flexibility})`;
    }

    return (
      <>
        <Text bold>Gender</Text>
        <Text>{text}</Text>
        <PapaGenderPreferenceLockedSwitch
          papaId={papa?.id ?? ""}
          locked={papa?.genderPreferenceLocked ?? false}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Papa Details - Papa Admin</title>
      </Helmet>
      <Grid>
        <Column>
          <Panel loading={loading}>
            <HeadingEditable title="Additional Details" toggleModal={toggleDetailsEditModal} />
            {papa && (
              <Drawer
                onClose={toggleDetailsEditModal}
                title="Edit Details"
                open={showDetailsEditModal}
                size="md"
              >
                <EditDetails papaId={id} papa={papa} onEditFinish={toggleDetailsEditModal} />
              </Drawer>
            )}

            <Panel.Item>
              <Text bold>Notes</Text>
              <Text>{description || EMPTY_PLACEHOLDER}</Text>
            </Panel.Item>

            <Panel.Item>
              <Text bold>Still drives?</Text>
              <Text>{stillDrives}</Text>
            </Panel.Item>

            <Panel.Item>
              <Text bold>Special Considerations</Text>
              {papaSpecialConsiderationsList
                ? papaSpecialConsiderationsList
                    .filter((psc: PapaSpecialConsideration) => psc.value)
                    .map((psc: PapaSpecialConsideration) => (
                      <Text key={psc.id}>{fancyEnum(psc.specialConsideration?.data?.name)}</Text>
                    ))
                : EMPTY_PLACEHOLDER}
            </Panel.Item>

            <Panel.Item>
              <Text bold>Living Situation</Text>
              <Text>
                {livingSituation ? livingSituationLabel[livingSituation] : EMPTY_PLACEHOLDER}
              </Text>
            </Panel.Item>

            <Panel.Item>
              <Text bold>Joined at</Text>
              <Text>{formatDateTime(insertedAt)}</Text>
            </Panel.Item>

            <Panel.Item>
              <Text bold>Updated at</Text>
              <Text>{formatDateTime(updatedAt)}</Text>
            </Panel.Item>

            <Panel.Item>
              <Text bold>Enrolled by</Text>
              {enrolledById ? (
                <Link to={`/members/${enrolledById}`}>{enrolledByName}</Link>
              ) : (
                EMPTY_PLACEHOLDER
              )}
            </Panel.Item>
          </Panel>
        </Column>

        <Column size={2}>
          <Panel loading={loading}>
            <HeadingEditable title="Preferences" toggleModal={togglePreferenceModal} />

            <Panel.Item>{renderGender()}</Panel.Item>

            <Panel.Item>
              <Text bold>Language</Text>
              <Text>
                {languagePreference
                  ? mapValueToLabel(languageOptions, languagePreference)
                  : NO_PREFERENCE_PLACEHOLDER}
              </Text>
            </Panel.Item>

            <Panel.Item>
              <Text bold>Vehicle</Text>
              <Text>
                {mapValueToLabel(vehicleTypeOptions, vehiclePreference) ||
                  NO_PREFERENCE_PLACEHOLDER}
              </Text>
            </Panel.Item>

            <Panel.Item>
              <Text bold>Preferred pals</Text>
              {favoritePalsList?.length
                ? favoritePalsList.map((item, index) => (
                    <span key={`pal_${index}`}>
                      <Link to={`/pals/${item.id}`}>{item.fullName}</Link>
                      {index === favoritePalsList.length - 1 ? "" : ", "}
                    </span>
                  ))
                : NO_PREFERENCE_PLACEHOLDER}
            </Panel.Item>

            <Panel.Item>
              <Text bold>Visit Type</Text>
              <VisitTypeSwitch papa={papa} />
            </Panel.Item>

            <Panel.Item>
              <Text bold>Default to Preferred Pals Only</Text>
              {defaultToFavoritePalsOnly ? "Yes" : "No"}
            </Panel.Item>

            <HorizontalLine />

            <Panel.Item>
              <BlockedPalHeading>
                <Text bold>Blocked pals</Text>
                <EditButton
                  onClick={() => {
                    setBlockedPalId("");
                    toggleBlockedPalModal();
                  }}
                >
                  Block a Pal
                </EditButton>
              </BlockedPalHeading>
              {blockedPalsList?.length
                ? blockedPalsList.map((item, index) => (
                    <span key={`blocked_pal_${index}`}>
                      <Link
                        to="#"
                        onClick={() => {
                          if (item?.id) {
                            setBlockedPalId(item.id);
                            toggleBlockedPalModal();
                          }
                        }}
                      >
                        {item.fullName}
                      </Link>
                      {index === blockedPalsList.length - 1 ? "" : ", "}
                    </span>
                  ))
                : NO_PREFERENCE_PLACEHOLDER}
            </Panel.Item>
          </Panel>

          <Address
            papaId={id}
            accountId={accountId}
            locations={locations}
            homeLocation={homeLocation}
            onAddLocationClick={onAddLocationClick}
            onUpdate={onRefetch}
          />
        </Column>
      </Grid>

      <Panel loading={loading}>
        <HeadingEditable title="Bio" toggleModal={toggleBioEditModal} />

        <ModalLayout toggle={toggleBioEditModal} title="Edit Bio" isOpen={showBioEditModal}>
          <EditPapaBio papaId={id} answers={biographyQuestions} onEditFinish={toggleBioEditModal} />
        </ModalLayout>

        {papa && (
          <ModalLayout
            toggle={togglePreferenceModal}
            title="Edit Preferences"
            isOpen={showPreferenceModal}
          >
            <EditPreferenceForm papa={papa} onEditFinish={handleEditFinish} />
          </ModalLayout>
        )}

        {biographyQuestions.map((item, index) => (
          <Panel.Item key={`bio_${index}`}>
            <Text bold>{item.question}</Text>
            <Text>{item.answer || EMPTY_PLACEHOLDER}</Text>
          </Panel.Item>
        ))}

        <PapaBlockedPalModal
          isOpen={showBlockedPalModal}
          toggle={toggleBlockedPalModal}
          papaId={id}
          palId={blockedPalId}
        />
      </Panel>
    </>
  );
};

const Grid = styled("div")`
  display: flex;
  margin: 0 -0.938rem;
`;

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
  margin: 0 0.938rem;
`;

const HorizontalLine = styled.hr`
  border: 1px solid #ededed;
  margin-bottom: 1.25rem;
`;

const BlockedPalHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Details;
