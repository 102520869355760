import React, { useState } from "react";
import { Link } from "react-router-dom";

import DataTableDropdownMenu, { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { Location } from "generated/types";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";

export const useLocationsTableColumns = () => {
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [location, setLocation] = useState<Location | null>(null);
  const menuItems = [{ label: "Delete", action: DataTableMenuActions.Delete }];
  const columns: Column<Location>[] = [
    {
      header: { title: "Address" },
      dataField: "address",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "Nickname" },
      dataField: "nickname",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "Zipcode" },
      dataField: "zipcode",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "City" },
      dataField: "city",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "State" },
      dataField: "state",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "Member" },
      dataField: "account.data",
      cellProps: { noWrap: true },
      formatter({ account }) {
        return <Link to={`/members/${account?.data?.id}`}>{account?.data?.fullName}</Link>;
      },
    },
    {
      header: { title: "Papa" },
      dataField: "papa.data",
      cellProps: { noWrap: true },
      formatter({ papa }) {
        const papaConcealedEntity = papaConcealed(papa?.data);
        return (
          <Link to={`/papas/${papaConcealedEntity?.id}`}>{papaConcealedEntity?.fullName}</Link>
        );
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(location) {
        return (
          <DataTableDropdownMenu
            items={menuItems}
            actionHandler={(action) => menuActionsHandler(action, location)}
          />
        );
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, location: Location) => {
    setAction(action);
    setLocation(location);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setLocation(null);
  };

  return {
    columns,
    action,
    location,
    closeModal,
  };
};
