import React from "react";
import styled from "styled-components";
import * as faSolid from "styled-icons/fa-solid";

import Tooltip from "components/Tooltip";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { colors, iconsDict } from "constants/tasks";
import { Task } from "generated/types";
import { opacity } from "utils/opacity";

type Props = {
  icons: Task[];
  small?: boolean;
  maximum?: number;
};

const VerticalIconsList = ({ icons = [], small = false, maximum = 0 }: Props) => {
  if (!icons.length) return <>{EMPTY_PLACEHOLDER}</>;

  let iconsRender = icons.filter((icon) => icon);

  if (maximum && maximum > 0) {
    iconsRender = iconsRender.slice(0, maximum);
  }

  const Plus = faSolid.Plus;

  const tasksRest = icons
    .filter((icon) => icon)
    .slice(maximum)
    .map((icon) => icon.name)
    .join(", ");

  return (
    <>
      {iconsRender.map(({ icon, id, name }) => {
        const Icon = (icon && iconsDict[icon]) || faSolid.Tasks;
        const bgColor = (icon && colors?.[icon]) || "#d2fffc";
        return (
          <Tooltip key={`icon_${id}`} title={name ?? ""}>
            <IconWrapper bgColor={bgColor} small={small}>
              <Icon size={small ? 10 : 16} color="#222428 " />
            </IconWrapper>
          </Tooltip>
        );
      })}
      {maximum > 0 && icons.length > maximum && (
        <Tooltip title={tasksRest}>
          <PlusWrapper small={small}>
            <Plus size={small ? 7 : 13} color="#222428 " />
          </PlusWrapper>
        </Tooltip>
      )}
    </>
  );
};

type IconWrapperType = {
  bgColor: string;
  small: boolean;
};

const IconWrapper = styled.span<IconWrapperType>`
  border-radius: ${({ small }) => (small ? "4px" : "9px")};
  width: ${({ small }) => (small ? "18px" : "26px")};
  height: ${({ small }) => (small ? "18px" : "26px")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => opacity(bgColor, 0.5)};
  margin-right: 4px;

  &:last-child {
    margin-right: 0px;
  }
`;

const PlusWrapper = styled.span<{ small: boolean }>`
  margin-left: 0px;
  width: ${({ small }) => (small ? "16px" : "22px")};
  height: ${({ small }) => (small ? "16px" : "22px")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export default VerticalIconsList;
