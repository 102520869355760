import { useContext } from "react";

import { CurrentUserContext } from "contexts/currentUserContext";

export const useAreFeaturesEnabled = (features: string[]) => {
  const { data } = useContext(CurrentUserContext);

  const featureReducer = (accumulator: Object, feature: string) => {
    const featureEntry = {
      [feature]: data?.currentAccount?.data?.enabledFeatures?.includes(feature),
    };
    return Object.assign(accumulator, featureEntry);
  };

  return features.reduce(featureReducer, {});
};
