import { CheckCircle } from "@styled-icons/boxicons-regular/CheckCircle";
import { ErrorCircle } from "@styled-icons/boxicons-regular/ErrorCircle";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Conditional from "components/Conditional";

import { DOCTOR, GROCERY, MEDICATION } from "../constants";

type Props = {
  tasks: string[];
  papaId: string;
};

const CannotScheduleAlert = ({ tasks, papaId }: Props) => {
  const isDoctorVisit = tasks.includes(DOCTOR);
  const isGroceryVisit = tasks.includes(GROCERY);
  const isMedicationVisit = tasks.includes(MEDICATION);

  return (
    <AlertContainer>
      <ErrorCircleIcon />
      <Header>We can't schedule this visit.</Header>
      <Summary>
        Papa Pals cannot provide hands-on assistance getting in and out of vehicles nor transport
        non-collapsible mobility aids in their vehicles.
      </Summary>
      <CheckCircleIcon />
      <InstructionHeader>What to do:</InstructionHeader>
      <Instructions>
        <li>Apologize for the inconvenience</li>
        <li>Explain why the request is out of scope</li>
        <Conditional show={isGroceryVisit || isMedicationVisit}>
          <li>
            <i>For groceries/Rx:</i> Offer to schedule a delivery visit instead
          </li>
        </Conditional>
        <Conditional show={isDoctorVisit}>
          <li>
            <i>For medical transportation:</i> Suggest they call their health plan and then{" "}
            <Link to={`/papas/${papaId}/care-concierge`} target="_blank">
              create a service request
            </Link>{" "}
            to log we made this suggestion
          </li>
        </Conditional>
      </Instructions>
    </AlertContainer>
  );
};

const AlertContainer = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: auto auto 20px auto auto;
  column-gap: 10px;
  row-gap: 0px;
  width: 35rem;
  border: 2px solid ${({ theme }) => theme.variants.danger};
  border-left-width: 10px;
  padding: 1rem;
  margin-top: 20px;
`;

const ErrorCircleIcon = styled(ErrorCircle)`
  grid-column: 1;
  grid-row: 1;
  color: ${({ theme }) => theme.variants.danger};
`;

const Header = styled.div`
  grid-column: 2;
  grid-row: 1;
  align-self: center;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.largex};
`;

const Summary = styled.div`
  grid-column: 2;
  grid-row: 2;
  font-style: italic;
`;

const CheckCircleIcon = styled(CheckCircle)`
  grid-column: 1;
  grid-row: 4;
`;

const InstructionHeader = styled.div`
  grid-column: 2;
  grid-row: 4;
  align-self: center;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

const Instructions = styled.ul`
  grid-column: 2;
  grid-row: 5;
  margin: 0 0 0 20px;
  padding: 0;
`;

export default CannotScheduleAlert;
