import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { confirm } from "components/Confirm";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import {
  grievanceOnlyStates,
  papaChangeStatusReasonOptions,
  papaStateOptions,
} from "constants/papaState";
import { PapaState } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { PapaConcealed } from "types";
import { mapValueToLabel } from "utils/helpers/formatters";

import { CHANGE_PAPA_STATUS } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  papaId: string;
  closeModal: () => void;
  papa?: PapaConcealed;
}

const SetStatusForm = ({ papaId, closeModal, papa }: Props): ReactElement => {
  const { isGrievancesAppealsAdmin } = useCurrentAccountRole();
  const [changePapaStatus] = useMutation(CHANGE_PAPA_STATUS);
  const initialValues: Schema = {
    state: papa?.status || null,
    changeStatusReason: null,
    comment: "",
  };

  const stateOptions = isGrievancesAppealsAdmin
    ? papaStateOptions
    : papaStateOptions.filter((option) => !grievanceOnlyStates.includes(option.value));

  const handleSubmit = async (input: Schema) => {
    if (grievanceOnlyStates.includes(input.state as PapaState)) {
      if (
        !(await confirm(
          `You are about to switch a papa's status to ${mapValueToLabel(
            papaStateOptions,
            input.state
          )}. All of their future visit will be terminated and it will not be possible to create new visits for them. Do you want to proceed?`
        ))
      )
        return;
    } else if (grievanceOnlyStates.includes(papa?.status as PapaState)) {
      if (
        !(await confirm(
          `You are about to switch a papa out of a ${mapValueToLabel(
            papaStateOptions,
            papa?.status
          )} status. Doing so will allow the papa to create new visits and pals will be able to take those visits. Do you want to proceed?`
        ))
      )
        return;
    }

    try {
      const { data } = await changePapaStatus({
        variables: {
          id: papaId,
          input,
        },
      });

      if (data?.changePapaStatus?.data.id) {
        toast.success("Change papa status Success.");
        closeModal();
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label required>Status</Form.Label>
            <Select
              aria-label="state"
              name="state"
              maxMenuHeight={200}
              options={stateOptions}
              isSearchable
              placeholder="Select State"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label required>Reason</Form.Label>
            <Select
              aria-label="changeStatusReason"
              name="changeStatusReason"
              maxMenuHeight={200}
              options={papaChangeStatusReasonOptions}
              isSearchable
              placeholder="Select Reason"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Comment</Form.Label>
            <Form.TextArea aria-label="comment" name="comment" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Form.SubmitButton action noContent>
            Save
          </Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default SetStatusForm;
