import { gql } from "@apollo/client";

export const CREATE_PAL_COMPLAINT = gql`
  mutation createPalComplaint($input: PalComplaintInput!) {
    createPalComplaint(input: $input) {
      data {
        id
        reason
        comment
        insertedAt
        updatedAt
        insertedBy {
          data {
            id
            fullName
          }
        }
        pal {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;
