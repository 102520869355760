import { VehicleType } from "generated/types";

export const yearOptions = (() => {
  const current = new Date().getFullYear() + 2;

  return Array.from({ length: 21 }, (year, index) => {
    const value = current - index;

    return { value, label: String(value) };
  });
})();

export interface UpdateVehicleInput {
  vehicle: VehicleType;
  vehicleColor: string;
  vehicleModel: string;
  vehicleYear: string;
}
