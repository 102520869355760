import { Lock, LockOpen } from "@styled-icons/boxicons-solid";
import React, { HTMLAttributes } from "react";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { Loader } from "components/Loader";
import ConfirmModal from "components/Modal/ConfirmModal";
import { SwitchButtonInput } from "components/SwitchButton";
import { useSetPapaGenderPreferenceLockMutation } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";

interface Props {
  papaId: string;
  locked: boolean;
}

const Switch = ({ papaId, locked }: Props) => {
  const { isOpen: confirmModalOpen, toggle: toggleConfirmModal } = useModalToggle();
  const [setPapaGenderPreferenceLock, { loading }] = useSetPapaGenderPreferenceLockMutation();

  const { isSuperAdmin, isGrievancesAppealsAdmin } = useCurrentAccountRole();
  const isAuthorized = isSuperAdmin || isGrievancesAppealsAdmin;

  const handleToggle = async () => {
    try {
      const { data } = await setPapaGenderPreferenceLock({
        variables: { id: papaId, value: !locked },
      });

      if (data?.setPapaGenderPreferenceLock?.data?.id) {
        toast.success(`Gender preference ${locked ? "unlocked" : "locked"}`);
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Container>
      <SwitchButton
        label={
          loading ? <Loader /> : locked ? "Gender Preference Locked" : "Gender Preference Unlocked"
        }
        checked={locked}
        disabled={!isAuthorized || loading}
        onClick={toggleConfirmModal}
      />

      <ConfirmModal
        isOpen={confirmModalOpen}
        onCancel={toggleConfirmModal}
        onConfirm={() => {
          handleToggle();
          toggleConfirmModal();
        }}
        header="Please Confirm"
      >
        {locked
          ? "Are you sure you want to unlock this papa's gender preference?"
          : "Are you sure you want to lock this papa's gender preference?"}
      </ConfirmModal>
    </Container>
  );
};

type SwitchButtonProps = {
  label: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const SwitchButton = ({ label, checked, disabled, ...inputProps }: SwitchButtonProps) => {
  return (
    <>
      <Label disabled={disabled}>
        <SwitchButtonInput readOnly {...inputProps} checked={checked} disabled={disabled} />
        {checked ? <Lock size={18} /> : <LockOpen size={18} />}
        <Text>{label}</Text>
      </Label>
    </>
  );
};

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 10px;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.text.secondary};
    `}

  & > svg {
    margin-left: 10px;
  }
`;

const Text = styled.span`
  margin-left: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default Switch;
