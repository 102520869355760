import { useMutation } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";

import { DESTROY_PAPA, UNDELETE_PAPA } from "./gql";

interface Props {
  id: string;
  fullName: string;
  isToDelete: boolean;
  closeModal: () => void;
  onDeleteFinish: () => void;
}

const PapaDelete: React.FC<Props> = ({ id, fullName, isToDelete, closeModal, onDeleteFinish }) => {
  const [destroyOrUnPapaDelete, { loading, error: mutationError }] = useMutation(
    isToDelete ? DESTROY_PAPA : UNDELETE_PAPA
  );
  const handleSubmit = async () => {
    try {
      const { data } = await destroyOrUnPapaDelete({
        variables: {
          id: id,
        },
      });
      if (data?.destroyPapa?.data?.id || data?.undeletePapa?.data?.id) {
        toast.success(`Papa ${fullName} was ${isToDelete ? "deleted" : "undeleted"} with success!`);
        onDeleteFinish();
      } else {
        throw new Error("Something is wrong");
      }
      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
    closeModal();
  };

  return (
    <>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <StyledForm>
          <Container>
            <h2>{isToDelete ? "Delete" : "Undelete"} Papa</h2>{" "}
            <p>
              Are you sure you want to
              {isToDelete ? " delete" : " undelete"} papa <strong>{fullName}</strong>?
            </p>
          </Container>

          <FormFooter>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? (
                <>
                  <SpinningIcon size={18} />
                  <span>{isToDelete ? "Deleting..." : "Undeleting..."}</span>
                </>
              ) : (
                "OK"
              )}
            </Button>
          </FormFooter>
        </StyledForm>
      </Formik>
    </>
  );
};

export default PapaDelete;

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5rem;
  padding-right: 2.625rem;
  ${Button}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const Container = styled.div`
  padding: 1.5rem 3.375rem 0 2.25rem;
`;

const StyledForm = styled(FormikForm)`
  width: 100%;
`;

export const ModalContainer = styled.div`
  padding: 0 2.625rem 0 4.5rem;
`;
