import React from "react";
import { useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";

import { ActionMenuAnalytics, actionMenuClicked } from "utils/segment";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  readonly handleToggle?: () => void;
  actionMenuAnalytics?: ActionMenuAnalytics;
};

const StyledItem = styled("a")<Props>`
  min-height: 38px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text.main};
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  padding: 9px 14px;

  &:first-of-type {
    border-top: 0;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          color: ${({ theme }) => theme.text.secondary};
        `
      : css`
          cursor: pointer;

          &:hover {
            color: ${({ theme }) => theme.variants.primary};
            background: #eaefff;
          }
        `}
`;

const DropdownItem = ({
  children,
  handleToggle,
  onClick,
  disabled = false,
  actionMenuAnalytics = undefined,
}: Props) => {
  const routeMatch = useRouteMatch();

  function handleClick() {
    if (actionMenuAnalytics) {
      actionMenuClicked(actionMenuAnalytics, routeMatch.path);
    }
    onClick && onClick();
    handleToggle && handleToggle();
  }

  return (
    <StyledItem disabled={disabled} onClick={handleClick}>
      {children}
    </StyledItem>
  );
};

export default DropdownItem;
