import { OperationVariables, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { ValueType } from "react-select/src/types";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import GoogleLocationSearch from "components/GoogleLocationSearch";
import { Loader } from "components/Loader";
import { LOCATIONS_SEARCH } from "components/LocationSearch/gql";
import UserSelect, { ChangeSelectEvent } from "components/UserSelect";
import { AccountListResult, PapaSingleResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";
import { nonNull } from "utils/nonNull";

import { ModalContainer, ModalFooter, ModalStyledForm } from "../Modal/Modal.styles";
import { MemberOption, mapMemberToOption } from "./constants";
import { CREATE_LOCATION, GET_LOCATIONS, GET_PAPA_DATA, SEARCH_MEMBER, SEARCH_PAPAS } from "./gql";
import { Values, validationSchema } from "./schema";

export interface Props {
  onClose: () => void;
  onSubmittingFinish: () => void;
  papaId?: string;
  locationId?: string;
  accountId?: string | null;
}

interface SearchMemberData {
  accounts: AccountListResult;
}

export const LocationForm: React.FC<Props> = ({
  accountId: selectedAccountId = "",
  papaId,
  onClose,
  onSubmittingFinish,
}) => {
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { data, loading } = useQuery<{ papa: PapaSingleResult }>(GET_PAPA_DATA, {
    variables: { id: papaId },
    skip: !papaId,
  });

  const client = useApolloClient();
  const [accountId, setAccountId] = useState(selectedAccountId);
  const [loadingPapa, setLoading] = useState(false);
  const [selectedPapa, setSelectedPapa] = useState<MemberOption[]>([]);

  const [createLocation] = useMutation(CREATE_LOCATION, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
        variables: {
          filter: {
            papa: {
              idEq: papaId,
            },
          },
          pagination: {
            limit: 50,
          },
        },
      },
      {
        query: LOCATIONS_SEARCH,
        variables: {
          filter: {
            deletedAt: { eq: null },
            papa: {
              idEq: papaId,
            },
          },
          pagination: {
            limit: 50,
          },
        },
      },
      {
        query: GET_LOCATIONS,
        variables: {
          filter: { deletedAt: { eq: null } },
          pagination: { beforeCursor, afterCursor, limit },
          sorting: { insertedAt: "DESC" },
        },
      },
    ],
  });

  const getPapas = useCallback(
    (filter: OperationVariables) => {
      return client.query({
        query: SEARCH_PAPAS,
        variables: {
          filter,
          pagination: {
            limit: 5,
          },
          sorting: {
            fullName: "ASC",
          },
        },
      });
    },
    [client]
  );

  useEffect(() => {
    const getSelectedPapa = async () => {
      setLoading(true);
      const { data: dataPapas } = await getPapas({ account: { idEq: accountId } });

      if (dataPapas?.papas?.data) {
        const papas = papasConcealed(dataPapas.papas.data);
        setSelectedPapa(papas.map(mapMemberToOption));
      }

      setLoading(false);
    };

    if (accountId) {
      getSelectedPapa();
    }
  }, [getPapas, accountId]);

  const handleSubmit = async (values: Values) => {
    try {
      const { data: dataCreateLocation } = await createLocation({
        variables: {
          input: {
            accountId: values.accountId,
            ...values.location,
            address2: values.address2,
            nickname: values.nickname,
            ...(values.papaId ? { papaId: values.papaId } : {}),
          },
        },
      });

      if (dataCreateLocation) {
        toast.success("Location has been successfully added");
      }

      onSubmittingFinish();
      onClose();
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  async function handleMemberLoadOptions(value: string) {
    const { data: dataSearchMember } = await client.query<SearchMemberData>({
      query: SEARCH_MEMBER,
      variables: {
        filter: [
          { fullName: { cont: value }, deletedAt: { eq: null } },
          { email: { cont: value }, deletedAt: { eq: null } },
        ],
      },
    });
    if (dataSearchMember?.accounts?.data) {
      return nonNull(dataSearchMember.accounts.data).map(mapMemberToOption);
    }

    return [];
  }

  async function handlePapaLoadOptions(value: string) {
    const { data: dataPapas } = await getPapas({
      account: { idEq: accountId },
      fullName: { cont: value },
    });

    if (dataPapas?.papas?.data) {
      const papas = papasConcealed(dataPapas.papas.data);

      return papas.map(mapMemberToOption);
    }

    return [];
  }

  const handleSelectAccount = (value: ValueType<ChangeSelectEvent>) => {
    const computedValue = value as ChangeSelectEvent;
    setAccountId(computedValue.value);
  };

  const initialValues = {
    accountId: accountId || data?.papa?.data?.accountId || "",
    papaId: papaId || "",
    nickname: "",
    address2: "",
    location: {
      address: "",
      lat: "",
      lng: "",
      state: "",
      countryIso: "",
      zipcode: "",
    },
  };

  if (loading) return <Loader />;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label required>Location Name</Form.Label>
              <Form.Input name="nickname" aria-label="nickname" />
            </Form.Group>
            {!papaId && !selectedAccountId && (
              <Form.Group>
                <Form.Label>Member name</Form.Label>
                <UserSelect
                  name="accountId"
                  onLoadOptions={handleMemberLoadOptions}
                  onSelect={handleSelectAccount}
                  placeholder="Search the members"
                  aria-label="member"
                />
              </Form.Group>
            )}
            {!papaId && (
              <Form.Group>
                <Form.Label>Papa name</Form.Label>
                {loadingPapa ? (
                  <SpinningIcon size={20} />
                ) : (
                  <UserSelect
                    name="papaId"
                    defaultOptions={selectedPapa}
                    onLoadOptions={handlePapaLoadOptions}
                    placeholder="Select the papa"
                    aria-label="papa search"
                  />
                )}
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label required>Address</Form.Label>
              <GoogleLocationSearch name="location" placeholder="" aria-label="address" />
              <Form.Feedback>Google places search</Form.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Address 2nd line</Form.Label>
              <Form.Input name="address2" aria-label="address2" />
            </Form.Group>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Form.SubmitButton action noContent>
              Save
            </Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </>
  );
};
