export enum ServiceRequestActions {
  NoAction,
  AddNote,
  AddFollowUp,
  Reassign,
  Update,
  ChangeStatus,
  CreateServiceRequest,
  CreateServiceRequestFollowUp,
  CreateServiceRequestNote,
}
