import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { useCallItPapaOrCareRecipient } from "hooks/useCallItPapaOrCareRecipient";

import CreatePapaForm from "./CreatePapaForm";

type Props = {
  accountId?: string | null;
  businessId?: string | null;
  fromCaregiver?: boolean;
  isOpen: boolean;
  toggle: () => void;
  onCreateFinish?: () => void;
};

const CreatePapaModal: React.FC<Props> = ({
  accountId,
  businessId,
  fromCaregiver,
  isOpen,
  toggle,
  onCreateFinish = () => {},
}) => {
  const { capitalized } = useCallItPapaOrCareRecipient();
  const title = fromCaregiver ? `Create ${capitalized.singularEntityName}` : "Create Papa";
  return (
    <ModalLayout toggle={toggle} title={title} width={750} isOpen={isOpen as boolean}>
      <CreatePapaForm
        accountId={accountId}
        businessId={businessId}
        closeModal={toggle}
        onCreateFinish={onCreateFinish}
      />
    </ModalLayout>
  );
};

export default CreatePapaModal;
