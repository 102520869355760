import { ExclamationCircle, QuestionCircle } from "@styled-icons/bootstrap";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";

import { colors } from "app/theme";
import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Heading from "components/Heading";
import Modal from "components/Modal";
import Text from "components/Text";

import { ModalFooter } from "./Modal.styles";

interface Props {
  isOpen: boolean;
  loading?: boolean;
  header?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  noCancel?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  icon?: ConfirmModalIcon;
}

export enum ConfirmModalIcon {
  none,
  exclamation,
  question,
}

const ConfirmModal: FunctionComponent<Props> = ({
  isOpen,
  loading = false,
  header,
  onCancel,
  onConfirm,
  noCancel,
  confirmBtnText = "Ok",
  cancelBtnText = "Cancel",
  icon = ConfirmModalIcon.question,
  children,
}) => {
  const [onConfirmLoading, setOnConfirmLoading] = useState(false);
  const onConfirmClick = async () => {
    setOnConfirmLoading(true);
    await onConfirm();
    setOnConfirmLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} shouldCloseOnOverlayClick shouldCloseOnEsc>
      <Modal.Body>
        <Container>
          {icon === ConfirmModalIcon.exclamation ? (
            <StyledExclamationCircleIcon fontWeight="bold" size={23} />
          ) : icon === ConfirmModalIcon.question ? (
            <StyledQuestionCircleIcon fontWeight="bold" size={23} />
          ) : null}
          <TextContainer>
            <Heading as="h2">{header}</Heading>
            {typeof children === "string" ? <Text>{children}</Text> : children}
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        {!noCancel && (
          <Button variant="secondary" onClick={onCancel}>
            {cancelBtnText}
          </Button>
        )}
        <Button
          type="button"
          variant="primary"
          onClick={onConfirmClick}
          disabled={loading || onConfirmLoading}
          action
          noContent
        >
          {loading || onConfirmLoading ? <SpinningIcon size={18} /> : confirmBtnText}
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledQuestionCircleIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledExclamationCircleIcon = styled(ExclamationCircle)`
  color: ${colors.canceled};
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;

export default ConfirmModal;
