import snakeCase from "lodash/snakeCase";

import { PapaSpecialConsideration, SpecialConsideration } from "generated/types";

export type SpecialConsiderationName =
  | "usesWheelchair"
  | "hasPets"
  | "hasCat"
  | "hasDog"
  | "hasOtherPet"
  | "hearingImpaired"
  | "cantDrive"
  | "smokes"
  | "visuallyImpaired"
  | "dementia"
  | "seizure"
  | "noPhone"
  | "requiresStrongPal";

export const specialConsiderationNames = [
  "usesWheelchair",
  "hasPets",
  "hasCat",
  "hasDog",
  "hasOtherPet",
  "hearingImpaired",
  "cantDrive",
  "smokes",
  "visuallyImpaired",
  "dementia",
  "seizure",
  "noPhone",
  "requiresStrongPal",
];

export const generateSpecialConnsiderationsInput = (
  specialConsiderations: SpecialConsideration[],
  input: { [key in string]?: any }
) =>
  specialConsiderationNames
    .map((name) => {
      const specialConsideration = specialConsiderations.find(
        (sc) => sc.name === snakeCase(name).toUpperCase()
      );
      const value = input[name as SpecialConsiderationName];
      return value != null
        ? {
            id: specialConsideration?.id,
            value,
          }
        : null;
    })
    .filter((sc) => sc != null);

export const generateInitialSpecialConsiderations = (
  papaSpecialConsiderations: PapaSpecialConsideration[]
) =>
  specialConsiderationNames.reduce((acc, name) => {
    return {
      ...acc,
      [name]: papaSpecialConsiderations?.find(
        (psc) => psc?.specialConsideration?.data?.name === snakeCase(name).toUpperCase()
      )?.value,
    };
  }, {});
