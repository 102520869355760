import React, { HTMLAttributes, ReactElement } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLFieldSetElement> {
  legend: string;
}

const Legend = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 1rem 0;
`;

const StyledFieldset = styled.fieldset`
  border: 0;
  border-top: solid 1px ${({ theme }) => theme.borderColor};
  padding: 1.5rem 0 0;
`;

const Fieldset = ({ legend, children, ...props }: Props): ReactElement => (
  <>
    <Legend>{legend}</Legend>
    <StyledFieldset {...props}>{children}</StyledFieldset>
  </>
);

export default Fieldset;
