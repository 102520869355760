import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { ButtonNew } from "components/Button";
import VisitsTable, { GET_VISITS_LIST } from "components/DataTables/VisitsTable";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import { FeatureFlags } from "constants/featureFlags";
import {
  BusinessPolicy,
  PapaAllotmentPeriod,
  VisitListResult,
  VisitSorting,
  VisitsQueryVariables,
} from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { useSorting } from "hooks/useSorting";
import { VisitFiltersModal } from "modals/VisitFiltersModal";
import { visitsDefaultStates } from "pages/Home";
import { nonNull } from "utils/nonNull";
import { visitFilterMaker } from "utils/visitFilters";

import Hours from "./Hours";

interface Props {
  allotmentPeriods?: Array<PapaAllotmentPeriod> | null | undefined;
  businessPolicy: BusinessPolicy | null | undefined;
  timezone?: string | null | undefined;
  isHealthPlanMember: boolean;
  isDhlBusiness: boolean;
}
interface Data {
  visits: VisitListResult;
}

const Visits: React.FC<Props> = ({
  allotmentPeriods,
  businessPolicy,
  timezone,
  isHealthPlanMember,
  isDhlBusiness,
}) => {
  const { page, limit, ...visitFilters } = usePageInfo(visitsDefaultStates);
  const { id: papaId } = useParams<{ id: string }>();
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const [sorting, setSorting] = useSorting<VisitSorting>(["scheduledFor"]);
  const { data, loading, error } = useQuery<Data, VisitsQueryVariables>(GET_VISITS_LIST, {
    variables: {
      filter: [
        {
          papa: {
            idEq: papaId,
          },
          ...visitFilterMaker(visitFilters),
        },
      ],
      sorting,
      pagination: { page, limit },
    },
  });

  const isHoursEnabled = useIsFeatureEnabled(FeatureFlags.AllotmentHours) && !isDhlBusiness;

  const handleFiltersChange = () => {
    toggleFiltersModal();
  };

  const FiltersButton = styled(ButtonNew)`
    margin-right: 0.75rem;
  `;

  return (
    <>
      <Helmet>
        <title>Papa Visits - Papa Admin</title>
      </Helmet>

      {isHoursEnabled && businessPolicy && allotmentPeriods && (
        <Hours
          papaId={papaId}
          allotmentPeriods={allotmentPeriods}
          businessPolicy={businessPolicy}
          isHealthPlanMember={isHealthPlanMember}
        />
      )}

      <Heading as="h2" color="primary">
        Visits
      </Heading>
      <FiltersButton onClick={toggleFiltersModal} size="small" type="button" variant="secondary">
        Filters
      </FiltersButton>
      <VisitsTable
        data={nonNull(data?.visits?.data)}
        loading={loading}
        sorting={sorting}
        onSortChange={setSorting}
        pagination={data?.visits?.pagination}
        error={error}
        fromPapa
        minimal
      />

      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Visits Filter"
        isOpen={isFiltersModalOpen}
      >
        <VisitFiltersModal onSubmit={handleFiltersChange} />
      </ModalLayout>
    </>
  );
};

export default Visits;
