import { gql } from "@apollo/client";

export const GET_PLANS = gql`
  query plans($filter: [PlanFilter]) {
    plans(filter: $filter) {
      data {
        description
        hourlyRate
        id
        monthlyRate
        name
      }
    }
  }
`;

export const PICK_PLAN = gql`
  mutation pickPlan($input: PickPlanInput!) {
    pickPlan(input: $input) {
      success
    }
  }
`;
