import { gql } from "@apollo/client";

export const REMOVE_PAL_AND_VISIT_TO_PENDING = gql`
  mutation removePalAndSetVisitToPending($id: ID!, $input: CancelVisitInput!) {
    removePalAndSetVisitToPending: cancelVisit(id: $id, input: $input) {
      data {
        id
        state
        pal {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;
