import { gql } from "@apollo/client";

export const CREATE_ACCOUNT = gql`
  mutation createAccount($input: AccountInput!) {
    createAccount(input: $input) {
      data {
        id
      }
    }
  }
`;

export const CREATE_CAREGIVER = gql`
  mutation createCaregiver($input: CaregiverInput!) {
    createCaregiver(input: $input) {
      data {
        id
      }
    }
  }
`;
