import { useField } from "formik";
import React, { useEffect } from "react";

import Form from "components/Form";
import { useIsGoWithAllowed } from "pages/ScheduleVisit/shared/DeliveryVisitHelpers";
import * as Segment from "utils/segment";

type MaybeBool = boolean | null | undefined;

interface Props {
  hasMedicationTask: MaybeBool;
  hasGroceryTask: MaybeBool;
  delivery: MaybeBool;
  onDeliveryChange: (newDelivery: MaybeBool) => void;
}

const DeliveryOptions: React.FC<Props> = ({
  hasMedicationTask,
  hasGroceryTask,
  delivery,
  onDeliveryChange,
}) => {
  const [{ value: papaId }, ,] = useField("papaId");
  const disableGoWith = !useIsGoWithAllowed();
  const showDeliveryOptions = hasMedicationTask || hasGroceryTask;

  const storeLocations = [
    ...(hasGroceryTask ? ["grocery store"] : []),
    ...(hasMedicationTask ? ["pharmacy"] : []),
  ];

  useEffect(() => {
    // The member's policy forbids "go with" transportation visits. If the
    // member has a grocery or medication task, it is forced to *delivery*
    // since their policy does not allow "go with" transportation visits.
    if (disableGoWith) {
      onDeliveryChange(showDeliveryOptions ? true : null);
      return;
    }
  }, [disableGoWith, showDeliveryOptions, delivery, onDeliveryChange]);

  const trackEvent = (value: "delivery" | "go with member") => {
    Segment.visitSchedulingAction("tasks", "answered delivery question", value, papaId);
  };

  if (!showDeliveryOptions) {
    return null;
  }

  return (
    <Form.Group>
      <Form.Label>How can we help the member?</Form.Label>
      <Form.Group>
        <Form.Radio
          controlId="isDelivery"
          name="delivery"
          value={true}
          checked={delivery === true}
          label="Contactless delivery"
          onChange={() => trackEvent("delivery")}
        />
        <Form.Radio
          controlId="isGoWith"
          name="delivery"
          value={false}
          checked={delivery === false}
          label={`Go with to ${storeLocations.join("/")}`}
          disabled={disableGoWith}
          onChange={() => trackEvent("go with member")}
        />
      </Form.Group>
    </Form.Group>
  );
};

export default DeliveryOptions;
