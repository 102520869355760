import React from "react";
import Helmet from "react-helmet";

import SmartTable from "components/SmartTable";
import { Visit } from "generated/types";
import { useVisitByAgent } from "hooks/useVisitByAgent";

import { useColumns } from "./useColumns";

const VisitsScheduled: React.FunctionComponent = () => {
  const columns = useColumns();
  const { data, pagination, loading, error } = useVisitByAgent();

  return (
    <>
      <Helmet>
        <title>Member Visits Scheduled - Papa Admin</title>
      </Helmet>

      <SmartTable<Visit>
        keyField="id"
        data={data}
        pagination={pagination}
        loading={loading}
        error={error}
        rowProps={({ state }) =>
          state
            ? {
                statusLegend: state,
              }
            : {}
        }
        columns={columns}
        cursorPagination
      />
    </>
  );
};

export default VisitsScheduled;
