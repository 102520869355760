import { useQuery } from "@apollo/client";

import { PapaListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { GET_PAPAS_ENROLLED } from "pages/Members/Details/PapasEnrolled/gql";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";

type Data = {
  papas: PapaListResult;
};

export const usePapaEnrolled = () => {
  const { page, limit } = usePageInfo();

  const { data, loading, error } = useQuery<Data>(GET_PAPAS_ENROLLED, {
    variables: {
      filter: [{ created_by_agent: true }],
      pagination: { page, limit },
    },
  });

  return {
    data: papasConcealed(data?.papas.data),
    pagination: data?.papas.pagination,
    loading,
    error,
  };
};
