import { useState } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Business } from "generated/types";

export const useBusinessActions = () => {
  const [business, setBusiness] = useState<Business | null>(null);
  const [action, setBusinessAction] = useState(DataTableMenuActions.NoAction);

  const setAction = (action: DataTableMenuActions, business: Business | null = null) => {
    setBusiness(business);
    setBusinessAction(action);
  };

  return {
    business,
    action,
    setAction,
  };
};
