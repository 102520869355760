import { ApolloError } from "@apollo/client";
import React from "react";

import QueryErrors from "components/QueryErrors";
import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { AccountActivityLog, AccountActivityLogListResult } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

import { ActivityLogChanges } from "./ActivityLogChanges";

type Data = {
  accountActivityLogs: AccountActivityLogListResult;
};

interface Props {
  logs: Data;
  loading: boolean;
  error: ApolloError | undefined;
}

const ActivityLogsTable: React.FC<Props> = ({ logs, error, loading }) => {
  const activityLogs = logs?.accountActivityLogs?.data;
  const columns: Column<AccountActivityLog>[] = [
    {
      header: { title: "Account Email" },
      dataField: "account.data.email",
    },
    {
      header: { title: "Subject Email" },
      dataField: "subject.data.email",
    },
    {
      header: { title: "Device Type" },
      dataField: "deviceType",
    },
    {
      header: { title: "IP Address" },
      dataField: "ipAddress",
    },
    {
      header: { title: "Event Type" },
      dataField: "eventType",
    },
    {
      header: { title: "Inserted At" },
      dataField: "insertedAt",
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt);
      },
    },
    {
      header: { title: "Changes" },
      dataField: "changes",
      cellProps: {
        align: "center",
      },
      formatter({ changes, operationType }) {
        return changes?.data?.length ? (
          <ActivityLogChanges changes={nonNull(changes.data)} operationType={operationType} />
        ) : (
          EMPTY_PLACEHOLDER
        );
      },
    },
  ];

  return (
    <>
      <QueryErrors error={error} />

      <SmartTable<AccountActivityLog>
        keyField="id"
        data={activityLogs}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default ActivityLogsTable;
