import styled from "styled-components";

import Heading from "components/Heading";

const FormHeading = styled(Heading)`
  color: ${({ theme }) => theme.variants.secondary};
  font-size: ${({ theme }) => theme.fontSizes.largex};
  margin-top: 0;
  margin-bottom: 1.875rem;
`;

export default FormHeading;
