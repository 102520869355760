import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import React, { useState } from "react";

import CaregiverActionsMenu from "components/ActionsMenu/CaregiverActionMenu";
import { useCaregiverActions } from "components/ActionsMenu/CaregiverActionMenu/useCaregiverActions";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { CaregiverOnListFragment, CaregiverSorting, SortDirection } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { formatDateTime } from "utils/helpers/formatters";

interface Params {
  sorting: CaregiverSorting;
  onSortChange: (sort: CaregiverSorting) => void;
}

export const useCaregiversTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { caregiver, action, setAction } = useCaregiverActions();

  const [sorting, setSorting] = useState<CaregiverSorting>(initialSorting);

  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const onSetSorting = (sortKey: keyof CaregiverSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const employerClientColumnHeaderTitle = clientCopyUpdateEnabled ? "Employer Client" : "Business";

  const columns: Column<CaregiverOnListFragment>[] = [
    {
      header: { title: "Name" },
      dataField: "fullName",
    },
    {
      header: { title: "Contact" },
      dataField: "email",
      formatter({ account }) {
        return (
          <>
            <Text>{account?.data?.email}</Text>
            <Text>{account?.data?.phoneNumber}</Text>
          </>
        );
      },
    },
    {
      header: { title: employerClientColumnHeaderTitle },
      dataField: "business",
      formatter({ business }) {
        return business?.data?.name ? business.data.name : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "Membership" },
      dataField: "plan.data.name",
      cellProps: { noWrap: true },
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter({ account }) {
        return formatDateTime(account?.data?.insertedAt?.toString());
      },
    },
    {
      header: { title: "Status" },
      dataField: "status",
      formatter({ status }) {
        return status ? capitalize(lowerCase(status)) : EMPTY_PLACEHOLDER;
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(caregiver) {
        return (
          caregiver && (
            <CaregiverActionsMenu caregiver={caregiver} setAction={setAction} isListMenu />
          )
        );
      },
    },
  ];

  const createCaregiver = () => {
    setAction(DataTableMenuActions.Create, null);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction, null);
  };

  return {
    columns,
    action,
    caregiver,
    createCaregiver,
    closeModal,
  };
};
