import React from "react";
import styled from "styled-components";

import Text from "components/Text";

interface Props {
  setOtherCompletedTasks: (text: string) => void;
}

export const OtherCompletedTasks: React.FC<Props> = ({ setOtherCompletedTasks }) => {
  return (
    <Container>
      <Text size="middle">Enter other tasks you completed here</Text>
      <StyledTextArea
        name="otherCompletedTasks"
        placeholder="Other completed tasks"
        onChange={(e) => setOtherCompletedTasks(e.target.value)}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 40px 0 43px;

  > ${Text} {
    margin-bottom: 10px;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 178px;
  border: 1px solid #dfe3e9;
  outline: none;
  border-radius: 4px;
  padding: 10px 14px;
`;
