import { VisitRecurrenceWeekday, VisitState, VisitVisibility } from "generated/types";

export enum VisitMenuActions {
  AssignPalToVisit,
  AcceptVisit,
  AddGrievancesAppealFlag,
  VisitWatchList,
  RemovePalAndSetVisitToPending,
  CancelVisit,
  CompleteVisit,
  CompleteVisitHandoff,
  ConfirmMember,
  ConfirmVisit,
  EditComplete,
  EditStartTime,
  Feedback,
  HandoffVisit,
  MakeCritical,
  MakeRecurring,
  NoAction,
  ReclaimHandoffedVisit,
  RemoveFromWatchList,
  RescheduleVisit,
  RevertToAccepted,
  RevertVisitCommute,
  RevertVisitStart,
  ReviewTerminatedVisit,
  StartCommute,
  StartVisit,
  TerminateReviewedVisit,
  warmTransfer,
  GrantCredit,
}

export const visitVisibilityOptions = [
  { value: VisitVisibility.AgentOnly, label: "Agent only" },
  { value: VisitVisibility.Private, label: "Private" },
  { value: VisitVisibility.Public, label: "Public" },
];

export const visitStateOptions = [
  { value: VisitState.Accepted, label: "Accepted" },
  { value: VisitState.Canceled, label: "Canceled" },
  { value: VisitState.Completed, label: "Completed" },
  { value: VisitState.Confirmed, label: "Confirmed" },
  { value: VisitState.Enroute, label: "Enroute" },
  { value: VisitState.Expired, label: "Expired" },
  { value: VisitState.PartnerOperated, label: "Partner Operated" },
  { value: VisitState.Pending, label: "Pending" },
  { value: VisitState.Rejected, label: "Rejected" },
  { value: VisitState.Reviewed, label: "Reviewed" },
  { value: VisitState.Started, label: "Started" },
  { value: VisitState.Terminated, label: "Terminated" },
];

export const visitRecurrenceWeekdayOptions = [
  { value: VisitRecurrenceWeekday.Sunday, label: "Sunday" },
  { value: VisitRecurrenceWeekday.Monday, label: "Monday" },
  { value: VisitRecurrenceWeekday.Tuesday, label: "Tuesday" },
  { value: VisitRecurrenceWeekday.Wednesday, label: "Wednesday" },
  { value: VisitRecurrenceWeekday.Thursday, label: "Thursday" },
  { value: VisitRecurrenceWeekday.Friday, label: "Friday" },
  { value: VisitRecurrenceWeekday.Saturday, label: "Saturday" },
];

export const isVisitStarted = (state: VisitState) => {
  const states = [
    VisitState.Completed,
    VisitState.Reviewed,
    VisitState.PartnerOperated,
    VisitState.Started,
  ];

  return states.some((startedState) => startedState === state);
};

export enum WhatTriggeredOption {
  Member,
  Pal,
  Service,
}

export enum ReasonCode {
  // Cancel
  AppIssues = "APP_ISSUES",
  ChangedInsurance = "CHANGED_INSURANCE",
  ConvertingToAgentVirtualVisit = "CONVERTING_TO_AGENT_VV",
  DuplicatedVisit = "DUPLICATED_VISIT",
  FailedPayments = "FAILED_PAYMENTS",
  MemberCantMakeIt = "MEMBER_CANT_MAKE_IT",
  MemberNoShow = "MEMBER_NO_SHOW",
  MemberResheduled = "MEMBER_RESCHEDULED",
  OutOfAssetsOrHours = "OUT_OF_ASSETS_OR_HOURS",
  PalCantMakeIt = "PAL_CANT_MAKE_IT",
  PalNoShow = "PAL_NO_SHOW",
  Test = "TEST",
  WeatherAbnormality = "WEATHER_ABNORMALITY",
  PalsNotAvailableMemberNotFlexible = "PALS_NOT_AVAILABLE_MEMBER_NOT_FLEXIBLE",

  // Reschedule
  insufficientFunds = "INSUFFICIENT_FUNDS",
  memberRescheduled = "MEMBER_RESCHEDULED",
  palReschedules = "PAL_RESCHEDULED",
  scheduleMistake = "SCHEDULING_MISTAKE",
  NoPalsAvailableMemberFlexible = "NO_PALS_AVAILABLE_MEMBER_FLEXIBLE",

  // Both
  noPalsAvailable = "NO_PALS_AVAILABLE",

  /* New Cancel Reason Codes */
  // Member
  MemberDoNotNeedAPal = "MEMBER_DO_NOT_NEED_PAL",
  MemberNotMyPreferredPal = "MEMBER_NOT_PREFERRED_PAL",
  MemberPrefersAnotherPal = "MEMBER_PREFERS_ANOTHER_PAL",
  MemberNotHome = "MEMBER_NOT_HOME",
  MemberUnresponsive = "MEMBER_UNRESPONSIVE",
  MemberNoAnswerVV = "MEMBER_NOANSWER_VV",
  MemberNotFeelingWell = "MEMBER_NOT_FEELING_WELL",
  MemberCovidRelated = "MEMBER_COVID",
  MemberDeceased = "MEMBER_DECEASED",
  MemberDoctorVisitChange = "MEMBER_DOCTOR_VISIT_CHANGED",
  MemberHelpFromOtherPerson = "MEMBER_HELP_FROM_OTHER",
  MemberVacationOrFamily = "MEMBER_VACATION",
  MemberPauseService = "MEMBER_PAUSE",
  MemberForgotAboutVisit = "MEMBER_FORGOT",
  MemberLessFrequentVisits = "MEMBER_LESS_FREQUENT_VISITS",
  MemberNotHappyWithPapa = "MEMBER_NOT_HAPPY",
  MemberNotScheduledDidNotKnow = "MEMBER_NOT_SCHEDULED",
  MemberNotNeededAnymore = "MEMBER_SERVICE_NOT_NEEDED",
  MemberInactive = "MEMBER_INACTIVE",
  MemberStopService = "MEMBER_STOP_SERVICE",
  MemberUndefined = "MEMBER_OTHER",
  MemberWrongDateOrTime = "MEMBER_RESCHEDULE_WRONG_DATE_TIME",
  MemberSomethingCameUp = "MEMBER_RESCHEDULE_SOMETHING_CAME_UP",
  MemberPreferredADifferentTime = "MEMBER_RESCHEDULE_PREFFERED_TIME",
  MemberScheduleLessFrequentVisit = "MEMBER_RESCHEDULE_LESS_FREQUENT",
  MemberRescheduledOther = "MEMBER_RESCHEDULE_OTHER",

  // Pal
  // PalNoShow = "PAL_NO_SHOW",
  PalSomethingCameUp = "PAL_SOMETHING_CAME_UP",
  PalRescheduleRequest = "PAL_RESCHEDULED",
  PalNotFeelingWell = "PAL_NOT_FEELING_WELL",
  PalCovidRelated = "PAL_COVID",
  PalEmergency = "PAL_EMERGENCY",
  PalUnawareOfTheVisit = "PAL_UNAWARE",
  PalCarIssues = "PAL_CAR_ISSUE",
  PalWeatherIssues = "PAL_WEATHER_ISSUE",
  PalAppIssues = "PAL_APP_ISSUE",
  PalCannotFindMember = "PAL_CANNOT_FIND",
  PalDoubleBooked = "PAL_DOUBLE_BOOKED",
  PalBillingIssues = "PAL_BILLING_ISSUE",
  PalUndefined = "PAL_OTHER",

  // Service
  ServiceNoPalAvailable = "SERVICE_NO_PAL_AVAILABLE",
  ServiceNoPalAvailableFlexible = "SERVICE_NO_PAL_AVAILABLE_NOT_FLEXIBLE",
  ServiceNoPalAvailableRescheduled = "SERVICE_NO_PAL_AVAILABLE_RESCHEDULED",
  ServiceOutOfHours = "SERVICE_OUT_OF_HOURS",
  ServiceNotEligible = "SERVICE_NOT_ELIGBILE",
  ServiceInsuranceChanged = "SERVICE_INSURANCE_CHANGED",
  ServiceDuplicateVisit = "SERVICE_DUPLICATE_VISIT",
  ServiceWrongVisitType = "SERIVCE_WRONG_VISIT_TYPE",
  ServiceWrongDateOrTime = "SERVICE_WRONG_DATE_TIME",
  ServiceWrongPhoneNumber = "SERVICE_WRONG_NUMBER",
  ServicePaymentIssue = "SERVICE_PAYMENT_ISSUE",
  ServiceWrongAccount = "SERVICE_WRONG_ACCOUNT",
  ServiceDuplicateMember = "SERVICE_DUPLICATE_MEMBER",
  ServiceAgentCloneVisit = "SERVICE_AGENT_CLONE_VISIT",
}

export const visitActivityReasonLabel: { [key: string]: string } = {
  /**Cancel */
  APP_ISSUES: "App Issues",
  CHANGED_INSURANCE: "Changed Insurance",
  CONVERTING_TO_AGENT_VV: "Converting To Agent Virtual Visit",
  DUPLICATED_VISIT: "Duplicated Visit",
  FAILED_PAYMENTS: "Failed Payments",
  MEMBER_CANT_MAKE_IT: "Member Can't Make It",
  MEMBER_NO_SHOW: "Member No Show",
  OUT_OF_ASSETS_OR_HOURS: "Out Of Assets Or Hours",
  PAL_CANT_MAKE_IT: "Pal Can't Make It",
  PAL_NO_SHOW: "Pal No Show",
  TEST: "Test",
  WEATHER_ABNORMALITY: "Weather/Abnormality",
  PALS_NOT_AVAILABLE_MEMBER_NOT_FLEXIBLE: "Pals Not available/Member Not Flexible",
  NOT_AVAILABLE_AT_TIME: "Not Available at time",

  // Rescheduled
  INSUFFICIENT_FUNDS: "Insufficient Funds/Hours",
  MEMBER_RESCHEDULED: "Member Rescheduled",
  PAL_RESCHEDULED: "Pal Rescheduled",
  SCHEDULING_MISTAKE: "Scheduling Mistake",
  NO_PALS_AVAILABLE_MEMBER_FLEXIBLE: "No Pals available/Member Flexible",

  // Both
  NO_PALS_AVAILABLE: "No Pals available",
};

export const visitStatusLabels = {
  ACCEPTED: "Accepted",
  CANCELED: "Canceled",
  COMPLETED: "Completed",
  CONFIRMED: "Confirmed",
  ENROUTE: "Enroute",
  EXPIRED: "Expired",
  PARTNER_OPERATED: "Partner operated",
  PENDING: "Pending",
  REJECTED: "Rejected",
  REVIEWED: "Reviewed",
  STARTED: "Started",
  TERMINATED: "Terminated",
};

export const DEFAULT_SUMMARY_STATES = [
  "PARTNER_OPERATED",
  "STARTED",
  "COMPLETED",
  "TERMINATED",
  "REVIEWED",
];

export const VISIT_EVENTS_WITH_ON_BEHALF = [
  "accepted",
  "commute_started",
  "completed",
  "confirmed",
  "inserted",
  "reviewed",
  "started",
];

export const RECOMMENDED_TIME_SELECTED = "RECOMMENDED_TIME_SELECTED";
