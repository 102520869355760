/*
 * by default backend deals with cents instead of decimals
 *
 * 1500 => $15.00
 **/
export const formatNumberToCurrency = (
  pennyValue: number = 0,
  noDecimal: boolean = false
): string => {
  const dollarValue = pennyValue / 100;
  const withDecimal = noDecimal ? `${dollarValue}` : dollarValue.toFixed(2);

  return "$" + withDecimal.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const parseValueToNumber = (value: string, noDecimal: boolean = false): number => {
  if (value.length) {
    let valueStr = value.replace(/[^0-9]/g, "");
    valueStr = valueStr.padStart(3, "0");

    if (Number.isNaN(parseInt(valueStr, 10))) {
      return 0;
    }

    const separator = noDecimal ? "" : ".";

    return parseFloat(
      [valueStr.substr(0, valueStr.length - 2), valueStr.substr(-2)].join(separator)
    );
  }

  return 0;
};
