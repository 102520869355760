import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { confirm } from "components/Confirm";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { PalChangeStatusReason, PalStatus } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { mapValueToLabel } from "utils/helpers/formatters";
import { mapSelectValuesToOptions } from "utils/select";

import { grievanceOnlyStatuses, reasonOptions, statusOptions } from "./ChangeStatus.models";
import { CHANGE_PAL_STATUS } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  palId: string;
  status: PalStatus | null;
  onChangeFinish: () => void;
}

const ChangeStatusForm = ({ palId, status, onChangeFinish }: Props): ReactElement => {
  const { isGrievancesAppealsAdmin } = useCurrentAccountRole();
  const [updateStatus] = useMutation(CHANGE_PAL_STATUS);
  const initialValues: Schema = {
    status: status || null,
    reason: null,
    comment: "",
  };

  const handleSubmit = async (input: Schema) => {
    if (grievanceOnlyStatuses.includes(input.status as PalStatus)) {
      if (
        !(await confirm(
          `You are about to switch a pal's status to ${mapValueToLabel(
            statusOptions,
            input.status
          )}. All of their future visits will be terminated and they will no longer be able to log into the Pal App. Do you want to proceed?`
        ))
      )
        return;
    } else if (grievanceOnlyStatuses.includes(status as PalStatus)) {
      if (
        !(await confirm(
          `You are about to switch a pal out of a ${mapValueToLabel(
            statusOptions,
            status
          )} status. Doing so will allow the pal to be able to log into the Pal App and take new visits. Do you want to proceed?`
        ))
      )
        return;
    }

    try {
      const { data } = await updateStatus({ variables: { id: palId, input } });

      if (data) {
        toast.success("Status has been successfully changed");
        onChangeFinish();
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label required>Status</Form.Label>
            <Select
              name="status"
              defaultValue={mapSelectValuesToOptions<PalStatus>(
                initialValues.status,
                statusOptions
              )}
              options={
                isGrievancesAppealsAdmin
                  ? statusOptions
                  : statusOptions.filter((option) => !grievanceOnlyStatuses.includes(option.value))
              }
              isSearchable={false}
              aria-label="status"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Reason</Form.Label>
            <Select
              name="reason"
              defaultValue={mapSelectValuesToOptions<PalChangeStatusReason>(
                initialValues.reason,
                reasonOptions
              )}
              options={reasonOptions}
              placeholder="Select reason"
              isSearchable={false}
              aria-label="reason"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Comment</Form.Label>
            <Form.TextArea aria-label="comment" name="comment" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onChangeFinish}>
            Close
          </Button>
          <Form.SubmitButton action noContent>
            Save
          </Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default ChangeStatusForm;
