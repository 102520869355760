import startCase from "lodash/startCase";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ArrowToggle } from "components/ArrowToggle";
import { Overflow } from "components/Overflow";
import Text from "components/Text";
import { FeatureFlags } from "constants/featureFlags";
import { VISIT_EVENTS_WITH_ON_BEHALF, visitActivityReasonLabel } from "constants/visit";
import {
  VisitAction,
  VisitActivityLogCommunicationLog,
  VisitActivityLogVisitCancellation,
  VisitActivityLogVisitComment,
  VisitActivityLogVisitEvent,
} from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

export type VisitActivityLog = VisitActivityLogCommunicationLog &
  VisitActivityLogVisitCancellation &
  VisitActivityLogVisitComment &
  VisitActivityLogVisitEvent;

export const ActivityComment = ({ log }: { log: VisitActivityLog }) => {
  const clientCopyUpdateEnabled = useIsFeatureEnabled(FeatureFlags.ClientCopyUpdate);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const {
    reasonDescription,
    reasonCode,
    action,
    content,
    reason,
    note,
    accountId,
    accountPalId,
    accountBusinessId,
    accountName,
    palId,
    palName,
    notificationName,
  } = log;

  const callItClientProgram = !!clientCopyUpdateEnabled && action === "override_business_policy";
  const formattedAction = getFormattedAction(action, callItClientProgram);

  const displayOnBehalfOfPalNote =
    !!action &&
    VISIT_EVENTS_WITH_ON_BEHALF.includes(action.toLowerCase()) &&
    accountPalId !== palId;

  const needsCredit = !notificationName;
  let notificationMessage = notificationName?.split(".")?.pop();
  if (!!notificationMessage) {
    notificationMessage = notificationMessage.endsWith("PN")
      ? `${notificationMessage} push notification sent to pal`
      : `${notificationMessage} SMS sent to member.`;
  }

  const description = reasonDescription || content || note || notificationMessage;

  const onCommentToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <Overflow
        container={CommentContainer}
        noWrap={isCollapsed}
        paddingRight="40px"
        render={({ isOverflowed }) => (
          <>
            {description}
            {renderReasonText(reasonCode, reason, callItClientProgram)}
            {isOverflowed && <ArrowToggle onClick={onCommentToggle} isCollapsed={isCollapsed} />}
          </>
        )}
      />
      <Text>
        <>
          {action && <EventActionLabel action={action}>{formattedAction}</EventActionLabel>}
          {needsCredit && <span>{action ? " by " : " By "}</span>}
        </>
        {!!accountId && (
          <Link
            to={
              accountPalId
                ? `/pals/${accountPalId}`
                : accountBusinessId
                ? `/${callItClientProgram ? "client-programs" : "businesses"}/${accountBusinessId}`
                : `/members/${accountId}`
            }
          >
            {accountName}
          </Link>
        )}
        {displayOnBehalfOfPalNote && !!action && !!palId && !!palName
          ? renderOnBehalfOfPalNote(action, palId, palName)
          : null}
      </Text>
    </div>
  );
};

const actionFormatted: { [key: string]: string } = {
  canceled: "Removed Pal",
  rebroadcasted: "Changed visit to Pending",
};

const CommentContainer = styled.div<{ noWrap: boolean; paddingRight: string }>`
  position: relative;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
  padding-right: ${({ paddingRight }) => paddingRight};
  overflow: hidden;
`;

const EventActionLabel = styled.span<{ action: string }>`
  font-weight: 600;
  color: ${({ theme, action }) => theme.eventAction[action]};
`;

const renderOnBehalfOfPalNote = (action: String, palId: String, palName: String) => {
  let note = null;

  if (action.toLowerCase() === VisitAction.Inserted.toLowerCase()) {
    note = (
      <>
        {" and "}
        <EventActionLabel action={"accepted"}>{"Accepted"}</EventActionLabel>
      </>
    );
  }

  return (
    <>
      {note} {" on behalf of "} <Link to={`/pals/${palId}`}>{palName}</Link>
    </>
  );
};

const getFormattedAction = (action: any, callItClientProgram: boolean) =>
  callItClientProgram
    ? "Override Client Program"
    : actionFormatted[action]
    ? actionFormatted[action]
    : startCase(action);

const renderReasonText = (reasonCode: any, reason: any, callItClientProgram: boolean) => {
  const text = reasonText(reasonCode, reason, callItClientProgram);
  if (text) {
    return <Text>{text}</Text>;
  } else {
    return null;
  }
};

const reasonText = (reasonCode: any, reason: any, callItClientProgram: boolean) => {
  if (callItClientProgram) {
    return "OVERRIDE_CLIENT_PROGRAM";
  }

  if (reasonCode) {
    return getActivityReasonLabel(reasonCode);
  }

  if (reason) {
    return getActivityReasonLabel(reason);
  }
};

const getActivityReasonLabel = (lookupKey: string) => {
  return visitActivityReasonLabel[lookupKey] || lookupKey;
};
