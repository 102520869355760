import { gql } from "@apollo/client";

export const PAPA_BLOCKED_PAL = gql`
  query papaBlockedPal($papaId: UUID!, $palId: UUID!) {
    papaBlockedPals(filter: { papaId: { eq: $papaId }, palId: { eq: $palId } }) {
      data {
        id
        comment
        pal {
          data {
            id
            fullName
            phoneNumber
          }
        }
      }
    }
  }
`;

export const UPDATE_PAPA_BLOCKED_PAL = gql`
  mutation updatePapaBlockedPal($id: ID!, $input: PapaBlockedPalInput!) {
    updatePapaBlockedPal(id: $id, input: $input) {
      data {
        id
        comment
        palId
      }
    }
  }
`;

export const CREATE_PAPA_BLOCKED_PAL = gql`
  mutation createPapaBlockedPal($input: PapaBlockedPalInput!) {
    createPapaBlockedPal(input: $input) {
      data {
        id
        papa {
          data {
            id
            blockedPals {
              data {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export const DESTROY_PAPA_BLOCKED_PAL = gql`
  mutation destroyPapaBlockedPal($id: ID!) {
    destroyPapaBlockedPal(id: $id) {
      data {
        id
        papa {
          data {
            id
            blockedPals {
              data {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
`;
