import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";

import { EditBio } from "components/EditBio/EditBio";
import { BiographyQuestions } from "generated/types";
import { MemberBioQuestions } from "pages/Members/constants";

import { UPDATE_BIO } from "./gql";

interface Props {
  palId: string;
  answers: BiographyQuestions[];
  onEditFinish: () => void;
}

export const EditMemberBio: React.FC<Props> = ({ palId, answers, onEditFinish }) => {
  const [updateBio, { error: mutationError }] = useMutation(UPDATE_BIO);

  const handleSubmit = async (answers: BiographyQuestions[]) => {
    try {
      const [bio] = answers;
      const { data } = await updateBio({
        variables: {
          id: palId,
          input: { bio: bio.answer },
        },
      });

      if (data?.updatePal?.data?.bio) {
        toast.success("Bio was edited with success!");
        onEditFinish();
      } else {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <EditBio
      questions={MemberBioQuestions}
      answers={answers}
      onEditFinish={onEditFinish}
      onSubmit={handleSubmit}
    />
  );
};
