import { gql } from "@apollo/client";

export const GET_SERVICEREQUESTS = gql`
  query getServiceRequestTypes(
    $filter: [ServiceRequestFilter]
    $pagination: PaginationInput
    $sorting: ServiceRequestSorting
  ) {
    serviceRequests(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        openedAt
        closedAt
        origin
        type {
          data {
            name
            id
          }
        }
        priority
        status
        closedReason
        need {
          data {
            id
            name
            code
          }
        }
        needText
        assignedTo {
          data {
            id
            fullName
          }
        }
        papa {
          data {
            id
            fullName
            dataVisibility {
              concealed
            }
            business {
              data {
                id
                name
              }
            }
          }
        }
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_MY_FOLLOWUPS = gql`
  query getopenfollowups(
    $filter: [ServiceRequestFollowupFilter]
    $pagination: PaginationInput
    $sorting: ServiceRequestSorting
  ) {
    serviceRequestFollowups(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        note
        serviceRequestId
        completedAt
        completed
        status
        dueAt
        action
        actionText
        assignedTo {
          data {
            id
            fullName
          }
        }
        serviceRequest {
          data {
            id
            papaId
            papa {
              data {
                id
                fullName
                business {
                  data {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
`;
