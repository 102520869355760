import styled from "styled-components";

import FormGroup from "../Form/FormGroup";
import Text from "../Text";
import { ModalFooter } from "./Modal.styles";

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  > ${Text} {
    margin-bottom: 13px;
  }
`;

export const FilterTitle = styled(Text)`
  color: #9d9faa;
`;

export const FilterType = styled(Text)`
  display: inline-block;
  margin-left: 5px;
  color: #2e5bff;
`;

export const TimeSeparator = styled(Text)`
  display: inline-block;
  margin: 0 14px;
`;

export const TimeContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const Footer = styled(ModalFooter)`
  margin-top: 3px;
`;
