import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";

import Heading from "components/Heading";
import { ELIGIBLE_LIVES_SEARCH } from "components/Search/gql";

const TestSearch: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [search, { loading, data }] = useLazyQuery(ELIGIBLE_LIVES_SEARCH);

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          search({ variables: { search: searchQuery } });
        }}
      >
        <input
          placeholder="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </form>

      <Heading>Results: {data && data.searchEligibleLives?.data?.length}</Heading>

      <hr />

      {loading && <p>loading...</p>}

      {data &&
        data.searchEligibleLives?.data?.map((item: any, index: number) => (
          <div key={index}>
            {item.id} / {item.firstName} / {item.lastName} / {item.phone1}
          </div>
        ))}
    </div>
  );
};

export default TestSearch;
