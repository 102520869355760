import { useContext } from "react";

import { CurrentUserContext } from "contexts/currentUserContext";
import { nonNull } from "utils/nonNull";

export function useCurrentCall() {
  const { data, loading } = useContext(CurrentUserContext);
  const currentCall = nonNull(data?.currentAccount?.data?.pal?.data?.visits?.data)[0];

  return { currentCall, loading };
}
