import { DataTableMenuItem } from "components/DataTableDropdownMenu";

export enum PriorityBonusMenuActions {
  noAction,
  cancelPriorityBonus,
  createPriorityBonus,
}

export const menuItems = (): DataTableMenuItem<PriorityBonusMenuActions>[] => {
  return [{ label: "Cancel bonus", action: PriorityBonusMenuActions.cancelPriorityBonus }];
};
