import { useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PlusCircle } from "@styled-icons/boxicons-solid/PlusCircle";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { ButtonLink } from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import config from "config";

import { CREATE_CREDIT_CARD } from "./gql";

export type CreditCardFormChange = {
  id: string;
  last4: string;
};

type Props = {
  accountId?: string;
  papaId?: string;
  onChange: (creditCard: CreditCardFormChange) => void;
  onCancel: () => void;
};

const AddCreditCardForm: React.FC<Props> = ({ accountId, papaId, onChange, onCancel }) => {
  const [isSubmittingCreditCard, setSubmitCreditCard] = useState(false);
  const [createCreditCard, { error }] = useMutation(CREATE_CREDIT_CARD);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (error) {
      toast.error(error as Error);
    }
  }, [error]);

  const handleAddNew = async () => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) return;

    setSubmitCreditCard(true);

    try {
      const { error, token: { id: tokenId, card } = {} } = await stripe.createToken(cardElement);

      if (error || !tokenId || !card) {
        // @ts-ignore
        toast.error((error as Error) ?? "Error on to create stripe token");
        return;
      }

      const input: { papaId?: string; accountId?: string } = {};
      if (config.featureFlag.papaCreditCard) {
        input.papaId = papaId;
      } else {
        input.accountId = accountId;
      }

      const { data } = await createCreditCard({
        variables: {
          input: {
            ...input,
            nickname: `${card.brand} - ${card.last4}`,
            brand: card.brand,
            expMonth: card.exp_month,
            expYear: card.exp_year,
            last4: card.last4,
            default: true,
            stripeCardId: card.id,
            stripeToken: tokenId,
          },
        },
      });

      if (data?.createCreditCard?.data?.id) {
        const creditCard = data.createCreditCard.data;
        toast.success("Your credit card was saved with success! ");
        onChange(creditCard);
      }
    } catch (error) {
      toast.error(error as Error);
    }

    setSubmitCreditCard(false);
  };

  return (
    <>
      <Form.GroupInline>
        <StripedWrapper>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "1rem",
                  color: "#424770",
                },
              },
            }}
          />
        </StripedWrapper>
        <Form.Inline>
          <ButtonLink type="button" variant="text" onClick={onCancel}>
            Cancel
          </ButtonLink>
        </Form.Inline>
      </Form.GroupInline>
      {!accountId && <Form.Feedback isInvalid>Please select the member first</Form.Feedback>}
      <ButtonLink
        type="button"
        variant="text"
        onClick={handleAddNew}
        disabled={isSubmittingCreditCard || !accountId}
      >
        {isSubmittingCreditCard ? (
          <>
            <SpinningIcon size={18} />
            <span>Saving...</span>
          </>
        ) : (
          <>
            <PlusCircle size={13} />
            Save
          </>
        )}
      </ButtonLink>
    </>
  );
};

const StripedWrapper = styled(Form.Inline)`
  width: 100%;
  max-width: 30rem;
  padding: 0.625rem 0.75rem;
`;

export default AddCreditCardForm;
