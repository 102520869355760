import React, { HTMLAttributes, ReactElement } from "react";
import styled from "styled-components";
import { Times } from "styled-icons/fa-solid";

interface Props extends HTMLAttributes<HTMLElement> {
  onDelete?: () => void;
}

const Pill = ({ children, onDelete }: Props): ReactElement => {
  const closeHandle = () => {
    onDelete && onDelete();
  };

  return (
    <PillBody>
      {children}
      <CloseButton type="button" onClick={closeHandle}>
        <Times size="10px" />
      </CloseButton>
    </PillBody>
  );
};

const PillBody = styled.span`
  display: inline-block;
  position: relative;
  font-size: 14px;
  margin: 0.5rem 1.25rem 0 0;
  padding: 4px 15px 4px 7px;
  color: ${({ theme }) => theme.text.panelHeader};
  background: ${({ theme }) => theme.background.invert};
`;

const CloseButton = styled.button`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 16px;
  height: 16px;
  border: none;
  border-radius: 50%;
  padding: 0;
  background: #ffdd2d;
  color: #000;
  font-size: 1px;
  cursor: pointer;
  outline: none;
`;

export default Pill;
