import * as yup from "yup";

import { VisitCommentType } from "constants/visitComment";

export interface Schema {
  type: VisitCommentType;
  text: string;
}

export const schema = yup.object().shape({
  type: yup.string().required("Please select comment type"),
  text: yup
    .string()
    .required("Please input comment")
    .max(1800, "Comment is limited to 1800 characters"),
});
