import { PapaState } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { PapaConcealed } from "types";

type Props = {
  papa?: PapaConcealed | null;
};

export const useAllowedSetPapaStatus = ({ papa }: Props) => {
  const { isGrievancesAppealsAdmin } = useCurrentAccountRole();

  if (!papa) return { isAllowedToSetPapaStatus: false };

  const isBannedOrSuspended = [PapaState.Banned, PapaState.Suspended].includes(
    papa?.status as PapaState
  );

  const isAllowedToSetPapaStatus =
    papa.status !== PapaState.Ineligible &&
    (papa.eligibleLife?.data?.id ? papa.isEligible : true) &&
    (!isBannedOrSuspended || isGrievancesAppealsAdmin);

  return {
    isAllowedToSetPapaStatus,
  };
};
