import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Label, StyledCheckbox } from "components/Checkbox";
import Tooltip from "components/Tooltip";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  tooltip?: string;
  disabled?: boolean;
  color?: string;
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ReadOnlyCheckbox: FunctionComponent<Props> = ({
  label,
  tooltip,
  disabled,
  color,
  ...props
}) => (
  <Tooltip title={tooltip!} isPrimary>
    <Container>
      <StyledCheckbox type="checkbox" readOnly {...props} color={color} />
      <Label disabled={disabled}>{label}</Label>
    </Container>
  </Tooltip>
);
