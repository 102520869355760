import { useFormikContext } from "formik";
import React from "react";
import styled, { css } from "styled-components";

import { CheckboxGroup } from "components/Checkbox/CheckboxGroup";
import Form from "components/Form";
import { RadioGroup } from "components/RadioGroup/RadioGroup";
import Select from "components/Select";
import { AssessmentQuestionFragment, QuestionEnum } from "generated/types";
import { AssessmentType, HardCodedQuestionsRequired } from "types/assessments";
import { nonNull } from "utils/nonNull";

import { FormValues } from "./models";

type Props = {
  type: AssessmentType;
  question: AssessmentQuestionFragment;
  hardCodedQuestionsRequired?: HardCodedQuestionsRequired;
  submitTouched: boolean;
};

const Questions = ({ type, question, hardCodedQuestionsRequired, submitTouched }: Props) => {
  const { values } = useFormikContext<FormValues>();

  const { id, questionText, type: questionType, options, position } = question;
  const optionsParsed = nonNull(options?.data).map((option) => ({
    label: option?.answerText as string,
    value: option?.id as string,
  }));
  const isLabelRequired =
    type !== AssessmentType.papaSocialIndex ||
    (!!question.position &&
      hardCodedQuestionsRequired &&
      hardCodedQuestionsRequired[`P${question.position}`]);

  const showWarning = submitTouched && isLabelRequired && !values[`question-${question.id}`];

  return (
    <Group key={id} showWarning={showWarning}>
      <Form.Label required={isLabelRequired} htmlFor={`question-${id}`}>
        {position} {questionText}
      </Form.Label>
      {questionType === QuestionEnum.CheckMultiple && (
        <CheckboxGroup name={`question-${id}`} options={optionsParsed} />
      )}
      {questionType === QuestionEnum.Multiple && optionsParsed.length > 5 && (
        <Select
          name={`question-${id}`}
          options={optionsParsed}
          isSearchable={false}
          placeholder="Select your answer"
          aria-label={questionText as string}
          menuPlacement="auto"
        />
      )}
      {questionType === QuestionEnum.Multiple && optionsParsed.length <= 5 && (
        <RadioGroup name={`question-${id}`} options={optionsParsed} />
      )}
      {questionType === QuestionEnum.Openended && (
        <Form.Input
          type="text"
          name={`question-${id}`}
          placeholder="Type your answer"
          aria-label={questionText as string}
        />
      )}
      {questionType === QuestionEnum.OpenendedNumeric && (
        <Form.Input
          type="number"
          name={`question-${id}`}
          placeholder="Type your answer, Ex: 0, 1, 2, 3"
          aria-label={questionText as string}
        />
      )}
    </Group>
  );
};

const Group = styled(Form.Group)<{ showWarning?: boolean }>`
  padding: 0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  ${({ showWarning }) =>
    showWarning &&
    css`
      background-color: rgb(255 165 0 / 20%);
      border-radius: 4px;
    `}
`;

export default Questions;
