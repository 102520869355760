import React from "react";
import { toast } from "react-toastify";

import { DropdownActionMenu } from "components/ActionsMenu/DropdownActionMenu";
import { confirm } from "components/Confirm";
import Dropdown from "components/Dropdown";
import {
  VisitForDashboardCardFragment,
  useAdminClaimVisitMutation,
  useAdminUnclaimVisitMutation,
} from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";

interface Props {
  visit: VisitForDashboardCardFragment;
}

export const ActionMenu = ({ visit }: Props) => {
  const [claimVisit] = useAdminClaimVisitMutation();
  const [unclaimVisit] = useAdminUnclaimVisitMutation();
  const { isSupervisor } = useCurrentAccountRole();
  const currentAccountId = useCurrentAccount()?.id;

  const claimedById = visit?.claimedBy?.data?.id;

  const handleClaimVisit = async () => {
    if (!(await confirm("Are you sure you want to claim this visit?"))) return;

    const claimVisitPromise = async () => {
      const { data } = await claimVisit({ variables: { visitId: visit.id! } });

      if (data?.adminClaimVisit?.data?.claimedBy?.data?.id !== currentAccountId) {
        throw new Error("Failed to claim visit");
      }
    };

    toast
      .promise(claimVisitPromise(), {
        pending: "Claiming visit...",
        success: "Visit claimed",
        error: {
          render({ data }: { data: Error }) {
            return data.message;
          },
        },
      })
      .catch(() => {});
  };

  const handleUnclaimVisit = async () => {
    if (!(await confirm("Are you sure you want to unclaim this visit?"))) return;

    const unclaimVisitPromise = async () => {
      const { data } = await unclaimVisit({ variables: { visitId: visit.id! } });

      if (data?.adminUnclaimVisit?.data?.claimedBy?.data?.id != null) {
        throw new Error("Failed to unclaim visit");
      }
    };

    toast
      .promise(unclaimVisitPromise(), {
        pending: "Unclaiming visit...",
        success: "Visit unclaimed",
        error: {
          render({ data }: { data: Error }) {
            return data.message;
          },
        },
      })
      .catch(() => {});
  };

  return (
    <DropdownActionMenu isListMenu={false}>
      <Dropdown.Link to={`/visits/${visit.id}`}>View visit details</Dropdown.Link>
      <Dropdown.Link to={`/papas/${visit.papa?.data?.id}`}>View papa</Dropdown.Link>
      {!!visit?.pal?.data && (
        <Dropdown.Link to={`/pals/${visit.pal?.data?.id}`}>View pal</Dropdown.Link>
      )}
      {!claimedById && <Dropdown.Item onClick={handleClaimVisit}>Claim visit</Dropdown.Item>}
      {claimedById && (claimedById === currentAccountId || isSupervisor) && (
        <Dropdown.Item onClick={handleUnclaimVisit}>Unclaim visit</Dropdown.Item>
      )}
    </DropdownActionMenu>
  );
};
