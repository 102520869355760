import { gql } from "@apollo/client";

export const COMPLETE_VISIT = gql`
  mutation completeVisit($id: ID!, $input: CompleteVisitInput!) {
    completeVisit(id: $id, input: $input) {
      data {
        id
        state
        completedAt
      }
    }
  }
`;

export const GET_VISIT = gql`
  query VisitForCompleteVisit($id: ID!) {
    visit(id: $id) {
      data {
        id
        startedAt
        location {
          data {
            id
            timezone
          }
        }
      }
    }
  }
`;
