import React, { FunctionComponent } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { ModalLayout } from "components/Modal/ModalLayout";
import { Objective } from "types/objective";

import { ObjectiveForm } from "../ObjectiveForm/ObjectiveForm";

interface Props {
  action: DataTableMenuActions;
  objective: Objective | null;
  closeModal: () => void;
}

const ObjectivesListActions: FunctionComponent<Props> = ({
  action,
  objective,
  closeModal,
}: Props) => (
  <>
    <ModalLayout
      toggle={closeModal}
      title={`${objective ? "Edit" : "Create"} Objective`}
      isOpen={action === DataTableMenuActions.Edit}
    >
      <ObjectiveForm objective={objective} onEditFinish={closeModal} />
    </ModalLayout>
  </>
);

export default ObjectivesListActions;
