import { useApolloClient } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useField, useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";
import uniqueId from "lodash/uniqueId";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import SwitchButton from "components/SwitchButton";
import Text from "components/Text";
import config from "config";
import { DEFAULT_TIMEZONE, TZ_FORMAT } from "constants/date";
import { FeatureFlags } from "constants/featureFlags";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useQueryTasks } from "hooks/useQueryTasks";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import { nonNull } from "utils/nonNull";
import { getDuration } from "utils/numbers/durations";
import { creditCardPlaceholder } from "utils/strings/creditCardPlaceholder";
import { getTasksNames } from "utils/strings/getTasksNames";

import { CREDIT_CARD_QUERY, PAPA_QUERY } from "../gql";
import { Values } from "../types";

interface Props {
  isEditMode?: boolean;
  submitTitle: string;
  sectionNumber?: number;
}

const Summary = ({ isEditMode = false, submitTitle, sectionNumber }: Props) => {
  const { singularEntityName: programOrBusiness } = useCallItClientProgramOrBusiness();
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const { isSupervisor } = useCurrentAccountRole();
  const [hasTouched, setTouched] = useState(false);
  const { isSubmitting, values, errors, submitForm, dirty } = useFormikContext<Values>();
  const [, { initialValue: initialScheduledForDay }] = useField("scheduledForDay");
  const [, { initialValue: initialScheduledForTime }] = useField("scheduledForTime");
  const [, { initialValue: initialSelectedRecommendedDate }] = useField("selectedRecommendedDate");
  const client = useApolloClient();
  const [summary, setSummary] = useState<SummaryState>({
    papa: null,
    creditCard: " - ",
    lastPapaId: "",
    lastCardId: "",
  });
  const { data: taskData } = useQueryTasks();

  useEffect(() => {
    const getAccount = async (papaId: string) => {
      const { data } = await client.query({
        query: PAPA_QUERY,
        variables: {
          papaId,
        },
      });

      if (data?.papa?.data) {
        const papa = papaConcealed(data.papa.data);
        setSummary({
          ...summary,
          lastPapaId: papaId,
          papa: {
            planName: papa?.account?.data?.plan?.data?.name ?? "no plan",
            fullName: papa?.fullName ?? "",
            phoneNumber: papa?.phoneNumber ?? "",
          },
        });
      }
    };
    const getCreditCard = async (id: string) => {
      const { data } = await client.query({
        query: CREDIT_CARD_QUERY,
        variables: {
          id,
        },
      });

      if (data?.creditCard?.data.last4) {
        setSummary({
          ...summary,
          lastCardId: id,
          creditCard: creditCardPlaceholder(data?.creditCard?.data.last4),
        });
      }
    };

    if (values.papaId && summary.lastPapaId !== values.papaId) {
      getAccount(values.papaId);
    }
    if (values.cardId && summary.lastCardId !== values.cardId) {
      getCreditCard(values.cardId);
    }
  }, [values, client, summary]);

  const hasErrors = !isEmpty(errors);

  const handleSubmitButton = () => {
    setTouched(true);
    if (hasErrors) {
      const errorMessages = Object.values(errors).join("; ");
      toast.error(`Invalid submission: ${errorMessages}`);
    }
    submitForm();
  };

  const scheduledForMoment =
    (values.scheduledForDay || values.selectedRecommendedDate) && values.scheduledForTime
      ? moment(
          moment(values.scheduledForDay || values.selectedRecommendedDate).format("LL") +
            " " +
            values.scheduledForTime,
          "LLL"
        )
      : null;

  const initialScheduledForMoment =
    (initialScheduledForDay || initialSelectedRecommendedDate) && initialScheduledForTime
      ? moment(
          moment(initialScheduledForDay || initialSelectedRecommendedDate).format("LL") +
            " " +
            initialScheduledForTime,
          "LLL"
        )
      : null;

  const isDateDirty = moment(scheduledForMoment).isSame(initialScheduledForMoment, "day") === false;

  const scheduledForDay = scheduledForMoment
    ? scheduledForMoment.format("dddd (MM/DD/YYYY)")
    : null;
  const scheduledForTime = scheduledForMoment ? scheduledForMoment.format("LT") : null;
  const estimatedDuration = getDuration(values.estimatedDuration ?? 5);
  const currentTaskNames = getTasksNames(nonNull(taskData?.tasks?.data), values.taskIds);
  const currentObjectives = values?.objectives?.map(({ label }) => label)?.join(", ") ?? "-";

  const timezone = values?.location
    ? moment()
        .tz(values.location.timezone || DEFAULT_TIMEZONE)
        .format(TZ_FORMAT)
    : "";

  const isRecurring = !!values.freq;

  return (
    <Form.Panel number={sectionNumber} headerText="Summary">
      <SummaryBox>
        <SummaryRow>
          <SummaryCol>
            <Text color="primary" bold>
              Papa
            </Text>
            {summary.papa ? (
              <>
                <Description>{summary.papa.fullName}</Description>
                <Description>
                  ({summary.papa.planName} {summary.papa.phoneNumber})
                </Description>
              </>
            ) : (
              <Description> - </Description>
            )}

            <Text color="primary" bold>
              Location
            </Text>
            <Description>{values?.location?.address ?? " - "}</Description>

            {values.memberNote && (
              <>
                <Text color="primary" bold>
                  Member Note
                </Text>
                <Description>{values.memberNote}</Description>
              </>
            )}

            {values.systemNotes && (
              <>
                <Text color="primary" bold>
                  System Notes
                </Text>
                <Description>
                  <ul>
                    {values.systemNotes &&
                      values.systemNotes.map((note) => (
                        <li
                          color="primary"
                          key={uniqueId()}
                          dangerouslySetInnerHTML={{ __html: note }}
                        />
                      ))}
                  </ul>
                </Description>
              </>
            )}

            <Text color="primary" bold>
              Scheduled For
            </Text>
            {scheduledForMoment && scheduledForMoment.isValid() ? (
              <>
                <Text>{scheduledForDay}</Text>
                <Description data-testid="schedule-visit-summary-description">
                  {scheduledForTime} {timezone}
                  {!!estimatedDuration && `Duration: ${estimatedDuration}`}
                </Description>
              </>
            ) : (
              <Description> - </Description>
            )}
          </SummaryCol>
          <SummaryCol>
            <Text color="primary" bold>
              Tasks
            </Text>
            <Description>{currentTaskNames}</Description>
            <Text color="primary" bold>
              Objectives
            </Text>
            <Description>{currentObjectives}</Description>
            {!hideCreditCardFields && (
              <>
                <Text color="primary" bold>
                  Credit Card
                </Text>
                <Description>{summary.creditCard}</Description>
              </>
            )}
          </SummaryCol>
        </SummaryRow>
      </SummaryBox>
      <div>
        {isRecurring && isEditMode && (
          <SummaryBoxAlt>
            <SummaryFullRow>
              <SummaryFullCol>
                <BoldLabel color="primary" required>
                  This visit is part of a recurring series.{" "}
                  {dirty && !isDateDirty && "Please choose an option before saving."}
                </BoldLabel>
                {dirty && !isDateDirty ? (
                  <Form.Group>
                    <Form.Radio
                      controlId="saveEditsToSingleVisit"
                      name="applyToFutureRecurrences"
                      value={false}
                      label="Save edits to this visit, only."
                    />
                    <Form.Radio
                      controlId="saveEditsToRecurringVisits"
                      name="applyToFutureRecurrences"
                      value={true}
                      label="Save edits to this and following visits"
                    />
                  </Form.Group>
                ) : (
                  <DescriptionAlt>
                    All edits made will be applied to this visit, only. If you need to make changes
                    to the entire series, you must create a new one.
                  </DescriptionAlt>
                )}
              </SummaryFullCol>
            </SummaryFullRow>
          </SummaryBoxAlt>
        )}
      </div>
      <Footer>
        <OverrideContainer>
          {config.featureFlag.businessPolicy && (
            <>
              <SwitchButton
                name="overrideBusinessPolicy"
                aria-label="overrideBusinessPolicy"
                label={`Override ${programOrBusiness} policies`}
                disabled={!isSupervisor ? true : undefined}
              />
              {values.overrideBusinessPolicy && (
                <Form.TextArea
                  name="obpReasonDescription"
                  placeholder="Reason"
                  rows={5}
                ></Form.TextArea>
              )}
            </>
          )}
        </OverrideContainer>
        <Button
          type="button"
          variant={hasErrors && hasTouched ? "danger" : "primary"}
          action={!isEditMode}
          disabled={isSubmitting}
          onClick={handleSubmitButton}
        >
          {isSubmitting ? (
            <>
              <SpinningIcon size={18} />
              <span>Submitting...</span>
            </>
          ) : hasErrors && hasTouched ? (
            "Error on submit"
          ) : (
            submitTitle
          )}
        </Button>
      </Footer>
    </Form.Panel>
  );
};

const SummaryBox = styled("div")`
  background: #f7f9ff;
  padding: 1.25rem 3.125rem;
  margin-bottom: 2rem;
`;

const SummaryBoxAlt = styled("div")`
  background: #fff9f0;
  padding: 1.25rem 3.125rem 0rem 3.125rem;
  margin-bottom: 2rem;
`;

const Description = styled(Text)`
  margin-bottom: 20px;
`;

const DescriptionAlt = styled(Text)`
  margin-bottom: 1.25rem;
`;

const SummaryRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  margin-right: -1rem;

  @media (min-width: 75rem) {
    flex-direction: row;
  }
`;

const SummaryFullRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const SummaryCol = styled.div`
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  & + & {
    margin-top: 1rem;
  }

  @media (min-width: 75rem) {
    & + & {
      margin-top: 0;
    }
    width: 50%;
  }
`;

const SummaryFullCol = styled.div`
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  & + & {
    margin-top: 1rem;
  }
`;

const OverrideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 60%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const BoldLabel = styled(Form.Label)`
  font-weight: bold;
  white-space: nowrap;
`;

type SummaryState = {
  papa: {
    fullName: string;
    phoneNumber: string;
    planName: string;
  } | null;
  creditCard: string;
  lastPapaId: string;
  lastCardId: string;
};

export default Summary;
