import { DEFAULT_TIMEZONE } from "constants/date";
import { Location } from "generated/types";

import { SelectedItem } from "./index";

export const getLocationOptions = (locations: Location[]) =>
  locations.map((location) => ({
    value: location.id,
    label: `${location.address}${location.address2 ? ` - ${location.address2}` : ""}${
      location.nickname ? ` - ${location.nickname}` : ""
    }`,
    timezone: location.timezone || DEFAULT_TIMEZONE,
    state: location.state,
    city: location.city,
    county: location.county,
    supportOperatingHours: location.supportOperatingHours,
  })) as SelectedItem[];
