import React from "react";
import { Route, Switch } from "react-router-dom";

import PapaDetails from "./Details";
import Papas from "./List";

const PapasRoutes = () => {
  return (
    <Switch>
      <Route exact path="/papas" component={Papas} />
      <Route
        exact
        path={["/papas/:id", "/papas/:id/:tab", "/papas/:id/care-concierge/:serviceId"]}
        component={PapaDetails}
      />
      <Route
        exact
        path={["/papas/:id", "/papas/:id/:tab", "/papas/:id/careconcierge/:serviceId/:followUpId"]}
        component={PapaDetails}
      />
    </Switch>
  );
};

export default PapasRoutes;
