import React, { FunctionComponent } from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import Dropdown from "components/Dropdown";
import { FeatureFlags } from "constants/featureFlags";
import { SupportTicketRequesterType } from "generated/types";
import { Pal } from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { DropdownActionMenu } from "../DropdownActionMenu";

interface Props {
  pal: Pal;
  isListMenu?: boolean;
  setAction: (action: DataTableMenuActions, pal: Pal) => void;
}

const PalActionsMenu: FunctionComponent<Props> = ({ pal, setAction, isListMenu = false }) => {
  const { isSupervisor } = useCurrentAccountRole();
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();
  const id = pal.id!;
  const type = SupportTicketRequesterType.Pal;
  const isAdmin = !!pal?.account?.data?.permissions?.admin;
  const canCreateSupportTicket = supportTicketingEnabled && !isAdmin;

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "pal action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  if (loading) return <SpinningIcon size={18} />;

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {isListMenu && <Dropdown.Link to={`/pals/${pal.id}`}>View Pal</Dropdown.Link>}
      {isSupervisor && (
        <Dropdown.Link to={`/accounts/${pal.id}/activity-logs`}>View account logs</Dropdown.Link>
      )}
      {renderDropDownItem("Edit pal", () => setAction(DataTableMenuActions.Edit, pal))}
      {isListMenu &&
        renderDropDownItem("Edit Pal Status", () =>
          setAction(DataTableMenuActions.SetPalStatus, pal)
        )}

      {renderDropDownItem("Send reset password email", () =>
        setAction(DataTableMenuActions.ResetPassword, pal)
      )}
      {renderDropDownItem("Send verification email", () =>
        setAction(DataTableMenuActions.SendVerificationEmail, pal)
      )}
      {renderDropDownItem("Delete", () => setAction(DataTableMenuActions.Delete, pal))}
      {canCreateSupportTicket ? (
        <>
          {renderDropDownItem("Report pal/agent interaction", () =>
            handleSubmitSupportTicket({ id, type, redirectToAgent: true })
          )}
          {renderDropDownItem("Submit Ticket", () => handleSubmitSupportTicket({ id, type }))}
        </>
      ) : null}
    </DropdownActionMenu>
  );
};

export default PalActionsMenu;
