import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import PapaLogo from "assets/images/papa-logo-white.svg";
import VirtualCall from "components/VirtualCall";

import MainContainerContext from "./MainContainerContext";

const Topbar = () => {
  const { topBarOptions } = useContext(MainContainerContext);
  const { title, icon: Icon, breadcrumbs, name } = topBarOptions;

  return (
    <TopbarContainer>
      <VirtualCall isOutside />
      <Content>
        {!!breadcrumbs?.length && (
          <Breadcrumb>
            {Icon && <Icon size={24} />}
            {breadcrumbs.map((breadcrumb, index) => {
              const lastItem = index === breadcrumbs.length - 1;

              if (typeof breadcrumb === "string") {
                return (
                  <React.Fragment key={index}>
                    {breadcrumb}
                    {!lastItem && <ChevronRight size={24} />}
                  </React.Fragment>
                );
              }

              return (
                <React.Fragment key={index}>
                  <Link key={index} to={breadcrumb.link ?? "#"}>
                    {breadcrumb.label}
                  </Link>
                  {!lastItem && <ChevronRight size={24} />}
                </React.Fragment>
              );
            })}
          </Breadcrumb>
        )}
        <MainCategory>
          {Icon && !breadcrumbs?.length && <Icon size={32} />} {title} {name && <span>{name}</span>}
        </MainCategory>
      </Content>
      <Logo src={PapaLogo} alt="Papa logo" />
    </TopbarContainer>
  );
};

const Logo = styled.img`
  height: 66px;
`;

const TopbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.variants.primary};

  position: sticky;
  top: 0;
  z-index: 10;

  height: 136px;
  width: 100%;
  padding: 0 33px;
`;

const Content = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const MainCategory = styled.h2`
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0;

  > span {
    font-weight: 200;
  }
`;

const Breadcrumb = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 8px;

  > a {
    color: white;
  }
`;

export default Topbar;
