import debounce from "lodash/debounce";
import omit from "lodash/omit";
import queryString from "query-string";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Async from "react-select/async";
import { ValueType } from "react-select/src/types";

import Button from "components/Button";
import { ChangeSelectEvent } from "components/UserSelect";
import { useUrlQuery } from "hooks/useUrlQuery";
import { PillData } from "types/search";

import { OptionLabel, SearchBox, formatOptionLabel, searchFieldStyles } from "./";

interface Props {
  pills: PillData[];
  setPills: (pills: PillData[] | ((pills: PillData[]) => PillData[])) => void;
  loadOptions: (value: string) => Promise<OptionLabel[]>;
  placeholder?: string;
  onlyOnePill?: boolean;
}

const VisitSearch: FC<Props> = ({
  pills,
  setPills,
  loadOptions,
  placeholder,
  onlyOnePill = true,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const urlQueries = useUrlQuery();

  const onAsyncChange = (event: ValueType<ChangeSelectEvent>) => {
    const { value, name, papaId, visitId } = event as ChangeSelectEvent;
    if (onlyOnePill && pills.length === 1) {
      setPills([{ id: value, name, papaId, visitId }]);
    } else {
      setPills((pills) => {
        if (pills.map(({ id }) => id).includes(value)) {
          return pills;
        }

        return [...pills, { id: value, name, papaId, visitId }];
      });
    }
  };

  const onSubmitSearch = () => {
    const pillSearch = pills.map(({ name, id }) => `${name}:${id}`);

    history.push(
      location.pathname +
        "?" +
        queryString.stringify({
          ...omit(urlQueries, ["papaId", "visitId"]),
          page: 1,
          ...(pills?.[0]?.papaId ? { papaId: pillSearch } : {}),
          ...(pills?.[0]?.visitId ? { visitId: pills[0].visitId } : {}),
        })
    );
  };
  const fetch = (inputValue: string, callback: any) => {
    loadOptions(inputValue).then((results: any) => callback(results));
    // Explicitly not returning a Promise.
    return;
  };

  const debouncedSearch = debounce(fetch, 500);

  return (
    <SearchBox>
      <Async
        aria-label="search"
        placeholder={placeholder}
        loadOptions={debouncedSearch}
        onChange={onAsyncChange}
        value={null}
        // @ts-ignore TODO:remove this
        styles={searchFieldStyles}
        formatOptionLabel={formatOptionLabel}
      />
      <Button onClick={onSubmitSearch}>Search</Button>
    </SearchBox>
  );
};

export default VisitSearch;
