import { ApolloError } from "@apollo/client";
import { useField } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { confirm } from "components/Confirm";
import Link from "components/Link";
import { ConfirmModalIcon } from "components/Modal/ConfirmModal";
import SmartTable from "components/SmartTable";
import { SwitchButtonInput } from "components/SwitchButton";
import { PalWithVisitMetadataResult, useUpdatePreferredPalsMutation } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

interface Props {
  data: PalWithVisitMetadataResult[];
  loading: boolean;
  error?: ApolloError;
  onRemovePreferredPal: () => void;
  favoritePalIds: string[];
  promotablePalsExist: boolean;
  nameColumnWidth?: string;
}
export const PreferredPalCompletedVisitRatings = ({
  data,
  loading,
  error,
  onRemovePreferredPal,
  favoritePalIds,
  promotablePalsExist,
  nameColumnWidth = "22em",
}: Props) => {
  const [{ value: papaId }] = useField("papaId");

  const [removePreferredPalMutation, { loading: updatePreferredPalLoading }] =
    useUpdatePreferredPalsMutation();

  const removePreferredPal = async (palId?: string | null, palName?: string | null) => {
    if (!palId) return;

    const confirmed = await confirm(
      <>
        This pal will no longer:
        <ul>
          <li>Get priority access to all of this member's visits</li>
          <li>Receive visit requests and push notifications about this member's visits</li>
        </ul>
      </>,
      {
        header: `Remove ${palName} as a Preferred Pal`,
        cancelBtnText: "No, go back",
        confirmBtnText: "Yes, continue",
        icon: ConfirmModalIcon.none,
      }
    );
    if (!confirmed) return;

    const newFavoritePalIds = favoritePalIds.filter((id) => id !== palId);

    try {
      const { data } = await removePreferredPalMutation({
        variables: {
          papaId,
          favoritePalIds: newFavoritePalIds,
          removeFavoritePals: newFavoritePalIds.length === 0,
        },
      });

      if (data?.updatePapa?.data?.id) {
        toast.success(palName + " was removed as a Preferred Pal");
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
    await onRemovePreferredPal();
  };

  if (data.length === 0)
    return (
      <StyledEmptyTable>
        {promotablePalsExist
          ? "Reminder: Ask member if they would like their pal to become a Preferred Pal"
          : "No Preferred Pals"}
      </StyledEmptyTable>
    );
  return (
    <>
      <SmartTable
        loading={loading}
        error={error}
        keyField="palId"
        showPagination={false}
        columns={[
          {
            header: { title: "Name" },
            dataField: "fullName",
            formatter: ({ fullName, id }) => <Link to={`/pals/${id}`}>{fullName ?? ""}</Link>,
            cellProps: { width: nameColumnWidth },
          },
          {
            header: { title: "Last visit" },
            dataField: "latestVisitScheduledFor",
            formatter: ({ latestVisitScheduledFor }) =>
              formatDateTime(latestVisitScheduledFor, { format: "MMM DD, YYYY" }),
          },
          {
            header: { title: "Member visit rating" },
            dataField: "averageRatingForPapa",
            formatter: ({ averageRatingForPapa }) =>
              averageRatingForPapa ? Math.round(averageRatingForPapa * 100) / 100 + "/5" : "",
          },
          {
            header: { title: "Pal visit rating" },
            dataField: "averageRatingForPal",
            formatter: ({ averageRatingForPal }) =>
              averageRatingForPal ? Math.round(averageRatingForPal * 100) / 100 + "/5" : "",
          },
          {
            header: { title: "Total visits" },
            dataField: "visitCount",
            formatter: ({ visitCount }) => visitCount ?? "",
          },
          {
            header: { title: "Preferred Pal" },
            dataField: "preferredPal",

            formatter: ({ id, fullName }) => (
              <>
                <SwitchButtonInput
                  checked={true}
                  disabled={updatePreferredPalLoading}
                  onChange={() => removePreferredPal(id, fullName)}
                />{" "}
                Yes
              </>
            ),
          },
        ]}
        data={data}
      />
    </>
  );
};

const StyledEmptyTable = styled.div`
  padding: 20px;
  font-style: italic;
  color: ${({ theme }) => theme.text.muted};
`;
