import { gql } from "@apollo/client";

export const visitListFragment = gql`
  fragment VisitPartsForDataTables on Visit {
    id
    state
    scheduledFor
    estimatedDuration
    isVirtual
    isEmployerVisit
    isPreviouslyMissed
    isUberEligible
    applyVisitProgram
    deductedMins
    tasks {
      data {
        id
        icon
        requiresVehicle
        name
      }
    }
    account {
      data {
        id
        fullName
      }
    }
    papa {
      data {
        id
        fullName
        business {
          data {
            id
            name
            currentBusinessPolicy {
              data {
                id
                warmTransfer
                warmTransferPhoneNumber
              }
            }
          }
        }
        caregiver {
          data {
            id
          }
        }
        dataVisibility {
          concealed
        }
      }
    }
    pal {
      data {
        id
        fullName
      }
    }
    recurrence {
      data {
        id
        firstScheduledFor
        validUntil
        config {
          freq
          interval
          byDay
        }
      }
    }
    visitFlags {
      data {
        id
        name
        reason
        resolvedAt
        description
      }
    }
    location {
      data {
        city
        county
        houseNumber
        lat
        lng
        state
        streetName
        zipcode
        timezone
      }
    }
  }
`;

export const GET_VISITS_LIST = gql`
  query visits($filter: [VisitFilter!], $pagination: PaginationInput, $sorting: VisitSorting) {
    visits(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        ...VisitPartsForDataTables
        demandProfile
        events(filter: { action: { eq: MEMBER_CONFIRMED } }) {
          data {
            ... on VisitEvent {
              action
            }
          }
        }
        visitRating {
          data {
            id
            ratingForPapa
          }
        }
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
  ${visitListFragment}
`;

export const GET_VISITS_LIST_WITH_CURSORS = gql`
  query VisitsWithCursor(
    $filter: [VisitFilter!]
    $pagination: PaginationInput
    $sorting: VisitSorting
  ) {
    visits(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        ...VisitPartsForDataTables
        demandProfile
        events(filter: { action: { eq: MEMBER_CONFIRMED } }) {
          data {
            ... on VisitEvent {
              action
            }
          }
        }
        visitRating {
          data {
            id
            ratingForPapa
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
  ${visitListFragment}
`;
