import { Location } from "generated/types";

export type GoogleLocationResult = Pick<
  Location,
  | "id"
  | "address"
  | "lat"
  | "lng"
  | "houseNumber"
  | "streetName"
  | "city"
  | "state"
  | "zipcode"
  | "countryIso"
>;

export const geoCodeResultToLocation = (result: google.maps.GeocoderResult) => {
  const initialObj: GoogleLocationResult = {
    address: result.formatted_address,
    lat: result.geometry.location.lat().toString(),
    lng: result.geometry.location.lng().toString(),
  };

  const location = result.address_components.reduce((acc, component) => {
    switch (component.types[0]) {
      case "street_number":
        acc.houseNumber = component.short_name;
        break;
      case "route":
        acc.streetName = component.short_name;
        break;
      case "sublocality_level_1":
        acc.city = component.short_name;
        break;
      case "locality":
        acc.city = component.short_name;
        break;
      case "administrative_area_level_1":
        acc.state = component.short_name;
        break;
      case "postal_code":
        acc.zipcode = component.short_name;
        break;
      case "country":
        acc.countryIso = component.short_name;
        break;
      default:
        break;
    }

    return acc;
  }, initialObj);

  return location;
};
