import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { AccountSingleResult, BiographyQuestions } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";

import { MemberBioQuestions } from "../constants";
import { GET_MEMBER_INFO } from "../gql";
import { EditMemberBio } from "./EditBio/EditBio";

type Data = {
  account: AccountSingleResult;
};

const Information = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<Data>(GET_MEMBER_INFO, {
    variables: { id },
  });

  const { isOpen: isBioModalOpen, toggle: toggleModal } = useModalToggle();
  const { pal } = data?.account.data ?? {};
  const { bio } = pal?.data ?? {};
  const [question] = MemberBioQuestions;
  const bioQuestion: BiographyQuestions = { question, answer: bio ?? "" };

  if (loading) return <p>loading...</p>;

  return (
    <>
      <Helmet>
        <title>Member Details - Papa Admin</title>
      </Helmet>

      <QueryErrors error={error} />

      <Panel title="Bio" onEditClick={toggleModal}>
        <Text bold>Can you tell me a little bit about yourself?</Text>
        <Text color="muted">{bio || EMPTY_PLACEHOLDER}</Text>
      </Panel>

      <ModalLayout title="Edit Bio" isOpen={isBioModalOpen} toggle={toggleModal}>
        <EditMemberBio
          palId={pal?.data?.id as string}
          answers={[bioQuestion]}
          onEditFinish={toggleModal}
        />
      </ModalLayout>
    </>
  );
};

export default Information;
