import { gql } from "@apollo/client";

export const UPDATE_PAPA_OVERVIEW = gql`
  mutation UpdatePapaOverview($id: ID!, $input: PapaInput!) {
    updatePapa(id: $id, input: $input) {
      data {
        id
        fullName
        description
        preferredContactMethod
        stillDrives
        memberId
        covidVaccinated
        warnings {
          data
        }
        status
        requirements {
          data
        }
        maskedPhoneChanges {
          palId
          oldMaskNumber
          newMaskNumber
        }
      }
    }
  }
`;
