import { gql } from "@apollo/client";

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $input: LocationInput) {
    updateLocation(id: $id, input: $input) {
      data {
        id
        address
        address2
        nickname
      }
    }
  }
`;
