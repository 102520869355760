import { Formik, Form as FormikForm } from "formik";
import moment, { Moment } from "moment-timezone";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { AccountSearchSelect } from "components/AccountSearchSelect";
import Button from "components/Button";
import Form from "components/Form";
import { HourPicker } from "components/HourPicker/HourPicker";
import InputDateRange from "components/InputDateRange";
import {
  CheckboxContainer,
  Container,
  FilterTitle,
  FilterType,
  Footer,
  StyledFormGroup,
  TimeContainer,
  TimeSeparator,
} from "components/Modal/FilterModal.styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { DEFAULT_TIMEZONE, timezoneOptions } from "constants/date";
import { visitStateOptions } from "constants/visit";
import { VisitState } from "generated/types";
import { QueryParams, usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";
import { visitsDefaultStates } from "pages/Home";

import { getVisitsFiltersUrlQuery } from "./searchUrlQuery";

export interface FormValues {
  states: VisitState[] | null;
  timeTo: string | null;
  timeFrom: string | null;
  visitType: string;
  timezone: string;
  accountId: string | null;
  dateRange: {
    endDate: Moment | string | null;
    startDate: Moment | string | null;
  };
}

interface Props {
  onSubmit: () => void;
  enableMembersSearch?: boolean;
  hideCleanFilter?: boolean;
  visitFiltersDefault?: Partial<QueryParams>;
  allStatesDefault?: boolean;
}

export const VisitFiltersModal: React.FC<Props> = ({
  onSubmit,
  enableMembersSearch,
  hideCleanFilter,
  visitFiltersDefault,
  allStatesDefault = false,
}) => {
  const history = useHistory();
  const urlQueries = useUrlQuery();

  const { page, limit, ...visitFiltersUrl } = usePageInfo(
    allStatesDefault ? Object.values(VisitState) : visitsDefaultStates
  );

  const handleSubmit = async (values: FormValues) => {
    const filters = getVisitsFiltersUrlQuery(values);
    const query = queryString.stringify({ ...urlQueries, ...filters }, { arrayFormat: "index" });

    history.push(`?${query}`);
    onSubmit();
  };

  const visitFilters = visitFiltersDefault || visitFiltersUrl;

  const initialValues = {
    accountId: visitFilters.accountId || null,
    timezone: visitFilters.timezone || DEFAULT_TIMEZONE,
    timeFrom: visitFilters.dayTimeFrom
      ? moment
          .tz(visitFilters.dayTimeFrom, "UTC")
          .tz(visitFilters.timezone || DEFAULT_TIMEZONE)
          .format("h:mm a")
      : null,
    timeTo: visitFilters.dayTimeTo
      ? moment
          .tz(visitFilters.dayTimeTo, "UTC")
          .tz(visitFilters.timezone || DEFAULT_TIMEZONE)
          .format("h:mm a")
      : null,
    states: visitFilters.states ? (visitFilters.states.split(",") as VisitState[]) : [],
    visitType: visitFilters.visitType || "isBoth",
    dateRange: {
      startDate: visitFilters.dayTimeFrom ? moment.tz(visitFilters.dayTimeFrom, "UTC") : null,
      endDate: visitFilters.dayTimeTo ? moment.tz(visitFilters.dayTimeTo, "UTC") : null,
    },
  };

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm, setFieldValue }) => (
        <FormikForm>
          <Container>
            <ModalContainer>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">time</FilterType>
                    </FilterTitle>

                    <TimeContainer>
                      <HourPicker interval={30} name="timeFrom" placeholder="9:00am" />
                      <TimeSeparator as="span">to</TimeSeparator>
                      <HourPicker interval={30} name="timeTo" placeholder="10:00am" />
                    </TimeContainer>
                  </StyledFormGroup>
                </Form.Col>
                <Form.Col>
                  <FilterTitle bold size="largex">
                    Filter by
                    <FilterType as="span">visit type</FilterType>
                  </FilterTitle>
                  <Form.GroupInline>
                    <Form.Inline>
                      <Form.Radio
                        name="visitType"
                        value="isVirtual"
                        controlId="isVirtual"
                        label="Virtual"
                      />
                    </Form.Inline>
                    <Form.Inline>
                      <Form.Radio
                        name="visitType"
                        value="isInPerson"
                        controlId="isInPerson"
                        label="In-person"
                      />
                    </Form.Inline>
                    <Form.Inline>
                      <Form.Radio name="visitType" value="isBoth" controlId="isBoth" label="both" />
                    </Form.Inline>
                  </Form.GroupInline>
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">timezone</FilterType>
                    </FilterTitle>
                    <Select
                      name="timezone"
                      placeholder="Select timezone"
                      isSearchable={false}
                      options={timezoneOptions}
                    />
                  </StyledFormGroup>
                </Form.Col>
                <Form.Col>
                  <StyledFormGroup>
                    <FilterTitle bold size="largex">
                      Filter by
                      <FilterType as="span">date</FilterType>
                      <TodayButton
                        type="button"
                        onClick={() => {
                          const today = moment();
                          setFieldValue("dateRange", {
                            startDate: today.startOf("day"),
                            endDate: today.endOf("day"),
                          });
                        }}
                      >
                        Today
                      </TodayButton>
                    </FilterTitle>
                    <InputDateRange
                      id="dateRange"
                      name="dateRange"
                      isOutsideRange={() => false}
                      minimumNights={0}
                    />
                  </StyledFormGroup>
                </Form.Col>
              </Form.Row>
              {enableMembersSearch && (
                <Form.Row>
                  <Form.Col>
                    <StyledFormGroup>
                      <AccountSearchSelect name="accountId" />
                    </StyledFormGroup>
                  </Form.Col>
                </Form.Row>
              )}
              <StyledFormGroup>
                <FilterTitle bold size="largex">
                  Filter by
                  <FilterType as="span">visit status</FilterType>
                </FilterTitle>

                <CheckboxContainer>
                  {visitStateOptions.map(({ label, value }) => {
                    return (
                      <div key={value}>
                        <Form.Check
                          controlId={`visitState-${value}`}
                          name="states"
                          value={value}
                          label={label}
                        />
                      </div>
                    );
                  })}
                </CheckboxContainer>
              </StyledFormGroup>
            </ModalContainer>
            <Footer>
              {!hideCleanFilter && (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() =>
                    resetForm({
                      values: {
                        accountId: null,
                        states: [] as VisitState[],
                        visitType: "isBoth",
                        timeFrom: null,
                        timeTo: null,
                        timezone: DEFAULT_TIMEZONE,
                        dateRange: {
                          startDate: null,
                          endDate: null,
                        },
                      },
                    })
                  }
                >
                  Clear Filters
                </Button>
              )}
              <Button type="submit" variant="primary">
                Apply
              </Button>
            </Footer>
          </Container>
        </FormikForm>
      )}
    </Formik>
  );
};

const TodayButton = styled(Button)`
  display: inline-block;
  margin-left: 10px;
  border-width: 2px;
  padding: 0.125rem 1rem;
`;
