import { gql } from "@apollo/client";

export const GET_LOCATION = gql`
  query location($id: ID!) {
    location(id: $id) {
      data {
        id
        address
        address2
        city
        countryIso
        lat
        lng
        state
        zipcode
        note
        nickname
      }
    }
  }
`;
