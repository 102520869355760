import { useQuery } from "@apollo/client";
import React, { ReactElement } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import SmartTable from "components/SmartTable";
import { VisitRating, VisitRatingListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import ApproveOrRejectModal from "./ApproveOrRejectModal";
import { GET_PAL_REVIEW } from "./gql";
import { usePalReviewTableColumns } from "./usePalReviewTableColumns";

interface Data {
  visitRatings: VisitRatingListResult;
}

const PalReviewsTable = (): ReactElement => {
  const { page, limit } = usePageInfo();
  const { columns, action, id, closeModal } = usePalReviewTableColumns();
  const { data, loading, refetch } = useQuery<Data>(GET_PAL_REVIEW, {
    variables: {
      pagination: { page, limit },
    },
  });
  const ratings = data?.visitRatings.data;
  const pagination = data?.visitRatings.pagination;

  return (
    <>
      <SmartTable<VisitRating>
        keyField="id"
        data={ratings}
        loading={loading}
        columns={columns}
        pagination={pagination}
      />

      <ApproveOrRejectModal
        id={id!}
        action={action}
        toggle={closeModal}
        isOpen={action === DataTableMenuActions.Approve || action === DataTableMenuActions.Reject}
        refetch={refetch}
      />
    </>
  );
};

export default PalReviewsTable;
