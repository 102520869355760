import { createGlobalStyle } from "styled-components";

import fonts from "./fonts";

export const GlobalStyle = createGlobalStyle`
  ${fonts}

  html {
    box-sizing: border-box;
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${({ theme }) => theme.text.main};
    font-size: 16px;
    line-height: 1.5;
    background: ${({ theme }) => theme.background.body};
    font-family: "Avenir",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }

  #root {
    min-height: 100vh;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }
`;
