import { EligibilityMethod } from "generated/types";

export const employerEligibilityMethodOptions = [
  {
    label: "Select an Option",
    value: "",
  },
  {
    label: "Employee email file",
    value: EligibilityMethod.Email,
  },
  {
    label: "Employer email domain",
    value: EligibilityMethod.EmailDomain,
  },
  {
    label: "Employee data file",
    value: EligibilityMethod.EmployeeData,
  },
];
