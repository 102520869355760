import { isValidPhoneNumber } from "react-phone-number-input";
import { object, string } from "yup";

export interface FormValues {
  fullName?: string | null;
  email?: string | null;
  birthDate?: string | null;
  phoneNumber?: string | null;
  gender?: string | null;
}

export const schema = object().shape({
  fullName: string().required("Please input full name."),
  email: string().email("Not a valid email address!").required("Please input email."),
  phoneNumber: string()
    .required("Please input phone number.")
    .test("phoneNumber", "Not a valid phone number!", (value) =>
      value ? isValidPhoneNumber(value) : true
    ),
  birthDate: string().nullable(),
  gender: string().nullable(),
});
