import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import ServiceRequestClosedReasonSelect from "components/ServiceRequest/ClosedReasonSelect";
import ServiceRequestStatusSelect from "components/ServiceRequest/StatusSelect";
import {
  ServiceRequestClosedReason,
  ServiceRequestStatus,
  useChangeServiceRequestStatusMutation,
} from "generated/types";

import { Schema, schema } from "./schema";

interface Props {
  serviceRequestId: string;
  status?: string | null;
  closedReason?: ServiceRequestClosedReason | null;
  isOpen: boolean;
  toggle: () => void;
  onRefetch: () => void;
}
export const EditStatusModal = ({
  serviceRequestId,
  status,
  closedReason,
  isOpen,
  toggle,
  onRefetch,
}: Props) => {
  const [servicerequestMutation] = useChangeServiceRequestStatusMutation();

  const initialValues: Schema = {
    status: status as ServiceRequestStatus,
    closedReason,
  };

  const handleSubmit = async (values: Schema) => {
    try {
      const { data } = await servicerequestMutation({
        variables: { id: serviceRequestId, input: values },
      });

      if (data?.changeServiceRequestStatus?.data?.id) {
        toast.success("Change Status successful!");
        onRefetch();
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error: any) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Change Status" isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ values }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label>Select Status</Form.Label>
                <ServiceRequestStatusSelect />
              </Form.Group>

              {values.status === ServiceRequestStatus.Closed && (
                <Form.Group>
                  <Form.Label>Select reason</Form.Label>
                  <ServiceRequestClosedReasonSelect />
                </Form.Group>
              )}
            </ModalContainer>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Form.SubmitButton>Save</Form.SubmitButton>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};
