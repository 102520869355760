import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import CareConciergeAssignSelect from "components/ServiceRequest/AssignSelect";
import { ServiceType } from "constants/service-requests";

import { SERVICE_REQUEST_REASSIGN } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  type?: ServiceType;
  serviceRequestIds: string[];
  isOpen: boolean;
  toggle: () => void;
  onRefetch: () => void;
}

export const ReassignModal = ({
  type,
  serviceRequestIds,
  isOpen,
  toggle,
  onRefetch,
}: Props): ReactElement => {
  const [servicerequestMutation] = useMutation(SERVICE_REQUEST_REASSIGN);

  const initialValues: Schema = {
    assignedToId: " ",
  };

  const handleSubmit = async ({ assignedToId, ...formData }: Schema) => {
    try {
      const { data } = await servicerequestMutation({
        variables: {
          ids: serviceRequestIds,
          input: {
            assignedToId: assignedToId === " " ? null : assignedToId,
            ...formData,
          },
        },
      });
      if (data) {
        toast.success("Service request assign successful!");
        onRefetch();
        toggle();
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  return (
    <ModalLayout toggle={toggle} title="Assign Service Requests" isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label>Assign To</Form.Label>
                <CareConciergeAssignSelect name="assignedToId" placeholder="Select User" />
              </Form.Group>
            </ModalContainer>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Form.SubmitButton isSubmitting={isSubmitting}>Save</Form.SubmitButton>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};
