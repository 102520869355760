import React, { FunctionComponent } from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";

import BusinessPolicyForm from "./BusinessPolicyForm";

interface Props {
  accountId: string;
  onClose: () => void;
  onCreateFinish: () => void;
  isOpen: boolean;
}

const BusinessPolicyModal: FunctionComponent<Props> = ({
  accountId,
  isOpen,
  onClose,
  onCreateFinish,
}) => {
  const {
    capitalized: { singularEntityName },
  } = useCallItClientProgramOrBusiness();

  return (
    <ModalLayout toggle={onClose} title={`${singularEntityName} Policy`} isOpen={isOpen}>
      <BusinessPolicyForm accountId={accountId} onClose={onClose} onCreateFinish={onCreateFinish} />
    </ModalLayout>
  );
};

export default BusinessPolicyModal;
