import { gql } from "@apollo/client";

export const MAKE_VISIT_CRITICAL = gql`
  mutation createVisitFlag($input: VisitFlagInput!) {
    createVisitFlag(input: $input) {
      data {
        id
        name
        visit {
          data {
            id
            visitFlags {
              data {
                id
                resolvedAt
                description
              }
            }
          }
        }
      }
    }
  }
`;
