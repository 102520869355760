import React from "react";

import Text from "components/Text";
import { Location } from "generated/types";

const SeeMapLink = ({
  location,
}: {
  location?: { lat?: number | string | null; lng?: number | string | null } | Location | null;
}) => {
  if (!location?.lat || !location?.lng) return null;

  return (
    <Text>
      <a
        href={`https://maps.google.com/?q=${location?.lat},${location?.lng}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        See map
      </a>
    </Text>
  );
};

export default SeeMapLink;
