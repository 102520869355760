import { gql } from "@apollo/client";

export const CREATE_OBJECTIVE = gql`
  mutation createVisitObjectiveTemplate($input: VisitObjectiveTemplateInput!) {
    createVisitObjectiveTemplate(input: $input) {
      data {
        id
        description
        insertedAt
        updatedAt
        deletedAt
        isActive
        task {
          data {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_OBJECTIVE = gql`
  mutation updateVisitObjectiveTemplate($id: ID!, $input: VisitObjectiveTemplateInput!) {
    updateVisitObjectiveTemplate(id: $id, input: $input) {
      data {
        id
        description
        updatedAt
        isActive
        task {
          data {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_OBJECTIVE_TASKS = gql`
  query Tasks($filter: [TaskFilter], $pagination: PaginationInput, $sorting: TaskSorting) {
    tasks(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        name
      }
    }
  }
`;
