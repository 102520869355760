import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { PlusCircle } from "@styled-icons/boxicons-solid/PlusCircle";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Async from "react-select/async";
import { OptionTypeBase, ValueType } from "react-select/src/types";

import { ButtonLink } from "components/Button";
import Form from "components/Form";
import { InputContainer, StyledInput } from "components/Form/FormInput";
import { customStyles } from "components/Select";
import { CreditCardListResult, PapaSingleResult } from "generated/types";
import { GET_ACCOUNT_BY_PAPA } from "pages/ScheduleVisit/gql";
import { nonNullWithId } from "utils/nonNull";
import { creditCardPlaceholder } from "utils/strings/creditCardPlaceholder";

import AddCreditCardForm, { CreditCardFormChange } from "./AddCreditCardForm";
import { CREDIT_CARD_LIST_QUERY } from "./gql";

type Data = {
  creditCards: CreditCardListResult;
};

type PapaQueryResponse = {
  papa: PapaSingleResult;
};

type Props = {
  name: string;
  accountId?: string;
  papaId?: string;
};

const pagination = {
  limit: 50,
};

const CreditCardSelect = ({ name, accountId, papaId }: Props) => {
  const [isNew, setIsNew] = useState(false);
  const [defaultValue, setDefaultValue] = useState<DefaultOptions[] | undefined>();
  const [defaultOptions, setDefaultOptions] = useState<DefaultOptions[]>();
  const client = useApolloClient();
  const { data: papaData } = useQuery<PapaQueryResponse>(GET_ACCOUNT_BY_PAPA, {
    skip: !papaId,
    variables: {
      papaId,
    },
  });
  const [getAccountCreditCards, { data: accountCreditCardsData }] =
    useLazyQuery<Data>(CREDIT_CARD_LIST_QUERY);
  const [{ value }, { touched, error }, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  const papaIsUnderBusiness = !!papaData?.papa?.data?.businessId;
  useEffect(() => {
    if (accountId && !papaIsUnderBusiness) {
      getAccountCreditCards({
        variables: {
          filter: {
            account: { idEq: accountId },
            deletedAt: {
              eq: null,
            },
          },
          pagination,
        },
      });
    } else if (papaId && papaIsUnderBusiness) {
      getAccountCreditCards({
        variables: {
          filter: {
            papa: { idEq: papaId },
            deletedAt: {
              eq: null,
            },
          },
          pagination,
        },
      });
    }
  }, [accountId, papaId, papaIsUnderBusiness, getAccountCreditCards]);

  const creditCards = nonNullWithId(accountCreditCardsData?.creditCards?.data);

  useEffect(() => {
    if (creditCards.length) {
      setDefaultOptions(
        creditCards.map((creditCard) => ({
          value: creditCard.id,
          label: creditCardPlaceholder(creditCard?.last4 ?? ""),
        }))
      );

      if (value) {
        setSelectedCard();
      }
    } else {
      setDefaultOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDefaultOptions, accountCreditCardsData]);

  const setSelectedCard = () => {
    if (!creditCards?.length) {
      return;
    }

    const selectedCard = creditCards.find(({ id }) => id === value);

    if (selectedCard) {
      setDefaultValue([
        {
          label: creditCardPlaceholder(selectedCard?.last4 ?? ""),
          value: selectedCard.id as string,
        },
      ]);
    }
  };

  const handleChange = (value: ValueType<ChangeSelectEvent>) => {
    const computedValue = value as ChangeSelectEvent;

    helpers.setValue(computedValue.value);
  };

  async function handleLoadOptions(value: string) {
    const dynamicFilters: DynamicProps = {};

    if (accountId) {
      dynamicFilters.account = {
        idEq: accountId,
      };
    }

    const { data } = await client.query({
      query: CREDIT_CARD_LIST_QUERY,
      variables: {
        filter: {
          account: { idEq: accountId },
          last4: { cont: value },
        },
        pagination,
      },
    });

    if (data?.creditCards?.data) {
      return data.creditCards.data.map((creditCard: CreditCard) => ({
        value: creditCard.id,
        label: creditCardPlaceholder(creditCard.last4),
      }));
    }

    return [];
  }

  const loadOptions = (value = "") => handleLoadOptions(value);

  const handleNewCreditCard = (creditCard: CreditCardFormChange) => {
    helpers.setValue(creditCard.id);
    setDefaultValue([
      {
        value: creditCard.id,
        label: creditCardPlaceholder(creditCard.last4),
      },
    ]);

    const newOptions = defaultOptions ? [...defaultOptions] : [];
    newOptions.push({
      value: creditCard.id,
      label: creditCardPlaceholder(creditCard.last4),
    });
    setDefaultOptions(newOptions);
    setIsNew(false);
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure that you cancel?")) {
      setIsNew(false);
    }
  };

  return (
    <>
      {isNew ? (
        <AddCreditCardForm
          accountId={accountId}
          papaId={papaId}
          onChange={handleNewCreditCard}
          onCancel={handleCancel}
        />
      ) : (
        <>
          <Form.Group>
            {defaultOptions && defaultOptions.length ? (
              <Async
                aria-label="credit card search"
                defaultOptions={defaultOptions}
                placeholder={accountId ? "Credit Card" : "Please select the member first"}
                loadOptions={loadOptions}
                onChange={handleChange}
                // @ts-ignore TODO:remove this
                styles={customStyles}
                isDisabled={isSubmitting || !accountId}
                defaultValue={defaultValue}
              />
            ) : (
              <InputContainer disabled>
                <StyledInput
                  value={accountId ? "no credit for this member" : "Please select the member first"}
                  disabled
                />
              </InputContainer>
            )}
          </Form.Group>
          <ButtonLink type="button" variant="text" onClick={() => setIsNew(true)}>
            <PlusCircle size={13} /> Add credit card
          </ButtonLink>
        </>
      )}
      {touched && !!error && <Form.Feedback isInvalid>{error}</Form.Feedback>}
    </>
  );
};

type DynamicProps = {
  account?: {
    idEq: string;
  };
};

interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

export type SelectedItem = {
  value: string;
  label: string;
};

type CreditCard = {
  id: string;
  last4: string;
};

type DefaultOptions = {
  value: string;
  label: string;
};

export default CreditCardSelect;
