import { useField } from "formik";
import React from "react";
import styled from "styled-components";

import Form from "components/Form";
import Feedback from "components/Form/FormFeedback";
import { Container } from "components/Radio";

interface Options {
  label: string;
  value: any;
}

interface Props {
  options: Options[];
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroup: React.FC<Props> = ({ options, name, onChange }) => {
  const [, { error, touched }] = useField(name);

  return (
    <>
      <Group>
        {options.map(({ label, value }, index) => (
          <Form.Radio
            key={value}
            value={value}
            name={name}
            controlId={value}
            label={label}
            onChange={onChange}
          />
        ))}
      </Group>

      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

const Group = styled.div`
  ${Container} + ${Container} {
    margin-top: 0.625rem;
  }
`;
