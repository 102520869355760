import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import { Formik } from "formik";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import Button from "components/Button";
import PapasTable from "components/DataTables/PapasTable";
import { FlexRow } from "components/Flex";
import Form from "components/Form";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { PapaListResult } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";
import CreatePapa from "pages/Papas/List/CreatePapa";
import { PapaExportModal } from "pages/Papas/List/PapaExport/PapaExportModal";
import PerformanceReportModal from "pages/Papas/List/PerformanceReport";
import { papasConcealed } from "utils/fieldsPermissions/papaConcealed";

import { SEARCH_PAPAS } from "../gql";

interface Data {
  searchResult: PapaListResult;
}

const Papas = () => {
  useTopBarOptions({ title: "Papas", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { search } = useUrlQuery();
  const location = useLocation();
  const history = useHistory();

  const { data, error, loading, refetch } = useQuery<Data>(SEARCH_PAPAS, {
    variables: { search },
    skip: !search,
  });

  const [showExportPapaModal, setShowExportPapaModal] = useState(false);
  const toggleExportPapaModal = () => setShowExportPapaModal(!showExportPapaModal);

  const [showPerformanceReportModal, setShowPerformanceReportModal] = useState(false);
  const togglePerformanceReportModal = () =>
    setShowPerformanceReportModal(!showPerformanceReportModal);

  const handleRefetch = async () => {
    await refetch();
  };

  const papas = papasConcealed(data?.searchResult?.data);

  return (
    <>
      <Helmet>
        <title>Papas - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <FlexRow>
          <Formik
            initialValues={{ search }}
            onSubmit={(values) => {
              history.push(location.pathname + "?search=" + values.search);
            }}
            validationSchema={yup.object().shape({
              search: yup.string().nullable(),
            })}
          >
            <StyledForm>
              <Form.Input
                aria-label="search"
                name="search"
                placeholder="Search by ID, Name, Phone, Member"
              />
              <Button type="submit">Search</Button>
            </StyledForm>
          </Formik>
        </FlexRow>

        <FlexRow>
          <Button onClick={togglePerformanceReportModal}>Performance Report</Button>
          <Button onClick={toggleExportPapaModal}>Export Papas</Button>
          <CreatePapa onCreateFinish={handleRefetch} />
        </FlexRow>
      </FlexRow>

      <PapasTable
        data={papas}
        loading={loading}
        error={error}
        sorting={{}}
        onSortChange={() => {}}
        onRefetch={handleRefetch}
      />

      <PapaExportModal isOpen={showExportPapaModal} toggle={toggleExportPapaModal} />

      <PerformanceReportModal
        isOpen={showPerformanceReportModal}
        toggle={togglePerformanceReportModal}
      />
    </>
  );
};

export default Papas;

const StyledForm = styled.form`
  display: flex;
`;
