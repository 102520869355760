import { css } from "styled-components";

export default css`
  @font-face {
    font-family: "Avenir";
    src: local("Avenir"), url("/fonts/AvenirLT-roman.woff2") format("woff2"),
      url("/fonts/AvenirLT-roman.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF; /* Latin glyphs */
  }

  @font-face {
    font-family: "Avenir";
    src: local("Avenir"), url("/fonts/AvenirLT-medium.woff2") format("woff2"),
      url("/fonts/AvenirLT-medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF; /* Latin glyphs */
  }

  @font-face {
    font-family: "Avenir LT Pro";
    src: local("Avenir LT Pro"), url("/fonts/AvenirLT-bold.woff2") format("woff2"),
      url("/fonts/AvenirLT-bold.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF; /* Latin glyphs */
  }
`;
