import camelCase from "lodash/camelCase";
import omit from "lodash/omit";

import { DataVisibility } from "types";

export const omitProtectedFields = <T extends object>(
  input: T,
  dataVisibility?: DataVisibility | null
) => {
  if (!dataVisibility) {
    return input;
  }

  const concealedFields = (dataVisibility.concealed ?? []).map((field) => camelCase(field));
  const maskedFields = (dataVisibility.masked ?? []).map((field) => camelCase(field));

  return omit(input, [...concealedFields, ...maskedFields]);
};
