import { Formik, useField } from "formik";
import React, { useEffect } from "react";

import Button from "components/Button";
// import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import { RadioGroup } from "components/RadioGroup/RadioGroup";
import Select, { GroupType } from "components/Select";
import { ReasonCode, WhatTriggeredOption } from "constants/visit";
import { Values } from "pages/ScheduleVisit/types";
import { reasonCodeSearched, reasonCodeSelected } from "utils/segment";

import { Schema, schema } from "./schema";

interface Props {
  isOpen: boolean;
  visitFormValues?: Values;
  onSubmit: (values: Values) => Promise<void>;
  toggle: () => void;
}

export const RescheduleVisitRequestModal: React.FC<Props> = ({
  isOpen,
  onSubmit,
  toggle,
  visitFormValues,
}) => {
  const initialValues: Schema = {
    whatTriggered: WhatTriggeredOption.Member,
    reason: {
      code: null,
    },
  };

  const whatTriggeredOptions = [
    { value: WhatTriggeredOption.Member, label: "Member" },
    { value: WhatTriggeredOption.Pal, label: "Pal" },
    { value: WhatTriggeredOption.Service, label: "Papa Service" },
  ];

  const memberReasonOptions = [
    {
      label: "Rescheduled",
      options: [
        {
          value: ReasonCode.MemberWrongDateOrTime,
          label: "Wrong date or time",
          tags: "schedule",
        },
        {
          value: ReasonCode.MemberSomethingCameUp,
          label: "Something came up",
          tags: "schedule",
        },
        {
          value: ReasonCode.MemberPreferredADifferentTime,
          label: "Preferred a different time",
          tags: "schedule",
        },
        {
          value: ReasonCode.MemberScheduleLessFrequentVisit,
          label: "Schedule less frequest visit",
          tags: "schedule",
        },
        {
          value: ReasonCode.MemberRescheduledOther,
          label: "Other",
          tags: "schedule",
        },
      ],
    },
    {
      label: "Event",
      options: [
        {
          value: ReasonCode.MemberDoctorVisitChange,
          label: "Doctor visit change",
          tags: "appointment, therapy, nursing, doc, appt, ER,emergency",
        },
      ],
    },
    {
      label: "Other",
      options: [
        {
          value: ReasonCode.MemberUndefined,
          label: "Undefined",
          tags: "",
        },
      ],
    },
  ];

  const serviceReasonOptions = [
    {
      label: "Network",
      options: [
        {
          value: ReasonCode.ServiceNoPalAvailableRescheduled,
          label: "No Pal available / rescheduled",
          tags: "",
        },
      ],
    },
  ];

  const palReasonOptions = [
    {
      label: "Pal",
      options: [
        {
          value: ReasonCode.PalRescheduleRequest,
          label: "Reschedule request",
          tags: "schedule",
        },
      ],
    },
    {
      label: "Other",
      options: [{ value: ReasonCode.PalUndefined, label: "Undefined", tags: "" }],
    },
  ];

  const ReasonCodeSelect = () => {
    const [{ value: whatTriggeredValue }] = useField("whatTriggered");
    const [, , helpers] = useField("reason.code");

    useEffect(() => {
      helpers.setValue(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whatTriggeredValue]);

    return (
      <Select
        name="reason.code"
        aria-label="reason"
        options={
          whatTriggeredValue === WhatTriggeredOption.Member
            ? (memberReasonOptions as GroupType[])
            : whatTriggeredValue === WhatTriggeredOption.Pal
            ? (palReasonOptions as GroupType[])
            : (serviceReasonOptions as GroupType[])
        }
        placeholder="Select a reason"
        isSearchable={true}
        filterOption={(option, rawInput) =>
          option.label.toLowerCase().includes(rawInput.toLowerCase()) ||
          option.data?.tags?.toLowerCase().includes(rawInput.toLowerCase())
        }
        trackSearch={(keyword) => reasonCodeSearched(keyword)}
        trackSelected={(keyword, value) => reasonCodeSelected(keyword, value)}
      />
    );
  };

  const handleSubmit = async ({ reason }: Schema) => {
    if (visitFormValues) {
      visitFormValues.rescheduleRequestReason = reason;
      await onSubmit(visitFormValues);
      toggle();
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Reschedule request" isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label>1. Who requested to reschedule?</Form.Label>
              <RadioGroup name="whatTriggered" options={whatTriggeredOptions} />
            </Form.Group>
            <Form.Group>
              <Form.Label>2. Why are they rescheduling?</Form.Label>
              <ReasonCodeSelect />
            </Form.Group>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save reschedule request
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};

export default RescheduleVisitRequestModal;
