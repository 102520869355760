import React from "react";
import { Route, Switch } from "react-router-dom";

import MemberDetails from "./Details";
import Members from "./List";

const MembersRoutes = () => {
  return (
    <Switch>
      <Route exact path="/members" component={Members} />
      <Route exact path={["/members/:id", "/members/:id/:tab"]} component={MemberDetails} />
    </Switch>
  );
};

export default MembersRoutes;
