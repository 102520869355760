/* eslint-disable quotes */
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled, { DefaultTheme, css, keyframes } from "styled-components";

import { colors } from "app/theme";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

type Variants = keyof DefaultTheme["variants"];
type Sizes = keyof DefaultTheme["sizes"];
type FontSizes = keyof DefaultTheme["fontSizes"];

export const defaultProps = {
  variant: "primary" as Variants,
  size: "middle" as Sizes,
  fontSize: "middle" as FontSizes,
};

type DefaultProps = Readonly<typeof defaultProps>;

type Props = {
  variant?: Variants;
  size?: Sizes;
  fontSize?: FontSizes;
  block?: boolean;
  loading?: boolean;
  disabled?: boolean;
  action?: boolean;
  nowrap?: boolean;
} & Partial<DefaultProps>;

const ButtonLink = styled(({ action, ...props }) => <Link {...props} />)<Props>`
  width: ${({ block }) => (block ? "100%" : "auto")};
  display: flex;
  justify-content: ${({ block }) => (block ? "center" : "space-between")};
  background: ${({ variant = defaultProps.variant, theme, action }) =>
    action ? theme.variants[variant!] : "transparent"};
  border: 0.15625rem ${({ variant = defaultProps.variant, theme }) => theme.variants[variant!]}
    solid;
  border-radius: 0.375rem;
  color: ${({ variant = defaultProps.variant, theme, action }) =>
    action ? "white" : theme.variants[variant!]};
  font-weight: bold;
  text-align: center;
  padding: ${({ size = defaultProps.size, theme }) => theme.sizes[size!]};
  font-size: ${({ fontSize = defaultProps.fontSize, theme }) => theme.fontSizes[fontSize!]};
  transition: 0.3s;
  padding-right: ${({ action, loading }) => (action || loading ? "45px" : "inherent")};
  cursor: pointer;
  text-decoration: none;

  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}

  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: ${({ variant = defaultProps.variant, theme, disabled }) =>
      !disabled && theme.variants[variant!]};
    color: ${({ variant = defaultProps.variant, theme, disabled }) => !disabled && "white"};
  }
  ${({ noContent, loading, action }) =>
    !noContent &&
    css`
      &:before {
        align-self: flex-start;
        animation: ${loading &&
        css`
          ${rotate} 2s linear infinite;
        `};
        line-height: ${(action || loading) && "1.125rem"};
        font-size: ${(action || loading) && "1.875rem"};
        font-weight: ${(action || loading) && "normal"};
        margin-right: ${(action || loading) && "0.625rem"};
        content: ${action || loading ? '"+"' : '""'};
      }

      &:after {
        content: "";
      }
    `}

  &:after {
    content: "";
  }

  &:active {
    transform: ${({ disabled, loading }) => (disabled || !loading) && "translateY(0.125rem)"};
  }

  &:focus {
    outline: none;
  }
`;

ButtonLink.defaultProps = defaultProps;

const color = {
  primary: "white",
  secondary: colors.blue800,
};

const backgroundColor = {
  primary: colors.blue800,
  secondary: "transparent",
};

const borderColor = {
  primary: colors.blue800,
  secondary: colors.blue800,
};

export type ButtonProps = {
  variant?: "primary" | "secondary";
  size?: "small" | "middle";
  block?: boolean;
  withIcon?: boolean;
};

const sizes = {
  small: "0.75rem",
  middle: "1rem",
};

type WrappedLinkProps = ButtonProps & LinkProps;

const WrappedLink = ({ withIcon, block, variant, ...props }: WrappedLinkProps) => {
  return <Link {...props} />;
};

export const ButtonStyle = css`
  background-color: ${({ variant = "primary" }: ButtonProps) => backgroundColor[variant]};
  color: ${({ variant = "primary" }: ButtonProps) => color[variant]};
  border: 2px solid ${({ variant = "primary" }: ButtonProps) => borderColor[variant]};

  border-radius: 6px;
  padding: 0.438rem 0.5rem; /* 7px 8px */
  font-weight: bold;
  font-size: ${({ size = "middle" }: ButtonProps) => sizes[size]};

  text-decoration: none;

  transition: 0.3s;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-height: 40px;

  ${({ block }: ButtonProps) =>
    block
      ? css`
          display: flex;
          width: 100%;
        `
      : css`
          display: inline-flex;
          text-align: center;
        `}

  ${({ withIcon }: ButtonProps) =>
    withIcon &&
    css`
      svg {
        margin-right: 0.375rem;
      }
    `}

  &:hover {
    opacity: 0.85;
    text-decoration: none;
  }

  &:disabled {
    background-color: #b2b9c5;
    border-color: #b2b9c5;
    font-weight: normal;
    cursor: default;
  }

  &:active:not(:disabled) {
    transform: translateY(0.125rem);
  }
`;

export const Button = styled(WrappedLink)<ButtonProps>`
  ${ButtonStyle}
`;

export default ButtonLink;
