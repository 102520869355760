import { useQuery } from "@apollo/client";
import queryString from "query-string";
import React from "react";
import { Redirect, Route, useParams } from "react-router-dom";

import Nav from "components/Nav";
import { VisitFilter } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";

import { VISITS_SEARCH_AS_PAL_EMPTY_CHECK } from "./gql";

export const PalAppVisitsTabs = ({
  filters: { availableFilters, upcomingFilters, myRequestsFilters },
}: {
  filters: {
    availableFilters: VisitFilter;
    upcomingFilters: VisitFilter;
    myRequestsFilters: VisitFilter;
  };
}) => {
  const { id } = useParams<{ id: string; debugTab: string }>();
  const { lat, lng } = useUrlQuery();

  const coordinates = { lat, lng };

  const { data: emptyCheckData } = useQuery(VISITS_SEARCH_AS_PAL_EMPTY_CHECK, {
    variables: {
      palId: id,
      coordinates,
      availableFilters,
      upcomingFilters,
      myRequestsFilters,
    },
  });

  const availableNotEmpty = emptyCheckData?.available?.data?.length ?? null;
  const upcomingNotEmpty = emptyCheckData?.upcoming?.data?.length ?? null;
  const myRequestsNotEmpty = emptyCheckData?.myRequests?.data?.length ?? null;

  const urlQueryString = queryString.stringify({ lat, lng });

  const linkToAvailableVisits = `/pals/${id}/debug/available-visits?${urlQueryString}`;
  const linkToUpcomingVisits = `/pals/${id}/debug/upcoming-visits?${urlQueryString}`;
  const linkToMyRequests = `/pals/${id}/debug/my-requests?${urlQueryString}`;

  return (
    <>
      <Nav>
        <Nav.Link to={linkToAvailableVisits}>
          Available visits {availableNotEmpty != null && `(${availableNotEmpty ? "Yes" : "None"})`}
        </Nav.Link>
        <Nav.Link to={linkToUpcomingVisits}>
          Upcoming visits {upcomingNotEmpty != null && `(${upcomingNotEmpty ? "Yes" : "None"})`}
        </Nav.Link>
        <Nav.Link to={linkToMyRequests}>
          My requests {myRequestsNotEmpty != null && `(${myRequestsNotEmpty ? "Yes" : "None"})`}
        </Nav.Link>
      </Nav>
      <Route exact path="/pals/:id/debug">
        <Redirect to={`/pals/${id}/debug/available-visits`} />
      </Route>
    </>
  );
};
