import { gql } from "@apollo/client";

export const GET_MEMBER_KEYWORDS = gql`
  query visitRatingKeywords {
    visitRatingKeywords {
      data {
        id
        keyword
        type
      }
    }
  }
`;
