import React, { ReactElement, useState } from "react";

import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";

import CreatePalForm from "./CreatePalForm";

interface Props {
  onCreateFinish: () => void;
}

const CreatePal = ({ onCreateFinish }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button action onClick={() => setIsOpen(true)}>
        New Pal
      </Button>

      <ModalLayout toggle={() => setIsOpen(!isOpen)} title="Create Pal" width={750} isOpen={isOpen}>
        <CreatePalForm closeModal={() => setIsOpen(false)} onCreateFinish={onCreateFinish} />
      </ModalLayout>
    </>
  );
};

export default CreatePal;
