import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { useModalToggle } from "hooks/useModalToggle";

import { VisitFeedbackModal } from "./VisitFeedbackModal/VisitFeedbackModal";
import { VisitFeedbackSuccess } from "./VisitFeedbackSuccess";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  visitId: string;
}

export const FeedbackModalsContainer: React.FC<Props> = ({
  toggle: toggleFeedbackModal,
  isOpen,
  visitId,
}) => {
  const { isOpen: showSuccessModal, toggle: toggleSuccessModal } = useModalToggle();

  const onFeedbackSuccess = () => {
    toggleFeedbackModal();
    toggleSuccessModal();
  };

  return (
    <>
      <ModalLayout
        toggle={toggleFeedbackModal}
        isOpen={isOpen}
        title="Review Member Visit"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <VisitFeedbackModal
          close={toggleFeedbackModal}
          onSuccess={onFeedbackSuccess}
          id={visitId}
        />
      </ModalLayout>

      <ModalLayout toggle={toggleSuccessModal} isOpen={showSuccessModal} title="Review Submitted">
        <VisitFeedbackSuccess close={toggleSuccessModal} />
      </ModalLayout>
    </>
  );
};
