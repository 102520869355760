import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import comingSoonImage from "assets/images/care-concierge-coming-soon.png";
import { ServiceRequestActions } from "components/ActionsMenu/ServiceRequestActionMenu/models";
import Button from "components/Button";
import Heading from "components/Heading";
import HeadingEditable from "components/HeadingEditable";
import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import Text from "components/Text";
import { originOptions, priorityOptions } from "constants/service-requests";
import {
  ServiceRequestClosedReason,
  ServiceRequestSingleResult,
  ServiceRequestStatus,
} from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { CreateServiceRequestFollowUpModal } from "modals/CareConcierge/CreateFollowUp";
import { EditStatusModal } from "modals/CareConcierge/EditStatus";
import { mapValueToLabel } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { fancyEnum } from "utils/strings/fancyEnum";

import EditDetails from "../EditDetails/EditDetails";
import ServiceRequestNotes from "../Notes/Notes";
import ServiceRequestRating from "../Rating";
import ServiceRequestFollowUps from "./FollowUpDetails";
import { GET_SERVICE_DETAIL } from "./gql";

interface Data {
  serviceRequest: ServiceRequestSingleResult;
}

type ColumnProps = {
  size?: number;
};

const ServiceRequestDetail = () => {
  const { isOpen: showDetailsEditModal, toggle: toggleDetailsEditModal } = useModalToggle();
  const { serviceId } = useParams<{ serviceId: string }>();
  const { data, refetch } = useQuery<Data>(GET_SERVICE_DETAIL, {
    variables: { id: serviceId },
  });

  const {
    assignedTo,
    priority,
    status,
    type,
    need,
    needText,
    origin,
    description,
    rating,
    serviceRequestComments,
    closedReason,
    assignedToId,
  } = data?.serviceRequest?.data ?? {};
  const servicerequest = data?.serviceRequest?.data;
  const createFollowUp = () => {
    setAction(ServiceRequestActions.CreateServiceRequestFollowUp);
  };
  const changeStatus = () => {
    setAction(ServiceRequestActions.ChangeStatus);
  };
  const [action, setAction] = useState(ServiceRequestActions.NoAction);
  const closeModal = () => {
    setAction(ServiceRequestActions.NoAction);
  };
  const handleRefetch = async () => {
    await refetch();
  };

  const isServiceResolved = status === ServiceRequestStatus.Resolved;
  const isServiceClosedAndReasonResolved =
    status === ServiceRequestStatus.Closed && closedReason === ServiceRequestClosedReason.Resolved;
  const showServiceRequestRating = isServiceResolved || isServiceClosedAndReasonResolved;

  return (
    <div>
      <Helmet>
        <title>Service Request Detail - Papa Admin</title>
      </Helmet>
      <Grid>
        <Column size={6}>
          <Panel>
            <HeadingEditable title={`#${serviceId}`} toggleModal={toggleDetailsEditModal} />
            <ModalLayout
              toggle={toggleDetailsEditModal}
              title="Edit Service Request"
              isOpen={showDetailsEditModal}
            >
              <EditDetails
                serviceId={serviceId}
                servicerequest={servicerequest!}
                onEditFinish={toggleDetailsEditModal}
              />
            </ModalLayout>
            <Panel.Item>
              <Grid>
                <Column size={1}>
                  <Text bold>Type</Text>
                  <Text>{type?.data?.name}</Text>
                </Column>
                <Column size={1}>
                  <Text bold>Need</Text>
                  <Text>{needText ? "Other - " + needText : need?.data?.name}</Text>
                </Column>
                <Column size={1}>
                  <Text bold>Priority</Text>
                  <Text>{mapValueToLabel(priorityOptions, priority)}</Text>
                </Column>
                <Column size={1}>
                  <Text bold>Origin</Text>
                  <Text>{mapValueToLabel(originOptions, origin)}</Text>
                </Column>
                <Column size={1}>
                  <Text bold>Assigned To</Text>
                  <Text>
                    {assignedToId ? assignedTo?.data?.fullName ?? "Deleted account" : "Unassigned"}
                  </Text>
                </Column>
                <Column size={1}>
                  <Text bold>Status</Text>
                  <Text>
                    {!!status && fancyEnum(status)}
                    {status === ServiceRequestStatus.Closed &&
                      !!closedReason &&
                      ` (${fancyEnum(closedReason)})`}
                  </Text>
                </Column>
              </Grid>
            </Panel.Item>
            <Panel.Item>
              <Text bold>Description</Text>
              <Text>{description}</Text>
            </Panel.Item>
            <Panel.Item>
              <Button action onClick={changeStatus}>
                Change Status
              </Button>
            </Panel.Item>
            <EditStatusModal
              serviceRequestId={serviceId}
              status={status}
              closedReason={closedReason}
              isOpen={action === ServiceRequestActions.ChangeStatus}
              toggle={closeModal}
              onRefetch={handleRefetch}
            />
          </Panel>
          {showServiceRequestRating && (
            <Panel>
              <ServiceRequestRating rating={rating} serviceRequestId={serviceId} />
            </Panel>
          )}
          <Panel>
            <Wrapper>
              <Heading
                as="h2"
                color="primary"
                style={{ display: "table-cell", width: "50%", verticalAlign: "middle" }}
              >
                Open Follow Ups
              </Heading>
            </Wrapper>
            <CreateServiceRequestFollowUpModal
              serviceRequestId={serviceId}
              isOpen={action === ServiceRequestActions.CreateServiceRequestFollowUp}
              toggle={closeModal}
              onCreateFinish={handleRefetch}
            />
            <Panel.Item>
              <Button action onClick={createFollowUp}>
                Add Follow Up
              </Button>
            </Panel.Item>
            <ServiceRequestFollowUps completed={false} serviceId={serviceId} />
          </Panel>
          <Panel>
            <Heading as="h2" color="primary">
              Completed Follow Ups
            </Heading>
            <ServiceRequestFollowUps completed={true} serviceId={serviceId} />
          </Panel>
          <Panel>
            <ServiceRequestNotes
              serviceId={serviceId}
              serviceRequestComments={nonNull(serviceRequestComments?.data)}
            />
          </Panel>
        </Column>
        <Column size={1}>
          <Panel>
            <Panel.Item>
              <img style={{ width: "80px" }} src={comingSoonImage} alt="Coming Soon" />
              <Text size="small">We are cooking something very special!</Text>
              <Text size="small" bold>
                New Feature Coming Soon
              </Text>
            </Panel.Item>
          </Panel>
        </Column>
      </Grid>
    </div>
  );
};

export const Wrapper = styled("div")`
  display: table;
  width: 100%;
  padding-bottom: 20px;
`;

const Grid = styled("div")`
  display: flex;
  margin: 0 -0.938rem;
`;

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
  margin: 0 0.938rem;
`;

export default ServiceRequestDetail;
