import React from "react";

import TableRow from "./TableRow";

type Props = React.HTMLAttributes<HTMLTableSectionElement> & {
  children: React.ReactNode;
};

const TableHead = ({ children, ...rest }: Props) => {
  return (
    <thead {...rest}>
      <TableRow>{children}</TableRow>
    </thead>
  );
};

export default TableHead;
