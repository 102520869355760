import { useQuery } from "@apollo/client";
import { Map } from "@styled-icons/boxicons-regular/Map";
import React from "react";
import Helmet from "react-helmet";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { GET_LOCATIONS } from "components/LocationForm/gql";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import { Location, LocationListResult } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import CreateLocation from "./CreateLocation";
import { DeleteLocationModal } from "./DeleteLocationModal/DeleteLocationModal";
import { useLocationsTableColumns } from "./useLocationsTableColumns";

interface Data {
  locations: LocationListResult;
}

const Locations = () => {
  useTopBarOptions({ title: "Locations", icon: Map });
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { columns, action, location, closeModal } = useLocationsTableColumns();
  const { data, loading, error } = useQuery<Data>(GET_LOCATIONS, {
    variables: {
      filter: { deletedAt: { eq: null } },
      pagination: { beforeCursor, afterCursor, limit },
      sorting: { insertedAt: "DESC" },
    },
  });
  const locations = data?.locations.data || [];
  const pagination = data?.locations.pagination;

  return (
    <div>
      <Helmet>
        <title>Locations - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="flex-end">
        <CreateLocation />
      </FlexRow>

      <SmartTable<Location>
        keyField="id"
        data={locations}
        loading={loading}
        columns={columns}
        pagination={pagination}
        error={error}
        cursorPagination
      />

      <DeleteLocationModal
        location={location}
        toggle={closeModal}
        isOpen={action === DataTableMenuActions.Delete}
      />
    </div>
  );
};

export default Locations;
