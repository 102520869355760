import { ChevronDown, ChevronUp } from "@styled-icons/fa-solid";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { StyledIcon } from "styled-icons/types";

import { StyledNavItem } from "./NavItem";
import SidebarContext from "./SidebarContext";

type Props = {
  children: React.ReactNode;
  icon: StyledIcon;
  text: string;
  setSidebarCollapsed: (value: boolean) => void;
};

type StyledNavProps = {
  collapsed: boolean;
};

type ButtonProps = {
  collapsed: boolean;
  sidebarCollapsed: boolean;
};

const CollapsableNav = ({ children, icon: Icon, text, setSidebarCollapsed }: Props) => {
  const { sidebarCollapsed } = useContext(SidebarContext);
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = useLocation();
  const [navLinks] = useState<string[]>(() => {
    const links: string[] = [];

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) {
        return;
      }

      const { to } = element.props;

      if (to) {
        const [url] = to.split("/").filter(Boolean);
        const link = url ? url.split("?")[0] : "/";

        links.push(link);
      }
    });

    return links;
  });

  useEffect(() => {
    const path = pathname.split("/")[1] || "/";

    if (navLinks.includes(path)) {
      setCollapsed(false);
    } else {
      if (sidebarCollapsed) {
        setCollapsed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, sidebarCollapsed]);

  const toggleNav = () => {
    if (!sidebarCollapsed) {
      setCollapsed(!collapsed);
    } else {
      setCollapsed(false);
      setSidebarCollapsed(false);
    }
  };

  return (
    <StyledNav collapsed={collapsed}>
      <StyledButton collapsed={collapsed} sidebarCollapsed={sidebarCollapsed} onClick={toggleNav}>
        <Icon size={24} />
        {!sidebarCollapsed && text}
        {!sidebarCollapsed &&
          (collapsed ? <StyledChevronDown size={14} /> : <StyledChevronUp size={14} />)}
      </StyledButton>

      {!sidebarCollapsed && children}
    </StyledNav>
  );
};

const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
`;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
`;

const StyledNav = styled("div")<StyledNavProps>`
  height: ${({ collapsed }) => (collapsed ? "3.5rem" : "auto")};
  background: ${({ theme, collapsed }) => (collapsed ? "transparent" : theme.variants.primary)};
  overflow: hidden;
  transition: height 0.3ms;

  ${({ theme }) => css`
    ${StyledNavItem} {
      &:hover {
        color: ${theme.text.tint};
      }
    }
  `}

  a {
    padding-left: calc(1.875rem + 1rem + 24px);
    color: ${({ theme }) => theme.text.panelHeader};
  }

  a:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  a.active {
    background: ${({ theme }) => theme.background.transparentTint};
    text-decoration: underline;
  }
`;

const StyledButton = styled("button")<ButtonProps>`
  position: relative;
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ collapsed, theme }) => (collapsed ? theme.text.tint : theme.text.panelHeader)};
  box-shadow: ${({ collapsed, theme }) =>
    collapsed ? "none" : `inset 4px 0 0${theme.background.primaryTint}`};
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "0 1.750rem" : "0 1.875rem")};
  cursor: pointer;
  white-space: nowrap;

  svg {
    flex-shrink: 0;
    margin-right: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : "1rem")};
  }
`;

export default CollapsableNav;
