import { gql } from "@apollo/client";

export const GET_ASSESSMENTS = gql`
  query getAssessments($filter: [SurveyFilter]) {
    surveys(filter: $filter) {
      data {
        name
        id
      }
    }
  }
`;
