import React from "react";
import { Route, Switch } from "react-router-dom";

import BusinessesPaymentInvoicesDetails from "./Businesses/Details";
import BusinessesPaymentInvoices from "./Businesses/List";
import MembersPaymentsDetails from "./Members/Details";
import MembersPayments from "./Members/List";

const PaymentsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/payments/members-payments" component={MembersPayments} />
      <Route
        exact
        path={"/payments/client-programs-payment-invoices"}
        component={BusinessesPaymentInvoices}
      />
      <Route
        exact
        path={"/payments/businesses-payment-invoices"}
        component={BusinessesPaymentInvoices}
      />
      <Route
        exact
        path={["/payments/members-payments/:id", "/payments/members-payments/:id/:tab"]}
        component={MembersPaymentsDetails}
      />
      <Route
        exact
        path={[
          "/payments/client-programs-payment-invoices/:paymentInvoiceId",
          "/payments/client-programs-payment-invoices/:paymentInvoiceId/:tab",
        ]}
        component={BusinessesPaymentInvoicesDetails}
      />
      <Route
        exact
        path={[
          "/payments/businesses-payment-invoices/:paymentInvoiceId",
          "/payments/businesses-payment-invoices/:paymentInvoiceId/:tab",
        ]}
        component={BusinessesPaymentInvoicesDetails}
      />
    </Switch>
  );
};

export default PaymentsRoutes;
