import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { BUSINESS_ABOUT } from "pages/Businesses/gql";

import { UPDATE_BUSINESS_ABOUT } from "./gql";
import { FormValues, schema } from "./schema";

interface Props {
  id: string;
  about: string;
  onEditFinish: () => void;
}

export const BusinessAboutEditForm: React.FC<Props> = ({ id, about, onEditFinish }) => {
  const [updateAbout] = useMutation(UPDATE_BUSINESS_ABOUT, {
    refetchQueries: [{ query: BUSINESS_ABOUT, variables: { id: id } }],
  });

  const handleSubmit = async ({ about }: FormValues) => {
    try {
      const { data } = await updateAbout({
        variables: { id, input: { about } },
      });

      if (data?.updateAccount?.data?.id) {
        toast.success("Business About was edited with success!");
        onEditFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues: FormValues = {
    about,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label>About</Form.Label>
            <Form.TextArea name="about" aria-label="about" />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onEditFinish}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
