import { PapaTeam } from "generated/types";

export const papaTeamOptions = [
  { value: PapaTeam.Outbound, label: "Outbound" },
  { value: PapaTeam.Inbound, label: "Inbound" },
  { value: PapaTeam.CustomerCare, label: "Customer Care" },
  { value: PapaTeam.CarePal, label: "Care Pal" },
  { value: PapaTeam.CareConcierge, label: "Care Concierge" },
  { value: PapaTeam.VisitSuccess, label: "Visit Success" },
  { value: PapaTeam.Engineering, label: "Engineering" },
  { value: PapaTeam.ClientOperations, label: "Client Operations" },
  { value: PapaTeam.Billing, label: "Billing" },
  { value: PapaTeam.NetworkDevelopment, label: "Network Development" },
  { value: PapaTeam.HumanResources, label: "Human Resources" },
  { value: PapaTeam.Growth, label: "Growth" },
  { value: PapaTeam.PalEnablement, label: "Pal Enablement" },
  { value: PapaTeam.Onboarding, label: "Onboarding" },
  { value: PapaTeam.PapaHealth, label: "Papa Health" },
  { value: PapaTeam.MemberExperience, label: "Member Experience" },
  { value: PapaTeam.MarketplaceOperations, label: "Marketplace Operations" },
  { value: PapaTeam.DataAnalytics, label: "Data Analytics" },
  { value: PapaTeam.Product, label: "Product" },
  { value: PapaTeam.ClientSuccess, label: "Client Success" },
  { value: PapaTeam.Sales, label: "Sales" },
  { value: PapaTeam.ItSecurity, label: "IT Security" },
];
