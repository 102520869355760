import { EllipsisH } from "@styled-icons/fa-solid";
import React, { FC } from "react";
import styled from "styled-components";

import Dropdown from "../Dropdown";

interface Props {
  isListMenu: boolean;
}

const AlignRightToggle = styled(Dropdown.Toggle)`
  margin-left: auto;
  margin-right: 20px;
`;

export const DropdownActionMenu: FC<Props> = ({ isListMenu, children }) => (
  <Dropdown placement="bottom-end">
    {isListMenu ? (
      <Dropdown.Actions data-testid="toggle">
        <EllipsisH size={18} />
      </Dropdown.Actions>
    ) : (
      <AlignRightToggle data-testid="toggle">
        <EllipsisH size={18} />
      </AlignRightToggle>
    )}

    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
);
