import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";

import { AssessmentQuestionFragment } from "generated/types";
import { AssessmentType, HardCodedQuestionsRequired, QuestionsFlow } from "types/assessments";

import Question from "./Question";
import { getSections } from "./getSections";
import { FormValues } from "./models";

type Props = {
  type: AssessmentType;
  questions: AssessmentQuestionFragment[];
  questionsFlow?: QuestionsFlow;
  hardCodedQuestionsRequired?: HardCodedQuestionsRequired;
  currentSection: string;
  submitTouched: boolean;
  groups: {
    id: string;
    questions: string[];
  }[];
};

const QuestionsSections = ({
  type,
  questions,
  questionsFlow,
  hardCodedQuestionsRequired,
  currentSection,
  submitTouched,
  groups,
}: Props) => {
  const { values } = useFormikContext<FormValues>();
  const sections = getSections(type, questions, values, groups, questionsFlow);

  return (
    <>
      <Sections>
        {sections[currentSection].map((question) => (
          <Question
            key={question.id}
            type={type}
            question={question}
            hardCodedQuestionsRequired={hardCodedQuestionsRequired}
            submitTouched={submitTouched}
          />
        ))}
      </Sections>
    </>
  );
};

const Sections = styled.div``;

export default QuestionsSections;
