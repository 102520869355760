import capitalize from "lodash/capitalize";
import moment from "moment-timezone";

import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Visit, VisitRecurrence } from "generated/types";
import { isByDayRecurrence } from "pages/ScheduleVisit/constants";
import { nonNull } from "utils/nonNull";

type Props = {
  recurrence?: VisitRecurrence | null;
  visit: Visit;
};

const VisitRecurrenceComponent = ({ recurrence, visit }: Props) => {
  if (!recurrence) return EMPTY_PLACEHOLDER;

  const freq = recurrence?.config?.freq;
  const validUntil = recurrence.validUntil;
  const byDays = nonNull(recurrence?.config?.byDay);
  const timezone = visit.location?.data?.timezone;

  const scheduledFor = moment(visit.scheduledFor);
  const localDate = timezone ? scheduledFor.tz(timezone) : scheduledFor;
  const time = localDate.format("LT z");

  let days = "";

  if (byDays.length) {
    days = byDays.map((day) => capitalize(day)).join(" and ");
  } else if (isByDayRecurrence(freq)) {
    days = localDate.format("dddd");
  }

  days = days ? `${days} at` : "";

  let adjective: string;
  const until = validUntil ? `until ${moment(validUntil).format("LL")}` : "";

  if (freq === "DAILY") {
    adjective = "Daily";
  } else if (freq === "MONTHLY") {
    adjective = `${scheduledFor.format("Do")} of Every Month`;
  } else if (freq === "WEEKLY") {
    adjective = "Every";
  } else {
    adjective = "Bi-weekly every";
  }

  return `${adjective} ${days} ${time} ${until}`;
};

export default VisitRecurrenceComponent;
