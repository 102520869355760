import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Form from "components/Form";
import InputDate from "components/InputDate";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import CareConciergeAssignSelect from "components/ServiceRequest/AssignSelect";
import {
  ServiceRequestFollowup,
  ServiceRequestFollowupAction,
  ServiceRequestFollowupStatus,
} from "generated/types";

import FollowupActionSelectGroup from "./FollowupActionSelectGroup";
import FollowupReasonSelectGroup from "./FollowupReasonSelectGroup";
import { CREATE_SERVICEREQUEST_FOLLOWUP, UPDATE_SERVICEREQUEST_FOLLOWUP } from "./gql";
import { Schema, formSchema } from "./schema";

interface Props {
  serviceRequestFollowup?: ServiceRequestFollowup;
  serviceRequestId?: string;
  isOpen: boolean;
  toggle: () => void;
  onCreateFinish: () => void;
}

export const CreateServiceRequestFollowUpModal = ({
  serviceRequestId,
  serviceRequestFollowup,
  isOpen,
  toggle,
  onCreateFinish,
}: Props) => {
  const request = serviceRequestFollowup
    ? UPDATE_SERVICEREQUEST_FOLLOWUP
    : CREATE_SERVICEREQUEST_FOLLOWUP;

  const initialValues: Schema = {
    dueAt: serviceRequestFollowup?.dueAt || "",
    note: serviceRequestFollowup?.note || "",
    action: serviceRequestFollowup?.action as ServiceRequestFollowupAction,
    assignedToId: serviceRequestFollowup?.assignedTo?.data?.id || "",
    serviceRequestId: serviceRequestId || "",
    actionText: serviceRequestFollowup?.actionText || "",
    reason: serviceRequestFollowup?.reason || null,
  };
  const [updateFollowup] = useMutation(request, { refetchQueries: ["getServiceFollowups"] });

  const handleSubmit = async ({ dueAt, ...formData }: Schema) => {
    const dueDate = new Date(dueAt);
    dueDate.setHours(23, 59, 59);
    try {
      const { data } = await updateFollowup({
        variables: {
          ...(serviceRequestFollowup ? { id: serviceRequestFollowup.id } : {}),
          input: {
            status: ServiceRequestFollowupStatus.Opened,
            dueAt: dueDate,
            ...formData,
          },
        },
      });
      if (data?.createServiceRequestFollowup?.data?.id) {
        toast.success("Add service request follow up successful!");
        onCreateFinish();
        toggle();
      } else if (data?.updateServiceRequestFollowup?.data?.id) {
        toast.success("Edit service request follow up successful!");
        onCreateFinish();
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout
      toggle={toggle}
      title={serviceRequestFollowup ? "View/Edit Follow Up" : "Add Follow Up"}
      isOpen={isOpen}
    >
      <Formik<Schema>
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <ModalStyledForm>
            <ModalContainer>
              <FollowupActionSelectGroup disabled={!!serviceRequestFollowup?.completed} />

              {values.action === ServiceRequestFollowupAction.Other && (
                <Form.Group>
                  <Form.Label required>Other</Form.Label>
                  <Form.Input
                    name="actionText"
                    aria-label="actionText"
                    disabled={!!serviceRequestFollowup?.completed}
                  />
                </Form.Group>
              )}
              <FollowupReasonSelectGroup />
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.TextArea
                  name="note"
                  placeholder="Please provide information about what Papa needs"
                  fullWidth
                  disabled={!!serviceRequestFollowup?.completed}
                />
              </Form.Group>
              <Form.Row>
                <Form.Col>
                  <Form.Group>
                    <Form.Label required>Assignee</Form.Label>
                    <CareConciergeAssignSelect
                      withUnassigned={false}
                      name="assignedToId"
                      placeholder="Select User"
                      initialValue={initialValues.assignedToId}
                      isDisabled={!!serviceRequestFollowup?.completed}
                    />
                  </Form.Group>
                </Form.Col>
                <Form.Col>
                  <Form.Group>
                    <Form.Label required>Due Date</Form.Label>
                    <InputDate
                      id="dueAt"
                      name="dueAt"
                      placeholder="Select due date"
                      disabled={!!serviceRequestFollowup?.completed}
                    />
                  </Form.Group>
                </Form.Col>
              </Form.Row>
            </ModalContainer>
            <ModalFooter>
              {serviceRequestFollowup?.completed ? (
                <Button type="button" variant="secondary" onClick={toggle}>
                  Close
                </Button>
              ) : (
                <>
                  <Button type="button" variant="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  <Form.SubmitButton>Save</Form.SubmitButton>
                </>
              )}
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 178px;
  border: 1px solid #dfe3e9;
  outline: none;
  border-radius: 4px;
  padding: 10px 14px;
`;
