import React, { useState } from "react";
import styled, { css } from "styled-components";

import { ArrowToggle } from "components/ArrowToggle";
import { Overflow } from "components/Overflow";

const CommentContainer = styled.div<{ noWrap: boolean; maxWidth?: number }>`
  ${({ maxWidth }) => css`
    max-width: ${maxWidth ? `${maxWidth}px` : "400px"};
  `}
  position: relative;
  text-overflow: ellipsis;
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
  overflow: hidden;
  padding-right: 30px;
`;

const Comment = ({ comment, maxWidth }: { comment?: string | null; maxWidth?: number }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <Overflow
      container={CommentContainer}
      noWrap={isCollapsed}
      maxWidth={maxWidth}
      render={({ isOverflowed }) => (
        <>
          {comment}
          {isOverflowed && (
            <ArrowToggle onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed} />
          )}
        </>
      )}
    />
  );
};

export default Comment;
