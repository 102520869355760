import { Clock } from "@styled-icons/fa-regular/Clock";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { getHoursWithInterval } from "utils/date";
import { Option } from "utils/select";

import Select from "../Select";

interface Props {
  placeholder?: string;
  name: string;
  interval: number;
}

export const HourPicker: React.FC<Props> = ({ name, interval, placeholder = "Pick a time" }) => {
  const timeIntervals = useMemo(() => getHoursWithInterval(interval), [interval]);
  const [options, setOptions] = useState<Option<string>[]>([]);

  useEffect(() => {
    const selectOptions = timeIntervals.map((time) => ({
      value: time,
      label: time,
    }));

    setOptions(selectOptions);
  }, [timeIntervals]);

  if (!options.length) return null;

  return (
    <Container>
      <ClockIcon size={20} />
      <Select
        styles={selectStyled}
        name={name}
        options={options}
        placeholder={placeholder}
        isSearchable={false}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 120px;
`;

const ClockIcon = styled(Clock)`
  position: absolute;
  top: 9px;
  left: 9px;
  color: #ced0da;
  z-index: 1;
`;

const selectStyled = {
  input() {
    return {
      padding: "0",
      margin: "0",
    };
  },
  control() {
    return {
      display: "flex",
      "flex-wrap": "wrap",
      "align-items": "center",
      border: "1px solid #DFE3E9",
      "border-radius": "4px",
      cursor: "default",
      "justify-content": "space-between",
      "min-height": "36px",
      transition: "all 100ms",
      padding: "6px 27px 6px 39px",
      width: "120px",
    };
  },
  indicatorSeparator() {
    return {
      display: "none",
    };
  },
  indicatorsContainer() {
    return {
      display: "none",
    };
  },
  valueContainer() {
    return {
      padding: "0",
    };
  },
};
