import * as yup from "yup";

export interface Schema {
  insuranceStatusApprove: boolean | null;
  rejectionReason: string;
}
export const schema = yup.object().shape({
  insuranceStatusApprove: yup
    .boolean()
    .nullable()
    .required()
    .oneOf([false, true], "Status is required"),
  rejectionReason: yup.string().when("insuranceStatusApprove", {
    is: false,
    then: yup.string().required("Required"),
  }),
});
