import { AssessmentsAnswersQuery } from "generated/types";
import { AssessmentsSurveyNames } from "modals/AssessmentsQuestions/models";
import { AssessmentType } from "types/assessments";
import { nonNull } from "utils/nonNull";

type Props = {
  assessmentAnswerData?: AssessmentsAnswersQuery;
};

export const useAvailableAssessments = ({ assessmentAnswerData }: Props) => {
  if (!assessmentAnswerData) {
    return {
      availableAssessments: [],
    };
  }

  const visibleAssessmentTypes = getAvailableAssessments(assessmentAnswerData);

  return {
    availableAssessments: visibleAssessmentTypes,
  };
};

export const getAvailableAssessments = (data?: AssessmentsAnswersQuery) => {
  const hiddenAssessmentNames = nonNull(
    data?.papa?.data?.currentBusinessPolicy?.data?.hiddenAssessments?.data
  ).map(({ survey }) => survey?.data?.name);

  return Object.values(AssessmentType).filter(
    (assessmentType) =>
      !hiddenAssessmentNames.some(
        (hiddenAssessmentName) => hiddenAssessmentName === AssessmentsSurveyNames[assessmentType]
      )
  );
};
