import React, { useState } from "react";

import BusinessActionsMenu from "components/ActionsMenu/BusinessActionMenu";
import { useBusinessActions } from "components/ActionsMenu/BusinessActionMenu/useBusinessActions";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { Business, BusinessSorting, SortDirection } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { formatDateTime } from "utils/helpers/formatters";

interface Params {
  sorting: BusinessSorting;
  onSortChange: (sort: BusinessSorting) => void;
}

export const useBusinessesTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { business, action, setAction } = useBusinessActions();
  const [sorting, setSorting] = useState<BusinessSorting>(initialSorting);
  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");

  const onSetSorting = (sortKey: keyof BusinessSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const clientColumnHeaderTitle = clientCopyUpdateEnabled ? "Client" : "Organization";

  const columns: Column<Business>[] = [
    {
      header: { title: "Name" },
      dataField: "name",
    },
    {
      header: { title: "Contact" },
      dataField: "email",
      formatter({ account, inboundMemberSupportNumber }) {
        return (
          <>
            <Text>{account?.data?.email}</Text>
            <Text>{inboundMemberSupportNumber}</Text>
          </>
        );
      },
    },
    {
      header: { title: "Membership" },
      dataField: "account.data.plan.data.name",
      cellProps: { noWrap: true },
    },
    {
      header: { title: clientColumnHeaderTitle },
      dataField: "account.data.organization.data.name",
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter({ account }) {
        const insertedAt = account?.data?.insertedAt;
        return formatDateTime(insertedAt?.toString());
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(business) {
        return <BusinessActionsMenu business={business} setAction={setAction} isListMenu />;
      },
    },
  ];

  const createBusiness = () => {
    setAction(DataTableMenuActions.Edit, null);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction, null);
  };

  return {
    columns,
    action,
    business,
    createBusiness,
    closeModal,
  };
};
