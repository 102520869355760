import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { CSSProperties, ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Select from "components/Select";
import { customStyles } from "components/Select";

import { VACCINE_REJECT } from "./gql";
import { Schema, schema } from "./schema";

const reasons: string[] = [
  "The pal name does not match the name on the card",
  "The card contains an incomplete vaccination record",
  "The image quality is low",
  "The image is not a vaccination card",
  "The vaccination dates do not match the card",
];

const options: any[] = reasons.map((r: string) => ({ label: r, value: r }));

interface Props {
  covidId: string;
  isOpen: boolean;
  toggle: () => void;
  onRefetch: () => void;
}
export const PalVaccineRejectModal = ({
  covidId,
  isOpen,
  toggle,
  onRefetch,
}: Props): ReactElement => {
  const [rejectMutation] = useMutation(VACCINE_REJECT);
  const initialValues: Schema = {
    rejectionReason: "",
  };

  const Reject = async ({ rejectionReason }: Schema) => {
    try {
      const { data } = await rejectMutation({
        variables: {
          input: { covidVaccinationRecordId: covidId, rejectionReason },
        },
      });
      if (data?.rejectCovidVaccinationRecord?.data) {
        toast.success("Vaccination rejection successful!");
        onRefetch();
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error: any) {
      toast.error((error as Error).message);
    }
  };

  customStyles.container = (provided: CSSProperties, state: any) => ({
    ...provided,
    background: "white",
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocus ? "4px 4px 0 0" : 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
    maxWidth: "26.75rem",
  });

  return (
    <ModalLayout toggle={toggle} title="Reject Proof of Vaccination" isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={Reject}>
        {({ isSubmitting }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label>Reason for rejection</Form.Label>
                <Select
                  name="rejectionReason"
                  options={options}
                  aria-label="rejectionReason"
                  placeholder="Select a reason"
                  autoWidth={false}
                  isSearchable={false}
                  styles={customStyles}
                />
              </Form.Group>
            </ModalContainer>
            <ModalFooter>
              <Form.SubmitButton isSubmitting={isSubmitting}>Update</Form.SubmitButton>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};
