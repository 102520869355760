import { Alert } from "@styled-icons/remix-fill/Alert";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Button from "components/Button";
import { Flex } from "components/Flex";
import Text from "components/Text";

const PENDING_VERSION_KEY = "pendingAdminVersion";
const VERSION_KEY = "adminVersion";

const VERSION_CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes
const VERSION_HEADER = "X-Admin-Version";

/**
 * AdminVersionToast
 *
 * This component is responsible for notifying the user to reload after a deploy. The flow is:
 * - On load if a version does not exist in local storage, the current version is stored
 * - The server is polled every 5 minutes to get the current version
 * - If the version matches the local storage version, nothing happens
 * - If the version does not match the local storage version:
 *   - Store the new version as pending and wait for the user to click reload
 *   - When the user clicks reload, set the pending version in local storage, clear the pending version, and reload
 */

export const AdminVersionToast: React.FC = () => {
  const [showToast, setVisibility] = useState(false);

  const getCurrentVersion = () => {
    try {
      const req = new XMLHttpRequest();
      req.open("OPTIONS", "/", false);
      req.send(null);

      return req.getResponseHeader(VERSION_HEADER);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const currentVersion = getCurrentVersion();

    // User has no local storage. Assume they are running the current version
    if (!getStoredVersion() && typeof currentVersion === "string") {
      localStorage.setItem(VERSION_KEY, currentVersion);
    }
  }, []);

  const getStoredVersion = () => {
    return localStorage.getItem(VERSION_KEY);
  };

  const getPendingVersion = () => {
    return localStorage.getItem(PENDING_VERSION_KEY);
  };

  useEffect(() => {
    function tick() {
      if (getPendingVersion()) {
        return;
      }

      const storedVersion = getStoredVersion();
      const currentVersion = getCurrentVersion();

      if (typeof currentVersion === "string" && storedVersion !== currentVersion) {
        // Store the version as pending until the user clicks reload
        localStorage.setItem(PENDING_VERSION_KEY, currentVersion);

        setVisibility(true);
      }
    }

    const id = setInterval(tick, VERSION_CHECK_INTERVAL);
    return () => clearInterval(id);
  }, []);

  const handleOnUpdate = () => {
    const pendingVersion = localStorage.getItem(PENDING_VERSION_KEY);

    if (typeof pendingVersion === "string") {
      // Set the version before the reload
      localStorage.setItem(VERSION_KEY, pendingVersion);
      localStorage.removeItem(PENDING_VERSION_KEY);

      window.location.reload();
    }
  };

  if (!showToast) return null;

  return (
    <Container>
      <Flex>
        <IconColumn>
          <Alert width={32} />
        </IconColumn>
        <TextColumn>
          <Text as="h3" size="middle">
            A new version of the admin is out!
          </Text>
          <Button type="button" action noContent size="small" onClick={handleOnUpdate}>
            Click here to update
          </Button>
        </TextColumn>
      </Flex>
    </Container>
  );
};

export const Container = styled.div`
  box-shadow: 1px 1px 6px rgb(0 0 0 / 34%);
  border-radius: 4px;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 400;
`;

export const IconColumn = styled.div`
  background-color: #fca009;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: white;
`;

export const TextColumn = styled.div`
  background-color: white;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${Text} {
    padding-bottom: 0.5rem;
  }
`;
