import { ApolloError } from "@apollo/client";
import React from "react";

import Actions from "components/ActionsMenu/PapaActionsMenu/Actions";
import SmartTable from "components/SmartTable";
import { Pagination, PapaSorting } from "generated/types";
import { PapaConcealed } from "types";

import { usePapaTableColumns } from "./usePapaTableColumns";

export { GET_PAPAS } from "./gql";

export interface Props {
  data?: PapaConcealed[];
  loading: boolean;
  pagination?: Pagination | null;
  error?: ApolloError;
  sorting: PapaSorting;
  onSortChange: (sort: PapaSorting) => void;
  onRefetch: () => void;
}

const PapasTable = ({
  data,
  loading,
  error,
  pagination,
  sorting,
  onSortChange,
  onRefetch,
}: Props) => {
  const { columns, action, papa, closeModal } = usePapaTableColumns({
    sorting,
    onSortChange,
  });

  return (
    <>
      <SmartTable<PapaConcealed>
        keyField="id"
        localStorageKey="papaAccounts"
        data={data}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
        cursorPagination
        rowProps={({ id }) => ({
          linkTo: `/papas/${id}`,
        })}
      />

      <Actions papa={papa} action={action} closeModal={closeModal} onRefetch={onRefetch} />
    </>
  );
};

export default PapasTable;
