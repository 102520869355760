import { KeyboardArrowUp } from "@styled-icons/material-sharp/KeyboardArrowUp";
import React from "react";
import styled from "styled-components";

interface Props {
  isCollapsed: boolean;
  onClick: () => void;
}

export const ArrowToggle: React.FC<Props> = ({ isCollapsed, onClick }) => (
  <Icon rotate={!isCollapsed} onClick={onClick} size={36} aria-label="arrow toggle" />
);

const Icon = styled(({ rotate, ...props }) => <KeyboardArrowUp {...props} />)<{
  rotate: boolean;
}>`
  position: absolute;
  right: 0;
  top: -10px;
  color: #a1a7af;
  cursor: pointer;
  transition: 0.2s transform ease;
  transform: ${({ rotate }) => `rotate(${rotate ? "0deg" : "180deg"})`};
  z-index: 1;
`;
