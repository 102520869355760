import * as Yup from "yup";

import { taskObjectivesSchema } from "components/TaskObjectives/constants";
import { VisitMobilityNeeds, VisitRecurrenceFrequency } from "generated/types";
import { getRecurrenceCount } from "utils/visitRecurrence";

import { AdditionalLocationScheduleInput } from "./types";

export const visitSchema = Yup.object().shape({
  accountId: Yup.string(),
  accountType: Yup.string(),
  cardId: Yup.string().when("isPaymentValid", {
    is: (value) => !value,
    then: Yup.string().required("Required"),
  }),
  estimatedDuration: Yup.number()
    .nullable()
    .when("isVirtual", {
      is: true,
      then: Yup.number().min(30, "Minimum estimated duration is 30 minutes"),
      otherwise: Yup.number().min(60, "Minimum estimated duration is one hour"),
    })
    .when(
      "maxMinutesPerVisit",
      (maxMinutesPerVisit: number | null | undefined, schema: Yup.NumberSchema) =>
        maxMinutesPerVisit != null
          ? schema.max(
              maxMinutesPerVisit,
              `Maximum estimated duration is ${maxMinutesPerVisit} minutes`
            )
          : schema
    ),
  freq: Yup.string().nullable(),
  byDay: Yup.array().of(Yup.string()).nullable(),
  validUntil: Yup.string().nullable(),
  count: Yup.number()
    .nullable()
    .when(
      ["scheduledForDay", "businessPolicyEnd", "freq"],
      (
        scheduledForDay: string,
        businessPolicyEnd: string,
        freq: string,
        schema: Yup.NumberSchema
      ) => {
        if (!businessPolicyEnd || !scheduledForDay || !freq) return schema;

        return schema.max(
          getRecurrenceCount(scheduledForDay, businessPolicyEnd, freq as VisitRecurrenceFrequency),
          ({ max }) =>
            `Maximum number of recurrences is ${max} for this frequency and business policy`
        );
      }
    ),
  isVirtual: Yup.bool().required(),
  location: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
      timezone: Yup.string(),
    })
    .nullable()
    .required("Required"),
  favoritePalsOnly: Yup.boolean(),
  palId: Yup.string()
    .nullable()
    .when("genderMismatch", {
      is: true,
      then: Yup.string().test(
        "gender mismatch",
        "This pal does not match the member's gender preferences. Please select a different pal.",
        () => false
      ),
    }),
  invitePalIds: Yup.array().of(Yup.string()).nullable(),
  papaId: Yup.string().required("Required"),
  scheduledForDay: Yup.string().required("Date Required"),
  scheduledForTime: Yup.string()
    .required("Required")
    .nullable()
    .when("timeWithinOperatingHours", {
      is: false,
      then: Yup.string().test(
        "outside business hours",
        "The scheduled time is outside business hours",
        () => false
      ),
    }),
  surveyIds: Yup.array().of(Yup.string()),
  taskIds: Yup.array().of(Yup.string()).required("Please select at least one task"),
  hasRelocationTasks: Yup.boolean(),
  hasGroceryTask: Yup.boolean(),
  hasMedicationTask: Yup.boolean(),
  destination: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
      timezone: Yup.string(),
    })
    .nullable()
    .when(["hasRelocationTasks", "additionalLocations"], {
      is: (hasRelocationTasks, additionalLocations) => {
        // IF it's not a transportation visit
        // OR there's at least one additional location that has a description
        // THEN destination can be null
        return (
          !hasRelocationTasks ||
          (additionalLocations &&
            additionalLocations.some(
              (location: AdditionalLocationScheduleInput) =>
                location.description && location.description.trim() !== ""
            ))
        );
      },
      then: Yup.object().nullable(),
      otherwise: Yup.object().required("Required"),
    }),
  objectives: taskObjectivesSchema,
  note: Yup.string(),
  overrideBusinessPolicy: Yup.boolean(),
  obpReasonDescription: Yup.string().when("overrideBusinessPolicy", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  delivery: Yup.boolean().nullable(),
  visibleToPalApp: Yup.bool().required(),
  mobilityNeeds: Yup.mixed<VisitMobilityNeeds>()
    .oneOf(Object.values(VisitMobilityNeeds), "Member must have supported mobility needs.")
    .notOneOf([VisitMobilityNeeds.Unsupported], "Member has Unsupported mobility needs.")
    .required(),
  acceptsThirdPartyTransportation: Yup.boolean()
    .nullable()
    .when("validateAcceptsThirdPartyTransportation", {
      is: true,
      then: (schema: Yup.BooleanSchema) =>
        schema.required("Third party transportation is required"),
    }),
});
