import * as yup from "yup";

import { VisitRecurrenceFrequency, VisitRecurrenceWeekday } from "generated/types";

export interface Schema {
  freq: VisitRecurrenceFrequency;
  byDay: VisitRecurrenceWeekday[] | null;
  ends: string;
  validUntil?: string;
  count?: number;
}

export const schema = yup.object().shape({
  freq: yup.string().required("Required"),
  byDay: yup.array().of(yup.string()).nullable(),
  ends: yup.string().required("Required"),
  validUntil: yup.string().nullable(),
  count: yup.number().nullable().min(1),
});
