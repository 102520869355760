import { ApolloError } from "@apollo/client";
import { Sort } from "@styled-icons/boxicons-regular/Sort";
import { ArrowUpwardOutline } from "@styled-icons/evaicons-outline/ArrowUpwardOutline";
import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { StyledCheckbox as Checkbox } from "components/Checkbox";
import PaginationQueryString from "components/Pagination/PaginationQueryString";
import PaginationCursor from "components/PaginationCursor";
import QueryErrors from "components/QueryErrors";
import Table from "components/Table";
import { RowProps } from "components/Table/TableRow";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Pagination, SortDirection } from "generated/types";

import ColumnCustomize from "./ColumnCustomize";
import { Column, ColumnHeader, RequiredRowParams } from "./schema";
import { useTableStorage } from "./useTableStorage";
import {
  applyFormatter,
  includingOnSelected,
  isContain,
  isInclude,
  mapColumnToSettings,
  removeFromSelected,
} from "./utils";

type Props<T> = {
  keyField: string; // the field in the data by which the key for the table rows is set
  localStorageKey?: string; // define the localStorageKey to make the columns customizable
  columns: Column<T>[]; // data for building table columns
  data?: ((T & RequiredRowParams) | null)[] | null;
  pagination?: Pagination | null;
  showPagination?: boolean;
  loading?: boolean;
  rowProps?: (row: T & RequiredRowParams) => RowProps;
  error?: ApolloError;
  cursorPagination?: boolean;
  rowsSelected?: string[];
  onRowSelect?: (id: string) => void;
  onAllRowsSelect?: (ids: string[]) => void;
  minimal?: boolean;
};

const SmartTable = <T,>({
  keyField,
  columns,
  localStorageKey,
  data = [],
  pagination,
  showPagination = true,
  loading = false,
  rowProps: rowPropsGenerator,
  error,
  cursorPagination = false,
  rowsSelected = [],
  onRowSelect,
  onAllRowsSelect = () => {},
  minimal = false,
}: Props<T>) => {
  const history = useHistory();
  const columnsSettings = useMemo(() => columns.map(mapColumnToSettings), [columns]);
  const initialSettings = useMemo(() => ({ columns: columnsSettings }), [columnsSettings]);
  const [savedSettings, setSavedSettings] = useTableStorage(
    `table.${localStorageKey}`,
    initialSettings
  );
  const [visibleColumns, setVisibleColumns] = useState<Column<T>[]>([]);
  const savedColumnsSettings = [...savedSettings.columns];
  useEffect(() => {
    const updatedVisibleColumns = columns.filter(({ dataField }) => {
      const savedSetting = savedSettings.columns.find((column) => column.id === dataField);

      return !savedSetting || !savedSetting.isHidden;
    });

    setVisibleColumns(updatedVisibleColumns);
  }, [columns, savedSettings]);

  const limit = pagination?.limit;
  const totalCount = pagination?.totalCount;

  const totalPages = pagination?.totalPages
    ? pagination.totalPages
    : limit && totalCount
    ? Math.ceil(totalCount / limit)
    : null;

  const onSelectAll = () => {
    setSavedSettings({ columns: columns.map(mapColumnToSettings) });
  };

  const onSortChange = (header: ColumnHeader) => {
    const sort = header.sort === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

    if (header.onChange) {
      header.onChange(sort);
    } else {
      throw Error(`SmartTable: no callback attached for ${header.title} sorting`);
    }
  };

  const removeColumn = (dataField: string) => {
    const columnToUpdate = savedColumnsSettings.find((column) => column.id === dataField);

    if (columnToUpdate) {
      columnToUpdate.isHidden = true;
      setSavedSettings({ columns: savedColumnsSettings });
    } else {
      const columns = [...savedColumnsSettings, { id: dataField, isHidden: true }];

      setSavedSettings({ columns });
    }
  };

  const onColumnChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const columnDataField = target.name;

    if (target.checked) {
      const columnToAdd = savedColumnsSettings.find((column) => column.id === columnDataField);

      if (columnToAdd) {
        columnToAdd.isHidden = false;

        setSavedSettings({ columns: savedColumnsSettings });
      } else {
        const columns = [...savedColumnsSettings, { id: columnDataField, isHidden: false }];

        setSavedSettings({ columns });
      }
    } else {
      removeColumn(columnDataField);
    }
  };

  const handleAllCheckboxClick = () => {
    if (isContain(rowsSelected, data, keyField)) {
      onAllRowsSelect(removeFromSelected(rowsSelected, data, keyField));
    } else {
      onAllRowsSelect(includingOnSelected(rowsSelected, data, keyField));
    }
  };

  const isAllRowsSelected = isContain(rowsSelected, data, keyField);
  const isAllRowsSelectedDisabled = !data?.length;
  const isIndeterminate = isInclude(rowsSelected, data, keyField) && !isAllRowsSelected;

  return (
    <>
      {cursorPagination && showPagination && (
        <PaginationCursor pagination={pagination} loading={loading} />
      )}
      {localStorageKey && (
        <ColumnCustomize
          visibleColumns={visibleColumns}
          columns={columns}
          onSelectAll={onSelectAll}
          onColumnChange={onColumnChange}
          expect={["actions"]}
          withIcon={!minimal}
        />
      )}
      <QueryErrors error={error} />

      <Table>
        <Table.Head>
          {visibleColumns.map(({ header }) => {
            const sortingEnabled = header.hasOwnProperty("sort");

            return (
              <Table.HeadCell key={header.title}>
                {header.title}
                {!loading && header.type === "select" && (
                  <Checkbox
                    style={{ marginLeft: "8px" }}
                    type="checkbox"
                    disabled={isAllRowsSelectedDisabled}
                    checked={isAllRowsSelected || isIndeterminate}
                    onChange={handleAllCheckboxClick}
                    indeterminate={isIndeterminate}
                  />
                )}
                {header.sort && (
                  <HeadSortIcon
                    size={18}
                    onClick={() => onSortChange(header)}
                    rotate={header.sort === SortDirection.Desc}
                  />
                )}
                {sortingEnabled && !header.sort && (
                  <DisabledSortIcon size={18} onClick={() => onSortChange(header)} />
                )}
              </Table.HeadCell>
            );
          })}
        </Table.Head>

        <Table.Body>
          {(loading || !data) && <Table.Placeholder rows={3} columns={visibleColumns.length} />}
          {!loading && !data?.length && (
            <Table.Row>
              <Table.Cell colSpan={visibleColumns.length} align="center" noWrap>
                No data to show
              </Table.Cell>
            </Table.Row>
          )}
          {!loading &&
            data?.map((row, index) => {
              if (!row) {
                return null;
              }

              const rowPropsOverwrite = onRowSelect ? { linkTo: "#" } : {};
              const rowProps = rowPropsGenerator ? rowPropsGenerator(row) : {};
              const linkTo = rowProps.linkTo;

              const onRowClick =
                linkTo || onRowSelect
                  ? (event: any) => {
                      if (event.altKey) return;
                      const tableCell = (event.target as HTMLElement).closest("td");
                      if (!tableCell?.querySelector("a, button, input")) {
                        if (linkTo) {
                          history.push(linkTo);
                        } else {
                          !!onRowSelect && onRowSelect(row[keyField]);
                        }
                      }
                    }
                  : undefined;
              const onMouseEnter =
                linkTo || onRowSelect
                  ? (event: any) => {
                      const tableCell = (event.target as HTMLElement).closest("td");
                      const tableRow = (event.target as HTMLElement).closest("tr");
                      if (!tableCell?.querySelector("a, button, input")) {
                        tableRow?.classList.add("hover-style");
                      }
                    }
                  : undefined;
              const onMouseLeave =
                linkTo || onRowSelect
                  ? (event: any) => {
                      const tableCell = (event.target as HTMLElement).closest("td");
                      const tableRow = (event.target as HTMLElement).closest("tr");
                      if (!tableCell?.querySelector("a, button, input")) {
                        tableRow?.classList.remove("hover-style");
                      }
                    }
                  : undefined;

              return (
                <Table.Row key={row[keyField]} {...rowProps} {...rowPropsOverwrite}>
                  {visibleColumns.map((column) => {
                    let cellValue = get(row, column.dataField, EMPTY_PLACEHOLDER);

                    if (cellValue === "" || (!column.formatter && cellValue === null)) {
                      cellValue = EMPTY_PLACEHOLDER;
                    }

                    if (!column.formatter && typeof cellValue === "object") {
                      cellValue = JSON.stringify(cellValue);
                    }

                    return (
                      <Table.Cell
                        key={column.dataField}
                        {...(column.cellProps ?? {
                          noWrap: column.dataField !== "actions",
                          fixed: column.dataField === "actions",
                        })}
                        onClick={column.dataField !== "actions" ? onRowClick : undefined}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        {column.header.type === "select" ? (
                          <Checkbox
                            type="checkbox"
                            onChange={() => onRowSelect && onRowSelect(row[keyField])}
                            checked={rowsSelected.includes(row[keyField])}
                          />
                        ) : column.formatter ? (
                          applyFormatter<T>(column.formatter, cellValue, row, index)
                        ) : (
                          cellValue
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      {showPagination && (
        <>
          {!cursorPagination && totalPages ? (
            <PaginationQueryString align="right" totalPages={totalPages} />
          ) : (
            <PaginationCursor pagination={pagination} loading={loading} />
          )}
        </>
      )}
    </>
  );
};

export const ItemSelected: React.FC<{ rowsSelected: string[] }> = ({ rowsSelected }) => {
  if (rowsSelected.length === 0) return null;

  return (
    <SelectRowText>
      {rowsSelected.length === 1 ? "1 item selected" : `${rowsSelected.length} items selected`}
    </SelectRowText>
  );
};

const SelectRowText = styled(Text)`
  padding: 7px 10px;
  margin-right: 20px;
  color: ${({ theme }) => theme.text.muted};
`;

const HeadSortIcon = styled(({ rotate, ...props }) => <ArrowUpwardOutline {...props} />)<{
  rotate: boolean;
}>`
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s transform ease;
  transform: ${({ rotate }) => `rotate(${rotate ? "180deg" : "0deg"})`};
`;

const DisabledSortIcon = styled(Sort)`
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.5;
`;

export default SmartTable;
