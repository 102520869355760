import React, { useState } from "react";

import DataTableDropdownMenu, { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { accountTypeOptions } from "constants/account";
import { Account, AccountSorting, SortDirection } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";

interface Params {
  sorting: AccountSorting;
  onSortChange: (sort: AccountSorting) => void;
}

export const useAdminsTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { isSupervisor } = useCurrentAccountRole();
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [account, setAccount] = useState<Account | null>(null);
  const [sorting, setSorting] = useState<AccountSorting>(initialSorting);

  const onSetSorting = (sortKey: keyof AccountSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const menuItems = ({ id, type }: Account) => [
    {
      label: `View ${type === "BUSINESS" ? "business" : "member"}`,
      link: `/${type === "BUSINESS" ? "businesses" : "members"}/${id}`,
    },
    {
      label: "View account logs",
      link: `/accounts/${id}/activity-logs`,
      showCondition: isSupervisor,
    },
    { label: "Edit admin account", action: DataTableMenuActions.Edit },
    {
      label: "Send reset password email",
      action: DataTableMenuActions.ResetPassword,
    },
    { label: "Delete", action: DataTableMenuActions.Delete },
  ];
  const columns: Column<Account>[] = [
    {
      header: { title: "Full name" },
      dataField: "fullName",
    },
    {
      header: { title: "Type" },
      dataField: "type",
      formatter({ type }) {
        return mapValueToLabel(accountTypeOptions, type);
      },
    },
    {
      header: { title: "Contact" },
      dataField: "email",
      formatter({ email, phoneNumber }) {
        return (
          <>
            <Text>{email}</Text>
            <Text>{phoneNumber}</Text>
          </>
        );
      },
    },
    {
      header: { title: "Membership" },
      dataField: "plan.data.name",
      cellProps: { noWrap: true },
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt?.toString());
      },
    },
    {
      header: { title: "Deleted At" },
      dataField: "deletedAt",
      cellProps: { noWrap: true },
      formatter({ deletedAt }) {
        return formatDateTime(deletedAt?.toString());
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(account) {
        return (
          <DataTableDropdownMenu
            items={menuItems(account)}
            actionHandler={(action) => menuActionsHandler(action, account)}
          />
        );
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, account: Account) => {
    setAction(action);
    setAccount(account);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setAccount(null);
  };

  return {
    columns,
    action,
    account,
    setAction,
    closeModal,
  };
};
