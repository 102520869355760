import { gql } from "@apollo/client";

export const REVERT_VISIT_COMMUTE = gql`
  mutation revertVisitCommute($id: ID!) {
    revertVisitCommute(id: $id) {
      data {
        id
        state
      }
    }
  }
`;
