import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import InputDate from "components/InputDate";
import InputPhone from "components/InputPhone";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { genderOptions } from "constants/gender";
import { Caregiver } from "generated/types";
import { mapSelectValuesToOptions } from "utils/select";
import { formatFullName } from "utils/strings/formatFullName";

import { UPDATE_ACCOUNT, UPDATE_CAREGIVER } from "./gql";
import { FormValues, schema } from "./schema";

interface Props {
  caregiver: Caregiver;
  onFormComplete: () => void;
}

export const CaregiverForm: React.FC<Props> = ({ caregiver, onFormComplete }) => {
  const [updateAccount] = useMutation(UPDATE_ACCOUNT);
  const [updateCaregiver] = useMutation(UPDATE_CAREGIVER);

  const initialValues = {
    fullName: caregiver.fullName ?? "",
    email: caregiver.account?.data?.email ?? "",
    phoneNumber: caregiver.account?.data?.phoneNumber ?? "",
    gender: caregiver.gender || null,
    birthDate: caregiver.birthDate || null,
  };

  const handleSubmit = async (values: FormValues) => {
    const momentBirthday = moment(values.birthDate);
    const birthDate =
      values.birthDate && momentBirthday.isValid() ? momentBirthday.format("YYYY-MM-DD") : null;

    try {
      const accountInput = {
        fullName: formatFullName(values.fullName as string),
        email: values.email,
        phoneNumber: values.phoneNumber,
      };

      const caregiverInput = {
        birthDate,
        gender: values.gender,
      };

      const mutations = [
        updateAccount({
          variables: { accountId: caregiver.account?.data?.id, input: accountInput },
        }),
        updateCaregiver({
          variables: { caregiverId: caregiver.id, input: caregiverInput },
        }),
      ];

      const [accountUpdate, caregiverUpdate] = await Promise.all(mutations);
      if (
        accountUpdate.data?.updateAccount?.data?.id &&
        caregiverUpdate.data?.updateCaregiver?.data?.id
      ) {
        toast.success("Caregiver was edited with success");
        onFormComplete();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label required>Name</Form.Label>
            <Form.Input type="text" aria-label="name" name="fullName" />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Email</Form.Label>
            <Form.Input type="text" aria-label="email" name="email" />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Phone number</Form.Label>
            <InputPhone name="phoneNumber" defaultCountry="US" aria-label="phoneNumber" />
          </Form.Group>
          <Form.Group>
            <Form.Label id="caregiver-gender" required>
              Gender
            </Form.Label>
            <Select
              data-testid="caregiver-gender"
              aria-labelledby="caregiver-gender"
              name="gender"
              defaultValue={mapSelectValuesToOptions(initialValues.gender, genderOptions)}
              options={genderOptions}
              isSearchable={false}
              placeholder="Select gender"
              aria-label="gender"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label required>Birth Date</Form.Label>
            <InputDate
              id="birthDate"
              name="birthDate"
              isOutsideRange={(day) => day.isAfter(moment())}
              withYear
              block
            />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onFormComplete}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
