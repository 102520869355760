import React, { FunctionComponent, useState } from "react";

import { Drawer } from "components/Drawer";
import ConfirmModal from "components/Modal/ConfirmModal";

import EnrollMemberForm from "../EnrollMemberForm";

interface Props {
  memberId: string | null;
  isModalFormOpen: boolean;
  setIsModalFormOpen: (state: boolean) => void;
}

const EnrollMember: FunctionComponent<Props> = ({
  memberId,
  isModalFormOpen,
  setIsModalFormOpen,
}: Props) => {
  const [dirty, setDirty] = useState(false);
  const [closeConfirm, setCloseConfirm] = useState(false);

  const closeFormModal = () => (dirty ? setCloseConfirm(true) : setIsModalFormOpen(false));

  const handleCloseConfirm = () => {
    setIsModalFormOpen(false);
    setCloseConfirm(false);
  };

  return (
    <>
      <Drawer open={isModalFormOpen} onClose={closeFormModal} title="Enroll member" size="md">
        <EnrollMemberForm id={memberId} setDirty={setDirty} closeModal={closeFormModal} />
      </Drawer>

      <ConfirmModal
        isOpen={closeConfirm}
        onCancel={() => setCloseConfirm(false)}
        onConfirm={handleCloseConfirm}
        header="Do you wish to close and not proceed?"
      />
    </>
  );
};

export default EnrollMember;
