import { singularize } from "inflection";

import { IntervalUnit } from "generated/types";

export const intervalOptions = Object.keys(IntervalUnit).map((key: string) => {
  return {
    value: (IntervalUnit as any)[key],
    label: singularize(key),
  };
});
