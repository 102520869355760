import { gql } from "@apollo/client";

export const PAPA_BLOCKED_PALS = gql`
  query papaBlockedPals(
    $filter: [PapaBlockedPalFilter]
    $sorting: PapaBlockedPalSorting
    $pagination: PaginationInput
  ) {
    papaBlockedPals(filter: $filter, sorting: $sorting, pagination: $pagination) {
      data {
        id
        insertedAt
        comment
        pal {
          data {
            id
            fullName
            blockedPalCount
            status
          }
        }
      }
      pagination {
        afterCursor
        beforeCursor
        limit
      }
    }
  }
`;
