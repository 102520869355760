/* eslint-disable quotes */
import styled, { DefaultTheme, css } from "styled-components";

import { cssStringOrPx } from "utils/helpers/cssStringOrPx";

type Colors = keyof DefaultTheme["text"];
type Sizes = keyof DefaultTheme["fontSizes"];

export type Props = {
  color?: Colors;
  size?: Sizes;
  medium?: boolean;
  underline?: boolean;
  bold?: boolean;
  italic?: boolean;
  ellipsis?: boolean;
  inline?: boolean;
  wrapString?: boolean;
  padding?: string | number;
  marginBottom?: string | number;
  marginTop?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
};

const Text = styled("p")<Props>`
  color: ${({ color, theme }) => {
    if (color && theme.text[color]) {
      return theme.text[color];
    }
    return "inherit";
  }};
  font-size: ${({ size, theme }) => {
    if (size && theme.fontSizes[size]) {
      return theme.fontSizes[size];
    }
    return "inherit";
  }};
  font-family: ${({ medium }) => medium && '"Avenir", sans-serif'};
  font-weight: ${({ bold }) => bold && "700"};
  font-style: ${({ italic }) => italic && "italic"};
  line-height: 1.313rem;
  text-decoration: ${({ underline }) => underline && "underline"};
  display: ${({ inline }) => (inline ? "inline" : "block")};
  margin-top: 0;
  text-overflow: ellipsis;
  max-width: 100%;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    `}

  ${({ wrapString }) =>
    wrapString &&
    css`
      word-break: break-all;
    `}
  
  ${({ marginBottom }) =>
    marginBottom
      ? css`
          margin-bottom: ${cssStringOrPx(marginBottom)};
        `
      : css`
          margin-bottom: 0;
        `}

  ${({ marginTop }) =>
    marginTop
      ? css`
          margin-top: ${cssStringOrPx(marginTop)};
        `
      : css`
          margin-top: 0;
        `}

  ${({ marginLeft }) =>
    marginLeft
      ? css`
          margin-left: ${cssStringOrPx(marginLeft)};
        `
      : css`
          margin-left: 0;
        `}

  ${({ marginRight }) =>
    marginRight
      ? css`
          margin-right: ${cssStringOrPx(marginRight)};
        `
      : css`
          margin-right: 0;
        `}

  ${({ padding }) =>
    padding
      ? css`
          padding: ${cssStringOrPx(padding)};
        `
      : css`
          padding: 0;
        `}
`;

export const SuccessText = styled(Text)`
  color: ${({ theme }) => theme.variants.success} !important;
`;

export default Text;
