import { useState } from "react";

import { ProspectPalMenuActions } from "components/ActionsMenu/ProspectPalActionMenu/models";
import { PalOnboarding } from "generated/types";

export const useProspectPalActions = () => {
  const [palOnboarding, setPalOnboarding] = useState<PalOnboarding | null>(null);
  const [action, setPalOnboardingAction] = useState(ProspectPalMenuActions.noAction);

  const setAction = (action: ProspectPalMenuActions, pal: PalOnboarding | null = null) => {
    setPalOnboarding(pal);
    setPalOnboardingAction(action);
  };

  return {
    palOnboarding,
    action,
    setAction,
  };
};
