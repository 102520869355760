import React, { FunctionComponent } from "react";
import { MenuPlacement } from "react-select";

import Select from "components/Select";

interface Props {
  name: string;
  options: { label: string; value: string }[];
  menuPlacement?: MenuPlacement;
}

const TaskSelect: FunctionComponent<Props> = ({ name, options, menuPlacement = "bottom" }) => (
  <Select
    aria-label={name}
    name={name}
    options={options}
    isMulti
    placeholder="Select tasks"
    menuPlacement={menuPlacement}
  />
);

export default TaskSelect;
