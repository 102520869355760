import { Formik, Form as FormikForm } from "formik";
import isEqual from "lodash/isEqual";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import {
  Container,
  FilterTitle,
  FilterType,
  Footer,
  StyledFormGroup,
} from "components/Modal/FilterModal.styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import { BusinessSearchSelect } from "components/Select/Business/BusinessSearchSelect";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useUrlQuery } from "hooks/useUrlQuery";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const CaregiverFiltersModal: React.FC<Props> = ({ isOpen, toggle }) => {
  const hideCleanFilter = false;
  const history = useHistory();
  const urlQueries = useUrlQuery();
  const [initialValues, setInitialValues] = useState<FormValues>({
    businessId: "",
  });

  useEffect(() => {
    if (!isEqual(urlQueries, initialValues)) {
      setInitialValues(urlQueries as unknown as FormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQueries]);

  const handleSubmit = async (values: FormValues) => {
    const query = queryString.stringify(values, { skipNull: true, skipEmptyString: true });

    history.push(`?${query}`);
    toggle();
  };

  const clientCopyUpdateEnabled = useIsFeatureEnabled("client_copy_update");
  const filterByFieldName = clientCopyUpdateEnabled ? "Employer Client" : "Client Name";

  return (
    <ModalLayout isOpen={isOpen} width={700} title="Caregiver Filter" toggle={toggle}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ resetForm }) => (
          <FormikForm>
            <Container>
              <ModalContainer>
                <StyledFormGroup>
                  <FilterTitle bold size="largex">
                    Filter by
                    <FilterType as="span">{filterByFieldName}</FilterType>
                  </FilterTitle>
                  <BusinessSearchSelect name="businessId" />
                </StyledFormGroup>
              </ModalContainer>
              <Footer>
                {!hideCleanFilter && (
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      resetForm({
                        values: {
                          businessId: "",
                        },
                      });
                      history.push("?");
                      toggle();
                    }}
                  >
                    Clear Filters
                  </Button>
                )}
                <Button type="submit" variant="primary">
                  Apply
                </Button>
              </Footer>
            </Container>
          </FormikForm>
        )}
      </Formik>
    </ModalLayout>
  );
};

interface FormValues {
  businessId: string;
}
