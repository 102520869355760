import styled, { DefaultTheme } from "styled-components";

type Variants = keyof DefaultTheme["variants"];

export const defaultProps = {
  variant: "primary" as Variants,
};

type DefaultProps = Readonly<typeof defaultProps>;

type Props = {
  variant?: Variants;
} & Partial<DefaultProps>;

const Tag = styled.span<Props>`
  display: inline-block;
  background: ${({ variant = defaultProps.variant, theme }) =>
    variant === "primary" ? theme.background.panelHeader : theme.variants[variant!]};
  border-radius: 0.375rem;
  color: ${({ variant = defaultProps.variant, theme }) =>
    variant === "primary" ? theme.text.panelHeader : "#fff"};
  padding: 0 7px;
`;

Tag.defaultProps = defaultProps;

export default Tag;
