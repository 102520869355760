import { AlertCircleOutline } from "@styled-icons/evaicons-outline/AlertCircleOutline";
import moment from "moment-timezone";
import React from "react";
import styled from "styled-components";
import { CheckCircle } from "styled-icons/fa-solid";

import Conditional from "components/Conditional";
import { Flex } from "components/Flex";
import Heading from "components/Heading";
import Text from "components/Text";
import {
  AssessmentSubmissionFragment,
  AssessmentsAnswersQuery,
  AssessmentsSurveyQuestionsByNameQuery,
  AssignedScreener,
  useAssessmentsSurveyQuestionsByNameQuery,
  useGetScreenerSubmissionsQuery,
} from "generated/types";
import {
  AssessmentsSurveyNames,
  AssessmentsSurveyTitles,
  getAssessmentType,
} from "modals/AssessmentsQuestions/models";
import { AssessmentType } from "types/assessments";
import { nonNull } from "utils/nonNull";

import { AssessmentCardsPlaceholder } from "./AssessmentCardsPlaceholder";

const MAX_PAST_DUE_DAYS = 90;

type Props = {
  assessmentAnswerData?: AssessmentsAnswersQuery;
  assessments: AssessmentSubmissionFragment[];
  onAssessmentClick: (type: AssessmentType) => void;
  onScreenerCardClick: (id: string) => void;
  availableAssessments: AssessmentType[];
  papaId: string;
  assignedScreeners: Pick<AssignedScreener, "id" | "title">[];
};

export const AssessmentCards = ({
  assessmentAnswerData,
  onAssessmentClick,
  onScreenerCardClick,
  availableAssessments,
  assessments,
  papaId,
  assignedScreeners,
}: Props) => {
  const names = Object.entries(AssessmentsSurveyNames)
    .filter(([key]) => availableAssessments.includes(key as AssessmentType))
    .map(([key, name]) => name);

  const { data, loading } = useAssessmentsSurveyQuestionsByNameQuery({
    skip: !names.length || !assessmentAnswerData,
    variables: {
      filter: {
        name: {
          in: names,
        },
      },
    },
  });

  const { data: screenerSubmissions, loading: screenerSubmissionsLoading } =
    useGetScreenerSubmissionsQuery({
      variables: {
        papaId: papaId,
      },
    });

  const papaSubmittedScreenerIds = screenerSubmissionsLoading
    ? []
    : screenerSubmissions?.papa?.data?.screenerSubmissions?.data?.map(
        (submission) => submission.screenerVersion?.data?.screener?.data?.id
      );

  const surveyQuestionsAmount = getSurveyQuestionsAmount(data);

  if (loading || !assessmentAnswerData) {
    return <AssessmentCardsPlaceholder amount={3} />;
  }

  const amount = availableAssessments.length;

  const lastSubmittedSubmissionByType = getLatestSubmittedAtByType(assessments);
  const isPastDue = (type: AssessmentType) => {
    const submittedAt = lastSubmittedSubmissionByType[type];

    if (!submittedAt) return true;
    const days = moment().diff(moment(submittedAt), "days");
    return days > MAX_PAST_DUE_DAYS;
  };

  const items = availableAssessments.map((type) => {
    const title = AssessmentsSurveyTitles[type];
    const questions = surveyQuestionsAmount[type];
    const questionAmount = `${questions} Question${questions !== 1 ? "s" : ""}`;
    return {
      title,
      questionAmount,
      label: title,
      type,
    };
  });

  return (
    <>
      <Heading as="h3" color="primary">
        Assessments due ({amount})
      </Heading>

      <Container>
        {!amount && (
          <Text size="small" color="muted">
            No assessments available
          </Text>
        )}
        {items.map(({ title, label, questionAmount, type }) => (
          <Card
            key={title}
            role="button"
            onClick={() => onAssessmentClick(type)}
            aria-label={`Start ${label}`}
          >
            <Info>
              <Flex flexDirection="column">
                <Text bold>{title}</Text>
                <Text size="small">{questionAmount}</Text>
              </Flex>

              {isPastDue(type) && (
                <Flex alignItems="center" gridGap={4}>
                  <AlertIcon size={14} />
                  <Due size="small">Past due</Due>
                </Flex>
              )}
            </Info>
            <Action type="button">Start</Action>
          </Card>
        ))}

        {assignedScreeners.map((screener) => (
          <ScreenerCard
            onScreenerCardClick={onScreenerCardClick}
            isComplete={!!papaSubmittedScreenerIds?.includes(screener.id)}
            id={screener.id}
            title={screener.title}
            key={screener.id}
          />
        ))}
      </Container>
    </>
  );
};

export const getSurveyQuestionsAmount = (data?: AssessmentsSurveyQuestionsByNameQuery) => {
  return nonNull(data?.surveys?.data).reduce(
    (acc, survey) => {
      const amount = nonNull(survey.questions?.data).length;
      const type = getAssessmentType(survey.name);
      if (!type) return acc;

      return {
        ...acc,
        [type]: amount,
      };
    },
    {
      [AssessmentType.health]: 0,
      [AssessmentType.loneliness]: 0,
      [AssessmentType.loneliness4Question]: 0,
      [AssessmentType.papaSocialIndex]: 0,
      [AssessmentType.papaSocialIndexMedicare]: 0,
    }
  );
};

export const getLatestSubmittedAtByType = (assessments: AssessmentSubmissionFragment[]) => {
  const initialAssessment: { [key in AssessmentType]?: string } = {};

  return assessments.reduce((acc, { survey, submittedAt, isLatest }) => {
    const type = getAssessmentType(survey?.data?.name);

    if (!type || !submittedAt || !isLatest) return acc;

    return {
      ...acc,
      [type]: submittedAt,
    };
  }, initialAssessment);
};

type ScreenerCardProps = {
  onScreenerCardClick: (id: string) => void;
  isComplete: boolean;
  id: string;
  title: string;
};

const ScreenerCard = ({ onScreenerCardClick, isComplete, id, title }: ScreenerCardProps) => {
  return (
    <Card key={title} role="button" onClick={() => onScreenerCardClick(id)} aria-label={title}>
      <Info>
        <Flex flexDirection="column">
          <Text bold>{title}</Text>
        </Flex>
      </Info>

      <Flex flexDirection="row" justifyContent="space-between" alignItems="flex-end" width="100%">
        <Action type="button">Start</Action>

        <Conditional show={isComplete}>
          <CheckIcon aria-label="completion check mark" />
        </Conditional>
      </Flex>
    </Card>
  );
};

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const Card = styled.div`
  border-radius: 4px;
  box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 16.125rem;
  height: 7.625rem;
  padding: 0.75rem 0.75rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

const Action = styled.button`
  color: ${({ theme }) => theme.text.primary};
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: inherit;
  padding: 0;
  margin: 0;
  outline: none;
`;

const Due = styled(Text)`
  white-space: nowrap;
`;

const AlertIcon = styled(AlertCircleOutline)`
  color: ${({ theme }) => theme.variants.danger};
`;

const CheckIcon = styled(CheckCircle)`
  color: ${({ theme }) => theme.variants.success};
  height: 2em;
  width: 2em;
`;
