import { PapaChangeStatusReasonEnum, PapaState } from "generated/types";

export const papaStateLabel: {
  [key in PapaState]: string;
} = {
  [PapaState.Active]: "Active",
  [PapaState.Banned]: "Banned",
  [PapaState.ActiveWithoutRecurring]: "Active Without Recurring",
  [PapaState.ChangedPlans]: "Changed Plans",
  [PapaState.Deceased]: "Deceased",
  [PapaState.DuplicateMember]: "Duplicate Member",
  [PapaState.Inactive]: "Inactive",
  [PapaState.OptOut]: "Opt Out",
  [PapaState.Paused]: "Paused",
  [PapaState.WrongAccount]: "Wrong Account",
  [PapaState.NotCurrentlyQualified]: "Not currently qualified",
  [PapaState.Suspended]: "Suspended",
  [PapaState.UnderInvestigation]: "Under Investigation",
  [PapaState.Ineligible]: "Ineligible",
};

export const papaStateOptions = [
  { value: PapaState.Active, label: papaStateLabel[PapaState.Active] },
  { value: PapaState.Banned, label: papaStateLabel[PapaState.Banned] },
  {
    value: PapaState.ActiveWithoutRecurring,
    label: papaStateLabel[PapaState.ActiveWithoutRecurring],
  },
  { value: PapaState.ChangedPlans, label: papaStateLabel[PapaState.ChangedPlans] },
  { value: PapaState.Deceased, label: papaStateLabel[PapaState.Deceased] },
  { value: PapaState.DuplicateMember, label: papaStateLabel[PapaState.DuplicateMember] },
  { value: PapaState.Inactive, label: papaStateLabel[PapaState.Inactive] },
  { value: PapaState.OptOut, label: papaStateLabel[PapaState.OptOut] },
  { value: PapaState.Paused, label: papaStateLabel[PapaState.Paused] },
  { value: PapaState.WrongAccount, label: papaStateLabel[PapaState.WrongAccount] },
  {
    value: PapaState.NotCurrentlyQualified,
    label: papaStateLabel[PapaState.NotCurrentlyQualified],
  },
  { value: PapaState.Suspended, label: papaStateLabel[PapaState.Suspended] },
  { value: PapaState.UnderInvestigation, label: papaStateLabel[PapaState.UnderInvestigation] },
];

export const grievanceOnlyStates = [
  PapaState.Banned,
  PapaState.Suspended,
  PapaState.UnderInvestigation,
];

export const papaChangeStatusReasonOptions = [
  { value: PapaChangeStatusReasonEnum.CancelledAllVisits, label: "Cancelled all visits" },
  { value: PapaChangeStatusReasonEnum.ChangedInsurance, label: "Changed insurance" },
  { value: PapaChangeStatusReasonEnum.Deceased, label: "Deceased" },
  { value: PapaChangeStatusReasonEnum.DidNotActuallySignup, label: "Did not actually signup" },
  { value: PapaChangeStatusReasonEnum.Dnc, label: "DNC" },
  {
    value: PapaChangeStatusReasonEnum.DoesNotWantVirtualVisits,
    label: "Does not want virtual visits",
  },
  { value: PapaChangeStatusReasonEnum.DoesNotWantVv, label: "Does not want VV only wants IPV" },
  { value: PapaChangeStatusReasonEnum.Duplicated, label: "Duplicated/account error" },
  { value: PapaChangeStatusReasonEnum.DuplicatedAccount, label: "Duplicated account" },
  { value: PapaChangeStatusReasonEnum.EscalationClientSuccess, label: "Escalation client success" },
  {
    value: PapaChangeStatusReasonEnum.EscalationGrievancesAppeals,
    label: "Escalation grievances appeals",
  },
  { value: PapaChangeStatusReasonEnum.GrievanceInvestigation, label: "Grievance investigation" },
  { value: PapaChangeStatusReasonEnum.HealthReasons, label: "Health reasons" },
  {
    value: PapaChangeStatusReasonEnum.InterestedInWorkingAgain,
    label: "Interested in working again",
  },
  { value: PapaChangeStatusReasonEnum.InvestigationCompleted, label: "Investigation completed" },
  { value: PapaChangeStatusReasonEnum.LanguageBarrier, label: "Language barrier" },
  {
    value: PapaChangeStatusReasonEnum.MemberEligibilityChanged,
    label: "Member eligibility changed",
  },
  { value: PapaChangeStatusReasonEnum.MemberPassedAway, label: "Member passed away" },
  { value: PapaChangeStatusReasonEnum.MemberReengaged, label: "Member reengaged" },
  {
    value: PapaChangeStatusReasonEnum.MemberWantsToScheduleVisit,
    label: "Member wants to schedule a visit",
  },
  { value: PapaChangeStatusReasonEnum.Moving, label: "Moving" },
  {
    value: PapaChangeStatusReasonEnum.NeedsMoreIntensiveCareNow,
    label: "Needs more intensive care now",
  },
  {
    value: PapaChangeStatusReasonEnum.NotInterestedForNow,
    label: "Not interested for now, maybe later",
  },
  {
    value: PapaChangeStatusReasonEnum.NotInterestedInService,
    label: "Not interested in service/opt out",
  },
  { value: PapaChangeStatusReasonEnum.PoorExperience, label: "Poor experience" },
  { value: PapaChangeStatusReasonEnum.SuspensionCompleted, label: "Suspension completed" },
  { value: PapaChangeStatusReasonEnum.TooManyCalls, label: "Too many calls" },
  {
    value: PapaChangeStatusReasonEnum.TooMuchOfTimeCommitment,
    label: "Too much of a time commitment",
  },
  { value: PapaChangeStatusReasonEnum.UnableToTakeCalls, label: "Unable to take calls" },
  { value: PapaChangeStatusReasonEnum.WantedDifferentServices, label: "Wanted different services" },
  { value: PapaChangeStatusReasonEnum.WrongNumber, label: "Wrong number/information" },
];
