import React from "react";
import styled from "styled-components";

type Props = React.ThHTMLAttributes<HTMLTableHeaderCellElement> & {
  textAlign?: "left" | "center" | "right";
  width?: string;
};

const StyledHeadCell = styled("th")<Props>`
  color: ${({ theme }) => theme.text.main};
  text-align: ${({ textAlign = "left" }) => textAlign};
  width: ${({ width = "auto" }) => width};
  padding: 0 0.938rem;
  white-space: nowrap;
`;

const TableHeadCell = (props: Props) => {
  return <StyledHeadCell {...props} />;
};

export default TableHeadCell;
