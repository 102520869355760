import { gql } from "@apollo/client";

export const GET_VISIT_TASK_OBJECTIVES = gql`
  query visitObjectiveTemplatesList(
    $filter: [VisitObjectiveTemplateFilter]
    $pagination: PaginationInput
  ) {
    visitObjectiveTemplates(filter: $filter, pagination: $pagination) {
      data {
        id
        description
        task {
          data {
            id
            name
          }
        }
      }
    }
  }
`;
