import * as yup from "yup";

import { VehicleType } from "generated/types";

export interface FormValues {
  year: number;
  color: string;
  type: VehicleType;
  model: string;
}

export const schema = yup.object().shape({
  type: yup.string().required("Required"),
  model: yup.string(),
  year: yup.number(),
  color: yup.string(),
});
