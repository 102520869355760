import React from "react";
import { Route, Switch } from "react-router-dom";

import Details from "./Details";
import List from "./List";

const BusinessesRoutes = () => {
  return (
    <Switch>
      <Route exact path="/businesses/:businessId/visit-programs" component={List} />
      <Route exact path="/client-programs/:businessId/visit-programs" component={List} />
      <Route exact path="/businesses/:businessId/visit-programs/:programId" component={Details} />
      <Route
        exact
        path="/client-programs/:businessId/visit-programs/:programId"
        component={Details}
      />
    </Switch>
  );
};

export default BusinessesRoutes;
