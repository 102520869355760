import { useQuery } from "@apollo/client";

import { AssessmentsSurveyNames } from "modals/AssessmentsQuestions/models";

import { GET_ASSESSMENTS } from "./gql";

export const useAssessmentOptions = () => {
  const {
    data: assessmentsData,
    loading,
    error,
  } = useQuery(GET_ASSESSMENTS, {
    variables: {
      filter: Object.values(AssessmentsSurveyNames).map((name) => ({ name: { eq: name } })),
    },
  });
  const assessmentOptions =
    assessmentsData?.surveys?.data?.map(({ name, id }: any) => ({ label: name, value: id })) || [];

  return { assessmentOptions, loading, error };
};
