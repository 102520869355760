import * as yup from "yup";

import {
  ServiceRequestClosedReason,
  ServiceRequestPriority,
  ServiceRequestStatus,
} from "generated/types";

export interface Schema {
  typeId: string;
  needId: string;
  needText: string;
  status: ServiceRequestStatus | null;
  priority: ServiceRequestPriority | null;
  closedReason?: ServiceRequestClosedReason | null;
}

export const formSchema = (otherSelected: boolean) =>
  yup.object().shape({
    typeId: yup.string().nullable(),
    needId: yup.string().nullable(),
    needText: otherSelected ? yup.string().required("Please enter other!") : yup.string(),
    status: yup.string().nullable(),
    priority: yup.string().nullable(),
    closedReason: yup
      .string()
      .nullable()
      .when("status", {
        is: (status: ServiceRequestStatus) => status === ServiceRequestStatus.Closed,
        then: yup.string().nullable().required("Please select closed reason"),
      }),
  });
