import React, { ReactElement, ReactNode } from "react";

type Props = {
  children: ReactNode;
  show: boolean;
};

const Conditional = ({ children, show }: Props): ReactElement | null => {
  return show ? <>{children}</> : null;
};

export default Conditional;
