import styled, { css } from "styled-components";

import Button from "components/Button";
import ButtonLink from "components/ButtonLink";
import { cssStringOrPx } from "utils/helpers/cssStringOrPx";

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ flexDirection }) =>
    !!flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
    
  ${({ grow }) =>
    grow &&
    css`
      flex-grow: 1;
    `}

    
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ wrap }) =>
    wrap &&
    css`
      flex-wrap: wrap;
    `}


    ${({ height }) =>
    height &&
    css`
      height: ${cssStringOrPx(height)};
    `}

    ${({ width }) =>
    width &&
    css`
      width: ${cssStringOrPx(width)};
    `}
    
  ${({ marginHorizontal }) =>
    marginHorizontal &&
    css`
      margin-left: ${cssStringOrPx(marginHorizontal)};
      margin-right: ${cssStringOrPx(marginHorizontal)};
    `}
    
  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    css`
      padding-left: ${cssStringOrPx(paddingHorizontal)};
      padding-right: ${cssStringOrPx(paddingHorizontal)};
    `}

  ${({ marginVertical }) =>
    marginVertical &&
    css`
      margin-top: ${cssStringOrPx(marginVertical)};
      margin-bottom: ${cssStringOrPx(marginVertical)};
    `}

  ${({ paddingVertical }) =>
    paddingVertical &&
    css`
      padding-top: ${cssStringOrPx(paddingVertical)};
      padding-bottom: ${cssStringOrPx(paddingVertical)};
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${cssStringOrPx(marginTop)};
    `}

  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${cssStringOrPx(paddingTop)};
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${cssStringOrPx(marginBottom)};
    `}
  
    
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${cssStringOrPx(marginLeft)};
    `}


  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${cssStringOrPx(paddingBottom)};
    `}

  ${({ gridGap }) =>
    gridGap &&
    css`
      grid-gap: ${cssStringOrPx(gridGap)};
    `}

  ${({ columnGap }) =>
    columnGap &&
    css`
      column-gap: ${cssStringOrPx(columnGap)};
    `}

  ${({ rowGap }) =>
    rowGap &&
    css`
      row-gap: ${cssStringOrPx(rowGap)};
    `}
`;

export const FlexRow = styled(Flex).attrs(({ wrap }: FlexRowProps) => ({
  flexWrap: wrap,
  flexDirection: "row",
  alignItems: "flex-start",
  marginBottom: "1.5rem",
}))<FlexRowProps>`
  ${Button} + ${Button} {
    margin-left: 1.25rem;
  }
  ${ButtonLink} {
    margin-left: 1.25rem;
  }
`;

type FlexProps = {
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "start" | "end";
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  grow?: boolean;
  wrap?: boolean;
  marginHorizontal?: number | string;
  paddingHorizontal?: number | string;
  marginVertical?: number | string;
  paddingVertical?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  paddingBottom?: number | string;
  marginTop?: number | string;
  paddingTop?: number | string;
  gridGap?: number | string;
  columnGap?: number | string;
  rowGap?: number | string;
  height?: number | string;
  width?: number | string;
};

type FlexRowProps = {
  wrap: FlexProps["flexWrap"];
} & FlexProps;

export default Flex;
