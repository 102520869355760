import * as yup from "yup";

import { Gender } from "generated/types";

export interface Schema {
  fullName: string | null;
  gender: Gender | null;
  email: string | null;
  birthDate: string | null;
}

export const schema = yup.object().shape({
  fullName: yup.string().required("Name is required"),
  gender: yup.string().nullable().required("Required"),
  email: yup.string().nullable().email("Email invalid").required("Required"),
  birthDate: yup.string().required("Required"),
});
