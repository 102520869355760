import { gql } from "@apollo/client";

export const GET_PAPA_REQUESTS = gql`
  query getPapaRequests(
    $filter: [ServiceRequestFilter]
    $pagination: PaginationInput
    $sorting: ServiceRequestSorting
  ) {
    serviceRequests(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        openedAt
        closedAt
        origin
        type {
          data {
            id
            name
          }
        }
        priority
        status
        closedReason
        need {
          data {
            id
            code
            name
          }
        }
        needText
        description
        account {
          data {
            id
            fullName
          }
        }
        assignedTo {
          data {
            id
            fullName
          }
        }
        papa {
          data {
            id
            fullName
            dataVisibility {
              concealed
            }
          }
        }
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
`;
