import { ChevronLeft, ChevronRight } from "@styled-icons/fa-solid";
import { StyledIconBase } from "@styled-icons/styled-icon";
import queryString from "query-string";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Pagination } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";

type Props = {
  pagination?: Pagination | null;
  loading: boolean;
};

const PaginationCursor = ({ pagination, loading }: Props) => {
  const urlQuery = useUrlQuery();
  const location = useLocation();
  const history = useHistory();

  const setCurrentPage = (afterCursor?: string | null, beforeCursor?: string | null) => {
    let urlQueryString = "";

    if (afterCursor) {
      urlQueryString = queryString.stringify(
        { ...urlQuery, afterCursor, beforeCursor: undefined },
        { arrayFormat: "index" }
      );
    } else if (beforeCursor) {
      urlQueryString = queryString.stringify(
        { ...urlQuery, beforeCursor, afterCursor: undefined },
        { arrayFormat: "index" }
      );
    } else {
      urlQueryString = queryString.stringify(
        {
          ...urlQuery,
          afterCursor: undefined,
          beforeCursor: undefined,
        },
        { arrayFormat: "index" }
      );
    }

    history.push(location.pathname + "?" + urlQueryString);
  };

  const handleClickPrev = () => {
    setCurrentPage(undefined, pagination?.beforeCursor);
    window.scrollTo(0, 0);
  };

  const handleClickNext = () => {
    setCurrentPage(pagination?.afterCursor);
    window.scrollTo(0, 0);
  };

  return (
    <Wrapper role="navigation">
      <SideButton
        title="first page"
        onClick={() => setCurrentPage()}
        disabled={!pagination?.beforeCursor || loading}
      >
        First Page
      </SideButton>
      <SideButton
        title="previous page"
        onClick={handleClickPrev}
        disabled={!pagination?.beforeCursor || loading}
      >
        <ChevronLeft size={18} />
        Prev
      </SideButton>
      <SideButton
        title="next page"
        onClick={handleClickNext}
        disabled={!pagination?.afterCursor || loading}
      >
        Next
        <ChevronRight size={18} />
      </SideButton>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

const PaginationButton = styled(Button).attrs({
  type: "button",
})`
  width: auto;
  min-width: 1.625rem;
  padding: 0.125rem 0.375rem;
  border-width: 2px;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  text-align: center;

  ${StyledIconBase} {
    color: ${({ theme }) => theme.variants.primary};
  }

  &:disabled {
    opacity: 0.4;

    &:hover {
      ${StyledIconBase} {
        color: currentColor;
      }
    }
  }

  &:hover {
    ${StyledIconBase} {
      color: white;
    }
  }
`;

const SideButton = styled(PaginationButton)`
  border-color: transparent;
`;

export default PaginationCursor;
