import { Placement, auto } from "@popperjs/core";
import React, { cloneElement, useRef, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";

import Text from "components/Text";

type Props = {
  title?: string | null;
  content?: React.ReactNode;
  placement: Placement;
  isPrimary: boolean;
  children: JSX.Element;
  isDarkMode?: boolean;
  width?: string;
  ariaLabel?: string;
};

const Tooltip = ({
  title,
  content,
  placement,
  isPrimary,
  children,
  isDarkMode,
  width,
  ariaLabel,
}: Props) => {
  const targetRef = useRef(null);
  const popperRef = useRef(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const [isVisible, setVisibility] = useState(false);

  const { styles, attributes } = usePopper(targetRef.current, popperRef.current, {
    placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowRef,
          padding: 5,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const triggerProps = {
    ref: targetRef,
    onMouseOver: () => setVisibility(true),
    onMouseOut: () => setVisibility(false),
  };

  if (!title && !content) return children;

  return (
    <span aria-label={ariaLabel}>
      {cloneElement(children, triggerProps)}
      {isVisible ? (
        <PopperContainer
          ref={popperRef}
          isPrimary={isPrimary}
          isDarkMode={isDarkMode}
          width={width}
          style={styles.popper}
          {...attributes.popper}
        >
          <Arrow ref={setArrowRef} style={styles.arrow} />

          {!!title && <PopperContentText>{title}</PopperContentText>}
          {!!content && <PopperContent>{content}</PopperContent>}
        </PopperContainer>
      ) : null}
    </span>
  );
};

Tooltip.defaultProps = {
  placement: "top",
  isPrimary: false,
};

const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    border: 1px solid #ced0da;
  }

  &[data-hide] {
    visibility: hidden;
  }
`;

const PopperContentText = styled(Text)`
  font-size: 0.875rem;
  text-align: center;
`;

const PopperContent = styled.div`
  font-size: 0.875rem;
`;

type PopperContainerProps = {
  isPrimary?: boolean;
  isDarkMode?: boolean;
  width?: string;
};

const PopperContainer = styled.div<PopperContainerProps>`
  border-radius: 5px;
  padding: 0.438rem 1.25rem;
  border: 1px solid #ced0da;
  background-color: ${({ isDarkMode, isPrimary, theme }) =>
    isDarkMode ? "#354052" : isPrimary ? theme.variants.primary : "white"};
  border-color: ${({ isPrimary, theme }) => (isPrimary ? theme.variants.primary : "#ced0da")};
  z-index: 1;
  width: ${({ width }) => (width ? width : auto)};

  ${PopperContent} {
    color: ${({ isDarkMode, isPrimary, theme }) => (isDarkMode || isPrimary ? "white" : "inherit")};
  }

  ${Arrow} {
    :after {
      background-color: ${({ isDarkMode, isPrimary, theme }) =>
        isDarkMode ? "#354052" : isPrimary ? theme.variants.primary : "white"};
      border-color: ${({ isPrimary, theme }) => (isPrimary ? theme.variants.primary : "#ced0da")};
    }
  }

  &[data-popper-placement^="bottom"] > ${Arrow} {
    bottom: 100%;
    :after {
      top: 0.188rem;
      border-bottom-width: 0;
      border-right-width: 0;
    }
  }
  &[data-popper-placement^="top"] > ${Arrow} {
    top: 100%;
    :after {
      top: -0.313rem;
      border-top-width: 0;
      border-left-width: 0;
    }
  }
  &[data-popper-placement^="right"] > ${Arrow} {
    right: 100%;
    :after {
      left: 0.25rem;
      border-top-width: 0;
      border-right-width: 0;
    }
  }
  &[data-popper-placement^="left"] > ${Arrow} {
    left: 100%;
    :after {
      left: -0.313rem;
      border-left-width: 0;
      border-bottom-width: 0;
    }
  }
`;

export default Tooltip;
