import { ConciergeBell } from "@styled-icons/fa-solid/ConciergeBell";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";

import ClosedFollowUps from "./ClosedFollowUps";
import OpenedFollowUps from "./OpenFollowUps";

const MyFollowUps = () => {
  useTopBarOptions({
    title: "My Follow Ups",
    icon: ConciergeBell,
    breadcrumbs: ["Care Concierge"],
  });

  return (
    <div>
      <Helmet>
        <title>Service Requests - Papa Admin</title>
      </Helmet>
      <br />
      <OpenedFollowUps />
      <br />
      <ClosedFollowUps />
    </div>
  );
};

export const Arrow = styled.div`
  margin-right: 5px;
`;

export default MyFollowUps;
