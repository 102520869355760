import React, { forwardRef } from "react";
import styled from "styled-components";

import Portal from "components/Portal";
import mergeRefs from "utils/mergeRefs";

export type MenuProps = {
  children: React.ReactNode;
  readonly isOpen?: boolean;
  readonly arrow?: React.SetStateAction<null>;
  readonly styles?: React.CSSProperties;
  readonly attributes?: object;
  readonly handleToggle?: () => void;
  readonly menuRef?: React.MutableRefObject<HTMLDivElement>;
};

type ChildProps = {
  children?: React.ReactNode;
  readonly handleToggle: () => void;
};

const StyledMenu = styled("div")<MenuProps>`
  background: white;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  z-index: 1000;
`;

const DropdownMenu = forwardRef<HTMLDivElement, MenuProps>(
  ({ children, isOpen, styles, attributes, menuRef, handleToggle, ...rest }: MenuProps, ref) => {
    if (!isOpen) return null;
    return (
      <Portal>
        <StyledMenu ref={mergeRefs([menuRef, ref])} style={styles} {...attributes} {...rest}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement<ChildProps>(child)) {
              const propsToPass = {
                ...{
                  handleToggle,
                },
              };

              return React.cloneElement(child, propsToPass);
            }
          })}
        </StyledMenu>
      </Portal>
    );
  }
);

DropdownMenu.displayName = "menu";

export default DropdownMenu;
