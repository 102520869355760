import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { isUuid } from "utils/isUuid";

interface Item {
  label: string;
  to?: string;
}

interface Props {
  items?: Item[];
}

const Breadcrumbs = ({ items }: Props) => {
  const { pathname } = useLocation();
  const subpaths = pathname.split("/").splice(1);

  const breadcrumbItems =
    items ||
    subpaths.map((subpath, index) => {
      const to = "/" + subpaths.slice(0, index + 1).join("/");
      const label = isUuid(subpath) ? subpath : makeTitle(subpath);

      return { label, to };
    });

  return (
    <BreadcrumbsContainer>
      {breadcrumbItems.map(({ label, to }, index) => {
        const displayLabel = isUuid(label) ? label.substring(0, 6) : label;

        return (
          <BreadcrumbItemContainer key={index}>
            {to ? <StyledLink to={to}>{displayLabel}</StyledLink> : displayLabel}
          </BreadcrumbItemContainer>
        );
      })}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;

const makeTitle = (slug: string) => {
  const words = slug.split("-");

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
};

const StyledLink = styled(Link)``;

const BreadcrumbItemContainer = styled.div`
  white-space: nowrap;
`;

const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 24px;
  background: ${({ theme }) => theme.background.panelContent};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;

  ${BreadcrumbItemContainer}:not(:first-child)::before {
    padding: 0 10px;
    content: "/";
    color: ${({ theme }) => theme.variants.secondary};
  }

  ${BreadcrumbItemContainer} {
    ${StyledLink}, & {
      color: ${({ theme }) => theme.variants.secondary};
    }
  }

  ${BreadcrumbItemContainer}:last-child {
    ${StyledLink}, & {
      text-decoration: none;
    }
  }
`;
