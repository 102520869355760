import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";

import PerformanceReportForm from "./PerformanceReportForm";

interface Props {
  toggle: () => void;
  isOpen: boolean;
}

const PerformanceReportModal: React.FC<Props> = ({ toggle, isOpen }) => {
  return (
    <>
      <ModalLayout toggle={toggle} title="Papa performance report" isOpen={isOpen} width={750}>
        <PerformanceReportForm toggle={toggle} />
      </ModalLayout>
    </>
  );
};

export default PerformanceReportModal;
