import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";

import Form from "components/Form";

import { Schema } from "./EditDetails.schema";

export const SpecialConsiderations = () => {
  const { values, setFieldValue } = useFormikContext<Schema>();
  const hasPets = values.hasPets;

  useEffect(() => {
    if (!hasPets) {
      setFieldValue("hasCat", values.hasCat != null ? false : null);
      setFieldValue("hasDog", values.hasDog != null ? false : null);
      setFieldValue("hasOtherPet", values.hasOtherPet != null ? false : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPets]);

  return (
    <Form.Group>
      <Form.Label>Special Considerations</Form.Label>

      <RadioYesNo name="hasPets" label="Has pets" />
      {values.hasPets && (
        <>
          <RadioYesNo indented name="hasCat" label="Cat" />
          <RadioYesNo indented name="hasDog" label="Dog" />
          <RadioYesNo indented name="hasOtherPet" label="Other" />
        </>
      )}
      <RadioYesNo name="usesWheelchair" label="Uses wheelchair" />
      <RadioYesNo name="smokes" label="Smokes" />
      <RadioYesNo name="hearingImpaired" label="Hearing impaired" />
      <RadioYesNo name="visuallyImpaired" label="Visually impaired" />
      <RadioYesNo name="cantDrive" label="Can't drive" />

      {/* "old" special condierations */}
      <RadioYesNo name="dementia" label="Dementia" />
      <RadioYesNo name="seizure" label="Seizure" />
      <RadioYesNo name="noPhone" label="No phone" />
      <RadioYesNo name="requiresStrongPal" label="Requires strong pal" />
    </Form.Group>
  );
};

const RadioYesNo = ({
  name,
  label,
  required,
  indented,
}: {
  name: string;
  label: string;
  required?: boolean;
  indented?: boolean;
}) => {
  return (
    <RadioYesNoContainer>
      <RadioLabel required={required} indented={indented}>
        {label}
      </RadioLabel>
      <Form.Radio name={name} label="Yes" controlId={`${name}-true`} value={true} />
      <Form.Radio name={name} label="No" controlId={`${name}-false`} value={false} />
    </RadioYesNoContainer>
  );
};

const RadioLabel = styled(Form.Label)<{ indented?: boolean }>`
  width: 180px;

  padding-left: ${({ indented }) => (indented ? "20px" : "0")};
`;

const RadioYesNoContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 5px;
`;
