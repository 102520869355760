import { gql } from "@apollo/client";

export const PERFORMANCE_REPORT = gql`
  mutation exportPapaPerformanceReport($filter: [PapaPerformanceReportFilter!]) {
    exportPapaPerformanceReport(filter: $filter) {
      data {
        success
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query GetPapasAccounts($filter: [AccountFilter]) {
    accounts(filter: $filter) {
      data {
        id
        fullName
      }
    }
  }
`;
