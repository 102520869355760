import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React from "react";
import Helmet from "react-helmet";

import Button from "components/Button";
import PalOnboardingsTable from "components/DataTables/PalOnboardingsTable";
import { usePalOnboardingSorting } from "components/DataTables/PalOnboardingsTable/usePalOnboardingSorting";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { ModalLayout } from "components/Modal/ModalLayout";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { ProspectPalFilterModal } from "modals/ProspectPalFilterModal";

import { PAL_ONBOARDINGS } from "../gql";
import { usePalOnboardingSearch } from "./usePalOnboardingSearch";

const PalOnboardings = () => {
  useTopBarOptions({ title: "Prospect Pals", icon: AccountCircle, breadcrumbs: ["Accounts"] });
  const { limit, beforeCursor, afterCursor, vehicleInsuranceStatus } = usePageInfo();
  const { filter, pills, setPills } = useSearch();
  const [sorting, setSorting] = usePalOnboardingSorting();
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const palOnboardingFilter = vehicleInsuranceStatus
    ? filter
      ? { ...filter, vehicleInsuranceStatus: { statusEq: vehicleInsuranceStatus } }
      : { vehicleInsuranceStatus: { statusEq: vehicleInsuranceStatus } }
    : filter
    ? filter
    : undefined;
  const { data, loading, error } = useQuery(PAL_ONBOARDINGS, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter: palOnboardingFilter,
      sorting,
    },
  });
  const palOnboardings = data?.palOnboardings;
  const { loadOptions, searchPillNames } = usePalOnboardingSearch(palOnboardings?.data);
  const handleFiltersChange = () => {
    toggleFiltersModal();
  };

  return (
    <>
      <Helmet>
        <title>Prospect Pals - Papa Admin</title>
      </Helmet>

      <FlexRow>
        <Search
          pills={pills}
          setPills={setPills}
          loadOptions={loadOptions}
          placeholder="Search by ID, Name, Phone, Email"
          extraPillpadding={true}
        />
        <Button type="button" onClick={toggleFiltersModal} style={{ marginLeft: "1.25rem" }}>
          <span>Filter</span>
          {vehicleInsuranceStatus && <span>(1)</span>}
        </Button>
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />

      <PalOnboardingsTable
        loading={loading}
        data={palOnboardings?.data ?? []}
        error={error}
        pagination={palOnboardings?.pagination}
        sorting={sorting}
        onSortChange={setSorting}
        cursorPagination
      />
      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Filter"
        isOpen={isFiltersModalOpen}
      >
        <ProspectPalFilterModal onSubmit={handleFiltersChange} />
      </ModalLayout>
    </>
  );
};

export default PalOnboardings;
