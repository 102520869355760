import React from "react";
import { Props, default as ReactSelect } from "react-select";
import { CSSProperties } from "styled-components";

import Feedback from "components/Form/FormFeedback";

import { Wrapper } from "./SelectWrapper";

export type OptionType = {
  label: string;
  value: any;
} & { [key: string]: any };
export type OptionsType = Array<OptionType>;

export type GroupType = {
  label: string;
  options: OptionsType;
};

type SelectProps = {
  name: string;
  small?: boolean;
  autoWidth?: boolean;
  options: OptionsType | GroupType[];
  onChange?: (value: any) => void;
  trackSearch?: (keyword: string) => void;
  trackSelected?: (keyword: string, value: any) => void;
  isClearable?: boolean;
  defaultValue?: any | null | undefined;
  error?: string;
  ["aria-labelledby"]?: string;
} & Props;

// Here we are providing a similar implementation of the Selection component that doesn't
// use Formik.
// It is more or less a duplicate of `src/components/Select/index.tsx` with Formik stripped out.
const SimpleSelect = ({
  name,
  small,
  autoWidth,
  isSearchable = true,
  onChange,
  trackSearch,
  trackSelected,
  isDisabled,
  isClearable,
  defaultValue,
  error,
  ...otherProps
}: SelectProps) => {
  return (
    <Wrapper small={small} autoWidth={autoWidth} isInvalid={!!error}>
      <ReactSelect
        placeholder="Select"
        // @ts-ignore TODO:remove this
        styles={{ ...customStyles, ...otherProps.customStyles }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        onChange={onChange}
        isDisabled={isDisabled}
        value={defaultValue}
        className="select"
        classNamePrefix="select"
        {...otherProps}
        name={name}
      />
      <Feedback isInvalid>{error}</Feedback>
    </Wrapper>
  );
};

export const customStyles = {
  container: (provided: CSSProperties, state: any) => ({
    ...provided,
    background: "white",
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocused ? "4px 4px 0 0" : 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
    maxWidth: "18.75rem",
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    borderColor: "#DFE3E9 !important",
    boxShadow: "none !important",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 0,
    minHeight: 36,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    color: "#CED0DA !important",
  }),
  menu: (provided: CSSProperties, state: any) => ({
    ...provided,
    color: state.selectProps.menuColor,
    boxShadow: "0 0 0 1px #DFE3E9",
    borderRadius: "0 0 4px 4px",
    margin: 0,
    padding: 0,
  }),
  menuList: (provided: CSSProperties, state?: any) => ({
    maxHeight: state.maxHeight || "none",
    padding: 0,
    overflow: "auto",
  }),
  option: (provided: CSSProperties, state: any) => ({
    ...provided,
    color: state.isSelected && "#2E5BFF",
    background: state.isSelected ? "#EAEFFF" : "white",
    boxShadow: "0 -1px 0 #DFE3E9",
  }),
};

export default SimpleSelect;
