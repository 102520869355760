import OktaSignIn from "@okta/okta-signin-widget";
import React, { useEffect } from "react";

import loginImage from "assets/images/login-image.png";
import oktaLogo from "assets/images/okta-logo.png";
import papaLogo from "assets/images/papa-logo.png";
import Text from "components/Text";
import config, { OktaConfig } from "config";

import LoginForm from "./LoginForm";
import {
  Container,
  ImageContainer,
  LoginContainer,
  LoginDisclaimer,
  LoginImage,
  LoginWidget,
  PapaLogo,
} from "./login.style";

const Login = () => {
  const { pkce, issuer, clientId, redirectUri, scopes } = OktaConfig;

  const signIn = new OktaSignIn({
    baseUrl: issuer?.split("/oauth2")[0],
    clientId,
    redirectUri,
    logo: oktaLogo,
    i18n: {
      en: {
        "primaryauth.title": "Sign In",
      },
    },
    authParams: {
      pkce,
      issuer,
      display: "page",
      scopes,
    },
    useClassicEngine: true,
  });

  useEffect(() => {
    signIn.showSignInAndRedirect(
      { el: "#sign-in-widget" },
      () => {},
      (err: any) => {
        throw err;
      }
    );

    return () => {
      signIn.remove();
    };
  });

  return (
    <Container>
      <ImageContainer>
        <LoginImage src={loginImage} alt="Login image" />
      </ImageContainer>
      <LoginContainer>
        <PapaLogo src={papaLogo} alt="Logo" />
        {config.featureFlag.disableOkta ? <LoginForm /> : <LoginWidget id="sign-in-widget" />}
        <LoginDisclaimer>
          <Text size="small">
            This system is the property of Papa Inc. Unauthorized access is prohibited and may be
            subject to criminal prosecution or employee discipline. By continuing, you consent to
            the following terms: Authorized personnel may monitor any activity or communication on
            the system. By accessing and using this system, you are consenting to such monitoring
            and information retrieval for law enforcement and other purposes. Users should not
            expect privacy as to any communication on the system. We reserve the right to suspend
            your system's use if we reasonably believe that your use of the system is unreasonably
            excessive, or if you are using the system for any prohibited activities.
          </Text>
        </LoginDisclaimer>
      </LoginContainer>
    </Container>
  );
};

export default Login;
