import { gql } from "@apollo/client";

export const PAL_CONDUCT = gql`
  query palConduct($filter: [PalFilter], $sorting: PalSorting, $pagination: PaginationInput) {
    pals(filter: $filter, sorting: $sorting, pagination: $pagination) {
      data {
        id
        fullName
        complaintPoints
        lifetimeComplaintPoints
        status
        palComplaints(sorting: { insertedAt: DESC }, pagination: { limit: 1 }) {
          data {
            id
            insertedAt
            initialPoints
            remainingPoints
          }
        }
        email
        phoneNumber
      }
      pagination {
        afterCursor
        beforeCursor
        limit
      }
    }
  }
`;
