import { PriorityBonusReason } from "generated/types";

export const priorityBonusReasonLabels: {
  [key in PriorityBonusReason]: string;
} = {
  [PriorityBonusReason.Cancelled]: "Cancelled",
  [PriorityBonusReason.CityLevelBonus]: "City Level Bonus",
  [PriorityBonusReason.SpecialRequest]: "Client Success Request",
  [PriorityBonusReason.DoctorsAppt]: "Doctors Appointment",
  [PriorityBonusReason.Escalations]: "Escalations",
  [PriorityBonusReason.Handicapped]: "Handicapped",
  [PriorityBonusReason.HardToFill]: "Hard To Fill",
  [PriorityBonusReason.LastMinuteDrop]: "Last Minute Drop",
  [PriorityBonusReason.LimitedPals]: "Limited Pals",
  [PriorityBonusReason.PriorityClient]: "Priority Client",
  [PriorityBonusReason.RemoteArea]: "Remote Area (100+ miles)",
  [PriorityBonusReason.SupervisorOnly]: "Special Request (Supervisor Only)",
  [PriorityBonusReason.Transportation]: "Transportation",
  [PriorityBonusReason.VipMember]: "VIP Member",
  [PriorityBonusReason.NoThirdPartyTransportation]: "No Third Party Transportation",
  [PriorityBonusReason.StillPending]: "Still Pending",
};

export const typePriorityBonusOptions = [
  { value: PriorityBonusReason.SpecialRequest, label: "Client Success Request" },
  { value: PriorityBonusReason.DoctorsAppt, label: "Doctors Appointment" },
  { value: PriorityBonusReason.Escalations, label: "Escalations" },
  { value: PriorityBonusReason.Handicapped, label: "Handicapped" },
  { value: PriorityBonusReason.HardToFill, label: "Hard To Fill" },
  { value: PriorityBonusReason.LastMinuteDrop, label: "Last Minute Drop" },
  { value: PriorityBonusReason.LimitedPals, label: "Limited Pals" },
  { value: PriorityBonusReason.PriorityClient, label: "Priority Client" },
  { value: PriorityBonusReason.RemoteArea, label: "Remote Area (100+ miles)" },
  { value: PriorityBonusReason.Transportation, label: "Transportation" },
  { value: PriorityBonusReason.VipMember, label: "VIP Member" },
  { value: PriorityBonusReason.NoThirdPartyTransportation, label: "No Third Party Transportation" },
  { value: PriorityBonusReason.StillPending, label: "Still Pending" },
];

export const typePriorityBonusOverrideOptions = [
  { value: PriorityBonusReason.SupervisorOnly, label: "Special Request (Supervisor Only)" },
];
