import { gql } from "@apollo/client";

export const GET_OBJECTIVES = gql`
  query VisitObjectiveTemplates(
    $filter: [VisitObjectiveTemplateFilter]
    $pagination: PaginationInput
    $sorting: VisitObjectiveTemplateSorting
  ) {
    visitObjectiveTemplates(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        description
        insertedAt
        updatedAt
        deletedAt
        isActive
        task {
          data {
            id
            name
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
