import { ApolloError } from "@apollo/client";
import React from "react";
import styled from "styled-components";

import { PalWithVisitMetadataResult } from "generated/types";

import { PreferredPalCompletedVisitRatings } from "./PreferredPalCompletedVisits";
import { RecentPalCompletedVisitRatings } from "./RecentPalCompletedVisitRatings";

export const PreferredAndRecentPalTable = ({
  onPreferredPalUpdate,
  assignablePals,
  assignablePalsLoading,
  assignablePalsError,
}: {
  onPreferredPalUpdate: () => void;
  assignablePals: PalWithVisitMetadataResult[];
  assignablePalsLoading: boolean;
  assignablePalsError: ApolloError | undefined;
}) => {
  const preferredPalData = assignablePals?.filter(({ isFavorite }) => isFavorite);
  const recentPalData = assignablePals?.filter(({ isFavorite }) => !isFavorite);
  const favoritePalIds = preferredPalData.map(({ id }) => id!);
  const promotablePalsExist = recentPalData.length > 0;

  return (
    <div data-testid="preferredAndRecentPalTable">
      <StyledHeader>Preferred Pals</StyledHeader>
      <PreferredPalCompletedVisitRatings
        data={preferredPalData}
        loading={assignablePalsLoading}
        error={assignablePalsError}
        onRemovePreferredPal={onPreferredPalUpdate}
        favoritePalIds={favoritePalIds}
        promotablePalsExist={promotablePalsExist}
        nameColumnWidth="22em"
      />
      <StyledHeader>Recent Pals</StyledHeader>
      <RecentPalCompletedVisitRatings
        data={recentPalData}
        loading={assignablePalsLoading}
        error={assignablePalsError}
        onMakePreferredPal={onPreferredPalUpdate}
        favoritePalIds={favoritePalIds}
        nameColumnWidth="22em"
      />
    </div>
  );
};

const StyledHeader = styled.header`
  background: ${({ theme }) => theme.background.panelHeaderActivated};
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text.panelHeader};
`;
