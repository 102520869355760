import * as yup from "yup";

import { VisitDiscountType } from "generated/types";

export interface Schema {
  startedAtDay: string;
  startedAtTime: string;
  completedAtDay: string;
  completedAtTime: string;
  commuteDistance: number | null;
  visitDistance: number | null;
  overrideDurationMins?: number | null;
  discount: {
    type: VisitDiscountType | null;
    value: number | null;
  };
  objectives: { [key: string]: boolean };
  taskIds: string[];
  delivery?: boolean | null;
  successful?: boolean | null;
}

export const schema = yup.object().shape({
  startedAtDay: yup.string().required("Required"),
  startedAtTime: yup.string().required("Required").nullable(),
  completedAtDay: yup.string(),
  completedAtTime: yup.string(),
  commuteDistance: yup.number().nullable(),
  visitDistance: yup.number().nullable(),
  overrideDurationMins: yup.number().nullable(),
  taskIds: yup.array().of(yup.string()).required("Please select at least one task"),
  discount: yup.object().shape({
    type: yup.string().nullable(),
    value: yup.number().nullable(),
  }),
  delivery: yup.boolean().nullable(),
  successful: yup.boolean(),
});
