import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";

import SetUpVirtualCallContent from "./SetUpVirtualCallContent";

interface Props {
  papaId?: string;
  isOpen: boolean;
  toggle: () => void;
}

const ModalBase: React.FC<Props> = ({ papaId, isOpen, toggle }) => {
  if (!papaId) return null;

  return (
    <ModalLayout toggle={toggle} title="Start virtual visit" isOpen={isOpen}>
      <SetUpVirtualCallContent papaId={papaId} onClose={toggle} />
    </ModalLayout>
  );
};

export default ModalBase;
