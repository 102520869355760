import { useMutation } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";

import BioForm from "components/BioForm/BioForm";
import { PapaBioQuestions } from "components/BioForm/papa-bio-questions";
import Button from "components/Button";
import CardElementWithToggle from "components/CreditCard/CardElementWithToggle";
import { useCreateCreditCard } from "components/CreditCardSelect/useCreateCreditCard";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import config from "config";
import { FeatureFlags } from "constants/featureFlags";
import { Gender } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";
import { formatFullName } from "utils/strings/formatFullName";

import BasePapaForm from "./BasePapaForm";
import PreferencesForm from "./PreferencesForm";
import { CREATE_PAPA } from "./gql";
import { initialValues } from "./initialValues";
import { schema } from "./schema";

interface Props {
  accountId?: string | null;
  businessId?: string | null;
  closeModal: () => void;
  onCreateFinish: () => void;
}

const CreatePapaForm: React.FC<Props> = ({ accountId, businessId, closeModal, onCreateFinish }) => {
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const [createPapa] = useMutation(CREATE_PAPA);
  const {
    createCreditCard,
    saveCreditCard,
    data: cardResult,
    error: cardError,
  } = useCreateCreditCard();

  const { papaName, papaBirthday, papaEmail, papaGender, papaGenderText } = useUrlQuery() as {
    papaName: string;
    papaBirthday: string;
    papaEmail: string;
    papaGender: string;
    papaGenderText: string;
  };

  const handleSubmit = async (formData: any) => {
    const { error, tokenId, card, cardElement } = await createCreditCard();

    if (cardElement && (error || !tokenId || !card)) {
      // @ts-ignore
      toast.error((error as Error)?.message || "Unable to create a card");

      return;
    }

    const { answers, memberId, genderPreference, ...input } = formData;

    // genderText is for when Gender.Diverse is selected
    if ([Gender.Male, Gender.Female].includes(input?.gender!)) {
      input.genderText = null;
    }

    input.biographyQuestions = answers.map((answer: string, index: number) => ({
      question: PapaBioQuestions[index],
      answer,
    }));

    const birthDate =
      input.birthDate && moment(input.birthDate).isValid()
        ? moment(input.birthDate).format("YYYY-MM-DD")
        : null;

    if (!config.featureFlag.papaCreditCard) {
      input.stripeToken = tokenId;
    }

    try {
      const { data } = await createPapa({
        variables: {
          input: {
            ...input,
            birthDate,
            memberId: memberId.trim(),
            fullName: formatFullName(input.fullName),
            ...(businessId ? { businessId } : {}),
            genderPreference: genderPreference === Gender.Diverse ? null : genderPreference,
          },
        },
      });

      const papa = data.createPapa?.data;

      if (papa?.id) {
        onCreateFinish();
        toast.success("Papa was created with success!");
      } else {
        throw new Error("Something is wrong");
      }

      if (cardElement && config.featureFlag.papaCreditCard && !hideCreditCardFields) {
        await saveCreditCard({ papaId: papa.id }, { tokenId, card });
      }

      closeModal();

      if (cardResult?.data?.id) {
        toast.success("Credit card was saved with success!");
      } else if (cardError) {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);

      if (cardError) {
        closeModal();
      }
    }
  };

  const prefilledInitialValues = {
    ...initialValues,
    fullName: papaName ?? "",
    email: papaEmail ?? "",
    birthDate: papaBirthday ?? null,
    gender: papaGender ?? null,
    genderText: papaGenderText ?? null,
    accountId: accountId ? accountId : null,
  };

  return (
    <Formik
      initialValues={prefilledInitialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <ModalStyledForm>
        <ModalContainer>
          <BasePapaForm />

          <BioForm />

          <Form.Group>
            <Form.Label>About</Form.Label>
            <Form.TextArea name="description" fullWidth />
          </Form.Group>

          <PreferencesForm />

          <Form.Fieldset legend="Settings">
            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Still Drives</Form.Label>
                  <Form.Switch name="stillDrives" aria-label="stillDrives" />
                </Form.Group>
              </Form.Col>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Covid-19 Vaccinated</Form.Label>
                  <Form.Switch name="covidVaccinated" aria-label="covidVaccinated" />
                </Form.Group>
              </Form.Col>
            </Form.Row>
          </Form.Fieldset>
          {hideCreditCardFields ? null : (
            <Form.Fieldset legend="Credit Card">
              <CardElementWithToggle />
            </Form.Fieldset>
          )}
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default CreatePapaForm;
