import * as bootstrap from "styled-icons/bootstrap";
import * as evaiconsSolid from "styled-icons/evaicons-solid";
import * as faSolid from "styled-icons/fa-solid";
import * as remixLine from "styled-icons/remix-line";

type iconsDictType = {
  [key: string]: any;
};

export const virtualVisitTasks = ["Companionship", "Tech Help", "Reminders"];

export const iconsDict: iconsDictType = {
  childcare: faSolid.Baby,
  companionship: bootstrap.HeartFill,
  doctor: faSolid.BriefcaseMedical,
  errands: evaiconsSolid.ShoppingCart,
  exercise: faSolid.Biking,
  grocery: faSolid.Carrot,
  houseHelp: faSolid.Home,
  medication: remixLine.Capsule,
  petHelp: faSolid.Paw,
  reminders: bootstrap.CalendarEventFill,
  technology: faSolid.MobileAlt,
  transportation: faSolid.Car,
};

type colorsType = {
  [key: string]: string;
};

export const colors: colorsType = {
  companionship: "#fbe0e3",
  doctor: "#fbe0e3",
  errands: "#fbf6d4",
  exercise: "#d2fffc",
  grocery: "#fed1fd",
  houseHelp: "#e2dafd",
  petHelp: "#e6f9d3",
  reminders: "#fed1fd",
  technology: "#feedde",
  transportation: "#d7eefc",
};
