import { gql } from "@apollo/client";

export const CREATE_LOCATION = gql`
  mutation createPalLocation($input: LocationInput!) {
    createLocation(input: $input) {
      data {
        id
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($input: AccountInput!) {
    createAccount(input: $input) {
      data {
        id
      }
    }
  }
`;

export const CREATE_PAL = gql`
  mutation createPal($input: PalInput!) {
    createPal(input: $input) {
      data {
        id
      }
    }
  }
`;

export const ASSIGN_ROLE = gql`
  mutation assignRole($accountId: ID!, $roleId: ID!) {
    assignRole(accountId: $accountId, roleId: $roleId) {
      data {
        id
      }
    }
  }
`;

export const GET_ROLES = gql`
  query roles {
    roles {
      data {
        id
        name
      }
    }
  }
`;
