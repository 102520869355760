import { ErrorWarning } from "@styled-icons/remix-line/ErrorWarning";
import React from "react";
import styled from "styled-components";

import ExternalLink from "components/ExternalLink/index";
import Panel from "components/Panel/index";
import Text from "components/Text/index";
import { Business, Papa } from "generated/types";

export interface Props {
  papaBusiness?: Pick<Business, "name" | "type"> | null;
  caregiverBusiness?: Pick<Business, "name" | "type"> | null;
  riskScore: Papa["riskScore"] | null;
}

export const AssessmentInstructions: React.FC<Props> = ({
  papaBusiness,
  caregiverBusiness,
  riskScore,
}) => {
  const displayBusiness = papaBusiness || caregiverBusiness;

  if (!riskScore) {
    return null;
  }

  return (
    <StyledPanel>
      <StyledWarningCircle size={20} />
      <StyledText inline as="div">
        This member has{" "}
        <Text inline bold>
          {riskScore}
        </Text>{" "}
        needs. Follow the {riskScore} needs scheduling recommendations in{" "}
        <ExternalLink to="https://papa.livepro.com/" text="Papapedia" /> for{" "}
        <Text inline bold>
          {displayBusiness && displayBusiness.name}
        </Text>
        .
      </StyledText>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel.Block)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledWarningCircle = styled(ErrorWarning)`
  color: ${({ theme }) => theme.variants.warning};
  display: inline;
  margin-right: 0.5rem;
`;

const StyledText = styled(Text)`
  vertical-align: middle;
`;
