import { useQuery } from "@apollo/client";
import React, { ReactElement } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import { StatusLogsTable } from "components/DataTables/StatusLogsTable";
import { AccountSingleResult, PalStatusLog } from "generated/types";
import { nonNull } from "utils/nonNull";

import { GET_MEMBER_STATUS_LOG } from "../gql";

const StatusLog = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<{
    account?: AccountSingleResult | null;
  }>(GET_MEMBER_STATUS_LOG, {
    variables: { id },
  });

  const logs = nonNull(data?.account?.data?.pal?.data?.statusLogs?.data);

  return (
    <>
      <Helmet>
        <title>Member Status Logs - Papa Admin</title>
      </Helmet>
      <StatusLogsTable<PalStatusLog>
        logs={logs}
        pagination={null}
        isLoading={loading}
        error={error!}
      />
    </>
  );
};

export default StatusLog;
