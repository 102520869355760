import React from "react";
import styled from "styled-components";

import Button from "components/Button";
import ButtonLink from "components/ButtonLink";
import { evaluationNeeded } from "components/DataTables/PapasTable/evaluationNeeded";
import { FlexRow } from "components/Flex";
import Link from "components/Link";
import Panel from "components/Panel";
import SeeMapLink from "components/SeeMapLink";
import Text from "components/Text";
import { VisitFlags } from "components/VisitFlags";
import { DEFAULT_TIMEZONE } from "constants/date";
import { demandProfileOptions } from "constants/demandProfile";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { visitStatusLabels, visitVisibilityOptions } from "constants/visit";
import { VisitSingleResult, VisitState } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { OverrideLocationButton } from "modals/OverrideLocation/OverrideLocationButton";
import EditCompletedVisit from "pages/ScheduleVisit/EditVisit/EditCompletedVisit";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import formatScheduledFor from "utils/formatScheduledFor";
import { mapValueToLabel } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

type Data = {
  visit: VisitSingleResult;
};

type Props = {
  data?: Data;
  loading: boolean;
};

const EvaluationNeededLink = styled(Link)`
  display: block;
  color: #f8381e !important;
`;

const AlertImage = styled.img`
  width: 22px;
  margin-right: 5px;
`;

const Overview = ({ data, loading }: Props) => {
  const { isOpen, toggle } = useModalToggle();

  if (loading || !data?.visit?.data) return <Panel.SideLoading />;

  const {
    id: visitId,
    account,
    scheduledFor,
    location,
    pal,
    visibility,
    state,
    payments,
    isVirtual,
    isPreviouslyMissed,
    visitFlags,
    visibleToPalApp,
    demandProfile,
  } = data.visit.data;

  const papa = papaConcealed(data.visit.data?.papa?.data);

  const { address, address2, lat, lng, id: locationId } = location?.data ?? {};

  return (
    <Panel.Side>
      <div>
        <Panel.Item>
          <Text bold>Visit ID</Text>
          <Text>{visitId}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Status</Text>
          <Text>
            {!!state && visitStatusLabels[state]}{" "}
            <VisitFlags
              visitFlags={nonNull(visitFlags?.data)}
              isPreviouslyMissed={isPreviouslyMissed}
            />
          </Text>
        </Panel.Item>

        <Panel.Item>
          {visibleToPalApp ? (
            <Text bold> Visible to Pal App</Text>
          ) : (
            <FlexRow>
              <AlertImage src="/images/icons/alert-icon.svg" alt="alert" />
              <Text bold>Invisible to Pal App</Text>
            </FlexRow>
          )}
        </Panel.Item>

        <Panel.Item>
          <Text bold>Member</Text>
          <Text>
            {account?.data?.id ? (
              <Link to={`/members/${account?.data?.id}`}>{account?.data?.fullName}</Link>
            ) : (
              EMPTY_PLACEHOLDER
            )}
          </Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Papa</Text>
          <Text>
            {papa?.id ? <Link to={`/papas/${papa.id}`}>{papa.fullName}</Link> : EMPTY_PLACEHOLDER}
          </Text>
          {papa && evaluationNeeded(papa) && (
            <EvaluationNeededLink to={`/papas/${papa?.id}/assessments`}>
              Evaluation needed
            </EvaluationNeededLink>
          )}
        </Panel.Item>

        {papa?.caregiver?.data?.id && (
          <Panel.Item>
            <Text bold>Caregiver</Text>
            <Text>
              <Link to={`/caregivers/${papa.caregiver.data.id}`}>
                {papa.caregiver.data.fullName}
              </Link>
            </Text>
          </Panel.Item>
        )}

        <Panel.Item>
          <Text bold>Scheduled For</Text>
          <Text>
            {formatScheduledFor(scheduledFor, location?.data?.timezone || DEFAULT_TIMEZONE)}
          </Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Visit Type</Text>
          <Text>{isVirtual ? "Virtual" : "In-person"}</Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Location</Text>
          <Text>{address || EMPTY_PLACEHOLDER}</Text>
          {address2 && <Text>{address2}</Text>}
          <SeeMapLink location={{ lat, lng }} />

          {!!locationId && <OverrideLocationButton locationId={locationId ?? ""} />}
        </Panel.Item>

        <Panel.Item>
          <Text bold>County</Text>
          <Text>
            {location?.data?.county || EMPTY_PLACEHOLDER}
            {demandProfile ? ` (${mapValueToLabel(demandProfileOptions, demandProfile)})` : null}
          </Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Pal</Text>
          <Text>
            {pal?.data?.id ? (
              <Link to={`/pals/${pal?.data?.id}`}>{pal?.data?.fullName}</Link>
            ) : (
              EMPTY_PLACEHOLDER
            )}
          </Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Payment</Text>
          <Text>
            {payments?.data?.[0]?.id ? (
              <Link to={`/payments/members-payments/${payments?.data[0]?.id}`}>
                See payment details
              </Link>
            ) : (
              EMPTY_PLACEHOLDER
            )}
          </Text>
        </Panel.Item>

        <Panel.Item>
          <Text bold>Visibility</Text>
          <Text>
            {visibility ? mapValueToLabel(visitVisibilityOptions, visibility) : EMPTY_PLACEHOLDER}
          </Text>
        </Panel.Item>
      </div>

      {state === VisitState.Completed || state === VisitState.Reviewed ? (
        <Button type="button" onClick={toggle}>
          Edit
        </Button>
      ) : (
        <ButtonLink to={`/edit-visit/${visitId}`} type="button">
          Edit
        </ButtonLink>
      )}

      <EditCompletedVisit visitId={visitId} isOpen={isOpen} toggle={toggle} />
    </Panel.Side>
  );
};

export default Overview;
