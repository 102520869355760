import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { billingPointTypeOptions } from "constants/payment";
import { BillingPoint, PaymentSingleResult } from "generated/types";
import { mapValueToLabel } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { formatNumberToCurrency } from "utils/numbers/currency";

import { GET_PAYMENT_BILLING_POINTS } from "../gql";

interface Data {
  payment?: PaymentSingleResult;
}

const BillingPoints = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<Data>(GET_PAYMENT_BILLING_POINTS, {
    variables: { id: id },
  });

  const billingPoints = nonNull(data?.payment?.data?.billingPoints?.data);
  const columns: Column<BillingPoint>[] = [
    {
      header: { title: "Service" },
      dataField: "type",
      formatter({ type }) {
        return mapValueToLabel(billingPointTypeOptions, type);
      },
    },
    {
      header: { title: "Details" },
      dataField: "explanation",
    },
    {
      header: { title: "Unit" },
      dataField: "unit",
    },
    {
      header: { title: "Unit price ($)" },
      dataField: "unitPrice",
      formatter({ unitPrice }) {
        return formatNumberToCurrency(unitPrice ?? 0);
      },
    },
    {
      header: { title: "Amount" },
      dataField: "amount",
    },
    {
      header: { title: "Total" },
      dataField: "total",
      formatter({ total }) {
        return formatNumberToCurrency(total ?? 0);
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Payment Billing Points - Papa Admin</title>
      </Helmet>

      <SmartTable<BillingPoint>
        keyField="id"
        data={billingPoints}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default BillingPoints;
