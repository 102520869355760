import * as yup from "yup";
import { array, string } from "yup";

import {
  Gender,
  Language,
  LivingSituation,
  PapaRequirement,
  PapaWarning,
  PreferredContactMethod,
  Relationship,
  VehicleType,
} from "generated/types";

export interface Schema {
  accountId: string | null;
  caregiverId: string | null;
  relationship: Relationship | null;
  language: Language | null;
  fullName: string | null;
  email: string | null;
  nickname: string | null;
  birthDate: string | null;
  gender: Gender | null;
  preferredContactMethod: PreferredContactMethod | null;
  fixedPhoneNumber: string | null;
  phoneNumber: string | null;
  warnings: PapaWarning | null;
  requirements: PapaRequirement | null;
  livingSituation: LivingSituation | null;
  answers: string[];
  description: string;
  languagePreference: Language | null;
  vehiclePreference: VehicleType | null;
  favoritePalIds: string[] | null;
  stillDrives: boolean;
  memberId?: string;
  covidVaccinated: boolean;

  genderPreference: Gender | null;
  genderPreferenceIsFlexible: boolean | null;
  genderPreferenceWasAsked: false;
}

export const schema = yup.object().shape({
  accountId: yup.string().required("Member is required").nullable(),
  relationship: yup.string().required("Relationship is required").nullable(),
  language: yup.string().required("The language is required").nullable(),
  fullName: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Email invalid")
    .when("preferredContactMethod", {
      is: PreferredContactMethod.Email,
      then: yup.string().required("Email is required"),
    })
    .nullable(),
  nickname: yup.string(),
  birthDate: yup.string().nullable(),
  gender: yup.string().nullable().required("Gender is required"),
  preferredContactMethod: yup.string().required("Preferred contact method is required").nullable(),
  fixedPhoneNumber: yup
    .string()
    .when("preferredContactMethod", {
      is: PreferredContactMethod.FixedPhoneNumber,
      then: yup.string().required("Home number is required"),
    })
    .nullable(),
  phoneNumber: yup
    .string()
    .when("preferredContactMethod", {
      is: PreferredContactMethod.CellPhoneNumber,
      then: yup.string().required("Mobile number is required"),
    })
    .nullable(),
  warnings: yup.string().nullable(),
  requirements: yup.string().nullable(),
  livingSituation: yup.string().required("Living Situation is required").nullable(),
  answers: array().of(string()),
  description: yup.string(),
  languagePreference: yup.string().required("The language is required").nullable(),
  vehiclePreference: yup.string().nullable(),
  favoritePalIds: array().of(string()).nullable(),
  stillDrives: yup.boolean(),
  memberId: yup.string().nullable(),
  covidVaccinated: yup.boolean(),

  genderPreference: yup.string().nullable(),
  genderPreferenceIsFlexible: yup
    .boolean()
    .nullable()
    .when("genderPreference", {
      is: (genderPreference) => {
        return genderPreference === Gender.Female || genderPreference === Gender.Male;
      },
      then: (schema: yup.BooleanSchema) =>
        schema.required("Please choose yes or no for gender preference flexibility."),
    }),
});
