import React, { useState } from "react";

import Button from "components/Button";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { Survey } from "generated/types";

export const useSurveysTableColumns = () => {
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const columns: Column<Survey>[] = [
    {
      header: { title: "Name" },
      dataField: "name",
      cellProps: { noWrap: true },
    },
    {
      header: { title: "URL" },
      dataField: "url",
      cellProps: { noWrap: true },
      formatter(survey) {
        return (
          <a href={survey.url ?? ""} target="_blank" rel="noopener noreferrer">
            {survey.url}
          </a>
        );
      },
    },
    {
      header: { title: "Active" },
      dataField: "isActive",
      formatter({ isActive }) {
        return isActive ? "Yes" : "No";
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        align: "center",
      },
      formatter(survey) {
        return (
          <Button onClick={() => menuActionsHandler(DataTableMenuActions.Edit, survey)}>
            Edit
          </Button>
        );
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, survey: Survey) => {
    setAction(action);
    setSurvey(survey);
  };

  const addSurvey = () => {
    setAction(DataTableMenuActions.Edit);
    setSurvey(null);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setSurvey(null);
  };

  return {
    columns,
    action,
    survey,
    addSurvey,
    closeModal,
  };
};
