import React, { useState } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

import { theme } from "app/theme";
import ConfirmModal, { ConfirmModalIcon } from "components/Modal/ConfirmModal";

import { mountRootId } from "./config";
import { Portal } from "./portal";

interface Options {
  header?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  noCancel?: boolean;
  icon?: ConfirmModalIcon;
}

export const confirm = async (
  content: string | React.ReactElement,
  options?: Options
): Promise<any> => {
  const mount = await document.getElementById(mountRootId);
  if (!mount) {
    const rootMount = await document.createElement("div");
    await rootMount.setAttribute("id", mountRootId);
    document.body.appendChild(rootMount);
  }

  return new Promise((resolve) => {
    const ConfirmBoxEl = React.createElement(ConfirmModalResolver, {
      resolver: resolve,
      content,
      options,
    });
    const PortalEl = React.createElement(Portal, null, ConfirmBoxEl);
    ReactDOM.render(PortalEl, document.getElementById(mountRootId));
  });
};

const ConfirmModalResolver = ({
  options,
  content,
  resolver,
}: {
  content: string | React.ReactElement;
  options?: Options;
  resolver: (decision: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ThemeProvider theme={theme}>
      <ConfirmModal
        isOpen={isOpen}
        onCancel={() => {
          resolver(false);
          setIsOpen(false);
        }}
        onConfirm={() => {
          resolver(true);
          setIsOpen(false);
        }}
        header={options?.header || "Please Confirm"}
        confirmBtnText={options?.confirmBtnText}
        cancelBtnText={options?.cancelBtnText}
        noCancel={options?.noCancel}
        icon={options?.icon}
      >
        {content}
      </ConfirmModal>
    </ThemeProvider>
  );
};
