import { TipaltiDisbursementStatus } from "generated/types";

export const tipaltiDisbursementStatusLabels = {
  [TipaltiDisbursementStatus.Error]: "Error",
  [TipaltiDisbursementStatus.Paid]: "Paid",
  [TipaltiDisbursementStatus.Processing]: "Processing",
};

export const tipaltiDisbursementStatusOptions = [
  { value: TipaltiDisbursementStatus.Error, label: "Error" },
  { value: TipaltiDisbursementStatus.Paid, label: "Paid" },
  { value: TipaltiDisbursementStatus.Processing, label: "Processing" },
];
