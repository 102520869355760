import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { ReadOnlyCheckbox } from "components/Checkbox/ReadOnlyCheckbox";
import { VisitObjective } from "generated/types";
import { WithId } from "utils/nonNull";

interface Props {
  objectives: WithId<VisitObjective>[];
}

export const VisitObjectives: FunctionComponent<Props> = ({ objectives }) => {
  return (
    <>
      {objectives.map(({ id, description, checked }) => (
        <Objective key={id}>
          <ReadOnlyCheckbox label={description!} checked={checked ?? undefined} />
        </Objective>
      ))}
    </>
  );
};

const Objective = styled.div`
  margin: 0.5rem 0;
  display: flex;

  input {
    cursor: default;
    margin-top: 2px;
    padding: 0.4rem;
  }
`;
