import * as yup from "yup";

export const schema = yup.object().shape({
  hourlyRate: yup.number().required("Required").positive().integer(),
  state: yup.string(),
});

export type Schema = {
  hourlyRate: string;
  state: string;
};
