/*  

clean this up ( delete? or remove branching feature flag logic ) in sc-55899
https://app.shortcut.com/papa/story/55899/clean-up-branching-logic-and-feature-flag-s-set-new-copy-in-stone

Example: 
import { useCallItClientProgramOrMember } from "hooks/useCallItClientProgramOrMember"; 
const { pluralEntityName, singularEntityName, capitalized } = useCallItClientProgramOrMember();

*/
import { FeatureFlags } from "constants/featureFlags";

import { useCallItThisOrThat } from "./useCallItThisOrThat";

const featureFlag = FeatureFlags.ClientCopyUpdate;

const current = {
  singular: "member",
  plural: "members",
};

const next = {
  singular: "client program",
  plural: "client programs",
};

export function useCallItClientProgramOrMember() {
  return useCallItThisOrThat(featureFlag, current, next);
}
