import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { FunctionComponent } from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import Dropdown from "components/Dropdown";
import { FeatureFlags } from "constants/featureFlags";
import { Caregiver, SupportTicketRequesterType } from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { DropdownActionMenu } from "../DropdownActionMenu";

interface Props {
  caregiver: Caregiver;
  isListMenu?: boolean;
  setAction: (action: DataTableMenuActions, caregiver: Caregiver) => void;
}

const CaregiverActionsMenu: FunctionComponent<Props> = ({
  caregiver,
  setAction,
  isListMenu = false,
}) => {
  const { isSupervisor } = useCurrentAccountRole();
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();

  const id = caregiver.id!;
  const type = SupportTicketRequesterType.Caregiver;

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "caregiver action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  if (loading) return <SpinningIcon size={18} />;

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {isListMenu && (
        <Dropdown.Link to={`/caregivers/${caregiver.id}`}>View Caregiver</Dropdown.Link>
      )}
      {isSupervisor && (
        <Dropdown.Link to={`/accounts/${caregiver.id}/activity-logs`}>
          View account logs
        </Dropdown.Link>
      )}
      {renderDropDownItem("Edit Caregiver", () => setAction(DataTableMenuActions.Edit, caregiver))}
      {isListMenu &&
        !caregiver?.account?.data?.pal?.data?.id &&
        renderDropDownItem("Add Care Recipient", () =>
          setAction(DataTableMenuActions.AddPapa, caregiver)
        )}
      {renderDropDownItem("Send reset password email", () =>
        setAction(DataTableMenuActions.ResetPassword, caregiver)
      )}
      {!hideCreditCardFields &&
        renderDropDownItem("Add credit card", () =>
          setAction(DataTableMenuActions.AddCreditCard, caregiver)
        )}
      {supportTicketingEnabled &&
        renderDropDownItem("Report caregiver/agent interaction", () =>
          handleSubmitSupportTicket({ id, type, redirectToAgent: true })
        )}
      {supportTicketingEnabled &&
        renderDropDownItem("Submit Ticket", () => handleSubmitSupportTicket({ id, type }))}
      {supportTicketingEnabled &&
        renderDropDownItem("View Tickets", () => handleSubmitSupportTicket({ id, type }))}
    </DropdownActionMenu>
  );
};

export default CaregiverActionsMenu;
