import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import { CreditCardTableActions } from "components/DataTables/CreditCardsTable/models";
import { useCreditCardTableColumns } from "components/DataTables/CreditCardsTable/useCreditCardsTableColumns";
import SmartTable from "components/SmartTable";
import { CreditCardFragment, usePapaCreditCardsQuery } from "generated/types";
import { DeleteCreditCardModal } from "modals/DeleteCreditCardModal";
import { nonNull } from "utils/nonNull";

const CreditCards: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = usePapaCreditCardsQuery({
    variables: { id },
  });
  const { columns, action, card, closeModal } = useCreditCardTableColumns();
  const creditCards = nonNull(data?.papa?.data?.creditCards?.data);

  return (
    <>
      <Helmet>
        <title>Papa&apos;s Credit Cards - Papa Admin</title>
      </Helmet>

      <>
        <SmartTable<CreditCardFragment>
          keyField="id"
          data={creditCards}
          loading={loading}
          columns={columns}
          error={error}
        />

        {card && (
          <DeleteCreditCardModal
            card={card}
            isOpen={action === CreditCardTableActions.delete}
            toggle={closeModal}
          />
        )}
      </>
    </>
  );
};

export default CreditCards;
