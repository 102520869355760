import { capitalize, lowerCase } from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import ProspectPalActionsMenu from "components/ActionsMenu/ProspectPalActionMenu/index";
import { ProspectPalMenuActions } from "components/ActionsMenu/ProspectPalActionMenu/models";
import { useProspectPalActions } from "components/ActionsMenu/ProspectPalActionMenu/useProspectPalActions";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { PalOnboarding, PalOnboardingSorting } from "generated/types";
import { SortDirection, VehicleInsuranceStatus } from "generated/types";
import { formatVehicleInsuranceStatus, welcomeVideoBooltoText } from "utils/helpers/formatters";
import { formatDateTime } from "utils/helpers/formatters";

interface Params {
  sorting: PalOnboardingSorting;
  onSortChange: (sort: PalOnboardingSorting) => void;
}

export const usePalOnboardingTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { palOnboarding, action, setAction } = useProspectPalActions();
  const [sorting, setSorting] = useState<PalOnboardingSorting>(initialSorting);

  const onSetSorting = (sortKey: keyof PalOnboardingSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };

  const columns: Column<PalOnboarding>[] = [
    {
      header: {
        title: "Full name",
        sort: sorting.providedFirstName,
        onChange: (direction) => onSetSorting("providedFirstName", direction),
      },
      dataField: "fullName",
      formatter({ id, providedFirstName, providedLastName }) {
        return (
          <Link to={`/prospect-pals/${id}`}>
            {providedFirstName} {providedLastName}
          </Link>
        );
      },
    },
    {
      header: { title: "Stage of Application" },
      dataField: "stageOfApplication",
      formatter({ stage }) {
        return <Text>{capitalize(lowerCase(stage ?? ""))}</Text>;
      },
    },
    {
      header: {
        title: "Welcome Video",
        sort: sorting.hasCompletedOrientationVideo,
        onChange: (direction) => onSetSorting("hasCompletedOrientationVideo", direction),
      },

      dataField: "hasCompletedOrientationVideo",
      formatter({ hasCompletedOrientationVideo }) {
        return <Text>{welcomeVideoBooltoText(!!hasCompletedOrientationVideo)}</Text>;
      },
    },
    {
      header: { title: "Vehicle Insurance" },
      dataField: "vehicleInsuanceStatus",
      formatter({ vehicleInsuranceStatus }) {
        return (
          <Text>
            {formatVehicleInsuranceStatus(vehicleInsuranceStatus as VehicleInsuranceStatus)}
          </Text>
        );
      },
    },
    {
      header: { title: "Background Check Status" },
      dataField: "bgcStatus",
      formatter({ bgcStatus }) {
        return <Text>{capitalize(lowerCase(bgcStatus ?? ""))}</Text>;
      },
    },
    {
      header: { title: "Email" },
      dataField: "email",
      formatter({ account }) {
        return <Text>{account?.data?.email}</Text>;
      },
    },
    {
      header: { title: "Phone number" },
      dataField: "phoneNumber",
      formatter({ account }) {
        return <Text>{account?.data?.phoneNumber}</Text>;
      },
    },
    {
      header: {
        title: "Joined At",
        sort: sorting.insertedAt,
        onChange: (direction) => onSetSorting("insertedAt", direction),
      },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt?.toString());
      },
    },
    {
      header: {
        title: "Updated At",
        sort: sorting.updatedAt,
        onChange: (direction) => onSetSorting("updatedAt", direction),
      },
      dataField: "updatedAt",
      cellProps: { noWrap: true },
      formatter({ updatedAt }) {
        return formatDateTime(updatedAt?.toString());
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(palOnboarding) {
        return (
          <ProspectPalActionsMenu palOnboarding={palOnboarding} setAction={setAction} isListMenu />
        );
      },
    },
  ];

  const closeModal = () => {
    setAction(ProspectPalMenuActions.noAction);
  };

  return { columns, action, palOnboarding, closeModal };
};
