import { useMutation } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import Select from "components/Select";
import CareConciergeAssignSelect from "components/ServiceRequest/AssignSelect";
import CareConciergeNeedSelect from "components/ServiceRequest/NeedSelect";
import CareConciergeTypeSelect from "components/ServiceRequest/TypeSelect";
import { originOptions, priorityOptions } from "constants/service-requests";
import {
  ServiceRequest,
  ServiceRequestOrigin,
  ServiceRequestPriority,
  ServiceRequestSorting,
  SortDirection,
} from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { GET_PAPA_REQUESTS } from "pages/Papas/Details/CareConcierge/List/gql";
import { mapSelectValuesToOptions } from "utils/select";

import { Schema, formSchema } from "./EditDetails.schema";
import { UPDATE_SERVICE_REQUEST_DETAILS } from "./gql";

type Props = {
  serviceId: string;
  servicerequest: ServiceRequest;
  onEditFinish: () => void;
};

const EditDetails = ({ serviceId, servicerequest, onEditFinish }: Props): ReactElement => {
  const [sorting] = useState<ServiceRequestSorting>({
    openedAt: SortDirection.Desc,
  });
  const { page, limit } = usePageInfo();
  const [updateServiceRequest, { loading, error: mutationError }] = useMutation(
    UPDATE_SERVICE_REQUEST_DETAILS,
    {
      refetchQueries: [
        {
          query: GET_PAPA_REQUESTS,
          variables: {
            filter: { papaId: { eq: servicerequest.papa?.data?.id } },
            pagination: { page, limit },
            sorting,
          },
        },
      ],
    }
  );
  const initialValues: Schema = {
    assignedToId: servicerequest?.assignedTo?.data?.id || " ",
    needId: servicerequest?.need?.data?.id || "",
    needText: servicerequest?.needText || "",
    description: servicerequest?.description || "",
    priority: servicerequest?.priority as ServiceRequestPriority,
    origin: servicerequest?.origin as ServiceRequestOrigin,
    typeId: servicerequest?.type?.data?.id || "",
  };

  const [otherSelected, setOther] = useState(!!initialValues.needText);
  const [schema, setSchema] = useState(() => formSchema(otherSelected));
  const handleSubmit = async ({ assignedToId, ...formData }: Schema) => {
    try {
      const { data } = await updateServiceRequest({
        variables: {
          id: serviceId,
          input: {
            assignedToId: assignedToId === " " ? null : assignedToId,
            ...formData,
          },
        },
      });
      if (data) {
        toast.success("Service request detail was edited with success!");
        onEditFinish();
      }
      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  const selectOther = (value: boolean) => {
    setOther(value);
  };
  useEffect(() => {
    setSchema(formSchema(otherSelected));
  }, [otherSelected]);

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ isSubmitting, values }) => (
        <StyledForm>
          <Container>
            <Form.Group>
              <Form.Label required>Service Request Type</Form.Label>
              <CareConciergeTypeSelect
                name="typeId"
                initialValue={initialValues.typeId}
                placeholder="Select Type"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Origin</Form.Label>
              <Select
                isSearchable={false}
                name="origin"
                defaultValue={mapSelectValuesToOptions(initialValues.origin, originOptions) as any}
                options={originOptions as any}
                aria-label="origin"
                isDisabled={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label required>Need</Form.Label>
              <CareConciergeNeedSelect
                name="needId"
                selectOther={selectOther}
                initialValue={initialValues.needId}
                typeId={values.typeId}
                placeholder="Select Need"
              />
            </Form.Group>
            {otherSelected && (
              <Form.Group>
                <Form.Label required>Other</Form.Label>
                <Form.Input name="needText" aria-label="needText" />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label required>Description</Form.Label>
              <Form.TextArea name="description" />
            </Form.Group>
            <Form.Group>
              <Form.Label required>Priority Level</Form.Label>
              <Select
                isSearchable={false}
                name="priority"
                defaultValue={
                  mapSelectValuesToOptions(initialValues.priority, priorityOptions) as any
                }
                options={priorityOptions as any}
                aria-label="priority"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Assign To</Form.Label>
              <CareConciergeAssignSelect
                name="assignedToId"
                placeholder="Select User"
                initialValue={initialValues.assignedToId}
              />
            </Form.Group>
          </Container>

          <FormFooter>
            <Button variant="secondary" onClick={onEditFinish}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              {loading ? (
                <>
                  <SpinningIcon size={18} />
                  <span>Saving...</span>
                </>
              ) : (
                "Save"
              )}
            </Button>
          </FormFooter>
        </StyledForm>
      )}
    </Formik>
  );
};

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5rem;
  padding-right: 2.625rem;
  ${Button}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const Container = styled.div`
  padding: 0 2.625rem 0 4.5rem;
`;

const StyledForm = styled(FormikForm)`
  width: 100%;
`;

export default EditDetails;
