import { useQuery } from "@apollo/client";
import capitalize from "lodash/capitalize";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { PaymentEvent, PaymentSingleResult } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

import { GET_PAYMENT_EVENTS } from "../gql";

interface Data {
  payment?: PaymentSingleResult;
}

const Activity = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<Data>(GET_PAYMENT_EVENTS, {
    variables: { id: id },
  });
  const events = data?.payment?.data?.events?.data;
  const columns: Column<PaymentEvent>[] = [
    {
      header: { title: "" },
      dataField: "id",
      formatter(event) {
        return <PaymentEventInfo event={event} />;
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Payment Activity - Papa Admin</title>
      </Helmet>

      <SmartTable<PaymentEvent>
        keyField="id"
        data={events}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

const PaymentEventInfo = ({ event }: { event: PaymentEvent }) => {
  const { insertedAt, type, failure } = event;
  const fullName = event?.account?.data?.fullName;

  return (
    <div>
      <span>{formatDateTime(insertedAt)}</span>
      <span>
        {" "}
        - <strong>{capitalize(type ?? "")}</strong>
      </span>
      {fullName ? <span> by {fullName}</span> : null}
      {failure && failure?.type === "error" ? (
        <div>
          {failure.source}: {failure.message}
        </div>
      ) : null}
    </div>
  );
};

export default Activity;
