import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { ModalContainer, ModalFooter } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";

import { EXPORT_PAPAS } from "./gql";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const PapaExportModal: React.FC<Props> = ({ isOpen, toggle }) => {
  const [exportPapas, { loading, error: mutationError }] = useMutation(EXPORT_PAPAS);
  const handleSubmit = async () => {
    try {
      const { data } = await exportPapas();
      if (mutationError) {
        toast.error(mutationError);
      }
      if (data?.exportPapas?.data?.success) {
        toast.success("Papas exported! Check your email.");
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
    toggle();
  };

  return (
    <ModalLayout title="Export Papas" isOpen={isOpen} toggle={toggle}>
      <ModalContainer>
        <p>A CSV file containing all papas will be sent to your email address.</p>

        <ModalFooter>
          <Button variant="secondary" onClick={toggle}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleSubmit}>
            {loading && <SpinningIcon size={18} />}
            OK
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalLayout>
  );
};

export default PapaExportModal;
