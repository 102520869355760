import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { CommentType, commentOptions } from "constants/papaComment";
import { PapaActivityLog } from "generated/types";
import { mapSelectValuesToOptions } from "utils/select";
import { getCommentTypeAndContent } from "utils/strings/getCommentTypeAndContent";

import { CREATE_PAPA_COMMENT, GET_PAPA_COMMENTS, UPDATE_PAPA_COMMENT } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  papaId: string;
  papaComment: PapaActivityLog | null;
  onEditFinish: () => void;
}

const EditComment: React.FC<Props> = ({ papaId, papaComment, onEditFinish }) => {
  const request = papaComment ? UPDATE_PAPA_COMMENT : CREATE_PAPA_COMMENT;
  const [updateComment, { loading, error: mutationError }] = useMutation(request, {
    refetchQueries: [{ query: GET_PAPA_COMMENTS, variables: { id: papaId } }],
  });
  const handleSubmit = async ({ type, content }: Schema) => {
    try {
      const { data } = await updateComment({
        variables: {
          id: papaComment ? papaComment.id : papaId,
          input: {
            comment: type ? `${type}: ${content}` : content,
          },
        },
      });
      if (data) {
        toast.success(`Papa comment was ${papaComment ? "edited" : "added"} with success!`);
        onEditFinish();
      }
      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  const [type, content] = getCommentTypeAndContent(papaComment?.content || "");
  const initialValues: Schema = {
    type: type === EMPTY_PLACEHOLDER ? null : (type as CommentType),
    content,
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label required>Select comment type</Form.Label>
              <Select
                name="type"
                defaultValue={mapSelectValuesToOptions<CommentType>(
                  initialValues.type,
                  commentOptions
                )}
                options={commentOptions}
                isSearchable={false}
                placeholder="Select type"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label required>Type in your comment here</Form.Label>
              <Form.TextArea name="content" fullWidth />
            </Form.Group>
          </ModalContainer>

          <ModalFooter>
            <Button variant="secondary" onClick={onEditFinish}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? (
                <>
                  <SpinningIcon size={18} />
                  <span>Saving...</span>
                </>
              ) : (
                "Save"
              )}
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </>
  );
};

export default EditComment;
