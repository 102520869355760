import { useMutation } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { object, string } from "yup";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { PalSingleResult } from "generated/types";
import { GET_PAL_NOTES } from "pages/Members/gql";
import { CREATE_PAL_NOTE, CreatePalNoteInput } from "pages/Pals/gql";

interface Props {
  accountId: string;
  palId: string;
  onEditFinish: () => void;
}

interface Schema {
  content: string;
}

export const AddCommentForm: React.FC<Props> = ({ accountId, palId, onEditFinish }) => {
  const [createNote, { loading, error: mutationError }] = useMutation<
    { createPalNote: PalSingleResult },
    CreatePalNoteInput
  >(CREATE_PAL_NOTE, {
    refetchQueries: [{ query: GET_PAL_NOTES, variables: { id: accountId } }],
  });

  const handleSubmit = async (input: Schema) => {
    try {
      const { data } = await createNote({
        variables: { input: { ...input, palId } },
      });

      if (data?.createPalNote?.data?.id) {
        toast.success("Comment was added with success!");
        onEditFinish();
      }
      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={{ content: "" }}
        validationSchema={object().shape({
          content: string().required("Please enter a comment."),
        })}
        onSubmit={handleSubmit}
      >
        <StyledForm>
          <Container>
            <Form.Group>
              <Form.Label htmlFor="member-comment">Type in your comment here</Form.Label>
              <Form.TextArea name="content" fullWidth id="member-comment" />
            </Form.Group>
          </Container>

          <FormFooter>
            <Button variant="secondary" onClick={onEditFinish}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? (
                <>
                  <SpinningIcon size={18} />
                  <span>Saving...</span>
                </>
              ) : (
                "Save"
              )}
            </Button>
          </FormFooter>
        </StyledForm>
      </Formik>
    </>
  );
};

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5rem;
  padding-right: 2.625rem;
  ${Button}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const Container = styled.div`
  padding: 0 3.375rem 0 2.25rem;
`;

const StyledForm = styled(FormikForm)`
  width: 100%;
`;
