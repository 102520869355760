import { useMutation } from "@apollo/client";
import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { FlexRow } from "components/Flex";
import Heading from "components/Heading";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";
import { Account } from "generated/types";

import { DeleteAccount } from "./gql";

interface Props {
  account: Partial<Account> | null;
  isOpen: boolean;
  toggle: () => void;
  onDeleteFinish: () => void;
}

export const DeleteAccountModal: React.FC<Props> = ({
  account,
  isOpen,
  toggle,
  onDeleteFinish,
}) => {
  const [deleteAccount, { loading }] = useMutation(DeleteAccount);

  const onDelete = async () => {
    try {
      const { data } = await deleteAccount({ variables: { id: account?.id } });

      if (data?.destroyAccount) {
        toast.success("Account was deleted");
        onDeleteFinish();
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <StyledIcon fontWeight="bold" size={23} />
          <TextContainer>
            <Heading as="h2">Delete Account</Heading>
            <FlexRow>
              <Text>Are you sure you want to delete</Text>
              <Text bold>&nbsp;{account?.fullName}?</Text>
            </FlexRow>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button disabled={loading} type="button" variant="primary" onClick={onDelete}>
          {loading ? <SpinningIcon size={18} /> : "Ok"}
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
