import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Label, StyledCheckbox } from "components/Checkbox";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  disabled?: boolean;
  color?: string;
};

export const OnlyCheckbox: FunctionComponent<Props> = ({
  label,
  name,
  disabled,
  color,
  ...props
}) => (
  <Container>
    <StyledCheckbox
      type="checkbox"
      id={name}
      name={name}
      color={color}
      {...props}
      disabled={disabled}
    />
    <Label htmlFor={name} disabled={disabled}>
      {label}
    </Label>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;
