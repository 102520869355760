import React from "react";
import { useParams } from "react-router";

import { ModalLayout } from "components/Modal/ModalLayout";

import { OverviewEditForm } from "../OverviewEditForm/OverviewEditForm";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  palId?: string | null;
}

export const OverviewEditModal: React.FC<Props> = ({ isOpen, toggle, palId }) => {
  const routeId = useParams<{ id: string }>().id;
  const id = palId || routeId;

  if (!id) return null;

  return (
    <ModalLayout toggle={toggle} title="Edit Pal" isOpen={isOpen} width={750}>
      <OverviewEditForm palId={id} onEditFinish={toggle} />
    </ModalLayout>
  );
};
