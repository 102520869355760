import { InfoCircle } from "@styled-icons/boxicons-regular/InfoCircle";
import { useField, useFormikContext } from "formik";
import capitalize from "lodash/capitalize";
import range from "lodash/range";
import moment, { Moment } from "moment-timezone";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import Conditional from "components/Conditional";
import Flex from "components/Flex";
import Form from "components/Form";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import { Loader } from "components/Loader";
import { OnlyRadio as Radio } from "components/Radio/OnlyRadio";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import { DEFAULT_TIMEZONE } from "constants/date";
import {
  Location,
  VisitRecurrenceFrequency,
  usePapaBusinessPoliciesForDateSelectQuery,
} from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { cssStringOrPx } from "utils/helpers/cssStringOrPx";
import { durationToHoursAndMinutes } from "utils/helpers/formatters";
import { isOutsideBusinessPolicy } from "utils/isOutsideBusinessPolicy";
import { nonNull } from "utils/nonNull";
import { isTimeBetween, timezoneGenericAbbrev, timezoneName } from "utils/time";

import { Values } from "../../types";
import DateSelect from "./DateSelect";
import { Duration } from "./Duration/Duration";
import NewTimeSelect from "./NewTimeSelect";

interface Props {
  isEditMode?: boolean;
  earliestAllowedDates?: { VvDate: string; IpvDate: string };
  sectionNumber?: number;
  sufficientSupply: boolean | null | undefined;
  isRecurrenceVisit: boolean;
  showRecurrenceDrawer: boolean;
  onEditRecurringVisit: () => void;
  handleIsRecurrenceVisitCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isOutsideEarliestAllowedDate: (day: Moment) => boolean;
  earliestAllowedDate?: string | Moment;
  scheduleAfter: Moment;
  visitFreq?: VisitRecurrenceFrequency;
}

const TimeDetails = ({
  isEditMode = false,
  sectionNumber,
  isRecurrenceVisit,
  showRecurrenceDrawer,
  handleIsRecurrenceVisitCheckbox,
  isOutsideEarliestAllowedDate,
  earliestAllowedDate,
  scheduleAfter,
  onEditRecurringVisit,
  visitFreq,
}: Props) => {
  const { values } = useFormikContext<Values>();
  const selectedDay =
    values.scheduledForDay && moment(values.scheduledForDay).isValid()
      ? moment(values.scheduledForDay).format("ddd, MMMM DD, YYYY")
      : null;

  const [{ value: scheduledForTime }, , scheduledForTimeHelpers] = useField("scheduledForTime");
  const [{ value: duration }, ,] = useField("estimatedDuration");
  const [, , timeWithinOperatingHoursHelpers] = useField("timeWithinOperatingHours");

  const onScheduledForTimeChange = (value: string) => {
    scheduledForTimeHelpers.setValue(value);
  };

  const { isVisitSuccessAgent, isEngineeringAdmin, isSuperAdmin } = useCurrentAccountRole();
  // For some reason it is necessary to do this within a useEffect.
  // This seems wrong to me. However, if it is done within
  // `onScheduledForTimeChange`, for example, the validation
  // will be overwritten after several re-renders
  useEffect(() => {
    if (
      scheduledForTime &&
      values.location?.timezone &&
      values.location?.supportOperatingHours?.startTime &&
      values.location?.supportOperatingHours?.endTime
    ) {
      const isPrivilegedUser = isVisitSuccessAgent || isEngineeringAdmin || isSuperAdmin;
      const startTime = values.location?.supportOperatingHours?.startTime;
      const endTime = values.location?.supportOperatingHours?.endTime;
      const timezone = values.location?.timezone;
      const isValid =
        isPrivilegedUser || isTimeBetween(scheduledForTime, startTime, endTime, timezone);
      timeWithinOperatingHoursHelpers.setValue(isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledForTime]);

  const getSelectedTime = (time: string | null, location: Location | null) => {
    if (time && location?.timezone) {
      const timezoneAbbreviation = timezoneGenericAbbrev(location.timezone);
      return (
        <SelectedDate>
          {`${moment(scheduledForTime, "h:mm A").format("h:mma")}, ${timezoneAbbreviation}`}
        </SelectedDate>
      );
    } else if (location?.timezone) {
      return <Description>{`Add time (Timezone: ${timezoneName(location.timezone)})`}</Description>;
    } else {
      return <Description>Please select location to set the timezone</Description>;
    }
  };

  const descriptionRecurrence = isRecurrenceVisit
    ? [
        "Recurring",
        values.freq
          ? values.freq === VisitRecurrenceFrequency.Fortnightly
            ? "bi-weekly"
            : values.freq.toLowerCase()
          : "",
        values.byDay?.[0] ? `on ${capitalize(values.byDay[0])}'s` : "",
      ]
        .filter((a) => !!a)
        .join(" ")
    : "";

  const subDescription = isRecurrenceVisit ? (
    <>
      {values.count && (
        <>
          {`Ends after ${values.count} occurrences`}
          <br />
        </>
      )}
      {values.scheduledForDay &&
        moment(values.scheduledForDay).isValid() &&
        moment(values.scheduledForDay).format("MMM D, YYYY")}
      {values.validUntil && <> to {moment(values.validUntil).format("MMM D, YYYY")}</>}
    </>
  ) : (
    ""
  );

  const renderSelectedDuration = (duration: string | null) => {
    if (duration) {
      const amount = parseInt(duration);
      return <SelectedDate>{durationToHoursAndMinutes(amount)}</SelectedDate>;
    } else {
      return <Description>Add duration</Description>;
    }
  };

  const accountId = values.accountId;
  const { data: businessPoliciesData, loading: loadingBusinessPolicies } =
    usePapaBusinessPoliciesForDateSelectQuery({
      variables: { accountId },
      skip: !accountId,
    });

  const businessPolicies = nonNull(businessPoliciesData?.businessPolicies?.data);

  const dateSelectDateOptions = range(0, 7).map((i) => {
    const date = moment(earliestAllowedDate)
      .tz(values.location?.timezone || DEFAULT_TIMEZONE)
      .add(i, "day");

    return {
      label: date.format("ddd, MMM D"),
      value: date.toISOString(),
      disabled: values.businessId ? isOutsideBusinessPolicy(businessPolicies, date) : false,
    };
  });

  return (
    <Form.Panel number={sectionNumber} headerText="Time details">
      <Flex>
        <Col minWidth="18.75rem">
          <Label>Date</Label>

          {descriptionRecurrence && subDescription ? (
            <>
              <SelectedDate>{descriptionRecurrence}</SelectedDate>
              <Text>{subDescription}</Text>
            </>
          ) : selectedDay ? (
            <Flex marginBottom="1rem" flexDirection="column">
              <SelectedDate>{selectedDay}</SelectedDate>
            </Flex>
          ) : (
            <Flex marginBottom="1rem" flexDirection="column">
              <Description>Add Date</Description>
            </Flex>
          )}

          {isEditMode && !!visitFreq && (
            <Flex marginBottom="1rem" flexDirection="column" flexWrap="nowrap">
              <TooltipContainer>
                <Tooltip
                  isDarkMode={true}
                  placement="bottom-start"
                  content={
                    <div>
                      <Text>This visit is part of a recurring series.</Text>
                      <Text>If you need to change the date for the</Text>
                      <Text>entire series, you must create a new one.</Text>
                    </div>
                  }
                >
                  <InfoCircle size={16} />
                </Tooltip>
                Editing date applies to this visit, only.
              </TooltipContainer>
            </Flex>
          )}

          {!isEditMode && (
            <Form.Group>
              {isRecurrenceVisit && !showRecurrenceDrawer ? (
                <>
                  <EditButton type="button" onClick={onEditRecurringVisit}>
                    Edit recurring visit
                  </EditButton>
                </>
              ) : (
                <Radio
                  name="isRecurrenceVisit"
                  value="true"
                  checked={isRecurrenceVisit}
                  label="Recurring visits"
                  onChange={handleIsRecurrenceVisitCheckbox}
                />
              )}
              <Radio
                name="isRecurrenceVisit"
                value="false"
                label="One time visit"
                checked={!isRecurrenceVisit}
                onChange={handleIsRecurrenceVisitCheckbox}
              />
            </Form.Group>
          )}
          {!isRecurrenceVisit && (
            <NewContainer>
              <DateSelect
                dateOptions={dateSelectDateOptions}
                isEdit={isEditMode}
                earliestAllowedDate={earliestAllowedDate}
                isOutsideEarliestAllowedDate={isOutsideEarliestAllowedDate}
                scheduleAfter={scheduleAfter}
                businessPolicies={businessPolicies}
              />
            </NewContainer>
          )}
        </Col>
        <Col>
          <Flex marginBottom="1rem" flexDirection="column">
            <Label>Visit start time</Label>
            {getSelectedTime(scheduledForTime, values.location)}
          </Flex>
          <NewContainer>
            {values.location && (
              <NewTimeSelect
                location={values.location}
                scheduledForTime={scheduledForTime}
                onScheduledForTimeChange={onScheduledForTimeChange}
              />
            )}
          </NewContainer>
        </Col>
        <Col>
          <Flex marginBottom="1rem" flexDirection="column">
            <Label>Duration</Label>
            <Conditional show={!loadingBusinessPolicies}>
              {renderSelectedDuration(duration)}
            </Conditional>
          </Flex>
          <Loader centered={false} isLoading={loadingBusinessPolicies}>
            <Duration businessPolicies={businessPolicies} />
          </Loader>
        </Col>
      </Flex>
    </Form.Panel>
  );
};

export default TimeDetails;

const NewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 10px;
`;

const Label = styled(ScheduleVisitLabel)`
  margin-bottom: 0;
`;

const Description = styled.p`
  color: #7d8695;
  margin-top: 0;
  margin-bottom: 0;
`;

const Col = styled.div<{ minWidth?: string | number }>`
  flex-basis: 33.33%;
  max-width: 33.33%;

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${cssStringOrPx(minWidth)};
    `}
`;

const SelectedDate = styled.div`
  color: ${({ theme }) => theme.text.main};
  font-size: 1rem;
  font-weight: 900;
`;

const EditButton = styled.button`
  text-decoration: underline;
  border-width: 0;
  color: ${({ theme }) => theme.variants.primary};
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  outline: none;
  font-weight: bold;
  font-family: "Avenir", sans-serif;
  margin-bottom: 0.5rem;
  font-size: 16px;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  vertical-align: bottom;

  svg {
    margin-top: -0.2rem;
  }
`;
