import { useField } from "formik";
import React from "react";

import ConfirmModal, { ConfirmModalIcon } from "components/Modal/ConfirmModal";

import { Values } from "../types";

interface Props {
  noTransportationOpen: boolean;
  toggleNoTransportation: () => void;
  transportationOpen: boolean;
  toggleTransportation: () => void;
  visitFormValues?: Values;
  onSubmit: (values: Values) => Promise<void>;
}

const OvernightModals: React.FC<Props> = ({
  noTransportationOpen,
  toggleNoTransportation,
  transportationOpen,
  toggleTransportation,
  visitFormValues,
  onSubmit,
}) => {
  const [, , scheduledForTimeHelpers] = useField("scheduledForTime");

  const handleSubmit = async () => {
    if (visitFormValues) {
      await onSubmit(visitFormValues);
    }
    toggleTransportation();
  };

  return (
    <>
      <ConfirmModal
        isOpen={noTransportationOpen}
        header="Overnight visits are not allowed"
        onConfirm={() => {
          if (toggleNoTransportation) {
            toggleNoTransportation();
          }
        }}
        confirmBtnText="Okay"
        noCancel
        icon={ConfirmModalIcon.exclamation}
      >
        Please select a different time for this visit.
      </ConfirmModal>
      <ConfirmModal
        isOpen={transportationOpen}
        header="Overnight visits are not allowed"
        onConfirm={handleSubmit}
        onCancel={() => {
          toggleTransportation();
          scheduledForTimeHelpers.setValue("");
        }}
        confirmBtnText="Yes"
        cancelBtnText="No"
      >
        Did you mean to schedule this visit to extend into the next day?
      </ConfirmModal>
    </>
  );
};

export default OvernightModals;
