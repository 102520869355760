import { useBillingFormulasQuery } from "generated/types";

export const useBillingFormulaOptions = () => {
  const { data, loading, error } = useBillingFormulasQuery();
  const formulaOptions =
    data?.billingFormulas?.data?.map(({ id, name, default: isDefault }) => ({
      label: name || "",
      value: id || "",
      isDefault: isDefault,
    })) ?? [];
  return { formulaOptions, loading, error };
};
