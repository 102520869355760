import { gql } from "@apollo/client";

export const UPDATE_SERVICE_REQUEST_DETAILS = gql`
  mutation updateServiceRequest($id: ID!, $input: ServiceRequestInput!) {
    updateServiceRequest(id: $id, input: $input) {
      data {
        id
        papa {
          data {
            id
          }
        }
        openedAt
        closedAt
        origin
        type {
          data {
            id
          }
        }
        priority
        status
        closedReason
        need {
          data {
            id
            name
          }
        }
        needText
        assignedTo {
          data {
            id
          }
        }
        assignedBy {
          data {
            id
          }
        }
      }
    }
  }
`;
