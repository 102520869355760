import React from "react";
import { toast } from "react-toastify";

import { confirm } from "components/Confirm";
import Dropdown from "components/Dropdown";
import {
  useApprovePaymentsMutation,
  useDisapprovePaymentsMutation,
  useMarkPaymentsAsChargedMutation,
  useResolvePaymentsMutation,
  useUnresolvePaymentsMutation,
} from "generated/types";

export enum ActionsMenu {
  NoAction,
  ChangeStatus,
}

type Props = {
  paymentIds: string[];
  onSuccess: () => void;
};

enum Action {
  Approve,
  Resolve,
  MarkAsCharged,
  Disapprove,
  Unresolve,
}

const MemberPaymentsMassMenu: React.FC<Props> = ({ paymentIds, onSuccess }) => {
  const [approvePayments] = useApprovePaymentsMutation();
  const [resolvePayments] = useResolvePaymentsMutation();
  const [markPaymentsAsCharged] = useMarkPaymentsAsChargedMutation();
  const [disapprovePayments] = useDisapprovePaymentsMutation();
  const [unresolvePayments] = useUnresolvePaymentsMutation();

  const handleAction = async (action: Action) => {
    const mutation =
      action === Action.Approve
        ? approvePayments
        : action === Action.Resolve
        ? resolvePayments
        : action === Action.MarkAsCharged
        ? markPaymentsAsCharged
        : action === Action.Disapprove
        ? disapprovePayments
        : unresolvePayments;

    if (
      !(await confirm(
        `Are you sure that you want to ${
          action === Action.Approve
            ? "approve"
            : action === Action.Resolve
            ? "resolve"
            : action === Action.MarkAsCharged
            ? "mark as charged"
            : action === Action.Disapprove
            ? "disapprove"
            : "unresolve"
        } ${paymentIds.length} payments?`
      ))
    )
      return;

    try {
      const { data } = await mutation({ variables: { paymentIds } });

      if (data) {
        onSuccess();
        toast.success(
          `${
            action === Action.Approve
              ? "Approved"
              : action === Action.Resolve
              ? "Resolved"
              : action === Action.MarkAsCharged
              ? "Marked as charged"
              : action === Action.Disapprove
              ? "Disapproved"
              : "Unresolved"
          } ${paymentIds.length} payments`
        );
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Dropdown.ActionButton isDisabled={!paymentIds?.length}>
      <Dropdown.Item onClick={() => handleAction(Action.Approve)}>Approve</Dropdown.Item>
      <Dropdown.Item onClick={() => handleAction(Action.Resolve)}>Resolve</Dropdown.Item>
      <Dropdown.Item onClick={() => handleAction(Action.MarkAsCharged)}>
        Mark as charged
      </Dropdown.Item>
      <Dropdown.Item onClick={() => handleAction(Action.Disapprove)}>Disapprove</Dropdown.Item>
      <Dropdown.Item onClick={() => handleAction(Action.Unresolve)}>Unresolve</Dropdown.Item>
    </Dropdown.ActionButton>
  );
};

export default MemberPaymentsMassMenu;
