import { gql } from "@apollo/client";

export const CREATE_SERVICEREQUEST_FOLLOWUP = gql`
  mutation createServiceRequestFollowup($input: ServiceRequestFollowupInput!) {
    createServiceRequestFollowup(input: $input) {
      data {
        id
        dueAt
        note
        action
        assignedTo {
          data {
            id
            fullName
          }
        }
        serviceRequestId
        actionText
        reason
      }
    }
  }
`;

export const UPDATE_SERVICEREQUEST_FOLLOWUP = gql`
  mutation updateServiceRequestFollowup($id: ID!, $input: ServiceRequestFollowupInput!) {
    updateServiceRequestFollowup(id: $id, input: $input) {
      data {
        id
        dueAt
        note
        action
        assignedTo {
          data {
            id
            fullName
          }
        }
        serviceRequestId
        actionText
        reason
      }
    }
  }
`;

export const GET_SERVICE_REQUEST_FOLLOWUP_REASON_OPTIONS = gql`
  query GetServiceRequestFollowupReasonOptions($action: ServiceRequestFollowupAction!) {
    getServiceRequestFollowupReasonOptions(action: $action) {
      value
      label
    }
  }
`;

export const GET_SERVICE_REQUEST_FOLLOWUP_ACTION_OPTIONS = gql`
  query GetServiceRequestFollowupActionOptions {
    getServiceRequestFollowupActionOptions {
      value
      label
    }
  }
`;
