export interface Assessment {
  id: string;
  score: number;
  type: AssessmentType;
  createdAt: string;
  createdBy: string;
  answers: AssessmentAnswerData;
}

interface AssessmentAnswerData {
  data: AssessmentAnswer[];
}

export interface AssessmentAnswer {
  text: string;
  answer: string | number;
  id: string;
}

export enum AssessmentType {
  loneliness = "LONELINESS",
  loneliness4Question = "LONELINESS_4_QUESTION",
  health = "HEALTH",
  papaSocialIndex = "PAPA_SOCIAL_INDEX",
  papaSocialIndexMedicare = "PAPA_SOCIAL_INDEX_MEDICARE",
}

export const AssessmentQuery = {
  loneliness: AssessmentType.loneliness,
  health: AssessmentType.health,
};

export type QuestionsFlow = { [key: string]: { [key: string]: string | string[] } };
export type HardCodedQuestionsRequired = { [key: string]: true };

export const assessmentOrdering: {
  [key in AssessmentType]?: number;
} = {
  [AssessmentType.loneliness]: 1,
  [AssessmentType.health]: 2,
};
