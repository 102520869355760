import { gql } from "@apollo/client";

export const UPDATE_MEMBER_VEHICLE = gql`
  mutation updateMemberVehicle($id: ID!, $input: PalInput!) {
    updatePal(id: $id, input: $input) {
      data {
        id
        vehicle
        vehicleColor
        vehicleModel
        vehicleYear
      }
    }
  }
`;
