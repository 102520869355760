import React from "react";

import Button from "components/Button";
import { LocationFormModal } from "components/LocationForm/LocationFormModal";
import { useModalToggle } from "hooks/useModalToggle";

const CreateLocation = () => {
  const { isOpen: isCreateModalOpen, toggle: toggleCreateModal } = useModalToggle();

  return (
    <>
      <Button action onClick={() => toggleCreateModal()}>
        New Location
      </Button>

      <LocationFormModal isOpen={isCreateModalOpen} toggle={toggleCreateModal} />
    </>
  );
};

export default CreateLocation;
