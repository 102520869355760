import { ServiceRequestOrigin, ServiceRequestPriority } from "generated/types";

export enum ServiceType {
  user = "user",
  followups = "followups",
  unassigned = "unassigned",
  all = "all",
  papa = "papa",
}

export const oneDayInSeconds = 86400;
export const twoDayInSeconds = 172800;
export const threeDayInSeconds = 259200;
export const fiveDayInSeconds = 432000;
export const sevenDayInSeconds = 604800;

export const priorityOptions = [
  { value: ServiceRequestPriority.Low, label: "Low" },
  { value: ServiceRequestPriority.High, label: "High" },
  { value: ServiceRequestPriority.Medium, label: "Medium" },
  { value: ServiceRequestPriority.Critical, label: "Critical" },
];

export const originOptions = [
  { value: ServiceRequestOrigin.ManualAdmin, label: "Manual - Admin" },
  { value: ServiceRequestOrigin.ManualCareConcierge, label: "Manual - Care Concierge" },
  { value: ServiceRequestOrigin.BenefitSurvey, label: "Benefits Survey" },
  { value: ServiceRequestOrigin.PalApp, label: "Pal App" },
  { value: ServiceRequestOrigin.PsiAssessment, label: "PSI Assessment" },
  { value: ServiceRequestOrigin.PsiMedicareAssessment, label: "PSI Medicare Assessment" },
];
