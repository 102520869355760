import { Search } from "@styled-icons/fa-solid/Search";
import { useField, useFormikContext } from "formik";
import debounce from "lodash/debounce";
import React, { CSSProperties, useMemo, useState } from "react";
import {
  IndicatorProps,
  OptionTypeBase,
  Props,
  default as ReactSelect,
  ValueType,
  components,
} from "react-select";

import Feedback from "components/Form/FormFeedback";
import { Option } from "utils/select";

import { Wrapper } from "./SelectWrapper";

interface ChangeSelectEvent extends OptionTypeBase {
  value: string | number;
  label: string;
}

export type OptionType = {
  label: string;
  value: any;
} & { [key: string]: any };
export type OptionsType = Array<OptionType>;

export type GroupType = {
  label: string;
  options: OptionsType;
};

type SelectProps = {
  name: string;
  small?: boolean;
  autoWidth?: boolean;
  options: OptionsType | GroupType[];
  onChange?: (value: any) => void;
  trackSearch?: (keyword: string) => void;
  trackSelected?: (keyword: string, value: any) => void;
  isClearable?: boolean;
  ["aria-labelledby"]?: string;
} & Props;

// An implementation of this Select component without Formik can be
// found at `src/components/Select/Simple.tsx`.
const Select = ({
  name,
  small,
  autoWidth,
  isSearchable = true,
  onChange,
  trackSearch,
  trackSelected,
  isDisabled,
  isClearable,
  ...otherProps
}: SelectProps) => {
  const [{ value }, { error, touched }, helpers] = useField(name);
  const [inputValue, setInputValue] = useState("");
  const { isSubmitting } = useFormikContext();

  const handleChange = (value: ValueType<ChangeSelectEvent>) => {
    let computedValue = null;

    // select with `isMulti` prop will emit array of values
    if (Array.isArray(value)) {
      computedValue = value.map((option) => option.value);
    } else {
      computedValue = (value as ChangeSelectEvent)?.value;
    }

    helpers.setValue(computedValue);
    onChange && onChange(computedValue);
    trackSelected && trackSelected(inputValue, computedValue);
  };

  const debouncedTrackSearch = useMemo(
    () => (trackSearch ? debounce(trackSearch, 500) : () => {}),
    [trackSearch]
  );

  const handleInputChange = (input: string) => {
    setInputValue(input);
    input && debouncedTrackSearch(input);
  };

  const dynamicProps: DynamicProps = {};

  if (isSearchable) {
    dynamicProps.components = { DropdownIndicator };
  }
  let defaultValue = null;
  const flattenedOptions =
    (otherProps?.options?.[0]?.options
      ? // @ts-ignore
        otherProps?.options?.map(({ options }) => options).flat()
      : otherProps?.options) ?? [];
  if (value && !Array.isArray(value)) {
    defaultValue = flattenedOptions.find(
      (option: Option<string>) => option?.value === value || option?.value === value.value
    );
  } else if (value?.length && otherProps.isMulti) {
    defaultValue = flattenedOptions.filter((option: Option<string>) =>
      value.includes(option.value)
    );
  }

  const isInvalid = touched && !!error;

  return (
    <Wrapper small={small} autoWidth={autoWidth} isInvalid={isInvalid}>
      <ReactSelect
        placeholder="Select"
        // @ts-ignore TODO:remove this
        styles={{ ...customStyles, ...otherProps.customStyles }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        onChange={handleChange}
        isDisabled={isDisabled || isSubmitting}
        value={defaultValue}
        className="select"
        classNamePrefix="select"
        {...otherProps}
        {...dynamicProps}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        name={name}
      />
      {isInvalid && <Feedback isInvalid>{error}</Feedback>}
    </Wrapper>
  );
};

type DynamicProps = {
  components?: {
    DropdownIndicator: (props: IndicatorProps<any>) => JSX.Element;
  };
};

const DropdownIndicator = (props: IndicatorProps<any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Search size={16} />
    </components.DropdownIndicator>
  );
};

export const customStyles = {
  container: (provided: CSSProperties, state: any) => ({
    ...provided,
    background: "white",
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocused ? "4px 4px 0 0" : 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
    maxWidth: "18.75rem",
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    borderColor: "#DFE3E9 !important",
    boxShadow: "none !important",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 0,
    minHeight: 36,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    color: "#CED0DA !important",
  }),
  menu: (provided: CSSProperties, state: any) => ({
    ...provided,
    color: state.selectProps.menuColor,
    boxShadow: "0 0 0 1px #DFE3E9",
    borderRadius: "0 0 4px 4px",
    margin: 0,
    padding: 0,
  }),
  menuList: (provided: CSSProperties, state?: any) => ({
    maxHeight: state.maxHeight || "none",
    padding: 0,
    overflow: "auto",
  }),
  option: (provided: CSSProperties, state: any) => ({
    ...provided,
    color: state.isSelected && "#2E5BFF",
    background: state.isSelected ? "#EAEFFF" : "white",
    boxShadow: "0 -1px 0 #DFE3E9",
  }),
};

export default Select;
