import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Column } from "components/SmartTable/schema";
import {
  ServiceRequestFollowup,
  ServiceRequestFollowupSorting,
  SortDirection,
  useGetServiceRequestFollowupActionOptionsQuery,
} from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { fancyEnum } from "utils/strings/fancyEnum";

interface Params {
  sorting: ServiceRequestFollowupSorting;
  onSortChange: (sort: ServiceRequestFollowupSorting) => void;
}

export const useFollowUpsTableColumns = ({ sorting: initialSorting, onSortChange }: Params) => {
  const { isCareConciergeSpecialist } = useCurrentAccountRole();
  const [sorting, setSorting] = useState<ServiceRequestFollowupSorting>(initialSorting);
  const onSetSorting = (sortKey: keyof ServiceRequestFollowupSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    setSorting(updatedSort);
    onSortChange(updatedSort);
  };
  const { data } = useGetServiceRequestFollowupActionOptionsQuery();
  const serviceRequestFollowupActionOptions = nonNull(data?.getServiceRequestFollowupActionOptions);
  const mapLabelFromValue = (value: string | undefined) => {
    return (
      serviceRequestFollowupActionOptions.find((e) => e.value === value)?.label || fancyEnum(value)
    );
  };
  const columns: Column<ServiceRequestFollowup>[] = [
    {
      header: { title: "Status" },
      dataField: "id",
      formatter({ completed }) {
        return completed ? "Completed" : "Open";
      },
    },
    {
      header: { title: "Member" },
      dataField: "MemberName",
      formatter({ serviceRequest }) {
        const papaConcealedEntity = papaConcealed(serviceRequest?.data?.papa?.data);
        return (
          <Link to={`/papas/${papaConcealedEntity?.id}/care-concierge/`}>
            {papaConcealedEntity?.fullName}
          </Link>
        );
      },
    },
    {
      header: { title: "Health Plan" },
      dataField: "serviceRequest.data.papa.data.business.data.name",
    },
    {
      header: { title: "SR ID" },
      dataField: "serviceRequestId",
      formatter({ serviceRequestId, serviceRequest }) {
        return isCareConciergeSpecialist ? (
          <Link
            to={`/papas/${serviceRequest?.data?.papa?.data?.id}/care-concierge/${serviceRequestId}/`}
          >
            {serviceRequestId}
          </Link>
        ) : (
          serviceRequestId ?? ""
        );
      },
    },
    {
      header: { title: "Follow Up Type" },
      dataField: "action",
      formatter(serviceRequestFollowup) {
        return mapLabelFromValue(serviceRequestFollowup.action ?? "") || "";
      },
    },
    {
      header: {
        title: "Due Date",
        sort: sorting.dueAt,
        onChange: (direction) => onSetSorting("dueAt", direction),
      },
      dataField: "dueAt",
      formatter(serviceRequestFollowup) {
        return formatDateTime(serviceRequestFollowup.dueAt, { format: "L" });
      },
    },
    {
      header: { title: "description" },
      dataField: "note",
    },
  ];

  return {
    columns,
  };
};
