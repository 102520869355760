import React from "react";
import { Route, Switch } from "react-router-dom";

import Papas from "./List";

const PapasTrialRoutes = () => {
  return (
    <Switch>
      <Route exact path="/papas-trial" component={Papas} />
    </Switch>
  );
};

export default PapasTrialRoutes;
