import { Formik } from "formik";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ButtonLink } from "components/Button";
import { OnlyCheckbox as Checkbox } from "components/Checkbox/OnlyCheckbox";
import { Flex } from "components/Flex";
import Form from "components/Form";
import { InputContainer as InputStyled } from "components/Form/FormInput";
import InputDateRange, { DateRange } from "components/InputDateRange";
import { PalStatus } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";

interface Props {
  loading: boolean;
}

export const Filter: React.FC<Props> = ({ loading, children }) => {
  const pageInfo = usePageInfo();
  const urlQueries = useUrlQuery();

  const history = useHistory();

  const [form, setForm] = useState({
    pal: pageInfo.pal ?? "",
    active: !!pageInfo.status?.includes(PalStatus.Active),
    suspended: !!pageInfo.status?.includes(PalStatus.Suspended),
    underInvestigation: !!pageInfo.status?.includes(PalStatus.UnderInvestigation),
    banned: !!pageInfo.status?.includes(PalStatus.Banned),
    dateRange:
      pageInfo.dayTimeFrom && pageInfo.dayTimeTo
        ? {
            startDate: moment(pageInfo.dayTimeFrom),
            endDate: moment(pageInfo.dayTimeTo),
          }
        : ({ startDate: null, endDate: null } as DateRange),
  });

  const hasFilters =
    history.location.search &&
    !isEqual(Object.keys(queryString.parse(history.location.search)), [
      "afterCursor",
      "beforeCursor",
    ]);

  const handleChange = (newForm: any) => {
    const hasDateRange = newForm.dateRange.startDate && newForm.dateRange.endDate;

    const dayTimeFrom = moment(newForm.dateRange.startDate).startOf("day").toISOString();
    const dayTimeTo = moment(newForm.dateRange.endDate).endOf("day").toISOString();

    const status = [
      ...(newForm.active ? ["ACTIVE"] : []),
      ...(newForm.suspended ? ["SUSPENDED"] : []),
      ...(newForm.underInvestigation ? ["UNDER_INVESTIGATION"] : []),
      ...(newForm.banned ? ["BANNED"] : []),
    ].join(",");

    const query = queryString.stringify(
      {
        ...urlQueries,
        pal: newForm.pal,
        status,

        ...(hasDateRange ? { dayTimeFrom, dayTimeTo } : {}),
      },
      { skipEmptyString: true, skipNull: true }
    );

    if (!query && !history.location.search) {
      return;
    }

    if (`?${query}` !== history.location.search) {
      history.push(`?${query}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => debounce(handleChange, 500), [urlQueries]);

  const handleInput = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const newForm = { ...form, [name]: value };

    setForm(newForm);
    action(newForm);
  };

  const handleDateChange = (newDateRange: DateRange) => {
    if (!newDateRange.startDate && !newDateRange.endDate) {
      return;
    }

    const newForm = {
      ...form,
      dateRange: newDateRange,
    };

    setForm(newForm);
    action(newForm);
  };

  const handleCheckboxToggle = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newForm = {
      ...form,
      [name]: checked,
    };

    setForm(newForm);
    action(newForm);
  };

  const handleCleanFilters = () => {
    setForm({
      pal: "",
      active: false,
      suspended: false,
      underInvestigation: false,
      banned: false,

      dateRange: {
        endDate: null,
        startDate: null,
      },
    });
    history.push("?");
  };

  return (
    <Formik initialValues={form} onSubmit={noop} enableReinitialize>
      <Container>
        <Flex justifyContent="space-between" alignItems="center" gridGap={10}>
          <Flex gridGap={10} grow>
            <Form.Input
              name="pal"
              aria-label="pal"
              placeholder="Search by Pal Name or Pal ID"
              onChange={handleInput}
              keepEnable
            />

            <InputDateRange
              id="dateRange"
              name="dateRange"
              isOutsideRange={() => false}
              onChange={handleDateChange}
              minimumNights={0}
            />
          </Flex>

          <Flex>{children}</Flex>
        </Flex>
        <Flex gridGap={10} alignItems="center" height={36}>
          <Checkbox
            label="Active"
            name="active"
            disabled={loading}
            onChange={handleCheckboxToggle}
            checked={form.active}
          />
          <Checkbox
            label="Suspended"
            name="suspended"
            disabled={loading}
            onChange={handleCheckboxToggle}
            checked={form.suspended}
          />
          <Checkbox
            label="Under Investigation"
            name="underInvestigation"
            disabled={loading}
            onChange={handleCheckboxToggle}
            checked={form.underInvestigation}
          />
          <Checkbox
            label="Banned"
            name="banned"
            disabled={loading}
            onChange={handleCheckboxToggle}
            checked={form.banned}
          />
          {hasFilters && (
            <ButtonLink color="muted" size="small" onClick={handleCleanFilters} nowrap>
              Clean Filters
            </ButtonLink>
          )}
        </Flex>
      </Container>
    </Formik>
  );
};

const Container = styled(Flex).attrs({
  gridGap: 10,
  flexDirection: "column",
  marginBottom: 20,
})`
  ${InputStyled} {
    max-width: 14rem;
  }
`;
