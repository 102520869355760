import { gql } from "@apollo/client";

export const DELETE_BUSINESS_CREDIT_CARD = gql`
  mutation deleteBusinessCreditCard($id: ID!) {
    destroyCreditCard(id: $id) {
      data {
        id
      }
    }
  }
`;
