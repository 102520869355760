import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React, { CSSProperties, ReactElement, useState } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import { RadioGroupParallel } from "components/RadioGroup/RadioGroupParallel";
import Select from "components/Select";
import { customStyles } from "components/Select";

import { PAL_ONBOARDING } from "../../pages/PalOnboardings/gql";
import { UPDATE_VEHICLE_INSURANCE_STATUS } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  palOnboardingId: string;
  insuranceStatus?: string;
  isOpen: boolean;
  toggle: () => void;
}

enum InsuranceStatusAction {
  APPROVE_VEHICLE_INSURANCE = "APPROVE_VEHICLE_INSURANCE",
  REJECT_VEHICLE_INSURANCE = "REJECT_VEHICLE_INSURANCE",
}

const reasons: string[] = [
  "The image is blurry",
  "The insurance is expired",
  "File uploaded cannot be opened",
  "The image is not an insurance card",
  "The insurance will expire within 7 days",
  "Vehicle make model and year are missing",
  "Vehicle make model or year does not match application",
  "The name on the insurance does not match the applicant name",
  "The name on the insurance and vehicle doesn't match the application",
  "The car on insurance card does not match the car on the application",
];

const options: any[] = reasons.map((r: string) => ({ label: r, value: r }));

export const PalVehicleInsuranceUpdateModal = ({
  palOnboardingId,
  insuranceStatus,
  isOpen,
  toggle,
}: Props): ReactElement => {
  const [updateMutation] = useMutation(UPDATE_VEHICLE_INSURANCE_STATUS, {
    refetchQueries: [{ query: PAL_ONBOARDING, variables: { palOnboardingId: palOnboardingId } }],
  });
  const [isRejectReasonDisabled, setIsRejectReasonDisabled] = useState(true);
  const initialValues: Schema = {
    insuranceStatusApprove: insuranceStatus === "APPROVED" ? true : null,
    rejectionReason: "",
  };

  const Update = async ({ insuranceStatusApprove, rejectionReason }: Schema) => {
    try {
      const defaultReject = !insuranceStatusApprove
        ? {
            arg: {
              rejectVehicleInsurance: {
                reason: rejectionReason,
              },
            },
          }
        : {};
      const { data } = await updateMutation({
        variables: {
          input: {
            palOnboardingId: palOnboardingId,
            action: insuranceStatusApprove
              ? InsuranceStatusAction.APPROVE_VEHICLE_INSURANCE
              : InsuranceStatusAction.REJECT_VEHICLE_INSURANCE,
            ...defaultReject,
          },
        },
      });
      if (data?.palApplicationAdmin?.data.vehicleInsuranceStatus) {
        toast.success("Insurance status update successful!");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error: any) {
      toast.error((error as Error).message);
    }
  };

  customStyles.container = (provided: CSSProperties, state: any) => ({
    ...provided,
    background: "white",
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocus ? "4px 4px 0 0" : 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
    maxWidth: "26.75rem",
  });

  return (
    <ModalLayout toggle={toggle} title="Update Vehicle Insurance Status" isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={Update}>
        {({ isSubmitting, setTouched, setFieldValue }) => (
          <ModalStyledForm>
            <ModalContainer>
              <Form.Group>
                <Form.Label>*Status</Form.Label>
                <Form.GroupInline>
                  <Form.Inline>
                    <RadioGroupParallel
                      aria-label="insuranceStatusApprove"
                      name="insuranceStatusApprove"
                      options={[
                        {
                          value: true,
                          label: "Approved",
                          controlId: "insurance-approved",
                          onChange: () => {
                            setIsRejectReasonDisabled(true);
                            setTouched({});
                            setFieldValue("rejectionReason", "");
                          },
                        },
                        {
                          value: false,
                          label: "Rejected",
                          controlId: "insurance-rejected",
                          onChange: () => {
                            setIsRejectReasonDisabled(false);
                          },
                        },
                      ]}
                    />
                  </Form.Inline>
                </Form.GroupInline>
                <Form.Label>*Reason for rejection</Form.Label>
                <Select
                  name="rejectionReason"
                  options={options}
                  aria-label="rejectionReason"
                  placeholder="Select a reason"
                  isDisabled={isRejectReasonDisabled}
                  autoWidth={false}
                  isSearchable={false}
                  styles={customStyles}
                />
              </Form.Group>
            </ModalContainer>
            <ModalFooter>
              <Form.SubmitButton isSubmitting={isSubmitting}>Update</Form.SubmitButton>
              <Button type="button" variant="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalStyledForm>
        )}
      </Formik>
    </ModalLayout>
  );
};
