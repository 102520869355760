import isArray from "lodash/isArray";

import { AssessmentQuestionFragment } from "generated/types";
import { AssessmentType, QuestionsFlow } from "types/assessments";
import { nonNull } from "utils/nonNull";

import { FormValues } from "./models";

function isNotPapaSocialIndexSurvey(type: AssessmentType) {
  return ![AssessmentType.papaSocialIndex, AssessmentType.papaSocialIndexMedicare].includes(type);
}

export const getSections = (
  type: AssessmentType,
  questions: AssessmentQuestionFragment[],
  values: FormValues,
  sectionGroups: {
    id: string;
    questions: string[];
  }[],
  questionsFlow?: QuestionsFlow
) => {
  if (isNotPapaSocialIndexSurvey(type)) return { S1: questions };

  const sections = {} as { [key: string]: AssessmentQuestionFragment[] };

  sectionGroups.forEach((group) => {
    if (!sections[group.id]) {
      sections[group.id] = [];
    }

    questions.forEach((question) => {
      const position = `P${question.position}`;

      if (group.questions.includes(position)) {
        if (questionsFlow && typeof questionsFlow[position] !== "undefined") {
          const [parent, answer] = Object.entries(questionsFlow[position])[0];

          const parentQuestion = questions.find((q) => `P${q.position}` === parent);

          if (parentQuestion) {
            const value = values[`question-${parentQuestion.id}`];

            const answerOptions = nonNull(parentQuestion.options?.data)
              .filter((o) => {
                const answerText = o?.answerText?.toLowerCase() ?? "";

                return isArray(answer)
                  ? answer.map((a) => a.toLocaleLowerCase()).includes(answerText)
                  : answerText.includes(answer);
              })
              .map((o) => o?.id!);

            if (typeof value === "string") {
              if (answerOptions.includes(value)) {
                sections[group.id].push(question);
              }
            } else if (isArray(value)) {
              if (answerOptions.filter((x) => value.includes(x)).length > 0) {
                sections[group.id].push(question);
              }
            }
          }
        } else {
          sections[group.id].push(question);
        }
      }
    });
  });
  return sections;
};
