import React from "react";
import Helmet from "react-helmet";

import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Visit } from "generated/types";
import { nonNull } from "utils/nonNull";

import { SurveysList } from "./SurveysList";
import { SurveysTable } from "./SurveysTable";

interface Props {
  visit?: Visit | null;
  listView?: boolean;
}

const Surveys: React.FC<Props> = ({ visit, listView }) => {
  if (!visit) {
    return null;
  }

  const surveys = nonNull(visit.surveyResponses?.data);

  if (!surveys.length) {
    return <Text>{EMPTY_PLACEHOLDER}</Text>;
  }

  return (
    <>
      <Helmet>
        <title>Visit Surveys - Papa Admin</title>
      </Helmet>
      {listView ? <SurveysList surveys={surveys} /> : <SurveysTable surveys={surveys} />}
    </>
  );
};

export default Surveys;
