import React from "react";

import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";
import { useModalToggle } from "hooks/useModalToggle";
import { QueryParams, usePageInfo } from "hooks/usePageInfo";
import { VisitFiltersModal } from "modals/VisitFiltersModal";
import { countFilters } from "utils/visitFilters";

interface Props {
  enableMembersSearch?: boolean;
  hideCleanFilter?: boolean;
  countFilterDefault?: number;
  visitFiltersDefault?: Partial<QueryParams>;
}

export const VisitsFiltersToggle: React.FC<Props> = ({
  enableMembersSearch,
  countFilterDefault,
  visitFiltersDefault,
  hideCleanFilter,
}) => {
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const { page, limit, accountId, ...visitFilters } = usePageInfo();

  const countFilter =
    typeof countFilterDefault === "undefined" ? countFilters(visitFilters) : countFilterDefault;

  return (
    <>
      <Button type="button" onClick={toggleFiltersModal}>
        <span>Filter</span>
        {countFilter > 0 && <span>({countFilter})</span>}
      </Button>

      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Visits Filter"
        isOpen={isFiltersModalOpen}
      >
        <VisitFiltersModal
          enableMembersSearch={enableMembersSearch}
          hideCleanFilter={hideCleanFilter}
          visitFiltersDefault={visitFiltersDefault}
          onSubmit={toggleFiltersModal}
        />
      </ModalLayout>
    </>
  );
};
