import moment, { Moment } from "moment-timezone";

import { VisitRecurrenceFrequency } from "generated/types";

export const getRecurrenceCount = (
  start: string,
  end: string,
  frequency: VisitRecurrenceFrequency
) => {
  switch (frequency) {
    case VisitRecurrenceFrequency.Daily:
      return moment(end).diff(moment(start), "day");
    case VisitRecurrenceFrequency.Weekly:
      return moment(end).diff(moment(start), "week");
    case VisitRecurrenceFrequency.Fortnightly:
      return Math.floor(moment(end).diff(moment(start), "week") / 2);
    case VisitRecurrenceFrequency.Monthly:
      return moment(end).diff(moment(start), "month");
  }
};

export const dateValidatorForRecurrenceEnd = (
  scheduledForDay: string,
  businessPolicyEnd: string
) => {
  return (day: Moment) => {
    const isBeforeScheduledDay = moment(day.format("yyyy-MM-DD")).isBefore(
      moment(scheduledForDay).format("yyyy-MM-DD")
    );
    const isAfterBusinessPolicy = businessPolicyEnd
      ? moment(day.format("yyyy-MM-DD")).isAfter(businessPolicyEnd)
      : false;

    return isBeforeScheduledDay || isAfterBusinessPolicy;
  };
};
