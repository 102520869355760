import styled from "styled-components";

import Tag from "./index";

const StatusTag = styled(Tag)`
  background: ${({ children, theme }) => {
    if (children === "approved") return theme.statusLegend.ACCEPTED;
    if (children === "rejected") return theme.statusLegend.REJECTED;
    if (children === "unreviewed") return theme.statusLegend.PENDING;
  }};
  text-transform: capitalize;
`;

export default StatusTag;
