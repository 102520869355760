import React from "react";
import { Route, Switch } from "react-router-dom";

import PalOnboardingDetails from "./Details";
import PalOnboardings from "./List";

const PalOnboardingsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/prospect-pals" component={PalOnboardings} />
      <Route
        exact
        path={["/prospect-pals/:prospectPalId", "/prospect-pals/:prospectPalId/:tab"]}
        component={PalOnboardingDetails}
      />
    </Switch>
  );
};

export default PalOnboardingsRoutes;
