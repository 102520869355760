import { useFormikContext } from "formik";
import first from "lodash/first";
import last from "lodash/last";
import React, { useState } from "react";
import styled from "styled-components";

import { ButtonNew as Button } from "components/Button";
import { Drawer } from "components/Drawer";
import { FlexRow as Flex } from "components/Flex";
import Form from "components/Form";
import Text from "components/Text";
import { assessmentMessage } from "constants/assessments";
import { AssessmentQuestionFragment } from "generated/types";
import {
  AssessmentType,
  HardCodedQuestionsRequired,
  QuestionsFlow,
  assessmentOrdering,
} from "types/assessments";

import { useAnswersScore } from "./AnswersScoreEdit";
import QuestionsSections from "./QuestionsSections";
import { getSections } from "./getSections";
import { AssessmentsSurveyTitles, FormValues } from "./models";

type Props = {
  type: AssessmentType;
  questions: AssessmentQuestionFragment[];
  hardCodedQuestionsRequired?: HardCodedQuestionsRequired;
  questionsFlow?: QuestionsFlow;
  onSkip?: () => void;
  handleClose: () => void;
  isSaving: boolean;
  groups: {
    id: string;
    questions: string[];
  }[];
  showProgress?: boolean;
};

const SectionFlow = ({
  type,
  questions,
  questionsFlow,
  hardCodedQuestionsRequired,
  onSkip,
  handleClose,
  isSaving,
  groups,
  showProgress,
}: Props) => {
  const [currentSection, setCurrentSection] = useState("S1");
  const [submitTouched, setSubmitTouched] = useState(false);
  const [warningVisibility, showWarning] = useState(false);

  const { values } = useFormikContext<FormValues>();
  const sections = getSections(type, questions, values, groups, questionsFlow);

  const sectionKeys = Object.keys(sections);
  const isLastSection = last(sectionKeys) === currentSection;
  const nextSection = isLastSection
    ? null
    : sectionKeys[sectionKeys.findIndex((k) => k === currentSection) + 1];
  const previousSection =
    first(sectionKeys) === currentSection
      ? null
      : sectionKeys[sectionKeys.findIndex((k) => k === currentSection) - 1];

  const hasErrors = isMissingRequiredQuestions(questions, values, hardCodedQuestionsRequired);
  const showSubmitButton = sectionKeys.length === 1 || !hasErrors;

  const submitCheckHandler = () => {
    showWarning(true);
    setSubmitTouched(true);
  };

  const backToBegin = () => {
    setCurrentSection(first(sectionKeys)!);
    showWarning(false);
  };

  if (warningVisibility) {
    return (
      <>
        <Drawer.Container>
          <Flex>
            <Text size="largex" bold>
              You have submitted an incomplete assessment. In order to see a recommendation, please
              go back and complete the remainder of the assessment with the member
            </Text>
          </Flex>
        </Drawer.Container>
        <Drawer.Footer>
          <Button type="button" variant="secondary" onClick={backToBegin}>
            Back
          </Button>
          <Form.SubmitButton action noContent>
            Yes
          </Form.SubmitButton>
        </Drawer.Footer>
      </>
    );
  }

  const title = AssessmentsSurveyTitles[type];

  const showSkip = !!onSkip;

  return (
    <>
      <Drawer.Container>
        {showProgress && <AssessmentOrdering type={type} />}
        <Title as="h3" color="primary" size="largex">
          {title}
        </Title>

        {currentSection === "S6" && <ShowEditResult />}
        <QuestionsSections
          type={type}
          questions={questions}
          questionsFlow={questionsFlow}
          hardCodedQuestionsRequired={hardCodedQuestionsRequired}
          currentSection={currentSection}
          submitTouched={submitTouched}
          groups={groups}
        />
      </Drawer.Container>

      <Drawer.Footer>
        {showSkip ? (
          <Button type="button" variant="secondary" onClick={onSkip} disabled={isSaving}>
            Skip
          </Button>
        ) : previousSection ? (
          <Button
            type="button"
            variant="secondary"
            onClick={() => setCurrentSection(previousSection)}
          >
            Back
          </Button>
        ) : (
          <Button type="button" variant="secondary" onClick={handleClose} disabled={isSaving}>
            Close
          </Button>
        )}
        {nextSection && (
          <Button type="button" variant="primary" onClick={() => setCurrentSection(nextSection)}>
            Next
          </Button>
        )}

        {isLastSection &&
          (showSubmitButton ? (
            <Form.SubmitButton action noContent>
              Submit Assessment
            </Form.SubmitButton>
          ) : (
            <Button type="button" variant="primary" onClick={submitCheckHandler}>
              Submit Assessment
            </Button>
          ))}
      </Drawer.Footer>
    </>
  );
};

const isMissingRequiredQuestions = (
  questions: AssessmentQuestionFragment[],
  values: FormValues,
  hardCodedQuestionsRequired?: HardCodedQuestionsRequired
) => {
  return hardCodedQuestionsRequired
    ? questions
        .filter((question) => hardCodedQuestionsRequired[`P${question.position}`])
        .some((question) => {
          const questionId = `question-${question.id}`;

          return !values[questionId];
        })
    : false;
};

const ShowEditResult = () => {
  const { result, firstQuestionAnswer } = useAnswersScore();
  if (!result) return null;
  return (
    <>
      <Flex flexDirection="row">{assessmentMessage[result as keyof typeof assessmentMessage]}</Flex>
      {firstQuestionAnswer && (
        <Flex flexDirection="row">
          Reminder, the member expressed needs of: {firstQuestionAnswer}
        </Flex>
      )}
    </>
  );
};

const AssessmentOrdering = ({ type }: { type: AssessmentType }) => {
  if (typeof assessmentOrdering[type] === "undefined") return null;

  const order = assessmentOrdering[type];
  const length = Object.keys(assessmentOrdering).length;

  return (
    <Text color="primary" size="small" marginBottom="0.5rem">
      Assessment {order} of {length}
    </Text>
  );
};

const Title = styled(Text)`
  margin-bottom: 1rem;
`;

export default SectionFlow;
