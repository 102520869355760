import MaterialDrawer, { DrawerProps as MaterialDrawerProps } from "@mui/material/Drawer";
import { ArrowBack } from "@styled-icons/evaicons-solid/ArrowBack";
import { StyledIconBase } from "@styled-icons/styled-icon";
import React from "react";
import styled, { css } from "styled-components";
import { Times } from "styled-icons/fa-solid";

import { ButtonNew } from "components/Button";
import Text from "components/Text";

import "./drawer.style.css";

export type DrawerProps = {
  size?: "sm" | "md" | "lg" | "xl";
  onClose: () => void;
  title: string;
  onBack?: () => void;
  noOverlay?: boolean;
  noFooter?: boolean;
} & Pick<MaterialDrawerProps, "open" | "children" | "anchor">;

/**
 * Component from Material UI https://mui.com/material-ui/react-drawer/
 * API: https://mui.com/material-ui/api/drawer/
 */
export const Drawer = ({
  size = "sm",
  onClose = () => {},
  anchor = "right",
  noOverlay,
  noFooter,
  children,
  title,
  onBack,
  ...props
}: DrawerProps) => {
  let variant: MaterialDrawerProps["variant"] = "temporary";
  let hideBackdrop = undefined;
  if (noOverlay) {
    variant = "persistent";
    hideBackdrop = true;
  }

  const dynamicProps = { hideBackdrop, variant };

  const hasBackButton = onBack !== undefined;

  return (
    <MaterialDrawer anchor={anchor} onClose={onClose} {...props} {...dynamicProps}>
      <Container>
        <Header hasBackButton={hasBackButton}>
          {hasBackButton && (
            <Button variant="secondary" withIcon size="small" onClick={onBack}>
              <ArrowBack size={16} />
              Back
            </Button>
          )}
          <Title as="h3" color="primary" size="largex" hasBackButton={hasBackButton}>
            {title}
          </Title>
          <BtnClose onClick={onClose} aria-label="Close">
            <Times size={14} aria-hidden="true" />
          </BtnClose>
        </Header>
        <Wrapper size={size} noFooter={noFooter !== undefined}>
          {children}
        </Wrapper>
      </Container>
    </MaterialDrawer>
  );
};

const contentSize = {
  sm: "17.5rem", // 280px
  md: "26.25rem", // 420px
  lg: "35rem", // 560px
  xl: "47rem", // 560px
};

const Header = styled.header<{ hasBackButton: boolean }>`
  height: 5.25rem;
  padding-left: ${({ theme }) => theme.drawer.padding};
  padding-right: 0.5rem;
  border: 0 solid #dfdfdf;
  border-bottom-width: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ hasBackButton }) =>
    hasBackButton
      ? css`
          padding-top: 0.563rem;
          padding-bottom: 0.625rem;
        `
      : css`
          padding-top: 0.875rem;
          padding-bottom: 0.875rem;
        `}
`;

const Title = styled(Text)<{ hasBackButton: boolean }>`
  flex-grow: 1;

  ${({ hasBackButton }) =>
    hasBackButton &&
    css`
      text-align: center;
    `}
`;

const Wrapper = styled.div<{ size: "sm" | "md" | "lg" | "xl"; noFooter: boolean }>`
  width: ${({ size }) => contentSize[size]};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ noFooter }) =>
    noFooter === true
      ? css`
          margin-bottom: 0px;
        `
      : css`
          margin-bottom: 5.25rem;
        `}
  overflow: auto;
`;

const BtnClose = styled.button`
  color: black;
  border: 0;
  background: transparent;
  padding: 0.5rem;
  cursor: pointer;

  ${StyledIconBase} {
    color: #bababa;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Button = styled(ButtonNew)`
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;
