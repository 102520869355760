import { EllipsisH } from "@styled-icons/fa-solid";
import React, { useState } from "react";

import Dropdown from "components/Dropdown";
import { Column } from "components/SmartTable/schema";
import { CreditCardFragment } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { creditCardPlaceholder } from "utils/strings/creditCardPlaceholder";
import { creditCardStatus } from "utils/strings/creditCardStatus";

import { CreditCardTableActions } from "./models";

export const useCreditCardTableColumns = () => {
  const [action, setAction] = useState<CreditCardTableActions | null>(null);
  const [card, setCard] = useState<CreditCardFragment | null>(null);
  const setDropdownMenuAction = (action: CreditCardTableActions, card: CreditCardFragment) => {
    setAction(action);
    setCard(card);
  };
  const closeModal = () => {
    setAction(null);
  };
  const columns: Column<CreditCardFragment>[] = [
    {
      header: { title: "Card Info" },
      dataField: "last4",
      formatter({ last4 }) {
        return creditCardPlaceholder(last4 ?? "");
      },
    },
    {
      header: { title: "Expires" },
      dataField: "expMonth",
      formatter({ expMonth, expYear }) {
        return expMonth && expYear ? `${expMonth}/${expYear}` : null;
      },
    },
    {
      header: { title: "State" },
      dataField: "default",
      formatter(creditCard) {
        return creditCardStatus(creditCard);
      },
    },
    {
      header: { title: "Added" },
      dataField: "insertedAt",
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt);
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        fixed: true,
        align: "center",
      },
      formatter(card) {
        return (
          <Dropdown placement="bottom-end">
            <Dropdown.Actions>
              <EllipsisH size={18} />
            </Dropdown.Actions>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setDropdownMenuAction(CreditCardTableActions.delete, card)}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  return {
    columns,
    action,
    card,
    closeModal,
  };
};
