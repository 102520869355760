import React from "react";

import { LocationFormModal } from "components/LocationForm/LocationFormModal";
import { ModalLayout } from "components/Modal/ModalLayout";
import { SupportTicketRequesterType } from "generated/types";
import WarmTransferModal from "modals/WarmTransferModal";
import SetStatusForm from "pages/Papas/List/SetStatus/SetStatusForm";
import { PapaDeleteModal } from "pages/Papas/shared/PapaDelete/PapaDeleteModal";
import { PapaConcealed } from "types";

import { PapaMenuActions } from "./models";

interface Props {
  papa: PapaConcealed | null;
  action: PapaMenuActions | null;
  closeModal: () => void;
  onRefetch: () => void;
}

const Actions = ({ papa, action, closeModal, onRefetch }: Props) => {
  if (!papa) return null;

  const businessName = papa?.business?.data?.name!;
  const phoneNumber = papa?.business?.data?.currentBusinessPolicy?.data?.warmTransferPhoneNumber!;

  return (
    <>
      <LocationFormModal
        isOpen={action === PapaMenuActions.addLocation}
        toggle={closeModal}
        papaId={papa?.id ?? ""}
        onSubmittingFinish={onRefetch}
      />

      <ModalLayout
        toggle={closeModal}
        title="Edit Papa Status"
        isOpen={action === PapaMenuActions.setStatus}
      >
        <SetStatusForm papaId={papa?.id || ""} closeModal={closeModal} papa={papa} />
      </ModalLayout>

      <PapaDeleteModal
        id={papa?.id || ""}
        fullName={papa?.fullName || ""}
        isOpen={action === PapaMenuActions.delete}
        isToDelete
        toggle={closeModal}
        onDeleteFinish={onRefetch}
      />

      <WarmTransferModal
        id={papa.id!}
        type={SupportTicketRequesterType.Papa}
        businessName={businessName}
        phoneNumber={phoneNumber}
        isOpen={action === PapaMenuActions.warmTransfer}
        closeModal={closeModal}
      />
    </>
  );
};

export default Actions;
