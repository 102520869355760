import React, { useEffect, useState } from "react";

interface RenderParams {
  isOverflowed: boolean;
}

interface Props {
  container: React.FunctionComponent<any>;
  ignoreHeight?: boolean;
  render: (params: RenderParams) => JSX.Element;
  [key: string]: any;
}

export const Overflow: React.FC<Props> = ({
  render,
  container: Container,
  ignoreHeight = false,
  ...rest
}) => {
  const [ref, setRef] = useState<HTMLElement>();

  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);

  useEffect(() => {
    if (ref) {
      const overflowed = ignoreHeight
        ? ref.clientWidth < ref.scrollWidth
        : ref.clientWidth < ref.scrollWidth || ref.clientHeight < ref.scrollHeight;
      setIsOverflowed(overflowed);
    }
  }, [setIsOverflowed, ref?.innerText, ref, ignoreHeight]);

  return (
    <Container ref={(newRef: any) => setRef(newRef)} {...rest}>
      {render({ isOverflowed })}
    </Container>
  );
};
