export type Nullable<T> = T | null | undefined;

export const nonNull = <T>(list: Nullable<Nullable<T>[]> = []) => {
  const newList = [];
  // to fix non_null list
  for (let i = 0; i < (list?.length ?? 0); i++) {
    const item = list?.[i];
    if (item) {
      newList.push(item);
    }
  }

  return newList;
};

type Entity<T> = T & { id?: string | null };

export type WithId<T> = T & { id: string };

export const withIds = <T>(data: Entity<T>[]): WithId<T>[] => {
  const items = [];

  for (const item of data) {
    if (typeof item.id === "string") {
      items.push({
        ...item,
        id: item.id,
      });
    }
  }
  return items;
};

export const withId = <T>(data: Nullable<Entity<T>>) => {
  if (data && typeof data.id === "string") {
    return {
      ...data,
      id: data.id,
    };
  }
  return null;
};

export const nonNullWithId = <T>(list: Nullable<Nullable<Entity<T>>[]> = []) => {
  const newList = [];

  for (let i = 0; i < (list?.length ?? 0); i++) {
    const item = list?.[i];
    if (typeof item?.id === "string") {
      newList.push({
        ...item,
        id: item.id,
      });
    }
  }

  return newList;
};
