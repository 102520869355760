import { PaymentState } from "generated/types";

export enum BillingPointType {
  cancellationFee = "cancellation_fee",
  commute = "commute",
  distance = "distance",
  initialHour = "initial_hour",
  discount = "discount",
  duration = "duration",
}

export const billingPointTypeOptions = [
  { value: BillingPointType.cancellationFee, label: "Cancellation fee" },
  { value: BillingPointType.commute, label: "Commute" },
  { value: BillingPointType.distance, label: "Distance" },
  { value: BillingPointType.initialHour, label: "Initial hour" },
  { value: BillingPointType.discount, label: "Discount" },
  { value: BillingPointType.duration, label: "Duration" },
];

export const paymentStateOptions = [
  { value: PaymentState.Approved, label: "Approved" },
  { value: PaymentState.Charged, label: "Charged" },
  { value: PaymentState.Failed, label: "Failed" },
  { value: PaymentState.Pending, label: "Pending" },
  { value: PaymentState.Refunded, label: "Refunded" },
  { value: PaymentState.Resolved, label: "Resolved" },
];
