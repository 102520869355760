import { useQuery } from "@apollo/client";
import capitalize from "lodash/capitalize";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { PaymentInvoiceEvent, PaymentInvoiceSingleResult } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";

import { GET_PAYMENT_INVOICE_EVENTS } from "./gql";

interface Data {
  paymentInvoice: PaymentInvoiceSingleResult;
}

const Activity = () => {
  const { paymentInvoiceId } = useParams<{ paymentInvoiceId: string }>();
  const { data, loading, error } = useQuery<Data>(GET_PAYMENT_INVOICE_EVENTS, {
    variables: { paymentInvoiceId },
  });
  const paymentInvoiceEvents = data?.paymentInvoice?.data?.paymentInvoiceEvents?.data;

  const columns: Column<PaymentInvoiceEvent>[] = [
    {
      header: { title: "Action" },
      dataField: "action",
      formatter({ action }) {
        return capitalize(action ?? "");
      },
    },
    {
      header: { title: "Created" },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter: ({ insertedAt }) => formatDateTime(insertedAt),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Payment Activity - Papa Admin</title>
      </Helmet>

      <SmartTable<PaymentInvoiceEvent>
        keyField="id"
        data={paymentInvoiceEvents}
        loading={loading}
        columns={columns}
        error={error}
      />
    </>
  );
};

export default Activity;
