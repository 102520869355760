import { FlagFill } from "@styled-icons/bootstrap/FlagFill";
import React from "react";

import Tooltip from "components/Tooltip";
import { VisitFlag } from "generated/types";
import { filterVisitFlags, getGrievancesAppealFlagMessage } from "utils/filterVisitFlags";

export const VisitFlags = ({
  visitFlags,
  isPreviouslyMissed,
  iconSize,
}: {
  visitFlags: VisitFlag[];
  isPreviouslyMissed?: boolean | null;
  iconSize?: number;
}) => {
  const { manuallyMarkedCriticalFlag, grievancesAppealFlag, visitLeadTimeExceptionFlag } =
    filterVisitFlags(visitFlags);

  return (
    <span>
      {!!manuallyMarkedCriticalFlag && (
        <Tooltip key={manuallyMarkedCriticalFlag.id} title={manuallyMarkedCriticalFlag.description}>
          <FlagFill color="red" size={iconSize ?? 20} />
        </Tooltip>
      )}
      {!!grievancesAppealFlag && (
        <Tooltip
          key={grievancesAppealFlag.id}
          title={getGrievancesAppealFlagMessage(grievancesAppealFlag)}
        >
          <FlagFill color="blue" size={iconSize ?? 20} />
        </Tooltip>
      )}
      {isPreviouslyMissed && (
        <Tooltip key="previouslyMissed" title="Member's last visit was missed">
          <FlagFill color="orange" size={iconSize ?? 20} />
        </Tooltip>
      )}
      {visitLeadTimeExceptionFlag && (
        <Tooltip
          key="visitLeadTimeException"
          title="Visit scheduled outside of suggested lead time"
        >
          <FlagFill color="purple" size={iconSize ?? 20} />
        </Tooltip>
      )}
    </span>
  );
};
