import { OperationVariables, useApolloClient } from "@apollo/client";
import { Times } from "@styled-icons/fa-solid/Times";
import { useField, useFormikContext } from "formik";
import React, { useState } from "react";
import Async from "react-select/async";
import { OptionTypeBase, ValueType } from "react-select/src/types";
import styled from "styled-components";

import Button from "components/Button";
import { customStyles } from "components/Select";
import Text from "components/Text";
import { Survey } from "generated/types";
import { useMountEffect } from "hooks/useMountEffect";

import { SURVEYS_SEARCH } from "./gql";

interface ChangeSelectEvent extends OptionTypeBase {
  value: string;
  label: string;
}

export type SelectedItem = {
  value: string;
  label: string;
};

type Props = {
  name: string;
};

const SurveysSelect = ({ name }: Props) => {
  const client = useApolloClient();
  const [{ value }, , helpers] = useField(name);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const { isSubmitting } = useFormikContext();

  const getSurveys = (variables: OperationVariables) =>
    client.query({
      query: SURVEYS_SEARCH,
      variables,
    });

  useMountEffect(() => {
    const getSelectedSurveys = async () => {
      const { data } = await getSurveys({
        filter: { id: { in: value }, isActive: { eq: true } },
        pagination: { limit: 20 },
      });
      const surveys = data?.surveys?.data;

      if (surveys) {
        const options = mapSurveysToOption(surveys);

        setSelectedItems(options);
      }
    };

    if (value?.length) {
      getSelectedSurveys();
    }
  });

  const removeSelectedItem = (computedValue: SelectedItem) => () => {
    if (window.confirm(`Are you sure to delete the ${computedValue.label} ?`)) {
      const newItems = selectedItems.filter((item) => item.value !== computedValue.value);
      setSelectedItems(newItems);
      helpers.setValue(newItems.map((item) => item.value));
    }
  };

  const handleChange = (value: ValueType<ChangeSelectEvent>) => {
    const computedValue = value as ChangeSelectEvent;
    let newSelectedItems: SelectedItem[] = [];

    if (!selectedItems.find((item) => item.value === computedValue.value)) {
      newSelectedItems = [...selectedItems, computedValue];
      setSelectedItems(newSelectedItems);
    }

    helpers.setValue(newSelectedItems.map((item) => item.value));
  };

  async function handleLoadOptions(value: string) {
    const { data } = await getSurveys({
      filter: {
        name: { cont: value },
        isActive: { eq: true },
      },
      pagination: {
        limit: 5,
      },
    });

    if (data?.surveys?.data) {
      return mapSurveysToOption(data.surveys.data);
    }

    return [];
  }

  const mapSurveysToOption = (surveys: Survey[]) =>
    surveys.map(({ id, name }) => {
      return { value: id ?? "", label: name ?? "" };
    });

  const loadOptions = (value = "") => handleLoadOptions(value);

  return (
    <>
      <Async
        isSearchable
        placeholder="Surveys Search"
        loadOptions={loadOptions}
        onChange={handleChange}
        // @ts-ignore TODO:remove this
        styles={customStyles}
        isDisabled={isSubmitting}
        value={[]}
      />
      <Items>
        {selectedItems.map((item) => (
          <Item key={item.value}>
            <Text color="primary">{item.label}</Text>
            <CloseButton onClick={removeSelectedItem(item)} disabled={isSubmitting}>
              <Times size={7} />
            </CloseButton>
          </Item>
        ))}
      </Items>
    </>
  );
};

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;

const Item = styled.div`
  display: inline-flex;
  background: #eaefff;
  padding: 9px 22px;
  position: relative;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const CloseButton = styled(Button).attrs({ action: true, noContent: true })`
  border-width: 0;
  padding: 3px;
  position: absolute;
  top: -3px;
  right: -3px;
`;

export default SurveysSelect;
