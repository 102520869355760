import { gql } from "@apollo/client";

export const TERMINATE_REVIEWED_VISIT = gql`
  mutation terminateReviewedVisit($visitId: ID!) {
    terminateReviewedVisit(id: $visitId) {
      data {
        id
        state
      }
    }
  }
`;
