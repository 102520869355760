import { Lock } from "@styled-icons/boxicons-solid";
import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";

import Form from "components/Form";
import Select from "components/Select";
import Text from "components/Text";
import YesNoRadioGroup from "components/YesNoRadioGroup";
import { genderPreferenceOptions } from "constants/gender";
import { Gender } from "generated/types";
import { Schema } from "pages/EligibleMembers/List/EnrollMemberForm/schema";

type Props = {
  genderPreferenceLocked?: boolean | null;
  genderPreferenceLabel?: string;
};

const GenderPreferences = ({
  genderPreferenceLocked,
  genderPreferenceLabel = "Gender Preference",
}: Props) => {
  //----------------------------------------------------------------------------
  // FORMIK
  //----------------------------------------------------------------------------
  const { errors } = useFormikContext<Schema>();

  const [{ value: gender }, ,] = useField("gender");
  const [{ value: genderPreferenceIsFlexible }, , { setValue: setGenderPreferenceIsFlexible }] =
    useField("genderPreferenceIsFlexible");
  const [{ value: genderPreference }, ,] = useField("genderPreference");
  const [{ value: genderPreferenceWasAsked }, , { setValue: setGenderPreferenceWasAsked }] =
    useField("genderPreferenceWasAsked");

  /*
   Only display the pal gender preference section if:
     1. The form is in edit mode for an existing papa
     2. The gender of a NEW member is NOT set to `Gender.Male`
  */
  const [{ value: memberGender }] = useField<string>("gender");

  //----------------------------------------------------------------------------
  // HOOKS
  //----------------------------------------------------------------------------
  // If the gender preference is set to not listed or null we want to also
  // unset genderPreferenceIsFlexible
  useEffect(() => {
    if ([Gender.Diverse, null].includes(genderPreference)) {
      setGenderPreferenceIsFlexible(null);
    }
  }, [genderPreference, setGenderPreferenceIsFlexible]);

  const onGenderPreferenceChanged = (value: any) => {
    const genderPreferenceIsSet = [Gender.Male, Gender.Female].includes(value);
    setGenderPreferenceWasAsked(genderPreferenceIsSet);
  };

  //----------------------------------------------------------------------------
  // RENDERING
  //----------------------------------------------------------------------------
  const requireGenderPreferenceIsFlexible = Object.keys(errors).includes(
    "genderPreferenceIsFlexible"
  );

  const renderAskedPreference = () => {
    if (gender !== Gender.Male) {
      return (
        <YesNoRadioGroup
          name="genderPreferenceWasAsked"
          label="Did we ask the member their pal gender preference?"
          value={genderPreferenceWasAsked}
          required
          onChange={setGenderPreferenceWasAsked}
        />
      );
    }
  };

  const renderGenderPreferenceFlexibility = () => {
    const genderPreferenceIsSet = [Gender.Male, Gender.Female].includes(genderPreference);
    if (genderPreferenceIsSet) {
      return (
        <YesNoRadioGroup
          name="genderPreferenceIsFlexible"
          label="If no pals matching that gender are available, would you be open to any pal?"
          value={genderPreferenceIsFlexible}
          required
          showRequiredError={requireGenderPreferenceIsFlexible}
          onChange={setGenderPreferenceIsFlexible}
        />
      );
    }
  };

  const renderGenderPreference = () => {
    const genderFieldLocked = !!genderPreferenceLocked;

    return (
      <Form.Group>
        <Form.Label>{genderPreferenceLabel}</Form.Label>
        <Select
          name="genderPreference"
          options={genderPreferenceOptions}
          isSearchable={false}
          placeholder="Select gender"
          isDisabled={genderFieldLocked}
          aria-label={genderPreferenceLabel}
          onChange={onGenderPreferenceChanged}
        />
        {genderFieldLocked && (
          <LockedInfo>
            <Lock size={18} />
            <Text>Locked by G&A</Text>
          </LockedInfo>
        )}
      </Form.Group>
    );
  };

  if (memberGender === Gender.Male) return null;

  return (
    <>
      {renderGenderPreference()}
      {renderGenderPreferenceFlexibility()}
      {renderAskedPreference()}
    </>
  );
};

const LockedInfo = styled.div`
  display: flex;
  margin-top: 10px;
`;

export default GenderPreferences;
