import React from "react";

import { ModalLayout } from "components/Modal/ModalLayout";
import { PalStatus } from "generated/types";

import ChangeStatusForm from "./ChangeStatusForm";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  palId?: string | null;
  status: PalStatus | null;
}

export const ChangeStatusFormModal: React.FC<Props> = ({ isOpen, toggle, palId, status }) => {
  if (!palId) return null;
  return (
    <ModalLayout toggle={toggle} title="Edit Pal Status" isOpen={isOpen}>
      <ChangeStatusForm palId={palId} status={status} onChangeFinish={toggle} />
    </ModalLayout>
  );
};
