import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { confirm } from "components/Confirm";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import { FeatureFlags } from "constants/featureFlags";
import {
  PapaActivityLog,
  PapaActivityLogType,
  SupportTicketRequesterType,
  useDeletePapaComplaintMutation,
  usePapaActivityLogsQuery,
} from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useCurrentAccountPermission } from "hooks/useCurrentAccountPermission";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { useUrlQuery } from "hooks/useUrlQuery";
import CreatePapaComplaintModal from "modals/CreatePapaComplaintModal";
import { nonNull } from "utils/nonNull";

import EditComment from "./EditComment";
import { Filter } from "./Filter";
import { useActivityLogTableColumns } from "./useActivityLogTableColumns";

const Activity = () => {
  const { id: papaId } = useParams<{ id: string }>();
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const [papaComment, setPapaComment] = useState<PapaActivityLog | null>(null);
  const { isOpen: showEditModal, toggle: toggleEditModal } = useModalToggle();
  const { isOpen: createPapaComplaintModalOpen, toggle: toggleCreatePapaComplaintModal } =
    useModalToggle();
  const showCreateComplaintButton = useCurrentAccountPermission(["CAN_CREATE_COMPLAINT"]);
  const [deletePapaComplaint] = useDeletePapaComplaintMutation();

  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);

  const { submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();

  const viewTicketsOnClick = () => {
    // this won't actually submit a ticket, but bring them to the user page where the can view all
    // the zendesk tickets for that user, and potentially create a ticket for them form that page as
    // well
    handleSubmitSupportTicket({ id: papaId, type: SupportTicketRequesterType.Papa });
  };

  const { type } = useUrlQuery();

  const createPapaComment = () => {
    setPapaComment(null);
    toggleEditModal();
  };
  const editPapaComment = (papaComment: PapaActivityLog) => {
    setPapaComment(papaComment);
    toggleEditModal();
  };

  const {
    data: activityLogData,
    loading: activityLogLoading,
    error: activityLogError,
    refetch: activityLogRefetch,
  } = usePapaActivityLogsQuery({
    variables: {
      papaId,
      filter: {
        type: {
          in: (type ?? []) as PapaActivityLogType[],
        },
      },
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const attemptDeleteComplaint = async (id: string) => {
    if (!(await confirm("Are you sure you want to delete this complaint?"))) return;

    try {
      const { data } = await deletePapaComplaint({
        variables: {
          id: id!,
        },
      });

      if (data?.deleteMemberComplaint?.data?.softDeletedAt) {
        toast.success("Complaint deleted successfully!");
        activityLogRefetch();
      } else {
        throw Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const { columns } = useActivityLogTableColumns({
    editPapaComment: editPapaComment,
    attemptDeleteComplaint: attemptDeleteComplaint,
  });

  const logs = nonNull(activityLogData?.papaActivityLogs?.data);

  return (
    <>
      <Heading color="primary">Activity</Heading>
      <AddButtonContainer>
        {showCreateComplaintButton && (
          <Button variant="danger" action onClick={toggleCreatePapaComplaintModal}>
            Add complaint
          </Button>
        )}
        <AddPapaCommentButton variant="primary" action onClick={() => createPapaComment()}>
          Add Papa comment
        </AddPapaCommentButton>
      </AddButtonContainer>
      <Filter />
      <SmartTable<PapaActivityLog>
        keyField="tableId"
        data={logs}
        loading={activityLogLoading}
        error={activityLogError}
        columns={columns}
        pagination={activityLogData?.papaActivityLogs?.pagination}
      />
      <ModalLayout
        toggle={toggleEditModal}
        title={`${papaComment ? "Edit" : "Add"} Papa comment`}
        isOpen={showEditModal}
      >
        <EditComment
          papaId={papaId}
          papaComment={papaComment}
          onEditFinish={() => {
            toggleEditModal();
            activityLogRefetch();
          }}
        />
      </ModalLayout>
      <CreatePapaComplaintModal
        papaId={papaId}
        isOpen={createPapaComplaintModalOpen}
        toggle={toggleCreatePapaComplaintModal}
        onCreateFinish={() => {
          activityLogRefetch();
        }}
      />
      {supportTicketingEnabled && (
        <>
          <Heading color="primary">Tickets</Heading>
          <Button variant="primary" onClick={viewTicketsOnClick}>
            View Tickets
          </Button>
        </>
      )}
    </>
  );
};

export default Activity;

const AddButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AddPapaCommentButton = styled(Button)`
  margin-left: 1rem;
`;
