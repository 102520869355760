import { Moment } from "moment-timezone";

import { BusinessPolicy } from "generated/types";

export const isOutsideBusinessPolicy = (businessPolicies: BusinessPolicy[], day: Moment) => {
  if (!businessPolicies.length) return false;

  return !businessPolicies.some(({ startsAt, endsAt }) => {
    return day.isBetween(startsAt, endsAt, "day", "[]");
  });
};
