import { Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import Text from "components/Text";
import {
  typePriorityBonusOptions,
  typePriorityBonusOverrideOptions,
} from "constants/priorityBonus";
import {
  PriorityBonusReason,
  VisitState,
  useCreatePriorityBonusMutation,
  useCreatePriorityBonusOverrideMutation,
} from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import * as segment from "utils/segment";

import { FormValues, schema } from "./schema";

interface Props {
  visitId: string;
  visitState?: VisitState | null;
  closeModal: () => void;
  onSubmitFinish: () => void;
}

const CreatePriorityBonusForm: React.FC<Props> = ({
  visitId,
  visitState,
  closeModal,
  onSubmitFinish,
}) => {
  const [amount, setAmount] = useState<number>(5);
  const { isSupervisor } = useCurrentAccountRole();

  const [createPriorityBonusMutation] = useCreatePriorityBonusMutation();
  const [createPriorityBonusOverrideMutation] = useCreatePriorityBonusOverrideMutation();

  const initialValues: FormValues = {
    amountInDollars: "5",
    reason:
      visitState !== VisitState.Pending && visitState !== undefined
        ? PriorityBonusReason.SupervisorOnly
        : null,
  };

  const handleChange = (event: any) => {
    const amount = parseInt(event.target.value, 10);
    setAmount(amount);
  };

  const handleSubmit = async (values: FormValues) => {
    const amountInCents = parseInt(values.amountInDollars, 10) * 100;
    segment.createPriorityBonusButtonClicked();
    try {
      if (values.reason === PriorityBonusReason.SupervisorOnly) {
        const { data } = await createPriorityBonusOverrideMutation({
          variables: {
            visitId: visitId,
            input: {
              amountInCents: amountInCents,
            },
          },
        });

        if (data?.createPriorityBonusOverride?.data) {
          toast.success("Priority Bonus was created with success!");
          onSubmitFinish();
          closeModal();
        } else {
          throw new Error("It was not possible to create the priority bonus");
        }
      } else {
        const { data } = await createPriorityBonusMutation({
          variables: {
            visitId: visitId,
            input: {
              amountInCents: amountInCents,
              reason: values.reason,
            },
          },
        });

        if (data?.createPriorityBonus?.data) {
          toast.success("Priority Bonus was created with success!");
          onSubmitFinish();
          closeModal();
        } else {
          throw new Error("It was not possible to create the priority bonus");
        }
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values }) => (
        <ModalStyledForm>
          <ModalContainer>
            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Reason</Form.Label>
                  <Select
                    name="reason"
                    aria-label="reason"
                    options={
                      isSupervisor
                        ? [...typePriorityBonusOptions, ...typePriorityBonusOverrideOptions]
                        : typePriorityBonusOptions
                    }
                    isSearchable={true}
                    placeholder="Select reason"
                    isDisabled={visitState !== VisitState.Pending && visitState !== undefined}
                  />
                  {visitState !== VisitState.Pending && visitState !== undefined && (
                    <Text size="small" color="secondary">
                      *Reason is automatically set to "Special Request (Supervisor Only)" for visits
                      not Pending*
                    </Text>
                  )}
                </Form.Group>
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>

                  <Form.Row>
                    <AmountText>
                      <Text size="large" marginBottom=".5rem" padding=".25rem">
                        ${" "}
                      </Text>
                      <DollarInputContainer>
                        <Form.Input
                          color={!isSupervisor && amount > 75 ? "red" : ""}
                          aria-label="amount"
                          name="amountInDollars"
                          type="number"
                          min={1}
                          max={isSupervisor ? 500 : 75}
                          fullWidth={true}
                          onChange={handleChange}
                        ></Form.Input>
                      </DollarInputContainer>
                      <Text size="large" padding=".5rem">
                        .00
                      </Text>
                    </AmountText>
                  </Form.Row>
                </Form.Group>
                {!isSupervisor && amount > 75 ? (
                  <Text size="small" color="error">
                    Need to go over $75? Contact a supervisor.
                  </Text>
                ) : (
                  ""
                )}
              </Form.Col>
            </Form.Row>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};

export default CreatePriorityBonusForm;

const AmountText = styled.span`
  display: flex;
  flex-direction: row;
  width: 9rem;
`;

const DollarInputContainer = styled.div`
  display: flex;
  width: 9rem;
  flex-direction: column;
`;
