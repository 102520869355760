import { createContext } from "react";

type Props = {
  sidebarCollapsed: boolean;
};

const SidebarContext = createContext<Props>({
  sidebarCollapsed: false,
});

export default SidebarContext;
