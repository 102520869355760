import { gql } from "@apollo/client";

export const GET_ELIGIBLE_EMPLOYEE = gql`
  query getEligibleEmployee($id: ID!) {
    eligibleEmployee(id: $id) {
      data {
        id
        dob
        employeeId
        firstName
        lastName
      }
    }
  }
`;

export const ACCEPT_ELIGIBLE_EMPLOYEE = gql`
  mutation acceptEligibleEmployeeAction($id: ID!, $input: AcceptEligibleEmployeeInput) {
    acceptEligibleEmployee(id: $id, input: $input) {
      data {
        token
        account {
          data {
            id
          }
        }
        caregiver {
          data {
            id
          }
        }
        activity {
          data {
            id
          }
        }
      }
    }
  }
`;
