import { Formik } from "formik";
import React from "react";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import { locationOptions } from "constants/location";

import { Schema, schema } from "./schema";

type Props = {
  initialValues: Schema;
  onSubmit: (values: Schema) => Promise<void>;
  onClose: () => void;
};

export const BaseRateForm = ({ initialValues, onSubmit, onClose }: Props) => {
  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Row>
            <Form.Col>
              <Form.Group controlId="hourlyRate">
                <Form.Label>Hourly Rate</Form.Label>
                <Form.Input type="number" name="hourlyRate" aria-label="hourlyRate" />
              </Form.Group>
            </Form.Col>
            <Form.Col>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Select name="state" options={locationOptions} aria-label="state" />
              </Form.Group>
            </Form.Col>
          </Form.Row>
        </ModalContainer>
        <ModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
