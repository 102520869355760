import { GoogleMap } from "@react-google-maps/api";
import React from "react";

import { VisitPathNode } from "generated/types";

import VisitPathMapElements from "./VisitPathMapElements";
import { getVisitPathWithLastCommutePoint } from "./getVisitPathWithLastCommutePoint";

type Props = {
  commutePath: VisitPathNode[];
  visitPath: VisitPathNode[];
};

const CommuteMap = ({ commutePath, visitPath }: Props) => {
  const { lat, lng } = commutePath[commutePath.length - 1] ?? visitPath[visitPath.length - 1];

  const center = { lat: lat ?? 0, lng: lng ?? 0 };

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
      <>
        <VisitPathMapElements
          path={commutePath}
          strokeColor="#418bf4"
          startIcon={MARKER_ICONS.START}
        />
        <VisitPathMapElements
          path={getVisitPathWithLastCommutePoint({
            commutePath,
            visitPath,
          })}
          strokeColor="#41f495"
          startIcon={MARKER_ICONS.PICKUP}
          endIcon={MARKER_ICONS.END}
        />
      </>
    </GoogleMap>
  );
};

const MARKER_ICONS = {
  START: "/images/map-icons/location-arrow.svg",
  PICKUP: "/images/map-icons/city-hall.svg",
  END: "/images/map-icons/embassy.svg",
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

export default CommuteMap;
