import React, { FunctionComponent } from "react";

import { ProspectPalMenuActions as DataTableMenuActions } from "components/ActionsMenu/ProspectPalActionMenu/models";
import { PalOnboarding } from "generated/types";
import { SendVerificationEmailModal } from "modals/SendVerificationEmailModal";
import { SetWatchedOrientationVideo } from "modals/SetWatchedOrientationVideo";
import { DeletablePalType } from "pages/Pals/PalDelete";
import { PalDeleteModal } from "pages/Pals/PalDeleteModal";

interface Props {
  action: DataTableMenuActions | null;
  palOnboarding: PalOnboarding | null;
  closeModal: () => void;
}

const ProspectPalActions: FunctionComponent<Props> = ({
  action,
  palOnboarding,
  closeModal,
}: Props) => {
  if (!palOnboarding) return null;

  return (
    <>
      <SendVerificationEmailModal
        email={palOnboarding?.account?.data?.email || ""}
        emailVerified={palOnboarding?.account?.data?.emailVerified}
        toggle={closeModal}
        isOpen={action === DataTableMenuActions.SendVerificationEmail}
      />
      <SetWatchedOrientationVideo
        email={palOnboarding?.account?.data?.email || ""}
        id={palOnboarding.id}
        closeModal={closeModal}
        isOpen={action === DataTableMenuActions.SetWelcomeVideoValue}
      />
      <PalDeleteModal
        palType={DeletablePalType.ProspectPal}
        id={palOnboarding?.id || ""}
        accountId={palOnboarding?.account?.data?.id || ""}
        fullName={palOnboarding?.account?.data?.fullName || ""}
        isOpen={action === DataTableMenuActions.Delete}
        toggle={closeModal}
      />
    </>
  );
};

export default ProspectPalActions;
