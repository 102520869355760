import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import VisitsTable from "components/DataTables/VisitsTable";
import { VisitSorting } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useSorting } from "hooks/useSorting";

import { GET_CAREGIVER_VISITS } from "./gql";

const Visits: React.FC = () => {
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const { caregiverId } = useParams<{ caregiverId: string }>();
  const [sorting, setSorting] = useSorting<VisitSorting>(["scheduledFor"]);

  const { data, loading, error } = useQuery(GET_CAREGIVER_VISITS, {
    variables: {
      caregiverId,
      sorting,
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  return (
    <>
      <Helmet>
        <title>Caregiver&apos;s Visits - Papa Admin</title>
      </Helmet>
      <VisitsTable
        data={data?.visits.data}
        fromCaregiver={true}
        pagination={data?.visits.pagination}
        sorting={sorting}
        onSortChange={setSorting}
        loading={loading}
        error={error}
        cursorPagination
      />
    </>
  );
};

export default Visits;
