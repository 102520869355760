import { useQuery } from "@apollo/client";
import { CheckCircle } from "@styled-icons/material-twotone/CheckCircle";
import React from "react";
import Helmet from "react-helmet";

import Button from "components/Button";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { ModalLayout } from "components/Modal/ModalLayout";
import Search from "components/Search";
import SearchPills from "components/Search/SearchPills";
import { useSearch } from "components/Search/useSearch";
import SmartTable from "components/SmartTable";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { Objective, ObjectivesData } from "types/objective";
import { countFilters, objectiveFilterMaker } from "utils/objectiveFilters";

import FiltersForm from "./FiltersForm";
import ObjectivesListActions from "./ObjectivesListActions";
import { GET_OBJECTIVES } from "./gql";
import { useObjectivesSearch } from "./useObjectivesSearch";
import { useObjectivesTableColumns } from "./useObjectivesTableColumns";

const ObjectivesList: React.FC = () => {
  useTopBarOptions({ title: "Objectives", icon: CheckCircle });
  const { isEngineeringAdmin, isSuperAdmin } = useCurrentAccountRole();
  const { beforeCursor, afterCursor, limit, tasks } = usePageInfo();
  const {
    filter: searchFilter,
    pills,
    setPills,
  } = useSearch({
    deletedAt: { eq: null },
  });
  const filter = {
    ...searchFilter,
    ...objectiveFilterMaker({ tasks }),
  };
  const countFilter = countFilters(tasks);

  const { columns, action, objective, closeModal, createObjective } = useObjectivesTableColumns();
  const { data, loading, error } = useQuery<ObjectivesData>(GET_OBJECTIVES, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter: Object.keys(filter).length > 0 ? filter : null,
    },
  });
  const objectives = data?.visitObjectiveTemplates?.data;
  const pagination = data?.visitObjectiveTemplates?.pagination;
  const { loadOptions, searchPillNames } = useObjectivesSearch(objectives!);
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();

  return (
    <div>
      <Helmet>
        <title>Objectives - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <FlexRow>
          <Search
            pills={pills}
            setPills={setPills}
            loadOptions={loadOptions}
            placeholder="Search by description"
          />
          <Button type="button" onClick={toggleFiltersModal}>
            <span>Filter</span>
            {countFilter > 0 && <span>({countFilter})</span>}
          </Button>
        </FlexRow>

        {(isEngineeringAdmin || isSuperAdmin) && (
          <Button action onClick={createObjective}>
            New Objective
          </Button>
        )}
      </FlexRow>

      <SearchPills pills={pills} setPills={setPills} names={searchPillNames} loading={loading} />

      <SmartTable<Objective>
        keyField="id"
        data={objectives}
        loading={loading}
        columns={columns}
        pagination={pagination}
        error={error}
        cursorPagination
      />

      <ObjectivesListActions action={action} objective={objective} closeModal={closeModal} />

      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Objectives Filter"
        isOpen={isFiltersModalOpen}
      >
        <FiltersForm onSubmit={toggleFiltersModal} />
      </ModalLayout>
    </div>
  );
};

export default ObjectivesList;
