import { useState } from "react";

import { PaymentInvoiceMenuActions as DataTableMenuActions } from "components/ActionsMenu/PaymentInvoicesMenu/models";
import { PaymentInvoice } from "generated/types";

export const usePaymentInvoiceActions = () => {
  const [paymentInvoice, setPaymentInvoice] = useState<PaymentInvoice | null>(null);
  const [action, setPaymentInvoiceAction] = useState(DataTableMenuActions.noAction);

  const setAction = (action: DataTableMenuActions, pal: PaymentInvoice | null = null) => {
    setPaymentInvoice(pal);
    setPaymentInvoiceAction(action);
  };

  return {
    paymentInvoice,
    action,
    setAction,
  };
};
