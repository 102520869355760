import * as yup from "yup";

import { ServiceRequestClosedReason, ServiceRequestStatus } from "generated/types";

export interface Schema {
  status: ServiceRequestStatus;
  closedReason?: ServiceRequestClosedReason | null;
}

export const schema = yup.object().shape({
  status: yup.string().nullable().required("Please select status"),
  closedReason: yup
    .string()
    .nullable()
    .when("status", {
      is: (status: ServiceRequestStatus) => status === ServiceRequestStatus.Closed,
      then: yup.string().nullable().required("Please select closed reason"),
    }),
});
