import { gql } from "@apollo/client";

export const GET_SERVICEREQUEST_TYPES = gql`
  query getServiceRequestsTypes {
    serviceRequestTypes {
      data {
        id
        name
      }
    }
  }
`;
