import { VisitPathNode } from "generated/types";

type Props = {
  commutePath: VisitPathNode[];
  visitPath: VisitPathNode[];
};

export const getVisitPathWithLastCommutePoint = ({ commutePath, visitPath }: Props) => {
  if (commutePath.length > 0 && visitPath.length > 0)
    return [commutePath[commutePath.length - 1], ...visitPath];

  return visitPath;
};
