import { gql } from "@apollo/client";

export const SEARCH_PAPAS = gql`
  query searchResultPapas($search: String!) {
    searchResult(search: $search, index: "papas") {
      data {
        ... on Papa {
          id
          isEligible
          eligibleLife {
            data {
              id
            }
          }
          fullName
          phoneNumber
          relationship
          birthDate
          gender
          insertedAt
          language
          status
          dataVisibility {
            concealed
          }
          account {
            data {
              id
              fullName
            }
          }
          surveySubmissions {
            data {
              submittedAt
            }
          }
          visits(filter: { state: { eq: COMPLETED } }) {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
