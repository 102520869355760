import { useMutation } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Formik, Form as FormikForm } from "formik";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import CardElementWithToggle from "components/CreditCard/CardElementWithToggle";
import { useCreateCreditCard } from "components/CreditCardSelect/useCreateCreditCard";
import { Drawer } from "components/Drawer";
import Form from "components/Form";
import { GenderInput } from "components/GenderInput";
import InputDate from "components/InputDate";
import InputPhone from "components/InputPhone";
import ConfirmModal from "components/Modal/ConfirmModal";
import AutomatedCallsSwitch from "components/PapaAutomatedCalls/Switch";
import MarketingConsentSwitch from "components/PapaMarketingConsent/Switch";
import TextMessagingStatusIcon from "components/PapaTextMessaging/StatusIcon";
import TextMessagingSwitch from "components/PapaTextMessaging/Switch";
import Select from "components/Select";
import Text from "components/Text";
import MemberSelect from "components/UserSelect/MemberSelect";
import config from "config";
import { FeatureFlags } from "constants/featureFlags";
import { DATA_CONCEALED } from "constants/fieldsPermissions";
import { languageOptions } from "constants/language";
import { preferredContactsOptions } from "constants/preferredContacts";
import { relationshipOptions } from "constants/relationship";
import {
  AccountType,
  Gender,
  Language,
  PreferredContactMethod,
  Relationship,
} from "generated/types";
import { Papa } from "generated/types";
import { useCallItClientProgramOrMember } from "hooks/useCallItClientProgramOrMember";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { MemberIdInput } from "pages/Papas/List/CreatePapa/MemberIdInput";
import { isFieldProtectedFN } from "utils/fieldsPermissions/isFieldProtected";
import { omitProtectedFields } from "utils/fieldsPermissions/omitProtectedFields";
import { mapSelectValuesToOptions } from "utils/select";
import { formatFullName } from "utils/strings/formatFullName";

import { Schema } from "./EditOverview.schema";
import { UPDATE_PAPA_OVERVIEW } from "./gql";

type Props = {
  papaId: string;
  papa?: Papa | null;
  closeDrawer: () => void;
  onRefetch?: () => void;
};

const EditOverview = ({ papaId, papa, closeDrawer, onRefetch = () => {} }: Props): ReactElement => {
  const {
    capitalized: { singularEntityName },
  } = useCallItClientProgramOrMember();

  const { isSupervisor } = useCurrentAccountRole();
  const [updatePapa, { error: mutationError }] = useMutation(UPDATE_PAPA_OVERVIEW);
  const { createCreditCard, saveCreditCard } = useCreateCreditCard();
  const { isOpen: confirmModalOpen, toggle: toggleConfirmModal } = useModalToggle();
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const [maskedChanges, setMaskedChanges] = useState<
    Array<{
      palId: string;
      oldMaskNumber: string;
      newMaskNumber: string;
    }>
  >([]);

  const initialValues: Schema = {
    gender: papa?.gender || null,
    genderText: papa?.genderText || null,
    language: papa?.language || null,
    email: papa?.email || null,
    birthDate: papa?.birthDate || null,
    accountId: papa?.account?.data?.id || null,
    relationship: papa?.relationship || null,
    preferredContactMethod: papa?.preferredContactMethod || null,
    fixedPhoneNumber: papa?.fixedPhoneNumber || null,
    phoneNumber: papa?.phoneNumber || null,
    memberId: papa?.memberId || "",
    covidVaccinated: papa?.covidVaccinated || false,
    fullName: papa?.fullName || "",
  };

  const isFieldProtected = isFieldProtectedFN(papa?.dataVisibility);

  const schema = yup.object().shape({
    gender: yup.string().nullable(),
    genderText: yup.string().nullable().max(255),
    language: yup.string().required("Language is required").nullable(),
    email: yup
      .string()
      .email("Email invalid")
      .when("preferredContactMethod", {
        is: PreferredContactMethod.Email,
        then: yup.string().required("Email is required"),
      })
      .nullable(),
    birthDate: isFieldProtected("birthDate")
      ? yup.string().nullable()
      : yup.string().nullable().required(),
    accountId: yup.string().required(`${singularEntityName} is required`),
    relationship: yup.string().required("Relationship is required").nullable(),
    preferredContactMethod: yup
      .string()
      .required("Preferred contact method is required")
      .nullable(),
    fixedPhoneNumber: yup
      .string()
      .when("preferredContactMethod", {
        is: PreferredContactMethod.FixedPhoneNumber,
        then: yup.string().required("Home number is required"),
      })
      .nullable(),
    phoneNumber: isFieldProtected("phoneNumber")
      ? yup.string().nullable()
      : yup
          .string()
          .when("preferredContactMethod", {
            is: (value) =>
              value === PreferredContactMethod.CellPhoneNumber ||
              value === PreferredContactMethod.Text,
            then: yup.string().required("Phone number is required"),
          })
          .nullable(),
    memberId: yup.string().nullable(),
    covidVaccinated: yup.boolean(),
    fullName: isFieldProtected("fullName")
      ? yup.string().nullable()
      : yup.string().nullable().required(),
  });

  const handleSubmit = async (values: Schema) => {
    const { memberId, fullName, ...input } = values;
    if (input?.birthDate) {
      input.birthDate = input.birthDate.split("T")[0];
    }

    // genderText is for when Gender.Diverse is selected
    if ([Gender.Male, Gender.Female].includes(input?.gender!)) {
      input.genderText = null;
    }

    try {
      const { error, tokenId, card, cardElement } = await createCreditCard();

      if (cardElement && (error || !tokenId || !card)) {
        // @ts-ignore
        toast.error((error as Error)?.message || "Unable to create a card");

        return;
      }

      if (!config.featureFlag.papaCreditCard) {
        input.stripeToken = tokenId;
      }
      const newInput = omitProtectedFields(
        {
          ...input,
          fullName: formatFullName(fullName!),
          memberId: memberId?.trim(),
        },
        papa?.dataVisibility
      );

      const promises = [
        updatePapa({
          variables: {
            id: papaId,
            input: newInput,
          },
        }),
      ];

      if (cardElement && config.featureFlag.papaCreditCard) {
        const creditCardPromise = saveCreditCard({ papaId }, { tokenId, card });

        promises.push(creditCardPromise);
      }

      const [{ data: papaData }, creditCard] = await Promise.all(promises);

      if (papaData?.updatePapa?.data && (!creditCard || creditCard?.data?.data?.id)) {
        toast.success("Papa overview was edited with success!");
        alertMaskedNumberChanges(papaData?.updatePapa?.data?.maskedPhoneChanges);
        onRefetch();
      }

      if (mutationError) {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const alertMaskedNumberChanges = (
    maskedChangesRecieved: {
      palId: string;
      oldMaskNumber: string;
      newMaskNumber: string;
    }[]
  ) => {
    if (!maskedChangesRecieved) {
      closeDrawer();
    } else {
      toggleConfirmModal();
      setMaskedChanges([...maskedChangesRecieved]);
    }
  };

  const closeMaskedNumberWarning = () => {
    toggleConfirmModal();
    closeDrawer();
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ values }) => (
          <FormikForm>
            <Drawer.Container>
              <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="2rem">
                Personal Information
              </Text>

              {(isSupervisor || papa?.account?.data?.type === AccountType.Regular) && (
                <Form.Group>
                  <Form.Label required={!isFieldProtected("fullName")}>Name</Form.Label>
                  <Form.Input
                    name="fullName"
                    disabled={isFieldProtected("fullName")}
                    placeholder={isFieldProtected("fullName") ? DATA_CONCEALED : undefined}
                  />
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label required={!isFieldProtected("birthDate")}>Date of Birth</Form.Label>
                <InputDate
                  id="birthDate"
                  name="birthDate"
                  isOutsideRange={(day) => day.isAfter(moment())}
                  disabled={isFieldProtected("birthDate")}
                  withYear
                  block
                />
              </Form.Group>

              <GenderInput initialValue={initialValues.gender} />

              <Form.Group>
                <Form.Label required>Primary Language</Form.Label>
                <Select
                  name="language"
                  defaultValue={mapSelectValuesToOptions<Language>(
                    initialValues.language,
                    languageOptions
                  )}
                  options={languageOptions}
                  isSearchable={false}
                  placeholder="Select language"
                />
              </Form.Group>

              <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="2rem">
                Papa Membership Information
              </Text>

              <Form.Group>
                <Form.Label required>{singularEntityName}</Form.Label>
                <MemberSelect placeholder={papa?.account?.data?.fullName ?? ""} />
              </Form.Group>
              <Form.Group>
                <Form.Label required>Relationship</Form.Label>
                <Select
                  name="relationship"
                  defaultValue={mapSelectValuesToOptions<Relationship>(
                    initialValues.relationship,
                    relationshipOptions
                  )}
                  options={relationshipOptions}
                  isSearchable={false}
                  placeholder="Select relationship"
                />
              </Form.Group>

              <MemberIdInput />

              <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="2rem">
                Contact Information
              </Text>

              <Form.Group>
                <Form.Label
                  required={
                    values.preferredContactMethod === PreferredContactMethod.FixedPhoneNumber
                  }
                >
                  Home Number
                </Form.Label>
                <InputPhone name="fixedPhoneNumber" />
              </Form.Group>

              <Form.Group>
                <Form.Label
                  required={
                    !isFieldProtected("phoneNumber") &&
                    (values.preferredContactMethod === PreferredContactMethod.CellPhoneNumber ||
                      values.preferredContactMethod === PreferredContactMethod.Text)
                  }
                >
                  Mobile Number
                </Form.Label>
                <PhoneInputWithIcon>
                  <InputPhone name="phoneNumber" disabled={isFieldProtected("phoneNumber")} />
                  <TextMessagingStatusIconContainer>
                    <TextMessagingStatusIcon
                      messageServiceAuthorization={papa?.messageServiceAuthorizations?.data?.[0]}
                    />
                  </TextMessagingStatusIconContainer>
                </PhoneInputWithIcon>
                <TextMessagingSwitch
                  onRefetch={onRefetch}
                  papaId={papaId}
                  messageServiceAuthorization={papa?.messageServiceAuthorizations?.data?.[0]}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label
                  required={values.preferredContactMethod === PreferredContactMethod.Email}
                >
                  Email
                </Form.Label>
                <Form.Input aria-label="papa email" name="email" />
                <MarketingConsentSwitch papaId={papaId} enabled={!!papa?.marketingConsent} />
              </Form.Group>

              <Form.Group>
                <Form.Label required>Preferred contact</Form.Label>
                <Select
                  name="preferredContactMethod"
                  options={preferredContactsOptions}
                  aria-label="preferredContactMethod"
                  isSearchable={false}
                />
                <AutomatedCallsSwitch papaId={papaId} enabled={!!papa?.automatedCallOptIn} />
              </Form.Group>

              <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="2rem">
                Health Information
              </Text>

              <Form.Group>
                <Form.Label>Covid-19 Vaccinated</Form.Label>
                <Form.Switch name="covidVaccinated" aria-label="covidVaccinated" />
              </Form.Group>

              {hideCreditCardFields ? null : (
                <>
                  <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="2rem">
                    Credit Card Information
                  </Text>
                  <CardElementWithToggle />
                </>
              )}
            </Drawer.Container>

            <Drawer.Footer>
              <Form.SubmitButton action noContent>
                Save
              </Form.SubmitButton>
            </Drawer.Footer>
          </FormikForm>
        )}
      </Formik>
      <ConfirmModal
        isOpen={confirmModalOpen}
        onCancel={() => closeMaskedNumberWarning()}
        onConfirm={() => closeMaskedNumberWarning()}
        header="This Member has Masked Number Changes"
        confirmBtnText="Confirm"
      >
        {maskedChanges.map((change) => {
          return (
            <>
              <Text key={change.palId}>
                {`Swapped out Pal mask number ${change.oldMaskNumber} for new mask
                ${change.newMaskNumber} because Member now has phone number in common with others`}
              </Text>
              <br />
            </>
          );
        })}
      </ConfirmModal>
    </>
  );
};

const TextMessagingStatusIconContainer = styled.div`
  position: absolute;
  right: 55px;
  top: 10px;
`;

const PhoneInputWithIcon = styled.div`
  position: relative;
`;

export default EditOverview;
