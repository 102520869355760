import { QuestionEnum } from "generated/types";
import { AssessmentType } from "types/assessments";

export type Schema = {
  [key: string]: null | string;
};

export const AssessmentsSurveyNames = {
  [AssessmentType.loneliness]: "Loneliness calculated by next 3 questions combined score",
  [AssessmentType.loneliness4Question]: "Loneliness calculated by next 4 questions combined score",
  [AssessmentType.health]: "CDC MENTAL + Unhealthy Day Scores - Standalone answers",
  [AssessmentType.papaSocialIndex]: "Papa Social Index Assessment",
  [AssessmentType.papaSocialIndexMedicare]: "Papa Social Index Assessment (Medicare)",
};

export const AssessmentsSurveyTitles = {
  [AssessmentType.loneliness]: "Loneliness",
  [AssessmentType.loneliness4Question]: "Loneliness",
  [AssessmentType.health]: "Unhealthy days",
  [AssessmentType.papaSocialIndex]: "Papa social index",
  [AssessmentType.papaSocialIndexMedicare]: "Papa social index (Medicare)",
};

export const getAssessmentType = (title?: string | null) => {
  for (const key in AssessmentsSurveyNames) {
    if (AssessmentsSurveyNames[key as unknown as AssessmentType] === title) {
      return key as AssessmentType;
    }
  }
  return null;
};

export type FormValues = Record<string, string | QuestionEnum | undefined | null | string[]>;
