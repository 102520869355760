import styled, { css } from "styled-components";

export type CellProps = {
  fixed?: boolean;
  align?: "left" | "center" | "right";
  width?: string;
  maxWidth?: string;
  noWrap?: boolean;
};

const StyledCell = styled("td")<CellProps>`
  width: ${({ width }) => (width ? width : "auto")};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  background: ${({ theme }) => theme.background.panelContent};
  font-size: 0.875rem;
  line-height: 1.313rem;
  padding: 0.75rem 0.938rem;
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "initial")};
  vertical-align: top;
  text-align: ${({ align = "left" }) => align};

  &:first-of-type {
    border-radius: ${({ theme }) => `${theme.borderRadius} 0 0 ${theme.borderRadius} `};
  }
  &:last-of-type {
    border-radius: 0 0.438rem 0.438rem 0;
  }

  a {
    color: inherit;
  }

  ${({ fixed }) =>
    fixed &&
    css`
      width: 72px;
      position: sticky;
      right: 0;
      vertical-align: middle;
      background-clip: padding-box;
      padding: 0;
    `}
`;

export default StyledCell;
