import React from "react";
import Helmet from "react-helmet";

import CreditCardsTable from "components/DataTables/CreditCardsTable";

const CreditCards: React.FC = () => (
  <>
    <Helmet>
      <title>Member&apos;s Credit Cards - Papa Admin</title>
    </Helmet>

    <CreditCardsTable />
  </>
);

export default CreditCards;
