import { gql } from "@apollo/client";

export const GET_SERVICEREQUEST_NEEDS = gql`
  query getServiceRequestNeeds($filter: ServiceRequestTypeFilter) {
    serviceRequestTypes(filter: $filter) {
      data {
        id
        name
        needs {
          data {
            code
            name
            id
          }
        }
      }
    }
  }
`;
