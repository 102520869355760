import { useMutation } from "@apollo/client";
import { useField } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { ModalLayout } from "components/Modal/ModalLayout";
import Text from "components/Text";

import { MAKE_PAPA_VACCINATED } from "./gql";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  onProceed: () => void;
}

const PapaCovidVaccinationModal = ({ isOpen, toggle, onProceed }: Props) => {
  const [{ value: papaId }] = useField("papaId");
  const [makePapaVaccinated] = useMutation(MAKE_PAPA_VACCINATED);

  const handleYes = async () => {
    try {
      const { data } = await makePapaVaccinated({ variables: { id: papaId } });

      if (data?.updatePapa?.data?.covidVaccinated) {
        onProceed();
      } else {
        toast.error("Unable to make the papa vaccinated.");
      }
    } catch (error) {
      window.console.error(error);
      toast.error((error as Error).message);
    }

    toggle();
  };

  return (
    <ModalLayout toggle={toggle} title="Covid-19 Vaccination Check" isOpen={isOpen}>
      <Container>
        <Text bold>Papa needs to be fully vaccinated in order to schedule in-person visits</Text>
        <HorizontalLine />
        <Text>Is this papa fully vaccinated?</Text>
        <Footer>
          <Button type="button" variant="primary" onClick={handleYes}>
            Yes
          </Button>
          <Button type="button" variant="secondary" onClick={toggle}>
            Close
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px 34px;
  width: 100%;
  & ${Text} {
    margin: 10px 0;
    text-align: center;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin-top: 3rem;
`;

const HorizontalLine = styled.hr`
  width: 207px;
  height: 0px;

  border: 1px solid #e5e5e5;
`;

export default PapaCovidVaccinationModal;
