import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import GoogleLocationSearch from "components/GoogleLocationSearch";
import InputPhone from "components/InputPhone";
import { Loader } from "components/Loader";
import Select from "components/Select";
import { SelectRoles } from "components/Select/SelectRoles";
import { papaTeamOptions } from "constants/papaTeam";
import { Account, AccountSingleResult } from "generated/types";
import { useAssignRolesMutation } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { formatFullName } from "utils/strings/formatFullName";

import { CREATE_LOCATION, GET_ACCOUNT_ROLES, UPDATE_ACCOUNT, UPDATE_LOCATION } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  account: Partial<Account>;
  toggleShow: (state: boolean) => void;
  forAdmin?: boolean;
  onEditFinish: () => void;
}

const EditOverview = ({ account, toggleShow, onEditFinish, forAdmin = false }: Props) => {
  const { data } = useQuery<{
    account?: AccountSingleResult | null;
  }>(GET_ACCOUNT_ROLES, {
    variables: { id: account.id },
    skip: !forAdmin,
  });
  const initialRoleIds = (data?.account?.data?.roles?.data ?? []).map(({ id }) => id!);
  const { email, phoneNumber, homeLocation, fullName } = account || {};
  const homeLocationId = homeLocation?.data?.id;
  const [updateAccount] = useMutation(UPDATE_ACCOUNT);
  const [createLocation] = useMutation(CREATE_LOCATION);
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const [assignRoles] = useAssignRolesMutation({
    refetchQueries: [{ query: GET_ACCOUNT_ROLES, variables: { id: account.id } }],
  });
  const [loading, setLoading] = useState(false);
  const { isAdmin: isUserAdmin } = useCurrentAccountRole();
  const showAdminSettings = isUserAdmin && forAdmin;

  const initialValues: Schema = {
    fullName: fullName || "",
    phoneNumber: phoneNumber || "",
    email: email || "",
    location: { address: homeLocation?.data?.address || "" },
    roleIds: initialRoleIds,
    isAdmin: account.permissions?.admin ?? false,
    papaTeam: account.papaTeam,
  };

  const handleSubmit = async (formData: Schema) => {
    const { fullName, phoneNumber, email, location, roleIds, isAdmin } = formData;
    const locationInput = location.address
      ? {
          accountId: account.id,
          address: location.address,
          countryIso: location.countryIso,
          houseNumber: location.houseNumber,
          lat: location.lat,
          lng: location.lng,
          state: location.state,
          streetName: location.streetName,
          zipcode: location.zipcode,
        }
      : null;
    const variables = {
      id: account.id,
      input: {
        fullName: formatFullName(fullName),
        phoneNumber,
        email,
        homeLocationId,
        papaTeam: showAdminSettings ? formData.papaTeam : undefined,
        permissions: showAdminSettings ? { admin: isAdmin } : undefined,
      },
    };

    setLoading(true);
    try {
      if (location.address) {
        if (!homeLocationId) {
          const { data } = await createLocation({
            variables: { locationInput },
          });
          if (data?.createLocation?.data?.id) {
            variables.input.homeLocationId = data.createLocation.data.id;
          }
        } else if (homeLocation?.data?.address !== location.address) {
          await updateLocation({
            variables: { id: homeLocationId, locationInput },
          });
        }
      }

      const { data } = await updateAccount({
        variables,
      });

      if (data) {
        toast.success("Account was edited with success!");
        toggleShow(false);
      } else {
        throw new Error("Something is wrong");
      }

      if (!isEqual(roleIds, initialRoleIds) && forAdmin) {
        await assignRoles({
          variables: {
            accountId: account.id as string,
            roleId: roleIds,
          },
        });
      }
      onEditFinish();
    } catch (error) {
      toast.error((error as Error).message);
    }
    setLoading(false);
  };

  if (!data && forAdmin) return <Loader />;

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <StyledForm>
        <Container>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Input name="fullName" aria-label="fullName" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <InputPhone name="phoneNumber" aria-label="phoneNumber" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Input aria-label="email" name="email" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Home Address</Form.Label>
            <GoogleLocationSearch name="location" placeholder="" aria-label="address" />
          </Form.Group>

          {showAdminSettings && (
            <>
              <Form.Group>
                <Form.Label>Has admin permission</Form.Label>
                <Form.Switch name="isAdmin" aria-label="isAdmin" />
              </Form.Group>

              <Form.Group>
                <Form.Label>Team</Form.Label>
                <Select
                  name="papaTeam"
                  options={papaTeamOptions}
                  isSearchable={false}
                  placeholder="Select team"
                  aria-label="team"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Roles</Form.Label>
                <SelectRoles name="roleIds" />
              </Form.Group>
            </>
          )}
        </Container>

        <FormFooter>
          <Button variant="secondary" onClick={() => toggleShow(false)}>
            Close
          </Button>
          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? (
              <>
                <SpinningIcon size={18} />
                <span>Saving...</span>
              </>
            ) : (
              "Save"
            )}
          </Button>
        </FormFooter>
      </StyledForm>
    </Formik>
  );
};

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5rem;
  padding-right: 2.625rem;

  ${Button}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const Container = styled.div`
  padding: 0 2.625rem 0 4.5rem;
`;

const StyledForm = styled(FormikForm)`
  width: 100%;
`;

export default EditOverview;
