import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { PalVisitInfo } from "components/PalVisitInfo";
import PalSelect from "components/UserSelect/AsyncPalSelect";
import { useAssignPalToVisitMutation } from "generated/types";

import { FormValues, schema } from "./schema";

interface Props {
  visitId: string;
  closeModal: () => void;
}

const AssignPalToVisitForm: React.FC<Props> = ({ visitId, closeModal }) => {
  const [assignPalToVisitMutation] = useAssignPalToVisitMutation();

  const initialValues: FormValues = {
    palId: null,
  };

  const handleSubmit = async ({ palId }: FormValues) => {
    if (!palId) return;
    try {
      const { data } = await assignPalToVisitMutation({
        variables: {
          visitId: visitId,
          input: {
            palId: palId,
          },
        },
      });

      if (data?.assignPalToVisit?.data) {
        toast.success("Successfully assigned Pal to visit!");
        closeModal();
      } else {
        throw new Error("It was not possible to assign the Pal");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values }) => (
        <ModalStyledForm>
          <ModalContainer>
            <Form.Row>
              <Form.Col>
                <Form.Group>
                  <Form.Label>Assigned pal</Form.Label>
                  <PalSelect placeholder="Search pals" aria-label="assigned pal" name="palId" />
                </Form.Group>
              </Form.Col>
            </Form.Row>

            <PalVisitInfo palId={values.palId} visitId={visitId} />
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Form.SubmitButton>Save</Form.SubmitButton>
          </ModalFooter>
        </ModalStyledForm>
      )}
    </Formik>
  );
};

export default AssignPalToVisitForm;
