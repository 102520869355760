import React, { useState } from "react";

import Button from "components/Button";

import CreatePapaModal from "./CreatePapaModal";

interface Props {
  onCreateFinish: () => void;
}

const CreatePapa = ({ onCreateFinish }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button action onClick={() => setIsOpen(true)}>
        New Papa
      </Button>

      <CreatePapaModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        onCreateFinish={onCreateFinish}
      />
    </>
  );
};

export default CreatePapa;
