import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

import { Gender, Language, Location, VehicleType } from "generated/types";

export interface Schema {
  fullName: string;
  email: string;
  dateOfBirth: Date | null;
  phoneNumber: string;
  gender: Gender | null;
  palTypeId: string | null;
  genderText?: string;
  languages: Language[];
  school: "";
  fieldOfStudy: "";
  referralSource: "";
  address: Partial<Location>;
  bio: "";
  vehicle: VehicleType | null;
  vehicleModel: string;
  vehicleYear: string;
  vehicleColor: string;
  verified: boolean;
  drugTestClean: boolean;
  testAccount: boolean;
  covidVaccinated: boolean;
}

export const schema = yup.object().shape({
  fullName: yup.string().required("Please input full name."),
  email: yup.string().email("Not a valid email address!").required("Please input email."),
  dateOfBirth: yup.string().nullable(),
  phoneNumber: yup
    .string()
    .required("Please input phone number.")
    .test("phoneNumber", "Not a valid phone number!", (value) =>
      value ? isValidPhoneNumber(value) : true
    ),
  gender: yup.string().nullable().required("Please select gender."),
  palTypeId: yup.string().nullable(),
  genderText: yup.string().nullable(),
  languages: yup.array<Language>().required("Please select languages."),
  school: yup.string(),
  fieldOfStudy: yup.string(),
  referralSource: yup.string(),
  address: yup
    .object()
    .shape({
      address: yup.string().required("Please input address."),
    })
    .nullable(),
  bio: yup.string(),
  vehicle: yup.string().nullable().required("Please select vehicle type."),
  vehicleModel: yup.string(),
  vehicleYear: yup.string(),
  vehicleColor: yup.string(),
  verified: yup.boolean(),
  drugTestClean: yup.boolean(),
  testAccount: yup.boolean(),
  covidVaccinated: yup.boolean(),
});
