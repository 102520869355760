declare global {
  interface Window {
    msw: {
      worker: typeof import("./mocks/browser").worker;
      graphql: typeof import("msw").graphql;
    };
    Cypress?: boolean;
    appReady?: boolean;
  }
}

export async function mswSetup() {
  const { graphql } = await import("msw");
  const { worker } = await import("./mocks/browser");

  window.msw = {
    worker,
    graphql,
  };

  worker.start();

  if (window.Cypress) {
    window.appReady = true;
  }
}
