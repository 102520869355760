import { VisitObjective } from "generated/types";
import { WithId } from "utils/nonNull";

export function sortObjectives(objectives: WithId<VisitObjective>[]) {
  return Array.from(objectives).sort((a, b) => {
    if (!a.description || !b.description || a.description === b.description) return 0;

    return a?.description > b?.description ? 1 : -1;
  });
}
