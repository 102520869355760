import { PalChangeStatusReason, PalStatus } from "generated/types";

export const statusOptions = [
  { value: PalStatus.Activated, label: "Activated" },
  { value: PalStatus.Active, label: "Active" },
  { value: PalStatus.Approved, label: "Approved" },
  { value: PalStatus.Banned, label: "Banned" },
  { value: PalStatus.Churned, label: "Churned" },
  { value: PalStatus.Inactive, label: "Inactive" },
  { value: PalStatus.NeverActivated, label: "Never Activated" },
  { value: PalStatus.Onboarded, label: "Onboarded" },
  { value: PalStatus.Suspended, label: "Suspended" },
  { value: PalStatus.UnderInvestigation, label: "Under Investigation" },
];

export const grievanceOnlyStatuses = [
  PalStatus.Banned,
  PalStatus.Suspended,
  PalStatus.UnderInvestigation,
];

export const reasonOptions = [
  { value: PalChangeStatusReason.BadRating, label: "Bad rating" },
  { value: PalChangeStatusReason.Dishonest, label: "Dishonest" },
  { value: PalChangeStatusReason.GrievanceDetermination, label: "Grievance determination" },
  { value: PalChangeStatusReason.GrievanceInvestigation, label: "Grievance investigation" },
  { value: PalChangeStatusReason.Inactive, label: "Inactive" },
  { value: PalChangeStatusReason.Integrity, label: "Integrity" },
  { value: PalChangeStatusReason.InterestedInWorkingAgain, label: "Interested in working again" },
  { value: PalChangeStatusReason.InvestigationCompleted, label: "Investigation completed" },
  { value: PalChangeStatusReason.Safety, label: "Safety" },
  { value: PalChangeStatusReason.SuspensionCompleted, label: "Suspension completed" },
  { value: PalChangeStatusReason.Unreliable, label: "Unreliable" },
  { value: PalChangeStatusReason.Unreliable, label: "Unreliable" },
  {
    value: PalChangeStatusReason.DoesNotMeetMvrRequirements,
    label: "Does not meet MVR requirements",
  },
];
