import React from "react";
import styled from "styled-components";

import Text from "components/Text";
import { memberMoods } from "constants/papa";
import { PapaMood } from "generated/types";

interface Props {
  mood: PapaMood | undefined;
  setMood: (mood: PapaMood) => void;
}

export const MemberMood: React.FC<Props> = ({ mood, setMood }) => {
  return (
    <Container>
      <Text size="large">Select an option below to describe your member’s mood…</Text>

      <ImagesContainer>
        {memberMoods.map(({ icon: Icon, value, label }) => {
          return (
            <MoodWrapper
              key={value}
              active={mood === value}
              onClick={() => setMood(value)}
              data-testid={`mood-${value}`}
            >
              <Icon />
              <Text size="large">{label}</Text>
            </MoodWrapper>
          );
        })}
      </ImagesContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 63px;

  > ${Text} {
    margin-bottom: 20px;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  grid-gap: 24px;
`;

export const MoodWrapper = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    fill: ${({ active, theme }) => (active ? theme.variants.primary : "#B6BEF8")};
  }

  ${Text} {
    margin-top: 10px;
    color: ${({ active, theme }) => (active ? theme.variants.primary : "#B6BEF8")};
  }
`;
