import { gql } from "@apollo/client";

export const ACCEPT_VISIT = gql`
  mutation acceptVisit($visitId: ID!) {
    acceptVisit(id: $visitId) {
      data {
        id
        state
        pal {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;
