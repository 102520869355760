import { Formik } from "formik";
import React, { ReactElement } from "react";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import { SupportTicketRequesterType } from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";

import { Schema, schema } from "./schema";

interface Props {
  id: string;
  type: SupportTicketRequesterType;
  isOpen: boolean;
  toggle: () => void;
}

const SubmitTicketGuestUserModal = ({ id, type, isOpen, toggle }: Props): ReactElement => {
  const initialValues: Schema = { name: "" };

  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket({
    onSuccess: toggle,
  });

  const handleSubmit = ({ name }: any) => handleSubmitSupportTicket({ id, type, name });

  return (
    <ModalLayout toggle={toggle} title={"Submit Ticket for Guest User"} isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Input
                type="text"
                aria-label="name"
                name="name"
                placeholder="Guest User's Name"
              />
            </Form.Group>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading} variant="primary">
              {loading && <SpinningIcon size={18} />}
              Submit
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};

export default SubmitTicketGuestUserModal;
