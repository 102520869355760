import { useField, useFormikContext } from "formik";
import React from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import Select from "components/Select";
import { useGetServiceRequestFollowupActionOptionsQuery } from "generated/types";
import { nonNull } from "utils/nonNull";

interface Props {
  disabled?: boolean;
}

const FollowupActionSelectGroup = ({ disabled }: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();

  const [{ value: action }] = useField("action");

  const { data, loading } = useGetServiceRequestFollowupActionOptionsQuery();
  const actionOptions = nonNull(data?.getServiceRequestFollowupActionOptions);

  const isDeprecatedAction = !!action && !actionOptions.some((option) => option.value === action);

  // This is for backward compatibility
  const options = isDeprecatedAction
    ? [...actionOptions, { value: action, label: `${action} (deprecated)` }]
    : actionOptions;

  if (loading) return <SpinningIcon size={18} />;

  return (
    <Form.Group>
      <Form.Label required>Follow up action</Form.Label>
      <Select
        name="action"
        options={options}
        isSearchable={false}
        aria-label="action"
        placeholder="Select follow up action"
        onChange={() => {
          setFieldValue("actionText", "");
          setFieldValue("reason", null);
        }}
        isDisabled={disabled || isSubmitting}
      />
    </Form.Group>
  );
};

export default FollowupActionSelectGroup;
