import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";

import { OnlyCheckbox as Checkbox } from "components/Checkbox/OnlyCheckbox";
import Form from "components/Form";
import GoogleLocationSearch from "components/GoogleLocationSearch";
import InputPhone from "components/InputPhone";
import Select from "components/Select";
import Text from "components/Text";
import { preferredContactsOptions } from "constants/preferredContacts";

import { Schema } from "./schema";

export const ContactInformation = () => {
  const {
    values: { doesNotHaveFixedPhoneNumber, doesNotHaveEmail, doesNotHavePhoneNumber },
    setFieldValue,
  } = useFormikContext<Schema>();

  return (
    <>
      <Text as="h3" color="primary" size="largex" marginBottom="1rem" marginTop="2rem">
        Contact Information
      </Text>
      <Form.Group>
        <Form.Label required>Address</Form.Label>
        <GoogleLocationSearch name="location" placeholder="Address" aria-label="address" />
        <Form.Feedback>Google places search</Form.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Address 2nd line</Form.Label>
        <Form.Input aria-label="address2" name="address2" placeholder="Address 2nd line" />
      </Form.Group>
      <Form.Group>
        <Form.Label required>Mobile Number</Form.Label>
        <InputPhone
          name="phoneNumber"
          aria-label="phoneNumber"
          disabled={doesNotHavePhoneNumber}
          placeholder="Mobile number"
        />
        <CheckboxContainerUnderInput>
          <Checkbox
            name="doesNotHavePhoneNumber"
            label="Member does not have a mobile phone"
            onChange={({ target }) => {
              if (target.checked) setFieldValue("phoneNumber", "");

              setFieldValue("enrollTextMessaging", !target.checked);
              setFieldValue("doesNotHavePhoneNumber", target.checked);
            }}
          />
        </CheckboxContainerUnderInput>
        <CheckboxContainerUnderInput>
          <Form.Check
            name="enrollTextMessaging"
            disabled={doesNotHavePhoneNumber}
            label="Opt-in to SMS reminders"
          />
        </CheckboxContainerUnderInput>
      </Form.Group>
      <Form.Group>
        <Form.Label required>Home Number</Form.Label>
        <InputPhone
          name="fixedPhoneNumber"
          aria-label="doesNotHaveFixedPhoneNumber"
          disabled={doesNotHaveFixedPhoneNumber}
          placeholder="Home number"
        />
        <CheckboxContainerUnderInput>
          <Checkbox
            name="doesNotHaveFixedPhoneNumber"
            label="Member does not have a home phone"
            onChange={({ target }) => {
              if (target.checked) setFieldValue("fixedPhoneNumber", "");

              setFieldValue("doesNotHaveFixedPhoneNumber", target.checked);
            }}
          />
        </CheckboxContainerUnderInput>
      </Form.Group>
      <Form.Group>
        <Form.Label required>Email</Form.Label>
        <Form.Input
          aria-label="email"
          name="email"
          disabled={doesNotHaveEmail}
          placeholder="Email"
        />
        <CheckboxContainerUnderInput>
          <Checkbox
            name="doesNotHaveEmail"
            label="Member does not have an email"
            onChange={({ target }) => {
              if (target.checked) setFieldValue("email", "");

              setFieldValue("doesNotHaveEmail", target.checked);
            }}
          />
        </CheckboxContainerUnderInput>
      </Form.Group>

      <Form.Group>
        <Form.Label required>Preferred Contact Method</Form.Label>
        <Select
          name="preferredContactMethod"
          aria-label="preferredContactMethod"
          options={preferredContactsOptions}
          isSearchable={false}
        />
      </Form.Group>
    </>
  );
};

const CheckboxContainerUnderInput = styled.div`
  margin-top: 10px;
`;
