import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ArrowToggle } from "components/ArrowToggle";
import EditButton from "components/EditButton";
import { Overflow } from "components/Overflow";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import { LogType } from "constants/papaActivity";
import { commentOptions } from "constants/papaComment";
import { PapaActivityLog } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { getCommentTypeAndContent } from "utils/strings/getCommentTypeAndContent";

interface Params {
  editPapaComment: (papaComment: PapaActivityLog) => void;
  attemptDeleteComplaint: (id: string) => Promise<void>;
}

export const useActivityLogTableColumns = ({ editPapaComment, attemptDeleteComplaint }: Params) => {
  const columns: Column<PapaActivityLog>[] = [
    {
      header: { title: "Type" },
      dataField: "type",
      formatter({ type }) {
        return <Text bold>{type}</Text>;
      },
    },
    {
      header: { title: "Comment" },
      dataField: "comment",
      formatter({ content: logContent, type: logType }) {
        const [type, content] = logContent
          ? getCommentTypeAndContent(logContent, { allowAnyType: true, capitalizeType: true })
          : [];
        return (
          <Comment>
            {logType !== LogType.VisitComment && <b>{type}: </b>}
            {content}
          </Comment>
        );
      },
    },
    {
      header: { title: "Timestamp" },
      dataField: "insertedAt",
      cellProps: { noWrap: true },
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt, { format: "MMM DD, YYYY [at] h:mm A" });
      },
    },
    {
      header: { title: "Created By" },
      dataField: "createdBy",
      formatter({ creatorId, creatorName }) {
        return <Text>{creatorName}</Text>;
      },
    },
    {
      header: { title: "" },
      dataField: "actions",
      formatter(log) {
        const [commentType] = log.content
          ? getCommentTypeAndContent(log.content, { allowAnyType: true, capitalizeType: false })
          : [];
        const visibleCommentOptionLabels = commentOptions.flatMap((kv) => kv.label);
        return log.type === LogType.PapaComment &&
          visibleCommentOptionLabels.includes(commentType) ? (
          <EditButtonContainer>
            <EditButton onClick={() => editPapaComment(log)}>Edit</EditButton>
          </EditButtonContainer>
        ) : log.type === LogType.Complaint ? (
          <EditButtonContainer>
            <EditButton onClick={() => attemptDeleteComplaint(log.id!)}>Delete</EditButton>
          </EditButtonContainer>
        ) : log.type === LogType.VisitComment ? (
          <EditButtonContainer>
            <EditButton>
              <Link to={`/visits/${log.visitId}/details`}>View Visit</Link>
            </EditButton>
          </EditButtonContainer>
        ) : (
          <></>
        );
      },
    },
  ];

  return {
    columns,
  };
};

const EditButtonContainer = styled.div`
  padding: 10px;
  width: max-content;
`;

const CommentContainer = styled.div<{ noWrap: boolean }>`
  max-width: 400px;
  position: relative;
  text-overflow: ellipsis;
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
  overflow: hidden;
  padding-right: 30px;
  & > svg {
    z-index: 0;
  }
`;

const Comment: React.FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <Overflow
      container={CommentContainer}
      ignoreHeight={true}
      noWrap={isCollapsed}
      render={({ isOverflowed }) => (
        <>
          {children}
          {isOverflowed && (
            <ArrowToggle onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed} />
          )}
        </>
      )}
    />
  );
};
