import { useField, useFormikContext } from "formik";
import React, { ChangeEvent, useState } from "react";

import Feedback from "components/Form/FormFeedback";
import { InputContainer as Container, StyledInput as Input } from "components/Form/FormInput";
import { formatNumberToCurrency, parseValueToNumber } from "utils/numbers/currency";

type Props = {
  name: string;
  placeholder?: string;
  noDecimal?: boolean;
};

const InputCurrency = ({ name, noDecimal = false, ...othersProps }: Props) => {
  const [field, { touched, error }, helpers] = useField<number>(name);
  const { isSubmitting } = useFormikContext();
  const [currentValue, setValue] = useState(formatNumberToCurrency(field.value || 0, noDecimal));

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const parsedDollarValue = parseValueToNumber(value, noDecimal);
    const parsedPennyValue = parsedDollarValue * 100;

    setValue(formatNumberToCurrency(parsedPennyValue, noDecimal));
    helpers.setValue(parsedPennyValue);
  };

  return (
    <>
      <Container disabled={isSubmitting}>
        <Input
          {...othersProps}
          onChange={handleChange}
          disabled={isSubmitting}
          value={currentValue}
        />
      </Container>
      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

export default InputCurrency;
