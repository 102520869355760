import moment from "moment-timezone";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import DataTableDropdownMenu, { DataTableMenuItem } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import Text from "components/Text";
import VerticalIconsList from "components/VerticalIconsList";
import { DEFAULT_TIMEZONE } from "constants/date";
import { BusinessPolicyFragment } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { getBusinessPolicyStatus } from "utils/businessPolicy";
import { formatBusinessPolicyDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";

export enum DataTableMenuActions {
  NoAction,
  Edit,
  Inactive,
}

export const useBusinessPoliciesColumns = () => {
  const { kebabCased } = useCallItClientProgramOrBusiness();
  const { businessId } = useParams<{ businessId: string }>();

  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [entity, setEntity] = useState<BusinessPolicyFragment | null>(null);

  const menuItems = (item: BusinessPolicyFragment): DataTableMenuItem<DataTableMenuActions>[] => [
    {
      label: "Details",
      link: `/${kebabCased.pluralEntityName}/${businessId}/${kebabCased.singularEntityName}-policies/${item.id}`,
    },
    {
      label: "Inactive",
      action: DataTableMenuActions.Inactive,
      showCondition: getBusinessPolicyStatus(item) === "Current",
    },
  ];

  const columns: Column<BusinessPolicyFragment>[] = [
    {
      header: { title: "Status" },
      dataField: "status",
      formatter: getBusinessPolicyStatus,
    },
    {
      header: { title: "In-Person" },
      dataField: "allowInPersonVisits",
      formatter: ({ allowInPersonVisits }) => (allowInPersonVisits ? "Yes" : "No"),
    },
    {
      header: { title: "Virtual" },
      dataField: "allowVirtualVisits",
      formatter: ({ allowVirtualVisits }) => (allowVirtualVisits ? "Yes" : "No"),
    },
    {
      header: { title: "Start Date" },
      dataField: "startsAt",
      formatter: ({ startsAt }) =>
        formatBusinessPolicyDateTime(
          startsAt,
          undefined,
          { hour: 0, minute: 0, second: 0 },
          DEFAULT_TIMEZONE
        ),
    },
    {
      header: { title: "Expiration Date" },
      dataField: "endsAt",
      formatter: ({ endsAt }) => {
        const datetime = formatBusinessPolicyDateTime(
          endsAt,
          undefined,
          { hour: 23, minute: 59, second: 0 },
          DEFAULT_TIMEZONE
        );
        if (moment(endsAt).isValid() && moment(endsAt) < moment()) {
          return (
            <Text color="secondary">
              <s>{datetime}</s>
            </Text>
          );
        } else {
          return datetime;
        }
      },
    },
    {
      header: { title: "Disallowed In-Person Tasks" },
      dataField: "inPersonDisallowedTasks",
      formatter({ inPersonDisallowedTasks }) {
        return <VerticalIconsList icons={nonNull(inPersonDisallowedTasks?.data)} />;
      },
    },
    {
      header: { title: "Disallowed Virtual Tasks" },
      dataField: "virtualDisallowedTasks",
      formatter({ virtualDisallowedTasks }) {
        return <VerticalIconsList icons={nonNull(virtualDisallowedTasks?.data)} />;
      },
    },
    {
      header: { title: "Pal Covid Compliant" },
      dataField: "palCovidCompliant",
      formatter: ({ palCovidVaccination }) => (palCovidVaccination ? "Yes" : "No"),
    },
    {
      header: { title: "Papa Covid Compliant" },
      dataField: "papaCovidCompliant",
      formatter: ({ papaCovidVaccination }) => (papaCovidVaccination ? "Yes" : "No"),
    },
    {
      header: { title: "Loneliness Compliant" },
      dataField: "lonelinessCompliant",
      formatter: ({ lonelinessCompliant }) => (lonelinessCompliant ? "Yes" : "No"),
    },
    {
      header: { title: "Allow Uber for transportation" },
      dataField: "allowUberForTransportation",
      formatter: ({ allowUberForTransportation }) => (allowUberForTransportation ? "Yes" : "No"),
    },
    {
      header: { title: "Service Requests Enabled" },
      dataField: "careConciergeEnabled",
      formatter: ({ careConciergeEnabled }) => (careConciergeEnabled ? "Yes" : "No"),
    },
    {
      header: { title: "Pays for Social Care Navigation" },
      dataField: "paysForCareConcierge",
      formatter: ({ careConciergeEnabled, paysForCareConcierge }) =>
        careConciergeEnabled ? (paysForCareConcierge ? "Yes" : "No") : null,
    },
    {
      header: { title: "Max minutes per visit" },
      dataField: "maxMinutesPerVisit",
    },
    {
      header: { title: "" },
      dataField: "actions",
      cellProps: {
        align: "right",
      },
      formatter(item) {
        return (
          <DataTableDropdownMenu<DataTableMenuActions>
            items={menuItems(item)}
            actionHandler={(action) => menuActionsHandler(action, item)}
          />
        );
      },
    },
  ];

  const menuActionsHandler = (action: DataTableMenuActions, entity: BusinessPolicyFragment) => {
    setAction(action);
    setEntity(entity);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setEntity(null);
  };

  return {
    columns,
    action,
    entity,
    closeModal,
  };
};
