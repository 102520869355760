import cl from "classnames";
import { useField, useFormikContext } from "formik";
import moment from "moment-timezone";
import React, { useState } from "react";
import { SingleDatePicker, SingleDatePickerShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import Feedback from "components/Form/FormFeedback";
import { yearsPickerRenderer } from "components/InputDate/yearsPickerRenderer";
import { isDayBefore } from "utils/isDayBefore";

import "./style.css";

interface Props extends Partial<SingleDatePickerShape> {
  id: string;
  name: string;
  isOutsideRange?: (day: any) => boolean;
  block?: boolean;
  format?: string;
  withYear?: boolean;
  isShort?: boolean;
  showError?: boolean;
}

const InputDate: React.FC<Props> = ({
  id,
  name,
  displayFormat = "MMM D, YYYY",
  placeholder = "Select the Day",
  isOutsideRange,
  format,
  withYear = true,
  isShort,
  disabled,
  onDateChange,
  showError = true,
  ...othersProps
}) => {
  const [isFocused, setFocused] = useState<boolean | null>(false);
  const [field, { touched, error }, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();
  const conditionalProps: Partial<SingleDatePickerShape> = {};

  isOutsideRange = isOutsideRange || isDayBefore;

  const handleChange = (date: moment.Moment | null) => {
    onDateChange && onDateChange(date);

    if (!date) {
      helpers.setValue("");

      return;
    }

    const value = format ? date.format(format) : date.toISOString();

    helpers.setValue(value);
  };

  const value = field.value && moment(field.value).isValid() ? moment(field.value) : null;

  if (withYear) {
    conditionalProps.renderMonthElement = yearsPickerRenderer;
  }

  const isInvalid = !!error;

  return (
    <div
      className={cl({
        "SingleDatePicker--focused": isFocused,
        "SingleDatePicker--error": isInvalid,
        "SingleDatePicker--short": isShort,
        "SingleDatePicker--disabled": disabled,
      })}
    >
      <SingleDatePicker
        id={id}
        date={value}
        focused={isFocused}
        onDateChange={handleChange}
        onFocusChange={({ focused }) => setFocused(focused)}
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        showDefaultInputIcon
        displayFormat={displayFormat}
        horizontalMargin={50}
        disabled={isSubmitting || disabled}
        placeholder={placeholder}
        readOnly
        {...conditionalProps}
        {...othersProps}
      />
      {showError && touched && isInvalid && <Feedback isInvalid>{error}</Feedback>}
    </div>
  );
};

export default InputDate;
