import React, { FC } from "react";
import styled from "styled-components";

import { PalStatus, PapaState } from "generated/types";

import { statusLogLabels } from "./statusLogLabels";

interface Props {
  status?: PalStatus | PapaState | null;
}

export const StatusCell: FC<Props> = ({ status }) => (
  <StyledCell status={status}>{(status && statusLogLabels[status]) || status}</StyledCell>
);

const StyledCell = styled.div<Props>`
  color: ${({ theme, status }) => {
    if (status === PalStatus.Active || status === PapaState.Active) return theme.variants.success;
    if (status === PalStatus.Banned) return theme.variants.danger;
    return theme.variants.warning;
  }};
`;
