import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import Select from "components/Select";
import Text from "components/Text";
import { paymentInvoiceStateLabels, statePaymentInvoiceOptions } from "constants/paymentInvoices";
import {
  PaymentInvoiceListResult,
  PaymentInvoiceSingleResult,
  PaymentInvoiceState,
  UpdatablePaymentInvoiceState,
} from "generated/types";

import { GET_PAYMENT_INVOICE_STATE, UPDATE_PAYMENT_INVOICE_STATE } from "./gql";
import { Schema, schema } from "./schema";

interface MutationInput {
  paymentInvoiceId: string;
  state: UpdatablePaymentInvoiceState;
}

interface Props {
  paymentInvoiceId: string;
  onRefetch?: () => void;
  closeModal: () => void;
}

const UpdatePaymentInvoiceState: React.FC<Props> = ({
  paymentInvoiceId,
  closeModal,
  onRefetch = () => {},
}) => {
  const { data, loading } = useQuery<
    { paymentInvoice: PaymentInvoiceSingleResult },
    { paymentInvoiceId: string }
  >(GET_PAYMENT_INVOICE_STATE, {
    variables: {
      paymentInvoiceId,
    },
  });
  const [updatePaymentInvoiceState] = useMutation<
    { updatePaymentInvoiceState: PaymentInvoiceListResult },
    MutationInput
  >(UPDATE_PAYMENT_INVOICE_STATE);

  const state: unknown = data?.paymentInvoice?.data?.state ?? null;

  const initialValues: Schema = {
    state: state ? (state as UpdatablePaymentInvoiceState) : null,
  };

  const handleSubmit = async ({ state }: Schema) => {
    if (!state) return;
    try {
      const { data } = await updatePaymentInvoiceState({
        variables: {
          paymentInvoiceId,
          state,
        },
      });

      if (data?.updatePaymentInvoiceState?.data?.[0]?.id) {
        toast.success("The status was changed with success");
        closeModal();
        onRefetch();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (loading) return <Loader />;

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Row>
            <Form.Col>
              <Form.Group>
                <Text>
                  Current Status: {paymentInvoiceStateLabels[state as PaymentInvoiceState]}
                </Text>
              </Form.Group>
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <Form.Col>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Select
                  name="state"
                  aria-label="state"
                  options={statePaymentInvoiceOptions}
                  isSearchable={false}
                  placeholder="Select state"
                />
              </Form.Group>
            </Form.Col>
          </Form.Row>
        </ModalContainer>

        <ModalFooter>
          <Button type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default UpdatePaymentInvoiceState;
