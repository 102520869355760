import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { ArrowToggle } from "components/ArrowToggle";
import Button from "components/Button";
import Heading from "components/Heading";
import { ModalLayout } from "components/Modal/ModalLayout";
import { Overflow } from "components/Overflow";
import SmartTable from "components/SmartTable";
import { Column } from "components/SmartTable/schema";
import { FeatureFlags } from "constants/featureFlags";
import { PalNote, PalSingleResult, SupportTicketRequesterType } from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { getCommentContent, getCommentType } from "utils/strings/getCommentTypeAndContent";

import { GET_PAL_NOTES } from "../gql";
import AddCommentForm from "./AddCommentForm/AddCommentForm";
import Complaints from "./Complaints";

interface Data {
  pal: PalSingleResult;
}

const Activity = () => {
  const { kebabCased } = useCallItClientProgramOrBusiness();
  const { page, limit } = usePageInfo();
  const { id } = useParams<{ id: string }>();
  const { isOpen: showAddCommentModal, toggle: toggleAddCommentModal } = useModalToggle();
  const [collapsedCommentsMap, setCollapsedCommentsMap] = useState<Record<string, boolean>>({});

  const { data, loading, error } = useQuery<Data>(GET_PAL_NOTES, {
    variables: { id, pagination: { page, limit } },
  });

  const onCommentCollapse = (id: string) => {
    const updatedComments = {
      ...collapsedCommentsMap,
      [id]: !collapsedCommentsMap[id],
    };

    setCollapsedCommentsMap(updatedComments);
  };

  const notes = nonNull(data?.pal?.data?.notes?.data);
  const pagination = data?.pal?.data?.notes?.pagination;
  const columns: Column<PalNote>[] = [
    {
      header: { title: "Type" },
      dataField: "content",
      formatter({ content }) {
        return getCommentType(content!);
      },
    },
    {
      header: { title: "Comment" },
      dataField: "id",
      formatter({ content, id }) {
        if (!id) return null;
        const comment = getCommentContent(content!);
        const isCollapsed = collapsedCommentsMap[id];

        return (
          <Overflow
            container={CommentContainer}
            noWrap={!isCollapsed}
            render={({ isOverflowed }) => (
              <>
                {comment}
                {isOverflowed && (
                  <ArrowToggle onClick={() => onCommentCollapse(id)} isCollapsed={isCollapsed} />
                )}
              </>
            )}
          />
        );
      },
    },
    {
      header: { title: "Timestamp" },
      dataField: "insertedAt",
      formatter({ insertedAt }) {
        return formatDateTime(insertedAt);
      },
    },
    {
      header: { title: "Created by" },
      dataField: "creator",
      formatter({ creator }) {
        return (
          <Link
            to={
              creator?.data?.type === "BUSINESS"
                ? `/${kebabCased.pluralEntityName}/${creator?.data?.id}`
                : `/members/${creator?.data?.id}`
            }
          >
            {creator?.data?.fullName}
          </Link>
        );
      },
    },
  ];

  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);

  const { submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();

  const viewTicketsOnClick = () => {
    // this won't actually submit a ticket, but bring them to the user page where the can view all
    // the zendesk tickets for that user, and potentially create a ticket for them form that page as
    // well
    handleSubmitSupportTicket({ id: id, type: SupportTicketRequesterType.Pal });
  };

  return (
    <>
      <Helmet>
        <title>Pal Activity - Papa Admin</title>
      </Helmet>

      <Complaints />

      <ModalLayout
        toggle={toggleAddCommentModal}
        title="Add a comment"
        isOpen={showAddCommentModal}
      >
        <AddCommentForm id={id} onAddFinish={toggleAddCommentModal} />
      </ModalLayout>

      <Heading>Comments</Heading>
      <Button variant="primary" action onClick={toggleAddCommentModal}>
        Add comment
      </Button>

      <SmartTable<PalNote>
        keyField="id"
        data={notes}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
      />

      {supportTicketingEnabled && (
        <>
          <Heading color="primary">Tickets</Heading>
          <Button variant="primary" onClick={viewTicketsOnClick}>
            View Tickets
          </Button>
        </>
      )}
    </>
  );
};

const CommentContainer = styled.div<{ noWrap: boolean }>`
  max-width: 400px;
  position: relative;
  text-overflow: ellipsis;
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
  overflow: hidden;
  padding-right: 30px;
`;

export default Activity;
