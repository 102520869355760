import React from "react";

import Table from "components/Table/index";

interface Props {
  rows: number;
  columns: number;
}

export const TableLoadingPlaceholder: React.FC<Props> = ({ rows, columns }) => {
  const tableRows = Array.from({ length: rows });
  const tableColumns = Array.from({ length: columns });

  return (
    <>
      {tableRows.map((_, rowIndex) => (
        <Table.Row key={rowIndex}>
          {tableColumns.map((_, columnIndex) => (
            <Table.LoadingCell key={columnIndex} />
          ))}
        </Table.Row>
      ))}
    </>
  );
};
