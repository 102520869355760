import { gql } from "@apollo/client";

export const GET_VISIT = gql`
  query VisitForEditStartTime($id: ID!) {
    visit(id: $id) {
      data {
        id
        startedAt
        location {
          data {
            id
            timezone
          }
        }
      }
    }
  }
`;

export const UPDATE_STATE_VISIT = gql`
  mutation updateStateTime($id: ID!, $input: VisitInput!) {
    updateVisit(id: $id, input: $input) {
      data {
        id
      }
    }
  }
`;
