import { useQuery } from "@apollo/client";
import { Payment as PaymentIcon } from "@styled-icons/material-twotone/Payment";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import Helmet from "react-helmet";

import Button from "components/Button";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import { useRowsSelectList } from "components/SmartTable/useRowsSelectList";
import { PaymentSorting, SortDirection } from "generated/types";
import { AccountType, Payment } from "generated/types";
import { PaymentListResult } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { PaymentsFilterModal } from "modals/PaymentsFilterModal";
import { countFilters, paymentsFiltersMaker } from "utils/paymentsFilters";

import { GET_PAYMENTS } from "../gql";
import PaymentExportModal from "./Export/PaymentExportModal";
import MemberPaymentsMassMenu from "./MassMenu";
import { usePaymentsTableColumns } from "./usePaymentsTableColumns";

type Data = {
  payments: PaymentListResult;
};

const Payments = () => {
  useTopBarOptions({ title: "Members Payments", icon: PaymentIcon, breadcrumbs: ["Payments"] });
  const { beforeCursor, afterCursor, limit, ...paymentsFilters } = usePageInfo();
  const [sorting, setSorting] = useState<PaymentSorting>({
    insertedAt: SortDirection.Desc,
  });
  const columns = usePaymentsTableColumns({
    sorting,
    onSortChange: setSorting,
  });
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const countFilter = countFilters(paymentsFilters);
  const paymentFilter = paymentsFiltersMaker({
    ...paymentsFilters,
    recipient: AccountType.Regular,
  });

  const { data, loading, error } = useQuery<Data>(GET_PAYMENTS, {
    variables: {
      pagination: { beforeCursor, afterCursor, limit },
      filter: isEmpty(paymentFilter) ? null : paymentFilter,
      sorting,
    },
  });

  const { rowsSelected, onRowSelect, onAllRowsSelect, setRowsSelected } = useRowsSelectList();

  const handleSuccess = () => {
    setRowsSelected([]);
  };

  const [showPaymentExportModal, setShowPaymentExportModal] = useState(false);
  const togglePaymentExportModal = () => setShowPaymentExportModal(!showPaymentExportModal);

  const payments = data?.payments.data;
  const pagination = data?.payments.pagination;

  return (
    <div>
      <Helmet>
        <title>Members Payments - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <FlexRow>
          <Button type="button" onClick={toggleFiltersModal}>
            <span>Filter</span>
            {countFilter > 0 && <span>({countFilter})</span>}
          </Button>
        </FlexRow>

        <FlexRow style={{ gap: 24 }}>
          <Button onClick={togglePaymentExportModal}>Export</Button>
          <MemberPaymentsMassMenu paymentIds={rowsSelected} onSuccess={handleSuccess} />
        </FlexRow>
      </FlexRow>

      <SmartTable<Payment>
        keyField="id"
        data={payments}
        pagination={pagination}
        loading={loading}
        columns={columns}
        rowProps={({ state }) =>
          state
            ? {
                paymentStatusLegend: state,
              }
            : {}
        }
        error={error}
        cursorPagination
        onRowSelect={onRowSelect}
        onAllRowsSelect={onAllRowsSelect}
        rowsSelected={rowsSelected}
      />

      <PaymentExportModal
        isOpen={showPaymentExportModal}
        toggle={togglePaymentExportModal}
        filters={paymentFilter}
      />

      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Payments Filter"
        isOpen={isFiltersModalOpen}
      >
        <PaymentsFilterModal onSubmit={toggleFiltersModal} />
      </ModalLayout>
    </div>
  );
};

export default Payments;
