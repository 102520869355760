import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";

import PalActionsMenu from "components/ActionsMenu/PalActionMenu";
import Actions from "components/ActionsMenu/PalActionMenu/Actions";
import { usePalActions } from "components/ActionsMenu/PalActionMenu/usePalActions";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import Flex from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import { PalSingleResult } from "generated/types";

import { GET_PAL_OVERVIEW } from "../gql";
import Activity from "./Activity";
import Debug from "./Debug";
import Details from "./Details";
import Devices from "./Devices";
import Overview from "./Overview";
import Payments from "./Payments";
import StatusLog from "./StatusLog";
import VaccineInfo from "./VaccineInfo";
import Vehicle from "./Vehicle";
import Visits from "./Visits";

type Props = {
  id: string;
};

const Container = styled("div")`
  display: flex;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

const PalDetails = ({ match }: RouteComponentProps<Props>) => {
  const { id } = match.params;
  const { action, setAction } = usePalActions();
  const { data, loading, error } = useQuery<{ pal: PalSingleResult }>(GET_PAL_OVERVIEW, {
    variables: { id: id },
  });
  const pal = data?.pal?.data;

  useTopBarOptions({
    title: "Pal Details",
    name: pal?.fullName ?? "",
    icon: AccountCircle,
    breadcrumbs: ["Accounts", { label: "All Pals", link: "/pals" }],
    deps: [pal?.fullName],
  });

  if (!pal && !loading) {
    return (
      <>
        <h1>404 :-(</h1>
        <p>pal not found</p>
      </>
    );
  }

  // Retain this if we want to restrict the tab again
  const showAppVisitsTab = true;

  const handleEditClick = () => {
    setAction(DataTableMenuActions.SetPalStatus, pal);
  };

  return (
    <>
      <Container>
        <Overview pal={pal} loading={loading} error={error} onClickEdit={handleEditClick} />

        <Main>
          <Flex justifyContent="space-between" marginBottom="1.5rem" alignItems="center">
            <Nav noMargin>
              <Nav.Link to={`/pals/${id}/details`}>Details</Nav.Link>
              <Nav.Link to={`/pals/${id}/activity`}>Activity</Nav.Link>
              <Nav.Link to={`/pals/${id}/vehicle`}>Vehicle</Nav.Link>
              <Nav.Link to={`/pals/${id}/visits`}>Visits</Nav.Link>
              <Nav.Link to={`/pals/${id}/payments`}>Payments</Nav.Link>
              <Nav.Link to={`/pals/${id}/statuslog`}>Status Logs</Nav.Link>
              <Nav.Link to={`/pals/${id}/devices`}>Devices</Nav.Link>
              <Nav.Link to={`/pals/${id}/vaccine-info`}>Vaccine Info</Nav.Link>
              {showAppVisitsTab && <Nav.Link to={`/pals/${id}/debug`}>App Visits (beta)</Nav.Link>}
            </Nav>

            {pal && (
              <Flex paddingHorizontal="0.5rem">
                <PalActionsMenu pal={pal} setAction={setAction} />
              </Flex>
            )}
          </Flex>

          <Switch>
            <Route exact path="/pals/:id/details" component={Details} />
            <Route exact path="/pals/:id/activity" component={Activity} />
            <Route exact path="/pals/:id/vehicle" component={Vehicle} />
            <Route exact path="/pals/:id/visits" component={Visits} />
            <Route exact path="/pals/:id/payments" component={Payments} />
            <Route exact path="/pals/:id/statuslog" component={StatusLog} />
            <Route exact path="/pals/:id/devices" component={Devices} />
            <Route exact path="/pals/:id/vaccine-info" component={VaccineInfo} />
            <Route
              exact
              path={["/pals/:id/debug/:debugTab", "/pals/:id/debug"]}
              render={() =>
                showAppVisitsTab && (
                  <Debug
                    palLanguages={pal?.languages?.data}
                    accountId={pal?.account?.data?.id ?? ""}
                    lastKnownLocation={pal?.lastKnownLocation}
                  />
                )
              }
            />
            <Redirect from="/pals/:id" to="/pals/:id/activity" />
          </Switch>
        </Main>

        {pal && (
          <Actions
            action={action}
            pal={pal}
            closeModal={() => setAction(DataTableMenuActions.NoAction)}
          />
        )}
      </Container>
    </>
  );
};

export default PalDetails;
