import { useField } from "formik";
import moment from "moment-timezone";
import React, { useContext, useState } from "react";

import DurationSelects from "components/DurationSelects";
import Form from "components/Form";
import {
  SwitchButtonInput,
  Label as SwitchLabel,
  Text as SwitchText,
} from "components/SwitchButton";
import { BusinessPolicy } from "generated/types";
import { CurrentBusinessPolicyContext } from "pages/ScheduleVisit/shared/CurrentBusinesPolicyContext";
import { getDuration } from "utils/numbers/durations";

import { DurationButtonsRadios } from "./DurationButtonsRadios";

interface DurationProps {
  businessPolicies: Array<BusinessPolicy>;
}

export const Duration = ({ businessPolicies }: DurationProps) => {
  const [{ value: estimatedDuration }, , { setValue: setEstimatedDuration }] =
    useField("estimatedDuration");
  const [isCustomDuration, setCustomDuration] = useState(false);
  const [{ value: duration }, { touched, error }] = useField("estimatedDuration");
  const [{ value: isVirtual }] = useField("isVirtual");

  const handleIsCustomDurationChange = () => {
    setCustomDuration(!isCustomDuration);
  };

  const { currentBusinessPolicy } = useContext(CurrentBusinessPolicyContext);
  const maxDuration = !!currentBusinessPolicy
    ? (currentBusinessPolicy as BusinessPolicy).maxMinutesPerVisit
    : businessPolicies.find((bp) => moment().isBetween(moment(bp.startsAt), moment(bp.endsAt)))
        ?.maxMinutesPerVisit;

  const minDurationMins = isVirtual ? 30 : 60;
  const maxDurationMins = maxDuration || 300;
  const getDurationError = () => {
    if (touched && !!error) {
      if (isVirtual && duration < 30) {
        return "Minimum duration is 30 minutes";
      } else if (!isVirtual && duration < 60) {
        return "Minimum duration is one hour";
      } else if (duration > maxDurationMins) {
        return `Maximum duration is ${getDuration(maxDurationMins)}`;
      } else {
        return error;
      }
    } else {
      return null;
    }
  };

  return (
    <Form.Group controlId="estimatedDuration">
      <SwitchLabel>
        <SwitchButtonInput
          name="isCustomDuration"
          checked={isCustomDuration}
          onChange={handleIsCustomDurationChange}
          aria-label="Choose a custom duration"
        />
        <SwitchText>Choose a custom duration</SwitchText>
      </SwitchLabel>
      {isCustomDuration ? (
        <DurationSelects
          value={estimatedDuration}
          onChange={setEstimatedDuration}
          minDuration={minDurationMins}
          maxDuration={maxDurationMins}
          error={getDurationError()}
        />
      ) : (
        <DurationButtonsRadios maxDuration={maxDurationMins} error={getDurationError()} />
      )}
    </Form.Group>
  );
};
