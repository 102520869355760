import { virtualVisitTasks } from "constants/tasks";
import { Task } from "generated/types";
import { TaskDisableReason } from "types";

export type TaskWithDisableReason = Task & { disableReason: TaskDisableReason | null };

export const getTasksByVisitType = (tasks: Task[], isVirtual: boolean): TaskWithDisableReason[] =>
  tasks.map((task) => {
    const disableReason =
      isVirtual && task.name && !virtualVisitTasks.includes(task.name)
        ? TaskDisableReason.virtualVisit
        : null;

    return {
      ...task,
      disableReason,
    };
  });

export const getExcludedTasks = (
  tasks: TaskWithDisableReason[],
  keys: string[],
  reason: TaskDisableReason
): TaskWithDisableReason[] =>
  tasks.map((task) => ({
    ...task,
    disableReason: task.disableReason ?? (task.id && keys.includes(task.id) ? null : reason),
  }));
