import React, { CSSProperties } from "react";
import { default as ReactSelect } from "react-select";
import styled, { css } from "styled-components";

import Conditional from "components/Conditional";
import Feedback from "components/Form/FormFeedback";

interface DurationSelectsProps {
  value: number;
  onChange: (value: number) => void;
  minDuration?: number;
  maxDuration?: number;
  error?: string | null;
  className?: string;
}

type DurationHoursOption = {
  value: number;
  label: string;
};

export const DurationSelects = ({
  value,
  onChange,
  minDuration = 0,
  maxDuration = 600,
  error,
  className,
}: DurationSelectsProps) => {
  const hours = getAmountOfFullHoursInMinutes(value);
  const minutes = getRestOfMinutesSubtractedByFullHours(value);

  const durationHoursOption = (minutes: number): DurationHoursOption => {
    const hours = Math.floor(minutes / 60);
    const unit = hours === 1 ? "hour" : "hours";
    return { value: minutes, label: `${hours} ${unit}` };
  };

  const buildDurationHoursOptions = (
    // This needs to be a multiple of 60, otherwise things get weird
    accumulator: number,
    options: Array<DurationHoursOption>
  ): Array<DurationHoursOption> => {
    if (accumulator >= maxDuration) return options;

    accumulator += 60;
    return buildDurationHoursOptions(accumulator, [...options, durationHoursOption(accumulator)]);
  };

  const minHours = getAmountOfFullHoursInMinutes(Math.min(value, minDuration));
  const durationHoursOptions = buildDurationHoursOptions(minHours, [durationHoursOption(minHours)]);

  const handleHoursChange = (option: any) => {
    if (option && !Array.isArray(option)) {
      onChange(Math.min(maxDuration, minutes + option.value));
    }
  };

  const handleMinutesChange = (option: any) => {
    if (option && !Array.isArray(option)) {
      onChange(hours + option.value);
    }
  };

  return (
    <Wrapper className={className}>
      <TimeWrapper>
        <HoursWrapper>
          <ReactSelect
            aria-label="hours"
            classNamePrefix="duration-selects"
            value={durationHoursOptions?.find((option) => option.value === hours)}
            options={durationHoursOptions}
            styles={dropdownStyles}
            onChange={handleHoursChange}
            isSearchable={false}
          />
        </HoursWrapper>
      </TimeWrapper>
      <TimeWrapper>
        {value < maxDuration && (
          <MinutesWrapper isInvalid={!!error}>
            <ReactSelect
              aria-label="minutes"
              classNamePrefix="duration-selects"
              value={durationMinutesOptions.find((option) => option.value === minutes)}
              options={durationMinutesOptions}
              styles={dropdownStyles}
              onChange={handleMinutesChange}
              isSearchable={false}
            />
          </MinutesWrapper>
        )}
      </TimeWrapper>
      <ErrorWrapper>
        <Conditional show={!!error}>
          <Feedback isInvalid>{error}</Feedback>
        </Conditional>
      </ErrorWrapper>
    </Wrapper>
  );
};

const getAmountOfFullHoursInMinutes = (minutes: number) => {
  return Math.floor(minutes / 60) * 60;
};

const getRestOfMinutesSubtractedByFullHours = (minutes: number) => {
  const hoursInMinutes = getAmountOfFullHoursInMinutes(minutes);
  return minutes - hoursInMinutes;
};

const durationMinutesOptions = [
  { value: 0, label: "0 minutes" },
  { value: 15, label: "15 minutes" },
  { value: 30, label: "30 minutes" },
  { value: 45, label: "45 minutes" },
];

const dropdownStyles = {
  container: (provided: CSSProperties, state: any) => ({
    ...provided,
    background: "white",
    border: "1px solid #DFE3E9",
    borderRadius: state.isFocused ? "4px 4px 0 0" : 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    borderColor: "#DFE3E9 !important",
    boxShadow: "none !important",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 0,
    minHeight: 36,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided: CSSProperties) => ({
    display: "none",
  }),
  menu: (provided: CSSProperties, state: any) => ({
    ...provided,
    color: state.selectProps.menuColor,
    boxShadow: "0 0 0 1px #DFE3E9",
    borderRadius: "0 0 4px 4px",
    margin: 0,
    padding: 0,
  }),
  menuList: (provided: CSSProperties, state?: any) => ({
    maxHeight: state.maxHeight || "none",
    padding: 0,
    overflow: "auto",
  }),
  option: (provided: CSSProperties, state: any) => ({
    ...provided,
    color: state.isSelected && "#2E5BFF",
    background: state.isSelected ? "#EAEFFF" : "white",
    boxShadow: "0 -1px 0 #DFE3E9",
  }),
};

const HoursWrapper = styled.div`
  min-width: 6.25rem;
`;

const MinutesWrapper = styled.div<{ isInvalid: boolean }>`
  min-width: 6.875rem;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      & > div {
        border: 1px solid #ed1c24;
      }
    `}
`;

const Wrapper = styled.div`
  display: grid;
  width: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  column-gap: 1rem;
  row-gap: 0.3rem;
  margin-top: 1rem;
`;

const TimeWrapper = styled.div`
  grid-column: span 1;
`;

const ErrorWrapper = styled.div`
  grid-column: span 2;
`;

export default DurationSelects;
