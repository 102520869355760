import { gql } from "@apollo/client";

export const UPDATE_PAPA_DETAILS = gql`
  mutation UpdatePapaDetails($id: ID!, $input: PapaInput!) {
    updatePapa(id: $id, input: $input) {
      data {
        description
        preferredContactMethod
        stillDrives
        status
        papaSpecialConsiderations {
          data {
            value
          }
        }
      }
    }
  }
`;
