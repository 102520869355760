import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown";
import capitalize from "lodash/capitalize";
import React from "react";
import { Link } from "react-router-dom";

import DescriptionDropdown from "components/DescriptionDrowdown";
import Flex from "components/Flex";
import { Column } from "components/SmartTable/schema";
import StateText from "components/StateText";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { tipaltiDisbursementStatusLabels } from "constants/tipaltidisbursements";
import { SortDirection, TipaltiDisbursement, TipaltiDisbursementSorting } from "generated/types";
import { formatDateTime } from "utils/helpers/formatters";
import { formatNumberToCurrency } from "utils/numbers/currency";
import * as segment from "utils/segment";

interface Params {
  sorting: Partial<TipaltiDisbursementSorting>;
  onSortChange: (sort: Partial<TipaltiDisbursementSorting>) => void;
}

export const useTipaltiDisbursementsTableColumns = ({ sorting, onSortChange }: Params) => {
  const onSetSorting = (sortKey: keyof TipaltiDisbursementSorting, value: SortDirection) => {
    const updatedSort = {
      [sortKey]: value,
    };

    onSortChange(updatedSort);
  };

  const handleDescriptionDropdownChildButtonClick = () => {
    segment.palPaymentsAmountDescriptionDropdownClicked();
  };

  const columns: Column<TipaltiDisbursement>[] = [
    {
      header: { title: "Status" },
      dataField: "status",
      formatter({ status }) {
        if (!status) {
          return EMPTY_PLACEHOLDER;
        } else {
          return (
            <StateText tipaltiDisbursementStatusLegend={status}>
              {capitalize(tipaltiDisbursementStatusLabels[status])}
            </StateText>
          );
        }
      },
    },
    {
      header: { title: "Amount" },
      dataField: "amountInCents",
      cellProps: { noWrap: false },
      formatter({ amountInCents, description }) {
        return (
          <Flex>
            <Text underline>{formatNumberToCurrency(amountInCents ?? 0)}</Text>
            <DescriptionDropdown
              content={<Text>{description}</Text>}
              placement="bottom"
              childButtonOnClick={handleDescriptionDropdownChildButtonClick}
            >
              <ChevronDown size={20} />
            </DescriptionDropdown>
          </Flex>
        );
      },
    },
    {
      header: { title: "Invoice Number" },
      dataField: "invoiceNumber",
      formatter({ invoiceNumber }) {
        return <>Inv. {invoiceNumber}</>;
      },
    },
    {
      header: {
        title: "Invoice Date",
        sort: sorting.invoiceDate,
        onChange: (direction) => onSetSorting("invoiceDate", direction),
      },
      dataField: "invoiceDate",
      cellProps: { noWrap: true },
      formatter: ({ invoiceDate }) => formatDateTime(invoiceDate),
    },
    {
      header: {
        title: "Member",
      },
      dataField: "papaName",
      cellProps: { noWrap: true },
    },
    {
      header: {
        title: "Visit Date",
      },
      dataField: "visitDate",
      cellProps: { noWrap: true },
      formatter({ visitDate, visitId }) {
        return <Link to={`/visits/${visitId}`}>{formatDateTime(visitDate)}</Link>;
      },
    },
  ];

  return { columns };
};
