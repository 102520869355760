import { Experiment, useExperiment, useGrowthBook } from "@growthbook/growthbook-react";
import { useEffect } from "react";

export const usePapaExperiment = <T>(papaId: string, exp: Experiment<T>): T => {
  const gb = useGrowthBook();

  useEffect(() => {
    gb.setAttributes({ ...gb.getAttributes(), papa_id: papaId });

    // use a cleanup function to unset the gb attrs papa_id after we are done
    return () => {
      const attrs = gb.getAttributes();
      delete attrs.papa_id;

      gb.setAttributes(attrs);
    };
  }, [gb, papaId]);

  const res = useExperiment({
    key: exp.key,
    variations: exp.variations,
    hashAttribute: exp.hashAttribute,
    hashVersion: 2,
  });

  return res.value;
};
