import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";

import { PapaBioQuestions } from "components/BioForm/papa-bio-questions";
import { EditBio } from "components/EditBio/EditBio";
import { BiographyQuestions } from "generated/types";

import { UPDATE_BIO } from "./gql";

interface Props {
  papaId: string;
  answers: BiographyQuestions[];
  onEditFinish: () => void;
}

export const EditPapaBio: React.FC<Props> = ({ papaId, answers, onEditFinish }) => {
  const [updateBio, { error: mutationError }] = useMutation(UPDATE_BIO);

  const handleSubmit = async (answers: BiographyQuestions[]) => {
    try {
      const { data } = await updateBio({
        variables: {
          id: papaId,
          input: { biographyQuestions: answers },
        },
      });

      if (data?.updatePapa?.data?.biographyQuestions) {
        toast.success("Bio was edited with success!");
        onEditFinish();
      } else {
        toast.error(mutationError);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <EditBio
      questions={PapaBioQuestions}
      answers={answers}
      onEditFinish={onEditFinish}
      onSubmit={handleSubmit}
    />
  );
};
