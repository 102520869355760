import * as yup from "yup";

import { CommentType } from "constants/papaComment";

export interface Schema {
  palId: string;
  type: CommentType | null;
  content: string;
}

export const schema = yup.object().shape({
  palId: yup.string().required("Required"),
  type: yup.string().nullable().required("Required"),
  content: yup.string().required("Required"),
});
