import { Formik } from "formik";
import React, { FunctionComponent, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Conditional from "components/Conditional";
import { FormFooter, StyledForm } from "components/EditBio/styles";
import Form from "components/Form";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import { Loader } from "components/Loader";
import ConfirmModal, { ConfirmModalIcon } from "components/Modal/ConfirmModal";
import { ModalContainer } from "components/Modal/Modal.styles";
import Select from "components/Select";
import TaskButtons from "components/TaskButtons";
import { discountOptions } from "constants/discount";
import {
  Maybe,
  VisitDiscountType,
  VisitDocument,
  VisitInput,
  VisitObjective,
  useReviewVisitMutation,
  useUpdateCompletedVisitMutation,
  useVisitQuery,
} from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { nonNullWithId } from "utils/nonNull";
import { isImpossibleStartEndTime, isLongVisit } from "utils/time";

import LongVisitModal from "../LongVisitModal";
import { getInitialValues } from "../getInitialValues";
import { mapSubmitFormValues } from "../mapSubmitFormValues";
import { Schema, schema } from "../schema";
import TimeDetails from "./TimeDetails";

interface Props {
  visitId: string;
  closeModal: () => void;
}

const EditCompleteVisitForm: FunctionComponent<Props> = ({ visitId, closeModal }) => {
  const { isBillingSpecialist, isAdmin, isSupervisor } = useCurrentAccountRole();
  const { isOpen: impossibleTimeModalOpen, toggle: toggleImpossibleTimeModal } = useModalToggle();
  const { isOpen: longTimeModalOpen, toggle: toggleLongTimeModal } = useModalToggle();
  const [visitFormValues, setVisitFormValues] = useState<Schema>();
  const [isImpossibleTime, setIsImpossibleTime] = useState(false);
  const [isMaxTimeReached, setIsMaxTimeReached] = useState(false);

  const { data: visitData, loading: visitLoading } = useVisitQuery({ variables: { id: visitId } });
  const [reviewVisit] = useReviewVisitMutation();
  const [updateVisit] = useUpdateCompletedVisitMutation({
    refetchQueries: [{ query: VisitDocument, variables: { id: visitId } }],
  });

  const visit = visitData?.visit?.data ?? {};
  const timezone = visit?.location?.data?.timezone;

  const preSubmit = async (formValues: Schema) => {
    setVisitFormValues(formValues);
    if (
      isLongVisit(
        formValues.startedAtDay,
        formValues.startedAtTime,
        formValues.completedAtDay,
        formValues.completedAtTime
      )
    ) {
      toggleLongTimeModal();
    } else {
      await handleSubmit(formValues);
    }
  };

  const handleSubmit = async (formValues: Schema) => {
    if (
      isImpossibleStartEndTime(
        formValues.startedAtDay,
        formValues.startedAtTime,
        formValues.completedAtDay,
        formValues.completedAtTime
      )
    ) {
      setIsImpossibleTime(true);
      toggleImpossibleTimeModal();
      return;
    }

    const { input, objectivesInput } = mapSubmitFormValues(
      formValues,
      nonNullWithId(visit?.objectives?.data),
      timezone
    );

    const { successful, ...otherValues } = input;

    const newInput: VisitInput = {
      ...otherValues,
      objectives: objectivesInput,
    };

    if ((isBillingSpecialist || isSupervisor) && typeof successful === "boolean") {
      newInput.successful = successful;
    }

    try {
      await reviewVisit({
        variables: {
          id: visitId,
          input: {
            objectives: objectivesInput,
          },
        },
      });
      const { data } = await updateVisit({
        variables: {
          id: visitId,
          // @ts-ignore
          input: newInput,
        },
      });
      if (data?.updateVisit?.data) {
        toast.success("Visit was edited with success!");
        closeModal();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (visitLoading || !visit) return <Loader />;

  const initialValues: Schema = getInitialValues(visit);

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={preSubmit}>
      {({ values }) => (
        <StyledForm>
          <ModalContainer>
            <Conditional show={isBillingSpecialist || isAdmin || isSupervisor}>
              <MemberShowup />
            </Conditional>
            <TimeDetails setIsImpossibleTime={setIsImpossibleTime} timezone={timezone} />
            <Form.InputWithLabel
              label="Commute Distance"
              name="commuteDistance"
              placeholder="Miles"
            />
            <Form.InputWithLabel label="Visit Distance" name="visitDistance" placeholder="Miles" />
            <Form.InputWithLabel
              label="Override Visit Duration"
              name="overrideDurationMins"
              placeholder="Mins"
            />
            <Discount discountType={values.discount.type} />
            <Conditional show={isBillingSpecialist && !visit.isVirtual}>
              <DeliveryOptions />
            </Conditional>
            <Tasks isMaxTimeReached={isMaxTimeReached} setIsMaxTimeReached={setIsMaxTimeReached} />
            <Objectives objectives={visit.objectives?.data} />
          </ModalContainer>

          <Footer disabled={isImpossibleTime} closeModal={closeModal} />
          <ConfirmModal
            isOpen={impossibleTimeModalOpen}
            header="Oops! A visit cannot start after it ends!"
            onConfirm={() => {
              toggleImpossibleTimeModal();
            }}
            confirmBtnText="Okay"
            noCancel
            icon={ConfirmModalIcon.exclamation}
          >
            Please check your start and end times for this visit.
          </ConfirmModal>
          <LongVisitModal
            isOpen={longTimeModalOpen}
            toggle={toggleLongTimeModal}
            visitFormValues={visitFormValues}
            onSubmit={handleSubmit}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

const MemberShowup = () => {
  return (
    <Form.Group>
      <Form.Label>Did the member show up?</Form.Label>
      <Form.Check name="successful" label="Yes, member showed" controlId="succesful" />
    </Form.Group>
  );
};

const DeliveryOptions = () => {
  return (
    <Form.Group>
      <Form.Label>Delivery options</Form.Label>
      <Form.Check name="delivery" label="Delivery" controlId="delivery" />
    </Form.Group>
  );
};

const Discount = ({ discountType }: { discountType: VisitDiscountType | null }) => {
  return (
    <Form.Group>
      <Form.Label>Discount</Form.Label>
      <InputGroup>
        <Select
          name="discount.type"
          options={discountOptions}
          isSearchable={false}
          small
          autoWidth
          className="discount-type"
        />
        <div className="discount-value">
          {discountType === VisitDiscountType.FixedAmount ? (
            <Form.InputCurrency name="discount.value" placeholder="$0.00" noDecimal />
          ) : (
            <Form.Input type="number" name="discount.value" placeholder="0" min="0" max="100" />
          )}
        </div>
      </InputGroup>
    </Form.Group>
  );
};

const Tasks = ({
  isMaxTimeReached,
  setIsMaxTimeReached,
}: {
  isMaxTimeReached: boolean;
  setIsMaxTimeReached: (state: boolean) => void;
}) => {
  return (
    <>
      <ScheduleVisitLabel>
        Tasks
        {isMaxTimeReached && " (MaxTime rule is applied. Certain tasks are blocked)"}
      </ScheduleVisitLabel>

      <Form.Group>
        <TaskButtons name="taskIds" setIsMaxTimeReached={setIsMaxTimeReached} />
      </Form.Group>
    </>
  );
};

const Objectives = ({ objectives }: { objectives: Maybe<Maybe<VisitObjective>[]> | undefined }) => {
  return (
    <Form.Fieldset legend="Objectives">
      {nonNullWithId(objectives).map(({ id, description }) => (
        <Form.Check
          key={id}
          name={`objectives.${id}`}
          label={description!}
          controlId={id ?? undefined}
        />
      ))}
    </Form.Fieldset>
  );
};

const Footer = ({ disabled, closeModal }: { disabled: boolean; closeModal: () => void }) => {
  return (
    <FormFooter>
      <Button variant="secondary" onClick={closeModal}>
        Close
      </Button>
      <Form.SubmitButton disabled={disabled}>Save</Form.SubmitButton>
    </FormFooter>
  );
};

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 18.75rem;
  width: 100%;

  .SingleDatePicker {
    width: auto;
  }
  .rc-time-picker {
    width: 130px;
  }
  .discount-type {
    width: 164px;
  }
  .discount-value > div {
    width: 130px;
  }
`;

export default EditCompleteVisitForm;
