import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { FunctionComponent } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import Dropdown from "components/Dropdown";
import config from "config";
import { FeatureFlags } from "constants/featureFlags";
import { Business } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { DropdownActionMenu } from "../DropdownActionMenu";

interface Props {
  business: Business;
  isListMenu?: boolean;
  setAction: (action: DataTableMenuActions, business: Business) => void;
}

const BusinessActionsMenu: FunctionComponent<Props> = ({
  business,
  setAction,
  isListMenu = false,
}) => {
  const { isSupervisor } = useCurrentAccountRole();
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const makePalEnabled = useIsFeatureEnabled(FeatureFlags.EnableMakePal);
  const hideCreditCardFields = useFeatureIsOn(FeatureFlags.HideCreditCards as string);
  const {
    kebabCased,
    capitalized: { singularEntityName: programOrBusiness },
  } = useCallItClientProgramOrBusiness();

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "pal action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {isListMenu && (
        <Dropdown.Link to={`/${kebabCased.pluralEntityName}/${business.id}`}>
          View {programOrBusiness}
        </Dropdown.Link>
      )}
      {isSupervisor && (
        <Dropdown.Link to={`/accounts/${business.id}/activity-logs`}>
          View account logs
        </Dropdown.Link>
      )}
      {renderDropDownItem(`Edit ${programOrBusiness}`, () =>
        setAction(DataTableMenuActions.Edit, business)
      )}
      {!business?.account?.data?.pal?.data?.id && (
        <Dropdown.Link to={"/schedule-visit"}>Schedule visit</Dropdown.Link>
      )}
      {!business?.account?.data?.pal?.data?.id &&
        renderDropDownItem("Add Papa", () => setAction(DataTableMenuActions.AddPapa, business))}
      {!business?.account?.data?.pal?.data?.id &&
        renderDropDownItem("Add location", () =>
          setAction(DataTableMenuActions.AddLocation, business)
        )}
      {!business?.account?.data?.pal?.data?.id &&
        !hideCreditCardFields &&
        renderDropDownItem("Add credit card", () =>
          setAction(DataTableMenuActions.AddCreditCard, business)
        )}
      {!business?.account?.data?.pal?.data?.id &&
        renderDropDownItem("Choose Plan", () =>
          setAction(DataTableMenuActions.ChoosePlan, business)
        )}
      {!business?.account?.data?.pal?.data?.id &&
        makePalEnabled &&
        renderDropDownItem("Make a Pal", () => setAction(DataTableMenuActions.MakePal, business))}
      {!!business?.account?.data?.pal?.data?.id &&
        renderDropDownItem("Edit Pal Status", () =>
          setAction(DataTableMenuActions.SetPalStatus, business)
        )}
      {renderDropDownItem("Send reset password email", () =>
        setAction(DataTableMenuActions.ResetPassword, business)
      )}
      {renderDropDownItem("Delete", () => setAction(DataTableMenuActions.Delete, business))}
      {config.featureFlag.businessPolicy && isListMenu && (
        <Dropdown.Link
          to={`/${kebabCased.pluralEntityName}/${business.id}/${kebabCased.singularEntityName}-policies`}
        >
          {programOrBusiness} Policies
        </Dropdown.Link>
      )}
      {supportTicketingEnabled && (
        <Dropdown.Link onClick={() => setAction(DataTableMenuActions.SubmitTicket, business)}>
          Submit Ticket
        </Dropdown.Link>
      )}
    </DropdownActionMenu>
  );
};

export default BusinessActionsMenu;
