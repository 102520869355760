import { AlertCircle } from "@styled-icons/ionicons-outline/AlertCircle";
import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import { Formik, Form as FormikForm } from "formik";
import React, { useState } from "react";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import styled from "styled-components";

import { ButtonNew as Button } from "components/Button";
import { Flex } from "components/Flex";
import Form from "components/Form";
import { ScheduleVisitLabel as Label } from "components/Form/ScheduleVisitLabel";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Text from "components/Text";
import PartnerSelect from "components/UserSelect/PartnerSelect";
import { useUploadPartnerOperatedVisitsCsvMutation } from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";

import { Values, schema } from "./schema";

const CloseOutPOV = () => {
  const { isSupervisor } = useCurrentAccountRole();
  const [uploadPartnerOperatedVisitCSV] = useUploadPartnerOperatedVisitsCsvMutation();
  const currentAccount = useCurrentAccount();
  const loading = !currentAccount;
  const [success, setSuccess] = useState(false);

  useTopBarOptions({
    title: "Close Out POV",
    icon: AccessTime,
    breadcrumbs: ["Visits"],
  });

  const initialValues = {
    partnerId: "",
    file: null,
  };

  const handleSubmit = async ({ file, partnerId }: Values) => {
    if (!file || !partnerId) return;

    try {
      const { data } = await uploadPartnerOperatedVisitCSV({
        variables: {
          file,
          partnerId,
        },
      });

      if (data?.uploadPartnerOperatedVisitsCsv === "successful") {
        setSuccess(true);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (loading) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height={1015}
        backgroundColor="#f3f3f3"
        foregroundColor="#c0c0c0"
        uniqueKey="blockLoad"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />
        <rect x="0" y="60" rx="3" ry="3" width="100%" height="290" />
      </ContentLoader>
    );
  }

  if (!isSupervisor) {
    return (
      <div>
        <h3>This page may only be accessed by supervisors</h3>
      </div>
    );
  }

  return (
    <div>
      <Header>Partner Operated Visits</Header>
      <Content>
        {success ? (
          <Flex flexDirection="column" gridGap="2rem" alignItems="flex-start">
            <Alert>
              <span>
                <AlertCircle size={30} />
              </span>
              File uploaded successfully. We will send you an email with the results shortly.
            </Alert>
            <Button size="small" onClick={() => setSuccess(false)}>
              Back to form
            </Button>
          </Flex>
        ) : (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            <FormikForm>
              <Form.Group>
                <Label>Partner</Label>
                <PartnerSelect />
              </Form.Group>
              <Form.Group controlId="file">
                <Form.FileInput name="file" label="CSV file" />
                <Text size="small" color="muted">
                  In order to close out partner operated visits, please upload the file you
                  downloaded from the Uber dashboard. Once the file has processed, you will receive
                  an email with the results including any visits that could not be matched.
                </Text>
              </Form.Group>
              <Flex justifyContent="flex-start" marginTop="2rem">
                <Form.SubmitButton action noContent>
                  Process File
                </Form.SubmitButton>
              </Flex>
            </FormikForm>
          </Formik>
        )}
      </Content>
    </div>
  );
};

const Alert = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.variants.success};
  color: white;
  font-size: 18px;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  margin-bottom: 4rem;

  span {
    margin-right: 0.5rem;
  }
`;

const Header = styled.div`
  background: #0e2765;
  display: flex;
  align-items: center;
  padding-left: 6rem;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  transition: background 0.4s ease-in-out;
  border-radius: 7px 7px 0 0;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.375rem;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
`;

const Content = styled.div`
  background-color: #ffffff;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  padding-left: 9.875rem;
  padding-right: 6.25rem;
  border-radius: 0 0 7px 7px;
  min-height: 300px;
`;

export default CloseOutPOV;
