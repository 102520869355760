import React, { HTMLAttributes } from "react";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { Loader } from "components/Loader";
import ConfirmModal from "components/Modal/ConfirmModal";
import { SwitchButtonInput } from "components/SwitchButton";
import { useSetIsTestPapaMutation } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";

interface Props {
  papaId: string;
  isTestPapa?: boolean | null;
}

const Switch = ({ papaId, isTestPapa }: Props) => {
  const { isOpen: confirmModalOpen, toggle: toggleConfirmModal } = useModalToggle();
  const [setIsTestPapa, { loading }] = useSetIsTestPapaMutation();

  const { isSuperAdmin, isEngineeringAdmin } = useCurrentAccountRole();
  const isAuthorized = isSuperAdmin || isEngineeringAdmin;

  if (!isAuthorized) return null;

  const handleToggle = async () => {
    try {
      const { data } = await setIsTestPapa({
        variables: { id: papaId, newValue: !isTestPapa },
      });

      if (data?.setIsTestPapa?.data?.id) {
        toast.success(`${isTestPapa ? "Unset as test papa" : "Set to test papa"}`);
      } else {
        toast.error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Container>
      <SwitchButton
        label={loading ? <Loader /> : "Test Papa"}
        checked={isTestPapa ?? false}
        disabled={!isAuthorized || loading}
        onClick={toggleConfirmModal}
      />

      <ConfirmModal
        isOpen={confirmModalOpen}
        onCancel={toggleConfirmModal}
        onConfirm={() => {
          handleToggle();
          toggleConfirmModal();
        }}
        header="Please Confirm"
      >
        {isTestPapa
          ? "You are removing this Member from the test group. Please ensure their language preference is correct. Are you sure you want to unset this as a test papa?"
          : "Setting this Member as a Test Member will remove their visits from our payment system and from being seen by Field Pals. Are you sure you want to set this as a test papa?"}
      </ConfirmModal>
    </Container>
  );
};

type SwitchButtonProps = {
  label: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const SwitchButton = ({ label, checked, disabled, ...inputProps }: SwitchButtonProps) => {
  return (
    <>
      <Label disabled={disabled}>
        <SwitchButtonInput readOnly {...inputProps} checked={checked} disabled={disabled} />
        <Text>{label}</Text>
      </Label>
    </>
  );
};

const Label = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 10px;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.text.secondary};
    `}
`;

const Text = styled.span`
  margin-left: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

export default Switch;
