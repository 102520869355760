import { gql } from "@apollo/client";

export const BUSINESSES_LIST = gql`
  query BusinessAccounts(
    $filter: [BusinessFilter]
    $pagination: PaginationInput
    $sorting: BusinessSorting
  ) {
    businesses(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        name
        inboundMemberSupportNumber
        account {
          data {
            email
            organization {
              data {
                id
                name
              }
            }
            plan {
              data {
                name
              }
            }
          }
        }
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;

export const BUSINESSES_DETAILS = gql`
  query businessDetails($businessId: ID!) {
    business(id: $businessId) {
      data {
        id
        name
        type
        eligibleLifeS3Folder
        eligibleEmployeeS3Folder
        email
        accountId
        useCustomVisitLeadHours
        customVisitLeadHours
        inboundMemberSupportNumber
        account {
          data {
            id
            fullName
            emailVerified
            about
            stripeCustomerId
            stripeSubscriptionId
            insertedAt
            updatedAt
            deletedAt
            requiresSignature
            requiresDrugTest
            testAccount
            didLatestPaymentFail
            permissions {
              admin
            }
            createdBy {
              data {
                email
                fullName
                id
              }
            }
            plan {
              data {
                id
                name
                description
                hourlyRate
                monthlyRate
                accessLevel
                trialPeriod
                insertedAt
                deletedAt
              }
            }
            billingProfile {
              data {
                id
                activeBillingProfilePaymentRate {
                  data {
                    id
                    paymentRate {
                      data {
                        id
                        chargeForInitialHalfHour
                        chargeForInitialHalfHourVirtual
                        chargeForInitialHour
                        chargeForInitialHourVirtual
                        courtesyTransportMiles
                        ratePerMileInTenths
                        ratePerMinuteInTenths
                        ratePerVirtualMinuteInTenths
                        rateDelivery
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BUSINESS_ABOUT = gql`
  query businessAccount($id: ID!) {
    account(id: $id) {
      data {
        about
        insertedAt
        updatedAt
      }
    }
  }
`;
