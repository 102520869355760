import React from "react";

import { ModalLayout } from "../Modal/ModalLayout";
import { LocationForm } from "./LocationForm";
import { Props as LocationFormProps } from "./LocationForm";

interface Props extends Partial<LocationFormProps> {
  isOpen: boolean;
  toggle: () => void;
  onSubmittingFinish?: () => void;
}
export const LocationFormModal: React.FC<Props> = ({
  toggle,
  isOpen,
  locationId,
  onSubmittingFinish = () => {},
  ...rest
}) => {
  return (
    <ModalLayout
      toggle={toggle}
      title={locationId ? "Edit location" : "Add location"}
      isOpen={isOpen}
    >
      <LocationForm onClose={toggle} onSubmittingFinish={onSubmittingFinish} {...rest} />
    </ModalLayout>
  );
};
