import React from "react";

import Conditional from "components/Conditional";
import Text from "components/Text";

interface Props {
  hasActiveBizPolicy: boolean;
  isHealthPlanMember: boolean;
}

const HeaderContents: React.FC<Props> = ({ hasActiveBizPolicy, isHealthPlanMember }) => {
  const getMessage = () => {
    if (!hasActiveBizPolicy && isHealthPlanMember) {
      return "No hours are displayed because this person is not part of a Health Plan with an active Papa benefit.";
    } else if (!isHealthPlanMember) {
      return "No hours are displayed since this Papa is associated with an Employer account.";
    } else {
      return "";
    }
  };

  return (
    <>
      <Conditional show={!hasActiveBizPolicy || !isHealthPlanMember}>
        <Text marginTop=".75rem" marginBottom=".5rem">
          {getMessage()}
        </Text>
      </Conditional>
    </>
  );
};

export default HeaderContents;
