import { useContext } from "react";

import { CurrentUserContext } from "contexts/currentUserContext";

export const useCurrentAccountPermission = (permissions: string[]) => {
  const { data } = useContext(CurrentUserContext);

  const accessPermissions = data?.currentAccount?.data?.accessPermissions?.data?.map(
    (permission) => permission?.name
  );

  return permissions.every((permission) => accessPermissions?.includes(permission));
};
