import React, { ReactElement } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { LocationFormModal } from "components/LocationForm/LocationFormModal";
import { ModalLayout } from "components/Modal/ModalLayout";
import { FeatureFlags } from "constants/featureFlags";
import { Account } from "generated/types";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DeleteAccountModal } from "modals/Accounts/DeleteAccount";
import EditOverview from "modals/Accounts/EditOverview";
import AddCreditCard from "modals/AddCreditCard";
import ChoosePlanForm from "modals/ChoosePlan/ChoosePlanForm";
import MakePalModal from "modals/MakePal";
import { ResetPasswordModal } from "modals/ResetPasswordModal/ResetPasswordModal";
import { ChangeStatusFormModal } from "pages/Pals/List/ChangeStatus/ChangeStatusFormModal";
import CreatePapaModal from "pages/Papas/List/CreatePapa/CreatePapaModal";

interface Props {
  action: DataTableMenuActions;
  account: Account | null;
  onClose: () => void;
  onRefetch: () => void;
}

const Actions = ({ action, account, onClose, onRefetch }: Props): ReactElement => {
  const makePalEnabled = useIsFeatureEnabled(FeatureFlags.EnableMakePal);

  return (
    <>
      {makePalEnabled && (
        <MakePalModal
          isOpen={action === DataTableMenuActions.MakePal}
          account={account}
          onClose={onClose}
        />
      )}

      <ModalLayout
        toggle={onClose}
        title="Choose membership plan"
        isOpen={action === DataTableMenuActions.ChoosePlan}
      >
        <ChoosePlanForm accountId={account?.id || ""} closeModal={onClose} />
      </ModalLayout>

      {account?.id && (
        <>
          <LocationFormModal
            accountId={account.id}
            isOpen={action === DataTableMenuActions.AddLocation}
            toggle={onClose}
          />
          <CreatePapaModal
            accountId={account.id}
            isOpen={action === DataTableMenuActions.AddPapa}
            toggle={onClose}
          />
        </>
      )}

      <ResetPasswordModal
        email={account?.email || ""}
        isOpen={action === DataTableMenuActions.ResetPassword}
        toggle={onClose}
      />

      <ModalLayout
        toggle={onClose}
        title="Add Credit Card"
        isOpen={action === DataTableMenuActions.AddCreditCard}
      >
        <AddCreditCard accountId={account?.id || ""} closeModal={onClose} />
      </ModalLayout>

      <DeleteAccountModal
        account={account}
        isOpen={action === DataTableMenuActions.Delete}
        toggle={onClose}
        onDeleteFinish={onRefetch}
      />

      <ChangeStatusFormModal
        isOpen={action === DataTableMenuActions.SetPalStatus}
        toggle={onClose}
        palId={account?.pal?.data?.id || ""}
        status={account?.pal?.data?.status || null}
      />

      <ModalLayout
        title="Edit Member Overview"
        isOpen={action === DataTableMenuActions.Edit}
        toggle={onClose}
      >
        <EditOverview account={account ?? {}} toggleShow={onClose} onEditFinish={onRefetch} />
      </ModalLayout>
    </>
  );
};

export default Actions;
