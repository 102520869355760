import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import { SortDirection } from "generated/types";
import { useUrlQuery } from "hooks/useUrlQuery";

interface SortingTypeGeneric {
  [key: string]: SortDirection | null | undefined;
}

export const useSorting = <SortingType extends SortingTypeGeneric>(
  sortingFields: string[],
  defaultSorting?: SortingType
): [SortingType, (newSorting: SortingType) => void] => {
  const history = useHistory();
  const location = useLocation();
  const urlQuery = useUrlQuery();

  const sorting: SortingTypeGeneric = {};

  sortingFields.forEach((key) => {
    sorting[key] =
      (urlQuery[`${key}Sorting`] as SortDirection) ?? defaultSorting?.[key] ?? SortDirection.Asc;
  });

  const setSorting = (newSorting: SortingType) => {
    const sortingQuery: SortingTypeGeneric = {};

    sortingFields.forEach((key) => {
      sortingQuery[`${key}Sorting`] = newSorting[key];
    });

    const urlQueryString = queryString.stringify(
      { ...urlQuery, ...sortingQuery },
      { arrayFormat: "index" }
    );

    history.push(location.pathname + "?" + urlQueryString);
  };

  return [sorting as SortingType, setSorting];
};
