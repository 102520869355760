import { Schema } from "./schema";

export const initialValues: Schema = {
  accountId: null,
  caregiverId: null,
  relationship: null,
  language: null,
  fullName: "",
  email: "",
  nickname: "",
  birthDate: null,
  gender: null,
  preferredContactMethod: null,
  fixedPhoneNumber: "",
  phoneNumber: "",
  warnings: null,
  requirements: null,
  livingSituation: null,
  answers: ["", "", ""],
  description: "",
  languagePreference: null,
  genderPreference: null,
  vehiclePreference: null,
  favoritePalIds: [],
  stillDrives: false,
  memberId: "",
  covidVaccinated: false,
  genderPreferenceIsFlexible: null,
  genderPreferenceWasAsked: false,
};
