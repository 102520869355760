import { withProperties } from "utils/type-helper";

import { Drawer as DrawerMain } from "./Drawer";
import { DrawerContainer, DrawerFooter } from "./DrawerChildren";

export type { DrawerProps } from "./Drawer";
export type { DrawerFooterProps } from "./DrawerChildren";

export const Drawer = withProperties(DrawerMain, {
  Container: DrawerContainer,
  Footer: DrawerFooter,
});
