import { DocumentNode, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { Loader } from "components/Loader";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { Plan } from "generated/types";

import { PlanPicker } from "./PlanPicker";
import { GET_PLANS, PICK_PLAN } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  accountId: string;
  refetchQuery?: DocumentNode;
  closeModal: () => void;
}

interface PlanListResult {
  data: Plan[];
}

interface Data {
  plans: PlanListResult;
}

const ChoosePlanForm = ({ accountId, closeModal }: Props): ReactElement => {
  const { data, loading } = useQuery<Data>(GET_PLANS);
  const [pickPlan] = useMutation(PICK_PLAN);

  const initialValues: Schema = {
    planId: null,
  };

  const handleSubmit = async ({ planId }: Schema) => {
    try {
      const { data } = await pickPlan({
        variables: {
          input: {
            accountId,
            planId,
          },
        },
      });

      if (data?.pickPlan.success) {
        toast.success("Choose plan success!");
        closeModal();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (loading) return <Loader />;

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          {data?.plans?.data && <PlanPicker plans={data.plans.data} />}
        </ModalContainer>

        <ModalFooter>
          <Button type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};

export default ChoosePlanForm;
