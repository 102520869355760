import { useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { LOGOUT } from "components/Sidebar/gql";
import { useMountEffect } from "hooks/useMountEffect";

interface Props {
  withOkta: boolean;
}

const Logout = ({ withOkta }: Props) => {
  const history = useHistory();
  const [logout] = useMutation(LOGOUT);

  useMountEffect(() => {
    if (withOkta) {
      toast.info("You successfully logged out.");
      history.push("/login");
    } else {
      (async () => {
        setTimeout(() => {
          if (window.location.pathname === "/logout") {
            window.location.pathname = "/login";
          }
        }, 7000);
        try {
          await logout();
          localStorage.removeItem("token");
        } catch (error) {
          window.console.error(error);
        }
        toast.info("Logged out due to inactivity");
        history.push("/login");
      })();
    }
  });

  return <>Loading...</>;
};

export default Logout;
