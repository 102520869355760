import { gql } from "@apollo/client";

export const REVERT_VISIT_START = gql`
  mutation revertVisitStart($id: ID!) {
    revertVisitStart(id: $id) {
      data {
        id
        state
        startedAt
      }
    }
  }
`;
