import { Formik } from "formik";
import React from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { useCreateVisitProgramMutation } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import { GET_VISIT_PROGRAMS } from "../List/gql";
import { FormValues, schema } from "./schema";

interface Props {
  toggle: () => void;
  onCreateFinish: () => void;
}

export const VisitProgramForm: React.FC<Props> = ({ toggle, onCreateFinish }) => {
  const { businessId } = useParams<{ businessId: string }>();
  const { beforeCursor, afterCursor, limit } = usePageInfo();
  const [createProgram] = useCreateVisitProgramMutation({
    refetchQueries: [
      {
        query: GET_VISIT_PROGRAMS,
        variables: {
          filter: {
            businessId,
          },
          pagination: { beforeCursor, afterCursor, limit },
        },
      },
    ],
  });

  const onSubmit = async ({ name, templates }: FormValues) => {
    try {
      const { data } = await createProgram({
        variables: {
          input: {
            businessId,
            name,
            numberOfTemplates: templates as number,
          },
        },
      });

      if (data?.createVisitProgram?.data?.id) {
        toast.success("Program was created with success");
        onCreateFinish();
        toggle();
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const initialValues = {
    name: "",
    templates: null,
  };

  return (
    <Formik<FormValues> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <Form.Group>
            <Form.Label htmlFor="name">Name</Form.Label>
            <Form.Input name="name" placeholder="Program name" id="name" />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="templates">Templates</Form.Label>
            <Form.Input
              name="templates"
              type="number"
              placeholder="Number of templates"
              id="templates"
            />
          </Form.Group>
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={toggle}>
            Close
          </Button>
          <Form.SubmitButton>Create Program</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
