import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import Button from "components/Button";
import { FlexRow } from "components/Flex";
import { ModalLayout } from "components/Modal/ModalLayout";
import SmartTable from "components/SmartTable";
import {
  PalSingleResult,
  SortDirection,
  TipaltiDisbursement,
  TipaltiDisbursementSorting,
} from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { usePageInfo } from "hooks/usePageInfo";
import { TipaltiDisbursementsFilterModal } from "modals/TipaltiDisbursements/TipaltiDisbursementsFilterModal";
import { countFilters, tipaltiDisbursementsFiltersMaker } from "utils/tipaltiDisbursementsFilters";

import { GET_PAL_TIPALTI_DISBURSEMENTS } from "./gql";
import { useTipaltiDisbursementsTableColumns } from "./useTipaltiDisbursementsTableColumns";

type Data = {
  pal: PalSingleResult;
};

const Payments = () => {
  const { id } = useParams<{ id: string }>();
  const [sorting, setSorting] = useState<Partial<TipaltiDisbursementSorting>>({
    invoiceDate: SortDirection.Desc,
  });
  const { columns } = useTipaltiDisbursementsTableColumns({
    sorting,
    onSortChange: setSorting,
  });
  const { page, limit, ...tipaltiDisbursementsFilters } = usePageInfo();
  const { isOpen: isFiltersModalOpen, toggle: toggleFiltersModal } = useModalToggle();
  const countFilter = countFilters(tipaltiDisbursementsFilters);
  const tipaltiDisbursementFilter = tipaltiDisbursementsFiltersMaker({
    ...tipaltiDisbursementsFilters,
  });

  const { data, loading, error } = useQuery<Data>(GET_PAL_TIPALTI_DISBURSEMENTS, {
    variables: {
      id: id,
      pagination: { page, limit },
      filter: isEmpty(tipaltiDisbursementFilter) ? null : tipaltiDisbursementFilter,
      sorting,
    },
  });

  const tipaltiDisbursements = data?.pal?.data?.tipaltiDisbursements?.data;
  const pagination = data?.pal?.data?.tipaltiDisbursements?.pagination;

  return (
    <>
      <Helmet>
        <title>Pal Payments - Papa Admin</title>
      </Helmet>

      <FlexRow justifyContent="space-between" wrap="wrap-reverse">
        <Button type="button" onClick={toggleFiltersModal}>
          <span>Filter</span>
          {countFilter > 0 && <span>({countFilter})</span>}
        </Button>
      </FlexRow>

      <SmartTable<TipaltiDisbursement>
        keyField="id"
        data={tipaltiDisbursements}
        pagination={pagination}
        loading={loading}
        columns={columns}
        error={error}
      />
      <ModalLayout
        width={700}
        toggle={toggleFiltersModal}
        title="Tipalti Disbursements Filter"
        isOpen={isFiltersModalOpen}
      >
        <TipaltiDisbursementsFilterModal onSubmit={toggleFiltersModal} />
      </ModalLayout>
    </>
  );
};

export default Payments;
