import { Maybe } from "graphql/jsutils/Maybe";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import Button from "components/Button";
import Conditional from "components/Conditional";
import { Drawer } from "components/Drawer";
import DurationSelects from "components/DurationSelects";
import FormSimple from "components/FormSimple";
import YesNoRadioGroup from "components/YesNoRadioGroup";
import {
  BusinessPolicy,
  BusinessPolicySingleResult,
  PapaAllotmentPeriod,
  useGrantCreditMutation,
} from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { GET_PAPA_OVERVIEW } from "pages/Papas/gql";
import { creditEvent } from "utils/segment";

import AllotmentPeriodSelect from "./AllotmentPeriodSelect";

type AllotmentPeriod = Pick<PapaAllotmentPeriod, "id" | "startDate" | "endDate"> & {
  businessPolicy?: Maybe<BusinessPolicySingleResult>;
};

interface Props {
  allotmentPeriods: AllotmentPeriod[];
  businessPolicy: Pick<BusinessPolicy, "id" | "startsAt" | "endsAt">;
  initialAllotmentPeriod?: PapaAllotmentPeriod | null;
  papaId: string;
  visitId?: string;
  onClose: () => void;
  onAllotmentPeriodChange?: (ap: AllotmentPeriod) => void;
}

type ReactSelectOption = {
  value: string;
  label: string;
};

const AddCredit: React.FC<Props> = ({
  allotmentPeriods,
  initialAllotmentPeriod,
  businessPolicy,
  papaId,
  visitId: presetVisitId = "",
  onClose,
  onAllotmentPeriodChange,
}) => {
  //// HOOKS ////
  const [allotmentPeriod, setAllotmentPeriod] = useState<AllotmentPeriod | null>(null);
  const [confirmed, setConfirmed] = useState<boolean | null>(null);
  const [visitId, setVisitId] = useState<string>(presetVisitId);
  const [ticketId, setTicketId] = useState<string>("");
  const [reason, setReason] = useState<ReactSelectOption | null>(null);
  const [notes, setNotes] = useState<string>("");
  const [minutes, setMinutes] = useState<number>(0);
  const [errors, setErrors] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const currentAccount = useCurrentAccount();
  const { isAdmin, isGrievancesAppealsAdmin, isSuperAdmin, isMemberSupportAdmin } =
    useCurrentAccountRole();
  const isElevatedRole = isGrievancesAppealsAdmin || isSuperAdmin || isMemberSupportAdmin;

  const [createCreditGrantMutation] = useGrantCreditMutation({
    refetchQueries: [{ query: GET_PAPA_OVERVIEW, variables: { papaId } }],
  });

  const routeMatch = useRouteMatch();

  //// FORM SUBMISSION ////
  const validate = (data: any): null | yup.ValidationError => {
    const schema = yup.object({
      allotmentPeriodId: yup.string().required("An allotment period is required"),
      minutes: yup
        .number()
        .required("A credit amount is required")
        .positive("Credit amount must be greater than 0")
        .integer(),
      papaId: yup.string().required(),
      creditReasonId: yup.string().required("A reason is required"),
      notes: yup.string().max(3000).required("Notes are required"),
      visitId: yup.string().when("$isElevatedRole", {
        is: false,
        then: yup.string().required("A Visit ID is required"),
        otherwise: yup.string(),
      }),
      ticketId: yup.string().when("$isElevatedRole", {
        is: true,
        then: yup.string().required("A Ticket ID is required"),
        otherwise: yup.string(),
      }),
    });

    try {
      schema.validateSync(data, { context: { isElevatedRole }, abortEarly: false });
      return null;
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        return err;
      }
      throw err;
    }
  };

  const submit = async () => {
    creditEvent("clicked submit credit", { papa_id: papaId, from_screen: routeMatch.path });

    const data = {
      allotmentPeriodId: allotmentPeriod?.id,
      minutes: minutes,
      papaId: papaId,
      creditReasonId: reason?.value!,
      notes: notes,
      visitId: visitId,
      ...(isElevatedRole ? { ticketId: ticketId } : {}),
    };

    const error = validate(data);

    if (error) {
      toast.error(error.message);
      const errorTuples = error.inner.map((e) => [e.path, e.message]);
      const formattedErrors = Object.fromEntries(errorTuples);
      setErrors(formattedErrors);
      return;
    } else {
      setErrors(null);
    }

    setIsSubmitting(true);
    try {
      const { data: result } = await createCreditGrantMutation({
        variables: { input: data },
      });

      if (result?.grantCredit?.data) {
        toast.success("Credit created");
        onClose();
      }
    } catch (error) {
      toast.error((error as Error).message);

      // Since this form is in a drawer, it's important that we only reset
      // isSubmitting in the case that there's an error. This serves the
      // purpose of preventing a double submission if the user double clicks
      // on the submit button. Even though it is disabled during form submission
      // there is an opportunity to resubmit while the drawer is closing.
      setIsSubmitting(false);
    }
  };

  ///// EVENT HANDLERS /////
  const handleAllotmentPeriodChanged = (ap: AllotmentPeriod, label?: string) => {
    // If there's not a label, that means the onChange is setting the initial value
    // which we don't want to track.
    if (label) {
      creditEvent("selected credit allotment period", {
        papa_id: papaId,
        selection: label,
        from_screen: routeMatch.path,
      });
    }

    setAllotmentPeriod(ap);
    if (onAllotmentPeriodChange) onAllotmentPeriodChange(ap);
  };

  const handleOnConfirmedChanged = (value: boolean) => {
    creditEvent("answered if credit will resolve issue", {
      papa_id: papaId,
      selection: value,
      from_screen: routeMatch.path,
    });
    setConfirmed(value);
  };

  const handleOnMinutesChanged = (value: number) => {
    creditEvent("selected credit amount", {
      papa_id: papaId,
      selection: value,
      from_screen: routeMatch.path,
    });
    setMinutes(value);
  };

  const handleOnReasonChanged = (option: any) => {
    creditEvent("selected credit reason", {
      papa_id: papaId,
      selection: option.label,
      from_screen: routeMatch.path,
    });

    if (option) setReason(option as ReactSelectOption);
  };

  const handleOnClose = () => {
    creditEvent("clicked cancel credit submission", {
      papa_id: papaId,
      from_screen: routeMatch.path,
    });

    onClose();
  };

  //// RENDERING ////
  if (!currentAccount?.availableCreditReasons.data) return null;

  const availableReasons: ReactSelectOption[] = currentAccount?.availableCreditReasons.data
    .map((reason) => ({ value: reason.id, label: reason.reasonName }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const maxDuration = isElevatedRole ? 20 * 60 : 60;

  return (
    <Wrapper>
      <Heading>Add credit</Heading>
      <Conditional show={isAdmin && !isElevatedRole}>
        <YesNoRadioGroup
          name="confirmed"
          label="Did you confirm granting a credit will resolve the member's issue?"
          value={confirmed}
          required
          onChange={handleOnConfirmedChanged}
        />
        <Conditional show={confirmed === false}>
          <FormSimple.Feedback isInvalid>
            Please confirm with the member that granting a credit will resolve the issue. If they'd
            rather escalate the issue, please submit a ticket.
          </FormSimple.Feedback>
        </Conditional>
      </Conditional>
      <Conditional show={!!confirmed || isElevatedRole}>
        <Group>
          <FormSimple.Label required>Allotment Period</FormSimple.Label>
          <AllotmentPeriodSelect
            allotmentPeriods={allotmentPeriods}
            businessPolicy={businessPolicy}
            onChange={handleAllotmentPeriodChanged}
            initialAllotmentPeriod={initialAllotmentPeriod}
            value={allotmentPeriod}
            isOnlyShowingAvailablePeriods
          />
        </Group>
        <Group>
          <FormSimple.Label required>Credit amount</FormSimple.Label>
          <DurationSelectsStyled
            value={minutes}
            onChange={handleOnMinutesChanged}
            maxDuration={maxDuration}
            error={errors?.minutes}
          />
        </Group>
        <Group>
          <FormSimple.Label required>Reason</FormSimple.Label>
          <FormSimple.Select
            name="reason"
            aria-label="reason"
            value={reason}
            options={availableReasons}
            onChange={handleOnReasonChanged}
            isSearchable
            error={errors?.creditReasonId}
          />
        </Group>
        <Group>
          <FormSimple.Label htmlFor="visitId" required={!isElevatedRole}>
            Visit ID (if applicable)
          </FormSimple.Label>
          <FormSimple.Input
            id="visitId"
            value={visitId}
            onChange={(value) => setVisitId(value)}
            error={errors?.visitId}
          />
        </Group>
        <Conditional show={isElevatedRole}>
          <Group>
            <FormSimple.Label required htmlFor="ticketId">
              Ticket ID
            </FormSimple.Label>
            <FormSimple.Input
              id="ticketId"
              value={ticketId}
              onChange={(value) => setTicketId(value)}
              error={errors?.ticketId}
            />
          </Group>
        </Conditional>
        <Group>
          <FormSimple.Label required>Notes</FormSimple.Label>
          <FormSimple.TextArea
            name="Notes"
            value={notes}
            onChange={(value) => setNotes(value)}
            error={errors?.notes}
          />
        </Group>

        <Drawer.Footer>
          <AddCreditButton block disabled={isSubmitting} action onClick={submit}>
            Add Credit
          </AddCreditButton>
          <Button block onClick={handleOnClose}>
            Cancel
          </Button>
        </Drawer.Footer>
      </Conditional>
    </Wrapper>
  );
};

const AddCreditButton = styled(Button)`
  &:before {
    content: "";
  }
  &:hover {
    opacity: 0.75;
  }
`;

const Group = styled("div")`
  margin-bottom: 1.25rem;
`;

const Wrapper = styled("div")`
  margin: 1.25rem;
  margin-left: 2rem;
`;

const Heading = styled("h3")`
  margin-top: 0;
  color: ${({ theme }) => theme.text.primary};
`;

const DurationSelectsStyled = styled(DurationSelects)`
  margin-top: 0;
`;

export default AddCredit;
