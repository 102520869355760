import { gql } from "@apollo/client";

export const UPDATE_OBJECTIVES = gql`
  mutation updateVisitObjectives($id: ID!, $input: VisitInput!) {
    updateVisit(id: $id, input: $input) {
      data {
        id
      }
    }
  }
`;
