import { useMutation } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";

import ConfirmModal from "components/Modal/ConfirmModal";
import { Account } from "generated/types";

import { MAKE_A_PAL } from "./gql";

interface Props {
  isOpen: boolean;
  account: Account | null;
  onClose: () => void;
}

const MakePalModal: FunctionComponent<Props> = ({ isOpen, account, onClose }) => {
  const fullName = account?.fullName || "";
  const [makePal, { loading }] = useMutation(MAKE_A_PAL);

  const onOkHandler = async () => {
    try {
      const input = { accountId: account?.id || "" };
      const { data } = await makePal({ variables: { input } });
      if (data?.createPal) {
        toast.success(`${fullName} is now a pal`);
        onClose();
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      loading={loading}
      header={`Make ${fullName} a pal`}
      onCancel={onClose}
      onConfirm={onOkHandler}
    >
      Are you sure that you want to make {fullName} a pal?
    </ConfirmModal>
  );
};

export default MakePalModal;
