import { useField } from "formik";
import React from "react";
import styled from "styled-components";

import Form from "components/Form";
import Feedback from "components/Form/FormFeedback";

interface Options {
  label: string;
  value: any;
  onChange: () => void;
  controlId: string;
}

interface Props {
  options: Options[];
  name: string;
}

export const RadioGroupParallel: React.FC<Props> = ({ options, name }) => {
  const [, { error, touched }] = useField(name);

  return (
    <>
      <Group>
        {options.map(({ controlId, label, value, onChange }, index) => (
          <Form.Radio
            key={value}
            value={value}
            name={name}
            controlId={controlId}
            label={label}
            onChange={onChange}
          />
        ))}
      </Group>
      {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
    </>
  );
};

const Group = styled.div`
  display: flex;
  flex-direction: row;

  div {
    padding-right: 100px;
  }
`;
