import { useMutation } from "@apollo/client";
import moment from "moment-timezone";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { ServiceRequestActions } from "components/ActionsMenu/ServiceRequestActionMenu/models";
import { StyledCheckbox } from "components/Checkbox";
import EditButton from "components/EditButton";
import { Column } from "components/SmartTable/schema";
import {
  ServiceRequestFollowup,
  ServiceRequestFollowupStatus,
  useGetServiceRequestFollowupActionOptionsQuery,
} from "generated/types";
import usePagination from "hooks/usePagination";
import { UPDATE_SERVICEREQUEST_FOLLOWUP } from "modals/CareConcierge/CreateFollowUp/gql";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { fancyEnum } from "utils/strings/fancyEnum";

import { GET_SERVICE_FOLLOWUPS } from "./gql";

interface Params {
  serviceId: string;
  onUpdateFinish: () => void;
}

export const useFollowUpsTableColumns = ({ serviceId, onUpdateFinish }: Params) => {
  const [action, setAction] = useState(ServiceRequestActions.NoAction);
  const { currentPage, currentLimit } = usePagination({ page: 1, limit: 10 });
  const [serviceRequestFollowup, setServiceRequestFollowup] = useState<
    ServiceRequestFollowup | undefined
  >(undefined);

  const { data } = useGetServiceRequestFollowupActionOptionsQuery();
  const serviceRequestFollowupActionOptions = nonNull(data?.getServiceRequestFollowupActionOptions);

  const [servicerequestFollowupMutation] = useMutation(UPDATE_SERVICEREQUEST_FOLLOWUP, {
    refetchQueries: [
      {
        query: GET_SERVICE_FOLLOWUPS,
        variables: {
          id: serviceId,
          filter: { status: { eq: ServiceRequestFollowupStatus.Opened } },
          pagination: { page: currentPage, limit: currentLimit },
        },
      },
      {
        query: GET_SERVICE_FOLLOWUPS,
        variables: {
          id: serviceId,
          filter: { status: { eq: ServiceRequestFollowupStatus.Completed } },
          pagination: { page: currentPage, limit: currentLimit },
        },
      },
    ],
  });
  const updateFollowup = async (id: string, completed: boolean) => {
    try {
      const { data } = await servicerequestFollowupMutation({
        variables: {
          id: id,
          input: {
            status: completed
              ? ServiceRequestFollowupStatus.Opened
              : ServiceRequestFollowupStatus.Completed,
            completedAt: completed ? null : moment(),
          },
        },
      });
      if (data?.updateServiceRequestFollowup?.data?.id) {
        onUpdateFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  const changeFollowUp = (id: string, completed: boolean) => {
    updateFollowup(id, completed);
  };
  const mapLabelFromValue = (value: string | undefined) => {
    return serviceRequestFollowupActionOptions.find((e) => e.value === value)?.label;
  };
  const columns: Column<ServiceRequestFollowup>[] = [
    {
      header: { title: "Status" },
      dataField: "id",
      formatter({ id, completed }) {
        return (
          <StyledCheckbox
            type="checkbox"
            checked={!!completed}
            onChange={() => changeFollowUp(id ?? "", !!completed)}
            id={id ?? undefined}
          />
        );
      },
    },
    {
      header: { title: "Follow up Type" },
      dataField: "action",
      formatter({ action }) {
        return mapLabelFromValue(action ?? "") || fancyEnum(action);
      },
    },
    {
      header: { title: "Due Date" },
      dataField: "dueAt",
      formatter(serviceRequestFollowup) {
        return formatDateTime(serviceRequestFollowup.dueAt, { format: "L" });
      },
    },
    {
      header: { title: "Assignee" },
      dataField: "assignedTo.data.fullName",
    },
    {
      header: { title: " " },
      dataField: "status",
      formatter(serviceRequestFollowup) {
        return (
          <EditButton
            onClick={() => updateFollowUp(serviceRequestFollowup as ServiceRequestFollowup)}
          >
            {serviceRequestFollowup.completed ? "View" : "View/Edit"}
          </EditButton>
        );
      },
    },
  ];

  const updateFollowUp = (serviceRequestFollowup: ServiceRequestFollowup) => {
    setAction(ServiceRequestActions.CreateServiceRequestFollowUp);
    setServiceRequestFollowup(serviceRequestFollowup);
  };

  const closeModal = () => {
    setAction(ServiceRequestActions.NoAction);
  };

  return {
    columns,
    action,
    serviceRequestFollowup,
    closeModal,
  };
};
