import moment from "moment-timezone";

import { DEFAULT_TIMEZONE, HOURS_FORMAT, ISO_FORMAT_DATE_WITH_TIME_ZONE } from "../constants/date";

interface Params {
  day: string;
  time: string;
  timezone?: string | null;
  formatDay?: string;
  formatTime?: string;
}

export const mergeDateAndTime = ({
  day,
  time,
  formatDay = "MMMM D, YYYY",
  formatTime = "LLL",
  timezone = DEFAULT_TIMEZONE,
}: Params) => {
  const momentDay = moment.tz(day, "UTC").format(formatDay);

  return moment.tz(`${momentDay} ${time}`, formatTime, timezone ?? DEFAULT_TIMEZONE).toISOString();
};

export const mergeTimezoneShiftedDateAndTime = ({
  day,
  time,
  formatDay = "MMMM D, YYYY",
  formatTime = "LLL",
  timezone = DEFAULT_TIMEZONE,
}: Params) => {
  const momentDay = moment.tz(day, timezone ?? DEFAULT_TIMEZONE).format(formatDay);

  return moment.tz(`${momentDay} ${time}`, formatTime, timezone ?? DEFAULT_TIMEZONE).toISOString();
};

export const getDuration = (value: number, unit: string, format = "HH:mm") => {
  const duration = moment.duration(value, "minutes").asMilliseconds();

  return moment.utc(duration).format(format);
};

export const getHoursWithInterval = (interval: number, format = HOURS_FORMAT) => {
  const minutesInHour = 60;
  const hoursInDay = 24;
  const splitIntervals = minutesInHour / interval;
  const intervalItems = Array.from({ length: hoursInDay * splitIntervals }, (_, index) => {
    const hour = index / splitIntervals;
    const minute = (index % splitIntervals) * interval;

    return moment({ hour, minute }).format(format);
  });

  intervalItems.push("11:59 pm");
  return intervalItems;
};

export const applyTimezoneToDate = (date: string, format: string, tz: string): string => {
  return moment(date, format).tz(tz).format(ISO_FORMAT_DATE_WITH_TIME_ZONE);
};
