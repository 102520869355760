import { useState } from "react";

export const useRowsSelectList = () => {
  const [rowsSelected, setRowsSelected] = useState<string[]>([]);

  const onRowSelect = (id: string) => {
    if (rowsSelected.includes(id)) {
      setRowsSelected(rowsSelected.filter((item) => item !== id));
    } else {
      setRowsSelected([...rowsSelected, id]);
    }
  };

  const onAllRowsSelect = (ids: string[]) => {
    setRowsSelected(ids);
  };

  return {
    rowsSelected,
    onRowSelect,
    onAllRowsSelect,
    setRowsSelected,
  };
};
