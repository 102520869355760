import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import { ModalLayout } from "components/Modal/ModalLayout";
import Panel from "components/Panel";
import Text from "components/Text";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Business } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { useModalToggle } from "hooks/useModalToggle";
import { BusinessAboutEditForm } from "modals/Businesses/BusinessAboutEditForm";
import { formatDateTime } from "utils/helpers/formatters";

import PaymentRatePanel from "./PaymentRatePanel";

type Props = {
  business: Business;
};

const About: React.FC<Props> = ({ business }) => {
  const { singularEntityName, capitalized } = useCallItClientProgramOrBusiness();
  const { isOpen: showEditModal, toggle: toggleEditModal } = useModalToggle();
  const aboutThis = `About this ${singularEntityName}`;

  const {
    about,
    insertedAt,
    updatedAt,
    billingProfile,
    id: accountId,
  } = business?.account?.data ?? {};
  const paymentRate =
    billingProfile?.data?.activeBillingProfilePaymentRate?.data?.paymentRate?.data;

  return (
    <>
      <Helmet>
        <title>{capitalized.singularEntityName} About - Papa Admin</title>
      </Helmet>

      <Grid>
        <Column>
          <Panel title={aboutThis} onEditClick={toggleEditModal}>
            <Panel.Item>
              <Text>{about || EMPTY_PLACEHOLDER}</Text>
            </Panel.Item>
          </Panel>
        </Column>
      </Grid>
      <Grid>
        <Column>
          <PaymentRatePanel paymentRate={paymentRate} />
        </Column>
      </Grid>
      <Grid>
        <Column>
          <Panel>
            {!!insertedAt && (
              <Panel.Item>
                <Text>Joined at</Text>
                <Text>{formatDateTime(insertedAt)}</Text>
              </Panel.Item>
            )}
            {!!updatedAt && (
              <Panel.Item>
                <Text>Updated at</Text>
                <Text>{formatDateTime(updatedAt)}</Text>
              </Panel.Item>
            )}
          </Panel>
        </Column>
      </Grid>

      {accountId && (
        <ModalLayout toggle={toggleEditModal} title={aboutThis} isOpen={showEditModal}>
          <BusinessAboutEditForm
            id={accountId}
            about={about || ""}
            onEditFinish={toggleEditModal}
          />
        </ModalLayout>
      )}
    </>
  );
};

type ColumnProps = {
  size?: number;
};

const Grid = styled("div")`
  display: flex;
  margin: 0 -0.938rem;
`;

const Column = styled("div")<ColumnProps>`
  flex: ${({ size = 1 }) => size};
  margin: 0 0.938rem;
`;

export default About;
