import isNaN from "lodash/isNaN";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";

import { Column, ColumnFormatter, ColumnSettings, RequiredRowParams } from "./schema";

export const mapColumnToSettings = <T>({ dataField }: Column<T>): ColumnSettings => ({
  id: dataField,
  isHidden: false,
});

export const applyFormatter = <T>(
  formatter: ColumnFormatter<T>,
  cell: string | number,
  row: T,
  index: number
) => {
  if (isUndefined(cell) || isNaN(cell) || isNull(cell)) {
    return "Empty";
  }

  return formatter(row, index);
};

export const includingOnSelected = <T>(
  a: string[],
  b: ((T & RequiredRowParams) | null)[] | null,
  keyField: string
) => {
  if (!b) {
    return a;
  }
  const newSelect = [...a];
  for (const i of b) {
    if (i && !a.includes(i[keyField])) {
      newSelect.push(i[keyField]);
    }
  }
  return newSelect;
};

export const removeFromSelected = <T>(
  a: string[],
  b: ((T & RequiredRowParams) | null)[] | null,
  keyField: string
) => a.filter((aItem) => !b?.find((bItem) => bItem && bItem[keyField] === aItem));

export const isInclude = <T>(
  a: string[],
  b: ((T & RequiredRowParams) | null)[] | null,
  keyField: string
) => {
  return !!a.find((aItem) => b?.find((bItem) => bItem && aItem === bItem[keyField]));
};

export const isContain = <T>(
  a: string[],
  b: ((T & RequiredRowParams) | null)[] | null,
  keyField: string
) => {
  return (
    b?.filter((bItem) => a.find((aItem) => bItem && bItem[keyField] === aItem)).length ===
      b?.length && !!b?.length
  );
};
