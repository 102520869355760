import { HardCodedQuestionsRequired, QuestionsFlow } from "types/assessments";

/* Putting all of the questions into one group defeats the purpose of having groups
(That purpose being to break down the displaying of questions into multiple screens).
However, this cheat allows us to use the legacy PSI survey logic for the conditional
rendering of questions with little to no code changes */
export const groups = [
  {
    id: "S1",
    questions: ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "P10", "P11", "P12"],
  },
];

export const hardcodedQuestionRequired: HardCodedQuestionsRequired = {
  P1: true,
  P2: true,
  P3: true,
  P4: true,
  P7: true,
  P8: true,
  P9: true,
  P10: true,
  P12: true,
};

export const questionsFlow: QuestionsFlow = {
  P5: {
    P4: "i went to the emergency room",
  },
  P6: {
    P4: "i was admitted to the hospital and stayed overnight",
  },
  P11: {
    P10: ["often", "sometimes"],
  },
};
