import React from "react";
import styled from "styled-components";

import Form from "components/Form";
import Select from "components/Select";
import { intervalOptions } from "constants/business-policy";

export const HourAllotment = () => {
  return (
    <>
      <Form.Group>
        <Form.Label>Billable Hour Allotment</Form.Label>
        <BillableHourAllotment>
          <div>
            <Form.Input type="number" name="numberOfHours" className="hours-input" />
          </div>
          <HoursPer>hour(s) per</HoursPer>
          <Select
            aria-label="interval"
            name="intervalUnit"
            className="interval-selector"
            options={intervalOptions}
            isSearchable={false}
          />
        </BillableHourAllotment>
      </Form.Group>
    </>
  );
};

const BillableHourAllotment = styled.div`
  display: flex;
  align-items: center;

  .hours-input {
    width: 8rem;
    input {
      width: 100%;
    }
  }

  .interval-selector {
    min-width: 8.5rem;
    width: 8.5rem;
  }
`;

const HoursPer = styled.div`
  font-style: italic;
  flex-shrink: 0;
  padding: 0 1rem;
`;

export default HourAllotment;
