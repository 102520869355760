import React from "react";

import Modal from "components/Modal";

import PapaDelete from "./PapaDelete";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  id: string;
  fullName: string;
  isToDelete: boolean;
  onDeleteFinish: () => void;
}

export const PapaDeleteModal: React.FC<Props> = ({
  isOpen,
  toggle,
  id,
  isToDelete,
  fullName,
  onDeleteFinish,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={toggle}>
      <PapaDelete
        id={id}
        fullName={fullName}
        isToDelete={isToDelete}
        closeModal={toggle}
        onDeleteFinish={onDeleteFinish}
      />
    </Modal>
  );
};
