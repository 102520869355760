import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";

import { Papa } from "generated/types";
import { PapaConcealed } from "types";
import { Nullable, nonNull } from "utils/nonNull";

import { concealedBoolean, concealedField } from "./concealedField";

export const papasConcealed = (papas: Nullable<Nullable<Papa>[]> = []): PapaConcealed[] => {
  return nonNull(papas).map((papa) => papaConcealedFormat(papa));
};

export const papaConcealed = (entity?: Papa | null) => {
  if (isUndefined(entity) || isNull(entity)) {
    return entity;
  }

  return papaConcealedFormat(entity);
};

export const papaConcealedFormat = (entity: Papa): PapaConcealed => {
  const dataVisibility = entity.dataVisibility;

  return {
    ...entity,
    fullName: concealedField("fullName", entity.fullName, dataVisibility),
    phoneNumber: concealedField("phoneNumber", entity.phoneNumber, dataVisibility),
    stillDrives: concealedBoolean("stillDrives", entity.stillDrives, dataVisibility),
    birthDate: concealedField("birthDate", entity.birthDate, dataVisibility),
  };
};
