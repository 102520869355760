import { gql } from "@apollo/client";

export const GET_PAPAS_ENROLLED = gql`
  query PapasEnrolledList(
    $filter: [PapaFilter]
    $pagination: PaginationInput
    $sorting: PapaSorting
  ) {
    papas(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        isEligible
        eligibleLife {
          data {
            id
          }
        }
        fullName
        phoneNumber
        gender
        insertedAt
        status
        dataVisibility {
          concealed
        }
        account {
          data {
            id
            fullName
          }
        }
      }
      pagination {
        limit
        page
        totalCount
        totalPages
      }
    }
  }
`;
