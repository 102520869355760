import { Papa, Visit } from "generated/types";
import { DataVisibility } from "types";

import { isFieldConcealed } from "./isFieldConcealed";
import { isFieldMasked } from "./isFieldMasked";

export type Field = keyof Papa | keyof Visit;

export const isFieldProtected = (field: Field, dataVisibility?: DataVisibility | null): boolean => {
  return isFieldMasked(field, dataVisibility) || isFieldConcealed(field, dataVisibility);
};

export const isFieldProtectedFN =
  (dataVisibility?: DataVisibility | null) =>
  (field: Field): boolean => {
    return isFieldProtected(field, dataVisibility);
  };
