import { gql } from "@apollo/client";

export const UNCONFIRM_VISIT = gql`
  mutation unconfirmVisit($id: ID!) {
    unconfirmVisit(id: $id) {
      data {
        id
        state
        confirmedAt
      }
    }
  }
`;
