import { gql } from "@apollo/client";

export const CREDIT_CARD_LIST_QUERY = gql`
  query creditCardsByAccount(
    $filter: [CreditCardFilter]
    $pagination: PaginationInput
    $sorting: CreditCardSorting
  ) {
    creditCards(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        last4
        default
        expMonth
        expYear
      }
    }
  }
`;

export const CREATE_CREDIT_CARD = gql`
  mutation createCreditCard($input: CreditCardInput!) {
    createCreditCard(input: $input) {
      data {
        id
        last4
      }
    }
  }
`;
