import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import SmartTable from "components/SmartTable";
import { CovidVaccinationRecord } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";

import { GET_PAL_VACCINATION } from "./gql";
import { usePalVerifyVaccinationColumns } from "./usePalVerifyVaccinationColumns";

const PalVerifyVaccination = () => {
  useTopBarOptions({
    title: "Verify Vaccination",
    icon: AccountCircle,
    breadcrumbs: ["Accounts"],
  });
  const location = useLocation();
  const { beforeCursor, afterCursor, limit } = usePageInfo();

  const { data, loading, error, refetch } = useQuery(GET_PAL_VACCINATION, {
    variables: {
      filter: [{ pendingReview: { boolEq: true } }],
      pagination: { beforeCursor, afterCursor, limit },
    },
  });

  const palVaccination = data?.covidVaccinationRecords?.data;
  const pagination = data?.covidVaccinationRecords?.pagination;

  const { columns } = usePalVerifyVaccinationColumns();

  useEffect(() => {
    const handleRefetch = async () => {
      await refetch();
    };
    if (location.pathname === "/verify-vaccination") {
      handleRefetch();
    }
  }, [location.pathname, refetch]);

  return (
    <>
      <Helmet>
        <title>Verify Vaccination - Papa Admin</title>
      </Helmet>
      <SmartTable<CovidVaccinationRecord>
        keyField="id"
        data={palVaccination}
        loading={loading}
        columns={columns}
        pagination={pagination}
        error={error}
        cursorPagination
      />
    </>
  );
};

export default PalVerifyVaccination;
