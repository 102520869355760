import { ConsentStatus, MessageServiceAuthorization } from "generated/types";

export enum TextMessagingStatusColor {
  NotEnrolled = "#2E5BFF",
  Pending = "#FCA009",
  OptIn = "#1BB934",
  OptOut = "#ED1C24",
}

export enum TextMessagingStatus {
  NotEnrolled,
  Pending,
  OptIn,
  OptOut,
}

export const calculateStatus = (
  messageServiceAuthorization?: MessageServiceAuthorization | null
) => {
  if (!messageServiceAuthorization) {
    return TextMessagingStatus.NotEnrolled;
  }

  const { consentStatus, consentAgreementSigned, autoEnrolled } = messageServiceAuthorization;

  if (consentStatus !== ConsentStatus.OptIn && !consentAgreementSigned && !autoEnrolled) {
    return TextMessagingStatus.Pending;
  }

  if (consentStatus === ConsentStatus.OptIn) {
    return TextMessagingStatus.OptIn;
  }

  if (consentStatus === ConsentStatus.OptOut) {
    return TextMessagingStatus.OptOut;
  }

  return null;
};
