import { useApolloClient } from "@apollo/client";
import { useField } from "formik";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import Async from "react-select/async";
import { ValueType } from "react-select/src/types";

import { searchFieldStyles } from "components/Search";
import {
  BusinessNameDocument,
  BusinessNameQuery,
  BusinessNameQueryVariables,
  SearchResultByBusinessDocument,
  SearchResultByBusinessQuery,
  SearchResultByBusinessQueryVariables,
} from "generated/types";
import { Option } from "utils/select";

interface Props {
  name: string;
  defaultValue?: string;
  onChange?: (businessId: string | null) => void;
}

export const BusinessSearchSelect: React.FC<Props> = ({ name, defaultValue = "", onChange }) => {
  const [{ value: businessId }, , helpers] = useField(name);
  const [options, setOptions] = useState<Option<string>[]>([]);
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query<
        SearchResultByBusinessQuery,
        SearchResultByBusinessQueryVariables
      >({
        query: SearchResultByBusinessDocument,
        variables: {
          search: `*${value}*`,
          index: "business",
        },
      });

      if (data?.searchResult?.data) {
        return data.searchResult.data
          .filter(
            (
              entity
            ): entity is {
              __typename?: "Business";
              id?: string | null;
              name?: string | null;
              inboundMemberSupportNumber?: string | null;
            } => entity.__typename === "Business"
          )
          .map(({ id, name }) => {
            return {
              value: id,
              label: name,
            };
          });
      }
    } catch (error) {}

    return [];
  };

  useEffect(() => {
    const getFirstOption = async () => {
      const { data } = await client.query<BusinessNameQuery, BusinessNameQueryVariables>({
        query: BusinessNameDocument,
        variables: {
          id: businessId,
        },
      });

      if (data?.business?.data?.name) {
        setOptions([
          {
            value: businessId,
            label: data.business.data.name,
          },
        ]);
      }
    };
    if (businessId && !options.length) {
      getFirstOption();
    }
  }, [businessId, options, client]);

  const fetch = (inputValue: string, callback: any) => {
    loadOptions(inputValue).then((results: any) => callback(results));
  };

  const debouncedSearch = debounce(fetch, 500);

  const onSearchInputChange = (event: ValueType<{ label: string; value: string }>) => {
    const option = event as { label: string; value: string };
    setOptions([option]);
    helpers.setValue(option.value);
  };

  return (
    <Async
      aria-label="search"
      placeholder="Search Business"
      loadOptions={debouncedSearch}
      onChange={onSearchInputChange}
      value={options}
      // @ts-ignore TODO:remove this
      styles={searchFieldStyles}
    />
  );
};
