import { useQuery } from "@apollo/client";
import React from "react";
import Helmet from "react-helmet";

import VisitsTable, { GET_VISITS_LIST } from "components/DataTables/VisitsTable";
import { VisitListResult, VisitSorting } from "generated/types";
import { usePageInfo } from "hooks/usePageInfo";
import { useSorting } from "hooks/useSorting";
import { nonNull } from "utils/nonNull";

interface Data {
  visits: VisitListResult;
}

type Props = {
  accountId: string;
};

const Visits: React.FC<Props> = ({ accountId }) => {
  const { page, limit } = usePageInfo();
  const [sorting, setSorting] = useSorting<VisitSorting>(["scheduledFor"]);

  const { data, loading, error } = useQuery<Data>(GET_VISITS_LIST, {
    variables: {
      filter: {
        account: {
          owner: accountId,
        },
      },
      sorting,
      pagination: { page, limit },
    },
  });

  return (
    <>
      <Helmet>
        <title>Business&apos;s Visits - Papa Admin</title>
      </Helmet>
      <VisitsTable
        data={nonNull(data?.visits.data)}
        loading={loading}
        sorting={sorting}
        onSortChange={setSorting}
        pagination={data?.visits?.pagination}
        error={error}
      />
    </>
  );
};

export default Visits;
