import React, { FunctionComponent } from "react";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Pal } from "generated/types";
import { ResetPasswordModal } from "modals/ResetPasswordModal/ResetPasswordModal";
import { SendVerificationEmailModal } from "modals/SendVerificationEmailModal";
import { ChangeStatusFormModal } from "pages/Pals/List/ChangeStatus/ChangeStatusFormModal";
import { DeletablePalType } from "pages/Pals/PalDelete";
import { PalDeleteModal } from "pages/Pals/PalDeleteModal";
import { OverviewEditModal } from "pages/Pals/shared/OverviewEditModal/OverviewEditModal";

interface Props {
  action: DataTableMenuActions;
  pal: Pal | null;
  closeModal: () => void;
}

const Actions: FunctionComponent<Props> = ({ action, pal, closeModal }: Props) => {
  if (!pal) return null;

  return (
    <>
      <PalDeleteModal
        id={pal.id}
        accountId={pal.account?.data?.id}
        fullName={pal.fullName || ""}
        palType={DeletablePalType.Pal}
        isOpen={action === DataTableMenuActions.Delete}
        toggle={closeModal}
      />

      <ResetPasswordModal
        email={pal.email || ""}
        toggle={closeModal}
        isOpen={action === DataTableMenuActions.ResetPassword}
      />

      <SendVerificationEmailModal
        email={pal.email || ""}
        emailVerified={pal?.account?.data?.emailVerified}
        toggle={closeModal}
        isOpen={action === DataTableMenuActions.SendVerificationEmail}
      />

      <OverviewEditModal
        isOpen={action === DataTableMenuActions.Edit}
        palId={pal.id}
        toggle={closeModal}
      />

      <ChangeStatusFormModal
        isOpen={action === DataTableMenuActions.SetPalStatus}
        toggle={closeModal}
        palId={pal.id}
        status={pal.status || null}
      />
    </>
  );
};

export default Actions;
