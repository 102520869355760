import { useQuery } from "@apollo/client";
import { AccountCircle } from "@styled-icons/material-twotone/AccountCircle";
import React, { useState } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";

import BusinessActionsMenu from "components/ActionsMenu/BusinessActionMenu";
import Actions from "components/ActionsMenu/BusinessActionMenu/Actions";
import { useBusinessActions } from "components/ActionsMenu/BusinessActionMenu/useBusinessActions";
import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { FlexRow } from "components/Flex";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import Nav from "components/Nav";
import Panel from "components/Panel";
import QueryErrors from "components/QueryErrors";
import config from "config";
import { BusinessSingleResult } from "generated/types";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";

import { BUSINESSES_DETAILS } from "../gql";
import About from "./About";
import BusinessPoliciesRoutes from "./BusinessPolicies/BusinessPoliciesRoutes";
import Overview from "./Overview";
import Papas from "./Papas";
import VisitProgramsRoutes from "./VisitPrograms/VisitProgramsRoutes";
import Visits from "./Visits";

type Props = {
  businessId: string;
};

const Details = ({ match }: RouteComponentProps<Props>) => {
  const {
    capitalized: { singularEntityName, pluralEntityName },
    kebabCased,
  } = useCallItClientProgramOrBusiness();

  const { businessId } = match.params;
  const { action, setAction } = useBusinessActions();
  const [handlePapasRefetch, setHandlePapasRefetch] = useState(() => () => {});
  const { data, loading, error, refetch } = useQuery<{ business: BusinessSingleResult }>(
    BUSINESSES_DETAILS,
    {
      variables: { businessId },
    }
  );

  const business = data?.business?.data;

  useTopBarOptions({
    title: `${singularEntityName} Details`,
    name: business?.name ?? "",
    icon: AccountCircle,
    breadcrumbs: [
      "Accounts",
      { label: `All ${pluralEntityName}`, link: `/${kebabCased.pluralEntityName}` },
    ],
    deps: [business?.name],
  });

  const handleRefetch = async () => {
    await refetch();
  };

  if (loading) {
    return (
      <>
        <Container>
          <Panel.SideLoading />
          <p>Loading...</p>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <Container>
        <Panel.Side />
        <QueryErrors error={error} />
      </Container>
    );
  }

  if (!business) {
    return (
      <>
        <h1>404</h1>
        <p>{singularEntityName} not found</p>
      </>
    );
  }

  return (
    <>
      <Container>
        <Overview business={business} onRefetch={handleRefetch} />

        <Main>
          <FlexRow>
            <Nav>
              <Nav.Link to={`/${kebabCased.pluralEntityName}/${businessId}/visits`}>
                Visits
              </Nav.Link>
              <Nav.Link to={`/${kebabCased.pluralEntityName}/${businessId}/about`}>About</Nav.Link>
              <Nav.Link to={`/${kebabCased.pluralEntityName}/${businessId}/papas`}>Papas</Nav.Link>
              {config.featureFlag.visitTemplate && (
                <Nav.Link to={`/${kebabCased.pluralEntityName}/${businessId}/visit-programs`}>
                  Visit Programs
                </Nav.Link>
              )}
              {config.featureFlag.businessPolicy && (
                <Nav.Link
                  to={`/${kebabCased.pluralEntityName}/${businessId}/${kebabCased.singularEntityName}-policies`}
                >
                  {singularEntityName} Policies
                </Nav.Link>
              )}
            </Nav>
            <BusinessActionsMenu business={business} setAction={setAction} />
          </FlexRow>

          <Switch>
            <Route
              exact
              path="/businesses/:businessId/visits"
              component={() =>
                business?.accountId ? <Visits accountId={business.accountId} /> : null
              }
            />
            <Route
              exact
              path="/client-programs/:businessId/visits"
              component={() =>
                business?.accountId ? <Visits accountId={business.accountId} /> : null
              }
            />
            <Route
              exact
              path="/businesses/:businessId/about"
              component={() => <About business={business} />}
            />
            <Route
              exact
              path="/client-programs/:businessId/about"
              component={() => <About business={business} />}
            />

            <Route
              exact
              path="/businesses/:businessId/papas"
              render={() =>
                business?.accountId ? (
                  <Papas accountId={business.accountId} setRefetch={setHandlePapasRefetch} />
                ) : null
              }
            />
            <Route
              exact
              path="/client-programs/:businessId/papas"
              render={() =>
                business?.accountId ? (
                  <Papas accountId={business.accountId} setRefetch={setHandlePapasRefetch} />
                ) : null
              }
            />
            {config.featureFlag.visitTemplate && (
              <Route
                path="/businesses/:businessId/visit-programs"
                component={VisitProgramsRoutes}
              />
            )}
            {config.featureFlag.visitTemplate && (
              <Route
                path="/client-programs/:businessId/visit-programs"
                component={VisitProgramsRoutes}
              />
            )}
            {config.featureFlag.businessPolicy && (
              <Route
                path="/businesses/:businessId/business-policies"
                component={() =>
                  business?.accountId ? (
                    <BusinessPoliciesRoutes
                      accountId={business.accountId}
                      businessId={business.id!}
                    />
                  ) : null
                }
              />
            )}
            {config.featureFlag.businessPolicy && (
              <Route
                path="/client-programs/:businessId/client-program-policies"
                component={() =>
                  business?.accountId ? (
                    <BusinessPoliciesRoutes
                      accountId={business.accountId}
                      businessId={business.id!}
                    />
                  ) : null
                }
              />
            )}
            <Redirect from="/businesses/:businessId" to="/businesses/:businessId/visits" />
            <Redirect
              from="/client-programs/:businessId"
              to="/client-programs/:businessId/visits"
            />
          </Switch>
        </Main>
        <Actions
          action={action}
          business={business}
          onClose={() => setAction(DataTableMenuActions.NoAction)}
          onRefetch={handleRefetch}
          onPapasRefetch={handlePapasRefetch}
        />
      </Container>
    </>
  );
};

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
`;

const Main = styled("div")`
  flex: 1;
  min-width: 0;
`;

export default Details;
