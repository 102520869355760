import { gql } from "@apollo/client";

export const GET_ELIGIBLE_MEMBERS_LIST = gql`
  query eligibleLives(
    $filter: [EligibleLifeFilter]
    $pagination: PaginationInput
    $sorting: EligibleLifeSorting
  ) {
    eligibleLives(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        memberId
        firstName
        lastName
        clientName
        dob
        status
        phone1
        phone2
        addressLn1
        addressLn2
        zip
      }
      pagination {
        limit
        afterCursor
        beforeCursor
      }
    }
  }
`;
