import { useField } from "formik";
import moment, { Moment } from "moment-timezone";
import React from "react";
import Calendar from "react-calendar";
import styled from "styled-components";

import Flex from "components/Flex";
import Text from "components/Text";

import "./react-calendar.css";

export type DayPickerCalendarProps = {
  name: string;
  isOutsideRange: (day: Moment) => boolean;
  isFocus?: boolean;
  timezone?: string;
  onChange?: (value: string) => void;
};

export const DayPickerCalendar = ({
  name,
  isOutsideRange,
  isFocus,
  onChange,
}: DayPickerCalendarProps) => {
  const [field, , helpers] = useField(name);

  const handleChange = (value: Date | null | [Date | null, Date | null]) => {
    if (!value) {
      helpers.setValue("");
      onChange && onChange("");
    } else if (!Array.isArray(value)) {
      value.setHours(12, 0, 0);
      const valueISO = moment(value).toISOString();
      helpers.setValue(valueISO);
      onChange && onChange(valueISO);
    }
  };

  const value: string | Date | null =
    field.value && moment(field.value).isValid() ? moment(field.value).toDate() : null;

  const defaultValue: Date = moment(Date.now()).startOf("day").toDate();

  function tileDisabled({ date, view }: { activeStartDate: Date; date: Date; view: View }) {
    if (view === "month") {
      return isOutsideRange(moment(date));
    }
    return false;
  }

  return (
    <>
      <Calendar
        onChange={handleChange}
        defaultValue={defaultValue}
        value={value}
        tileDisabled={tileDisabled}
        minDetail="month"
        locale="en-US"
        formatShortWeekday={(locale, date) => moment(date).format("dd")[0]}
        className={isFocus ? "react-calendar--focus" : ""}
      />
      <Flex marginTop="1rem">
        <AvailableIndicator />
        <Text as="span" size="middle">
          Available to schedule
        </Text>
      </Flex>
    </>
  );
};

export type View = "century" | "decade" | "year" | "month";

const AvailableIndicator = styled.span`
  background-color: #e3ebff;
  width: 0.875rem;
  height: 0.875rem;
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.5rem;
`;
