import { Lock } from "@styled-icons/boxicons-solid";
import React from "react";
import ContentLoader from "react-content-loader";
import styled, { css } from "styled-components";

import EmployerIcon from "assets/images/employer.svg";
import Tooltip from "components/Tooltip";
import VerticalIconsList from "components/VerticalIconsList";
import { VisitFlags } from "components/VisitFlags";
import { DEFAULT_TIMEZONE, TIME_WITH_TIMEZONE_FORMAT } from "constants/date";
import { VisitForDashboardCardFragment } from "generated/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { getFirstLastName } from "utils/strings/getFirstLastName";

import { ActionMenu } from "../ActionMenu";
import { getColorsAndName } from "../constants";

interface Props {
  visit: VisitForDashboardCardFragment;
  setVisitId: (visitId: string) => void;
}

export const Card = ({ visit, setVisitId }: Props) => {
  const currentAccountId = useCurrentAccount()?.id;

  const scheduledFor = formatDateTime(visit.scheduledFor, {
    format: TIME_WITH_TIMEZONE_FORMAT,
    timezone: visit.location?.data?.timezone || DEFAULT_TIMEZONE,
  });

  const visitFlags = nonNull(visit?.visitFlags?.data);
  const papaFullName = getFirstLastName(visit.papa?.data?.fullName ?? "");
  const stateColorsAndName = getColorsAndName(visit.state);
  const claimedBy = visit.claimedBy?.data;

  return (
    <Container isClaimedByOthers={!!claimedBy && currentAccountId !== claimedBy.id}>
      <Header onClick={() => setVisitId(visit.id!)}>
        <PapaName>{papaFullName}</PapaName>

        <HeaderIcons>
          {!!claimedBy && (
            <Tooltip title={`Claimed by ${claimedBy.fullName}`}>
              <Lock size={18} />
            </Tooltip>
          )}

          <VisitFlags
            visitFlags={visitFlags}
            isPreviouslyMissed={visit?.isPreviouslyMissed}
            iconSize={14}
          />
        </HeaderIcons>
      </Header>
      <Content>
        <VisitTime>{scheduledFor}</VisitTime>
        {visit.isEmployerVisit && (
          <Tooltip title="Employer Market Visit">
            <img src={EmployerIcon} width={15} alt="employer visit icon" />
          </Tooltip>
        )}
      </Content>
      <Footer>
        <Status color={stateColorsAndName.background}>{stateColorsAndName.name}</Status>
        <VerticalIconsList icons={nonNull(visit.tasks?.data)} maximum={4} />
        <ActionMenu visit={visit} />
      </Footer>
    </Container>
  );
};

const CardLoading = () => (
  <Container>
    <ContentLoader height={71} width="100%">
      <rect x="0" y="0" rx="4" ry="4" width="60%" height="25%" />
      <rect x="0" y="40%" rx="4" ry="4" width="50%" height="20%" />
      <rect x="0" y="80%" rx="4" ry="4" width="20%" height="20%" />
      <rect x="30%" y="80%" rx="4" ry="4" width="40%" height="20%" />
    </ContentLoader>
  </Container>
);

Card.Loading = CardLoading;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  button {
    width: 18px;
    margin: 0;
    padding: 0;
    right: 0;
    position: absolute;
  }
`;

const Status = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  border-radius: 6px;
  height: max-content;
  padding: 2px 3px;
  margin-right: 8px;
  font-size: 10px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  color: #2e4055;
  font-weight: 400;
  gap: 12px;
  margin-bottom: 4px;
`;

const Container = styled.div<{ isClaimedByOthers?: boolean }>`
  border-radius: 4px;
  box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 258px;
  padding: 16px;
  padding-left: 18px;

  ${({ isClaimedByOthers }) =>
    isClaimedByOthers &&
    css`
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    `}
`;

const PapaName = styled.div`
  font-size: 16px;
  color: #0d2973;
  font-weight: 700;
`;

const VisitTime = styled.div`
  font-size: 16px;
`;
