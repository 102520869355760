import React from "react";
import { toast } from "react-toastify";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";

import { Drawer } from "components/Drawer";
import { AssignedScreener, useCreateScreenerSubmissionMutation } from "generated/types";

import { GET_SCREENER_SUBMISSIONS } from "../gql";
import screenerTheme from "./surveyTheme";

type Props = {
  papaId: string;
  screener: Pick<AssignedScreener, "definition" | "screenerVersionId">;
  isOpen: boolean;
  onClose: () => void;
};

export const Screener = ({ papaId, screener, isOpen, onClose }: Props) => {
  const [createScreenerSubmissionMutation] = useCreateScreenerSubmissionMutation({
    refetchQueries: [{ query: GET_SCREENER_SUBMISSIONS, variables: { papaId: papaId } }],
  });

  const surveyComplete = async (sender: any) => {
    try {
      const { data } = await createScreenerSubmissionMutation({
        variables: {
          input: {
            papaId: papaId,
            screenerVersionId: screener.screenerVersionId,
            response: JSON.stringify(sender.data),
          },
        },
      });

      if (data?.createScreenerSubmission?.data?.id) {
        toast.success("Screener submitted");
      } else {
        toast.error("Unable to submit screener");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    onClose();
  };

  const digitalHealthLiteracyModel = new Model(screener.definition);

  //TODO: Retrieve survey theme from BE
  digitalHealthLiteracyModel.applyTheme(screenerTheme);

  digitalHealthLiteracyModel.onComplete.add(surveyComplete);
  digitalHealthLiteracyModel.clearInvisibleValues = false;

  return (
    <Drawer open={isOpen} onClose={onClose} title="Screener" size="xl">
      <Survey model={digitalHealthLiteracyModel} />
    </Drawer>
  );
};
