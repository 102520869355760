import React, { FunctionComponent } from "react";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import Dropdown from "components/Dropdown";
import { FeatureFlags } from "constants/featureFlags";
import { EligibleEmployee, SupportTicketRequesterType } from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { DropdownActionMenu } from "../DropdownActionMenu";

interface Props {
  eligibleEmployee: EligibleEmployee;
  isListMenu?: boolean;
}

const EligibleEmployeeActionMenu: FunctionComponent<Props> = ({
  eligibleEmployee,
  isListMenu = false,
}) => {
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();

  const id = eligibleEmployee.id!;
  const type = SupportTicketRequesterType.EligibleEmployee;

  if (loading) return <SpinningIcon size={18} />;

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "eligible employees action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {supportTicketingEnabled &&
        renderDropDownItem("Submit Ticket", () => handleSubmitSupportTicket({ id, type }))}
    </DropdownActionMenu>
  );
};

export default EligibleEmployeeActionMenu;
