import * as yup from "yup";

import { LivingSituation } from "generated/types";

export interface Schema {
  description: string;
  livingSituation: LivingSituation | null;

  hasPets?: boolean | null;
  hasCat?: boolean | null;
  hasDog?: boolean | null;
  hasOtherPet?: boolean | null;
  usesWheelchair?: boolean | null;
  smokes?: boolean | null;
  hearingImpaired?: boolean | null;
  visuallyImpaired?: boolean | null;
  cantDrive?: boolean | null;

  // "old" special considerations
  dementia?: boolean | null;
  seizure?: boolean | null;
  noPhone?: boolean | null;
  requiresStrongPal?: boolean | null;
}

export const schema = yup.object().shape({
  description: yup.string(),
  livingSituation: yup.string().nullable(),

  hasPets: yup.boolean().nullable(),
  hasCat: yup.boolean().nullable(),
  hasDog: yup.boolean().nullable(),
  hasOtherPet: yup.boolean().nullable(),
  usesWheelchair: yup.boolean().nullable(),
  smokes: yup.boolean().nullable(),
  hearingImpaired: yup.boolean().nullable(),
  visuallyImpaired: yup.boolean().nullable(),
  cantDrive: yup.boolean().nullable(),

  // "old" special considerations
  dementia: yup.boolean().nullable(),
  seizure: yup.boolean().nullable(),
  noPhone: yup.boolean().nullable(),
  requiresStrongPal: yup.boolean().nullable(),
});
