import React, { ReactElement } from "react";
import styled from "styled-components";

import Text from "components/Text";

type Props = {
  number?: number;
  headerText: string;
  children: ReactElement | ReactElement[];
};

const FormPanel = ({ number, headerText, children }: Props) => {
  return (
    <section>
      <Header>
        <span>{number}.</span>
        <HeaderText>{headerText}</HeaderText>
      </Header>
      <Content>{children}</Content>
    </section>
  );
};

const Header = styled.header`
  background: ${({ theme }) => theme.background.panelHeaderActivated};
  display: flex;
  align-items: center;
  padding-left: 1.875rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.text.panelHeader};
`;

const Content = styled.div`
  background-color: ${({ theme }) => theme.background.panelContent};
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
`;

const HeaderText = styled(Text)`
  margin-left: 0.5rem;
`;

export default FormPanel;
