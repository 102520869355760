import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import Button from "components/Button";
import Form from "components/Form";
import FormInput from "components/Form/FormInput";
import Heading from "components/Heading";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import { PalOnboardingDocument, useUpdateAccountMutation } from "generated/types";

interface Props {
  email?: string;
  id: string | undefined;
  palOnboardingId: string | undefined;
  isOpen: boolean;
  closeModal: () => void;
}

type FormikFormValue = {
  email: string | undefined;
};

export const EditProspectPalModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  id,
  palOnboardingId,
  email,
}) => {
  const [updateAccountMutation] = useUpdateAccountMutation({
    refetchQueries: [{ query: PalOnboardingDocument, variables: { palOnboardingId } }],
  });

  const initialValues = {
    email,
  };
  const handleSubmit: (
    values: FormikFormValue,
    formikHelpers: FormikHelpers<FormikFormValue>
  ) => void = async (value, { setSubmitting }) => {
    try {
      const { data } = await updateAccountMutation({
        variables: {
          id: id!,
          input: {
            email: value.email,
          },
        },
      });
      if (data?.updateAccount?.data?.id) {
        setSubmitting(false);
        closeModal();
        toast.success(`${value.email} has been updated`);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };
  const schema = yup.object().shape({
    email: yup.string().email().required("Please enter a email."),
  });

  return (
    <Modal isOpen={isOpen}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {() => (
          <>
            <StyledForm>
              <Modal.Body>
                <Container>
                  <TextContainer>
                    <StyledIcon fontWeight="bold" size={23} />
                    <Heading as="h2">Change Prospect Pal's email</Heading>
                  </TextContainer>
                  <FormInput name="email" />
                </Container>
              </Modal.Body>
              <StyledFooter>
                <Button variant="secondary" type="button" onClick={closeModal}>
                  Cancel
                </Button>
                <Form.SubmitButton>Ok</Form.SubmitButton>
              </StyledFooter>
            </StyledForm>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledIcon = styled(QuestionCircle)`
  color: #faad14;
  margin-right: 16px;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;

const StyledForm = styled(FormikForm)`
  width: 100%;
`;
