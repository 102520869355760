import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import ActivityLogsTable from "./ActivityLogsTable";
import { GET_ACTIVITY_LOGS } from "./gql";

const AccountLogs: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery(GET_ACTIVITY_LOGS, {
    variables: { accountId: id, sorting: { insertedAt: "DESC" } },
  });

  return <ActivityLogsTable logs={data} error={error} loading={loading} />;
};

export default AccountLogs;
