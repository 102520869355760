import React from "react";
import { Link } from "react-router-dom";

import { StatusCell } from "components/DataTables/VisitsTable/StatusCell";
import VerticalIconsList from "components/VerticalIconsList";
import { DEFAULT_TIMEZONE } from "constants/date";
import { EMPTY_PLACEHOLDER } from "constants/empty-placeholder";
import { Visit, VisitAction, VisitState } from "generated/types";
import { papaConcealed } from "utils/fieldsPermissions/papaConcealed";
import formatScheduledFor from "utils/formatScheduledFor";
import { formatDateTime } from "utils/helpers/formatters";
import { nonNull } from "utils/nonNull";
import { getDuration as formatDurationHours } from "utils/numbers/durations";

export const useColumns = () => {
  return [
    {
      header: { title: "Status" },
      dataField: "state",
      formatter({ state, isVirtual, events, visitFlags, isPreviouslyMissed }: Visit) {
        const isMemberConfirmed = events?.data?.some(
          (event) =>
            event?.__typename === "VisitEvent" &&
            event?.action?.toUpperCase() === VisitAction.MemberConfirmed
        );

        return (
          <StatusCell
            state={state}
            visitFlags={nonNull(visitFlags?.data)}
            isPreviouslyMissed={isPreviouslyMissed}
            isMemberConfirmed={isMemberConfirmed}
            isVirtual={isVirtual}
          />
        );
      },
    },
    {
      header: {
        title: "Scheduled For",
      },
      dataField: "scheduledFor",
      formatter(visit: Visit) {
        return (
          <>
            {formatScheduledFor(
              visit.scheduledFor,
              visit.location?.data?.timezone || DEFAULT_TIMEZONE
            )}
            {typeof visit?.estimatedDuration === "number" && (
              <>
                <br /> {formatDurationHours(visit.estimatedDuration)}
              </>
            )}
          </>
        );
      },
    },
    {
      header: { title: "Tasks" },
      dataField: "tasks.data",
      formatter(visit: Visit) {
        return <VerticalIconsList icons={nonNull(visit.tasks?.data)} />;
      },
    },
    {
      header: { title: "Business" },
      dataField: "account.data",
      formatter(visit: Visit) {
        return (
          <Link to={`/members/${visit.account?.data?.id}`}>{visit.account?.data?.fullName}</Link>
        );
      },
    },
    {
      header: { title: "Papa" },
      dataField: "papa.data",
      formatter(visit: Visit) {
        const papaConcealedEntity = papaConcealed(visit.papa?.data);
        return (
          <Link to={`/papas/${papaConcealedEntity?.id}`}>{papaConcealedEntity?.fullName}</Link>
        );
      },
    },
    {
      header: { title: "Pal" },
      dataField: "pal.data",
      formatter(visit: Visit) {
        if (visit.state === VisitState.Pending || !visit.pal?.data) {
          return EMPTY_PLACEHOLDER;
        }

        return <Link to={`/pals/${visit.pal.data.id}`}>{visit.pal.data.fullName}</Link>;
      },
    },
    {
      header: {
        title: "Inserted At",
      },
      dataField: "insertedAt",
      formatter: ({ insertedAt }: Visit) => formatDateTime(insertedAt),
    },
  ];
};
