import { gql } from "@apollo/client";

export const GET_ELIGIBLE_MEMBER = gql`
  query eligibleLife($id: ID!) {
    eligibleLife(id: $id) {
      data {
        id
        memberId
        firstName
        lastName
        gender
        language
        phone1
        phone2
        dob
        addressLn1
        addressLn2
        zip
        city
        state
        authorizedUser {
          data {
            id
            firstName
            lastName
            relationship
          }
        }
      }
    }
  }
`;

export const ACCEPT_ELIGIBLE_LIFE = gql`
  mutation acceptEligibleLife($id: ID!, $input: AcceptEligibleLifeInput) {
    acceptEligibleLife(id: $id, input: $input) {
      data {
        id
        papa {
          data {
            id
          }
        }
      }
    }
  }
`;

export const SPECIAL_CONSIDERATIONS_QUERY = gql`
  query SpecialConsiderations {
    specialConsiderations {
      data {
        id
        name
      }
    }
  }
`;
