import { Field, FieldProps } from "formik";
import React, { useContext } from "react";
import styled from "styled-components";

import FormContext from "./FormContext";
import Feedback from "./FormFeedback";

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  disabled?: boolean;
};

type ContainerProps = {
  id?: string;
  fullWidth?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
};

const TextAreaContainer = styled("div")<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "18.75rem")};
  border: 1px
    ${({ isValid, isInvalid, theme }) => {
      if (isValid) return theme.variants.success;
      if (isInvalid) return theme.variants.danger;
      return theme.borderColor;
    }}
    solid;
  background-color: white;
  opacity: ${({ disabled }) => disabled && "0.5"};
  padding: 10px;
  border-radius: 5px;

  &:focus-within {
    border: thin solid ${({ theme }) => theme.variants.primary};
  }
`;

const StyledTextArea = styled.textarea<Props>`
  flex: 1;
  border: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: inherit;
    opacity: 0.5;
  }
`;

const FormTextArea: React.FC<Props & ContainerProps> = ({
  id,
  isValid,
  isInvalid,
  fullWidth,
  name,
  ...rest
}: Props & ContainerProps) => {
  const { controlId } = useContext(FormContext);

  return (
    <Field name={name}>
      {({ field, form, meta: { touched, error } }: FieldProps) => (
        <>
          <TextAreaContainer
            isValid={isValid}
            isInvalid={isInvalid || (touched && !!error)}
            fullWidth={fullWidth}
            disabled={form.isSubmitting}
          >
            <StyledTextArea
              disabled={form.isSubmitting}
              {...rest}
              {...field}
              id={id || controlId}
              aria-label={name}
            />
          </TextAreaContainer>
          {touched && !!error && <Feedback isInvalid>{error}</Feedback>}
        </>
      )}
    </Field>
  );
};

export default FormTextArea;
