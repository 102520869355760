import { useQuery } from "@apollo/client";
import { Formik, Form as FormikForm } from "formik";
import queryString from "query-string";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Form from "components/Form";
import {
  CheckboxContainer,
  Container,
  FilterTitle,
  FilterType,
  Footer,
  StyledFormGroup,
} from "components/Modal/FilterModal.styles";
import { ModalContainer } from "components/Modal/Modal.styles";
import { usePageInfo } from "hooks/usePageInfo";
import { ObjectiveTasksData } from "types/objective";

import { GET_OBJECTIVE_TASKS } from "../ObjectiveForm/gql";

interface FormValues {
  taskIds: string[];
}

interface Props {
  onSubmit: () => void;
}

interface Data {
  tasks: ObjectiveTasksData;
}

const FiltersForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const history = useHistory();
  const { tasks: queryTasks } = usePageInfo();
  const { data, loading } = useQuery<Data>(GET_OBJECTIVE_TASKS, {
    variables: { pagination: { limit: 1000 } },
  });
  const tasks = data?.tasks.data || [];
  const initialValues = {
    taskIds: queryTasks?.split(",") || [],
  };

  const handleSubmit = async ({ taskIds }: FormValues) => {
    history.push(
      "?" +
        queryString.stringify({
          page: 1,
          ...(taskIds?.length ? { tasks: taskIds.join(",") } : {}),
        })
    );

    onSubmit();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <FormikForm>
        <Container>
          <ModalContainer>
            <StyledFormGroup>
              <FilterTitle bold size="largex">
                Filter by
                <FilterType as="span">tasks</FilterType>
              </FilterTitle>

              {loading ? (
                <SpinningIcon size={18} />
              ) : (
                <CheckboxContainer>
                  {tasks.map(({ name, id }) => (
                    <div key={id}>
                      <Form.Check
                        controlId={`visitState-${id}`}
                        name="taskIds"
                        value={id}
                        label={name}
                      />
                    </div>
                  ))}
                </CheckboxContainer>
              )}
            </StyledFormGroup>
          </ModalContainer>

          <Footer>
            <Button type="submit" variant="primary">
              Apply
            </Button>
          </Footer>
        </Container>
      </FormikForm>
    </Formik>
  );
};

export default FiltersForm;
