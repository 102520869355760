import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

import { Gender } from "generated/types";

export interface Schema {
  fullName: string;
  email: string;
  dateOfBirth: Date | null;
  phoneNumber: string;
  gender: Gender | null;
}

export const schema = yup.object().shape({
  fullName: yup.string().required("Please input full name."),
  email: yup.string().email("Not a valid email address!").required("Please input email."),
  dateOfBirth: yup.string().required("Please input birth date.").nullable(),
  phoneNumber: yup
    .string()
    .required("Please input phone number.")
    .test("phoneNumber", "Not a valid phone number!", (value) =>
      value ? isValidPhoneNumber(value) : true
    ),
  gender: yup.string().nullable().required("Please select gender."),
});
