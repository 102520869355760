import styled, { css } from "styled-components";

import Text, { Props as TextProps } from "components/Text";

export type Props = {
  as?: "h1" | "h2" | "h3";
  ellipsis?: boolean;
} & TextProps;

const defaultProps: Props = {
  as: "h1",
  bold: true,
};

const Heading = styled(Text).attrs(({ as = defaultProps.as }: Props) => ({
  as,
}))<Props>`
  font-size: ${({ as = defaultProps.as }) => {
    if (as === "h1") return "1.250rem";
    if (as === "h2") return "1.125rem";
    return "1rem";
  }};
  line-height: ${({ as = defaultProps.as }) => {
    if (as === "h1") return "1.75rem";
    if (as === "h2") return "1.625rem";
    return "1.5rem";
  }};
  margin: 0 0 0.5rem;
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    `}
`;

export default Heading;
