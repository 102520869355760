import moment from "moment-timezone";

import { BusinessPolicyFragment } from "generated/types";

export const getBusinessPolicyStatus = (businessPolicy: BusinessPolicyFragment) => {
  const startsAt = moment(businessPolicy?.startsAt);
  const endsAt = moment(businessPolicy?.endsAt);
  const now = moment();

  if (!startsAt.isValid() || !endsAt.isValid() || endsAt < startsAt) {
    return "Invalid";
  } else if (startsAt < now && endsAt < now) {
    return "Inactive";
  } else if (startsAt < now && endsAt > now) {
    return "Current";
  } else if (startsAt > now && endsAt > now) {
    return "Next";
  } else {
    return "Invalid";
  }
};
